import { DexpEndpoint, DexpEndpointLevel, DexpEndpointTiers } from '@hcs/types';

export const dexpCorrectEndpointIdAndLevel = (endpoint: DexpEndpoint) => {
  if (endpoint.level === DexpEndpointLevel.Property) {
    if (endpoint.id.includes('value_report')) {
      return {
        level: 'value-report',
        endpointId: endpoint.id
          .split('value_report')[1]
          ?.split('_')
          .filter((p) => !!p)
          .join('-'),
      };
    }
    return {
      level: endpoint.level,
      endpointId: endpoint.id.replace('property-api/', '').split('/')[1],
    };
  } else if (endpoint.level === DexpEndpointLevel.ValueReport) {
    return {
      level: endpoint.level,
      endpointId: endpoint.id.split('/')[1]?.replace(/_/g, '-'),
    };
  } else {
    return {
      level: endpoint.level,
      endpointId: endpoint.id
        .replace('property-api/', '')
        .split('/')[1]
        ?.split('_')
        ?.slice(1)
        .join('_'),
    };
  }
};

export const dexpFixEndpointId = (endpoint: DexpEndpoint) => {
  const { endpointId, level } = dexpCorrectEndpointIdAndLevel(endpoint);
  if (endpoint.id === 'property/property') {
    return 'property/estimate';
  }
  return `${level}/${endpointId}`;
};

export const endpointToCapability = (endpointId: string) => {
  if (endpointId.startsWith('property/zip_')) {
    return endpointId.replace('property/zip_', 'zip/');
  }
  if (endpointId.startsWith('property/block_')) {
    return endpointId.replace('property/block_', 'block/');
  }
  if (endpointId.startsWith('property/blockgroup_')) {
    return endpointId.replace('property/blockgroup_', 'blockgroup/');
  }
  if (endpointId.startsWith('property/msa_')) {
    return endpointId.replace('property/msa_', 'msa/');
  }
  if (endpointId.startsWith('property/state_')) {
    return endpointId.replace('property/state_', 'state/');
  }
  return endpointId;
};

export const getEndpointTierLabelAndCostKey = (
  endpoint: DexpEndpoint,
  dexpTiers: DexpEndpointTiers | undefined,
) => {
  const fixedEndpointId = dexpFixEndpointId(endpoint);
  const capabilityKey = endpointToCapability(fixedEndpointId);
  return !dexpTiers
    ? {}
    : capabilityKey === 'property/geocode' ||
        capabilityKey === 'property/census'
      ? { endpointTierLabel: 'Free', endpointCostKey: null }
      : dexpTiers['data-points-basic'].capabilities.find(
            (c) => c.key === capabilityKey,
          )
        ? { endpointTierLabel: 'Basic', endpointCostKey: 'data-points-basic' }
        : dexpTiers['data-points-premium'].capabilities.find(
              (c) => c.key === capabilityKey,
            )
          ? {
              endpointTierLabel: 'Premium',
              endpointCostKey: 'data-points-premium',
            }
          : dexpTiers['data-points-premium-plus'].capabilities.find(
                (c) => c.key === capabilityKey,
              )
            ? {
                endpointTierLabel: 'Premium-Plus',
                endpointCostKey: 'data-points-premium-plus',
              }
            : dexpTiers['property-estimate-api'].capabilities.find(
                  (c) => c.key === capabilityKey,
                )
              ? {
                  endpointTierLabel: 'Property Estimate',
                  endpointCostKey: 'property-estimate-api',
                }
              : dexpTiers['build-to-api'].capabilities.find(
                    (c) => c.key === capabilityKey,
                  )
                ? {
                    endpointTierLabel: 'Build to Rent',
                    endpointCostKey: 'build-to-api',
                  }
                : {};
};
