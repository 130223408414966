import React, { ReactNode, useState } from 'react';
import * as yup from 'yup';

import {
  Button,
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
  EditIcon,
  IconButton,
  Input,
  Switch,
  TextButton,
} from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { useActiveState } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import { useEditOrganization, useOrganizationInternal } from '../../hooks';
import { useUpdateOrganizationName } from '../../hooks/useUpdateOrganizationName';
import { formatOrgType } from '../../utils';
import { OrgConvertToSelfServe } from '../OrgConvertToSelfServe/OrgConvertToSelfServe';

import styles from './EditOrganizationForm.module.css';

export const FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
});

interface Props {
  orgId: number;
  actionPortalIdRender?: string;
  onSubmit?: VoidFunction;
}
export const EditOrganizationForm = ({
  orgId,
  onSubmit,
  actionPortalIdRender,
}: Props) => {
  const { mutate: updateOrgName, isLoading } = useUpdateOrganizationName();
  const { data: orgData } = useOrganizationInternal(orgId);
  const [orgName, setOrgName] = useState(orgData?.name || '');
  const [convertToSelfServe, setConvertToSelfServe] = useState(false);
  const { mutate: editOrg } = useEditOrganization();
  if (convertToSelfServe && orgData) {
    return (
      <OrgConvertToSelfServe
        orgId={orgId}
        orgType={orgData.type}
        onSubmit={onSubmit}
        onCancel={() => setConvertToSelfServe(false)}
        actionPortalIdRender={actionPortalIdRender}
      />
    );
  }
  return (
    <div className={styles.Form}>
      <DialogInputLayout dataHcName="org-name" label="Name" required={false}>
        <div className={styles.InputRow}>
          <Input
            dataHcName="org-name-input"
            placeholder="Organization Name"
            value={orgName || orgData?.name || ''}
            onChange={setOrgName}
            className={styles.LargeCell}
          />
          <Button
            dataHcName="org-name-submit"
            disabled={!orgName || orgName === orgData?.name}
            loading={isLoading}
            onClick={() => {
              updateOrgName({
                orgId,
                name: orgName,
              });
              if (onSubmit) {
                onSubmit();
              }
            }}
          >
            Submit
          </Button>
        </div>
      </DialogInputLayout>
      <DialogInputLayout dataHcName="org-type" label="Type" required={false}>
        <div className={styles.InputRow}>
          <b>{formatOrgType(orgData?.type)}</b>
          {/* only want to allow for legacy-ss for now, the BE needs more work to support enterprise */}
          {orgData?.type && orgData.type === 'legacy-ss' && (
            <div>
              <TextButton
                dataHcName="convert-to-selfserve"
                onClick={() => setConvertToSelfServe(true)}
              >
                Convert to Self Serve
              </TextButton>
            </div>
          )}
        </div>
      </DialogInputLayout>
      <DialogInputLayout
        dataHcName="org-active"
        label="Active"
        labelHelper="Deactivating an org will prevent all users of that org from logging in."
        required={false}
      >
        <Switch
          dataHcName="org-active-switch"
          switchOnOption={{ value: true }}
          switchOffOption={{ value: false }}
          value={!!orgData?.active}
          onChange={(active) => {
            editOrg({ orgId, active });
          }}
        />
      </DialogInputLayout>
      <DialogInputLayout
        dataHcName="org-test"
        label="Test"
        labelHelper="Indicates if the organization is for testing purposes."
        required={false}
      >
        <Switch
          dataHcName="org-test-switch"
          switchOnOption={{ value: true }}
          switchOffOption={{ value: false }}
          value={!!orgData?.test}
          onChange={(test) => {
            editOrg({ orgId, test });
          }}
        />
      </DialogInputLayout>
    </div>
  );
};

interface EditOrganizationFormLauncherProps {
  trigger?: ReactNode;
  orgId: number;
  size?: IconProps['size'];
}

export const EditOrganizationFormLauncher = ({
  trigger,
  orgId,
  size,
}: EditOrganizationFormLauncherProps) => {
  const { active, handleClose, handleOpen } = useActiveState();
  const { data: organization } = useOrganizationInternal(orgId);
  return (
    <>
      {trigger ? (
        <span
          data-hc-name={`org-details-edit-launcher-button`}
          onClick={handleOpen}
        >
          {trigger}
        </span>
      ) : (
        <IconButton
          dataHcName={`org-details-edit-launcher-edit`}
          icon={<EditIcon size={size} />}
          onClick={handleOpen}
          tooltip={{
            label: 'Edit Org Details',
          }}
        />
      )}
      <Dialog
        title={`Edit ${organization?.name} Details`}
        dataHcName={`org-details-edit-launcher-overlayPage`}
        active={active}
        theme={{ DialogContent: styles.DialogContent }}
        onClose={handleClose}
      >
        <EditOrganizationForm
          orgId={orgId}
          onSubmit={handleClose}
          actionPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
        />
      </Dialog>
    </>
  );
};
