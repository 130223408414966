import React from 'react';
import classNames from 'classnames';

import { DotIndicator } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { ValidationResult } from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import styles from './ItemIssuesPopover.module.css';

type PopoverTriggerProps = {
  dataHcName: string;
  active?: boolean;
  firstValidationResult: ValidationResult;
  issuePopoverText: string;
};
const PopoverTrigger = (props: PopoverTriggerProps) => {
  const { dataHcName, firstValidationResult, issuePopoverText } = props;
  return (
    <div className={styles.Trigger}>
      <DotIndicator
        dataHcName={`${dataHcName}-dot-indicator`}
        colorType={firstValidationResult.level === 'warning' ? 'orange' : 'red'}
      />
      <span
        className={classNames({
          [styles.warning]: firstValidationResult.level === 'warning',
          [styles.error]: firstValidationResult.level === 'error',
        })}
      >
        {issuePopoverText}
      </span>
    </div>
  );
};

interface Props {
  validationResults: ValidationResult[];
}
const getErrorMessage = (validationResult: ValidationResult | undefined) => {
  if (!validationResult) {
    return undefined;
  }
  let message = validationResult.message;
  if (validationResult.message.startsWith('{')) {
    try {
      message = capitalizeFirstLetter(JSON.parse(message).error || '');
    } catch {
      console.warn('Could not parse error message');
    }
  }
  return message;
};

const dataHcName = 'item-issues-popover';
export const ItemIssuesPopover = ({ validationResults }: Props) => {
  let issuePopoverText = 'No Issues';
  let firstValidationResult: ValidationResult | null = null;
  if (validationResults.length > 0) {
    firstValidationResult = validationResults[0] || null;
    issuePopoverText = `${getErrorMessage(firstValidationResult)} ${
      validationResults.length > 1 ? ' +' + (validationResults.length - 1) : ''
    }`;
  }

  return (
    <div>
      {firstValidationResult ? (
        <Tooltip
          dataHcName={dataHcName}
          trigger={
            <PopoverTrigger
              dataHcName={`${dataHcName}-popover-trigger`}
              firstValidationResult={firstValidationResult}
              issuePopoverText={issuePopoverText}
            />
          }
          label={
            <div className={styles.AdditionalIssuesContainer}>
              {validationResults.map(
                (validationResult: ValidationResult, index: number) => {
                  return (
                    <div className={styles.AdditionalIssueItem} key={index}>
                      <div
                        className={classNames(styles.AdditionalIssueText, {
                          [styles.warning]:
                            validationResult.level === 'warning',
                          [styles.error]: validationResult.level === 'error',
                        })}
                      >
                        {`${validationResult.level
                          .charAt(0)
                          .toUpperCase()}${validationResult.level.slice(
                          1,
                        )} - ${getErrorMessage(validationResult)}`}
                      </div>
                      <div>{validationResult.detail}</div>
                    </div>
                  );
                },
              )}
            </div>
          }
        />
      ) : (
        <div data-hc-name={dataHcName} className={styles.Trigger}>
          <DotIndicator
            dataHcName={`${dataHcName}-no-issue-dot-indicator`}
            colorType="green"
          />
          <span className={styles.NoIssuesText}>{issuePopoverText}</span>
        </div>
      )}
    </div>
  );
};
