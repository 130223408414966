import { useMutation } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { ReportId, ReportTypes } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

import { ReportApi } from '../api';

import { useReport } from './useReport';
import { useReportConfig } from './useReportConfig';
import { useSubjectDocument } from './useSubjectDocument';

export const useDownloadReportPdf = (reportId: ReportId) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const reportType = reportConfig?.reportType;
  const reportQuery = useReport(reportId);
  const { data: report } = reportQuery;
  const effectiveDate = report?.effectiveDate;
  const productName =
    reportConfig?.productName === 'Property Explorer'
      ? 'PEXP'
      : reportConfig?.productName;

  return useMutation(
    async () => {
      return await ReportApi.downloadReportPdf({
        reportId,
        filename: `${productName ? `${productName} - ` : ''}${
          reportType === ReportTypes.EffectiveDate
            ? `Effective Date ${effectiveDate} - `
            : ''
        }${formatFullAddress(
          subjectDocument?.data.propertyState.location,
        )}.pdf`,
      });
    },
    {
      onMutate: () => {
        toastOpen({
          title: 'Downloading PDF...',
          type: 'loading',
          duration: null,
        });
      },
      onSuccess: () => {
        toastOpen({
          title: 'PDF Downloaded',
          type: 'loading-success',
        });
      },
      onError: () => {
        toastOpen({
          title: 'PDF Download Failed',
          type: 'loading-failure',
        });
      },
    },
  );
};
