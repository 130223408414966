import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { FetchOrgUsersParams, OrgAdminApi } from '@hcs/org-admin';
import { DEFAULT_USERS_PAGE, DEFAULT_USERS_PAGE_SIZE } from '@hcs/org-admin';
import { AdminFeatureOrgProps } from '@hcs/types';
import {
  OrgUserFilterIds,
  OrgUserFilters,
  OrgUserOrInvitation,
  UseQueriesParams,
} from '@hcs/types';

import { HcsAdminOrgsApi } from '../api';

type OrgUsersArgs = AdminFeatureOrgProps & {
  params?: Partial<UseQueriesParams>;
  requestAllUsers?: boolean;
  filters?: OrgUserFilters;
};

export const QUERY_KEY_ORG_USERS_LEGACY = 'org-users-legacy';

export const useOrgUsersLegacy = ({
  mode,
  orgId,
  params,
  requestAllUsers,
  filters,
}: OrgUsersArgs) => {
  const queryResult = useQuery(
    [QUERY_KEY_ORG_USERS_LEGACY, orgId, params, requestAllUsers],
    async () => {
      const apiMethod =
        mode === 'internal'
          ? async (params: FetchOrgUsersParams) =>
              await HcsAdminOrgsApi.fetchOrgUsers(orgId, { ...params })
          : OrgAdminApi.fetchOrgUsers;
      const apiParams: FetchOrgUsersParams = {
        page: params?.page || DEFAULT_USERS_PAGE,
        itemsPerPage: params?.itemsPerPage || DEFAULT_USERS_PAGE_SIZE,
        search: params?.search ? params.search : undefined,
        orderBy: params?.orderBy
          ? {
              field: params?.orderBy.field,
              direction: params?.orderBy.order,
            }
          : undefined,
      };

      /**
       * To get all users, we need to make a get request to get data of
       * the first page then use totalPages value from the response
       * to make get requests the rest of pages.
       */
      if (requestAllUsers) {
        const firstPageResponse = await apiMethod({
          ...apiParams,
          page: 1,
        });
        const totalPages = firstPageResponse.totalPages;
        const invitationsCount = firstPageResponse.invitationsCount;
        const usersCount = firstPageResponse.invitationsCount;
        const totalResults = firstPageResponse.totalPages;
        const usersDataFirstPage = firstPageResponse.objects;

        let allUsersData = [...usersDataFirstPage];
        const morePageNoArr = [];

        for (let i = 2; i <= totalPages; i++) {
          morePageNoArr.push(i);
        }

        const usersResponse = morePageNoArr.map(async (pageNo) => {
          return await apiMethod({
            ...apiParams,
            page: pageNo,
          });
        });

        const moreUsersResponse = await Promise.all(usersResponse);

        moreUsersResponse.forEach((response) => {
          allUsersData = [...allUsersData, ...response.objects];
        });
        return {
          page: null,
          invitationsCount: invitationsCount,
          usersCount: usersCount,
          totalResults: totalResults,
          totalPages: totalPages,
          objects: allUsersData,
        };
      } else {
        return await apiMethod(apiParams);
      }
    },
  );

  const getFilteredUsersData = (
    filters: OrgUserFilters,
    queryResultObjects?: OrgUserOrInvitation[],
  ): OrgUserOrInvitation[] => {
    let field: OrgUserFilterIds;
    let usersData;

    for (field in filters) {
      if (field in OrgUserFilterIds) {
        usersData = queryResultObjects?.filter(
          (user) => user[field] === filters[field],
        );
      }
    }

    return usersData || [];
  };

  const usersData = useMemo(() => {
    const queryResultObjects = queryResult.data?.objects;
    const filteredUsersData = filters
      ? getFilteredUsersData(filters, queryResultObjects)
      : queryResultObjects;

    return {
      page: queryResult.data?.page,
      invitationsCount: queryResult.data?.invitationsCount,
      usersCount: queryResult.data?.usersCount,
      totalResults: queryResult.data?.totalResults,
      totalPages: queryResult.data?.totalPages,
      objects: filteredUsersData || [],
    };
  }, [filters, queryResult.data]);

  return {
    ...queryResult,
    data: usersData,
  };
};
