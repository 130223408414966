import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { LayoutBanner } from '@hcs/design-system';
import { EntryPageContainer } from '@hcs/design-system';
import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderOutlet } from '@hcs/design-system';
import { APP_CONFIG_DEXP, VIEW_PATHS_DEXP } from '@hcs/hc-products';
import { build404RedirectRoute } from '@hcs/utils';

import dexpBannerPng from '../../../assets/images/dexp-banner-bg.png';
import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { DexpNavBeta } from '../navigation/DexpNav';
import { NewAnalysisPageNav } from '../navigation/NewAnalysisPageNav';
import {
  AnalysisResultsPage,
  GenerateAnalysisPage,
  NewAnalysisPage,
} from '../pages';

export const routes = (): RouteObject[] => {
  return [
    {
      path: APP_CONFIG_DEXP.rootPath,
      element: (
        <PrivateApplicationPageRedirect>
          <LayoutGlobalHeaderOutlet
            header={<SolutionsPlatformGlobalHeader />}
          />
        </PrivateApplicationPageRedirect>
      ),
      children: [
        {
          path: '',
          element: <Navigate to={VIEW_PATHS_DEXP.NEW_ANALYSIS} replace />,
        },
        {
          path: VIEW_PATHS_DEXP.NEW_ANALYSIS,
          element: (
            <>
              <DexpNavBeta />
              <NewAnalysisPageNav />
              <Outlet />
            </>
          ),
          children: [
            {
              path: '',
              element: (
                <Navigate to={VIEW_PATHS_DEXP.SELECT_DATA_POINTS} replace />
              ),
            },
            {
              path: `${VIEW_PATHS_DEXP.SELECT_DATA_POINTS}`,
              element: (
                <EntryPageContainer>
                  <LayoutBanner
                    bannerContent={<img alt="" src={dexpBannerPng} />}
                  >
                    <NewAnalysisPage />
                  </LayoutBanner>
                </EntryPageContainer>
              ),
            },
            {
              path: `${VIEW_PATHS_DEXP.GENERATE_ANALYSIS}`,
              element: (
                <LayoutContent>
                  <GenerateAnalysisPage />
                </LayoutContent>
              ),
            },
          ],
        },
        {
          path: VIEW_PATHS_DEXP.PREVIOUS_ANALYSES,
          element: (
            <>
              <DexpNavBeta />
              <LayoutContent>
                <AnalysisResultsPage />
              </LayoutContent>
            </>
          ),
        },
        {
          path: APP_CONFIG_DEXP.rootPath,
          element: <Navigate to={VIEW_PATHS_DEXP.NEW_ANALYSIS} replace />,
        },
        build404RedirectRoute(APP_CONFIG_DEXP.rootPath),
      ],
    },
  ];
};
