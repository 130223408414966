import { useEffect } from 'react';

import { useAccount } from '@hcs/authn';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';

import { useTrackEngagementEvent } from '../../hooks';
import { useEventQueueSlice } from '../../hooks/useEventQueueSlice';

/** Feature to queue engagement events until relevant user state is known */
export const EngagementEventQueue = () => {
  const { isFetched: isAuthStateKnown, data: account } = useAccount();
  const {
    isFetched: isSelfServeStateFetched,
    isFetching: isSelfServeStateFetching,
  } = useOrgSelfServeInfo();
  const {
    actions: { setShouldQueueEvents, clearQueuedEvents },
    state: { shouldQueueEvents, events },
  } = useEventQueueSlice();
  const { mutate: trackEngagementEvent } = useTrackEngagementEvent();
  // Self serve state can be re-fetched when the user changes or cancels their plan
  // Queueing should be renabled while the request is in flight
  const isSelfServeStateKnown =
    isSelfServeStateFetched && !isSelfServeStateFetching;
  useEffect(() => {
    // If user is not logged in we don't need to wait for self serve state
    if (isAuthStateKnown && (account ? isSelfServeStateKnown : true)) {
      // Disable queueing when we know the user's auth and self serve state
      if (shouldQueueEvents) {
        setShouldQueueEvents(false);
      }
      if (events.length) {
        // Resend all queued events
        events.forEach((e) => trackEngagementEvent(e));
        // Clear the queue
        clearQueuedEvents();
      }
    } else {
      if (!shouldQueueEvents) {
        // Queue events if we don't know the user's auth and self serve state
        setShouldQueueEvents(true);
      }
    }
  }, [
    isAuthStateKnown,
    isSelfServeStateKnown,
    account,
    shouldQueueEvents,
    events,
    setShouldQueueEvents,
    clearQueuedEvents,
    trackEngagementEvent,
  ]);
  return null;
};
