import React from 'react';
import * as yup from 'yup';

import { AccountFieldConfig, AccountFields } from '@hcs/types';
import { TERMS_OF_USE_CANARYAI_URL } from '@hcs/urls';
import { validatorUtils } from '@hcs/utils';

const { minFieldSizeMessage, maxFieldSizeMessage } = validatorUtils;

const MAX_EMAIL_LENGTH = 128;
const MIN_PASSWORD_LENGTH = 8;
export const REQUIRED_MESSAGE = 'Required';
const MAX_NAME_LENGTH = 32;
const MAX_COMPANY_LENGTH = 64;
export const INVALID_EMAIL_MESSAGE = 'Invalid email';
const PASSWORD_CONFIRM_ERROR_MESSAGE = 'The new password fields do not match.';
const NON_LICENSED_AGREEMENT_ERROR_MESSAGE =
  '* You must agree in order to proceed';

const transformEmptyStringToNull = (v: string): string | null =>
  v === '' ? null : v;

const COMMON_PASSWORD_FIELD_CONFIG: Omit<
  AccountFieldConfig,
  'label' | 'field'
> = {
  inputProps: {
    type: 'password',
  },
  validator: yup
    .string()
    .min(
      MIN_PASSWORD_LENGTH,
      minFieldSizeMessage('Password', MIN_PASSWORD_LENGTH),
    )
    .required(REQUIRED_MESSAGE),
};

export const ACCOUNT_FIELD_CONFIGS: {
  [field in AccountFields]: AccountFieldConfig;
} = {
  [AccountFields.FirstName]: {
    label: 'First Name*',
    field: AccountFields.FirstName,
    validator: yup
      .string()
      .max(MAX_NAME_LENGTH, maxFieldSizeMessage('First Name', MAX_NAME_LENGTH))
      .required(REQUIRED_MESSAGE),
  },
  [AccountFields.LastName]: {
    label: 'Last Name*',
    field: AccountFields.LastName,
    validator: yup
      .string()
      .max(MAX_NAME_LENGTH, maxFieldSizeMessage('Last Name', MAX_NAME_LENGTH))
      .required(REQUIRED_MESSAGE),
  },
  [AccountFields.Email]: {
    label: 'Email*',
    field: AccountFields.Email,
    inputProps: {
      type: 'email',
    },
    validator: yup
      .string()
      .email(INVALID_EMAIL_MESSAGE)
      .max(MAX_EMAIL_LENGTH, maxFieldSizeMessage('Email', MAX_EMAIL_LENGTH))
      .required(REQUIRED_MESSAGE),
  },
  [AccountFields.Password]: {
    ...COMMON_PASSWORD_FIELD_CONFIG,
    label: 'Password*',
    field: AccountFields.Password,
  },
  [AccountFields.CurrentPassword]: {
    ...COMMON_PASSWORD_FIELD_CONFIG,
    label: 'Current Password*',
    field: AccountFields.CurrentPassword,
  },
  [AccountFields.NewPassword]: {
    ...COMMON_PASSWORD_FIELD_CONFIG,
    label: 'New Password*',
    field: AccountFields.NewPassword,
  },
  [AccountFields.ConfirmPassword]: {
    ...COMMON_PASSWORD_FIELD_CONFIG,
    label: 'Confirm Password*',
    field: AccountFields.ConfirmPassword,
    validator: COMMON_PASSWORD_FIELD_CONFIG.validator.oneOf(
      [yup.ref(AccountFields.NewPassword), null],
      PASSWORD_CONFIRM_ERROR_MESSAGE,
    ),
  },
  [AccountFields.StreetAddress]: {
    label: 'Street Address',
    field: AccountFields.StreetAddress,
    validator: yup
      .string()
      .max(255, maxFieldSizeMessage('Street Address', 255)),
  },
  [AccountFields.City]: {
    label: 'City',
    field: AccountFields.City,
    validator: yup.string().max(40, maxFieldSizeMessage('City', 40)),
  },
  [AccountFields.State]: {
    label: 'State',
    field: AccountFields.State,
    validator: yup.string().max(2, maxFieldSizeMessage('State', 2)),
  },
  [AccountFields.Zipcode]: {
    label: 'Zip Code',
    field: AccountFields.Zipcode,
    validator: yup
      .string()
      .nullable()
      .transform(transformEmptyStringToNull)
      .min(5, minFieldSizeMessage('Zip Code', 5))
      .max(20, maxFieldSizeMessage('Zip Code', 20)),
  },
  [AccountFields.Phone]: {
    label: 'Phone Number',
    field: AccountFields.Phone,
    validator: yup
      .string()
      .nullable()
      .transform(transformEmptyStringToNull)
      .min(10, minFieldSizeMessage('Phone Number', 10))
      .max(20, maxFieldSizeMessage('Phone Number', 20)),
  },
  [AccountFields.CompanyName]: {
    label: 'Company',
    field: AccountFields.CompanyName,
    validator: yup
      .string()
      .max(
        MAX_COMPANY_LENGTH,
        maxFieldSizeMessage('Company', MAX_COMPANY_LENGTH),
      ),
  },
  [AccountFields.NonLicensedAgreement]: {
    label: 'Eligibility Confirmation',
    field: AccountFields.NonLicensedAgreement,
    description:
      'By signing up and creating an account, you are confirming that you are not currently a licensed real estate agent, licensed real estate broker, or equivalent in the state(s) from which you will be accessing HouseCanary products. False attestation may result in a cancellation of your account at the sole discretion of HouseCanary or its affiliates.',
    validator: yup
      .boolean()
      .oneOf([true], NON_LICENSED_AGREEMENT_ERROR_MESSAGE)
      .required(NON_LICENSED_AGREEMENT_ERROR_MESSAGE),
  },
};

export const CARARYAI_FIELD_CONFIGS: {
  [field in AccountFields]: AccountFieldConfig;
} = {
  ...ACCOUNT_FIELD_CONFIGS,
  [AccountFields.NonLicensedAgreement]: {
    ...ACCOUNT_FIELD_CONFIGS[AccountFields.NonLicensedAgreement],
    label: 'Eligibility Confirmation & Agreement to Terms of Use',
    description: (
      <span>
        By signing up and creating an account, you are confirming that you are
        not currently a licensed real estate agent, licensed real estate broker,
        or equivalent in the state(s) from which you will be accessing
        HouseCanary products. False attestation may result in a cancellation of
        of your account at the sole discretion of HouseCanary or its affiliates.
        You also agree to our CanaryAI&nbsp;
        <a target="_blank" href={TERMS_OF_USE_CANARYAI_URL} rel="noreferrer">
          Terms of Use
        </a>
        .
      </span>
    ),
  },
};
