import { useMemo } from 'react';
import { DefaultValues } from 'react-hook-form';
import * as yup from 'yup';

import { MarketGrade } from '@hcs/types';
import { CerberusStatsFields, MarketLevel } from '@hcs/types';
import {
  CerberusStatsFieldFilters,
  CerberusStatsFilterField,
  CerberusStatsFiltersFormData,
  CerberusStatsFiltersFormMinFieldNames,
  CerberusStatsFiltersRangeFormFieldNames,
} from '@hcs/types';
import { getRangeMaxYup, getRangeMinYup } from '@hcs/utils';
import { getMaxFromRangeValue, getMinFromRangeValue } from '@hcs/utils';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../configs';
import { useCerberusStatsFields } from '../hooks/useCerberusStatsFields';
import {
  getMarketGradeName,
  getMaxFieldName,
  getMinFieldName,
  isCerberusStatsFilterField,
} from '../utils/cerberusStatsFilters.utils';

const decimalToPercent = (
  num?: number | null,
  decimalPlaces = 1,
): number | null | undefined => {
  if (num != null) {
    return Number((num * 100).toFixed(decimalPlaces));
  }
  return num;
};

export const useCerberusStatsFiltersForm = ({
  fieldFilters,
  filterSetQueryIsLoading,
  marketLevel,
}: {
  fieldFilters?: CerberusStatsFieldFilters | null;
  filterSetQueryIsLoading: boolean;
  marketLevel: MarketLevel;
}) => {
  const { data: cerberusStatsFields } = useCerberusStatsFields(
    marketLevel === 'msa' ? 'columnsMsa' : 'columnsZip',
  );

  const filterableFields = useMemo(() => {
    if (!cerberusStatsFields) {
      return null;
    }
    return cerberusStatsFields.order.reduce<CerberusStatsFilterField[]>(
      (accum, field) => {
        if (isCerberusStatsFilterField(field)) {
          accum.push(field);
        }
        return accum;
      },
      [],
    );
  }, [cerberusStatsFields]);

  // yup resolver
  const validationSchema = useMemo(() => {
    if (!filterableFields) {
      return null;
    }
    return yup.object().shape(
      filterableFields.reduce<
        Partial<
          Record<
            CerberusStatsFiltersRangeFormFieldNames,
            yup.NumberSchema<number | null | undefined>
          >
        >
      >((accum, field) => {
        if (field !== CerberusStatsFields.marketGrade) {
          const filterConfig =
            CERBERUS_STATS_FIELD_CONFIGS[field]?.filterConfig;
          const minFieldName = getMinFieldName(field);
          const maxFieldName = getMaxFieldName(field);
          accum[minFieldName] = getRangeMinYup({
            minValue: filterConfig?.minValue,
            shouldFormatNumber: filterConfig?.formatWithCommas,
            positive: filterConfig?.isPositive,
            decimalPlaces: filterConfig?.decimalPlaces,
          });
          accum[maxFieldName] = getRangeMaxYup<
            CerberusStatsFiltersFormMinFieldNames,
            void
          >({
            maxValue: filterConfig?.maxValue,
            shouldFormatNumber: filterConfig?.formatWithCommas,
            positive: filterConfig?.isPositive,
            decimalPlaces: filterConfig?.decimalPlaces,
            minFieldName: minFieldName,
          });
        }
        return accum;
      }, {}),
    );
  }, [filterableFields]);

  const defaultFormValues = useMemo(() => {
    if (!filterableFields || filterSetQueryIsLoading) {
      return null;
    } else {
      if (!fieldFilters) {
        // fieldFilters have loaded but are undefined, default values are a null for each field
        return filterableFields.reduce<
          DefaultValues<CerberusStatsFiltersFormData>
        >((accum, field) => {
          if (field === CerberusStatsFields.marketGrade) {
            accum[getMarketGradeName()] = Object.values(MarketGrade);
          } else {
            const minFieldName = getMinFieldName(field);
            const maxFieldName = getMaxFieldName(field);
            accum[minFieldName] = null;
            accum[maxFieldName] = null;
          }

          return accum;
        }, {});
      } else {
        return filterableFields.reduce<
          DefaultValues<CerberusStatsFiltersFormData>
        >((accum, field) => {
          if (field === CerberusStatsFields.marketGrade) {
            accum[getMarketGradeName()] =
              fieldFilters[field] || Object.values(MarketGrade);
          } else {
            const minFieldName = getMinFieldName(field);
            const maxFieldName = getMaxFieldName(field);
            const filterConfig =
              CERBERUS_STATS_FIELD_CONFIGS[field].filterConfig;

            // need to convert the percent to whole number values here
            const minValue = getMinFromRangeValue(fieldFilters[field]);
            const maxValue = getMaxFromRangeValue(fieldFilters[field]);
            accum[minFieldName] = filterConfig?.isPercent
              ? decimalToPercent(minValue, filterConfig.decimalPlaces)
              : minValue;
            accum[maxFieldName] = filterConfig?.isPercent
              ? decimalToPercent(maxValue, filterConfig.decimalPlaces)
              : maxValue;
          }
          return accum;
        }, {});
      }
    }
  }, [filterableFields, fieldFilters, filterSetQueryIsLoading]);

  return {
    state: {
      filterableFields,
      validationSchema,
      defaultFormValues,
    },
  };
};
