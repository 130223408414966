import { generatePath } from 'react-router';

import { APP_CONFIG_PORTFOLIO, VIEW_PATHS_PORTFOLIO } from '@hcs/hc-products';

export const generatePortfolioPath = (portfolioId: string) =>
  `${APP_CONFIG_PORTFOLIO.rootPath}/${generatePath(
    VIEW_PATHS_PORTFOLIO.PORTFOLIO,
    {
      portfolioId,
    },
  )}`;
