import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AuthnApi } from '../api';

const QUERY_KEY_CHECK_RESET_PASSWORD_TOKEN = 'check-reset-password-token';

export const useCheckResetPasswordToken = (tokenOrNonce: {
  token?: string;
  nonce?: string;
}) => {
  return useQuery<null, AxiosError<unknown>>(
    [QUERY_KEY_CHECK_RESET_PASSWORD_TOKEN, tokenOrNonce],
    async () => {
      if (tokenOrNonce.token || tokenOrNonce.nonce) {
        await AuthnApi.checkResetPasswordToken(tokenOrNonce);
        return null;
      } else {
        // Should never get here because of enabled option below
        throw new Error(
          '[Check Reset Password Nonce] No Token or Nonce to Check',
        );
      }
    },
    {
      enabled: !!tokenOrNonce.token || !!tokenOrNonce.nonce,
    },
  );
};
