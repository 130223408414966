import { AxiosAccessTokenClientBearer, AxiosClient } from '@hcs/http-clients';
import { fetchAccount } from '@hcs/http-clients';
import { Account, ConfirmUserRequest } from '@hcs/types';
import { ACCOUNT_URL_EXTERNAL } from '@hcs/urls';

export const UserApi = {
  confirmUser: async (requestPayload: ConfirmUserRequest) => {
    const response = await AxiosClient.post<Account>(
      `${ACCOUNT_URL_EXTERNAL}/ss/confirm-user`,
      requestPayload,
      { withCredentials: true },
    );
    return fetchAccount(response.data);
  },
  resendConfirmation: async (userId: number) => {
    return await AxiosAccessTokenClientBearer.post<void>(
      `${ACCOUNT_URL_EXTERNAL}/users/${userId}/resend-confirmation`,
    );
  },
};
