import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { NewOrderApi } from '../api/newOrderApi';

interface Params {
  orderId: number;
  fileName: string;
}

export const useReportSummaryCsv = () => {
  return useMutation<void, AxiosError, Params>(
    async ({ orderId, fileName }) => {
      await NewOrderApi.downloadReportSummaryCsv(orderId, fileName);
    },
  );
};
