import React, { useState } from 'react';
import classNames from 'classnames';

import { CloseButton } from '@hcs/design-system';
import { IconButton } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { CardTitle } from '@hcs/design-system';
import { FlexScrollCard } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { EditIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { ReportFeatures, ReportFeatureTypes, ReportId } from '@hcs/types';
import { ReportConfigSliceState } from '@hcs/types';
import { formatSnakeCase } from '@hcs/utils';

import { REPORT_FEATURE_CONFIGS } from '../../constants';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useReportConfigSlice } from '../../hooks/useReportConfigSlice';
import { ConfigurableReportDropdown } from '../ConfigurableReportDropdown';

import styles from './ReportConfigEditor.module.css';
import devtoolStyles from './ReportDevTools.module.css';

const dataHcName = 'report-config-editor';
interface Props {
  reportId: ReportId;
  onClose: VoidFunction;
  className?: string;
}
const REPORT_FEATURE_TYPE_ORDER = [
  ReportFeatureTypes.SubjectProperty,
  ReportFeatureTypes.PropertyValue,
  ReportFeatureTypes.Comps,
  ReportFeatureTypes.PropertyValueRental,
  ReportFeatureTypes.RentalComps,
  ReportFeatureTypes.Market,
  ReportFeatureTypes.Data,
  ReportFeatureTypes.Inspection,
  ReportFeatureTypes.Sharing,
  ReportFeatureTypes.Output,
];

export const ReportConfigEditor = ({ reportId, onClose, className }: Props) => {
  const {
    actions: { setReportFeatureSupport, setReportFeatureSupportPartial },
  } = useReportConfigSlice();
  const [expandedFeatureTypes, setExpandedFeatureTypes] = useState<
    ReportFeatureTypes[]
  >([]);
  const { data: reportConfig } = useReportConfig(reportId);
  const allReportFeatures = Object.keys(
    reportConfig?.reportFeaturesSupport || {},
  ) as ReportFeatures[];
  return (
    <FlexScrollCard
      dataHcName={dataHcName}
      header={{
        height: 30,
        content: (
          <CardTitle dataHcName={`${dataHcName}-title`}>
            Report Config Editor
          </CardTitle>
        ),
      }}
      className={classNames(styles.ReportConfigEditor, styles.fixed, className)}
      smallPadding
    >
      <CloseButton
        dataHcName={`${dataHcName}-close-button`}
        className={styles.CloseButton}
        onClick={onClose}
      />
      <ConfigurableReportDropdown
        onSelect={(reportConfigApi) => {
          const results: ReportConfigSliceState = {};
          reportConfigApi.reportFeatures.forEach((f) => {
            results[f] = true;
          });
          allReportFeatures.forEach((f) => {
            if (!(f in results)) {
              results[f] = false;
            }
          });
          setReportFeatureSupport(results);
        }}
      />
      {REPORT_FEATURE_TYPE_ORDER.map((reportFeatureType) => {
        const reportFeaturesForType =
          reportConfig?.reportFeaturesByType[reportFeatureType];
        const isExpanded = expandedFeatureTypes.includes(reportFeatureType);
        if (!reportFeaturesForType?.length) {
          return null;
        }
        let numFeaturesEnabledForType = 0;
        reportFeaturesForType.forEach((reportFeature) => {
          if (
            reportFeature &&
            reportConfig?.reportFeaturesSupport[reportFeature]
          ) {
            numFeaturesEnabledForType++;
          }
        });
        const isFeatureTypeChecked =
          numFeaturesEnabledForType === reportFeaturesForType.length;
        return (
          <>
            <div
              onClick={() => {
                if (isExpanded) {
                  setExpandedFeatureTypes(
                    expandedFeatureTypes.filter((v) => v !== reportFeatureType),
                  );
                } else {
                  setExpandedFeatureTypes([
                    ...expandedFeatureTypes,
                    reportFeatureType,
                  ]);
                }
              }}
              className={styles.FeatureTypeLabel}
            >
              {`${formatSnakeCase(
                reportFeatureType,
              )} (${numFeaturesEnabledForType})`}
              <div className={styles.FeatureTypeControls}>
                <DirectionalChevron
                  dataHcName={`${dataHcName}-chevron`}
                  direction={isExpanded ? 'up' : 'down'}
                  size="sm"
                />
                <Checkbox
                  stopClickPropagation
                  dataHcName={`${dataHcName}-feature-group-select`}
                  onChange={(v) => {
                    const results: ReportConfigSliceState = {};
                    reportFeaturesForType.forEach((f) => {
                      results[f] = v;
                    });
                    setReportFeatureSupportPartial(results);
                  }}
                  checked={isFeatureTypeChecked}
                />
              </div>
            </div>
            {isExpanded ? (
              <div>
                {reportFeaturesForType.map((reportFeature) => {
                  return (
                    <div
                      key={reportFeature}
                      className={styles.ReportFeatureOption}
                    >
                      <Checkbox
                        label={REPORT_FEATURE_CONFIGS[reportFeature].name}
                        checked={
                          !!reportConfig?.reportFeaturesSupport[reportFeature]
                        }
                        dataHcName={`${dataHcName}-checkbox-${reportFeature}`}
                        onChange={(v) => {
                          setReportFeatureSupportPartial({
                            [reportFeature]: v,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </>
        );
      })}
    </FlexScrollCard>
  );
};
export const ReportConfigEditorDevtool = (props: Omit<Props, 'onClose'>) => {
  const { active, handleOpen, handleClose } = useActiveState();
  return (
    <li
      data-hc-name={`${dataHcName}-launcher`}
      className={classNames(devtoolStyles.Item, devtoolStyles.clickable)}
      onClick={handleOpen}
    >
      {active && <ReportConfigEditor {...props} onClose={handleClose} />}
      Report Config Editor
    </li>
  );
};

export const ReportConfigEditorLauncher = (props: Omit<Props, 'onClose'>) => {
  const { active, handleToggle, handleClose } = useActiveState();
  return (
    <>
      <IconButton
        dataHcName={`${dataHcName}-launcher`}
        onClick={handleToggle}
        icon={<EditIcon dataHcName={`${dataHcName}-launcher-icon`} />}
        label="Edit Config"
      />
      {active && <ReportConfigEditor {...props} onClose={handleClose} />}
    </>
  );
};
