import { useMemo } from 'react';

import { getMapTileImage } from '@hcs/maps';
import { useStreetView } from '@hcs/street-view';
import { ListingImageFragment } from '@hcs/types';
import { PropertyStateImagesResult } from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useComp } from '../hooks';
import { reportFeaturesSupportedAny } from '../utils';

import { useCompPhotosDocument } from './useCompPhotosDocument';
import { useReportConfig } from './useReportConfig';

export const useCompPhotos = (
  reportId: ReportId,
  compIdentifier: CompIdentifier,
) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const streetViewSupported = reportFeaturesSupportedAny(reportConfig, [
    ReportFeatures.CompsStreetview,
  ]);

  const compQuery = useComp(reportId, compIdentifier);
  const compPhotosQuery = useCompPhotosDocument(reportId, compIdentifier);
  const streetViewQuery = useStreetView(
    streetViewSupported &&
      compQuery.data?.compSchema.propertyState.location?.latitude &&
      compQuery.data?.compSchema.propertyState.location.longitude
      ? compQuery.data?.compSchema.propertyState.location
      : undefined,
    {
      size: 'lg',
      publicLinkUid: typeof reportId === 'string' ? reportId : undefined,
    },
  );
  const combinedQuery = combineUseQueryResult([
    compQuery,
    streetViewQuery,
    compPhotosQuery,
  ]);
  const data = useMemo(() => {
    let allPhotos: ListingImageFragment[] = [];
    compPhotosQuery.data?.data?.listingPhotos?.forEach((l) => {
      if (l.media?.images?.length) {
        allPhotos = [...allPhotos, ...l.media.images];
      }
    });
    const frontPhoto = allPhotos?.[0];
    const streetView =
      streetViewSupported && streetViewQuery.data
        ? {
            url: streetViewQuery.data.url,
          }
        : undefined;
    if (streetView) {
      allPhotos.push(streetView);
    }
    const previewPhoto = frontPhoto || streetView;
    const mapTile = getMapTileImage(compQuery.data?.compSchema.propertyState);
    const result: PropertyStateImagesResult | undefined =
      combinedQuery.isInitialLoading
        ? undefined
        : {
            previewPhoto,
            frontPhoto,
            streetView,
            mapTile: mapTile
              ? {
                  url: mapTile,
                }
              : undefined,
            listingPreviewPhoto: allPhotos?.[1],
            listingPhotos: compPhotosQuery.data?.data.listingPhotos,
            totalPhotos:
              (allPhotos?.length || 0) + (streetViewQuery.data ? 1 : 0),
            allPhotos,
          };
    return result;
  }, [
    combinedQuery.isInitialLoading,
    compPhotosQuery.data,
    streetViewQuery.data,
  ]);
  return {
    ...combinedQuery,
    data,
  };
};
