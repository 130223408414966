import { AvmBreakdownDocument, DocumentRoles, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useAvmBreakdownDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<AvmBreakdownDocument>(
    reportId,
    DocumentRoles.AvmBreakdown,
  );
};
