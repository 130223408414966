import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';
import { formatPropertyType } from '@hcs/utils';

import {
  DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL,
  ORDER_MANAGER_PROPERTY_TYPE_TO_CERBERUS,
} from '../../constants';

import { DisplayProps } from '.';

const Display = ({ orderItem }: DisplayProps): JSX.Element | null => {
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-property-type`}>
      {formatPropertyType(
        orderItem?.propertyType
          ? ORDER_MANAGER_PROPERTY_TYPE_TO_CERBERUS[orderItem.propertyType]
          : undefined,
      )}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return (
    <TableHeaderCell {...tableHeaderCellProps}>Property Type</TableHeaderCell>
  );
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ITEM_PROPERTY_TYPE_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Property Type',
  sortable: true,
};
