import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { useActiveState } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import { Anchor } from '../../../display/links/Anchor';

import styles from './MainNav.module.css';

export interface NavItemConfig {
  navigateTo: string;
  Icon: React.ComponentType<IconProps>;
  label: string;
  dataHcName: string;
  dataHcEventSection?: string;
  isExternalLink?: boolean;
  end?: boolean;
}

export interface MainNavProps {
  dataHcName: string;
  className?: string;
  navItemsConfig: NavItemConfig[];
  /** use internal state to open on hover and collapse on blur */
  hoverToOpen?: boolean;
}

const NavItem = ({
  label,
  Icon,
  dataHcName,
  dataHcEventSection,
}: Omit<NavItemConfig, 'navigateTo' | 'isExternalLink'>) => (
  <div
    data-hc-name={`${dataHcName}-nav-item`}
    data-hc-event-section={dataHcEventSection}
    className={styles.NavItem}
    title={label}
  >
    <div className={styles.IconContainer}>
      <Icon dataHcName={`${dataHcName}-icon`} />
    </div>
    <div data-hc-name={`${dataHcName}-label`} className={styles.LabelContainer}>
      {label}
    </div>
  </div>
);

export const MainNav = ({
  dataHcName,
  className,
  navItemsConfig,
  hoverToOpen = false,
}: MainNavProps) => {
  const { active: isMouseOver, handleOpen, handleClose } = useActiveState();

  return (
    <>
      {/** WidthMaintainer div maintains width of skinny nav in the DOM for absolute positioning */}
      {hoverToOpen && <div className={styles.WidthMaintainer}></div>}
      <div
        aria-expanded={hoverToOpen ? isMouseOver : true}
        data-hc-name={dataHcName}
        className={classNames(styles.MainNav, className, {
          [styles.collapse]: hoverToOpen && !isMouseOver,
          [styles.absolutePosition]: hoverToOpen,
        })}
        onMouseOver={handleOpen}
        onMouseOut={handleClose}
      >
        {navItemsConfig.map(
          ({
            label,
            Icon,
            dataHcEventSection,
            dataHcName,
            navigateTo,
            isExternalLink,
            end,
          }) => {
            const navItem = (
              <NavItem
                label={label}
                Icon={Icon}
                dataHcName={dataHcName}
                dataHcEventSection={dataHcEventSection}
              />
            );
            return isExternalLink ? (
              <Anchor
                key={dataHcName}
                dataHcName={dataHcName}
                href={navigateTo}
                target="_blank"
              >
                {navItem}
              </Anchor>
            ) : (
              <NavLink
                key={dataHcName}
                to={navigateTo}
                end={end}
                className={({ isActive }) =>
                  classNames({
                    [styles.active]: isActive,
                  })
                }
              >
                {navItem}
              </NavLink>
            );
          },
        )}
      </div>
    </>
  );
};
