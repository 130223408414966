import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { useScreenSize } from '@hcs/hooks';

import { HEADER_HEIGHT_LG, HEADER_HEIGHT_PHONE } from '../Header';

import styles from './LayoutGlobalHeader.module.css';

export interface LayoutGlobalHeaderProps {
  type: 'outlet' | 'children';
  children?: ReactNode;
  header?: ReactNode;
}

export const LayoutGlobalHeader = ({
  type,
  header,
  children,
}: LayoutGlobalHeaderProps) => {
  const { isPhoneWidth } = useScreenSize();
  return (
    <>
      {header || null}
      <div
        className={styles.Layout}
        style={{
          paddingTop: isPhoneWidth
            ? `${HEADER_HEIGHT_PHONE}px`
            : `${HEADER_HEIGHT_LG}px`,
        }}
      >
        {type === 'outlet' && <Outlet />}
        {children}
      </div>
    </>
  );
};

export const LayoutGlobalHeaderOutlet = (
  props: Omit<LayoutGlobalHeaderProps, 'type'>,
) => {
  return <LayoutGlobalHeader type="outlet" {...props} />;
};

export const LayoutGlobalHeaderChildren = (
  props: Omit<LayoutGlobalHeaderProps, 'type'> & { children: ReactNode },
) => {
  return <LayoutGlobalHeader type="children" {...props} />;
};
