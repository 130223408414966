import React from 'react';
import classNames from 'classnames';

import { OverlayPage } from '@hcs/design-system';
import { PageTitle } from '@hcs/design-system';
import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import {
  CompSavedFilterSetCard,
  dataHcEventSectionCompsSavedFilters,
  dataHcEventSectionRentalSavedFilters,
} from '../CompSavedFilterSetCard';

import styles from './CompSavedFiltersOverlayPage.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  active: boolean;
  className?: string;
  onClose: VoidFunction;
}
const dataHcName = 'comp-saved-filters-overlay-page';
export const CompSavedFiltersOverlayPage = ({
  reportId,
  compType,
  active,
  className,
  onClose,
}: Props) => {
  const { data: filterSets } = useSavedCompFilterSetsForUser(compType);
  const dataHcEventSection =
    compType === CompTypes.Rental
      ? dataHcEventSectionRentalSavedFilters
      : dataHcEventSectionCompsSavedFilters;
  return (
    <OverlayPage
      active={active}
      headerProps={{
        onBack: onClose,
      }}
      dataHcName={dataHcName}
    >
      <div
        className={classNames(styles.CompSavedFiltersOverlayPage, className)}
        data-hc-event-section={dataHcEventSection}
      >
        <PageTitle dataHcName={`${dataHcName}-title`}>
          My Saved Filters
        </PageTitle>
        <div data-hc-name={`${dataHcName}-subtitle`}>
          Access and select previously saved filter criteria.
        </div>
        <div className={styles.Divider} />
        {Object.entries(filterSets || {}).map(([filterSetId, filterSet]) => {
          return (
            <CompSavedFilterSetCard
              reportId={reportId}
              compType={compType}
              className={styles.CompSavedFilterSetCard}
              key={filterSetId}
              filterSetId={filterSetId}
              filterSet={filterSet}
              onClose={onClose}
            />
          );
        })}
      </div>
    </OverlayPage>
  );
};
