import { useMemo } from 'react';

import { AppConfig } from '@hcs/types';

import { APP_CONFIG_FALLBACK } from '../constants';

import { useCurrentUrlRootPath } from './useCurrentUrlRootPath';

export const useCurrentAppConfig = (): AppConfig => {
  const currentRootPath = useCurrentUrlRootPath();
  return (
    useMemo(
      () =>
        window.BUNDLE_APP_CONFIGS?.find((c) => c.rootPath === currentRootPath),
      [currentRootPath],
    ) || APP_CONFIG_FALLBACK
  );
};
