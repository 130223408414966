import { useSelector } from 'react-redux';

import { useMapDispatchToSliceActions } from '@hcs/hooks';
import {
  EngagementTrackingEventDataSliceState,
  EngagementTrackingPartialAppState,
} from '@hcs/types';

import { ENGAGEMENT_TRACKING_REDUCER_KEY } from '../rtk/engagementTracking.reducer';
import { engagementTrackingEventDataSlice } from '../rtk/eventData.slice';

export const useEngagementTrackingEventDataSlice = () => {
  const actions = useMapDispatchToSliceActions(
    engagementTrackingEventDataSlice,
  );
  return {
    state: useSelector<
      EngagementTrackingPartialAppState,
      EngagementTrackingEventDataSliceState
    >(
      (state) =>
        state[ENGAGEMENT_TRACKING_REDUCER_KEY][
          engagementTrackingEventDataSlice.name
        ],
    ),
    actions,
  };
};
