import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { Account, AuthapiLoginRequestExt } from '@hcs/types';

import { AuthnApi } from '../api';

// Export hook-specific types here rather than in types file
export type UseLoginOptions = UseMutationOptions<
  Account,
  unknown,
  AuthapiLoginRequestExt,
  void
>;

export const useLogin = (options?: UseLoginOptions) => {
  const queryClient = useQueryClient();
  return useMutation<Account, unknown, AuthapiLoginRequestExt, void>(
    AuthnApi.login,
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
      },
      onSuccess(account, variables) {
        queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
        options?.onSuccess?.(account, variables);
      },
    },
  );
};
