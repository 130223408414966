import { MarketGrade } from '@hcs/types';
import { CerberusStatsFields } from '@hcs/types';
import {
  CerberusStatsFieldFilters,
  CerberusStatsFilterField,
  CerberusStatsFiltersFormData,
  CerberusStatsFiltersFormMaxFieldNames,
  CerberusStatsFiltersFormMinFieldNames,
  CerberusStatsFiltersMarketGradeFormField,
  CerberusStatsFiltersRangeFormField,
} from '@hcs/types';
import { getRangeFilter } from '@hcs/utils';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../configs';
import { CERBERUS_STATS_NON_FILTER_FIELDS } from '../constants';

export const isCerberusStatsFilterField = (
  field: CerberusStatsFields,
): field is CerberusStatsFilterField => {
  return CERBERUS_STATS_NON_FILTER_FIELDS.indexOf(field) === -1;
};

// in react-hook-form, dots in a field name have special meaning and cause problems
const fieldNameDotToUnderscore = (
  field: CerberusStatsFilterField,
): CerberusStatsFiltersRangeFormField => {
  return field.replace('.', '_') as CerberusStatsFiltersRangeFormField;
};

export const getMinFieldName = (
  filterField: CerberusStatsFilterField,
): CerberusStatsFiltersFormMinFieldNames => {
  return `${fieldNameDotToUnderscore(filterField)}Min`;
};

export const getMaxFieldName = (
  filterField: CerberusStatsFilterField,
): CerberusStatsFiltersFormMaxFieldNames => {
  return `${fieldNameDotToUnderscore(filterField)}Max`;
};

export const getMarketGradeName =
  (): CerberusStatsFiltersMarketGradeFormField => {
    return CerberusStatsFields.marketGrade.replace(
      '.',
      '_',
    ) as CerberusStatsFiltersMarketGradeFormField;
  };

const percentToDecimal = (num: number | null): number | null => {
  if (num !== null) {
    return num / 100;
  }
  return null;
};

export const getNewFieldFiltersFromForm = (
  filterableFields: CerberusStatsFilterField[],
  formData: CerberusStatsFiltersFormData,
): CerberusStatsFieldFilters => {
  return filterableFields.reduce<CerberusStatsFieldFilters>((accum, field) => {
    if (field === CerberusStatsFields.marketGrade) {
      const marketGradeFilter = formData[getMarketGradeName()];
      // we don't bother saving if all market grade options are selected or unselected
      if (
        marketGradeFilter &&
        marketGradeFilter.length !== Object.values(MarketGrade).length &&
        marketGradeFilter.length !== 0
      ) {
        accum[field] = marketGradeFilter;
      }
    } else {
      const minFieldName = getMinFieldName(field);
      const maxFieldName = getMaxFieldName(field);
      const minFormValue = formData[minFieldName];
      const maxFormValue = formData[maxFieldName];
      if (minFormValue !== undefined && maxFormValue !== undefined) {
        const filterConfig = CERBERUS_STATS_FIELD_CONFIGS[field].filterConfig;
        const rangeFilter = getRangeFilter(
          filterConfig?.isPercent
            ? percentToDecimal(minFormValue)
            : minFormValue,
          filterConfig?.isPercent
            ? percentToDecimal(maxFormValue)
            : maxFormValue,
        );
        if (rangeFilter !== null) {
          accum[field] = rangeFilter.rng;
        }
      }
    }
    return accum;
  }, {});
};
