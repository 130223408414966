import React, { JSXElementConstructor, ReactNode } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import { Button } from '../../../controls-and-inputs';
import { Card } from '../../cards';
import { Anchor } from '../../links';

import styles from './ProductCard.module.css';

interface ProductCardTheme {
  ProductCard: string;
  DefaultCell?: string;
}

export interface ProductCardProps {
  dataHcName: string;
  className?: string;
  theme?: Partial<ProductCardTheme>;
  productName: ReactNode;
  subheading?: string;
  productDescription: string | string[];
  Icon?: JSXElementConstructor<IconProps>;
  large?: boolean;
  largeContent?: ReactNode;
  hasAccess: boolean;
  onClick: VoidFunction;
  isNewProduct?: boolean;
}
export const ProductCard = (props: ProductCardProps) => {
  const {
    dataHcName,
    className,
    theme,
    productName,
    subheading,
    productDescription,
    Icon,
    large,
    largeContent,
    hasAccess,
    onClick,
    isNewProduct,
  } = props;
  return (
    <Card
      dataHcName={dataHcName}
      className={classNames(styles.ProductCard, theme?.ProductCard, className, {
        [styles.large]: large,
        [styles.noAccess]: !hasAccess,
      })}
      onClick={onClick}
      smallPadding
    >
      <div className={classNames(styles.DefaultCell, theme?.DefaultCell)}>
        <div className={styles.Icon}>
          {Icon && (
            <Icon
              size="xl"
              color="neutral-dark-20"
              dataHcName={`${dataHcName}-icon`}
            />
          )}
        </div>
        {isNewProduct && (
          <div data-hc-name={`${dataHcName}-new-product`}>New Product</div>
        )}
        <div
          data-hc-name={`${dataHcName}-product-name`}
          className={styles.ProductName}
        >
          {productName}
        </div>
        {subheading && (
          <div
            data-hc-name={`${dataHcName}-product-subheading`}
            className={styles.Subheading}
          >
            {subheading}
          </div>
        )}
        <div
          className={styles.ProductDescription}
          data-hc-name={`${dataHcName}-product-description`}
        >
          {productDescription}
        </div>
        {hasAccess ? (
          <Button dataHcName={`${dataHcName}-launch`} className={styles.Cta}>
            Launch
          </Button>
        ) : (
          <Anchor
            dataHcName={`${dataHcName}-get-access`}
            className={styles.Cta}
          >
            Get Access
          </Anchor>
        )}
      </div>
      {large && largeContent && (
        <div
          className={styles.LargeCell}
          data-hc-name={`${dataHcName}-large-content`}
        >
          {largeContent}
        </div>
      )}
    </Card>
  );
};
