import { ExperienceFlagIds, ExperienceFlagsConfig } from '@hcs/types';

export const EXPERIENCE_FLAG_CONFIGS: {
  [key in ExperienceFlagIds]: ExperienceFlagsConfig;
} = {
  DEXP_TEMPLATES: {
    experienceFlagId: 'DEXP_TEMPLATES',
    description: 'DEXP Analysis Templates and Comp Analysis',
    jiraTickets: ['DP-205', 'DP-206'],
    envs: {
      dev: true,
      stage: false,
      prod: false,
    },
  },
  PEXP_REPORT_API: {
    experienceFlagId: 'PEXP_REPORT_API',
    displayName: 'PEXP 1.5 - Report-Api',
    description: 'PEXP Reports Use Report API. Users see PEXP 1.5 by default',
    jiraTickets: ['EXP-275'],
    figmaLinks: {
      inspect:
        'https://www.figma.com/file/pdQZ14kLbLD686nsMIKJgd/2021-PEXP---Tier-3?node-id=3733%3A137446&t=5NkEOxLD81yRaLXP-4',
    },
    envs: {
      dev: true,
      stage: false,
      prod: false,
    },
  },
  PEXP_REDESIGN: {
    experienceFlagId: 'PEXP_REDESIGN',
    displayName: 'PEXP 2.0',
    description: 'PEXP users see the redesigned PEXP 2.0 experience.',
    jiraTickets: ['EXP-324'],
    figmaLinks: {
      prototype:
        'https://www.figma.com/proto/pdQZ14kLbLD686nsMIKJgd/2021-PEXP---Tier-3?node-id=811%3A35788&scaling=min-zoom&page-id=811%3A35788&starting-point-node-id=3614%3A141421&show-proto-sidebar=1',
      inspect:
        'https://www.figma.com/file/pdQZ14kLbLD686nsMIKJgd/2021-PEXP---Tier-3?node-id=811%3A35788&t=xQvQnKN2YfFtWLBe-0',
    },
    envs: {
      dev: true,
      stage: true,
      prod: true,
    },
  },
  REPORT_API_WEBHOOK: {
    experienceFlagId: 'REPORT_API_WEBHOOK',
    description:
      'Receive updates via webhook when reports are created and updated.',
    jiraTickets: ['SPLAT-466'],
    envs: {
      dev: false,
      stage: false,
      prod: false,
    },
  },
};
