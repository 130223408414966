import {
  DocumentRoles,
  MonthsOfSupplyChartDocument,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from './useSingleDocumentRole';

export const useMonthsOfSupplyChartDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<MonthsOfSupplyChartDocument>(
    reportId,
    DocumentRoles.MonthsOfSupplyChart,
  );
};
