import {
  fetchEventSource,
  FetchEventSourceInit,
} from '@microsoft/fetch-event-source';
import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientEhrm, getHcAuthHeader } from '@hcs/http-clients';
import {
  PortfolioFields,
  PortfolioPayload,
  PortfolioPayloadWithId,
  PortfolioResponse,
  PortfolioTableColumns,
  ReportId,
} from '@hcs/types';
import {
  AddPortfolioAssetsAddressError,
  AddPortfolioAssetsPayload,
  AddPortfolioAssetsResponse,
  DeletePortfolioAssetsPayload,
  FetchPortfolioAssetsPayload,
  ImportPortfolioAssetsCreateError,
  ImportPortfolioAssetsPayload,
  PortfolioAssets,
} from '@hcs/types';
import { PORTFOLIO_API_URL } from '@hcs/urls';
import { camelCaseToSnakeCase } from '@hcs/utils';

const getPortfolioApiUrl = (portfolioId: string) => {
  return `${PORTFOLIO_API_URL}/portfolios/${portfolioId}`;
};

const getAPIColumnName = (columnName: PortfolioTableColumns | 'address') => {
  if (columnName === 'lastUpdate') {
    return 'update_timestamp';
  }
  return camelCaseToSnakeCase(columnName);
};

export const PortfolioApi = {
  fetchPortfolios: async (): Promise<PortfolioResponse[]> => {
    const response = await AxiosAccessTokenClientEhrm.get<PortfolioResponse[]>(
      `${PORTFOLIO_API_URL}/portfolios`,
    );

    return camelcaseKeys(response.data, { deep: true });
  },

  createPortfolio: async (portfolioSetupPayload: PortfolioPayload) => {
    try {
      const formattedPortfolioSetupPayload = snakecaseKeys(
        portfolioSetupPayload,
      );
      const response = await AxiosAccessTokenClientEhrm.post<PortfolioResponse>(
        `${PORTFOLIO_API_URL}/portfolios`,
        formattedPortfolioSetupPayload,
      );
      return camelcaseKeys(response.data, { deep: true });
    } catch (error) {
      throw new Error(`No response from portfolios creation ${error && error}`);
    }
  },
  createPortfolioApiEventSource: async ({
    id,
    config,
  }: {
    id: string | ReportId;
    config: FetchEventSourceInit;
  }) => {
    const baseHeaders = {
      'Content-Type': 'application/json',
    };
    const authHeaders = getHcAuthHeader();
    return fetchEventSource(`${getPortfolioApiUrl(`${id}`)}/events`, {
      headers: {
        ...baseHeaders,
        ...authHeaders,
      },
      ...config,
    });
  },
  fetchPortfolioAssets: async ({
    portfolioId,
    filters,
    cursor,
    order,
  }: FetchPortfolioAssetsPayload) => {
    const { orderBy, orderDirection } = order;
    const orderDir = orderDirection?.toLowerCase();
    const sort = orderBy
      ? orderDir
        ? `${getAPIColumnName(orderBy)}.${orderDir}`
        : `${getAPIColumnName(orderBy)}`
      : undefined;
    const response = await AxiosAccessTokenClientEhrm.get<PortfolioAssets>(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/assets`,
      {
        params: {
          ...filters,
          sort,
          cursor,
        },
      },
    );

    return camelcaseKeys(response.data, { deep: true });
  },

  importPortfolioAssetsJob: async ({
    portfolioId,
    formData,
  }: ImportPortfolioAssetsPayload) => {
    try {
      const response =
        // if success, the import endpoint returns code 204 with no Content
        await AxiosAccessTokenClientEhrm.post(
          `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/assets/import`,
          formData,
        );
      return camelcaseKeys(response.data, { deep: true });
    } catch (err: unknown) {
      let message = '';
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<ImportPortfolioAssetsCreateError>;
        const data = error.response?.data;
        if (typeof data === 'string') {
          message = data;
        } else if (data?.status) {
          if (typeof data?.status === 'string') {
            message = data.status;
          } else {
            message = data.status.join('\n');
          }
        }
      }
      throw new Error(message);
    }
  },

  downloadPortfolioAssets: async ({ portfolioId }: { portfolioId: string }) => {
    const response = await AxiosAccessTokenClientEhrm.get(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/download`,
    );
    if (response.status !== 200) {
      throw new Error('Failed to download portfolio csv file');
    }
    return true;
  },

  addPortfolioAssets: async ({
    portfolioId,
    addressesPayload,
  }: AddPortfolioAssetsPayload) => {
    try {
      const snakecaseAddressPayload = snakecaseKeys(addressesPayload, {
        deep: true,
      });

      const response =
        await AxiosAccessTokenClientEhrm.post<AddPortfolioAssetsResponse>(
          `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/assets`,
          snakecaseAddressPayload,
        );

      return camelcaseKeys(response.data, { deep: true });
    } catch (err: unknown) {
      let message = '';
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<AddPortfolioAssetsAddressError>;
        const data = error.response?.data;
        if (typeof data === 'string') {
          message = data;
        } else if (data?.status) {
          if (typeof data?.status === 'string') {
            message = data.status;
          } else {
            message = data.status.join('\n');
          }
        }
      }
      throw new Error(message);
    }
  },

  deletePortfolioAssets: async (
    deletePortfolioAssetsPayload: DeletePortfolioAssetsPayload,
  ) => {
    const { portfolioId, assetIdsArr } = deletePortfolioAssetsPayload;
    await AxiosAccessTokenClientEhrm.delete(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/assets`,
      { data: assetIdsArr },
    );
  },

  downloadUpdatedAssetsFile: async ({
    portfolioId,
    s3Identifier,
  }: {
    portfolioId: string;
    s3Identifier: string;
  }) => {
    await AxiosAccessTokenClientEhrm.get(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/assets/updates/${s3Identifier}`,
      {
        responseType: 'blob',
      },
    ).then(async (res) => {
      const responseDataInString = await res.data.text();
      const jsonResponseData = JSON.parse(responseDataInString);
      fileDownload(jsonResponseData.file, jsonResponseData.name);
    });
  },

  fetchPortfolio: async (
    portfolioId: PortfolioPayloadWithId[PortfolioFields.PortfolioId],
  ): Promise<PortfolioResponse> => {
    const response = await AxiosAccessTokenClientEhrm.get<PortfolioResponse>(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}`,
    );
    return camelcaseKeys(response.data, { deep: true });
  },

  updatePortfolio: async ({
    portfolioId,
    portfolioPayload,
  }: PortfolioPayloadWithId) => {
    const response = await AxiosAccessTokenClientEhrm.patch<PortfolioResponse>(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}`,
      snakecaseKeys(portfolioPayload),
    );
    return camelcaseKeys(response.data, { deep: true });
  },

  downloadPortfolioErrorsFile: async ({
    portfolioId,
  }: {
    portfolioId: string;
  }) => {
    await AxiosAccessTokenClientEhrm.get(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}/errors`,
      {
        responseType: 'blob',
      },
    ).then(async (res) => {
      const responseDataInString = await res.data.text();
      const jsonResponseData = JSON.parse(responseDataInString);
      fileDownload(jsonResponseData.file, jsonResponseData.name);
    });
  },

  deletePortfolio: async (
    portfolioId: PortfolioPayloadWithId[PortfolioFields.PortfolioId],
  ) => {
    await AxiosAccessTokenClientEhrm.delete(
      `${PORTFOLIO_API_URL}/portfolios/${portfolioId}`,
    );
  },
};
