import {
  CompValueOptions,
  GlossaryTermConfig,
  GlossaryTerms,
} from '@hcs/types';
import { ValueExtended } from '@hcs/types';
import { formatMoney } from '@hcs/utils';

export const GLOSSARY: { [key in GlossaryTerms]: GlossaryTermConfig } = {
  [GlossaryTerms.Active]: {
    type: 'static',
    title: 'Active',
    content: [
      'Active listings within a 1 year timeframe near the subject property.',
    ],
    source: 'Local MLS',
  },
  [GlossaryTerms.AdjustedValue]: {
    type: 'static',
    title: 'Adjusted Value',
    content: [
      'The Adjusted Value has been adjusted to reflect changes in property attributes, such as bed and bath additions and changes to the property’s condition. After the property attributes are adjusted, HouseCanary calculates a new value for the property; this is how we create the Adjusted Value for each home. Source: HouseCanary analysis.',
    ],
  },
  [GlossaryTerms.ComparableValueSelected]: {
    type: 'compValue',
    title: 'Comparable Value',
    content: (compValueOptions?: CompValueOptions | null) => {
      return [
        `The Comparable Value is an average value of all selected comparables (based on the sale or most recent list price) plus the user's price adjustments for each comparable. ${
          compValueOptions?.hpiRpiAdjustment
            ? 'A time-based adjustment to match the report effective using the home price index is applied to the Comparable Value when HPI Adjustment is selected.'
            : ''
        } ${
          compValueOptions?.hcAdjustment
            ? 'An attribute-based adjustment to match the subject property is applied to the Comparable Value when HC Adjustment is selected.'
            : ''
        }`,
      ];
    },
    source: 'HouseCanary analysis.',
  },
  [GlossaryTerms.CompProperties]: {
    type: 'static',
    title: 'Comparable Properties',
    content: [
      `All nearby properties of the same property and sales type that have been ranked according to their
      similarity to the subject property's locational and physical characteristics.`,
    ],
    source: 'Public Record, HouseCanary analysis',
  },
  [GlossaryTerms.CurrentValue]: {
    type: 'static',
    title: 'Current Value',
    content: [
      `Current Value of the similar property represents HouseCanary’s most recent value estimate of the respective property. HouseCanary’s valuation model adjusts for price changes through time with a proprietary block level home
      price appreciation index.`,
    ],
    source: 'Public Record, HouseCanary Automated Valuation Model',
  },
  [GlossaryTerms.DaysOnMarket]: {
    type: 'static',
    title: 'Days on Market',
    content: [
      `The current days on market is the average number of days since listing for all current listings on the market for
      the given geography. The calculation represents a 13-week rolling average to minimize rapid swings in the data.`,
    ],
    source: 'Local MLS, HouseCanary analysis',
  },
  [GlossaryTerms.ForecastStandardDeviation]: {
    type: 'static',
    title: 'Forecast Standard Deviation (FSD)',
    content: [
      `A statistical measure of model uncertainty in the value estimate generated by the AVM. Lower values of FSD
    imply less uncertainty in the value estimate. FSD is measured in percentage terms relative to the value
    estimate to allow for comparison of model uncertainty across multiple properties, regardless of the actual
    dollar value of those individual estimates. FSD below 0.15 implies high model confidence, FSD between
    0.15-0.3 implies average model confidence, and FSD above 0.3 implies low model confidence.`,
    ],
    source: 'House Canary analysis',
  },
  [GlossaryTerms.RentalHcValue]: {
    type: 'avm',
    title: 'HouseCanary Rental Value',
    content: (avm?: ValueExtended | null) => [
      `We value this property’s monthly rent at ${formatMoney(
        avm?.value.value,
      )}. The rental price will likely fall between ${formatMoney(
        avm?.value.valueLower,
      )} and ${formatMoney(avm?.value.valueUpper)}. This is
    HouseCanary’s estimated monthly rental value for this home. It is not a formal appraisal. This estimate is based
    on our market knowledge, and it should be used as a starting point to determine a home’s rent.`,
    ],
    source: 'HouseCanary analysis',
  },
  [GlossaryTerms.HcSuggestedComps]: {
    type: 'static',
    title: 'HouseCanary Suggested Comps',
    content: [
      `HouseCanary’s suggested comparables based on similarity and property type, within the same state and a 6-month timeframe.`,
    ],
    source: 'HouseCanary analysis',
  },
  [GlossaryTerms.HcValue]: {
    type: 'avm',
    title: 'HouseCanary Value',
    content: (avm?: ValueExtended | null) => [
      `We value this property at ${formatMoney(
        avm?.value.value,
      )}. The sales price will likely fall between ${formatMoney(
        avm?.value.valueLower,
      )} and ${formatMoney(
        avm?.value.valueUpper,
      )}. This is HouseCanary’s estimated market value for this home. It is not a formal appraisal. This estimate is based on our market
    knowledge, and it should be used as a starting point to determine a home’s value.`,
    ],
    source: 'HouseCanary analysis',
  },
  [GlossaryTerms.MonthsOfSupply]: {
    type: 'static',
    title: 'Months of Supply',
    content: [
      `The months of supply is a metric to reflect the pace at which listing inventory is turning over in the local market.
    The calculation reflects the total listings on the market divided by the 3-month rolling average of sales volume.
    Generally, less than 5 months of supply is considered inflationary due to the constrained nature of listings available for sale. A value greater than 7 months of supply is typically considered oversupplied and deflationary.`,
    ],
    source: 'Local MLS, HouseCanary analysis',
  },
  [GlossaryTerms.NearbyProperties]: {
    type: 'static',
    title: `Subject’s Comparability to Market`,
    content: [
      `All nearby properties and associated attributes. This chart allows for comparison of the subject property attributes
    with nearby properties.`,
    ],
    source: 'Public Record, HouseCanary analysis',
  },
  [GlossaryTerms.NonDisclosureState]: {
    type: 'static',
    title: 'Non-Disclosure State',
    content: [
      `In non-disclosure states (or counties) both the transaction sales price and date may be unavailable. This is
    because these states are not required or cannot legally disclose such information to the public. As a result,
    HouseCanary relies on other sources (like MLS) to complement the data when possible.
    
    The following twelve states are considered non-disclosure: Alaska, Idaho, Kansas, Louisiana,
    Mississippi, Missouri (certain counties), Montana, New Mexico, North Dakota, Texas, Utah and Wyoming.`,
    ],
    source: 'Public Record, MLS',
  },
  [GlossaryTerms.OccupancyType]: {
    type: 'static',
    title: 'Occupancy Type',
    content: [
      `Owner occupancy indicates whether the owner of the home is the primary resident`,
    ],
    source: 'Public Record',
  },
  [GlossaryTerms.PropertyType]: {
    type: 'static',
    title: 'Property Type',
    content: [
      `Property Type indicates the classification of the building based upon public record information. HouseCanary
    has normalized property type information into five groupings: Single Family Detached, Condominium, Townhouse, Manufactured/Mobile Home and Multifamily. Note that buildings that do not fall into these categories,
    i.e. apartment houses, highrise apartments, etc. will not be mapped into one of these categories.`,
    ],
    source: 'Public Record',
  },
  [GlossaryTerms.RentalComparableValueSelected]: {
    type: 'compValue',
    title: 'Rental Comparable Value',
    content: (compValueOptions?: CompValueOptions | null) => {
      return [
        `The Rental Comparable Value is an average value of all selected comparable rental listings plus the user's price adjustments for each comparable. ${
          compValueOptions?.hpiRpiAdjustment
            ? 'A time-based adjustment to match the report effective using the rental price index is applied to the Rental Comparable Value when RPI Adjustment is selected.'
            : ''
        }`,
      ];
    },
    source: 'HouseCanary analysis',
  },
  [GlossaryTerms.RiskOfDecline]: {
    type: 'static',
    title: 'MSA 1-Year Risk of Decline',
    content: [
      `The one year risk of decline is a proprietary HouseCanary metric that measures the probability that this market’s median home prices will be lower 12 months from now than the current market median price. This one-year
    chance of loss is derived through HouseCanary’s multivariate time series models using a combination of fundamental and technical indicators.`,
    ],
    source: 'Local MLS, HouseCanary analysis',
  },
  [GlossaryTerms.Similarity]: {
    type: 'static',
    title: 'Similarity',
    content: [
      `HouseCanary proprietary score calculated via multivariate analysis using a combination of geographic
      information and key property characteristics such as bedrooms, square footage, lot size, etc. The measure
      defines similarity of comparable properties relative to the subject property.`,
    ],
    source: 'Public Records, MLS, HouseCanary analysis',
  },
  [GlossaryTerms.UserOpinionOfPrice]: {
    type: 'static',
    title: 'User Opinion of Price',
    content: [
      `This value is not generated or endorsed by HouseCanary, and it does not represent a broker price opinion (BPO) or a formal appraisal. Report owners can add their own price opinions to the Value Report and share the Value Report with collaborators and partners, which keeps everyone on the same page when it comes to a property’s value.`,
    ],
  },
  [GlossaryTerms.RentalUserOpinionOfPrice]: {
    type: 'static',
    title: 'User Opinion of Rental Price',
    content: [
      `This value is not generated or endorsed by HouseCanary, and it does not represent a broker price opinion (BPO) or a formal appraisal. Report owners can add their own price opinions to the Value Report and share the Value Report with collaborators and partners, which keeps everyone on the same page when it comes to a property’s value.`,
    ],
  },

  [GlossaryTerms.Condition]: {
    type: 'static',
    title: 'Condition',
    content: [
      'Adjusting the Condition will allow you to adjust the value of a property based ' +
        'on the overall physical condition of the property.  Below are suggestions for selecting the ' +
        'property condition. The suggestions below were written to be in alignment with definitions ' +
        'used by appraisers completing a UAD compliant appraisal.',
    ],
  },
  [GlossaryTerms.Excellent]: {
    type: 'static',
    title: 'Excellent',
    content: [
      "'Excellent' - This property has been very recently constructed and may not have previously " +
        'been occupied. The entire structure and all components are new and the dwelling features ' +
        'no physical depreciation.',
    ],
  },
  [GlossaryTerms.Good]: {
    type: 'static',
    title: 'Good',
    content: [
      "'Good' - The property has little or no physical depreciation, requires no repairs, and is " +
        'similar in condition to new construction. Virtually all building components are new or ' +
        'have been recently repaired, refinished, or rehabilitated. All outdated components and ' +
        'finishes have been updated and/or replaced with components that meet current standards.',
    ],
  },
  [GlossaryTerms.Fair]: {
    type: 'static',
    title: 'Fair',
    content: [
      "'Fair' - The property has been maintained and features physical depreciation due to normal " +
        'wear and tear. Some components, but not every major building component, may be updated ' +
        'or recently rehabilitated. All major building components have been adequately maintained ' +
        'and are functionally adequate.',
    ],
  },
  [GlossaryTerms.SubPar]: {
    type: 'static',
    title: 'SubPar',
    content: [
      "'Subpar' - The property features obvious deferred maintenance and is in need of " +
        'some significant repairs. Some building components require repairs, rehabilitation, or ' +
        'updating. The functional utility and overall livability are somewhat diminished due to ' +
        'condition, but the dwelling remains usable and functional as a residence.',
    ],
  },
  [GlossaryTerms.Poor]: {
    type: 'static',
    title: 'Poor',
    content: [
      "'Poor' - The property has substantial damage or deferred maintenance with deficiencies " +
        'or defects that are severe enough to affect the safety, soundness, or structural integrity ' +
        'of the improvements. The improvements are in need of substantial repairs and ' +
        'rehabilitation, including many or most major components.',
    ],
    source: 'HouseCanary analysis, Fannie Mae UAD Definitions for Condition',
  },
  [GlossaryTerms.ValueForecast]: {
    type: 'static',
    title: 'Value Forecast',
    content: [
      'Future Value of the subject property obtained by applying HouseCanary’s ' +
        'proprietary Zip-Code level price appreciation forecast to the current value estimate ',
      'of the subject property.',
    ],
    source:
      'HouseCanary Automated Valuation Model, HouseCanary Home Price Index',
  },
  [GlossaryTerms.MarketIndex]: {
    type: 'static',
    title: 'Market Index',
    content: [
      'The market index is designed to measure supply versus demand at a local Zip-Code ' +
        'level. The index ranges from 0-100 where values of 41-60 indicate a market in equilibrium ' +
        '(neutral). Values above 61 indicate that demand exceeds supply, and that the local ' +
        "area is a seller's market. Values below 41 indicate that supply exceeds demand, and " +
        "that the local area is a buyer's market. Demand is measured using indicators such as " +
        'sales volume, changes in listing prices, and days on market. Supply is measured using ' +
        'indicators such as inventory and the number of new listings.',
    ],
    source: 'Local MLS, HouseCanary analysis',
  },
  [GlossaryTerms.MarketStatus]: {
    type: 'static',
    title: 'Market Status',
    content: [
      'The market status is the summary conclusion on the market index. ' +
        "Specifically whether the market is currently classified as a buyer's market, seller's " +
        'market or neutral. For more details see market index definition.',
    ],
    source: 'Local MLS, HouseCanary analysis',
  },
  [GlossaryTerms.OwnerOccupancy]: {
    type: 'static',
    title: 'Owner Occupancy',
    content: [
      'Owner occupancy indicates whether the owner of the home is the primary resident.',
    ],
    source: 'Public Record',
  },
  [GlossaryTerms.ComparableValue]: {
    type: 'static',
    title: 'Comparable Value',
    content: [
      `
      The “Comparable Value” is calculated by averaging selected comparables’ 
      adjusted sales price. We automatically adjust each comparable’s sale value by comparing 
      each comparable to the subject property. These factors include but are not limited to: 
      locational differences, square feet, bedrooms, bathrooms, lotsize, 
      pools, basements, and many other possible fields.
      `,
    ],
    source: 'HouseCanary analysis',
  },
  [GlossaryTerms.RentalComps]: {
    type: 'static',
    title: 'Rental Comparable Properties',
    content: [
      'Rental Comparable Properties are properties with similar features to the subject property ' +
        'that are currently being rented or have recently been listed for rent. Our rental values adjust ' +
        'for locational differences, square footage, bedrooms, bathrooms, lot size, pools, basements, ' +
        'and many other possible factors.',
    ],
  },
  [GlossaryTerms.OneYearHpiForecast]: {
    type: 'static',
    title: '1-Year HPI Forecast',
    content: [
      'A proprietary HouseCanary metric for home price appreciation for the next 12 months of the respective MSA. ',
    ],
  },
  [GlossaryTerms.MedianGrossYield]: {
    type: 'static',
    title: 'Median Gross Yield',
    content: [
      'Median gross yield for the MSA. Gross_yield = rental AVM * 12 / AVM.',
    ],
  },
  [GlossaryTerms.SaleToListPriceRatio]: {
    type: 'static',
    title: 'Sale-to-List Price Ratio',
    content: [
      'Median sales to list price ratio measured as the closed sale price divided by the original listed price.',
    ],
  },
  [GlossaryTerms.NumberActiveListings]: {
    type: 'static',
    title: 'Number of Active Listings',
    content: [
      'Total number of single family detached properties actively listed for sale, or in contract.',
    ],
  },
  [GlossaryTerms.MsaMonthsOfSupply]: {
    type: 'static',
    title: 'Months of Supply',
    content: [
      'Median months of supply for all single family detached properties.',
    ],
  },
};
