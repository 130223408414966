import React, { useMemo, useRef, useState } from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';

import { MapMarker } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { useClickOutsideComponent } from '@hcs/hooks';
import { GeoLocation } from '@hcs/types';

import {
  getCenterOutAnimationSteps,
  getMarkerOffsets,
  getSeriesAnimationSteps,
} from '../../utils/marker.utils';
import { PropertyMarker } from '../PropertyMarker';
import {
  PropertyMarkerProps,
  PropertyMarkerTypes,
} from '../PropertyMarker/PropertyMarker.types';

import stylesNext from './MultiUnitMarker.module.css';
import stylesNow from './MultiUnitMarkerDeprecated.module.css';

interface MultiUnitMarkerTheme {
  Marker?: string;
  Label?: string;
}
export interface MultiUnitMarkerProps {
  /** location of multi-unit property */
  geoLocation: GeoLocation;
  /** Type to determine look and feel of marker */
  type?: PropertyMarkerTypes;
  /** properties at this multi-unit street address */
  propertyMarkers: PropertyMarkerProps[];
  dataHcName?: string;
  markerToHighlight?: PropertyMarkerProps | null;
  theme?: MultiUnitMarkerTheme;
  markerId?: string | number;
}

export const MultiUnitMarker = ({
  propertyMarkers,
  type = PropertyMarkerTypes.Property,
  geoLocation,
  dataHcName = 'multi-unit-marker',
  theme,
  markerToHighlight,
}: MultiUnitMarkerProps) => {
  const styles = useExperienceFlag('PEXP_REDESIGN') ? stylesNext : stylesNow;

  // indicates whether to show all of the markers at this address
  const [showIndividualMarkers, setShowIndividualMarkers] = useState(false);

  const markerRefs = useRef<HTMLElement[]>([]);

  const addToRefs = (node: HTMLElement) => {
    markerRefs.current.push(node);
  };

  // close all individual markers when the user clicks outside this component
  useClickOutsideComponent(markerRefs, () => {
    if (showIndividualMarkers) {
      setShowIndividualMarkers(false);
    }
  });

  // memoize individual markers
  const individualMarkers = useMemo(() => {
    // get px offsets for all markers
    // offsets depend on the total number of markers
    const offsets = getMarkerOffsets(propertyMarkers.length);

    return propertyMarkers.map((propertyMarkerProps, i) => {
      // marker offsets (left, top) in px for this property
      const markerOffset = offsets[i];

      return (
        <PropertyMarker
          key={propertyMarkerProps.markerId}
          {...propertyMarkerProps}
          addElementToRefList={addToRefs}
          animationSteps={
            // For multi-unit clusters with more than 4 properties, animate each marker
            // through the entire spiral, up to their final spot. Else if less than 4, animate
            // from center of cluster out to final offset position.
            propertyMarkers.length > 4
              ? getSeriesAnimationSteps(i, offsets)
              : getCenterOutAnimationSteps(
                  offsets[i] || { offsetLeft: 0, offsetTop: 0 },
                )
          }
          reverseAnimationOnUnMount
          pulse={
            markerToHighlight?.markerId
              ? markerToHighlight.markerId === propertyMarkerProps.markerId
              : undefined
          }
          markerId={`multi-unit-marker-${propertyMarkerProps.markerId}`}
          onClick={() => propertyMarkerProps.onClick?.(markerOffset)}
          moveToTop
          dataHcName={`${dataHcName}-marker`}
        />
      );
    });
  }, [dataHcName, propertyMarkers, markerToHighlight]);

  if (geoLocation.latitude && geoLocation.longitude) {
    return (
      <>
        <Marker
          style={
            // Only allow hover over individual markers when they are visible
            showIndividualMarkers
              ? { pointerEvents: 'none' }
              : { pointerEvents: 'all' }
          }
          latitude={geoLocation.latitude}
          longitude={geoLocation.longitude}
        >
          <MapMarker
            className={classNames(
              theme?.Marker,
              styles.MultiUnit,
              styles.Marker,
              styles[type],
              {
                [styles.moveToTop]: showIndividualMarkers,
                [styles.clusterExpanded]: showIndividualMarkers,
              },
            )}
            dataHcName={dataHcName}
            onClick={() => setShowIndividualMarkers(!showIndividualMarkers)}
          >
            <div
              className={classNames(styles.Label, theme?.Label)}
              data-hc-name={`${dataHcName}-label`}
            >
              <span className={styles.Count}>{propertyMarkers.length}</span>
              <br />
              Units
            </div>
          </MapMarker>
        </Marker>
        {showIndividualMarkers && individualMarkers}

        {/*
            If displaying all individual markers, then markerToHighlight is already rendered.
            Don't need to render again.
         */}
        {markerToHighlight && !showIndividualMarkers && (
          <PropertyMarker
            key={`highlighted-${markerToHighlight.markerId}`}
            dataHcName={`${dataHcName}-marker`}
            {...markerToHighlight}
            pulse
            moveToTop
          />
        )}
      </>
    );
  }

  return null;
};
