import { useMemo } from 'react';

import { useAccount } from '@hcs/authn';

export const useCheckAnyAccessibleApplications = () => {
  const accountQuery = useAccount();

  const hasAccessibleApplications = useMemo(() => {
    if (accountQuery.isSuccess) {
      return !!accountQuery.data.legacy.accessibleApplications.length;
    }
    return undefined;
  }, [accountQuery.isSuccess, accountQuery.data]);

  return {
    ...accountQuery,
    data: hasAccessibleApplications,
  };
};
