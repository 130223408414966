import React, { useEffect, useRef, useState } from 'react';

import { useActiveState, useClickOutsideComponent } from '@hcs/hooks';
import { DateStr, InputProps } from '@hcs/types';
import { formatDateLong } from '@hcs/utils';

import { CloseIcon, OmCalendarIcon } from '../../../../svgs';
import { CircleButton } from '../../buttons';
import { Input } from '../Input';

import styles from './DateInput.module.css';

type ParentRef = HTMLInputElement | null;

export interface DateInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'type' | 'theme'> {
  dataHcName: string;
  className?: string;
  name?: string;
  value: string | null;
  onChange?: (val: string | null) => void;
  onSetDate?: (val: DateStr | null) => void;
  onCancel?: VoidFunction;
  onBlur?: () => void;
}
export const DateInput = React.forwardRef<ParentRef, DateInputProps>(
  (
    {
      dataHcName,
      className,
      value,
      onChange,
      onSetDate,
      onCancel,
      onBlur,
      error,
      ...inputProps
    }: DateInputProps,
    ref,
  ) => {
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const [innerValue, setInnerValue] = useState(value || '');
    const { active, handleClose, handleOpen } = useActiveState();
    useEffect(() => {
      setInnerValue(value || '');
    }, [value]);
    const handleCancel = () => {
      setInnerValue(value || '');
      onCancel?.();
      handleClose();
      if (onBlur) {
        onBlur();
      }
    };
    const handleSetDate = () => {
      if (active) {
        if (value === innerValue || (!value && !innerValue)) {
          handleCancel();
        } else {
          onSetDate?.(
            innerValue === '' ? null : (innerValue as DateStr), // It will either be an empty string or DateStr
          );
          handleClose();
        }
        if (onBlur) {
          onBlur();
        }
      }
    };
    useClickOutsideComponent(inputContainerRef, handleSetDate);
    return (
      <div className={className}>
        <div className={styles.DateInput}>
          {active ? (
            <div className={styles.ActiveDisplay} ref={inputContainerRef}>
              <Input
                {...inputProps}
                dataHcName={`${dataHcName}-valid-till`}
                type="date"
                value={innerValue || ''}
                onChange={(v) => {
                  setInnerValue(v);
                  onChange?.(v || null);
                }}
                theme={{
                  InputElement: styles.Input,
                }}
                ref={ref}
              />
              <CircleButton
                dataHcName="cancel"
                size="sm"
                buttonStyle="secondary"
                onClick={handleCancel}
              >
                <CloseIcon />
              </CircleButton>
            </div>
          ) : (
            <div className={styles.InactiveDisplay} onClick={handleOpen}>
              <OmCalendarIcon size="sm" />
              {value ? (
                formatDateLong(value)
              ) : !active ? (
                <div className={styles.AddDate}>Add Date</div>
              ) : null}
            </div>
          )}
        </div>
        {error ? (
          <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
            {error}
          </div>
        ) : null}
      </div>
    );
  },
);
