import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId, SubjectValuePaths, ValuationMethod } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';

import { ValueSelectorButton } from '../../components-deprecated';
import { ValueSelectorItem } from '../../components-deprecated';
import {
  VALUATION_METHOD_DESCRIPTIONS,
  VALUATION_METHOD_DESCRIPTIONS_RENTAL,
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants';
import {
  useDocumentPatch,
  useReportPermissions,
  useSubjectValueDocument,
} from '../../hooks';
import { useLegacyCompValues } from '../../hooks/useLegacyCompValues';
import { useReportLimitedType } from '../../hooks/useReportLimitedType';

import styles from './ReportValueSelector.module.css';

interface Props {
  reportId: ReportId;
  onClose?: VoidFunction;
  showRentalValue: boolean;
  className?: string;
  onClickSelectComps: VoidFunction;
  onClickProvideLocation: NavigateToCompSelectionDrawFn;
}
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const PATH_ADJ_DATE: SubjectValuePaths = `/data/${ValuationMethod.Comps}/hcAdjustments/hcAdjustmentDate`;
const PATH_ADJ_DETAILS: SubjectValuePaths = `/data/${ValuationMethod.Comps}/hcAdjustments/hcAdjustmentPropertyDetails`;
const dataHcName = 'comp-based-avm-selector-item';
export const ComparableValueSelectorItem = ({
  reportId,
  className,
  showRentalValue,
  onClose,
  onClickProvideLocation,
  onClickSelectComps,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: reportLimited } = useReportLimitedType(reportId);
  const { data: avmDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });
  const shouldProvideLocation =
    reportLimited?.limitedMapping['missing-location'] &&
    !reportLimited.locationProvidedByUser;
  const compType = showRentalValue ? CompTypes.Rental : CompTypes.Sold;
  // TODO: Post Phase III - remove this temporary comp value hook
  const { data: legacyCompValues } = useLegacyCompValues(reportId, compType);
  if (!avmDocument || !reportPermissions || !legacyCompValues) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton`}
        containerHeight="380px"
      />
    );
  }
  const { comparableValue } = legacyCompValues;
  const handleSelect = () => {
    documentPatchMutation.mutate({
      reportId,
      document: avmDocument,
      operations: [
        {
          op: 'replace',
          path: PATH_SELECTED_VALUE,
          value: ValuationMethod.Comps,
        },
        // TODO: Post Phase III - Remove these operations
        {
          op: 'add',
          path: PATH_ADJ_DATE,
          value: true,
        },
        {
          op: 'add',
          path: PATH_ADJ_DETAILS,
          value: true,
        },
      ],
    });
    if (comparableValue?.value.value) {
      onClose?.();
    } else if (shouldProvideLocation) {
      onClickProvideLocation(reportId, compType);
    } else {
      onClickSelectComps();
    }
  };
  return (
    <ValueSelectorItem
      title={
        showRentalValue
          ? VALUATION_METHOD_LABELS_RENTAL[ValuationMethod.Comps]
          : VALUATION_METHOD_LABELS[ValuationMethod.Comps]
      }
      dataHcName={dataHcName}
      value={comparableValue?.value.value}
      className={classNames(styles.AVMSelectionItem, className)}
    >
      {comparableValue?.value.value ? (
        <span data-hc-name={`${dataHcName}-value-description`}>
          Adjusted average of comparables&apos;{' '}
          {showRentalValue
            ? VALUATION_METHOD_DESCRIPTIONS_RENTAL[ValuationMethod.Avm]
            : VALUATION_METHOD_DESCRIPTIONS[ValuationMethod.Avm]}
        </span>
      ) : (
        <span data-hc-name={`${dataHcName}-nonvalue-description`}>
          The adjusted average HouseCanary Estimated Value of the comparables
          you select. Click below to select comps now.
        </span>
      )}
      {reportPermissions.isEditable && (
        <ValueSelectorButton
          dataHcName={`${dataHcName}-value-selector-button`}
          onClick={handleSelect}
        >
          {comparableValue?.value.value
            ? 'Use For Report'
            : shouldProvideLocation
              ? 'Update Subject Location'
              : `Select ${showRentalValue ? 'Rental ' : ''}Comps`}
        </ValueSelectorButton>
      )}
    </ValueSelectorItem>
  );
};
