import { useNavigate } from 'react-router';

import { APP_CONFIG_MARKET_INSIGHTS } from '../constants';

export const useNavigateToMarketInsights = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_MARKET_INSIGHTS.rootPath);
  };
};
