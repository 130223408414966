import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAccount } from '@hcs/authn';
import { useReportPreferencesForUser } from '@hcs/huell';
import { useToastSlice } from '@hcs/toast';
import { CompTypes } from '@hcs/types';
import {
  CompDocument,
  CompFarmUserPropertyAdjustments,
  CompId,
  CompsFarmDocument,
  DocumentRoles,
  RentalCompDocument,
  RentalCompsFarmDocument,
  ReportApiDocument,
  ReportEventTypes,
  ReportId,
  SelectCompArg,
} from '@hcs/types';

import { ReportApi } from '../api';
import { QUERY_KEY_COMPS_FARM_ADJUSTMENTS } from '../hooks/useCompsFarmAdjustments';
import { useReportEventsRecovery } from '../hooks/useReportEventsRecovery';
import { makeOptimisticDocument } from '../utils';

import { QUERY_KEY_DOCUMENT_ROLE } from './useDocumentRole';
import { useSelectedCompsLimit } from './useSelectedCompsLimit';

export const useCompsSelect = (reportId: ReportId, compType: CompTypes) => {
  const queryClient = useQueryClient();
  const { data: account } = useAccount();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const expectEvent = useReportEventsRecovery(reportId);
  const {
    data: { hasReachedSelectedCompsLimit },
  } = useSelectedCompsLimit(reportId, compType);
  const { data: reportPreferences } = useReportPreferencesForUser();
  const intraOrgReportAccess = reportPreferences?.intraOrgReportAccess;
  const compsSelectMutation = useMutation(
    async (selectCompsArg: SelectCompArg) => {
      // Only show this toast when the user is signed in
      if (account) {
        toastOpen({
          type: 'loading',
          title: 'Updating report values...',
          duration: null,
        });
      }
      return compType === CompTypes.Rental
        ? await ReportApi.selectRentalComps({ ...selectCompsArg, reportId })
        : await ReportApi.selectComps({ ...selectCompsArg, reportId });
    },
    {
      onMutate: (selectCompsArg) => {
        expectEvent(ReportEventTypes.DocumentsCreated);
        const docRoleComp =
          compType === CompTypes.Rental
            ? DocumentRoles.RentalComp
            : DocumentRoles.Comp;
        // Update documentRole cache if populated
        const compFarmDocQueryKey = [
          QUERY_KEY_DOCUMENT_ROLE,
          reportId,
          compType === CompTypes.Rental
            ? DocumentRoles.RentalCompsFarm
            : DocumentRoles.CompsFarm,
        ];
        const farmCache =
          queryClient.getQueryData<
            (CompsFarmDocument | RentalCompsFarmDocument)[]
          >(compFarmDocQueryKey)?.[0];
        // Setup mappings of the selectCompsArg for efficient lookup when looking through the farm
        const selectedHcAddressIds: Record<number, boolean> = {};
        const selectedListings: Record<string, boolean> = {};
        // const cachedComp: CompSchema | undefined = undefined;
        if (selectCompsArg.type === 'hcAddressId') {
          selectCompsArg.hcAddressIds.forEach((hcAddressId) => {
            selectedHcAddressIds[hcAddressId] = true;
          });
        } else {
          selectCompsArg.listingIdentifiers.forEach(
            ({ hcMlsId, listingId }) => {
              selectedListings[`${hcMlsId}-${listingId}`] = true;
            },
          );
        }
        const optimisticComps: (CompDocument | RentalCompDocument)[] = [];
        for (const compIdStr in farmCache?.data.farm) {
          const compId = compIdStr as CompId;
          const farmComp = farmCache?.data.farm[compId];
          const farmCompListingDetails =
            farmComp?.propertyState[
              compType === CompTypes.Rental
                ? 'listingDetailsRental'
                : 'listingDetailsSale'
            ];
          const hcAddressId = farmComp?.propertyState.hcAddressId;
          const listingLookupStr =
            farmCompListingDetails?.hcMlsId &&
            farmCompListingDetails.listingId &&
            `${farmCompListingDetails?.hcMlsId}-${farmCompListingDetails?.listingId}`;
          if (
            farmComp &&
            ((hcAddressId && selectedHcAddressIds[hcAddressId]) ||
              (listingLookupStr && selectedListings[listingLookupStr]))
          ) {
            const adjustmentsQueryKey = [
              QUERY_KEY_COMPS_FARM_ADJUSTMENTS,
              reportId,
              compType === CompTypes.Rental
                ? DocumentRoles.RentalComp
                : DocumentRoles.Comp,
            ];
            const adjustments =
              queryClient.getQueryData<CompFarmUserPropertyAdjustments>(
                adjustmentsQueryKey,
              );
            optimisticComps.push(
              makeOptimisticDocument(
                farmComp,
                docRoleComp,
                intraOrgReportAccess || 'editable',
                adjustments?.[farmComp.compID],
              ),
            );
          }
        }
        const compDocQueryKey = [
          QUERY_KEY_DOCUMENT_ROLE,
          reportId,
          docRoleComp,
        ];
        const documentRoleCache =
          queryClient.getQueryData<ReportApiDocument[]>(compDocQueryKey);
        if (documentRoleCache) {
          queryClient.setQueryData<ReportApiDocument[]>(compDocQueryKey, [
            ...documentRoleCache,
            ...optimisticComps,
          ]);
        } else {
          queryClient.setQueryData<ReportApiDocument[]>(
            compDocQueryKey,
            optimisticComps,
          );
        }
        return { previousDocRoleCache: documentRoleCache };
      },
      // onSuccess: Do not update the document cache, useDocumentRole will do it.
      onError: (err, vars, context) => {
        toastOpen({
          type: 'error',
          title: 'Error Selecting Comp',
        });
        const { previousDocRoleCache } = context || {};
        if (previousDocRoleCache) {
          const compDocQueryKey = [
            QUERY_KEY_DOCUMENT_ROLE,
            reportId,
            compType === CompTypes.Rental
              ? DocumentRoles.RentalComp
              : DocumentRoles.Comp,
          ];
          queryClient.setQueryData<ReportApiDocument[]>(
            compDocQueryKey,
            previousDocRoleCache,
          );
        }
      },
    },
  );
  return {
    ...compsSelectMutation,
    mutate: hasReachedSelectedCompsLimit
      ? undefined
      : compsSelectMutation.mutate,
  };
};
