import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAppConfigsForOrg } from '@hcs/authz';
import { DropdownProps } from '@hcs/design-system';
import {
  FormContainer,
  FormFieldsDate,
  FormFieldsDropdown,
  FormFieldsSwitch,
} from '@hcs/forms';
import { FormFieldsInput } from '@hcs/forms';
import { FormRow } from '@hcs/forms';
import {
  CapabilityApplications,
  OrganizationapiSubmitUsageQueryRequest,
} from '@hcs/types';

import styles from './OrgUsageQueryDetails.module.css';

interface OrgUsageQueryDetailsCreateProps {
  mode: 'internal' | 'external';
  orgId: number;
}

type ApplicationDropdownValue = CapabilityApplications | null;

const dataHcName = 'org-usage-query-create-details';
export const OrgUsageQueryDetailsCreate = ({
  mode,
  orgId,
}: OrgUsageQueryDetailsCreateProps) => {
  const { formState } =
    useFormContext<OrganizationapiSubmitUsageQueryRequest>();
  const { data: appConfigs } = useAppConfigsForOrg(orgId);
  const applicationDropdownOptions: DropdownProps<ApplicationDropdownValue>['options'] =
    useMemo(() => {
      const defaultOptions = [
        {
          value: null,
          label: 'All Applications',
        },
      ];
      const appConfigOptions =
        appConfigs?.map((appConfig) => {
          return {
            value: appConfig.applicationKey,
            label: `${
              appConfig.applicationKey === 'canaryai'
                ? 'CanaryAI'
                : appConfig.name
            } (${appConfig.applicationKey})`,
          };
        }) || [];
      return [...defaultOptions, ...appConfigOptions];
    }, [appConfigs]);

  const { errors } = formState;
  return (
    <FormContainer dataHcName={`${dataHcName}-form-container`}>
      <div className={styles.OrgUsageQueryDetailsWrapper}>
        <FormRow dataHcName={`${dataHcName}-form-row`}>
          <FormFieldsDate
            name="startReqTime"
            label="From (inclusive)"
            className={styles.StartReqTime}
            error={errors.startReqTime?.message}
          />
          <FormFieldsDate
            name="endReqTime"
            label="To (exclusive)"
            className={styles.EndReqTime}
            error={errors.endReqTime?.message}
          />
        </FormRow>
        <FormRow dataHcName={`${dataHcName}-form-row`}>
          <FormFieldsDropdown<ApplicationDropdownValue>
            name="application"
            label="Application"
            className={styles.Application}
            options={applicationDropdownOptions}
            error={errors.application?.message}
          />
          <FormFieldsInput
            name="userEmail"
            label="User Email"
            className={styles.UserEmail}
            error={errors.userEmail?.message}
          />
        </FormRow>
        {mode === 'internal' && (
          <FormRow dataHcName={`${dataHcName}-form-row`}>
            <FormFieldsInput
              name="subjectID"
              label="Subject ID"
              className={styles.SubjectID}
              error={errors.subjectID?.message}
            />
            <FormFieldsInput
              name="subjectType"
              label="Subject Type"
              className={styles.SubjectType}
              error={errors.subjectType?.message}
            />
          </FormRow>
        )}
        <FormRow>
          <FormFieldsSwitch
            label="Include Active Users"
            name="includeActiveUsers"
          />
        </FormRow>
      </div>
    </FormContainer>
  );
};
