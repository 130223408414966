import { Option } from '@hcs/design-system';
import { LoanType, MlsState } from '@hcs/types';
import {
  MlsStatus,
  PortfolioAvmReferences,
  PortfolioAvmThresholdOperators,
  PortfolioForm,
  PortfolioFormFields,
  PortfolioLtvThresholdOperators,
} from '@hcs/types';

export const MLS_STATUS_TYPE_OPTIONS: Option<MlsStatus>[] = [
  { value: MlsState.Expired, label: 'Expired' },
  { value: MlsState.Leased, label: 'Leased' },
  { value: MlsState.Withdrawn, label: 'Withdrawn' },
  { value: MlsState.Sold, label: 'Sold' },
  { value: MlsState.ComingSoon, label: 'Coming Soon' },
  { value: MlsState.Cancelled, label: 'Cancelled' },
  { value: MlsState.Closed, label: 'Closed' },
  { value: MlsState.Contingent, label: 'Contingent' },
  { value: MlsState.Pending, label: 'Pending' },
  { value: MlsState.Active, label: 'Active' },
  { value: MlsState.Deleted, label: 'Deleted' },
];

export const LIEN_TYPE_OPTIONS: Option<LoanType>[] = [
  { value: LoanType.Arm, label: 'ARM' },
  { value: LoanType.Commercial, label: 'Commercial' },
  { value: LoanType.Construction, label: 'Construction' },
  { value: LoanType.Conventional, label: 'Conventional' },
  { value: LoanType.FannieMaeFreddieMac, label: 'Fannie Mae Freddie Mac' },
  {
    value: LoanType.FarmersHomeAdministration,
    label: 'Farmers Home Administration',
  },
  { value: LoanType.Fha, label: 'FHA' },
  { value: LoanType.LandContract, label: 'Land Contract' },
  { value: LoanType.OpenEnd, label: 'Open End' },
  { value: LoanType.RevolvingCreditLine, label: 'Revolving Credit Line' },
  {
    value: LoanType.SecondToCoverDownPayment,
    label: 'Second to Cover Down Payment',
  },
  { value: LoanType.SellerTakeBack, label: 'Seller Take Back' },
  { value: LoanType.StandAloneFirst, label: 'Stand Alone First' },
  { value: LoanType.StandAloneRefi, label: 'Stand Alone Refi' },
  { value: LoanType.StandAloneSecond, label: 'Stand Alone Second' },
  { value: LoanType.StateVeterans, label: 'State Veterans' },
  { value: LoanType.Usda, label: 'USDA' },
  { value: LoanType.Va, label: 'VA' },
];

export const DEFAULT_LTV_VALUE = {
  operator: PortfolioLtvThresholdOperators.GREATER_THAN,
  threshold: 80,
};

export const DEFAULT_PORTFOLIO_SETUP_FORM_VALUES: PortfolioForm = {
  [PortfolioFormFields.Name]: '',
  [PortfolioFormFields.NotificationType]: [],
  [PortfolioFormFields.MlsStatus]: MLS_STATUS_TYPE_OPTIONS.map(
    ({ value }) => value,
  ),
  [PortfolioFormFields.Nod]: null,
  [PortfolioFormFields.AvmThresholds]: {
    relativeTo: PortfolioAvmReferences.LAST_MONTH,
    thresholds: [
      {
        operator: PortfolioAvmThresholdOperators.INCREASE,
        value: null,
      },
    ],
  },
  [PortfolioFormFields.LtvThresholds]: [DEFAULT_LTV_VALUE],
  [PortfolioFormFields.Liens]: LIEN_TYPE_OPTIONS.map(({ value }) => value),
  [PortfolioFormFields.Emails]: [],
  [PortfolioFormFields.WebhookUrl]: '',
};
