import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { Drawer } from '@hcs/design-system';
import { DrawerPosition } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { DownloadIcon } from '@hcs/design-system';
import { useToastSlice } from '@hcs/toast';

import { useExportRequests } from '../../hooks/useExportRequests';
import { useInitiateNewDownload } from '../../hooks/useInitiateNewDownload';
import { useOrder } from '../../hooks/useOrder';

import styles from './DownloadAllDrawer.module.css';

interface Props {
  orderId: number;
  hideModal: () => void;
}

const dataHcName = 'download-all-drawer';
export const DownloadAllDrawer = ({ orderId, hideModal }: Props) => {
  const {
    data: order,
    isError: orderIsError,
    error: orderError,
  } = useOrder(orderId);

  const [includeJson, setIncludeJson] = useState<boolean>(false);
  const [newExpectedTotal, setNewExpectedTotal] = useState<number | null>(null);
  const [pollExportRequests, setPollExportRequests] = useState(false);
  const {
    actions: { toastOpen, toastClose },
  } = useToastSlice();
  const {
    data: exportRequests,
    isInitialLoading: exportRequestsIsLoading,
    isSuccess: exportRequestsIsSuccess,
    isError: exportRequestsIsError,
    error: exportRequestsError,
  } = useExportRequests(orderId, {
    refetchInterval: pollExportRequests ? 3000 : false,
  });
  useEffect(() => {
    if (newExpectedTotal && exportRequests?.length !== newExpectedTotal) {
      setPollExportRequests(true);
    } else if (exportRequests?.length === newExpectedTotal) {
      toastOpen({
        type: 'loading-success',
        title: 'Your Download is Ready',
      });
      setPollExportRequests(false);
    }
  }, [exportRequests, newExpectedTotal, setPollExportRequests, toastOpen]);
  const {
    mutate: initiateNewDownload,
    isLoading,
    isError: initiateNewDownloadIsError,
    error: initiateNewDownloadError,
  } = useInitiateNewDownload({
    onSuccess: () => {
      toastOpen({
        type: 'loading',
        title: 'Creating Order Download...',
        duration: null,
      });
      setNewExpectedTotal(
        (newExpectedTotal || exportRequests?.length || 0) + 1,
      );
    },
  });
  useEffect(() => {
    return () => {
      // close any open toasts on unmount
      toastClose();
    };
    // unmount hook
  }, [toastClose]);

  return (
    <Drawer
      className={styles.DrawerContainer}
      dataHcName={dataHcName}
      onClose={hideModal}
      active={true}
      position={DrawerPosition.RIGHT}
      title={order?.name}
      notifications={[
        {
          dataHcName: 'order-error',
          type: 'error',
          show: orderIsError,
          title: orderError?.message || 'Failed to fetch order',
        },
        {
          dataHcName: 'export-request-error',
          type: 'error',
          show: exportRequestsIsError,
          title:
            exportRequestsError?.message || 'Failed to fetch export requests',
        },
        {
          dataHcName: 'initiate-new-download-error',
          type: 'error',
          show: initiateNewDownloadIsError,
          title:
            initiateNewDownloadError?.message ||
            'Failed to initiate new download',
        },
      ]}
    >
      <div>
        <Button
          className={styles.InitiateNewDownload}
          disabled={!exportRequestsIsSuccess}
          onClick={() => {
            initiateNewDownload({
              orderId: orderId,
              excludeJson: !includeJson,
            });
          }}
          loading={isLoading}
          dataHcName={`${dataHcName}-new-download-button`}
        >
          Initiate New Download
        </Button>
        <br />
        <div>
          <Checkbox
            style={{
              marginRight: '5px',
            }}
            checked={includeJson}
            onChange={setIncludeJson}
            dataHcName={`${dataHcName}-include-json-checkbox`}
          >
            Include JSON data in downloads
          </Checkbox>
        </div>
        <br />
        <div>
          {exportRequestsIsLoading ? (
            <LoadingSpinner
              dataHcName={`${dataHcName}-export-requests-loading`}
            />
          ) : (
            <div>
              {exportRequests?.map((exportRequest) => {
                const completedAt = exportRequest.completedAt;
                return (
                  <div
                    className={styles.ExportRequestLink}
                    key={exportRequest.id}
                  >
                    <a href={exportRequest.exportedData}>
                      <div className={styles.LinkInfoContainer}>
                        <div className={styles.ExportRequestLinkDateTime}>
                          {completedAt && (
                            <>
                              <div>
                                {format(new Date(completedAt), 'MMM d, yyyy')}
                              </div>
                              <div className={styles.ExportRequestTime}>
                                {format(new Date(completedAt), 'p')}
                              </div>
                            </>
                          )}
                        </div>
                        <DownloadIcon
                          dataHcName={`${dataHcName}-download-icon`}
                          size="sm"
                          color="neutral-dark-20"
                        />
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
