import React from 'react';
import classNames from 'classnames';

import { useActiveState } from '@hcs/hooks';
import { AppConfigExtended, AppSlugs } from '@hcs/types';

import { AppLauncherIcon } from '../../../../svgs/icons/navigation';
import { SlideInPane } from '../../../display/dialogs/SlideInPane';
import { Anchor } from '../../../display/links/Anchor';

import { AppLauncherOnClickAppCallback } from './../AppLauncher';

import styles from './AppLauncherMobile.module.css';

export interface AppLauncherMobileProps {
  dataHcName: string;
  appsSupported: AppSlugs[];
  appConfigsExtended: AppConfigExtended[];
  onClickApp: AppLauncherOnClickAppCallback;
  viewAll?: {
    onClick: VoidFunction;
    label: string;
  };
  bottomSection?: React.ReactNode;
}

const AppItem = ({
  appConfigExtended,
  onClick,
  dataHcName,
  disabled,
}: {
  appConfigExtended: AppConfigExtended;
  onClick: VoidFunction;
  dataHcName: string;
  disabled?: boolean;
}) => {
  const { Icon, name, descriptionShort } = appConfigExtended.appConfig;
  return (
    <li
      onClick={disabled ? () => void 0 : onClick}
      data-hc-name={dataHcName}
      className={classNames(styles.AppButton, {
        [styles.Disabled]: disabled,
      })}
    >
      {Icon && (
        <div className={styles.IconCell}>
          <Icon dataHcName={`${dataHcName}-icon`} height="24px" width="24px" />
        </div>
      )}
      <div className={styles.InfoCell}>
        <div className={styles.AppName} data-hc-name={`${dataHcName}-name`}>
          {name}
        </div>
        <div
          className={styles.ByLine}
          data-hc-name={`${dataHcName}-description`}
        >
          {descriptionShort}
        </div>
      </div>
    </li>
  );
};
export const AppLauncherMobile = ({
  appConfigsExtended,
  appsSupported,
  dataHcName,
  onClickApp: onClickAppProp,
  viewAll,
  bottomSection,
}: AppLauncherMobileProps) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const onClickApp = (appConfigExtended: AppConfigExtended) => {
    onClickAppProp(appConfigExtended);
    handleClose();
  };
  return (
    <>
      <AppLauncherIcon
        color={'neutral-light-10'}
        className={styles.TriggerButton}
        dataHcName={`${dataHcName}-trigger`}
        aria-label="Open App Launcher"
        onClick={() => {
          active ? handleClose() : handleOpen();
        }}
      />
      <SlideInPane
        active={active}
        onClose={handleClose}
        expandedWidth={'293px'}
        dataHcName={dataHcName}
        className={styles.SlideInPane}
      >
        <>
          <ul
            data-hc-name={`${dataHcName}-app-buttons-supported`}
            className={styles.AppButtons}
          >
            {appsSupported.map((slug) => {
              const appConfigExt = appConfigsExtended.find(
                (ext) => ext.appConfig.appSlug === slug,
              );
              if (!appConfigExt) {
                return null;
              }
              const { appSlug } = appConfigExt.appConfig;
              return (
                <AppItem
                  dataHcName={`app-launcher-button-${slug}`}
                  key={appSlug}
                  appConfigExtended={appConfigExt}
                  onClick={() => onClickApp(appConfigExt)}
                />
              );
            })}
          </ul>
          <div className={styles.NotSupportedSection}>
            <div className={styles.NotSupportedDescription}>
              Please launch these products in a desktop browser for the best
              experience.
            </div>
            <ul
              data-hc-name={`${dataHcName}-app-buttons-not-supported`}
              className={styles.AppButtons}
            >
              {appConfigsExtended
                .filter(
                  (appConfigExt) =>
                    !appsSupported.includes(appConfigExt.appConfig.appSlug),
                )
                .map((appConfigExt) => {
                  const { appSlug } = appConfigExt.appConfig;
                  return (
                    <AppItem
                      dataHcName={`app-launcher-button-${appSlug}`}
                      key={appSlug}
                      appConfigExtended={appConfigExt}
                      onClick={() => onClickApp(appConfigExt)}
                      disabled
                    />
                  );
                })}
            </ul>
          </div>
          {viewAll && (
            <Anchor
              className={styles.ViewAllLink}
              dataHcName={`${dataHcName}-view-all`}
              onClick={() => {
                handleClose();
                viewAll.onClick();
              }}
            >
              {viewAll.label}
            </Anchor>
          )}
          {bottomSection && <div>{bottomSection}</div>}
        </>
      </SlideInPane>
    </>
  );
};
