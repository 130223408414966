import React from 'react';

import { TableCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';

import {
  COMP_SELECT_BUTTON_FEATURES_RENTAL,
  COMP_SELECT_BUTTON_FEATURES_SALE,
  CompSelectButton,
} from '../../CompSelectButton';
import { ReportFeaturesSupported } from '../../ReportFeaturesSupported';

interface Props extends TableCellProps {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
}

export const SELECT_CELL_FEATURES_SALE = COMP_SELECT_BUTTON_FEATURES_SALE;
export const SELECT_CELL_FEATURES_RENTAL = COMP_SELECT_BUTTON_FEATURES_RENTAL;
export const CompsFarmListSelectCell = ({
  reportId,
  compIdentifier,
  ...tableCellProps
}: Props) => {
  return (
    <TableCell {...tableCellProps}>
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          compIdentifier.compType === CompTypes.Rental
            ? SELECT_CELL_FEATURES_RENTAL
            : SELECT_CELL_FEATURES_SALE
        }
      >
        <CompSelectButton
          reportId={reportId}
          compIdentifier={compIdentifier}
          small
        />
      </ReportFeaturesSupported>
    </TableCell>
  );
};

// Using default props allows for child.props checks
// in the Table components to work properly
const defaultProps: Partial<TableCellProps> = {
  width: 50,
  sticky: true,
  align: 'center',
  // Needed so child component type-checking passes
  isTableCell: true,
};
CompsFarmListSelectCell.defaultProps = defaultProps;
