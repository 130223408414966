import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OrderProgress } from '@hcs/types';

import { OrderManagerApi } from '../api/orderManagerApi';

export const QUERY_KEY_ORDER_PROGRESS = 'QUERY_KEY_ORDER_PROGRESS';
export const useOrderProgress = (
  orderId: number,
  options?: UseQueryOptions<
    OrderProgress,
    AxiosError,
    OrderProgress,
    [typeof QUERY_KEY_ORDER_PROGRESS, string | number]
  >,
) => {
  return useQuery(
    [QUERY_KEY_ORDER_PROGRESS, orderId],
    async () => await OrderManagerApi.fetchOrderProgress(orderId),
    {
      ...options,
      // we don't want this data to cache, want a fresh request every time the user hovers to see progress
      staleTime: 0,
    },
  );
};
