import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { IconButton } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { Anchor } from '@hcs/design-system';
import { InfoTooltip } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { LineClamp } from '@hcs/design-system';
import { Header } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { TextBadge } from '@hcs/design-system';
import { EditIcon } from '@hcs/design-system';
import {
  CompFields,
  CompTypes,
  MeaningfulEventTypes,
  PropertyStateFields,
} from '@hcs/types';
import {
  CompIdentifier,
  IncludeHcAdjustments,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import { NavigateToPropertyPreviewFn } from '@hcs/types';
import { logException } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import {
  PATH_HC_ADJUSTMENT_DATE,
  PATH_HC_ADJUSTMENT_PROPERTY_DETAILS,
} from '../../constants';
import {
  useCompDocuments,
  useCompsFarmDocument,
  useDocumentPatch,
  useReportPermissions,
  useSubjectDocument,
  useSubjectValueDocument,
} from '../../hooks';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import {
  CompAdjustmentOperation,
  CompCompareChangeHandler,
  CompCompareScrollHandler,
  PendingOperations,
} from './CompCompare.types';
import { CompCompareColumn } from './CompCompareColumn';
import { CompCompareSubject } from './CompCompareSubject';

import styles from './CompCompare.module.css';

export interface CompCompareProps {
  reportId: ReportId;
  // Optionally overridden
  dataHcName?: string;
  className?: string;
  compIdentifiers: CompIdentifier[];
  compType: CompTypes;
  fields: (PropertyStateFields | CompFields)[];
  /** Allow adjustments if the comp is selected */
  isAdjustable?: boolean;
  /** Id of actions portal in the header */
  actionsPortalIdRender?: string;
  onChangeIsAdjusting?: (isAdjusting: boolean) => void;
  navigateToPropertyPreview: NavigateToPropertyPreviewFn;
}
type CompFieldErrors = Partial<Record<PropertyStateFields, boolean>>;
export const CompCompare = ({
  dataHcName = 'comp-compare',
  reportId,
  compType,
  compIdentifiers,
  isAdjustable = true,
  actionsPortalIdRender,
  fields,
  navigateToPropertyPreview,
  className,
  onChangeIsAdjusting,
}: CompCompareProps) => {
  const isRental = compType === CompTypes.Rental;
  const dataHcEventSectionAdjusting = isRental
    ? 'Adjust Selected Rental Comps'
    : 'Adjust Selected Comps';
  // Report-Api Queries
  const subjectValueQuery = useSubjectValueDocument(reportId, {
    showRentalValue: isRental,
  });
  const subjectQuery = useSubjectDocument(reportId);
  const farmQuery = useCompsFarmDocument(reportId, compType);
  const compsQuery = useCompDocuments(reportId, compType);
  const reportPermissionsQuery = useReportPermissions(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectValueQuery,
    subjectQuery,
    farmQuery,
    compsQuery,
    reportPermissionsQuery,
  ]);
  const documentPatchMutation = useDocumentPatch(reportId);
  const isMultiCompare = compIdentifiers.length > 1;
  const { data: subjectValueDocument } = subjectValueQuery;

  // Local State of Form
  const [isCompAdjusting, setIsCompAdjusting] = useState(false);
  const [compAdjustErrors, setCompAdjustErrors] = useState<CompFieldErrors>({});
  const [pendingOperations, setPendingOperations] = useState<PendingOperations>(
    {},
  );
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const adjustmentContainer = useRef<HTMLDivElement | null>(null);

  // Handlers
  const handleSetIsAdjusting = (v: boolean) => {
    setIsCompAdjusting(v);
    onChangeIsAdjusting?.(v);
  };

  const handleScroll: CompCompareScrollHandler = (event) => {
    if (scrollContainer?.current && adjustmentContainer?.current) {
      scrollContainer.current.scrollLeft = event.currentTarget?.scrollLeft || 0;
      adjustmentContainer.current.scrollLeft =
        event.currentTarget.scrollLeft || 0;
    }
  };

  const handleChangeAdjustment: CompCompareChangeHandler = ({
    changeArg,
    documentId,
    type,
  }) => {
    // Currently only supports patching primitives
    if (
      typeof changeArg.value === 'number' ||
      typeof changeArg.value === 'string' ||
      typeof changeArg.value === 'boolean' ||
      changeArg.value === null ||
      changeArg.value === undefined
    ) {
      const updatedData = { ...pendingOperations };
      const pendingChanges = updatedData[documentId] || {
        type: type === 'includeHcAdjustments' ? 'subjectValue' : 'comp',
        operations: [],
      };
      const operation: CompAdjustmentOperation = {
        op: 'add',
        path: `/data${type !== 'propertyState' ? '' : '/propertyState'}${
          changeArg.path
        }`,
        value: changeArg.value,
      };
      pendingChanges.operations = pendingChanges.operations.filter(
        (op) => op.path !== operation.path,
      );
      pendingChanges.operations.push(operation);
      if (pendingChanges.type === 'subjectValue') {
        updatedData[documentId] = {
          type: 'subjectValue',
          operations: pendingChanges.operations,
        };
      } else {
        updatedData[documentId] = {
          type: 'comp',
          operations: pendingChanges.operations,
        };
      }
      setPendingOperations(updatedData);
    } else {
      logException(
        `CompCompare: Attempted to PATCH non-primitive value for ${changeArg.field}`,
      );
    }
  };

  const handleSubmit = () => {
    const documentIds = Object.keys(pendingOperations);
    documentIds.forEach((documentId) => {
      const { type, operations } = pendingOperations[documentId] || {};
      const document =
        type === 'subjectValue'
          ? subjectValueDocument
          : compsQuery.data?.find((d) => d.documentId === documentId);
      if (document && operations) {
        documentPatchMutation.mutate({
          reportId,
          document,
          operations,
        });
      }
    });
    setPendingOperations({});
    handleSetIsAdjusting(false);
  };

  const handleCancel = () => {
    handleSetIsAdjusting(false);
  };

  const includeHcAdjustments: IncludeHcAdjustments = useMemo(() => {
    // Pull the new include adjustment values from the pending operations if they exist,
    // otherwise get them from the subject value document
    const pendingSubjectValueOps =
      (subjectValueDocument?.documentId &&
        pendingOperations[subjectValueDocument.documentId]) ||
      undefined;
    const pendingHcAdjustmentDate =
      pendingSubjectValueOps?.type === 'subjectValue'
        ? pendingSubjectValueOps.operations.find(
            (o) => o.path === `/data${PATH_HC_ADJUSTMENT_DATE}`,
          )?.value
        : undefined;
    const pendingHcAdjustmentPropertyDetails =
      pendingSubjectValueOps?.type === 'subjectValue'
        ? pendingSubjectValueOps.operations.find(
            (o) => o.path === `/data${PATH_HC_ADJUSTMENT_PROPERTY_DETAILS}`,
          )?.value
        : undefined;
    return {
      hcAdjustmentDate:
        pendingHcAdjustmentDate === undefined
          ? !!subjectValueDocument?.data.comparableValue.hcAdjustments
              .hcAdjustmentDate
          : pendingHcAdjustmentDate,
      hcAdjustmentPropertyDetails:
        pendingHcAdjustmentPropertyDetails === undefined
          ? !!subjectValueDocument?.data.comparableValue.hcAdjustments
              .hcAdjustmentPropertyDetails
          : pendingHcAdjustmentPropertyDetails,
    };
  }, [subjectValueDocument, pendingOperations]);

  if (combinedQuery.isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  } else if (combinedQuery.isError) {
    return (
      <NullState
        dataHcName={`${dataHcName}-error`}
        title="Error Retrieving Report Data."
      />
    );
  }
  return (
    <>
      {isAdjustable && reportPermissionsQuery.data?.isEditable && (
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? [
                  ReportFeatures.RentalCompsAdjust,
                  ReportFeatures.RentalCompsEditDetails,
                ]
              : [ReportFeatures.CompsAdjust, ReportFeatures.CompsEditDetails]
          }
        >
          {isCompAdjusting ? (
            <Header
              dataHcName={`${dataHcName}-actions`}
              dataHcEventSection={dataHcEventSectionAdjusting}
              size="sm"
              title="Adjust details"
              onBack={() => handleSetIsAdjusting(false)}
            >
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={
                  isRental
                    ? [
                        ReportFeatures.RentalCompsAdjust,
                        ReportFeatures.RentalCompsEditDetails,
                      ]
                    : [
                        ReportFeatures.CompsAdjust,
                        ReportFeatures.CompsEditDetails,
                      ]
                }
              >
                <Button
                  dataHcName={`${dataHcName}-adjust-comps-save`}
                  onClick={handleSubmit}
                  disabled={Object.values(compAdjustErrors).includes(true)}
                  dataHcEventName={
                    isRental
                      ? 'Saved Rental Comp Adjustments'
                      : 'Saved Comp Adjustments'
                  }
                  dataHcEventType={MeaningfulEventTypes.Goal}
                  primary
                >
                  Save
                </Button>
                <Anchor
                  dataHcName={`${dataHcName}-adjust-comps-cancel`}
                  onClick={handleCancel}
                >
                  Cancel
                </Anchor>
                <TextBadge
                  dataHcName={dataHcName}
                  className={styles.TextBadge}
                  value={
                    <LineClamp className={styles.TextBadgeText} lines={3}>
                      By default, your comps are valued by HouseCanary’s data.
                      If you remove {isRental ? 'RPI' : 'HPI'} and HC
                      Adjustments, your comps will be valued by the market
                      price.
                    </LineClamp>
                  }
                  icon={
                    <InfoTooltip
                      dataHcName={`${dataHcName}-desc-tooltip`}
                      label={`Adjustment applied to the ${
                        compType === CompTypes.Rental ? 'Lease' : 'Sale'
                      } or List price depending on the property status.`}
                    />
                  }
                ></TextBadge>
              </ReportFeaturesSupported>
            </Header>
          ) : (
            <ActionButtons
              dataHcName={`${dataHcName}-actions`}
              className={styles.AdjustingHeader}
              portalIdRender={actionsPortalIdRender}
            >
              <Tooltip
                dataHcName={`${dataHcName}-adjust-comps-adjust`}
                trigger={
                  <IconButton
                    dataHcName={`${dataHcName}-adjust-comps-adjust-btn`}
                    icon={<EditIcon />}
                    onClick={() => handleSetIsAdjusting(true)}
                  />
                }
                label="Adjust Details"
              />
            </ActionButtons>
          )}
        </ReportFeaturesSupported>
      )}
      <div
        data-hc-name={dataHcName}
        className={classNames(styles.CompCompare, className, {
          [styles.multiCompare]: isMultiCompare,
        })}
        ref={scrollContainer}
        onScroll={handleScroll}
        id="comp-compare"
      >
        <CompCompareSubject
          fields={fields}
          reportId={reportId}
          compIdentifiers={compIdentifiers}
          compType={compType}
          isAdjusting={isCompAdjusting}
          includeHcAdjustments={includeHcAdjustments}
          onChangeAdjustments={handleChangeAdjustment}
        />
        {compIdentifiers.map((compIdentifier, i) => {
          return (
            <CompCompareColumn
              className={
                isMultiCompare && i === 0 ? styles.firstColumn : undefined
              }
              fields={fields}
              isAdjusting={isCompAdjusting}
              reportId={reportId}
              key={`comp-col-${i}`}
              colIndex={i}
              compIdentifier={compIdentifier}
              onErrorChange={(field, error) => {
                setCompAdjustErrors((prevCompAdjustErrors) => {
                  return {
                    ...prevCompAdjustErrors,
                    [field]: !!error,
                  };
                });
              }}
              navigateToPropertyPreview={navigateToPropertyPreview}
              onChangeAdjustment={handleChangeAdjustment}
              includeHcAdjustments={includeHcAdjustments}
            />
          );
        })}
      </div>
    </>
  );
};
