import { useDispatch, useSelector } from 'react-redux';

import { MarketStatePartialAppState, MarketStateSliceState } from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { marketStateSearchSlice } from '../rtk';

export const useMarketStateSearchSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<MarketStatePartialAppState, MarketStateSliceState>(
      (state) => state[marketStateSearchSlice.name],
    ),
    actions: mapDispatchToSliceActions(dispatch, marketStateSearchSlice),
  };
};
