import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import {
  CompFarmListSearchParams,
  CompFarmListViewType,
} from '../features/CompFarmList/CompFarmList.types';

import { useCompDocuments } from './useCompDocuments';

export const useCompFarmList = (reportId: ReportId, compType: CompTypes) => {
  const compsQuery = useCompDocuments(reportId, compType);
  const [searchParams, setSearchParams] = useSearchParams();
  const state = {
    listViewType:
      (searchParams.get(
        CompFarmListSearchParams.ListViewType,
      ) as CompFarmListViewType) || CompFarmListViewType.Card,
    selectedOnly:
      searchParams.get(CompFarmListSearchParams.SelectedOnly) === 'true',
  };
  useEffect(() => {
    // If no comps are selected
    if (compsQuery.isSuccess && !compsQuery.data.length) {
      if (state.selectedOnly) {
        // Don't show selected only if no comps are selected
        searchParams.set(CompFarmListSearchParams.SelectedOnly, 'false');
      }
      if (state.listViewType === CompFarmListViewType.Compare) {
        searchParams.set(
          CompFarmListSearchParams.ListViewType,
          CompFarmListViewType.Card,
        );
      }
      // Update search params
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    state.listViewType,
    state.selectedOnly,
    compsQuery.isSuccess,
    compsQuery.data?.length,
  ]);
  return {
    state,
    compFarmListSetListViewType: (newListViewType: CompFarmListViewType) => {
      if (newListViewType === CompFarmListViewType.Compare) {
        searchParams.set(CompFarmListSearchParams.SelectedOnly, 'true');
      }
      searchParams.set(CompFarmListSearchParams.ListViewType, newListViewType);
      setSearchParams(searchParams, { replace: true });
    },
    compFarmListSetSelectedOnly: (newSelectedOnly: boolean) => {
      searchParams.set(
        CompFarmListSearchParams.SelectedOnly,
        newSelectedOnly.toString(),
      );
      if (
        !newSelectedOnly &&
        state.listViewType === CompFarmListViewType.Compare
      ) {
        searchParams.set(
          CompFarmListSearchParams.ListViewType,
          CompFarmListViewType.Card,
        );
      }
      setSearchParams(searchParams, { replace: true });
    },
  };
};
