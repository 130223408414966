import React from 'react';

import { Section } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ASSOCIATION_CONFIG } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatBoolYesNoUnknown } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const dataHcName = 'listing-hoa-section';

const { getValue: getAssociation } = ASSOCIATION_CONFIG;
export const ListingHoaSection = () => {
  const { propertyStateCore } = useMlsListingSheetData();
  const association = getAssociation(propertyStateCore);
  return (
    <Section
      dataHcName={dataHcName}
      sectionHeaderProps={{ title: 'HOA Information' }}
      compact
    >
      <TwoColTable
        smallLabels
        compact
        dataHcName={`${dataHcName}-table`}
        data={[
          {
            dataHcName: 'hoa-has',
            label: 'Has Association',
            value: formatBoolYesNoUnknown(
              association ? !!association?.length : null,
            ),
          },
          PropertyStateFields.association,
        ].map((field) => {
          if (typeof field === 'string') {
            const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return {
              dataHcName: `${dataHcName}-${field}`,
              value: <Display propertyStateArgs={propertyStateCore} />,
              label,
            };
          } else {
            return field;
          }
        })}
      />
    </Section>
  );
};
