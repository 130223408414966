import { PlatformIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_PLATFORM: AppConfig = {
  name: 'Solutions Platform',
  applicationKey: 'platform',
  type: 'external-app',
  Icon: PlatformIcon,
  appSlug: AppSlugs.Platform,
  globalHeaderTitle: '',
  rootPath: `/platform`,
  descriptionLong:
    'Functionality that spans many HouseCanary Solutions products.',
  descriptionShort:
    'Functionality that spans many HouseCanary Solutions products.',
  feOnlyApp: true,
};
