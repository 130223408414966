import {
  CompFields,
  PropertyStateArgs,
  PropertyStateFields,
  PropertyStateFlat,
} from '@hcs/types';

import { COMP_FIELDS_CONFIG, PROPERTY_STATE_FIELD_CONFIGS } from '../configs';

// Type predicate for mixing PropertyStateFields and CompFields configs
export const isCompField = (
  field: PropertyStateFields | CompFields,
): field is CompFields =>
  Object.values(CompFields).includes(field as CompFields);

export const getPropertyStateFieldValue = <F extends PropertyStateFields>(
  propertyStateField: F,
  propertyStateArgs: PropertyStateArgs | undefined,
): PropertyStateFlat[F] =>
  PROPERTY_STATE_FIELD_CONFIGS[propertyStateField].getValue(propertyStateArgs);

// Use above type predicate to get the field config in a type-safe way
export const getCompFieldConfig = (field: PropertyStateFields | CompFields) => {
  if (isCompField(field)) {
    return COMP_FIELDS_CONFIG[field];
  } else {
    return PROPERTY_STATE_FIELD_CONFIGS[field];
  }
};
