import { useNavigate } from 'react-router';

import { APP_CONFIG_AEXP } from '../constants/AppConfigAexp.constants';

export const useNavigateToAexp = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_AEXP.rootPath);
  };
};
