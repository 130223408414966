import { Link } from 'react-router-dom';

import { useUserIsOrgAdmin } from '@hcs/auth';
import {
  UserMenuAvatarButton,
  UserMenuLogoutButton,
  useUserIsConfirmed,
} from '@hcs/authn';
import {
  UserAlert,
  UserMenu,
  UserMenuButton,
  UserMenuButtonProps,
  UserMenuButtons,
} from '@hcs/design-system';
import {
  EntitlementsIcon,
  OrgSettingsIcon,
  ProfileIcon,
  SupportIcon,
  UsageIcon,
} from '@hcs/design-system';
import {
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_USER_ADMIN,
  VIEW_PATHS_ORG_ADMIN,
  VIEW_PATHS_USER_ADMIN,
} from '@hcs/hc-products';
import { useScreenSize } from '@hcs/hooks';
import { useOrgIsSelfServe } from '@hcs/self-serve-info';
import { SUPPORT_URL } from '@hcs/urls';

import styles from './SolutionsPlatformUserMenu.module.css';

export const USER_ADMIN_ROOT_PATH = APP_CONFIG_USER_ADMIN.rootPath;
export const ORG_ADMIN_ROOT_PATH = APP_CONFIG_ORG_ADMIN.rootPath;

const dataHcName = 'global-user-menu';
export interface SolutionsPlatformUserMenuProps {
  buttonGroups?: UserMenuButtonProps[][];
  triggerClassName?: string;
  onLogout?: VoidFunction;
}
export const SolutionsPlatformUserMenu = ({
  buttonGroups,
  triggerClassName,
  onLogout,
}: SolutionsPlatformUserMenuProps) => {
  const { data: isOrgAdmin } = useUserIsOrgAdmin();
  const { data: isUserConfirmed } = useUserIsConfirmed();
  const { data: isOrgSelfServe } = useOrgIsSelfServe();
  const { isPhoneWidth } = useScreenSize();

  const logoutButton = (
    <UserMenuLogoutButton
      dataHcName={`${dataHcName}-logout`}
      onLogout={onLogout}
    />
  );
  const supportLink = (
    <a
      href={SUPPORT_URL}
      data-hc-name={`${dataHcName}-support-link`}
      className={styles.Button}
      target="_blank"
      rel="noreferrer"
    >
      <UserMenuButton
        dataHcName={`${dataHcName}-support`}
        icon={<SupportIcon dataHcName={`${dataHcName}-support-icon`} />}
        label="Support"
        description="View support articles or get in touch."
      />
    </a>
  );
  return (
    <UserMenu
      triggerClassName={triggerClassName}
      content={
        isUserConfirmed ? (
          <>
            <Link
              data-hc-name={`${dataHcName}-avatar-link`}
              to={USER_ADMIN_ROOT_PATH}
            >
              <UserMenuAvatarButton />
            </Link>
            {isPhoneWidth ? (
              <UserMenuButtons dataHcName={`${dataHcName}-user-buttons`}>
                <UserAlert
                  type="warning"
                  text="To view your usage or edit your organization settings, please view on desktop."
                />
                {isOrgAdmin && isOrgSelfServe && (
                  <Link
                    to={`${ORG_ADMIN_ROOT_PATH}/${VIEW_PATHS_ORG_ADMIN.CHANGE_PLAN}`}
                    className={styles.Button}
                    data-hc-name={`${dataHcName}-change-plan-link`}
                  >
                    <UserMenuButton
                      dataHcName={`${dataHcName}-change-plan`}
                      icon={<EntitlementsIcon />}
                      label="Change or Cancel Plan"
                      description="Change or cancel the plan that you are subscribed to"
                    />
                  </Link>
                )}
                {logoutButton}
              </UserMenuButtons>
            ) : (
              <>
                <UserMenuButtons dataHcName={`${dataHcName}-usage-buttons`}>
                  <Link
                    to={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_USAGE}`}
                    className={styles.Button}
                    data-hc-name={`${dataHcName}-usage-link`}
                  >
                    <UserMenuButton
                      dataHcName={`${dataHcName}-usage`}
                      icon={
                        <UsageIcon dataHcName={`${dataHcName}-usage-icon`} />
                      }
                      label="Usage"
                      description="View the amount of Property Explorer reports pulled this month."
                    />
                  </Link>
                </UserMenuButtons>
                {buttonGroups?.map((buttonGroup, g) => {
                  return (
                    <UserMenuButtons
                      dataHcName={`${dataHcName}-app-group-${g}`}
                      key={`group-${g}`}
                    >
                      {buttonGroup.map((button, b) => {
                        return (
                          <UserMenuButton
                            {...button}
                            key={`group-${g}-button-${b}`}
                          />
                        );
                      })}
                    </UserMenuButtons>
                  );
                })}
                {isOrgAdmin && (
                  <UserMenuButtons dataHcName={`${dataHcName}-org-buttons`}>
                    <Link
                      to={ORG_ADMIN_ROOT_PATH}
                      className={styles.Button}
                      data-hc-name={`${dataHcName}-org-admin-link`}
                    >
                      <UserMenuButton
                        dataHcName={`${dataHcName}-org-admin`}
                        icon={
                          <OrgSettingsIcon
                            dataHcName={`${dataHcName}-org-admin-icon`}
                          />
                        }
                        label="Organization Settings"
                        description="Manage your organization's users, permissions, and preferences."
                      />
                    </Link>
                  </UserMenuButtons>
                )}
                <UserMenuButtons dataHcName={`${dataHcName}-user-buttons`}>
                  <Link
                    to={`${USER_ADMIN_ROOT_PATH}/${VIEW_PATHS_USER_ADMIN.USER_CONTACT_INFO}`}
                    className={styles.Button}
                    data-hc-name={`${dataHcName}-user-admin-link`}
                  >
                    <UserMenuButton
                      dataHcName={`${dataHcName}-user-admin`}
                      icon={
                        <ProfileIcon
                          dataHcName={`${dataHcName}-user-admin-icon`}
                        />
                      }
                      label="Edit Profile"
                      description="Manage your personal information or change your password."
                    />
                  </Link>

                  {supportLink}
                  {logoutButton}
                </UserMenuButtons>
              </>
            )}
          </>
        ) : (
          <>
            <UserMenuAvatarButton />
            <UserMenuButtons dataHcName={`${dataHcName}-user-buttons`}>
              {supportLink}
              {logoutButton}
            </UserMenuButtons>
          </>
        )
      }
    />
  );
};
