import React from 'react';
import classNames from 'classnames';

import { Checkbox } from '@hcs/design-system';
import { CardBadge } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { PropertyFieldInfoTooltip } from '@hcs/property-state';
import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import { isCompField } from '@hcs/property-state';
import {
  CompFields,
  CompTypes,
  MeaningfulEventTypes,
  PropertyStateFields,
  PropertyStateType,
  ReportTypes,
} from '@hcs/types';
import {
  CompIdentifier,
  IncludeHcAdjustments,
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
  SubjectSchema,
} from '@hcs/types';
import { formatMoney, formatMoneyPerMonth } from '@hcs/utils';

import {
  PATH_HC_ADJUSTMENT_DATE,
  PATH_HC_ADJUSTMENT_PROPERTY_DETAILS,
} from '../../constants';
import { CompCompareChangeHandler } from '../../features/CompCompare/CompCompare.types';
import {
  useCompDocuments,
  useCompsFarmDocument,
  useReportConfig,
  useSubjectDocument,
  useSubjectValueDocument,
} from '../../hooks';
import { usePropertyStateFieldsSupported } from '../../hooks/usePropertyStateFieldsSupported';
import { useReportValue } from '../../hooks/useReportValue';
import { checkIsHcAdjustment, compKeywordSearchKey } from '../../utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import { SubjectTransactionHistoryLauncher } from '../SubjectTransactionHistory/SubjectTransactionHistoryLauncher';
import { SubjectPropertyCard, SubjectPublicRemarks } from '..';

import { CompCompareRow } from './CompCompareRow';

import styles from './CompCompare.module.css';

const dataHcName = 'comp-compare-subject';
export interface CompCompareSubjectProps {
  reportId: ReportId;
  isAdjusting?: boolean;
  compIdentifiers: CompIdentifier[];
  fields: (PropertyStateFields | CompFields)[];
  compType: CompTypes;
  includeHcAdjustments: IncludeHcAdjustments;
  onChangeAdjustments: CompCompareChangeHandler;
}

const RowLabel = ({
  isAppraisalComps,
  compField,
  reportType,
}: {
  isAppraisalComps: boolean;
  compField: CompFields | PropertyStateFields;
  reportType?: ReportTypes;
}) => {
  let labelDynamic;
  const { label } = isCompField(compField)
    ? COMP_FIELDS_CONFIG[compField]
    : PROPERTY_STATE_FIELD_CONFIGS[compField];
  const valueType =
    reportType === ReportTypes.EffectiveDate ? 'effectivedate' : 'value';
  if (!isCompField(compField)) {
    labelDynamic = PROPERTY_STATE_FIELD_CONFIGS[compField]?.labelDynamic?.({
      valueType,
    });
  }
  const shouldUseLabelDynamic =
    reportType === ReportTypes.EffectiveDate &&
    (compField === PropertyStateFields.currentValue ||
      compField === PropertyStateFields.currentValuePerSqFt);
  const fieldLabel = shouldUseLabelDynamic ? labelDynamic : label;
  return (
    <>
      {isAppraisalComps && compField === CompFields.userTotalDollarAdjustment
        ? 'Appraiser Adjustment'
        : fieldLabel}
      <PropertyFieldInfoTooltip
        theme={{ Trigger: styles.InfoTooltip }}
        field={compField}
      />
    </>
  );
};

const RowContent = ({
  reportId,
  compField,
  isRental,
  subjectSchema,
  includeHcAdjustments,
  onChangeAdjustments,
  isAdjusting,
}: Pick<
  CompCompareSubjectProps,
  'reportId' | 'includeHcAdjustments' | 'onChangeAdjustments' | 'isAdjusting'
> & {
  subjectSchema: SubjectSchema;
  compField: PropertyStateFields | CompFields;
  isRental: boolean;
}) => {
  const { data: reportValue } = useReportValue(reportId, isRental);
  const subjectValueQuery = useSubjectValueDocument(reportId, {
    showRentalValue: isRental,
  });
  const { data: subjectValueDocument } = subjectValueQuery;
  if (isCompField(compField)) {
    const { isHcAdjustmentField, isHcDateAdjustment } =
      checkIsHcAdjustment(compField);
    if (isAdjusting && isHcAdjustmentField) {
      const checked = isHcDateAdjustment
        ? includeHcAdjustments.hcAdjustmentDate
        : includeHcAdjustments.hcAdjustmentPropertyDetails;
      return (
        <Checkbox
          checked={checked === null ? false : checked}
          dataHcName={`${dataHcName}-hpi-adjustment-checkbox`}
          dataHcEventName={
            isHcDateAdjustment
              ? checked
                ? `Removed ${isRental ? 'RPI' : 'HPI'} Adjustment`
                : `Included ${isRental ? 'RPI' : 'HPI'} Adjustment`
              : checked
                ? `Removed ${
                    isRental ? 'Rental ' : ''
                  }Property Details Adjustment`
                : `Included ${
                    isRental ? 'Rental ' : ''
                  }Property Details Adjustment`
          }
          dataHcEventType={MeaningfulEventTypes.Goal}
          label="Include in Report"
          onChange={(value) => {
            if (subjectValueDocument?.documentId) {
              onChangeAdjustments({
                changeArg: {
                  op: 'add',
                  value,
                  path: isHcDateAdjustment
                    ? PATH_HC_ADJUSTMENT_DATE
                    : PATH_HC_ADJUSTMENT_PROPERTY_DETAILS,
                },
                documentId: subjectValueDocument?.documentId,
                type: 'includeHcAdjustments',
              });
            }
          }}
        />
      );
    } else {
      return null;
    }
  } else {
    const { Display } = PROPERTY_STATE_FIELD_CONFIGS[compField];
    return (
      ((compField === PropertyStateFields.currentValue ||
        compField === PropertyStateFields.currentValueRental) &&
        reportValue?.selectedValue && (
          <Tooltip
            dataHcName={`${dataHcName}-current-value-tooltip`}
            trigger={
              isRental
                ? formatMoneyPerMonth(reportValue.selectedValue?.value.value)
                : formatMoney(reportValue.selectedValue?.value.value)
            }
            label={reportValue.label}
          />
        )) || (
        <Display
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: subjectSchema.propertyState,
          }}
        />
      )
    );
  }
};

export const CompCompareSubject = ({
  reportId,
  isAdjusting,
  compType,
  fields,
  compIdentifiers,
  includeHcAdjustments,
  onChangeAdjustments,
}: CompCompareSubjectProps) => {
  const isRental = compType === CompTypes.Rental;
  const { data: reportConfig } = useReportConfig(reportId);
  const reportType = reportConfig?.reportType;
  const propertyFields = usePropertyStateFieldsSupported(reportId, fields);
  const experienceFlag = useExperienceFlag('PEXP_REDESIGN');
  const subjectValueQuery = useSubjectValueDocument(reportId, {
    showRentalValue: isRental,
  });
  const { data: subjectValueDocument } = subjectValueQuery;
  const subjectQuery = useSubjectDocument(reportId);
  const farmQuery = useCompsFarmDocument(reportId, compType);
  const compsQuery = useCompDocuments(reportId, compType);

  if (
    subjectQuery.isInitialLoading ||
    compsQuery.isInitialLoading ||
    farmQuery.isInitialLoading ||
    subjectValueQuery.isInitialLoading
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  if (!subjectValueDocument?.data) {
    return (
      <NullState
        dataHcName={`${dataHcName}-error`}
        title="Error Retrieving Report Data."
      />
    );
  }

  const subjectSchema = subjectQuery.data?.data;
  if (!subjectSchema || !compsQuery.data || !farmQuery.data) {
    return <div data-hc-name={`${dataHcName}-error`}>An error occurred</div>;
  }

  const isMultiCompare = compIdentifiers.length > 1;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompareCol, styles.subjectCol)}
    >
      <div
        className={classNames(styles.CardCell, {
          [styles.cardCellLegacy]: experienceFlag,
          [styles.cardCellLegacyAdjusting]: experienceFlag && isAdjusting,
        })}
      >
        {isMultiCompare && <div className={styles.ShadowVert} />}
        <SubjectPropertyCard
          reportId={reportId}
          className={styles.SubjectPropertyCard}
          badges={
            <>
              <CardBadge dataHcName={`${dataHcName}-badge-subject`} secondary>
                Subject Property
              </CardBadge>
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={
                  isRental
                    ? [
                        ReportFeatures.SubjectTransactionHistory,
                        ReportFeatures.RentalCompsTransactionHistory,
                      ]
                    : [
                        ReportFeatures.SubjectTransactionHistory,
                        ReportFeatures.CompsTransactionHistory,
                      ]
                }
                reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
              >
                <SubjectTransactionHistoryLauncher
                  reportId={reportId}
                  trigger={
                    <CardBadge
                      dataHcName={`${dataHcName}-badge-subject-transaction-history`}
                    >
                      Transaction History
                    </CardBadge>
                  }
                />
              </ReportFeaturesSupported>
            </>
          }
          noDetails
          flat
        />
      </div>
      <div className={styles.Fields}>
        {propertyFields.map((compField) => {
          const { isHcAdjustmentField, isHcDateAdjustment } =
            checkIsHcAdjustment(compField);
          const isSelectedAdjustmentField =
            (isHcDateAdjustment && includeHcAdjustments.hcAdjustmentDate) ||
            (isHcAdjustmentField &&
              !isHcDateAdjustment &&
              includeHcAdjustments.hcAdjustmentPropertyDetails);
          const classNameAdjustment = classNames({
            [styles.selectedAdjustment]: isSelectedAdjustmentField,
            [styles.adjustPaddingForBg]: isSelectedAdjustmentField,
          });
          return (
            <div
              className={classNames(styles.LabelRow, classNameAdjustment)}
              key={`row-subject-${compField}`}
            >
              <div
                data-hc-name={`${dataHcName}-row-label`}
                className={classNames(styles.Label, styles.Row)}
              >
                <RowLabel
                  reportType={reportType}
                  compField={compField}
                  isAppraisalComps={
                    compIdentifiers[0]?.type === 'appraisalComp'
                  }
                />
              </div>
              <CompCompareRow>
                <RowContent
                  reportId={reportId}
                  compField={compField}
                  isRental={isRental}
                  isAdjusting={!!isAdjusting}
                  subjectSchema={subjectSchema}
                  onChangeAdjustments={onChangeAdjustments}
                  includeHcAdjustments={includeHcAdjustments}
                />
              </CompCompareRow>
            </div>
          );
        })}
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? [ReportFeatures.RentalCompsListingRemarks]
              : [ReportFeatures.CompsListingRemarks]
          }
        >
          <div className={styles.LabelRow} key={`row-subject-remarks`}>
            <div
              className={classNames(styles.Label, styles.Row)}
              data-hc-name={`${dataHcName}-row-label`}
            >
              Listing Remarks
            </div>
            <CompCompareRow>
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[ReportFeatures.SubjectListingRemarks]}
              >
                <SubjectPublicRemarks
                  reportId={reportId}
                  keywordSearchKey={compKeywordSearchKey(compType)}
                  isRental={isRental}
                />
              </ReportFeaturesSupported>
            </CompCompareRow>
          </div>
        </ReportFeaturesSupported>
      </div>
      <div className={styles.ColumnActions} />
    </div>
  );
};
