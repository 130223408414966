import { useFeatureFlagsForUser } from '@hcs/huell';
import { LEGACY_PEXP_UI_URL } from '@hcs/urls';

export const LegacyPexpRedirect = () => {
  const { isSuccess: featureFlagsLoaded } = useFeatureFlagsForUser();
  if (featureFlagsLoaded) {
    window.open(LEGACY_PEXP_UI_URL, '_self');
    return <span />;
  } else {
    return <span />;
  }
};
