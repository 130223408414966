import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { default as LazyRender, forceCheck } from 'react-lazyload';
import classNames from 'classnames';

import { reactKeyFromCerberusInput } from '@hcs/cerberus';
import { Disclosure } from '@hcs/design-system';
import { MlsAttribution } from '@hcs/mls-lookup';
import { PropertyStateCerberusInput } from '@hcs/types';

import { PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE } from '../../components';
import {
  PropertyStateCard,
  PropertyStateCardPropsCreator,
} from '../../features/PropertyStateCard/PropertyStateCard';

import styles from './PropertyStateCardList.module.css';

export interface PropertyStateCardListProps {
  dataHcName: string;
  propertyStateCerberusInputs:
    | (PropertyStateCerberusInput | null)[]
    | undefined;
  className?: string;
  scrollContainerId?: string;
  heightCard?: number;
  children?: ReactNode;
  onScrollToBottom?: VoidFunction;
  onScroll?: (isBottom: boolean) => void;
  propertyStateCardProps?: PropertyStateCardPropsCreator;
}

const HEIGHT_CARD = 234;
const HEIGHT_FIELD = 20;
export const PropertyStateCardList = ({
  dataHcName,
  propertyStateCerberusInputs,
  heightCard,
  children,
  className,
  onScrollToBottom,
  onScroll,
  propertyStateCardProps,
  scrollContainerId: scrollContainerIdProp,
}: PropertyStateCardListProps) => {
  const [mlsIds, setMlsIds] = useState<number[]>([]);
  const container = useRef<HTMLDivElement>(null);
  const complianceFooter = useRef<HTMLDivElement>(null);
  const isScrolledToBottom = () => {
    if (container.current) {
      const { scrollTop, scrollHeight, clientHeight } = container.current;

      // user scrolled to within 250px of bottom of cards container
      if (
        scrollTop + clientHeight >=
        scrollHeight - (complianceFooter.current?.clientHeight || 0) - 250
      ) {
        return true;
      }
    }

    return false;
  };
  const handleScroll = () => {
    const isBottom = isScrolledToBottom();
    if (isBottom) {
      onScrollToBottom?.();
    }
    onScroll?.(isBottom);
  };
  useEffect(() => {
    // Update lazy render if the inputs change
    forceCheck();
  }, [propertyStateCerberusInputs]);
  const scrollContainerId =
    scrollContainerIdProp || `property-state-card-list-${dataHcName}`;
  return (
    <div
      id={scrollContainerId}
      onScroll={handleScroll}
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyStateCardList, className)}
      ref={container}
    >
      {children}
      {propertyStateCerberusInputs?.map((propertyStateCerberusInput, i) => {
        const key = `${reactKeyFromCerberusInput(
          propertyStateCerberusInput?.cerberusInput,
        )}-${i}`;
        if (!propertyStateCerberusInput?.cerberusInput?.hcAddressId) {
          return <Fragment key={key}>{null}</Fragment>;
        }
        const cardProps =
          typeof propertyStateCardProps === 'function'
            ? propertyStateCardProps(propertyStateCerberusInput)
            : propertyStateCardProps;
        const heightFields =
          (cardProps?.fields?.length ||
            PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE.length) * HEIGHT_FIELD;
        return (
          <LazyRender
            key={key}
            // Width is needed or else all placeholders are rendered in a single row with 0 width
            style={{ width: '250px' }}
            height={heightCard || heightFields + HEIGHT_CARD}
            scrollContainer={`#${scrollContainerId}`}
            unmountIfInvisible
            overflow
            scroll
            resize
            data-hc-name="lazy-render-card"
          >
            <PropertyStateCard
              {...cardProps}
              onDataReady={(psArgs) => {
                const hcMlsId =
                  psArgs.propertyState?.complexFieldsSale?.currentHcMlsId;
                if (hcMlsId && !mlsIds.find((m) => m === hcMlsId)) {
                  mlsIds.push(hcMlsId);
                  setMlsIds(mlsIds);
                }
                cardProps?.onDataReady?.(psArgs);
              }}
              propertyStateCerberusInput={propertyStateCerberusInput}
            />
          </LazyRender>
        );
      })}
      <div ref={complianceFooter}>
        <Disclosure dataHcName="disclosure" includeAVMSection={false} />
        {mlsIds.map((mlsId) => (
          <MlsAttribution
            key={`${dataHcName}-mls-attribution-${mlsId}`}
            mlsId={mlsId}
            // className={styles.MlsAttributionRow}
          />
        ))}
      </div>
    </div>
  );
};
