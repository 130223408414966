import { ReactNode } from 'react';
import classNames from 'classnames';

import {
  Anchor,
  Button,
  Card,
  ContactUsIcon,
  InfoTooltip,
  Skeleton,
} from '@hcs/design-system';
import { SelfServePlan, SelfServicePlanTier } from '@hcs/types';
import { CONTACT_SALES_URL, TERMS_OF_USE_URL } from '@hcs/urls';
import { formatMoney, formatNumber } from '@hcs/utils';

import {
  SELF_SERVICE_FEATURES_MAPPING,
  SELF_SERVICE_TIERS_MAPPING,
} from '../../configs';
import { useSelfServicePlan } from '../../hooks/useSelfServicePlan';

import styles from './PlanCard.module.css';
export interface PlanCardProps {
  planId?: string;
  tierKey?: SelfServicePlanTier;
  cta?: ReactNode;
  hideCta?: boolean;
  trials?: boolean;
  highlightCta?: boolean;
  isLoading?: boolean;
  className?: string;
  hideEmptyPromotion?: boolean;
  onSelectPlan: (plan: SelfServePlan) => void;
}

export const PlanCard = ({
  planId,
  className,
  trials,
  tierKey: tierKeyProp,
  highlightCta,
  hideCta,
  onSelectPlan,
  isLoading,
  cta,
  hideEmptyPromotion,
}: PlanCardProps) => {
  const { data: plan } = useSelfServicePlan(planId);
  const tierKey = (plan?.name || tierKeyProp) as SelfServicePlanTier;
  const tierConfig = tierKey && SELF_SERVICE_TIERS_MAPPING[tierKey];
  const dataHcName = `plan-card-${plan?.term}-${tierKey}`;
  const showTrial = trials !== false && !!plan?.trial;
  if (tierKey !== 'Enterprise' && !plan) return null;
  return (
    <div
      className={classNames(styles.Plan, {
        [styles.hasPromotion]: showTrial,
      })}
    >
      {(!hideEmptyPromotion || showTrial) && (
        <div className={styles.PromotionCell}>
          {showTrial && (
            <>
              Free {formatNumber(plan?.trial_length_days)} Day Trial
              <InfoTooltip
                dataHcName="free-trial-tooltip"
                color="neutral-light-10"
                description={
                  <>
                    Free access to the platform for 14 days. After the 14 day
                    period is completed, you will automatically be enrolled in
                    the Pro plan until you cancel. Usage limits will still apply
                    during your free trial and you may be charged if you exceed
                    these limits.{' '}
                    <Anchor
                      dataHcName="free-trial-terms-link"
                      target="_blank"
                      href={TERMS_OF_USE_URL}
                      className={styles.TermsLink}
                    >
                      See Terms
                    </Anchor>{' '}
                    for more details.
                  </>
                }
              />
            </>
          )}
        </div>
      )}
      <Card
        smallPadding
        dataHcName={`${dataHcName}-card`}
        className={classNames(styles.PlanCard, className)}
        theme={{ Content: styles.PlanContent }}
      >
        <div className={styles.PlanName}>
          {isLoading ? (
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
          ) : (
            <>
              {tierConfig?.name}
              {showTrial && <div className={styles.Chip}>Free trial</div>}
            </>
          )}
        </div>
        <div className={styles.Price}>
          {isLoading ? (
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
          ) : typeof plan?.price === 'number' ? (
            <>
              {formatMoney(plan.price)}
              <span className={styles.BillingPeriod}>
                {'/'}
                {plan.term === 'Year' ? 'year' : 'month'}
              </span>
            </>
          ) : (
            <ContactUsIcon size="xl" />
          )}
        </div>
        {hideCta ? null : isLoading ? (
          <div style={{ position: 'relative', height: '40px' }}>
            <Skeleton
              dataHcName={`${dataHcName}-skeleton`}
              type="button"
              style={{
                flex: '0 0 40px',
                height: '40px',
                borderRadius: '40px',
                width: '100%',
              }}
            />
          </div>
        ) : cta ? (
          cta
        ) : plan ? (
          <Button
            onClick={() => onSelectPlan(plan)}
            dataHcName={`${dataHcName}-cta`}
            className={styles.Cta}
            primary={showTrial || highlightCta}
            secondary={!showTrial && !highlightCta}
            label={showTrial ? 'Start Free Trial' : 'Buy Now'}
          />
        ) : (
          <a href={CONTACT_SALES_URL}>
            <Button
              dataHcName={`${dataHcName}-cta`}
              className={styles.Cta}
              label="Contact Us"
              secondary
            />
          </a>
        )}
        <div>{tierConfig.description}</div>
        <div className={styles.Divider} />
        <ul className={styles.Features}>
          {isLoading ? (
            <>
              <li>
                <Skeleton dataHcName={`${dataHcName}-skeleton`} />
              </li>
              <li>
                <Skeleton dataHcName={`${dataHcName}-skeleton`} />
              </li>
              <li>
                <Skeleton dataHcName={`${dataHcName}-skeleton`} />
              </li>
              <li>
                <Skeleton dataHcName={`${dataHcName}-skeleton`} />
              </li>
            </>
          ) : (
            tierConfig?.features.map((k) => {
              const featureKey = typeof k === 'string' ? k : k.key;
              const featureValue = typeof k === 'string' ? 0 : k.value;
              const featureConfig = SELF_SERVICE_FEATURES_MAPPING[featureKey];
              if (!featureConfig) return null;
              const { Label, Icon } = featureConfig;
              return (
                <li key={`${tierKey}-feature-${featureKey}`}>
                  <Icon color="neutral-dark-30" />
                  <span>
                    {typeof Label === 'function' ? (
                      <Label value={featureValue} />
                    ) : (
                      Label
                    )}
                  </span>
                </li>
              );
            })
          )}
        </ul>
      </Card>
    </div>
  );
};
