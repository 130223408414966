import React, { useEffect, useState } from 'react';

import { DropdownMenu } from '@hcs/design-system';
import {
  OrderItem,
  OrderItemGroup,
  OrderItemStatus,
  OrderItemsUpdateParams,
} from '@hcs/types';

import { ORDER_STATUSES } from '../../constants';
import { CancelOrderDialog } from '../../features/CancelOrderDialog';
import { useCancelOrderItem } from '../../hooks/useCancelOrderItem';
import { useDownloadAllOrderItemData } from '../../hooks/useDownloadAllOrderItemData';
import { useOrder } from '../../hooks/useOrder';
import { useOrderItemPdfDownload } from '../../hooks/useOrderItemPdfDownload';
import { useUpdateOrderItems } from '../../hooks/useUpdateOrderItems';
import { getFullAddressFromOrderItem } from '../../utils/orderItem.utils';
import { OrderItemDetailsDrawer } from '../OrderItemDetailsDrawer';

import styles from './OrderItemsActions.module.css';

interface Props {
  className?: string;
  orderItem: OrderItem;
  orderId: number;
}

const dataHcName = 'in-order-table-actions';

export const OrderItemsActions = ({ className, orderItem, orderId }: Props) => {
  const { data: order } = useOrder(orderId);
  const { mutate: updateOrderItems } = useUpdateOrderItems();
  const { mutate: downloadAllOrderItemData } = useDownloadAllOrderItemData();

  const {
    data: pdfDownloadData,
    mutate: orderItemPdfDownload,
    isSuccess: isSuccessPdfDownloadData,
  } = useOrderItemPdfDownload();

  const {
    mutate: cancelOrderItem,
    isLoading: isLoadingCancelOrderItem,
    isSuccess: isSuccessCancelOrderItem,
    isError: isErrorCancelOrderItem,
    error: errorCancelOrderItem,
  } = useCancelOrderItem();

  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [showOrderItemDetailsDrawer, setShowOrderItemDetailsDrawer] =
    useState<boolean>(false);

  const fullAddress = getFullAddressFromOrderItem(orderItem);
  const fullAddressSlug = fullAddress.replace(/,\s|\s/gm, '-');

  useEffect(() => {
    // To open a report pdf in another tab after successfully fetching the report
    if (isSuccessPdfDownloadData && pdfDownloadData?.url)
      window.open(pdfDownloadData.url);
  }, [pdfDownloadData?.url, isSuccessPdfDownloadData]);

  const onRemoveItem = () => {
    const payload: OrderItemsUpdateParams = {
      orderId,
      updateType: 'REMOVE_ITEMS_FROM_ORDER',
      orderItemGroup: OrderItemGroup.InOrder,
      orderItems: [
        {
          id: orderItem.id,
          processItem: false,
        },
      ],
    };

    updateOrderItems(payload);
  };

  const getOrderItemsActionOptions = () => {
    const seeDetailsAction = {
      key: `see-details-${orderItem}`,
      dataHcName: 'see-details-action',
      label: 'See Details',
      onClick: () => setShowOrderItemDetailsDrawer(true),
    };

    const orderItemsActions = [seeDetailsAction];
    const availableDownloadables = orderItem.availableDownloadables;

    for (const ele of availableDownloadables) {
      const downloadAction = {
        key: ele.key,
        dataHcName: `download-${ele.key}-action`,
        label: 'View Report',
        onClick: () => {
          orderItemPdfDownload({
            orderItemId: orderItem.id,
            orderId,
            pdfType: ele.key,
          });
        },
      };
      orderItemsActions.push(downloadAction);
    }

    if (
      order?.status === ORDER_STATUSES.COMPLETE ||
      orderItem.status === OrderItemStatus.Complete
    ) {
      const downloadAction = {
        key: `download-all-data-action-${orderItem}`,
        dataHcName: 'download-all-data-action',
        label: 'Download All Data',
        onClick: () => {
          const fileName = `${fullAddressSlug}.zip`;
          downloadAllOrderItemData({
            orderId,
            fileName: fileName,
          });
        },
      };
      orderItemsActions.push(downloadAction);
    }

    if (order?.status !== ORDER_STATUSES.CLIENT_REVIEW && orderItem.canCancel) {
      const cancelAction = {
        key: `cancel-action-${orderItem}`,
        dataHcName: 'cancel-action',
        label: 'Cancel Address',
        onClick: () => setShowCancelDialog(true),
      };
      orderItemsActions.push(cancelAction);
    }

    if (order?.status === ORDER_STATUSES.CLIENT_REVIEW) {
      const removeAddressAction = {
        key: `remove-action-${orderItem}`,
        dataHcName: 'remove-action',
        label: 'Remove Address',
        onClick: () => onRemoveItem(),
      };
      orderItemsActions.push(removeAddressAction);
    }

    return orderItemsActions;
  };

  const orderItemsActionsOptions = getOrderItemsActionOptions();
  const cancelDialogHeaderText = `Canceling Address: ${orderItem.address}`;

  return (
    <>
      <div data-hc-name={dataHcName} className={className}>
        <DropdownMenu
          theme={{
            DropdownMenuContainer: styles.DropdownMenuContainer,
            OptionsContainer: styles.OptionsContainer,
          }}
          options={orderItemsActionsOptions}
          dataHcName={`${dataHcName}-dropdown`}
          title="Actions"
        />
      </div>
      {showOrderItemDetailsDrawer && (
        <OrderItemDetailsDrawer
          orderId={orderId}
          orderItem={orderItem}
          hideModal={() => setShowOrderItemDetailsDrawer(false)}
        />
      )}
      {showCancelDialog && (
        <CancelOrderDialog
          isOrderItem
          cancelOrder={() =>
            cancelOrderItem({ orderId, orderItemId: orderItem.id.toString() })
          }
          isLoadingCancelOrder={isLoadingCancelOrderItem}
          isSuccessCancelOrder={isSuccessCancelOrderItem}
          headerText={cancelDialogHeaderText}
          hideModal={() => setShowCancelDialog(false)}
          notifications={[
            {
              dataHcName: 'cancel-order-item-error',
              show: isErrorCancelOrderItem,
              type: 'error',
              title:
                errorCancelOrderItem?.message || 'Failed to cancel order item',
            },
          ]}
        />
      )}
    </>
  );
};
