import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useUserIsLegacySelfService } from '@hcs/authn';
import { Button, LoadingSpinner, OverlayPage } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { MeaningfulEventTypes, SelfServePlan } from '@hcs/types';

import {
  useSelfServeRecommendedPlan,
  useTransitionLegacySelfServeOrg,
} from '../../hooks';
import { SelfServicePlanSelectAndCompare } from '../SelfServicePlanSelectAndCompare';
import { SignUpCheckout } from '../SignUp';

import styles from './LegacySsTransition.module.css';
const TRANSITION_QUERY_PARAM = 'ss-plan-transition';
const dataHcName = 'legacy-ss-opt-in';
interface Props {
  trigger?: ReactNode;
  dataHcEventName: string;
  buttonLabel?: ReactNode;
}
export const LegacySsOptInLauncher = ({
  trigger,
  buttonLabel,
  dataHcEventName,
}: Props) => {
  const [searchParams] = useSearchParams();
  const transitionQueryParamSet =
    searchParams.get(TRANSITION_QUERY_PARAM) !== null;
  const { data: userIsLegacySelfService } = useUserIsLegacySelfService();
  const { isLoading, data: planRecommendations } =
    useSelfServeRecommendedPlan();
  const { active, handleClose, handleOpen } = useActiveState({
    activeInitial: transitionQueryParamSet,
  });
  const [selectedPlan, setSelectedPlan] = useState<SelfServePlan | null>(null);
  const [orgHasConverted, setOrgHasConverted] = useState(false);
  const { mutate: transitionToNewSs } = useTransitionLegacySelfServeOrg({
    onSuccess: () => {
      setOrgHasConverted(true);
    },
  });
  if (!userIsLegacySelfService && !orgHasConverted) return null;
  return (
    <>
      {orgHasConverted && selectedPlan && (
        <SignUpCheckout
          planId={selectedPlan.id || ''}
          navigateToSelectPlan={() => {
            setSelectedPlan(null);
          }}
          successUrl={window.location.href}
          cancelUrl={window.location.href}
        />
      )}
      {trigger ? (
        <span onClick={handleOpen} data-hc-name={dataHcName}>
          {trigger}
        </span>
      ) : (
        <Button
          dataHcEventType={MeaningfulEventTypes.Goal}
          dataHcEventName={dataHcEventName}
          className={styles.LauncherButton}
          onClick={handleOpen}
          dataHcName={dataHcName}
        >
          {buttonLabel || 'Select Plan'}
        </Button>
      )}
      <OverlayPage
        active={active}
        headerProps={{
          onBack: handleClose,
          onClose: handleClose,
        }}
        dataHcName={`${dataHcName}-dialog`}
      >
        {isLoading ? (
          <LoadingSpinner
            dataHcName={`${dataHcName}-skeleton`}
            absoluteCenter
          />
        ) : (
          <SelfServicePlanSelectAndCompare
            recommendedPlan={
              planRecommendations?.recommended_plan ||
              planRecommendations?.default_plan
            }
            onSelectPlan={(plan) => {
              setSelectedPlan(plan);
              transitionToNewSs();
            }}
          />
        )}
      </OverlayPage>
    </>
  );
};
