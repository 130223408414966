import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { useClickOutsideComponent, useForwardedRef } from '@hcs/hooks';
import { DateStr } from '@hcs/types';
import { scrollFocusEl } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';

import { DropdownOption } from './DropdownOption';

import styles from './Dropdown.module.css';

export interface DropdownTheme {
  DropdownContainer?: string;
  Dropdown?: string;
  SelectContainer?: string;
  SelectElement?: string;
  ChevronContainer?: string;
  OptionsContainer?: string;
  active?: string;
  selected?: string;
  Option?: string;
  DropdownError?: string;
}

export type DropdownOptionType<OptionValueType> = {
  value: OptionValueType;
  label: ReactNode;
};

export interface DropdownProps<OptionValueType> {
  className?: string;
  dataHcName: string;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  name?: string;
  onBlur?: VoidFunction;
  onSelect: (val: OptionValueType) => void;
  options: DropdownOptionType<OptionValueType>[];
  hideSelectedOption?: boolean;
  showItemCount?: boolean;
  role?: string;
  value: OptionValueType | undefined | null;
  theme?: DropdownTheme;
  onClick?: MouseEventHandler;
}

export type DropdownOptionTypeExtends =
  | string
  | number
  | boolean
  | DateStr
  | null;

export const DropdownInner = <
  OptionValueType extends DropdownOptionTypeExtends,
>(
  props: DropdownProps<OptionValueType>,
  inputRef: React.ForwardedRef<HTMLInputElement>,
) => {
  const {
    className,
    dataHcName,
    disabled,
    onClick,
    error,
    name,
    onBlur,
    onSelect,
    options: optionsProp,
    hideSelectedOption = false,
    placeholder,
    role,
    theme,
    value,
    showItemCount,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const [focusedOption, setFocusedOption] = useState<{
    idx: number;
  } | null>(null);
  const forwardedSelectedRef = useForwardedRef<HTMLInputElement>(inputRef);

  const [selectedValue, setSelectedValue] = useState<
    OptionValueType | null | undefined
  >(value);

  const selectedOption = useMemo(
    () => optionsProp.find((option) => option.value === selectedValue),
    [selectedValue, optionsProp],
  );

  const options = hideSelectedOption
    ? optionsProp?.filter((option) => option.value !== selectedOption?.value)
    : optionsProp;

  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  // new default value prop, update selection option
  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
  }, [value]);

  const scrollFocusToOption = (
    optionIdx: number,
    shouldScroll: boolean,
    shouldFocus: boolean,
  ) => {
    if (!optionRefs.current) return;
    const scrollToOption = optionRefs.current[optionIdx];
    if (!scrollToOption) return;

    scrollFocusEl(
      scrollToOption,
      optionsContainerRef.current,
      shouldScroll,
      shouldFocus,
    );

    if (shouldFocus) {
      setFocusedOption({
        idx: optionIdx,
      });
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleArrowDown = () => {
    // if focused on last item in list, focus on input and scroll to the top of the list
    if (focusedOption?.idx === optionRefs.current.length - 1) {
      forwardedSelectedRef.current?.focus();
      setFocusedOption(null);
      scrollFocusToOption(0, true, false);
      return;
    }

    const nextFocusedOptionIdx =
      focusedOption !== null ? focusedOption.idx + 1 : 0;
    scrollFocusToOption(nextFocusedOptionIdx, nextFocusedOptionIdx === 0, true);
  };

  const handleArrowUp = () => {
    // if no option is focused, focus on and scroll to the last item in list
    if (focusedOption === null) {
      const nextFocusedOptionIdx = optionRefs.current.length - 1;
      scrollFocusToOption(nextFocusedOptionIdx, true, true);
      return;
    }

    // if focused on first item in list, focus goes back to input for accessibility
    if (focusedOption?.idx === 0) {
      forwardedSelectedRef.current?.focus();
      setFocusedOption(null);
      return;
    }

    const nextFocusedOptionIdx = focusedOption.idx - 1;
    scrollFocusToOption(nextFocusedOptionIdx, false, true);
  };

  const activate = (activeStatus: boolean) => {
    if (!focusedOption) {
      optionRefs.current[0]?.focus();
    }
    setIsActive(activeStatus);
  };

  const deActivate = () => {
    setFocusedOption(null);
    setIsActive(false);
  };

  useClickOutsideComponent(containerRef, deActivate);

  const handleSelect = (option: DropdownOptionType<OptionValueType>) => {
    setSelectedValue(option.value);
    deActivate();
    onSelect(option.value);
    if (onBlur) {
      onBlur();
    }
  };

  const keyboardNavigationListener = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      handleArrowDown();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      handleArrowUp();
    } else if (e.key === 'Escape') {
      deActivate();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardNavigationListener);

    return () => {
      document.removeEventListener('keydown', keyboardNavigationListener);
    };
  });

  const dropdownOptionHeight = Math.min(options?.length * 40, 240);

  return (
    <div
      data-hc-name={dataHcName}
      onClick={onClick}
      className={classNames(
        styles.DropdownContainer,
        className,
        theme?.DropdownContainer,
      )}
    >
      <div
        className={classNames(
          {
            [styles.active]: isActive,
            [theme?.active || '']: isActive,
            [styles.dropdownError]: error,
          },
          styles.Dropdown,
          theme?.Dropdown,
        )}
        data-hc-name={`${dataHcName}-key-trigger`}
        ref={containerRef}
      >
        <div
          className={classNames(
            styles.SelectContainer,
            theme?.SelectContainer,
            {
              [styles.active]: isActive,
              [theme?.active || '']: isActive,
              [styles.disabled]: disabled,
              [styles.dropdownError]: error,
            },
          )}
          onClick={() => {
            if (!disabled) activate(!isActive);
          }}
        >
          <div
            className={classNames(styles.SelectElement, theme?.SelectElement)}
            data-hc-name={`${dataHcName}-selected`}
            ref={forwardedSelectedRef}
            title={
              typeof selectedOption?.label === 'string'
                ? selectedOption?.label
                : ''
            }
          >
            <div className={styles.SelectedOptionLabel}>
              {selectedOption?.label || (
                  <span data-hc-name={`${dataHcName}-placeholder`}>
                    {placeholder}
                  </span>
                ) ||
                ''}
            </div>
            {showItemCount && (
              <div className={styles.ItemCount}>{`1 of ${options.length}`}</div>
            )}
            <input
              data-hc-name={`${dataHcName}-input`}
              name={name}
              role={role}
              type="hidden"
              value={
                selectedValue === null
                  ? ''
                  : typeof selectedValue === 'boolean'
                    ? selectedValue.toString()
                    : selectedValue
              }
            />
          </div>
          <div
            className={classNames(
              {
                [styles.disabled]: disabled,
              },
              styles.ChevronContainer,
              theme?.ChevronContainer,
            )}
          >
            <DirectionalChevron
              dataHcName={`${dataHcName}-open-close`}
              direction={isActive ? 'up' : 'down'}
              size="sm"
            />
          </div>
        </div>
        {isActive && (
          <div
            className={classNames(
              styles.OptionsContainer,
              theme?.OptionsContainer,
            )}
            style={{
              minHeight: `${dropdownOptionHeight}px`,
            }}
            data-hc-name={`${dataHcName}-options`}
            ref={optionsContainerRef}
          >
            {options?.map((option, idx) => {
              const focused = idx === focusedOption?.idx;
              const selected = option.value === selectedOption?.value;

              return (
                <DropdownOption
                  dataHcName={`${dataHcName}-option`}
                  focused={focused}
                  handleSelect={() => handleSelect(option)}
                  key={`${idx}-${option.value}`}
                  label={option.label}
                  selected={selected}
                  setRef={(el) => (optionRefs.current[idx] = el)}
                  theme={theme}
                />
              );
            })}
            {options.length === 0 && (
              <div
                className={styles.NoOptions}
                data-hc-name={`${dataHcName}-no-options`}
              >
                No Options
              </div>
            )}
          </div>
        )}
      </div>
      {error ? (
        <div
          className={classNames(styles.Error, theme?.DropdownError)}
          data-hc-name={`${dataHcName}-error`}
        >
          {error}
        </div>
      ) : null}
    </div>
  );
};

export const Dropdown = React.forwardRef(DropdownInner);
