import { CerberusStats, CerberusStatsFields } from '@hcs/types';
import { logWarning } from '@hcs/utils';

import { LISTING_STATS_RENTAL_FIELDS } from '../constants/listingStats.constants';

export const getRentalLabel = (fieldLabel: string): string => {
  return `Rental ${fieldLabel}`;
};

export const logValueUnsupportedWarning = (
  field: CerberusStatsFields,
  cerberusStats: CerberusStats,
) => {
  logWarning(
    `${field} getValue called with unsupported cerberusStatsType: ${cerberusStats?.type}`,
  );
};

export const getIsRentalListingStatsField = (
  field: CerberusStatsFields,
): boolean => {
  return LISTING_STATS_RENTAL_FIELDS.indexOf(field) !== -1;
};
