import { PayloadAction } from '@reduxjs/toolkit';

import { EngagementTrackingMeaningfulEventsSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: EngagementTrackingMeaningfulEventsSliceState = {
  event_section: null,
};
export const engagementTrackingMeaningfulEventsSlice = createHcReduxSlice({
  name: 'meaningfulEvents',
  initialState,
  reducers: {
    setEventSection(
      state,
      {
        payload: { event_section },
      }: PayloadAction<{ event_section: string | null }>,
    ) {
      state.event_section = event_section;
    },
  },
});
