import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { Variables } from 'graphql-request';

import {
  GraphQLAccessTokenClientEhrm,
  GraphQLUnauthenticatedClient,
} from '@hcs/http-clients';
import { CERBERUS_PROXY_ENABLED, CERBERUS_URL } from '@hcs/urls';
import { PULL_REQUEST_ID } from '@hcs/webapps';

export const CerberusClient = CERBERUS_PROXY_ENABLED
  ? GraphQLAccessTokenClientEhrm(CERBERUS_URL, {
      headers: {
        'X-Build': `hcs-nx${PULL_REQUEST_ID ? '-pr' : ''}/${
          import.meta.env.NX_BUILD
        }`,
      },
    })
  : GraphQLUnauthenticatedClient(CERBERUS_URL, {});

export const CerberusApi = {
  fetchQuery: async <Response, V extends Variables>(
    query: TypedDocumentNode<Response, V>,
    variables: V,
  ) => {
    // eslint-disable-next-line
    // @ts-ignore This is is an issue with the library type definitions
    return await CerberusClient.request<Response, V>(query, variables);
  },
  bulkFetchQuery: async <Response, Variables>(
    query: TypedDocumentNode<Response, Variables>,
    variablesArray: Variables[],
  ) => {
    return await CerberusClient.request<Response>(query, {
      extensions: {
        variablesList: variablesArray,
      },
    });
  },
};
