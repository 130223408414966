import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { APP_CONFIG_MARKET_INSIGHTS } from '../../constants';
import { useNavigateToMarketInsights } from '../../hooks/useNavigateToMarketInsights';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './ProductCards.module.css';

interface MIProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

const dataHcName = 'market-insights-explorer-card';

export const MarketInsightsProductCard = ({
  navigateToSSSelectPlan,
}: MIProductCardProps) => {
  const marketInsightsConfigExtended = useAppConfigExtended(
    AppSlugs.MarketInsights,
  );
  const navigateToMpp = useNavigateToMarketInsights();
  const {
    active: marketInsightsActive,
    handleOpen: handleMarketInsightsOpen,
    handleClose: handleMarketInsightsClose,
  } = useActiveState();
  if (!marketInsightsConfigExtended) return null;
  const { hasAccess } = marketInsightsConfigExtended;
  const handleClick = () => {
    if (hasAccess) {
      return navigateToMpp();
    } else {
      handleMarketInsightsOpen();
    }
  };
  return (
    <>
      <ProductCard
        dataHcName={dataHcName}
        theme={{
          ProductCard: styles.ProductCard,
        }}
        productName={APP_CONFIG_MARKET_INSIGHTS.name}
        productDescription={APP_CONFIG_MARKET_INSIGHTS.descriptionLong}
        hasAccess={!!hasAccess}
        Icon={APP_CONFIG_MARKET_INSIGHTS.Icon}
        onClick={handleClick}
      />
      <ProductPreviewDialog
        appConfigExtended={marketInsightsConfigExtended}
        active={marketInsightsActive}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleMarketInsightsClose}
      />
    </>
  );
};
