import { CompTypes } from '@hcs/types';
import {
  CompDocument,
  DocumentRoles,
  RentalCompDocument,
  ReportId,
} from '@hcs/types';

import { useDocumentRole } from '.';

export const useCompDocuments = (reportId: ReportId, compType: CompTypes) => {
  return useDocumentRole<CompDocument | RentalCompDocument>(
    reportId,
    compType === CompTypes.Rental
      ? DocumentRoles.RentalComp
      : DocumentRoles.Comp,
    {
      includeUserPropertyAdjustments: true,
    },
  );
};
