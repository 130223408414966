import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useFixedPortalElement } from '@hcs/hooks';
import { PhotoProp } from '@hcs/types';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { CloseIcon } from '../../../../svgs/icons/navigation';

import styles from './PhotoViewer.module.css';

export interface PhotoViewerProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  active?: boolean;
  onClose: VoidFunction;
  photos: PhotoProp[];
  initialIndex?: number | null;
}
export const PhotoViewer = ({
  active,
  dataHcName,
  className,
  initialIndex,
  onClose,
  photos,
}: PhotoViewerProps) => {
  const [activePhotoIndex, setActivePhotoIndex] = useState<number>(
    initialIndex || 0,
  );
  const portalElm = useFixedPortalElement();
  // Reset initialIndex when active state changes
  useEffect(() => {
    setActivePhotoIndex(initialIndex || 0);
  }, [active]);

  const onHandleNext = () => {
    setActivePhotoIndex((currPhoto) => {
      if (currPhoto === photos.length - 1) {
        return 0;
      } else {
        return currPhoto + 1;
      }
    });
  };

  const onHandlePrev = () => {
    setActivePhotoIndex((currPhoto) => {
      if (currPhoto === 0) {
        return photos.length - 1;
      } else {
        return currPhoto - 1;
      }
    });
  };

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
      if (e.key === 'ArrowRight') onHandleNext();
      if (e.key === 'ArrowLeft') onHandlePrev();
    },
    [onClose, photos],
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  const activePhoto = photos[activePhotoIndex];
  if (!portalElm || activePhoto == null) return null;
  return (
    <>
      {createPortal(
        active && (
          <div
            data-hc-name={`${dataHcName}-wrapper`}
            className={styles.PhotoViewerWrapper}
          >
            <div
              data-hc-name={`${dataHcName}-wrapper-background`}
              className={styles.PhotoViewerBackground}
            />
            <div
              data-hc-name={dataHcName}
              className={classNames(styles.PhotoViewer, className)}
            >
              <div className={styles.PhotoInfoContainer}>
                <div
                  data-hc-name={`${dataHcName}-close-button`}
                  className={classNames(
                    styles.PhotoInfoContainerText,
                    styles.Close,
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                >
                  <CloseIcon
                    dataHcName={`${dataHcName}-close-button-icon`}
                    size="sm"
                  />
                  <span data-hc-name={`${dataHcName}-close-button-text`}>
                    Close
                  </span>
                </div>
                <div
                  data-hc-name={`${dataHcName}-center-label`}
                  className={classNames(
                    styles.PhotoInfoContainerText,
                    styles.CenterText,
                  )}
                >
                  {activePhoto.metadata}
                </div>
                <span
                  data-hc-name={`${dataHcName}-photo-count`}
                  className={styles.PhotoInfoContainerText}
                >
                  {activePhotoIndex + 1} / {photos.length}
                </span>
              </div>
              <div className={styles.InteractivePhotoContainer}>
                <div
                  data-hc-name={`${dataHcName}-previous-button`}
                  className={styles.PhotoButton}
                  onClick={onHandlePrev}
                >
                  <DirectionalChevron
                    dataHcName={`${dataHcName}-previous-icon`}
                    className={styles.Icon}
                    size={20}
                    direction={'left'}
                  />
                </div>
                <div
                  data-hc-name={`${dataHcName}-image-container`}
                  className={styles.PhotoImageContainer}
                >
                  <img
                    alt="active"
                    data-hc-name={`${dataHcName}-image`}
                    src={activePhoto?.photo?.url || ''}
                  />
                </div>
                <div
                  data-hc-name={`${dataHcName}-next-button`}
                  className={classNames(styles.PhotoButton, styles.next)}
                  onClick={onHandleNext}
                >
                  <DirectionalChevron
                    dataHcName={`${dataHcName}-next-icon`}
                    className={styles.Icon}
                    direction={'right'}
                  />
                </div>
              </div>
            </div>
          </div>
        ),
        portalElm,
      )}
    </>
  );
};
