import React from 'react';

import { useAccount } from '@hcs/authn';
import { useCapabilityCheck } from '@hcs/authz';
import { SubNav, SubNavItemConfig } from '@hcs/design-system';
import { VIEW_PATHS_ORG_ADMIN } from '@hcs/hc-products';
import { useOmSftpAccess } from '@hcs/order-manager';
import { useOrgIsSelfServe } from '@hcs/self-serve-info';

const dataHcName = 'organization-left-nav';
export const OrgAdminNav = () => {
  const { data: account } = useAccount();
  const { data: hasOMAccess } = useOmSftpAccess();
  const { data: isSelfServiceOrg } = useOrgIsSelfServe();
  const { data: hasUsageQueriesAccess } =
    useCapabilityCheck('org-usage-queries');
  const { data: canManageSso } = useCapabilityCheck('org-sso-config');
  const { data: hasViewBillingAccess } = useCapabilityCheck('view-org-billing');
  const subNavItemsConfig: (SubNavItemConfig | false | undefined | null)[] = [
    {
      label: 'Team',
      dataHcName: 'team-header',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USERS,
      children: [
        {
          label: 'Users',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USERS,
          dataHcName: 'users',
        },
        canManageSso && {
          label: 'Single Sign On',
          dataHcName: 'single-sign-on',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_SINGLE_SIGN_ON,
        },
      ],
    },

    {
      label: 'Billing & Usage',
      dataHcName: 'account-header',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE,
      children: [
        isSelfServiceOrg && {
          label: 'Manage Account',
          navigateTo: VIEW_PATHS_ORG_ADMIN.MANAGE_BILLING,
          dataHcName: 'stripe',
        },
        {
          label: 'Usage',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE,
          dataHcName: 'usage',
        },
        hasUsageQueriesAccess && {
          label: 'Usage Queries',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_USAGE_QUERIES,
          dataHcName: 'usage-queries',
        },
        !!(account?.organization.hasM3terAccount && hasViewBillingAccess) && {
          label: 'Billing',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_BILLING,
          dataHcName: 'billing',
        },
      ],
    },

    {
      label: 'Application Preferences',
      dataHcName: 'application-preferences',
      navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES,
      children: [
        {
          label: 'Property Explorer',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_REPORT_PREFERENCES,
          dataHcName: 'report-preferences',
        },
        hasOMAccess && {
          label: 'Order Manager',
          navigateTo: VIEW_PATHS_ORG_ADMIN.ORG_OM_SFTP,
          dataHcName: 'sftp',
        },
      ],
    },
  ];

  return (
    <SubNav
      dataHcName={`${dataHcName}-sub-nav`}
      subNavItemsConfig={subNavItemsConfig}
    />
  );
};
