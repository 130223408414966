import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { SCROLL_ID } from '@hcs/webapps';

import styles from './LayoutBannerMobile.module.css';

export interface LayoutBannerMobileProps {
  children?: ReactNode;
  className?: string;
  title?: ReactNode;
}
export const LayoutBannerMobile = ({
  className,
  title,
  children
}: LayoutBannerMobileProps) => {
  return (
    <div
      id={SCROLL_ID}
      className={classNames(styles.LayoutBannerMobile, className)}
    >
      <div className={styles.Content}>
        {children}
        {title && (
            <div className={styles.Title}>
              {title}
            </div>
        )}
        {<Outlet />}
      </div>
    </div>
  );
};
