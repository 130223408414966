import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { CompFields, CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { capitalizeFirstLetter, trimWithEllipsis } from '@hcs/utils';

import { useCompsFiltersDocument } from '../../../hooks';
import { CompFilterSimilarity } from '../../CompFiltersDeprecated/CompFilterSimilarity';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = CompFields.similarity;
const fieldConfig = COMP_FIELDS_CONFIG[COMP_FIELD];
const dataHcName = 'comp-filter-button-similarity';

export const CompFilterButtonSimilarity = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        filterValue?.absoluteValue
          ? trimWithEllipsis(
              filterValue.absoluteValue.map(capitalizeFirstLetter).join(', '),
              30,
            )
          : fieldConfig.labelShort
      }
      className={className}
      compField={COMP_FIELD}
      content={<CompFilterSimilarity reportId={reportId} compType={compType} />}
      active={active}
    />
  );
};
