import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

import { CircleButton } from '@hcs/design-system';
import { SavedFiltersIcon, UnselectedFiltersIcon } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useAppliedSavedCompFilterSet } from '../../hooks';
import { SavedCompFilterSetFormLauncher } from '../SavedCompFilterSetForm';

import styles from './CompSavedFilterSetsButton.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onSuccess?: () => void;
}
const dataHcName = 'comp-saved-filter-sets-button';
export const CompSavedFilterSetsButton = ({
  reportId,
  compType,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onSuccess,
}: Props) => {
  const appliedSavedFilterSetQuery = useAppliedSavedCompFilterSet(
    reportId,
    compType,
  );
  if (appliedSavedFilterSetQuery.data.appliedFilterSetId) {
    return (
      <CircleButton
        dataHcName={dataHcName}
        className={classNames(styles.CompSavedFilterSetsButton, className)}
        onClick={onClick}
      >
        <SavedFiltersIcon color="primary-10" size={18} />
      </CircleButton>
    );
  }
  return (
    <SavedCompFilterSetFormLauncher
      reportId={reportId}
      compType={compType}
      trigger={
        <CircleButton
          dataHcName={dataHcName}
          className={classNames(styles.CompSavedFilterSetsButton, className)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <UnselectedFiltersIcon color="primary-10" size={18} />
        </CircleButton>
      }
      onClickTrigger={onClick}
      onSuccess={onSuccess}
    />
  );
};
