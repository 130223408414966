import {
  OranizationSubscriptionType,
  OrgType,
  SelfServePlan,
  SelfServeSubscriptionTypes,
} from '@hcs/types';

export const getPlanSubscriptionType = (
  plan: SelfServePlan,
): SelfServeSubscriptionTypes => `${plan.name}-${plan.term}`;

export const getOrgSubscriptionType = (
  type: OrgType | undefined,
  plan?: SelfServePlan,
): OranizationSubscriptionType | undefined =>
  plan
    ? getPlanSubscriptionType(plan)
    : type === 'legacy-ss'
      ? 'self-service'
      : type;
