import { useSyncExternalStore } from 'react';
const useVisibilityChangeSubscribe = (callback: (ev: Event) => void) => {
  document.addEventListener('visibilitychange', callback);

  return () => {
    document.removeEventListener('visibilitychange', callback);
  };
};

const getVisibilityChangeSnapshot = () => {
  return document.visibilityState;
};

export function useIsPageVisible() {
  const visibilityState = useSyncExternalStore(
    useVisibilityChangeSubscribe,
    getVisibilityChangeSnapshot,
  );

  return visibilityState === 'visible';
}
