import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { ReportId } from '@hcs/types';

import { ReportApi } from '../api';

export const useRefreshReportData = (
  options?: UseMutationOptions<void, unknown, number | string>,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const refreshReportDataMutation = useMutation(
    async (reportId: ReportId) => {
      return await ReportApi.refreshReportData(reportId);
    },
    {
      ...options,
      onError: (error, variables, context) => {
        toastOpen({
          type: 'loading-failure',
          title: 'Failed to Refresh Report',
        });
        options?.onError?.(error, variables, context);
      },
    },
  );
  return refreshReportDataMutation;
};
