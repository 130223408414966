import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/authn';
import { HuellApi } from '@hcs/huell';
import { ItemObjInShoppingCart } from '@hcs/types';
import { DexpEndpoint } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useDexpEndpoints } from '../hooks/useDexpEndpoints';

export interface DexpTemplateForUser {
  templateId: string;
  name: string;
  endpointsData: Record<DexpEndpoint['id'], ItemObjInShoppingCart>;
}
export type DexpTemplatesForUser =
  | {
      [key: string]: DexpTemplateForUser;
    }
  | undefined
  | null;

export const QUERY_KEY_DEXP_TEMPLATES_USER = 'QUERY_KEY_DEXP_TEMPLATES_USER';
export const useDexpTemplatesForUser = () => {
  const { data: isLoggedIn } = useIsLoggedIn();

  const huellQuery = useQuery(
    [QUERY_KEY_DEXP_TEMPLATES_USER],
    HuellApi.fetchDexpTemplatesForUser,
    { enabled: !!isLoggedIn },
  );
  const endpointsQuery = useDexpEndpoints();
  const combinedQueryResult = combineUseQueryResult([
    huellQuery,
    endpointsQuery,
  ]);

  const data: DexpTemplatesForUser | undefined = useMemo(() => {
    if (combinedQueryResult.isSuccess) {
      const huellQueryData = huellQuery.data;
      const endpointsQueryData = endpointsQuery.data;
      const combinedData: DexpTemplatesForUser = {};

      if (huellQueryData && endpointsQueryData) {
        Object.entries(huellQueryData).forEach(([key, value]) => {
          const endpointIdsArr = value.endpoints;
          const endpointsData: Record<
            DexpEndpoint['id'],
            ItemObjInShoppingCart
          > = {};
          for (const endpointId of endpointIdsArr) {
            const endpoint = endpointsQueryData.filter(
              (ele) => ele.id === endpointId,
            )[0];
            if (endpoint) {
              endpointsData[endpointId] = {
                isSelected: true,
                label: endpoint.name,
                rightSideLabel: endpoint.level,
              };
            }
          }

          combinedData[key] = {
            templateId: value.templateId,
            name: value.name,
            endpointsData,
          };
        });
      }

      return combinedData;
    } else {
      return undefined;
    }
  }, [endpointsQuery.data, huellQuery.data, combinedQueryResult.status]);

  return {
    ...combinedQueryResult,
    data,
  };
};
