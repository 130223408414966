import React, { ReactNode } from 'react';
import classNames from 'classnames';
import remove from 'lodash/remove';

import { Checkbox, CheckboxTheme } from '../../inputs/Checkbox';

import styles from './MultiSelect.module.css';

interface OptionTheme {
  Checkbox?: CheckboxTheme;
  Disabled?: string;
}

export interface Option<T> {
  label: string | ReactNode;
  value: T;
  detail?: ReactNode;
  disabledOption?: boolean;
  disabledRightElement?: ReactNode;
  theme?: OptionTheme;
}
export interface OptionProps<T> extends Option<T> {
  checked: boolean;
  onChange?: (optionValue: Option<T>['value']) => void;
  dataHcName: string;
  inline?: boolean;
  className?: string;
  disabled?: boolean;
}

const OptionComponent = <T extends number | string | null>({
  label,
  value,
  detail,
  disabledOption,
  disabledRightElement,
  checked,
  disabled,
  onChange,
  dataHcName,
  inline,
  className,
  theme,
}: OptionProps<T>) => {
  return (
    <div
      className={classNames(
        {
          [styles.Option]: !inline,
          [styles.OptionInline]: inline,
        },
        className,
        (disabled || disabledOption) && theme?.Disabled,
      )}
      data-hc-name={dataHcName}
    >
      <Checkbox
        dataHcName={`${dataHcName}-checkbox`}
        checked={checked}
        disabled={disabled || disabledOption}
        onChange={() => onChange?.(value)}
        label={label}
        disabledRightElement={disabledRightElement}
        detail={detail}
        theme={theme?.Checkbox}
      />
    </div>
  );
};

interface MultiSelectTheme {
  MultiSelect: string;
  Option: string;
}
export interface MultiSelectProps<T> {
  dataHcName: string;
  options: Option<T>[];
  disabled?: boolean;
  className?: string;
  value: T[];
  onChange: (value: T[]) => void;
  label?: string;
  inline?: boolean;
  theme?: Partial<MultiSelectTheme>;
}

export const MultiSelect = <T extends number | string | null>({
  options,
  value,
  disabled,
  className,
  label,
  onChange,
  dataHcName,
  inline = false,
  theme,
}: MultiSelectProps<T>) => {
  const handleChange = (v: T) => {
    const updatedValue = [...value];
    const vIndex = updatedValue.indexOf(v);
    if (vIndex > -1) {
      remove(updatedValue, (i) => i === v);
    } else {
      updatedValue.push(v);
    }
    onChange(updatedValue);
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        {
          [styles.MultiSelect]: !inline,
          [styles.MultiSelectInline]: inline,
        },
        className,
        theme?.MultiSelect,
      )}
    >
      {label}
      {options.map((option) => (
        <OptionComponent
          dataHcName={`${dataHcName}-option-${option.value}`}
          key={`option-${option.value}`}
          {...option}
          disabled={disabled}
          onChange={handleChange}
          checked={value.indexOf(option.value) > -1}
          inline={inline}
          className={theme?.Option}
        />
      ))}
    </div>
  );
};
