import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatPropertyType } from '@hcs/utils';
import { trimWithEllipsis } from '@hcs/utils';

import { useCompsFiltersDocument } from '../../../hooks';
import { CompFilterPropertyType } from '../../CompFiltersDeprecated/CompFilterPropertyType';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.propertyType;
const dataHcName = 'comp-filter-button-property-type';
export const CompFilterButtonPropertyType = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }
  const filterValue =
    filterDocument.data.filters?.[PropertyStateFields.propertyType];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        filterValue?.absoluteValue
          ? trimWithEllipsis(
              filterValue.absoluteValue.map(formatPropertyType).join(', '),
              30,
            )
          : 'Property Type'
      }
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterPropertyType reportId={reportId} compType={compType} />
      }
      active={active}
    />
  );
};
