import React from 'react';
import classNames from 'classnames';

import { DirectionalChevron, Switch } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { HcsDevToolGroupType } from '@hcs/types';

import styles from './HcsDevTools.module.css';

export const HcsDevToolsGroup = <T extends string | number | boolean | null>({
  dataHcName,
  ...hcsDevToolGroup
}: HcsDevToolGroupType<T> & { dataHcName?: string }) => {
  const { active, handleToggle } = useActiveState();
  return (
    <ul
      data-hc-name={dataHcName}
      className={classNames(styles.ButtonGroup, hcsDevToolGroup.className)}
    >
      {hcsDevToolGroup.title && (
        <li
          data-hc-name={`${dataHcName}-expand`}
          className={classNames(styles.Item, styles.GroupTitle)}
          onClick={() => {
            hcsDevToolGroup.onChangeExpanded?.(!active);
            handleToggle();
          }}
        >
          <div
            className={styles.ButtonGroupTitle}
            data-hc-name={`${dataHcName}-title`}
          >
            {hcsDevToolGroup.title}
          </div>
          <DirectionalChevron
            direction={active ? 'up' : 'down'}
            dataHcName={`${dataHcName}-chevron`}
            size="sm"
          />
        </li>
      )}
      {(hcsDevToolGroup.title ? active : true) &&
        hcsDevToolGroup.items.map((item, i) => {
          return (
            <li
              key={`devtool-group-${i}`}
              data-hc-name={item.item.dataHcName}
              onClick={item.item.onClick}
              className={classNames(styles.Item, item.item.className, {
                [styles.largeContent]: item.item.largeContent,
                [styles.clickable]: item.item.clickable || item.item.onClick,
                [styles.disabled]: item.item.disabled,
                [styles.flexItem]: item.type === 'switch',
              })}
            >
              {item.type === 'switch' ? (
                <>
                  <div>{item.item.content}</div>
                  <Switch {...item.switchProps} />
                </>
              ) : (
                item.item.content
              )}
            </li>
          );
        })}
    </ul>
  );
};
