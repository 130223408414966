import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Switch } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';

import styles from './FormFields.module.css';

interface Props {
  name: string;
  label: string;
  isLoading?: boolean;
  onChange?: (value: boolean) => void;
}

const dataHcName = 'form-fields-switch';
export const FormFieldsSwitch = ({
  name,
  label,
  isLoading,
  onChange,
}: Props) => {
  const formContext = useFormContext();
  if (isLoading) {
    return (
      <Skeleton dataHcName={`${dataHcName}-${name}-skeleton`} type="text" />
    );
  }
  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field }) => {
        return (
          <div className={styles.FormGroup}>
            <div className={styles.SwitchGroup}>
              <Switch
                {...field}
                dataHcName={`${dataHcName}-active`}
                value={!!field.value}
                switchOffOption={{
                  value: false,
                }}
                switchOnOption={{
                  value: true,
                }}
                onChange={(val) => {
                  onChange?.(val);
                  return field.onChange(val);
                }}
              />
              {label}
            </div>
          </div>
        );
      }}
    />
  );
};
