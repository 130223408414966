import { useMemo } from 'react';

import { useBuyBoxSummaries } from './useBuyBoxSummaries';

interface BuyBoxCount {
  total: number;
  active: number;
  disabled: number;
}

export const useBuyBoxCount = (): {
  data: BuyBoxCount;
  isInitialLoading: boolean;
} => {
  const { data = [], isInitialLoading } = useBuyBoxSummaries();

  const buyBoxCount = useMemo(() => {
    const result = {
      total: data.length || 0,
      active: 0,
      disabled: 0,
    };

    data.forEach((item) => {
      if (item.enabled) {
        result.active++;
      } else {
        result.disabled++;
      }
    });
    return result;
  }, [data]);

  return { data: buyBoxCount, isInitialLoading };
};
