import { useEffect } from 'react';

import { LoadingSpinner } from '@hcs/design-system';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '../../constants';
import { useAccount } from '../../hooks/useAccount';
import { useLogout } from '../../hooks/useLogout';

export const Logout = () => {
  const logoutMutation = useLogout();
  const accountQuery = useAccount();
  useEffect(() => {
    // Ensure the access token is not in the process of refreshing before logging out.
    if (accountQuery.isSuccess && !accountQuery.isFetching) {
      logoutMutation.mutate();
    }
    // mutate will likely change on every render, but we don't expect it to change in functionality
  }, [accountQuery.isSuccess, accountQuery.isFetching]);
  return (
    <LoadingSpinner
      dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER}
      absoluteCenter
    />
  );
};
