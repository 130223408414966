import { useEffect, useState } from 'react';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

export const useLatestInfiniteQueryData = <TData, TError>(
  useInfiniteQueryResult: UseInfiniteQueryResult<TData, TError>,
) => {
  // Return the latest successful result
  // Prevents results in lists from flickering as the params change
  const [latestData, setLatestData] = useState<InfiniteData<TData> | undefined>(
    useInfiniteQueryResult.data,
  );

  const { isSuccess, isError, data } = useInfiniteQueryResult;
  useEffect(() => {
    if (isError) {
      setLatestData(undefined);
      return;
    }
    if (isSuccess && data) {
      setLatestData(data);
    }
  }, [isSuccess, isError, data]);
  return { ...useInfiniteQueryResult, data: latestData };
};
