import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { useToastSlice } from '@hcs/toast';
import {
  MeaningfulEventTypes,
  SamlProviderConfig,
  SamlProviderConfigFormData,
  SaveSamlProviderConfig,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { SingleSignOnApi } from '../api/SingleSignOn.api';

import { QUERY_KEY_ORG_IS_SSO_ONLY } from './useOrgIsSsoOnly';
import { QUERY_KEY_ORG_SAML_PROVIDER } from './useOrgSamlProvider';

const formDataToSaveSamlProviderConfig = (
  formData: SamlProviderConfigFormData,
): SaveSamlProviderConfig | null => {
  const { authAudience, authMetadata, certificate, domain, mandatory } =
    formData;
  if (certificate === null || authMetadata === null || domain === null) {
    return null;
  }
  return {
    authAudience: authAudience === null ? undefined : authAudience,
    authMetadata,
    certificate,
    domain,
    mandatory: !!mandatory,
  };
};

interface Params {
  formData: SamlProviderConfigFormData;
  orgId: number;
  existingSamlProvider: SamlProviderConfig | null;
}

export const useSaveOrgSamlProvider = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const { mutate: trackEngagementEvent } = useTrackEngagementEvent();
  return useMutation<
    SamlProviderConfig,
    AxiosError<{ status: string; message?: string }>,
    Params
  >(
    async ({ existingSamlProvider, formData, orgId }) => {
      const saveSamlProviderConfig = formDataToSaveSamlProviderConfig(formData);
      if (saveSamlProviderConfig !== null) {
        try {
          let newSettings = null;
          // update existing
          if (existingSamlProvider) {
            newSettings = await SingleSignOnApi.updateOrgSamlProvider(
              orgId,
              existingSamlProvider.id,
              saveSamlProviderConfig,
            );
            // or create
          } else {
            newSettings = await SingleSignOnApi.createOrgSamlProvider(
              orgId,
              saveSamlProviderConfig,
            );
          }
          return newSettings;
        } catch (e) {
          if (axios.isAxiosError(e)) {
            // message will be multiple field messages separated by "\n", including a potential leading "\n" on the first error
            if (e.response?.data.message) {
              const errMessages: string[] = e.response.data.message.split('\n');
              const errMessagesBlanksRemoved = errMessages.filter(
                (errMessage) => errMessage !== '',
              );
              const errMessagesCapitalized = errMessagesBlanksRemoved.map(
                (errMessage) =>
                  errMessage.charAt(0).toUpperCase() + errMessage.slice(1),
              );
              if (errMessagesCapitalized.length > 0) {
                throw new Error(errMessagesCapitalized.join(', '));
              }
            }
            if (e.response?.data.status) {
              throw new Error(e.response?.data.status);
            }
          }
          throw e;
        }
      } else {
        logException(
          'useSaveOrgSamlProvider: one of the required values was null',
        );
        throw new Error('One of the required fields is missing');
      }
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEY_ORG_IS_SSO_ONLY,
          variables.orgId,
        ]);
        queryClient.setQueryData<SamlProviderConfig>(
          [QUERY_KEY_ORG_SAML_PROVIDER, variables.orgId],
          response,
        );
        toastOpen({
          type: 'success',
          title: 'Saml Settings Saved',
        });
        if (!variables.existingSamlProvider) {
          trackEngagementEvent({
            event_name: 'saml-provider-created',
            event_type: MeaningfulEventTypes.Goal,
          });
        }
      },
    },
  );
};
