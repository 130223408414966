import React from 'react';

import { SubNav } from '@hcs/design-system';
import { APP_CONFIG_AEXP, VIEW_PATHS_AEXP } from '@hcs/hc-products';

const dataHcName = 'activity-page-nav';
export const ActivityPageNav = () => {
  const SUB_NAV_ITEMS_CONFIG = [
    {
      navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.ACTIVITY}`,
      label: 'Activity',
      dataHcName: `${dataHcName}-activity-header`,
      children: [
        {
          navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.ACTIVITY}/${VIEW_PATHS_AEXP.ACTIVITY_MONITOR}`,
          label: 'Monitor',
          dataHcName: `${dataHcName}-monitor`,
        },
        {
          navigateTo: `${APP_CONFIG_AEXP.rootPath}/${VIEW_PATHS_AEXP.ACTIVITY}/${VIEW_PATHS_AEXP.ACTIVITY_PROPERTY_SEARCH}`,
          label: 'Property Search',
          dataHcName: `${dataHcName}-property-search`,
        },
      ],
    },
  ];

  return (
    <SubNav dataHcName={dataHcName} subNavItemsConfig={SUB_NAV_ITEMS_CONFIG} />
  );
};
