import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { useToastSlice } from '@hcs/toast';
import { UserContactInfo, UserContactInfoForm } from '@hcs/types';

import { UserAdminApi } from '../api';

export const useUserContactInfoPatch = (
  options?: UseMutationOptions<
    UserContactInfo,
    unknown,
    Partial<UserContactInfoForm>
  >,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const queryClient = useQueryClient();
  return useMutation<UserContactInfo, unknown, Partial<UserContactInfoForm>>(
    async (formData) => {
      return await UserAdminApi.patchUserContactInfo(formData);
    },
    {
      ...options,
      onError: (error, variables, context) => {
        toastOpen({
          type: 'error',
          title: 'Failed to update contact info',
        });
        options?.onError?.(error, variables, context);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        toastOpen({
          type: 'success',
          title: 'Successfully updated contact info',
        });
      },
    },
  );
};
