import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { UserContactInfo, UserContactInfoForm } from '@hcs/types';
import { ChangePasswordErrorData, ChangePasswordPayload } from '@hcs/types';
import { EHRMANTRAUT_URL } from '@hcs/urls';

export const UserAdminApi = {
  changeUserPassword: async (
    formData: ChangePasswordPayload,
  ): Promise<UserContactInfo> => {
    const response = await AxiosAccessTokenClientEhrm.post<UserContactInfo>(
      // Legacy implementation uses the contact-info endpoint
      `${EHRMANTRAUT_URL}/auth/contact-info`,
      snakecaseKeys(formData, { deep: true }),
      {
        // Using transformResponse to handle error data too
        transformResponse: (d: string) => {
          const data = camelcaseKeys(JSON.parse(d), { deep: true }) as
            | UserContactInfo
            | ChangePasswordErrorData;
          return data;
        },
      },
    );
    return response.data;
  },
  patchUserContactInfo: async (
    formData: Partial<UserContactInfoForm>,
  ): Promise<UserContactInfo> => {
    const response = await AxiosAccessTokenClientEhrm.post<UserContactInfo>(
      `${EHRMANTRAUT_URL}/auth/contact-info`,
      snakecaseKeys(formData, { deep: true }),
      {
        // Using transformResponse to handle error data too
        transformResponse: (d: string) => {
          const data = camelcaseKeys(JSON.parse(d), { deep: true }) as
            | UserContactInfo
            | unknown;
          return data;
        },
      },
    );
    return response.data;
  },
};
