import { DeveloperCenterIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_DEVELOPER_CENTER: AppConfig = {
  name: 'Developer Center',
  rootPath: `/developer-center`,
  type: 'product',
  appSlug: AppSlugs.Platform,
  Icon: DeveloperCenterIcon,
  applicationKey: 'developer-center',
  descriptionLong: 'Create API Keys and other developer resources',
  descriptionShort: 'Create API Keys and other developer resources',
};

export enum VIEW_PATHS_DEVELOPER_CENTER {
  API_KEYS = 'api-keys',
}
