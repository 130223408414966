import React from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Drawer } from '@hcs/design-system';
import { DrawerPosition } from '@hcs/design-system';
import { CloseIcon } from '@hcs/design-system';
import { AvailableDownloadable, OrderItem, OrderItemStatus } from '@hcs/types';

import { useDownloadAllOrderItemData } from '../../hooks/useDownloadAllOrderItemData';
import { getFullAddressFromOrderItem } from '../../utils/orderItem.utils';
import { getOrderItemStatusLabel } from '../../utils/status.util';
import { Metadata } from '../Metadata';
import { OrderItemPdfButton } from '../OrderItemPdfButton';

import styles from './OrderItemDetailsDrawer.module.css';

interface Props {
  orderId: number;
  orderItem: OrderItem;
  hideModal: () => void;
}

const dataHcName = 'detail-info-panel';
export const OrderItemDetailsDrawer = ({
  orderId,
  orderItem,
  hideModal,
}: Props) => {
  const fullAddress = getFullAddressFromOrderItem(orderItem);
  const fullAddressSlug = fullAddress.replace(/,\s|\s/gm, '-');
  const { mutate: downloadAllOrderItemData } = useDownloadAllOrderItemData();

  const {
    address,
    unit,
    city,
    state,
    zipcode,
    status,
    availableDownloadables,
    id,
  } = orderItem;

  const isComplete = status === OrderItemStatus.Complete;

  return (
    <Drawer
      className={styles.DrawerContainer}
      dataHcName={dataHcName}
      onClose={hideModal}
      active={true}
      position={DrawerPosition.RIGHT}
    >
      <div>
        <div data-hc-name="header-div" className={styles.TitleCloseContainer}>
          <h3>Order Item Details</h3>
          <CloseIcon
            onClick={hideModal}
            className={styles.CloseButton}
            dataHcName={`${dataHcName}-close-button`}
          />
        </div>
        <div className={styles.Content}>
          <div
            className={classNames([styles.StatusContainer], {
              [styles.Complete]: isComplete,
            })}
            data-hc-name="status-div"
          >
            <div className={styles.StatusIndicator}></div>
            <div className={styles.Status}>
              {getOrderItemStatusLabel(status)}
            </div>
          </div>

          <div>
            <h2 className={styles.AddressTop}>{`${address} ${unit || ''}`}</h2>
            <h3 className={styles.AddressBottom}>
              {`${city || ''}, ${state || ''} ${zipcode || ''}`}
            </h3>
          </div>
          <div>
            {availableDownloadables.map(
              (availableDownloadable: AvailableDownloadable) => {
                return (
                  <OrderItemPdfButton
                    className={styles.DownloadOrderItemButton}
                    key={availableDownloadable.key}
                    availableDownloadable={availableDownloadable}
                    orderId={orderId}
                    orderItemId={id}
                  />
                );
              },
            )}
            {isComplete && (
              <Button
                className={styles.DownloadAllOrderItemButton}
                dataHcName="download-all-data-action"
                secondary
                onClick={() => {
                  const fileName = `${fullAddressSlug}.zip`;
                  downloadAllOrderItemData({
                    orderId,
                    fileName: fileName,
                  });
                }}
              >
                Download All Data
              </Button>
            )}
          </div>
        </div>
        <Metadata
          orderId={orderId}
          orderItem={orderItem}
          dataHcName="metadata"
        />
      </div>
    </Drawer>
  );
};
