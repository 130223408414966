import React from 'react';
import classNames from 'classnames';

import { Toggle } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import {
  ReportFeatures,
  ReportFeaturesSupportTypes,
  ReportId,
} from '@hcs/types';
import { ChartType } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useReportValue } from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useSimilarPropertiesChartDocument } from '../../hooks/useSimilarPropertiesChartDocument';
import { useSimilarPropertiesChartFilteredDocument } from '../../hooks/useSimilarPropertiesChartFilteredDocument';
import { TITLE_CONSTANTS } from '../../utils/charts.utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import { SimilarPropertiesChartDisplay } from './SimilarPropertiesChartDisplay';

import styles from './SimilarPropertiesChart.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  onChartTypeChange?: (chartType: ChartType) => void;
}

const dataHcName = 'valuation-chart';
const CENTER_NULL_STATE = true;
export const SIMILAR_PROPERTIES_CHARTS_FEATURES = [
  ReportFeatures.SimilarPropertiesChart,
  ReportFeatures.CompsSimilarPropertiesCharts,
];
export const SimilarPropertiesCharts = ({
  reportId,
  className,
  onChartTypeChange,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const [chartType, setChartType] = React.useState<ChartType>(
    reportConfig?.reportFeaturesSupport[
      ReportFeatures.CompsSimilarPropertiesCharts
    ]
      ? ChartType.Valuation
      : ChartType.Comparables,
  );
  const valuationChartQuery = useSimilarPropertiesChartDocument(reportId);
  const valuationFilteredChartQuery =
    useSimilarPropertiesChartFilteredDocument(reportId);

  const reportValueQuery = useReportValue(reportId);
  const { isInitialLoading } = combineUseQueryResult([
    valuationChartQuery,
    reportValueQuery,
    valuationFilteredChartQuery,
  ]);

  const chart =
    chartType === ChartType.Valuation
      ? valuationChartQuery?.data?.data?.chart
      : valuationFilteredChartQuery?.data?.data?.chart;
  const bothChartsNull =
    !valuationChartQuery?.data?.data?.chart &&
    !valuationFilteredChartQuery?.data?.data?.chart;
  const handleChange = (option: ChartType) => {
    setChartType(option);
    onChartTypeChange?.(chartType);
  };

  const titleString =
    chartType === ChartType.Comparables
      ? TITLE_CONSTANTS.COMPARABLE_PROPERTIES
      : TITLE_CONSTANTS.VALUATION_TOGGLE;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.SimilarPropertiesChart, className)}
    >
      {!chart && !isInitialLoading && (
        <NullState
          title={`${titleString} Unavailable`}
          dataHcName={`${dataHcName}-nullstate`}
          absCenter={CENTER_NULL_STATE}
        >
          <span>
            Sorry! There aren’t enough similar homes to generate this chart -
            but our data improves everyday. Please check again later!
          </span>
        </NullState>
      )}
      {chart && !isInitialLoading && (
        <SimilarPropertiesChartDisplay
          subjectValue={reportValueQuery.data?.selectedValue?.value.value || 0}
          chart={chart}
          chartType={chartType}
        />
      )}
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={SIMILAR_PROPERTIES_CHARTS_FEATURES}
        reportFeaturesSupportType={ReportFeaturesSupportTypes.All}
      >
        {!bothChartsNull && (
          <div className={styles.ChartTypeToggle}>
            <Toggle
              dataHcName={`${dataHcName}-toggle`}
              value={chartType}
              options={[
                {
                  label: TITLE_CONSTANTS.VALUATION_TOGGLE,
                  value: ChartType.Valuation,
                },
                {
                  label: TITLE_CONSTANTS.FILTERED_COMPARABLES,
                  value: ChartType.Comparables,
                },
              ]}
              onChange={handleChange}
            />
          </div>
        )}
      </ReportFeaturesSupported>
    </div>
  );
};
