import React, { ReactNode } from 'react';
import { Navigate } from 'react-router';

import {
  useCheckAnyAccessibleApplications,
  useCheckAppAccess,
} from '@hcs/auth';
import { LimitedAccess } from '@hcs/auth';
import {
  DATA_HC_NAME_PAGE_AUTH_SPINNER,
  useUserIsLegacySelfService,
} from '@hcs/authn';
import { LoadingSpinner } from '@hcs/design-system';
import {
  APP_CONFIG_PEXP,
  VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM,
  VIEW_PATHS_PEXP,
} from '@hcs/hc-products';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';
import {
  useSelfServiceCheckoutSessionParam,
  useSelfServicePaymentMethod,
} from '@hcs/self-service';
import { AppSlugs } from '@hcs/types';

export const NoAccessibleApplicationsRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { sessionParam } = useSelfServiceCheckoutSessionParam();
  const {
    isInitialLoading: selfServiceOrgInfoLoading,
    data: selfServiceOrgInfo,
  } = useOrgSelfServeInfo();
  const {
    data: hasAccessibleApplication,
    isInitialLoading: hasAccessibleApplicationIsLoading,
  } = useCheckAnyAccessibleApplications();
  const {
    data: userIsLegacySelfService,
    isInitialLoading: userIsLegacySelfServiceIsLoading,
  } = useUserIsLegacySelfService();
  const {
    data: selfServicePaymentMethod,
    isInitialLoading: paymentMethodIsLoading,
  } = useSelfServicePaymentMethod({ enabled: !!userIsLegacySelfService });
  const {
    data: hasMarketInsights,
    isInitialLoading: hasMarketInsightsIsLoading,
  } = useCheckAppAccess(AppSlugs.MarketInsights);

  if (
    selfServiceOrgInfoLoading ||
    hasAccessibleApplicationIsLoading ||
    userIsLegacySelfServiceIsLoading ||
    paymentMethodIsLoading ||
    hasMarketInsightsIsLoading
  ) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (selfServiceOrgInfo?.checkout_completed === false) {
    if (sessionParam) {
      return <Navigate to={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.CHECKOUT} />;
    } else {
      return <Navigate to={VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.SELECT_PLAN} />;
    }
  } else if (userIsLegacySelfService) {
    // PEXP self-serve:
    // once a self service user enters their credit credit, we make VR an accessible application
    // but it's possible for a user to signup and confirm without entering their credit card
    // so we direct them to add their credit card in this scenario

    // Market Insights self-serve:
    // MI self-serve users are able to access Market Insights without any accessible applications
    // These do not need to enter credit card until they hit paywall in PEXP app
    if (
      !hasAccessibleApplication &&
      !selfServicePaymentMethod &&
      !hasMarketInsights
    ) {
      return (
        <Navigate
          to={`${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SELF_SERVICE_SETUP}`}
          replace
        />
      );
    } else {
      return children;
    }
  } else if (!hasAccessibleApplication) {
    return <LimitedAccess />;
  } else {
    return children;
  }
};
