import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ReportConfigApi } from '@hcs/types';

import { ReportApi } from '../api';

export const QUERY_KEY_REPORT_CONFIGS = 'QUERY_KEY_REPORT_CONFIGS';
export const useReportConfigs = (
  options?: UseQueryOptions<
    ReportConfigApi[],
    unknown,
    ReportConfigApi[],
    Array<typeof QUERY_KEY_REPORT_CONFIGS>
  >,
) => {
  // Fetches all report configs that user has access to
  return useQuery(
    [QUERY_KEY_REPORT_CONFIGS],
    async () => {
      const response = await ReportApi.fetchReportConfigs();
      return response;
    },
    options,
  );
};
