import React, { JSXElementConstructor, ReactNode } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton';

import styles from './Menu.module.css';

export interface MenuItemProps {
  dataHcName: string;
  className?: string;
  label: ReactNode;
  description?: ReactNode;
  Icon?: JSXElementConstructor<IconProps>;
  onClick?: VoidFunction;
  contentRight?: ReactNode;
  skeletonConfig?: {
    isLoading: boolean;
    numItems: number;
    description?: boolean;
    Icon?: boolean;
    contentRight?: boolean;
  };
}
export const MenuItem = ({
  dataHcName,
  className,
  label,
  description,
  Icon,
  contentRight,
  onClick,
  skeletonConfig,
}: MenuItemProps) => {
  return (
    <li
      data-hc-name={dataHcName}
      className={classNames(
        styles.MenuItem,
        { [styles.clickable]: !!onClick },
        className,
      )}
      onClick={onClick}
    >
      {(Icon || skeletonConfig?.Icon) &&
        (skeletonConfig?.isLoading ? (
          <div className={styles.IconSkeleton}>
            <Skeleton dataHcName={`${dataHcName}-skeleton`} type="fill" />
          </div>
        ) : (
          Icon && <Icon size="lg" color="neutral-dark-20" />
        ))}
      <div className={styles.TextCell}>
        <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
          {skeletonConfig?.isLoading ? (
            <Skeleton
              dataHcName={`${dataHcName}-skeleton`}
              type="text"
              width="200px"
            />
          ) : (
            label
          )}
        </div>
        {(description || skeletonConfig?.description) && (
          <div
            className={styles.Description}
            data-hc-name={`${dataHcName}-description`}
          >
            {skeletonConfig?.isLoading ? (
              <Skeleton
                dataHcName={`${dataHcName}-skeleton`}
                type="text"
                width="90%"
              />
            ) : (
              description
            )}
          </div>
        )}
      </div>
      {(contentRight || skeletonConfig?.contentRight) && (
        <div className={styles.ControlCell}>
          {skeletonConfig?.contentRight ? (
            <Skeleton
              dataHcName={`${dataHcName}-skeleton`}
              type="text"
              width="85px"
            />
          ) : (
            contentRight
          )}
        </div>
      )}
    </li>
  );
};
