import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions, StreetViewUrlResponse } from '@hcs/types';
import { expect404 } from '@hcs/utils';

import { buildStaticStreetViewUrl } from '../utils/streetView.utils';

export const StreetViewApi = {
  fetchStreetViewUrl: async (
    location: PropertyStateLocation['location'],
    options?: StreetViewOptions,
  ) => {
    const response =
      await AxiosAccessTokenClientBearer.get<StreetViewUrlResponse>(
        buildStaticStreetViewUrl(location, options),
      ).catch((err) => {
        // 404 is a common response so we want to just handle it
        return expect404(err);
      });
    // react-query is unhappy if you return undefined on a successful promise (so returning null instead)
    return response?.data || null;
  },
};
