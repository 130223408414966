import { DocumentRoles, ReportId, SubjectPhotosDocument } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSubjectPhotosDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<SubjectPhotosDocument>(
    reportId,
    DocumentRoles.SubjectPhotos,
  );
};
