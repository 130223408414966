import {
  DocumentRoles,
  ReportApiDocument,
  ReportApiDocumentQueryParamsDocumentRole,
  ReportId,
} from '@hcs/types';

import { useDocumentRole } from './useDocumentRole';

// Used to get document roles that will only ever a single document
export const useSingleDocumentRole = <DocType extends ReportApiDocument>(
  reportId: ReportId,
  documentRole: DocumentRoles,
  params?: ReportApiDocumentQueryParamsDocumentRole,
) => {
  const documentRoleQuery = useDocumentRole<DocType>(
    reportId,
    documentRole,
    params,
  );
  if (documentRoleQuery.data) {
    return {
      ...documentRoleQuery,
      data: documentRoleQuery.data[0],
    };
  } else {
    return {
      ...documentRoleQuery,
      data: undefined,
    };
  }
};
