import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';
import moment from 'moment';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import { CerberusStatsFields } from '@hcs/types';
import {
  BulkEditErrorResponse,
  BulkEditPatch,
  BuyBox,
  BuyBoxActivityFromServer,
  BuyBoxActivityFromServerMap,
  BuyBoxPatch,
  BuyBoxSummary,
  Filters,
  LeadFeedOrg,
  MSA,
  MSAWithGeom,
  MsaWithGeomFromServer,
  NewBuyBox,
  PropertyHistoryFromServer,
  RangeFilters,
  Template,
  TimeSeriesResolution,
} from '@hcs/types';
import { LEAD_FEED_URL } from '@hcs/urls';

import { BuyBoxBulkEditError } from '../utils';

const BuyBoxToCamelcase = (buyBox: BuyBox) => {
  return {
    ...camelcaseKeys(buyBox), // need to do this to avoid doing a camelcaseKeys on the Date object
    defaults: camelcaseKeys(buyBox.defaults, { deep: true }),
  };
};

const getMarketActivityFieldsQueryStr = (
  fieldsList?: CerberusStatsFields[],
) => {
  let marketActivityFields = '';
  if (fieldsList) {
    fieldsList.forEach((fieldName, idx) => {
      marketActivityFields =
        marketActivityFields +
        `${idx === 0 ? '?' : '&'}marketActivityFields=${fieldName}`;
    });
  }
  return marketActivityFields;
};

const downloadMsaReport = async ({
  fileName,
  msaId,
  fieldsList,
}: {
  fileName: string;
  msaId: string;
  fieldsList?: CerberusStatsFields[];
}) => {
  const response = await AxiosAccessTokenClientJwt.get<Blob>(
    `${LEAD_FEED_URL}/generate-workbook/${msaId}${getMarketActivityFieldsQueryStr(
      fieldsList,
    )}`,
    { responseType: 'blob' },
  );
  const file = response.data;
  fileDownload(file, fileName);
};

const downloadZipReport = async ({
  fileName,
  zipcode,
  fieldsList,
}: {
  fileName: string;
  zipcode: string;
  fieldsList?: CerberusStatsFields[];
}) => {
  const response = await AxiosAccessTokenClientJwt.get<Blob>(
    `${LEAD_FEED_URL}/generate-workbook-zip/${zipcode}${getMarketActivityFieldsQueryStr(
      fieldsList,
    )}`,
    { responseType: 'blob' },
  );
  const file = response.data;
  fileDownload(file, fileName);
};

const fetchBuyBox = async (buyBoxId: BuyBox['id'] | null) => {
  if (buyBoxId === null) {
    throw new Error('fetchBuyBox: buyBoxId is null');
  }
  const response = await AxiosAccessTokenClientJwt.get<BuyBox>(
    `${LEAD_FEED_URL}/collections/${buyBoxId}`,
  );
  return BuyBoxToCamelcase(response.data);
};

const fetchBuyBoxSummaries = async () => {
  const response = await AxiosAccessTokenClientJwt.get<BuyBoxSummary[]>(
    `${LEAD_FEED_URL}/collections`,
  );

  return camelcaseKeys(response.data, { deep: true });
};

const fetchMsas = async () => {
  const response = await AxiosAccessTokenClientJwt.get<MSA[]>(
    `${LEAD_FEED_URL}/msas/`, // trailing slash is required or the backend will return 404
  );

  return camelcaseKeys(response.data, { deep: true });
};
const fetchLeadFeedOrgs = async () => {
  const response = await AxiosAccessTokenClientJwt.get<LeadFeedOrg[]>(
    `${LEAD_FEED_URL}/organizations`,
  );

  return camelcaseKeys(response.data, { deep: true });
};

const fetchMsaGeom = async (
  msaId: MSA['msaId'] | null,
): Promise<MSAWithGeom> => {
  if (msaId === null) {
    throw new Error('fetchMsaGeom: msaId is null');
  }
  const response = await AxiosAccessTokenClientJwt.get<MsaWithGeomFromServer>(
    `${LEAD_FEED_URL}/msas/${msaId}/geom/`, // trailing slash is required or the backend will return 301
  );

  const camelCaseResponse = camelcaseKeys(response.data, { deep: true });
  // geom comes down as JSON string and needs to be parsed
  const parsedGeom: MSAWithGeom['geom'] = JSON.parse(camelCaseResponse.geom);
  return {
    ...camelCaseResponse,
    geom: parsedGeom,
  };
};

const fetchTemplates = async () => {
  const response = await AxiosAccessTokenClientJwt.get<Template[]>(
    `${LEAD_FEED_URL}/templates`,
  );

  return response.data.map((template) => {
    return {
      ...camelcaseKeys<Template>(template),
      updatedAt: moment(template.updatedAt).toDate(), // We need a date object to allow sorting on
      createdAt: moment(template.createdAt).toDate(),
      template: camelcaseKeys<Filters>(template.template, { deep: true }),
    };
  });
};

const patchBuyBox = async (buyBoxPatch: BuyBoxPatch): Promise<BuyBox> => {
  return await AxiosAccessTokenClientJwt.patch(
    `${LEAD_FEED_URL}/collections/${buyBoxPatch.id}`,
    snakecaseKeys(buyBoxPatch),
  );
};

const createBuyBox = async (newBuyBox: NewBuyBox): Promise<BuyBox> => {
  const createBuyBoxResult = await AxiosAccessTokenClientJwt.post<BuyBox>(
    `${LEAD_FEED_URL}/collections`,
    snakecaseKeys(newBuyBox),
  );
  return BuyBoxToCamelcase(createBuyBoxResult.data);
};

const bulkEditBuyBoxes = async (
  bulkEditPatch: BulkEditPatch,
): Promise<undefined> => {
  try {
    await AxiosAccessTokenClientJwt.patch(
      `${LEAD_FEED_URL}/collections/filters/default`,
      snakecaseKeys(bulkEditPatch),
    );
    return;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      // Unfortunately, the above util doesn't accept a type argument for the error data
      const error = err as AxiosError<BulkEditErrorResponse>;
      if (error.response) {
        const errorData = camelcaseKeys(error.response.data, { deep: true });
        // check to see if it's the right error response
        if (errorData.ids) {
          const fieldName = Object.keys(
            errorData.default,
          )[0] as keyof RangeFilters;
          throw new BuyBoxBulkEditError(
            errorData.ids[0],
            fieldName,
            errorData.default[fieldName],
          );
        }
      }
    }
    throw err;
  }
};

export interface FetchBuyBoxActivityArg {
  startDate: string; // date in YYYY-MM-DD format
  endDate: string; // date in YYYY-MM-DD format
  resolution: TimeSeriesResolution;
  timeZone: string;
}

const fetchBuyBoxActivity = async (arg: FetchBuyBoxActivityArg | null) => {
  if (arg === null) {
    throw new Error('fetchBuyBoxActivity: argument is null');
  }
  const { startDate, endDate, resolution, timeZone } = arg;
  const response = await AxiosAccessTokenClientJwt.get<
    BuyBoxActivityFromServer[]
  >(
    `${LEAD_FEED_URL}/activity/time-series?start_date=${startDate}&end_date=${endDate}&resolution=${resolution}&timezone=${timeZone}`,
  );

  const responseCamelCase = response.data.map((activity) => {
    return {
      ...camelcaseKeys(activity),
      leads: activity.leads.map((lead) => camelcaseKeys(lead)),
    };
  });
  // convert to map with buy box id as key
  return responseCamelCase.reduce<BuyBoxActivityFromServerMap>(
    (accum, buyBoxActivity) => {
      accum[buyBoxActivity.collectionId] = buyBoxActivity;
      return accum;
    },
    {},
  );
};

interface PropertyHistoryIdArg {
  id: [
    {
      addressId: number;
    },
  ];
}
const fetchPropertyHistory = async (addressId: number | null) => {
  if (addressId === null) {
    throw new Error('fetchPropertyHistory: addressId was null');
  }
  const idArg: PropertyHistoryIdArg = {
    id: [
      {
        addressId,
      },
    ],
  };
  const response =
    await AxiosAccessTokenClientJwt.post<PropertyHistoryFromServer>(
      `${LEAD_FEED_URL}/activity/property-history`,
      snakecaseKeys(idArg),
    );

  const camelCaseResponse = camelcaseKeys(response.data, { deep: true });
  return camelCaseResponse;
};

export const LeadFeedApi = {
  fetchBuyBox,
  fetchBuyBoxSummaries,
  fetchMsas,
  fetchMsaGeom,
  fetchTemplates,
  fetchLeadFeedOrgs,
  patchBuyBox,
  createBuyBox,
  downloadMsaReport,
  downloadZipReport,
  bulkEditBuyBoxes,
  fetchBuyBoxActivity,
  fetchPropertyHistory,
};
