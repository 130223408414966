import camelcaseKeys from 'camelcase-keys';

import { Account, UserContactInfo } from '@hcs/types';
import { ACCOUNT_URL, EHRMANTRAUT_URL } from '@hcs/urls';

import { AxiosClient, queryClient } from '../clients';
import { ACCOUNT_QUERY_KEY } from '../constants';

import {
  authHeaderFnAccountApi,
  authHeaderFnEhrmantraut,
} from './authHeaders.utils';

export const getAccessTokenFromCache = () => {
  const account = queryClient.getQueryData<Account>([ACCOUNT_QUERY_KEY]);
  return account?.token?.access;
};

const removeAllQueriesExceptAccount = () => {
  const queryCache = queryClient.getQueryCache();
  queryCache.getAll().forEach(({ queryKey }) => {
    if (queryKey.length === 1 && queryKey[0] === ACCOUNT_QUERY_KEY) {
      // leave the account query
    } else {
      queryClient.removeQueries(queryKey);
    }
  });
};

export const resetAccountQuery = () => {
  // we need to reset the account query (as opposed to removing) so a refetch is triggered (and thus a re-render to it's subscribers)
  queryClient.resetQueries([ACCOUNT_QUERY_KEY]);
  removeAllQueriesExceptAccount();
};

export const fetchAccount = async (account?: Account) => {
  // some endpoints already get account back - like login, so we just need to add on the legacy key
  let accessToken = account ? account.token.access : null;
  if (!account) {
    // refresh needs the existing access token to do some login-as checks
    const existingAccessToken = getAccessTokenFromCache();
    const accountResponse = await AxiosClient.post<Account>(
      `${ACCOUNT_URL}/auth/refresh`,
      undefined,
      {
        headers: authHeaderFnAccountApi(existingAccessToken || null),
        withCredentials: true,
      },
    );
    account = accountResponse.data;
    accessToken = accountResponse.data.token.access;
  }

  const userInfoResponse = await AxiosClient.get<UserContactInfo>(
    `${EHRMANTRAUT_URL}/auth/contact-info`,
    {
      headers: authHeaderFnEhrmantraut(accessToken),
    },
  );
  const userInfoResponseCamelCase = camelcaseKeys(userInfoResponse.data, {
    deep: true,
  });

  const accountWithLegacy: Account = {
    ...account,
    legacy: userInfoResponseCamelCase,
  };

  return accountWithLegacy;
};
