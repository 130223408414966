import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { PaginatedResponseData } from '@hcs/types';
import { Report, ReportSearchInputs } from '@hcs/types';

import { ReportApi } from '../api';

import { QUERY_KEY_REPORT } from './useReport';

export const QUERY_KEY_REPORT_SEARCH = 'report-api-reportSearch';
export const useReports = (
  reportSearchInputs: ReportSearchInputs,
  options?: UseQueryOptions<
    PaginatedResponseData<Report[]>,
    unknown,
    PaginatedResponseData<Report[]>,
    (string | ReportSearchInputs)[]
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QUERY_KEY_REPORT_SEARCH, reportSearchInputs],
    async () => {
      const response = await ReportApi.fetchReports(reportSearchInputs);
      return response;
    },
    {
      ...options,
      onSuccess: (reports) => {
        // Update the useReport query cache so reports load quicker when clicking into one
        reports.data.forEach((report) => {
          queryClient.setQueryData<Report>(
            [QUERY_KEY_REPORT, report.id],
            report,
          );
        });
        options?.onSuccess?.(reports);
      },
    },
  );
};
