import { usePortfolioMonitorZuoraSubscription } from '@hcs/auth';
import { PortfolioFormNotificationTypes } from '@hcs/types';

import { accessibleNotificationsFromSubscriptions } from '../utils/subscription.utils';

export const useAccessibleNotifications = () => {
  /**
   * Its possible for some portfolios to have no associated
   * 'accessibleApplications' with the account even though they
   * still have access to Portfolio Monitor. This means there is
   * no 'app' ('app' will be undefined)
   */
  const { data: app, ...rest } = usePortfolioMonitorZuoraSubscription();

  const accessibleNotifications = app
    ? accessibleNotificationsFromSubscriptions(app.components)
    : [];

  /**
   *
   * All portfolios have access to MLS and LTV notifications
   * even if those subscriptions don't exist for them in Zuora.
   * Ensure these two subscriptions always exist for each portfolio
   */
  return {
    data: Array.from(
      new Set([
        PortfolioFormNotificationTypes.Mls,
        PortfolioFormNotificationTypes.Ltv,
        ...accessibleNotifications,
      ]),
    ),
    ...rest,
  };
};
