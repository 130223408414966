import { ReportId, ValuationMethod } from '@hcs/types';
import { CombinedUseQueryResult } from '@hcs/types';
import { ValueExtended } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useSubjectDocument } from './useSubjectDocument';
import { useSubjectValueDocument } from './useSubjectValueDocument';

export const useReportHouseCanaryValue = (
  reportId: ReportId,
): CombinedUseQueryResult<ValueExtended | undefined> => {
  const subjectValueQuery = useSubjectValueDocument(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectQuery,
    subjectValueQuery,
  ]);
  return {
    ...combinedQuery,
    data: subjectValueQuery.data?.data[ValuationMethod.Avm],
  };
};
