import React, { JSXElementConstructor, ReactNode } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import styles from './GenerateAnalysisComplete.module.css';

interface GenerateAnalysisCompleteTheme {
  Icon?: string;
  Title?: string;
}

interface Props {
  dataHcName: string;
  Icon: JSXElementConstructor<IconProps>;
  title: string;
  inputFile: File | null;
  message?: ReactNode;
  children: ReactNode;
  theme?: GenerateAnalysisCompleteTheme;
}

export const GenerateAnalysisComplete = ({
  dataHcName,
  Icon,
  title,
  inputFile,
  message,
  children,
  theme,
}: Props) => {
  return (
    <div data-hc-name={dataHcName}>
      <div className={styles.GenerateAnalysisComplete}>
        <Icon className={theme?.Icon} dataHcName={`${dataHcName}-icon`} />
        <div
          className={classNames(styles.Title, theme?.Title)}
          data-hc-name={`${dataHcName}-text`}
        >
          {title}
        </div>

        <div data-hc-name={`${dataHcName}-file-name`}>
          {formatMissing(inputFile?.name)}
        </div>
        {message && <div>{message}</div>}
      </div>
      <div className={styles.Action}>{children}</div>
    </div>
  );
};
