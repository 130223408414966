import { SearchByMlsNumberActions } from './searchByMlsNumber.actions';

export interface SearchByMlsNumberState {
  // Store inputs for query
  [queryId: string]: string;
}

export const searchByMlsNumberReducer = (
  state: SearchByMlsNumberState,
  action: SearchByMlsNumberActions,
) => {
  const { queryId, input } = action.payload;
  return {
    ...state,
    [queryId]: input,
  };
};
