import { PayloadAction } from '@reduxjs/toolkit';

import { ToastProps, ToastSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: ToastSliceState = { active: undefined };
export const toastSlice = createHcReduxSlice({
  name: 'toast',
  initialState,
  reducers: {
    toastOpen(state, action: PayloadAction<ToastProps>) {
      state.active = action.payload;
    },
    toastClose(state) {
      state.active = undefined;
    },
  },
});
