import { RefObject } from 'react';

import { useComponentDidMount } from './useComponentDidMount';
import { useRerender } from './useRerender';
import { useWindowResize } from './useWindowResize';

// NOTE: Parent of ref must have non-static visibility and overflow: hidden
export const useIsOverflowed = <T extends HTMLElement>(
  ref: RefObject<T>,
  deps?: unknown[],
) => {
  const { incRerenders } = useRerender({
    deps,
  });
  // Rerender in case parent is mounted at the same time
  useComponentDidMount(incRerenders);
  // Listen to window resizes
  useWindowResize(incRerenders);
  // Elements to compare
  const el = ref.current;
  if (el) {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = el;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  }
  return;
};
