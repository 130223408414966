import React from 'react';
import classNames from 'classnames';

import { HomeCircleIcon } from '@hcs/design-system';
import { HouseCanaryLogomarkFullColor } from '@hcs/design-system';
import { SimilarityLevel } from '@hcs/types';

import styles from './SimilarityLevelLabel.module.css';

export interface SimilarityLevelLabelProps {
  dataHcName: string;
  label?: number | string | null;
  similarityLevel?: SimilarityLevel | null | '';
  radius?: number;
  highlight?: 'hc' | 'appraisal';
}

const FILL_BY_HIGHLIGHT = {
  hc: '#000', // '#0a62ff',
  appraisal: '#4a4a4a',
};

const FILL_BY_LEVEL = {
  [SimilarityLevel.High]: '#41D782',
  [SimilarityLevel.Moderate]: '#FEB913',
  [SimilarityLevel.Low]: '#FF8253',
};

const TEXT_BY_LEVEL = {
  [SimilarityLevel.High]: '#fff',
  [SimilarityLevel.Moderate]: '#fff',
  [SimilarityLevel.Low]: '#fff',
};

export const SimilarityLevelLabel = ({
  dataHcName,
  label,
  similarityLevel,
  radius = 12,
  highlight,
}: SimilarityLevelLabelProps) => {
  const fill = highlight
    ? FILL_BY_HIGHLIGHT[highlight]
    : similarityLevel
      ? FILL_BY_LEVEL[similarityLevel]
      : '#FFF';
  const textFill = highlight
    ? '#FFF'
    : similarityLevel
      ? TEXT_BY_LEVEL[similarityLevel]
      : '#4a4a4a';
  const largeLabel =
    typeof label === 'string'
      ? label.length < 2
      : typeof label === 'number'
        ? label < 10
        : '';
  return (
    <svg
      data-hc-name={dataHcName}
      className={classNames(styles.SimilarityLevelLabel)}
      width={radius * 2}
      height={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        id="svg_4"
        ry={radius}
        rx={radius}
        cy={radius}
        cx={radius}
        fill={fill}
        opacity={'0.3'}
      />
      <ellipse
        id="svg_5"
        ry={radius * 0.6667}
        rx={radius * 0.6667}
        cy={radius}
        cx={radius}
        fill={fill}
      />
      {label && (
        <text
          x={radius * 0.58}
          y={highlight ? radius * 1.7 : radius * 1.25}
          dx={largeLabel ? radius * 0.208 : 0}
          style={{ fontSize: radius * 0.75, fill: textFill, fontWeight: 800 }}
        >
          {label}
        </text>
      )}
      {highlight === 'hc' && (
        <HouseCanaryLogomarkFullColor
          className={styles.HcLogoMark}
          x={radius * 0.6}
          y={radius * 0.25}
          height={radius * 0.8}
          width={radius * 0.8}
        />
      )}
      {highlight === 'hc' && (
        <HouseCanaryLogomarkFullColor
          className={styles.HcLogoMark}
          x={radius * 0.6}
          y={radius * 0.25}
          height={radius * 0.8}
          width={radius * 0.8}
        />
      )}
      {highlight === 'appraisal' && (
        <HomeCircleIcon
          className={styles.AppraiserIcon}
          x={0}
          y={0}
          height={radius * 2}
          width={radius * 2}
        />
      )}
    </svg>
  );
};
