import { GraphQLClient } from 'graphql-request';
import { RequestConfig } from 'graphql-request/build/esm/types';

import { PROPERTY_GRAPH_URL } from '@hcs/urls';

import { buildAxiosFetch } from '../utils/axiosFetch.utils';

import { AxiosAccessTokenClientEhrm } from './AxiosAccessToken.client';

export const GraphQLAccessTokenClientEhrm = (
  baseUrl: string,
  options: RequestConfig,
) => {
  return new GraphQLClient(baseUrl, {
    ...options,
    fetch: buildAxiosFetch(AxiosAccessTokenClientEhrm),
  });
};

export const GraphQLUnauthenticatedClient = (
  baseUrl: string,
  options: RequestConfig,
) => {
  return new GraphQLClient(baseUrl, options);
};

export const PropertyGraphClient = GraphQLAccessTokenClientEhrm(
  PROPERTY_GRAPH_URL,
  {},
);
