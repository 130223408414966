import { useMemo } from 'react';

import { useMsas } from '@hcs/lead-feed';
import { CerberusStatsMsa } from '@hcs/types';
import { logWarning } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useCerberusStatsAllMsas } from '../hooks/useCerberusStatsAllMsas';

export const useCerberusStatsAllMsasWithHpi = () => {
  const leadFeedMsasQuery = useMsas();
  const cerberusStatsMsasQuery = useCerberusStatsAllMsas();

  const data = useMemo(() => {
    // we are using lead feed msas to filters all msas since lead feed msas have been checked for hpi data
    // ideally this check would exist in cerberus
    if (leadFeedMsasQuery.data && cerberusStatsMsasQuery.data) {
      return leadFeedMsasQuery.data.reduce<CerberusStatsMsa[]>(
        (accum, leadFeedMsa) => {
          const cerberusStatsMsa = cerberusStatsMsasQuery.data?.find(
            (msa) => msa?.id === leadFeedMsa.msaId,
          );
          if (cerberusStatsMsa) {
            accum.push(cerberusStatsMsa);
          } else {
            logWarning(
              'useCerberusStatsAllMsasWithHpi: Could not find matching msa in cerberus data',
            );
          }
          return accum;
        },
        [],
      );
    }
    return undefined;
  }, [leadFeedMsasQuery.data, cerberusStatsMsasQuery.data]);

  return {
    ...combineUseQueryResult([leadFeedMsasQuery, cerberusStatsMsasQuery]),
    data,
  };
};
