import { useState } from 'react';

import { Card, Menu, MenuItem } from '@hcs/design-system';
import { useOrgIsSelfServe } from '@hcs/self-serve-info';

import { useSelfServiceChangePayment, useSelfServicePortal } from '../../hooks';
import { StripeRedirect } from '../StripeRedirect';

const dataHcName = 'manage-stripe-account';
const REDIRECT_ERROR = {
  subtitle: 'An error occurred while taking you to our payment provider',
  nextAction:
    'Please refresh the page to try again or contact support for help',
};
export const StripePortalRedirect = () => {
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { isError, mutate } = useSelfServicePortal({
    onSuccess: ({ url }) => {
      setRedirectUrl(url || null);
    },
  });

  return (
    <StripeRedirect
      subtitle="We're taking you to our payment provider to manage your account"
      redirectUrl={redirectUrl}
      onRequestUrl={() =>
        mutate({
          success_url: window.location.href,
          cancel_url: window.location.href,
        })
      }
      error={isError ? REDIRECT_ERROR : undefined}
      supportCallout
    />
  );
};

export const StripePaymentMethodRedirect = () => {
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { isError, mutate } = useSelfServiceChangePayment({
    onSuccess: ({ url }) => {
      setRedirectUrl(url || null);
    },
  });

  return (
    <StripeRedirect
      subtitle="We're taking you to our payment provider to change your payment method"
      redirectUrl={redirectUrl}
      onRequestUrl={() =>
        mutate({
          success_url: window.location.href,
          cancel_url: window.location.href,
        })
      }
      error={isError ? REDIRECT_ERROR : undefined}
      supportCallout
    />
  );
};

export const ManageStripeAccount = ({
  onClickChangePlan,
}: {
  onClickChangePlan: VoidFunction;
}) => {
  const { data: isSelfServiceOrg } = useOrgIsSelfServe();
  const [active, setActive] = useState<
    'portal' | 'payment-method' | 'change-plan' | null
  >(null);
  if (!isSelfServiceOrg) return null;
  return (
    <>
      <Card dataHcName={dataHcName}>
        <Menu>
          <MenuItem
            dataHcName={`${dataHcName}-portal`}
            label="Manage Your Account"
            description="Manage your account details, update billing address, and view billing history"
            onClick={() => setActive('portal')}
          />
          <MenuItem
            dataHcName={`${dataHcName}-payment-method`}
            label="Change Payment Method"
            description="Change the payment method charged each billing period"
            onClick={() => setActive('payment-method')}
          />
          <MenuItem
            dataHcName={`${dataHcName}-change-plan`}
            label="Change or Cancel Plan"
            description="Change or cancel the plan that you are subscribed to."
            onClick={onClickChangePlan}
          />
        </Menu>
      </Card>
      {active === 'portal' && <StripePortalRedirect />}
      {active === 'payment-method' && <StripePaymentMethodRedirect />}
    </>
  );
};
