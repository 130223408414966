import { combineReducers } from 'redux';

import { engagementTrackingEventDataSlice } from './eventData.slice';
import { engagementTrackingEventQueueSlice } from './eventQueue.slice';
import { engagementTrackingMeaningfulEventsSlice } from './meaningfulEvents.slice';

export const ENGAGEMENT_TRACKING_REDUCER_KEY = 'engagementTracking';
export const engagementTrackingReducer = combineReducers({
  [engagementTrackingEventQueueSlice.name]:
    engagementTrackingEventQueueSlice.reducer,
  [engagementTrackingEventDataSlice.name]:
    engagementTrackingEventDataSlice.reducer,
  [engagementTrackingMeaningfulEventsSlice.name]:
    engagementTrackingMeaningfulEventsSlice.reducer,
});
