import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import { DirectionalChevronProps } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { TabButton } from '../../tabs/TabButton';
import { CaretDimensions, Popover, PopoverProps } from '../Popover';
import { CaretPosition } from '../Popover/types';

import styles from './TabPopover.module.css';

const TAB_DIMENSIONS: CaretDimensions = {
  anchor: 'edge',
  height: 53,
  width: 47,
};

const TAB_DIMENSIONS_CHEVRON: CaretDimensions = {
  ...TAB_DIMENSIONS,
  width: 75,
};

const DEFAULT_PAGE_PADDING: [number, number, number, number] = [15, 22, 15, 10];
const PHONE_MENU_PAGE_PADDING: [number, number, number, number] = [0, 8, 0, 8];

export interface TabPopoverProps extends PopoverProps {
  buttonName?: string;
  chevron?: DirectionalChevronProps['direction'];
  theme?: PopoverProps['theme'] & {
    Content?: string;
  };
  closeTrigger?: PopoverProps['trigger'];
  inPhoneMenu?: boolean;
}
export const TabPopover = ({
  trigger,
  closeTrigger,
  content,
  theme,
  dataHcName,
  chevron,
  pagePadding,
  buttonName,
  inPhoneMenu = false,
  ...props
}: TabPopoverProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [caretPosition, setCaretPosition] = useState<CaretPosition>();
  const onSetCaretPosition = useCallback(
    (newCaretPosition: CaretPosition) => {
      if (newCaretPosition.left !== caretPosition?.left) {
        setCaretPosition(newCaretPosition);
      }
    },
    [caretPosition],
  );
  const tabDimensions = chevron ? TAB_DIMENSIONS_CHEVRON : TAB_DIMENSIONS;
  const leftAlignedTab =
    caretPosition?.left != null ? caretPosition.left <= 0 : false;
  const contentLeft =
    contentRef.current?.offsetParent?.getBoundingClientRect().left;
  const rightAlignedTab =
    contentRef.current?.offsetWidth != null && caretPosition?.left != null
      ? caretPosition.left + tabDimensions.width >=
        contentRef.current.offsetWidth - (contentLeft || 0)
      : false;
  return (
    <Popover
      {...props}
      pagePadding={
        pagePadding || inPhoneMenu
          ? PHONE_MENU_PAGE_PADDING
          : DEFAULT_PAGE_PADDING
      }
      dataHcName={dataHcName}
      positionOffset={{
        anchorBottom: 44.5,
      }}
      caretDimensions={tabDimensions}
      onSetCaretPosition={onSetCaretPosition}
      theme={{
        ...theme,
        PopoverContentPosition: classNames(
          styles.PopoverContentPosition,
          theme?.PopoverContentPosition,
        ),
      }}
      trigger={
        // TabButton always in closed state
        <TabButton
          dataHcName={`${dataHcName}-button-closed`}
          className={classNames(styles.Button, {
            [styles.withChevron]: !!chevron,
          })}
          onClick={() => undefined}
          isActive={false}
          label={trigger}
          chevron={chevron}
          name={buttonName}
          role="button"
        />
      }
      content={
        <>
          {/* TabButton always in closed state rendered over the closed one above */}
          <TabButton
            style={caretPosition ? { left: caretPosition.left } : undefined}
            dataHcName={`${dataHcName}-button-open`}
            className={classNames(styles.Button, {
              [styles.withChevron]: !!chevron,
            })}
            onClick={() => undefined}
            label={closeTrigger ? closeTrigger : trigger}
            curveLeft={rightAlignedTab || !leftAlignedTab}
            curveRight={!rightAlignedTab}
            chevron={chevron}
            isActive
          />
          <div
            data-hc-name={`${dataHcName}-content`}
            className={classNames(styles.Content, theme?.Content, {
              [styles.rightAlignedTab]: rightAlignedTab,
              [styles.leftAlignedTab]: leftAlignedTab,
            })}
            ref={contentRef}
          >
            {/* ContentInner needed to render over the tab paint */}
            <div className={styles.ContentInner}>{content}</div>
          </div>
        </>
      }
    />
  );
};
