import React, { JSXElementConstructor, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import { Card } from '../../cards/Card';
import { Anchor } from '../../links/Anchor';

import styles from './AgileCard.module.css';

export interface PdfProductCardProps {
  dataHcName: string;
  className?: string;
  productName: string;
  subheading?: string;
  productDescription: string;
  Icon: JSXElementConstructor<IconProps>;
  hasAccess?: boolean;
  onClick: MouseEventHandler;
}
export const AgileCard = (props: PdfProductCardProps) => {
  const {
    dataHcName,
    className,
    productName,
    subheading,
    productDescription,
    Icon,
    hasAccess,
    onClick,
  } = props;
  return (
    <Card
      dataHcName={dataHcName}
      className={classNames(styles.PdfProductCard, className, {
        [styles.noAccess]: !hasAccess,
      })}
      onClick={onClick}
    >
      <div className={styles.Icon}>
        {
          <Icon
            dataHcName={`${dataHcName}-icon`}
            size={50}
            color="neutral-dark-20"
          />
        }
      </div>
      <div>
        <div className={styles.NameCell}>
          <div
            data-hc-name={`${dataHcName}-product-name`}
            className={styles.ProductName}
          >
            {productName}
          </div>
          {subheading && (
            <div
              className={styles.Subheading}
              data-hc-name={`${dataHcName}-product-subheading`}
            >
              {subheading}
            </div>
          )}
        </div>
        <div
          className={styles.ProductDescription}
          data-hc-name={`${dataHcName}-product-description`}
        >
          {productDescription}
        </div>
        <div className={styles.Cta}>
          {hasAccess ? (
            <Anchor dataHcName={`${dataHcName}-launch`}>Order Now</Anchor>
          ) : (
            <Anchor dataHcName={`${dataHcName}-get-access`}>Get Access</Anchor>
          )}
        </div>
      </div>
    </Card>
  );
};
