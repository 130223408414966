import { useMemo } from 'react';

import {
  useFeatureFlagsForUser,
  useReportPreferencesForUser,
} from '@hcs/huell';
import {
  ReportConfig,
  ReportFeatures,
  ReportFeatureTypes,
  ReportId,
} from '@hcs/types';

import {
  REPORT_BASE_PROPERTY_STATE_FIELDS,
  REPORT_FEATURE_CONFIGS,
} from '../constants';
import { fieldsToSupportMapping } from '../utils/reportConfig.utils';

import { useReport } from './useReport';
import { useReportConfigSlice } from './useReportConfigSlice';

export const SearchParamsReportConfig = {
  ReportFeaturesDebug: 'reportFeaturesDebug',
};

export const useReportConfig = (reportId: ReportId) => {
  const { state: reportFeaturesFromSlice } = useReportConfigSlice();
  const { data: featureFlags } = useFeatureFlagsForUser();
  const { data: reportPreferences } = useReportPreferencesForUser();
  const reportQuery = useReport(reportId);
  const { data: report } = reportQuery;
  const reportConfigBase = report?.reportConfig;
  if (report?.reportFeatures && reportConfigBase) {
    // Replace report features on the config with the features that were enabled when the report was created
    reportConfigBase.reportFeatures = report.reportFeatures;
  }
  const reportFeaturesList = useMemo(
    () => reportConfigBase?.reportFeatures || [],
    [reportConfigBase?.reportFeatures],
  );
  const { reportFeaturesSupport, fieldsSupport } = useMemo(() => {
    const vFeatures: ReportConfig['reportFeaturesSupport'] = {};
    const vFields: ReportConfig['fieldsSupport'] = fieldsToSupportMapping(
      REPORT_BASE_PROPERTY_STATE_FIELDS,
    );
    reportFeaturesList.forEach((m) => {
      if (
        report?.reportType &&
        reportConfigBase?.reportFeatures.includes(m) &&
        reportFeaturesFromSlice[m] !== false
      ) {
        vFeatures[m] = true;
      }
      // Check report preferences if the feature is dependent on a report preference
      const reportPreferencesForFeature =
        REPORT_FEATURE_CONFIGS[m]?.reportPreferences;
      const featureFlagForFeature = REPORT_FEATURE_CONFIGS[m]?.featureFlag;
      const fields = REPORT_FEATURE_CONFIGS[m]?.fields;

      if (fields) {
        fields.forEach((f) => {
          vFields[f] = true;
        });
      }
      if (reportPreferencesForFeature) {
        const { supportDefault, values: reportPreferenceValues } =
          reportPreferencesForFeature;
        let enabledByPreferences = false;
        for (const {
          reportPreferencesKey,
          acceptableValues,
        } of reportPreferenceValues) {
          // // Turn feature access off if the user's report preference value is not set to one of accepted values
          if (
            reportPreferences?.[reportPreferencesKey] &&
            acceptableValues.includes(reportPreferences[reportPreferencesKey])
          ) {
            enabledByPreferences = true;
            break;
          }
        }
        if (!enabledByPreferences && !supportDefault) {
          vFeatures[m] = false;
        }
      }

      // Handle report features controlled by feature flags
      if (featureFlagForFeature) {
        const flagSet = featureFlags?.[featureFlagForFeature];
        if (!flagSet) {
          vFeatures[m] = false;
        }
      }
    });
    return { reportFeaturesSupport: vFeatures, fieldsSupport: vFields };
  }, [
    report,
    reportFeaturesFromSlice,
    reportPreferences,
    reportConfigBase,
    reportFeaturesList,
    featureFlags,
  ]);
  const documentRoles = useMemo(() => {
    const v: ReportConfig['documentRoles'] = {};
    Object.keys(reportFeaturesSupport).forEach((reportFeatureStr) => {
      const reportFeature = reportFeatureStr as ReportFeatures;
      if (reportFeature in REPORT_FEATURE_CONFIGS) {
        const documentRoles =
          REPORT_FEATURE_CONFIGS[reportFeature]?.documentRoles;
        documentRoles.forEach((dr) => {
          v[dr] = true;
        });
      }
    });
    return v;
  }, [reportFeaturesSupport]);
  const reportFeaturesByType = useMemo(() => {
    const v: Partial<{ [key in ReportFeatureTypes]: ReportFeatures[] }> = {};
    reportFeaturesList.forEach((reportFeature) => {
      if (!reportFeature) return;
      const type = REPORT_FEATURE_CONFIGS[reportFeature]?.type;
      if (!v[type]) {
        v[type] = [];
      }
      v[type]?.push(reportFeature);
    });
    return v;
  }, [reportFeaturesList]);
  const data: ReportConfig | undefined =
    reportQuery.isInitialLoading || !report?.reportType || !reportConfigBase
      ? undefined
      : {
          ...reportConfigBase,
          reportFeaturesSupport,
          fieldsSupport,
          documentRoles,
          reportFeaturesByType,
          reportType: report.reportType,
        };
  return {
    ...reportQuery,
    data,
  };
};
