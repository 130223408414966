import { useReportPreferencesForUser } from '@hcs/huell';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { DEFAULT_SELECTED_COMP_LIMIT } from '../constants';

import { useCompDocuments } from './useCompDocuments';

export const useSelectedCompsLimit = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const compsQuery = useCompDocuments(reportId, compType);
  const { data: compDocuments } = compsQuery;
  const { data: reportPreferences } = useReportPreferencesForUser();
  const selectedCompsLimit =
    reportPreferences?.selectedCompsLimit || DEFAULT_SELECTED_COMP_LIMIT;
  const hasReachedSelectedCompsLimit =
    (compDocuments?.length || 0) >= selectedCompsLimit;
  const combinedQueryDeps = combineUseQueryResult([compsQuery]);
  return {
    ...combinedQueryDeps,
    data: {
      selectedCompsLimit,
      hasReachedSelectedCompsLimit,
    },
  };
};
