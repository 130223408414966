import { useMemo } from 'react';

import { useAppConfigsForOrg } from '@hcs/authz';
import { AccRoleExternalRoleKeys, AppConfig, Role } from '@hcs/types';

import { useOrgRoles } from './useOrgRoles';

export interface ProductAccessRoleConfig {
  role: Role;
  appConfig: AppConfig;
}

export interface OrgRoleGroups {
  productAccessRoles: ProductAccessRoleConfig[];
  omAccessRoles: Role[];
  otherRoles: Role[];
}

export const useOrgRoleGroups = (orgId: number | undefined) => {
  const { data: roles } = useOrgRoles(orgId);
  const { data: appConfigs } = useAppConfigsForOrg(orgId);

  const roleGroups: OrgRoleGroups | null = useMemo(() => {
    if (!roles || !appConfigs) {
      return null;
    }
    const groups = roles.reduce<OrgRoleGroups>(
      (acc, role) => {
        if (role.type === 'product-access') {
          const applicationKey =
            role.applications.length === 1 ? role.applications[0] : null;
          const appConfig = appConfigs?.find(
            (c) => c.applicationKey === applicationKey,
          );
          if (appConfig?.rootPath) {
            acc.productAccessRoles.push({
              role,
              appConfig,
            });
          }
        } else if (
          role.key === AccRoleExternalRoleKeys.OmViewOrders ||
          role.key === AccRoleExternalRoleKeys.OmCreateOrder
        ) {
          acc.omAccessRoles.push(role);
        } else if (
          role.key === AccRoleExternalRoleKeys.BrokerPartner ||
          role.key === AccRoleExternalRoleKeys.OrgAdmin
        ) {
          acc.otherRoles.push(role);
        }
        return acc;
      },
      { productAccessRoles: [], omAccessRoles: [], otherRoles: [] },
    );
    groups.productAccessRoles.sort((a, b) => {
      const aName = a.role.display_name || '';
      const bName = b.role.display_name || '';
      if (aName > bName) {
        return 1;
      } else if (aName < bName) {
        return -1;
      }
      return 0;
    });
    return groups;
  }, [roles, appConfigs]);
  return roleGroups;
};
