import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';

import { Button } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { ItemObjInShoppingCart, MeaningfulEventTypes } from '@hcs/types';
import { DexpEndpoint, DexpTemplateFormValues } from '@hcs/types';
import { DexpTemplate } from '@hcs/types';

import {
  DexpTemplateForUser,
  useDexpTemplatesForUser,
} from '../../hooks/useDexpTemplatesForUser';
import { usePatchDexpTemplatesForUser } from '../../hooks/usePatchDexpTemplatesForUser';
import { EndpointsDataTable } from '../EndpointsDataTable';
import { SaveAnalysisNameInput } from '../SaveAnalysisNameInput/SaveAnalysisNameInput';

import styles from './SaveAnalysisTemplate.module.css';

export interface SaveAnalysisTemplateProps {
  className?: string;
  templateId?: string;
  endpointsData: Record<DexpEndpoint['id'], ItemObjInShoppingCart>;
  onCancel?: VoidFunction;
  onSubmit?: (template: DexpTemplateForUser) => void;
}

const dataHcName = 'save-analysis-template';
export const dataHcEventSectionDexpTemplateForm = 'DEXP Template Form';
export const SaveAnalysisTemplate = ({
  className,
  templateId: templateIdProp,
  endpointsData,
  onSubmit,
  onCancel,
}: SaveAnalysisTemplateProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [templateId, setTemplateId] = useState(
    templateIdProp || new Date().getTime().toString(),
  );
  const { data: dexpTemplates } = useDexpTemplatesForUser();
  const existingTemplate = dexpTemplates?.[templateId];
  const { mutate: patchDexpTemplates } = usePatchDexpTemplatesForUser();
  const dexpTemplateNames = Object.entries(dexpTemplates || {}).map(
    (template) => template[1].name,
  );
  const DexpTemplateFormValidationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .max(255)
      .matches(/[a-zA-Z]/, 'Name must contain at least one letter')
      .notOneOf(dexpTemplateNames, 'Name must be unique'),
  });
  const methods = useForm<DexpTemplateFormValues>({
    mode: 'onChange',
    resolver: yupResolver(DexpTemplateFormValidationSchema),
    defaultValues: {
      name: existingTemplate?.name,
    },
  });
  const name = methods.watch('name');
  useEffect(() => {
    if (existingTemplate && existingTemplate.templateId !== templateId) {
      setTemplateId(existingTemplate.templateId);
    }
  }, [existingTemplate, templateId]);
  const handleSave: SubmitHandler<DexpTemplateFormValues> = ({ name }) => {
    const template: DexpTemplateForUser = {
      templateId,
      name,
      endpointsData,
    };
    const endpoints = Object.keys(endpointsData);
    const templateForApi: DexpTemplate = {
      templateId,
      name,
      endpoints,
    };

    patchDexpTemplates([
      {
        op: 'add',
        path: `/${templateId}`,
        value: templateForApi,
      },
    ]);
    onSubmit?.(template);
  };

  const endpointIdsArr = Object.keys(endpointsData);

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSectionDexpTemplateForm}
      className={classNames(styles.SaveAnalysisTemplate, className)}
    >
      {confirmDelete ? (
        <>
          <p className={styles.DeleteConfirmMessage}>
            Are you sure you want to delete this template?
          </p>
          <ActionButtons dataHcName={`${dataHcName}-actions`}>
            <Button
              dataHcName={`${dataHcName}-cancel`}
              label="Cancel"
              secondary
              onClick={onCancel}
            />
            <Button
              dataHcName={`${dataHcName}-delete`}
              dataHcEventType={MeaningfulEventTypes.Goal}
              dataHcEventName="DEXP Template Delete"
              label="Delete"
              onClick={() => {
                onCancel?.();
                patchDexpTemplates([
                  {
                    op: 'remove',
                    path: `/${templateId}`,
                  },
                ]);
              }}
            />
          </ActionButtons>
        </>
      ) : (
        <>
          <div className={styles.InputRow}>
            <FormProvider {...methods}>
              <form className={styles.Form}>
                <SaveAnalysisNameInput className={styles.Name} />
              </form>
            </FormProvider>
            {existingTemplate && (
              <TextButton
                dataHcName={`${dataHcName}-delete`}
                onClick={() => setConfirmDelete(true)}
              >
                Delete
              </TextButton>
            )}
          </div>
          <EndpointsDataTable
            endpointLevel="all"
            filters={{ included: endpointIdsArr }}
            selectable={false}
          />
          <ActionButtons dataHcName={`${dataHcName}-actions`}>
            <Button
              dataHcName={`${dataHcName}-cancel`}
              label="Cancel"
              secondary
              onClick={onCancel}
            />
            <Button
              dataHcName={`${dataHcName}-save`}
              dataHcEventType={MeaningfulEventTypes.Goal}
              dataHcEventName={
                existingTemplate ? 'DEXP Template Edit' : 'DEXP Template Create'
              }
              label="Save"
              onClick={() => methods.handleSubmit(handleSave)()}
              disabled={
                !endpointIdsArr.length ||
                (existingTemplate && existingTemplate.name === name) ||
                !methods.formState.isValid
              }
            />
          </ActionButtons>
        </>
      )}
    </div>
  );
};
