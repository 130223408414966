import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { useNavigateToPortfolio } from '../../hooks/useNavigateToPortfolio';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './ProductCards.module.css';
const dataHcName = 'portfolio-card';

interface PortfolioProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

export const PortfolioProductCard = ({
  navigateToSSSelectPlan,
}: PortfolioProductCardProps) => {
  const appConfigExtended = useAppConfigExtended(AppSlugs.PortfolioMonitor);
  const { active, handleOpen, handleClose } = useActiveState();
  const navigateToPortfolio = useNavigateToPortfolio();
  if (!appConfigExtended) return null;
  const { hasAccess, appConfig } = appConfigExtended;
  const { name, descriptionLong, Icon } = appConfig;
  const handleClick = () => {
    if (hasAccess) {
      return navigateToPortfolio();
    } else {
      return handleOpen();
    }
  };
  return (
    <>
      <ProductCard
        dataHcName={dataHcName}
        theme={{
          ProductCard: styles.ProductCard,
        }}
        productName={name}
        productDescription={descriptionLong}
        hasAccess={!!hasAccess}
        Icon={Icon}
        onClick={handleClick}
      />
      <ProductPreviewDialog
        active={active}
        appConfigExtended={{ ...appConfigExtended, hasAccess }}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleClose}
      />
    </>
  );
};
