import camelcaseKeys from 'camelcase-keys';

import { AxiosAccessTokenClientEhrm, AxiosClient } from '@hcs/http-clients';
import {
  CheckInvitationCodeResponse,
  OrganizationWithApplicationsLegacy,
  RolesResponse,
  SingleUseTokenResponse,
  UserContactInfo,
} from '@hcs/types';
import { EHRMANTRAUT_URL } from '@hcs/urls';

export const AuthApi = {
  fetchCurrentOrg: async () => {
    const response =
      await AxiosAccessTokenClientEhrm.get<OrganizationWithApplicationsLegacy>(
        `${EHRMANTRAUT_URL}/auth/current-organization`,
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  getUserContactInfo: async () => {
    const userInfoResponse =
      await AxiosAccessTokenClientEhrm.get<UserContactInfo>(
        `${EHRMANTRAUT_URL}/auth/contact-info`,
      );
    return camelcaseKeys(userInfoResponse.data, {
      deep: true,
    });
  },
  fetchSystemRoles: async () => {
    const makeRoleRequest = async () => {
      const response = await AxiosAccessTokenClientEhrm.get<RolesResponse>(
        `${EHRMANTRAUT_URL}/admin/role?results_per_page=100`,
      );
      return camelcaseKeys(response.data, { deep: true });
    };
    return await makeRoleRequest();
  },
  checkInvitationCode: async (invitationCode: string) => {
    const response = await AxiosClient.get<CheckInvitationCodeResponse>(
      `${EHRMANTRAUT_URL}/invitation/code/${invitationCode}`,
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchSingleUseToken: async () => {
    const response =
      await AxiosAccessTokenClientEhrm.get<SingleUseTokenResponse>(
        `${EHRMANTRAUT_URL}/auth/single-use-token`,
      );
    return camelcaseKeys(response.data);
  },
};
