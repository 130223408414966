import { useCallback, useState } from 'react';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { QUERY_KEY_SELF_SERVICE_COUNTS } from '@hcs/self-service';
import { Report, ReportEventTypes, ReportId, ReportStatus } from '@hcs/types';

import { ReportApi } from '../api';

import { useSubscribeToReportEvents } from './useSubscribeToReportEvents';

export const QUERY_KEY_REPORT = 'report-api-report';
export const generateQueryKeyReport = (reportId: ReportId) => {
  return [QUERY_KEY_REPORT, reportId];
};
export const useReport = (
  reportId: ReportId,
  options?: UseQueryOptions<
    Report,
    AxiosError<{
      status: string;
      message?: string;
      expired?: string;
    }>,
    Report,
    ReportId[]
  >,
) => {
  const queryClient = useQueryClient();
  const [prevStatus, setPrevStatus] = useState<ReportStatus | undefined>();
  useSubscribeToReportEvents({
    callbackId: QUERY_KEY_REPORT,
    onMessage: useCallback(
      // Handler to update the query cache for this hook when we receive relevant SSEs
      (reportEvent) => {
        const queryKey = generateQueryKeyReport(reportId);
        const reportStatus = reportEvent.report.status;
        if (reportEvent.report.id === reportId) {
          // Update report metadata when receiving SSEs about the report
          if (reportEvent.type === ReportEventTypes.DocumentsUpdated) {
            const cacheData = queryClient.getQueryData<Report>(queryKey);
            if (cacheData) {
              queryClient.setQueryData<Report>(queryKey, {
                ...cacheData,
                status: reportEvent.report.status,
              });
            }
          } else if (reportEvent.type === ReportEventTypes.DocumentsRefreshed) {
            // Only refresh document data after the refresh action is done
            if (reportEvent.patchStatus === 'DONE') {
              queryClient.refetchQueries(queryKey);
            }
          } else {
            const cachedReport = queryClient.getQueryData<Report>(queryKey);
            queryClient.setQueryData<Report>(queryKey, {
              ...cachedReport,
              ...reportEvent.report,
            });
          }
        }
        // Update usage and self service data when new report is created
        if (
          reportStatus !== prevStatus &&
          reportStatus === ReportStatus.Completed
        ) {
          queryClient.invalidateQueries([QUERY_KEY_SELF_SERVICE_COUNTS]);
          // accessible applications - pepx potentially updated with legacy self service report gen
          queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        } else if (reportStatus === ReportStatus.Error) {
          const cachedReport = queryClient.getQueryData<Report>(queryKey);
          if (cachedReport) {
            queryClient.setQueryData<Report>(queryKey, {
              ...cachedReport,
              status: reportStatus,
            });
          }
        }

        setPrevStatus(reportStatus);
      },
      [reportId, prevStatus, setPrevStatus, queryClient],
    ),
  });
  return useQuery(
    generateQueryKeyReport(reportId),
    async () => {
      const report = await ReportApi.fetchReport(reportId);
      return {
        ...report,
      };
    },
    options,
  );
};
