import { useQuery } from '@tanstack/react-query';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';

import { AuthnApi } from '../api';

export const useAccount = () => {
  return useQuery(
    [ACCOUNT_QUERY_KEY],
    async () => {
      return await AuthnApi.fetchAccount();
    },
    {
      retry: 1,
    },
  );
};
