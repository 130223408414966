import {
  HcsAdminOrgApiFilter,
  HcsAdminOrgApiParams,
  HcsAdminOrgApiRateLimitMultiplier,
  HcsAdminOrgFilterFields,
  HcsAdminOrgOptions,
} from '@hcs/types';
import { camelCaseToSnakeCase, formatNumber } from '@hcs/utils';

import {
  HCS_ADMIN_ORG_FILTER_OPS,
  HCS_ADMIN_PAGE_SIZE_DEFAULT,
} from '../constants/hcsAdmin.constants';

import { stringifyHcsAdminFilters } from './hcsAdminFilters.utils';

export const prepHcsAdminOrgFiltersForApi = (
  allFilters: HcsAdminOrgOptions | undefined,
): HcsAdminOrgApiParams => {
  const { pagination, filters } = allFilters || {};
  const { sortBy, sortOrder, page, pageSize } = pagination || {};
  const apiFilters: HcsAdminOrgApiFilter[] = [];
  let hasM3terFilter: boolean | null = null;
  let hasZuoraFilter: boolean | null = null;
  let hasSalesforceFilter: boolean | null = null;
  let hasStripeFilter: boolean | null = null;
  let otherSalesforceFilterSet = false;
  let otherStripeFilterSet = false;
  let otherM3terFilterSet = false;
  let otherZuoraFilterSet = false;
  for (const nameStr in filters) {
    const name = nameStr as HcsAdminOrgFilterFields;
    const val = filters[name];

    if (name === 'active' && typeof val === 'boolean') {
      apiFilters.push({
        name: camelCaseToSnakeCase(name),
        val,
        op: 'eq',
      });
    } else if (name === 'type' && typeof val === 'string') {
      apiFilters.push({
        name: 'type',
        val,
        op: 'eq',
      });
    } else if (name === 'activeSubscriptions' && typeof val === 'boolean') {
      hasZuoraFilter = val;
    } else if (name.indexOf('z_') !== -1) {
      otherZuoraFilterSet = true;
    } else if (name !== 'active' && typeof val === 'string') {
      apiFilters.push({
        name: camelCaseToSnakeCase(name),
        val: `%${val}%`,
        op: HCS_ADMIN_ORG_FILTER_OPS[name],
      });
    } else if (name === 'hasM3ter' && typeof val === 'boolean') {
      hasM3terFilter = val;
    } else if (name.indexOf('3ter') !== -1) {
      otherM3terFilterSet = true;
    } else if (name === 'hasSalesforceAccount' && typeof val === 'boolean') {
      hasSalesforceFilter = val;
    } else if (name.indexOf('salesforce') !== -1) {
      otherSalesforceFilterSet = true;
    } else if (name === 'hasStripeAccount' && typeof val === 'boolean') {
      hasStripeFilter = val;
    } else if (name === 'stripeCustomerId') {
      otherStripeFilterSet = true;
    }
  }
  if (hasZuoraFilter && !otherZuoraFilterSet) {
    apiFilters.push({
      name: 'z_account_number',
      op: HCS_ADMIN_ORG_FILTER_OPS.activeSubscriptions,
      val: '',
    });
  }
  if (hasM3terFilter && !otherM3terFilterSet) {
    apiFilters.push({
      name: 'm3ter_account_id',
      op: HCS_ADMIN_ORG_FILTER_OPS.hasM3ter,
      val: '',
    });
  }
  if (hasSalesforceFilter && !otherSalesforceFilterSet) {
    apiFilters.push({
      name: 'salesforce_account_id',
      op: HCS_ADMIN_ORG_FILTER_OPS.hasSalesforceAccount,
      val: '',
    });
  }
  if (hasStripeFilter && !otherStripeFilterSet) {
    apiFilters.push({
      name: 'stripe_customer_id',
      op: HCS_ADMIN_ORG_FILTER_OPS.hasStripeAccount,
      val: '',
    });
  }
  return stringifyHcsAdminFilters({
    page: page || 1,
    resultsPerPage: pageSize || HCS_ADMIN_PAGE_SIZE_DEFAULT,
    q: {
      filters: apiFilters,
      orderBy: [
        {
          field: sortBy || 'name',
          direction:
            (sortOrder?.toLowerCase() as 'asc' | 'desc' | undefined) || 'asc',
        },
      ],
    },
  });
};

export const hcsAdminOrgApiRateLimitDurationDisplay = (val: number) => {
  if (val === 0) {
    return '0 days';
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.day === 0) {
    return `${formatNumber(val / HcsAdminOrgApiRateLimitMultiplier.day)} days`;
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.hour === 0) {
    return `${formatNumber(
      val / HcsAdminOrgApiRateLimitMultiplier.minute,
    )} hours`;
  }

  if (val % HcsAdminOrgApiRateLimitMultiplier.minute === 0) {
    return `${formatNumber(
      val / HcsAdminOrgApiRateLimitMultiplier.minute,
    )} minutes`;
  }

  return `${val} seconds`;
};
