import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import { SavedCompFilterSet } from '@hcs/types';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { CompFiltersAll, ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { isAppliedFilterNotSavable } from '../utils';

import { useCompsFiltersDocument } from './useCompsFiltersDocument';

// This hook returns the saved filter set if one is selected
// We can't rely on the value saved to the document because
// the saved filter set may have been edited on another report
export const useAppliedSavedCompFilterSet = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const filterSetQuery = useSavedCompFilterSetsForUser(compType);
  const filterDocQuery = useCompsFiltersDocument(reportId, compType);
  const appliedFilters = filterDocQuery.data?.data.filters;
  const unsavableFilters = useMemo(() => {
    const unsavable: (PropertyStateFields | CompFields)[] = [];
    for (const filterKeyStr in appliedFilters) {
      const filterKey = filterKeyStr as keyof CompFiltersAll;
      const appliedFilterValue = appliedFilters[filterKey];
      if (appliedFilterValue && isAppliedFilterNotSavable(appliedFilterValue)) {
        unsavable.push(filterKey);
      }
    }
    return unsavable;
  }, [appliedFilters]);
  const numSavableAppliedFilters = useMemo(() => {
    let count = 0;
    for (const filterKeyStr in appliedFilters) {
      const filterKey = filterKeyStr as keyof CompFiltersAll;
      const appliedFilterValue = appliedFilters[filterKey];
      if (
        appliedFilterValue &&
        !isAppliedFilterNotSavable(appliedFilterValue)
      ) {
        count++;
      }
    }
    return count;
  }, [appliedFilters]);
  return {
    ...combineUseQueryResult([filterSetQuery, filterDocQuery]),
    data: useMemo(() => {
      if (filterSetQuery.data && appliedFilters) {
        const possibleFilterSets = new Map(Object.entries(filterSetQuery.data));
        const numAppliedFilters = Object.keys(appliedFilters).length;
        // Must check all saved filter sets
        possibleFilterSets.forEach((filterSet, filterSetId) => {
          if (
            filterSet.values &&
            Object.keys(filterSet.values).length === numAppliedFilters
          ) {
            for (const key in filterSet.values) {
              const field = key as keyof SavedCompFilterSet['values'];
              const savedValue = filterSet.values[field]?.relativeValue;
              const relativeValue = appliedFilters[field]?.relativeValue;
              // Only the order of NumberRange matters,
              // other array type values just need to have the same members,
              // so we sort them first before comparing
              const compareA =
                Array.isArray(savedValue) && typeof savedValue[0] === 'string'
                  ? [...savedValue].sort()
                  : savedValue;
              const compareB =
                Array.isArray(relativeValue) &&
                typeof relativeValue[0] === 'string'
                  ? [...relativeValue].sort()
                  : relativeValue;
              if (!isEqual(compareA, compareB)) {
                possibleFilterSets.delete(filterSetId);
                break;
              }
            }
          } else {
            possibleFilterSets.delete(filterSetId);
          }
        });
        const appliedFilterSetId = possibleFilterSets.entries().next()
          .value?.[0] as string | undefined;
        const appliedFilterSet =
          appliedFilterSetId && possibleFilterSets.get(appliedFilterSetId);
        if (appliedFilterSet) {
          return {
            appliedFilterSetId,
            appliedFilterSet,
            numSavableAppliedFilters,
            unsavableFilters,
          };
        }
      }
      return {
        appliedFilterSetId: undefined,
        appliedFilterSet: undefined,
        numSavableAppliedFilters,
        unsavableFilters,
      };
    }, [filterSetQuery.data, appliedFilters]),
  };
};
