import { DocumentRoles, ReportId, SubjectDocument } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSubjectDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<SubjectDocument>(
    reportId,
    DocumentRoles.Subject,
    {
      includeUserPropertyAdjustments: true,
    },
  );
};
