import { JSXElementConstructor, ReactNode } from 'react';

import {
  AdvancedApiIcon,
  AvmReportsIncludedIcon,
  CoreApiIcon,
  CustomerSupportIcon,
  CustomQuestionnairesIcon,
  IncludedUsersIcon,
  NationwideBrokerageIcon,
  ProductGridIcon,
  PropertiesIcon,
  ScalableDatasetIcon,
  Soc2Icon,
  SolutionsEngineerIcon,
  SsoIcon,
  ValuationReportsIncludedIcon,
} from '@hcs/design-system';
import { APP_CONFIG_CANARYAI } from '@hcs/hc-products';
import { IconProps, SelfServicePlanTier } from '@hcs/types';
import { formatNumber } from '@hcs/utils';
export type SelfServeCostUnits =
  | 'user'
  | 'report'
  | 'successful call'
  | 'export'
  | 'property/month';
type SelfServiceMarketingConfig = {
  key: string;
  name: string;
  unit?: SelfServeCostUnits;
};
type SelfServiceMarketingCostConfig = {
  key: string;
  name: string;
  unit: SelfServeCostUnits;
  discount?: boolean;
};
type PlanMarketingFeatureConfigMapping = Record<
  string,
  PlanMarketingFeatureConfig
>;
type SelfServiceMarketingConfigMapping = Record<
  string,
  SelfServiceMarketingConfig
>;
type PlanTierMarketingConfigMapping = Record<
  SelfServicePlanTier,
  PlanTierMarketingConfig
>;
interface IncludedConfig {
  key: string;
  value: string | number | null;
}
interface ProductConfig {
  key: string;
  value: boolean | 'partial';
}
interface CostConfig {
  key: string;
  value: number | string | null;
}
interface PlanTierMarketingConfig {
  name: string;
  description: string;
  key: SelfServicePlanTier;
  features: (string | { key: string; value: number })[];
  included: Record<string, IncludedConfig>;
  products: Record<string, ProductConfig>;
  costs: Record<string, CostConfig | undefined>;
}

interface PlanMarketingFeatureConfig {
  key: string;
  Icon: JSXElementConstructor<IconProps>;
  Label: ReactNode | JSXElementConstructor<{ value: number }>;
}

export const SELF_SERVICE_INCLUDED_UNITS: SelfServiceMarketingConfig[] = [
  { key: 'users', name: 'Users', unit: 'user' },
  {
    key: 'pexp-reports',
    name: 'Custom Valuation Reports (Property Explorer)',
    unit: 'report',
  },
  {
    key: 'agile-insights',
    name: 'AVM PDF Reports (Agile Insights)',
    unit: 'report',
  },
  {
    key: 'portfolio-properties',
    name: 'Properties Included (Portfolio Monitoring)',
    unit: 'property/month',
  },
];
export const SELF_SERVICE_INCLUDED_UNITS_MAPPING: SelfServiceMarketingConfigMapping =
  SELF_SERVICE_INCLUDED_UNITS.reduce((acc, { key, name }) => {
    acc[key] = { key, name };
    return acc;
  }, {} as SelfServiceMarketingConfigMapping);

export const SELF_SERVICE_PRODUCTS: SelfServiceMarketingConfig[] = [
  {
    key: 'pexp-reports',
    name: 'Custom Valuation Reports (Property Explorer)',
  },
  { key: 'canaryai', name: 'CanaryAI' },
  { key: 'bpo-reports', name: 'Exterior Broker Price Opinions (BPOs)' },
  { key: 'dexp', name: 'Match and Append (Data Explorer)' },
  { key: 'agile-insights', name: 'AVM PDF Reports (Agile Insights)' },
  { key: 'market-insights', name: 'Market Insights' },
  { key: 'aexp', name: 'Custom Buybox Property Search (Acquisition Explorer)' },
  {
    key: 'agile-eval-ext',
    name: 'Exterior Onsite Evaluations (Agile Evaluation)',
  },
  { key: 'insp-only-ext', name: 'Exterior Onsite Inspections' },
  { key: 'dexp-api', name: 'Property Analytics API' },
  { key: 'portfolio', name: 'Portfolio Monitoring' },
  { key: 'property-estimate-api', name: 'Property Estimate Solutions' },
  { key: 'value-check', name: 'Interactive Value Check' },
  { key: 'build-to-api', name: 'Build to Rent Solutions' },
  { key: 'bulk-property-data', name: 'Bulk Property Data' },
  { key: 'aexp-deliveries', name: 'Programmatic Listing Updates' },
  { key: 'bulk-hpi-rpi', name: 'Nationwide Bulk Price and Rental Forecasts' },
  { key: 'report-api', name: 'Property Explorer API' },
  { key: 'value-check-api', name: 'Value Check API' },
  { key: 'agile-eval-int', name: 'Interior Onsite Evaluations' },
  { key: 'insp-only-int', name: 'Interior Onsite Inspections' },
  { key: 'comehome', name: 'ComeHome Engagement Platform' },
];
export const SELF_SERVICE_PRODUCTS_MAPPING: SelfServiceMarketingConfigMapping =
  SELF_SERVICE_PRODUCTS.reduce((acc, { key, name }) => {
    acc[key] = { key, name };
    return acc;
  }, {} as SelfServiceMarketingConfigMapping);

export const SELF_SERVICE_COSTS: SelfServiceMarketingCostConfig[] = [
  {
    key: 'additional-active-users',
    name: 'Additional Active Monthly Users',
    unit: 'user',
  },
  {
    key: 'pexp-reports',
    name: 'Custom Valuation Reports (Property Explorer)',
    unit: 'report',
  },
  {
    key: 'agile-insights',
    name: 'AVM PDF Reports (Agile Insights)',
    unit: 'report',
  },
  {
    key: 'data-points-basic',
    name: 'Property Analytics API - Basic Endpoints',
    unit: 'successful call',
  },
  {
    key: 'data-points-premium',
    name: 'Property Analytics API - Premium Endpoints',
    unit: 'successful call',
  },
  {
    key: 'data-points-premium-plus',
    name: 'Property Analytics API - Premium Plus Endpoints',
    unit: 'successful call',
  },
  {
    key: 'canary-ai-export',
    name: 'CanaryAI Data Export',
    unit: 'export',
    discount: true,
  },
  {
    key: 'bpo-reports',
    name: 'Exterior Broker Price Opinions (BPOs)',
    unit: 'report',
  },
  {
    key: 'agile-eval-ext',
    name: 'Exterior Onsite Evaluations (Agile Evaluation)',
    unit: 'report',
  },
  {
    key: 'agile-eval-int',
    name: 'Interior Onsite Evaluations (Agile Evaluation)',
    unit: 'report',
  },
  { key: 'insp-only-ext', name: 'Exterior Onsite Inspections', unit: 'report' },
  { key: 'insp-only-int', name: 'Interior Onsite Inspections', unit: 'report' },
  {
    key: 'properties-monitored',
    name: "Add'l Properties - Portfolio Monitoring",
    unit: 'property/month',
  },
  {
    key: 'property-estimate-api',
    name: 'Property Estimate API',
    unit: 'successful call',
  },
  { key: 'value-check', name: 'Interactive Value Check', unit: 'report' },
  { key: 'build-to-api', name: 'Build to Rent API', unit: 'successful call' },
];

const reduceToMap = <T,>(arr: { key: string; value: T }[]) => {
  return arr.reduce(
    (acc, { key, value }) => {
      acc[key] = { key, value };
      return acc;
    },
    {} as Record<string, { key: string; value: T }>,
  );
};
export const SELF_SERVICE_COSTS_MAPPING: SelfServiceMarketingConfigMapping =
  SELF_SERVICE_COSTS.reduce((acc, { key, name }) => {
    acc[key] = { key, name };
    return acc;
  }, {} as SelfServiceMarketingConfigMapping);

const SELF_SERVICE_FEATURES: PlanMarketingFeatureConfig[] = [
  {
    key: 'included-users',
    Icon: IncludedUsersIcon,
    Label: ({ value }) => (
      <>
        <b>{formatNumber(value)}</b>{' '}
        {value === 1 ? 'user included' : 'active monthly users'}
      </>
    ),
  },
  {
    key: 'included-pexp',
    Icon: ValuationReportsIncludedIcon,
    Label: ({ value }) => (
      <>
        <b>{formatNumber(value)}</b> custom valuation reports included per month
        at no extra cost
      </>
    ),
  },
  {
    key: 'included-agile-insights',
    // TODO: Icon
    Icon: AvmReportsIncludedIcon,
    Label: ({ value }) => (
      <>
        <b>{formatNumber(value)}</b> AVM PDF reports included per month at no
        extra cost
      </>
    ),
  },
  {
    key: 'included-portfolio',
    // TODO: Icon
    Icon: PropertiesIcon,
    Label: ({ value }) => (
      <>
        Monitoring for <b>{formatNumber(value)}</b> properties included at no
        extra cost
      </>
    ),
  },
  {
    key: 'canaryai-full',
    Icon: APP_CONFIG_CANARYAI.Icon,
    Label: 'Full access to CanaryAI',
  },
  {
    key: 'canaryai-trial',
    Icon: APP_CONFIG_CANARYAI.Icon,
    Label: 'Limited trial to CanaryAI',
  },
  {
    key: 'in-app-support',
    Icon: CustomerSupportIcon,
    Label: 'Robust knowledge base and in-app customer support',
  },
  {
    key: 'brokerage-services',
    Icon: NationwideBrokerageIcon,
    Label: 'Nationwide real estate brokerage services included',
  },
  {
    key: 'custom-users',
    Icon: IncludedUsersIcon,
    Label: (
      <>
        <b>Custom</b> user limits
      </>
    ),
  },
  {
    key: 'custom-pricing',
    Icon: ValuationReportsIncludedIcon,
    Label: (
      <>
        <b>Custom</b> pricing and product inclusion with negotiable minimums
      </>
    ),
  },
  {
    key: 'all-products-access',
    Icon: ProductGridIcon,
    Label: 'Access to all products within the HouseCanary platform',
  },
  {
    key: 'most-products-access',
    Icon: ProductGridIcon,
    Label: 'Access to most products within the HouseCanary platform',
  },
  {
    key: 'core-api-access',
    Icon: CoreApiIcon,
    Label: "Access to HouseCanary's core API for scalable data access",
  },
  {
    key: 'advanced-feature-access',
    Icon: AdvancedApiIcon,
    Label: 'Access to advanced APIs and platform features',
  },
  {
    key: 'custom-products',
    Icon: ScalableDatasetIcon,
    Label: 'Access to additional custom products and scalable datasets',
  },
  {
    key: 'solutions-engineering',
    Icon: SolutionsEngineerIcon,
    Label: 'Exposure to Solutions Engineering and Research teams',
  },
  {
    key: 'sso',
    Icon: SsoIcon,
    Label: 'Configurable SSO',
  },
  {
    key: 'soc2',
    Icon: Soc2Icon,
    Label: 'Access to SOC2 reports by request',
  },
  {
    key: 'custom-compliance',
    Icon: CustomQuestionnairesIcon,
    Label: 'Custom compliance questionnaires',
  },
];
export const SELF_SERVICE_FEATURES_MAPPING: PlanMarketingFeatureConfigMapping =
  SELF_SERVICE_FEATURES.reduce((acc, featureConfig) => {
    acc[featureConfig.key] = featureConfig;
    return acc;
  }, {} as PlanMarketingFeatureConfigMapping);

export const SELF_SERVICE_TIERS: PlanTierMarketingConfig[] = [
  {
    name: 'Basic',
    description: 'Best for individuals with basic needs for real estate data.',
    key: 'Basic',
    features: [
      { key: 'included-users', value: 1 },
      { key: 'included-pexp', value: 2 },
      'canaryai-trial',
      'in-app-support',
      'brokerage-services',
      'most-products-access',
    ],
    included: reduceToMap<IncludedConfig['value']>([
      { key: 'users', value: 1 },
      { key: 'pexp-reports', value: 2 },
    ]),
    products: reduceToMap<ProductConfig['value']>([
      { key: 'pexp-reports', value: true },
      { key: 'canaryai', value: 'partial' },
      { key: 'market-insights', value: true },
      { key: 'aexp', value: true },
      { key: 'agile-eval-ext', value: true },
      { key: 'agile-insights', value: true },
      { key: 'bpo-reports', value: true },
      { key: 'dexp', value: true },
      { key: 'insp-only-ext', value: true },
    ]),
    costs: reduceToMap<CostConfig['value']>([
      {
        key: 'pexp-reports',
        value: 12.0,
      },
      {
        key: 'agile-insights',
        value: 10.0,
      },
      {
        key: 'data-points-basic',
        value: 0.5,
      },
      {
        key: 'data-points-premium',
        value: 4.0,
      },
      {
        key: 'data-points-premium-plus',
        value: 6.0,
      },
      { key: 'canary-ai-export', value: 'Custom per export' },
      {
        key: 'bpo-reports',
        value: 89,
      },
      {
        key: 'agile-eval-ext',
        value: 75,
      },
      {
        key: 'insp-only-ext',
        value: 50,
      },
    ]),
  },
  {
    name: 'Pro',
    description:
      'Best for pros looking for industry-leading real estate tools.',
    key: 'Pro',
    features: [
      { key: 'included-users', value: 1 },
      { key: 'included-pexp', value: 15 },
      { key: 'included-agile-insights', value: 15 },
      { key: 'included-portfolio', value: 25 },
      'canaryai-full',
      'in-app-support',
      'brokerage-services',
      'all-products-access',
      'core-api-access',
    ],
    included: reduceToMap<IncludedConfig['value']>([
      { key: 'users', value: 1 },
      { key: 'pexp-reports', value: 15 },
      { key: 'agile-insights', value: 15 },
      { key: 'portfolio-properties', value: 25 },
    ]),
    products: reduceToMap<ProductConfig['value']>([
      { key: 'pexp-reports', value: true },
      { key: 'market-insights', value: true },
      { key: 'aexp', value: true },
      { key: 'agile-eval-ext', value: true },
      { key: 'agile-insights', value: true },
      { key: 'bpo-reports', value: true },
      { key: 'dexp', value: true },
      { key: 'insp-only-ext', value: true },
      { key: 'canaryai', value: true },
      { key: 'portfolio', value: true },
      { key: 'dexp-api', value: true },
    ]),
    costs: reduceToMap<CostConfig['value']>([
      {
        key: 'pexp-reports',
        value: 11.0,
      },
      {
        key: 'agile-insights',
        value: 9.0,
      },
      {
        key: 'data-points-basic',
        value: 0.4,
      },
      {
        key: 'data-points-premium',
        value: 3.0,
      },
      {
        key: 'data-points-premium-plus',
        value: 5.0,
      },
      { key: 'canary-ai-export', value: 0.1 },
      {
        key: 'bpo-reports',
        value: 89,
      },
      {
        key: 'agile-eval-ext',
        value: 75,
      },
      {
        key: 'insp-only-ext',
        value: 50,
      },
      { key: 'properties-monitored', value: 0.45 },
    ]),
  },
  {
    name: 'Teams',
    description:
      'For small-medium sized teams looking for a complete real estate solution.',
    key: 'Teams',
    features: [
      { key: 'included-users', value: 10 },
      { key: 'included-pexp', value: 40 },
      { key: 'included-agile-insights', value: 40 },
      { key: 'included-portfolio', value: 50 },
      'canaryai-full',
      'in-app-support',
      'brokerage-services',
      'all-products-access',
      'core-api-access',
      'advanced-feature-access',
      'sso',
      'soc2',
    ],
    included: reduceToMap<IncludedConfig['value']>([
      { key: 'users', value: 10 },
      { key: 'pexp-reports', value: 40 },
      { key: 'agile-insights', value: 40 },
      { key: 'portfolio-properties', value: 50 },
    ]),
    products: reduceToMap<ProductConfig['value']>([
      { key: 'pexp-reports', value: true },
      { key: 'market-insights', value: true },
      { key: 'aexp', value: true },
      { key: 'agile-eval-ext', value: true },
      { key: 'agile-insights', value: true },
      { key: 'bpo-reports', value: true },
      { key: 'dexp', value: true },
      { key: 'insp-only-ext', value: true },
      { key: 'canaryai', value: true },
      { key: 'portfolio', value: true },
      { key: 'dexp-api', value: true },
      { key: 'property-estimate-api', value: true },
      { key: 'value-check', value: true },
      { key: 'build-to-api', value: true },
      { key: 'bulk-property-data', value: false },
    ]),
    costs: reduceToMap<CostConfig['value']>([
      { key: 'additional-active-users', value: 10.0 },
      {
        key: 'pexp-reports',
        value: 9.0,
      },
      {
        key: 'agile-insights',
        value: 7.0,
      },
      {
        key: 'data-points-basic',
        value: 0.3,
      },
      {
        key: 'data-points-premium',
        value: 2.5,
      },
      {
        key: 'data-points-premium-plus',
        value: 4.0,
      },
      { key: 'canary-ai-export', value: 0.2 },
      {
        key: 'bpo-reports',
        value: 89,
      },
      {
        key: 'agile-eval-ext',
        value: 75,
      },
      {
        key: 'insp-only-ext',
        value: 50,
      },
      { key: 'properties-monitored', value: 0.35 },
      { key: 'property-estimate-api', value: 0.05 },
      { key: 'value-check', value: 25.0 },
      { key: 'build-to-api', value: 25.0 },
    ]),
  },
  {
    name: 'Enterprise',
    description:
      'Custom plans for the most sophisticated players in real estate.',
    key: 'Enterprise',
    features: [
      'custom-users',
      'custom-pricing',
      'canaryai-full',
      'in-app-support',
      'brokerage-services',
      'all-products-access',
      'core-api-access',
      'advanced-feature-access',
      'custom-products',
      'solutions-engineering',
      'sso',
      'soc2',
      'custom-compliance',
    ],
    included: reduceToMap<IncludedConfig['value']>([
      { key: 'users', value: 'Custom' },
      { key: 'pexp-reports', value: 'Custom' },
      { key: 'agile-insights', value: 'Custom' },
      { key: 'portfolio-properties', value: 'Custom' },
    ]),
    products: reduceToMap<ProductConfig['value']>([
      { key: 'pexp-reports', value: true },
      { key: 'market-insights', value: true },
      { key: 'aexp', value: true },
      { key: 'agile-eval-ext', value: true },
      { key: 'agile-insights', value: true },
      { key: 'bpo-reports', value: true },
      { key: 'dexp', value: true },
      { key: 'insp-only-ext', value: true },
      { key: 'canaryai', value: true },
      { key: 'portfolio', value: true },
      { key: 'dexp-api', value: true },
      { key: 'property-estimate-api', value: true },
      { key: 'value-check', value: true },
      { key: 'build-to-api', value: true },
      { key: 'aexp-deliveries', value: true },
      { key: 'report-api', value: true },
      { key: 'value-check-api', value: true },
      { key: 'bulk-property-data', value: true },
      { key: 'bulk-hpi-rpi', value: true },
      { key: 'agile-eval-int', value: true },
      { key: 'insp-only-int', value: true },
      { key: 'comehome', value: true },
    ]),
    costs: reduceToMap<CostConfig['value']>([
      { key: 'additional-active-users', value: 'Custom' },
      {
        key: 'pexp-reports',
        value: 'Custom',
      },
      {
        key: 'agile-insights',
        value: 'Custom',
      },
      {
        key: 'data-points-basic',
        value: 'Custom',
      },
      {
        key: 'data-points-premium',
        value: 'Custom',
      },
      {
        key: 'data-points-premium-plus',
        value: 'Custom',
      },
      { key: 'canary-ai-export', value: 'Custom' },
      {
        key: 'bpo-reports',
        value: 'Custom',
      },
      {
        key: 'agile-eval-ext',
        value: 'Custom',
      },
      { key: 'agile-eval-int', value: 'Custom' },
      {
        key: 'insp-only-ext',
        value: 'Custom',
      },
      { key: 'insp-only-int', value: 'Custom' },
      { key: 'properties-monitored', value: 'Custom' },
      { key: 'property-estimate-api', value: 'Custom' },
      { key: 'value-check', value: 'Custom' },
      { key: 'build-to-api', value: 'Custom' },
    ]),
  },
];
export const SELF_SERVICE_TIERS_MAPPING = SELF_SERVICE_TIERS.reduce(
  (acc, { key: keyStr, ...rest }) => {
    const key = keyStr as SelfServicePlanTier;
    acc[key] = { key, ...rest };
    return acc;
  },
  {} as PlanTierMarketingConfigMapping,
);
