import { AppraisalSubjectDocument, DocumentRoles, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useAppraisalSubjectDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<AppraisalSubjectDocument>(
    reportId,
    DocumentRoles.AppraisalSubject,
  );
};
