import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';
import isEmpty from 'lodash/isEmpty';

import { ReportId } from '@hcs/types';

export const QUERY_KEY_OPT_UPDATES = 'report-api-optimistic-updates';

interface OptimisticUpdatesDocumentState {
  [path: string]: number;
}

interface OptimisticUpdatesReportState {
  [documentId: string]: OptimisticUpdatesDocumentState;
}

export interface OptimisticUpdatesState {
  [reportId: ReportId]: OptimisticUpdatesReportState;
}

const INITIAL_STATE: OptimisticUpdatesState = {};

interface OptimisticUpdatesArg {
  reportId: ReportId;
  documentId: string;
  operations: Operation[];
}
export const usePendingOptimisticUpdates = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery([QUERY_KEY_OPT_UPDATES], async () => {
    return { ...INITIAL_STATE };
  });
  return {
    addPendingOperations: ({
      reportId,
      documentId,
      operations,
    }: OptimisticUpdatesArg) => {
      queryClient.setQueryData<OptimisticUpdatesState | undefined>(
        [QUERY_KEY_OPT_UPDATES],
        (cacheData) => {
          const updatedCache: OptimisticUpdatesState = { ...cacheData };
          const reportCache: OptimisticUpdatesReportState =
            updatedCache?.[reportId] || {};
          const documentCache: OptimisticUpdatesDocumentState =
            reportCache[documentId] || {};
          operations.forEach((operation) => {
            if (!documentCache[operation.path]) {
              documentCache[operation.path] = 1;
            } else {
              documentCache[operation.path] += 1;
            }
          });
          reportCache[documentId] = documentCache;
          updatedCache[reportId] = reportCache;
          return updatedCache;
        },
      );
    },
    removePendingOperations: ({
      reportId,
      documentId,
      operations,
    }: OptimisticUpdatesArg) => {
      queryClient.setQueryData<OptimisticUpdatesState | undefined>(
        [QUERY_KEY_OPT_UPDATES],
        (cacheData) => {
          const updatedCache: OptimisticUpdatesState = { ...cacheData };
          const reportCache: OptimisticUpdatesReportState =
            updatedCache?.[reportId] || {};
          const documentCache: OptimisticUpdatesDocumentState | undefined =
            reportCache[documentId];

          if (documentCache) {
            operations.forEach((operation) => {
              if (documentCache[operation.path]) {
                documentCache[operation.path] -= 1;
                if ((documentCache[operation.path] || 1) < 1) {
                  delete documentCache[operation.path];
                }
              }
            });
            reportCache[documentId] = documentCache;
            if (isEmpty(reportCache[documentId])) {
              delete reportCache[documentId];
            }
            updatedCache[reportId] = reportCache;
            if (isEmpty(updatedCache[reportId])) {
              delete updatedCache[reportId];
            }
          }
          return updatedCache;
        },
      );
    },
    data,
  };
};
