import React from 'react';
import classNames from 'classnames';

import { CardTabs, Tab } from '@hcs/design-system';
import { useExperienceFlags } from '@hcs/experience-flags';
import { ReportFeatures, ReportId } from '@hcs/types';
import { ArrayOneOrMore } from '@hcs/types';

import { ReportRiskBreakdown } from '../../features/ReportRiskBreakdown';
import { ReportValueComparison } from '../../features/ReportValueComparison';
import {
  SUBJECT_PROPERTY_DETAILS_SECTION_FEATURES,
  SubjectPropertyDetailsSection,
} from '../../features/SubjectPropertyDetailsSection';
import {
  dataHcEventSectionSubjectTransactionHistoryRental,
  dataHcEventSectionSubjectTransactionHistorySale,
  SUBJECT_TRANSACTION_HISTORY_FEATURES,
  SubjectTransactionHistory,
} from '../../features/SubjectTransactionHistory';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import {
  AVM_BREAKDOWN_FEATURES,
  AvmBreakdown,
  dataHcEventSectionAvmBreakdown,
} from '../AvmBreakdown';
import {
  dataHcEventSectionNeighborhoodInsights,
  NEIGHBORHOOD_INSIGHTS_SECTION_FEATURES,
  NeighborhoodInsightsSection,
} from '../NeighborhoodInsightsSection';
import {
  dataHcEventSectionOpinionOfPriceRental,
  dataHcEventSectionOpinionOfPriceSale,
  USER_OPINION_OF_PRICE_FORM_FEATURES_RENTAL,
  USER_OPINION_OF_PRICE_FORM_FEATURES_SALE,
  UserOpinionOfPriceForm,
} from '../UserOpinionOfPriceForm';

import styles from './SubjectPropertyTabSection.module.css';

const dataHcName = 'subject-tab-section';
interface Props {
  reportId: ReportId;
  className?: string;
  showRentalContent?: boolean;
}
export const SUBJECT_PROPERTY_TAB_SECTION_ID = dataHcName;
export const SUBJECT_PROPERTY_TAB_SECTION_FEATURES_SALE = [
  ...AVM_BREAKDOWN_FEATURES,
  ...SUBJECT_PROPERTY_DETAILS_SECTION_FEATURES,
  ...SUBJECT_TRANSACTION_HISTORY_FEATURES,
];
export const SUBJECT_PROPERTY_TAB_SECTION_FEATURES_RENTAL = [
  ...SUBJECT_PROPERTY_DETAILS_SECTION_FEATURES,
  ...SUBJECT_TRANSACTION_HISTORY_FEATURES,
];
export const TAB_ID_USER_OPINION = 'userOpinion';
export const TAB_ID_PROPERTY_DETAILS = 'propertyDetails';

export const SubjectPropertyTabSection = ({
  reportId,
  className,
  showRentalContent,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const EXPERIENCE_FLAGS = useExperienceFlags();
  const tabs: Tab[] = [];
  if (
    !showRentalContent &&
    reportFeaturesSupportedAny(reportConfig, [
      ReportFeatures.AppraisalSubjectValue,
    ])
  ) {
    tabs.unshift({
      tabId: 'appraisalValueCompare',
      label: 'Value Comparison',
      noPadding: true,
      content: <ReportValueComparison reportId={reportId} />,
    });
  }
  if (
    !showRentalContent &&
    reportFeaturesSupportedAny(reportConfig, [
      ReportFeatures.AppraisalComparison,
    ])
  ) {
    tabs.unshift({
      tabId: 'appraisalRis',
      label: 'Risk Breakdown',
      content: <ReportRiskBreakdown reportId={reportId} />,
    });
  }
  if (
    !showRentalContent &&
    reportFeaturesSupportedAny(reportConfig, AVM_BREAKDOWN_FEATURES)
  ) {
    tabs.push({
      tabId: 'avmBreakdown',
      label: 'AVM Breakdown',
      noPadding: true,
      dataHcEventSection: dataHcEventSectionAvmBreakdown,
      content: <AvmBreakdown reportId={reportId} />,
    });
  }
  if (
    reportFeaturesSupportedAny(
      reportConfig,
      SUBJECT_PROPERTY_DETAILS_SECTION_FEATURES,
    )
  ) {
    tabs.push({
      tabId: 'propertyDetails',
      label: 'Property Details',
      dataHcEventSection: 'Property Details',
      noPadding: true,
      content: (
        <SubjectPropertyDetailsSection
          reportId={reportId}
          className={styles.PropertyDetailsSection}
        />
      ),
    });
  }
  if (
    reportFeaturesSupportedAny(
      reportConfig,
      SUBJECT_TRANSACTION_HISTORY_FEATURES,
    )
  ) {
    tabs.push({
      tabId: 'transactionHistory',
      label: showRentalContent ? 'Rental Transactions' : 'Transaction History',
      dataHcEventSection: showRentalContent
        ? dataHcEventSectionSubjectTransactionHistoryRental
        : dataHcEventSectionSubjectTransactionHistorySale,
      noPadding: true,
      content: (
        <SubjectTransactionHistory
          className={styles.SubjectTransactionHistoryTab}
          reportId={reportId}
          valueType={
            EXPERIENCE_FLAGS.PEXP_REDESIGN
              ? showRentalContent
                ? 'RENTAL'
                : 'SOLD'
              : undefined
          }
        />
      ),
    });
  }
  if (
    !showRentalContent &&
    reportFeaturesSupportedAny(
      reportConfig,
      NEIGHBORHOOD_INSIGHTS_SECTION_FEATURES,
    )
  ) {
    tabs.push({
      tabId: 'neighborhoodInsights',
      label: dataHcEventSectionNeighborhoodInsights,
      dataHcEventSection: dataHcEventSectionNeighborhoodInsights,
      noPadding: true,
      content: (
        <NeighborhoodInsightsSection
          reportId={reportId}
          className={styles.NeighborhoodInsights}
        />
      ),
    });
  }

  if (
    EXPERIENCE_FLAGS.PEXP_REDESIGN &&
    reportFeaturesSupportedAny(
      reportConfig,
      showRentalContent
        ? USER_OPINION_OF_PRICE_FORM_FEATURES_RENTAL
        : USER_OPINION_OF_PRICE_FORM_FEATURES_SALE,
    )
  ) {
    const opinionOfPriceLabel = showRentalContent
      ? dataHcEventSectionOpinionOfPriceRental
      : dataHcEventSectionOpinionOfPriceSale;
    tabs.push({
      tabId: TAB_ID_USER_OPINION,
      label: opinionOfPriceLabel,
      noPadding: true,
      dataHcEventSection: opinionOfPriceLabel,
      content: (
        <UserOpinionOfPriceForm
          reportId={reportId}
          showRentalValue={!!showRentalContent}
        />
      ),
    });
  }

  if (tabs.length) {
    // We can be cast because we have checked the array length above
    const tabsPopulated: ArrayOneOrMore<Tab> = tabs as ArrayOneOrMore<Tab>;
    return (
      <CardTabs
        dataHcName={dataHcName}
        searchParamId={SUBJECT_PROPERTY_TAB_SECTION_ID}
        className={classNames(styles.SubjectPropertyTabSection, className)}
        theme={{ Content: styles.Content }}
        tabs={tabsPopulated}
        minContentHeight={450}
      />
    );
  } else {
    return null;
  }
};
