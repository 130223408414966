import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';

import {
  PaginatedResponseData,
  PaginationHeaderData,
  PaginationOptions,
} from '@hcs/types';

import { parseStringOrNumber } from './formatters.utils';

export const parsePaginationHeaders = (
  response: AxiosResponse,
): PaginationHeaderData => {
  const results = {
    totalCount:
      parseStringOrNumber(response.headers?.['x-total-count']) ||
      response.data.length,
    numPages: parseStringOrNumber(response.headers?.['x-num-pages']) || 1,
  };
  if (response.headers['link']) {
    const linkString = response.headers['link'] as string;
    const links = linkString.split(',');
    links.forEach((link) => {
      const linkParts = link.split(';');
      const firstLastPart = linkParts[1]?.match('rel="(.*)"')?.[1];
      const urlPart = linkParts[0]?.match('<(.*)>')?.[1];
      if (firstLastPart === 'last' && urlPart) {
        const urlParams = new URLSearchParams(urlPart.split('?')[1]);
        results.numPages = parseInt(urlParams.get('page') || '1');
      }
    });
  }
  return results;
};

const parsePaginationHeadersNoPaginationLinks = (
  response: AxiosResponse,
  paginationOptions: PaginationOptions,
): PaginationHeaderData => {
  const totalCount =
    parseStringOrNumber(response.headers?.['x-total-count']) ||
    response.data.length;
  const results = {
    totalCount,
    numPages: Math.ceil(totalCount / paginationOptions.pageSize) || 1,
  };
  return results;
};

export const makePaginatedResponseData = <
  ResponseData extends readonly unknown[] | Record<string, unknown>,
>(
  response: AxiosResponse<ResponseData>,
  paginationOptions: PaginationOptions,
  options?: {
    camelCaseKeys?: boolean;
    noPaginationLinks?: boolean;
  },
): PaginatedResponseData<ResponseData> => {
  const { noPaginationLinks = false, camelCaseKeys = false } = options || {};

  const paginationHeaderData = noPaginationLinks
    ? parsePaginationHeadersNoPaginationLinks(response, paginationOptions)
    : parsePaginationHeaders(response);
  return {
    pagination: {
      ...paginationOptions,
      ...paginationHeaderData,
    },
    data: camelCaseKeys
      ? (camelcaseKeys(response.data, { deep: true }) as ResponseData)
      : response.data,
  };
};

export const getOffsetFromPaginationOptions = (
  paginationOptions: PaginationOptions,
): number => {
  return (paginationOptions.page - 1) * paginationOptions.pageSize;
};
