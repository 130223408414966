import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { useDebounce } from '@hcs/hooks';
import { usePrevious } from '@hcs/hooks';
import { BuyBoxFormData } from '@hcs/types';

import { buyBoxValidationSchema } from '../utils';

const FORM_CHANGE_DEBOUNCE = 500;

export const useBuyBoxFormChange = (
  control: Control<BuyBoxFormData>,
  callback?: (formData: BuyBoxFormData) => void,
) => {
  const [isValid, setIsValid] = useState(true);
  // use previous for msaId
  /*
   * the type returned by useWatch is DefaultValues<BuyBoxFormData>
   * We're making an assumption by casting here that all forms in the app have provided sufficient defaultValues to useForm
   * such that no fields will have undefined value (only their value or null)
   * */
  const formData = useWatch({ control }) as BuyBoxFormData;
  const msaId = formData.msaId;
  const previousMsaId = usePrevious(msaId);
  // don't want to wait on the debounce if this is a first run or if a different buy box was selected
  const debounceDelay =
    msaId && msaId !== previousMsaId ? 0 : FORM_CHANGE_DEBOUNCE;
  const debouncedFormData = useDebounce(formData, debounceDelay);

  useEffect(() => {
    if (debouncedFormData.msaId) {
      if (callback) {
        const validate = async () => {
          try {
            // have to manually validate since react-hook-form does async validation, so isValid won't necessarily be up to date when the data changes
            await buyBoxValidationSchema.validate(debouncedFormData);
            // we can assume valid now
            callback(debouncedFormData);
            setIsValid(true);
          } catch {
            // will end up in this catch if the validation failed
            setIsValid(false);
          }
        };
        validate();
      }
    }
  }, [debouncedFormData, callback]);

  return {
    isValid,
    hasPendingChange: formData !== debouncedFormData,
  };
};
