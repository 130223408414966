import React, { useEffect, useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { ExpressionFilterSpecification } from '@maplibre/maplibre-gl-style-spec';
import { FillPaint } from 'mapbox-gl';

import { VectilesMetricsConsumer } from '@hcs/types';

import { HALFTONE_IMAGES, VECTILES_TILES_URLS } from '../../constants';
import { useHcMapLayers } from '../../hooks/useHcMapLayers';

interface Props {
  mapId: string;
  /* A property that triggers a repainting when it changes. If not supplied,
   * a change to `metric` will trigger a repaint */
  /* If provided, layers in this component are inserted beneath this layer on the map */
  paint?: FillPaint;
  beneathLayerId?: string;
}

const BLOCK_FEATURES_FILTER: ExpressionFilterSpecification = [
  'any',
  ['>', ['number', ['get', VectilesMetricsConsumer.ALL_LAND], Infinity], 0],
  ['==', ['number', ['get', VectilesMetricsConsumer.ALL_WATER], Infinity], 0],
];

/**
 * Renders one or more Gaia block layers optionally comprised of different tile sets
 * at different zoom levels.  Makes decisions about layer configuration using our
 * in-house HC standards for convenience.
 */
export const BlocksLayer = (props: Props) => {
  const { current: map } = useMap();
  const [isMounted, setIsMounted] = useState(false);

  /**
   * Load all images needed for monochrome a11y map layer into map
   */
  useEffect(() => {
    if (!isMounted && map) {
      HALFTONE_IMAGES.forEach((item) => {
        const imageId = `halftone-shade-${item.name}`;
        const image = new Image(8, 8);
        if (!map.hasImage(imageId) && imageId && item?.icon) {
          image.addEventListener('load', () => {
            /* Map might be unmounted by the time the image loads */
            if (!map.hasImage(imageId)) {
              map.addImage(imageId, image, { pixelRatio: 2 });
            }
          });
          // Not a security threat this is standard way of setting image src properties
          // if defined as an object as per https://www.w3schools.com/jsref/prop_img_src.asp
          image.src = item?.icon.toString();
        }
      });
      setIsMounted(true);
    }
  }, [map, isMounted]);
  const { vectilesLayerSource } = useHcMapLayers(props.mapId);
  if (!vectilesLayerSource) return null;
  const { vectilesLayer, zoomRange } = vectilesLayerSource;

  return (
    <Source
      key={`${vectilesLayer}-source-key`}
      id={`${vectilesLayer}_source`}
      type="vector"
      tiles={[`${VECTILES_TILES_URLS}/${vectilesLayer}/{z}/{x}/{y}.mvt`]}
      minzoom={zoomRange.min}
      maxzoom={zoomRange.max}
    >
      <Layer
        key={vectilesLayer}
        id={`${vectilesLayer}_layer`}
        type="fill"
        source={`${vectilesLayer}_source`}
        source-layer={vectilesLayer}
        paint={props.paint}
        filter={BLOCK_FEATURES_FILTER}
        minzoom={zoomRange.min}
        maxzoom={zoomRange.max}
      />
    </Source>
  );
};
