import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { Account, ResetPasswordPayload } from '@hcs/types';

import { AuthnApi } from '../api';

// Export hook-specific types here rather than in types file
export type UseResetPasswordOptions = UseMutationOptions<
  Account,
  AxiosError,
  ResetPasswordPayload,
  void
>;

export const useResetPassword = (options?: UseResetPasswordOptions) => {
  const queryClient = useQueryClient();
  return useMutation<Account, AxiosError, ResetPasswordPayload, void>(
    async (payload) => {
      return await AuthnApi.resetPassword(payload);
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
      },
      onSuccess(account, variables) {
        queryClient.setQueryData<Account>([ACCOUNT_QUERY_KEY], account);
        options?.onSuccess?.(account, variables);
      },
    },
  );
};
