import { AxiosClient } from '@hcs/http-clients';
import { VectileLayers, VectilesStatsReturnType } from '@hcs/types';

import { VECTILES_TILES_URLS } from '../constants';

export const VectorTilesApi = {
  fetchVectorTilesStats: async (
    blocksType?: VectileLayers,
    integerZoom?: number,
    tileRangeURLSegment?: string,
  ) => {
    const url = `${VECTILES_TILES_URLS}/${blocksType}/${integerZoom}/${tileRangeURLSegment}/stats`;
    const response = await AxiosClient.get<VectilesStatsReturnType>(url);
    return response.data;
  },
};
