import { AppSlugs } from '@hcs/types';

// Discontinued Legacy Product, used to filter application lists
export const DISCONTINUED_PRODUCT_NAMES: Record<string, boolean> = {
  Pro: true,
  Appraiser: true,
  'Self Service Investor': true,
};

export const MOBILE_APPS_SUPPORTED: AppSlugs[] = [AppSlugs.CanaryAI];
