import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { AcquisitionExplorerIcon, ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { useNavigateToAexp } from '../../hooks/useNavigateToAexp';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './ProductCards.module.css';

interface AexpProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

const dataHcName = 'acquisition-explorer-card';

export const AexpProductCard = ({
  navigateToSSSelectPlan,
}: AexpProductCardProps) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const aexpConfigExtended = useAppConfigExtended(AppSlugs.AcquisitionExplorer);
  const marketInsightsConfigExtended = useAppConfigExtended(
    AppSlugs.MarketInsights,
  );
  const navigateToAexp = useNavigateToAexp();
  if (!aexpConfigExtended || !marketInsightsConfigExtended) return null;
  const { hasAccess: hasAexpAccess } = aexpConfigExtended;
  const handleClick = () => {
    if (hasAexpAccess) {
      return navigateToAexp();
    } else {
      handleOpen();
    }
  };
  return (
    <>
      <ProductCard
        dataHcName={dataHcName}
        theme={{
          ProductCard: styles.ProductCard,
        }}
        productName="Acquisition Explorer"
        subheading={aexpConfigExtended.appConfig.secondaryName}
        productDescription="Get notified when properties change status across any market of your choice."
        hasAccess={!!hasAexpAccess}
        Icon={AcquisitionExplorerIcon}
        onClick={handleClick}
      />
      <ProductPreviewDialog
        appConfigExtended={aexpConfigExtended}
        active={active}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleClose}
      />
    </>
  );
};
