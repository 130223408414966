import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserIsOrgAdmin } from '@hcs/auth';
import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '@hcs/authn';
import { LoadingSpinner } from '@hcs/design-system';
import { APP_CONFIG_USER_ADMIN, VIEW_PATHS_USER_ADMIN } from '@hcs/hc-products';

export const NonAdminPageRedirect = ({ children }: { children: ReactNode }) => {
  const { data: isOrgAdmin, isInitialLoading } = useUserIsOrgAdmin();
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (!isOrgAdmin) {
    return (
      <Navigate
        to={`${APP_CONFIG_USER_ADMIN.rootPath}/${VIEW_PATHS_USER_ADMIN.USER_USAGE}`}
        replace
      />
    );
  } else {
    return children;
  }
};
