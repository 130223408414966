import React, { useState } from 'react';

import { AddressSearch } from '@hcs/address-search';
import { Button } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { AddressSearchHit } from '@hcs/types';
import { OrderItem } from '@hcs/types';

import { useOrder } from '../../hooks/useOrder';
import { useUpdateOrder } from '../../hooks/useUpdateOrder';

import styles from './EditOrderItem.module.css';

interface Props {
  orderId: number;
  orderItem: OrderItem;
}

const dataHcName = 'edit-order-item';
export const EditOrderItem = ({ orderId, orderItem }: Props) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const currentAddress = `${orderItem.address}${
    orderItem.unit ? ` ${orderItem.unit}` : ''
  }, ${orderItem.city}, ${orderItem.state} ${orderItem.zipcode}`;
  const {
    data: order,
    isError: orderIsError,
    error: orderError,
  } = useOrder(orderId);

  const {
    mutate: updateOrder,
    isLoading: updateOrderIsLoading,
    isError: updateOrderIsError,
    error: updateOrderError,
  } = useUpdateOrder();

  const initialFormState = {
    address: null,
    propertyType: orderItem.propertyType,
  };

  const [formState, setFormState] = useState<{
    address: AddressSearchHit | null;
    propertyType: string;
  }>(initialFormState);

  const hideDialog = () => {
    setShowEditDialog(false);
    setFormState(initialFormState);
  };

  const saveAddress = () => {
    const originalAddress = {
      address: orderItem.address,
      unit: orderItem.unit,
      unitDesignator: orderItem.unitDesignator,
      city: orderItem.city,
      state: orderItem.state,
      zipcode: orderItem.zipcode,
    };
    const updatedAddress = formState.address;

    updateOrder(
      {
        orderId: orderId || 0,
        itemId: orderItem.id,
        address: { originalAddress, updatedAddress },
        propertyType: formState.propertyType,
      },
      {
        onSuccess: hideDialog,
      },
    );
  };

  return (
    <div data-hc-name={dataHcName}>
      {!orderItem?.cancelled && (
        <Button
          dataHcName={`${dataHcName}-button`}
          onClick={() => setShowEditDialog(true)}
        >
          Edit
        </Button>
      )}
      <Dialog
        dataHcName={`${dataHcName}-edit-dialog`}
        onClose={hideDialog}
        title="Edit"
        active={showEditDialog}
        theme={{
          Dialog: styles.Dialog,
        }}
        actions={[
          {
            dataHcName: `${dataHcName}-edit-dialog-cancel`,
            label: 'Cancel',
            onClick: hideDialog,
            secondary: true,
          },
          {
            dataHcName: `${dataHcName}-submit-dialog-cancel`,
            label: 'Save',
            onClick: saveAddress,
            disabled:
              formState.address === null &&
              formState.propertyType === orderItem.propertyType,
            loading: updateOrderIsLoading,
          },
        ]}
        notifications={[
          {
            dataHcName: 'update-order-error',
            show: updateOrderIsError,
            type: 'error',
            title: updateOrderError?.message || 'Error updating order item',
          },
          {
            dataHcName: 'order-error',
            show: orderIsError,
            type: 'error',
            title: orderError?.message || 'Failed to fetch order',
          },
        ]}
      >
        <div className={styles.DialogContent}>
          <div className={styles.ContentHeader}>
            <h4>Original Address:</h4>
            <span>{currentAddress}</span>
          </div>
          <AddressSearch
            placeholder="Re-enter the address and select the match"
            onSelect={(address) => setFormState({ ...formState, address })}
            onClearSelection={() =>
              setFormState({ ...formState, address: null })
            }
            hideChevron
          />
          <div className={styles.ContentHeader}>
            <h4>Original Property Type:</h4>
            <span>{orderItem.propertyType}</span>
          </div>
          <Dropdown
            dataHcName={`${dataHcName}-property-type-dropdown`}
            onSelect={(propertyType) =>
              setFormState({ ...formState, propertyType })
            }
            options={order?.orderTypeDescriptor.propertyTypes || []}
            value={orderItem.propertyType}
          />
        </div>
      </Dialog>
    </div>
  );
};
