import React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { InputNumber } from '@hcs/design-system';
import {
  PortfolioForm,
  PortfolioFormFieldCommonProps,
  PortfolioFormFields,
  PortfolioLtvThresholdFields,
  PortfolioLtvThresholdOperators,
} from '@hcs/types';

import { PORTFOLIO_SETUP_FIELD_CONFIGS } from '../../constants/Portfolio.constants';
import { DEFAULT_LTV_VALUE } from '../../constants/PortfolioForm.constants';

import { PortfolioSetupFieldTemplate } from './PortfolioSetupFieldTemplate';

import styles from './LtvFieldInput.module.css';

const dataHcName = 'portfolio-ltv-field';

const OPERATOR_OPTIONS = [
  { value: PortfolioLtvThresholdOperators.GREATER_THAN, label: 'Greater Than' },
  { value: PortfolioLtvThresholdOperators.LESS_THAN, label: 'Less Than' },
];

export const LtvFieldInput = ({ className }: PortfolioFormFieldCommonProps) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<PortfolioForm>();

  const { fields, append, remove } = useFieldArray<
    PortfolioForm,
    PortfolioFormFields.LtvThresholds
  >({
    control: control,
    name: PortfolioFormFields.LtvThresholds,
  });

  const ltvValuesObj = useWatch({
    name: PortfolioFormFields.LtvThresholds,
    control: control,
  });

  const ltvValues = ltvValuesObj?.map((val) => val?.threshold);
  // Check if a number was entered to enable the "Add Another Value" button.
  const isInputNumber =
    ltvValues && typeof ltvValues[ltvValues.length - 1] === 'number';

  return (
    <PortfolioSetupFieldTemplate
      className={className}
      label={
        PORTFOLIO_SETUP_FIELD_CONFIGS[PortfolioFormFields.LtvThresholds].label
      }
      fieldName={PortfolioFormFields.LtvThresholds}
    >
      <div
        data-hc-name={`${dataHcName}-description`}
        className={styles.FieldDescription}
      >
        Get notified when properties cross your desired LTV threshold. A default
        notification occurs when property LTV becomes greater than 80%, you may
        add additional thresholds (1-99%):
      </div>

      {fields.map((item, index) => {
        return (
          <div key={`ltv-value-input-${item.id}`}>
            <Controller
              name={`${PortfolioFormFields.LtvThresholds}.${index}`}
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                const eachLtvValueErrorMsg =
                  errors?.[PortfolioFormFields.LtvThresholds] &&
                  errors?.[PortfolioFormFields.LtvThresholds][index]?.threshold
                    ?.message;

                return (
                  <>
                    {index === 0 ? (
                      <div className={styles.ReadOnlyContainer}>
                        <Dropdown
                          theme={{ SelectContainer: styles.ReadOnlyElement }}
                          dataHcName={`${dataHcName}-operator-dropdown-default`}
                          disabled
                          options={OPERATOR_OPTIONS}
                          value={
                            DEFAULT_LTV_VALUE[
                              PortfolioLtvThresholdFields.Operator
                            ]
                          }
                          onSelect={() => void 0}
                        />
                        <div
                          className={classNames(
                            styles.LtvInputReadOnly,
                            styles.ReadOnlyElement,
                          )}
                        >
                          {
                            DEFAULT_LTV_VALUE[
                              PortfolioLtvThresholdFields.Threshold
                            ]
                          }
                        </div>
                      </div>
                    ) : (
                      <div
                        data-hc-name={`${dataHcName}-data-row`}
                        className={styles.LtvInputRow}
                      >
                        <div className={styles.LtvInputContainer}>
                          <Dropdown
                            theme={{
                              SelectContainer: styles.SelectContainer,
                              Option: styles.Option,
                            }}
                            dataHcName={`${dataHcName}-operator-dropdown`}
                            options={OPERATOR_OPTIONS}
                            value={
                              field.value[PortfolioLtvThresholdFields.Operator]
                            }
                            onSelect={(value) => {
                              field.onChange({
                                [PortfolioLtvThresholdFields.Operator]: value,
                                [PortfolioLtvThresholdFields.Threshold]:
                                  field.value[
                                    PortfolioLtvThresholdFields.Threshold
                                  ],
                              });
                              // trigger validation
                              trigger(PortfolioFormFields.LtvThresholds);
                            }}
                          />
                          <InputNumber
                            {...field}
                            className={styles.LtvInput}
                            theme={{ InputElement: styles.InputElement }}
                            value={
                              field.value[PortfolioLtvThresholdFields.Threshold]
                            }
                            dataHcName={`${dataHcName}-input`}
                            onChange={(value) => {
                              field.onChange({
                                [PortfolioLtvThresholdFields.Operator]:
                                  field.value[
                                    PortfolioLtvThresholdFields.Operator
                                  ],
                                [PortfolioLtvThresholdFields.Threshold]: value,
                              });
                            }}
                          />
                        </div>

                        <Button
                          className={classNames(
                            styles.ButtonNoBackground,
                            styles.RemoveButton,
                          )}
                          dataHcName={`${dataHcName}-remove-lst-value-button`}
                          label="Remove"
                          primary={false}
                          onClick={() => remove(index)}
                        />
                      </div>
                    )}
                    {invalid && (
                      <div
                        data-hc-name={`${dataHcName}-error`}
                        className={styles.Error}
                      >
                        {eachLtvValueErrorMsg}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>
        );
      })}
      {errors?.[PortfolioFormFields.LtvThresholds]?.message && (
        <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
          {errors?.[PortfolioFormFields.LtvThresholds]?.message}
        </div>
      )}
      <Button
        className={classNames(styles.ButtonNoBackground, styles.AddButton)}
        dataHcName={`${dataHcName}-add-another-value-button`}
        disabled={
          !!errors?.[PortfolioFormFields.LtvThresholds] || !isInputNumber
        }
        label="Add Another Value"
        primary={false}
        type="button"
        onClick={() =>
          append({
            operator: PortfolioLtvThresholdOperators.GREATER_THAN,
            threshold: null,
          })
        }
      />
    </PortfolioSetupFieldTemplate>
  );
};
