import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { RadioButton } from '../../inputs/RadioButton';

import styles from './RadioSelect.module.css';

export interface RadioOption<T> {
  label: ReactNode;
  value: T;
}
export interface RadioSelectProps<T> {
  dataHcName: string;
  className?: string;
  disabled?: boolean;
  options: RadioOption<T>[];
  value: T | null;
  onChange: (v: T) => void;
  blockOptions?: boolean;
}
export const RadioSelect = <
  T extends string | number | null | boolean | undefined,
>({
  dataHcName,
  className,
  options,
  disabled,
  value,
  onChange,
  blockOptions,
}: RadioSelectProps<T>) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.RadioSelect,
        { [styles.blockOptions]: blockOptions },
        className,
      )}
    >
      {options.map((option, i) => {
        return (
          <RadioButton
            key={`option-${i}`}
            dataHcName={`${dataHcName}-option-${i}`}
            label={option.label}
            value={option.value}
            className={styles.RadioButton}
            checked={option.value === value}
            disabled={disabled}
            onChange={() => onChange(option.value)}
          />
        );
      })}
    </div>
  );
};
