import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useAppConfigsExtended } from '@hcs/auth';
import { useUserIsConfirmed } from '@hcs/authn';
import { DeveloperCenterBanner } from '@hcs/data-explorer';
import { AppLauncher, AppLauncherMobile } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import { APP_CONFIG_PORTFOLIO } from '@hcs/hc-products';
import { APP_CONFIG_AEXP } from '@hcs/hc-products';
import { APP_CONFIG_AGILE_SUITE } from '@hcs/hc-products';
import { APP_CONFIG_DEXP } from '@hcs/hc-products';
import { APP_CONFIG_PEXP } from '@hcs/hc-products';
import { APP_CONFIG_MARKET_INSIGHTS } from '@hcs/hc-products';
import { APP_CONFIG_CANARYAI } from '@hcs/hc-products';
import { ProductPreviewDialog } from '@hcs/hc-products';
import { useNavigateToAgileSuite } from '@hcs/hc-products';
import { useNavigateToPexp } from '@hcs/hc-products';
import { useNavigateToPlatformLanding } from '@hcs/hc-products';
import { MOBILE_APPS_SUPPORTED } from '@hcs/hc-products';
import { useScreenSize } from '@hcs/hooks';
import { AppConfig, AppConfigExtended, AppSlugs } from '@hcs/types';

import styles from './SolutionsPlatformAppLauncher.module.css';

const dataHcName = 'solutions-platform-app-launcher';
export const SolutionsPlatformAppLauncher = () => {
  const appConfigs: AppConfig[] = [
    APP_CONFIG_CANARYAI,
    APP_CONFIG_DEXP,
    APP_CONFIG_PEXP,
    APP_CONFIG_MARKET_INSIGHTS,
    APP_CONFIG_AEXP,
    APP_CONFIG_AGILE_SUITE,
    APP_CONFIG_PORTFOLIO,
  ];
  const { data: appConfigsExtended } = useAppConfigsExtended(appConfigs, {
    showUnauthorized: true,
  });
  const PEXP_REPORT_API = useExperienceFlag('PEXP_REPORT_API');
  const navigate = useNavigate();
  const navigateToPlatformLanding = useNavigateToPlatformLanding();
  const navigateToPexp = useNavigateToPexp();
  const [getAccessAppConfigExtended, setGetAccessAppConfigExtended] =
    useState<AppConfigExtended>();
  const navigateToAgileSuite = useNavigateToAgileSuite();
  const { data: isUserConfirmed, isInitialLoading: isUserConfirmedIsLoading } =
    useUserIsConfirmed();
  const { isMobileWidth } = useScreenSize();

  const onClickApp = (appConfigExtended: AppConfigExtended) => {
    if (appConfigExtended.hasAccess) {
      if (
        appConfigExtended.appConfig.appSlug === AppSlugs.PropertyExplorer &&
        PEXP_REPORT_API
      ) {
        navigateToPexp();
      } else if (appConfigExtended.appConfig.appSlug === AppSlugs.AgileSuite) {
        navigateToAgileSuite();
      } else {
        navigate(appConfigExtended.appConfig.rootPath);
      }
    } else {
      setGetAccessAppConfigExtended(appConfigExtended);
    }
  };

  if (!isUserConfirmed || isUserConfirmedIsLoading) {
    return null;
  }

  return (
    <>
      {isMobileWidth ? (
        <AppLauncherMobile
          appsSupported={MOBILE_APPS_SUPPORTED}
          dataHcName={dataHcName}
          appConfigsExtended={appConfigsExtended}
          onClickApp={onClickApp}
          viewAll={{
            label: 'View All Products',
            onClick: navigateToPlatformLanding,
          }}
        />
      ) : (
        <AppLauncher
          theme={{
            Content: styles.Content,
          }}
          dataHcName={dataHcName}
          appConfigsExtended={appConfigsExtended}
          onClickApp={onClickApp}
          viewAll={{
            label: 'View All Products',
            onClick: navigateToPlatformLanding,
          }}
          bottomSection={
            <DeveloperCenterBanner className={styles.BottomSection} />
          }
        />
      )}
      <ProductPreviewDialog
        active={!!getAccessAppConfigExtended}
        appConfigExtended={getAccessAppConfigExtended}
        onClose={() => {
          setGetAccessAppConfigExtended(undefined);
        }}
      />
    </>
  );
};
