import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { useClickOutsideComponent, useForwardedRef } from '@hcs/hooks';
import { getRelatedTargetHcName, scrollFocusEl } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { CloseIcon } from '../../../../svgs/icons/navigation';
import { SearchInputStyle } from '../SearchInputStyle';

import {
  AutoCompleteMultiSelectConfig,
  AutoCompleteOptionType,
  AutoCompleteSingleSelectConfig,
} from './AutoComplete.types';
import { AutoCompleteOption } from './AutoCompleteOption';

import styles from './AutoComplete.module.css';

// Redeclare forwardRef
// forwardRef out of the box doesn't support generics so this is a possible solution
// source: https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export interface AutoCompleteTheme {
  Input?: string;
  OptionsContainer?: string;
}

export type AutoCompleteInputStyle = 'form' | 'transparent' | 'search';

export interface AutoCompleteProps<OptionValueType> {
  dataHcName: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  role?: string;
  name?: string;
  inputStyle?: AutoCompleteInputStyle;
  // Hide the chevron icon
  hideChevron?: boolean;
  options: AutoCompleteOptionType<OptionValueType>[];
  // Should the component filter the options based on the search string
  optionMode?: 'async' | 'sync';
  onBlur?: VoidFunction;
  // Called when the input changes
  onChange?: (value: string) => void;
  disableClear?: boolean;
  config:
    | AutoCompleteMultiSelectConfig<OptionValueType>
    | AutoCompleteSingleSelectConfig<OptionValueType>;
  inputIcon?: ReactNode;
  clearAfterSelect?: boolean;
  theme?: AutoCompleteTheme;
  fallbackValue?: string;
  resultsHelper?: ReactNode;
  optionsContent?: ReactNode;
  isLoading?: boolean;
}

export const AutoCompleteInner = <OptionValueType,>(
  props: AutoCompleteProps<OptionValueType>,
  inputRef: React.ForwardedRef<HTMLInputElement>,
) => {
  const {
    dataHcName,
    className,
    disabled,
    error,
    placeholder,
    role,
    name,
    options,
    optionMode = 'sync',
    onBlur,
    onChange,
    config,
    disableClear,
    inputStyle = 'form',
    hideChevron,
    inputIcon,
    clearAfterSelect,
    theme,
    fallbackValue,
    resultsHelper,
    optionsContent,
    isLoading,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [search, setSearch] = useState('');

  const setSearchFromOption = (
    option: AutoCompleteOptionType<OptionValueType>,
  ) => {
    setSearch(option.searchString);
  };

  const findSelectedOption = useCallback(() => {
    if (config.value === undefined || config.selectType !== 'single') {
      return null;
    }
    const selectedOption = options.find(
      (option) => option.value === config.value,
    );

    return selectedOption !== undefined ? selectedOption : null;
  }, [options, config.value]);

  const findSelectedOptions = useCallback(() => {
    if (config.value === undefined || config.selectType !== 'multi') {
      return [];
    } else {
      return options.filter((opt) => config.value?.includes(opt.value));
    }
  }, [options, config.value]);

  /** SINGLE SELECT ONLY */
  const initialSelectedOption = findSelectedOption();
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOptionType<OptionValueType> | null>(
      initialSelectedOption,
    );

  // if new options are received, reset the selected option
  // this is needed in the case that the selected option's value DID NOT change,
  // but its label DID
  useEffect(() => {
    if (config.selectType === 'single') {
      setSelectedOption(findSelectedOption());
    }
  }, [options]);
  /** SINGLE SELECT ONLY */

  /** MULTI SELECT ONLY */
  const initialMultiSelectedOption = findSelectedOptions();
  const [multiSelectedOption, setMultiSelectedOption] = useState<
    AutoCompleteOptionType<OptionValueType>[]
  >(initialMultiSelectedOption);
  /** MULTI SELECT ONLY */

  // if a new value is received from props that is different from
  // the value stored in this component, update the selection option
  useEffect(() => {
    if (
      config.selectType === 'single' &&
      config.value !== selectedOption?.value
    ) {
      setSelectedOption(findSelectedOption());
    } else if (config.selectType === 'multi') {
      setMultiSelectedOption(findSelectedOptions());
    }
  }, [config.value]);

  const getMultiSelectLabel = (numSelected: number, numOptions: number) => {
    if (config.selectType === 'multi') {
      return `(${numSelected} out of ${numOptions}) ${config.label}`;
    }

    return '';
  };

  const [filteredOptions, setFilteredOptions] = useState(options);
  const [focusedOption, setFocusedOption] = useState<{
    idx: number;
    searchString: string;
  } | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const optionDataHcName = `${dataHcName}-option`;
  const clearDataHcName = `${dataHcName}-clear`;
  const filterOptions = useCallback(
    (newSearch: string) => {
      if (newSearch !== '' && optionMode === 'sync') {
        const filteredOptions = options.filter((option) => {
          return option.searchString
            .toLocaleLowerCase()
            .includes(newSearch.toLocaleLowerCase());
        });
        setFilteredOptions(filteredOptions);
      } else {
        setFilteredOptions(options);
      }
    },
    [options],
  );

  const selectedOptionIdx = options.findIndex(
    (option) => option.value === selectedOption?.value,
  );

  useEffect(() => {
    if (
      !inputHasFocus &&
      selectedOption &&
      selectedOption.searchString !== focusedOption?.searchString
    ) {
      setFocusedOption({
        searchString: options[selectedOptionIdx]?.searchString || '',
        idx: selectedOptionIdx,
      });
    }
  }, [
    inputHasFocus,
    options,
    selectedOption,
    focusedOption,
    selectedOptionIdx,
  ]);

  useEffect(() => {
    filterOptions(search);
  }, [options]);

  const scrollFocusToOption = (
    optionIdx: number,
    shouldScroll: boolean,
    shouldFocus: boolean,
  ) => {
    if (!optionRefs.current) return;
    const scrollToOption = optionRefs.current[optionIdx];
    if (!scrollToOption) return;

    scrollFocusEl(
      scrollToOption,
      optionsContainerRef.current,
      shouldScroll,
      shouldFocus,
    );

    if (shouldFocus) {
      setFocusedOption({
        searchString: filteredOptions[optionIdx]?.searchString || '',
        idx: optionIdx,
      });
    }
  };

  const activate = (reason?: 'keyboard' | 'auto') => {
    // scroll to top of options of multiselect
    if (config.selectType === 'multi') {
      scrollFocusToOption(0, true, false);
    } else {
      setSelectedOption(findSelectedOption());

      // if an option is already selected AND focus wasn't shifted to the input from an option
      // via the keyboard, scroll to that option, but don't shift focus
      // however, we want to set the focusedOption to that selected option anyway, incase
      // the user wants to use arrows to navigate, starting from the selected option makes sense
      if (selectedOption && reason !== 'keyboard') {
        scrollFocusToOption(selectedOptionIdx, true, false);
        setFocusedOption({
          searchString: options[selectedOptionIdx]?.searchString || '',
          idx: selectedOptionIdx,
        });
      }
    }

    // when activating reason is keyboard, maintain the search term
    // even if an option was already selected
    if (selectedOption && reason !== 'keyboard') {
      setSearch(search);
    }

    // only filter by the search term (on activation) if there is no selected option
    // OR the search term does not equal the selected option
    // this will show all options regardless of the input contents, per Material UI pattern
    let filterTerm = '';
    if (selectedOption === null || search !== selectedOption.label) {
      filterTerm = search;
    }
    filterOptions(filterTerm);
    setIsActive(true);
  };

  // this needs a useCallback since we're passing to useClickOutsideComponent as a callback and that hook uses the callback as a useEffect dependency
  const deActivate = useCallback(() => {
    setFocusedOption(null);
    setIsActive(false);
    const search = '';
    setSearch(search);
    filterOptions(search);
  }, []);

  const toggleActive = () => {
    if (isActive) {
      deActivate();
      return;
    }
    activate();
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(containerRef, deActivate);

  const forwardedInputRef = useForwardedRef<HTMLInputElement>(inputRef);

  const handleSelect = (option: AutoCompleteOptionType<OptionValueType>) => {
    if (config.selectType !== 'multi') {
      if (!config.manualControl) {
        // Don't store selected value locally if clearAfterSelect
        if (!clearAfterSelect) {
          setSelectedOption(option);
        }
        setSearchFromOption(option);
        deActivate();
      }
      config.onSelect(
        option.value,
        config.manualControl
          ? { setSearch, setSelectedOption, setIsActive }
          : undefined,
      );
      if (onBlur) {
        onBlur();
      }
    } else {
      let selected = [...multiSelectedOption];

      if (
        selected.some(
          (selectionOption) => selectionOption.value === option.value,
        )
      ) {
        selected = selected.filter(
          (selectedOption) => selectedOption.value !== option.value,
        );
      } else {
        selected.push(option);
      }

      // Don't store selected value locally if clearAfterSelect
      if (!clearAfterSelect) {
        setMultiSelectedOption(selected);
      }
      config.onSelect(selected.map((s) => s.value));
      if (onBlur) {
        onBlur();
      }
    }
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value || '';
    setSearch(search);
    filterOptions(search);
    setFocusedOption(null);
    onChange?.(search);
  };

  const handleClear = (focus = true) => {
    const search = '';
    setSearch(search);
    filterOptions(search);
    setFocusedOption(null);

    // only clear selection for the single-select version of this component
    if (config.selectType === 'single') {
      setSelectedOption(null);
      config.onSelect(null);
    }
    if (focus) {
      forwardedInputRef.current?.focus();
    }
  };

  useEffect(() => {
    if (
      (config.value === undefined || config.value === '') &&
      fallbackValue === undefined
    ) {
      handleClear(false);
    }
  }, [config.value, fallbackValue]);

  const handleArrowDown = () => {
    // if focused on last item in list, focus goes back to input
    // also scroll the list to the top
    if (focusedOption?.idx === optionRefs.current.length - 1) {
      forwardedInputRef.current?.focus();
      setFocusedOption(null);
      scrollFocusToOption(0, true, false);
      return;
    }

    const nextFocusedOptionIdx =
      focusedOption !== null ? focusedOption.idx + 1 : 0;
    scrollFocusToOption(nextFocusedOptionIdx, nextFocusedOptionIdx === 0, true);
  };

  const handleArrowUp = () => {
    // if no option is focused, then focus is on the input
    // scroll to and focus on the last item in list
    if (focusedOption === null) {
      const nextFocusedOptionIdx = optionRefs.current.length - 1;
      scrollFocusToOption(nextFocusedOptionIdx, true, true);
      return;
    }

    // if focused on first item in list, focus goes back to input
    if (focusedOption?.idx === 0) {
      forwardedInputRef.current?.focus();
      setFocusedOption(null);
      return;
    }

    const nextFocusedOptionIdx = focusedOption.idx - 1;
    scrollFocusToOption(nextFocusedOptionIdx, false, true);
  };

  const value = (() => {
    // multiselect
    if (config.selectType === 'multi') {
      if (inputHasFocus || search !== '') {
        return search;
      } else {
        return getMultiSelectLabel(multiSelectedOption.length, options.length);
      }
    }

    //single select
    if (isActive && !focusedOption) {
      return search;
    } else {
      return focusedOption
        ? focusedOption.searchString
        : selectedOption?.searchString || fallbackValue || '';
    }
  })();

  const isRelatedTargetAnOption = (e: React.FocusEvent<HTMLInputElement>) => {
    return getRelatedTargetHcName(e) === optionDataHcName;
  };

  const setSelectAll = (selectAll: boolean) => {
    if (config.selectType !== 'multi') return;
    let selected: AutoCompleteOptionType<OptionValueType>[] = [];

    if (selectAll) {
      selected = options;
    }

    setMultiSelectedOption(selected);
    config.onSelect(selected.map((s) => s.value));
    if (onBlur) {
      onBlur();
    }
  };

  const getAllOption = () => {
    const allSelected = options.length === multiSelectedOption.length;
    const hasFocus = document.activeElement === optionRefs.current[0];

    return (
      <AutoCompleteOption
        selected={allSelected}
        focused={hasFocus}
        setRef={(el) => (optionRefs.current[0] = el)}
        dataHcName={optionDataHcName}
        handleSelect={() => setSelectAll(!allSelected)}
        multiselect={true}
        label={'All'}
      />
    );
  };

  // show the 'All' option for multi select when the isn't typing to search
  const showAllOption = config.selectType === 'multi' && search === '';

  /**
   * show clear button rules:
   *    disableClear is not true
   *    single-select: a value is selected AND the dropdown is activated
   *    multi-select: the user has typed in the input to search
   */
  const showClearButton =
    !disableClear &&
    ((config.selectType === 'single' && config.value && isActive) ||
      (config.selectType === 'multi' && search !== ''));
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.AutoCompleteContainer, className, {
        [styles.transparentInput]:
          inputStyle === 'transparent' || inputStyle === 'search',
      })}
    >
      <SearchInputStyle
        unstyled={inputStyle !== 'search'}
        showIcon={inputStyle === 'search'}
      >
        <div
          className={classNames(
            {
              [styles.Active]: isActive,
              [styles.AutoCompleteError]: error,
            },
            styles.AutoComplete,
          )}
          ref={containerRef}
          onKeyDown={(e) => {
            if (e.key === 'Tab') deActivate();
            else if (e.key === 'ArrowDown') {
              e.preventDefault(); // prevent too much y-scroll to keep focused option in view
              handleArrowDown();
            } else if (e.key === 'ArrowUp') {
              e.preventDefault(); // prevent too much y-scroll to keep focused option in view
              handleArrowUp();
            }
          }}
          data-hc-name={`${dataHcName}-key-trigger`}
        >
          <div className={styles.InputContainer}>
            {inputIcon && <span className={styles.InputIcon}>{inputIcon}</span>}
            <input
              name={name}
              role={role}
              disabled={disabled}
              className={classNames(styles.InputElement, theme?.Input, {
                [styles.extraLeftPadding]: inputIcon, // Extra left padding for absolute-pos icon
              })}
              data-hc-name={`${dataHcName}-input`}
              placeholder={placeholder}
              value={value}
              onChange={handleSearch}
              onBlur={(e) => {
                // call onBlur only if the focus hasn't shifted to an option
                if (!isRelatedTargetAnOption(e) && onBlur) {
                  onBlur();
                }

                setInputHasFocus(false);
              }}
              onFocus={(e) => {
                // if an option is selected, highlight text on focus
                if (selectedOption) {
                  e.target.select();
                }
                const activateReason = isRelatedTargetAnOption(e)
                  ? 'keyboard'
                  : 'auto';

                // if clear was just clicked, focus was shifted back to input
                // don't call activate again
                if (getRelatedTargetHcName(e) !== clearDataHcName) {
                  activate(activateReason);
                }

                setInputHasFocus(true);
              }}
              ref={forwardedInputRef}
              autoComplete="off"
            />
            {showClearButton && (
              <CloseIcon
                tabIndex={0}
                dataHcName={clearDataHcName}
                className={classNames(styles.CloseIcon, {
                  [styles.SearchStyleCloseIcon]: inputStyle === 'search',
                })}
                onClick={() => handleClear()}
              />
            )}
            {!hideChevron && (
              <div onClick={toggleActive} className={styles.ChevronContainer}>
                <DirectionalChevron
                  dataHcName={`${dataHcName}-open-close`}
                  direction={isActive ? 'up' : 'down'}
                  size={20}
                />
              </div>
            )}
          </div>
          <div
            data-hc-name={`${dataHcName}-options`}
            className={classNames(
              styles.OptionsContainer,
              theme?.OptionsContainer,
            )}
            ref={optionsContainerRef}
          >
            {showAllOption && getAllOption()}
            {filteredOptions?.map((option, idx) => {
              // add 1 to accommodate the 'All' option at the '0' index
              const refIdx = showAllOption ? idx + 1 : idx;

              const selected =
                config.selectType === 'multi'
                  ? multiSelectedOption.some(
                      (selectedOption) => selectedOption.value === option.value,
                    )
                  : option.value === config.value;

              const focused = refIdx === focusedOption?.idx;

              return (
                <AutoCompleteOption
                  key={`autoopt-${idx}-${
                    typeof option.value === 'string' ||
                    typeof option.value === 'number'
                      ? option.value
                      : ''
                  }`}
                  dataHcEventName={option.dataHcEventName}
                  dataHcEventType={option.dataHcEventType}
                  dataHcEventSection={option.dataHcEventSection}
                  selected={selected}
                  focused={focused}
                  setRef={(el) => (optionRefs.current[refIdx] = el)}
                  dataHcName={optionDataHcName}
                  handleSelect={() => handleSelect(option)}
                  multiselect={config.selectType === 'multi'}
                  label={option.label}
                />
              );
            })}
            {filteredOptions.length === 0 && !isLoading && value && (
              <div
                data-hc-name={`${dataHcName}-no-options`}
                className={styles.NoOptions}
              >
                {optionsContent ? optionsContent : 'No Options'}
              </div>
            )}
            {resultsHelper && value && (
              <div
                data-hc-name={`${dataHcName}-results-helper`}
                className={styles.ResultsHelper}
              >
                {resultsHelper}
              </div>
            )}
          </div>
        </div>
      </SearchInputStyle>
      {error ? (
        <div data-hc-name={`${dataHcName}-error`} className={styles.Error}>
          {error}
        </div>
      ) : null}
    </div>
  );
};

export const AutoComplete = React.forwardRef(AutoCompleteInner);
