import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { useToastSlice } from '@hcs/toast';
import { MeaningfulEventTypes } from '@hcs/types';

import { SelfServiceApi } from '../api';

type SelfServiceChangePlanProps = UseMutationOptions<
  void,
  AxiosError<{ status: string }>,
  void,
  void
>;

const EVENT_NAME_LEGACY_TRANSITION = 'self-service-legacy-transition';

export const useTransitionLegacySelfServeOrg = (
  options?: SelfServiceChangePlanProps,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  return useMutation<void, AxiosError<{ status: string }>, void, void>(
    async () => {
      await SelfServiceApi.convertLegacy();
    },
    {
      ...options,
      onMutate(variables) {
        toastOpen({
          title: 'Setting up your account...',
          type: 'loading',
        });
        options?.onMutate?.(variables);
      },
      onError(error, variables) {
        // Track a distinct engagement event
        toastOpen({
          title: 'Failed to convert your account.',
          type: 'loading-failure',
        });
        options?.onError?.(error, variables);
      },
      onSuccess(data, variables) {
        toastOpen({
          title: 'Account Setup Successfully.',
          type: 'loading-success',
        });
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          event_name: `${EVENT_NAME_LEGACY_TRANSITION}-success`,
          event_type: MeaningfulEventTypes.Goal,
        });
        options?.onSuccess?.(data, variables);
      },
    },
  );
};
