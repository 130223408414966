import React from 'react';
import classNames from 'classnames';

import { ThumbnailCarousel } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { StreetViewDialog } from '@hcs/street-view';
import { StreetViewLauncher } from '@hcs/street-view';
import { ReportFeatures, ReportId } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import {
  usePhotosPage,
  useReportConfig,
  useSubjectDocument,
  useSubjectPhotos,
} from '../../hooks';
import { reportFeaturesSupportedAny } from '../../utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './SubjectPhotoCarousel.module.css';

export interface SubjectPhotoCarouselProps {
  reportId: ReportId;
  className?: string;
  showStreetViewIcon?: boolean;
  onStreetViewToggle?: (isStreetViewOpen: boolean) => void;
}
export const SUBJECT_PHOTO_CAROUSEL_FEATURES = [
  ReportFeatures.SubjectPhotosAll,
  ReportFeatures.SubjectPhotosCurrent,
  ReportFeatures.SubjectStreetview,
];
const dataHcName = 'subject-photo-carousel';
export const SubjectPhotoCarousel = ({
  className,
  reportId,
  showStreetViewIcon,
  onStreetViewToggle,
}: SubjectPhotoCarouselProps) => {
  const { photosPageOpen } = usePhotosPage();
  const {
    active: streetViewActive,
    handleClose,
    handleOpen,
  } = useActiveState();
  const {
    active: isHovering,
    handleClose: onIsHoveringHandleClose,
    handleOpen: onIsHoveringHandleOpen,
  } = useActiveState();
  const subjectQuery = useSubjectDocument(reportId);
  const subjectPhotosQuery = useSubjectPhotos(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const { isInitialLoading } = combineUseQueryResult([
    subjectQuery,
    subjectPhotosQuery,
  ]);
  const hasStreetView =
    !subjectPhotosQuery.isInitialLoading &&
    !!subjectPhotosQuery.data?.streetView;
  const hasMlsPhotos =
    !subjectPhotosQuery.isInitialLoading &&
    !!subjectPhotosQuery.data?.listingPhotos?.length;
  const { data: subjectDocument } = subjectQuery;
  const { data: photos } = subjectPhotosQuery;
  return (
    <>
      <ThumbnailCarousel
        dataHcName={dataHcName}
        className={className}
        isLoading={isInitialLoading}
        onClick={(e) => {
          e.stopPropagation();
          if (hasMlsPhotos) {
            photosPageOpen({ type: 'subject' });
          } else if (
            reportFeaturesSupportedAny(reportConfig, [
              ReportFeatures.SubjectStreetview,
            ]) &&
            hasStreetView
          ) {
            handleOpen();
          }
        }}
        photos={
          photos?.allPhotos.length
            ? photos.allPhotos
            : photos?.mapTile
              ? [photos.mapTile]
              : []
        }
        onMouseEnter={onIsHoveringHandleOpen}
        onMouseLeave={onIsHoveringHandleClose}
      >
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[ReportFeatures.SubjectStreetview]}
        >
          {hasMlsPhotos &&
            showStreetViewIcon !== false &&
            subjectDocument?.data && (
              <StreetViewLauncher
                location={subjectDocument.data.propertyState.location}
                dataHcName={`${dataHcName}-street-view-launcher`}
                theme={{
                  StreetViewLauncherButton: classNames(
                    styles.StreetViewLauncherButton,
                    { [styles.isHovering]: isHovering },
                  ),
                }}
                onToggle={onStreetViewToggle}
                options={{
                  publicLinkUid:
                    typeof reportId === 'string' ? reportId : undefined,
                }}
              />
            )}
        </ReportFeaturesSupported>
      </ThumbnailCarousel>
      {hasStreetView && !hasMlsPhotos && (
        <StreetViewDialog
          active={streetViewActive}
          onClose={handleClose}
          location={subjectDocument?.data.propertyState.location}
          dataHcName={`${dataHcName}-street-view-dialog`}
        />
      )}
    </>
  );
};
