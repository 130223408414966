import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Operation } from 'fast-json-patch';

import { Dialog } from '@hcs/design-system';
import { Popover } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { DeleteIcon } from '@hcs/design-system';
import { usePatchReportPreferencesForUser } from '@hcs/huell';
import { usePatchSavedCompFilterSetsForUser } from '@hcs/huell';
import { useReportPreferencesForUser } from '@hcs/huell';
import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import {
  getPrimaryFilterSetIdByCompType,
  getPrimaryFilterSetPreferencesKey,
} from '@hcs/huell';
import { SavedCompFilterSet } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';
import { NULL_VALUE } from '@hcs/utils';

import { StarCheckbox } from '../../components-deprecated';
import {
  useAppliedSavedCompFilterSet,
  useCompsFiltersDocument,
  useDocumentPatch,
  useReportPermissions,
} from '../../hooks';

import styles from './SavedCompFilterSetSelector.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
}
const dataHcName = 'saved-comp-filter-set-selector';

export const SavedCompFilterSetSelectorOption = ({
  filterSetId,
  savedFilterSet,
  onClickDelete,
  primary,
  dataHcName,
  compType,
  onSelect,
}: {
  filterSetId: string;
  compType: CompTypes;
  savedFilterSet: SavedCompFilterSet;
  primary?: boolean;
  dataHcName: string;
  onSelect: (filterSetId: string) => void;
  onClickDelete: (filterSetId: string) => void;
}) => {
  const { mutate: patchReportPreferencesForUser } =
    usePatchReportPreferencesForUser();

  const handleClickPrimary = (primaryValue: boolean) => {
    const operations: Operation[] = [
      primaryValue
        ? {
            op: 'add',
            path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
            value: filterSetId,
          }
        : {
            op: 'remove',
            path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
          },
    ];
    patchReportPreferencesForUser?.(operations);
  };
  return (
    <li
      data-hc-name={dataHcName}
      onClick={() => onSelect(filterSetId)}
      className={classNames(styles.Option, { [styles.primary]: primary })}
    >
      <div className={styles.PrimaryCell}>
        <StarCheckbox
          className={styles.PrimaryCheckbox}
          checked={!!primary}
          dataHcName={`${dataHcName}-make-primary`}
          onChange={handleClickPrimary}
        />
      </div>
      <div data-hc-name={`${dataHcName}-label`} className={styles.LabelCell}>
        {savedFilterSet.label}
      </div>
      <div className={styles.DeleteCell}>
        <DeleteIcon
          className={styles.DeleteButton}
          dataHcName={`${dataHcName}-delete`}
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(filterSetId);
          }}
          size="sm"
        />
      </div>
    </li>
  );
};

const PATH_APPLIED_FILTER_SET: CompsFiltersPaths = '/data/appliedFilterSetId';
export const SavedCompFilterSetSelector = ({ reportId, compType }: Props) => {
  const { mutate: patchReportPreferencesForUser } =
    usePatchReportPreferencesForUser();
  const { mutate: patchSavedCompFilterSetsForUser } =
    usePatchSavedCompFilterSetsForUser({
      compType,
    });
  const [isOpen, setIsOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<string | null>(null);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const primaryFilterSetPreferencesKey =
    getPrimaryFilterSetPreferencesKey(compType);

  const appliedSavedFilterSetQuery = useAppliedSavedCompFilterSet(
    reportId,
    compType,
  );
  const { data: filterSets } = useSavedCompFilterSetsForUser(compType);
  const { isInitialLoading: isLoadingReportPrefs, data: reportPreferences } =
    useReportPreferencesForUser();
  const filterQuery = useCompsFiltersDocument(reportId, compType);
  const documentPatchMutation = useDocumentPatch(reportId);
  const primaryFilterSetId = getPrimaryFilterSetIdByCompType(
    reportPreferences,
    compType,
  );
  const handleSelect = useCallback(
    (filterSetId: string) => {
      if (filterQuery.data?.documentId) {
        documentPatchMutation.mutate({
          reportId,
          document: filterQuery.data,
          operations: [
            {
              op: 'add',
              path: PATH_APPLIED_FILTER_SET,
              value: filterSetId,
            },
          ],
        });
      }
    },
    [documentPatchMutation],
  );
  const handleDeleteFilterSet = () => {
    if (filterToDelete) {
      patchSavedCompFilterSetsForUser?.([
        { op: 'remove', path: `/${filterToDelete}` },
      ]);
      if (primaryFilterSetId === filterToDelete) {
        patchReportPreferencesForUser?.([
          {
            op: 'remove',
            path: `/${primaryFilterSetPreferencesKey}`,
          },
        ]);
      }
    }
    setFilterToDelete(null);
  };
  const options = useMemo(() => {
    const filterSetIds = Object.keys(filterSets || {});
    if (!filterSetIds.length) {
      return;
    }
    return filterSetIds.map((filterSetId) => {
      const filterSet = filterSets?.[filterSetId];
      if (filterSet) {
        return (
          <SavedCompFilterSetSelectorOption
            key={`compFilterSet-${filterSetId}`}
            dataHcName={`${dataHcName}-option`}
            filterSetId={filterSetId}
            compType={compType}
            savedFilterSet={filterSet}
            onSelect={handleSelect}
            onClickDelete={setFilterToDelete}
            primary={primaryFilterSetId === filterSetId}
          />
        );
      } else {
        return null;
      }
    });
  }, [filterSets, reportPreferences, handleSelect]);
  if (
    !options ||
    isLoadingReportPrefs ||
    !filterQuery.data ||
    !reportPermissions?.isEditable
  )
    return null;
  const { appliedFilterSet } = appliedSavedFilterSetQuery.data || {};
  return (
    <>
      <Popover
        dataHcName={dataHcName}
        onChangeActiveState={setIsOpen}
        theme={{
          Button: styles.PopoverButton,
        }}
        trigger={
          <button
            className={classNames(styles.Button, {
              [styles.open]: isOpen,
              [styles.noneSelected]: !appliedFilterSet,
            })}
            data-hc-name={`${dataHcName}-button`}
          >
            {appliedFilterSet ? appliedFilterSet.label : 'Unsaved Filter set'}
            <DirectionalChevron
              dataHcName={`${dataHcName}-chevron`}
              direction="down"
              size="sm"
            />
          </button>
        }
        content={
          <div
            className={styles.Options}
            data-hc-name={`${dataHcName}-options`}
          >
            <div className={styles.OptionsHeader}>
              <div
                data-hc-name={`${dataHcName}-options-title`}
                className={styles.OptionsTitle}
              >
                Saved Filter Sets
              </div>
              <div
                data-hc-name={`${dataHcName}-options-description`}
                className={styles.OptionsDesc}
              >
                Previously saved filter criteria
              </div>
            </div>
            <ul
              className={styles.OptionsList}
              data-hc-name={`${dataHcName}-options-list`}
            >
              {options}
            </ul>
          </div>
        }
        closeOnClick
      />
      <Dialog
        active={!!filterToDelete}
        dataHcName={`${dataHcName}-delete-confirmation`}
        onClose={() => setFilterToDelete(null)}
        title="Delete Saved Filter Set"
        type="small"
        actions={[
          {
            dataHcName: `${dataHcName}-delete-confirmation-submit`,
            label: 'Delete',
            onClick: handleDeleteFilterSet,
          },
        ]}
      >
        Are you sure you want to delete{' '}
        <b>
          <i>{filterSets?.[filterToDelete || '']?.label || NULL_VALUE}</i>
        </b>
        ?
      </Dialog>
    </>
  );
};
