import { useMemo } from 'react';

import { useRegisterEngagementTrackingDataWhileMounted } from '@hcs/engagement-tracking';
import { usePropertyStatePreview } from '@hcs/property-state';
import { CerberusInputHcs } from '@hcs/types';
import { EngagementEventData } from '@hcs/types';

const EVENT_DATA_ID = 'offer_now';
export const useOfferNowEngagementTrackingData = (
  cerberusInput?: CerberusInputHcs,
  listPrice?: number | null,
) => {
  const { data: propertyStateArgs, isSuccess } =
    usePropertyStatePreview(cerberusInput);
  const engagementData: EngagementEventData | undefined = useMemo(() => {
    const location = propertyStateArgs?.propertyState?.location;
    return isSuccess
      ? {
          [EVENT_DATA_ID]: {
            listPrice,
            hcAddressId: propertyStateArgs?.propertyState?.hcAddressId,
            streetAddress: location?.address,
            unit: location?.unit,
            city: location?.city,
            state: location?.state,
            zipcode: location?.zipcode,
            slug: location?.addressSlug,
            msaId: location?.msaId,
            countyName: location?.county,
            countyFips: location?.fips,
          },
        }
      : undefined;
  }, [isSuccess, propertyStateArgs, listPrice]);
  useRegisterEngagementTrackingDataWhileMounted(EVENT_DATA_ID, engagementData);
};
