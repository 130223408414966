import { AppraisalCompDocument, DocumentRoles, ReportId } from '@hcs/types';

import { useDocumentRole } from '.';

export const useAppraisalCompDocuments = (reportId: ReportId) => {
  return useDocumentRole<AppraisalCompDocument>(
    reportId,
    DocumentRoles.AppraisalComp,
  );
};
