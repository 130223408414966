import { useAccount } from './useAccount';

export const useUserIsLegacySelfService = () => {
  const accountQuery = useAccount();
  if (accountQuery.isSuccess) {
    return {
      ...accountQuery,
      data: accountQuery.data.organization.type === 'legacy-ss',
    };
  } else {
    return {
      ...accountQuery,
      data: undefined,
    };
  }
};
