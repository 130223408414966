import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { SingleSignOnApi } from '../api/SingleSignOn.api';
export const QUERY_KEY_ORG_SAML_PROVIDER = 'org-saml-provider';
export const useOrgSamlProvider = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_SAML_PROVIDER, orgId],
    async () => {
      if (orgId) {
        return await SingleSignOnApi.fetchOrgSamlProvider(orgId);
      } else {
        logException(
          'useOrgSamlProvider: orgId is undefined, check enabled logic',
        );
        throw new Error('An Error Occurred');
      }
    },
    {
      enabled: !!orgId,
    },
  );
};
