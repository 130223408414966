import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CardTitle.module.css';

export interface CardTitleProps {
  dataHcName?: string;
  className?: string;
  children: ReactNode;
  onClick?: VoidFunction;
}
export const CardTitle = ({
  dataHcName,
  className,
  onClick,
  children,
}: CardTitleProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CardTitle, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
