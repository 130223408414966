import { PayloadAction } from '@reduxjs/toolkit';
import { UseQueryResult } from '@tanstack/react-query';

import { ItemObjInShoppingCart } from '@hcs/types';
import {
  DexpEndpoint,
  DexpEndpointsFilterIds,
  DexpEndpointsParams,
} from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

import { DEXP_ENDPOINT_LEVEL_LABEL } from '../constants';

export interface DataExplorerSliceState {
  filters: DexpEndpointsParams;
  allAvailableDexpEndpoints?: UseQueryResult<DexpEndpoint[]>;
  dexpEndpointsQuery?: UseQueryResult<DexpEndpoint[]>;
  endpointsInShoppingCart: Record<DexpEndpoint['id'], ItemObjInShoppingCart>;
}

export interface DataExplorerPartialAppState {
  dataExplorerSlice: DataExplorerSliceState;
}

const initialState: DataExplorerSliceState = {
  filters: {},
  endpointsInShoppingCart: {},
};

export const dataExplorerSlice = createHcReduxSlice({
  name: 'dataExplorerSlice',
  initialState,
  reducers: {
    tableChangeFilter: (
      state,
      action: PayloadAction<{ filterChanges: DexpEndpointsParams }>,
    ) => {
      let field: DexpEndpointsFilterIds;
      for (field in action.payload.filterChanges) {
        const filterValue = action.payload.filterChanges[field];
        state.filters = {
          ...state.filters,
          [field]: filterValue ? filterValue : undefined,
        };
      }
    },
    setEndpointsInCart(
      state,
      action: PayloadAction<{
        endpointsData: Record<DexpEndpoint['id'], ItemObjInShoppingCart>;
      }>,
    ) {
      state.endpointsInShoppingCart = action.payload.endpointsData;
    },
    addToCart(
      state,
      action: PayloadAction<{
        endpointId: DexpEndpoint['id'];
        name: DexpEndpoint['name'];
        level: DexpEndpoint['level'];
      }>,
    ) {
      state.endpointsInShoppingCart = {
        ...state.endpointsInShoppingCart,
        [action.payload.endpointId]: {
          isSelected: true,
          label: action.payload.name,
          rightSideLabel: DEXP_ENDPOINT_LEVEL_LABEL[action.payload.level],
        },
      };
    },
    removeFromCart(
      state,
      action: PayloadAction<{ endpointId: DexpEndpoint['id'] }>,
    ) {
      delete state.endpointsInShoppingCart[action.payload.endpointId];
    },
    deselectAndLeaveInCart(
      state,
      action: PayloadAction<{ endpointId: DexpEndpoint['id'] }>,
    ) {
      state.endpointsInShoppingCart = {
        ...state.endpointsInShoppingCart,
        [action.payload.endpointId]: {
          ...state.endpointsInShoppingCart[action.payload.endpointId],
          isSelected: false,
        },
      };
    },
    clearCart(state) {
      state.endpointsInShoppingCart = initialState.endpointsInShoppingCart;
    },
  },
});
