import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  EventTypes,
  ListingStatusCerberus,
  PropertyStateArgs,
  PropertyStateFields,
} from '@hcs/types';
import {
  listingStatusNormalized,
  listingStatusNormalizedFromListingEvent,
} from '@hcs/utils';

import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs/index';
import { getPropertyStateFieldValue } from '../../utils';

import styles from './ListingStatusIndicator.module.css';

export interface ListingStatusIndicatorProps {
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  saturated?: boolean;
  isRental?: boolean;
  /**
   * Only show the badge if in an active or pending status
   */
  showActiveOnly?: boolean;
  showPrice?: boolean;
  /**
   * Supports both Listing Statuses and Listing Events (from Transaction History)
   */
  value:
    | {
        type: 'listingEvent';
        value: EventTypes | null | undefined;
      }
    | {
        type: 'listingStatus';
        value: ListingStatusCerberus | null | undefined;
      }
    | {
        type: 'propertyState';
        value: PropertyStateArgs | null | undefined;
      };
  label?: ReactNode;
}
const dataHcName = 'listing-status-indicator';
export const ListingStatusIndicator = ({
  className,
  saturated,
  label,
  isRental,
  showActiveOnly,
  showPrice,
  value,
}: ListingStatusIndicatorProps) => {
  // Convert all possible statues to either Active, Pending or Sold
  const propertyStateField = isRental
    ? PropertyStateFields.currentStatusRental
    : PropertyStateFields.currentStatus;
  const normalizedStatus = !value.value
    ? undefined
    : value.type === 'propertyState'
      ? listingStatusNormalized(
          getPropertyStateFieldValue(propertyStateField, value.value),
        )
      : value.type === 'listingStatus'
        ? listingStatusNormalized(value.value)
        : listingStatusNormalizedFromListingEvent(value.value);
  if (
    showActiveOnly &&
    value.type !== 'listingEvent' &&
    normalizedStatus !== 'Active' &&
    normalizedStatus !== 'Pending'
  ) {
    return null;
  }
  const { Display: DisplayCurrentPrice } =
    PROPERTY_STATE_FIELD_CONFIGS[
      isRental
        ? PropertyStateFields.currentPriceRental
        : PropertyStateFields.currentPrice
    ];
  return (
    <div
      className={classNames(styles.Wrapper, className)}
      data-hc-name={dataHcName}
    >
      <div
        data-hc-name={`${dataHcName}-status-wrapper`}
        className={classNames(
          styles.ListingStatusIndicator,
          normalizedStatus &&
            styles[
              normalizedStatus.toLowerCase() as 'active' | 'pending' | 'closed'
            ],
          {
            [styles.saturated]: saturated,
          },
        )}
      >
        {(value.type === 'propertyState'
          ? getPropertyStateFieldValue(
              propertyStateField,
              value.value || undefined,
            )
          : value.value) || 'Unknown'}
        {showPrice && value.type === 'propertyState' && value.value && (
          <span data-hc-name={`${dataHcName}-price`} className={styles.Price}>
            <DisplayCurrentPrice propertyStateArgs={value.value} />
          </span>
        )}
      </div>
      {label && (
        <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
          {label}
        </div>
      )}
    </div>
  );
};
