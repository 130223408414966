import React, { useMemo } from 'react';

import {
  ChartTimeSeriesLine,
  ChartTimeSeriesLineProps,
  LINE_COLOR,
} from '@hcs/design-system';
import { CerberusStatsRpiTS } from '@hcs/types';
import { getD3NumberFormat } from '@hcs/utils';

const FORECAST_RPI_DATA_ID = 'forecastRpi';

const LEGEND_ITEMS: ChartTimeSeriesLineProps['legendItems'] = [
  {
    id: 'historical',
    title: 'Historical RPI',
    background: { type: 'single', color: LINE_COLOR },
  },
  {
    id: 'forecast',
    title: 'Forecasted RPI',
    background: { type: 'single', color: LINE_COLOR },
    isDotDashed: true,
  },
];

const D3_Y_FORMAT = getD3NumberFormat({ precision: 1 });

export interface CerberusStatsRpiChartProps {
  dataHcName: string;
  className?: string;
  isLoading: boolean;
  data: CerberusStatsRpiTS | null | undefined;
}
export const CerberusStatsRpiChart = ({
  className,
  dataHcName,
  isLoading,
  data,
}: CerberusStatsRpiChartProps) => {
  const historicalTimeSeries = data?.historicalTimeSeries;
  const forecastTimeSeries = data?.forecastTimeSeries;
  const chartData: ChartTimeSeriesLineProps['chartData'] = useMemo(() => {
    if (!historicalTimeSeries || !forecastTimeSeries) {
      return null;
    }
    const historicalLastRecord =
      historicalTimeSeries[historicalTimeSeries.length - 1];
    if (historicalLastRecord == null) {
      return null;
    }
    const forecastTimeSeriesWithLastHistoric = [
      historicalLastRecord,
      ...forecastTimeSeries,
    ];
    return [
      {
        id: 'historicalRpi',
        data:
          historicalTimeSeries?.map((record) => {
            return {
              x: `${record.month}`,
              y: record.valueIndexed,
            };
          }) || [],
      },
      {
        id: FORECAST_RPI_DATA_ID,
        data:
          forecastTimeSeriesWithLastHistoric?.map((record) => {
            return {
              x: `${record.month}`,
              y: record.valueIndexed,
            };
          }) || [],
      },
    ];
  }, [historicalTimeSeries, forecastTimeSeries]);

  return (
    <ChartTimeSeriesLine
      dataHcName={dataHcName}
      isLoading={isLoading}
      chartData={chartData}
      title="Rental Price Index"
      tooltipDateFormat={'monthYear'}
      d3FormatterForY={D3_Y_FORMAT}
      theme={{
        Container: className,
      }}
      forecastSerieId={FORECAST_RPI_DATA_ID}
      legendItems={LEGEND_ITEMS}
    />
  );
};
