import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useLatestMutationData } from '@hcs/hooks';
import { AddressSearchInputs, AddressSearchResults } from '@hcs/types';

import { AddressSearchApi } from '../api';
import { searchStringContainsUnit } from '../utils/addressSearch.utils';

export type UseAddressSearchOptions = UseMutationOptions<
  AddressSearchResults,
  unknown,
  AddressSearchInputs
>;

export const useAddressSearch = (options?: UseAddressSearchOptions) => {
  // Return the latest successful result
  // Prevents results in autocomplete components from disappearing as the user types
  return useLatestMutationData(
    useMutation<AddressSearchResults, unknown, AddressSearchInputs>(
      async (addressSearchInputs: AddressSearchInputs) => {
        // Return individual units if a building Id is provided
        const searchType: 'building' | 'address' = searchStringContainsUnit(
          addressSearchInputs.searchStr || '',
        )
          ? 'address'
          : 'building';
        const hits =
          searchType === 'address'
            ? await AddressSearchApi.fetchAddressSearch(addressSearchInputs)
            : await AddressSearchApi.fetchAddressSearchBuilding(
                addressSearchInputs,
              );

        return {
          type: searchType,
          hits,
        };
      },
      options,
    ),
  );
};
