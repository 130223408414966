import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useIsLoggedIn, useUserIsConfirmed } from '@hcs/authn';
import {
  HEADER_HEIGHT_LG,
  HEADER_HEIGHT_PHONE,
  HouseCanaryLogoFullColor,
  HouseCanaryLogomarkCurrentColor,
} from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';

import { useCurrentAppConfig } from '../../hooks/useCurrentAppConfig';

import styles from './GlobalHeader.module.css';

export interface GlobalHeaderProps {
  /** AppLauncher feature for multi-app bundles */
  appLauncher?: ReactNode;
  /** Content to be rendered in the center of the header */
  children?: ReactNode;
  /** Content to be rendered in the header when logged in */
  authenticatedAdditionalHeaderContent?: ReactNode;
  /** Popover Actions to display when logged in */
  authenticatedActionPopovers?: ReactNode;
  /** Popover Actions to display when not logged in */
  unauthenticatedActionPopovers?: ReactNode;
  /** Fixed position */
  fixed?: boolean;
  className?: string;
}

/** Props that are commonly exposed by the parent of this feature */
export type GlobalHeaderExtendableProps = Omit<
  GlobalHeaderProps,
  'allBundleAppConfigs' | 'appLauncher'
>;

// For fixed header layouts
const GLOBAL_HEADER_HEIGHT = HEADER_HEIGHT_LG;
const GLOBAL_HEADER_HEIGHT_PHONE = HEADER_HEIGHT_PHONE;
const dataHcName = 'global-nav';
export const GlobalHeader = ({
  appLauncher,
  authenticatedAdditionalHeaderContent,
  authenticatedActionPopovers,
  unauthenticatedActionPopovers,
  className,
  fixed = true,
  children,
}: GlobalHeaderProps) => {
  const currentAppConfig = useCurrentAppConfig();
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  const { data: userIsConfirmed, isLoading: userIsConfirmedIsLoading } =
    useUserIsConfirmed();
  const { isPhoneWidth } = useScreenSize();
  const title =
    currentAppConfig.globalHeaderTitle != null
      ? currentAppConfig.globalHeaderTitle
      : currentAppConfig.name;
  return (
    <header
      style={{
        height: `${
          isPhoneWidth ? GLOBAL_HEADER_HEIGHT_PHONE : GLOBAL_HEADER_HEIGHT
        }px`,
      }}
      className={classNames(styles.Header, className, {
        [styles.fixed]: fixed,
      })}
      data-hc-name={dataHcName}
    >
      {isLoggedIn && appLauncher}
      <div className={styles.LogoProductNameContainer}>
        <Link
          to="/"
          className={styles.Link}
          data-hc-name={`${dataHcName}-main-link`}
        >
          {isPhoneWidth ? (
            <HouseCanaryLogomarkCurrentColor
              dataHcName={`${dataHcName}-main-logo`}
              className={styles.HouseCanaryLogoMark}
            />
          ) : (
            <HouseCanaryLogoFullColor
              className={styles.HouseCanaryLogoFull}
              dataHcName={`${dataHcName}-main-logo`}
            />
          )}
        </Link>

        {title && (
          <>
            <div className={styles.Divider} />
            {(userIsConfirmed || userIsConfirmedIsLoading) &&
            currentAppConfig.rootPath ? (
              <Link
                className={styles.AppName}
                data-hc-name={`${dataHcName}-product-name`}
                to={currentAppConfig.rootPath}
              >
                {title}
              </Link>
            ) : (
              <div
                className={styles.AppName}
                data-hc-name={`${dataHcName}-product-name`}
              >
                {title}
              </div>
            )}
          </>
        )}
      </div>
      {children}
      <div data-hc-name={`${dataHcName}-actions`} className={styles.Actions}>
        {isLoggedIn &&
          authenticatedAdditionalHeaderContent &&
          authenticatedAdditionalHeaderContent}
        {isLoggedIn
          ? authenticatedActionPopovers
          : unauthenticatedActionPopovers}
      </div>
    </header>
  );
};
