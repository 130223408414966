import { BulkHpiIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_BULK_RPI: AppConfig = {
  name: 'Bulk RPI Data',
  applicationKey: 'bulk-rpi',
  type: 'product',
  appSlug: AppSlugs.Platform,
  rootPath: `/`,
  Icon: BulkHpiIcon,
  descriptionLong:
    'Bulk RPI Data at the Zip/MSA/State levels, delivered monthly.',
  descriptionShort: 'Bulk RPI Data at the Zip/MSA/State levels',
};
