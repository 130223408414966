import { ReportId, ReportTypes } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useOfferNowAvailableForProperty } from '../hooks/useOfferNowAvailableForProperty';
import { useReport } from '../hooks/useReport';

export const useOfferNowAvailableForReport = (reportId: ReportId) => {
  const reportQuery = useReport(reportId);
  const offerNowQuery = useOfferNowAvailableForProperty(
    reportQuery.data ? { hcAddressId: reportQuery.data.addressID } : undefined,
  );
  const combinedQuery = combineUseQueryResult([reportQuery, offerNowQuery]);
  if (combinedQuery.isSuccess) {
    const { data: reportData } = reportQuery;
    const { data: offerNowAvailableForProperty } = offerNowQuery;
    return {
      ...combinedQuery,
      data:
        !!offerNowAvailableForProperty &&
        reportData?.reportType === ReportTypes.Value,
    };
  }
  return {
    ...combinedQuery,
    data: false,
  };
};
