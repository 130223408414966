import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { QUERY_KEY_ORG_SELF_SERVE_INFO } from '@hcs/self-serve-info';
import { useToastSlice } from '@hcs/toast';
import { delayPromise } from '@hcs/utils';

import { SelfServiceApi } from '../api';

type SelfServiceChangePlanProps = UseMutationOptions<
  void,
  AxiosError<{ status: string }>,
  void,
  void
>;

const EVENT_NAME_CANCEL_PLAN = 'self-service-cancel-plan';

export const useSelfServiceCancelPlan = (
  options?: SelfServiceChangePlanProps,
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  return useMutation<void, AxiosError<{ status: string }>, void, void>(
    async () => {
      // Api returns a 202
      await SelfServiceApi.cancelPlan();
      await delayPromise(3000);
    },
    {
      ...options,
      onMutate(variables) {
        toastOpen({
          title: 'Canceling plan...',
          type: 'loading',
        });
        options?.onMutate?.(variables);
      },
      onError(error, variables) {
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          event_name: `${EVENT_NAME_CANCEL_PLAN}-error`,
        });
        toastOpen({
          title: 'Failed to Cancel Plan.',
          type: 'loading-failure',
        });
        options?.onError?.(error, variables);
      },
      onSuccess(data, variables) {
        toastOpen({
          title: 'Plan Cancelled Successfully.',
          type: 'loading-success',
        });
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          event_name: `${EVENT_NAME_CANCEL_PLAN}-success`,
          event_data: {
            canceled_subscription: true,
          },
        });
        queryClient.invalidateQueries([QUERY_KEY_ORG_SELF_SERVE_INFO]);
        options?.onSuccess?.(data, variables);
      },
    },
  );
};
