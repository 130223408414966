import {
  MouseEvent as MouseEventReact,
  RefObject,
  useCallback,
  useEffect,
} from 'react';

export const useClickOutsideComponent = <T extends HTMLElement>(
  ref: RefObject<T[]> | RefObject<T> | null,
  callback: (e: Event | MouseEvent | MouseEventReact<HTMLElement>) => void,
) => {
  const handleAllClicks = useCallback(
    (e: Event | MouseEvent | MouseEventReact<HTMLElement>) => {
      if (ref) {
        const target = e.target as Node;
        let clickedOutside = true;

        const arrayRef = Array.isArray(ref.current)
          ? ref.current
          : [ref.current];
        for (const ref of arrayRef) {
          if (ref?.contains(target)) {
            clickedOutside = false;
            break;
          }
        }
        if (clickedOutside) {
          callback(e);
        }
      }
    },
    [callback, ref],
  );
  // Using document-level listener to identify clicks outside of the component
  useEffect(() => {
    document.addEventListener('mousedown', handleAllClicks);
    return () => {
      document.removeEventListener('mousedown', handleAllClicks);
    };
  }, [callback, handleAllClicks]);
};
