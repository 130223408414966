import React from 'react';

import { RadioOption, RadioSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';

import { useCompsFiltersDocument, useSubjectDocument } from '../../../hooks';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

import { CompFilterChip } from './CompFilterChip';

import styles from '../CompFilters.module.css';

const FIELD = PropertyStateFields.stories;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const OPTIONS: RadioOption<null | 'Single Story' | 'Multi Story'>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Single Story',
    value: 'Single Story',
  },
  {
    label: 'Multi Story',
    value: 'Multi Story',
  },
];
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-stories';
export const COMP_FILTERS_STORIES: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const active = !!filterValue;
    const label =
      active && filterValue.absoluteValue
        ? filterValue.absoluteValue[0] === 1
          ? 'Single Story'
          : 'Multi Story'
        : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType, className }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }

    const filterValue = filterDocument.data.filters?.[FIELD];
    const subjectValue = getPropertyStateFieldValue(FIELD, {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectDocument.data.propertyState,
    });

    return (
      <RadioSelect
        dataHcName={`${dataHcName}-select`}
        value={
          filterValue?.absoluteValue
            ? filterValue.absoluteValue[0] === 1
              ? 'Single Story'
              : 'Multi Story'
            : null
        }
        disabled={!reportPermissions.isEditable}
        options={OPTIONS.map((o) =>
          (o.value === 'Single Story' && subjectValue === 1) ||
          (o.value === 'Multi Story' && subjectValue && subjectValue > 1)
            ? {
                ...o,
                label: (
                  <>
                    {o.label}
                    <div
                      className={styles.SubjectPropertyMatch}
                      data-hc-name={`${dataHcName}-subject-match`}
                    >
                      Subject property match
                    </div>
                  </>
                ),
              }
            : o,
        )}
        className={className}
        onChange={(value) => {
          if (value === null) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value: value === 'Single Story' ? [1, 1] : [2, null],
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
