import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Switch } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { AppConfig, Role } from '@hcs/types';

import styles from './RoleSwitch.module.css';

export interface RoleSwitchProps {
  className?: string;
  appConfig?: AppConfig;
  role: Role;
  value: boolean;
  isSubToggle?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}
export const RoleSwitch = ({
  className,
  role,
  appConfig,
  value,
  onChange,
  disabled,
  isSubToggle,
}: RoleSwitchProps) => {
  let label: ReactNode = role.display_name;
  if (appConfig) {
    const { Icon, name } = appConfig;
    label = (
      <div className={styles.AppSwitch}>
        {Icon && <Icon />}
        {name}
      </div>
    );
  }
  return (
    <DialogInputLayout
      dataHcName={`access-${role.key}-switch`}
      key={role.key}
      label={label || role.display_name}
      className={classNames(
        {
          [styles.SubToggles]: isSubToggle,
        },
        className,
      )}
      theme={{
        InputContainer: styles.InputContainer,
        InputLabelContainer: styles.InputLabelContainer,
      }}
      required={false}
    >
      <Switch<boolean>
        dataHcName={`access-${role.key}-switch`}
        disabled={disabled}
        value={value}
        switchOnOption={{ value: true }}
        switchOffOption={{ value: false }}
        onChange={onChange}
      />
    </DialogInputLayout>
  );
};
