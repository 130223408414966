import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';

import { HuellApi } from '@hcs/huell';
import { useToastSlice } from '@hcs/toast';
import { DexpTemplates } from '@hcs/types';
import { jsonPatchApplyEnhanced } from '@hcs/utils';

import { QUERY_KEY_DEXP_TEMPLATES_USER } from '../hooks/useDexpTemplatesForUser';

export const usePatchDexpTemplatesForUser = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const patchDexpTemplatesMutation = useMutation(
    async (operations: Operation[]) => {
      return await HuellApi.patchDexpTemplatesForUser(operations);
    },
    {
      onMutate: async (operations) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)

        // Snapshot the previous value
        const previousDexpTemplates =
          queryClient.getQueryData<DexpTemplates>([
            QUERY_KEY_DEXP_TEMPLATES_USER,
          ]) || {};

        // Optimistically update to the new value
        queryClient.setQueryData<DexpTemplates>(
          [QUERY_KEY_DEXP_TEMPLATES_USER],
          jsonPatchApplyEnhanced(previousDexpTemplates, operations)[0]
            ?.newDocument,
        );

        // Return a context object with the snapshooted value
        return { previousDexpTemplates };
      },
      onError: (err, operations, context) => {
        toastOpen({
          type: 'error',
          title:
            operations[0]?.op === 'remove'
              ? 'Failed to Delete DEXP Template'
              : 'Failed to Save DEXP Template',
        });
        console.error(err);
        queryClient.setQueryData<DexpTemplates>(
          [QUERY_KEY_DEXP_TEMPLATES_USER],
          context?.previousDexpTemplates,
        );
      },
      onSuccess: (newDexpTemplates: DexpTemplates, operations: Operation[]) => {
        toastOpen({
          type: 'success',
          title:
            operations[0]?.op === 'remove'
              ? 'DEXP Template Deleted'
              : 'DEXP Template Saved',
        });
        queryClient.setQueryData<DexpTemplates>(
          [QUERY_KEY_DEXP_TEMPLATES_USER],
          newDexpTemplates,
        );
      },
    },
  );
  return {
    ...patchDexpTemplatesMutation,
    mutate: patchDexpTemplatesMutation.mutate,
  };
};
