// @flow
import React, { ChangeEvent, KeyboardEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';

import { ErrorIcon } from '../../../../svgs/icons/indicator';
import { CloseIcon } from '../../../../svgs/icons/navigation';

import styles from './InputComplex.module.css';

type ValueType = number | string;

type LabelProps = {
  dataHcName: string;
  floating?: boolean;
  label: string;
  required?: boolean;
  className: string;
  children?: ReactNode;
};

const isValuePresent = (value: ValueType) => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !(typeof value === 'number' && Number.isNaN(value))
  );
};

const Label = ({
  dataHcName,
  className,
  label,
  required,
  children,
}: LabelProps) => {
  return (
    <label data-hc-name={`${dataHcName}-label`} className={className}>
      {children}
      {label}
      {required ? (
        <span data-hc-name={`${dataHcName}-label`} className={styles.required}>
          {' '}
          *{' '}
        </span>
      ) : null}
    </label>
  );
};

export interface InputComplexTheme {
  InputElement?: string;
}
export interface InputComplexProps {
  children?: ReactNode;
  dataHcName: string;
  value: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  floating?: boolean;
  hint?: string;
  label?: string;
  maxLength?: number;
  placeholder?: string;
  required?: boolean;
  role?: string;
  mini?: boolean;
  type?: string;
  onBlur?: () => void;
  onChange: (v: string) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  onFocus?: () => void;
  name?: string;
  canClear?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  theme?: InputComplexTheme;
}
export const InputComplex = ({
  dataHcName,
  className,
  value,
  label,
  disabled,
  error,
  type,
  mini,
  canClear,
  name,
  placeholder,
  role,
  required,
  maxLength,
  hint,
  children,
  floating,
  noBorder,
  noPadding,
  theme,
  onChange,
  onKeyUp,
  onBlur,
  onFocus,
}: InputComplexProps) => {
  const [initialValue] = useState(value);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const value = event.target.value || '';
      onChange(value);
    }
  };

  const handleClear = () => {
    onChange('');
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.Input,
        {
          [styles.disabled]: disabled,
          [styles.errored]: error,
          [styles.hidden]: type === 'hidden',
          [styles.noPadding]: noPadding,
        },
        className,
      )}
    >
      <div
        className={classNames(styles.InputBorder, {
          [styles.noBorder]: noBorder,
        })}
      >
        <input
          data-hc-name={`${dataHcName}-input`}
          className={classNames(styles.InputElement, theme?.InputElement, {
            [styles.filled]: isValuePresent(value),
            [styles.initialValue]: value && value === initialValue,
            [styles.mini]: mini,
            [styles.visiblePlaceholder]: !label && placeholder,
          })}
          name={name}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          role={role}
          disabled={disabled}
          required={required}
          type={type}
          maxLength={maxLength}
          value={value}
        />
        {canClear && value && !disabled && (
          <CloseIcon
            className={styles.ClearButton}
            onClick={handleClear}
            dataHcName={`${dataHcName}-close-button`}
          />
        )}
        {error && !canClear ? (
          <ErrorIcon dataHcName={`${dataHcName}-error-icon`} />
        ) : null}
        {children}
      </div>
      {label ? (
        <>
          <Label
            dataHcName={dataHcName}
            className={classNames(styles.Label, {
              [styles.fixed]: !floating,
            })}
            floating={floating}
            label={label}
            required={required}
          />
          <Label
            dataHcName={`${dataHcName}-label-animation-mask`}
            className={classNames(styles.LabelMaskOuter, {
              [styles.fixed]: !floating,
            })}
            floating={floating}
            label={label}
            required={required}
          >
            <div className={styles.LabelMask} />
          </Label>
        </>
      ) : null}
      {hint && !error ? (
        <span data-hc-name={`${dataHcName}-hint`} className={styles.hint}>
          {hint}
        </span>
      ) : null}
      {error ? <span data-hc-name={`${dataHcName}-error`}>{error}</span> : null}
      {maxLength ? (
        <span data-hc-name={`${dataHcName}-counter`}>
          {`${maxLength && value ? String(value).length : 0}/${maxLength}`}
        </span>
      ) : null}
    </div>
  );
};
