import {
  AddressSearchApiParams,
  AddressSearchInputs,
  StringBool,
} from '@hcs/types';

import { UNIT_INDICATORS } from '../constants/addressSearch.constants';

export const searchStringContainsUnit = (searchString: string) => {
  const regExp = new RegExp(' ' + UNIT_INDICATORS.join('| '), 'i');
  return regExp.test(searchString);
};
export const boolToStrBool = (bool: boolean): StringBool => (bool ? '1' : '0');

export const addressSearchInputsToApiParams = (
  addressSearchInputs: AddressSearchInputs,
): AddressSearchApiParams => {
  const results: AddressSearchApiParams = {
    q: addressSearchInputs.searchStr || '',
  };
  if (addressSearchInputs.geoLocation) {
    results.lat_lon = `${addressSearchInputs.geoLocation.latitude},${addressSearchInputs.geoLocation.longitude}`;
  }
  if (addressSearchInputs.hasAvm) {
    results.has_avm = boolToStrBool(addressSearchInputs.hasAvm);
  }
  if (addressSearchInputs.bldgId) {
    results.bldg_id = addressSearchInputs.bldgId;
  }
  return results;
};
