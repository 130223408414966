import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OrderItem, OrderItemsParams, PaginatedResponseData } from '@hcs/types';

import { ViewOrderApi } from '../api/viewOrderApi';

export const QUERY_KEY_ORDER_ITEMS = 'order-items';
export const useOrderItems = (
  orderId: number,
  params: OrderItemsParams,
  options?: UseQueryOptions<
    PaginatedResponseData<OrderItem[]>,
    AxiosError,
    PaginatedResponseData<OrderItem[]>,
    [typeof QUERY_KEY_ORDER_ITEMS, number, OrderItemsParams | undefined]
  >,
) => {
  return useQuery(
    [QUERY_KEY_ORDER_ITEMS, orderId, params],
    async ({ signal }) => {
      return ViewOrderApi.fetchOrderItems(orderId, params, signal);
    },
    options,
  );
};
