import React from 'react';

import { BuyBox } from '@hcs/types';

import {
  BathsRange,
  BedsRange,
  BuyBoxZipMultiSelectField,
  ExcludeCheckboxesInput,
  GarageSpacesRange,
  GlaRange,
  HistoricalDetectionToggle,
  ListingPriceRange,
  LotSizeRange,
  MsaReadOnly,
  NameInput,
  NumStoriesRange,
  PropertyTypeMultiSelect,
  YearBuiltRange,
} from '../BuyBoxInputs';

interface Props {
  msaId?: BuyBox['msaId'];
  buyBoxId: BuyBox['id'];
  enabled: boolean;
}

export const EditBuyBoxForm = ({ msaId, buyBoxId, enabled }: Props) => (
  <form>
    <NameInput required />
    <MsaReadOnly msaId={msaId} required />
    <ListingPriceRange required />
    <BedsRange />
    <BathsRange />
    <GlaRange />
    <LotSizeRange />
    <YearBuiltRange />
    <NumStoriesRange />
    <GarageSpacesRange />
    <PropertyTypeMultiSelect required />
    <ExcludeCheckboxesInput />
    <BuyBoxZipMultiSelectField
      required
      config={{ source: 'buyBox', buyBoxId }}
    />
    {enabled && <HistoricalDetectionToggle />}
  </form>
);
