import React, { useMemo } from 'react';

import {
  ChartTimeSeriesLine,
  ChartTimeSeriesLineProps,
} from '@hcs/design-system';
import { DateStr } from '@hcs/types';
import { formatPercentD3, getD3PercentFormat } from '@hcs/utils';

import { MACRO_INSIGHTS_FROM_DATE } from '../../constants/marketInsights.constants';
import { useMsaUnemploymentTimeSeries } from '../../hooks/useMsaUnemploymentTimeSeries';

const D3_FORMAT_CONFIG = { precision: 1 };
const D3_FORMAT_STR = getD3PercentFormat(D3_FORMAT_CONFIG);

const dataHcName = 'msa-unemployment-chart';
interface MsaUnemploymentChartProps {
  className?: string;
  msaId: string;
  fromDate?: DateStr;
  toDate?: DateStr;
}
export const MsaUnemploymentChart = ({
  msaId,
  className,
  fromDate = MACRO_INSIGHTS_FROM_DATE,
  toDate,
}: MsaUnemploymentChartProps) => {
  const { data, isInitialLoading } = useMsaUnemploymentTimeSeries({
    msaId,
    fromDate,
    toDate,
  });

  const timeSeriesData =
    data?.lookupMsaDetails?.populationStats?.unemploymentRate?.timeSeries;
  const chartData: ChartTimeSeriesLineProps['chartData'] = useMemo(() => {
    if (!timeSeriesData || timeSeriesData.length === 0) {
      return null;
    }
    return [
      {
        id: 'unemployment',
        data: timeSeriesData.map((record) => {
          return {
            x: `${record.month}`,
            y: record.unemploymentRate != null ? record.unemploymentRate : null,
          };
        }),
      },
    ];
  }, [timeSeriesData]);
  // current value should be the last record
  const currentUnemploymentRate = timeSeriesData
    ? timeSeriesData[timeSeriesData.length - 1]
    : null;

  if (!isInitialLoading && (!timeSeriesData || timeSeriesData.length === 0)) {
    return null;
  }

  return (
    <ChartTimeSeriesLine
      dataHcName={dataHcName}
      isLoading={isInitialLoading}
      chartData={chartData}
      title="Unemployment Rate"
      CurrentValue={
        <>
          {formatPercentD3(
            currentUnemploymentRate?.unemploymentRate,
            D3_FORMAT_CONFIG,
          )}
        </>
      }
      d3FormatterForY={D3_FORMAT_STR}
      theme={{
        Container: className,
      }}
    />
  );
};
