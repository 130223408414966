import { MutableRefObject, useCallback } from 'react';

import { hcsConsole } from '@hcs/console';
import {
  ConnectionStatus,
  EventSourceType,
  ReportId,
  SSEEvent,
  SSEEventTypes,
} from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

export const useCloseEventSource = <T extends SSEEventTypes>(
  eventSource: MutableRefObject<{
    eventSourceAbortController: AbortController;
    id: string | ReportId;
  } | null>,
  listeners: MutableRefObject<{
    onMessage: Map<string, (e: SSEEvent<T>) => void | undefined>;
  }>,
  connectionStatus: MutableRefObject<ConnectionStatus>,
  eventSourceType: EventSourceType,
) => {
  return useCallback(
    ({ shouldClearListeners }: { shouldClearListeners: boolean }) => {
      if (eventSource.current !== null) {
        hcsConsole.log(
          `${capitalizeFirstLetter(eventSourceType)}-Api: SSE | disconnected`,
        );
        eventSource.current?.eventSourceAbortController.abort();
        eventSource.current = null;
        if (shouldClearListeners) {
          listeners.current.onMessage?.clear();
        }
      }
      connectionStatus.current = ConnectionStatus.Disconnected;
    },
    [eventSource, listeners, connectionStatus],
  );
};
