import { PayloadAction } from '@reduxjs/toolkit';

import { ReportConfigSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: ReportConfigSliceState = {};
export const reportConfigSlice = createHcReduxSlice({
  name: 'reportConfig',
  initialState,
  reducers: {
    setReportFeatureSupport(
      state,
      action: PayloadAction<ReportConfigSliceState>,
    ) {
      return action.payload;
    },
    setReportFeatureSupportPartial(
      state,
      action: PayloadAction<ReportConfigSliceState>,
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
