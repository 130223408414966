import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';

import { SingleSignOnApi } from '../api/SingleSignOn.api';

import { QUERY_KEY_ORG_IS_SSO_ONLY } from './useOrgIsSsoOnly';
import { QUERY_KEY_ORG_SAML_PROVIDER } from './useOrgSamlProvider';

interface Params {
  orgId: number;
  providerId: string;
  onDeleteSuccess?: VoidFunction;
}

export const useDeleteOrgSamlProvider = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError, Params>(
    async ({ providerId, orgId }) =>
      await SingleSignOnApi.deleteOrgSamlProvider(orgId, providerId),
    {
      onSuccess: (data, variables) => {
        queryClient.resetQueries({
          queryKey: [QUERY_KEY_ORG_SAML_PROVIDER, variables.orgId],
          exact: true,
        });
        queryClient.invalidateQueries([
          QUERY_KEY_ORG_IS_SSO_ONLY,
          variables.orgId,
        ]);
        toastOpen({
          type: 'success',
          title: 'Saml Settings Deleted',
        });
        variables.onDeleteSuccess?.();
      },
    },
  );
};
