import {
  ComparableValue,
  ReportId,
  ValuationMethod,
  ValuationMethodLabel,
} from '@hcs/types';
import { ValueExtended } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useSubjectValueDocument } from '../hooks/useSubjectValueDocument';

import { useSubjectDocument } from './useSubjectDocument';

interface CompResult {
  selectedValue?: ComparableValue;
  valuationMethod: ValuationMethod.Comps;
  label: string;
}

interface OtherResults {
  selectedValue?: ValueExtended;
  valuationMethod: Exclude<ValuationMethod, ValuationMethod.Comps>;
  label: string;
}
type ReturnType = CompResult | OtherResults;
export const useReportValue = (
  reportId: ReportId,
  showRentalValue?: boolean,
) => {
  const subjectValueQuery = useSubjectValueDocument(reportId, {
    showRentalValue: !!showRentalValue,
  });
  const subjectQuery = useSubjectDocument(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectQuery,
    subjectValueQuery,
  ]);
  let result: ReturnType | undefined = undefined;
  const subjectValueDocument = subjectValueQuery.data;
  if (combinedQuery.isFetched && subjectValueDocument) {
    if (subjectValueDocument?.data.selectedValue === ValuationMethod.Comps) {
      result = {
        selectedValue: subjectValueDocument.data[ValuationMethod.Comps],
        valuationMethod: subjectValueDocument.data.selectedValue,
        label: ValuationMethodLabel[subjectValueDocument.data.selectedValue],
      };
    } else {
      const selectedValue =
        subjectValueDocument.data[subjectValueDocument.data.selectedValue];
      result = {
        selectedValue: selectedValue == null ? undefined : selectedValue,
        valuationMethod: subjectValueDocument.data.selectedValue,
        label: ValuationMethodLabel[subjectValueDocument.data.selectedValue],
      };
    }
  }
  return {
    ...combinedQuery,
    data: result,
  };
};
