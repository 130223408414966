import React from 'react';

import { PropertyTransactionHistoryTable } from '@hcs/property-state';
import {
  CompIdentifier,
  CompTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';

import { useCompTransactionHistoryDocument } from '../../hooks/useCompTransactionHistoryDocument';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  className?: string;
}
export const COMP_TRANSACTION_HISTORY_FEATURES_SALE = [
  ReportFeatures.CompsTransactionHistory,
];
export const COMP_TRANSACTION_HISTORY_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsTransactionHistory,
];
export const dataHcEventSectionCompHistoryRental =
  'Rental Comp Transaction History';
export const dataHcEventSectionCompHistorySale = 'Comp Transaction History';
export const CompTransactionHistory = ({
  reportId,
  className,
  compIdentifier,
}: Props) => {
  const { isInitialLoading, isError, data } = useCompTransactionHistoryDocument(
    reportId,
    compIdentifier,
  );
  const dataHcEventSection =
    compIdentifier.compType === CompTypes.Rental
      ? dataHcEventSectionCompHistoryRental
      : dataHcEventSectionCompHistorySale;
  return (
    <PropertyTransactionHistoryTable
      className={className}
      dataHcEventSection={dataHcEventSection}
      isLoading={isInitialLoading}
      isError={isError}
      propertyTransactionHistory={data?.data}
    />
  );
};
