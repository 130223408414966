import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { AgileProductGroupings, AppSlugs } from '@hcs/types';
import { CONTACT_SALES_URL } from '@hcs/urls';

interface RequestProductAccessPayload {
  requestedProduct: AppSlugs | AgileProductGroupings;
}
export const useRequestProductAccess = (
  navigateToSSSelectPlan?: VoidFunction,
) => {
  const { mutate: trackEngagementEvent } = useTrackEngagementEvent();
  return useMutation<void, AxiosError, RequestProductAccessPayload, void>(
    async (requestAccessPayload) => {
      trackEngagementEvent({
        event_name: 'request-product-access',
        event_specific_field: requestAccessPayload.requestedProduct,
        event_data: {
          requested_product: requestAccessPayload.requestedProduct,
        },
      });
      if (navigateToSSSelectPlan) {
        navigateToSSSelectPlan();
        return;
      }

      window.open(CONTACT_SALES_URL, '_blank');
    },
  );
};
