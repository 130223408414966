import { OrganizationIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, Roles } from '@hcs/types';

export const APP_CONFIG_ORG_ADMIN: AppConfig = {
  name: 'Organization Settings',
  applicationKey: 'org-settings',
  type: 'external-app',
  appSlug: AppSlugs.OrgAdmin,
  Icon: OrganizationIcon,
  rootPath: `/org-admin`,
  descriptionLong: 'View organization settings, usage, and billing data.',
  descriptionShort: 'View organization settings, usage, and billing data.',
  feOnlyApp: true,
  requiredRole: [Roles.OrgAdmin],
};

export enum VIEW_PATHS_ORG_ADMIN {
  ORG_USAGE = 'usage',
  LEGACY_USAGE_AND_BILLING = '/usage-and-billing',
  ORG_BILLING = 'billing',
  ORG_USERS = 'users',
  MANAGE_BILLING = 'manage-billing',
  CHANGE_PLAN = `manage-billing/change-plan`,
  LEGACY_ORG_SETTINGS = '/organization-settings',
  ORG_OM_SFTP = 'order-manager-sftp',
  ORG_PLATFORM_PREFERENCES = 'platform-preferences',
  ORG_REPORT_PREFERENCES = 'report-preferences',
  ORG_SINGLE_SIGN_ON = 'single-sign-on',
  ORG_USAGE_QUERIES = 'usage-queries',
}

export enum ORG_ADMIN_REPORT_PREFERENCES_PATHS {
  ACCESS = 'access',
  DATA = 'data',
}
