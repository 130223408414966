import {
  PropertySpatialSearchListQueryVariables,
  PropertySpatialSearchMapQueryVariables,
} from '@hcs/types';

const MAX_PRICE = 999999999;
export const makeSafePropertySpatialSearchParams = <
  T extends
    | PropertySpatialSearchMapQueryVariables
    | PropertySpatialSearchListQueryVariables
    | null,
>(
  params: T,
): T => {
  if (!params) {
    return params;
  }
  // Prices can not be over $999,999,999 or cerberus-api will error
  if (params.spatialFilters.maxListPrice != null) {
    params.spatialFilters.maxListPrice = Math.min(
      MAX_PRICE,
      params.spatialFilters.maxListPrice,
    );
  }
  if (params.spatialFilters.maxSalePrice != null) {
    params.spatialFilters.maxSalePrice = Math.min(
      MAX_PRICE,
      params.spatialFilters.maxSalePrice,
    );
  }
  if (params.spatialFilters.maxAvmPrice != null) {
    params.spatialFilters.maxAvmPrice = Math.min(
      MAX_PRICE,
      params.spatialFilters.maxAvmPrice,
    );
  }
  if (params.spatialFilters.maxAvmUpperPrice != null) {
    params.spatialFilters.maxAvmUpperPrice = Math.min(
      MAX_PRICE,
      params.spatialFilters.maxAvmUpperPrice,
    );
  }
  return params;
};
