import { RangeFilterOrNull, RangeValueOrNull } from '@hcs/types';

export const getMinFromRangeValue = (rangeValue?: RangeValueOrNull) => {
  if (rangeValue != null) {
    return rangeValue[0];
  }
  return null;
};

export const getMinFromRangeFilter = (
  rangeFilter: RangeFilterOrNull,
): number | null => {
  if (rangeFilter === null) {
    return null;
  }
  return getMinFromRangeValue(rangeFilter.rng);
};

export const getMaxFromRangeValue = (rangeValue?: RangeValueOrNull) => {
  if (rangeValue != null) {
    return rangeValue[1];
  }
  return null;
};

export const getMaxFromRangeFilter = (
  rangeFilter: RangeFilterOrNull,
): number | null => {
  if (rangeFilter === null) {
    return null;
  }
  return getMaxFromRangeValue(rangeFilter.rng);
};

export const getRangeFilter = (
  minValue: number | null,
  maxValue: number | null,
): RangeFilterOrNull => {
  if (minValue == null && maxValue == null) {
    return null;
  }
  return {
    rng: [minValue, maxValue],
  };
};
