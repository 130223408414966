import { CompTypes } from '@hcs/types';
import {
  BiasRiskDocument,
  DocumentRoles,
  ReportId,
  SchemaIds,
  ValuationMethod,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useAppraisalCompDataSourcesDocuments } from '../hooks/useAppraisalCompDataSourcesDocuments';
import { useAppraisalCompDocuments } from '../hooks/useAppraisalCompDocuments';
import { useAppraisalSubjectDocument } from '../hooks/useAppraisalSubjectDocument';
import { useAppraisalSubjectValueDocument } from '../hooks/useAppraisalSubjectValueDocument';
import { useCompDocuments } from '../hooks/useCompDocuments';
import { useSubjectDataSourcesDocument } from '../hooks/useSubjectDataSourcesDocument';
import { useSubjectDocument } from '../hooks/useSubjectDocument';
import { useSubjectValueDocument } from '../hooks/useSubjectValueDocument';
import { computeRiskSchema } from '../utils/appraisalComparison.utils';

export const useAppraisalComparisonDocument = (reportId: ReportId) => {
  const subjectQuery = useSubjectDocument(reportId);
  const subjectDataSourcesQuery = useSubjectDataSourcesDocument(reportId);
  const subjectValueQuery = useSubjectValueDocument(reportId);
  const appraisalSubjectQuery = useAppraisalSubjectDocument(reportId);
  const hcCompsQuery = useCompDocuments(reportId, CompTypes.Sold);
  const appraisalCompsQuery = useAppraisalCompDocuments(reportId);
  const appraisalCompsDataSourcesQuery =
    useAppraisalCompDataSourcesDocuments(reportId);
  const appraisalSubjectValueQuery = useAppraisalSubjectValueDocument(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectQuery,
    subjectDataSourcesQuery,
    subjectValueQuery,
    appraisalSubjectQuery,
    hcCompsQuery,
    appraisalCompsQuery,
    appraisalCompsDataSourcesQuery,
    appraisalSubjectValueQuery,
  ]);

  const data: BiasRiskDocument | undefined =
    subjectQuery.data && combinedQuery.isSuccess
      ? {
          ...subjectQuery.data,
          role: DocumentRoles.AppraisalComparison,
          schemaId: SchemaIds.BiasRisk,
          data: {
            ...computeRiskSchema({
              hcSubject: subjectDataSourcesQuery.data?.data.hc,
              hcSubjectValue: subjectValueQuery.data?.data,
              appraisalSubject: appraisalSubjectQuery.data?.data.propertyState,
              appraisalSubjectValue: appraisalSubjectValueQuery.data?.data,
              appraisalCompDocs: appraisalCompsQuery.data || [],
              appraisalCompsDataSourcesDocs:
                appraisalCompsDataSourcesQuery.data || [],
              compDocs: hcCompsQuery.data || [],
            }),
            // Comparison of appraisal value to HC values
            valueComparison: {
              [ValuationMethod.Avm]: {
                // (appraisal_subject_value.userValue.value.value - subject_value.avm.value.value) / subject_value.avm.value.value
                diffPct: 0.4232,
                // appraisal_subject_value.userValue.value.value - subject_value.avm.value.value
                diffVal: 100,
              },
              [ValuationMethod.Adjusted]: {
                // (appraisal_subject_value.userValue.value.value - subject_value.adjustedAvm.value.value) / subject_value.adjustedAvm.value.value
                diffPct: 0.4232,
                // appraisal_subject_value.userValue.value.value - subject_value.adjustedAvm.value.value
                diffVal: 100,
              },
              [ValuationMethod.Comps]: {
                // (appraisal_subject_value.userValue.value.value - subject_value.comparableValue.value.value) / subject_value.comparableValue.value.value
                diffPct: 0.4232,
                // appraisal_subject_value.userValue.value.value - subject_value.comparableValue.value.value
                diffVal: 100,
              },
            },
          },
        }
      : undefined;
  return { ...combinedQuery, data };
};
