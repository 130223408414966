import React, { ReactNode, useMemo } from 'react';

import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerTypes } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { OpenMarkerPopupComp } from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';
import { locationToGeoLocation } from '@hcs/utils';

import { useCompDocuments } from '../../hooks';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  // Render index + 1 instead of price
  indexLabels?: boolean;
  onClickMarker?: (openMarkerPopupComp: OpenMarkerPopupComp) => void;
  mapId?: string;
}

const dataHcName = 'comp-markers-selected';
export const CompMarkersSelected = ({
  reportId,
  compType,
  indexLabels,
  onClickMarker,
  mapId,
}: Props) => {
  const { data: compDocuments } = useCompDocuments(reportId, compType);
  const markers = useMemo(() => {
    const results: ReactNode[] = [];
    compDocuments?.forEach((compDocument, i) => {
      const compSchema = compDocument.data;
      const compGeoLocation = locationToGeoLocation(
        compSchema.propertyState.location,
      );
      if (compGeoLocation) {
        results.push(
          <PropertyMarker
            dataHcName={dataHcName}
            mapId={mapId}
            key={`prop-marker-${i}`}
            type={PropertyMarkerTypes.Comp}
            markerId={`marker-${compSchema.propertyState.hcAddressId || i}`}
            geoLocation={compGeoLocation}
            listingStatus={
              compType === CompTypes.Rental
                ? getPropertyStateFieldValue(
                    PropertyStateFields.currentStatusRental,
                    {
                      propertyStateType: PropertyStateType.Core,
                      propertyState: compSchema.propertyState,
                    },
                  )
                : getPropertyStateFieldValue(
                    PropertyStateFields.currentStatus,
                    {
                      propertyStateType: PropertyStateType.Core,
                      propertyState: compSchema.propertyState,
                    },
                  )
            }
            onClick={() => {
              onClickMarker?.({
                type: 'comp',
                compIdentifier: {
                  type: 'compId',
                  compType,
                  compId: compSchema.compID,
                },
              });
            }}
            label={
              indexLabels
                ? i + 1
                : formatNumberAbbrev(
                    getPropertyStateFieldValue(
                      compType === CompTypes.Rental
                        ? PropertyStateFields.currentPriceRental
                        : PropertyStateFields.currentPrice,
                      {
                        propertyStateType: PropertyStateType.Core,
                        propertyState: compSchema.propertyState,
                      },
                    ),
                  )
            }
            selected
            hoverable
          />,
        );
      }
    });
    return results;
  }, [compDocuments, compType, indexLabels, mapId, onClickMarker]);
  return markers;
};
