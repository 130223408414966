import React from 'react';
import { Bar, BarDatum, BarItemProps } from '@nivo/bar';
import { BarSvgProps } from '@nivo/bar/dist/types/types';
import { animated, useSpring } from '@react-spring/web';

import { formatNumber } from '@hcs/utils';

import styles from './AvmBreakdownAvmTypeBarChart.module.css';

const dataHcName = 'avm-type-bar-chart';
export interface AvmBreakdownAvmTypeData extends BarDatum {
  color: string;
  bar: number;
  value: number;
}

interface Props
  extends Omit<BarSvgProps<AvmBreakdownAvmTypeData>, 'width' | 'height'> {
  isPartial: boolean;
  data: AvmBreakdownAvmTypeData[];
  valueLeftAppendStr: string;
  valueRightAppendStr?: string;
}

const BAR_WIDTH = 250;
const LABEL_ABOVE_BAR_POSITION_LABEL_1 = -100;
const LABEL_ABOVE_BAR_POSITION_LABEL_2 = -76;
const START_AMOUNT = 20;
const EXTRA_HEIGHT = 45;

interface BarComponentProps extends BarItemProps<AvmBreakdownAvmTypeData> {
  valueLeftAppendStr: string;
  valueRightAppendStr?: string;
  isPartial: boolean;
}

const BarComponent = ({
  valueLeftAppendStr,
  valueRightAppendStr,
  isPartial,
  bar: { x, data, y, height },
}: BarComponentProps) => {
  const textAlign = data.data.bar === 1 ? 'start' : 'end';
  const {
    h: springHeight,
    t: text1PosSpring,
    t2: text2PosSpring,
  } = useSpring({
    from: {
      h: START_AMOUNT,
      t: LABEL_ABOVE_BAR_POSITION_LABEL_1 + (height - START_AMOUNT),
      t2: LABEL_ABOVE_BAR_POSITION_LABEL_2 + (height - START_AMOUNT),
    },
    to: {
      h: height + EXTRA_HEIGHT,
      t: LABEL_ABOVE_BAR_POSITION_LABEL_1,
      t2: LABEL_ABOVE_BAR_POSITION_LABEL_2,
    },
    config: {
      mass: 0.2,
      tension: 250,
      friction: 58,
      precision: 0.01,
      velocity: 0.027,
      clamp: true,
      delay: 700,
    },
  });

  const valueAppendStr =
    data.data.bar === 1
      ? valueLeftAppendStr
      : valueRightAppendStr
        ? valueRightAppendStr
        : valueLeftAppendStr;

  const value = isPartial ? data.data.value : formatNumber(data.data.value);

  return (
    <animated.g
      id={`bar-${data.data.bar}`}
      data-hc-name={`${dataHcName}-bar`}
      transform={`translate(${data.data.bar === 1 ? 0 : x - 25}, ${y})`}
    >
      <animated.g
        id={`a-bar-${data.data.bar}`}
        transform={`translate(0, ${height}) scale(1, -1)`}
      >
        <defs>
          <clipPath id={`round-corner-${data.data.bar}`}>
            <animated.rect
              x="0"
              y="0"
              width={BAR_WIDTH}
              height={springHeight}
              rx="25"
              ry="25"
            />
          </clipPath>
        </defs>
        <animated.rect
          width={BAR_WIDTH}
          height={springHeight}
          clipPath={`url(#round-corner-${data.data.bar})`}
          fill={data.data.color}
          strokeWidth="1"
          stroke={`${data.data[`${data.id}Color_Border`]}`}
        />
      </animated.g>

      <animated.text
        x={data.data.bar === 1 ? 0 : BAR_WIDTH}
        y={text1PosSpring}
        textAnchor={textAlign}
        dominantBaseline="central"
        style={{
          fontFamily: 'Avenir',
          fontSize: 12,
          fontWeight: 800,
          lineHeight: 16.39,
          pointerEvents: 'none',
          fill: '#4A4A4A',
          opacity: 1,
        }}
      >
        {data.data.bar === 1 ? 'Typical homes in the area' : 'This Home'}
      </animated.text>
      <animated.text
        x={data.data.bar === 1 ? 0 : BAR_WIDTH}
        y={text2PosSpring}
        textAnchor={textAlign}
        dominantBaseline="central"
        style={{
          fontFamily: 'Avenir',
          fontSize: 22,
          fontWeight: 800,
          lineHeight: 16.39,
          pointerEvents: 'none',
          fill: '#4A4A4A',
          opacity: 1,
        }}
      >
        {value} {valueAppendStr}
      </animated.text>
    </animated.g>
  );
};

const AVM_CHART_HEIGHT = 100;
const AVM_CHART_WIDTH = 587;

export const AvmBreakdownAvmTypeBarChart = ({
  isPartial,
  data,
  valueLeftAppendStr,
  valueRightAppendStr,
  ...chartProps
}: Props) => {
  return (
    <div data-hc-name={dataHcName} className={styles.BarGraph}>
      <Bar
        {...chartProps}
        width={AVM_CHART_WIDTH}
        height={AVM_CHART_HEIGHT}
        borderRadius={10}
        barComponent={(props) => (
          <BarComponent
            isPartial={isPartial}
            valueLeftAppendStr={valueLeftAppendStr}
            valueRightAppendStr={valueRightAppendStr}
            {...props}
          />
        )}
        enableGridY={false}
        enableLabel={false}
        isInteractive={false}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        data={data}
        keys={['value']}
        indexBy="bar"
      />
    </div>
  );
};
