import { useAccount } from '@hcs/authn';
import { combineUseQueryResult } from '@hcs/utils';

import { useCapabilitiesForUser } from './useCapabilitiesForUser';

export const useCapabilities = () => {
  const accountQuery = useAccount();
  const capabilitiesQuery = useCapabilitiesForUser(accountQuery.data?.user.id);
  const combinedQuery = combineUseQueryResult([
    accountQuery,
    capabilitiesQuery,
  ]);
  return {
    ...combinedQuery,
    data: capabilitiesQuery.data,
  };
};
