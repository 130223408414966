import { MutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Operation } from 'fast-json-patch';

import { ReportId } from '@hcs/types';
import { ValueExtended } from '@hcs/types';

import { ReportApi } from '../api';

interface PreviewAdjustmentParams {
  reportId: ReportId;
  operations: Operation[];
}

export const usePreviewAdjustedAvm = (
  mutationOptions?: MutationOptions<
    ValueExtended,
    AxiosError,
    PreviewAdjustmentParams,
    unknown
  >,
) => {
  return useMutation(
    async ({ reportId, operations }: PreviewAdjustmentParams) => {
      return await ReportApi.fetchPreviewAdjustedAvm({
        reportId,
        operations,
      });
    },
    mutationOptions,
  );
};
