import React from 'react';

import { TextButton } from '@hcs/design-system';
import { FlexScroll } from '@hcs/design-system';
import { TabPopover } from '@hcs/design-system';
import { SearchIcon } from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';
import { useActiveState } from '@hcs/hooks';
import { ReportSearchInputs } from '@hcs/types';

import { ReportsList } from '../ReportsList';

import {
  dataHcEventSectionReportSearch,
  ReportSearch,
  ReportSearchProps,
} from './ReportSearch';

import styles from './ReportSearch.module.css';

const dataHcName = 'report-search-popover';
export const ReportSearchTabPopover = ({
  reportSearchInputs,
  onClickFooterLink,
  ...props
}: ReportSearchProps & {
  reportSearchInputs?: ReportSearchInputs;
  onClickFooterLink: () => void;
}) => {
  const { active, setActiveState, handleClose } = useActiveState();
  const { isPhoneWidth } = useScreenSize();
  const lightTrigger = (
    <SearchIcon color={isPhoneWidth ? 'neutral-light-10' : undefined} />
  );
  const darkTrigger = <SearchIcon />;
  return (
    <TabPopover
      onChangeActiveState={setActiveState}
      active={active}
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionReportSearch}
      trigger={isPhoneWidth ? lightTrigger : darkTrigger}
      closeTrigger={darkTrigger}
      inPhoneMenu={isPhoneWidth}
      theme={{
        Content: styles.Content,
      }}
      content={
        <FlexScroll
          dataHcName={`${dataHcName}-content`}
          className={styles.FlexScroll}
          header={{
            height: 60,
            content: (
              <>
                <ReportSearch {...props} className={styles.ReportSearch} />
                <span>Reports within the last 30 days</span>
              </>
            ),
          }}
          footer={{
            height: 60,
            content: (
              <TextButton
                dataHcName={`${dataHcName}-view-all-link`}
                // onClick={navigateToReportsHistory}
                onClick={onClickFooterLink}
              >
                View All
              </TextButton>
            ),
          }}
          theme={{
            Header: styles.ReportSearchTabPopoverHeader,
            Footer: styles.ReportSearchTabPopoverFooter,
          }}
        >
          <ReportsList
            reportSearchInputs={reportSearchInputs}
            onClick={(report) => {
              handleClose();
              props.onSelectReportHit(report);
            }}
            loadingRows={5}
          />
        </FlexScroll>
      }
      closeOnClick={false}
    />
  );
};
