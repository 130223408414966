import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AddOperation, Operation, RemoveOperation } from 'fast-json-patch';

import { useToastSlice } from '@hcs/toast';
import { CompTypes, SavedCompFilters, SavedCompFilterSet } from '@hcs/types';

import { HuellApi } from '../api';

import { QUERY_KEY_SAVED_COMP_FILTER_SETS_USER } from './useSavedCompFilterSetsForUser';

export const usePatchSavedCompFilterSetsForUser = ({
  compType,
  options,
}: {
  compType: CompTypes;
  options?: UseMutationOptions<
    SavedCompFilters,
    unknown,
    (AddOperation<SavedCompFilterSet> | RemoveOperation)[],
    unknown
  >;
}) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const queryClient = useQueryClient();

  const patchSavedCompFilterSetsMutation = useMutation(
    async (operations: Operation[]) => {
      return await HuellApi.patchSavedCompFilterSetsForUser({
        compType,
        operations,
      });
    },
    {
      ...options,
      onSuccess: (
        newSavedCompFilterSets: SavedCompFilters,
        variables,
        context,
      ) => {
        queryClient.setQueryData<SavedCompFilters>(
          [QUERY_KEY_SAVED_COMP_FILTER_SETS_USER, compType],
          newSavedCompFilterSets,
        );
        options?.onSuccess?.(newSavedCompFilterSets, variables, context);
        toastOpen({
          type: 'success',
          title:
            compType === CompTypes.Rental
              ? 'Saved Rental Comp Filters'
              : 'Saved Comp Filters',
        });
      },
    },
  );
  return {
    ...patchSavedCompFilterSetsMutation,
    mutate: patchSavedCompFilterSetsMutation.mutate,
  };
};
