import { APP_NAME, ENV, IS_LOCAL, PULL_REQUEST_SUBDOMAIN } from '@hcs/webapps';

import {
  buildServiceUrl,
  buildServiceUrlEhrmMounted,
} from './utils/urls.utils';

const TLD = ENV === 'prod' ? '.com' : '.net';
const APP_SUBDOMAIN =
  ENV === 'prod' ? '' : `${IS_LOCAL ? `local.${ENV}` : ENV}.hc.`;
const PULL_REQUEST_URL_PREFIX = PULL_REQUEST_SUBDOMAIN
  ? `${PULL_REQUEST_SUBDOMAIN}.`
  : '';
// API urls
export const API_URL = buildServiceUrl('api');

export const CERBERUS_PROXY_ENABLED = !IS_LOCAL || ENV === 'prod';
export const CERBERUS_URL = CERBERUS_PROXY_ENABLED
  ? `${buildServiceUrlEhrmMounted('cerberus-api')}/graphql`
  : 'https://cerberus-api.dryrun.de.housecanary.net/graphql';
export const CERBERUS_INTERNAL_API_DOCS_URL =
  ENV === 'prod'
    ? 'https://cerberus-api.prod.de.housecanary.net/graphql'
    : 'https://cerberus-api.dryrun.de.housecanary.net/graphql';
export const EHRMANTRAUT_URL = `${API_URL}/api/v1`;
export const EHRMANTRAUT_ADMIN_INTERNAL_URL = `${buildServiceUrl(
  'api',
  true,
)}/api/v1`;
export const EHRMANTRAUT_ADMIN_URL = `${API_URL}/api/v1/admin`;
export const EHRMANTRAUT_PROXY = `${EHRMANTRAUT_URL}/proxy`;
export const BEACON_URL = buildServiceUrl('beacon');
export const LEAD_FEED_URL = `${buildServiceUrl('lead-feed')}/client-api/v1`;
export const DATA_EXPLORER_URL = buildServiceUrl('dexp-api');
export const ORDER_MANAGER_API_URL = buildServiceUrl('order-manager-api');
export const ORDER_MANAGER_CLIENT_API_URL = `${ORDER_MANAGER_API_URL}/client-api/v1`;
export const ORDER_MANAGER_REVIEW_API_URL = `${ORDER_MANAGER_API_URL}/review-api/v1`;
export const ORDER_MANAGER_API_WS_URL = `${buildServiceUrl(
  'order-manager-api',
  true,
)}/websockets/v1`;
export const PORTFOLIO_API_URL = `${API_URL}/asset-watch-api`;
export const PROPERTY_GRAPH_URL = `${buildServiceUrlEhrmMounted(
  'property-graph',
)}/graphql`;
export const REPORT_API_URL = buildServiceUrlEhrmMounted('report-api');
export const REPORT_API_ADMIN_URL = `${buildServiceUrl(
  'api',
  true,
)}/report-api`;
export const VECTILES_URL = `${EHRMANTRAUT_PROXY}/vectiles`;
export const ADDRESS_SEARCH_URL = `${EHRMANTRAUT_PROXY}/address-search`;
export const ADDRESS_SEARCH_BUILDING_URL = `${EHRMANTRAUT_PROXY}/address-search-building`;
export const KEYWORD_SEARCH_URL = `${EHRMANTRAUT_PROXY}/listing-keyword-search-api`;
export const HUELL_URL = `${EHRMANTRAUT_PROXY}/huell/api/v1`;
// DP-402 A subset of huell endpoints dealing with non-PEXP preferences and feature flags should be opened for access
// by all authenticated users, not only those with PEXP access.
export const PREFERENCES_API_URL = `${EHRMANTRAUT_PROXY}/preferences-api/api/v1`;
export const VR_SHARE_URL = `${EHRMANTRAUT_PROXY}/vrshare/api/v1`;
export const SELF_SERVICE_URL = `${EHRMANTRAUT_URL}/self-service`;
export const WINGMATE_API_URL = `${buildServiceUrl('wingmate')}/api`;

// Internal Only
export const QA_TESTRAIL_URL =
  'https://qa-dashboard.dev.infra.housecanary.net:8000/api/testrail';
export const BULK_DATA_URL = buildServiceUrl('bulk-data', true);
export const PDF_SERVICE_URL = `https://pdf-service.${ENV}.hc.housecanary.net`;

// Frontend Apps
const CANARY_COMMAND_APP_SUBDOMAIN = 'command';
export const SOLUTIONS_URL = `https://${PULL_REQUEST_URL_PREFIX}solutions.${APP_SUBDOMAIN}housecanary${TLD}${
  IS_LOCAL ? ':3001' : ''
}`;
export const CANARY_COMMAND_URL = `https://${PULL_REQUEST_URL_PREFIX}${CANARY_COMMAND_APP_SUBDOMAIN}.${APP_SUBDOMAIN}housecanary${TLD}`;
export const PARTNER_TOOLS_URL = `https://${PULL_REQUEST_URL_PREFIX}partner-tools.${APP_SUBDOMAIN}housecanary${TLD}${
  IS_LOCAL ? ':3003' : ''
}`;
export const SOLUTIONS_INTERNAL_URL =
  ENV === 'prod'
    ? 'https://solutions.prod.hc.housecanary.net'
    : `https://${PULL_REQUEST_URL_PREFIX}solutions.${APP_SUBDOMAIN}housecanary${TLD}${
        IS_LOCAL ? ':3001' : ''
      }`;
export const ADMIN_QC_URL = `https://qc.${APP_SUBDOMAIN}housecanary.net`;
export const LEGACY_PEXP_UI_URL = `https://pexp.${APP_SUBDOMAIN}housecanary${TLD}`;

export const ACCOUNT_URL_EXTERNAL = `${buildServiceUrl('account')}`;
export const ACCOUNT_URL_INTERNAL = `${buildServiceUrl('manager')}`;
export const ACCOUNT_URL =
  APP_NAME === CANARY_COMMAND_APP_SUBDOMAIN
    ? ACCOUNT_URL_INTERNAL
    : ACCOUNT_URL_EXTERNAL;

// API Documentation
export const LEGACY_ANALYTICS_API_DOCS_URL =
  'https://api-docs-legacy.housecanary.com/';
export const ANALYTICS_API_DOCS_URL = 'https://api-docs.housecanary.com/';
export const ANALYTICS_API_DOCS_POST_REQUESTS_URL = `${ANALYTICS_API_DOCS_URL}`;
export const ORDER_MANAGER_API_DOCS_URLS = `${ORDER_MANAGER_API_URL}/docs/`;

// Other URLs
export const MAPBOX_TILE_DOMAIN = 'https://api.tiles.mapbox.com';
export const SUPPORT_URL = 'https://support.housecanary.com';
export const TERMS_OF_USE_URL =
  'https://www.housecanary.com/legal/housecanary-product-terms-of-use/';
export const TERMS_OF_USE_CANARYAI_URL =
  'https://www.housecanary.com/legal/canaryai-terms-of-use';
export const PRIVACY_POLICY_URL =
  'https://www.housecanary.com/legal/privacy-policy?utm_source=platform&utm_medium=platform&utm_campaign=platform';
export const CONTACT_SALES_URL =
  'https://www.housecanary.com/contact-sales/?target=sales';
export const PRODUCT_LEARN_MORE_URL =
  'https://resources.housecanary.com/assets/hc_platform_product-sheet.pdf';
export const AGILE_LEARN_MORE_URL =
  'https://resources.housecanary.com/assets/hc_agile-suite_product-sheet.pdf';
export const EXPLORERS_PRODUCT_PAGE =
  'https://www.housecanary.com/products/explorers/';

export { convertUrlToPrPreview } from './utils/urls.utils';
