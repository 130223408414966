import mapboxgl from 'mapbox-gl';

import {
  LngLatObject,
  MapBounds,
  PropertyStateLocation,
  TileCoords,
} from '@hcs/types';
import { MAPBOX_TILE_DOMAIN } from '@hcs/urls';
import { logException } from '@hcs/utils';

import { MAPBOX_KEY } from '../constants';

/**
 * Given lat, lng coordinates, return tile coordinates for the given zoom level
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
 */
export const latLngToTileCoords = ({
  lat,
  lng,
  zoom,
}: {
  lat: number;
  lng: number;
  zoom: number;
}): Omit<TileCoords, 'zoom'> => {
  return {
    x: Math.floor(((lng + 180) / 360) * Math.pow(2, zoom)),
    y: Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180),
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom),
    ),
  };
};

/**
 * Given tile coordinates, return a lat,lng for the given zoom level
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
 */
export const tileCoordsToLatLng = ({
  x,
  y,
  zoom,
}: TileCoords): LngLatObject => {
  if (!zoom) {
    new Error(
      `Zoom value is required to be passed to tileCoordsToLatLng, you passed ${zoom}`,
    );
  }
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, zoom);
  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: (x / Math.pow(2, zoom)) * 360 - 180,
  };
};

/**
 * Calculate the array of tiles within the given set of bounds
 */
export const getTilesInBounds = (
  mapBounds: MapBounds,
  zoom: number,
): TileCoords[] => {
  if (!zoom || !Number.isInteger(zoom)) {
    logException(
      `Zoom provided to getTilesInBounds must be an integer, you passed: ${zoom}`,
    );
  }
  const { ne, sw } = mapBounds;
  const tileNE = latLngToTileCoords({ lat: ne.lat, lng: ne.lng, zoom });
  const tileSW = latLngToTileCoords({ lat: sw.lat, lng: sw.lng, zoom });
  const numRows = tileSW.y - tileNE.y + 1;
  const numCols = tileNE.x - tileSW.x + 1;
  const tiles: { x: number; y: number; zoom: number }[] = [];

  for (let x = tileSW.x; x < tileSW.x + numCols; x++) {
    for (let y = tileNE.y; y < tileNE.y + numRows; y++) {
      tiles.push({ x, y, zoom });
    }
  }
  return tiles;
};

export const getAdditionalZoom = (
  zoom: number,
  additionalTileZoom = 1,
): number => {
  return Math.floor(zoom) + additionalTileZoom;
};

/* Mapbox renders 512px tiles. We want to send tile request at one or more zoom levels higher,
 * effectively 256px tiles, in order for the API to return more granular clusters.
 * This is also more efficient, fetching property markers for a smaller area closer
 * to the actual viewport size */
export const getTilesInBoundsWithAdditionalZoom = (
  mapBounds: MapBounds,
  zoom: number,
  additionalTileZoom = 1,
): TileCoords[] => {
  const effectiveZoom = getAdditionalZoom(zoom, additionalTileZoom);
  const tilesInBounds = getTilesInBounds(mapBounds, effectiveZoom);
  return tilesInBounds;
};

/**
 * Create a string representation of a tile
 */
export const coordsToTileKey = ({ x, y, zoom }: TileCoords): string =>
  `${zoom}/${x}/${y}`;

/**
 * does the provided lngLatObject exist within the bounds of the tile
 */
export const isLatLngWithinTile = (
  lngLatObject: LngLatObject,
  { x, y, zoom }: TileCoords,
): boolean => {
  const northWestBound = tileCoordsToLatLng({ x, y, zoom });
  const southEastBound = tileCoordsToLatLng({ x: x + 1, y: y + 1, zoom });
  const lng = lngLatObject.lng;
  const lat = lngLatObject.lat;
  return (
    lat < northWestBound.lat &&
    lat > southEastBound.lat &&
    lng > northWestBound.lng &&
    lng < southEastBound.lng
  );
};

/**
 * Given a set of tile coords, return the tile coords of the tile n zoom level(s) lower
 */
export const getParentTileCoordsForCoords = (
  { x, y, zoom }: TileCoords,
  zoomLevelsOut = 1,
): TileCoords => {
  const divisor = Math.pow(2, zoomLevelsOut);
  return {
    x: Math.floor(x / divisor),
    y: Math.floor(y / divisor),
    zoom: zoom - zoomLevelsOut,
  };
};

/**
 * Given a set of lat,lng bounds, compute the center point
 */
export const getCenterPointFromBounds = ({
  ne,
  sw,
}: MapBounds): LngLatObject => {
  return new mapboxgl.LngLatBounds(
    [sw.lng, sw.lat],
    [ne.lng, ne.lat],
  ).getCenter();
};

/**
 * Given a tile coordinate, return the lat,lng at the center of the tile
 */
export const getCenterPointOfTile = ({
  x,
  y,
  zoom,
}: TileCoords): LngLatObject => {
  const sw = tileCoordsToLatLng({ x, y: y + 1, zoom });
  const ne = tileCoordsToLatLng({ x: x + 1, y, zoom });

  return getCenterPointFromBounds({ sw, ne });
};

export const getMapTileImage = (
  propertyStateLocation: PropertyStateLocation | undefined | null,
) => {
  const { latitude, longitude } = propertyStateLocation?.location || {};
  return latitude && longitude
    ? `${MAPBOX_TILE_DOMAIN}/styles/v1/mapbox/streets-v11/static/pin-m+16B7D5(${longitude},${latitude})/${longitude},${latitude},16/640x480@2x?access_token=${MAPBOX_KEY}`
    : undefined;
};
