import React, { useEffect, useRef, useState } from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions } from '@hcs/types';

import { GOOGLE_MAPS_SDK_KEY } from '../../constants';
import { useStreetView } from '../../hooks';
import { getHeading, loadMapApi } from '../../utils';

import styles from './StreetViewInteractive.module.css';

let panorama: google.maps.StreetViewPanorama;

interface Props {
  location?: PropertyStateLocation['location'] | null;
  options?: StreetViewOptions;
  dataHcName: string;
}

export const StreetViewInteractive = ({
  location,
  options,
  dataHcName,
}: Props) => {
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const streetViewQuery = useStreetView(location, options);

  const processSVData = (
    data: google.maps.StreetViewPanoramaData | null,
    status: google.maps.StreetViewStatus,
  ) => {
    if (status === 'OK' && data?.location) {
      const streetViewLocation = {
        lat: data.location.latLng?.lat(),
        lng: data.location.latLng?.lng(),
      };

      const propertyLocation = {
        lat: location?.latitude || undefined,
        lng: location?.longitude || undefined,
      };
      const heading = getHeading(streetViewLocation, propertyLocation);

      panorama.setVisible(true);

      if (panorama && data.location.pano && heading) {
        panorama.setPano(data.location.pano);
        panorama.setPov({
          heading,
          pitch: 0,
        });
        panorama.setVisible(true);
      }
    }
  };

  const initMap = (): void => {
    if (ref.current && location?.latitude && location?.longitude) {
      const sv = new google.maps.StreetViewService();
      sv.getPanorama(
        {
          location: { lat: location.latitude, lng: location.longitude },
          radius: 50,
          sources: [window.google.maps.StreetViewSource.OUTDOOR],
        },
        processSVData,
      );

      const options = {
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
        panControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
        motionTracking: false,
      };
      panorama = new google.maps.StreetViewPanorama(ref.current, options);
    }
  };

  useEffect((): void => {
    if (!window.google) {
      const googleApi = loadMapApi(GOOGLE_MAPS_SDK_KEY);
      googleApi.addEventListener('load', () => {
        setGoogleLoaded(true);
      });
    } else {
      initMap();
    }
  }, []);

  useEffect((): void => {
    if (!panorama && googleLoaded) {
      initMap();
    }
  }, [googleLoaded]);

  if (!window.google || streetViewQuery.isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  } else if (
    window.google &&
    streetViewQuery.isSuccess &&
    streetViewQuery.data
  ) {
    return (
      <div
        data-hc-name={dataHcName}
        ref={ref}
        style={{ height: '100%', width: '100%' }}
      />
    );
  } else {
    return (
      <div className={styles.NullStateWrapper}>
        <NullState
          dataHcName={`${dataHcName}-null-state`}
          title="Street View Unavailable at this Location"
          className={styles.NullState}
        />
      </div>
    );
  }
};
