import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';

import { ChipButton } from '@hcs/design-system';
import { SearchInput } from '@hcs/design-system';
import { FilterIcon } from '@hcs/design-system';
import { CloseIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { useIsOverflowed } from '@hcs/hooks';
import { CerberusStatsFields, MarketLevel } from '@hcs/types';
import {
  CerberusStatsFieldFilters,
  CerberusStatsFilterSet,
  CerberusStatsRangeFilterField,
} from '@hcs/types';
import { formatWithD3FormatStr } from '@hcs/utils';
import { getMaxFromRangeValue, getMinFromRangeValue } from '@hcs/utils';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { getCerberusStatsFieldLabelShort } from '../../utils/cerberusStatsFields.utils';
import { CerberusStatsEditFiltersDialog } from '../CerberusStatsEditFiltersDialog';

import styles from './CerberusStatsFilters.module.css';

const FilterClearIcon = ({
  parentDataHcName,
}: {
  parentDataHcName: string;
}) => {
  return <CloseIcon dataHcName={`${parentDataHcName}-clear-icon`} size="sm" />;
};

interface RangeFilterDisplayProps {
  dataHcName: string;
  filterKey: CerberusStatsRangeFilterField;
  fieldFilters: CerberusStatsFilterSet['fieldFilters'];
  onClick: VoidFunction;
  onClickIcon: VoidFunction;
}

const RangeFilterDisplay = ({
  dataHcName,
  filterKey,
  fieldFilters,
  onClick,
  onClickIcon,
}: RangeFilterDisplayProps) => {
  const filterValue = fieldFilters?.[filterKey];
  if (filterValue == null) {
    return null;
  }
  const config = CERBERUS_STATS_FIELD_CONFIGS[filterKey];
  const filterLabel = config.labelShort;
  if (!filterLabel) {
    return null;
  }

  const d3FormatStr = config?.d3FormatStr;
  const minValue = getMinFromRangeValue(filterValue);
  const maxValue = getMaxFromRangeValue(filterValue);
  const min =
    minValue === null
      ? 'No Min'
      : d3FormatStr
        ? formatWithD3FormatStr(minValue, d3FormatStr)
        : minValue;
  const max =
    maxValue === null
      ? 'No Max'
      : d3FormatStr
        ? formatWithD3FormatStr(maxValue, d3FormatStr)
        : maxValue;

  return (
    <ChipButton
      dataHcName={`${dataHcName}-chipbutton`}
      icon={<FilterClearIcon parentDataHcName={dataHcName} />}
      onClick={onClick}
      onClickIcon={onClickIcon}
    >
      <span data-hc-name={`${dataHcName}-label`}>
        {filterLabel}
        {' ('}
        {min} to {max}
        {')'}
      </span>
    </ChipButton>
  );
};

interface MarketGradeFilterDisplayProps {
  dataHcName: string;
  filterKey: CerberusStatsFields.marketGrade;
  fieldFilters: CerberusStatsFilterSet['fieldFilters'];
  onClick: VoidFunction;
  onClickIcon: VoidFunction;
}

const MarketGradeFilterDisplay = ({
  dataHcName,
  filterKey,
  fieldFilters,
  onClick,
  onClickIcon,
}: MarketGradeFilterDisplayProps) => {
  const filterValue = fieldFilters?.[filterKey];
  if (filterValue == null) {
    return null;
  }
  const filterLabel = getCerberusStatsFieldLabelShort(filterKey);
  if (!filterLabel) {
    return null;
  }

  return (
    <ChipButton
      dataHcName={`${dataHcName}-chipbutton`}
      icon={<FilterClearIcon parentDataHcName={dataHcName} />}
      onClick={onClick}
      onClickIcon={onClickIcon}
    >
      <span
        data-hc-name={`${dataHcName}-label`}
      >{`${filterLabel} (${filterValue.join(', ')})`}</span>
    </ChipButton>
  );
};

interface CerberusStatsFiltersProps {
  className?: string;
  dataHcName: string;
  searchPlaceholder: string;
  search: string | null;
  fieldFilters: CerberusStatsFilterSet['fieldFilters'];
  filterSetQueryIsLoading: boolean;
  saveIsLoading: boolean;
  marketLevel: MarketLevel;
  onSearch: (search: string | null) => void;
  onUpdateFieldFilters: (fieldFilters: CerberusStatsFieldFilters) => void;
  onClearFieldFilter: (fieldFilterKey: keyof CerberusStatsFieldFilters) => void;
}

export const CerberusStatsFilters = ({
  className,
  dataHcName,
  searchPlaceholder,
  search,
  fieldFilters,
  filterSetQueryIsLoading,
  saveIsLoading,
  marketLevel,
  onSearch,
  onUpdateFieldFilters,
  onClearFieldFilter,
}: CerberusStatsFiltersProps) => {
  const {
    active: filtersDrawerActive,
    handleOpen: handleFilterDrawerOpen,
    handleClose: handleFilterDrawerClose,
  } = useActiveState();
  const activeFilterKeys = useMemo(() => {
    if (!fieldFilters) {
      return [];
    }
    // have to cast type since Object.keys will return string[]
    const savedFilterKeys = fieldFilters
      ? (Object.keys(fieldFilters) as Array<keyof CerberusStatsFieldFilters>)
      : [];
    return savedFilterKeys.filter(
      (filterKey) => fieldFilters?.[filterKey] != null,
    );
  }, [fieldFilters]);
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflowed = useIsOverflowed(ref);

  return (
    <div data-hc-name={dataHcName} className={className}>
      <div
        className={classNames(styles.Container, {
          [styles['is-overflowed']]: isOverflowed,
        })}
      >
        <div className={styles.FiltersRow} ref={ref}>
          <SearchInput
            className={styles.SearchInput}
            value={search || ''}
            onChange={onSearch}
            dataHcName={`${dataHcName}-search-input`}
            placeholder={searchPlaceholder}
          />
          <ChipButton
            dataHcName={`${dataHcName}-open-button`}
            icon={
              <FilterIcon
                dataHcName={`${dataHcName}-open-button-icon`}
                size="sm"
              />
            }
            onClick={handleFilterDrawerOpen}
          >
            Filter to Refine{' '}
            {activeFilterKeys.length > 0
              ? `(${activeFilterKeys.length})`
              : null}
          </ChipButton>
          {fieldFilters &&
            activeFilterKeys.map((filterKey) => {
              const handleOnClick = handleFilterDrawerOpen;
              const handleClickIcon = () => onClearFieldFilter(filterKey);
              const filterDataHcName = `${dataHcName}-${filterKey}`;
              if (filterKey === CerberusStatsFields.marketGrade) {
                return (
                  <MarketGradeFilterDisplay
                    key={filterKey}
                    dataHcName={filterDataHcName}
                    filterKey={filterKey}
                    fieldFilters={fieldFilters}
                    onClick={handleOnClick}
                    onClickIcon={handleClickIcon}
                  />
                );
              } else {
                return (
                  <RangeFilterDisplay
                    key={filterKey}
                    dataHcName={filterDataHcName}
                    filterKey={filterKey}
                    fieldFilters={fieldFilters}
                    onClick={handleOnClick}
                    onClickIcon={handleClickIcon}
                  />
                );
              }
            })}
          <CerberusStatsEditFiltersDialog
            marketLevel={marketLevel}
            isActive={filtersDrawerActive}
            fieldFilters={fieldFilters}
            filterSetQueryIsLoading={filterSetQueryIsLoading}
            saveIsLoading={saveIsLoading}
            onUpdateFieldFilters={onUpdateFieldFilters}
            onClose={handleFilterDrawerClose}
          />
        </div>
      </div>
    </div>
  );
};
