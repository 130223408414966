import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { AxiosAccessTokenClientEhrm, AxiosClient } from '@hcs/http-clients';
import {
  AppSlugs,
  Package,
  PaymentIntent,
  PaymentIntentError,
  PaymentIntentWebhookResponse,
  PaymentMethod,
  SelfServiceAccess,
  SetupIntentKeys,
  SetupIntentWebhookResponse,
} from '@hcs/types';
import { EHRMANTRAUT_URL, SELF_SERVICE_URL } from '@hcs/urls';

export class PaymentNeedsAuthorizationError extends Error {
  // base constructor only accepts string message as an argument
  // we extend it here to accept an object, allowing us to pass other data
  constructor(
    public paymentIntentError: PaymentIntentError,
    message: string,
  ) {
    super(message);
    this.name = 'PaymentNeedsAuthorizationError';
    this.paymentIntentError = paymentIntentError; // this property is defined in parent
  }
}

export const LegacySelfServiceApi = {
  fetchPackages: async () => {
    const response = await AxiosClient.get<Package[]>(
      `${SELF_SERVICE_URL}/packages`,
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchPaymentMethods: async () => {
    const response = await AxiosAccessTokenClientEhrm.get<PaymentMethod[]>(
      `${SELF_SERVICE_URL}/payment-method`,
    );
    return camelcaseKeys(response.data);
  },
  setupIntent: async (packageId: Package['id']) => {
    const response = await AxiosAccessTokenClientEhrm.post<SetupIntentKeys>(
      `${SELF_SERVICE_URL}/setup-intent`,
      { packageId },
    );
    return camelcaseKeys(response.data);
  },
  setupIntentCheckWebhook: async (
    clientSecret: SetupIntentKeys['clientSecret'],
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.get<SetupIntentWebhookResponse>(
        `${SELF_SERVICE_URL}/setup-intent/${clientSecret}`,
      );
    return camelcaseKeys(response.data);
  },
  paymentIntent: async (packageId: Package['id']) => {
    try {
      const response = await AxiosAccessTokenClientEhrm.post<PaymentIntent>(
        `${SELF_SERVICE_URL}/payment-intent`,
        { package_id: packageId },
      );
      return camelcaseKeys(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          const responseData: PaymentIntentError = camelcaseKeys(
            e.response.data,
          );
          if (responseData.declineCode === 'authentication_required') {
            throw new PaymentNeedsAuthorizationError(responseData, e.message);
          }
        }
      }
      throw e;
    }
  },
  paymentIntentCheckWebhook: async (
    clientSecret: PaymentIntent['clientSecret'],
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.get<PaymentIntentWebhookResponse>(
        `${SELF_SERVICE_URL}/payment-intent/${clientSecret}`,
      );
    return camelcaseKeys(response.data);
  },
  fetchSelfServiceAccess: async (appSlug: AppSlugs) => {
    const response = await AxiosAccessTokenClientEhrm.get<SelfServiceAccess>(
      `${EHRMANTRAUT_URL}/component/check-my-access?component_name=${appSlug}`,
    );
    return camelcaseKeys(response.data);
  },
};
