import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { useParentDimensions } from '@hcs/hooks';
import { DataPriority } from '@hcs/types';

import { SearchIcon } from '../../../../svgs/icons/generics';
import { ActionButtons } from '../../../display/action-buttons/ActionButtons';
import { FlexScroll } from '../../../display/layout/FlexScroll';
import { NoContent } from '../../../global/loading-errors-null/NoContent';
import { TextButton } from '../../buttons/TextButton';
import { SearchInput } from '../../inputs/SearchInput';

import { DataPriorityControlOption } from './DataPriorityControlOption';

import styles from './DataPriorityControl.module.css';

export const COL_WIDTH = 350;

interface ActiveLimit {
  limit: number;
  limitWarning: string;
}
export type DataPriorityControlLabelsType<T extends string> = Partial<
  Record<
    T,
    {
      label: string;
      description?: string;
    }
  >
>;

export interface DataPriorityControlProps<T extends string> {
  dataHcName: string;
  dataHcEventSection?: string;
  saveEngagement?: {
    dataHcEventName?: string;
    dataHcEventType?: string;
  };
  defaultOrder: T[];
  onSubmit: (updated: DataPriority<T>) => void;
  title?: string;
  description?: string;
  labels: DataPriorityControlLabelsType<T>;
  dataPreferences?: DataPriority<T>;
  numCols?: number;
  submitDisabled?: boolean;
  submitText?: string;
  actionsPortalIdRender?: string;
  onCancel?: VoidFunction;
  onChangeNumColumns?: (numCols: number) => void;
  hasReset?: boolean;
  hasSearch?: boolean;
  resetTableOptionsPrefs?: VoidFunction;
  activeLimit?: ActiveLimit;
}
export const DataPriorityControl = <T extends string>({
  dataHcName,
  dataHcEventSection,
  saveEngagement,
  title,
  description,
  labels,
  onSubmit,
  defaultOrder,
  dataPreferences,
  numCols,
  submitDisabled,
  submitText = 'Save & Apply',
  actionsPortalIdRender,
  onCancel,
  onChangeNumColumns,
  hasReset,
  hasSearch,
  resetTableOptionsPrefs,
  activeLimit,
}: DataPriorityControlProps<T>) => {
  const [active, setActive] = useState<T[]>(defaultOrder);
  const [inactive, setInactive] = useState<T[]>([]);
  const [search, setSearch] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const parentDimensions = useParentDimensions({ ref });
  const resetColumnOptions = () => {
    if (dataPreferences) {
      // Keep track of the options added from 'order' so we can add newly created filters
      const added: { [key in T]?: boolean } = {};
      const newActive: T[] = [];
      const newInactive: T[] = [];
      dataPreferences.order.forEach((field) => {
        if (dataPreferences.inactive[field]) {
          newInactive.push(field);
        } else {
          newActive.push(field);
        }
        added[field] = true;
      });
      if (defaultOrder.length > newActive.length + newInactive.length) {
        defaultOrder.forEach((field) => {
          if (!added[field]) {
            if (dataPreferences.inactive[field]) {
              newInactive.push(field);
            } else {
              newActive.push(field);
            }
            added[field] = true;
          }
        });
      }
      setActive(newActive);
      setInactive(newInactive);
    } else {
      setActive(defaultOrder);
      setInactive([]);
    }
  };
  useEffect(resetColumnOptions, [dataPreferences]);

  const filterActive = useMemo(() => {
    return active.filter((field) =>
      labels[field]?.label
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase()),
    );
  }, [search, active]);

  const filterInactive = useMemo(() => {
    return inactive.filter((field) =>
      labels[field]?.label
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase()),
    );
  }, [search, inactive]);

  const handleCancel = () => {
    resetColumnOptions();
    onCancel?.();
  };

  const handleDragEnd = ({ source, destination }: DropResult) => {
    // destination will be null if dropped outside of the container
    if (source && destination) {
      const column = filterActive[source.index];
      if (column) {
        // Remove dragged column from the list
        const newOrder = filterActive
          .slice(0, source.index)
          .concat(filterActive.slice(source.index + 1, filterActive.length));
        // Add the dragged column in the dropped position
        newOrder.splice(destination.index, 0, column);
        setActive(newOrder);
      }
    }
  };

  const activeLimitReached = activeLimit && active.length >= activeLimit.limit;
  const parentHeight = parentDimensions?.height
    ? parentDimensions.height - 80
    : undefined;
  const parentWidth = window.innerWidth * 0.9;
  const cols: T[][] = [];
  let numFieldsPerCol = 1;
  if (parentHeight && parentWidth) {
    const numColsToMake =
      numCols ||
      Math.min(
        Math.ceil((defaultOrder.length * 45) / parentHeight),
        Math.max(Math.floor(parentWidth / COL_WIDTH), 1),
      );
    numFieldsPerCol = Math.ceil(defaultOrder.length / numColsToMake);
    for (let i = 0; i < numColsToMake; i++) {
      const colsRendered = i * numFieldsPerCol;
      const colsRenderedAfter = (i + 1) * numFieldsPerCol;
      // If column is all filterActive
      if (filterActive.length >= colsRenderedAfter) {
        cols.push(filterActive.slice(colsRendered, colsRenderedAfter));
      }
      // If this column starts w/ filterActive and ends with filterInactive
      else if (filterActive.length >= colsRendered) {
        cols.push([
          ...filterActive.slice(colsRendered),
          ...filterInactive.slice(0, colsRenderedAfter - filterActive.length),
        ]);
      }
      // Column is entirely filterInactive fields
      else {
        cols.push(
          filterInactive.slice(
            colsRendered - filterActive.length,
            colsRenderedAfter - filterActive.length,
          ),
        );
      }
    }
  }

  useEffect(() => {
    onChangeNumColumns?.(cols.length);
  }, [cols.length]);

  const handleToggleColumn = (field: T, isActive: boolean) => {
    const nextActive = [...active].filter((c) => c !== field);
    const nextInactive = [...inactive].filter((c) => c !== field);
    if (isActive) {
      nextActive.push(field);
    } else {
      nextInactive.unshift(field);
    }
    setActive(nextActive);
    setInactive(nextInactive);
  };

  const handleSave = () => {
    const updated: DataPriority<T> = {
      order: active,
      inactive: {},
    };
    inactive.forEach((field) => {
      updated.inactive[field] = true;
    });
    onSubmit(updated);
  };

  const handleResetTableOptions = () => {
    resetTableOptionsPrefs?.();
    onCancel?.();
  };

  return (
    <FlexScroll
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      noPadding
      header={{
        // this really ends up being min-height
        height: 0,
        content: (
          <div className={styles.Header}>
            {title && (
              <div
                className={styles.Title}
                data-hc-name={`${dataHcName}-title`}
              >
                {title}
              </div>
            )}
            {description && (
              <div
                className={styles.Description}
                data-hc-name={`${dataHcName}-description`}
              >
                {description}
              </div>
            )}
            {hasSearch || hasReset ? (
              <div className={styles.SearchResetContainer}>
                {hasSearch && (
                  <SearchInput
                    withClear
                    dataHcName={`${dataHcName}-search-input`}
                    className={styles.SearchInput}
                    value={search}
                    onChange={setSearch}
                  />
                )}
                {hasReset && (
                  <TextButton
                    dataHcName={`${dataHcName}-defaults-button`}
                    onClick={handleResetTableOptions}
                    className={styles.ResetButton}
                  >
                    Reset to Defaults
                  </TextButton>
                )}
              </div>
            ) : null}
            {activeLimitReached && (
              <div
                className={styles.ActiveLimit}
                data-hc-name={`${dataHcName}-max-limit`}
              >
                {activeLimit.limitWarning}
              </div>
            )}
            {!active.length && (
              <div
                className={styles.ActiveLimit}
                data-hc-name={`${dataHcName}-minimum`}
              >
                You must make at least one selection.
              </div>
            )}
            {search && !filterActive.length && !filterInactive.length ? (
              <NoContent
                dataHcName={`${dataHcName}-no-content`}
                className={styles.NoContent}
                Icon={() => <SearchIcon size="lg" />}
              >
                No column label results found
              </NoContent>
            ) : null}
          </div>
        ),
      }}
      footer={{
        height: actionsPortalIdRender ? 0 : 60,
        content: (
          <ActionButtons
            dataHcName={`${dataHcName}-actions`}
            portalIdRender={actionsPortalIdRender}
            actions={[
              {
                dataHcName: `${dataHcName}-cancel-button`,
                dataHcEventSection,
                label: 'Cancel',
                onClick: handleCancel,
                secondary: true,
              },
              {
                ...saveEngagement,
                dataHcName: `${dataHcName}-submit-button`,
                dataHcEventSection,
                label: submitText,
                disabled: submitDisabled || !active.length,
                onClick: handleSave,
              },
            ]}
          />
        ),
      }}
      theme={{
        Scroll: styles.Scroll,
      }}
    >
      <div
        data-hc-name={`${dataHcName}-drag-drop-container`}
        ref={ref}
        className={styles.DragDrop}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          {cols.map((col, i) => {
            return (
              <div
                data-hc-name={`${dataHcName}-col-${i}`}
                key={`col-${i}`}
                className={styles.DroppableColWrapper}
              >
                <Droppable droppableId={`table-col-${i}`} type="COLUMN">
                  {(provided) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {col.map((field, j) => {
                          const activeOption = !inactive.includes(field);
                          return (
                            <DataPriorityControlOption
                              key={`option-${field}`}
                              dataHcName={`${dataHcName}-option-${field}`}
                              label={labels[field]?.label}
                              description={labels[field]?.description}
                              field={field}
                              index={i * numFieldsPerCol + j}
                              onToggle={() =>
                                handleToggleColumn(field, !activeOption)
                              }
                              disabled={!activeOption && activeLimitReached}
                              active={activeOption}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </FlexScroll>
  );
};
