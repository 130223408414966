import {
  fetchEventSource,
  FetchEventSourceInit,
} from '@microsoft/fetch-event-source';
import camelcaseKeys from 'camelcase-keys';
import { Operation } from 'fast-json-patch';
import FileDownload from 'js-file-download';

import {
  AxiosAccessTokenClientEhrm,
  getHcAuthHeader,
  getHcAuthPrincipals,
} from '@hcs/http-clients';
import {
  EditableLinkCreateResponse,
  EditableLinkPayload,
  Personalization,
} from '@hcs/types';
import { PdfServicePayload } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import {
  CompDocument,
  CompsFarmDocument,
  DeleteDocumentArg,
  DocumentRoles,
  GetTagsArg,
  MutateTagsArg,
  PatchDocumentArg,
  PublicLinkCreateResponse,
  RentalCompDocument,
  RentalCompsFarmDocument,
  Report,
  ReportApiDocument,
  ReportApiDocumentQueryParams,
  ReportConfigApi,
  ReportConfigSlugs,
  ReportCreateInputs,
  ReportId,
  ReportSearchInputs,
  ReportTypes,
  ReportWithData,
  SelectCompArg,
} from '@hcs/types';
import {
  CompFarmUserPropertyAdjustmentsApi,
  UserPropertyAdjustments,
} from '@hcs/types';
import { ReportApiReports } from '@hcs/types';
import {
  ReportTypeSupportRequest,
  ReportTypeSupportResponse,
} from '@hcs/types';
import { ValueExtended } from '@hcs/types';
import { REPORT_API_URL } from '@hcs/urls';
import { makePaginatedResponseData } from '@hcs/utils';
import { isNumericStr } from '@hcs/utils';
import { PULL_REQUEST_ID } from '@hcs/webapps';

import { transformCompsUserPropertyAdjustments } from '../utils';
import {
  checkIsPublicLink,
  prepReportCreateInputsForApi,
  prepReportSearchInputsForApi,
} from '../utils/report.utils';
import { prepListingIdentifierForApi } from '../utils/reportApiInputs';

const DEFAULT_REPORTS_PER_PAGE = 20;

// getHeaders is used to connect to local report-api
const getHeaders = () => {
  if (import.meta.env.NX_LOCAL_REPORT_API) {
    return { headers: getHcAuthPrincipals() };
  }
  return {};
};

// getReportApiUrl used for public link support
const getReportApiUrl = (reportId: ReportId | undefined) => {
  // 'https://api.dev.hc.housecanary.net/report-api/reports/15868/documents/?role=subject_photos'
  // 'https://api.dev.hc.housecanary.net/report-api-public/reports/external/l7d1l0zkd0K5a?output=pdf'
  return reportId && checkIsPublicLink(reportId)
    ? `${REPORT_API_URL}-public`
    : REPORT_API_URL;
};

// getHttpClient used for public link support
const getHttpClient = () => {
  return AxiosAccessTokenClientEhrm;
};

export const createReportApiEventSource = ({
  reportId,
  config,
}: {
  reportId: ReportId;
  config: FetchEventSourceInit;
}) => {
  const isPublicLink = reportId && checkIsPublicLink(reportId);
  const stream = isPublicLink
    ? `publicLinks.${reportId}`
    : `reports.${reportId || 'all'}`;
  const baseHeaders = {
    'Content-Type': 'application/json',
  };
  const authHeaders = isPublicLink ? undefined : getHcAuthHeader();
  return fetchEventSource(
    `${getReportApiUrl(reportId)}/events?stream=${stream}`,
    {
      headers: {
        ...baseHeaders,
        ...authHeaders,
      },
      ...config,
    },
  );
};

export const ReportApi = {
  fetchReport: async (reportId: ReportId) => {
    const response = await getHttpClient().get<Report>(
      `${getReportApiUrl(reportId)}/reports/${reportId}`,
      getHeaders(),
    );
    return response.data;
  },

  fetchDocumentRole: async <DocType extends ReportApiDocument>(
    reportId: ReportId,
    documentRole: DocumentRoles,
    params?: Omit<ReportApiDocumentQueryParams, 'role'>,
  ): Promise<DocType[]> => {
    const response = await getHttpClient().get<DocType[]>(
      `${getReportApiUrl(reportId)}/reports/${reportId}/documents/`,
      {
        ...getHeaders(),
        params: {
          ...params,
          role: documentRole,
        },
      },
    );
    return response.data;
  },

  fetchDocument: async <DocType extends ReportApiDocument>(
    reportId: ReportId,
    documentId: string,
  ) => {
    const response = await getHttpClient().get<DocType>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/${documentId}`,
      getHeaders(),
    );
    return response.data;
  },

  fetchReportCobranding: async (reportId: ReportId) => {
    const response = await getHttpClient().get<Personalization>(
      `${getReportApiUrl(reportId)}/reports/${reportId}/cobranding`,
      getHeaders(),
    );
    return camelcaseKeys(response.data, { deep: true });
  },

  fetchReportTypeSupport: async ({ requests }: ReportTypeSupportRequest) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<ReportTypeSupportResponse>(
        `${REPORT_API_URL}/reporttypes/`,
        {
          ...getHeaders(),
          requests,
        },
      );
    return response.data;
  },

  createPublicLink: async (reportId: ReportId) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<PublicLinkCreateResponse>(
        `${REPORT_API_URL}/reports/${reportId}/actions/public_link`,
        {
          ...getHeaders(),
        },
      );
    return response.data;
  },

  createEditableLink: async (
    reportId: ReportId,
    payload: EditableLinkPayload,
  ) => {
    const response =
      await AxiosAccessTokenClientEhrm.post<EditableLinkCreateResponse>(
        `${REPORT_API_URL}/reports/${reportId}/actions/editable_link`,
        payload,
        {
          ...getHeaders(),
        },
      );
    return response.data;
  },

  addTags: async ({
    reportId,
    documentId,
    tags,
  }: MutateTagsArg): Promise<string[]> => {
    const response = await AxiosAccessTokenClientEhrm.post<string[]>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/${documentId}/tags/add`,
      tags,
      getHeaders(),
    );
    return response.data;
  },

  getTags: async ({ reportId, documentId }: GetTagsArg): Promise<string[]> => {
    const response = await getHttpClient().get<string[]>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/${documentId}/tags`,
      getHeaders(),
    );
    return response.data;
  },

  deleteTags: async ({
    reportId,
    documentId,
    tags,
  }: MutateTagsArg): Promise<string[] | null> => {
    const response = await AxiosAccessTokenClientEhrm.post<string[]>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/${documentId}/tags/delete`,
      tags,
      getHeaders(),
    );
    return response.data;
  },

  patchDocument: async ({
    reportId,
    document,
    operations,
    clientPatchId,
  }: PatchDocumentArg & { clientPatchId?: string }): Promise<
    ReportApiDocument[] | null
  > => {
    const response = await AxiosAccessTokenClientEhrm.patch<
      ReportApiDocument[] | null
    >(
      `${getReportApiUrl(reportId)}/reports/${reportId}/documents/${
        document.documentId
      }?async=true`,
      { operations, clientPatchId },
      getHeaders(),
    );
    return response.data;
  },

  deleteDocument: async ({ reportId, documentId }: DeleteDocumentArg) => {
    const response = await AxiosAccessTokenClientEhrm.delete<void>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/${documentId}?async=true`,
      getHeaders(),
    );
    return response.data;
  },

  selectComps: async (
    selectCompArg: SelectCompArg & { reportId: ReportId },
  ) => {
    const { reportId } = selectCompArg;
    const response =
      selectCompArg.type === 'hcAddressId'
        ? await AxiosAccessTokenClientEhrm.post<CompDocument[]>(
            `${getReportApiUrl(
              reportId,
            )}/reports/${reportId}/actions/comps?async=true`,
            selectCompArg.hcAddressIds,
            getHeaders(),
          )
        : await AxiosAccessTokenClientEhrm.post<CompDocument[]>(
            `${getReportApiUrl(
              reportId,
            )}/reports/${reportId}/actions/comps_by_mls_id?async=true`,
            selectCompArg.listingIdentifiers.map(prepListingIdentifierForApi),
            getHeaders(),
          );
    return response.data;
  },

  selectRentalComps: async (
    selectCompArg: SelectCompArg & { reportId: ReportId },
  ) => {
    const { reportId } = selectCompArg;
    const response =
      selectCompArg.type === 'hcAddressId'
        ? await AxiosAccessTokenClientEhrm.post<RentalCompDocument[]>(
            `${getReportApiUrl(
              reportId,
            )}/reports/${reportId}/actions/rental_comps?async=true`,
            selectCompArg.hcAddressIds,
            getHeaders(),
          )
        : await AxiosAccessTokenClientEhrm.post<RentalCompDocument[]>(
            `${getReportApiUrl(
              reportId,
            )}/reports/${reportId}/actions/rental_comps_by_mls_id?async=true`,
            selectCompArg.listingIdentifiers.map(prepListingIdentifierForApi),
            getHeaders(),
          );
    return response.data;
  },

  refreshReportData: async (reportId: ReportId) => {
    const response = await AxiosAccessTokenClientEhrm.post<void>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/actions/refresh?async=true`,
      undefined,
      getHeaders(),
    );
    return response.data;
  },

  addToCompsFarm: async (
    selectCompArg: SelectCompArg & { reportId: ReportId; compType: CompTypes },
  ) => {
    const response = await AxiosAccessTokenClientEhrm.post<
      (CompsFarmDocument | RentalCompsFarmDocument)[] | null
    >(
      `${getReportApiUrl(selectCompArg.reportId)}/reports/${
        selectCompArg.reportId
      }/actions/${
        selectCompArg.compType === CompTypes.Rental
          ? 'rental_comps_farm'
          : 'comps_farm'
      }?async=true`,
      selectCompArg.type === 'hcAddressId'
        ? selectCompArg.hcAddressIds.map((hcAddressId) => ({ hcAddressId }))
        : selectCompArg.listingIdentifiers.map(prepListingIdentifierForApi),
      getHeaders(),
    );
    return response.data;
  },

  fetchReportConfigs: async () => {
    const response = await AxiosAccessTokenClientEhrm.get<ReportConfigApi[]>(
      `${REPORT_API_URL}/configs`,
      {
        ...getHeaders(),
      },
    );
    return response.data;
  },

  fetchReportConfig: async (reportConfigSlug: ReportConfigSlugs) => {
    const response = await AxiosAccessTokenClientEhrm.get<ReportConfigApi>(
      `${REPORT_API_URL}/configs/${reportConfigSlug}`,
      {
        ...getHeaders(),
      },
    );
    return response.data;
  },

  fetchReports: async (reportSearchInputs: ReportSearchInputs) => {
    const { page = 1, pageSize = DEFAULT_REPORTS_PER_PAGE } =
      reportSearchInputs || {};
    const response = await AxiosAccessTokenClientEhrm.get<Report[]>(
      `${REPORT_API_URL}/reports`,
      {
        ...getHeaders(),
        params: prepReportSearchInputsForApi(reportSearchInputs),
      },
    );
    const paginatedResponse = makePaginatedResponseData(response, {
      page,
      pageSize,
    });
    return paginatedResponse;
  },

  createReport: async (reportCreateInputs: ReportCreateInputs) => {
    const response = await AxiosAccessTokenClientEhrm.post<ReportWithData>(
      `${REPORT_API_URL}/reports`,
      prepReportCreateInputsForApi(reportCreateInputs),
      getHeaders(),
    );
    return response.data;
  },

  downloadReportPdf: async ({
    reportId,
    filename,
  }: {
    reportId: ReportId;
    filename: string;
  }) => {
    const response = await getHttpClient().get<ArrayBuffer>(
      `${getReportApiUrl(reportId)}/reports/${reportId}?output=pdf${
        isNumericStr(PULL_REQUEST_ID) ? `&pullRequest=${PULL_REQUEST_ID}` : ''
      }`,
      { responseType: 'arraybuffer', ...getHeaders() },
    );
    FileDownload(
      new window.Blob([response.data], { type: 'application/pdf' }),
      filename,
    );
    return response.data;
  },

  fetchReportPdfPayload: async ({ reportId }: { reportId: ReportId }) => {
    const response = await getHttpClient().get<
      PdfServicePayload<ReportApiReports>
    >(
      `${getReportApiUrl(reportId)}/reports/${reportId}?output=pdf-payload`,
      getHeaders(),
    );
    return response.data;
  },

  downloadReportExcel: async ({
    reportId,
    filename,
  }: {
    reportId: ReportId;
    filename: string;
  }) => {
    const response = await getHttpClient().get<ArrayBuffer>(
      `${getReportApiUrl(reportId)}/reports/${reportId}?output=excel`,
      { ...getHeaders(), responseType: 'arraybuffer' },
    );
    FileDownload(
      new window.Blob([response.data], { type: 'application/xlsx' }),
      filename,
    );
    return response.data;
  },

  fetchReportPdfPayloadLegacy: async ({ reportId }: { reportId: string }) => {
    if (reportId.length === 32) {
      const response = await AxiosAccessTokenClientEhrm.get<
        PdfServicePayload<ReportApiReports>
      >(
        `${getReportApiUrl(
          reportId,
        )}/reports/external/${reportId}?output=pdf-payload`,
      );
      return response.data;
    } else {
      const response = await getHttpClient().get<
        PdfServicePayload<ReportApiReports>
      >(
        `${getReportApiUrl(
          reportId,
        )}/reports/external/${reportId}?output=pdf-payload`,
        getHeaders(),
      );
      return response.data;
    }
  },

  downloadReportPdfLegacy: async ({
    reportId,
    filename,
  }: {
    reportId: ReportId;
    filename: string;
  }) => {
    const response = await getHttpClient().get<ArrayBuffer>(
      `${REPORT_API_URL}/reports/external/${reportId}?output=pdf`,
      { responseType: 'arraybuffer', ...getHeaders() },
    );
    FileDownload(
      new window.Blob([response.data], { type: 'application/pdf' }),
      filename,
    );
    return response.data;
  },

  downloadReportPdfPayload: async ({
    reportId,
    filename,
  }: {
    reportId: ReportId;
    filename: string;
  }) => {
    const response = await getHttpClient().get<
      Record<string | number, unknown>
    >(
      `${getReportApiUrl(reportId)}/reports/${reportId}?output=pdf-payload`,
      getHeaders(),
    );
    FileDownload(JSON.stringify(response.data), filename);
    return response.data;
  },

  fetchUserPropertyAdjustments: async (
    reportId: ReportId,
    params: {
      role: Exclude<
        DocumentRoles,
        DocumentRoles.CompsFarm | DocumentRoles.RentalCompsFarm
      >;
    },
  ) => {
    const response = await getHttpClient().get<UserPropertyAdjustments[]>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/user-property-adjustments`,
      {
        ...getHeaders(),
        params,
      },
    );
    return response.data;
  },
  fetchUserPropertyAdjustmentsFarm: async (
    reportId: ReportId,
    documentRole: DocumentRoles.CompsFarm | DocumentRoles.RentalCompsFarm,
  ) => {
    const response = await getHttpClient().get<
      CompFarmUserPropertyAdjustmentsApi[]
    >(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/documents/user-property-adjustments`,
      {
        ...getHeaders(),
        params: { role: documentRole },
      },
    );
    // We never want to deal with the format returned from the api
    // Instead we transform the adjustments to be a mapping of
    // CompId: UserPropertyAdjustments, similar to comp and subject adjustments
    const farmDocumentAdjustments = response.data?.[0];
    if (farmDocumentAdjustments) {
      return transformCompsUserPropertyAdjustments(farmDocumentAdjustments);
    }
    return null;
  },

  fetchPreviewAdjustedAvm: async ({
    reportId,
    operations,
  }: {
    reportId: ReportId;
    operations: Operation[];
  }) => {
    const response = await AxiosAccessTokenClientEhrm.post<ValueExtended>(
      `${getReportApiUrl(
        reportId,
      )}/reports/${reportId}/actions/preview_adjusted_avm`,
      operations,
      getHeaders(),
    );
    return response.data;
  },

  createReportFromMismoXml: async (formData: FormData) => {
    const response = await AxiosAccessTokenClientEhrm.post<ReportWithData>(
      `${REPORT_API_URL}/reports/${ReportTypes.Appraisal}`,
      formData,
      getHeaders(),
    );
    return response.data;
  },
};
