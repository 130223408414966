import { PropertyStateFields } from '@hcs/types';
import { UserPropertyAdjustments } from '@hcs/types';

export const processUserAdjustmentChanges = (
  adjustments: UserPropertyAdjustments['adjustments'],
): UserPropertyAdjustments['adjustments'] => {
  // Remove adjustment entries that are the same as the sourceValue
  for (const k in adjustments) {
    const field = k as PropertyStateFields;
    const adjustment = adjustments[field];
    if (adjustment?.sourceValue === adjustment?.currentValue) {
      delete adjustments[field];
    }
  }
  return adjustments;
};
