import React, { useEffect } from 'react';
import {
  Controller,
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { AnyObjectSchema } from 'yup';

import { MultiSelect, MultiSelectProps } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { RangeField } from '@hcs/design-system';
import { DialogInputLayout } from '@hcs/forms';
import { MarketGrade } from '@hcs/types';
import { CerberusStatsFields, MarketLevel } from '@hcs/types';
import {
  CerberusStatsFieldFilters,
  CerberusStatsFilterField,
  CerberusStatsFiltersFormData,
  CerberusStatsFiltersFormMaxFieldNames,
  CerberusStatsFiltersFormMinFieldNames,
} from '@hcs/types';

import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { useCerberusStatsFiltersForm } from '../../hooks/useCerberusStatsFiltersForm';
import {
  getMarketGradeName,
  getMaxFieldName,
  getMinFieldName,
} from '../../utils/cerberusStatsFilters.utils';
import { getNewFieldFiltersFromForm } from '../../utils/cerberusStatsFilters.utils';

import styles from './CerberusStatsEditFiltersDialog.module.css';

const MARKET_GRADE_OPTIONS: MultiSelectProps<MarketGrade | null>['options'] = [
  {
    label: 'A',
    value: MarketGrade.A,
  },
  {
    label: 'B',
    value: MarketGrade.B,
  },
  {
    label: 'C',
    value: MarketGrade.C,
  },
  {
    label: 'D',
    value: MarketGrade.D,
  },
  {
    label: 'F',
    value: MarketGrade.F,
  },
  {
    label: 'No Grade',
    value: null,
  },
];

interface CerberusStatsEditFiltersDialogProps {
  isActive: boolean;
  fieldFilters?: CerberusStatsFieldFilters | null;
  filterSetQueryIsLoading: boolean;
  onUpdateFieldFilters: (fieldFilters: CerberusStatsFieldFilters) => void;
  saveIsLoading: boolean;
  marketLevel: MarketLevel;
  onClose: VoidFunction;
}

type EditFiltersFormProps = Omit<
  CerberusStatsEditFiltersDialogProps,
  'isActive' | 'fieldFilters' | 'filterSetQueryIsLoading'
> & {
  dataHcName: string;
  filterableFields: CerberusStatsFilterField[];
  defaultFormValues: DefaultValues<CerberusStatsFiltersFormData>;
  // didn't want to use AnyObjectSchema here, but couldn't figure how to make the yup types happy with optional field/schemas
  validationSchema: AnyObjectSchema;
  actionsPortalIdRender?: string;
};

const EditFiltersForm = ({
  dataHcName,
  validationSchema,
  filterableFields,
  defaultFormValues,
  onUpdateFieldFilters,
  saveIsLoading,
  onClose,
  actionsPortalIdRender,
}: EditFiltersFormProps) => {
  const formMethods = useForm<CerberusStatsFiltersFormData>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const { reset, control, handleSubmit } = formMethods;
  const { isValid } = formMethods.formState;

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const onClear = () => {
    reset({});
    onUpdateFieldFilters({});
    onClose();
  };

  const onSubmit: SubmitHandler<CerberusStatsFiltersFormData> = (formData) => {
    onUpdateFieldFilters(
      getNewFieldFiltersFromForm(filterableFields, formData),
    );
    onClose();
  };

  return (
    <>
      <FormProvider {...formMethods}>
        {filterableFields.map((field) => {
          const fieldConfig = CERBERUS_STATS_FIELD_CONFIGS[field];
          if (field === CerberusStatsFields.marketGrade) {
            return (
              <DialogInputLayout
                key={field}
                theme={{
                  InputLayout: styles.MarketGradeRow,
                  InputLabelContainer: styles.LabelContainer,
                }}
                dataHcName={`${dataHcName}-${field}-layout}`}
                label={fieldConfig.labelShort}
                required={false}
              >
                <Controller
                  name={getMarketGradeName()}
                  control={control}
                  render={({ field }) => {
                    return (
                      <MultiSelect<MarketGrade | null>
                        inline
                        dataHcName={`${dataHcName}-${field}`}
                        options={MARKET_GRADE_OPTIONS}
                        onChange={field.onChange}
                        value={field.value || []}
                      />
                    );
                  }}
                />
              </DialogInputLayout>
            );
          }
          const minFieldName = getMinFieldName(field);
          const maxFieldName = getMaxFieldName(field);
          const filterConfig = fieldConfig.filterConfig;
          return (
            <DialogInputLayout
              key={field}
              theme={{
                InputLayout: styles.FormRow,
                InputLabelContainer: styles.LabelContainer,
              }}
              dataHcName={`${dataHcName}-${field}-layout}`}
              label={fieldConfig.labelShort}
              required={false}
            >
              <RangeField<
                CerberusStatsFiltersFormData,
                CerberusStatsFiltersFormMinFieldNames,
                CerberusStatsFiltersFormMaxFieldNames
              >
                minName={minFieldName}
                maxName={maxFieldName}
                dataHcName={`${dataHcName}-${field}`}
                isFloat={filterConfig?.decimalPlaces !== undefined}
                shouldFormat={{
                  shouldFormatNumber: !!filterConfig?.formatWithCommas,
                }}
                isPercent={filterConfig?.isPercent}
              />
            </DialogInputLayout>
          );
        })}
      </FormProvider>
      <ActionButtons
        dataHcName={`${dataHcName}-actions`}
        portalIdRender={actionsPortalIdRender}
        actions={[
          {
            dataHcName: `${dataHcName}-clear-filters-button`,
            label: 'Clear Filters',
            onClick: onClear,
            secondary: true,
          },
          {
            label: 'Apply Filters',
            dataHcName: `${dataHcName}-apply-filters-button`,
            disabled: !isValid || saveIsLoading,
            loading: saveIsLoading,
            onClick: () => handleSubmit(onSubmit)(),
          },
        ]}
      />
    </>
  );
};

const dataHcName = 'edit-cerberus-stats-filters-dialog';
export const CerberusStatsEditFiltersDialog = ({
  isActive,
  fieldFilters,
  filterSetQueryIsLoading,
  onUpdateFieldFilters,
  saveIsLoading,
  marketLevel,
  onClose,
}: CerberusStatsEditFiltersDialogProps) => {
  const {
    state: { filterableFields, validationSchema, defaultFormValues },
  } = useCerberusStatsFiltersForm({
    fieldFilters,
    filterSetQueryIsLoading,
    marketLevel,
  });

  return (
    <Dialog
      active={isActive}
      dataHcName={dataHcName}
      onClose={onClose}
      title="Filters"
    >
      {isActive &&
        validationSchema &&
        defaultFormValues &&
        filterableFields && (
          <EditFiltersForm
            actionsPortalIdRender={DIALOG_ACTIONS_PORTAL_ID}
            onClose={onClose}
            dataHcName={`${dataHcName}-form`}
            validationSchema={validationSchema}
            defaultFormValues={defaultFormValues}
            filterableFields={filterableFields}
            onUpdateFieldFilters={onUpdateFieldFilters}
            marketLevel={marketLevel}
            saveIsLoading={saveIsLoading}
          />
        )}
    </Dialog>
  );
};
