import React from 'react';
import classNames from 'classnames';

import styles from './DotIndicator.module.css';

export interface DotIndicatorProps {
  dataHcName: string;
  className?: string;
  style?: React.CSSProperties;
  colorType: 'red' | 'orange' | 'green' | 'gray' | 'custom';
  pulsate?: boolean;
}
export const DotIndicator = ({
  colorType,
  className,
  style,
  dataHcName,
  pulsate = false,
}: DotIndicatorProps) => {
  return (
    <span
      data-hc-name={dataHcName}
      style={style}
      className={classNames(
        styles.DotIndicator,
        {
          [styles.red]: colorType === 'red',
          [styles.green]: colorType === 'green',
          [styles.gray]: colorType === 'gray',
          [styles.orange]: colorType === 'orange',
          [styles.pulsate]: pulsate,
        },
        className,
      )}
    />
  );
};
