import {
  getSessionId,
  getUtmFromLocalStorage,
  setUtmInLocalStorage,
} from '@hcs/authn';
import { useComponentDidMount } from '@hcs/hooks';
import { OtherUrlTrackingParams, UtmCodes } from '@hcs/types';

import { QUERY_PARAM_TRACKING_PREFIX } from '../constants';

export const URL_SEARCH_PARAMS_EVENT_DATA_KEY = 'url_search_params';
export const useUrlSearchParamsEngagementData = () => {
  useComponentDidMount(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const {
      utmCodes: localStorageUtm,
      otherTrackingParams: localStorageOtherParams,
    } = getUtmFromLocalStorage();
    const utms: UtmCodes = {
      utm_campaign:
        searchParams.get('utm_campaign') ||
        localStorageUtm?.utm_campaign ||
        null,
      utm_content:
        searchParams.get('utm_content') || localStorageUtm?.utm_content || null,
      utm_medium:
        searchParams.get('utm_medium') || localStorageUtm?.utm_medium || null,
      utm_source:
        searchParams.get('utm_source') || localStorageUtm?.utm_source || null,
      utm_adgroup:
        searchParams.get('utm_adgroup') || localStorageUtm?.utm_adgroup || null,
      utm_region:
        searchParams.get('utm_region') || localStorageUtm?.utm_region || null,
      utm_term:
        searchParams.get('utm_term') || localStorageUtm?.utm_term || null,
    };
    const otherTrackingParams: OtherUrlTrackingParams = {
      ...localStorageOtherParams,
      ...Object.fromEntries(
        Array.from(searchParams.entries()).filter(
          ([key]) =>
            !(key in utms) && key.startsWith(QUERY_PARAM_TRACKING_PREFIX),
        ),
      ),
    };
    // Check that the session is still valid, if not set or it is expired, utms in storage will be cleared before setting new ones
    getSessionId();
    setUtmInLocalStorage(utms, otherTrackingParams);
  });
};
