import React from 'react';
import classNames from 'classnames';

import { MultiSelect, Option } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ListingStatusCerberus } from '@hcs/types';
import { PropertyStateFields } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { ListingStatusNormalized } from '@hcs/types';
import { capitalizeFirstLetter, trimWithEllipsis } from '@hcs/utils';

import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../../hooks';
import { useReportPermissions } from '../../../hooks/useReportPermissions';

import { CompFilterChip } from './CompFilterChip';

import styles from './CompFilterPropertyStatus.module.css';

const OPTIONS: Option<ListingStatusNormalized>[] = [
  {
    value: ListingStatusCerberus.Active,
    label: (
      <>
        <div
          className={classNames(
            styles.StatusColor,
            styles[ListingStatusCerberus.Active],
          )}
        />{' '}
        Active
      </>
    ),
  },
  {
    value: ListingStatusCerberus.Pending,
    label: (
      <>
        <div
          className={classNames(
            styles.StatusColor,
            styles[ListingStatusCerberus.Pending],
          )}
        />{' '}
        Pending
      </>
    ),
  },
  {
    value: ListingStatusCerberus.Closed,
    label: (
      <>
        <div
          className={classNames(
            styles.StatusColor,
            styles[ListingStatusCerberus.Closed],
          )}
        />{' '}
        Closed
      </>
    ),
  },
];

const FIELD = PropertyStateFields.currentStatus;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-property-status';
export const COMP_FILTER_PROPERTY_STATUS: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const label = filterValue?.absoluteValue
      ? trimWithEllipsis(
          filterValue.absoluteValue.map(capitalizeFirstLetter).join(', '),
          30,
        )
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }
    const filterValue = filterDocument.data.filters?.[FIELD];
    return (
      <MultiSelect<ListingStatusNormalized>
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        disabled={!reportPermissions.isEditable}
        options={OPTIONS}
        onChange={(value: ListingStatusNormalized[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
