import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks';
import {
  convertNumArrayToFilterRange,
  formatMoneyAbbrStr,
  formatOrUndefined,
  getFilterLabel,
} from '../../../utils';
import { CompFilterListPriceRental } from '../../CompFiltersDeprecated/CompFilterListPriceRental';

import { CompFilterButton } from './CompFilterButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const COMP_FIELD = PropertyStateFields.currentListingPriceRental;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[COMP_FIELD];
const dataHcName = `comp-filter-button-${COMP_FIELD}`;
export const CompFilterButtonListPriceRental = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  if (!filterDocument) {
    return (
      <Skeleton
        style={{ margin: '0 5px' }}
        dataHcName={`${dataHcName}-skeleton`}
        type="button"
      />
    );
  }

  const filterValue = filterDocument.data.filters?.[COMP_FIELD];
  const active = !!filterValue;
  return (
    <CompFilterButton
      reportId={reportId}
      compType={compType}
      label={
        active
          ? getFilterLabel({
              field: COMP_FIELD,
              value: formatOrUndefined(
                formatMoneyAbbrStr,
                convertNumArrayToFilterRange(filterValue?.absoluteValue),
              ),
            })
          : fieldConfig.labelShort
      }
      className={className}
      compField={COMP_FIELD}
      content={
        <CompFilterListPriceRental reportId={reportId} compType={compType} />
      }
      active={active}
    />
  );
};
