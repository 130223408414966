import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';

import { UiPreferences } from '@hcs/types';
import { jsonPatchApplyEnhanced } from '@hcs/utils';

import { HuellApi } from '../api';

import { QUERY_KEY_UI_PREFERENCES_USER } from './useUiPreferencesForUser';

export const usePatchUiPreferencesForUser = () => {
  const queryClient = useQueryClient();
  const patchUiPreferencesMutation = useMutation(
    async (operations: Operation[]) => {
      return await HuellApi.patchUiPreferencesForUser(operations);
    },
    {
      onMutate: async (operations) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QUERY_KEY_UI_PREFERENCES_USER]);

        // Snapshot the previous value
        const previousUiPreferences =
          queryClient.getQueryData<UiPreferences>([
            QUERY_KEY_UI_PREFERENCES_USER,
          ]) || {};

        // Optimistically update to the new value
        queryClient.setQueryData<UiPreferences>(
          [QUERY_KEY_UI_PREFERENCES_USER],
          jsonPatchApplyEnhanced(previousUiPreferences, operations)[0]
            ?.newDocument || {},
        );

        // Return a context object with the snapshooted value
        return { previousUiPreferences };
      },
      onError: (err, newUiPreferences, context) => {
        console.error(err);
        queryClient.setQueryData<UiPreferences>(
          [QUERY_KEY_UI_PREFERENCES_USER],
          context?.previousUiPreferences || {},
        );
      },
      onSuccess: (newUiPreferences: UiPreferences) => {
        queryClient.setQueryData<UiPreferences>(
          [QUERY_KEY_UI_PREFERENCES_USER],
          newUiPreferences,
        );
      },
    },
  );
  return {
    ...patchUiPreferencesMutation,
    mutate: patchUiPreferencesMutation.mutate,
  };
};
