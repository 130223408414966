import { useMemo } from 'react';

import { useKeywordSearchByListingsByCerberusId } from '@hcs/keyword-search';
import { CompTypes } from '@hcs/types';
import { CompId, CompSchema, ReportId } from '@hcs/types';

import { compKeywordSearchKey } from '../utils';

import { useCompFarmListFiltered } from './useCompFarmListFiltered';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
}
export const useCompKeywordSearchMatches = ({ reportId, compType }: Props) => {
  const keywordSearchKey = compKeywordSearchKey(compType);
  const keywordSearchQuery =
    useKeywordSearchByListingsByCerberusId(keywordSearchKey);
  const { data: keywordSearchMatches } = keywordSearchQuery;
  const { data: compFarmListFiltered } = useCompFarmListFiltered(
    reportId,
    compType,
  );
  const { keywordMatchCompIds, keywordMatchCompSchemas } = useMemo(() => {
    const keywordMatchCompSchemas: Record<string, CompSchema> = {};
    const keywordMatchCompIds: CompId[] = [];
    compFarmListFiltered.forEach((compSchema) => {
      const cerberusId =
        compType === CompTypes.Rental
          ? compSchema.propertyState.listingDetailsRental?.entityId
          : compSchema.propertyState.listingDetailsSale?.entityId;
      if (cerberusId && keywordSearchMatches[cerberusId]) {
        keywordMatchCompIds.push(compSchema.compID);
        keywordMatchCompSchemas[compSchema.compID] = compSchema;
      }
    });
    return { keywordMatchCompIds, keywordMatchCompSchemas };
  }, [compFarmListFiltered, keywordSearchMatches]);
  return {
    ...keywordSearchQuery,
    data: {
      keywordMatchCompSchemas,
      keywordMatchCompIds,
    },
  };
};
