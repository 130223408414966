import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { formatList } from '@hcs/utils';

import { ArrowUpCircledIcon } from '../../../../foundations';
import { CompleteIcon, RemoveIcon } from '../../../../svgs/icons/indicator';
import { LoadingBar } from '../../../global/loading-errors-null/LoadingBar';
import { Button } from '../../buttons/Button';
import { TextButton } from '../../buttons/TextButton';

import styles from './DragAndDropUpload.module.css';

interface SingleConfig {
  multipleFiles: false;
  onChange: (file: File | null) => void;
}

interface MultiConfig {
  multipleFiles: true;
  onChange: (file: File[] | null) => void;
}

interface DragAndDropTheme {
  UploadSuccessCtaButton?: string;
}

export interface UploadSuccessCtaConfig {
  text: string;
  onClick: VoidFunction;
}

export interface DragAndDropUploadProps {
  dataHcName: string;
  className?: string;
  extensions: `.${string}`[];
  config: SingleConfig | MultiConfig;
  browseOnClick?: boolean;
  instructions?: string;
  isLoading?: boolean;
  showAllowedExtensions?: boolean;
  onClickTryAgain?: VoidFunction;
  onErrorChange?: (message: string | null) => void;
  uploadSuccessCta?: UploadSuccessCtaConfig;
  header?: {
    title: string;
    sampleFile?: {
      file: string;
      name: string;
      sampleFileText?: string;
    };
    clearUploadedFilesText?: string;
  };
  theme?: DragAndDropTheme;
}

const UploadFiles = forwardRef(
  (
    {
      dataHcName,
      browseOnClick,
      className,
      config,
      onBrowse,
      onChange,
      instructions,
      showAllowedExtensions,
      extensions,
    }: DragAndDropUploadProps & {
      onBrowse: VoidFunction;
      onChange: (files: File[]) => void;
    },
    ref: Ref<HTMLInputElement>,
  ) => (
    <div
      className={classNames(styles.VisibleDropArea, className)}
      onClick={browseOnClick ? onBrowse : undefined}
    >
      <div className={styles.Content}>
        <ArrowUpCircledIcon
          dataHcName={`${dataHcName}-upload-icon`}
          className={styles.UploadIcon}
          width={24}
          height={24}
        />
        <div className={styles.VisibleTitle}>Drop file here to upload</div>

        <div className={styles.BrowseComputer}>
          <span>or </span>
          <span
            data-hc-name={`${dataHcName}-select-file-action`}
            className={styles.BrowseComputerAction}
            onClick={onBrowse}
          >
            Browse Computer
          </span>
        </div>

        {instructions && (
          <div
            data-hc-name={`${dataHcName}-instructions`}
            className={styles.Instructions}
          >
            {instructions}
          </div>
        )}

        {showAllowedExtensions && (
          <div className={styles.AcceptedFileTypes}>
            Accepted file types: {formatList(extensions, 'or')}
          </div>
        )}
      </div>
      <input
        data-hc-name={`${dataHcName}-input`}
        ref={ref}
        onChange={(evt) => {
          if (evt?.target?.files?.length) {
            const fileArray = Array.from(evt?.target?.files).map(
              (file) => file,
            );
            onChange(fileArray);
          }
        }}
        type="file"
        accept={extensions.join(',')}
        style={{ display: 'none' }}
        multiple={config.multipleFiles}
      />
    </div>
  ),
);

const UploadSuccess = ({
  dataHcName,
  isLoading,
  selectedFiles,
  onRemove,
  uploadSuccessCta,
  theme,
}: {
  dataHcName: string;
  isLoading?: boolean;
  selectedFiles: File[] | null;
  onRemove: (index: number) => void;
  uploadSuccessCta?: UploadSuccessCtaConfig;
  theme?: DragAndDropTheme;
}) => (
  <div className={styles.DropdownSuccessful}>
    <div className={styles.Content}>
      <CompleteIcon
        color="primary-10"
        size="lg"
        dataHcName={`${dataHcName}-success-icon`}
      />
      <div
        data-hc-name={`${dataHcName}-success-title`}
        className={classNames(styles.VisibleTitle, {
          [styles.success]: true,
        })}
      >
        Upload successful
      </div>
      <div
        className={styles.UploadedFiles}
        data-hc-name={`${dataHcName}-uploaded-files`}
      >
        {selectedFiles?.map((file, i) => {
          return (
            <span
              key={file.name}
              onClick={() => {
                onRemove(i);
              }}
            >
              {file.name}
              {i < selectedFiles.length - 1 ? ', ' : ''}
            </span>
          );
        })}
      </div>
      {uploadSuccessCta && (
        <div className={styles.UploadSuccessCtaButtonWrapper}>
          <Button
            className={classNames(
              styles.UploadSuccessCtaButton,
              theme?.UploadSuccessCtaButton,
            )}
            dataHcName={`${dataHcName}-button`}
            onClick={uploadSuccessCta.onClick}
            loading={isLoading}
          >
            {uploadSuccessCta.text}
          </Button>
        </div>
      )}
    </div>
  </div>
);

const UploadError = ({
  dataHcName,
  selectedFiles,
  errorMessage,
  onClickTryAgain,
}: {
  dataHcName: string;
  selectedFiles: File[] | null;
  errorMessage: string;
  onClickTryAgain: VoidFunction;
}) => (
  <div className={styles.DropdownSuccessful}>
    <div className={styles.Content}>
      <RemoveIcon
        dataHcName={`${dataHcName}-failure-icon`}
        size={40}
        color="error-10"
      />
      <div
        className={classNames(styles.VisibleTitle, {
          [styles.failure]: true,
        })}
      >
        Upload Failed: {errorMessage}
      </div>
      <div className={styles.UploadedFiles}>
        {selectedFiles?.map((file) => file.name).join(', ')}
      </div>
    </div>
    <div className={styles.TryAgain} onClick={onClickTryAgain}>
      Try again
    </div>
  </div>
);

const Uploading = ({ dataHcName }: { dataHcName: string }) => {
  return (
    <div className={styles.DropdownSuccessful}>
      <div className={styles.Content}>
        <div
          className={classNames(styles.VisibleTitle, {
            [styles.success]: true,
          })}
        >
          Uploading...
        </div>
      </div>
      <LoadingBar
        className={styles.LoadingBar}
        dataHcName={`${dataHcName}-loading`}
      />
    </div>
  );
};
export const DragAndDropUpload = (props: DragAndDropUploadProps) => {
  const {
    dataHcName,
    extensions,
    config,
    onClickTryAgain,
    onErrorChange,
    isLoading,
    header,
    uploadSuccessCta,
    theme,
  } = props;
  const ERROR_MSG_EXTENSIONS = `Only ${formatList(
    extensions,
  )} files are allowed`;
  const fileInput = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    onErrorChange?.(errorMessage || null);
  }, [errorMessage]);

  const handleOnChange = (files: File[]) => {
    if (config.multipleFiles) {
      config.onChange(files);
    } else {
      config.onChange(files[0] || null);
    }
  };

  const handleSelectFiles = useCallback(
    (files: File[]) => {
      handleOnChange(files);
      setSelectedFiles(files);
    },
    [config.onChange],
  );

  const handleRemoveFile = (i: number) => {
    if (!selectedFiles) return;
    const files = [...selectedFiles];
    files?.splice(i, 1);

    setSelectedFiles(files);
    handleOnChange(files);
  };

  const handleDragEnter = (evt: MouseEvent) => {
    evt.preventDefault();
    setOverlayVisible(true);
    setErrorMessage('');
  };
  const handleDragOver = (evt: DragEvent) => {
    evt.preventDefault();
  };
  const handleDragLeave = (evt: DragEvent) => {
    evt.preventDefault();
  };

  const handleDrop = useCallback(
    (evt: DragEvent) => {
      evt.preventDefault();
      const files = evt.dataTransfer?.files;
      if ((files?.length || 0) > 1 && !config.multipleFiles) {
        setErrorMessage('Please upload only one file at a time');
        setOverlayVisible(false);
      } else if (files?.length) {
        const filesArray: File[] = [];
        Array.from(files).forEach((file) => {
          const extension = file?.name.split('.').pop();
          if (file && extensions.includes(`.${extension}`)) {
            if (fileInput.current) {
              fileInput.current.value = '';
            }
            filesArray.push(file);
          } else {
            setErrorMessage(ERROR_MSG_EXTENSIONS);
            setOverlayVisible(false);
          }
          handleSelectFiles([...filesArray]);
          setOverlayVisible(false);
        });
      } else {
        setErrorMessage(ERROR_MSG_EXTENSIONS);
        setOverlayVisible(false);
      }
    },
    [fileInput, handleSelectFiles, setErrorMessage, setOverlayVisible],
  );

  useEffect(() => {
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDrop]);

  return (
    <>
      {overlayVisible && (
        <div
          data-hc-name={dataHcName}
          className={styles.DragAndDropFileUpload}
          onClick={() => setOverlayVisible(false)}
        >
          <div className={styles.Content}>
            <p className={styles.Title}>Drop anywhere to upload</p>
          </div>
        </div>
      )}
      {header && (
        <div className={classNames(styles.AddressUploadHeader)}>
          <div
            className={styles.Title}
            data-hc-name={`${dataHcName}-header-title`}
          >
            {header.title}
          </div>
          {!selectedFiles?.length && header.sampleFile && (
            <div className={styles.Help}>
              Need help?{' '}
              <TextButton
                dataHcName={`${dataHcName}-sample-download-link`}
                className={styles.TextButton}
                noPadding
              >
                <a
                  href={header.sampleFile.file}
                  download={header.sampleFile.name}
                >
                  {header.sampleFile.sampleFileText ||
                    'Download a sample spreadsheet.'}
                </a>
              </TextButton>
            </div>
          )}
          {selectedFiles && selectedFiles.length > 0 && (
            <TextButton
              dataHcName={`${dataHcName}-clear-uploaded-file`}
              className={styles.TextButton}
              onClick={() => {
                config.onChange(null);
                setSelectedFiles(null);
                setErrorMessage('');
              }}
            >
              {header.clearUploadedFilesText
                ? header.clearUploadedFilesText
                : 'Upload a different file'}
            </TextButton>
          )}
        </div>
      )}

      {errorMessage ? (
        <UploadError
          dataHcName={dataHcName}
          selectedFiles={selectedFiles}
          errorMessage={errorMessage}
          onClickTryAgain={() => {
            setErrorMessage('');
            onClickTryAgain?.();
          }}
        />
      ) : !selectedFiles?.length ? (
        <UploadFiles
          {...props}
          ref={fileInput}
          onChange={handleSelectFiles}
          onBrowse={() => fileInput.current?.click()}
        />
      ) : isLoading ? (
        <Uploading dataHcName={dataHcName} />
      ) : (
        <UploadSuccess
          dataHcName={dataHcName}
          selectedFiles={selectedFiles}
          onRemove={handleRemoveFile}
          uploadSuccessCta={uploadSuccessCta}
          theme={theme}
        />
      )}
    </>
  );
};
