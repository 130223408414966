import React from 'react';
import classNames from 'classnames';

import { useCardTabsState } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { ReportId, SubjectValuePaths, ValuationMethod } from '@hcs/types';

import { ValueSelectorButton } from '../../components-deprecated';
import { ValueSelectorItem } from '../../components-deprecated';
import {
  VALUATION_METHOD_DESCRIPTIONS,
  VALUATION_METHOD_DESCRIPTIONS_RENTAL,
  VALUATION_METHOD_LABELS,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants/valuationMethods.constants';
import { useSubjectValueDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './ReportValueSelector.module.css';

interface Props {
  reportId: ReportId;
  showRentalValue: boolean;
  className?: string;
  onClose: VoidFunction;
}
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const dataHcName = 'adjusted-avm-selector-item';
export const AdjustedAvmSelectorItem = ({
  reportId,
  showRentalValue,
  className,
  onClose,
}: Props) => {
  const {
    actions: { setActiveTabId },
  } = useCardTabsState('subject-tab-section');
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });

  if (!subjectValueDocument || !reportPermissions) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton`}
        containerHeight="380px"
      />
    );
  }
  const noAdjustedValue =
    !subjectValueDocument.data[ValuationMethod.Adjusted]?.value.value;
  const handleSelect = () => {
    documentPatchMutation.mutate({
      reportId,
      document: subjectValueDocument,
      operations: [
        {
          op: 'replace',
          path: PATH_SELECTED_VALUE,
          value: ValuationMethod.Adjusted,
        },
      ],
    });
    if (noAdjustedValue) {
      const subjectTabSectionElm: HTMLElement | null = document.querySelector(
        `[data-hc-name="${'subject-tab-section'}"]`,
      );
      subjectTabSectionElm?.scrollIntoView({ behavior: 'smooth' });
      console.log('SET TAB', 'subject-tab-section');
      setActiveTabId('propertyDetails');
    }
    onClose();
  };

  return (
    <ValueSelectorItem
      title={
        showRentalValue
          ? VALUATION_METHOD_LABELS_RENTAL[ValuationMethod.Adjusted]
          : VALUATION_METHOD_LABELS[ValuationMethod.Adjusted]
      }
      dataHcName={dataHcName}
      value={subjectValueDocument.data[ValuationMethod.Adjusted]?.value.value}
      className={classNames(styles.AVMSelectionItem, className)}
    >
      {showRentalValue
        ? VALUATION_METHOD_DESCRIPTIONS_RENTAL[ValuationMethod.Adjusted]
        : VALUATION_METHOD_DESCRIPTIONS[ValuationMethod.Adjusted]}
      {noAdjustedValue && 'Click below to make adjustments.'}
      {reportPermissions.isEditable && (
        <ValueSelectorButton
          dataHcName={`${dataHcName}-value-selector-button`}
          onClick={handleSelect}
        >
          {noAdjustedValue ? 'Adjust Details' : 'Use For Report'}
        </ValueSelectorButton>
      )}
    </ValueSelectorItem>
  );
};
