import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ACCOUNT_QUERY_KEY } from '@hcs/http-clients';
import { useToastSlice } from '@hcs/toast';

import { UserApi } from '../api';

type ResendConfirmationEmailProps = UseMutationOptions<
  void,
  AxiosError<{ status: string }>,
  number,
  void
>;

export const useResendConfirmationEmail = (
  options?: ResendConfirmationEmailProps,
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError<{ status: string }>, number, void>(
    async (userId: number) => {
      // Api returns a 202
      await UserApi.resendConfirmation(userId);
    },
    {
      ...options,
      onMutate(variables) {
        toastOpen({
          title: 'Sending Confirmation Email...',
          type: 'loading',
        });
        options?.onMutate?.(variables);
      },
      onError(error, variables) {
        toastOpen({
          title: 'Failed to Send Confirmation Email.',
          type: 'loading-failure',
        });
        options?.onError?.(error, variables);
      },
      onSuccess(data, variables) {
        toastOpen({
          title: 'Confirmation Email Sent.',
          type: 'loading-success',
        });
        queryClient.invalidateQueries([ACCOUNT_QUERY_KEY]);
        options?.onSuccess?.(data, variables);
      },
    },
  );
};
