import React, { useEffect } from 'react';

import {
  PropertyStateArgsCore,
  PropertyStateArgsPreview,
  PropertyStateCerberusInput,
} from '@hcs/types';

import { PropertyCard, PropertyCardProps } from '../../components';
import { usePropertyState } from '../../hooks';
import { PropertyStateThumbnailCarousel } from '../PropertyStateThumbnailCarousel';

export interface PropertyStateCardProps
  extends Omit<
    PropertyCardProps,
    'dataHcName' | 'propertyStateArgs' | 'photos' | 'isLoading'
  > {
  propertyStateCerberusInput: PropertyStateCerberusInput;
  onDataReady?: (
    propertyState: PropertyStateArgsCore | PropertyStateArgsPreview,
  ) => void;
  showStatusPillLabel?: boolean;
}

export type PropertyStateCardPropsCreator =
  | Omit<PropertyStateCardProps, 'propertyStateCerberusInput'>
  | ((
      propertyStateCerberusInput: PropertyStateCerberusInput,
    ) => Omit<PropertyStateCardProps, 'propertyStateCerberusInput'>);

const dataHcName = 'cerberus-property-card';
export const PropertyStateCard = ({
  propertyStateCerberusInput,
  onPhotoViewToggle,
  onDataReady,
  showStatusPillLabel,
  noGallery = false,
  ...propertyCardProps
}: PropertyStateCardProps) => {
  const propertyQuery = usePropertyState({
    ...propertyStateCerberusInput,
    onSuccess: onDataReady,
  });
  useEffect(() => {
    if (!!onDataReady && !!propertyQuery.data?.propertyState) {
      onDataReady(propertyQuery.data);
    }
  }, []);
  return (
    <PropertyCard
      dataHcName={dataHcName}
      {...propertyCardProps}
      noGallery={noGallery}
      photos={
        noGallery ? null : (
          <PropertyStateThumbnailCarousel
            onPhotoViewToggle={onPhotoViewToggle}
            propertyStateCerberusInput={propertyStateCerberusInput}
            propertyStateArgs={propertyQuery.data}
            showStatusPillLabel={showStatusPillLabel}
          />
        )
      }
      isLoading={propertyQuery.isInitialLoading}
      propertyStateArgs={propertyQuery.data}
    />
  );
};
