import {
  useTrackClickEvents,
  useUrlSearchParamsEngagementData,
} from '../../hooks';
import { useTrackBlurEvents } from '../../hooks/useTrackBlurEvents';
import { useTrackPageViewedEvents } from '../../hooks/useTrackPageViewedEvents';
import { EngagementEventQueue } from '../EngagementEventQueue/EngagementEventQueue';

export const EngagementTracking = () => {
  useUrlSearchParamsEngagementData();
  useTrackPageViewedEvents();
  useTrackClickEvents();
  useTrackBlurEvents();
  return <EngagementEventQueue />;
};
