import React, { useMemo } from 'react';
import classNames from 'classnames';

import { AddressSearch } from '@hcs/address-search';
import { MultiSearch, MultiSearchInput } from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';
import { SearchByMlsNumber } from '@hcs/search-by-mls-number';
import { AddressSearchHit, MeaningfulEventTypes } from '@hcs/types';
import { PropertyGraphListing } from '@hcs/types';
import { Report } from '@hcs/types';

import { SearchByClientId } from '../SearchByClientId';

import styles from './ReportSearch.module.css';

export interface ReportSearchProps {
  className?: string;
  invertedColors?: boolean;
  onSelectAddressHit: (addressHit: AddressSearchHit) => void;
  onSelectReportHit: (reportHit: Report) => void;
  onSelectListingHit: (listing: PropertyGraphListing) => void;
}
const dataHcName = 'report-search';
export const dataHcEventSectionReportSearch = 'Report Search';
export const ReportSearch = ({
  className,
  invertedColors,
  onSelectAddressHit,
  onSelectReportHit,
  onSelectListingHit,
}: ReportSearchProps) => {
  const { isPhoneWidth } = useScreenSize();
  const inputs = useMemo<MultiSearchInput[]>(() => {
    return [
      {
        label: 'Address',
        value: 'address',
        input: (
          <AddressSearch
            selectEngagement={{
              dataHcEventType: MeaningfulEventTypes.Goal,
              dataHcEventName: 'Subject Property Search Select',
            }}
            placeholder={isPhoneWidth ? "Address" : "Search for an Address"}
            onSelect={onSelectAddressHit}
            disableClear
            hideChevron
            inputStyle="transparent"
            hasAvm
          />
        ),
      },
      {
        label: 'Client File ID',
        value: 'clientId',
        input: (
          <SearchByClientId
            placeholder={isPhoneWidth ? "Client ID" : "Search by Client ID"}
            onSelect={onSelectReportHit}
            disableClear
            hideChevron
            selectEngagement={{
              dataHcEventType: MeaningfulEventTypes.Goal,
              dataHcEventName: 'Client File ID Search Select',
            }}
            inputStyle="transparent"
          />
        ),
      },
      {
        label: 'MLS',
        value: 'listing',
        input: (
          <SearchByMlsNumber
            placeholder={isPhoneWidth ? "MLS Number" : "Search for an MLS Number"}
            queryId="report-search"
            selectEngagement={{
              dataHcEventType: MeaningfulEventTypes.Goal,
              dataHcEventName: 'Subject Listing Search Select',
            }}
            onSelect={onSelectListingHit}
            disableClear
            hideChevron
            inputStyle="transparent"
          />
        ),
      },
    ];
  }, [onSelectAddressHit, onSelectReportHit, onSelectListingHit, isPhoneWidth]);
  return (
    <MultiSearch
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionReportSearch}
      className={classNames(className, styles.ReportSearch)}
      inputs={inputs}
      invertedColors={invertedColors}
    />
  );
};
