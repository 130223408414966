import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  PropertyStateMediaDocument,
  PropertyStateMediaQuery,
  PropertyStateMediaQueryVariables,
} from '@hcs/types';
import { PropertyStateCerberusInput } from '@hcs/types';

import {
  cerberusLatestListingToPropertyStateMedia,
  prepPropertyStateCerberusInputs,
} from '../utils';

const QUERY_KEY_CERBERUS_PROPERTY_MEDIA = 'cerberus-property-media';
export const usePropertyStateMedia = (
  propertyStateCerberusInput: PropertyStateCerberusInput,
) => {
  const { cerberusInput } = prepPropertyStateCerberusInputs(
    propertyStateCerberusInput,
  );
  return useQuery(
    [QUERY_KEY_CERBERUS_PROPERTY_MEDIA, propertyStateCerberusInput],
    async () => {
      if (cerberusInput) {
        const response = await CerberusApi.fetchQuery<
          PropertyStateMediaQuery,
          PropertyStateMediaQueryVariables
        >(PropertyStateMediaDocument, {
          cerberusInput,
        });
        return cerberusLatestListingToPropertyStateMedia(response.lookup);
      } else {
        return null;
      }
    },
  );
};
