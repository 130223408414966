import { PayloadAction } from '@reduxjs/toolkit';

import { AppSlugs, AuthDevToolsSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

interface AppAccessPayload {
  appSlug: AppSlugs;
}

const initialState: AuthDevToolsSliceState = { disabledApps: {} };

export const authDevToolsSlice = createHcReduxSlice({
  name: 'authDevTools',
  initialState,
  reducers: {
    enableAppAccess(state, action: PayloadAction<AppAccessPayload>) {
      state.disabledApps[action.payload.appSlug] = false;
    },
    disableAppAccess(state, action: PayloadAction<AppAccessPayload>) {
      state.disabledApps[action.payload.appSlug] = true;
    },
  },
});
