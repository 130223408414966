import { PlatformIcon } from '@hcs/design-system';
import { AppSlugs } from '@hcs/types';
import { AppConfig } from '@hcs/types';

// Fallback AppConfig that allows user access to the app
export const APP_CONFIG_FALLBACK: AppConfig = {
  appSlug: AppSlugs.Platform,
  type: 'external-app',
  Icon: PlatformIcon,
  applicationKey: 'platform',
  rootPath: '/',
  name: '',
  descriptionLong: '',
  descriptionShort: '',
};
