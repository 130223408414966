import { useQuery } from '@tanstack/react-query';

import { hookEnabledError } from '@hcs/errors';

import { OrgUsersApi } from '../api';

export const QUERY_KEY_ORG_ROLES = 'org-roles';

export const useOrgRoles = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_ROLES, orgId],
    async () => {
      if (orgId) {
        const response = await OrgUsersApi.getOrgRoles(orgId);
        return response.roles;
      } else {
        throw hookEnabledError('useOrgRoles', 'orgId');
      }
    },
    {
      enabled: !!orgId,
    },
  );
};
