import React from 'react';
import classNames from 'classnames';

import { AppSlugs, PlatformDashboardAppSlugs } from '@hcs/types';

import { AexpProductCard } from './AexpProductCard';
import { AgileSuiteProductCard } from './AgileSuiteProductCard';
import { CanaryAIProductCard } from './CanaryAIProductCard';
import { DexpProductCard } from './DexpProductCard';
import { MarketInsightsProductCard } from './MarketInsightsProductCard';
import { PexpProductCard } from './PexpProductCard';
import { PortfolioProductCard } from './PortfolioProductCard';

import styles from './ProductCards.module.css';

interface Props {
  className?: string;
  appSlugList: PlatformDashboardAppSlugs;
  navigateToSSSelectPlan?: VoidFunction;
}

/* Access is checked within each dedicated product card component */
const COMPONENT_FOR_APP_SLUG = {
  [AppSlugs.DataExplorer]: DexpProductCard,
  [AppSlugs.PropertyExplorer]: PexpProductCard,
  [AppSlugs.AcquisitionExplorer]: AexpProductCard,
  [AppSlugs.MarketInsights]: MarketInsightsProductCard,
  [AppSlugs.PortfolioMonitor]: PortfolioProductCard,
  [AppSlugs.CanaryAI]: CanaryAIProductCard,
  [AppSlugs.AgileSuite]: AgileSuiteProductCard,
} satisfies Partial<Record<AppSlugs, React.FC>>;

const dataHcName = 'product-cards';
export const ProductCards = ({
  appSlugList,
  className,
  navigateToSSSelectPlan,
}: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ProductCards, className)}
    >
      {appSlugList.map((slug) => {
        const Component = COMPONENT_FOR_APP_SLUG[slug];

        return (
          <Component
            key={slug}
            navigateToSSSelectPlan={navigateToSSSelectPlan}
          />
        );
      })}
    </div>
  );
};
