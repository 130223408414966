import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatMissing, formatMoney, NULL_VALUE } from '@hcs/utils';

const FIELD = PropertyStateFields.association;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Home Owners Association';
const labelShort = 'HOA';
const propertyStatePath: PropertyStatePaths = '/propertyDetails/association';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.propertyDetails?.association;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) => {
  const associations = getValue(propertyStateArgs);
  if (associations?.length) {
    const results: string[] = [];
    associations.forEach((association) => {
      const feeDesc = [];
      if (association?.fee) {
        feeDesc.push(
          `${formatMoney(association?.fee)} / ${formatMissing(
            association?.frequency,
          )}`,
        );
      }
      if (association?.name) {
        feeDesc.push(`Name: ${association.name}`);
      }
      if (association?.phone) {
        feeDesc.push(`Phone: ${association?.phone}`);
      }
    });
    return results.length > 0 ? results.join('\n') : NULL_VALUE;
  } else {
    return NULL_VALUE;
  }
};

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `HOA Amenities ${formatValue(propertyStateArgs)}`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const ASSOCIATION_CONFIG: PropertyStateFieldConfig<typeof FIELD> = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  Display,
  HeaderCell,
  ContentCell,
};
