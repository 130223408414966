import { OranizationSubscriptionType, SelfServePlan } from '../account-api';
import { AgileProductGroupings, AppSlugs } from '../auth';
import { CanaryAiEventData } from '../cararyai';

export enum BaseEventTypes {
  Click = 'click',
  Blur = 'blur',
}

export enum MeaningfulEventTypes {
  SectionViewed = 'Section Viewed',
  Goal = 'Goal Complete',
}
export type OptionalEngagementProps = Partial<{
  dataHcEventName: string;
  dataHcEventSection: string;
  dataHcEventType: string;
  dataHcEventIgnore: boolean;
}>;

export interface BrowserEventData {
  browser: string;
  browser_version: string;
  browser_height: number;
  browser_width: number;
  os: string;
  os_version: string;
  user_agent: string;
}

export interface PropertyReportEngagementEventData {
  viewer_is_report_owner?: boolean;
  report_id?: number;
  report_owner_id?: number;
  report_owner_organization_id?: number;
  report_type?: string;
  report_config_slug?: string;
  street_address?: string;
  unit?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  slug?: string;
  hc_address_id?: number;
}

export interface OfferNowEngagementEventData {
  list_price?: number | null;
  hc_address_id?: number | null;
  street_address?: string | null;
  unit?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  slug?: string | null;
  msa_id?: string | null;
  county_name?: string | null;
  county_fips?: string | null;
}

export interface UtmCodes {
  utm_campaign?: string | null;
  utm_content?: string | null;
  utm_medium?: string | null;
  utm_source?: string | null;
  utm_adgroup?: string | null;
  utm_region?: string | null;
  utm_term?: string | null;
}

export type OtherUrlTrackingParams = Record<`trk_${string}`, string>;

export type EngagementEventData = Partial<{
  event_section: string;
  device: BrowserEventData;
  page_group: string;
  url_search_params: Record<string, string>;
  input_value: string;
  data_hc_name: string;
  original_event_type: string;
  time_entered: string;
  time_left: string;
  seconds_on_page: number;
  property_report: PropertyReportEngagementEventData;
  offer_now: OfferNowEngagementEventData;
  requested_product: AppSlugs | AgileProductGroupings;
  self_service_signup_error: string;
  self_service_credit_card_save_error: string;
  utm: UtmCodes | null;
  // Self serve event data
  plan_id: string;
  plan: SelfServePlan;
  canceled_subscription: boolean;
  upcoming_self_serve_plan: SelfServePlan;
  // CanaryAI event data
  feedback_message: string;
  chart_id: string | null;
  chart_description: string | null;
  conversation_id_to_select: string;
  conversation_id_to_delete: string;
  dataset_download_type:
    | string
    | { index: number; description: string }[]
    | null;
  // Allow arbirary additional fields to be passed

  // [additionalKey: string]: any;
}> &
  Partial<CanaryAiEventData>;

// Attributes that can only be populated when dispatching
export interface EngagementEvent extends UtmCodes {
  // Passed w/ when triggering an event
  event_name: string;
  event_type?: BaseEventTypes | MeaningfulEventTypes | string;
  event_data?: EngagementEventData;
  event_specific_field?: string; // Event Specific data that Product can request be populated for easier querying
  // Not passed when triggering an event, populated before sent to beacon
  app: AppSlugs;
  session_id: string;
  http_path: string; // Only passed from the pageleave event
  http_referer: string;
  // Optionally set by EngagementEventPageGroup feature. Gets set on RTK slice when routes are wrapped.
  page_group?: string;
  // User Info - optional so we can track events from unauthenticated users
  // Typically not passed whent triggering but can be for events like login and confirm
  auth_user_id?: number;
  email?: string;
  auth_organization_slug?: string;
  auth_organization_id?: number;
  subscription_type?: OranizationSubscriptionType;
}

// Argument for the useTrackEngagementEvent hook
export type EngagementEventArg = Omit<
  EngagementEvent,
  'session_id' | 'http_referer' | 'http_path' | 'app' | keyof UtmCodes
> & {
  // Passed w/ the pageleave event
  http_path?: string;
};

export interface EngagementTrackingEventDataSliceState {
  [eventDataId: string]:
    | {
        /** Unique Id for the event data - Multiple components can register the same data */
        eventDataId: string;
        /** Event data to be sent with every tracking event */
        event_data: EngagementEventData;
        /** Unique Ids for all instances of components registering this event data */
        components: string[];
      }
    | undefined;
}

export interface EventQueueSliceState {
  shouldQueueEvents: boolean;
  events: EngagementEventArg[];
}

export interface EngagementTrackingDevToolState {
  logEvents: false | 'all' | 'meaningful';
  outlineSections: boolean;
}

export interface EngagementTrackingMeaningfulEventsSliceState {
  event_section: string | null;
}

export interface EngagementTrackingPartialAppState {
  engagementTracking: {
    eventQueue: EventQueueSliceState;
    eventData: EngagementTrackingEventDataSliceState;
    meaningfulEvents: EngagementTrackingMeaningfulEventsSliceState;
  };
}

export type IntercomEventData = {
  browser?: BrowserEventData['browser'];
  browserVersion?: BrowserEventData['browser_version'];
  os?: BrowserEventData['os'];
  osVersion?: BrowserEventData['os_version'];
  eventSection: EngagementEventData['event_section'];
  eventType: EngagementEvent['event_type'];
  app: EngagementEvent['app'];
  pageGroup: EngagementEvent['page_group'];
  httpPath: EngagementEvent['http_path'];
  httpReferer: EngagementEvent['http_referer'];
  dataHcName: EngagementEventData['data_hc_name'];
  originalEventType: EngagementEventData['original_event_type'];
  propertyReportAddressId?: PropertyReportEngagementEventData['hc_address_id'];
  propertyReportAddressSlug?: PropertyReportEngagementEventData['slug'];
  propertyReportId?: PropertyReportEngagementEventData['report_id'];
  offerNowAddressId?: OfferNowEngagementEventData['hc_address_id'];
  offerNowAddressSlug?: OfferNowEngagementEventData['slug'];
  requestedProduct?: EngagementEventData['requested_product'];
};
