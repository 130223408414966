import React from 'react';

import { AppConfigExtended } from '@hcs/types';

import { AppLauncherIcon } from '../../../../svgs/icons/navigation';
import { Anchor } from '../../../display/links/Anchor';
import { PopoverTheme } from '../../../display/popovers/Popover';
import { TabPopover } from '../../../display/popovers/TabPopover';

import { AppLauncherOnClickAppCallback } from './AppLauncher.types';
import { AppLauncherButton } from './AppLauncherButton';

import styles from './AppLauncher.module.css';

export interface AppLauncherProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  appConfigsExtended: AppConfigExtended[];
  onClickApp: AppLauncherOnClickAppCallback;
  theme?: PopoverTheme & {
    Content?: string;
  };
  viewAll?: {
    onClick: VoidFunction;
    label: string;
  };
  bottomSection?: React.ReactNode;
}
export const AppLauncher = ({
  appConfigsExtended,
  dataHcName,
  theme,
  onClickApp,
  viewAll,
  bottomSection,
}: AppLauncherProps) => {
  const trigger = (
    <AppLauncherIcon color="primary-10" dataHcName={`${dataHcName}-trigger`} />
  );
  return (
    <TabPopover
      dataHcName={dataHcName}
      closeOnClick
      theme={theme}
      trigger={trigger}
      closeTrigger={trigger}
      content={
        <>
          <ul
            data-hc-name={`${dataHcName}-app-buttons`}
            className={styles.AppButtons}
          >
            {appConfigsExtended.map((appConfigExtended) => {
              return (
                <AppLauncherButton
                  key={`${appConfigExtended.appConfig.appSlug}-${appConfigExtended.appConfig.name}`}
                  appConfigExtended={appConfigExtended}
                  onClick={onClickApp}
                />
              );
            })}
          </ul>
          {viewAll && (
            <Anchor
              className={styles.ViewAllLink}
              dataHcName={`${dataHcName}-view-all`}
              onClick={viewAll.onClick}
            >
              {viewAll.label}
            </Anchor>
          )}
          {bottomSection && <div>{bottomSection}</div>}
        </>
      }
    />
  );
};
