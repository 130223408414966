import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';

import { useToastSlice } from '@hcs/toast';
import { OrgReportPreferencesResponse, ReportPreferences } from '@hcs/types';

import { HuellApi } from '../api';

import { QUERY_KEY_REPORT_PREFERENCES_USER } from './useReportPreferencesForUser';

export const usePatchReportPreferencesForUser = () => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const queryClient = useQueryClient();
  const patchReportPreferencesMutation = useMutation<
    OrgReportPreferencesResponse,
    unknown,
    Operation[]
  >(
    async (operations: Operation[]) => {
      return await HuellApi.patchReportPreferencesForUser(operations);
    },
    {
      onSuccess: (newReportPreferences) => {
        if (newReportPreferences.preferences) {
          queryClient.setQueryData<ReportPreferences>(
            [QUERY_KEY_REPORT_PREFERENCES_USER],
            newReportPreferences.preferences,
          );
        }
        toastOpen({
          type: 'success',
          title: 'Report Preferences Updated',
        });
      },
    },
  );
  return {
    ...patchReportPreferencesMutation,
    mutate: patchReportPreferencesMutation.mutate,
  };
};
