import { DateStr } from '../Date.types';

import { ReportTypes } from '.';

export enum LimitedReportTypes {
  MissingAvm = 'missing-avm',
  MissingRentalAvm = 'missing-rental-avm',
  MissingLocation = 'missing-location',
  MissingMls = 'missing-mls-coverage',
}

export interface ReportTypeSupport {
  errors: string | null;
  limitedType: LimitedReportTypes[] | null;
}

export interface ReportTypeSupportInputs {
  streetAddress?: string | null;
  unit?: string | null;
  zipcode?: string | null;
  slug?: string | null;
  hcAddressId?: number | null;
  addressID?: number | null;
  mlsID?: string | null;
  mlsNumber?: string | null;
  clientID?: string | null;
  reportType?: ReportTypes;
  effectiveDate?: DateStr | null;
}

export interface ReportTypeSupportRequest {
  requests: ReportTypeSupportInputs[];
}

interface ReportTypeSupportOutputs {
  request: ReportTypeSupportInputs;
  canCreate: boolean;
  detail: ReportTypeSupport;
}

export interface ReportTypeSupportResponse {
  results: ReportTypeSupportOutputs[];
}
