import React from 'react';
import classNames from 'classnames';

import { ThumbnailCarousel } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { StreetViewDialog } from '@hcs/street-view';
import { StreetViewLauncher } from '@hcs/street-view';
import { CompTypes } from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';

import { CompTags } from '../../features/CompTags';
import { useComp } from '../../hooks';
import { useCompPhotos } from '../../hooks/useCompPhotos';
import { usePhotosPage } from '../../hooks/usePhotosPage';
import { useReportConfig } from '../../hooks/useReportConfig';
import { isOptimisticDocumentId } from '../../utils';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import {
  dataHcEventSectionCompStreetView,
  dataHcEventSectionRentalCompStreetView,
} from '../ReportPhotosOverlayPage';

import styles from './CompPhotoCarousel.module.css';

export interface CompPhotoCarouselProps {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  className?: string;
  onStreetViewToggle?: (isStreetViewOpen: boolean) => void;
  showStreetViewIcon?: boolean;
}
const dataHcName = 'comp-photo-carousel';
export const COMP_PHOTO_CAROUSEL_FEATURES_SALE = [
  ReportFeatures.CompsPhotosCurrent,
  ReportFeatures.CompsStreetview,
  ReportFeatures.Tags,
];
export const COMP_PHOTO_CAROUSEL_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsPhotosCurrent,
  ReportFeatures.RentalCompsStreetview,
  ReportFeatures.Tags,
];
export const CompPhotoCarousel = ({
  reportId,
  className,
  compIdentifier,
  showStreetViewIcon,
  onStreetViewToggle,
}: CompPhotoCarouselProps) => {
  const { photosPageOpen } = usePhotosPage();
  const {
    active: streetViewActive,
    handleClose,
    handleOpen,
  } = useActiveState();
  const {
    active: showOptions,
    handleOpen: onMouseEnter,
    handleClose: onMouseLeave,
  } = useActiveState();
  const { data: reportConfig } = useReportConfig(reportId);
  const compQuery = useComp(reportId, compIdentifier);
  const compsPhotosQuery = useCompPhotos(reportId, compIdentifier);
  const { isError, data: comp } = compQuery;
  const { data: photos } = compsPhotosQuery;
  if (isError) {
    return <div data-hc-name={`${dataHcName}-error`}>An error occurred</div>;
  }
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const hasStreetView =
    !compsPhotosQuery.isInitialLoading && !!compsPhotosQuery.data?.streetView;
  const hasMlsPhotos =
    !compsPhotosQuery.isInitialLoading &&
    !!compsPhotosQuery.data?.listingPhotos?.length;
  return (
    <>
      <ThumbnailCarousel
        dataHcName={dataHcName}
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          if (hasMlsPhotos) {
            photosPageOpen({ type: 'comp', compIdentifier });
          } else if (
            reportFeaturesSupportedAny(reportConfig, [
              isRental
                ? ReportFeatures.RentalCompsStreetview
                : ReportFeatures.CompsStreetview,
            ]) &&
            hasStreetView
          ) {
            handleOpen();
          }
        }}
        photos={
          photos?.allPhotos.length
            ? photos.allPhotos
            : photos?.mapTile
              ? [photos.mapTile]
              : []
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[
            isRental
              ? ReportFeatures.RentalCompsStreetview
              : ReportFeatures.CompsStreetview,
          ]}
        >
          {showStreetViewIcon !== false && (
            <StreetViewLauncher
              location={comp?.compSchema.propertyState.location}
              dataHcName={`${dataHcName}-street-view-launcher`}
              dataHcEventSection={
                isRental
                  ? dataHcEventSectionRentalCompStreetView
                  : dataHcEventSectionCompStreetView
              }
              options={{
                publicLinkUid:
                  typeof reportId === 'string' ? reportId : undefined,
              }}
              theme={{
                StreetViewLauncherButton: classNames(
                  styles.StreetViewLauncherButton,
                  { [styles.hide]: !showOptions },
                ),
              }}
              onToggle={onStreetViewToggle}
            />
          )}
        </ReportFeaturesSupported>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={[ReportFeatures.Tags]}
        >
          {comp?.documentId &&
            !isOptimisticDocumentId(comp.documentId) &&
            !isRental && (
              <CompTags
                overlay={'transparent'}
                reportId={reportId}
                documentId={comp.documentId}
                className={classNames(styles.CompTags, {
                  [styles.hide]: !showOptions,
                })}
              />
            )}
        </ReportFeaturesSupported>
      </ThumbnailCarousel>
      {hasStreetView && !hasMlsPhotos && (
        <StreetViewDialog
          active={streetViewActive}
          onClose={handleClose}
          location={compQuery.data?.compSchema.propertyState.location}
          dataHcName={`${dataHcName}-street-view-dialog`}
          dataHcEventSection={
            isRental
              ? dataHcEventSectionRentalCompStreetView
              : dataHcEventSectionCompStreetView
          }
        />
      )}
    </>
  );
};
