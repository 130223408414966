import { useEffect } from 'react';
import debounce from 'lodash/debounce';

import { DomInput } from '@hcs/types';

// For observing size changes on an HTML element
// callback is called when the ref element is resized
export const useResizeObserver = <T extends HTMLElement>(
  { ref, element }: DomInput<T>,
  // Consuming components should use the useCallback hook
  // to prevent creating a new observer on each render
  callback: (v: ResizeObserverEntry) => void,
) => {
  const domElement = element || ref?.current;
  useEffect(() => {
    const observer = new ResizeObserver(
      debounce((entries) => {
        entries.forEach((entry: ResizeObserverEntry) => {
          callback(entry);
        });
      }, 200),
    );
    if (domElement) {
      observer.observe(domElement);
    }
    return () => {
      observer.disconnect();
    };
  }, [callback, domElement]);
};
