import {
  CompValueHcSuggestedDocument,
  DocumentRoles,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useComparableValueHcSuggestedDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<CompValueHcSuggestedDocument>(
    reportId,
    DocumentRoles.CompValueHcSuggested,
  );
};
