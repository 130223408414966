import React, { MouseEventHandler } from 'react';

import { useCheckAgileProductZuoraSubscription } from '@hcs/auth';
import { AgileCard, AgileInsightsIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AgileProductGroupings } from '@hcs/types';

import { AGILE_SUITE_CONFIG_AGILE_INSIGHTS } from '../../constants';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

const dataHcName = 'agile-insights-card';
export const AgileInsightsProductCard = () => {
  const { data: hasAccess } = useCheckAgileProductZuoraSubscription(
    AgileProductGroupings.AgileInsights,
  );
  const { active, handleOpen, handleClose } = useActiveState();
  const handleClick: MouseEventHandler = (e) => {
    if (!hasAccess) {
      e.stopPropagation();
      return handleOpen();
    }
  };
  return (
    <>
      <AgileCard
        dataHcName={dataHcName}
        productName={AgileProductGroupings.AgileInsights}
        subheading="Formerly Static Value Report"
        productDescription="Pre-appraisal assessment tool with AVM"
        hasAccess={hasAccess}
        Icon={AgileInsightsIcon}
        onClick={handleClick}
      />
      <ProductPreviewDialog
        appConfigExtended={{
          hasAccess,
          appConfig: AGILE_SUITE_CONFIG_AGILE_INSIGHTS,
        }}
        active={active}
        onClose={handleClose}
      />
    </>
  );
};
