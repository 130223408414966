import { ReactNode, useRef } from 'react';

import { TermsAndPrivacyLinks } from '@hcs/authn';
import { Card } from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';
import { SelfServeOrgInfo, SelfServePlan } from '@hcs/types';

import { SelfServicePlanCompare } from '../SelfServicePlanCompare/SelfServicePlanCompare';
import {
  SelfServicePlanSelector,
  SelfServicePlanSelectorProps,
} from '../SelfServicePlanSelector/SelfServicePlanSelector';

import styles from './SelfServicePlanSelectAndCompare.module.css';
const dataHcName = 'plan-select-and-compare';

interface SignUpPlanSelectionProps {
  title?: ReactNode;
  trials?: boolean;
  orgSsInfo?: SelfServeOrgInfo;
  hideCtas?: boolean;
  recommendedPlan?: SelfServePlan;
  onSelectPlan: SelfServicePlanSelectorProps['onSelectPlan'];
}

export const SelfServicePlanSelectAndCompare = ({
  title,
  trials,
  orgSsInfo,
  hideCtas,
  recommendedPlan,
  onSelectPlan,
}: SignUpPlanSelectionProps) => {
  const comparePlansRef = useRef<HTMLDivElement>(null);
  const { isMobileWidth } = useScreenSize();
  return (
    <div className={styles.Content}>
      <SelfServicePlanSelector
        title={title}
        trials={trials}
        hideCtas={hideCtas}
        onSelectPlan={onSelectPlan}
        orgSsInfo={orgSsInfo}
        recommendedPlan={recommendedPlan}
      />
      <div>
        <section className={styles.CompareSection}>
          <Card
            dataHcName={`${dataHcName}-compare-card`}
            smallPadding={isMobileWidth}
            title={
              <div
                ref={comparePlansRef}
                className={styles.CompareTitle}
                data-hc-name="compare-plan-features"
                onClick={() =>
                  comparePlansRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }
              >
                Compare plans and features
              </div>
            }
          >
            <SelfServicePlanCompare
              currentPlanId={orgSsInfo?.self_serve_plan?.id}
            />
          </Card>
        </section>
      </div>
      <TermsAndPrivacyLinks />
    </div>
  );
};
