import React, { useMemo } from 'react';

import { CardBadge } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { PropertyCard, PropertyCardProps } from '@hcs/property-state';
import {
  CompFields,
  CompTypes,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';

import { PropertyCard as PropertyCardDeprecated } from '../../components-deprecated';
import { useComp, useCompCompareDialogSlice } from '../../hooks';
import { useCompKeywordSearchMatches } from '../../hooks';
import {
  CompPhotoCarousel,
  CompPhotoCarouselProps,
} from '../CompPhotoCarousel';
import { CompSelectButton } from '../CompSelectButton';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import { CompPropertyCardContent } from '.';

const dataHcName = 'comp-property-card';
interface Props
  extends Omit<
    PropertyCardProps,
    'dataHcName' | 'photos' | 'propertyStateArgs'
  > {
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  // Renders in the photos prop of PropertyCard
  photos?: React.ReactNode;
  // Renders in the content prop of PropertyCard
  content?: React.ReactNode;
  showPrice?: boolean;
  clickToCompare?: boolean;
  noHighlight?: boolean;
  onStreetViewToggle?: CompPhotoCarouselProps['onStreetViewToggle'];
}

// Entrypoint component that determines how data should be loaded.
// Selected Comps have individual documents, Unselected are schemas on the farm list
export const CompPropertyCard = ({
  reportId,
  compIdentifier,
  clickToCompare = true,
  noHighlight,
  content,
  photos,
  showPrice,
  badges,
  onClick,
  onStreetViewToggle,
  ...propertyCardProps
}: Props) => {
  const fields = useMemo(
    () =>
      compIdentifier.compType === CompTypes.Rental
        ? [
            PropertyStateFields.currentPriceRental,
            PropertyStateFields.currentStatusDateRental,
            [
              PropertyStateFields.bedrooms,
              PropertyStateFields.bathrooms,
              PropertyStateFields.livingArea,
            ],
            PropertyStateFields.currentStatusRental,
            [CompFields.similarity, CompFields.distance],
          ]
        : [
            PropertyStateFields.currentPrice,
            PropertyStateFields.currentStatusDate,
            [
              PropertyStateFields.bedrooms,
              PropertyStateFields.bathrooms,
              PropertyStateFields.livingArea,
            ],
            PropertyStateFields.currentStatus,
            [CompFields.similarity, CompFields.distance],
          ],
    [compIdentifier.compType],
  );
  const {
    actions: { compCompareDialogOpen },
  } = useCompCompareDialogSlice();
  const {
    isInitialLoading,
    isError,
    data: comp,
  } = useComp(reportId, compIdentifier);
  const {
    data: { keywordMatchCompSchemas },
  } = useCompKeywordSearchMatches({
    reportId,
    compType: compIdentifier.compType,
  });
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  if (isError || !comp?.compSchema) {
    return <div data-hc-name={`${dataHcName}-error`}>An error occurred</div>;
  }
  const handleClick = () => {
    if (clickToCompare) {
      compCompareDialogOpen(compIdentifier);
    }
    onClick?.();
  };
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <PropertyCard
          {...propertyCardProps}
          badges={
            badges || (
              <>
                {comp.isHcSuggestedComp && (
                  <CardBadge dataHcName={`${dataHcName}-comp-chip`} primary>
                    HC Comp
                  </CardBadge>
                )}
                {comp.isAppraisalComp && (
                  <CardBadge dataHcName={`${dataHcName}-comp-chip`} secondary>
                    Appraisal
                  </CardBadge>
                )}
              </>
            )
          }
          onClick={handleClick}
          comp={comp.compSchema}
          fields={fields}
          dataHcName={dataHcName}
          highlighted={
            noHighlight !== undefined
              ? noHighlight
                ? false
                : !!keywordMatchCompSchemas[comp.compSchema.compID]
              : !!keywordMatchCompSchemas[comp.compSchema.compID]
          }
          photos={
            <>
              <CompPhotoCarousel
                reportId={reportId}
                compIdentifier={compIdentifier}
                onStreetViewToggle={onStreetViewToggle}
              />
              {photos}
            </>
          }
          propertyStateArgs={{
            propertyStateType: PropertyStateType.Core,
            propertyState: comp.compSchema.propertyState,
          }}
          isRental={compIdentifier.compType === CompTypes.Rental}
          showBrokerageCredit
          floatingActionButton={
            compIdentifier.type !== 'appraisalComp' ? (
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[
                  compIdentifier.compType === CompTypes.Rental
                    ? ReportFeatures.RentalCompsSelect
                    : ReportFeatures.CompsSelect,
                ]}
              >
                <CompSelectButton
                  reportId={reportId}
                  compIdentifier={compIdentifier}
                  onClick={propertyCardProps.onMouseLeave}
                  hoverable
                />
              </ReportFeaturesSupported>
            ) : undefined
          }
        />
      }
      now={
        <PropertyCardDeprecated
          dataHcName={dataHcName}
          className={propertyCardProps.className}
          onClick={handleClick}
          onMouseEnter={propertyCardProps.onMouseEnter}
          onMouseLeave={propertyCardProps.onMouseLeave}
          photos={
            <>
              <CompPhotoCarousel
                reportId={reportId}
                compIdentifier={compIdentifier}
              />
              {photos}
            </>
          }
          content={
            <>
              <CompPropertyCardContent
                reportId={reportId}
                compIdentifier={compIdentifier}
                showPrice={showPrice}
                flat={propertyCardProps.flat}
              />
              {content}
            </>
          }
        />
      }
    />
  );
};
