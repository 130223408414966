import { MarketGrade } from '@hcs/types';
import {
  CerberusStats,
  CerberusStatsFields,
  CerberusStatsMsa,
  CerberusStatsZip,
} from '@hcs/types';
import { CerberusStatsFieldFilters } from '@hcs/types';
import { RangeValueOrNull } from '@hcs/types';
import { SORT_ORDER } from '@hcs/types';
import { getMaxFromRangeValue, getMinFromRangeValue } from '@hcs/utils';
import { sortFn } from '@hcs/utils';

import { getCerberusStatsFieldValue } from '../utils/cerberusStatsFields.utils';

const displayNameSearchFilterCheck = (
  cerberusStats: CerberusStats,
  search?: string,
): boolean => {
  if (!search) {
    return true;
  }
  const lowerCaseSearch = search.toLocaleLowerCase();
  const displayName = getCerberusStatsFieldValue(
    CerberusStatsFields.displayName,
    cerberusStats,
  );
  const lowerCaseDisplayName = displayName?.toLocaleLowerCase();
  if (lowerCaseDisplayName?.includes(lowerCaseSearch)) {
    return true;
  }
  return false;
};

const statsRangeFilterCheck = ({
  rangeFilterValue,
  statsFieldValue,
}: {
  rangeFilterValue?: RangeValueOrNull;
  statsFieldValue: number | undefined | null;
}): boolean => {
  if (rangeFilterValue == null) {
    return true;
  }
  if (statsFieldValue == null) {
    return false;
  }
  const rangeMin = getMinFromRangeValue(rangeFilterValue);
  // if rangeMin is null, the check should pass
  const minPassed = rangeMin !== null ? statsFieldValue >= rangeMin : true;
  const rangeMax = getMaxFromRangeValue(rangeFilterValue);
  const maxPassed = rangeMax !== null ? statsFieldValue <= rangeMax : true;
  return minPassed && maxPassed;
};

const marketGradeFilterCheck = ({
  marketGradeFilterValue,
  statsFieldValue,
}: {
  marketGradeFilterValue?: (MarketGrade | null)[] | null;
  statsFieldValue?: MarketGrade | null;
}): boolean => {
  if (marketGradeFilterValue == null) {
    return true;
  }
  if (statsFieldValue == null && marketGradeFilterValue.includes(null)) {
    return true;
  }
  if (statsFieldValue == null) {
    return false;
  }
  return marketGradeFilterValue.includes(statsFieldValue);
};

type ListItemType = CerberusStatsMsa | CerberusStatsZip;
export interface FilterListConfig<LIT extends ListItemType> {
  list?: LIT[] | null;
  filterSetIsLoading: boolean;
  fieldFilters?: CerberusStatsFieldFilters | null;
  search?: string | null;
  interestedOnly?: boolean | null;
  interestedInMap?: Record<string, true> | undefined;
}
export const filterList = <LIT extends ListItemType>({
  list,
  filterSetIsLoading,
  fieldFilters,
  search,
  interestedOnly,
  interestedInMap,
}: FilterListConfig<LIT>): LIT[] => {
  if (!list) {
    return [];
  }
  if (filterSetIsLoading) {
    return list;
  }

  let result = list;

  if (interestedOnly && interestedInMap) {
    result = result.filter((cerberusStatsItem) => {
      return interestedInMap[cerberusStatsItem.id];
    });
  }

  if (search) {
    result = result.filter((cerberusStatsItem) => {
      const passesSearchCheck = displayNameSearchFilterCheck(
        cerberusStatsItem,
        search,
      );
      return passesSearchCheck;
    });
  }

  if (fieldFilters) {
    result = result.filter((cerberusStatsItem) => {
      const fieldFilterChecks: boolean[] = [];

      let field: keyof CerberusStatsFieldFilters;
      for (field in fieldFilters) {
        if (field === CerberusStatsFields.marketGrade) {
          fieldFilterChecks.push(
            marketGradeFilterCheck({
              marketGradeFilterValue: fieldFilters[field],
              statsFieldValue: getCerberusStatsFieldValue(
                field,
                cerberusStatsItem,
              ),
            }),
          );
        } else {
          fieldFilterChecks.push(
            statsRangeFilterCheck({
              rangeFilterValue: fieldFilters[field],
              statsFieldValue: getCerberusStatsFieldValue(
                field,
                cerberusStatsItem,
              ),
            }),
          );
        }
      }
      // did any checks fail?
      return !fieldFilterChecks.includes(false);
    });
  }

  return result;
};

enum DEPRECATED_SORT_FIELDS {
  interested = 'interested',
}

interface SortListConfig<LIT extends ListItemType> {
  list: LIT[];
  sortField?: CerberusStatsFields | DEPRECATED_SORT_FIELDS | null;
  sortOrder?: SORT_ORDER | null;
  filterSetIsLoading: boolean;
}
export const sortList = <LIT extends ListItemType>({
  list,
  sortField,
  sortOrder,
  filterSetIsLoading,
}: SortListConfig<LIT>): LIT[] => {
  if (
    filterSetIsLoading ||
    !sortField ||
    !sortOrder ||
    !list ||
    sortField === DEPRECATED_SORT_FIELDS.interested
  ) {
    return list;
  }
  // Array.sort sorts in place, so make a copy to make sure we aren't modifying the passed in list
  const result = [...list];
  const sortedStats = result.sort((a, b) => {
    const aField = getCerberusStatsFieldValue(sortField, a);
    const bField = getCerberusStatsFieldValue(sortField, b);

    return sortFn(aField, bField, sortOrder);
  });

  return sortedStats;
};
