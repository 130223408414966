import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';

import { hcsConsole } from '@hcs/console';
import {
  OmOrder,
  OrderItem,
  OrderManagerWebsocketMessage,
  OrderSets,
  OrderStatus,
  PaginatedResponseData,
} from '@hcs/types';
import { getDebugTime } from '@hcs/utils';

import { OrderManagerApi, ViewOrderApi } from '../api';
import { ORDER_MANAGER_WEB_SOCKET_URL } from '../constants';
import { ORDER_SET_TO_STATUSES } from '../constants';
import {
  OrdersQueryKey,
  QUERY_KEY_ORDER,
  QUERY_KEY_ORDER_ITEMS,
  QUERY_KEY_ORDERS,
} from '../hooks';

export const orderStatusBelongsInOrderSet = (
  orderStatus: OrderStatus,
): OrderSets => {
  if (ORDER_SET_TO_STATUSES[OrderSets.active]?.indexOf(orderStatus) !== -1) {
    return OrderSets.active;
  } else if (
    ORDER_SET_TO_STATUSES[OrderSets.completed]?.indexOf(orderStatus) !== -1
  ) {
    return OrderSets.completed;
  } else if (
    ORDER_SET_TO_STATUSES[OrderSets.cancelled]?.indexOf(orderStatus) !== -1
  ) {
    return OrderSets.cancelled;
  }
  return OrderSets.all;
};

const createMessageId = (message: OrderManagerWebsocketMessage) => {
  if (message.stream === 'order') {
    return `${message.payload.orderId}-${message.payload.action}-${message.payload.data.updatedAt}`;
  }
  return undefined;
};

// Keep track of messages received. OM sends duplicate messages and we only want to call the callback once
const ORDER_MESSAGES_RECEIVED: Record<string, true> = {};

export const OrderUpdatesContext = createContext(null);

export const OrderUpdatesProvider = ({ children }: { children: ReactNode }) => {
  const websocket = useRef<WebSocket | null>(null);
  const queryClient = useQueryClient();
  const ordersToReload = useRef(new Set<number>());
  // map of order id to Set of item ids
  const orderItemsToReload = useRef(new Map<number, Set<number>>());
  const ordersShouldInvalidate = useRef(false);
  const orderItemsShouldInvalidate = useRef(new Map<number, boolean>());

  // this token isn't needed outside of the context
  const tokenQuery = useQuery(
    ['ORDER_MANAGER_WEBSOCKET_TOKEN'],
    OrderManagerApi.websocketTokenExchange,
  );
  const token = tokenQuery.data?.token;

  // assumption is made that there's only one query that contains the order list
  const findInOrdersCache = useCallback(
    (
      orderId: number,
    ): {
      foundInOrdersCacheQk: OrdersQueryKey;
      foundInOrdersCachedData: PaginatedResponseData<OmOrder[]>;
    } | null => {
      let foundInOrdersCacheQk: QueryKey | null = null;
      let foundInOrdersCachedData: PaginatedResponseData<OmOrder[]> | null =
        null;
      const queryCache = queryClient.getQueriesData<
        PaginatedResponseData<OmOrder[]>
      >([QUERY_KEY_ORDERS]);
      queryCache.forEach(([cacheQk, cacheData]) => {
        if (cacheData) {
          const cachedOrder = cacheData?.data.find((order) => {
            return order.id === orderId;
          });
          if (cachedOrder) {
            foundInOrdersCacheQk = cacheQk;
            foundInOrdersCachedData = cacheData;
          }
        } else {
          hcsConsole.logVerbose('OM-API: WS | no orders in cache');
        }
      });
      if (foundInOrdersCacheQk && foundInOrdersCachedData) {
        return {
          foundInOrdersCacheQk,
          foundInOrdersCachedData,
        };
      }
      return null;
    },
    [queryClient],
  );

  const findInOrderCache = useCallback(
    (
      orderId: number,
    ): {
      foundInOrderCacheQk: OrdersQueryKey;
      foundInOrderCachedData: OmOrder;
    } | null => {
      let foundInOrderCacheQk: QueryKey | null = null;
      let foundInOrderCachedData: OmOrder | null = null;
      const queryCache = queryClient.getQueriesData<OmOrder>([QUERY_KEY_ORDER]);
      queryCache.forEach(([cacheQk, cacheData]) => {
        if (cacheData && cacheData.id === orderId) {
          foundInOrderCacheQk = cacheQk;
          foundInOrderCachedData = cacheData;
        }
      });
      if (foundInOrderCacheQk && foundInOrderCachedData) {
        return {
          foundInOrderCacheQk,
          foundInOrderCachedData,
        };
      }
      return null;
    },
    [queryClient],
  );

  const addOrderToReload = useCallback((orderId: number) => {
    ordersToReload.current.add(orderId);
  }, []);

  const addOrderItemToReload = useCallback(
    (orderId: number, orderItemId: number) => {
      const orderItemSet = orderItemsToReload.current.get(orderId);
      if (orderItemSet) {
        orderItemSet.add(orderItemId);
      } else {
        orderItemsToReload.current.set(orderId, new Set([orderItemId]));
      }
    },
    [],
  );

  const invalidateOrdersAfterLoad = useCallback(() => {
    ordersShouldInvalidate.current = true;
  }, []);

  const fetchUpdatedOrders = useCallback(
    async (orderIds: number[]) => {
      const updatedOrders = await OrderManagerApi.fetchOrderIds(orderIds);
      const updatedOrdersById = updatedOrders.reduce<{ [id: number]: OmOrder }>(
        (accum, updatedOrder: OmOrder) => {
          accum[updatedOrder.id] = updatedOrder;
          return accum;
        },
        {},
      );

      // update orders list in place after fetch
      const queryCacheOrders = queryClient.getQueriesData<
        PaginatedResponseData<OmOrder[]>
      >([QUERY_KEY_ORDERS]);
      queryCacheOrders.forEach(([cacheQk, cacheData]) => {
        if (cacheData) {
          let hasUpdatedOrder = false;
          const newOrderList = cacheData?.data.map((order) => {
            const updatedOrder = updatedOrdersById[order.id];
            if (updatedOrder) {
              hasUpdatedOrder = true;
              return updatedOrder;
            } else {
              return order;
            }
          });
          if (hasUpdatedOrder) {
            hcsConsole.logVerbose(
              'OM-API: WS | update orders list in place after fetch',
              getDebugTime(),
            );
            queryClient.setQueryData<PaginatedResponseData<OmOrder[]>>(
              cacheQk,
              (existingData) => {
                if (!existingData) {
                  return existingData;
                }
                return {
                  pagination: existingData?.pagination,
                  data: newOrderList,
                };
              },
            );
          }
        }
      });

      const queryCacheOrder = queryClient.getQueriesData<OmOrder>([
        QUERY_KEY_ORDER,
      ]);
      queryCacheOrder.forEach(([cacheQk, cacheData]) => {
        if (cacheData) {
          const updatedOrder = updatedOrdersById[cacheData.id];
          if (updatedOrder) {
            hcsConsole.logVerbose(
              'OM-API: WS | update order in place after fetch',
              getDebugTime(),
            );
            queryClient.setQueryData<OmOrder>(cacheQk, updatedOrder);
          }
        }
      });
    },
    [queryClient],
  );

  const fetchUpdatedOrderItems = useCallback(
    async (orderId: number, itemIds: number[]) => {
      const updatedItems = await ViewOrderApi.fetchOrderItemIds(
        orderId,
        itemIds,
      );
      const updatedItemsById = updatedItems.reduce<{
        [id: number]: OrderItem;
      }>((accum, updatedItem: OrderItem) => {
        accum[updatedItem.id] = updatedItem;
        return accum;
      }, {});

      hcsConsole.logVerbose('OM-API: WS | updatedItemsById', updatedItemsById);

      const queryCache = queryClient.getQueriesData<
        PaginatedResponseData<OrderItem[]>
      >([QUERY_KEY_ORDER_ITEMS]);
      queryCache.forEach(([cacheQk, cacheData]) => {
        if (cacheData) {
          let hasUpdatedItem = false;
          hcsConsole.logVerbose(
            'OM-API: WS | order items cacheData.data',
            cacheData.data,
          );
          const newItemList = cacheData?.data.map((orderItem) => {
            const updatedItem = updatedItemsById[orderItem.id];
            if (updatedItem) {
              hasUpdatedItem = true;
              hcsConsole.logVerbose(
                'OM-API: WS | updating order item in place',
                updatedItem,
              );
              return updatedItem;
            } else {
              return orderItem;
            }
          });
          if (hasUpdatedItem) {
            queryClient.setQueryData<PaginatedResponseData<OrderItem[]>>(
              cacheQk,
              (existingData) => {
                if (!existingData) {
                  return existingData;
                }
                return {
                  pagination: existingData.pagination,
                  data: newItemList,
                };
              },
            );
          }
        } else {
          hcsConsole.logVerbose(
            'OM-API: WS | no order items cached data found',
            getDebugTime(),
          );
        }
      });
    },
    [queryClient],
  );

  useEffect(() => {
    const fetchOrdersAndItemsInteval = setInterval(() => {
      if (ordersToReload.current.size > 0) {
        hcsConsole.logVerbose(
          'OM-API: WS | ordersToReload',
          Array.from(ordersToReload.current),
        );
        fetchUpdatedOrders(Array.from(ordersToReload.current));
        ordersToReload.current.clear();
      }
      if (orderItemsToReload.current.size > 0) {
        orderItemsToReload.current.forEach((itemIds, orderId) => {
          hcsConsole.logVerbose('OM-API: WS | orderItemsToReload', itemIds);
          fetchUpdatedOrderItems(orderId, Array.from(itemIds));
        });
        orderItemsToReload.current.clear();
      }
      if (ordersShouldInvalidate.current) {
        const ordersQueryState = queryClient.getQueryState([QUERY_KEY_ORDERS], {
          exact: false,
        });
        hcsConsole.logVerbose(
          'OM-API: WS | ordersQueryState status',
          ordersQueryState?.status,
          getDebugTime(),
        );
        if (ordersQueryState?.status === 'success') {
          hcsConsole.logVerbose(
            'OM-API: WS | ws invalidate orders',
            getDebugTime(),
          );
          queryClient.invalidateQueries([QUERY_KEY_ORDERS]);
          ordersShouldInvalidate.current = false;
        }
      }
      orderItemsShouldInvalidate.current.forEach(
        (shouldInvalidate, orderId) => {
          if (shouldInvalidate) {
            const orderItemsQueryState = queryClient.getQueryState(
              [QUERY_KEY_ORDER_ITEMS, orderId],
              { exact: false },
            );
            hcsConsole.logVerbose(
              'OM-API: WS | orderItemsQueryState status',
              orderItemsQueryState?.status,
            );
            if (orderItemsQueryState?.status === 'success') {
              hcsConsole.logVerbose(
                'OM-API: WS | ws invalidate order items',
                getDebugTime(),
              );
              queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS, orderId]);
              orderItemsShouldInvalidate.current.delete(orderId);
            }
          }
        },
      );
    }, 5000);

    return () => {
      clearInterval(fetchOrdersAndItemsInteval);
    };
  }, [fetchUpdatedOrders, fetchUpdatedOrderItems, queryClient]);

  const createWebsocketConnection = useCallback(
    (token: string) => {
      websocket.current = new WebSocket(
        `${ORDER_MANAGER_WEB_SOCKET_URL}?token=${token}`,
      );
      websocket.current.onopen = () => {
        hcsConsole.log('OM-Api: WS | connected');
      };
      websocket.current.onmessage = (event: MessageEvent<string>) => {
        const message = camelcaseKeys<OrderManagerWebsocketMessage>(
          JSON.parse(event.data),
          {
            deep: true,
          },
        );
        if (message.stream !== 'debug') {
          hcsConsole.logVerbose('OM-API: WS |', message);
          const messageId = createMessageId(message);
          if (
            message.stream !== 'order' ||
            (messageId && !ORDER_MESSAGES_RECEIVED[messageId])
          ) {
            if (messageId) {
              // OM sends duplicate messages for the order stream. Only call the callback once.
              ORDER_MESSAGES_RECEIVED[messageId] = true;
            }
            if (message.stream === 'order') {
              const { action, orderId, data } = message.payload;
              // assuming the create order mutations will invalidate orders on success, so we only care about updates
              if (action === 'updated') {
                // logic for orders list
                const { foundInOrdersCacheQk, foundInOrdersCachedData } =
                  findInOrdersCache(orderId) || {};
                if (foundInOrdersCacheQk && foundInOrdersCachedData) {
                  hcsConsole.logVerbose(
                    'OM-API: WS | update orders cache right away',
                    getDebugTime(),
                  );
                  queryClient.setQueryData<PaginatedResponseData<OmOrder[]>>(
                    foundInOrdersCacheQk,
                    (existingData) => {
                      if (!existingData) {
                        return existingData;
                      }
                      const newData: PaginatedResponseData<OmOrder[]> = {
                        pagination: existingData.pagination,
                        data: foundInOrdersCachedData.data.map((order) => {
                          if (order.id === orderId) {
                            hcsConsole.logVerbose(
                              'OM-API: WS | found order in orders cache to update',
                            );
                            return {
                              ...order,
                              status: data.status,
                              cancelled: data.cancelled,
                            };
                          }
                          return order;
                        }),
                      };
                      return newData;
                    },
                  );
                }

                const belongsInOrderSet = orderStatusBelongsInOrderSet(
                  data.status,
                );
                // by default we choose all orders
                const selectedOrderSet =
                  foundInOrdersCacheQk?.[1]?.status || OrderSets.all;
                // if the order belongs on the page, update all orders to get the full order update or to get the order on the page (if it isn't yet)
                if (
                  selectedOrderSet === OrderSets.all ||
                  belongsInOrderSet === selectedOrderSet
                ) {
                  // if the updated order is currently on this page
                  if (foundInOrdersCacheQk) {
                    hcsConsole.logVerbose(
                      `OM-API: WS | found in orders cache, reloading single order ${orderId}`,
                      getDebugTime(),
                    );
                    addOrderToReload(orderId);
                    // if the updated order isn't on this page, but should be
                  } else {
                    // reload with orders
                    hcsConsole.logVerbose(
                      'OM-API: WS | not found in orders cache but it should be here, invalidating',
                      getDebugTime(),
                    );
                    invalidateOrdersAfterLoad();
                  }
                  // if the order no longer belongs on the page, but is on the page
                } else if (foundInOrdersCacheQk) {
                  hcsConsole.logVerbose(
                    'OM-API: WS | found in orders cache, but should not be here, invalidating',
                    getDebugTime(),
                  );
                  // reload the orders list to remove the order from the page
                  invalidateOrdersAfterLoad();
                }

                // logic for order detail
                const { foundInOrderCacheQk } = findInOrderCache(orderId) || {};
                if (foundInOrderCacheQk) {
                  hcsConsole.logVerbose(
                    'OM-API: WS | update ORDER cache right away',
                  );
                  queryClient.setQueryData<OmOrder>(
                    foundInOrderCacheQk,
                    (existingData) => {
                      if (!existingData) {
                        return existingData;
                      }
                      return {
                        ...existingData,
                        status: data.status,
                        cancelled: data.cancelled,
                      };
                    },
                  );
                  hcsConsole.logVerbose(
                    `OM-API: WS | found in ORDER cache, reload order ${orderId}`,
                  );
                  // request full reload of order
                  addOrderToReload(orderId);
                }
              }
            } else if (message.stream === 'orderitem') {
              const { orderId, action } = message.payload;
              if (action === 'updated') {
                hcsConsole.logVerbose(
                  'OM-API: WS | orderitem ws message',
                  message,
                );
                // if the order is in the list cache, reload it to get updated item counts
                const { foundInOrdersCacheQk } =
                  findInOrdersCache(orderId) || {};
                if (foundInOrdersCacheQk) {
                  hcsConsole.logVerbose(
                    `OM-API: WS | orderitem ws, reload order ${orderId}`,
                  );
                  addOrderToReload(orderId);
                }

                // if item is in cache, update immediately, then request full reload
                const { id, status, cancelled } = message.payload.data;
                const messageOrderItemId = Number(id);
                const queryCache = queryClient.getQueriesData<
                  PaginatedResponseData<OrderItem[]>
                >([QUERY_KEY_ORDER_ITEMS]);
                queryCache.forEach(([cacheQk, cacheData]) => {
                  if (cacheData) {
                    const cachedItem = cacheData?.data.find((item) => {
                      return item.id === messageOrderItemId;
                    });
                    if (cachedItem) {
                      hcsConsole.logVerbose(
                        'OM-API: WS | updating order item right away',
                      );
                      queryClient.setQueryData<
                        PaginatedResponseData<OrderItem[]>
                      >(cacheQk, (existingData) => {
                        if (!existingData) {
                          return existingData;
                        }
                        return {
                          pagination: existingData.pagination,
                          data: cacheData.data.map((item) => {
                            if (item.id === messageOrderItemId) {
                              return {
                                ...item,
                                status,
                                cancelled,
                              };
                            }
                            return item;
                          }),
                        };
                      });
                      hcsConsole.logVerbose(
                        'OM-API: WS | add order item to reload',
                      );
                      addOrderItemToReload(orderId, messageOrderItemId);
                    }
                  }
                });
              }
            }
          }
        }
      };
      websocket.current.onerror = async (event) => {
        // 401 handled in the websocket token exchange
        hcsConsole.log(`OM-Api: WS | Error ${JSON.stringify(event)}`);
      };
    },
    [
      addOrderToReload,
      addOrderItemToReload,
      queryClient,
      invalidateOrdersAfterLoad,
      findInOrdersCache,
      findInOrderCache,
    ],
  );

  // Open and close connection
  useEffect(() => {
    // Do not attempt to connect if there isn't a token
    // Create the websocket connection if it does not exist
    if (token && !websocket.current) {
      createWebsocketConnection(token);
    }
    return () => {
      hcsConsole.log('OM-Api: disconnected');
      websocket.current?.close();
      websocket.current = null;
    };
  }, [createWebsocketConnection, token]);

  return (
    <OrderUpdatesContext.Provider value={null}>
      {children}
    </OrderUpdatesContext.Provider>
  );
};
