import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  useGoogleTagManagerEvents,
  useTrackEngagementEvent,
} from '@hcs/engagement-tracking';
import { ACCOUNT_QUERY_KEY, fetchAccount } from '@hcs/http-clients';
import { QUERY_KEY_ORG_SELF_SERVE_INFO } from '@hcs/self-serve-info';
import { Account, RegisterRequest, SelfServePlan } from '@hcs/types';
import { logException } from '@hcs/utils';

import { SelfServiceApi } from '../api';
type RegisterError = AxiosError<{
  status: string;
  message?: string;
  errors?: Partial<{
    accepted_terms: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }>;
}>;
export type SelfServiceRegisterVariables = RegisterRequest & {
  plan: SelfServePlan;
};
// Export hook-specific types here rather than in types file
export type SelfServiceRegisterProps = UseMutationOptions<
  Account,
  RegisterError,
  SelfServiceRegisterVariables,
  void
>;

const EVENT_NAME_SIGN_UP = 'user-sign-up-self-service';

export const useSelfServiceRegister = (options?: SelfServiceRegisterProps) => {
  const queryClient = useQueryClient();
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const { fireSignUp } = useGoogleTagManagerEvents();
  return useMutation<
    Account,
    RegisterError,
    SelfServiceRegisterVariables,
    void
  >(
    async ({ plan, ...payload }) => {
      const account = await SelfServiceApi.register(payload);
      return fetchAccount(account);
    },
    {
      ...options,
      onError(error, variables) {
        // 400 is usually password not meeting requirements or something that isn't a problem to address
        if (error.response?.status !== 400) {
          logException(
            new Error(`Sign Up failed: ${error.response?.data.status}`),
            {
              email: variables.email,
              response: error.response,
            },
          );
        }
        options?.onError?.(error, variables);
        const event_name = `${EVENT_NAME_SIGN_UP}-error`;
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          event_name: event_name,
          email: variables.email,
          event_data: {
            self_service_signup_error: error.response?.data.status,
          },
        });
      },
      onSuccess(data, variables) {
        // || empty string because email is required by the form but possibly undefined in the generated types
        fireSignUp(variables.email || '', variables.plan);
        options?.onSuccess?.(data, variables);
        queryClient.invalidateQueries([QUERY_KEY_ORG_SELF_SERVE_INFO]);
        queryClient.setQueryData([ACCOUNT_QUERY_KEY], data);
        // Track a distinct engagement event
        trackEngagementEventMutation.mutate({
          event_name: `${EVENT_NAME_SIGN_UP}-success`,
          auth_organization_id: data.organization.id,
          auth_organization_slug: data.organization.slug,
          auth_user_id: data.user.id,
          // Passing these fields directly since the useAccount hook in the scope where trackEngagementEventMutation
          // is defined won't have the updated account data yet
          email: variables.email,
        });
      },
    },
  );
};
