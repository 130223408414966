import React from 'react';
import classNames from 'classnames';

import { Toggle } from '@hcs/design-system';
import {
  useMapPreferencesForUser,
  usePatchUiPreferencesForUser,
} from '@hcs/huell';
import { ControlPosition, PreferencesKeys } from '@hcs/types';

import styles from './ClusterToggle.module.css';

interface Props {
  className?: string;
  position: ControlPosition;
}
const OPTIONS = [
  {
    label: 'Cluster On',
    value: true,
  },
  {
    label: 'Cluster Off',
    value: false,
  },
];
const dataHcName = 'cluster-toggle';
export const ClusterToggle = ({ className, position }: Props) => {
  const { data: mapPreferences } = useMapPreferencesForUser();
  const { mutate: patchPreferences } = usePatchUiPreferencesForUser();
  const clustersEnabled = !mapPreferences?.disableClusters;
  return (
    <Toggle
      secondary
      dataHcName={`${dataHcName}-cluster-toggle`}
      className={classNames(className, styles[position])}
      options={OPTIONS}
      value={clustersEnabled}
      onChange={(value) => {
        patchPreferences?.([
          {
            op: 'add',
            path: `/${PreferencesKeys.Maps}/disableClusters`,
            value: !value,
          },
        ]);
      }}
    />
  );
};
