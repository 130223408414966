import {
  CapabilitiesCheckParams,
  Capability,
  FeatureFlags,
  FeatureFlagsData,
} from '@hcs/types';
import { logException } from '@hcs/utils';

export const checkHasCapability = (
  capabilityKey: string,
  userCapabilities: Capability[],
  featureFlags: FeatureFlagsData | undefined,
) => {
  let hasCapability: boolean = userCapabilities.some(
    (capability) => capability.key === capabilityKey,
  );
  if (!hasCapability) {
    if (capabilityKey === 'appraisal-compare-report') {
      hasCapability = !!featureFlags?.[FeatureFlags.AppraisalComparisonReports];
    }

    /**
     * Handle Migration Case:
     * Offer Now - Feature Flag <> Capability
     */
    if (capabilityKey === 'offer-now') {
      hasCapability = !!featureFlags?.[FeatureFlags.OfferNowAvailable];
    }
    /**
     * Migration Case:
     * Effective Date Reports - Feature Flag <> Capability
     */
    if (capabilityKey === 'effective-date-dynamic-report') {
      hasCapability = !!featureFlags?.[FeatureFlags.EffectiveDateReports];
    }
  }
  return hasCapability;
};

export const checkHasCapabilities = (
  capabilitescheckParams: CapabilitiesCheckParams,
  userCapabilities: Capability[],
  featureFlags: FeatureFlagsData | undefined,
) => {
  const { capabilityKeys, operation } = capabilitescheckParams;
  const checks = capabilityKeys.map((capabilityKey) =>
    checkHasCapability(capabilityKey, userCapabilities, featureFlags),
  );
  if (operation === 'AND') {
    return checks.every(Boolean);
  } else if (operation === 'OR') {
    return checks.some(Boolean);
  } else {
    logException(
      'useCapabilitiesCheckForUser called with unknown operation' + operation,
    );
  }
};
