import React, { ReactNode, useMemo } from 'react';

import {
  Anchor,
  Dialog,
  PhotoPageGroup,
  PhotosOverlayPage,
  PhotosPageProps,
  StreetViewIcon,
  TabOption,
} from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { StreetViewDialog } from '@hcs/street-view';
import { PropertyStateArgsProps, PropertyStateImagesResult } from '@hcs/types';
import { ListingIdentifier } from '@hcs/types';
import {
  formatDateShort,
  formatMissing,
  formatMoney,
  formatMoneyPerMonth,
  formatStreetAddress,
} from '@hcs/utils';

import { ListingStatusIndicator } from '../../components/ListingStatusIndicator';
import { PropertySummaryHeader } from '../../components/PropertySummaryHeader';

import styles from './PropertyPhotoOverlayPage.module.css';

export const buildListingPhotoGroupId = ({
  listingId,
  hcMlsId,
}: ListingIdentifier) => `${hcMlsId}-${listingId}`;

const dataHcName = 'property-photo-overlay-page';
export interface PropertyPhotoOverlayPageProps extends PropertyStateArgsProps {
  active: boolean;
  dataHcEventSection?: string;
  dataHcEventSectionStreetView?: string;
  isLoading?: boolean;
  className?: string;
  isError?: boolean;
  listingIdentifier?: ListingIdentifier;
  price?: {
    label: ReactNode;
    value: number | null | undefined;
  };
  propertyPhotosResult: PropertyStateImagesResult | undefined;
  onClose: VoidFunction;
  photoGridSize?: PhotosPageProps['photoGridSize'];
  onChangeSize?: PhotosPageProps['onChangeSize'];
}
export const PropertyPhotoOverlayPage = ({
  active,
  isLoading,
  price,
  dataHcEventSection,
  dataHcEventSectionStreetView,
  className,
  listingIdentifier,
  propertyPhotosResult,
  photoGridSize,
  propertyStateArgs,
  onClose,
  onChangeSize,
}: PropertyPhotoOverlayPageProps) => {
  const { propertyState } = propertyStateArgs || {};
  const {
    active: streetViewActive,
    handleOpen: handleStreetViewOpen,
    handleClose: handleStreetViewClose,
  } = useActiveState();
  const {
    active: propertyDetailsActive,
    handleOpen: handlePropertyDetailsOpen,
    handleClose: handlePropertyDetailsClose,
  } = useActiveState();
  const { photoGroups, tabsBefore } = useMemo(() => {
    const photoGroups: PhotoPageGroup[] = [];
    const { streetView, listingPhotos } = propertyPhotosResult || {};
    const tabsBefore: TabOption<string | number>[] | undefined = streetView
      ? [
          {
            value: 'streetView',
            dataHcEventSection: dataHcEventSectionStreetView,
            label: (
              <div className={styles.StreetView}>
                Street View
                <StreetViewIcon size="sm" color="neutral-dark-20" />
              </div>
            ),
            content: null,
            preventActiveState: true,
            onClick: handleStreetViewOpen,
          },
        ]
      : undefined;

    listingPhotos?.forEach((l) => {
      if (l.media?.images?.length) {
        const formattedDate = formatDateShort(
          l.listingDetails.currentStatusDate,
        );
        const listingMetadata = (
          <ListingStatusIndicator
            value={{
              type: 'listingStatus',
              value: l.listingDetails.status,
            }}
            label={`${formattedDate} for ${
              l.listingDetails.rentalListing
                ? formatMoneyPerMonth(l.listingDetails.currentPrice)
                : formatMoney(l.listingDetails.currentPrice)
            }`}
          />
        );

        photoGroups.push({
          id: buildListingPhotoGroupId({
            listingId: l.listingDetails.listingId || '',
            hcMlsId: l.listingDetails.hcMlsId || 0,
          }),
          tabLabel: `${formatMissing(
            l.listingDetails.status,
          )} ${formattedDate}${
            l.listingDetails.rentalListing ? ' (Rental)' : ''
          }`,
          header: (
            <div className={styles.PhotoGroupHeader}>{listingMetadata}</div>
          ),
          photos: l.media.images.map((p) => {
            return {
              fullscreen: true,
              photo: p,
              metadata: listingMetadata,
            };
          }),
        });
      }
    });
    return {
      photoGroups,
      tabsBefore,
    };
  }, [propertyPhotosResult]);

  const handleClose = () => {
    if (!streetViewActive) {
      onClose();
    }
  };
  if (isLoading) {
    return null;
  }
  return (
    <>
      <PhotosOverlayPage
        dataHcName={dataHcName}
        dataHcEventSection={dataHcEventSection}
        className={className}
        photoGridSize={photoGridSize}
        activeTab={
          listingIdentifier && buildListingPhotoGroupId(listingIdentifier)
        }
        onChangeSize={onChangeSize}
        overlayPageProps={{
          headerProps: {
            title: (
              <>
                <span data-hc-name={`${dataHcName}-images-title`}>
                  Images for {formatStreetAddress(propertyState?.location)}
                </span>
                <Anchor
                  onClick={handlePropertyDetailsOpen}
                  dataHcName={`${dataHcName}-property-details-dialog-trigger`}
                  className={styles.PropertyDetailsPopoverTrigger}
                >
                  Property Details
                </Anchor>
              </>
            ),
            onBack: handleClose,
            children: price ? (
              <div className={styles.Price}>
                <span
                  data-hc-name={`${dataHcName}-price-value`}
                  className={styles.PriceValue}
                >
                  {formatMoney(price.value)}
                  &nbsp;/&nbsp;
                </span>
                <label
                  data-hc-name={`${dataHcName}-price-label`}
                  className={styles.PriceLabel}
                >
                  {price.label}
                </label>
              </div>
            ) : null,
          },

          active,
        }}
        photoGroups={photoGroups}
        tabsBefore={tabsBefore}
      />
      <StreetViewDialog
        dataHcName={`${dataHcName}-street-view`}
        dataHcEventSection={dataHcEventSectionStreetView}
        active={streetViewActive}
        onClose={handleStreetViewClose}
        location={propertyState?.location}
      />
      <Dialog
        theme={{ DialogWrapper: styles.DialogWrapper }}
        active={propertyDetailsActive}
        title={'Property Details'}
        dataHcName={`${dataHcName}-property-details-dialog`}
        width={500}
        onClose={handlePropertyDetailsClose}
      >
        <PropertySummaryHeader
          propertyStateArgs={propertyStateArgs}
          expanded="full"
          noPadding
          addressOneLine
          summaryDetailsFullWidth
        />
      </Dialog>
    </>
  );
};
