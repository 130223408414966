import React from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

import {
  ChevronRightLargeIcon,
  ChevronRightSmallIcon,
  ChevronUpLargeIcon,
  ChevronUpSmallIcon,
} from '../../../../../svgs/icons/navigation';

import styles from './DirectionalChevron.module.css';

export interface DirectionalChevronProps extends IconProps {
  direction: 'up' | 'down' | 'right' | 'left';
  smallIcon?: boolean;
}

export const DirectionalChevron = ({
  direction,
  smallIcon = false,
  ...props
}: DirectionalChevronProps) => {
  const iconOrientation =
    direction === 'right' || direction === 'left' ? 'horz' : 'vert';

  if (smallIcon) {
    return iconOrientation === 'horz' ? (
      <ChevronRightSmallIcon
        {...props}
        className={classNames(
          props.className,
          styles.DirectionalChevron,
          styles[direction],
        )}
      />
    ) : (
      <ChevronUpSmallIcon
        {...props}
        className={classNames(
          props.className,
          styles.DirectionalChevron,
          styles[direction],
        )}
      />
    );
  }

  return iconOrientation === 'horz' ? (
    <ChevronRightLargeIcon
      {...props}
      className={classNames(
        props.className,
        styles.DirectionalChevron,
        styles[direction],
      )}
    />
  ) : (
    <ChevronUpLargeIcon
      {...props}
      className={classNames(
        props.className,
        styles.DirectionalChevron,
        styles[direction],
      )}
    />
  );
};
