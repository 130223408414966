import { useMutation } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { ReportId } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';

import { ReportApi } from '../api';

import { useReportConfig } from './useReportConfig';
import { useSubjectDocument } from './useSubjectDocument';

export const useDownloadReportExcel = (reportId: ReportId) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportConfig } = useReportConfig(reportId);
  const productName =
    reportConfig?.productName === 'Property Explorer'
      ? 'PEXP'
      : reportConfig?.productName;

  return useMutation(
    async () => {
      return await ReportApi.downloadReportExcel({
        reportId,
        filename: `${productName ? `${productName} - ` : ''}${formatFullAddress(
          subjectDocument?.data.propertyState.location,
        )}.xlsx`,
      });
    },
    {
      onMutate: () => {
        toastOpen({
          title: 'Downloading Spreadsheet...',
          type: 'loading',
          duration: null,
        });
      },
      onSuccess: () => {
        toastOpen({
          title: 'Spreadsheet Downloaded',
          type: 'loading-success',
        });
      },
      onError: () => {
        toastOpen({
          title: 'Spreadsheet Download Failed',
          type: 'loading-failure',
        });
      },
    },
  );
};
