import { useQuery } from '@tanstack/react-query';

import { logException } from '@hcs/utils';

import { AuthzApi } from '../api';

export const QUERY_KEY_CAPABILITIES_FOR_ORG = 'QUERY_KEY_CAPABILITIES_FOR_ORG';
export const useCapabilitiesForOrg = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_CAPABILITIES_FOR_ORG, orgId],
    async () => {
      if (!orgId) {
        logException('useCapabilitiesForOrg called with undefined orgId');
        throw new Error('Failed to get user capabilities');
      }
      const response = await AuthzApi.getCapabilitiesForOrg(orgId);
      return response.capabilities;
    },
    {
      enabled: !!orgId,
    },
  );
};
