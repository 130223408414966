import React, { Fragment, useRef, useState } from 'react';
import classNames from 'classnames';

import { ResizeHandleVertical } from '@hcs/design-system';
import { CountChip } from '@hcs/design-system';
import { FlexScroll } from '@hcs/design-system';
import { Flyout } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { SettingsIcon } from '@hcs/design-system';
import { CheckIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { useIsOverflowed } from '@hcs/hooks';
import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import { useKeywordSearch } from '@hcs/keyword-search';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { isCompField } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { CompFields, CompTypes, PropertyStateType } from '@hcs/types';
import { FilterFieldsListType, FilterFieldsType, ReportId } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import { NULL_VALUE } from '@hcs/utils';

import {
  useCompsFiltersDocument,
  useReportPermissions,
  useSubjectDocument,
} from '../../hooks';
import { useCompFieldsFilters } from '../../hooks/useCompFieldsFilters';
import { compKeywordSearchKey } from '../../utils';
import { CompDataPriorityUserLauncher } from '../CompDataPriorityUser';
import {
  COMP_FILTER_DISTANCE,
  COMP_FILTER_FIELD_CONTROLS,
} from '../CompFilters/CompFiltersControls';
import { CompSavedFilterSetsButton } from '../CompSavedFilterSetsButton';
import { CompSavedFiltersOverlayPageLauncher } from '../CompSavedFiltersOverlayPage/CompSavedFiltersOverlayPageLauncher';

import { COMP_FILTER_KEYWORD_SEARCH } from './CompFiltersControls/CompFilterKeywordSearch';

import styles from './CompFilters.module.css';

const KeywordSearchChip = COMP_FILTER_KEYWORD_SEARCH.Chip;
interface Props {
  reportId: ReportId;
  compType: CompTypes;
  dataHcEventSection?: string;
  className?: string;
  fields?: FilterFieldsListType;
  onToggleFilters?: () => void;
  navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
}
const dataHcName = 'comp-filters';
export const CompFilters = ({
  reportId,
  compType,
  dataHcEventSection,
  navigateToCompSelectionDraw,
  className,
  fields,
  onToggleFilters,
}: Props) => {
  const { data: compFieldsFilters } = useCompFieldsFilters(reportId, compType);
  const { data: filterSets } = useSavedCompFilterSetsForUser(compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { data: compFiltersDocument } = useCompsFiltersDocument(
    reportId,
    compType,
  );
  const keywordSearchKey = compKeywordSearchKey(compType);
  const {
    state: { inputs: keywordSearchInputs },
  } = useKeywordSearch(keywordSearchKey);
  const numFiltersApplied =
    Object.keys(compFiltersDocument?.data.filters || {}).length +
    (keywordSearchInputs ? 1 : 0);
  const filterFields = fields || compFieldsFilters.order;
  const numSavedFilterSets = Object.keys(filterSets || {}).length;
  const appliedFiltersSectionRef = useRef<HTMLDivElement>(null);
  const { active, handleToggle: _handleToggle } = useActiveState({
    activeInitial: true,
  });
  const {
    active: saveFiltersFlyoutActive,
    handleOpen: saveFiltersFlyoutHandleOpen,
    handleClose: saveFiltersFlyoutHandleClose,
  } = useActiveState();
  const handleToggle = () => {
    _handleToggle();
    onToggleFilters?.();
  };
  const {
    active: appliedFiltersExpanded,
    handleToggle: handleAppliedFiltersToggle,
  } = useActiveState();
  const isAppliedFiltersOverflowed = useIsOverflowed(appliedFiltersSectionRef, [
    appliedFiltersExpanded,
  ]);
  const [activeFilterButton, setActiveFilterButton] = useState<
    FilterFieldsType | undefined
  >();
  const { Control: ActiveFilterControl } =
    (activeFilterButton
      ? COMP_FILTER_FIELD_CONTROLS[activeFilterButton]
      : undefined) || {};
  const {
    active: savedNotificationFlyoutActive,
    handleOpen: savedNotificationFlyoutOpen,
    handleClose: savedNotificationFlyoutClose,
  } = useActiveState();
  const [firstSuccessfullySaved, setFirstSuccessfullySaved] = useState(false);

  return (
    <FlexScroll
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      theme={{
        FlexScroll: classNames(
          styles.CompFilters,
          { [styles.collapsed]: !active },
          className,
        ),
      }}
      header={{
        height: 64,
        content: (
          <Flyout
            dataHcName={`${dataHcName}-flyout`}
            displayText={'Save filters'}
            flyoutDimensions={{
              height: 45,
              width: 110,
            }}
            flyoutFrom={{ direction: 'right', position: 'top' }}
            offset={10}
            active={saveFiltersFlyoutActive}
          >
            <div
              data-hc-name={`${dataHcName}-expand-button`}
              className={styles.Header}
              onClick={handleToggle}
            >
              <div className={styles.HeaderLabel}>
                <label data-hc-name={`${dataHcName}-expand-button-label`}>
                  Filters
                </label>
                <DirectionalChevron
                  dataHcName={`${dataHcName}-expand-button-chevron`}
                  direction={active ? 'up' : 'down'}
                  size={12}
                />
              </div>
              {active && (
                <CompSavedFilterSetsButton
                  onMouseEnter={saveFiltersFlyoutHandleOpen}
                  onMouseLeave={saveFiltersFlyoutHandleClose}
                  reportId={reportId}
                  compType={compType}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onSuccess={() => {
                    if (numSavedFilterSets === 0)
                      setFirstSuccessfullySaved(true);
                  }}
                />
              )}
              {!active && !!numFiltersApplied && (
                <CountChip
                  dataHcName={`${dataHcName}-count-chip`}
                  count={numFiltersApplied}
                />
              )}
            </div>
          </Flyout>
        ),
      }}
      footer={
        reportPermissions?.isEditable
          ? {
              height: numSavedFilterSets ? 100 : 50,
              content: (
                <div className={styles.Footer}>
                  {active && <div className={styles.ScrollShadow} />}
                  <CompDataPriorityUserLauncher
                    showTooltip={false}
                    trigger={
                      <div className={styles.FooterButton}>
                        Customize <SettingsIcon />
                      </div>
                    }
                    compType={compType}
                  />
                  {!!numSavedFilterSets && (
                    <Flyout
                      dataHcName={`${dataHcName}-notification-flyout`}
                      displayText={'Filter set saved here'}
                      icon={
                        <CheckIcon
                          className={styles.CheckFlyoutIcon}
                          size="sm"
                          color="neutral-dark-10"
                        />
                      }
                      flyoutDimensions={{
                        height: 40,
                        width: 190,
                      }}
                      flyoutFrom={{ direction: 'right', position: 'top' }}
                      withDuration={{
                        duration: 1500,
                        handleClose: () => {
                          savedNotificationFlyoutClose();
                          setFirstSuccessfullySaved(false);
                        },
                      }}
                      peek={
                        firstSuccessfullySaved && numSavedFilterSets === 1
                          ? 38
                          : 0
                      }
                      offset={5}
                      active={
                        savedNotificationFlyoutActive &&
                        numSavedFilterSets === 1
                      }
                    >
                      <div>
                        <CompSavedFiltersOverlayPageLauncher
                          reportId={reportId}
                          compType={compType}
                          trigger={
                            <div
                              className={styles.FooterButton}
                              onMouseEnter={
                                firstSuccessfullySaved &&
                                numSavedFilterSets === 1
                                  ? savedNotificationFlyoutOpen
                                  : undefined
                              }
                            >
                              Saved filters
                              <CountChip
                                dataHcName={`${dataHcName}-saved-filter-sets-count`}
                                count={numSavedFilterSets}
                              />
                            </div>
                          }
                        />
                      </div>
                    </Flyout>
                  )}
                </div>
              ),
            }
          : undefined
      }
    >
      {active && (
        <div data-hc-name={`${dataHcName}-content`}>
          {!!numFiltersApplied && (
            <div
              ref={appliedFiltersSectionRef}
              className={classNames(styles.AppliedFilters, {
                [styles.expanded]: appliedFiltersExpanded,
              })}
              data-hc-name={`${dataHcName}-applied-filters`}
            >
              <KeywordSearchChip reportId={reportId} compType={compType} />
              {Object.entries(compFiltersDocument?.data.filters || {}).map(
                ([key, { field }]) => {
                  if (field === CompFields.distance) {
                    return (
                      <COMP_FILTER_DISTANCE.Chip
                        key={`applied-${key}`}
                        reportId={reportId}
                        compType={compType}
                      />
                    );
                  } else {
                    const { Chip } = COMP_FILTER_FIELD_CONTROLS[field] || {};
                    if (Chip) {
                      return (
                        <Chip
                          key={`applied-${key}`}
                          reportId={reportId}
                          compType={compType}
                        />
                      );
                    } else {
                      return <Fragment key={`applied-${key}`}>{null}</Fragment>;
                    }
                  }
                },
              )}
            </div>
          )}
          <div
            className={classNames({
              [styles.appliedFiltersShadow]: !!numFiltersApplied,
            })}
          >
            {(isAppliedFiltersOverflowed || appliedFiltersExpanded) && (
              <ResizeHandleVertical
                dataHcName={`${dataHcName}-applied-filters-resize`}
                className={styles.ResizeHandleVertical}
                onClick={handleAppliedFiltersToggle}
              />
            )}
            {activeFilterButton &&
            (ActiveFilterControl ||
              activeFilterButton === CompFields.distance) ? (
              (() => {
                const { label } =
                  activeFilterButton === 'listingDetailsSale.remarks' ||
                  activeFilterButton === 'listingDetailsRental.remarks'
                    ? { label: 'Keyword Search' }
                    : isCompField(activeFilterButton)
                      ? COMP_FIELDS_CONFIG[activeFilterButton]
                      : PROPERTY_STATE_FIELD_CONFIGS[activeFilterButton];
                return (
                  <>
                    <div
                      data-hc-name={`${dataHcName}-active-back`}
                      className={styles.UnsetActiveFilterButton}
                      onClick={() => setActiveFilterButton(undefined)}
                    >
                      <div className={styles.UnsetActiveFilterButtonContent}>
                        <div
                          data-hc-name={`${dataHcName}-unactive-button-container`}
                          className={styles.UnactiveFilterButtonIconContainer}
                        >
                          <DirectionalChevron size={'sm'} direction="left" />
                        </div>
                        <div>
                          <div data-hc-name={`${dataHcName}-active-back-label`}>
                            {label}
                          </div>
                          {activeFilterButton !==
                            'listingDetailsSale.remarks' &&
                          activeFilterButton !==
                            'listingDetailsRental.remarks' &&
                          !isCompField(activeFilterButton)
                            ? (() => {
                                const { Display } =
                                  PROPERTY_STATE_FIELD_CONFIGS[
                                    activeFilterButton
                                  ];
                                return (
                                  <div
                                    data-hc-name={`${dataHcName}-subject-value`}
                                    className={styles.SubjectValue}
                                  >
                                    <span className={styles.SubjectValueLabel}>
                                      Subject
                                    </span>{' '}
                                    /{' '}
                                    {subjectDocument?.data.propertyState ? (
                                      <Display
                                        propertyStateArgs={{
                                          propertyStateType:
                                            PropertyStateType.Core,
                                          propertyState:
                                            subjectDocument.data.propertyState,
                                        }}
                                      />
                                    ) : (
                                      NULL_VALUE
                                    )}
                                  </div>
                                );
                              })()
                            : null}
                        </div>
                      </div>
                    </div>
                    <div
                      data-hc-name={`${dataHcName}-active-filters`}
                      className={styles.ActiveFilterContent}
                    >
                      {activeFilterButton === CompFields.distance ? (
                        <COMP_FILTER_DISTANCE.Control
                          reportId={reportId}
                          compType={compType}
                          navigateToCompSelectionDraw={
                            navigateToCompSelectionDraw
                          }
                        />
                      ) : ActiveFilterControl ? (
                        <ActiveFilterControl
                          reportId={reportId}
                          compType={compType}
                        />
                      ) : null}
                    </div>
                  </>
                );
              })()
            ) : (
              <ul
                data-hc-name={`${dataHcName}-filter-controls`}
                className={styles.FilterControls}
              >
                {filterFields?.map((field) => {
                  const isKeywordSearchField =
                    field === 'listingDetailsSale.remarks' ||
                    field === 'listingDetailsRental.remarks';
                  if (!reportPermissions?.isEditable && isKeywordSearchField)
                    return null;
                  const { label } = isKeywordSearchField
                    ? { label: 'Keyword Search' }
                    : isCompField(field)
                      ? COMP_FIELDS_CONFIG[field]
                      : PROPERTY_STATE_FIELD_CONFIGS[field];
                  const hasFilterControl =
                    !!COMP_FILTER_FIELD_CONTROLS[field] ||
                    field === CompFields.distance;
                  if (hasFilterControl) {
                    return (
                      <li
                        key={`field-${field}`}
                        data-hc-name={
                          isKeywordSearchField
                            ? `${dataHcName}-button-keyword-search`
                            : `${dataHcName}-button-${field}`
                        }
                        className={styles.FilterButton}
                        onClick={() => setActiveFilterButton(field)}
                      >
                        {label}
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            )}
          </div>
        </div>
      )}
    </FlexScroll>
  );
};
