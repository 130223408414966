import { PreferencesKeys } from '@hcs/types';

import { useUiPreferencesForUser } from './useUiPreferencesForUser';

export const useMapPreferencesForUser = () => {
  const mergedUserOrgUiPreferences = useUiPreferencesForUser();
  return {
    ...mergedUserOrgUiPreferences,
    data: mergedUserOrgUiPreferences.isFetched
      ? mergedUserOrgUiPreferences.data?.[PreferencesKeys.Maps]
      : undefined,
  };
};
