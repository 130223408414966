import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WebMercatorViewport } from '@deck.gl/core';
import { InteractiveState } from '@deck.gl/core/lib/deck';
import Layer from '@deck.gl/core/lib/layer';
import { Color } from '@deck.gl/core/typed';
import { DeckGL } from '@deck.gl/react';
import {
  DrawPolygonMode,
  Feature,
  FeatureCollection,
  ModifyMode,
} from '@nebula.gl/edit-modes';
import { EditHandleFeature } from '@nebula.gl/edit-modes/src/types';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { HtmlOverlay, HtmlOverlayItem } from '@nebula.gl/overlays';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import { point, polygon, Position } from '@turf/helpers';

import { Button } from '@hcs/design-system';
import { CloseButton } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { HelpChatHideWhileMounted } from '@hcs/help-chat';
import { HcMap } from '@hcs/maps';
import { HcMapLayersControl } from '@hcs/maps';
import { SatelliteToggle } from '@hcs/maps';
import { ZoomControl } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import {
  DISTANCE_UNITS,
  GEO_JSON_COLOR,
  getColorWithAlpha,
  getFeatureCollection,
  getGEOJSONPolygonCircleForDisplay,
} from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  ControlPosition,
  DrawType,
  FeatureProperties,
  GeometryTypesEnum,
  LayersControlConfig,
  MapStyles,
  MeaningfulEventTypes,
} from '@hcs/types';
import {
  CompFields,
  CompTypes,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';
import { LimitedReportTypes } from '@hcs/types';
import { hexToRgb } from '@hcs/utils';

import {
  useCompsFarmDocument,
  useCompsFiltersDocument,
  useDocumentPatch,
  useReportLimitedType,
  useReportPermissions,
  useSubjectDocument,
} from '../../hooks';
import { CompCounts } from '../CompCounts';
import { SubjectMarker } from '../SubjectMarker';

import { DrawControls } from './DrawControls';
import {
  MeasureDrawPolygon,
  MeasureDrawRectangle,
  MeasureExtrudeMode,
  MeasureTransformMode,
} from './modes';

import styles from './CompSelectionDrawMap.module.css';

const enum EDIT_HANDLE_TYPE {
  EXISTING = 'existing',
  INTERMEDIATE = 'intermediate',
  ROTATE = 'rotate',
  SNAP = 'snap',
}

const MAP_ID = 'draw';
const INITIAL_ZOOM = 14;
const TRANSPARENT = 76;
const geoJSONColorRGB = hexToRgb(GEO_JSON_COLOR) || {
  red: 0,
  green: 0,
  blue: 0,
};
const MEASURE_COLOR: Color = [61, 135, 57, 255];
const FINAL_COLOR: Color = [
  geoJSONColorRGB.red,
  geoJSONColorRGB.green,
  geoJSONColorRGB.blue,
  255,
];
const WHITE_COLOR: Color = [255, 255, 255, 255];

interface Measurements {
  area: string;
  radius?: string;
}

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  onDone: VoidFunction;
  layersControl?: LayersControlConfig;
}

const FIELD = CompFields.distance;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const MHcMap = memo(HcMap);
const MDeckGL = memo(DeckGL);

const dataHcName = 'comp-selection-draw-map';
export const dataHcEventSectionDrawMapComps = 'Comps Selection Draw Map';
export const dataHcEventSectionDrawMapRental =
  'Rental Comps Selection Draw Map';
export const CompSelectionDrawMap = ({
  reportId,
  compType,
  onDone,
  layersControl,
}: Props) => {
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const { isFetching: isFarmDocFetching } = useCompsFarmDocument(
    reportId,
    compType,
  );
  const {
    mapState,
    actions: { hcMapViewportChange, hcMapChangeZoom },
  } = useHcMap(MAP_ID);
  const [activeControl, setActiveControl] = React.useState<
    GeometryTypesEnum | undefined
  >();
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isLoadingFarm, setIsLoadingFarm] = useState(false);
  const { data: limitedReport } = useReportLimitedType(reportId);
  const [trashDisabled, setTrashDisabled] = React.useState<boolean>(true);
  const { latitude, longitude } =
    subjectDocument?.data.propertyState.location || {};
  const [coordinates, setCoordinates] = React.useState<number[]>([]);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const [features, setFeatures] = React.useState<
    FeatureCollection | undefined
  >();
  const [editedPolygon, setEditedPolygon] = React.useState<boolean>(false);
  const [tooltipText, setTooltipText] = React.useState<string>();
  const [measurements, setMeasurements] = React.useState<Measurements>();
  const [mode, setMode] = React.useState(() => DrawPolygonMode);
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = React.useState<
    number[]
  >([]);
  const dataHcEventSection =
    compType === CompTypes.Rental
      ? dataHcEventSectionDrawMapRental
      : dataHcEventSectionDrawMapComps;
  const deckViewPort = useRef<WebMercatorViewport>();

  useEffect(() => {
    if (!isFarmDocFetching) {
      setIsLoadingFarm(false);
    }
  }, [isFarmDocFetching]);

  const updateTooltipOnEvent = useCallback(
    () => (editType: string) => {
      switch (activeControl) {
        case GeometryTypesEnum.CIRCLE:
          if (editType === 'updateTentativeFeature') {
            setTooltipText(
              `Radius: ${
                measurements?.radius || ''
              }\nClick mouse to finish drawing.`,
            );
          } else if (editType === 'scaling') {
            setTooltipText(`Radius: ${measurements?.radius}`);
          } else {
            // Anything we are not handling stop the overlay
            setTooltipText(undefined);
          }
          break;
        case GeometryTypesEnum.RECTANGLE:
          if (editType === 'updateTentativeFeature') {
            setTooltipText(
              `Area: ${measurements?.area}\nClick mouse to finish drawing.`,
            );
          } else if (editType === 'scaling' || editType === 'extruding') {
            const baseTooltip = `Area: ${measurements?.area}`;
            const tooltip =
              editType === 'scaling'
                ? baseTooltip + '. Hold down shift to change aspect ratio.'
                : baseTooltip;
            setTooltipText(tooltip);
          } else {
            // Anything we are not handling stop the overlay
            setTooltipText(undefined);
          }
          break;
        case GeometryTypesEnum.POLYGON:
          if (
            editType === 'addPosition' ||
            editType === 'removePosition' ||
            editType === 'movePosition'
          ) {
            setTooltipText(`Release mouse button to finish editing.`);
          } else if (editType === 'updateTentativeFeature') {
            setTooltipText(
              `Click to continue to build this shape.\nDouble click mouse to finish drawing.`,
            );
          } else {
            // Anything we are not handling stop the overlay
            setTooltipText(undefined);
          }
          break;
        default:
          setTooltipText(undefined);
          break;
      }
    },
    [activeControl, measurements?.area, measurements?.radius],
  );

  const initValue = useMemo(() => {
    return {
      latitude: mapState?.viewport?.latitude || latitude || undefined,
      longitude: mapState?.viewport?.longitude || longitude || undefined,
      zoom: mapState?.viewport?.zoom || INITIAL_ZOOM,
    };
  }, [mapState, latitude, longitude]);

  const viewStateChange = useCallback(
    ({
      viewState,
    }: {
      viewState: { latitude: number; longitude: number; zoom: number };
    }) => {
      const { latitude, longitude, zoom } = viewState;
      hcMapViewportChange({
        mapId: MAP_ID,
        viewport: {
          latitude,
          longitude,
          zoom,
        },
      });
    },
    [hcMapViewportChange],
  );

  // Convert a display Polygon GeoJSON type to the type needed on the backend
  // In this case a center point and radius in meters
  const convertPolygonCircleToCenterAndRadius = (
    circleFeature: Feature,
  ): Feature => {
    const circlePolygon = polygon(
      circleFeature.geometry.coordinates as Position[][],
    );
    const points: Position[] = circleFeature?.geometry
      .coordinates?.[0] as Position[];
    let feature = circleFeature;
    if (points?.[0]) {
      const outerPoint = point(points[0]);
      const centerPoint = centroid(circlePolygon);
      const radius = distance(outerPoint, centerPoint, {
        units: DISTANCE_UNITS.METERS,
      });

      feature = {
        ...circleFeature,
        geometry: {
          coordinates: centerPoint.geometry.coordinates as [number, number],
          type: 'Point',
        },
        properties: {
          radius: radius,
          radius_unit: DISTANCE_UNITS.METERS,
        },
      };
    }
    return feature;
  };

  const updateZoom = (zoom: number) => {
    hcMapChangeZoom({
      mapId: MAP_ID,
      zoom,
    });
  };

  const zoomSlowly = (oldZoom: number, zoom: number) => {
    const increment = 0.07;
    const timeInterval = 50;

    const zoomIn = zoom > oldZoom;

    const int = setInterval(() => {
      if ((zoomIn && oldZoom >= zoom) || (!zoomIn && oldZoom <= zoom)) {
        clearInterval(int);
      }
      updateZoom(oldZoom);
      oldZoom = zoomIn ? oldZoom + increment : oldZoom - increment;
    }, timeInterval);
  };

  const saveOrGoBack = () => {
    if (editedPolygon) {
      setIsLoadingFarm(true);
      if (
        filterDocument !== undefined &&
        selectedFeatureIndexes?.[0] !== undefined &&
        features?.features !== undefined &&
        features?.features[selectedFeatureIndexes?.[0]] !== undefined
      ) {
        const selectedFeature =
          features?.features?.[selectedFeatureIndexes?.[0]];

        const nebulaFeature: Feature | undefined =
          activeControl === GeometryTypesEnum.CIRCLE && selectedFeature
            ? convertPolygonCircleToCenterAndRadius(selectedFeature)
            : selectedFeature;

        if (nebulaFeature) {
          const geoFeatureDefault: GeoJSON.Feature = {
            ...nebulaFeature,
            properties: {
              shape: activeControl,
              drawType: 'custom' as DrawType,
            } as FeatureProperties,
          };
          const geoFeature =
            activeControl === GeometryTypesEnum.CIRCLE
              ? {
                  ...geoFeatureDefault,
                  properties: {
                    ...geoFeatureDefault.properties,
                    ...nebulaFeature.properties,
                  },
                }
              : geoFeatureDefault;

          documentPatchMutation.mutate({
            reportId,
            document: filterDocument,
            operations: [
              {
                op: 'add',
                path: PATH_ABS,
                value: geoFeature,
              },
              {
                op: 'add',
                path: PATH_FIELD,
                value: CompFields.distance,
              },
            ],
          });
        }
      }
    } else {
      onDone();
    }
    setEditedPolygon(false);
  };

  const mainColor: Color =
    mode === MeasureDrawPolygon ||
    mode === MeasureDrawRectangle ||
    mode === MeasureTransformMode
      ? MEASURE_COLOR
      : FINAL_COLOR;

  const layer = useMemo(
    () =>
      new EditableGeoJsonLayer({
        data: features || {
          type: 'FeatureCollection',
          features: [],
        },
        mode,
        modeConfig: {
          measurementCallback: (area: string, radius?: string) => {
            setMeasurements({ area, radius });
          },
          mouseMove: (coordinates: number[]) => {
            setCoordinates(coordinates);
          },
        },
        selectedFeatureIndexes,
        getTentativeLineColor: MEASURE_COLOR,
        getEditHandlePointOutlineColor: mainColor,
        getEditHandlePointColor: (handle: EditHandleFeature) => {
          return handle.properties.editHandleType ===
            EDIT_HANDLE_TYPE.INTERMEDIATE
            ? getColorWithAlpha(WHITE_COLOR, TRANSPARENT)
            : WHITE_COLOR;
        },
        editHandlePointRadiusScale: 7,
        getTentativeFillColor: getColorWithAlpha(MEASURE_COLOR, TRANSPARENT),
        getFillColor: getColorWithAlpha(FINAL_COLOR, TRANSPARENT),
        getLineColor: FINAL_COLOR,
        onEdit: ({ updatedData, editType }) => {
          setEditedPolygon(true);
          updateTooltipOnEvent()(editType);
          if (
            editType === 'scaled' ||
            editType === 'translating' ||
            editType === 'finishMovePosition' ||
            editType === 'translated'
          ) {
            setTooltipText(undefined); // Stop displaying the tooltip overlay when finished editing
          }
          if (editType === 'addFeature') {
            setTimeout(() => {
              //Note if we don't set a timeout the lib looses references and errors
              setSelectedFeatureIndexes([0]);
              setTooltipText(undefined); // Stop displaying the overlay when finished creating object
              setTrashDisabled(false); // we have finished editing
              if (mode === MeasureDrawPolygon) {
                setMode(() => ModifyMode);
              } else {
                setMode(() => MeasureTransformMode); //Once we have added we can go to edit mode
              }
            }, 0);
          }
          setFeatures(updatedData);
        },
      }),
    [features, mainColor, mode, selectedFeatureIndexes, updateTooltipOnEvent],
  );

  const getCursor = useCallback(
    (interactiveState: InteractiveState) => {
      const { isDragging } = interactiveState;
      let cursor: string;
      try {
        const layerCursor = layer.getCursor.bind(layer);
        cursor = layerCursor(interactiveState);
      } catch {
        cursor = isDragging ? 'grabbing' : 'grab';
      }
      return cursor;
    },
    [layer],
  );

  const updateModesOnShift = useCallback(
    (e: KeyboardEvent) => {
      if (
        (e.code === 'ShiftLeft' || e.code === 'ShiftRight') &&
        activeControl === GeometryTypesEnum.RECTANGLE &&
        mode?.name !== 'MeasureExtrudeMode'
      ) {
        setMode(() => MeasureExtrudeMode);
      }
    },
    [activeControl, mode?.name],
  );

  const resetModeBack = useCallback(
    (e: KeyboardEvent) => {
      if (
        (e.code === 'ShiftLeft' || e.code === 'ShiftRight') &&
        activeControl === GeometryTypesEnum.RECTANGLE &&
        mode?.name !== 'MeasureTransformMode'
      ) {
        setMode(() => MeasureTransformMode);
      }
    },
    [activeControl, mode?.name],
  );

  const filterValueDoc =
    filterDocument?.data.filters?.[CompFields.distance]?.absoluteValue;
  const filterValue =
    filterValueDoc?.properties?.shape === GeometryTypesEnum.CIRCLE
      ? getGEOJSONPolygonCircleForDisplay(filterValueDoc as Feature)
      : filterValueDoc;

  useEffect(() => {
    document.addEventListener('keydown', updateModesOnShift);
    document.addEventListener('keyup', resetModeBack);

    if (filterDocument) {
      const filterValueDoc =
        filterDocument.data.filters?.[CompFields.distance]?.absoluteValue;
      const filterValue =
        filterValueDoc?.properties?.shape === GeometryTypesEnum.CIRCLE
          ? getGEOJSONPolygonCircleForDisplay(filterValueDoc as Feature)
          : filterValueDoc;
      if (filterValue && features === undefined) {
        setTrashDisabled(false);
        const featureCollection = getFeatureCollection(filterValue as Feature);
        setFeatures(featureCollection);
        const shape = (filterValue as Feature)?.properties?.shape;
        setActiveControl(shape);
        setSelectedFeatureIndexes([0]);
        if (shape === GeometryTypesEnum.POLYGON) {
          setMode(() => ModifyMode);
        } else {
          setMode(() => MeasureTransformMode);
        }
      }
    }

    return () => {
      document.removeEventListener('keydown', updateModesOnShift);
      document.removeEventListener('keyup', resetModeBack);
    };
  }, [
    resetModeBack,
    updateModesOnShift,
    subjectDocument,
    filterDocument,
    features,
  ]);

  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  if (!reportPermissions.isEditable) {
    return (
      <NullState
        dataHcName={`${dataHcName}-not-editable-error`}
        title="This report is not editable"
      />
    );
  }
  if (!latitude || !longitude) {
    return (
      <NullState
        dataHcName={`${dataHcName}-subject-coordinates-error`}
        title="Missing subject coordinates"
      />
    );
  }

  const subjectPropertyType = subjectDocument?.data.propertyState
    ? getPropertyStateFieldValue(PropertyStateFields.propertyType, {
        propertyStateType: PropertyStateType.Core,
        propertyState: subjectDocument.data.propertyState,
      })
    : undefined;

  return (
    <div className={styles.Page} data-hc-event-section={dataHcEventSection}>
      <HelpChatHideWhileMounted />
      <div className={styles.Header}>
        <CloseButton
          onClick={onDone}
          dataHcName={`${dataHcName}-close-btn`}
          className={styles.CloseButton}
        />
        <div className={styles.HeaderLabel}>Draw Comp Search Area</div>
      </div>
      <div className={styles.MapContainer}>
        <div className={styles.Map} data-hc-name={`${dataHcName}-map`}>
          {/* @ts-expect-error DeckGl not typed property as JSX component  */}
          <MDeckGL
            initialViewState={initValue}
            onViewStateChange={viewStateChange}
            controller={true}
            layers={activeControl ? [layer as Layer<EditableGeoJsonLayer>] : []}
            getCursor={getCursor}
          >
            {({ viewport }: { viewport: WebMercatorViewport }) => {
              deckViewPort.current = viewport;
            }}
            <MHcMap
              mapId={MAP_ID}
              dataHcName={dataHcName}
              propertyType={subjectPropertyType}
              longitude={mapState?.viewport?.longitude || longitude}
              latitude={mapState?.viewport?.latitude || latitude}
              mapStyle={mapState?.mapStyle || MapStyles.Default}
            >
              {!isEditingLocation && !editedPolygon && (
                <CompCounts
                  reportId={reportId}
                  compType={compType}
                  isLoadingFarm={isLoadingFarm}
                  className={styles.CompCounts}
                />
              )}
              <SubjectMarker
                enableLocationUpdate={isEditingLocation}
                onReset={() => setIsEditingLocation(false)}
                onSave={() => setIsEditingLocation(false)}
                reportId={reportId}
              />
            </MHcMap>
          </MDeckGL>
          <Button
            dataHcName={`${dataHcName}-submit-button`}
            className={styles.SubmitButton}
            onClick={saveOrGoBack}
            dataHcEventName={
              editedPolygon
                ? `Saved Custom ${
                    compType === CompTypes.Rental ? 'Rental ' : ''
                  }Comp Distance Filter`
                : undefined
            }
            dataHcEventType={
              editedPolygon ? MeaningfulEventTypes.Goal : undefined
            }
          >
            {editedPolygon ? 'Search' : 'Done'}
          </Button>
          {limitedReport?.limitedMapping[LimitedReportTypes.MissingLocation] &&
          !limitedReport.locationProvidedByUser ? null : (
            <DrawControls
              deleteEngagement={
                trashDisabled || !filterValue
                  ? undefined
                  : {
                      dataHcEventType: MeaningfulEventTypes.Goal,
                      dataHcEventName: `Deleted Custom ${
                        compType === CompTypes.Rental ? 'Rental ' : ''
                      }Comp Distance Filter`,
                    }
              }
              setEditedPolygon={setEditedPolygon}
              onDelete={() => {
                if (filterDocument) {
                  documentPatchMutation.mutate({
                    reportId,
                    document: filterDocument,
                    operations: [
                      {
                        op: 'remove',
                        path: PATH_FILTER,
                      },
                    ],
                  });
                }
              }}
              setActiveControl={(activeControl) => {
                setIsEditingLocation(false);
                setActiveControl(activeControl);
              }}
              activeControl={activeControl}
              setTooltipText={setTooltipText}
              setSelectedFeatureIndexes={setSelectedFeatureIndexes}
              setTrashDisabled={setTrashDisabled}
              trashDisabled={trashDisabled}
              setMode={setMode}
              onSetGeoJson={(g) => {
                setFeatures(g);
              }}
              showLocationControl={
                limitedReport?.limitedMapping[
                  LimitedReportTypes.MissingLocation
                ]
              }
              locationControlActive={isEditingLocation}
              onClickLocationControl={() => {
                setIsEditingLocation(true);
              }}
            />
          )}
          <ZoomControl
            mapId={MAP_ID}
            zoom={mapState?.viewport?.zoom || INITIAL_ZOOM}
            position={ControlPosition.TopLeft}
            manual={true}
            onChange={(zoom: number) => {
              const oldZoom = mapState?.viewport?.zoom;
              if (oldZoom) {
                zoomSlowly(oldZoom, zoom);
              }
            }}
          />
          <SatelliteToggle
            mapId={MAP_ID}
            mapStyle={mapState?.mapStyle || MapStyles.Default}
            position={ControlPosition.BottomLeft}
          />
          {deckViewPort.current &&
            tooltipText &&
            coordinates &&
            coordinates.length > 0 && (
              <HtmlOverlay viewport={deckViewPort.current}>
                <HtmlOverlayItem
                  style={{ zIndex: 99999 }}
                  coordinates={coordinates}
                >
                  <div
                    data-hc-name={`${dataHcName}-tooltip`}
                    className={styles.Tooltip}
                  >
                    {tooltipText}
                  </div>
                </HtmlOverlayItem>
              </HtmlOverlay>
            )}

          {layersControl && (
            <HcMapLayersControl mapId={MAP_ID} {...layersControl} />
          )}
        </div>
      </div>
    </div>
  );
};
