import { LineSvgProps } from '@nivo/line';

/*
 * lineChartPaddedMinMax util
 * with @nivo line charts, you can set literal chart min and maxes, or you can pass 'auto' to the yScale
 * 'auto' will unfortunately sat the yScale so it's top and bottom values are the min and max with no buffer
 * this function provides literal min and max values to pass to nivo's yScale that have a buffer
 */
export const EMPTY_MIN_MAX = { min: 0, max: 0 };
export const lineChartPaddedMinMax = (
  chartData: LineSvgProps['data'] | null,
) => {
  if (!chartData || chartData.length === 0) {
    return EMPTY_MIN_MAX;
  }
  const chartMinMax = chartData.reduce<{
    chartMin: number | null;
    chartMax: number | null;
  }>(
    (accum, dataSerie) => {
      const serieMin = Math.min(
        ...dataSerie.data.map((point) =>
          typeof point.y === 'number' ? point.y : +Infinity,
        ),
      );
      if (accum.chartMin === null || serieMin < accum.chartMin) {
        accum.chartMin = serieMin;
      }
      const serieMax = Math.max(
        ...dataSerie.data.map((point) =>
          typeof point.y === 'number' ? point.y : -Infinity,
        ),
      );
      if (accum.chartMax === null || serieMax > accum.chartMax) {
        accum.chartMax = serieMax;
      }
      return accum;
    },
    { chartMin: null, chartMax: null },
  );

  const { chartMin, chartMax } = chartMinMax;
  if (chartMin === null || chartMax === null) {
    return EMPTY_MIN_MAX;
  }
  const chartRange = chartMax - chartMin;
  const spacingAmount = chartRange / 6;

  return {
    min: chartMin - spacingAmount,
    max: chartMax + spacingAmount,
  };
};
