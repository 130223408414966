import { useQuery } from '@tanstack/react-query';

import { BuyBox } from '@hcs/types';

import { LeadFeedApi } from '../';

export const QUERY_KEY_BUY_BOX = 'buyBox';
export const useBuyBox = (buyBoxId: BuyBox['id'] | null) => {
  return useQuery(
    [QUERY_KEY_BUY_BOX, { id: buyBoxId }],
    async () => await LeadFeedApi.fetchBuyBox(buyBoxId),
    {
      enabled: buyBoxId !== null,
    },
  );
};
