import flatten from 'lodash/flatten';
import isInteger from 'lodash/isInteger';
import uniqBy from 'lodash/uniqBy';
import mapboxgl, { Expression, FillPaint } from 'mapbox-gl';

import {
  ConsumerMapLegendStats,
  HcMapLayerNumberType,
  HcMapLegendStats,
  LegendColorTable,
  LegendInterval,
  LngLatObject,
  MapLegendMetricStats,
  PropertyTypeEnum,
  ReactMapGlLngLatPair,
  TilePropertyTypes,
  VectilesMetricGroups,
  VectilesMetricIds,
  VectilesMetrics,
  VectilesMetricsConsumer,
  VectilesMetricsHcs,
  VectilesMetricsHcsRental,
} from '@hcs/types';
import { formatNumberAbbrev, NULL_VALUE } from '@hcs/utils';

import {
  DEFAULT_LAYER_METRIC,
  HALFTONE_IMAGES,
  PROPERTY_TYPE_TO_VECTILES_TYPE,
  SCHOOL_MARKER_IMAGE_IDS,
  SCHOOLS_LEGEND_INTERVALS,
  TRANSPARENT_COLOR,
  VECTILES_METRICS_CONFIG,
  VECTILES_METRICS_GROUP_CONFIG,
} from '../constants';

const NUM_DIGITS_FOR_PERCENTAGE = 1;
const ONE_HUNDRED_PERCENT = 100;
const ZERO_PERCENT = 0;
const RADIX = 10;

/**
 * Count number of decimals places in number
 */
const countDecimals = (value: number): number => {
  return isInteger(value) ? 0 : value?.toString().split('.')?.[1]?.length || 0;
};

/**
 * Get min number increment
 */
export const getMinNumberIncrement = (num: number): number => {
  const decNum = countDecimals(num);
  if (decNum === 0) return 1;
  let incNum = '0.';
  for (let i = 0; i < decNum - 1; i++) {
    incNum += '0';
  }
  return parseFloat(incNum + '1');
};

/**
 * Create an object with color, label from Intervals object to be used for labels, tooltip in the map legend
 * @param  [object]
 * @param  {object} labelFormatterOptions
 * @return {object}
 */
export const getColorTableFromIntervals = ({
  intervals,
  numberType,
  mainMenuValue,
}: {
  intervals: LegendInterval[];
  numberType: HcMapLayerNumberType;
  mainMenuValue: VectilesMetricGroups;
}): LegendColorTable => {
  /* Allow overriding but fallback to our current assumption */

  const prefix = numberType === HcMapLayerNumberType.MONEY ? '$' : '';
  const suffix = numberType === HcMapLayerNumberType.PERCENTAGE ? '%' : '';

  return intervals.map((interval, idx) => {
    let low;
    let high;

    if (numberType !== HcMapLayerNumberType.PERCENTAGE) {
      if (interval.start < 0) {
        interval.start = 0;
      }
      if (interval.end < 0) {
        interval.end = 0;
      }
      if (idx !== 0) {
        const current = parseInt(`${interval.start}`, RADIX);
        const previous = parseInt(`${intervals?.[idx - 1]?.end}`, RADIX);

        if (current === previous) {
          interval.start = current + 1;
        }
      }
      low = formatNumberAbbrev(interval.start) || NULL_VALUE;
      high = formatNumberAbbrev(interval.end) || NULL_VALUE;
    } else {
      const start = parseFloat(
        (interval.start * ONE_HUNDRED_PERCENT).toFixed(
          NUM_DIGITS_FOR_PERCENTAGE,
        ),
      );
      const end = parseFloat(
        (interval.end * ONE_HUNDRED_PERCENT).toFixed(NUM_DIGITS_FOR_PERCENTAGE),
      );
      low =
        mainMenuValue === VectilesMetricGroups.Schools ||
        mainMenuValue === VectilesMetricGroups.Crime
          ? mainMenuValue === VectilesMetricGroups.Crime
            ? interval.start
            : interval.min
          : idx !== 0
            ? (start + getMinNumberIncrement(start)).toFixed(
                NUM_DIGITS_FOR_PERCENTAGE,
              )
            : start;
      high =
        mainMenuValue === VectilesMetricGroups.Schools ||
        mainMenuValue === VectilesMetricGroups.Crime
          ? mainMenuValue === VectilesMetricGroups.Crime
            ? interval.end
            : interval.max
          : end;
    }
    low = `${prefix}${low}${suffix}`;
    high = `${prefix}${high}${suffix}`;

    return {
      color: interval.color,
      label: [low, high],
      patternImageIcon: undefined,
    };
  });
};

/**
 * Generate CSS gradient defs for each layer group and id to be used in the legend color bar
 */
export const getLegendBreakGradients = (
  intervals: LegendInterval[],
  layerId: VectilesMetrics,
  isMonochromeOverlay: boolean,
) => {
  if (isMonochromeOverlay) {
    const patterns: string[] = HALFTONE_IMAGES.filter(
      (img) => img.name !== 'empty',
    ).map((img) => {
      return `url(${img.icon})`;
    });
    return patterns;
  }

  return (
    intervals &&
    intervals
      .map((_, i) => {
        return i > 0
          ? `linear-gradient(to right, ${intervals?.[i - 1]?.color} 0%,${
              intervals?.[i]?.color
            } 100%)`
          : null;
      })
      .slice(1)
  );
};

export const isConsumerLayerEnum = (
  layer:
    | VectilesMetricsConsumer
    | VectilesMetricsHcs
    | VectilesMetricsHcsRental,
): layer is VectilesMetricsConsumer => {
  return Object.values(VectilesMetricsConsumer).includes(
    layer as VectilesMetricsConsumer,
  );
};

export const isConsumerStats = (
  stats: ConsumerMapLegendStats | HcMapLegendStats,
): stats is ConsumerMapLegendStats => {
  const firstKey = Object.keys(stats)?.[0] as VectilesMetricsConsumer;
  return firstKey && isConsumerLayerEnum(firstKey);
};

export const getMapLegendMetricStats = (
  metric: VectilesMetrics,
  breaksMapping: ConsumerMapLegendStats | HcMapLegendStats,
  propertyType?: PropertyTypeEnum | null,
): MapLegendMetricStats | undefined => {
  const propType: TilePropertyTypes | undefined = propertyType
    ? PROPERTY_TYPE_TO_VECTILES_TYPE[propertyType]
    : undefined;

  return isConsumerStats(breaksMapping) && isConsumerLayerEnum(metric)
    ? breaksMapping?.[metric]
    : propType &&
        !isConsumerStats(breaksMapping) &&
        !isConsumerLayerEnum(metric)
      ? breaksMapping?.[`${metric}_${propType.toLowerCase()}`]
      : undefined;
};

/**
 * Given a breaks mapping API object, return an object containing legend break ranges
 * and colors for a single metric
 * @param  {string} metric - the layer metric
 * @param  {object} breaksMapping - an object containing breaks for all metrics
 * @return {object}
 */
export const getLegendBreaksForMetric = (
  vectilesMetricId: VectilesMetricIds,
  breaksMapping: ConsumerMapLegendStats | HcMapLegendStats,
  propertyType?: PropertyTypeEnum | null,
) => {
  const { vectilesMetricGroup, vectilesMetric } =
    VECTILES_METRICS_CONFIG[vectilesMetricId];
  //First we get the breakValues from the bins
  const metricStats = getMapLegendMetricStats(
    vectilesMetric,
    breaksMapping,
    propertyType,
  );
  const stats = metricStats
    ? metricStats
    : getMapLegendMetricStats(
        DEFAULT_LAYER_METRIC,
        breaksMapping,
        propertyType,
      );

  const breakValues: number[] | undefined = stats?.bins;

  const breaks: LegendInterval[] = [];
  const percentageInc =
    breakValues?.length && breakValues?.length > 1
      ? ONE_HUNDRED_PERCENT / (breakValues?.length - 1)
      : ZERO_PERCENT;

  if (breakValues && breakValues.length) {
    for (let idx = 0; idx < breakValues.length - 1; idx++) {
      if (
        breakValues[idx] &&
        breakValues[idx + 1] &&
        stats?.min &&
        stats?.max
      ) {
        breaks.push({
          start: breakValues[idx] as number,
          end: breakValues[idx + 1] as number,
          color:
            VECTILES_METRICS_GROUP_CONFIG[vectilesMetricGroup].colors[idx] ||
            '',
          min:
            vectilesMetricGroup === VectilesMetricGroups.Schools
              ? idx * percentageInc + 1
              : stats?.min,
          max:
            vectilesMetricGroup === VectilesMetricGroups.Schools
              ? idx * percentageInc + percentageInc
              : stats?.max,
        });
      }
    }
  } else {
    return undefined;
  }
  /* We're only using the lower bound of each bin to compute the Mapbox color assignment expression */
  return uniqBy(breaks, (item) => item.start);
};

export const latLngToLngLat = (
  latLng: ReactMapGlLngLatPair,
): ReactMapGlLngLatPair => [latLng[1], latLng[0]];
export const lngLatToLatLng = (
  lngLat: ReactMapGlLngLatPair,
): ReactMapGlLngLatPair => [lngLat[1], lngLat[0]];

export const lngLatToLngLatObject = (
  value?: mapboxgl.LngLat,
): LngLatObject | undefined => {
  return value
    ? {
        lng: value.lng,
        lat: value.lat,
      }
    : undefined;
};

/**
 * Generate a configuration accepted by the Mapbox GL API to assign image ids
 * to school symbols depending on the school's rank
 * @example
 *  [
 *    ['any', ['>=', ['get', 'rank_unrounded'], 0], ['<', ['get', 'rank_unrounded'], 20]],
 *    'school-icon-0',
 *    ['any', ['>=', ['get', 'rank_unrounded'], 20], ['<', ['get', 'rank_unrounded'], 40]],
 *    'school-icon-1'
 *  ]
 */
export const getSchoolsLayerIconImageCaseDefinition = (): (
  | Expression
  | string
)[] => {
  const stepDef: (Expression | string)[] = [];
  const existingInternals = SCHOOLS_LEGEND_INTERVALS;
  const additionalInterval: LegendInterval = {
    start: 101,
    end: +Infinity,
    color: '#000',
    mapboxImageID: SCHOOL_MARKER_IMAGE_IDS.SCHOOL_MARKER_IMAGE_UNRANKED,
  };

  [...existingInternals]
    .concat([additionalInterval])
    .forEach(({ start, end, mapboxImageID }) => {
      stepDef.push([
        'all',
        ['>', ['number', ['get', 'rank_unrounded'], Infinity], start],
        ['<=', ['number', ['get', 'rank_unrounded'], Infinity], end],
      ]);
      if (mapboxImageID) {
        stepDef.push(mapboxImageID);
      }
    });
  return stepDef;
};

const SCHOOL_DEFAULT_COLOR = '#DCDCDC';

/**
 * Given a school's rank, return the color used to fill its district layer
 */
export const getSchoolDistrictFeatureColor = (rank: number): string => {
  for (const interval of SCHOOLS_LEGEND_INTERVALS) {
    if (rank > interval.start && rank <= interval.end) {
      return (
        interval.color ||
        SCHOOL_DEFAULT_COLOR /* this fallback should never be hit */
      );
    }
  }
  return SCHOOL_DEFAULT_COLOR;
};

/**
 * Generate a Mapbox GL expression for the halftone fill definition
 */
export const getBlocksLayerHalftoneDefinition = (
  intervals: LegendInterval[],
  layerId: VectilesMetrics,
  propType?: string,
): Expression => {
  /* If no breaks are available for the given metric, apply transparent color to features */
  const attribute = isConsumerLayerEnum(layerId)
    ? layerId
    : `${layerId}_${propType?.toLowerCase()}`;
  const value = ['number', ['get', attribute], -Infinity];
  return [
    'case',
    ...flatten(
      intervals.map((item, index) => {
        let condition: Expression = [
          'all',
          ['>=', value, item.start],
          ['<=', value, item.end],
        ];
        if (index === intervals.length - 1) {
          condition = ['>=', value, item.start];
        }
        return [condition, `halftone-shade-${index}`];
      }),
    ),
    'halftone-shade-empty',
  ] as Expression;
};

/**
 * Generate a Mapbox GL expression for the color fill definition
 */
export const getBlocksLayerFillInterpolateDefinition = (
  intervals: LegendInterval[],
): (string | number)[] => {
  /* If no breaks are available for the given metric, apply transparent color to features */
  return flatten([
    /* -Infinity is set as the fallback value, rendering a transparent fill color */
    -Infinity,
    TRANSPARENT_COLOR,
    ...intervals.map((breakDef) => [breakDef.start, breakDef.color]),
  ]);
};

/**
 * Get the complete fill definition for a layer
 */
export const getBlocksLayerFillDefinition = (
  hasMonochromeOverlay: boolean,
  propertyType?: PropertyTypeEnum | null,
  intervals?: LegendInterval[],
  activeLayerId?: VectilesMetrics | null,
): FillPaint => {
  const propType = propertyType
    ? PROPERTY_TYPE_TO_VECTILES_TYPE[propertyType]
    : undefined;
  if (intervals && activeLayerId) {
    if (hasMonochromeOverlay) {
      return {
        'fill-pattern': getBlocksLayerHalftoneDefinition(
          intervals,
          activeLayerId,
          propType,
        ),
      };
    } else {
      const attribute = isConsumerLayerEnum(activeLayerId)
        ? activeLayerId
        : `${activeLayerId}_${propType?.toLowerCase()}`;
      return {
        'fill-color': [
          'interpolate-hcl',
          ['linear'],
          /* -Infinity will be assigned to any `null` value, causing the fallback step color to be used */
          ['number', ['get', attribute], -Infinity],
          ...getBlocksLayerFillInterpolateDefinition(intervals),
        ] as Expression,
      };
    }
  } else {
    return {
      'fill-color': TRANSPARENT_COLOR,
    };
  }
};

/**
 * Reformat the legend breaks passed via props to prepare for display in the UI
 */
export const getLegendColorTableForLayer = (
  vectilesMetricGroup: VectilesMetricGroups,
  intervals: LegendInterval[],
): LegendColorTable => {
  const vectilesMetricGroupConfig =
    VECTILES_METRICS_GROUP_CONFIG[vectilesMetricGroup];
  // Average price is currency
  const colorTable = getColorTableFromIntervals({
    intervals,
    numberType: vectilesMetricGroupConfig.numberType,
    mainMenuValue: vectilesMetricGroup,
  });

  colorTable.forEach((row, i) => {
    row.patternImageIcon = HALFTONE_IMAGES?.[i]?.icon.toString();
  });
  return colorTable;
};
