import React, { Children, ReactNode } from 'react';
import classNames from 'classnames';

import { PhotoRepresentation } from '@hcs/types';

import { NoImagesIcon } from '../../../../svgs/icons/generics';
import { NoContent } from '../../../global/loading-errors-null/NoContent';
import { Skeleton } from '../../../global/loading-errors-null/Skeleton';
import { ThumbnailImage } from '../../carousels/ThumbnailCarousel';

import styles from './ImageSection.module.css';

export interface ImageSectionProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  dataHcEventSection?: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  images?: {
    photoRepresentation: PhotoRepresentation;
    onClick?: VoidFunction;
  }[];
  children?: ReactNode;
  isLoading?: boolean;
  nullState?: {
    isNull: boolean;
    content: ReactNode;
    className?: string;
  };
}
export const ImageSection = ({
  dataHcName,
  dataHcEventSection,
  className,
  images,
  isLoading,
  children,
  nullState,
}: ImageSectionProps) => {
  const skeletons = [
    <Skeleton key="skel-1" dataHcName={`${dataHcName}-skeleton`} />,
    <Skeleton key="skel-2" dataHcName={`${dataHcName}-skeleton`} />,
    <Skeleton key="skel-3" dataHcName={`${dataHcName}-skeleton`} />,
  ];
  const numChildren = isLoading ? skeletons.length : Children.count(children);
  const numImages = numChildren + (images?.length || 0);
  const cellType =
    numImages > 2 ? 'multi' : numImages === 2 ? 'double' : 'single';
  if (nullState?.isNull && nullState.content) {
    return (
      <NoContent
        dataHcName={`${dataHcName}-null`}
        Icon={NoImagesIcon}
        className={nullState.className}
      >
        <span>{nullState.content}</span>
      </NoContent>
    );
  }
  return (
    <section
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(styles.ImageSection, className, styles[cellType])}
    >
      {isLoading ? (
        skeletons
      ) : (
        <>
          {Children.map(children, (child, i) => {
            if (i <= 2) {
              return (
                <div
                  data-hc-name={`${dataHcName}-image-${i}`}
                  className={styles.ThumbnailImage}
                  key={`img-${i}`}
                >
                  {child}
                </div>
              );
            } else {
              return null;
            }
          })}
          {numChildren < 3 &&
            images?.map(({ photoRepresentation: { httpUrl } }, i) =>
              numChildren + i <= 3 ? (
                <ThumbnailImage
                  key={`image-${i}`}
                  dataHcName={`${dataHcName}-image-${i}`}
                  url={httpUrl}
                  className={styles[cellType]}
                />
              ) : null,
            )}
        </>
      )}
    </section>
  );
};
