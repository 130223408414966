import { CerberusExplorerIcon } from '@hcs/design-system';
import { AppSlugs } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_CERBERUS_EXPLORER: AppConfig = {
  name: 'Cerberus Explorer',
  applicationKey: 'internal-cerberus-explorer',
  type: 'internal',
  appSlug: AppSlugs.CerberusExplorer,
  Icon: CerberusExplorerIcon,
  rootPath: '/cerberus-explorer',
  descriptionLong: 'Easily view raw data',
  descriptionShort: 'Easily view raw data',
  feOnlyApp: true,
};

export enum VIEW_PATHS_CERBERUS_EXPLORER {
  PROPERTY_STATE = 'property-state',
  PROPERTY_SEARCH = 'property-search',
  STATS = 'stats',
}
