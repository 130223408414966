import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { SearchIcon } from '../../../../svgs/icons/generics';

import styles from './SearchInputStyle.module.css';

interface SearchInputStyleProps {
  dataHcName?: string;
  dataHcEventSection?: string;
  className?: string;
  children: ReactNode;
  showIcon?: boolean;
  unstyled?: boolean;
}
export const SearchInputStyle = ({
  dataHcName,
  dataHcEventSection,
  children,
  className,
  showIcon = true,
  unstyled = false,
}: SearchInputStyleProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={classNames(
        styles.SearchInputStyle,
        {
          [styles.unstyled]: unstyled,
        },
        className,
      )}
    >
      {showIcon && <SearchIcon dataHcName={'search-icon'} size="sm" />}
      {children}
    </div>
  );
};
