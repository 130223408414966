import { useMemo } from 'react';

import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import { useCompDocuments } from './useCompDocuments';

export const useCompDocIdsByCompId = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const query = useCompDocuments(reportId, compType);
  const { data: compDocuments } = query;
  return {
    ...query,
    data: useMemo(() => {
      const results: { [key: string]: string } = {};
      compDocuments?.forEach((compDocument) => {
        results[compDocument.data.compID] = compDocument.documentId;
      });
      return results;
    }, [compDocuments]),
  };
};
