import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OfferCreateObj } from '@hcs/types';

import { OfferApi } from '../api';
import { createOfferFormToNewOffer } from '../utils/offerForm.utils';

export const useOfferCreate = () => {
  return useMutation<void, AxiosError, OfferCreateObj, void>(
    async (offerCreateObj) => {
      const newOffer = createOfferFormToNewOffer(offerCreateObj);
      await OfferApi.createOffer(newOffer);
    },
  );
};
