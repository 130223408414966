import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useComponentDidMount } from '@hcs/hooks';
import { logWarning } from '@hcs/utils';
import { FIXED_QUERY_SELECTOR } from '@hcs/webapps';

import styles from './Flyout.module.css';

export interface FlyoutProps {
  dataHcName: string;
  active: boolean;
  displayText: string;
  flyoutDimensions: {
    width: number;
    height: number;
  };
  peek?: number;
  isStatic?: boolean;
  offset?: number;
  flyoutFrom: {
    direction: 'right' | 'left';
    position: 'top' | 'bottom';
  };
  icon?: ReactNode;
  withDuration?: {
    duration: number;
    handleClose: () => void;
  };
  children: ReactNode;
}

// Renders Popover outside of current DOM tree
const FlyoutContentPortal = ({ children }: { children: ReactNode }) => {
  const elm = document.querySelector(FIXED_QUERY_SELECTOR);
  return !elm ? null : createPortal(children, elm);
};

export const Flyout = ({
  dataHcName,
  active,
  displayText,
  flyoutDimensions,
  offset = 0,
  peek = 0,
  isStatic = false,
  flyoutFrom,
  icon,
  withDuration,
  children,
}: FlyoutProps) => {
  if (React.Children.count(children) !== 1) {
    logWarning(
      `[Flyout] Expects a single child. Received ${React.Children.count} children`,
    );
  }

  useEffect(() => {
    let hideTimeout: NodeJS.Timeout;
    if (active && withDuration) {
      hideTimeout = setTimeout(() => {
        withDuration.handleClose();
      }, withDuration.duration);
    }

    return () => clearTimeout(hideTimeout);
  }, [active]);

  const parentRef = useRef<HTMLDivElement>(null);
  const [offsets, setOffsets] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  useComponentDidMount(() => {
    const handlePositionChange = () => {
      if (parentRef?.current) {
        const { top, left, height, width } =
          parentRef.current.getBoundingClientRect();

        setOffsets({
          top:
            top +
            (flyoutFrom.position === 'bottom'
              ? height - offset - flyoutDimensions.height
              : offset),
          left:
            left +
            (flyoutFrom.direction === 'right'
              ? width
              : flyoutDimensions.width * -1),
        });
      }
    };

    handlePositionChange();
    window.addEventListener('resize', handlePositionChange);
    window.addEventListener('scroll', handlePositionChange, true);

    return () => {
      window.removeEventListener('resize', handlePositionChange);
      window.removeEventListener('scroll', handlePositionChange, true);
    };
  });

  const displayElement = (
    <div
      style={{
        left:
          active || isStatic
            ? 0
            : flyoutDimensions.width *
                (flyoutFrom.direction === 'right' ? -1 : 1) -
              peek * (flyoutFrom.direction === 'right' ? -1 : 1),
      }}
      className={classNames(styles.FlyoutContent, {
        [styles.left]: flyoutFrom.direction === 'left',
      })}
    >
      {displayText}
      {icon}
    </div>
  );

  return (
    <>
      <FlyoutContentPortal>
        <div
          data-hc-name={dataHcName}
          className={styles.Content}
          style={{
            top: offsets.top,
            left: offsets.left,
          }}
        >
          <div
            style={{
              width: flyoutDimensions.width,
              height: flyoutDimensions.height,
            }}
            className={styles.FlyoutContainer}
          >
            {displayElement}
          </div>
        </div>
      </FlyoutContentPortal>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === React.Fragment) {
            logWarning(
              `[Flyout] Cannot pass Fragment as direct child element - unable to forward ref`,
            );
          }
          return React.cloneElement(child, {
            ...child.props,
            ref: parentRef,
          });
        } else {
          return null;
        }
      })}
    </>
  );
};
