import { useMemo } from 'react';

import { useCompFields } from '@hcs/property-state';
import { CompFields, CompTypes } from '@hcs/types';
import { FilterFieldsListType, ReportId } from '@hcs/types';

import { COMP_FILTER_FIELD_CONTROLS } from '../features/CompFilters/CompFiltersControls';
import {
  COMP_FILTER_KEYWORD_SEARCH_FEATURES_RENTAL,
  COMP_FILTER_KEYWORD_SEARCH_FEATURES_SALE,
} from '../features/CompFilters/CompFiltersControls/CompFilterKeywordSearch';
import { reportFeaturesSupportedAny } from '../utils/reportConfig.utils';

import { useReportConfig } from './useReportConfig';

// A Hook to return comp fields based on user preferences and defaults
export const useCompFieldsFilters = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const isRental = compType === CompTypes.Rental;
  const { data: reportConfig } = useReportConfig(reportId);
  const compFieldsQuery = useCompFields(compType);
  const order = useMemo(() => {
    const results: FilterFieldsListType | undefined =
      compFieldsQuery.data?.order.filter((f) => {
        return !!COMP_FILTER_FIELD_CONTROLS[f] || f === CompFields.distance;
      });
    if (
      reportFeaturesSupportedAny(
        reportConfig,
        isRental
          ? COMP_FILTER_KEYWORD_SEARCH_FEATURES_RENTAL
          : COMP_FILTER_KEYWORD_SEARCH_FEATURES_SALE,
      )
    ) {
      results?.unshift(
        isRental
          ? 'listingDetailsRental.remarks'
          : 'listingDetailsSale.remarks',
      );
    }
    return results;
  }, [compFieldsQuery]);
  return {
    ...compFieldsQuery,
    data: {
      ...compFieldsQuery.data,
      order,
    },
  };
};
