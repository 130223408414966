import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { hookEnabledError } from '@hcs/errors';

import { SingleSignOnApi } from '../api/SingleSignOn.api';

export const QUERY_KEY_ORG_IS_SSO_ONLY = 'QUERY_KEY_ORG_IS_SSO_ONLY';
export const useOrgIsSsoOnly = (orgId: number | undefined) => {
  return useQuery<boolean, AxiosError>(
    [QUERY_KEY_ORG_IS_SSO_ONLY, orgId],
    async () => {
      if (orgId) {
        const { ssoMandatory } = await SingleSignOnApi.fetchIsSso(orgId);
        return !!ssoMandatory;
      } else {
        throw hookEnabledError(`useOrgIsSsoOnly`, 'orgId');
      }
    },
    {
      enabled: !!orgId,
    },
  );
};
