import { ReactNode } from 'react';
import classNames from 'classnames';

import {
  Dialog,
  GreenCheckIcon,
  GreyMinusIcon,
  HalfGreenCheckIcon,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { useActiveState, useScreenSize } from '@hcs/hooks';
import { SelfServicePlanTier } from '@hcs/types';
import { formatMoney, formatNumber, formatPercent } from '@hcs/utils';

import {
  SELF_SERVICE_COSTS,
  SELF_SERVICE_INCLUDED_UNITS,
  SELF_SERVICE_PRODUCTS,
  SELF_SERVICE_TIERS,
  SelfServeCostUnits,
} from '../../configs';
import { useSelfServicePlan } from '../../hooks';

import styles from './SelfServicePlanCompare.module.css';

const dataHcName = 'self-service-plan-compare';

const SMALL_SCREEN_NAME_CELL_WIDTH = 140;

const CELL_WIDTH = 180;

const EXCLUDE_ICON = <GreyMinusIcon height={20} />;

export interface SelfServicePlanCompareProps {
  currentPlanId?: string;
  unit?: SelfServeCostUnits;
  sections?: ('INCLUDED' | 'PRODUCTS' | 'COSTS')[];
  tiers?: SelfServicePlanTier[];
}
export const SelfServicePlanCompare = ({
  currentPlanId,
  unit: unitProp,
  sections = ['INCLUDED', 'PRODUCTS', 'COSTS'],
  tiers = ['Basic', 'Pro', 'Teams', 'Enterprise'],
}: SelfServicePlanCompareProps) => {
  const { data: currentPlan } = useSelfServicePlan(currentPlanId || undefined);
  const { isMobileWidth: useStickyNameColumn } = useScreenSize();
  const showIncluded = sections?.includes('INCLUDED');
  const showProducts = sections?.includes('PRODUCTS');
  const showCosts = sections?.includes('COSTS');
  const tiersToRender = SELF_SERVICE_TIERS.filter((tier) =>
    tiers.includes(tier.key),
  );
  return (
    <Table dataHcName={`${dataHcName}-included-per-month`}>
      <TableHeader>
        <TableHeaderCell
          sticky={useStickyNameColumn}
          width={useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined}
        />
        {tiersToRender.map((tier) => {
          return (
            <TableHeaderCell
              className={classNames(styles.PlanHeader, {
                [styles.currentPlan]: currentPlan?.name === tier.key,
              })}
              width={CELL_WIDTH}
              key={tier.key}
              align="center"
            >
              {tier.key}
            </TableHeaderCell>
          );
        })}
      </TableHeader>
      {showIncluded && (
        <TableRow className={styles.Row} key="included-separator">
          <TableCell
            className={styles.Cell}
            wordWrap
            sticky={useStickyNameColumn}
            width={
              useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
            }
          >
            <span className={styles.SectionHeader}>Included per Month</span>
          </TableCell>
          {tiersToRender.map((tier) => {
            return (
              <TableCell
                key={`included-break-${tier.key}`}
                className={classNames({
                  [styles.currentPlan]: currentPlan?.name === tier.key,
                })}
              />
            );
          })}
        </TableRow>
      )}
      {showIncluded &&
        SELF_SERVICE_INCLUDED_UNITS.map(({ key, name, unit }) => {
          if (unitProp && unit !== unitProp) return null;
          return (
            <TableRow className={styles.Row} key={`included-${key}`}>
              <TableCell
                className={styles.Cell}
                wordWrap
                sticky={useStickyNameColumn}
                width={
                  useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
                }
              >
                {name}
              </TableCell>
              {tiersToRender.map((tier) => {
                const { value } = tier.included[key] || {};
                return (
                  <TableCell
                    className={classNames(styles.Cell, {
                      [styles.currentPlan]: currentPlan?.name === tier.key,
                    })}
                    key={`included-${key}-${tier.key}`}
                    align="center"
                    width={CELL_WIDTH}
                  >
                    {typeof value === 'number'
                      ? formatNumber(value)
                      : !value
                        ? EXCLUDE_ICON
                        : value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      {showProducts && (
        <TableRow className={styles.Row} key="products-separator">
          <TableCell
            className={styles.Cell}
            wordWrap
            sticky={useStickyNameColumn}
            width={
              useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
            }
          >
            <span className={styles.SectionHeader}>
              Access to HouseCanary Products
            </span>
          </TableCell>
          {tiersToRender.map((tier) => {
            return (
              <TableCell
                key={`products-break-${tier.key}`}
                className={classNames({
                  [styles.currentPlan]: currentPlan?.name === tier.key,
                })}
              />
            );
          })}
        </TableRow>
      )}
      {showProducts &&
        SELF_SERVICE_PRODUCTS.map(({ key, name }, i) => {
          return (
            <TableRow className={styles.Row} key={`${key}-i`}>
              <TableCell
                className={styles.Cell}
                wordWrap
                sticky={useStickyNameColumn}
                width={
                  useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
                }
              >
                {name}
              </TableCell>
              {tiersToRender.map((tier) => {
                const { value } = tier.products[key] || {};
                return (
                  <TableCell
                    className={classNames(styles.Cell, {
                      [styles.currentPlan]: currentPlan?.name === tier.key,
                    })}
                    key={`product-${key}-${tier.key}`}
                    align="center"
                    width={CELL_WIDTH}
                  >
                    {value === 'partial' ? (
                      <HalfGreenCheckIcon height={20} />
                    ) : value ? (
                      <GreenCheckIcon height={20} />
                    ) : (
                      EXCLUDE_ICON
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      {showCosts && (
        <TableRow className={styles.Row} key="costs-separator">
          <TableCell
            className={styles.Cell}
            wordWrap
            sticky={useStickyNameColumn}
            width={
              useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
            }
          >
            <span className={styles.SectionHeader}>Additional Costs</span>
          </TableCell>
          {tiersToRender.map((tier) => {
            return (
              <TableCell
                key={`costs-break-${tier.key}`}
                className={classNames({
                  [styles.currentPlan]: currentPlan?.name === tier.key,
                })}
              />
            );
          })}
        </TableRow>
      )}
      {showCosts &&
        SELF_SERVICE_COSTS.map(({ key, name, unit, discount }) => {
          if (unitProp && unit !== unitProp) return null;
          return (
            <TableRow className={styles.Row} key={`cost-${key}`}>
              <TableCell
                className={styles.Cell}
                wordWrap
                sticky={useStickyNameColumn}
                width={
                  useStickyNameColumn ? SMALL_SCREEN_NAME_CELL_WIDTH : undefined
                }
              >
                {name}
              </TableCell>
              {tiersToRender.map((tier) => {
                const { value } = tier.costs[key] || {};
                return (
                  <TableCell
                    className={classNames(styles.Cell, {
                      [styles.currentPlan]: currentPlan?.name === tier.key,
                    })}
                    key={`cost-${key}-${tier.key}`}
                    align="center"
                    width={CELL_WIDTH}
                  >
                    {typeof value === 'string' ? (
                      value
                    ) : value ? (
                      <>
                        <b>
                          {discount
                            ? formatPercent(value, false)
                            : formatMoney(
                                value,
                                value % 1 ? { precision: 2 } : undefined,
                              )}
                        </b>
                        {discount ? ' discount ' : ' '}/ {unit}
                      </>
                    ) : (
                      EXCLUDE_ICON
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
    </Table>
  );
};

export const SelfServicePlanCompareLauncher = ({
  trigger,
}: {
  trigger: ReactNode;
}) => {
  const { active, handleClose, handleOpen } = useActiveState();
  return (
    <>
      <span onClick={handleOpen} data-hc-name={`${dataHcName}-trigger`}>
        {trigger}
      </span>
      <Dialog
        dataHcName={`${dataHcName}-dialog`}
        active={active}
        onClose={handleClose}
      >
        <SelfServicePlanCompare />
      </Dialog>
    </>
  );
};
