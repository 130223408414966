import { Dialog, TextButton } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';
import { MeaningfulEventTypes } from '@hcs/types';
import { formatDate } from '@hcs/utils';

import { useSelfServiceCancelPlan } from '../../hooks';

const dataHcName = 'self-service-cancel-subscription';
export const SelfServiceCancelSubscription = () => {
  const { active, handleClose, handleOpen } = useActiveState();
  const { isLoading, mutate } = useSelfServiceCancelPlan();
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  if (!orgSsInfo) return null;
  const currentPeriodStart =
    orgSsInfo.active_plan?.billing_period_start &&
    formatDate(new Date(orgSsInfo.active_plan.billing_period_start));
  const currentPeriodEnd =
    orgSsInfo.active_plan?.billing_period_end &&
    formatDate(new Date(orgSsInfo.active_plan.billing_period_end));
  return (
    <>
      <TextButton
        dataHcName={`${dataHcName}-trigger`}
        onClick={(e) => {
          e.stopPropagation();
          handleOpen();
        }}
      >
        Cancel Subscription
      </TextButton>
      <Dialog
        title="Confirm plan cancellation"
        dataHcName={`${dataHcName}-dialog`}
        dataHcEventSection="Cancel Subscription Dialog"
        active={active}
        onClose={handleClose}
        actions={[
          {
            dataHcName: `${dataHcName}-cancel`,
            label: 'Cancel',
            secondary: true,
            onClick: handleClose,
          },
          {
            dataHcName: `${dataHcName}-confirm`,
            label: 'Confirm',
            primary: true,
            dataHcEventType: MeaningfulEventTypes.Goal,
            disabled: isLoading,
            onClick: () => {
              mutate();
              handleClose();
            },
          },
        ]}
      >
        {/* note that any changes here should be matched in hcs-mail-renderer repo: SelfServiceCancelEmail template */}
        We're sorry to see you go! Please confirm your cancellation by clicking
        the “Confirm” button below. You will continue to have access to the
        platform until the end of your current{' '}
        {orgSsInfo.self_serve_plan?.term === 'Month'
          ? 'billing period'
          : 'annual subscription'}
        , which ends on <b>{currentPeriodEnd}</b>. After{' '}
        <b>{currentPeriodEnd}</b>, you will no longer have access to the
        platform. Any usage charges incurred since <b>{currentPeriodStart}</b>{' '}
        will be charged to your payment method{' '}
        {orgSsInfo.self_serve_plan?.term === 'Month' ? 'on' : 'monthly until'}{' '}
        <b>{currentPeriodEnd}</b>.
      </Dialog>
    </>
  );
};
