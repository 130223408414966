import { useDispatch, useSelector } from 'react-redux';

import {
  AuthDevToolsPartialAppState,
  AuthDevToolsSliceState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { AUTH_DEV_TOOLS_REDUCER_KEY, authDevToolsSlice } from '../rtk';

export const useAuthDevToolsSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<AuthDevToolsPartialAppState, AuthDevToolsSliceState>(
      (state) => state[AUTH_DEV_TOOLS_REDUCER_KEY],
    ),
    actions: mapDispatchToSliceActions(dispatch, authDevToolsSlice),
  };
};
