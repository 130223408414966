import React, { useEffect, useMemo, useState } from 'react';

import { AutoComplete, AutoCompleteProps } from '@hcs/design-system';
import { BuyBoxListItem } from '@hcs/types';
import { SORT_ORDER } from '@hcs/types';

import { useBuyBoxList } from '../../';

interface SingleSelectConfig {
  selectType: 'single';
  value: BuyBoxListItem['id'] | null;
  onSelect: (v: BuyBoxListItem | null) => void;
  initialSelectedBB?: number;
}

export type InitialSelectedBBMulti = 'all' | number[] | undefined;

interface MultiSelectConfig {
  selectType: 'multi';
  initialSelectedBB: InitialSelectedBBMulti;
  onSelect: (v: BuyBoxListItem[], isSelectingAll: boolean) => void;
  value: BuyBoxListItem['id'][];
  label: string;
}

interface BuyBoxAutoCompleteProps
  extends Omit<
    AutoCompleteProps<BuyBoxListItem['id']>,
    'dataHcName' | 'options' | 'config'
  > {
  className?: string;
  config: SingleSelectConfig | MultiSelectConfig;
}

const dataHcName = 'buybox-autocomplete';
export const BuyBoxAutoComplete = (props: BuyBoxAutoCompleteProps) => {
  const { className, config, ...restProps } = props;
  const { initialSelectedBB, selectType, onSelect, value } = config;

  // After this component's API call for the buy boxes is complete,
  // it calls onSelect with the id's from initialSelectedBB only if initialized
  // is false
  const [initialized, setInitialized] = useState(false);

  const { data: filteredSortedList } = useBuyBoxList({
    removeNotActive: true,
    sort: {
      field: 'lastModified',
      order: SORT_ORDER.Desc,
    },
  });

  const buyBoxOptions = useMemo(() => {
    const options = filteredSortedList
      ? filteredSortedList.map((buyBoxListItem) => ({
          value: buyBoxListItem.id,
          label: buyBoxListItem.name,
          searchString: buyBoxListItem.name,
        }))
      : [];
    return options;
  }, [filteredSortedList]);

  /**
   * Since we fetch the buybox list in this component, and the parent wants
   * onSelect to be called with a whole BuyBox object from those results,
   * we:
   *
   *  1. Check if the initialSelectedBB is a valid buybox id from the results.
   *     If so, call onSelect with that buybox object.
   *  2. If no valid initialSelectedBB is supplied, call onSelect with
   *     the last modified buybox for single select and 'all' for multi.
   */
  useEffect(() => {
    if (!filteredSortedList.length || initialized) return;

    if (selectType === 'single' && value === null) {
      const lastModifiedBB = filteredSortedList[0];

      if (initialSelectedBB) {
        const initialSelectedBBResult: BuyBoxListItem | undefined =
          filteredSortedList.find((bb) => bb.id === initialSelectedBB);

        if (initialSelectedBBResult) {
          onSelect(initialSelectedBBResult);
        } else if (lastModifiedBB) {
          // value is null, and the initialSelectedBB provided is not valid
          // use the last modified BB
          onSelect(lastModifiedBB);
        }
      } else if (lastModifiedBB) {
        // value is null, and no initialSelectedBB was provided
        // use the last modified BB
        onSelect(lastModifiedBB);
      }
    } else if (selectType === 'multi') {
      // multi

      if (initialSelectedBB) {
        // try to use the initialSelectedBB, which is a provided array of buybox ids OR 'all', and call onSelect
        // with found buy boxes
        if (initialSelectedBB === 'all') {
          onSelect(filteredSortedList, true);
        } else {
          onSelect(
            filteredSortedList.filter(
              (bb) => initialSelectedBB.indexOf(bb.id) > -1,
            ),
            false,
          );
        }
      } else {
        // select all by default if initialSelectedBB is NOT provided
        onSelect(filteredSortedList, true);
      }
    }

    setInitialized(true);
  }, [
    filteredSortedList,
    initialized,
    onSelect,
    selectType,
    value,
    initialSelectedBB,
  ]);

  const findSelectedBuyBox = (v: number) => {
    return filteredSortedList.find((buyBox) => buyBox.id === v);
  };

  return (
    <AutoComplete<BuyBoxListItem['id']>
      dataHcName={dataHcName}
      className={className}
      ref={React.createRef()}
      options={buyBoxOptions}
      error={undefined}
      disableClear
      config={
        config.selectType !== 'multi'
          ? {
              onSelect: (v: number | null) => {
                if (v !== null) {
                  const selectedBuyBox = findSelectedBuyBox(v);
                  if (selectedBuyBox) config.onSelect(selectedBuyBox);
                }
              },
              selectType: 'single',
              value: config.value ? config.value : undefined,
            }
          : {
              label: config.label,
              onSelect: (v: number[] | null) => {
                if (v !== null) {
                  const selected = filteredSortedList.filter((bb) =>
                    v.includes(bb.id),
                  );
                  config.onSelect(
                    selected,
                    filteredSortedList.length === selected.length,
                  );
                }
              },
              selectType: 'multi',
              value: config.value ? config.value : [],
            }
      }
      {...restProps}
    />
  );
};
