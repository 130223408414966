import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import styles from './Checkbox.module.css';

export interface CheckboxTheme {
  disabled?: string;
  checked?: string;
  Check?: string;
  Label?: string;
}

export interface CheckboxProps extends OptionalEngagementProps {
  checked: boolean;
  dataHcName: string;
  onChange: (v: boolean) => void;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  detail?: ReactNode;
  disabledRightElement?: ReactNode;
  style?: CSSProperties;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  stopClickPropagation?: boolean;
  theme?: CheckboxTheme;
}
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      dataHcName,
      dataHcEventName,
      dataHcEventSection,
      dataHcEventType,
      onChange,
      children,
      className,
      disabled,
      label,
      detail,
      disabledRightElement,
      style,
      theme,
      onMouseEnter,
      onMouseLeave,
      stopClickPropagation,
    }: CheckboxProps,
    ref,
  ) => {
    return (
      <>
        <label
          data-hc-name={dataHcName}
          className={classNames(
            styles.Checkbox,
            {
              [styles.withDetail]: detail,
              [classNames(styles.disabled, theme?.disabled)]: disabled,
            },
            className,
          )}
          data-hc-event-type={dataHcEventType}
          data-hc-event-name={dataHcEventName}
          data-hc-event-section={dataHcEventSection}
          onClick={(e) => (stopClickPropagation ? e.stopPropagation() : null)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <input
            data-hc-name={`${dataHcName}-input`}
            checked={checked}
            className={styles.Input}
            disabled={disabled}
            onClick={(e) => {
              if (stopClickPropagation) e.stopPropagation();
              if (!disabled) onChange(!checked);
            }}
            onChange={() => null}
            type="checkbox"
            ref={ref}
          />
          <div
            data-hc-name={`${dataHcName}-check`}
            className={classNames(styles.Check, theme?.Check, {
              [classNames(styles.checked, theme?.checked)]: checked,
            })}
            style={style}
          />

          {label ? (
            <div
              data-hc-name={`${dataHcName}-label`}
              className={classNames(styles.Label, theme?.Label)}
            >
              {label}
              {detail ? (
                <div
                  data-hc-name={`${dataHcName}-detail`}
                  className={styles.Detail}
                >
                  {detail}
                </div>
              ) : null}
            </div>
          ) : null}

          {children}
        </label>

        {disabledRightElement}
      </>
    );
  },
);
