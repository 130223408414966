import { useMemo } from 'react';

import { CompTypes } from '@hcs/types';
import { CombinedUseQueryResult } from '@hcs/types';
import {
  AppraisalCompDocument,
  CompDocument,
  CompIdentifier,
  CompIdentifierHcAddressId,
  CompIdentifierId,
  CompIdentifierListing,
  CompIdentifierSchema,
  CompSchema,
  CompsFarmDocument,
  CompsFilteredDocument,
  RentalCompDocument,
  RentalCompsFarmDocument,
  RentalCompsFilteredDocument,
  ReportId,
} from '@hcs/types';
import { UserPropertyAdjustments } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useAppraisalCompDocuments } from '../hooks/useAppraisalCompDocuments';

import { useCompDocuments } from './useCompDocuments';
import { useCompsFarmDocument } from './useCompsFarmDocument';
import { useCompsFilteredDocument } from './useCompsFilteredDocument';

interface UseCompData {
  documentId: string | undefined;
  tagsForAddress?: string[] | null;
  compSchema: CompSchema;
  isHcSuggestedComp?: boolean;
  isAppraisalComp?: boolean;
  userPropertyAdjustments?: Pick<UserPropertyAdjustments, 'adjustments'>;
}
type UseCompResult = CombinedUseQueryResult<UseCompData | undefined>;

// Data methods for each CompIdentifier type
const _getDataForCompId = (
  compIdentifier: CompIdentifierId,
  farmDocument: CompsFarmDocument | RentalCompsFarmDocument,
  compDocuments: (CompDocument | RentalCompDocument | AppraisalCompDocument)[],
  filteredDocument: CompsFilteredDocument | RentalCompsFilteredDocument,
  appraisalCompDocuments: AppraisalCompDocument[],
): UseCompData | undefined => {
  const { compId } = compIdentifier;
  const compDocument = compDocuments.find(
    (document) => document.data.compID === compId,
  );
  const isHcSuggestedComp = !!filteredDocument?.data?.hcSuggestedComps?.some(
    (hcCompId) => hcCompId === compId,
  );
  const isAppraisalComp = !!appraisalCompDocuments?.some(
    (d) => d.data.compID === compId,
  );
  if (compDocument) {
    return {
      compSchema: compDocument.data,
      tagsForAddress: compDocument.tagsForAddress,
      documentId: compDocument.documentId,
      isHcSuggestedComp,
      isAppraisalComp,
      userPropertyAdjustments: compDocument.userPropertyAdjustments,
    };
  } else {
    const schemaFromFarm = farmDocument.data.farm?.[compId];
    const userPropertyAdjustments =
      farmDocument.userPropertyAdjustments?.[compId];
    if (schemaFromFarm) {
      return {
        compSchema: schemaFromFarm,
        documentId: undefined,
        isHcSuggestedComp,
        isAppraisalComp,
        userPropertyAdjustments,
      };
    }
  }
  return undefined;
};

const _getDataForSchema = (
  compIdentifier: CompIdentifierSchema,
  farmDocument: CompsFarmDocument | RentalCompsFarmDocument,
  compDocuments: (CompDocument | RentalCompDocument)[],
): UseCompData => {
  const compId = compIdentifier.compSchema.compID;
  const userPropertyAdjustments =
    farmDocument.userPropertyAdjustments?.[compId];
  const compDocument = compDocuments.find(
    (document) => document.data.compID === compId,
  );
  if (compDocument) {
    return {
      compSchema: compDocument.data,
      documentId: compDocument.documentId,
      userPropertyAdjustments: compDocument.userPropertyAdjustments,
    };
  }
  return {
    compSchema: compIdentifier.compSchema,
    documentId: undefined,
    userPropertyAdjustments,
  };
};

const _getDataForListing = (
  compIdentifier: CompIdentifierListing,
  farmDocument: CompsFarmDocument | RentalCompsFarmDocument,
  compDocuments: (CompDocument | RentalCompDocument)[],
): UseCompData | undefined => {
  const { listingId, hcMlsId } = compIdentifier.listingIdentifier;
  // Look for listing in comp documents
  const compDocument = compDocuments.find((document) => {
    const compHcMlsId =
      compIdentifier.compType === CompTypes.Rental
        ? document.data.propertyState.complexFieldsRental?.currentHcMlsId
        : document.data.propertyState.complexFieldsSale?.currentHcMlsId;
    const compListingId =
      compIdentifier.compType === CompTypes.Rental
        ? document.data.propertyState.complexFieldsRental?.currentListingId
        : document.data.propertyState.complexFieldsSale?.currentListingId;
    return compListingId === listingId && compHcMlsId === hcMlsId;
  });
  if (compDocument) {
    return {
      compSchema: compDocument.data,
      documentId: compDocument.documentId,
      userPropertyAdjustments: compDocument.userPropertyAdjustments,
    };
  }
  // Look for listing in farm comp schemas
  for (const compId in farmDocument.data.farm) {
    const compSchema = farmDocument.data.farm[compId];
    const compHcMlsId =
      compIdentifier.compType === CompTypes.Rental
        ? compSchema?.propertyState.complexFieldsRental?.currentHcMlsId
        : compSchema?.propertyState.complexFieldsSale?.currentHcMlsId;
    const compListingId =
      compIdentifier.compType === CompTypes.Rental
        ? compSchema?.propertyState.complexFieldsRental?.currentListingId
        : compSchema?.propertyState.complexFieldsSale?.currentListingId;
    if (compSchema && compListingId === listingId && compHcMlsId === hcMlsId) {
      return {
        compSchema,
        documentId: undefined,
      };
    }
  }

  return undefined;
};

const _getDataForHcAddressId = (
  compIdentifier: CompIdentifierHcAddressId,
  farmDocument: CompsFarmDocument | RentalCompsFarmDocument,
  compDocuments: (CompDocument | RentalCompDocument)[],
): UseCompData | undefined => {
  const { hcAddressId } = compIdentifier;
  // Look for hcAddressId in comp documents
  const compDocument = compDocuments.find((document) => {
    return document.data.propertyState.hcAddressId === hcAddressId;
  });
  if (compDocument) {
    return {
      compSchema: compDocument.data,
      documentId: compDocument.documentId,
      userPropertyAdjustments: compDocument.userPropertyAdjustments,
    };
  }
  // Look for hcAddressId in farm comp schemas
  for (const compId in farmDocument.data.farm) {
    const compSchema = farmDocument.data.farm[compId];
    if (compSchema?.propertyState.hcAddressId === hcAddressId) {
      return {
        compSchema,
        documentId: undefined,
      };
    }
  }

  return undefined;
};

// Returns comp schema from the comp document if it exists
// otherwise use the comp schema from the farm list
export const useComp = (
  reportId: ReportId,
  compIdentifier: CompIdentifier,
): UseCompResult => {
  // All results are dependent on the comp documents regardless of compIdentifier.type
  const farmQuery = useCompsFarmDocument(reportId, compIdentifier.compType);
  const appraisalCompsQuery = useAppraisalCompDocuments(reportId);
  const compsQuery = useCompDocuments(reportId, compIdentifier.compType);
  const filteredQuery = useCompsFilteredDocument(
    reportId,
    compIdentifier.compType,
  );

  return useMemo(() => {
    // Combined metadata of all dependent queries
    const combinedQueryDeps = combineUseQueryResult([
      farmQuery,
      compsQuery,
      filteredQuery,
    ]);
    // Ensure dependent queries are loaded before attempting to return data
    if (!combinedQueryDeps.isSuccess) {
      return { ...combinedQueryDeps, data: undefined };
    } else {
      const { data: appraisalCompDocuments } = appraisalCompsQuery;
      const { data: compDocuments } = compsQuery;
      const { data: farmDocument } = farmQuery;
      const { data: filteredDocument } = filteredQuery;
      // This scenario should never happen, but set error flag if it does
      if (!compDocuments || !farmDocument || !filteredDocument) {
        return {
          ...combinedQueryDeps,
          isSuccess: false,
          isError: true,
          error: 'Missing comp data',
          data: undefined,
        };
      }
      if (compIdentifier.type === 'compId') {
        const data = _getDataForCompId(
          compIdentifier,
          farmDocument,
          compDocuments,
          filteredDocument,
          appraisalCompDocuments || [],
        );

        return {
          ...combinedQueryDeps,
          // Set error state if comp is not part of the report
          ...(!data
            ? {
                isError: true,
                isSuccess: false,
                error: 'Comp does not exist on this report',
              }
            : {}),
          data,
        };
      } else if (compIdentifier.type === 'appraisalComp') {
        const data = _getDataForCompId(
          { ...compIdentifier, type: 'compId' },
          farmDocument,
          appraisalCompsQuery.data || [],
          filteredDocument,
          appraisalCompDocuments || [],
        );

        return {
          ...combinedQueryDeps,
          // Set error state if comp is not part of the report
          ...(!data
            ? {
                isError: true,
                isSuccess: false,
                error: 'Comp does not exist on this report',
              }
            : {}),
          data,
        };
      } else if (compIdentifier.type === 'schema') {
        return {
          ...combinedQueryDeps,
          data: _getDataForSchema(compIdentifier, farmDocument, compDocuments),
        };
      } else if (compIdentifier.type === 'listing') {
        return {
          // Now include the listing query in the metadata dependencies
          ...combineUseQueryResult([farmQuery, compsQuery]),
          data: _getDataForListing(compIdentifier, farmDocument, compDocuments),
        };
      } else if (compIdentifier.type === 'hcAddressId') {
        return {
          // Now include the hcAddressIdToCompQuery in the metadata dependencies
          ...combineUseQueryResult([farmQuery, compsQuery]),
          data: _getDataForHcAddressId(
            compIdentifier,
            farmDocument,
            compDocuments,
          ),
        };
      }
    }
    return { ...combinedQueryDeps, data: undefined };
  }, [
    appraisalCompsQuery,
    compsQuery,
    compIdentifier,
    farmQuery,
    filteredQuery,
  ]);
};
