import React from 'react';
import classNames from 'classnames';

import { Skeleton } from '@hcs/design-system';
import {
  CompSchema,
  PropertyStateArgsProps,
  PropertyStateFields,
  PropertyStateFieldsConfigProp,
  PropertyStateFieldsConfigProps,
} from '@hcs/types';

import { COMP_FIELDS_CONFIG } from '../../configs/CompFields';
import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs/index';
import { isCompField } from '../../utils';

import styles from './PropertySummaryDetails.module.css';

export const PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE: PropertyStateFieldsConfigProp =
  [
    PropertyStateFields.currentPrice,
    PropertyStateFields.currentStatusDate,
    [
      PropertyStateFields.bedrooms,
      PropertyStateFields.bathrooms,
      PropertyStateFields.livingArea,
    ],
    PropertyStateFields.currentStatus,
  ];
export const PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_RENTAL: PropertyStateFieldsConfigProp =
  [
    PropertyStateFields.currentPriceRental,
    PropertyStateFields.currentStatusDateRental,
    [
      PropertyStateFields.bedrooms,
      PropertyStateFields.bathrooms,
      PropertyStateFields.livingArea,
    ],
    PropertyStateFields.currentStatusRental,
  ];
export interface PropertySummaryDetailsProps
  extends PropertyStateArgsProps,
    PropertyStateFieldsConfigProps {
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  isLoading?: boolean;
  comp?: Pick<CompSchema, 'distance' | 'similarity' | 'compID'>;
  size?: 'sm' | 'md';
}
const dataHcName = 'property-summary-details';
export const PropertySummaryDetails = ({
  className,
  comp,
  isLoading,
  isRental,
  fields: fieldsProp,
  size = 'md',
  propertyStateArgs,
}: PropertySummaryDetailsProps) => {
  const fields = fieldsProp
    ? fieldsProp
    : isRental
      ? PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_RENTAL
      : PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(
        styles.PropertySummaryDetails,
        styles[size],
        className,
      )}
    >
      {fields.map((field, i) => {
        if (Array.isArray(field)) {
          return (
            <div
              data-hc-name={`${dataHcName}-detail-row`}
              className={styles.DetailRow}
              key={`field-row-${i}`}
            >
              <div
                className={styles.DetailLabel}
                data-hc-name={`${dataHcName}-detail-label`}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  field
                    .map((multiField) => {
                      return (
                        isCompField(multiField)
                          ? COMP_FIELDS_CONFIG[multiField]
                          : PROPERTY_STATE_FIELD_CONFIGS[multiField]
                      ).labelMicro;
                    })
                    .join(' / ')
                )}
              </div>
              <div
                className={styles.DetailValue}
                data-hc-name={`${dataHcName}-detail-value`}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  field
                    .map((multiField) => {
                      return isCompField(multiField)
                        ? COMP_FIELDS_CONFIG[multiField].formatValueShort?.(
                            comp,
                          )
                        : PROPERTY_STATE_FIELD_CONFIGS[
                            multiField
                          ].formatValueShort?.(propertyStateArgs);
                    })
                    .join(' / ')
                )}
              </div>
            </div>
          );
        } else if (isCompField(field)) {
          const { label, formatValue } = COMP_FIELDS_CONFIG[field];
          return (
            <div
              data-hc-name={`${dataHcName}-detail-row`}
              className={styles.DetailRow}
              key={`field-row-${i}`}
            >
              <div
                data-hc-name={`${dataHcName}-detail-label`}
                className={styles.DetailLabel}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  label
                )}
              </div>
              <div
                data-hc-name={`${dataHcName}-detail-value`}
                className={styles.DetailValue}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  formatValue(comp)
                )}
              </div>
            </div>
          );
        } else {
          const { label, labelDynamic, formatValue } =
            PROPERTY_STATE_FIELD_CONFIGS[field];
          return (
            <div
              data-hc-name={`${dataHcName}-detail-row`}
              className={styles.DetailRow}
              key={`field-row-${field}`}
            >
              <div
                data-hc-name={`${dataHcName}-detail-label`}
                className={styles.DetailLabel}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  labelDynamic?.({ propertyStateArgs: propertyStateArgs }) ||
                  label
                )}
              </div>
              <div
                data-hc-name={`${dataHcName}-detail-value`}
                className={styles.DetailValue}
              >
                {isLoading ? (
                  <Skeleton dataHcName={`${dataHcName}-skeleton`} type="text" />
                ) : (
                  formatValue(propertyStateArgs)
                )}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
