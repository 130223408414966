import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { usePrevious } from '@hcs/hooks';
import { Cluster, ClusterProps } from '@hcs/maps';
import { MultiUnitMarker } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerProps, PropertyMarkerTypes } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { ReportId } from '@hcs/types';
import { CompsListTypes, OpenMarkerPopupComp } from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';
import { locationToGeoLocation } from '@hcs/utils';

import { useCompDocIdsByCompId } from '../../../../hooks';
import { useCompsList } from '../../../../hooks/useCompsList';

import styles from './CompsListMapMarkers.module.css';

interface Props {
  reportId: ReportId;
  mapId: string;
  compType: CompTypes;
  compsListType: CompsListTypes;
  clustersEnabled: boolean;
  onClickCluster?: ClusterProps['onClick'];
  onClickMarker?: (openMarkerPopupComp: OpenMarkerPopupComp) => void;
}

export const CompsListMapMarkers = ({
  reportId,
  mapId,
  compType,
  compsListType,
  onClickMarker,
  onClickCluster,
  clustersEnabled,
}: Props) => {
  const clustersEnabledPrevious = usePrevious(clustersEnabled);
  const [clustersEnabledLocal, setClustersEnabledLocal] =
    useState(clustersEnabled);
  const { data: compDocIdsByCompId } = useCompDocIdsByCompId(
    reportId,
    compType,
  );
  const {
    state: { compsListQuery },
  } = useCompsList({ reportId, compType, compsListType });
  // If the parent changes update the local var if needed
  useEffect(() => {
    if (
      clustersEnabledPrevious !== clustersEnabled &&
      clustersEnabledLocal !== clustersEnabled
    ) {
      setClustersEnabledLocal(clustersEnabled);
    }
  }, [clustersEnabledLocal, clustersEnabledPrevious, clustersEnabled]);

  const markers = useMemo(() => {
    const resultsByStreetAddress: { [key: string]: PropertyMarkerProps[] } = {};
    const results: ReactElement[] = [];
    compsListQuery.data.forEach((compSchema) => {
      const propertyStateArgs: PropertyStateArgsCore = {
        propertyStateType: PropertyStateType.Core,
        propertyState: compSchema.propertyState,
      };
      const compGeoLocation = locationToGeoLocation(
        compSchema?.propertyState.location,
      );
      const selected = !!compDocIdsByCompId[compSchema?.compID || ''];
      if (compSchema && compGeoLocation) {
        const marker: PropertyMarkerProps = {
          type: PropertyMarkerTypes.Comp,
          mapId,
          markerId: `comp-${compSchema.compID}`,
          geoLocation: compGeoLocation,
          className: styles.Marker,
          listingStatus:
            compType === CompTypes.Rental
              ? getPropertyStateFieldValue(
                  PropertyStateFields.currentStatusRental,
                  propertyStateArgs,
                )
              : getPropertyStateFieldValue(
                  PropertyStateFields.currentStatus,
                  propertyStateArgs,
                ),
          selected,
          onClick: () => {
            onClickMarker?.({
              type: 'comp',
              compIdentifier: { type: 'schema', compType, compSchema },
            });
          },
          label: formatNumberAbbrev(
            getPropertyStateFieldValue(
              compType === CompTypes.Rental
                ? PropertyStateFields.currentPriceRental
                : PropertyStateFields.currentPrice,
              propertyStateArgs,
            ),
          ),
          hoverable: true,
        };

        const streetAddressKey =
          compSchema.propertyState.location?.address || '';

        if (resultsByStreetAddress[streetAddressKey]) {
          resultsByStreetAddress[streetAddressKey]?.push(marker);
        } else {
          resultsByStreetAddress[streetAddressKey] = [marker];
        }
      }
    });
    Object.entries(resultsByStreetAddress).forEach((entry) => {
      const result = entry[1];
      const firstResult = result[0];
      if (firstResult) {
        if (result.length > 1) {
          results.push(
            <MultiUnitMarker
              type={PropertyMarkerTypes.Comp}
              geoLocation={firstResult.geoLocation}
              propertyMarkers={result}
              key={`multi-${result[0]?.markerId}`}
            />,
          );
        } else {
          results.push(
            <PropertyMarker {...firstResult} key={firstResult.markerId} />,
          );
        }
      }
    });
    return results;
  }, [compType, compDocIdsByCompId, compsListQuery.data, mapId, onClickMarker]);
  return clustersEnabledLocal ? (
    <Cluster mapId={mapId} onClick={onClickCluster} onClickBehavior="fit">
      {markers}
    </Cluster>
  ) : (
    markers
  );
};
