import { useSearchParams } from 'react-router-dom';

import { SS_CHECKOUT_SESSION_PARAM } from '../constants/selfService.constants';

export const useSelfServiceCheckoutSessionParam = () => {
  const [searchParams] = useSearchParams(
    new URLSearchParams(window.location.search),
  );
  const sessionParam = searchParams.get(SS_CHECKOUT_SESSION_PARAM);
  return {
    sessionParam,
  };
};
