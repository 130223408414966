import React from 'react';

import { Anchor, Appreciation, TableCell, TableRow } from '@hcs/design-system';
import {
  ListingStatusCerberus,
  TransactionHistoryActionsCallback,
  TransactionHistoryEvent,
  TransactionHistoryOnClickRow,
  ValueTypes,
} from '@hcs/types';
import {
  formatDateShort,
  formatMissing,
  formatMoney,
  formatMoneyPerMonth,
  formatNumber,
} from '@hcs/utils';

import { ListingStatusIndicator } from '../ListingStatusIndicator';

import styles from './PropertyTransactionHistoryTable.module.css';

export interface PropertyTransactionHistoryTableRowProps {
  dataHcName?: string;
  transactionHistoryEvent: TransactionHistoryEvent;
  showPriceChanges?: boolean;
  onClick?: TransactionHistoryOnClickRow;
  valueType?: ValueTypes;
  isLoading?: boolean;
  isError?: boolean;
  isMostRecentEventInGroup: boolean;
  actions?: TransactionHistoryActionsCallback;
}

export const PropertyTransactionHistoryTableRow = ({
  dataHcName: dataHcNameProp,
  transactionHistoryEvent,
  showPriceChanges,
  onClick,
  isMostRecentEventInGroup,
  valueType,
  actions,
}: PropertyTransactionHistoryTableRowProps) => {
  const dataHcName = dataHcNameProp || 'property-transaction-history-table';
  const isRental =
    ('isRental' in transactionHistoryEvent &&
      transactionHistoryEvent.isRental) ||
    valueType === 'RENTAL';
  const isActiveEvent =
    transactionHistoryEvent.status === ListingStatusCerberus.Active;
  const isClosedEvent =
    transactionHistoryEvent.status === ListingStatusCerberus.Closed;
  const transactionType = isRental
    ? 'Rental'
    : transactionHistoryEvent.distressed
      ? 'Distressed'
      : transactionHistoryEvent.reo
        ? 'REO'
        : transactionHistoryEvent.armsLength === false && isClosedEvent
          ? 'Non-Arms-Length'
          : transactionHistoryEvent.armsLength && isClosedEvent
            ? 'Arms-Length'
            : null;
  const isPriceChangeEvent =
    isActiveEvent && !!Math.abs(transactionHistoryEvent.priceVariance || 0);
  if (isPriceChangeEvent && !showPriceChanges) {
    return null;
  }
  const daysOnMarketCumulative =
    // Present '--' Days on Market in the UI for the initial event
    !isActiveEvent || !!transactionHistoryEvent.daysOnMarketCumulative
      ? transactionHistoryEvent.daysOnMarketCumulative
      : null;

  return (
    <TableRow
      dataHcName={dataHcName}
      onClick={() =>
        onClick?.(transactionHistoryEvent, isMostRecentEventInGroup)
      }
    >
      <TableCell dataHcName={`${dataHcName}-date`}>
        {formatDateShort(transactionHistoryEvent.date)}
      </TableCell>
      <TableCell dataHcName={`${dataHcName}-event`}>
        {isPriceChangeEvent ? (
          'PRICE CHANGE'
        ) : (
          <ListingStatusIndicator
            value={{
              type: 'listingStatus',
              value: transactionHistoryEvent.status,
            }}
            isRental={isRental}
          />
        )}
      </TableCell>
      <TableCell dataHcName={`${dataHcName}-type`}>
        {formatMissing(transactionType)}
      </TableCell>
      <TableCell align={'left'} dataHcName={`${dataHcName}-price`}>
        {isRental
          ? formatMoneyPerMonth(transactionHistoryEvent.price)
          : formatMoney(transactionHistoryEvent.price)}
      </TableCell>
      <TableCell align={'left'}>
        {isPriceChangeEvent ? (
          formatMissing(transactionHistoryEvent.priceVariance)
        ) : (
          <Appreciation
            dataHcName={`${dataHcName}-appreciation`}
            appreciation={
              isClosedEvent &&
              transactionHistoryEvent.priceChangeLastClose &&
              transactionHistoryEvent.priceChangePctLastClose
                ? {
                    absoluteValue: transactionHistoryEvent.priceChangeLastClose,
                    rate: transactionHistoryEvent.priceChangePctLastClose,
                  }
                : undefined
            }
          />
        )}
      </TableCell>
      <TableCell align={'left'} dataHcName={`${dataHcName}-dom`}>
        {formatNumber(daysOnMarketCumulative)}
      </TableCell>
      <TableCell dataHcName={`${dataHcName}-source`}>
        {formatMissing(
          transactionHistoryEvent.sourceType === 'deed'
            ? 'Public Record'
            : transactionHistoryEvent.sourceId?.slice(3),
        )}
      </TableCell>
      {actions && (
        <TableCell
          dataHcName={`${dataHcName}-actions`}
          className={styles.Actions}
        >
          {actions(transactionHistoryEvent, isMostRecentEventInGroup)?.map(
            (action, i) => {
              if (action.type === 'anchorProps') {
                return (
                  <Anchor
                    key={`${action.value.dataHcName}-${i}`}
                    {...action.value}
                  />
                );
              } else {
                return action.value;
              }
            },
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

PropertyTransactionHistoryTableRow.defaultProps = {
  isTableRow: true,
};
