import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CerberusApi } from '@hcs/cerberus';
import {
  MlsLookupDocument,
  MlsLookupQuery,
  MlsLookupQueryVariables,
} from '@hcs/types';
import { logException } from '@hcs/utils';

export const QUERY_KEY_MLS_LOOKUP = 'QUERY_KEY_MLS_LOOKUP';
export type MlsLookupQueryOptions = UseQueryOptions<
  MlsLookupQuery['lookupMls'],
  AxiosError,
  MlsLookupQuery['lookupMls'],
  [typeof QUERY_KEY_MLS_LOOKUP, number | undefined]
>;
export const useMls = (
  mlsId: number | undefined,
  options?: MlsLookupQueryOptions,
): UseQueryResult<MlsLookupQuery['lookupMls']> => {
  return useQuery(
    [QUERY_KEY_MLS_LOOKUP, mlsId],
    async () => {
      if (mlsId) {
        const response = await CerberusApi.fetchQuery<
          MlsLookupQuery,
          MlsLookupQueryVariables
        >(MlsLookupDocument, {
          cerberusMlsIdentifierInput: { hcMlsID: mlsId },
        });
        return response.lookupMls;
      } else {
        const msg = 'Error in useMls: mlsId is unexpectedly undefined';
        logException(msg);
        throw msg;
      }
    },
    {
      ...options,
      enabled:
        !!mlsId && (options?.enabled !== undefined ? options.enabled : true),
    },
  );
};
