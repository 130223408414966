export enum RANGE_TYPES {
  MIN = 'min',
  MAX = 'max',
}

export type FilterRangeType = {
  [RANGE_TYPES.MIN]?: string;
  [RANGE_TYPES.MAX]?: string;
};

export enum FORMAT_TYPES {
  NUMBER = 'NUMBER',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
  COMMA = 'COMMA',
  MONEY = 'MONEY',
}
