import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconButton } from '@hcs/design-system';
import { InputNumber } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { NoContent } from '@hcs/design-system';
import { DeleteIcon, SearchAddIcon } from '@hcs/design-system';
import { CustomCellField, CustomCellProps } from '@hcs/property-state';
import {
  PropertyStateTable,
  PropertyStateTableProps,
} from '@hcs/property-state';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';

import { ReportPreviewSearchParams } from '../../features/ReportPreview';
import { useReportPreviewInputs } from '../../hooks/useReportPreviewInputs';

import styles from './AppraisalCompareCreate.module.css';

const dataHcName = 'appraisal-compare-create-comps-table';

const ValueCell: CustomCellField<{ value: number }>['CustomCell'] = (
  props: CustomCellProps<{ value: number }>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { appraisalComps } = useReportPreviewInputs();
  const { value } = props.customData || {};
  const { propertyStateArgs } = props;
  return (
    <TableCell>
      <div className={styles.ValueCell}>
        ${' '}
        <InputNumber
          shouldFormat={{ shouldFormatNumber: true }}
          dataHcName={`${dataHcName}-value-input`}
          value={value || null}
          error={!value ? 'Adjusted Value Required.' : undefined}
          onChange={(value) => {
            // Appraisal Comps stored in search params as hcAddressId:value,hcAddressId:value
            searchParams.set(
              ReportPreviewSearchParams.AppraisalComps,
              appraisalComps
                ?.map(
                  (c) =>
                    `${c.cerberusInput.hcAddressId}:${
                      c.cerberusInput.hcAddressId ===
                      propertyStateArgs?.propertyState?.hcAddressId
                        ? value
                        : c.value
                    }`,
                )
                .join(',') || '',
            );
            setSearchParams(searchParams);
          }}
        />
      </div>
    </TableCell>
  );
};

const DeleteCell: CustomCellField<{ value: number }>['CustomCell'] = (
  props: CustomCellProps<{ value: number }>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { appraisalComps } = useReportPreviewInputs();
  const { propertyStateArgs } = props;
  return (
    <TableCell>
      <IconButton
        dataHcName={`${dataHcName}-remove`}
        icon={<DeleteIcon />}
        onClick={() => {
          searchParams.set(
            ReportPreviewSearchParams.AppraisalComps,
            appraisalComps
              ?.filter(
                (c) =>
                  c.cerberusInput.hcAddressId !==
                  propertyStateArgs?.propertyState?.hcAddressId,
              )
              .map((c) => `${c.cerberusInput.hcAddressId}:${c.value}`)
              .join(',') || '',
          );
          setSearchParams(searchParams);
        }}
      />
    </TableCell>
  );
};

const COLUMNS: PropertyStateTableProps<{ value: number }>['fields'] = [
  {
    type: 'customField',
    value: 'value',
    header: <TableHeaderCell>Appraiser-Adjusted Value</TableHeaderCell>,
    CustomCell: ValueCell,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatus,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatusDate,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentPrice,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bedrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bathrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.livingArea,
  },
  {
    type: 'customField',
    value: 'delete',
    header: <TableHeaderCell />,
    CustomCell: DeleteCell,
  },
];

export const AppraisalCompareCreateCompsTable = () => {
  const { appraisalComps } = useReportPreviewInputs();
  return appraisalComps ? (
    <PropertyStateTable<{ value: number }>
      dataHcName={dataHcName}
      fields={COLUMNS}
      data={appraisalComps.map(({ cerberusInput, value }) => {
        return {
          propertyStateCerberusInput: {
            propertyStateType: PropertyStateType.Preview,
            cerberusInput,
          },
          customData: { value },
        };
      })}
    />
  ) : (
    <NoContent Icon={SearchAddIcon} dataHcName={`${dataHcName}-no-content`}>
      Add appraisal-selected comps and their adjusted values
    </NoContent>
  );
};
