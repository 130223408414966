import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { StripePortalRequest, StripeRedirectResponse } from '@hcs/types';

import { SelfServiceApi } from '../api';

type SelfServiceCheckoutProps = UseMutationOptions<
  StripeRedirectResponse,
  AxiosError<{ status: string }>,
  StripePortalRequest,
  void
>;

export const useSelfServiceChangePayment = (
  options?: SelfServiceCheckoutProps,
) => {
  return useMutation<
    StripeRedirectResponse,
    AxiosError<{ status: string }>,
    StripePortalRequest,
    void
  >(async (payload: StripePortalRequest) => {
    return (await SelfServiceApi.changePayment(payload)).data;
  }, options);
};
