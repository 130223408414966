import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { ReportId } from '@hcs/types';
import { formatPercent } from '@hcs/utils';

import { useForecastChartBlockDocument } from '../../hooks/useForecastChartBlockDocument';
import { useForecastChartZipDocument } from '../../hooks/useForecastChartZipDocument';
import { useForecastStats } from '../../hooks/useForecastStats';
import { FORECAST_CHART_FEATURES } from '../ForecastChart/ForecastChart';

interface Props {
  reportId: ReportId;
}
const dataHcName = 'forecast-chart-button-summary';
export const FORECAST_CHART_BUTTON_SUMMARY_FEATURES = FORECAST_CHART_FEATURES;
export const ForecastChartButtonSummary = ({ reportId }: Props) => {
  const forecastZipQuery = useForecastChartZipDocument(reportId);
  const forecastBlockQuery = useForecastChartBlockDocument(reportId);
  const forecastStatsQuery = useForecastStats(reportId);
  if (forecastStatsQuery.isInitialLoading) {
    return (
      <>
        <Skeleton
          width="70%"
          type="text"
          dataHcName={`${dataHcName}-skeleton`}
        />
        <Skeleton
          width="44%"
          type="text"
          dataHcName={`${dataHcName}-skeleton`}
        />
      </>
    );
  } else if (forecastBlockQuery.isError && forecastZipQuery.isError) {
    return <>An error fetching chart data occurred</>;
  } else if (
    !forecastZipQuery.data?.data?.chart?.data?.length &&
    !forecastBlockQuery.data?.data?.chart?.data?.length
  ) {
    return <>Forecast data unavailable for this zip code</>;
  }

  return (
    <>
      <div>
        1 year:{' '}
        <span data-hc-name={`${dataHcName}-stats-year-1-value-zip`}>
          {formatPercent(forecastStatsQuery.data?.zip?.[0]?.percentDiff, true)}
        </span>{' '}
        (zip),{' '}
        <span data-hc-name={`${dataHcName}-stats-year-1-value-block`}>
          {formatPercent(
            forecastStatsQuery.data?.block?.[0]?.percentDiff,
            true,
          )}
        </span>{' '}
        (block)
      </div>
      <div>
        3 year:{' '}
        <span data-hc-name={`${dataHcName}-stats-year-3-value-zip`}>
          {formatPercent(forecastStatsQuery.data?.zip?.[2]?.percentDiff, true)}
        </span>{' '}
        (zip)
      </div>
    </>
  );
};
