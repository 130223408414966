import React, { useCallback, useRef, useState } from 'react';
import { Popup } from 'react-map-gl';
import classNames from 'classnames';
import { Offset } from 'mapbox-gl';

import { ExperienceFlag } from '@hcs/experience-flags';
import { useClickOutsideComponent } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';

import {
  CompPropertyCard,
  CompPropertyCardContent,
} from '../../features/CompPropertyCard';
import { useComp, useCompCompareDialogSlice } from '../../hooks';
import {
  COMP_PHOTO_CAROUSEL_FEATURES_RENTAL,
  COMP_PHOTO_CAROUSEL_FEATURES_SALE,
  CompPhotoCarousel,
} from '../CompPhotoCarousel';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from '../SubjectMarkerPopup/MarkerPopup.module.css';
import stylesDeprecated from './CompMarkerPopup.module.css';

export const MAX_CARD_WIDTH = '460';

interface Props {
  className?: string;
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  onClose: VoidFunction;
}
const dataHcName = 'comp-marker-popup';
export const COMP_MARKER_POPUP_FEATURES_SALE = [
  ReportFeatures.CompsSelect,
  ...COMP_PHOTO_CAROUSEL_FEATURES_SALE,
];
export const COMP_MARKER_POPUP_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelect,
  ...COMP_PHOTO_CAROUSEL_FEATURES_RENTAL,
];
const OFFSET: mapboxgl.Offset = [2, -35];
const OFFSET_NEXT: Offset = {
  top: [0, 35],
  left: [55, 0],
  'top-left': [0, 30],
  'bottom-left': [0, -35],
  right: [-55, 0],
  center: [0, -35],
  bottom: [0, -35],
  'top-right': [0, 30],
  'bottom-right': [0, -35],
};
export const CompMarkerPopup = ({
  reportId,
  compIdentifier,
  className,
  onClose,
}: Props) => {
  const [streetViewOpen, setStreetViewOpen] = useState(false);
  const compQuery = useComp(reportId, compIdentifier);
  const { compSchema } = compQuery.data || {};

  const handleClose = useCallback(() => {
    if (!streetViewOpen) {
      onClose();
    }
  }, [onClose, streetViewOpen]);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(ref, handleClose);
  const {
    actions: { compCompareDialogOpen },
  } = useCompCompareDialogSlice();
  const { latitude, longitude } = compSchema?.propertyState.location || {};
  if (!compSchema || !latitude || !longitude) {
    return null;
  }
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <Popup
          className={styles.MarkerPopup}
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          maxWidth={MAX_CARD_WIDTH}
          closeOnClick={false}
          onClose={handleClose}
          offset={OFFSET_NEXT}
        >
          <div ref={ref}>
            <CompPropertyCard
              reportId={reportId}
              compIdentifier={compIdentifier}
              onStreetViewToggle={setStreetViewOpen}
              horizontal={false}
            />
          </div>
        </Popup>
      }
      now={
        <Popup
          className={stylesDeprecated.CompMarkerPopup}
          longitude={longitude}
          latitude={latitude}
          closeButton={false}
          maxWidth={MAX_CARD_WIDTH}
          closeOnClick={false}
          onClose={handleClose}
          offset={OFFSET}
        >
          <div
            ref={ref}
            data-hc-name={dataHcName}
            className={classNames(
              stylesDeprecated.CompMarkerPopupContent,
              className,
            )}
          >
            <ReportFeaturesSupported
              reportId={reportId}
              reportFeatures={
                compIdentifier.compType === CompTypes.Rental
                  ? COMP_PHOTO_CAROUSEL_FEATURES_RENTAL
                  : COMP_PHOTO_CAROUSEL_FEATURES_SALE
              }
            >
              <div className={stylesDeprecated.Photos}>
                <CompPhotoCarousel
                  reportId={reportId}
                  compIdentifier={compIdentifier}
                  onStreetViewToggle={setStreetViewOpen}
                />
              </div>
            </ReportFeaturesSupported>
            <div
              className={stylesDeprecated.Content}
              onClick={() => compCompareDialogOpen(compIdentifier)}
            >
              <CompPropertyCardContent
                reportId={reportId}
                compIdentifier={compIdentifier}
              />
            </div>
          </div>
        </Popup>
      }
    />
  );
};
