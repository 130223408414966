import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosClient } from '@hcs/http-clients';
import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { UserLegacy } from '@hcs/types';
import {
  CheckInvitationCodeResponse,
  EditUserOrgPermissionsPayload,
  EditUserOrgPermissionsPayloadRequest,
  OrgUserSort,
  OrgUsersResponse,
} from '@hcs/types';
import { EHRMANTRAUT_ADMIN_URL, EHRMANTRAUT_URL } from '@hcs/urls';
import { camelCaseToSnakeCase } from '@hcs/utils';

export interface FetchOrgUsersParams {
  page?: number;
  itemsPerPage?: number;
  search?: string;
  orderBy?: {
    field: OrgUserSort['field'];
    direction: OrgUserSort['order'];
  };
}

const fetchOrgUsers = async (params?: FetchOrgUsersParams) => {
  /*
   * Some janky conversion here to give the BE the strange (for querystrings) format it expects for order_by
   * i.e. The BE expects the key for order_by to not be an array key i.e. order_by[]= (as axios.getUri would normally make it since the value is an array)
   * the BE also expects the value to be an array
   */
  const modifiedParams = (params: FetchOrgUsersParams) => {
    return {
      ...params,
      orderBy: params.orderBy
        ? JSON.stringify([
            snakecaseKeys({
              ...params.orderBy,
              field: camelCaseToSnakeCase(params.orderBy.field),
            }),
          ])
        : undefined,
    };
  };

  const response = await AxiosAccessTokenClientEhrm.get<OrgUsersResponse>(
    axios.getUri({
      url: `${EHRMANTRAUT_URL}/org_users`,
      params: params ? snakecaseKeys(modifiedParams(params)) : undefined,
    }),
  );
  return camelcaseKeys(response.data, { deep: true });
};

const fetchUserData = async (userId: string) => {
  const response = await AxiosAccessTokenClientEhrm.get<UserLegacy>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`,
  );
  return camelcaseKeys(response.data, { deep: true });
};

const editUserPermissionsForOrg = async (
  userId: number,
  editUserRolePayload: EditUserOrgPermissionsPayload,
) => {
  const editUserPermissionsPayload: EditUserOrgPermissionsPayloadRequest = {
    ...editUserRolePayload,
    id: userId,
  };

  const response = await AxiosAccessTokenClientEhrm.put<UserLegacy>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`,
    snakecaseKeys(editUserPermissionsPayload),
  );

  return camelcaseKeys(response.data, { deep: true });
};

const checkInvitationCode = async (invitationCode: string) => {
  const response = await AxiosClient.get<CheckInvitationCodeResponse>(
    `${EHRMANTRAUT_URL}/invitation/code/${invitationCode}`,
  );
  return camelcaseKeys(response.data, { deep: true });
};

export const OrgAdminApi = {
  fetchOrgUsers,
  fetchUserData,
  editUserPermissionsForOrg,
  checkInvitationCode,
  endLegacySubscriptions: async ({
    orgId,
    date,
  }: {
    orgId: number;
    date: string | null;
  }) => {
    const payload: { organization_id: number; date?: string } = {
      organization_id: orgId,
    };
    if (date) {
      payload.date = date;
    }
    await AxiosAccessTokenClientEhrm.post(
      `${EHRMANTRAUT_URL}/subscription/terminate`,
      payload,
    );
  },
};
