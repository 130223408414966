import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SelfServeCheckoutRequest, StripeRedirectResponse } from '@hcs/types';
import { SOLUTIONS_URL } from '@hcs/urls';

import { SelfServiceApi } from '../api';

type SelfServiceCheckoutProps = UseMutationOptions<
  StripeRedirectResponse,
  AxiosError<{ status: string }>,
  SelfServeCheckoutRequest,
  void
>;

export const useSelfServiceCheckout = (options?: SelfServiceCheckoutProps) => {
  return useMutation<
    StripeRedirectResponse,
    AxiosError<{ status: string }>,
    SelfServeCheckoutRequest,
    void
  >(async (payload) => {
    const cancel_url = payload.cancel_url || window.location.href;
    const success_url = payload.success_url || SOLUTIONS_URL;
    return (
      await SelfServiceApi.checkout({ ...payload, cancel_url, success_url })
    ).data;
  }, options);
};
