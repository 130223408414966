import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';

import { HuellApi } from '../api';

export const useUploadPersonalizationPhotoUser = (
  options?: UseMutationOptions<{ photoUrl: string }, { message: string }, File>,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<{ photoUrl: string }, { message: string }, File>(
    HuellApi.uploadPersonalizationPhotoUser,
    {
      onMutate: (variables) => {
        toastOpen({
          type: 'loading',
          duration: null,
          title: 'Uploading User Photo...',
        });
        options?.onMutate?.(variables);
      },
      onSuccess: (data, variables, context) => {
        toastOpen({
          type: 'loading-success',
          duration: null,
          title: 'Uploaded User Photo',
        });
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        toastOpen({
          type: 'loading-failure',
          duration: null,
          title: `Failed to Upload: ${error.message}`,
        });
        options?.onError?.(error, variables, context);
      },
    },
  );
};
