import { ReactNode, Suspense } from 'react';

import { LoadingSpinner } from '../LoadingSpinner';

interface Props {
  children: ReactNode;
}
export const SkeletonPage = ({ children }: Props) => {
  return (
    <Suspense
      fallback={
        <div id="chunk-loading" style={{ height: '100%' }}>
          <LoadingSpinner dataHcName={`app-skeleton-chunk-load`} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
