import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SearchByMlsNumberQueryKey } from '@hcs/types';

import { SearchByMlsNumberApi } from '../api';
import { SearchByMlsNumberContext } from '../context';

interface Props {
  // ID of Search instance (e.g. 'comps' or 'rental-comps')
  queryId: string;
}
export const useSearchByMlsNumber = ({ queryId }: Props) => {
  const { state, onSubmit } = useContext(SearchByMlsNumberContext);
  const queryKey: SearchByMlsNumberQueryKey = ['search-by-mls-number', queryId];
  const mlsNumber = state[queryId] || '';
  const query = useQuery(
    [...queryKey, mlsNumber],
    async function () {
      if (mlsNumber) {
        return await SearchByMlsNumberApi.submit({
          input: {
            items: [
              {
                mlsNumber,
              },
            ],
          },
        });
      }
      return;
    },
    { enabled: !!mlsNumber },
  );
  return {
    onSubmit: (value: string) => {
      onSubmit(queryId, value);
    },
    input: mlsNumber,
    query,
  };
};
