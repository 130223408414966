import { HcsEnvs } from '@hcs/types';

export const { ENV, IS_LOCAL } = ((): { ENV: HcsEnvs; IS_LOCAL: boolean } => {
  const envs = ['dev', 'stage', 'prod', 'local'];
  const hostParts = window.location.hostname.split('.');
  // Expects hosts to follow conventions:
  // solutions.local.[env].hc.housecanary.net => Local Dev Server
  // solutions.[env].hc.housecanary.net       => Non-Prod Deployments
  // solutions.housecanary.com                => Prod Deployments
  const envHostPart = hostParts[hostParts.length - 4] as HcsEnvs;
  const localHostPart = hostParts[hostParts.length - 5];
  const isLocal = localHostPart === 'local';
  if (envs.includes(envHostPart)) {
    return { IS_LOCAL: isLocal, ENV: envHostPart };
  } else {
    return { IS_LOCAL: isLocal, ENV: isLocal ? 'dev' : 'prod' };
  }
})();

const prPreviewPrefix = 'pull-';
export const PULL_REQUEST_ID =
  (window.location.hostname.startsWith(prPreviewPrefix) &&
    window.location.hostname.split('.')[0]?.replace(prPreviewPrefix, '')) ||
  undefined;

export const PULL_REQUEST_SUBDOMAIN = PULL_REQUEST_ID
  ? `${prPreviewPrefix}${PULL_REQUEST_ID}`
  : undefined;

export const APP_NAME = ((): string => {
  const hostParts = window.location.hostname.split('.');
  const appPart = PULL_REQUEST_ID === undefined ? hostParts[0] : hostParts[1];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return appPart!; // assert the array item as truthy since we know the URL hostname will always contain multiple parts
})();

// Query Selectors
// Root of main app dom tree
export const ROOT_QUERY_SELECTOR = '#react-root';
export const ROOT_PDF_CLASSNAME = 'pdf-root';
// Element outside of app dom tree for rendering fixed-position content
export const FIXED_QUERY_SELECTOR_ID = 'react-fixed-portal';
export const FIXED_QUERY_SELECTOR = `#${FIXED_QUERY_SELECTOR_ID}`;
export const SCROLL_QUERY_SELECTOR = '#react-scroll';
export const FIXED_ID = FIXED_QUERY_SELECTOR.replace('#', '');
export const SCROLL_ID = SCROLL_QUERY_SELECTOR.replace('#', '');

export const URL_PARAM_REDIRECT = 'redirect';
// URL Param to prevent adding redirect after logout
export const URL_PARAM_NO_REDIRECT = 'noRedirect';
let IS_SENTRY_ENABLED_INTERNAL = false;
try {
  IS_SENTRY_ENABLED_INTERNAL = __SENTRY_DSN__ !== undefined;
} catch {
  // do nothing - elsewhere we log sentry disabled
}
export const IS_SENTRY_ENABLED = IS_SENTRY_ENABLED_INTERNAL;
