import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import { SelfServeOrgInfoResponse } from '@hcs/types';
import { ACCOUNT_URL, ACCOUNT_URL_INTERNAL } from '@hcs/urls';

export const OrgSelfServeInfoApi = {
  fetchIsSso: async (orgId: number) => {
    const response = await AxiosAccessTokenClientBearer.get<null>(
      `${ACCOUNT_URL}/organizations/${orgId}/is-sso`,
    );
    return response.data;
  },
  fetchOrgSelfServeInfo: async () => {
    return await AxiosAccessTokenClientBearer.get<SelfServeOrgInfoResponse>(
      `${ACCOUNT_URL}/ss/org-info`,
    );
  },
  fetchOrgSelfServeInfoInternal: async (orgId: number) => {
    return await AxiosAccessTokenClientBearer.get<SelfServeOrgInfoResponse>(
      `${ACCOUNT_URL_INTERNAL}/organizations/${orgId}/ss-info`,
    );
  },
};
