import React from 'react';

export const PortfolioDashboard = React.lazy(
  () => import('./PortfolioDashboard'),
);

export const PortfolioSetupPage = React.lazy(
  () => import('./PortfolioSetupPage'),
);

export const PortfolioPage = React.lazy(() => import('./PortfolioPage'));
