import React, { ReactNode } from 'react';

import { useActiveState } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';

import {
  dataHcEventSectionCompsSavedFilters,
  dataHcEventSectionRentalSavedFilters,
} from '../CompSavedFilterSetCard';

import { CompSavedFiltersOverlayPage } from './CompSavedFiltersOverlayPage';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  trigger: ReactNode;
}
const dataHcName = 'comp-saved-filters-overlay-page-launcher';
export const CompSavedFiltersOverlayPageLauncher = ({
  reportId,
  compType,
  trigger,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const dataHcEventSection =
    compType === CompTypes.Rental
      ? dataHcEventSectionRentalSavedFilters
      : dataHcEventSectionCompsSavedFilters;
  return (
    <>
      <span
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSection}
        onClick={handleOpen}
      >
        {trigger}
      </span>
      <CompSavedFiltersOverlayPage
        onClose={handleClose}
        active={active}
        reportId={reportId}
        compType={compType}
      />
    </>
  );
};
