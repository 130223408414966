import { PayloadAction } from '@reduxjs/toolkit';

import {
  FitBoundsPayload,
  HcMapPreferences,
  HcMapSliceState,
  HcMapState,
  MapStyles,
  MoveMapProps,
  PropertyTypeEnum,
  VectilesMetricGroups,
  VectilesMetricIds,
} from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

import { VECTILES_METRICS_GROUP_CONFIG } from '../constants';
import {
  fitGeoLocationsToViewPort,
  getBoundsInfoFromViewportState,
} from '../utils/viewport.utils';

export const INITIAL_ZOOM = 14;
const PADDING = 100;

export type HcMapMountArgs = {
  mapId: string;
  mapState: Omit<HcMapState, 'boundsInfo'>;
};
export type HcMapShowMonochromeArgs = {
  mapId: string;
  visible: boolean;
};
export type HcMapChangeVectileGroupArgs = {
  mapId: string;
  vectilesMetricGroup: VectilesMetricGroups | null;
};
export type HcMapChangeVectileMetricArgs = {
  mapId: string;
  vectilesMetricId: VectilesMetricIds | null;
};
export const initialState: HcMapSliceState = {};
export const hcMapSlice = createHcReduxSlice({
  name: 'map',
  initialState,
  reducers: {
    hcMapMount(state, action: PayloadAction<HcMapMountArgs>) {
      state[action.payload.mapId] = {
        ...action.payload.mapState,
        boundsInfo: getBoundsInfoFromViewportState(
          action.payload.mapState.viewport,
        ),
      };
    },
    hcMapViewportChange(
      state,
      action: PayloadAction<{
        mapId: string;
        viewport?: MoveMapProps;
        options?: {
          changeZoom?: number;
        };
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        if (action.payload.options?.changeZoom) {
          map.viewport.zoom += action.payload.options?.changeZoom;
        }
        map.viewport = { ...map.viewport, ...action.payload.viewport };
        map.boundsInfo = getBoundsInfoFromViewportState(map.viewport);
      }
    },
    hcMapShowMarkers(
      state,
      action: PayloadAction<{
        mapId: string;
        showMarkers: boolean;
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.markers.showMarkers = action.payload.showMarkers;
      }
    },
    hcMapLayersShowMonochrome(
      state,
      action: PayloadAction<HcMapShowMonochromeArgs>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.heatmap.showMonochrome = action.payload.visible;
      }
    },
    hcMapChangeVectilesMetricGroup(
      state,
      action: PayloadAction<HcMapChangeVectileGroupArgs>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.heatmap.vectilesMetricGroup = action.payload.vectilesMetricGroup;
        if (action.payload.vectilesMetricGroup) {
          map.heatmap.vectilesMetricId =
            VECTILES_METRICS_GROUP_CONFIG[
              action.payload.vectilesMetricGroup
            ].vectilesMetricIds[0];
        } else {
          map.heatmap.vectilesMetricId = null;
        }
      }
    },
    hcMapLayersChangePropertyType(
      state,
      action: PayloadAction<{
        mapId: string;
        propertyType?: PropertyTypeEnum | null;
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.heatmap.propertyType = action.payload.propertyType;
      }
    },
    hcMapChangeZoom(
      state,
      action: PayloadAction<{
        mapId: string;
        zoom: number;
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.viewport = {
          ...map.viewport,
          zoom: action.payload.zoom,
        };
        map.boundsInfo = getBoundsInfoFromViewportState(map.viewport);
      }
    },
    hcMapChangeMapStyle(
      state,
      action: PayloadAction<{
        mapId: string;
        mapStyle: MapStyles;
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.mapStyle = action.payload.mapStyle;
      }
    },
    hcMapChangeVectilesMetric(
      state,
      action: PayloadAction<HcMapChangeVectileMetricArgs>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.heatmap.vectilesMetricId = action.payload.vectilesMetricId;
      }
    },
    hcMapApplyPreferences(
      state,
      action: PayloadAction<{
        mapId: string;
        preferences: HcMapPreferences;
      }>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        map.heatmap = {
          ...map.heatmap,
          ...action.payload.preferences.heatmap,
        };
      }
    },
    hcMapFitBoundsToGeoLocations(
      state,
      action: PayloadAction<FitBoundsPayload>,
    ) {
      const map = state?.[action.payload.mapId];
      if (map) {
        const viewPortInfo = fitGeoLocationsToViewPort(
          action.payload.coords,
          map,
          action.payload.padding || PADDING,
        );

        if (viewPortInfo) {
          const { coords: nextCoords, viewport: nextViewport } = viewPortInfo;
          map.viewport = {
            latitude: nextCoords.lat,
            longitude: nextCoords.lng,
            height: nextViewport.height,
            width: nextViewport.width,
            zoom: nextViewport.zoom,
          };
          map.boundsInfo = getBoundsInfoFromViewportState(map.viewport);
          map.fitId = action.payload.fitId;
        }
      }
    },
  },
});
