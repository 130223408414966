import { useMemo } from 'react';

import { CompIdentifierAppraisal, ReportId } from '@hcs/types';

import { useAppraisalCompDataSourcesDocuments } from '../hooks/useAppraisalCompDataSourcesDocuments';
import { useComp } from '../hooks/useComp';

interface Props {
  reportId: ReportId;
  compIdentifier: CompIdentifierAppraisal;
}
export const useAppraisalCompDataSourcesDocument = ({
  reportId,
  compIdentifier,
}: Props) => {
  const documentsQuery = useAppraisalCompDataSourcesDocuments(reportId);
  const { data: comp } = useComp(reportId, compIdentifier);
  const hcAddressId = comp?.compSchema.propertyState.hcAddressId;
  return useMemo(() => {
    return {
      ...documentsQuery,
      data: documentsQuery.data?.find(
        (c) => c.data.hc.hcAddressId === hcAddressId,
      ),
    };
  }, [documentsQuery, hcAddressId]);
};
