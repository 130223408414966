import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  SamlapiActivateProviderConfigRequestExt,
  SamlProviderConfig,
} from '@hcs/types';

import { SingleSignOnApi } from '../api';

import { QUERY_KEY_ORG_IS_SSO_ONLY } from './useOrgIsSsoOnly';
import { QUERY_KEY_ORG_SAML_PROVIDER } from './useOrgSamlProvider';

export const useActivateOrgSamlProvider = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    SamlProviderConfig,
    AxiosError<{ status: string; message?: string }>,
    {
      orgId: number;
      providerId: string;
    } & SamlapiActivateProviderConfigRequestExt
  >(
    async (payload) => {
      return await SingleSignOnApi.activateOrgSamlProvider(payload);
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEY_ORG_IS_SSO_ONLY,
          variables.orgId,
        ]);
        queryClient.setQueryData<SamlProviderConfig>(
          [QUERY_KEY_ORG_SAML_PROVIDER, variables.orgId],
          response,
        );
        toastOpen({
          type: 'success',
          title: 'Saml Activated Successfully',
        });
      },
    },
  );
};
