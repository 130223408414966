import React, { FunctionComponent, SVGProps as SVGPropsNative } from 'react';

export interface SVGProps extends SVGPropsNative<SVGElement> {
  dataHcName?: string;
}

export const useSvg = (
  Svg: FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >,
  { dataHcName, ...props }: SVGProps,
) => {
  const { ref, ...restProps } = props;
  const height = props.height || props.width ? props.height : '30px';
  return <Svg data-hc-name={dataHcName} {...restProps} {...{ height }} />;
};
