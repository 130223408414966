import {
  DraggingEvent,
  ExtrudeMode,
  FeatureCollection,
  GuideFeatureCollection,
  ModeProps,
} from '@nebula.gl/edit-modes';
import turfArea from '@turf/area';
import { polygon } from '@turf/helpers';

import { getEditHandlesForGeometry, updateMeasurements } from '@hcs/maps';
import { GeometryTypesEnum } from '@hcs/types';

const RECTANGLE_POINTS_AMOUNT = 4;

export class MeasureExtrudeMode extends ExtrudeMode {
  getGuides(props: ModeProps<FeatureCollection>): GuideFeatureCollection {
    const handles = [];

    const { data } = props;
    const { features } = data;

    for (const index of props.selectedIndexes) {
      if (index < features.length) {
        const feature = features[index];
        if (feature) {
          const { geometry } = feature;
          handles.push(...getEditHandlesForGeometry(geometry, index));
        }
      } else {
        console.warn(`selectedFeatureIndexes out of range ${index}`);
      }
    }

    return {
      type: 'FeatureCollection',
      features: handles,
    };
  }

  handleDragging(event: DraggingEvent, props: ModeProps<FeatureCollection>) {
    const guides = this.getGuides(props);

    if (guides && guides.features.length === RECTANGLE_POINTS_AMOUNT) {
      const polyCoords = guides?.features.map((feature) => {
        return feature.geometry.coordinates as [number, number];
      });
      polyCoords.push(
        guides?.features?.[0]?.geometry.coordinates as [number, number],
      );
      const poly = polygon([polyCoords]);
      updateMeasurements(
        event,
        props,
        GeometryTypesEnum.RECTANGLE,
        turfArea(poly),
        guides?.features?.[3]?.geometry.coordinates as [number, number],
        guides,
      );
    }
    super.handleDragging(event, props);
  }
}
