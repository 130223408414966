import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { Personalization } from '@hcs/types';
import { HUELL_URL } from '@hcs/urls';
import { expect404 } from '@hcs/utils';

export const HuellPersonalizationApi = {
  fetchPersonalization: async () => {
    try {
      const response = await AxiosAccessTokenClientEhrm.get<Personalization>(
        `${HUELL_URL}/personalization/`,
      );
      return camelcaseKeys(response.data, { deep: true }) as Personalization;
    } catch (err) {
      return expect404(err);
    }
  },
  updatePersonalization: async ({
    // EXP-1236: Remove fields that will break huell api
    id,
    userId,
    createdAt,
    updatedAt,
    organizationId,
    ...personalizationPayload
  }: Personalization) => {
    const response = await AxiosAccessTokenClientEhrm.post<
      Omit<Personalization, 'id'>,
      AxiosResponse<Personalization>
    >(
      `${HUELL_URL}/personalization/`,
      snakecaseKeys(personalizationPayload, { deep: true }),
    );
    return camelcaseKeys(response.data, { deep: true }) as Personalization;
  },
  uploadBrokerSignatureImage: async (file: File) => {
    const response = await AxiosAccessTokenClientEhrm.post<{
      photoUrl: string;
    }>(`${HUELL_URL}/personalization/signature/`, file, {
      headers: {
        'content-type': file.type,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  uploadPersonalizationPhotoUser: async (file: File) => {
    const response = await AxiosAccessTokenClientEhrm.post<{
      photoUrl: string;
    }>(`${HUELL_URL}/personalization/user_photo/`, file, {
      headers: {
        'content-type': file.type,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  uploadPersonalizationPhotoCompany: async (file: File) => {
    const response = await AxiosAccessTokenClientEhrm.post<{
      photoUrl: string;
    }>(`${HUELL_URL}/personalization/company_logo/`, file, {
      headers: {
        'content-type': file.type,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
};
