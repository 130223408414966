import { CompTypes } from '@hcs/types';
import {
  CompsFiltersDocument,
  DocumentRoles,
  RentalCompsFiltersDocument,
  ReportId,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useCompsFiltersDocument = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  return useSingleDocumentRole<
    CompsFiltersDocument | RentalCompsFiltersDocument
  >(
    reportId,
    compType === CompTypes.Rental
      ? DocumentRoles.RentalCompsFilters
      : DocumentRoles.CompsFilters,
  );
};
