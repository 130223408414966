import classNames from 'classnames';

import {
  ListingStatusCerberus,
  PropertyStateArgs,
  PropertyStateFields,
} from '@hcs/types';

import { PROPERTY_STATE_FIELD_CONFIGS } from '../../configs';

import styles from './PropertyCardPillLabel.module.css';

export interface PropertyCardPillLabelProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  value: ListingStatusCerberus;
  propertyStateArgs: PropertyStateArgs;
}
export const PropertyCardPillLabel = ({
  dataHcName,
  className,
  value,
  propertyStateArgs,
}: PropertyCardPillLabelProps) => {
  const { formatValue } =
    PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentStatus];
  const label = formatValue(propertyStateArgs);

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.PropertyCardPillLabel, className)}
    >
      <span
        data-hc-name={`${dataHcName}-pill-circle`}
        className={classNames(styles.PropertyCardPillCircle, {
          [styles.Active]: value === ListingStatusCerberus.Active,
        })}
      />
      {label}
    </div>
  );
};
