import React from 'react';

import { TwoColSection } from '@hcs/pdf/pdf-service';
import {
  Page,
  PageFooter,
  PageHeader,
  PageHeaderProduct,
} from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { CURRENT_STATUS_CONFIG } from '@hcs/property-state';
import { CURRENT_STATUS_RENTAL_CONFIG } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import {
  formatCityStateZip,
  formatDateShort,
  formatMissing,
  formatStreetAddress,
} from '@hcs/utils';

import { ListingAgentSection } from '../../features';
import { ListingConstructionSection } from '../../features';
import { ListingDetailsSection } from '../../features';
import { ListingFeaturesSection } from '../../features';
import { ListingHoaSection } from '../../features';
import { ListingLocationSection } from '../../features';
import { ListingRemarksSection } from '../../features';
import { ListingSummarySection } from '../../features';
import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

const REPORT_TITLE = 'MLS Listing Sheet';
const dataHcName = 'mls-listing-sheet-pdf';

const { formatValue: formatCurrentStatusSale } = CURRENT_STATUS_CONFIG;
const { formatValue: formatCurrentStatusRental } = CURRENT_STATUS_RENTAL_CONFIG;

export const MlsListingSheetPdf = () => {
  const { rentalListing, propertyStateCore } = useMlsListingSheetData();
  const listingDetails =
    propertyStateCore.propertyState?.[
      rentalListing ? 'listingDetailsRental' : 'listingDetailsSale'
    ];
  return (
    <Page
      dataHcName={dataHcName}
      header={
        <>
          <PageHeader
            cobrandLogo={false}
            headerDetails={[
              {
                dataHcName: `${dataHcName}-mls-number-status`,
                label: 'MLS # / Status',
                value: (
                  <>
                    <span data-hc-name={`${dataHcName}-mls-number`}>
                      {formatMissing(listingDetails?.listingId)}
                    </span>{' '}
                    /{' '}
                    <span data-hc-name={`${dataHcName}-listing-status`}>
                      {rentalListing
                        ? formatCurrentStatusRental(propertyStateCore)
                        : formatCurrentStatusSale(propertyStateCore)}
                    </span>
                  </>
                ),
              },
              rentalListing
                ? PropertyStateFields.daysOnMarketMlsRental
                : PropertyStateFields.daysOnMarketMls,
              rentalListing
                ? PropertyStateFields.lastClosePriceRental
                : PropertyStateFields.lastClosePrice,
              rentalListing
                ? PropertyStateFields.lastClosePricePerSqFtRental
                : PropertyStateFields.lastClosePricePerSqFt,
            ].map((field) => {
              if (typeof field === 'string') {
                const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
                return {
                  dataHcName: `${dataHcName}-${field}`,
                  value: <Display propertyStateArgs={propertyStateCore} />,
                  label,
                };
              } else {
                return field;
              }
            })}
          />
          <PageHeaderProduct
            productName={REPORT_TITLE}
            leftContentLabel={formatStreetAddress(
              propertyStateCore.propertyState?.location,
            )}
            leftContentValue={formatCityStateZip(
              propertyStateCore.propertyState?.location,
            )}
          />
        </>
      }
      footer={
        <PageFooter
          title={REPORT_TITLE}
          leftContent={
            <>
              <span data-hc-name={`${dataHcName}-report-creation-label`}>
                Report Creation Date:&nbsp;
              </span>
              <span data-hc-name={`${dataHcName}-report-creation-value`}>
                {formatDateShort(new Date())}
              </span>
            </>
          }
        />
      }
    >
      <ListingSummarySection />
      <ListingRemarksSection />
      <TwoColSection
        compact
        dataHcName={`${dataHcName}-data-tables`}
        contentLeft={
          <>
            <ListingFeaturesSection />
            <ListingConstructionSection />
          </>
        }
        contentRight={
          <>
            <ListingDetailsSection />
            <ListingAgentSection />
            <ListingLocationSection />
            <ListingHoaSection />
          </>
        }
      />
    </Page>
  );
};
