export const sum = (values: number[]) => values.reduce((a, c) => a + c);

export const isPositive = (value?: number) => value && value >= 0;

export const calculateCircumference = (radius: number) => {
  return 2 * Math.PI * radius;
};

export const getPercentage = (
  value: number,
  total: number,
  isRound = false,
  asDecimal = false,
  toFixed: number | undefined = undefined,
): number => {
  const initialPercentage = value / total;
  let percentage = asDecimal ? initialPercentage : initialPercentage * 100;

  if (toFixed) percentage = parseFloat(percentage.toFixed(toFixed));

  return isRound ? Math.round(percentage) : percentage;
};

export const getDecimalCount = (value: number) => {
  const numStr = String(value);
  if (numStr.includes('.')) {
    return numStr.split('.')[1]?.length || 0;
  }
  return 0;
};

export const roundToDecimalPlace = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const truncateToDecimalPlace = (value: number, decimal: number) => {
  const power = 10 ** decimal;
  return Math.trunc(value * power) / power;
};

export const findClosestToDivisibleBy = (
  closestTo: number,
  divisibleBy: number,
) => {
  // find the quotient
  const q = Math.round(closestTo / divisibleBy);
  // 1st possible closest number
  const n1 = divisibleBy * q;
  // 2nd possible closest number
  const n2 =
    closestTo * divisibleBy > 0 ? divisibleBy * (q + 1) : divisibleBy * (q - 1);
  // if true, then n1 is the
  // required closest number
  if (Math.abs(closestTo - n1) < Math.abs(closestTo - n2)) return n1;
  // else n2 is the required
  // closest number
  return n2;
};
