import React from 'react';
import classNames from 'classnames';
import { Operation } from 'fast-json-patch';

import { Button } from '@hcs/design-system';
import { CloseButton } from '@hcs/design-system';
import { RadioButton } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { CardTitle } from '@hcs/design-system';
import { SearchChip } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { usePatchReportPreferencesForUser } from '@hcs/huell';
import { usePatchSavedCompFilterSetsForUser } from '@hcs/huell';
import { useReportPreferencesForUser } from '@hcs/huell';
import {
  getPrimaryFilterSetIdByCompType,
  getPrimaryFilterSetPreferencesKey,
} from '@hcs/huell';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { isCompField } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { MeaningfulEventTypes, SavedCompFilterSet } from '@hcs/types';
import { CompTypes } from '@hcs/types';
import { CompsFiltersPaths, ReportId } from '@hcs/types';

import { useCompsFiltersDocument, useDocumentPatch } from '../../hooks';

import styles from './CompSavedFilterSetCard.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  filterSetId: string;
  filterSet: SavedCompFilterSet;
  onClose: VoidFunction;
}
const PATH_APPLIED_FILTER_SET: CompsFiltersPaths = '/data/appliedFilterSetId';
const dataHcName = 'comp-filter-set-card';
export const dataHcEventSectionCompsSavedFilters = 'Saved Comp Filters';
export const dataHcEventSectionRentalSavedFilters = 'Saved Rental Comp Filters';
export const CompSavedFilterSetCard = ({
  reportId,
  compType,
  className,
  filterSetId,
  filterSet,
  onClose,
}: Props) => {
  const isRental = compType === CompTypes.Rental;
  const dataHcEventSection = isRental
    ? dataHcEventSectionRentalSavedFilters
    : dataHcEventSectionCompsSavedFilters;

  const filterQuery = useCompsFiltersDocument(reportId, compType);
  const { data: reportPreferences } = useReportPreferencesForUser();
  const documentPatchMutation = useDocumentPatch(reportId, {
    onSuccess: onClose,
  });
  const { mutate: patchReportPreferencesForUser } =
    usePatchReportPreferencesForUser();
  const {
    active: deleteSavedFilterDialogActive,
    handleOpen: handleOpenSavedFilterDialog,
    handleClose: handleCloseSavedFilterDialog,
  } = useActiveState();
  const { mutate: patchSavedCompFilterSetsForUser } =
    usePatchSavedCompFilterSetsForUser({
      compType,
    });
  const primaryFilterSetPreferencesKey =
    getPrimaryFilterSetPreferencesKey(compType);
  const isPrimaryFilterSet =
    getPrimaryFilterSetIdByCompType(reportPreferences, compType) ===
    filterSetId;
  return (
    <Card
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSection}
      className={classNames(styles.CompSavedFilterSetCard, className)}
    >
      <CardTitle dataHcName={`${dataHcName}-title`} className={styles.Title}>
        {filterSet.label}
        <CloseButton
          dataHcName={`${dataHcName}-delete-button`}
          onClick={handleOpenSavedFilterDialog}
        />

        <Dialog
          type="small"
          active={deleteSavedFilterDialogActive}
          dataHcName={`${dataHcName}-delete-saved-filter-dialog`}
          onClose={handleCloseSavedFilterDialog}
          title="Delete Saved Filter"
          dataHcEventSection={dataHcEventSection}
          isOverlayDialog
          actions={[
            {
              dataHcName: `${dataHcName}-delete-saved-filter-dialog-confirm-delete`,
              dataHcEventName: isRental
                ? 'Delete Rental Comp Saved Filter Set'
                : 'Delete Comp Saved Filter Set',
              dataHcEventType: MeaningfulEventTypes.Goal,
              highlightRed: true,
              label: 'Confirm Delete',
              type: 'button',
              onClick: () => {
                patchSavedCompFilterSetsForUser?.([
                  { op: 'remove', path: `/${filterSetId}` },
                ]);
                if (isPrimaryFilterSet) {
                  patchReportPreferencesForUser?.([
                    {
                      op: 'remove',
                      path: `/${primaryFilterSetPreferencesKey}`,
                    },
                  ]);
                }
              },
              primary: true,
            },
            {
              dataHcName: `${dataHcName}-delete-saved-filter-dialog-cancel`,
              label: 'Cancel',
              type: 'button',
              onClick: handleCloseSavedFilterDialog,
              secondary: true,
            },
          ]}
        >
          <div className={styles.DeleteMessage}>
            Are you sure? <span>(This action is irreversible)</span>
          </div>
        </Dialog>
      </CardTitle>
      <div className={styles.FilterChips}>
        {Object.entries(filterSet.values).map((entry) => {
          const { field } = entry[1];
          if (isCompField(field)) {
            const { labelShort } = COMP_FIELDS_CONFIG[field];
            return (
              <SearchChip
                dataHcName={`filter-chip-${field}`}
                key={`filter-chip-${field}`}
                label={labelShort}
              />
            );
          } else {
            const { labelShort } = PROPERTY_STATE_FIELD_CONFIGS[field];
            return (
              <SearchChip
                dataHcName={`filter-chip-${field}`}
                key={`filter-chip-${field}`}
                label={labelShort}
              />
            );
          }
        })}
      </div>
      <div className={styles.Actions}>
        <RadioButton
          dataHcName={`${dataHcName}-default`}
          dataHcEventName={
            isRental
              ? 'Delete Rental Comp Saved Filter Set'
              : 'Delete Comp Saved Filter Set'
          }
          dataHcEventType={MeaningfulEventTypes.Goal}
          className={styles.RadioButton}
          label="Set filter as default"
          value={filterSetId}
          checked={isPrimaryFilterSet}
          onChange={(filterSetId) => {
            const operations: Operation[] = [
              isPrimaryFilterSet
                ? {
                    op: 'remove',
                    path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
                  }
                : {
                    op: 'add',
                    path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
                    value: filterSetId,
                  },
            ];
            patchReportPreferencesForUser?.(operations);
          }}
        />
        <Button
          dataHcName={`${dataHcName}-apply`}
          dataHcEventName={
            isRental
              ? 'Load Rental Comp Saved Filter Set'
              : 'Load Comp Saved Filter Set'
          }
          dataHcEventType={MeaningfulEventTypes.Goal}
          className={styles.LoadButton}
          label="Load filter"
          onClick={() => {
            if (filterQuery.data?.documentId) {
              documentPatchMutation.mutate({
                reportId,
                document: filterQuery.data,
                operations: [
                  {
                    op: 'add',
                    path: PATH_APPLIED_FILTER_SET,
                    value: filterSetId,
                  },
                ],
              });
            }
          }}
          primary
        />
      </div>
    </Card>
  );
};
