import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useAccount } from '@hcs/authn';
import { SelfServeOrgInfo, SelfServeOrgInfoResponse } from '@hcs/types';

import { OrgSelfServeInfoApi } from '../api';

export const QUERY_KEY_ORG_SELF_SERVE_INFO = 'QUERY_KEY_ORG_SELF_SERVE_INFO';
export const QUERY_KEY_ORG_SELF_SERVE_INFO_INTERNAL =
  'QUERY_KEY_ORG_SELF_SERVE_INFO_INTERNAL';
const augmentOrgInfo = (
  orgSsInfo: SelfServeOrgInfoResponse,
): SelfServeOrgInfo => {
  const activeUntil = orgSsInfo?.active_plan?.active_until;
  const currentPlan = orgSsInfo?.self_serve_plan;
  const upcomingPlan = orgSsInfo?.upcoming_self_serve_plan;
  return {
    ...orgSsInfo,
    canceled_subscription: !!(activeUntil && !upcomingPlan),
    changed_plan: !!(
      activeUntil &&
      upcomingPlan &&
      (upcomingPlan.name !== currentPlan?.name ||
        upcomingPlan.term !== currentPlan?.term)
    ),
  };
};
export const useOrgSelfServeInfo = (
  options?: UseQueryOptions<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string]
  >,
) => {
  const accountQuery = useAccount();
  return useQuery<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string]
  >(
    [QUERY_KEY_ORG_SELF_SERVE_INFO],
    async () => {
      try {
        const orgSsInfo = (await OrgSelfServeInfoApi.fetchOrgSelfServeInfo())
          .data;
        return augmentOrgInfo(orgSsInfo);
      } catch {
        return null;
      }
    },
    {
      ...options,
      enabled: options?.enabled === false ? false : !!accountQuery.data,
    },
  );
};

export const useOrgSelfServeInfoInternal = (
  orgId: number | undefined,
  options?: UseQueryOptions<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string, number | undefined]
  >,
) => {
  return useQuery<
    SelfServeOrgInfo | null,
    unknown,
    SelfServeOrgInfo | null,
    [string, number | undefined]
  >(
    [QUERY_KEY_ORG_SELF_SERVE_INFO_INTERNAL, orgId],
    async () => {
      try {
        if (!orgId) {
          throw new Error('orgId is required');
        }
        const orgSsInfo = (
          await OrgSelfServeInfoApi.fetchOrgSelfServeInfoInternal(orgId)
        ).data;
        return augmentOrgInfo(orgSsInfo);
      } catch {
        return null;
      }
    },
    {
      ...options,
      enabled: options?.enabled === false ? false : !!orgId,
    },
  );
};
