import React from 'react';

import { ExperienceFlag } from '@hcs/experience-flags';
import { KeywordSearchListingRemarksTooltip } from '@hcs/keyword-search';
import { PropertySummaryHeader } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompIdentifier, ReportId } from '@hcs/types';
import {
  formatMissing,
  formatNumber,
  formatPropertyType,
  formatSqFt,
} from '@hcs/utils';

import { PropertyDetailsHeader } from '../../components-deprecated';
import { CompPhotoCarousel } from '../../features/CompPhotoCarousel';
import { useComp } from '../../hooks';
import { compKeywordSearchKey } from '../../utils';

interface Props {
  reportId: ReportId;
  children?: React.ReactNode;
  compIdentifier: CompIdentifier;
  className?: string;
}

const dataHcName = 'comp-details-header';
export const CompDetailsHeader = ({
  reportId,
  children,
  compIdentifier,
  className,
}: Props) => {
  const { isInitialLoading, data: compData } = useComp(
    reportId,
    compIdentifier,
  );
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: compData?.compSchema.propertyState,
  };
  const isRental = compIdentifier.compType === CompTypes.Rental;
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <PropertySummaryHeader
          isLoading={isInitialLoading}
          propertyStateArgs={propertyStateArgs}
          photo={
            <CompPhotoCarousel
              reportId={reportId}
              compIdentifier={compIdentifier}
              showStreetViewIcon={false}
            />
          }
        />
      }
      now={
        <PropertyDetailsHeader
          dataHcName={dataHcName}
          location={compData?.compSchema.propertyState.location}
          propertyAttributes={[
            `${formatPropertyType(
              getPropertyStateFieldValue(
                PropertyStateFields.propertyType,
                propertyStateArgs,
              ),
            )}`,
            `${formatNumber(
              getPropertyStateFieldValue(
                PropertyStateFields.bedrooms,
                propertyStateArgs,
              ),
            )} Beds`,
            `${formatMissing(
              getPropertyStateFieldValue(
                PropertyStateFields.bathrooms,
                propertyStateArgs,
              ),
            )} Baths`,
            `${formatSqFt(
              getPropertyStateFieldValue(
                PropertyStateFields.livingArea,
                propertyStateArgs,
              ),
            )}`,
            `${formatSqFt(
              getPropertyStateFieldValue(
                PropertyStateFields.lotSize,
                propertyStateArgs,
              ),
            )} Lot`,
          ]}
          publicRemarks={
            compData?.compSchema.propertyState && (
              <KeywordSearchListingRemarksTooltip
                keywordSearchKey={compKeywordSearchKey(compIdentifier.compType)}
                remarks={
                  compData?.compSchema.propertyState?.[
                    isRental ? 'listingDetailsRental' : 'listingDetailsSale'
                  ]?.remarks?.find((r) => r?.type === 'Public')?.value
                }
                date={
                  compData?.compSchema.propertyState[
                    isRental ? 'complexFieldsRental' : 'complexFieldsSale'
                  ]?.currentListDate
                }
              />
            )
          }
          className={className}
        >
          {children}
        </PropertyDetailsHeader>
      }
    />
  );
};
