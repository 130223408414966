import { useMutation } from '@tanstack/react-query';

import { EngagementEvent } from '@hcs/types';

import { BeaconApi } from '../api';

export const useTrackBeaconEvent = () => {
  const trackMutation = useMutation(BeaconApi.trackEvent);
  const trackBeaconEvent = (engagementEvent: EngagementEvent) => {
    trackMutation.mutate(engagementEvent);
  };
  return {
    ...trackMutation,
    mutate: trackBeaconEvent,
  };
};
