import { ReactNode } from 'react';

import { DATA_HC_NAME_PAGE_AUTH_SPINNER } from '@hcs/authn';
import { LoadingSpinner } from '@hcs/design-system';

import { useCheckCurrentAppAccess } from '../../hooks/useCheckCurrentAppAccess';

export interface PrivateApplicationPageProps {
  // Render if user is not logged in
  unauthenticatedRender: ReactNode;
  // Render if user is logged in but does not have access
  unauthorizedRender: ReactNode;
  children: ReactNode;
}
// Renders Page content (children) if the user has access to the application
// a loading spinner if checking the token
// value of the `unauthenticatedRender` prop if the user does not have access
export const PrivateApplicationPage = ({
  children,
  unauthenticatedRender,
  unauthorizedRender,
}: PrivateApplicationPageProps) => {
  const checkApplicationAccessQuery = useCheckCurrentAppAccess();
  const {
    isInitialLoading,
    isSuccess,
    isFetched,
    isError,
    isFetching,
    data: hasApplicationAccess,
  } = checkApplicationAccessQuery;
  if (
    // The query has not been fetched
    !isFetched ||
    // Is loading for the first time
    isInitialLoading ||
    // Previously failed and is refetching
    (isFetching && isError)
  ) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (isSuccess && hasApplicationAccess) {
    return children;
  } else if (isError) {
    return unauthenticatedRender;
  }
  return unauthorizedRender;
};
