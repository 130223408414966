import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { NullState } from '@hcs/design-system';
import { Skeleton } from '@hcs/design-system';
import { useMapPreferencesForUser } from '@hcs/huell';
import { DEFAULT_LAYERS, DEFAULT_LAYERS_RENTAL } from '@hcs/maps';
import { HcMap, HcMapProps } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerTypes } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { INITIAL_ZOOM } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { GeoLocation } from '@hcs/types';
import { PreferencesKeys } from '@hcs/types';
import { ControlPosition } from '@hcs/types';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import {
  CompIdentifier,
  CompsListTypes,
  CompsListViewType,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import { OpenMarkerPopup } from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';
import { locationToGeoLocation } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import {
  useCompDocuments,
  useReport,
  useSubjectDocument,
} from '../../../hooks';
import { useCompsList } from '../../../hooks/useCompsList';
import { useReportConfig } from '../../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../../utils/reportConfig.utils';
import { CompFilterDistanceGeoJsonLayer } from '../../CompFilterDistanceGeoJsonLayer';
import { CompMarkerPopup } from '../../CompMarkerPopup';
import { HoveredCompState } from '../../CompSelectionPage';
import { SubjectMarker } from '../../SubjectMarker';
import { SubjectMarkerPopup } from '../../SubjectMarkerPopup';

import { CompsListMapMarkers } from './CompsListMapMarkers';

interface Props {
  reportId: ReportId;
  dataHcEventSection?: string;
  compType: CompTypes;
  compsListType: CompsListTypes;
  hoveredComp: HoveredCompState | null;
  mapId?: string;
  onChangeClusterMarkers?: (v: boolean) => void;
  children?: ReactNode;
  onClick?: VoidFunction;
  scrollZoom?: boolean;
  fitBounds?: Omit<HcMapProps['fitBounds'], 'coords'>;
}

const dataHcName = 'comps-list-map';
export const COMPS_LIST_MAP_FEATURES = [ReportFeatures.CompsSelect];
const MAP_ID = `REPORT_API/COMPS_LIST_MAP`;
export const CompsListMap = ({
  reportId,
  compType,
  compsListType,
  dataHcEventSection,
  onClick,
  hoveredComp,
  children,
  mapId: mapIdProp = MAP_ID,
  ...mapProps
}: Props) => {
  const mapId = `${mapIdProp}-${compType}`;
  const { data: reportConfig } = useReportConfig(reportId);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const compsQuery = useCompDocuments(reportId, compType);
  const {
    state: { compsListQuery, listViewType },
  } = useCompsList({ reportId, compType, compsListType });
  const { data: mapPreferences } = useMapPreferencesForUser();
  const { mapState } = useHcMap(mapId);
  const reportQuery = useReport(reportId);
  const isHoveredCompSelected = useMemo(() => {
    if (hoveredComp) {
      return !!compsQuery.data?.find(
        (d) => d.data.compID === hoveredComp.compSchema.compID,
      );
    }
    return false;
  }, [hoveredComp, compsQuery.data]);
  const [openMarkerPopup, setOpenMarkerPopup] =
    useState<OpenMarkerPopup | null>(null);
  const onCloseMarkerPopup = useCallback(() => setOpenMarkerPopup(null), []);
  const onSetOpenMarkerPopupSubject = useCallback(() => {
    setOpenMarkerPopup({ type: 'subject' });
  }, [setOpenMarkerPopup]);

  const { latitude, longitude } =
    subjectDocument?.data.propertyState.location || {};
  const { showMarkers } = mapState?.markers || {};
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument?.data.propertyState,
  };
  const subjectPropertyType = getPropertyStateFieldValue(
    PropertyStateFields.propertyType,
    propertyStateArgs,
  );

  const fitBounds: HcMapProps['fitBounds'] | undefined = useMemo(() => {
    const coords: GeoLocation[] = [];
    const subjectGeoLocation = locationToGeoLocation(
      subjectDocument?.data.propertyState.location,
    );
    if (subjectGeoLocation) {
      coords.push(subjectGeoLocation);
    }
    compsListQuery.data.forEach((compSchema) => {
      const compGeoLocation = locationToGeoLocation(
        compSchema?.propertyState.location,
      );
      if (compGeoLocation) {
        coords.push(compGeoLocation);
      }
    });
    if (coords.length > 1) {
      return { ...mapProps.fitBounds, coords, fitId: `fit-${compsListType}` };
    }
    return undefined;
  }, [compsListQuery.data, compsListType]);

  const combinedQueryResult = combineUseQueryResult([
    compsListQuery,
    reportQuery,
  ]);

  if (combinedQueryResult.isInitialLoading) {
    return <Skeleton dataHcName={`${dataHcName}-skeleton`} />;
  }

  if (!latitude || !longitude) {
    return (
      <NullState
        dataHcName={`${dataHcName}-error`}
        absCenter
        title="Error: Missing subject coordinates"
      />
    );
  }

  const compId: CompIdentifier | undefined =
    openMarkerPopup?.type === 'comp' &&
    openMarkerPopup?.compIdentifier &&
    openMarkerPopup.compIdentifier.type === 'schema'
      ? {
          ...openMarkerPopup?.compIdentifier,
          type: 'compId',
          compId: openMarkerPopup.compIdentifier?.compSchema?.compID,
          compType,
        }
      : hoveredComp?.compSchema?.compID &&
          listViewType === CompsListViewType.Table
        ? {
            type: 'compId',
            compId: hoveredComp?.compSchema?.compID,
            compType,
          }
        : undefined;

  const hoveredCompGeoLocation = locationToGeoLocation(
    hoveredComp?.compSchema.propertyState.location,
  );
  const hoveredCompPropertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: hoveredComp?.compSchema.propertyState,
  };
  return (
    <HcMap
      {...mapProps}
      dataHcEventSection={dataHcEventSection}
      uiPreferencesKey={
        compType === CompTypes.Rental
          ? PreferencesKeys.CompsMapRental
          : PreferencesKeys.CompsMapSale
      }
      onClick={onClick}
      layersControl={
        reportFeaturesSupportedAny(reportConfig, [ReportFeatures.Heatmaps])
          ? {
              position: ControlPosition.BottomRight,
              vectilesMetricGroups:
                compType === CompTypes.Rental
                  ? DEFAULT_LAYERS_RENTAL
                  : DEFAULT_LAYERS,
            }
          : undefined
      }
      latitude={latitude}
      longitude={longitude}
      zoom={INITIAL_ZOOM}
      propertyType={subjectPropertyType}
      mapId={mapId}
      dataHcName={dataHcName}
      fitBounds={fitBounds}
      satelliteControl={{ position: ControlPosition.BottomLeft }}
      zoomControl={{ position: ControlPosition.TopRight }}
    >
      <CompFilterDistanceGeoJsonLayer reportId={reportId} compType={compType} />

      {showMarkers && (
        <CompsListMapMarkers
          reportId={reportId}
          mapId={mapId}
          compType={compType}
          compsListType={compsListType}
          clustersEnabled={!mapPreferences?.disableClusters}
          onClickMarker={setOpenMarkerPopup}
        />
      )}
      <SubjectMarker
        reportId={reportId}
        onClick={onSetOpenMarkerPopupSubject}
      />
      {hoveredComp && hoveredCompGeoLocation && (
        <PropertyMarker
          dataHcName={`${dataHcName}-marker-hovered`}
          type={PropertyMarkerTypes.Comp}
          mapId={mapId}
          markerId={`marker-${hoveredComp.compSchema.compID}`}
          geoLocation={hoveredCompGeoLocation}
          listingStatus={
            compType === CompTypes.Rental
              ? getPropertyStateFieldValue(
                  PropertyStateFields.currentStatusRental,
                  hoveredCompPropertyStateArgs,
                )
              : getPropertyStateFieldValue(PropertyStateFields.currentStatus, {
                  propertyStateType: PropertyStateType.Core,
                  propertyState: hoveredComp.compSchema.propertyState,
                })
          }
          selected={isHoveredCompSelected}
          label={formatNumberAbbrev(
            getPropertyStateFieldValue(
              compType === CompTypes.Rental
                ? PropertyStateFields.currentPriceRental
                : PropertyStateFields.currentPrice,
              hoveredCompPropertyStateArgs,
            ),
          )}
          pulse
        />
      )}
      {children}
      {compId && (
        <CompMarkerPopup
          compIdentifier={compId}
          reportId={reportId}
          onClose={onCloseMarkerPopup}
        />
      )}
      {openMarkerPopup?.type === 'subject' && (
        <SubjectMarkerPopup reportId={reportId} onClose={onCloseMarkerPopup} />
      )}
    </HcMap>
  );
};
