import React, { useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { InputNumber } from '@hcs/design-system';
import {
  PortfolioAvmThresholdFields,
  PortfolioAvmThresholdOperators,
  PortfolioAvmThresholdValueFields,
  PortfolioForm,
  PortfolioFormFieldCommonProps,
  PortfolioFormFields,
} from '@hcs/types';

import styles from './AvmThresholdValueFieldInput.module.css';

interface Props extends PortfolioFormFieldCommonProps {
  dataHcName: string;
}

const OPERATOR_OPTIONS = [
  { value: PortfolioAvmThresholdOperators.INCREASE, label: 'Increases by' },
  { value: PortfolioAvmThresholdOperators.DECREASE, label: 'Decreases by' },
];

export const AvmThresholdValueFieldInput = ({ dataHcName }: Props) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<PortfolioForm>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `${PortfolioFormFields.AvmThresholds}.thresholds`,
  });

  const avmThresholdObj = useWatch({
    name: PortfolioFormFields.AvmThresholds,
    control: control,
  });

  useEffect(() => {
    trigger(`avmThresholds.thresholds`);
  }, [trigger]);

  const avmValuesObj =
    avmThresholdObj?.[PortfolioAvmThresholdFields.Thresholds];

  const addTemplateThresholdFields = () => {
    append({
      operator: PortfolioAvmThresholdOperators.INCREASE,
      value: null,
    });
  };

  const avmValues = avmValuesObj?.map((val) => val?.value);
  // Check if a number was entered to enable the "Add Another Value" button.
  const isInputNumber =
    avmValues && typeof avmValues[avmValues.length - 1] === 'number';

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={`avm-value-input-${item.id}`}>
            <Controller
              name={`avmThresholds.thresholds.${index}`}
              control={control}
              render={({ field, fieldState: { invalid } }) => {
                const avmReferenceValueError =
                  errors?.[PortfolioFormFields.AvmThresholds]?.[
                    PortfolioAvmThresholdFields.Thresholds
                  ];

                const eachAvmReferenceValueErrorMsg =
                  Array.isArray(avmReferenceValueError) &&
                  avmReferenceValueError?.[index]?.value?.message;

                return (
                  <>
                    <div
                      className={styles.InputRow}
                      data-hc-name={`${dataHcName}-input-row`}
                    >
                      <div className={styles.InputContainer}>
                        <Dropdown
                          theme={{
                            SelectContainer: styles.SelectContainer,
                            Option: styles.Option,
                          }}
                          dataHcName={`${dataHcName}-operator-dropdown`}
                          options={OPERATOR_OPTIONS}
                          value={
                            field.value[
                              PortfolioAvmThresholdValueFields.Operator
                            ]
                          }
                          onSelect={(value) => {
                            field.onChange({
                              [PortfolioAvmThresholdValueFields.Operator]:
                                value,
                              [PortfolioAvmThresholdValueFields.Value]:
                                field.value[
                                  PortfolioAvmThresholdValueFields.Value
                                ],
                            });
                            // trigger validation
                            trigger(PortfolioFormFields.AvmThresholds);
                          }}
                        />
                        <InputNumber
                          {...field}
                          className={styles.Input}
                          theme={{ InputElement: styles.InputElement }}
                          value={
                            field.value[PortfolioAvmThresholdValueFields.Value]
                          }
                          dataHcName={`${dataHcName}-input`}
                          isFloat
                          shouldFormat={{
                            shouldFormatNumber: false,
                          }}
                          onChange={(value) => {
                            field.onChange({
                              [PortfolioAvmThresholdValueFields.Operator]:
                                field.value[
                                  PortfolioAvmThresholdValueFields.Operator
                                ],
                              [PortfolioAvmThresholdValueFields.Value]: value,
                            });
                          }}
                        />
                      </div>
                      {index > 0 && (
                        <Button
                          className={classNames(
                            styles.ButtonNoBackground,
                            styles.RemoveButton,
                          )}
                          dataHcName={`${dataHcName}-remove-reference-avm-button`}
                          label="Remove"
                          primary={false}
                          onClick={() => remove(index)}
                        />
                      )}
                    </div>
                    {invalid && (
                      <div
                        className={styles.Error}
                        data-hc-name={`${dataHcName}-error`}
                      >
                        {eachAvmReferenceValueErrorMsg}
                      </div>
                    )}
                  </>
                );
              }}
            />
          </div>
        );
      })}
      {errors?.[PortfolioFormFields.AvmThresholds]?.thresholds?.message && (
        <div className={styles.Error} data-hc-name={`${dataHcName}-error`}>
          {errors?.[PortfolioFormFields.AvmThresholds]?.thresholds?.message}
        </div>
      )}
      <Button
        className={classNames(styles.ButtonNoBackground, styles.AddButton)}
        dataHcName={`${dataHcName}-add-another-value-button`}
        disabled={
          !!errors?.[PortfolioFormFields.AvmThresholds] || !isInputNumber
        }
        label="Add Another Value"
        primary={false}
        type="button"
        onClick={addTemplateThresholdFields}
      />
    </>
  );
};
