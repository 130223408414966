import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useAccount } from '@hcs/authn';
import { CloseButton } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { CardTitle } from '@hcs/design-system';
import { FlexScrollCard } from '@hcs/design-system';
import { SmallCountChip } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { AnimatedCheck } from '@hcs/design-system';
import { CheckIcon } from '@hcs/design-system';
import { useFixedPortalElement } from '@hcs/hooks';
import {
  DocumentDeletedEvent,
  DocumentRoles,
  DocumentsCreatedEvent,
  DocumentUpdatedEvent,
  ReportEvent,
  ReportEventTypes,
  ReportId,
  ReportStatus,
  UpdateCancelledEvent,
} from '@hcs/types';
import { formatTimeAgo } from '@hcs/utils';
import { formatFirstLastName } from '@hcs/utils';

import { useReport, useSubscribeToReportEvents } from '../../hooks';

import styles from './ReportUpdates.module.css';

interface Props {
  reportId: ReportId;
  /** Number of milliseconds to wait before clearing notifications */
  clearNotifications?: number;
  className?: string;
}
const DOC_UPDATE_TITLE_FOR_ROLE: Partial<{ [key in DocumentRoles]: string }> = {
  [DocumentRoles.Comp]: 'Changed Selected Comps',
  [DocumentRoles.CompsFilters]: 'Comp Filters Updated',
  [DocumentRoles.ForecastChartBlock]: 'Forecast Chart Updated',
  [DocumentRoles.RentalComp]: 'Changed Selected Rental Comps',
  [DocumentRoles.RentalCompsFilters]: 'Rental Comp Filters Updated',
  [DocumentRoles.Subject]: 'Subject Updated',
  [DocumentRoles.SubjectValue]: 'Report Value Updated',
  [DocumentRoles.RentalSubjectValue]: 'Report Rental Value Updated',
};

interface ReportUpdateInfo {
  editedBy: ReportEvent['editedBy'];
  timestamp: string;
  message: string;
  patchId: number | undefined;
  clientPatchId: string | undefined;
  documentRole: DocumentRoles;
  patchStatus: DocumentUpdatedEvent['patchStatus'] | 'CANCELLED';
}
interface ReportUpdatesState {
  expanded: boolean;
  dismissed: boolean;
  reportUpdates: ReportUpdateInfo[];
  unreadUpdates: number;
}

const INITIAL_STATE: ReportUpdatesState = {
  expanded: false,
  dismissed: false,
  reportUpdates: [],
  unreadUpdates: 0,
};

interface UiActions {
  type: 'toggleExpanded' | 'clearEvents';
}

interface ReportUpdatesActions {
  type: 'updateReceived' | 'updateComplete';
  payload:
    | DocumentUpdatedEvent
    | DocumentsCreatedEvent
    | UpdateCancelledEvent
    | DocumentDeletedEvent;
}

type AllReportUpdatesActions = ReportUpdatesActions | UiActions;

const reportUpdatesReducer = (
  state: ReportUpdatesState,
  action: AllReportUpdatesActions,
): ReportUpdatesState => {
  switch (action.type) {
    case 'updateReceived': {
      let reportUpdatesNew: ReportUpdatesState['reportUpdates'] = [
        ...state.reportUpdates,
      ];
      if (action.payload.type === ReportEventTypes.DocumentsUpdated) {
        const patchStatus = action.payload.patchStatus;
        let unreadUpdatesNew = state.unreadUpdates;
        const { editedBy } = action.payload;
        action.payload.report.documentEdits.forEach((docEdit) => {
          const { id: patchId, documentRole, clientPatchId } = docEdit;
          if (documentRole in DOC_UPDATE_TITLE_FOR_ROLE) {
            const newUpdate: ReportUpdateInfo = {
              editedBy,
              timestamp: docEdit.updatedAt || docEdit.createdAt,
              message: DOC_UPDATE_TITLE_FOR_ROLE[documentRole] || '',
              documentRole,
              patchStatus,
              clientPatchId,
              patchId,
            };
            if (patchStatus === 'IN_PROGRESS') {
              let patchAlreadyTracked = false;
              reportUpdatesNew = reportUpdatesNew.map((reportUpdate) => {
                if (
                  reportUpdate.clientPatchId === clientPatchId ||
                  reportUpdate.patchId === patchId
                ) {
                  patchAlreadyTracked = true;
                  return newUpdate;
                } else {
                  return reportUpdate;
                }
              });
              if (!patchAlreadyTracked) {
                if (state.dismissed || !state.expanded) {
                  unreadUpdatesNew++;
                }
                reportUpdatesNew.unshift(newUpdate);
              }
            } else if (patchStatus === 'DONE') {
              const { clientPatchId } = docEdit;
              reportUpdatesNew = reportUpdatesNew.map((u) =>
                !clientPatchId || u.clientPatchId === clientPatchId
                  ? { ...u, patchStatus }
                  : u,
              );
            }
          }
        });
        return {
          ...state,
          unreadUpdates: unreadUpdatesNew,
          reportUpdates: reportUpdatesNew,
        };
      } else if (
        action.payload.type === ReportEventTypes.DocumentsCreated ||
        action.payload.type === ReportEventTypes.DocumentsDeleted
      ) {
        let unreadUpdatesNew = state.unreadUpdates;
        const reportUpdatesNew: ReportUpdatesState['reportUpdates'] = [
          ...state.reportUpdates,
        ];
        const eventDocumentRole = action.payload.report.documents[0]?.role;
        if (eventDocumentRole) {
          if (state.dismissed || !state.expanded) {
            unreadUpdatesNew++;
          }

          const newUpdate: ReportUpdateInfo = {
            editedBy: action.payload.editedBy,
            timestamp: action.payload.timestamp.toString(),
            message: DOC_UPDATE_TITLE_FOR_ROLE[eventDocumentRole] || '',
            documentRole: eventDocumentRole,
            patchStatus: action.payload.patchStatus,
            clientPatchId: undefined,
            patchId: undefined,
          };
          reportUpdatesNew.push(newUpdate);
        }
        return {
          ...state,
          reportUpdates: reportUpdatesNew,
          unreadUpdates: unreadUpdatesNew,
        };
      } else if (action.payload.type === ReportEventTypes.UpdateCancelled) {
        const { clientPatchId, patchStatus } = action.payload;
        reportUpdatesNew = reportUpdatesNew.map((u) =>
          !clientPatchId || u.clientPatchId === clientPatchId
            ? { ...u, patchStatus }
            : u,
        );
        return {
          ...state,
          reportUpdates: reportUpdatesNew,
        };
      }

      return state;
    }

    case 'toggleExpanded': {
      const expanded = !state.expanded;
      return {
        ...state,
        unreadUpdates: 0,
        expanded,
      };
    }

    case 'clearEvents': {
      return {
        ...state,
        unreadUpdates: 0,
        reportUpdates: [],
      };
    }

    default:
      return state;
  }
};
const dataHcName = 'report-updates';
export const ReportUpdates = ({
  reportId,
  className,
  clearNotifications,
}: Props) => {
  const [{ expanded, dismissed, reportUpdates, unreadUpdates }, dispatch]: [
    ReportUpdatesState,
    Dispatch<AllReportUpdatesActions>,
  ] = useReducer(reportUpdatesReducer, INITIAL_STATE);
  const { data: report } = useReport(reportId);
  const { data: account } = useAccount();
  const portalElm = useFixedPortalElement();
  const { areAnyPending, areAllDone } = useMemo(() => {
    let areAnyPending = false;
    let areAllDone = !!reportUpdates.length;
    reportUpdates.forEach((u) => {
      if (u.patchStatus === 'IN_PROGRESS') {
        areAnyPending = true;
        areAllDone = false;
      }
    });
    return { areAllDone, areAnyPending };
  }, [reportUpdates]);
  useEffect(() => {
    const hideTimeout =
      clearNotifications && areAllDone && !areAnyPending
        ? // When there aren't pending events, hide and clear updates after 5 seconds of no activity
          setTimeout(() => {
            dispatch({
              type: 'clearEvents',
            });
          }, 5000)
        : undefined;
    return () => {
      if (clearNotifications && hideTimeout) {
        clearTimeout(hideTimeout);
      }
    };
  }, [areAnyPending, areAllDone, clearNotifications]);
  useSubscribeToReportEvents({
    callbackId: 'ReportUpdates',
    onMessage: useCallback(
      (reportEvent) => {
        const patchEditedBy = reportEvent.editedBy;
        if (patchEditedBy?.id && patchEditedBy.id !== account?.user.id) {
          if (reportEvent.type === ReportEventTypes.DocumentsUpdated) {
            dispatch({
              type: 'updateReceived',
              payload: reportEvent,
            });
          } else if (
            reportEvent.type === ReportEventTypes.DocumentsCreated ||
            reportEvent.type === ReportEventTypes.DocumentsDeleted
          ) {
            dispatch({ type: 'updateReceived', payload: reportEvent });
          } else if (reportEvent.type === ReportEventTypes.UpdateCancelled) {
            dispatch({
              type: 'updateReceived',
              payload: reportEvent,
            });
          }
        }
      },
      [account?.user.id],
    ),
  });
  if (
    !portalElm ||
    dismissed ||
    !reportUpdates.length ||
    report?.status !== ReportStatus.Completed
  )
    return <span />;
  return createPortal(
    expanded ? (
      <FlexScrollCard
        dataHcName={dataHcName}
        className={classNames(styles.ReportUpdates, className)}
        header={{
          height: 80,
          content: (
            <CardTitle
              dataHcName={`${dataHcName}-title`}
              className={styles.Title}
            >
              <div className={styles.TitleLeft}>
                <div className={styles.IconCell}>
                  {areAnyPending ? (
                    <LoadingSpinner
                      className={styles.LoadingSpinner}
                      dataHcName={`${dataHcName}-loading-title`}
                      absoluteCenter={false}
                      small
                    />
                  ) : (
                    <AnimatedCheck key="check" />
                  )}
                </div>
                <span data-hc-name={`${dataHcName}-title-text`}>
                  Report Updates
                </span>
              </div>
              <CloseButton
                dataHcName={`${dataHcName}-close`}
                className={styles.CloseButton}
                onClick={() => {
                  dispatch({ type: 'toggleExpanded' });
                }}
              />
            </CardTitle>
          ),
        }}
        smallPadding
      >
        {reportUpdates.map((reportUpdate, i) => {
          return (
            <ul
              className={styles.Details}
              key={`update-${reportUpdate.patchId}-${i}`}
            >
              <li className={styles.Detail}>
                <div className={styles.DetailIcon}>
                  {reportUpdate.patchStatus === 'DONE' ||
                  reportUpdate.patchStatus === 'CANCELLED' ? (
                    <CheckIcon className={styles.DetailCheck} size="sm" />
                  ) : (
                    <LoadingSpinner
                      className={styles.LoadingSpinnerDetail}
                      dataHcName={`${dataHcName}-loading-detail`}
                      absoluteCenter={false}
                      small
                    />
                  )}
                </div>
                <div className={styles.DetailInfo}>
                  <div
                    data-hc-name={`${dataHcName}-detail-message`}
                    className={styles.DetailMessage}
                  >
                    {reportUpdate.message}
                  </div>
                  <div className={styles.DetailSecondary}>
                    <span data-hc-name={`${dataHcName}-detail-user`}>
                      {formatFirstLastName(reportUpdate.editedBy || {})}
                    </span>{' '}
                    made edits{' '}
                    <span data-hc-name={`${dataHcName}-detail-time`}>
                      {reportUpdate.timestamp
                        ? formatTimeAgo(reportUpdate.timestamp)
                        : 'a few seconds ago'}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          );
        })}
      </FlexScrollCard>
    ) : (
      <Card
        dataHcName={dataHcName}
        className={classNames(
          styles.ReportUpdates,
          className,
          styles.collapsed,
        )}
        onClick={() => {
          dispatch({ type: 'toggleExpanded' });
        }}
        noPadding
      >
        <div className={styles.IconCell}>
          {areAnyPending ? (
            <LoadingSpinner
              dataHcName={`${dataHcName}-loading-collapsed`}
              absoluteCenter={false}
              className={styles.LoadingSpinner}
              small
            />
          ) : (
            <AnimatedCheck key="check" />
          )}
          <SmallCountChip
            dataHcName={`${dataHcName}-notification-count`}
            count={unreadUpdates}
            className={styles.SmallCountChip}
          />
        </div>
        <label
          data-hc-name={`${dataHcName}-collapsed-label`}
          className={styles.CollapsedLabel}
        >
          Report Updates
        </label>
      </Card>
    ),
    portalElm,
  );
};
