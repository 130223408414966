import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { useComponentDidMount, useWindowResize } from '@hcs/hooks';
import { findClosestToDivisibleBy } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations';

import styles from './ResizableFlexColumn.module.css';

const DOUBLE_CLICK_TIMEOUT = 150;

const SCROLL_BAR_GUTTER = 15;
export interface ResizableFlexColumnProps {
  /* Callback executed on resize, with the container width and remaining width arguments */
  onResize?: (columnWidth: number, restWidth: number) => void;
  /* Callback executed when the current resize action is complete */
  onResizeComplete?: (columnWidth: number, restWidth: number) => void;
  /* Disable resize functionality */
  disabled?: boolean;
  /* Enable the ability to resize by clicking the handle (only support drag to resize) */
  clickToResize?: boolean;
  /* Enforce a minimum width in px */
  minWidth?: number;
  /* Enforce a maximum width in px */
  maxWidth?: number;
  /* Enforce a minimum px that must remain visible outside of the column */
  pixelsRemaining?: number;
  initialWidth?: number;
  /* class applied to the outer element wrapping both children and the handle */
  classNameOuter?: string;
  /* class applied to the outer element wrapping only children */
  classNameInner?: string;
  children: React.ReactNode;
  dataHcName: string;
  /* column */
  columns?: {
    /* Snap to nearest column when applying width */
    snapTo?: boolean;
    width: number;
    gap: number;
    /* Additional adjustment to apply in pixels */
    adjustment?: number;
  };
  /* Whether to disgard the parent's max width when calculating the max resizing width of this component.
   * When enabled, this allows for usage of `onResize` to resize a parent container when this component is resized. */
  disregardParentMaxWidth?: boolean;
}

type ClickDirection = '+' | '-';

/**u
 * A component that is able to be resized by clicking and dragging handles placed on the sides
 */
export const ResizableFlexColumn = (props: ResizableFlexColumnProps) => {
  const {
    onResize,
    onResizeComplete,
    disabled,
    minWidth,
    maxWidth,
    pixelsRemaining,
    initialWidth,
    classNameOuter,
    classNameInner,
    clickToResize = true,
    children,
    dataHcName,
    columns,
    disregardParentMaxWidth,
  } = props;
  const [clickDirection, setClickDirection] = useState<ClickDirection>('+');
  /* isResizing ref is used by callbacks that cannot wait for rerender */
  const [isResizing1, setIsResizing] = useState(false);
  const isResizing = useRef(false);
  /** For tracking double-clicks */
  const numClicks = useRef<number>(0);
  const clickTimeout = useRef(0);
  const initialMousePosition = useRef(0);
  /** Element Refs */
  const componentNode = useRef<HTMLDivElement | null>(null);
  const parentNode = useRef<HTMLElement | null>(null);
  /** Important Widths */
  const width = useRef<number>(initialWidth || 0);
  const parentWidth = useRef<number>(0);
  const columnWidth = columns && columns.width + columns.gap;
  /** Adjustment accounts for scrollbar and gap css */
  const widthAdjustment =
    SCROLL_BAR_GUTTER + (columns?.gap || 0) + (columns?.adjustment || 0);

  const calcActualMaxWidth = () => {
    const maxParentWidth = parentWidth.current - (pixelsRemaining || 0);
    const widthsToCompare = disregardParentMaxWidth ? [] : [maxParentWidth];
    if (maxWidth) {
      widthsToCompare.push(maxWidth);
    }
    if (columnWidth) {
      widthsToCompare.push(
        Math.floor(maxParentWidth / columnWidth) * columnWidth +
          widthAdjustment,
      );
    }
    return Math.min(...widthsToCompare);
  };

  /** Default to 25% increments */
  const calcIncrementWidth = () =>
    columnWidth || Math.floor(calcActualMaxWidth() / 4);
  const applyWidthToElements = (
    newWidth: number,
    options?: {
      callback?: (n: number, remaining: number) => void;
      snapToColumn?: boolean;
    },
  ) => {
    const { snapToColumn, callback } = options || {};
    if (componentNode.current) {
      /** Adjust Width According to Min/Max */
      const actualMaxWidth = calcActualMaxWidth();
      if (minWidth && newWidth < minWidth) {
        newWidth = minWidth;
      } else if (newWidth > actualMaxWidth) {
        newWidth = actualMaxWidth;
      }
      /** Snap to Column */
      if (
        snapToColumn !== false &&
        columns?.snapTo &&
        columnWidth &&
        actualMaxWidth
      ) {
        newWidth = Math.min(
          findClosestToDivisibleBy(newWidth, columnWidth) + widthAdjustment,
          actualMaxWidth,
        );
      }
      /** Apply Final Width to Element */
      width.current = newWidth;
      componentNode.current.style.flex = `0 0 ${newWidth}px`;
      componentNode.current.style.width = `${newWidth}px`;
      /** Side effects and callbacks */
      const incrementWidth = calcIncrementWidth();
      if (
        clickDirection === '+' &&
        // Not enough space for next increment
        newWidth + incrementWidth > actualMaxWidth
      ) {
        setClickDirection('-');
      } else if (
        clickDirection === '-' &&
        // Not enough space for next increment
        newWidth - incrementWidth < incrementWidth
      ) {
        setClickDirection('+');
      }
      const remaining = parentWidth.current - newWidth;
      callback?.(newWidth, remaining);
    }
  };

  /** LISTENERS */
  useWindowResize(() => {
    window.requestAnimationFrame(() => {
      if (componentNode?.current?.parentNode instanceof HTMLElement) {
        parentNode.current = componentNode.current.parentNode;
        parentWidth.current = parentNode.current.offsetWidth;
      }
      applyWidthToElements(width.current);
    });
  });

  useComponentDidMount(() => {
    if (componentNode?.current?.parentNode instanceof HTMLElement) {
      parentNode.current = componentNode.current.parentNode;
      parentWidth.current = parentNode.current.offsetWidth;
    }
    /** Set initial width */
    applyWidthToElements(initialWidth || parentWidth.current / 2);

    return () => {
      clearTimeout(clickTimeout.current);
    };
  });

  /** DRAG RESIZE FLOW */
  const dragResize = (e: MouseEvent) => {
    clearTimeout(clickTimeout.current);
    window.requestAnimationFrame(() => {
      /**  Sometimes this will get called even after stopResize() */
      if (!isResizing.current) {
        return;
      }
      const resizedWidth =
        parentWidth.current -
        e.clientX +
        (window.innerWidth - parentWidth.current);
      applyWidthToElements(resizedWidth, {
        snapToColumn: false,
        callback: onResize,
      });
    });
  };

  const stopDragResize = (e: MouseEvent) => {
    isResizing.current = false;
    setIsResizing(false);
    window.removeEventListener('mouseup', stopDragResize);
    window.removeEventListener('mousemove', dragResize);
    if (e.clientX !== initialMousePosition.current) {
      applyWidthToElements(width.current, { callback: onResizeComplete });
    }
  };

  const initDragResize = (e: React.MouseEvent) => {
    window.addEventListener('mouseup', stopDragResize);
    window.addEventListener('mousemove', dragResize);

    isResizing.current = true;
    setIsResizing(true);
    initialMousePosition.current = e.clientX;
  };

  /** CLICK HANDLERS */
  const incrementNextWidth = () => {
    /** Reset double click tracker */
    numClicks.current = 0;
    const incrementWidth = calcIncrementWidth();
    applyWidthToElements(
      clickDirection === '+'
        ? width.current + incrementWidth
        : width.current - incrementWidth,
      { callback: onResize },
    );
  };

  const handleClick = (e: React.MouseEvent) => {
    /** The mouse has not moved from the mousedown position */
    if (e.clientX === initialMousePosition.current) {
      if (numClicks.current) {
        /** Handle Double-Click */
        clearTimeout(clickTimeout.current);
        numClicks.current = 0;
        applyWidthToElements(
          clickDirection === '+' ? calcActualMaxWidth() : 0,
          { callback: onResize },
        );
      } else {
        clickTimeout.current = window.setTimeout(
          incrementNextWidth,
          DOUBLE_CLICK_TIMEOUT,
        );
        numClicks.current += 1;
      }
    }
  };

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ResizableContainer, classNameOuter, {
        [styles.resizing]: isResizing1,
        [styles.animate]: !isResizing1,
      })}
      style={
        width.current !== undefined && width.current !== null
          ? { width: width.current }
          : {}
      }
      ref={componentNode}
    >
      {!disabled && (
        <div
          onMouseDown={initDragResize}
          onClick={clickToResize ? handleClick : undefined}
        >
          {clickToResize ? (
            <div
              data-hc-name={`${dataHcName}-resizer-handle`}
              className={classNames(styles.ResizerClickOrDragHandle)}
            >
              <DirectionalChevron
                dataHcName={`${dataHcName}-resizer-icon`}
                direction={clickDirection === '-' ? 'right' : 'left'}
                size={12}
              />
            </div>
          ) : (
            <div
              data-hc-name={`${dataHcName}-resizer-handle-drag-only`}
              className={classNames(styles.ResizerDragOnlyHandle)}
            />
          )}
        </div>
      )}
      <div className={classNames(classNameInner, styles.Inner)}>{children}</div>
    </div>
  );
};
