import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import { AddressFormFields, AddressParts } from '@hcs/types';
import { ORDER_MANAGER_CLIENT_API_URL } from '@hcs/urls';

const ADDRESS_URL = `${ORDER_MANAGER_CLIENT_API_URL}/address/`;

const parseAddress = async (enteredAddress: AddressFormFields) => {
  const parseAddressPayload = {
    addressId: enteredAddress.addressId || '',
    addressString: enteredAddress.addressFullLine || '',
  };

  const response = await AxiosAccessTokenClientJwt.post<AddressParts>(
    `${ADDRESS_URL}parse/`,
    snakecaseKeys(parseAddressPayload),
  );

  const parsedAddressResponse = camelcaseKeys(response.data);

  const { addressId, addressFullLine, ...rest } = enteredAddress;

  return {
    ...parsedAddressResponse,
    ...rest,
    addressUnparsed: enteredAddress.addressFullLine,
  };
};

export const AddressApi = {
  parseAddress,
};
