import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  CompCompareSliceState,
  CompId,
  CompTypes,
  ReportApiPartialAppState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { REPORT_API_REDUCER_KEY } from '../rtk';
import { compCompareDialogSlice } from '../rtk';

const SEARCH_PARAM_SALE = 'focusedComp';
const SEARCH_PARAM_RENTAL = 'focusedRentalComp';

export const useCompCompareDialogSlice = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const focusedSale = searchParams.get(SEARCH_PARAM_SALE) as CompId | undefined;
  const focusedRental = searchParams.get(SEARCH_PARAM_RENTAL) as
    | CompId
    | undefined;
  const dispatch = useDispatch();
  const hookReturn = {
    state: useSelector<ReportApiPartialAppState, CompCompareSliceState>(
      (state) => state[REPORT_API_REDUCER_KEY][compCompareDialogSlice.name],
    ),
    actions: mapDispatchToSliceActions(dispatch, compCompareDialogSlice),
  };
  // Allow deep linking to a specific comp comparison
  // Clients rely on this functionality
  useEffect(() => {
    if (focusedSale) {
      hookReturn.actions.compCompareDialogOpen({
        type: 'compId',
        compType: CompTypes.Sold,
        compId: focusedSale,
      });
    } else if (focusedRental) {
      hookReturn.actions.compCompareDialogOpen({
        type: 'compId',
        compType: CompTypes.Rental,
        compId: focusedRental,
      });
    }
    // @jnettleman - We only want this to happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      !hookReturn.state.active &&
      (SEARCH_PARAM_SALE || SEARCH_PARAM_RENTAL)
    ) {
      setSearchParams((prevParams) => {
        prevParams.delete(SEARCH_PARAM_SALE);
        prevParams.delete(SEARCH_PARAM_RENTAL);
        return prevParams;
      });
    }
  }, [hookReturn.state.active, setSearchParams]);
  return hookReturn;
};
