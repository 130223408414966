import React from 'react';

import { NoImagePlaceHolder } from '@hcs/pdf/pdf-service';
import { TwoColSection } from '@hcs/pdf/pdf-service';
import { TwoColTable } from '@hcs/pdf/pdf-service';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { YEAR_BUILT_CONFIG } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { useMlsListingSheetData } from '../../hooks/useMlsListingSheetData';

import styles from './ListingSummarySection.module.css';

const { label: labelYearBuilt, formatValue: formatYearBuilt } =
  YEAR_BUILT_CONFIG;

const dataHcName = 'listing-summary-section';
export const ListingSummarySection = () => {
  const { propertyStateMedia, propertyStateCore, reso } =
    useMlsListingSheetData();
  const photoUrl = propertyStateMedia.media?.images?.[0]?.url;
  return (
    <TwoColSection
      compact
      dataHcName={dataHcName}
      sectionHeaderProps={{
        title: 'Summary',
      }}
      contentLeft={
        <div data-hc-name={`${dataHcName}-photo`} className={styles.Image}>
          {photoUrl ? (
            <img
              alt="listing"
              data-hc-name={`${dataHcName}-photo-image`}
              src={photoUrl}
            />
          ) : (
            <NoImagePlaceHolder
              dataHcName={`${dataHcName}-photo-placeholder`}
            />
          )}
        </div>
      }
      contentRight={
        <TwoColTable
          smallLabels
          compact
          dataHcName={`${dataHcName}-table`}
          data={[
            PropertyStateFields.propertyType,
            PropertyStateFields.bedrooms,
            PropertyStateFields.bathrooms,
            PropertyStateFields.livingArea,
            PropertyStateFields.lotSize,
            {
              dataHcName: 'year-built-effective',
              label: `${labelYearBuilt} / Effective`,
              value: `${formatYearBuilt(propertyStateCore)} / ${formatMissing(
                reso?.propertyDetails?.yearBuiltEffective,
              )}`,
            },
            PropertyStateFields.roomsTotal,
            PropertyStateFields.basementHas,
            PropertyStateFields.parkingTotal,
          ].map((field) => {
            if (typeof field === 'string') {
              const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
              return {
                dataHcName: `${dataHcName}-${field}`,
                value: <Display propertyStateArgs={propertyStateCore} />,
                label,
              };
            } else {
              return field;
            }
          })}
        />
      }
    />
  );
};
