import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import { CapabilitiesResponse } from '@hcs/types';
import { ACCOUNT_URL } from '@hcs/urls';

export const AuthzApi = {
  getCapabilitiesForUser: async (userId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<CapabilitiesResponse>(
        `${ACCOUNT_URL}/users/${userId}/capabilities`,
      );
    return response.data;
  },
  getCapabilitiesForOrg: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<CapabilitiesResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/capabilities`,
      );
    return response.data;
  },
};
