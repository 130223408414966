import React from 'react';

import { Option } from '@hcs/design-system';
import {
  PortfolioEventsFields,
  PortfolioFormFieldConfig,
  PortfolioFormFields,
  PortfolioFormNotificationTypes,
  PortfolioPageParams,
  PortfolioParamsIds,
} from '@hcs/types';

export const PORTFOLIO_PAGE_PARAMS: PortfolioPageParams = {
  [PortfolioParamsIds.PortfolioId]: 'portfolioId',
};

const MlsStatusLabel = () => (
  <>
    <div>MLS Status</div>
    <div>Changes to</div>
  </>
);

export const PORTFOLIO_SETUP_FIELD_CONFIGS: {
  [field in PortfolioFormFields]: PortfolioFormFieldConfig;
} = {
  [PortfolioFormFields.Name]: {
    label: 'Portfolio Name',
    placeHolder: 'My Portfolio',
    field: PortfolioFormFields.Name,
  },
  [PortfolioFormFields.NotificationType]: {
    label: 'Notifications',
    field: PortfolioFormFields.NotificationType,
  },
  [PortfolioFormFields.MlsStatus]: {
    label: <MlsStatusLabel />,
    field: PortfolioFormFields.MlsStatus,
  },
  [PortfolioFormFields.LtvThresholds]: {
    label: 'LTV Thresholds',
    field: PortfolioFormFields.LtvThresholds,
  },
  [PortfolioFormFields.AvmThresholds]: {
    label: 'AVM Thresholds',
    field: PortfolioFormFields.AvmThresholds,
  },
  [PortfolioFormFields.Liens]: {
    label: 'Open Lien',
    field: PortfolioFormFields.Liens,
  },
  [PortfolioFormFields.Nod]: {
    label: 'Notice of Default',
    field: PortfolioFormFields.Nod,
  },
  [PortfolioFormFields.Emails]: {
    label: 'Email Delivery',
    placeHolder: 'username@email',
    field: PortfolioFormFields.Emails,
  },
  [PortfolioFormFields.WebhookUrl]: {
    label: 'Webhook Delivery',
    placeHolder: 'https://example.com',
    field: PortfolioFormFields.WebhookUrl,
  },
  [PortfolioFormFields.WebhookUsername]: {
    label: 'Username',
    placeHolder: 'username',
    field: PortfolioFormFields.WebhookUsername,
  },
  [PortfolioFormFields.WebhookPassword]: {
    label: 'Password',
    placeHolder: 'password',
    field: PortfolioFormFields.WebhookPassword,
  },
};

export const NOTIFICATION_TYPE_OPTIONS_INIT: Option<PortfolioFormNotificationTypes>[] =
  [
    {
      value: PortfolioFormNotificationTypes.Mls,
      label: 'MLS Status',
      detail: 'Get notified when MLS status changes for your properties.',
    },
    {
      value: PortfolioFormNotificationTypes.Ltv,
      label: 'LTV',
      detail: 'Monitor LTV thresholds across your properties.',
    },
    {
      value: PortfolioFormNotificationTypes.Avm,
      label: 'AVM',
      detail: 'Track AVM changes for your properties.',
    },
    {
      value: PortfolioFormNotificationTypes.Lien,
      label: 'Open Lien',
      detail: 'Monitor new open liens placed on your properties.',
    },
    {
      value: PortfolioFormNotificationTypes.Nod,
      label: 'Notice of Default',
      detail:
        'Get notified when a Notice of Default is filed for one of your properties.',
    },
  ];

export const PORTFOLIO_EVENTS_FIELDS_LIST = Object.values(
  PortfolioEventsFields,
) as Array<PortfolioEventsFields>;

export const EVENT_FIELDS_TO_NOTIFICATION_TYPE: {
  [key in PortfolioEventsFields]: PortfolioFormNotificationTypes;
} = {
  [PortfolioEventsFields.Status]: PortfolioFormNotificationTypes.Mls,
  [PortfolioEventsFields.LtvThresholds]: PortfolioFormNotificationTypes.Ltv,
  [PortfolioEventsFields.Avm]: PortfolioFormNotificationTypes.Avm,
  [PortfolioEventsFields.Lien]: PortfolioFormNotificationTypes.Lien,
  [PortfolioEventsFields.Nod]: PortfolioFormNotificationTypes.Nod,
};
