import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { ListingStatusNormalized } from '@hcs/types';

import styles from './Price.module.css';

export type PriceTheme = {
  Price: string;
  Value: string;
  Label: string;
};

export interface PriceProps {
  dataHcName: string;
  theme?: Partial<PriceTheme>;
  price: string | ReactNode;
  date?: string | ReactNode;
  label: string | ReactNode;
  listingStatusColor?: ListingStatusNormalized | null;
}

export const Price = ({
  dataHcName,
  theme,
  listingStatusColor,
  price,
  date,
  label,
}: PriceProps) => {
  return (
    <div data-hc-name={dataHcName} className={theme?.Price}>
      <div>
        <span
          className={classNames(
            styles.Label,
            theme?.Label,
            listingStatusColor
              ? styles[
                  listingStatusColor.toLowerCase() as
                    | 'sold'
                    | 'pending'
                    | 'active'
                    | 'leased'
                ]
              : undefined,
          )}
          data-hc-name={`${dataHcName}-label`}
        >
          {label}
        </span>
        {date && (
          <span className={styles.Date}>
            {' '}
            ・ <span data-hc-name={`${dataHcName}-date`}>{date}</span>
          </span>
        )}
      </div>
      <div
        className={classNames(styles.Value, theme?.Value)}
        data-hc-name={`${dataHcName}-value`}
      >
        {price}
      </div>
    </div>
  );
};
