import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import { useDebounce } from '@hcs/hooks';
import { useLatestInfiniteQueryData } from '@hcs/hooks';
import {
  PropertySpatialSearchListDocument,
  PropertySpatialSearchListQuery,
  PropertySpatialSearchListQueryVariables,
} from '@hcs/types';
import { PropertySpatialHit } from '@hcs/types';
import { logException } from '@hcs/utils';

import {
  convertSpatialHitToPropertySpatialHit,
  makeSafePropertySpatialSearchParams,
} from '../utils';

export const QUERY_KEY_PROPERTY_SPATIAL_SEARCH_LIST =
  'propertySpatialSearchList';
const usePropertySpatialSearchListInfiniteQuery = (
  paramsUnsafe: PropertySpatialSearchListQueryVariables | null,
) => {
  const params = makeSafePropertySpatialSearchParams(paramsUnsafe);
  // Debounce this query in case this is being driven by user interaction with a map
  const debouncedQueryKey = useDebounce(
    [QUERY_KEY_PROPERTY_SPATIAL_SEARCH_LIST, params],
    500,
  );
  return useInfiniteQuery(
    debouncedQueryKey,
    async ({ queryKey, pageParam }) => {
      const params = queryKey[1];

      if (params != null && typeof params !== 'string') {
        // insert pageParam (cursor), which gets populated by the getNextPageParam() below,
        // into the params object for the next request
        const paramsWithCursor = { ...params };
        paramsWithCursor.cursor = pageParam;

        const response = await CerberusApi.fetchQuery<
          PropertySpatialSearchListQuery,
          PropertySpatialSearchListQueryVariables
        >(PropertySpatialSearchListDocument, paramsWithCursor);

        if (
          response.propertySpatialESSearch?.__typename ===
          'SpatialSearchDetailResults'
        ) {
          return {
            ...response.propertySpatialESSearch,
            hits: response.propertySpatialESSearch.hits?.reduce<
              PropertySpatialHit[]
            >((accum, hit) => {
              if (hit) {
                accum.push(convertSpatialHitToPropertySpatialHit(hit));
              }
              return accum;
            }, []),
          };
        } else {
          const err = new Error(
            `usePropertySpatialSearchListInfiniteQuery: query returned __typename: ${response.propertySpatialESSearch?.__typename}, but SpatialSearchDetailResults was expected`,
          );
          logException(err);
          throw err;
        }
      } else {
        const err = new Error(
          'usePropertySpatialSearchListInfiniteQuery called with null params (check enabled logic)',
        );
        logException(err);
        throw err;
      }
    },
    {
      enabled: !!params?.id,
      getNextPageParam: (lastPage) => {
        if (lastPage?.__typename === 'SpatialSearchDetailResults') {
          const atEnd = lastPage.atEnd;
          const cursor = lastPage.cursor;
          if (!atEnd) {
            return cursor;
          }
        }
        return undefined;
      },
    },
  );
};

export const usePropertySpatialSearchList = (
  params: PropertySpatialSearchListQueryVariables | null,
) => {
  const queryResult = useLatestInfiniteQueryData(
    usePropertySpatialSearchListInfiniteQuery(params),
  );
  // combine hits from each page into single propertyHits array
  const propertyHits = useMemo(() => {
    if (queryResult.data?.pages) {
      return queryResult.data.pages.reduce<PropertySpatialHit[]>(
        (hits, page) => {
          if (page.hits) {
            return hits.concat(page.hits);
          }
          return hits;
        },
        [],
      );
    }
    return null;
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: queryResult.data
      ? {
          ...queryResult.data,
          propertyHits,
          totalCount: queryResult.data?.pages[0]?.totalCount,
        }
      : undefined,
  };
};
