import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/authn';

import { HuellApi } from '../api';

export const QUERY_KEY_REPORT_PREFERENCES_USER = 'reportPreferences-user';
export const useReportPreferencesForUser = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return useQuery(
    [QUERY_KEY_REPORT_PREFERENCES_USER],
    HuellApi.fetchReportPreferencesForUser,
    {
      enabled: isLoggedIn,
    },
  );
};
