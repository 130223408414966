import React, { useMemo } from 'react';
import { linearGradientDef, SvgDefsAndFill } from '@nivo/core';
import {
  Datum,
  LineSvgProps,
  ResponsiveLine,
  Serie,
  SliceTooltipProps,
} from '@nivo/line';
import classNames from 'classnames';

import { ChartTimeSeries } from '@hcs/types';
import { ForecastChartFields, ForecastDatumMetadata } from '@hcs/types';
import {
  formatMoney,
  lineChartPaddedMinMax,
  parseStringOrNumber,
} from '@hcs/utils';

import { LINE_COLOR } from '../ChartTimeSeriesLine/ChartTimeSeriesLine';
import { ChartTooltip } from '../ChartTooltip/ChartTooltip';

import styles from './ChartForecastLine.module.css';

const CURRENT_YEAR_SYMBOL = {
  size: 23,
  color: '#ffffff',
  borderWidth: 2,
  borderColor: '#4a4a4a',
};
const FORECAST_YEAR_SYMBOL = {
  size: 13,
  color: '#4a4a4a',
  borderWidth: 1,
  borderColor: '#ffffff',
};
// Add more gradients if you would like to shade additional forecast years:
const FORECAST_GRADIENTS = [
  {
    gradientId: 'gradient_historical',
    offset: 0,
    opacity: 0,
    color: '#ffffff',
  },
  {
    gradientId: 'gradient_0',
    offset: 0,
    opacity: 0.3,
    color: LINE_COLOR,
  },
  {
    gradientId: 'gradient_1',
    offset: 0,
    opacity: 0.5,
    color: LINE_COLOR,
  },
  {
    gradientId: 'gradient_2',
    offset: 0,
    opacity: 0.7,
    color: LINE_COLOR,
  },
];

const COMMON_RESPONSIVE_LINE_PROPS: Partial<LineSvgProps> = {
  lineWidth: 2,
  colors: [LINE_COLOR],
  margin: { top: 20, right: 13, bottom: 30, left: 70 },
  xScale: {
    type: 'time',
    precision: 'day',
    format: '%Y-%m-%d',
    useUTC: false,
  },
  xFormat: 'time:%m/%d/%Y',
  axisBottom: {
    format: '%Y',
    tickSize: 6,
  },
  axisLeft: {
    tickSize: 6,
    tickValues: 5,
    format: (value) => `${formatMoney(Number(value) / 1000)}k`,
  },
  enableArea: true,
  areaOpacity: 1,
  enablePoints: true,
  enableSlices: 'x',
  enableGridX: false,
  enableGridY: false,
  theme: {
    fontFamily: 'Avenir, sans-serif',
    textColor: '#4A4A4A',
    fontSize: 12,
    crosshair: {
      line: {
        stroke: '#4A4A4A',
        strokeDasharray: '1',
      },
    },
  },
  defs: FORECAST_GRADIENTS.map(({ gradientId, offset, opacity, color }) =>
    linearGradientDef(gradientId, [{ offset, color, opacity }]),
  ),
};

export interface ChartForecastLineProps<T, M> {
  currentMonthIndex: number;
  data: ChartTimeSeries<T, M>;
  dataHcName: string;
  className?: string;
  shouldPadYRange?: boolean;
}
type ForecastChartExtraData =
  | ForecastChartFields
  | (ForecastChartFields & {
      [key: string | number]: unknown;
    });
type ForecastDatumMetadataExtraData =
  | ForecastDatumMetadata
  | (ForecastDatumMetadata & {
      [key: string | number]: unknown;
    });
export const ChartForecastLine = <
  // Must have currentMonthIndex but can also have other fields on the chart data
  T extends ForecastChartExtraData,
  // Can pass any metadata
  M extends ForecastDatumMetadataExtraData,
>({
  dataHcName,
  className,
  currentMonthIndex,
  data,
  shouldPadYRange = true,
}: ChartForecastLineProps<T, M>) => {
  const lineChartData = useMemo(() => {
    const allChartSerie: Serie[] = [];
    const forecastSerie: Serie[] = [];
    const forecastYearIndices: number[] = [];
    const fill: SvgDefsAndFill<unknown>['fill'] = [];
    if (data.data) {
      const allDataWithIndex = data.data.map((datum, i) => {
        return {
          ...datum,
          index: i,
        };
      });

      allChartSerie.push({
        id: 'Historical Value',
        data: allDataWithIndex,
      });

      let monthIndex = currentMonthIndex;
      let currentYear = 1;
      while (monthIndex < allDataWithIndex.length) {
        const idxYear = monthIndex + 12;
        forecastSerie.push({
          id: `${currentYear} Year`,
          data: data.data.slice(monthIndex, idxYear + 1),
          yearIndex: monthIndex,
        });
        currentYear += 1;
        monthIndex += 12;
      }

      forecastSerie.forEach((forecastYear) => {
        forecastYearIndices.push(forecastYear.yearIndex);
      });

      allChartSerie.concat(forecastSerie).forEach(({ id }, i) => {
        fill.push({
          match: { id },
          id: FORECAST_GRADIENTS[i]?.gradientId || '',
        });
      });
    }
    return {
      allChartSerie,
      forecastSerie,
      forecastYearIndices,
      fill,
    };
  }, [data, currentMonthIndex]);
  const { allChartSerie, forecastSerie, forecastYearIndices, fill } =
    lineChartData;

  // X/Y Padding for Chart
  const paddedMinMaxY = useMemo(() => {
    if (shouldPadYRange) {
      return lineChartPaddedMinMax(allChartSerie);
    }
    return null;
  }, [allChartSerie, shouldPadYRange]);

  // Create and place SVG on Chart
  const chartSymbolFactory = ({
    size,
    color,
    borderWidth,
    borderColor,
  }: {
    size: number;
    color?: string;
    borderWidth: number;
    borderColor: string;
  }) => (
    <g>
      <circle
        fill={color}
        r={size / 2}
        strokeWidth={borderWidth}
        stroke={borderColor}
      />
    </g>
  );
  const customPointSymbol = ({ datum }: { datum: Datum }) => {
    if (datum.index === currentMonthIndex) {
      return chartSymbolFactory(CURRENT_YEAR_SYMBOL);
    } else if (forecastYearIndices.includes(datum.index)) {
      const allChartSerieArray = allChartSerie[0]?.data;
      // If datum is the very last chunk of data in line chart, do not plot SVG.
      if (allChartSerieArray && datum.index !== allChartSerieArray.length - 1) {
        return chartSymbolFactory(FORECAST_YEAR_SYMBOL);
      }
    }
    return null;
  };

  const getSliceTooltip = ({ slice }: SliceTooltipProps) => {
    // "allDataPoint" will always be the last element of points[] in this instances.
    // Gradient shade serie will always be "topLayerPoint".
    const allDataPoint = slice.points[slice.points.length - 1];
    const topLayerPoint = slice.points[0];

    if (allDataPoint && topLayerPoint) {
      return (
        <ChartTooltip
          dataHcName={`${dataHcName}-tooltip`}
          dataRows={[
            {
              id: `${topLayerPoint.id}`,
              dotColor: {
                colorType: 'custom',
                color: topLayerPoint.serieColor,
              },
              label:
                allDataPoint.index === currentMonthIndex
                  ? 'Current Value'
                  : topLayerPoint.serieId,
              value: formatMoney(
                parseStringOrNumber(allDataPoint.data.yFormatted),
              ),
            },
          ]}
        />
      );
    }
    return null;
  };

  return (
    <div
      data-hc-name={`${dataHcName}-hc-forecast-chart`}
      className={classNames(styles.HcForecastChart, className)}
    >
      <ResponsiveLine
        {...COMMON_RESPONSIVE_LINE_PROPS}
        fill={fill}
        // overlay forecast on top of allChartSerie:
        data={allChartSerie.concat(forecastSerie)}
        pointSymbol={customPointSymbol}
        sliceTooltip={getSliceTooltip}
        areaBaselineValue={
          paddedMinMaxY !== null ? paddedMinMaxY.min : undefined
        }
        yScale={{
          type: 'linear',
          min: paddedMinMaxY !== null ? paddedMinMaxY.min : undefined,
          max: paddedMinMaxY !== null ? paddedMinMaxY.max : undefined,
        }}
      />
    </div>
  );
};
