import {
  EngagementEventArg,
  EngagementEventData,
  MeaningfulEventTypes,
} from '@hcs/types';

export const isMeaningfulEvent = (event: EngagementEventArg) => {
  return (
    event.event_type &&
    Object.values<string>(MeaningfulEventTypes).includes(event.event_type)
  );
};
const findEventSection = (element: Element): string | undefined => {
  const dataHcEventSection = element.getAttribute('data-hc-event-section');
  if (dataHcEventSection) {
    return dataHcEventSection;
  } else {
    const parentElement = element.parentElement;
    if (parentElement) {
      return findEventSection(parentElement);
    } else {
      return undefined;
    }
  }
};

export interface BuildEngagementEventOptions {
  findAncestor?: boolean;
  event_data?: Partial<EngagementEventData>;
  event_type?: string;
}
export const buildEngagementEventFromElement = (
  element: Element,
  eventPrefix: string,
  options?: BuildEngagementEventOptions,
): EngagementEventArg | undefined => {
  const dataHcName = element.getAttribute('data-hc-name');
  const dataHcEventName = element.getAttribute('data-hc-event-name');
  const dataHcEventIgnore = element.getAttribute('data-hc-event-ignore');
  if (!dataHcEventIgnore && (dataHcName || dataHcEventName)) {
    const dataHcEventType =
      element.getAttribute('data-hc-event-type') || undefined;
    const dataHcEventDataRaw = element.getAttribute('data-hc-event-data');
    const dataHcEventData = dataHcEventDataRaw
      ? JSON.parse(dataHcEventDataRaw)
      : undefined;
    const dataHcEventSection = findEventSection(element);
    return {
      event_data: {
        ...dataHcEventData,
        ...options?.event_data,
        event_section: dataHcEventSection,
        dataHcName,
        originalEventType: dataHcEventType ? options?.event_type : undefined,
      },
      event_type: dataHcEventType || options?.event_type,
      event_name: dataHcEventName || `${eventPrefix}-${dataHcName}`,
    };
  } else if (options?.findAncestor && element.parentElement) {
    return buildEngagementEventFromElement(
      element.parentElement,
      eventPrefix,
      options,
    );
  }
  return undefined;
};

export const getPageGroupFromUrl = () => {
  return window.location.pathname.split('/')[2];
};
