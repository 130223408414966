import { Operation } from 'fast-json-patch';

import { EventSourceType } from '../sse';

import { ReportApiDocument } from './Document.types';
import { UserPropertyAdjustments } from './PropertyAdjustments.types';
import { ReportBase, ReportId } from './Report.types';
import { DocumentBase, DocumentRoles } from '.';

export enum ReportEventTypes {
  DocumentsCreated = 'DocumentsCreated',
  DocumentsUpdated = 'DocumentsUpdated',
  DocumentsDeleted = 'DocumentsDeleted',
  DocumentsRefreshed = 'DocumentsRefreshed',
  ReportCreated = 'ReportCreated',
  UpdateCancelled = 'UpdateCancelled',
  Error = 'Error',
}

interface ReportBaseWithUserAdjustmentChanges extends ReportBase {
  userPropertyAdjustmentChanges?: UserPropertyAdjustments[];
}

export interface ReportApiDocumentEdit {
  createdAt: string;
  clientPatchId?: string;
  documentId: string;
  documentRole: DocumentRoles;
  id: number;
  patchData: Operation[];
  systemGenerated: boolean;
  tag: string;
  updatedAt: string;
  userId: number;
}

export interface DocumentMutationVariables {
  document: DocumentBase;
  operations: Operation[];
  reportId: ReportId;
}

export interface DocumentMutation {
  state: {
    variables: DocumentMutationVariables;
  };
}

export interface ReportEventData extends ReportBase {
  documents: ReportApiDocument[];
}

interface ReportEventDataWithUserAdjustmentChanges
  extends ReportBaseWithUserAdjustmentChanges {
  documents: ReportApiDocument[];
}

export interface ReportOperationsData
  extends ReportBaseWithUserAdjustmentChanges {
  documentEdits: ReportApiDocumentEdit[];
}

export interface ReportEventNoData extends ReportBase {
  documents: DocumentBase[];
}

interface ReportEventBase<
  D extends
    | ReportOperationsData
    | ReportEventData
    | ReportEventNoData
    | ReportBase
    | ReportEventDataWithUserAdjustmentChanges,
> {
  clientPatchId?: string;
  timestamp: number;
  report: D;
  editedBy?: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
  };
  sseEventType: EventSourceType.Report;
}
export interface ReportCreatedEvent extends ReportEventBase<ReportEventData> {
  type: ReportEventTypes.ReportCreated;
}

export interface ReportErrorEvent extends ReportEventBase<ReportEventData> {
  message: string;
  type: ReportEventTypes.Error;
}

export interface UpdateCancelledEvent extends ReportEventBase<ReportEventData> {
  type: ReportEventTypes.UpdateCancelled;
  clientPatchId?: string;
  patchStatus: 'CANCELLED';
}

export interface DocumentsCreatedEvent
  extends ReportEventBase<ReportEventDataWithUserAdjustmentChanges> {
  type: ReportEventTypes.DocumentsCreated;
  patchStatus: 'IN_PROGRESS' | 'DONE' | 'ERROR';
}

interface DocumentsRefreshedEvent extends ReportEventBase<ReportBase> {
  type: ReportEventTypes.DocumentsRefreshed;
  patchStatus: 'IN_PROGRESS' | 'DONE' | 'ERROR';
}

export interface DocumentUpdatedEvent
  extends ReportEventBase<ReportOperationsData> {
  type: ReportEventTypes.DocumentsUpdated;
  patchStatus: 'IN_PROGRESS' | 'DONE' | 'ERROR';
}

export interface DocumentDeletedEvent
  extends ReportEventBase<ReportEventNoData> {
  type: ReportEventTypes.DocumentsDeleted;
  patchStatus: 'IN_PROGRESS' | 'DONE' | 'ERROR';
}

export type ReportEvent =
  | ReportCreatedEvent
  | DocumentsCreatedEvent
  | DocumentsRefreshedEvent
  | DocumentDeletedEvent
  | DocumentUpdatedEvent
  | UpdateCancelledEvent
  | ReportErrorEvent;
