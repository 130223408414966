import { DocumentRoles, NearbyFarmDocument, ReportId } from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useNearbyFarmDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<NearbyFarmDocument>(
    reportId,
    DocumentRoles.NearbyFarm,
  );
};
