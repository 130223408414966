import React from 'react';

import { useUserIsOrgAdmin } from '@hcs/auth';
import { MainNav } from '@hcs/design-system';
import { OrganizationIcon, UserSettingsIcon } from '@hcs/design-system';
import { APP_CONFIG_ORG_ADMIN, APP_CONFIG_USER_ADMIN } from '@hcs/hc-products';

const ORG_ADMIN_ROOT_PATH = APP_CONFIG_ORG_ADMIN.rootPath;
const USER_ADMIN_ROOT_PATH = APP_CONFIG_USER_ADMIN.rootPath;
const dataHcName = 'admin-left-nav';
export const AdminNav = () => {
  const { data: isOrgAdmin } = useUserIsOrgAdmin();
  const NAV_ITEMS_CONFIG = [
    {
      label: 'User',
      navigateTo: USER_ADMIN_ROOT_PATH,
      dataHcName: `${dataHcName}-user-link`,
      Icon: UserSettingsIcon,
    },
  ];
  if (isOrgAdmin) {
    NAV_ITEMS_CONFIG.push({
      label: 'Organization',
      navigateTo: ORG_ADMIN_ROOT_PATH,
      dataHcName: `${dataHcName}-organization-link`,
      Icon: OrganizationIcon,
    });
  }
  return (
    <MainNav
      dataHcName={dataHcName}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
