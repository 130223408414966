import { Navigate, RouteObject } from 'react-router-dom';

import {
  APP_CONFIG_DEVELOPER_CENTER,
  APP_CONFIG_USER_ADMIN,
  VIEW_PATHS_USER_ADMIN,
} from '@hcs/hc-products';

export const routes = (): RouteObject[] => [
  {
    path: APP_CONFIG_DEVELOPER_CENTER.rootPath,
    element: (
      <Navigate
        to={`${APP_CONFIG_USER_ADMIN.rootPath}/${VIEW_PATHS_USER_ADMIN.API_KEYS}`}
        replace
      />
    ),
  },
];
