import { useNavigate } from 'react-router';

import { APP_CONFIG_AGILE_SUITE, VIEW_PATHS_ORDER_MANAGER } from '../constants';

export const useNavigateToAgileSuite = () => {
  const navigate = useNavigate();
  return () => {
    navigate(
      `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDERS}`,
    );
  };
};
