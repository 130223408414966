import React from 'react';

import { Button } from '@hcs/design-system';
import { DistanceSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { DrawType } from '@hcs/types';
import { CompFields, CompTypes } from '@hcs/types';
import { CompsFiltersPaths, ReportFeatures } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';

import {
  useCompsFiltersDocument,
  useDocumentPatch,
  useSubjectDocument,
} from '../../../hooks';
import { useReportPermissions } from '../../../hooks';
import { ReportFeaturesSupported } from '../../ReportFeaturesSupported';

import { CompFilterChip } from './CompFilterChip';

import styles from './CompFilterDistance.module.css';

const FIELD = CompFields.distance;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const fieldConfig = COMP_FIELDS_CONFIG[FIELD];
const dataHcName = 'comp-filter-distance';
export const COMP_FILTER_DISTANCE_FEATURES_SALE = [
  ReportFeatures.CompsFilterDistance,
  ReportFeatures.CompsFilterGeojson,
];
export const COMP_FILTER_DISTANCE_FEATURES_RENTAL = [
  ReportFeatures.CompsFilterDistance,
  ReportFeatures.CompsFilterGeojson,
];
// Distance is a special filter that requires additional props so it is not a CompFilterConfig type
export const COMP_FILTER_DISTANCE = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const label = filterValue?.relativeValue
      ? `${filterValue.relativeValue} Mile${
          filterValue.relativeValue === 1 ? '' : 's'
        } from Subject`
      : filterValue?.absoluteValue?.properties?.drawType &&
          filterValue.absoluteValue.properties.shape
        ? `${capitalizeFirstLetter(
            `${filterValue.absoluteValue.properties.drawType}`,
          )} ${`${filterValue.absoluteValue.properties.shape}`}`
        : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({
    reportId,
    disabled = false,
    compType,
    navigateToCompSelectionDraw,
    className,
  }: CompFilterControlTypeProps & {
    navigateToCompSelectionDraw: NavigateToCompSelectionDrawFn;
    disabled?: boolean;
  }) => {
    const isRental = compType === CompTypes.Rental;
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }
    const geoJsonFeature =
      filterDocument.data.filters?.[CompFields.distance]?.absoluteValue || null;
    const distanceRadiusMiles =
      filterDocument.data.filters?.[CompFields.distance]?.relativeValue || null;
    const distanceSelectValue = distanceRadiusMiles
      ? distanceRadiusMiles
      : // If no radius but there is a geoJson pass undefined so select option is checked
        geoJsonFeature
        ? undefined
        : null;

    return (
      <>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? [ReportFeatures.RentalCompsFilterDistance]
              : [ReportFeatures.CompsFilterDistance]
          }
        >
          <DistanceSelect
            dataHcName={`${dataHcName}-select`}
            disabled={!reportPermissions?.isEditable}
            value={distanceSelectValue}
            className={className}
            onChange={(radius) => {
              if (radius) {
                documentPatchMutation.mutate({
                  reportId,
                  document: filterDocument,
                  operations: [
                    {
                      op: 'add',
                      path: PATH_REL,
                      value: radius,
                    },
                    {
                      op: 'add',
                      path: PATH_FIELD,
                      value: CompFields.distance,
                    },
                  ],
                });
              } else if (radius === null) {
                documentPatchMutation.mutate({
                  reportId,
                  document: filterDocument,
                  operations: [
                    {
                      op: 'remove',
                      path: PATH_FILTER,
                    },
                  ],
                });
              }
            }}
          />
        </ReportFeaturesSupported>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            compType === CompTypes.Rental
              ? COMP_FILTER_DISTANCE_FEATURES_RENTAL
              : COMP_FILTER_DISTANCE_FEATURES_SALE
          }
        >
          <Button
            disabled={disabled || !reportPermissions?.isEditable}
            onClick={() => navigateToCompSelectionDraw(reportId, compType)}
            className={styles.Button}
            secondary
            dataHcName="distance-draw-button"
          >
            {geoJsonFeature &&
            'properties' in geoJsonFeature &&
            geoJsonFeature.properties?.drawType === ('custom' as DrawType)
              ? `Edit ${capitalizeFirstLetter(
                  `${geoJsonFeature.properties.drawType}`,
                )}
          ${capitalizeFirstLetter(`${geoJsonFeature.properties.shape}`)}`
              : 'Draw Custom Area'}
          </Button>
        </ReportFeaturesSupported>
      </>
    );
  },
};
