import React, { useMemo } from 'react';

import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import {
  PropertyStateTable,
  PropertyStateTableDatum,
  PropertyStateTableProps,
} from '@hcs/property-state';
import { PropertySpatialSearchListQueryVariables } from '@hcs/types';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';

import { usePropertySpatialSearchList } from '../../hooks/usePropertySpatialSearchList';

const DEFAULT_TABLE_FIELDS: PropertyStateTableProps['fields'] = [
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentPrice,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatusDate,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatus,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bedrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bathrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.livingArea,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.lotSize,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.yearBuilt,
  },
];
export interface PropertySpatialSearchListTableProps {
  spatialSearchVariables: PropertySpatialSearchListQueryVariables;
  propertyStateTableProps?: Omit<
    PropertyStateTableProps,
    'data' | 'dataHcName'
  >;
}
const dataHcName = 'property-spatial-search-list';
export const PropertySpatialSearchListTable = ({
  spatialSearchVariables,
  propertyStateTableProps,
}: PropertySpatialSearchListTableProps) => {
  const { data, fetchNextPage } = usePropertySpatialSearchList(
    spatialSearchVariables,
  );
  const sort = spatialSearchVariables.sort;
  const propertyStateTableFields = useMemo<
    PropertyStateTableProps['fields']
  >(() => {
    const fields: PropertyStateTableProps['fields'] = [];
    (propertyStateTableProps?.fields || DEFAULT_TABLE_FIELDS).forEach(
      (field) => {
        if (field.type === 'propertyStateFields') {
          const { spatialSortField } =
            PROPERTY_STATE_FIELD_CONFIGS[field.value];
          fields.push({
            ...field,
            headerCellProps: {
              ...field.headerCellProps,
              sortable: true,
              sort:
                sort?.field === spatialSortField
                  ? sort?.order === 'ASCENDING'
                    ? 'ASC'
                    : 'DESC'
                  : undefined,
              onClick: () => {
                field.headerCellProps?.onClick?.();
              },
            },
          });
        } else {
          fields.push(field);
        }
      },
    );
    return fields;
  }, [sort, propertyStateTableProps]);
  const propertyStateCerberusInputs = data?.propertyHits?.map((hit) =>
    hit.hcAddressId
      ? {
          propertyStateType: PropertyStateType.Core,
          cerberusInput: {
            hcAddressId: hit.hcAddressId,
          },
        }
      : null,
  );
  return (
    <PropertyStateTable
      {...propertyStateTableProps}
      dataHcName={`${dataHcName}-table`}
      fields={propertyStateTableFields}
      data={
        propertyStateCerberusInputs?.map((propertyStateCerberusInput) => {
          const datum: PropertyStateTableDatum = {
            propertyStateCerberusInput,
          };
          return datum;
        }) || []
      }
      infiniteScroll={{
        onScrollToBottom: fetchNextPage,
        // footer:
        //   isFetchingNextPage || hasNextPage ? undefined : (
        //     <>
        //       <Disclosure dataHcName="disclosure" includeAVMSection={false} />
        //       {mlsIds.map((mlsId) => (
        //         <MlsAttribution
        //           key={`${dataHcName}-mls-attribution-${mlsId}`}
        //           mlsId={parseInt(mlsId)}
        //           className={styles.MlsAttributionRow}
        //         />
        //       ))}
        //     </>
        //   )
      }}
    />
  );
};
