import {
  DocumentRoles,
  ReportId,
  SubjectTransactionHistoryDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSubjectTransactionHistoryDocument = (reportId: ReportId) => {
  return useSingleDocumentRole<SubjectTransactionHistoryDocument>(
    reportId,
    DocumentRoles.SubjectTransactionHistory,
  );
};
