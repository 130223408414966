import { useQuery } from '@tanstack/react-query';

import { hookEnabledError } from '@hcs/errors';

import { OrgUsersApi } from '../api';

export const QUERY_KEY_ORG_INVITES = 'QUERY_KEY_ORG_INVITES';
export const useOrgInvites = (orgId: number | undefined) => {
  return useQuery(
    [QUERY_KEY_ORG_INVITES, orgId],
    async () => {
      if (orgId) {
        return await OrgUsersApi.getOrgInvites(orgId);
      } else {
        throw hookEnabledError('useOrgInvites', 'orgId');
      }
    },
    {
      enabled: !!orgId,
    },
  );
};
