import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { NullState } from '@hcs/design-system';
import { usePrevious } from '@hcs/hooks';
import { DEFAULT_LAYERS, DEFAULT_LAYERS_RENTAL } from '@hcs/maps';
import { HcMap } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerTypes } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { GeoLocation } from '@hcs/types';
import { PreferencesKeys } from '@hcs/types';
import { ControlPosition, FitBoundsPayload } from '@hcs/types';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { ReportFeatures, ReportId } from '@hcs/types';
import { OpenMarkerPopup } from '@hcs/types';

import { useSelectedOrSuggestedComps } from '../../hooks';
import { useSubjectDocument } from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import { CompFilterDistanceGeoJsonLayer } from '../CompFilterDistanceGeoJsonLayer';
import { CompMarkerPopup } from '../CompMarkerPopup';
import { SubjectMarker } from '../SubjectMarker';
import { SubjectMarkerPopup } from '../SubjectMarkerPopup';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  children?: ReactNode;
  scrollZoom?: boolean;
}

export const COMP_SUMMARY_MAP_FEATURES_SALE = [
  ReportFeatures.CompsHcSuggested,
  ReportFeatures.CompsSelect,
];
export const COMP_SUMMARY_MAP_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsHcSuggested,
  ReportFeatures.RentalCompsSelect,
];
export const COMP_SUMMARY_MAP_ID = 'COMP_SUMMARY_MAP_ID';
const dataHcName = 'comp-summary-map';
export const CompSummaryMap = ({
  reportId,
  compType,
  children,
  scrollZoom = true,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  // Need unique map id for rental variant
  const mapId =
    compType === CompTypes.Rental
      ? `RENTAL_${COMP_SUMMARY_MAP_ID}-${reportId}`
      : `COMP_SUMMARY_MAP_ID-${reportId}`;
  const { mapState } = useHcMap(mapId);
  const { isFetched: isFetchedSubject, data: subjectDocument } =
    useSubjectDocument(reportId);
  const { latitude, longitude } =
    subjectDocument?.data.propertyState.location || {};
  const [openMarkerPopup, setOpenMarkerPopup] =
    useState<OpenMarkerPopup | null>(null);
  const {
    isFetched: isFetchedComps,
    isError,
    data,
  } = useSelectedOrSuggestedComps(reportId, compType);
  const onCloseMarkerPopup = useCallback(() => setOpenMarkerPopup(null), []);
  const onOpenSubjectMarkerPopup = useCallback(
    () => setOpenMarkerPopup({ type: 'subject' }),
    [],
  );
  const { comps } = data || {};
  const isFetched = isFetchedSubject && isFetchedComps;
  const { showMarkers } = mapState?.markers || {};
  const { geoLocationsToFit, compMarkers } = useMemo(() => {
    const compMarkers: ReactNode[] = [];
    const geoLocationsToFit: GeoLocation[] = [];
    // Subject
    const subjectGeoLocation: GeoLocation | undefined =
      subjectDocument?.data.propertyState.location?.latitude &&
      subjectDocument?.data.propertyState.location.longitude
        ? {
            latitude: subjectDocument.data.propertyState.location.latitude,
            longitude: subjectDocument.data.propertyState.location.longitude,
          }
        : undefined;
    if (subjectGeoLocation) {
      geoLocationsToFit.push(subjectGeoLocation);
    }
    // Comp Markers
    comps?.forEach((comp, i) => {
      const markerId = `summary-map-comp-marker-${comp.compIdentifier.compId}-${i}`;
      const compGeoLocation: GeoLocation | undefined =
        comp.compSchema.propertyState.location?.latitude &&
        comp.compSchema.propertyState.location.longitude
          ? {
              latitude: comp.compSchema.propertyState.location.latitude,
              longitude: comp.compSchema.propertyState.location.longitude,
            }
          : undefined;
      if (compGeoLocation) {
        geoLocationsToFit.push(compGeoLocation);
        compMarkers.push(
          <PropertyMarker
            key={markerId}
            markerId={markerId}
            geoLocation={compGeoLocation}
            label={i + 1}
            type={PropertyMarkerTypes.Comp}
            onClick={() =>
              setOpenMarkerPopup({
                type: 'comp',
                compIdentifier: comp.compIdentifier,
              })
            }
            selected
          />,
        );
      }
    });
    return { compMarkers, geoLocationsToFit };
  }, [comps, subjectDocument]);
  const fitId = `${compType}-summary-map`;
  const [fitToBoundsInfo, setFitToBoundsInfo] = useState<
    Omit<FitBoundsPayload, 'mapId'> | undefined
  >({ coords: geoLocationsToFit, fitId });

  const prevLocationsToFit = usePrevious(geoLocationsToFit);

  // It takes a few renders before geoLocationsToFit is set.
  // We use this useEffect when the map mounts we can set fitToBoundsInfo,
  // once we have geoLocationsToFit set to an actual value.
  // When we click zoom in or zoom out button, then the geoLocationsToFit
  // is set to undefined. At his point we no longer update fitToBoundsInfo
  // because we want to stop the map auto zooming to fit the markers.
  useEffect(() => {
    if (
      fitToBoundsInfo !== undefined &&
      ((prevLocationsToFit === undefined && geoLocationsToFit) ||
        (prevLocationsToFit &&
          geoLocationsToFit &&
          JSON.stringify(prevLocationsToFit) !==
            JSON.stringify(geoLocationsToFit)))
    ) {
      setFitToBoundsInfo({ coords: geoLocationsToFit, fitId });
    }
  }, [fitToBoundsInfo, prevLocationsToFit, geoLocationsToFit]);

  // If the user zooms in or out, stop auto zooming to fit the markers
  const onZoomControlChange = () => {
    setFitToBoundsInfo(undefined);
  };

  if (isFetched && (!latitude || !longitude)) {
    return (
      <NullState
        title="Error: Missing subject coordinates"
        dataHcName={`${dataHcName}-error`}
      />
    );
  }
  const subjectPropertyType = subjectDocument?.data.propertyState
    ? getPropertyStateFieldValue(PropertyStateFields.propertyType, {
        propertyStateType: PropertyStateType.Core,
        propertyState: subjectDocument.data.propertyState,
      })
    : undefined;
  return (
    <HcMap
      dataHcName={dataHcName}
      mapId={mapId}
      uiPreferencesKey={
        compType === CompTypes.Rental
          ? PreferencesKeys.CompsMapRental
          : PreferencesKeys.CompsMapSale
      }
      loading={!isFetched}
      latitude={latitude || undefined}
      longitude={longitude || undefined}
      scrollZoom={scrollZoom}
      onZoomControlChange={onZoomControlChange}
      fitBounds={fitToBoundsInfo}
      propertyType={subjectPropertyType}
      layersControl={
        reportFeaturesSupportedAny(reportConfig, [ReportFeatures.Heatmaps])
          ? {
              position: ControlPosition.BottomRight,
              vectilesMetricGroups:
                compType === CompTypes.Rental
                  ? DEFAULT_LAYERS_RENTAL
                  : DEFAULT_LAYERS,
            }
          : undefined
      }
      satelliteControl={{ position: ControlPosition.BottomLeft }}
      zoomControl={{ position: ControlPosition.TopLeft }}
    >
      {isError && (
        <NullState
          dataHcName={`${dataHcName}-error`}
          title={`Error Loading ${
            compType === CompTypes.Rental ? 'Rental ' : ''
          }Comps`}
        />
      )}
      <CompFilterDistanceGeoJsonLayer reportId={reportId} compType={compType} />
      {showMarkers && compMarkers}
      <SubjectMarker reportId={reportId} onClick={onOpenSubjectMarkerPopup} />
      {children}
      {openMarkerPopup?.type === 'comp' && (
        <CompMarkerPopup
          compIdentifier={openMarkerPopup.compIdentifier}
          reportId={reportId}
          onClose={onCloseMarkerPopup}
        />
      )}
      {openMarkerPopup?.type === 'subject' && (
        <SubjectMarkerPopup reportId={reportId} onClose={onCloseMarkerPopup} />
      )}
    </HcMap>
  );
};
