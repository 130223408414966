import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

// IMPORTANT: Consuming components should wrap callback in useCallback
// to prevent rebinding of the listeners on each render
export const useWindowResize = (callback: VoidFunction, delay = 700) => {
  const debouncedHandleResize = useCallback(debounce(callback, delay), [
    callback,
    delay,
  ]);
  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize]);
};
