import { capitalizeFirstLetter } from './strings.utils';

const maxFieldSizeMessage = (fieldLabel: string, maxSize: number) =>
  `${capitalizeFirstLetter(fieldLabel)} must be at most ${maxSize} characters`;

const minFieldSizeMessage = (fieldLabel: string, minSize: number) =>
  `${capitalizeFirstLetter(fieldLabel)} must be at least ${minSize} characters`;

export const validatorUtils = {
  maxFieldSizeMessage,
  minFieldSizeMessage,
};

export const isAnyTruthy = (values: unknown[]) => values.some((v) => !!v);
export const areAllTruthy = (values: unknown[]) => values.every((v) => !!v);
export const isAnyFalsey = (values: unknown[]) => values.some((v) => !v);
export const areAllFalsey = (values: unknown[]) => values.every((v) => !v);

/*
 * SECURITY: the regex has the potential to run for a long time and block the event loop (depending on input),
 * but the effect of that would just be crashing the user's tab @jdimattia
 */
export const ValidHostnameRegex =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
