import { useEffect, useState } from 'react';

import thumbnailPlaceholderImg0 from '../assets/images/thumbnail-placeholder-0.jpg';
import thumbnailPlaceholderImg1 from '../assets/images/thumbnail-placeholder-1.png';
import thumbnailPlaceholderImg2 from '../assets/images/thumbnail-placeholder-2.png';
import thumbnailPlaceholderImg3 from '../assets/images/thumbnail-placeholder-3.png';
import thumbnailPlaceholderImg4 from '../assets/images/thumbnail-placeholder-4.png';
import thumbnailPlaceholderImg5 from '../assets/images/thumbnail-placeholder-5.png';

const PLACEHOLDERS = [
  thumbnailPlaceholderImg0,
  thumbnailPlaceholderImg1,
  thumbnailPlaceholderImg2,
  thumbnailPlaceholderImg3,
  thumbnailPlaceholderImg4,
  thumbnailPlaceholderImg5,
];

const createPlaceholderMapping = (
  urls: string[],
  existingMapping?: Record<string, string>,
) => {
  const results: Record<string, string> = {};
  urls.forEach((url) => {
    const existingPlaceholder = existingMapping?.[url];
    if (existingPlaceholder) {
      results[url] = existingPlaceholder;
    } else {
      const newPlaceholder =
        PLACEHOLDERS[Math.floor(Math.random() * (PLACEHOLDERS.length - 1)) + 1];
      if (newPlaceholder) {
        results[url] = newPlaceholder;
      }
    }
  });
  return results;
};

// Returns a mapping of url to placeholder image
// Ensures placeholder image does not change for a given url
export const usePlaceholderImages = (urls: string[]) => {
  const [placeholders, setPlaceholders] = useState(
    createPlaceholderMapping(urls),
  );

  useEffect(() => {
    setPlaceholders(createPlaceholderMapping(urls, placeholders));
  }, [urls]);
  return placeholders;
};
