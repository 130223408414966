import { ReactNode } from 'react';

import {
  Dialog,
  InfoTooltip,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { useDexpEndpointTiers } from '@hcs/hc-products';
import { useOrgSelfServeInfo } from '@hcs/self-serve-info';
import { SELF_SERVICE_TIERS_MAPPING } from '@hcs/self-service';
import { MeaningfulEventTypes } from '@hcs/types';
import { formatMoney, formatNumber } from '@hcs/utils';

import { useDexpEndpoints, useDexpEndpointsTable } from '../../hooks';
import { getEndpointTierLabelAndCostKey } from '../../utils';
import { DexpShoppingCartCardProps } from '../DexpShoppingCartCard';

import styles from './DexpAnalysisPriceEstimate.module.css';
const dataHcName = 'dexp-analysis-price-confirmation';
export const DexpAnalysisPriceEstimate = () => {
  const {
    state: { endpointsInShoppingCart },
  } = useDexpEndpointsTable();
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  const { data: dexpTiers } = useDexpEndpointTiers();
  const { data: dexpEndpoints } = useDexpEndpoints();
  const currentSsTier =
    orgSsInfo?.self_serve_plan?.name ||
    // If orginfo has active_plan but no self_service_plan then have cancelled their subscription but their trial has not expired
    (orgSsInfo?.active_plan ? 'Pro' : undefined);
  const tierConfig = currentSsTier && SELF_SERVICE_TIERS_MAPPING[currentSsTier];
  const endpointIds = Object.keys(endpointsInShoppingCart);
  const init: Record<string, number> = {};
  const countByTier = endpointIds.reduce((acc, endpointId) => {
    const endpoint = dexpEndpoints?.find((e) => e.id === endpointId);
    if (!endpoint) return acc;
    const { endpointTierLabel } = getEndpointTierLabelAndCostKey(
      endpoint,
      dexpTiers,
    );
    if (!endpointTierLabel) return acc;
    return {
      ...acc,
      [endpointTierLabel]: acc[endpointTierLabel]
        ? acc[endpointTierLabel] + 1
        : 1,
    };
  }, init);
  const totalCost =
    (Number(tierConfig?.costs['data-points-basic']?.value) || 0) *
      (countByTier['Basic'] || 0) +
    (Number(tierConfig?.costs['data-points-premium']?.value) || 0) *
      (countByTier['Premium'] || 0) +
    (Number(tierConfig?.costs['data-points-premium-plus']?.value) || 0) *
      (countByTier['Premium-Plus'] || 0) +
    (Number(tierConfig?.costs['property-estimate-api']?.value) || 0) *
      (countByTier['Property Estimate'] || 0) +
    (Number(tierConfig?.costs['build-to-api']?.value) || 0) *
      (countByTier['Build to Rent'] || 0);
  return (
    <Table dataHcName={`${dataHcName}-cost-table`}>
      <TableHeader>
        <TableHeaderCell>Tier</TableHeaderCell>
        <TableHeaderCell>Qty</TableHeaderCell>
        <TableHeaderCell>Cost</TableHeaderCell>
      </TableHeader>
      {countByTier['Free'] && (
        <TableRow>
          <TableCell>Free</TableCell>
          <TableCell>{formatNumber(countByTier['Free'])}</TableCell>
          <TableCell>{formatMoney(0, { precision: 2 })}</TableCell>
        </TableRow>
      )}
      {countByTier['Basic'] && (
        <TableRow>
          <TableCell>Basic</TableCell>
          <TableCell>{formatNumber(countByTier['Basic'])}</TableCell>
          <TableCell>
            {formatMoney(
              Number(tierConfig?.costs['data-points-basic']?.value) *
                countByTier['Basic'],
              { precision: 2 },
            )}
          </TableCell>
        </TableRow>
      )}
      {countByTier['Premium'] && (
        <TableRow>
          <TableCell>Premium</TableCell>
          <TableCell>{formatNumber(countByTier['Premium'])}</TableCell>
          <TableCell>
            {formatMoney(
              Number(tierConfig?.costs['data-points-premium']?.value) *
                countByTier['Premium'],
              { precision: 2 },
            )}
          </TableCell>
        </TableRow>
      )}
      {countByTier['Premium-Plus'] && (
        <TableRow>
          <TableCell>Premium-Plus</TableCell>
          <TableCell>{formatNumber(countByTier['Premium-Plus'])}</TableCell>
          <TableCell>
            {formatMoney(
              Number(tierConfig?.costs['data-points-premium-plus']?.value) *
                countByTier['Premium-Plus'],
              { precision: 2 },
            )}
          </TableCell>
        </TableRow>
      )}
      {countByTier['Property Estimate'] && (
        <TableRow>
          <TableCell>Property Estimate</TableCell>
          <TableCell>
            {formatNumber(countByTier['Property Estimate'])}
          </TableCell>
          <TableCell>
            {formatMoney(
              Number(tierConfig?.costs['property-estimate-api']?.value) *
                countByTier['Property Estimate'],
              { precision: 2 },
            )}
          </TableCell>
        </TableRow>
      )}
      {countByTier['Build to Rent'] && (
        <TableRow>
          <TableCell>Build to Rent</TableCell>
          <TableCell>{formatNumber(countByTier['Build to Rent'])}</TableCell>
          <TableCell>
            {formatMoney(
              Number(tierConfig?.costs['build-to-api']?.value) *
                countByTier['Build to Rent'],
              { precision: 2 },
            )}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell className={styles.TotalCost} colSpan={2}>
          Estimated Total{' '}
          <InfoTooltip
            dataHcName={`${dataHcName}-cost-tooltip`}
            description="Estimated total cost of the selected data points per address in the analysis. You will only be charged for datapoints that are returned successfully"
          />
        </TableCell>

        <TableCell className={styles.TotalCost}>
          {formatMoney(totalCost, { precision: 2 })}{' '}
          <sub style={{ position: 'relative', top: '-3px' }}>per Address</sub>
        </TableCell>
      </TableRow>
    </Table>
  );
};

interface DexpAnalysisPriceConfirmationDialogProps
  extends Pick<DexpShoppingCartCardProps, 'onClickCta'> {
  active: boolean;
  ctaLabel: ReactNode;
  onClose: VoidFunction;
}

export const DexpAnalysisPriceConfirmationDialog = ({
  active,
  onClose,
  onClickCta,
  ctaLabel,
}: DexpAnalysisPriceConfirmationDialogProps) => {
  const {
    state: { endpointsInShoppingCart },
  } = useDexpEndpointsTable();
  return (
    <Dialog
      dataHcName={`${dataHcName}-dialog`}
      active={active}
      title={`Estimated Price per Address in Analysis`}
      onClose={onClose}
      actions={[
        {
          label: 'Cancel',
          onClick: onClose,
          dataHcName: `${dataHcName}-dialog-cancel`,
          secondary: true,
        },
        {
          label: ctaLabel,
          dataHcEventType: MeaningfulEventTypes.Goal,
          dataHcEventName: 'dexp-analysis-price-confirm',
          onClick: () => {
            onClickCta(endpointsInShoppingCart);
          },
          dataHcName: `${dataHcName}-dialog-submit`,
        },
      ]}
    >
      <DexpAnalysisPriceEstimate />
    </Dialog>
  );
};
