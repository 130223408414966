import { DateStr } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { ForecastChartSchema } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useForecastChartBlockDocument } from './useForecastChartBlockDocument';
import { useForecastChartZipDocument } from './useForecastChartZipDocument';

export interface ForecastStatsItem {
  year: number;
  date: DateStr;
  price: number;
  hpi: number;
  percentDiff: number;
}

interface Data {
  zip: ForecastStatsItem[] | null;
  block: ForecastStatsItem[] | null;
}

const getNextForecast = (chart: ForecastChartSchema['chart'], year: number) => {
  if (!chart?.data) return undefined;
  const { data, currentMonthIndex } = chart;
  const forecast = data[12 * year + currentMonthIndex];
  if (!forecast && data[12 * year + currentMonthIndex - 1]) {
    // For final year, use the previous month if populated
    return data[12 * year + currentMonthIndex - 1];
  }
  return forecast;
};

const getForecastStats = (
  chart: ForecastChartSchema['chart'],
): ForecastStatsItem[] => {
  const results: ForecastStatsItem[] = [];
  let y = 1;
  let forecast = getNextForecast(chart, y);
  while (forecast) {
    results.push({
      date: forecast.x,
      year: Number(forecast.x.split('-')[0]),
      price: forecast.y,
      hpi: forecast.metaData.hpi,
      percentDiff: forecast.metaData.percentDiff,
    });
    y++;
    forecast = getNextForecast(chart, y);
  }
  return results;
};

export const useForecastStats = (reportId: ReportId) => {
  const forecastZipQuery = useForecastChartZipDocument(reportId);
  const forecastBlockQuery = useForecastChartBlockDocument(reportId);
  const blockChart = forecastBlockQuery.data?.data?.chart;
  const zipChart = forecastZipQuery.data?.data?.chart;

  const data: Data | undefined =
    forecastZipQuery.isFetched && forecastBlockQuery.isFetched
      ? {
          zip: zipChart ? getForecastStats(zipChart) : null,
          block: blockChart ? getForecastStats(blockChart) : null,
        }
      : undefined;
  return {
    ...combineUseQueryResult([forecastBlockQuery, forecastZipQuery]),
    data,
  };
};
