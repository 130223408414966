import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import AdvancedApi from './advanced-api.svg?react';
import AvmReportsIncluded from './avm-reports-included.svg?react';
import CoreApi from './core-api.svg?react';
import CustomQuestionnaires from './custom-questionnaires.svg?react';
import CustomerSupport from './customer-support.svg?react';
import IncludedUsers from './included-users.svg?react';
import NationwideBrokerage from './nationwide-brokerage.svg?react';
import ProductGrid from './product-grid.svg?react';
import Properties from './properties.svg?react';
import SalesforceSync from './salesforce-sync.svg?react';
import ScalableDataset from './scalable-dataset.svg?react';
import Soc2 from './soc2.svg?react';
import SolutionsEngineer from './solutions-engineer.svg?react';
import Sso from './sso.svg?react';
import ValuationReportsIncluded from './valuation-reports-included.svg?react';

export const AdvancedApiIcon = (props: IconProps) =>
  useIcon(AdvancedApi, props);
export const AvmReportsIncludedIcon = (props: IconProps) =>
  useIcon(AvmReportsIncluded, props);
export const CoreApiIcon = (props: IconProps) => useIcon(CoreApi, props);
export const CustomQuestionnairesIcon = (props: IconProps) =>
  useIcon(CustomQuestionnaires, props);
export const CustomerSupportIcon = (props: IconProps) =>
  useIcon(CustomerSupport, props);
export const IncludedUsersIcon = (props: IconProps) =>
  useIcon(IncludedUsers, props);
export const NationwideBrokerageIcon = (props: IconProps) =>
  useIcon(NationwideBrokerage, props);
export const ProductGridIcon = (props: IconProps) =>
  useIcon(ProductGrid, props);
export const PropertiesIcon = (props: IconProps) => useIcon(Properties, props);
export const SalesforceSyncIcon = (props: IconProps) =>
  useIcon(SalesforceSync, props);
export const ScalableDatasetIcon = (props: IconProps) =>
  useIcon(ScalableDataset, props);
export const Soc2Icon = (props: IconProps) => useIcon(Soc2, props);
export const SolutionsEngineerIcon = (props: IconProps) =>
  useIcon(SolutionsEngineer, props);
export const SsoIcon = (props: IconProps) => useIcon(Sso, props);
export const ValuationReportsIncludedIcon = (props: IconProps) =>
  useIcon(ValuationReportsIncluded, props);
