import { ReactNode } from 'react';

import { GlobalHeader } from '@hcs/auth';
import { useAccount, useLogout } from '@hcs/authn';
import { AuthenticatedCheck } from '@hcs/authz';
import { LoadingSpinner, TextButton } from '@hcs/design-system';
import { useOrgIsSelfServe, useOrgSelfServeInfo } from '@hcs/self-serve-info';
import { ConfirmUser } from '@hcs/user';
import { combineUseQueryResult } from '@hcs/utils';

import { useSelfServiceCheckoutSessionParam } from '../../hooks';
import { ManageStripeAccount } from '../ManageStripeAccount';
import { PlanChangedOrCancelledWarning } from '../PlanChangedOrCancelledWarning';

import styles from './SelfServeStatus.module.css';
interface SelfServeStatusProps {
  skeleton?: ReactNode;
  header: ReactNode;
  children: ReactNode;
  // User completed the sign up flow but never completed the stripe checkout
  checkoutAbandonded: ReactNode;
  // User has been redirected back to the platform from stripe checkout
  checkoutInProgress: ReactNode;
  // User is not logged in
  error401: ReactNode;
  onConfirmUserSuccess: VoidFunction;
  navigateToChangePlan: VoidFunction;
}

const dataHcName = 'self-serve-status';
export const SelfServeStatus = ({
  children,
  skeleton,
  header,
  error401,
  checkoutAbandonded,
  navigateToChangePlan,
  onConfirmUserSuccess,
  checkoutInProgress,
}: SelfServeStatusProps) => {
  const { mutate: logoutUser } = useLogout();
  const orgInfoQuery = useOrgSelfServeInfo();
  const accountQuery = useAccount();
  const combinedQuery = combineUseQueryResult([orgInfoQuery, accountQuery]);
  const { sessionParam } = useSelfServiceCheckoutSessionParam();
  const { data: isSelfServe } = useOrgIsSelfServe();
  const { data: selfServiceOrgInfo } = orgInfoQuery;
  const { data: account } = accountQuery;
  let renderedContent = children;
  if (combinedQuery.isInitialLoading) {
    renderedContent = skeleton || (
      <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
    );
  } else if (isSelfServe && selfServiceOrgInfo?.checkout_completed === false) {
    if (sessionParam) {
      renderedContent = checkoutInProgress;
    } else {
      renderedContent = checkoutAbandonded;
    }
  } else if (
    isSelfServe &&
    (selfServiceOrgInfo?.self_serve_status === 'payment-failure' ||
      selfServiceOrgInfo?.self_serve_status === 'missing-payment-method')
  ) {
    renderedContent = (
      <>
        <GlobalHeader
          authenticatedActionPopovers={
            <TextButton onClick={() => logoutUser()} dataHcName="logout-button">
              Logout
            </TextButton>
          }
        />
        <div className={styles.PaymentFailure}>
          <PlanChangedOrCancelledWarning
            onClickSelectPlan={navigateToChangePlan}
          />
          <ManageStripeAccount onClickChangePlan={navigateToChangePlan} />
        </div>
      </>
    );
  } else if (account?.user && !account.user.confirmed) {
    renderedContent = (
      <ConfirmUser onClickSuccess={onConfirmUserSuccess} header={header} />
    );
  }
  return (
    <AuthenticatedCheck skeleton={skeleton} error401={error401}>
      {renderedContent}
    </AuthenticatedCheck>
  );
};
