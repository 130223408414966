import React, { CSSProperties, ReactNode, useState } from 'react';
import classNames from 'classnames';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { IconButton } from '../../../controls-and-inputs/buttons/IconButton';

import styles from './Carousel.module.css';

export interface CarouselProps {
  /**
   * Required automation and engagement HTML attribute.
   */
  dataHcName: string;
  /**
   * Optional className applied to main HTML element.
   */
  className?: string;
  height?: CSSProperties['height'];
  slides: ReactNode[];
  initialIndex?: number;
  theme?: Partial<{
    ContentCell: string;
  }>;
}
export const Carousel = ({
  dataHcName,
  height,
  className,
  slides,
  theme,
  initialIndex,
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex || 0);
  const activeSlide = slides[activeIndex];
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Carousel, className)}
      style={height !== undefined ? { height } : undefined}
    >
      <div className={styles.ControlCell}>
        <IconButton
          dataHcName={`${dataHcName}-button-prev`}
          className={styles.CarouselArrow}
          icon={
            <DirectionalChevron
              className={styles.ChevronIcon}
              direction="left"
              onClick={() => {
                setActiveIndex(
                  !activeIndex ? slides.length - 1 : activeIndex - 1,
                );
              }}
            />
          }
        />
      </div>
      <div className={classNames(styles.ContentCell, theme?.ContentCell)}>
        {activeSlide}
      </div>
      <div className={styles.ControlCell}>
        <IconButton
          dataHcName={`${dataHcName}-button-next`}
          className={styles.CarouselArrow}
          icon={
            <DirectionalChevron
              className={styles.ChevronIcon}
              direction="right"
              onClick={() => {
                setActiveIndex(
                  (activeIndex || 0) >= slides.length - 1
                    ? 0
                    : (activeIndex || 0) + 1,
                );
              }}
            />
          }
        />
      </div>
    </div>
  );
};
