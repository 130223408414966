import React from 'react';

import { RadioOption, RadioSelect } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CompsFiltersPaths } from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';

import { useCompsFiltersDocument } from '../../../hooks/useCompsFiltersDocument';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';
import { useSubjectDocument } from '../../../hooks/useSubjectDocument';

import { CompFilterChip } from './CompFilterChip';

import styles from '../CompFilters.module.css';

const OPTIONS: RadioOption<null | boolean>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const FIELD = PropertyStateFields.pool;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = PROPERTY_STATE_FIELD_CONFIGS[FIELD];
const dataHcName = 'comp-filter-pool';
export const COMP_FILTER_POOL: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const active = !!filterValue;
    const label = active
      ? filterValue.absoluteValue
        ? 'Has Pool'
        : 'No Pool'
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType, className }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }

    const filterValue = filterDocument.data.filters?.[FIELD];
    const subjectValue = getPropertyStateFieldValue(FIELD, {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectDocument.data.propertyState,
    });

    return (
      <RadioSelect
        blockOptions
        dataHcName={`${dataHcName}-select`}
        value={
          filterValue?.absoluteValue == null ? null : filterValue.absoluteValue
        }
        disabled={!reportPermissions.isEditable}
        options={OPTIONS.map((o) =>
          o.value !== null && o.value === subjectValue
            ? {
                ...o,
                label: (
                  <>
                    {o.label}
                    <div
                      className={styles.SubjectPropertyMatch}
                      data-hc-name={`${dataHcName}-subject-match`}
                    >
                      Subject property match
                    </div>
                  </>
                ),
              }
            : o,
        )}
        className={className}
        onChange={(value) => {
          if (value === null) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    );
  },
};
