import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { IS_SENTRY_ENABLED } from '@hcs/webapps';

import { useAccount } from '../../hooks/useAccount';

export const SentryUser = () => {
  const { data: account } = useAccount();

  useEffect(() => {
    if (IS_SENTRY_ENABLED) {
      try {
        if (account) {
          Sentry.setUser({
            id: account.user.id,
            email: account.user.email,
            orgSlug: account.organization.slug,
          });
        } else {
          Sentry.setUser(null);
        }
      } catch {
        console.warn('Unable to set sentry user');
      }
    }
  }, [account]);

  return null;
};
