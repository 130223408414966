import React from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

interface RedirectWithSearchParamsProps {
  path: string;
}

export const RedirectWithSearchParams = ({
  path,
}: RedirectWithSearchParamsProps) => {
  const [searchParams] = useSearchParams();
  const searchParamsAsString = searchParams.toString();
  return (
    <Navigate
      to={{
        pathname: path,
        search:
          // search requires a leading "?"
          searchParamsAsString !== '' ? `?${searchParamsAsString}` : undefined,
      }}
      replace
    />
  );
};
