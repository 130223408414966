import { logException } from '@hcs/utils';

export const hookUserError = (hookName: string) => {
  const msg = `${hookName} hook called without userId`;
  logException(msg);
  return new Error(msg);
};

export const hookEnabledError = (hookName: string, variableName: string) => {
  const msg = `${hookName} hook called without ${variableName} controlling enabled option`;
  logException(msg);
  return new Error(msg);
};
