import React, { MouseEventHandler } from 'react';

import { useCheckAgileProductZuoraSubscription } from '@hcs/auth';
import { Anchor } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AgileProductGroupings } from '@hcs/types';

import { AGILE_SUITE_CONFIG_AGILE_INSPECTION } from '../../constants';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

const dataHcName = 'agile-inspection-link';
export const AgileInspectionProductLink = () => {
  const { data: hasAccess } = useCheckAgileProductZuoraSubscription(
    AgileProductGroupings.AgileInspection,
  );
  const { active, handleOpen, handleClose } = useActiveState();
  const handleClick: MouseEventHandler = (e) => {
    if (!hasAccess) {
      e.stopPropagation();
      return handleOpen();
    }
  };
  return (
    <>
      <div>
        <Anchor onClick={handleClick} dataHcName={dataHcName}>
          Interior and Exterior Inspections
        </Anchor>{' '}
        also available
      </div>
      <ProductPreviewDialog
        appConfigExtended={{
          hasAccess,
          appConfig: AGILE_SUITE_CONFIG_AGILE_INSPECTION,
        }}
        active={active}
        onClose={handleClose}
      />
    </>
  );
};
