import { useMemo } from 'react';

import { getPropertyStateFieldValue } from '@hcs/property-state';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { CombinedUseQueryResult } from '@hcs/types';
import { ComparableValue, ReportId, ValuationMethod } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

// TODO: Phase III - Remove this hook. This is temporary functionality for supporting the
// current UX during Phase II. For Phase III we will update the comp adjustment
// experience and will remove the concept of Comp Average in favor of a single comparable value
import { useCompDocuments, useSubjectValueDocument } from '.';

export const useLegacyCompValues = (
  reportId: ReportId,
  compType: CompTypes,
): CombinedUseQueryResult<
  | undefined
  | {
      isCompAvgSelected: boolean;
      isCompValueSelected: boolean;
      comparableAverage: ComparableValue | undefined;
      comparableValue: ComparableValue | undefined;
    }
> => {
  const subjectValueQuery = useSubjectValueDocument(reportId, {
    showRentalValue: compType === CompTypes.Rental,
  });
  const compsQuery = useCompDocuments(reportId, compType);
  const combinedQuery = combineUseQueryResult([subjectValueQuery, compsQuery]);
  const selectedValueMethod = subjectValueQuery.data?.data.selectedValue;
  const docComparableValue = subjectValueQuery.data?.data.comparableValue;
  const isDocCompValue =
    docComparableValue?.hcAdjustments.hcAdjustmentDate &&
    docComparableValue?.hcAdjustments.hcAdjustmentPropertyDetails;
  const isCompAvgSelected =
    selectedValueMethod === ValuationMethod.Comps && !isDocCompValue;
  const isCompValueSelected =
    selectedValueMethod === ValuationMethod.Comps && !!isDocCompValue;
  const calculatedValue: ComparableValue = useMemo(() => {
    let totalValue = 0;
    let nullValueComps = 0;
    const calculatedValue: ComparableValue = {
      value: {
        valueLower: null,
        valueUpper: null,
        value: null,
        valueMedian: null,
        condition: null,
        quality: null,
        fsd: null,
        fsd90Percent: null,
      },
      calculatedFields: {
        avmConfidence: null,
        valueLowerPerSqFt: null,
        valuePerSqFt: null,
        valueUpperPerSqFt: null,
      },
      hcAdjustments: {
        hcAdjustmentDate: false,
        hcAdjustmentPropertyDetails: false,
      },
    };

    compsQuery.data?.forEach((compDocument) => {
      const marketPrice = getPropertyStateFieldValue(
        compType === CompTypes.Rental
          ? PropertyStateFields.currentPriceRental
          : PropertyStateFields.currentPrice,
        {
          propertyStateType: PropertyStateType.Core,
          propertyState: compDocument.data.propertyState,
        },
      );
      const compDateAdjustment = isDocCompValue
        ? 0
        : compDocument.data.hcAdjustments.hcAdjustmentDate || 0;
      const compDetailsAdjustment = isDocCompValue
        ? 0
        : compDocument.data.hcAdjustments.hcAdjustmentPropertyDetails || 0;
      const compUserAdjustment =
        compDocument.data.userAdjustments?.totalDollarAdjustment || 0;
      const compValue =
        (marketPrice || 0) +
        compDateAdjustment +
        compDetailsAdjustment +
        compUserAdjustment;

      if (compValue) {
        totalValue += compValue;
        if (
          calculatedValue.value.valueLower == null ||
          calculatedValue.value.valueLower > compValue
        ) {
          calculatedValue.value.valueLower = compValue;
        }
        if (
          calculatedValue.value.valueUpper == null ||
          calculatedValue.value.valueUpper < compValue
        ) {
          calculatedValue.value.valueUpper = compValue;
        }
      } else {
        nullValueComps++;
      }
    });
    calculatedValue.value.value = Math.floor(
      totalValue / Math.max((compsQuery.data?.length || 0) - nullValueComps, 1),
    );
    return calculatedValue;
  }, [
    subjectValueQuery.data,
    compsQuery.data,
    isDocCompValue,
    isCompAvgSelected,
    isCompValueSelected,
  ]);

  return {
    ...combinedQuery,
    data: !combinedQuery.isInitialLoading
      ? {
          isCompAvgSelected,
          isCompValueSelected,
          comparableAverage: isDocCompValue
            ? calculatedValue
            : docComparableValue,
          comparableValue: isDocCompValue
            ? docComparableValue
            : calculatedValue,
        }
      : undefined,
  };
};
