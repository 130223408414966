import { useQuery } from '@tanstack/react-query';

import { AuthApi } from '@hcs/auth';
import { useToastSlice } from '@hcs/toast';

const QUERY_KEY_CHECK_INVITATION_CODE = 'check-invitation-code';
export const useCheckInvitationCode = (invitationCode?: string | null) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useQuery(
    [QUERY_KEY_CHECK_INVITATION_CODE, invitationCode],
    async () => {
      if (invitationCode) {
        return await AuthApi.checkInvitationCode(invitationCode);
      } else {
        // Should never get here because of enabled option below
        throw new Error('[Check Invitation Code] No Code to Check');
      }
    },
    {
      enabled: !!invitationCode,
      onError: () => {
        toastOpen({
          type: 'error',
          title: 'Invalid Invitation Code',
        });
      },
    },
  );
};
