import React from 'react';

import { Toggle } from '@hcs/design-system';
import { HcsDevToolsPortal } from '@hcs/dev-tools';

import { useHcsConsole } from '../../hooks/useHcsConsole';

const dataHcName = 'hcs-console-dev-tool';
export const HcsConsoleDevTool = () => {
  const { hcsConsole, hcsConsoleState } = useHcsConsole();
  return (
    <HcsDevToolsPortal
      groups={[
        {
          dataHcName,
          title: 'HCS Console',
          items: [
            {
              type: 'item',
              item: {
                largeContent: true,
                dataHcName: 'hcs-console',
                content: (
                  <Toggle
                    dataHcName={`${dataHcName}-hcsConsole-toggle`}
                    value={
                      hcsConsoleState.enabledVerbose
                        ? 'enableVerbose'
                        : hcsConsoleState.enabled
                          ? 'enable'
                          : 'disable'
                    }
                    options={[
                      { label: 'Off', value: 'disable' },
                      { label: 'On', value: 'enable' },
                      { label: 'Verbose', value: 'enableVerbose' },
                    ]}
                    onChange={(v) => {
                      hcsConsole?.[v]();
                    }}
                  />
                ),
              },
            },
          ],
        },
      ]}
    />
  );
};
