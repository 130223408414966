import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { UserInterfaceIcon } from '../../../../svgs';
import { IconButtonProps } from '../../../controls-and-inputs';

import styles from './SlideInPane.module.css';

export interface SlideInPaneProps {
  /* Whether the pane is displayed to the user */
  active: boolean;
  /* Callback to call when the user seeks to close the pane */
  onClose: () => void;
  /* Callback to call when the user seeks to open the pane using the trigger button */
  onOpen?: () => void;
  /* The width of the pane when expanded */
  expandedWidth?: string;
  dataHcName?: string;
  /* The button component that will expand and collapse the pane */
  TriggerIconButton?: React.FC<IconButtonProps>;
  /* A JSX element that will be rendered in the top right corner of the pane when it's expanded,
   * to line up horizontally with the trigger button */
  topRightButton?: JSX.Element;
  children: React.ReactNode;
  /* Whether to render a spacer that will cause sibling content of the pane to shift as the pane displays.
   * When falsy, the pane will overlap page content and a background screen will cover the page */
  withSpacer?: boolean;
  theme?: {
    SlideInPane?: string;
    Pane?: string;
    Expanded?: string;
    Screen?: string;
    Spacer?: string;
    TriggerButton?: string;
  };
  className?: string;
}

const DEFAULT_EXPANDED_WIDTH = '313px';
const INITIAL_X = -10;
const COLLAPSED_WIDTH = 0;

/**
 * A pane that slides in from the left side of the screen. Its opened/closed state is managed externally
 * via `active` and is aided by onOpen and onClose callbacks that allow it to be opened/closed from click
 * actions on elements inside this component (i.e. the trigger button and screen overlay).
 */
export const SlideInPane = (props: SlideInPaneProps) => {
  const {
    active,
    onClose,
    onOpen,
    expandedWidth = DEFAULT_EXPANDED_WIDTH,
    TriggerIconButton,
    topRightButton,
    dataHcName,
    withSpacer,
    children,
    theme,
    className,
  } = props;

  const hasActionsContainer = !!(TriggerIconButton || topRightButton);

  return (
    <AnimatePresence>
      <div
        className={classNames(
          styles.SlideInPane,
          className,
          theme?.SlideInPane,
          {
            [styles.Expanded]: active,
            [styles.WithSpacer]: withSpacer,
            [styles.WithActionsContainer]: hasActionsContainer,
            ...(theme?.Expanded && { [theme?.Expanded]: active }),
          },
        )}
      >
        {hasActionsContainer && (
          <div
            key="actions"
            className={styles.ActionsContainer}
            style={{ width: active ? expandedWidth : 'initial' }}
          >
            {TriggerIconButton && (
              <TriggerIconButton
                className={classNames(
                  styles.TriggerButton,
                  theme?.TriggerButton,
                )}
                dataHcName={`${dataHcName}-ui-button`}
                onClick={() => (active ? onClose() : onOpen?.())}
                icon={<UserInterfaceIcon size="lg" />}
              />
            )}
            {active && topRightButton}
          </div>
        )}
        <motion.div
          key="pane"
          initial={{ x: INITIAL_X }}
          animate={{
            x: active ? 0 : INITIAL_X,
            width: active ? expandedWidth : COLLAPSED_WIDTH,
            transition: {
              duration: 0.2,
              type: 'easeInOut',
            },
          }}
          className={styles.Pane}
          data-hc-name={dataHcName}
        >
          {active && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.2 },
              }}
              className={styles.ScrollableContainer}
            >
              {children}
            </motion.div>
          )}
        </motion.div>
        {/* Only render the screen when the pane is overlapping page content (i.e. no spacer) */}
        {active && !withSpacer && (
          <div
            className={classNames(styles.Screen, theme?.Screen)}
            onClick={onClose}
          />
        )}
      </div>
      {withSpacer && (
        <motion.div
          key="spacer"
          initial={{ x: INITIAL_X }}
          animate={{
            x: active ? 0 : INITIAL_X,
            width: active ? expandedWidth : COLLAPSED_WIDTH,
            transition: {
              type: 'easeInOut',
            },
          }}
          className={classNames(styles.Spacer, theme?.Spacer)}
        ></motion.div>
      )}
    </AnimatePresence>
  );
};
