export const HCS_DEV_TOOLS_QUERY_SELECTOR = '#hcs-dev-tools-portal';
export const HCS_DEV_TOOLS_ID = HCS_DEV_TOOLS_QUERY_SELECTOR.replace('#', '');
export const MESSAGES = [
  'Hello there!',
  'Can I interest you in some devtools?',
  'I see you!',
  'Ouch! You poked my eye!',
  'Did you file a ticket for that?',
  'Back again I see...',
  'You must be truly desperate to come to me for help.',
  'I better get a biscuit for helping with this!',
  "Who's a good developer? That's right YOU are!",
  'Give me a tummy rub or close the devtools.',
  'You found me!',
  'Much devtools. Such wow!',
  'Did someone say treats?',
];
