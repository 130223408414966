import React from 'react';
import Pluralize from 'pluralize';

import { SkeletonLoader } from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { ReportId } from '@hcs/types';
import { formatMissing, formatPropertyType, formatSqFt } from '@hcs/utils';

import { useSubjectDocument } from '../../hooks';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'subject-main-property-detailsText';
export const SubjectMainPropertyDetailsText = ({
  reportId,
  className,
}: Props) => {
  const { isInitialLoading, data: subjectDocument } =
    useSubjectDocument(reportId);

  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument?.data.propertyState,
  };
  return (
    <div className={className}>
      <SkeletonLoader
        dataHcName={`${dataHcName}-street-address-skeleton`}
        isLoading={isInitialLoading}
        type="text"
      >
        <span data-hc-name={`${dataHcName}-property-type`}>
          {formatPropertyType(
            getPropertyStateFieldValue(
              PropertyStateFields.propertyType,
              propertyStateArgs,
            ),
          )}
        </span>
        <span data-hc-name={`${dataHcName}-beds`}>
          {formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bedrooms,
              propertyStateArgs,
            ),
          )}{' '}
          {Pluralize(
            'Bed',
            getPropertyStateFieldValue(
              PropertyStateFields.bedrooms,
              propertyStateArgs,
            ) || 0,
          )}
        </span>
        <span data-hc-name={`${dataHcName}-baths`}>
          {formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.bathrooms,
              propertyStateArgs,
            ),
          )}{' '}
          {Pluralize(
            'Bath',
            getPropertyStateFieldValue(
              PropertyStateFields.bathrooms,
              propertyStateArgs,
            ) || 0,
          )}
        </span>
        <span data-hc-name={`${dataHcName}-sqft`}>
          {formatSqFt(
            getPropertyStateFieldValue(
              PropertyStateFields.livingArea,
              propertyStateArgs,
            ),
          )}
        </span>
        <span data-hc-name={`${dataHcName}-year-built`}>
          Built{' '}
          {formatMissing(
            getPropertyStateFieldValue(
              PropertyStateFields.yearBuilt,
              propertyStateArgs,
            ),
          )}
        </span>
      </SkeletonLoader>
    </div>
  );
};
