import { CerberusStatsFields } from '@hcs/types';

const caclculateInactive = (
  defaultOrder: CerberusStatsFields[],
  defaultActive: CerberusStatsFields[],
): CerberusStatsFields[] => {
  return defaultOrder.reduce<CerberusStatsFields[]>((accum, field) => {
    if (defaultActive.indexOf(field) === -1) {
      accum.push(field);
    }
    return accum;
  }, []);
};

export const CERBERUS_STATS_ALL_FIELDS: CerberusStatsFields[] = [
  CerberusStatsFields.daysOnMarketMedian,
  CerberusStatsFields.daysOnMarketMedianRental,
  CerberusStatsFields.daysOnMarketMedianMovingAvg,
  CerberusStatsFields.daysOnMarketMedianMovingAvgRental,
  CerberusStatsFields.daysOnMarketNotContractMedian,
  CerberusStatsFields.daysOnMarketNotContractMedianRental,
  CerberusStatsFields.daysOnMarketNotContractMedianMovingAvg,
  CerberusStatsFields.daysOnMarketNotContractMedianMovingAvgRental,
  CerberusStatsFields.daysToCloseMedian,
  CerberusStatsFields.daysToCloseMedianRental,
  CerberusStatsFields.daysToCloseMedianMovingAvg,
  CerberusStatsFields.daysToCloseMedianMovingAvgRental,
  CerberusStatsFields.daysToContractMedian,
  CerberusStatsFields.daysToContractMedianRental,
  CerberusStatsFields.daysToContractMedianMovingAvg,
  CerberusStatsFields.daysToContractMedianMovingAvgRental,
  CerberusStatsFields.daysToFirstPriceDropMedian,
  CerberusStatsFields.daysToFirstPriceDropMedianRental,
  CerberusStatsFields.daysToFirstPriceDropMedianMovingAvg,
  CerberusStatsFields.daysToFirstPriceDropMedianMovingAvgRental,
  CerberusStatsFields.listingsClosedCount,
  CerberusStatsFields.listingsClosedCountRental,
  CerberusStatsFields.listingsClosedCountMovingAvg,
  CerberusStatsFields.listingsClosedCountMovingAvgRental,
  CerberusStatsFields.listingsNewCount,
  CerberusStatsFields.listingsNewCountRental,
  CerberusStatsFields.listingsNewCountMovingAvg,
  CerberusStatsFields.listingsNewCountMovingAvgRental,
  CerberusStatsFields.listingsOnMarketCount,
  CerberusStatsFields.listingsOnMarketCountRental,
  CerberusStatsFields.listingsOnMarketCountMovingAvg,
  CerberusStatsFields.listingsOnMarketCountMovingAvgRental,
  CerberusStatsFields.listingsPriceChangeCount,
  CerberusStatsFields.listingsPriceChangeCountRental,
  CerberusStatsFields.listingsPriceChangeCountMovingAvg,
  CerberusStatsFields.listingsPriceChangeCountMovingAvgRental,
  CerberusStatsFields.listingsPriceDropCount,
  CerberusStatsFields.listingsPriceDropCountRental,
  CerberusStatsFields.listingsPriceDropCountMovingAvg,
  CerberusStatsFields.listingsPriceDropCountMovingAvgRental,
  CerberusStatsFields.listingsPriceDropPercent,
  CerberusStatsFields.listingsPriceDropPercentRental,
  CerberusStatsFields.listingsPriceDropPercentMovingAvg,
  CerberusStatsFields.listingsPriceDropPercentMovingAvgRental,
  CerberusStatsFields.listingsRemovedCount,
  CerberusStatsFields.listingsRemovedCountRental,
  CerberusStatsFields.listingsRemovedCountMovingAvg,
  CerberusStatsFields.listingsRemovedCountMovingAvgRental,
  CerberusStatsFields.listingsRemovedPercent,
  CerberusStatsFields.listingsRemovedPercentRental,
  CerberusStatsFields.listingsRemovedPercentMovingAvg,
  CerberusStatsFields.listingsRemovedPercentMovingAvgRental,
  CerberusStatsFields.listingsUnderContractCount,
  CerberusStatsFields.listingsUnderContractCountRental,
  CerberusStatsFields.listingsUnderContractCountMovingAvg,
  CerberusStatsFields.listingsUnderContractCountMovingAvgRental,
  CerberusStatsFields.listingsUnderContractPercent,
  CerberusStatsFields.listingsUnderContractPercentRental,
  CerberusStatsFields.listingsUnderContractPercentMovingAvg,
  CerberusStatsFields.listingsUnderContractPercentMovingAvgRental,
  CerberusStatsFields.marketGrade,
  CerberusStatsFields.monthsOfSupplyMedian,
  CerberusStatsFields.monthsOfSupplyMedianRental,
  CerberusStatsFields.monthsOfSupplyMedianMovingAvg,
  CerberusStatsFields.monthsOfSupplyMedianMovingAvgRental,
  CerberusStatsFields.netPopulationGrowth,
  CerberusStatsFields.priceClosedMedian,
  CerberusStatsFields.priceClosedMedianRental,
  CerberusStatsFields.priceClosedMedianMovingAvg,
  CerberusStatsFields.priceClosedMedianMovingAvgRental,
  CerberusStatsFields.priceNewListMedian,
  CerberusStatsFields.priceNewListMedianRental,
  CerberusStatsFields.priceNewListMedianMovingAvg,
  CerberusStatsFields.priceNewListMedianMovingAvgRental,
  CerberusStatsFields.priceOnMarketMedian,
  CerberusStatsFields.priceOnMarketMedianRental,
  CerberusStatsFields.priceOnMarketMedianMovingAvg,
  CerberusStatsFields.priceOnMarketMedianMovingAvgRental,
  CerberusStatsFields.priceOnMarketMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftClosedMedian,
  CerberusStatsFields.pricePerSqftClosedMedianRental,
  CerberusStatsFields.pricePerSqftClosedMedianMovingAvg,
  CerberusStatsFields.pricePerSqftClosedMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftNewListMedian,
  CerberusStatsFields.pricePerSqftNewListMedianRental,
  CerberusStatsFields.pricePerSqftNewListMedianMovingAvg,
  CerberusStatsFields.pricePerSqftNewListMedianMovingAvgRental,
  CerberusStatsFields.pricePerSqftOnMarketMedian,
  CerberusStatsFields.pricePerSqftOnMarketMedianRental,
  CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvg,
  CerberusStatsFields.pricePerSqftOnMarketMedianMovingAvgRental,
  CerberusStatsFields.return1YearForecast,
  CerberusStatsFields.risk1YearLoss,
  CerberusStatsFields.rpiReturn1Year,
  CerberusStatsFields.saleToListPriceMedian,
  CerberusStatsFields.saleToListPriceMedianRental,
  CerberusStatsFields.saleToListPriceMedianMovingAvg,
  CerberusStatsFields.saleToListPriceMedianMovingAvgRental,
  CerberusStatsFields.saleToListPriceOriginalMedian,
  CerberusStatsFields.saleToListPriceOriginalMedianRental,
  CerberusStatsFields.saleToListPriceOriginalMedianMovingAvg,
  CerberusStatsFields.saleToListPriceOriginalMedianMovingAvgRental,
];

// fields not available at the MSA level
const NOT_AVAILABLE_IN_MSA_FIELDS = [CerberusStatsFields.marketGrade];

// it's easier to list the active fields and just calculate the inactive ones from the total
const CHARTS_DEFAULT_ACTIVE: CerberusStatsFields[] = [
  CerberusStatsFields.listingsOnMarketCount,
  CerberusStatsFields.saleToListPriceOriginalMedian,
  CerberusStatsFields.daysOnMarketMedian,
  CerberusStatsFields.priceOnMarketMedian,
  CerberusStatsFields.monthsOfSupplyMedian,
];

const NO_CHARTS_FIELDS = [
  CerberusStatsFields.netPopulationGrowth,
  CerberusStatsFields.marketGrade,
  CerberusStatsFields.risk1YearLoss,
  CerberusStatsFields.return1YearForecast,
  CerberusStatsFields.rpiReturn1Year,
];

const notAvailableInMsaFilterFn = (field: CerberusStatsFields) =>
  NOT_AVAILABLE_IN_MSA_FIELDS.indexOf(field) === -1;

const ALL_CHARTS_ORDER: CerberusStatsFields[] = [
  ...CHARTS_DEFAULT_ACTIVE,
  // add the rest of fields that aren't in default active list or other fields we don't want for the charts
  ...CERBERUS_STATS_ALL_FIELDS.filter(
    (field) =>
      CHARTS_DEFAULT_ACTIVE.indexOf(field) === -1 &&
      NO_CHARTS_FIELDS.indexOf(field) === -1,
  ),
];

export const MARKET_STATE_MSA_ALL_CHARTS_ORDER: CerberusStatsFields[] = [
  ...ALL_CHARTS_ORDER.filter(notAvailableInMsaFilterFn),
];

export const MARKET_STATE_MSA_CHARTS_DEFAULT_INACTIVE: CerberusStatsFields[] =
  caclculateInactive(
    MARKET_STATE_MSA_ALL_CHARTS_ORDER,
    CHARTS_DEFAULT_ACTIVE.filter(notAvailableInMsaFilterFn),
  );

export const MARKET_STATE_ZIP_ALL_CHARTS_ORDER: CerberusStatsFields[] = [
  ...ALL_CHARTS_ORDER,
];

export const MARKET_STATE_ZIP_CHARTS_DEFAULT_INACTIVE: CerberusStatsFields[] =
  caclculateInactive(MARKET_STATE_ZIP_ALL_CHARTS_ORDER, CHARTS_DEFAULT_ACTIVE);

const COLUMNS_DEFAULT_ACTIVE: CerberusStatsFields[] = [
  CerberusStatsFields.return1YearForecast,
  CerberusStatsFields.grossYieldMedian,
  CerberusStatsFields.saleToListPriceOriginalMedian,
  CerberusStatsFields.monthsOfSupplyMedian,
  CerberusStatsFields.listingsOnMarketCount,
];

const ALL_COLUMNS_ORDER: CerberusStatsFields[] = [
  ...COLUMNS_DEFAULT_ACTIVE,
  // add the rest of fields that aren't in default active list
  ...CERBERUS_STATS_ALL_FIELDS.filter(
    (field) => COLUMNS_DEFAULT_ACTIVE.indexOf(field) === -1,
  ),
];

export const MARKET_STATE_MSA_ALL_COLUMNS_ORDER: CerberusStatsFields[] = [
  ...ALL_COLUMNS_ORDER.filter(notAvailableInMsaFilterFn),
];

export const MARKET_STATE_MSA_COLUMNS_DEFAULT_INACTIVE: CerberusStatsFields[] =
  caclculateInactive(
    MARKET_STATE_MSA_ALL_COLUMNS_ORDER,
    COLUMNS_DEFAULT_ACTIVE.filter(notAvailableInMsaFilterFn),
  );

export const MARKET_STATE_ZIP_ALL_COLUMNS_ORDER: CerberusStatsFields[] = [
  ...ALL_COLUMNS_ORDER,
];

export const MARKET_STATE_ZIP_COLUMNS_DEFAULT_INACTIVE: CerberusStatsFields[] =
  caclculateInactive(
    MARKET_STATE_ZIP_ALL_COLUMNS_ORDER,
    COLUMNS_DEFAULT_ACTIVE,
  );

export const CERBERUS_STATS_NON_FILTER_FIELDS: CerberusStatsFields[] = [
  CerberusStatsFields.displayName,
  CerberusStatsFields.hpiLatestMonth,
  CerberusStatsFields.listingStatsEventDate,
  CerberusStatsFields.listingStatsEventDateRental,
  CerberusStatsFields.populationLatestMonth,
  CerberusStatsFields.rpiLatestMonth,
];
