import { useEffect, useState } from 'react';

import {
  usePatchUiPreferencesForUser,
  useUiPreferencesForUser,
} from '@hcs/huell';

import { VECTILES_METRICS_GROUP_CONFIG } from '../constants';
import {
  HcMapChangeVectileGroupArgs,
  HcMapChangeVectileMetricArgs,
  HcMapMountArgs,
  HcMapShowMonochromeArgs,
} from '../rtk';

import { useHcMapSlice } from './useHcMapSlice';

export const useHcMap = (mapId: string) => {
  const hcMapSlice = useHcMapSlice();
  const actions = hcMapSlice.actions;
  const mapState = hcMapSlice.state[mapId];
  const { isInitialLoading: isLoadingPrefs, data: uiPreferences } =
    useUiPreferencesForUser();
  const { mutate: patchPreferences } = usePatchUiPreferencesForUser();
  const [queuedMount, setQueuedMount] = useState<HcMapMountArgs | undefined>();
  // Override hcMapMount to wait for preferences
  const handleMountWithPreferences = ({ mapId, mapState }: HcMapMountArgs) => {
    setQueuedMount(undefined);
    if (mapState.uiPreferencesKey) {
      const mapPreferences = uiPreferences?.[mapState.uiPreferencesKey];
      return hcMapSlice.actions.hcMapMount({
        mapId,
        mapState: {
          ...mapState,
          heatmap: {
            ...mapState.heatmap,
            ...mapPreferences?.heatmap,
          },
        },
      });
    }
    return;
  };

  useEffect(() => {
    if (queuedMount) {
      handleMountWithPreferences(queuedMount);
    }
  }, [queuedMount, isLoadingPrefs, uiPreferences]);

  // Override actions that save to preferences
  const hcMapMount = (payload: HcMapMountArgs) => {
    const { mapState } = payload;
    if (mapState.uiPreferencesKey) {
      if (isLoadingPrefs && !queuedMount) {
        // Queue mount
        return setQueuedMount(payload);
      } else {
        return handleMountWithPreferences(payload);
      }
    }
    return actions.hcMapMount(payload);
  };

  const hcMapLayersShowMonochrome = (payload: HcMapShowMonochromeArgs) => {
    const { visible } = payload;
    if (mapState?.uiPreferencesKey) {
      patchPreferences([
        {
          op: 'add',
          path: `/${mapState.uiPreferencesKey}/heatmap/showMonochrome`,
          value: visible,
        },
      ]);
    }
    return actions.hcMapLayersShowMonochrome(payload);
  };

  const hcMapChangeVectilesMetricGroup = (
    payload: HcMapChangeVectileGroupArgs,
  ) => {
    const { vectilesMetricGroup } = payload;
    if (mapState?.uiPreferencesKey) {
      patchPreferences([
        {
          op: 'add',
          path: `/${mapState.uiPreferencesKey}/heatmap/vectilesMetricGroup`,
          value: vectilesMetricGroup,
        },
        {
          op: 'add',
          path: `/${mapState.uiPreferencesKey}/heatmap/vectilesMetricId`,
          value:
            vectilesMetricGroup &&
            VECTILES_METRICS_GROUP_CONFIG[vectilesMetricGroup]
              .vectilesMetricIds[0],
        },
      ]);
    }
    return actions.hcMapChangeVectilesMetricGroup(payload);
  };
  const hcMapChangeVectilesMetric = (payload: HcMapChangeVectileMetricArgs) => {
    const { vectilesMetricId } = payload;
    if (mapState?.uiPreferencesKey) {
      patchPreferences([
        {
          op: 'add',
          path: `/${mapState.uiPreferencesKey}/heatmap/vectilesMetricGroup`,
          value: mapState.heatmap.vectilesMetricGroup,
        },
        {
          op: 'add',
          path: `/${mapState.uiPreferencesKey}/heatmap/vectilesMetricId`,
          value: vectilesMetricId,
        },
      ]);
    }
    return actions.hcMapChangeVectilesMetric(payload);
  };

  return {
    mapState,
    actions: {
      ...actions,
      hcMapMount,
      hcMapLayersShowMonochrome,
      hcMapChangeVectilesMetricGroup,
      hcMapChangeVectilesMetric,
    },
  };
};
