import { useDispatch, useSelector } from 'react-redux';

import {
  EngagementTrackingMeaningfulEventsSliceState,
  EngagementTrackingPartialAppState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { ENGAGEMENT_TRACKING_REDUCER_KEY } from '../rtk/engagementTracking.reducer';
import { engagementTrackingMeaningfulEventsSlice } from '../rtk/meaningfulEvents.slice';

export const useEngagementTrackingMeaningfulEventsSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<
      EngagementTrackingPartialAppState,
      EngagementTrackingMeaningfulEventsSliceState
    >(
      (state) =>
        state[ENGAGEMENT_TRACKING_REDUCER_KEY][
          engagementTrackingMeaningfulEventsSlice.name
        ],
    ),
    actions: mapDispatchToSliceActions(
      dispatch,
      engagementTrackingMeaningfulEventsSlice,
    ),
  };
};
