import { useDispatch, useSelector } from 'react-redux';

import { HcMapPartialAppState, HcMapSliceState } from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { hcMapSlice } from '../rtk';
import { MAP_REDUCER_KEY } from '../rtk';

export const useHcMapSlice = () => {
  const dispatch = useDispatch();
  const state = useSelector<HcMapPartialAppState, HcMapSliceState>(
    (state) => state[MAP_REDUCER_KEY],
  );
  const actions = mapDispatchToSliceActions(dispatch, hcMapSlice);
  return {
    state,
    actions,
  };
};
