import {
  DocumentRoles,
  ReportId,
  SimilarPropertiesChartFilteredDocument,
} from '@hcs/types';

import { useSingleDocumentRole } from '.';

export const useSimilarPropertiesChartFilteredDocument = (
  reportId: ReportId,
) => {
  return useSingleDocumentRole<SimilarPropertiesChartFilteredDocument>(
    reportId,
    DocumentRoles.SimilarPropertiesChartFiltered,
  );
};
