import { PayloadAction } from '@reduxjs/toolkit';

import { CompCompareSliceState, CompIdentifier } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: CompCompareSliceState = {};
export const compCompareDialogSlice = createHcReduxSlice({
  name: 'compCompareDialog',
  initialState,
  reducers: {
    compCompareDialogOpen(state, action: PayloadAction<CompIdentifier>) {
      state.active = action.payload;
    },
    compCompareDialogClose(state) {
      state.active = undefined;
    },
  },
});
