import { useCapabilityCheck } from '@hcs/authz';
import { useOrgIsSsoOnly } from '@hcs/single-sign-on';
import { CapabilityKeys } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

export const useCanInviteUsers = (
  orgId: number | undefined,
  internal: boolean,
) => {
  const capabilityKeyInvites: CapabilityKeys = internal
    ? 'client-admin-edit'
    : 'invite-users';
  const capCheckQuery = useCapabilityCheck(capabilityKeyInvites);
  const ssoOnlyQuery = useOrgIsSsoOnly(orgId);
  const combinedInviteEnabledQuery = combineUseQueryResult([
    capCheckQuery,
    ssoOnlyQuery,
  ]);
  const { data: hasInviteCapability } = capCheckQuery;
  const { data: isSsoOnly } = ssoOnlyQuery;
  return {
    ...combinedInviteEnabledQuery,
    data: combinedInviteEnabledQuery.isFetched
      ? combinedInviteEnabledQuery.isFetched &&
        hasInviteCapability &&
        !isSsoOnly
      : undefined,
  };
};
