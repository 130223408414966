import React, { useState } from 'react';

import { DragAndDropUpload, UploadSuccessCtaConfig } from '@hcs/design-system';
import { Card } from '@hcs/design-system';

import samplePortfolioPropertiesFile from '../../assets/sample_portfolio_properties.csv';
import { useImportPortfolioAssets } from '../../hooks/useImportPortfolioAssets';
import { usePortfolioNotifications } from '../../hooks/usePortfolioNotifications';

import styles from './BulkUploadAddProperties.module.css';

interface Props {
  portfolioId: string;
  dataHcName?: string;
  onSuccess: VoidFunction;
}

const DEFAULT_DATA_HC_NAME = 'bulk-upload';

export const BulkUploadAddProperties = ({
  portfolioId,
  dataHcName = DEFAULT_DATA_HC_NAME,
  onSuccess,
}: Props) => {
  const { isAcknowledged, removeAcknowledgedNotification } =
    usePortfolioNotifications(portfolioId);

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const resetStates = () => {
    setFile(null);
    setError(null);
  };

  const handleOnTryAgainClick = () => {
    resetStates();
  };

  const importPortfolioAssetsMutation = useImportPortfolioAssets({
    onSuccess,
  });

  const bulkUploading = (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    importPortfolioAssetsMutation.mutate({
      portfolioId,
      formData,
    });
  };

  const uploadSuccessCtaConfig: UploadSuccessCtaConfig = {
    text: 'Add Properties',
    onClick: () => {
      if (isAcknowledged) {
        removeAcknowledgedNotification();
      }
      if (file) {
        bulkUploading(file);
      }
    },
  };

  const shouldDisplayBottomText = !!file && !error;

  return (
    <>
      <Card dataHcName={`${dataHcName}-card`} className={styles.BulkUploadCard}>
        <DragAndDropUpload
          className={styles.DragAndDropArea}
          dataHcName={`${dataHcName}`}
          extensions={['.csv', '.xlsx']}
          config={{
            onChange: setFile,
            multipleFiles: false,
          }}
          header={{
            title: 'Bulk Upload',
            sampleFile: {
              file: samplePortfolioPropertiesFile,
              name: 'sampleFile',
            },
          }}
          onClickTryAgain={handleOnTryAgainClick}
          showAllowedExtensions
          uploadSuccessCta={file && !error ? uploadSuccessCtaConfig : undefined}
          onErrorChange={setError}
          theme={{ UploadSuccessCtaButton: styles.UploadSuccessCtaButton }}
        />
      </Card>
      {shouldDisplayBottomText && (
        <div className={styles.BottomText}>
          Depending on how many properties have been uploaded, this process may
          take some time
        </div>
      )}
    </>
  );
};
