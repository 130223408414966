import React from 'react';
import classNames from 'classnames';

import { logWarning } from '@hcs/utils';

import { DotDashedIcon } from '../../../../svgs/icons/indicator';
import { DotIndicator, DotIndicatorProps } from '../DotIndicator';

import styles from './DotLabel.module.css';

export type DotLabelTheme = Partial<{
  Container: string;
  Dot: string;
  Label: string;
}>;

export interface DotLabelProps {
  dataHcName: string;
  label?: React.ReactNode;
  dotColor:
    | {
        colorType: Exclude<DotIndicatorProps['colorType'], 'custom'>;
      }
    | {
        colorType: Extract<DotIndicatorProps['colorType'], 'custom'>;
        color: string;
      };
  dotBorderColor?: string;
  theme?: DotLabelTheme;
  isDotDashed?: boolean;
  pulsate?: boolean;
}
export const DotLabel = (props: DotLabelProps) => {
  const {
    dataHcName,
    dotColor,
    dotBorderColor,
    label,
    theme,
    isDotDashed = false,
    pulsate = false,
  } = props;

  if (isDotDashed && dotBorderColor) {
    logWarning(
      'DotLabel: dotBorderColor not supported when isDotDashed is true',
    );
  }

  if (isDotDashed && pulsate) {
    logWarning('DotLabel: pulsate not supported when isDotDashed is true');
  }

  if (isDotDashed && dotColor.colorType !== 'custom') {
    logWarning(
      'DotLabel: only custom colorType is supported when isDotDashed is true',
    );
  }

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Container, theme?.Container)}
    >
      {isDotDashed ? (
        <DotDashedIcon
          dataHcName={`${dataHcName}-dot-dashed-indicator`}
          className={classNames(styles.Dot, theme?.Dot)}
          size="lg"
          style={{
            color: dotColor.colorType === 'custom' ? dotColor.color : undefined,
          }}
        />
      ) : (
        <DotIndicator
          style={{
            background:
              dotColor.colorType === 'custom' ? dotColor.color : undefined,
            border: dotBorderColor ? `1px solid ${dotBorderColor}` : undefined,
          }}
          dataHcName={`${dataHcName}-dot-indicator`}
          colorType={dotColor.colorType}
          className={classNames(styles.Dot, theme?.Dot)}
          pulsate={pulsate}
        />
      )}
      {label && (
        <span
          data-hc-name={`${dataHcName}-label`}
          className={classNames(styles.Label, theme?.Label)}
        >
          {label}
        </span>
      )}
    </div>
  );
};
