import { useMemo } from 'react';

import { GlossaryDefinition, GlossaryTermsProp } from '@hcs/types';

import { GLOSSARY } from '../constants';

export const useGlossaryDefinitions = (
  glossaryTerms: GlossaryTermsProp,
): GlossaryDefinition[] => {
  return useMemo(() => {
    const results: GlossaryDefinition[] = [];
    glossaryTerms.forEach((termProp) => {
      if (typeof termProp === 'string') {
        const glossaryConfig = GLOSSARY[termProp];
        // If the term prop is a string the config type is static
        // but we still need to check to make TS happy
        if (glossaryConfig.type === 'static') {
          results.push(glossaryConfig);
        }
      } else {
        const glossaryConfig = GLOSSARY[termProp.glossaryTerm];
        if (glossaryConfig.type === 'avm' && termProp.type === 'avm') {
          results.push({
            title: glossaryConfig.title,
            source: glossaryConfig.source,
            content: glossaryConfig.content(termProp.avm),
          });
        } else if (
          glossaryConfig.type === 'compValue' &&
          termProp.type === 'compValue'
        ) {
          results.push({
            title: glossaryConfig.title,
            source: glossaryConfig.source,
            content: glossaryConfig.content(termProp.compValueOptions),
          });
        } else if (glossaryConfig.type === 'static') {
          results.push(glossaryConfig);
        }
      }
    });

    return results;
  }, [glossaryTerms]);
};
