import { useSelector } from 'react-redux';

import { useMapDispatchToSliceActions } from '@hcs/hooks';
import {
  EngagementTrackingPartialAppState,
  EventQueueSliceState,
} from '@hcs/types';

import { ENGAGEMENT_TRACKING_REDUCER_KEY } from '../rtk/engagementTracking.reducer';
import { engagementTrackingEventQueueSlice } from '../rtk/eventQueue.slice';

export const useEventQueueSlice = () => {
  const actions = useMapDispatchToSliceActions(
    engagementTrackingEventQueueSlice,
  );
  return {
    state: useSelector<EngagementTrackingPartialAppState, EventQueueSliceState>(
      (state) =>
        state[ENGAGEMENT_TRACKING_REDUCER_KEY][
          engagementTrackingEventQueueSlice.name
        ],
    ),
    actions,
  };
};
