import React from 'react';
import classNames from 'classnames';

import { useAppAccessCheck } from '@hcs/authz';
import { LinkAnchor } from '@hcs/design-system';
import { APP_CONFIG_DEVELOPER_CENTER } from '@hcs/hc-products';

import styles from './DeveloperCenterBanner.module.css';

interface Props {
  className?: string;
}
const dataHcName = 'developer-center-banner';
export const DeveloperCenterBanner = ({ className }: Props) => {
  const { data: hasAccessToDeveloperCenter } =
    useAppAccessCheck('developer-center');
  if (!hasAccessToDeveloperCenter) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.DeveloperCenterBanner, className)}
    >
      <p>
        Looking for API access & documentation? Try our{' '}
        <LinkAnchor
          dataHcName={`${dataHcName}-link`}
          to={APP_CONFIG_DEVELOPER_CENTER.rootPath}
        >
          Developer Center
        </LinkAnchor>
      </p>
    </div>
  );
};
