import { differenceInMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { OtherUrlTrackingParams, UtmCodes } from '@hcs/types';

const SESSION_TIMEOUT_MINUTES = 30;
const LOCAL_STORAGE_SESSION_ID = 'hcSessionId';
const LOCAL_STORAGE_SESSION_TIME = 'hcSessionTime';
const LOCAL_STORAGE_UTM = 'hcUtm';
const LOCAL_STORAGE_OTHER_TRACKING_PARAMS = 'hcTrk';

export const setNewSessionId = () => {
  const sessionId = uuidv4();
  const sessionTime = new Date();
  localStorage.setItem(LOCAL_STORAGE_SESSION_ID, sessionId);
  localStorage.setItem(LOCAL_STORAGE_SESSION_TIME, sessionTime.toString());
  clearUtmFromLocalStorage();
  return sessionId;
};

export const getSessionId = () => {
  // SECURITY: localStorage is used safely to store a session id that carries no meaning outside of engagement events
  // the sessionId needs to persist across multiple tabs so localStorage is used instead of sessionStorage
  const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID);
  const sessionTimeRaw = localStorage.getItem(LOCAL_STORAGE_SESSION_TIME);
  if (
    !sessionId ||
    !sessionTimeRaw ||
    differenceInMinutes(new Date(), new Date(sessionTimeRaw)) >
      SESSION_TIMEOUT_MINUTES
  ) {
    return setNewSessionId();
  } else {
    // Update time
    localStorage.setItem(LOCAL_STORAGE_SESSION_TIME, new Date().toString());
    return sessionId;
  }
};

export const setUtmInLocalStorage = (
  utmCodes: UtmCodes,
  otherTrackingParams: OtherUrlTrackingParams,
) => {
  localStorage.setItem(LOCAL_STORAGE_UTM, JSON.stringify(utmCodes));
  localStorage.setItem(
    LOCAL_STORAGE_OTHER_TRACKING_PARAMS,
    JSON.stringify(otherTrackingParams),
  );
};

export const getUtmFromLocalStorage = () => {
  const utmCodeStr = localStorage.getItem(LOCAL_STORAGE_UTM);
  const otherTrackingParamsStr = localStorage.getItem(
    LOCAL_STORAGE_OTHER_TRACKING_PARAMS,
  );
  return {
    utmCodes: utmCodeStr ? (JSON.parse(utmCodeStr) as UtmCodes) : null,
    otherTrackingParams: otherTrackingParamsStr
      ? (JSON.parse(otherTrackingParamsStr) as OtherUrlTrackingParams)
      : null,
  };
};

export const clearUtmFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_UTM);
  localStorage.removeItem(LOCAL_STORAGE_OTHER_TRACKING_PARAMS);
};
