import { useMemo } from 'react';

import { getMapTileImage } from '@hcs/maps';
import { useStreetView } from '@hcs/street-view';
import {
  PropertyStateCerberusInput,
  PropertyStateImageExtended,
  PropertyStateImagesResult,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { usePropertyStateLocation } from '../hooks/usePropertyStateLocation';

import { usePropertyStateMedia } from './usePropertyStateMedia';

export const usePropertyStateImages = (
  propertyStateCerberusInput: PropertyStateCerberusInput,
  options: { includeStreetView?: boolean } = { includeStreetView: true },
) => {
  const { includeStreetView } = options;
  const locationQuery = usePropertyStateLocation(propertyStateCerberusInput);
  const mediaQuery = usePropertyStateMedia(propertyStateCerberusInput);
  const streetViewQuery = useStreetView(
    (includeStreetView &&
      locationQuery.isSuccess &&
      locationQuery.data?.location) ||
      undefined,
    {
      size:
        // Get large street view image if there aren't MLS images
        mediaQuery.isSuccess && mediaQuery.data?.media?.images?.length
          ? 'sm'
          : 'lg',
    },
  );
  const combinedQuery = combineUseQueryResult([
    locationQuery,
    mediaQuery,
    streetViewQuery,
  ]);

  const data = useMemo(() => {
    const allPhotos: PropertyStateImageExtended[] = [];
    mediaQuery.data?.media?.images?.forEach((image) => {
      if (image.url) {
        allPhotos.push({
          overlayUrl:
            mediaQuery.data?.mls?.regulations?.logoOverlay || undefined,
          ...image,
        });
      }
    });
    const frontPhoto = allPhotos?.[0];
    const streetView =
      includeStreetView && streetViewQuery.data
        ? {
            url: streetViewQuery.data.url,
          }
        : undefined;
    if (streetView) {
      allPhotos.push(streetView);
    }
    const previewPhoto = frontPhoto || streetView;
    const mapTile = getMapTileImage(locationQuery.data);
    const result: PropertyStateImagesResult | undefined =
      combinedQuery.isInitialLoading
        ? undefined
        : {
            previewPhoto,
            frontPhoto,
            streetView,
            mapTile: mapTile
              ? {
                  url: mapTile,
                }
              : undefined,
            listingPreviewPhoto: mediaQuery.data?.media?.images?.[1],
            listingPhotos: mediaQuery.data?.media?.images?.length
              ? [mediaQuery.data]
              : [],
            totalPhotos: allPhotos?.length || 0,
            allPhotos,
          };
    return result;
  }, [combinedQuery.isInitialLoading, mediaQuery.data, streetViewQuery.data]);
  return {
    ...combinedQuery,
    data,
  };
};
