import { useQuery } from '@tanstack/react-query';

import { AuthnApi } from '../api';

import { useAccount } from './useAccount';

export const QUERY_KEY_USER_HMAC_HASH = 'QUERY_KEY_USER_HMAC_HASH';
export const useUserHmacHash = () => {
  const { data: account, isSuccess: isLoggedIn } = useAccount();
  return useQuery(
    [QUERY_KEY_USER_HMAC_HASH],
    async () => {
      if (account) {
        return await AuthnApi.fetchUserHmacHash(account.user.id);
      }
    },
    {
      enabled: isLoggedIn,
    },
  );
};
