import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { ReportId } from '@hcs/types';
import { formatMoney } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useSimilarPropertiesChartDocument } from '../../hooks/useSimilarPropertiesChartDocument';
import { useSimilarPropertiesChartFilteredDocument } from '../../hooks/useSimilarPropertiesChartFilteredDocument';

import { SIMILAR_PROPERTIES_CHARTS_FEATURES } from './SimilarPropertiesCharts';

interface Props {
  reportId: ReportId;
  className?: string;
}

const dataHcName = 'similar-properties-chart-button-summary';

export const SIMILAR_PROPERTIES_CHART_BUTTON_SUMMARY_FEATURES =
  SIMILAR_PROPERTIES_CHARTS_FEATURES;
export const SimilarPropertiesChartButtonSummary = ({
  reportId,
  className,
}: Props) => {
  const valuationChartQuery = useSimilarPropertiesChartDocument(reportId);
  const valuationFilteredChartQuery =
    useSimilarPropertiesChartFilteredDocument(reportId);
  const { isInitialLoading, isError } = combineUseQueryResult([
    valuationChartQuery,
    valuationFilteredChartQuery,
  ]);

  const percentile =
    valuationChartQuery?.data?.data?.chart?.stats.similarPropertiesStats
      .salePricePercentile90;
  const hasData = percentile?.upper && percentile?.lower;

  if (isInitialLoading) {
    return (
      <Skeleton width="70%" type="text" dataHcName={`${dataHcName}-skeleton`} />
    );
  } else if (isError) {
    return <>An error fetching chart data occurred</>;
  } else if (!hasData) {
    return (
      <>There are not enough highly similar comps to generate this chart.</>
    );
  }
  return (
    <div className={className}>
      90% of comparable properties sold between{' '}
      <strong>{formatMoney(percentile?.lower)}</strong> and{' '}
      <strong>{formatMoney(percentile?.upper)}</strong>.
    </div>
  );
};
