import { useIsHelpChatEnabled, useLogIntercomEvent } from '@hcs/help-chat';
import {
  EngagementEvent,
  IntercomEventData,
  MeaningfulEventTypes,
} from '@hcs/types';

export const useTrackIntercomEvent = () => {
  const helpChatEnabled = useIsHelpChatEnabled();
  const logIntercomEvent = useLogIntercomEvent();
  const trackIntercomEvent = (engagementEvent: EngagementEvent) => {
    // only track goal events, intercom has a unique event type limit of 120 which we were exceeding by tracking all meaningful events
    if (
      helpChatEnabled &&
      engagementEvent.event_type === MeaningfulEventTypes.Goal &&
      engagementEvent.event_data
    ) {
      const {
        device,
        event_section,
        data_hc_name,
        original_event_type,
        property_report,
        requested_product,
        offer_now,
      } = engagementEvent.event_data;

      // note that intercom limits this data to 20 attributes
      const intercomEventData: IntercomEventData = {
        browser: device?.browser,
        browserVersion: device?.browser_version,
        os: device?.os,
        osVersion: device?.os_version,
        eventSection: event_section,
        eventType: engagementEvent.event_type,
        originalEventType: original_event_type,
        app: engagementEvent.app,
        pageGroup: engagementEvent.page_group,
        httpPath: engagementEvent.http_path,
        httpReferer: engagementEvent.http_referer,
        dataHcName: data_hc_name,
        propertyReportAddressId: property_report?.hc_address_id,
        propertyReportAddressSlug: property_report?.slug,
        propertyReportId: property_report?.report_id,
        offerNowAddressId: offer_now?.hc_address_id,
        offerNowAddressSlug: offer_now?.slug,
        requestedProduct: requested_product,
      };

      logIntercomEvent(engagementEvent.event_name, intercomEventData);
    }
  };
  return {
    mutate: trackIntercomEvent,
  };
};
