import { useCapabilityCheck } from '../../hooks';

import { CapabilityCheckProps } from './CapabilityCheckForUser';

export const CapabilityCheck = ({
  capabilityKey,
  children,
  error403,
  skeleton,
}: CapabilityCheckProps) => {
  const {
    isInitialLoading,
    isSuccess,
    data: hasCapability,
  } = useCapabilityCheck(capabilityKey);
  if (isInitialLoading) {
    return skeleton || null;
  }
  if (!hasCapability && isSuccess) {
    return error403 || null;
  }
  return children;
};
