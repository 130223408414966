import { useDispatch, useSelector } from 'react-redux';

import {
  KeywordSearchPartialAppState,
  KeywordSearchSliceState,
} from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { KEYWORD_SEARCH_REDUCER_KEY } from '../rtk';
import { keywordSearchSlice } from '../rtk/keywordSearch.slice';

export const useKeywordSearchSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<KeywordSearchPartialAppState, KeywordSearchSliceState>(
      (state) => state[KEYWORD_SEARCH_REDUCER_KEY],
    ),
    actions: mapDispatchToSliceActions(dispatch, keywordSearchSlice),
  };
};
