import { ReactNode, useCallback, useEffect, useState } from 'react';

import { LoadingPage, OverlayPage } from '@hcs/design-system';
import { useComponentDidMount } from '@hcs/hooks';

import styles from './StripeRedirect.module.css';

const dataHcName = 'stripe-redirect';

interface StripeRedirectProps {
  title?: ReactNode;
  subtitle: ReactNode;
  redirectUrl: string | null | undefined;
  minTimeShown?: number;
  onRequestUrl?: VoidFunction;
  supportCallout?: boolean;
  error?: {
    title?: ReactNode;
    subtitle: ReactNode;
    nextAction: ReactNode;
  };
}
export const StripeRedirect = ({
  title,
  subtitle,
  redirectUrl,
  supportCallout,
  minTimeShown = 3000,
  onRequestUrl,
  error,
}: StripeRedirectProps) => {
  const [timePageShown] = useState<number>(new Date().getTime());
  useComponentDidMount(() => onRequestUrl?.());
  const redirectToStripe = useCallback(() => {
    if (redirectUrl) {
      // @jnettleman - destination is a safe url from an internal api
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);
  // Listen to redirectUrl and redirect after minTimeShown
  useEffect(() => {
    if (!redirectUrl) return;
    const now = Date.now();
    if (now - (timePageShown || 0) >= minTimeShown) {
      redirectToStripe();
    }
    const redirectTimeout = setTimeout(
      redirectToStripe,
      Math.max(0, minTimeShown - (now - timePageShown)),
    );
    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, [redirectUrl, redirectToStripe, timePageShown, minTimeShown]);

  return (
    <OverlayPage
      active
      dataHcName={dataHcName}
      dataHcEventSection={'Stripe Redirect'}
    >
      <LoadingPage
        isLoading={!redirectUrl}
        title={title}
        subtitle={
          redirectUrl
            ? 'Click the link below if you are not redirected automatically:'
            : subtitle
        }
        supportCallout={supportCallout}
        error={error}
      >
        {redirectUrl && (
          <a
            className={styles.Link}
            data-hc-name={`${dataHcName}-redirect-link`}
            href={redirectUrl}
          >
            Continue to Payment Portal
          </a>
        )}
      </LoadingPage>
    </OverlayPage>
  );
};
