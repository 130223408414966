import { APP_CONFIG_AEXP } from './AppConfigAexp.constants';
import { APP_CONFIG_AGILE_SUITE } from './AppConfigAgileSuite.constants';
import { APP_CONFIG_BULK_HPI } from './AppConfigBulkHpi.constants';
import { APP_CONFIG_BULK_PROPERTY_DATA } from './AppConfigBulkPropertyData.constants';
import { APP_CONFIG_BULK_RPI } from './AppConfigBulkRpi.constants';
import { APP_CONFIG_CANARYAI } from './AppConfigCanaryAI.constants';
import { APP_CONFIG_DEVELOPER_CENTER } from './AppConfigDeveloperCenter.constants';
import { APP_CONFIG_DEXP } from './AppConfigDexp.constants';
import { APP_CONFIG_MARKET_INSIGHTS } from './AppConfigMarketInsights.constants';
import { APP_CONFIG_MARKET_PULSE } from './AppConfigMarketPulse.constants';
import { APP_CONFIG_ORG_ADMIN } from './AppConfigOrgAdmin.constants';
import { APP_CONFIG_PEXP } from './AppConfigPexp.constants';
import { APP_CONFIG_PLATFORM } from './AppConfigPlatform.constants';
import { APP_CONFIG_PORTFOLIO } from './AppConfigPortfolio.constants';
import { APP_CONFIG_USER_ADMIN } from './AppConfigUserAdmin.constants';

export * from './AppConfigAccessSolutionsPlatform.constants';
export * from './AppConfigAexp.constants';
export * from './AppConfigAgileSuite.constants';
export * from './AppConfigDeveloperCenter.constants';
export * from './AppConfigDexp.constants';
export * from './AppConfigMarketInsights.constants';
export * from './AppConfigOrgAdmin.constants';
export * from './AppConfigPexp.constants';
export * from './AppConfigPlatform.constants';
export * from './AppConfigPortfolio.constants';
export * from './AppConfigCanaryAI.constants';
export * from './AppConfigUserAdmin.constants';
export * from './AppConfigBulkPropertyData.constants';
export * from './AppConfigBulkHpi.constants';
export * from './AppConfigBulkRpi.constants';
export * from './AppConfigMarketPulse.constants';
export * from './products.constants';
export * from './agileSuiteBillingCodes.constants';

export const EXTERNAL_APP_CONFIGS = [
  APP_CONFIG_AEXP,
  APP_CONFIG_AGILE_SUITE,
  APP_CONFIG_BULK_HPI,
  APP_CONFIG_BULK_PROPERTY_DATA,
  APP_CONFIG_BULK_RPI,
  APP_CONFIG_DEVELOPER_CENTER,
  APP_CONFIG_DEXP,
  APP_CONFIG_MARKET_INSIGHTS,
  APP_CONFIG_MARKET_PULSE,
  APP_CONFIG_ORG_ADMIN,
  APP_CONFIG_PEXP,
  APP_CONFIG_PLATFORM,
  APP_CONFIG_PORTFOLIO,
  APP_CONFIG_CANARYAI,
  APP_CONFIG_USER_ADMIN,
];
