import React from 'react';

import { GlobalHeader, GlobalHeaderExtendableProps } from '@hcs/auth';
import { LegacySsOptInLauncher } from '@hcs/self-service';

import { SolutionsPlatformAppLauncher } from '../SolutionsPlatformAppLauncher';
import {
  SolutionsPlatformUserMenu,
  SolutionsPlatformUserMenuProps,
} from '../SolutionsPlatformUserMenu';

export interface SolutionsPlatformGlobalHeaderProps
  extends GlobalHeaderExtendableProps {
  userMenuProps?: SolutionsPlatformUserMenuProps;
}
export const SolutionsPlatformGlobalHeader = ({
  userMenuProps,
  children,
  ...props
}: SolutionsPlatformGlobalHeaderProps) => {
  return (
    <GlobalHeader
      {...props}
      appLauncher={<SolutionsPlatformAppLauncher />}
      authenticatedActionPopovers={
        <>
          <LegacySsOptInLauncher
            dataHcEventName="click-legacy-opt-in-global-header"
            buttonLabel="Upgrade now for full access"
          />
          {props.authenticatedActionPopovers}
          <SolutionsPlatformUserMenu {...userMenuProps} />
        </>
      }
    >
      {children}
    </GlobalHeader>
  );
};
