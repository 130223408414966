import { useMutation } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';

import { AccountApi } from '../api/AccountApiExternal.api';

export const useSendSsoLoginLinkEmail = () => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation(AccountApi.sendSsoLoginLinkEmail, {
    onMutate: ({ email }) => {
      toastOpen({
        type: 'loading',
        title: `Creating login link for ${email}...`,
        duration: null,
      });
    },
    onSuccess: (data) => {
      toastOpen({
        type: 'loading-success',
        title:
          data.message ||
          'An email with a login link has been sent if SSO is configured for your organization.',
      });
    },
    onError: (e, { email }) => {
      toastOpen({
        type: 'loading-failure',
        title: `Failed to create login link for ${email}`,
      });
    },
  });
};
