import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { StatusMessageProps } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { usePatchReportPreferencesForUser } from '@hcs/huell';
import { usePatchSavedCompFilterSetsForUser } from '@hcs/huell';
import { useReportPreferencesForUser } from '@hcs/huell';
import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import { getPrimaryFilterSetPreferencesKey } from '@hcs/huell';
import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import {
  MeaningfulEventTypes,
  SavedCompFilters,
  SavedCompFilterSet,
} from '@hcs/types';
import { CompFields, CompTypes, PropertyStateFields } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { getDatetimeString } from '@hcs/utils';

import { useAppliedSavedCompFilterSet } from '../../hooks';
import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';
import { convertAppliedFiltersToSavedFilterSetValues } from '../../utils';

import styles from './SavedCompFilterSetForm.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  onNotificationChange: (notifications: StatusMessageProps[]) => void;
  onSuccess?: (updatedFilterSets: SavedCompFilters) => void;
}
const MAX_FILTER_SETS = 10;
const dataHcName = 'saved-comp-filter-set-form';
export const dataHcEventSectionSaveFilterSetComps = 'Save Comp Filter Set';
export const dataHcEventSectionSaveFilterSetRental =
  'Save Rental Comp Filter Set';
export const SavedCompFilterSetForm = ({
  reportId,
  compType,
  onNotificationChange,
  onSuccess,
}: Props) => {
  const dataHcEventSection =
    compType === CompTypes.Rental
      ? dataHcEventSectionSaveFilterSetRental
      : dataHcEventSectionSaveFilterSetComps;
  // Queries
  const reportPreferencesQuery = useReportPreferencesForUser();
  const filterQuery = useCompsFiltersDocument(reportId, compType);
  const filterSetQuery = useSavedCompFilterSetsForUser(compType);
  const reachedMaxFilterSets =
    Object.keys(filterSetQuery.data || {}).length >= MAX_FILTER_SETS;
  // Local State
  const [newName, setNewName] = useState('');
  const [selectedFilterSetId, setSelectedFilterSetId] = useState<string | null>(
    Date.now().toString(),
  );
  const [notifications, setNotifications] = useState<StatusMessageProps[]>([]);
  useEffect(() => {
    onNotificationChange(notifications);
  }, [notifications]);

  const {
    data: { numSavableAppliedFilters, unsavableFilters },
  } = useAppliedSavedCompFilterSet(reportId, compType);
  const isFormDisabled = numSavableAppliedFilters < 1 || reachedMaxFilterSets;

  const { mutate: patchReportPreferencesForUser } =
    usePatchReportPreferencesForUser();
  const { mutate: patchSavedCompFilterSetsForUser } =
    usePatchSavedCompFilterSetsForUser({
      compType,
      options: {
        onSuccess: (updatedFilterSets) => {
          onSuccess?.(updatedFilterSets);

          patchReportPreferencesForUser?.([
            {
              op: 'add',
              path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
              value: selectedFilterSetId,
            },
          ]);

          setSelectedFilterSetId(Date.now().toString());
        },
      },
    });

  const getHumanReadableFilterStr = (
    filters: (PropertyStateFields | CompFields)[],
  ) => {
    const humanReadableFilters = filters.map((key) => {
      return {
        ...PROPERTY_STATE_FIELD_CONFIGS,
        ...COMP_FIELDS_CONFIG,
      }[key].label;
    });

    if (humanReadableFilters.length > 1) {
      const lastFilter = humanReadableFilters.pop();
      const newLastFilter =
        humanReadableFilters.length > 0 ? `or ${lastFilter}` : lastFilter;
      newLastFilter && humanReadableFilters.push(newLastFilter);
    }

    return humanReadableFilters.join(
      humanReadableFilters.length > 2 ? ', ' : ' ',
    );
  };

  const createNotification = (
    dataHcNameNotification: string,
    type: StatusMessageProps['type'],
    title: string,
  ) => {
    return {
      dataHcName: `${dataHcName}-${dataHcNameNotification}`,
      show: true,
      title,
      type,
    };
  };

  // Validate
  useEffect(() => {
    const currNotifications: StatusMessageProps[] = [];

    if (unsavableFilters.length > 0 && numSavableAppliedFilters > 0) {
      const humanReadableFilterStr =
        getHumanReadableFilterStr(unsavableFilters);
      const title = `You can not save ${humanReadableFilterStr} to this filter set because the subject property is missing these value(s).`;
      currNotifications.push(
        createNotification('unsavable-filter-warning', 'warning', title),
      );
    }

    if (unsavableFilters.length > 0 && numSavableAppliedFilters === 0) {
      const humanReadableFilterStr =
        getHumanReadableFilterStr(unsavableFilters);
      const title = `You can not save ${humanReadableFilterStr} to this filter set because the subject property is missing these value(s).`;
      currNotifications.push(
        createNotification('unsavable-filter-error', 'error', title),
      );
    }

    if (reachedMaxFilterSets) {
      const title = `Max filters allowed: ${MAX_FILTER_SETS}`;
      currNotifications.push(
        createNotification('max-filters-error', 'error', title),
      );
    }

    if (newName && filterSetQuery.data) {
      for (const filterSetId in filterSetQuery.data) {
        const filterSet = filterSetQuery.data[filterSetId];

        if (filterSet && filterSet.label === newName) {
          const title = 'Please give this filter set a unique name';
          currNotifications.push(
            createNotification('unique-name-error', 'error', title),
          );
        }
      }
    }
    setNotifications(currNotifications);
  }, [newName, filterSetQuery.data, reachedMaxFilterSets]);

  if (
    filterQuery.isInitialLoading ||
    filterSetQuery.isInitialLoading ||
    reportPreferencesQuery.isInitialLoading
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const handleSubmit = () => {
    // Mutations
    const appliedFilters = filterQuery.data?.data.filters;
    const updatedAt = getDatetimeString();
    if (appliedFilters && patchSavedCompFilterSetsForUser) {
      const filterSetToSave: SavedCompFilterSet = {
        label: newName,
        createdByReportId: reportId,
        updatedAt,
        values: convertAppliedFiltersToSavedFilterSetValues(appliedFilters),
        // Mutations
      };
      // Save New Filter Set
      patchSavedCompFilterSetsForUser([
        {
          op: 'add',
          path: `/${selectedFilterSetId}`,
          value: filterSetToSave,
        },
      ]);
    }
  };
  const disabled =
    isFormDisabled ||
    notifications.map((n) => n.type).includes('error') ||
    !selectedFilterSetId ||
    !newName;

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSection}
      className={styles.Content}
    >
      <div className={styles.Instructions}>
        Save these filters for future reports. All filters are saved relative
        (e.g. +/- 1 bed) to the subject.
      </div>
      <div
        className={classNames(styles.InputWrapper, {
          [styles.disabled]: isFormDisabled,
        })}
      >
        <Input
          dataHcName={`${dataHcName}-new-name`}
          value={newName}
          onChange={setNewName}
          noBorders
          disabled={isFormDisabled}
          placeholder="Name your filter"
          maxLength={25}
        />
      </div>
      <div className={styles.Submit}>
        <Button
          dataHcName={`${dataHcName}-submit`}
          dataHcEventName={
            disabled
              ? undefined
              : compType === CompTypes.Rental
                ? 'Created Rental Comp Filter Set'
                : 'Created Comp Filter Set'
          }
          dataHcEventType={!disabled ? MeaningfulEventTypes.Goal : undefined}
          disabled={disabled}
          label="Save"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
