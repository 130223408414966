import React from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { TextButton } from '@hcs/design-system';
import { FlexScrollCard } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LineClamp } from '@hcs/design-system';
import { LinkComponentType } from '@hcs/routing';
import { CerberusStatsFields, CerberusStatsType } from '@hcs/types';
import { logWarning } from '@hcs/utils';

import { CerberusStatsFilters } from '../../components/CerberusStatsFilters';
import { InterestedInTableCheckbox } from '../../components/InterestedInTableCheckbox';
import { CERBERUS_STATS_FIELD_CONFIGS } from '../../configs';
import { useCerberusStatsFields } from '../../hooks/useCerberusStatsFields';
import { useCerberusStatsZipsTable } from '../../hooks/useCerberusStatsZipsTable';
import { MarketStateColumnsZipForUserForUserLauncher } from '../';

import styles from './CerberusStatsTables.module.css';

const displayNameConfig =
  CERBERUS_STATS_FIELD_CONFIGS[CerberusStatsFields.displayName];

// 62px is the actual height of the header with 3 lines. It's important this is accurate to actual height for the msa sticky row
const ROW_HEIGHT = 62;
const STAT_CELL_WIDTH = 150;
const NAME_WIDTH_LOOKUP: Record<string, number> = {
  icon: 70,
  name: 250,
  button: 100,
};

const dataHcName = 'zip-filter-table';

interface CerberusStatsTableProps {
  className?: string;
  msaId: string;
  MsaLink: LinkComponentType<{ msaId: string }>;
  ZipLink: LinkComponentType<{ msaId: string; zipcode: string }>;
}
export const CerberusStatsZipsTable = ({
  className,
  msaId,
  ZipLink,
  MsaLink,
}: CerberusStatsTableProps) => {
  const {
    state: {
      isLoading,
      filteredSortedList,
      totalCount,
      interestedInZipMap,
      interestedInMsaMap,
      interestedOnly,
      cerberusStatsMsa,
      search,
      sortField,
      sortOrder,
      fieldFilters,
      filterSetMutationIsLoading,
    },
    actions: {
      onSort,
      onSearch,
      onUpdateFieldFilters,
      onClearFieldFilter,
      onToggleZipInterested,
      onToggleMsaInterested,
      onToggleInterestedOnly,
      onUpdateFilterableFields,
    },
  } = useCerberusStatsZipsTable(msaId);
  const { data: customFieldsData } = useCerberusStatsFields('columnsZip');

  return (
    <FlexScrollCard
      className={classNames(styles.CardContainer, styles.viewHeight, className)}
      dataHcName={dataHcName}
      header={{
        height: 110,
        content: (
          <div className={styles.FlexScrollCardHeader}>
            <CerberusStatsFilters
              dataHcName={`cerberus-stats-msa-filters`}
              searchPlaceholder="ZIPs Search"
              search={search}
              fieldFilters={fieldFilters}
              onSearch={onSearch}
              onClearFieldFilter={onClearFieldFilter}
              saveIsLoading={filterSetMutationIsLoading}
              filterSetQueryIsLoading={isLoading}
              onUpdateFieldFilters={onUpdateFieldFilters}
              marketLevel="zip"
            />
            <div className={styles.Count}>
              <span data-hc-name={`${dataHcName}-title`}>ZIPs</span>{' '}
              <span data-hc-name={`${dataHcName}-count`}>
                {filteredSortedList.length} of {totalCount}
              </span>
            </div>
          </div>
        ),
      }}
      noPadding
    >
      <Table
        skeletonConfig={{
          isLoading: isLoading,
          colCount: 8,
          rows: 10,
        }}
        dataHcName={`${dataHcName}-table`}
        belowRows={
          !isLoading &&
          filteredSortedList.length === 0 && (
            <div
              className={styles.NoResultsFound}
              data-hc-name={`${dataHcName}-no-results`}
            >
              No results found
            </div>
          )
        }
      >
        <TableHeader
          sticky
          height={ROW_HEIGHT}
          dataHcName={`${dataHcName}-table-header`}
        >
          <TableHeaderCell
            dataHcName={`${dataHcName}-interested-header-cell`}
            width={NAME_WIDTH_LOOKUP['icon']}
            sticky
          >
            <InterestedInTableCheckbox
              dataHcName={`${dataHcName}-interested-icon-header`}
              checked={!!interestedOnly}
              onChange={() => onToggleInterestedOnly()}
              uncheckedMessage="Only show interested"
              checkedMessage="Show all"
            />
          </TableHeaderCell>
          <displayNameConfig.HeaderCell
            className={styles.TableDisplayNameHeaderCell}
            cerberusStatsType={CerberusStatsType.Zip}
            dataHcName={`${dataHcName}-name-header-cell`}
            onClick={() => onSort(CerberusStatsFields.displayName)}
            sortable
            sort={
              sortField === CerberusStatsFields.displayName && sortOrder
                ? sortOrder
                : undefined
            }
            width={NAME_WIDTH_LOOKUP['name']}
            sticky
            afterSortContent={
              <MarketStateColumnsZipForUserForUserLauncher
                onUpdate={(dataPriorityData) => {
                  onUpdateFilterableFields(dataPriorityData.order);
                }}
                showTooltip={false}
                trigger={
                  <TextButton
                    dataHcName={`${dataHcName}-data-priority`}
                    noPadding
                  >
                    Choose Fields
                  </TextButton>
                }
              />
            }
          />
          <TableHeaderCell
            dataHcName={`${dataHcName}-view-button-header-cell`}
            align="right"
            sticky
            width={NAME_WIDTH_LOOKUP['button']}
          />
          {customFieldsData?.order.map((field) => {
            const config = CERBERUS_STATS_FIELD_CONFIGS[field];
            if (!config) {
              logWarning(
                `CerberusStatsZipsTable: config not found for field ${field}`,
              );
              return null;
            }
            return (
              <config.HeaderCell
                key={field}
                dataHcName={`${dataHcName}-${field}-header-cell`}
                sortable
                sort={sortField === field && sortOrder ? sortOrder : undefined}
                width={STAT_CELL_WIDTH}
                wordWrap
                onClick={() => onSort(field)}
              />
            );
          })}
        </TableHeader>
        <TableRow height={ROW_HEIGHT} highlighted sticky>
          <TableCell
            sticky
            width={NAME_WIDTH_LOOKUP['icon']}
            dataHcName={`${dataHcName}-msa-interested-cell`}
          >
            <InterestedInTableCheckbox
              dataHcName={`${dataHcName}-msa-interested`}
              whiteBg
              checked={!!interestedInMsaMap?.[msaId]}
              onChange={() => onToggleMsaInterested(msaId)}
              recordGoal
              marketLevel="msa"
            />
          </TableCell>
          <TableCell
            className={styles.TableMsaNameCell}
            dataHcName={`${dataHcName}-msa-name-cell`}
            sticky
            width={NAME_WIDTH_LOOKUP['name']}
          >
            <LineClamp key={'msa-name'} lines={1}>
              MSA:{' '}
              <displayNameConfig.Display cerberusStats={cerberusStatsMsa} />
            </LineClamp>
          </TableCell>

          <TableCell
            dataHcName={`${dataHcName}-msa-view-button-cell`}
            align="right"
            sticky
            width={NAME_WIDTH_LOOKUP['button']}
          >
            <MsaLink msaId={msaId} dataHcName={`${dataHcName}-msa-view-link`}>
              <Button
                dataHcName={`${dataHcName}-msa-view-button`}
                highlightYellow
              >
                View
              </Button>
            </MsaLink>
          </TableCell>
          {customFieldsData?.order.map((field) => {
            const config = CERBERUS_STATS_FIELD_CONFIGS[field];
            if (!config) {
              return null;
            }
            return (
              <config.ContentCell
                key={field}
                cerberusStats={cerberusStatsMsa}
                width={STAT_CELL_WIDTH}
                wordWrap
                dataHcName={`${dataHcName}-${field}-msa-cell`}
              />
            );
          })}
        </TableRow>
        {filteredSortedList &&
          filteredSortedList.map((listItem) => {
            const id = listItem.id;
            return (
              <TableRow
                style={{ background: 'none' }}
                key={id}
                height={ROW_HEIGHT}
              >
                <TableCell
                  sticky
                  width={NAME_WIDTH_LOOKUP['icon']}
                  dataHcName={`${dataHcName}-zip-interested-cell`}
                >
                  <InterestedInTableCheckbox
                    dataHcName={`${dataHcName}-zip-interested`}
                    checked={!!interestedInZipMap?.[id]}
                    onChange={() => onToggleZipInterested(id)}
                    recordGoal
                    marketLevel="zip"
                  />
                </TableCell>
                <displayNameConfig.ContentCell
                  sticky
                  width={NAME_WIDTH_LOOKUP['name']}
                  isLineClamp={true}
                  className={styles.TableMsaNameCell}
                  dataHcName={`${dataHcName}-zip-name-cell`}
                  cerberusStats={listItem}
                />
                <TableCell
                  sticky
                  width={NAME_WIDTH_LOOKUP['button']}
                  dataHcName={`${dataHcName}-zip-view-button-cell`}
                  align="right"
                >
                  <ZipLink
                    msaId={msaId}
                    zipcode={id}
                    dataHcName={`${dataHcName}-zip-view-link`}
                  >
                    <Button dataHcName={`${dataHcName}-zip-view-button`}>
                      View
                    </Button>
                  </ZipLink>
                </TableCell>
                {customFieldsData?.order.map((field) => {
                  const config = CERBERUS_STATS_FIELD_CONFIGS[field];
                  if (!config) {
                    return null;
                  }
                  return (
                    <config.ContentCell
                      key={field}
                      cerberusStats={listItem}
                      width={STAT_CELL_WIDTH}
                      wordWrap
                      dataHcName={`${dataHcName}-${field}-zip-cell`}
                    />
                  );
                })}
              </TableRow>
            );
          })}
      </Table>
    </FlexScrollCard>
  );
};
