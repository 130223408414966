import React, { useCallback, useEffect } from 'react';
import { Layer, Popup, Source, useMap } from 'react-map-gl';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import uniqBy from 'lodash/uniqBy';

import { useComponentDidMount, usePrevious } from '@hcs/hooks';
import {
  HcMapMapboxLayerIds,
  LngLatObject,
  VectilesMetricIds,
} from '@hcs/types';

import schoolIcon0 from '../../assets/school-pin-0.png';
import schoolIcon1 from '../../assets/school-pin-1.png';
import schoolIcon2 from '../../assets/school-pin-2.png';
import schoolIcon3 from '../../assets/school-pin-3.png';
import schoolIcon4 from '../../assets/school-pin-4.png';
import schoolIconUnranked from '../../assets/school-pin-unranked.png';
import { VECTILES_TILES_URLS } from '../../constants';
import { getSchoolDistrictFeatureColor } from '../../utils';

import { SchoolMapMarkerPopup } from './SchoolMapMarkerPopup';

type Props = {
  featureFilter?: (
    feature: Feature<Geometry, GeoJsonProperties>,
  ) => boolean | undefined | null;
  changeFilterTrigger: VectilesMetricIds | null;
};

const SCHOOL_ZOOM_MIN = 9;
const SCHOOL_ZOOM_MAX = 22;
const SCHOOLS_SYMBOLS_SOURCE = 'schools-symbol-source';
const SCHOOLS_SOURCE_LAYER_ID = 'schools_raw';

enum SchoolMarkerIds {
  I_0 = 'school-marker-image-0',
  I_1 = 'school-marker-image-1',
  I_2 = 'school-marker-image-2',
  I_3 = 'school-marker-image-3',
  I_4 = 'school-marker-image-4',
  I_UNRANKED = 'school-marker-image-unranked',
}

const MARKER_URLS: Record<SchoolMarkerIds, string> = {
  [SchoolMarkerIds.I_0]: schoolIcon0,
  [SchoolMarkerIds.I_1]: schoolIcon1,
  [SchoolMarkerIds.I_2]: schoolIcon2,
  [SchoolMarkerIds.I_3]: schoolIcon3,
  [SchoolMarkerIds.I_4]: schoolIcon4,
  [SchoolMarkerIds.I_UNRANKED]: schoolIconUnranked,
};

/* Typical location of Point feature coordinates in Gaia responses */
const getLatLng = (
  feature: Feature<Geometry, GeoJsonProperties>,
): LngLatObject | null => {
  return feature.properties
    ? { lat: feature.properties.lat, lng: feature.properties.lon }
    : null;
};

const uniqByDef = (feature: Feature<Geometry, GeoJsonProperties>) =>
  feature.properties
    ? `${feature.properties.uid}-${feature.properties.level}`
    : null;

const dataHcName = 'school-symbol-layer';

/**
 * Renders an icon for certain features in the data source determined by a `featureFilter`
 * prop. This allows rendering a 'symbol' type layer even if the data source contains no
 * Point geometry.  If the data source does contain Point geometry, you don't need this
 * component, and can simply use a Layer component, passing type=symbol
 *
 * This component creates an underlying transparent vector tile source and fill layer which
 * it parses data from to create a geoJSON source and visible symbol layer containing Point
 * features that pass the `featureFilter` prop
 */
export const SchoolLayer = ({ featureFilter, changeFilterTrigger }: Props) => {
  const { current: map } = useMap();
  const [geoJSONPointFeatures, setGeoJSONPointFeatures] = React.useState<
    Feature<Geometry, GeoJsonProperties>[] | null
  >(null);
  const prevGeoJSONPointFeatures = usePrevious(geoJSONPointFeatures);
  const geoJSONSourceId = `${HcMapMapboxLayerIds.SCHOOL_SYMBOL_LAYER}-geojson-source`;
  const geoJSONLayerId = `${HcMapMapboxLayerIds.SCHOOL_SYMBOL_LAYER}-geojson-layer`;
  const prevChangeFilterTrigger = usePrevious(changeFilterTrigger);
  const [activeSchoolFeature, setActiveSchoolFeature] = React.useState<Feature<
    Geometry,
    GeoJsonProperties
  > | null>(null);

  /**
   * Load all images needed for school symbols, then activate the schools symbol layer
   */
  const loadSchoolMarkerImages = () => {
    if (!map) {
      throw new Error(
        'Attempting to load school marker images into the map before the map is available',
      );
    }

    // Load the icon image
    Object.values(SchoolMarkerIds).forEach((schoolImageEnumValue) => {
      const currentImage = MARKER_URLS[schoolImageEnumValue];

      if (!map.hasImage(schoolImageEnumValue) && currentImage) {
        map.loadImage(currentImage, (error, image) => {
          if (error) {
            throw error;
          }
          // Map might be unmounted by the time the image loads
          if (map && image && !map.hasImage(schoolImageEnumValue)) {
            // Add the icon image to the map

            map.addImage(schoolImageEnumValue, image);
          }
        });
      }
    });
  };

  /**
   * Iterate features within the transparent vector layer, generating geoJSON Point
   * features from points matched by `featureFilter`
   * @return {array} geoJSON point features
   */
  const getGeoJSONPointFeatures = useCallback((): Feature<
    Geometry,
    GeoJsonProperties
  >[] => {
    const pointFeatures: Feature<Geometry, GeoJsonProperties>[] = [];
    const sourceFeatures =
      SCHOOLS_SYMBOLS_SOURCE && map
        ? map.querySourceFeatures(SCHOOLS_SYMBOLS_SOURCE, {
            sourceLayer: SCHOOLS_SOURCE_LAYER_ID,
          })
        : undefined;

    (uniqBy(sourceFeatures, uniqByDef) || sourceFeatures || []).forEach(
      (feature) => {
        if (!featureFilter || featureFilter(feature)) {
          const { lat, lng } = getLatLng(feature) || {};

          if (lat && lng) {
            /* Add Point geometry */
            pointFeatures.push({
              type: 'Feature',
              properties: feature.properties,
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
            });
          }
        }
      },
    );
    return pointFeatures;
  }, [featureFilter, map]);

  const loadData = useCallback(() => {
    if (
      map?.getLayer('main_layer') &&
      map?.getSource(SCHOOLS_SYMBOLS_SOURCE) &&
      map?.isSourceLoaded(SCHOOLS_SYMBOLS_SOURCE)
    ) {
      const features = getGeoJSONPointFeatures();
      setGeoJSONPointFeatures(features);
    }
    map?.off('idle', loadData);
  }, [getGeoJSONPointFeatures, map]);

  const handleMoveEnd = useCallback((): void => {
    if (!map) {
      throw new Error(
        'Map not available on move end.  Please ensure <SymbolsLayer> is a child of <Map>.',
      );
    }
    loadData();
  }, [loadData, map]);

  useComponentDidMount(() => {
    loadSchoolMarkerImages();
  });

  const handleClick = useCallback(
    (e: mapboxgl.MapLayerEventType['click'] & mapboxgl.EventData) => {
      const schoolFeature = e.features?.[0] as
        | Feature<Geometry, GeoJsonProperties>
        | undefined;
      if (schoolFeature) {
        setActiveSchoolFeature(schoolFeature);
      }
    },
    [],
  );

  const handleMouseout = useCallback(() => {
    setActiveSchoolFeature(null);
  }, []);

  useEffect(() => {
    map?.on('click', geoJSONLayerId, handleClick);
    map?.on('mouseenter', geoJSONLayerId, handleClick);
    map?.on('mouseout', geoJSONLayerId, handleMouseout);
    return () => {
      map?.off('click', geoJSONLayerId, handleClick);
      map?.off('mouseenter', geoJSONLayerId, handleClick);
      map?.off('mouseout', geoJSONLayerId, handleMouseout);
    };
  }, [handleClick, handleMouseout, geoJSONLayerId, map]);

  useEffect(() => {
    if (prevChangeFilterTrigger !== changeFilterTrigger) {
      loadData();
    }

    if (map) {
      if (!prevChangeFilterTrigger && changeFilterTrigger) {
        map.on('idle', loadData);
      }

      if (
        JSON.stringify(prevGeoJSONPointFeatures) !==
          JSON.stringify(geoJSONPointFeatures) &&
        geoJSONPointFeatures
      ) {
        map.off('idle', loadData);
      }

      map.on('moveend', handleMoveEnd);
    }
    return () => {
      if (map) {
        map.off('moveend', handleMoveEnd);
      }
    };
  }, [
    geoJSONPointFeatures,
    prevGeoJSONPointFeatures,
    prevChangeFilterTrigger,
    changeFilterTrigger,
    handleMoveEnd,
    loadData,
    map,
  ]);

  const schoolPopupPosition =
    activeSchoolFeature &&
    ([
      activeSchoolFeature?.properties?.lat,
      activeSchoolFeature?.properties?.lon,
    ] as [number, number]);

  const featureProperties = {
    name: activeSchoolFeature?.properties?.name,
    rank_unrounded: activeSchoolFeature?.properties?.rank_unrounded,
    uid: activeSchoolFeature?.properties?.uid,
  };

  return (
    <div data-hc-name={dataHcName}>
      <Source
        id={SCHOOLS_SYMBOLS_SOURCE}
        type={'vector'}
        tiles={[
          `${VECTILES_TILES_URLS}/${SCHOOLS_SOURCE_LAYER_ID}/{z}/{x}/{y}.mvt`,
        ]}
        minzoom={SCHOOL_ZOOM_MIN}
      >
        <Layer
          id={'main_layer'}
          type={'fill'}
          source={SCHOOLS_SYMBOLS_SOURCE}
          source-layer={SCHOOLS_SOURCE_LAYER_ID}
          paint={{
            /* Mapbox requires a paint definition for the layer data to be pulled */
            'fill-color': 'rgba(0,0,0,0)',
          }}
          maxzoom={SCHOOL_ZOOM_MAX}
          minzoom={SCHOOL_ZOOM_MIN}
        />
        {activeSchoolFeature && (
          <Layer
            id={HcMapMapboxLayerIds.SCHOOL_DISTRICT_BOUNDARY_LAYER}
            type="fill"
            source={SCHOOLS_SYMBOLS_SOURCE}
            source-layer={SCHOOLS_SOURCE_LAYER_ID}
            filter={[
              '==',
              ['get', 'uid'],
              activeSchoolFeature?.properties?.uid,
            ]}
            paint={{
              'fill-opacity': 0.5,
              'fill-color': getSchoolDistrictFeatureColor(
                activeSchoolFeature?.properties?.rank_unrounded,
              ),
            }}
            beforeId={'main_layer'}
          />
        )}
        {schoolPopupPosition && (
          <Popup
            key={
              activeSchoolFeature
                ? activeSchoolFeature?.properties?.uid
                : Math.random()
            }
            longitude={activeSchoolFeature?.properties?.lon}
            latitude={activeSchoolFeature?.properties?.lat}
            closeButton={false}
            style={{ zIndex: 10, pointerEvents: 'none' }}
          >
            <SchoolMapMarkerPopup featureProperties={featureProperties} />
          </Popup>
        )}
      </Source>

      <Source
        id={geoJSONSourceId}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: geoJSONPointFeatures || [],
        }}
        maxzoom={SCHOOL_ZOOM_MAX}
      >
        <Layer
          id={geoJSONLayerId}
          type={'symbol'}
          paint={{}}
          source={geoJSONSourceId}
          layout={{
            'icon-image': [
              'case',
              ['<', ['get', 'rank'], 3],
              SchoolMarkerIds.I_0,
              ['<', ['get', 'rank'], 5],
              SchoolMarkerIds.I_1,
              ['<', ['get', 'rank'], 7],
              SchoolMarkerIds.I_2,
              ['<', ['get', 'rank'], 9],
              SchoolMarkerIds.I_3,
              ['>', ['get', 'rank'], 8],
              SchoolMarkerIds.I_4,
              SchoolMarkerIds.I_UNRANKED,
            ],
            'icon-size': 0.5,
          }}
        />
      </Source>
    </div>
  );
};
