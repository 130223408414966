import { PayloadAction } from '@reduxjs/toolkit';

import { EngagementEventArg, EventQueueSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: EventQueueSliceState = {
  shouldQueueEvents: true,
  events: [],
};
export const engagementTrackingEventQueueSlice = createHcReduxSlice({
  name: 'eventQueue',
  initialState,
  reducers: {
    setShouldQueueEvents(state, shouldQueue: PayloadAction<boolean>) {
      state.shouldQueueEvents = shouldQueue.payload;
    },
    queueEvent(state, { payload }: PayloadAction<EngagementEventArg>) {
      state.events.push(payload);
    },
    clearQueuedEvents(state) {
      state.events = [];
    },
  },
});
