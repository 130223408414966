import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { SimilarityLevel } from '@hcs/property-state';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { CompFields } from '@hcs/types';
import {
  CompsFiltersPaths,
  SimilarityLevel as SimilarityLevelType,
} from '@hcs/types';
import {
  CompFilterChipTypeProps,
  CompFilterConfig,
  CompFilterControlTypeProps,
} from '@hcs/types';
import { capitalizeFirstLetter, trimWithEllipsis } from '@hcs/utils';

import { useCompsFiltersDocument } from '../../../hooks/useCompsFiltersDocument';
import { useDocumentPatch } from '../../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../../hooks/useReportPermissions';
import { useSubjectDocument } from '../../../hooks/useSubjectDocument';

import { CompFilterChip } from './CompFilterChip';

import styles from '../CompFilters.module.css';

const FIELD = CompFields.similarity;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const fieldConfig = COMP_FIELDS_CONFIG[FIELD];
const dataHcName = 'comp-filter-similarity';
const OPTIONS: Option<SimilarityLevelType>[] = [
  {
    value: SimilarityLevelType.High,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-high`}
          similarityLevel={SimilarityLevelType.High}
        />{' '}
        High (85 - 100)
      </>
    ),
  },
  {
    value: SimilarityLevelType.Moderate,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-moderate`}
          similarityLevel={SimilarityLevelType.Moderate}
        />{' '}
        Moderate (75 - 84)
      </>
    ),
  },
  {
    value: SimilarityLevelType.Low,
    label: (
      <>
        <SimilarityLevel
          dataHcName={`${dataHcName}-option-low`}
          similarityLevel={SimilarityLevelType.Low}
        />{' '}
        Low (0 - 74)
      </>
    ),
  },
];
export const COMP_FILTER_SIMILARITY: CompFilterConfig = {
  Chip: ({ reportId, compType, ...props }: CompFilterChipTypeProps) => {
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    if (!filterDocument) return <span />;
    const filterValue = filterDocument.data.filters?.[FIELD];
    const label = filterValue?.absoluteValue
      ? trimWithEllipsis(
          filterValue.absoluteValue.map(capitalizeFirstLetter).join(', '),
          30,
        )
      : fieldConfig.labelShort;
    return (
      <CompFilterChip
        {...props}
        reportId={reportId}
        compType={compType}
        compField={FIELD}
        label={label}
      />
    );
  },
  Control: ({ reportId, compType }: CompFilterControlTypeProps) => {
    const { data: reportPermissions } = useReportPermissions(reportId);
    const documentPatchMutation = useDocumentPatch(reportId);
    const { data: filterDocument } = useCompsFiltersDocument(
      reportId,
      compType,
    );
    const { data: subjectDocument } = useSubjectDocument(reportId);
    if (!filterDocument || !subjectDocument || !reportPermissions) {
      return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
    }
    const filterValue = filterDocument.data.filters?.[FIELD];
    return (
      <>
        <div
          data-hc-name={`${dataHcName}-similarity-description`}
          className={styles.SimilarityDescription}
        >{`HouseCanary's Similarity Score uses Data Points including bed, bath, GLA,
        and other property details along with distance and time to create a
        regression model for the subject property and nearby comparable
        properties. The location based model provides a numerical score for each
        possible comparable, ranked from 1-100 in similarity.`}</div>
        <MultiSelect
          dataHcName={`${dataHcName}-select`}
          value={filterValue?.absoluteValue || []}
          disabled={!reportPermissions.isEditable}
          options={OPTIONS}
          onChange={(value: SimilarityLevelType[]) => {
            if (value.length === 0) {
              documentPatchMutation.mutate({
                reportId,
                document: filterDocument,
                operations: [
                  {
                    op: 'remove',
                    path: PATH_FILTER,
                  },
                ],
              });
            } else {
              documentPatchMutation.mutate({
                reportId,
                document: filterDocument,
                operations: [
                  {
                    op: 'add',
                    path: PATH_ABS,
                    value,
                  },
                  {
                    op: 'add',
                    path: PATH_FIELD,
                    value: FIELD,
                  },
                ],
              });
            }
          }}
        />
      </>
    );
  },
};
