import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useCurrentAppConfig } from '@hcs/auth';
import {
  DATA_HC_NAME_PAGE_AUTH_SPINNER,
  useUserIsLegacySelfService,
} from '@hcs/authn';
import { LoadingSpinner } from '@hcs/design-system';
import { VIEW_PATHS_USER_ADMIN } from '@hcs/hc-products';

export const NonSelfServicePageRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data: isLegacySelfService, isInitialLoading } =
    useUserIsLegacySelfService();
  const { rootPath } = useCurrentAppConfig();
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={DATA_HC_NAME_PAGE_AUTH_SPINNER} />;
  } else if (!isLegacySelfService) {
    return (
      <Navigate
        to={`${rootPath}/${VIEW_PATHS_USER_ADMIN.USER_USAGE}`}
        replace
      />
    );
  } else {
    return children;
  }
};
