import React, { useEffect, useState } from 'react';

import { FormError, LoadingSpinner, UserAlert } from '@hcs/design-system';
import { logException } from '@hcs/utils';

import { useSelfServicePackage } from '../../hooks/useSelfServicePackage';
import {
  useSelfServiceSetupIntent,
  useSelfServiceSetupIntentWebhook,
} from '../../hooks/useSelfServiceSetupIntent';
import { StripeSetupIntent } from '../StripeCreditCard/StripeCreditCard';

import styles from './SelfServiceSetupCreditCard.module.css';

interface SelfServiceSetupCreditCardProps {
  className?: string;
  onCancel?: VoidFunction;
}
const dataHcName = 'self-service-setup-credit-card';
export const SelfServiceSetupCreditCard = ({
  className,
  onCancel,
}: SelfServiceSetupCreditCardProps) => {
  const [pollSetupWebhook, setPollSetupWebhook] = useState(false);
  const {
    data: selfServicePackage,
    isInitialLoading: selfServicePackageIsLoading,
    isError: selfServicePackageIsError,
  } = useSelfServicePackage();
  // this setup is called on load
  const {
    data: intentKeys,
    isSuccess: setupIntentIsSuccess,
    isInitialLoading: setupIntentIsLoading,
    isError: setupIntentIsError,
  } = useSelfServiceSetupIntent(selfServicePackage?.id);
  // this setup webhook is called 1 to n times after the user submits the credit card
  // it's called until the response isn't "shouldReflect"
  const {
    data: intentWebhookData,
    isError: intentWebhookIsError,
    isInitialLoading: intentWebhookIsLoading,
  } = useSelfServiceSetupIntentWebhook({
    clientSecret: intentKeys?.clientSecret,
    enabled: pollSetupWebhook,
  });

  useEffect(() => {
    if (intentWebhookData && intentWebhookData !== 'shouldRefetch') {
      setPollSetupWebhook(false);
    }
  }, [intentWebhookData]);

  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <div data-hc-name={dataHcName} className={className}>
        {children}
      </div>
    );
  };

  if (selfServicePackageIsLoading || setupIntentIsLoading) {
    return (
      <Wrapper>
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
      </Wrapper>
    );
  }

  if (intentWebhookIsLoading || pollSetupWebhook) {
    return (
      <>
        <LoadingSpinner
          small
          containerHeight="50px"
          dataHcName={`${dataHcName}-submitting-credit-card-loader`}
        />
        <div
          className={styles.LoadingMessage}
          data-hc-name="submitting-credit-card-message"
        >
          Submitting Credit Card...
        </div>
      </>
    );
  }

  if (selfServicePackageIsError || setupIntentIsError || intentWebhookIsError) {
    return (
      <Wrapper>
        <FormError
          dataHcEventSection="Self Serve Credit Card Error"
          dataHcName={`${dataHcName}-error`}
          value="An error occurred while attempting to setup a payment method. Your credit card has not been charged. Please refresh your browser to try again."
        />
      </Wrapper>
    );
  }

  // if setup is finished and we aren't polling the webhook, show the stripe enter credit card component
  if (setupIntentIsSuccess && !intentWebhookData) {
    return (
      <StripeSetupIntent
        clientSecret={intentKeys.clientSecret}
        onSuccess={() => setPollSetupWebhook(true)}
        onCancel={onCancel}
      />
    );
  } else if (intentWebhookData && intentWebhookData !== 'shouldRefetch') {
    return (
      <div
        data-hc-event-section={'Self Serve Credit Card Success'}
        className={styles.Success}
      >
        <UserAlert
          type="success"
          dataHcName={`${dataHcName}-success`}
          text="Card Setup Complete"
          className={styles.UserAlert}
        />
      </div>
    );
  } else {
    logException(
      new Error(
        'SelfServiceSetupCreditCard: unexpected scenario, rendering null',
      ),
    );
    return null;
  }
};
