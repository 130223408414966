import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SelfServeRecommendedPlanResponse } from '@hcs/types';

import { SelfServiceApi } from '../api';

const QUERY_KEY_RECOMMENDED_PLAN = 'QUERY_KEY_RECOMMENDED_PLAN';
export const useSelfServeRecommendedPlan = (
  options?: UseQueryOptions<
    SelfServeRecommendedPlanResponse,
    AxiosError<{ status: string }>,
    SelfServeRecommendedPlanResponse,
    [string]
  >,
) => {
  return useQuery(
    [QUERY_KEY_RECOMMENDED_PLAN],
    async () => {
      return await SelfServiceApi.recommendedPlan();
    },
    options,
  );
};
