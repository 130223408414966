import snakecaseKeys from 'snakecase-keys';

import { AxiosClient } from '@hcs/http-clients';
import { EngagementEvent } from '@hcs/types';
import { BEACON_URL } from '@hcs/urls';

export const BeaconApi = {
  trackEvent: async (EngagementEvent: EngagementEvent) => {
    await AxiosClient.post(
      `${BEACON_URL}/v1/t`,
      snakecaseKeys(EngagementEvent),
      {
        headers: {
          'Content-type': 'application/json',
        },
        withCredentials: true,
      },
    );
  },
};
