import React from 'react';
import { Navigate } from 'react-router';

import {
  PrivateApplicationAccess,
  PrivateApplicationAccessProps,
} from '@hcs/auth';
import {
  APP_CONFIG_PLATFORM,
  VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM,
} from '@hcs/hc-products';
import { createRedirectQuerystring } from '@hcs/utils';

export const PrivateApplicationAccessRedirect = (
  props: Omit<
    PrivateApplicationAccessProps,
    'unauthenticatedRender' | 'unauthorizedRender'
  >,
) => {
  const redirectQuery = createRedirectQuerystring();
  return (
    <PrivateApplicationAccess
      {...props}
      unauthenticatedRender={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_SOLUTIONS_PLATFORM.LOGIN}${redirectQuery}`}
          replace
        />
      }
      unauthorizedRender={
        <Navigate to={APP_CONFIG_PLATFORM.rootPath} replace />
      }
    />
  );
};
