import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CerberusApi } from '@hcs/cerberus';
import {
  CerberusInputHcs,
  PropertyStatePreviewHcDocument,
  PropertyStatePreviewHcQuery,
  PropertyStatePreviewHcQueryVariables,
} from '@hcs/types';
import { PropertyStateArgsPreview, PropertyStateType } from '@hcs/types';
import { formatFullAddress, logException } from '@hcs/utils';

import { cerberusLookupResponseToPropertyStateHcPreview } from '../utils';

export type PropertyStatePreviewUseQueryOptions = UseQueryOptions<
  PropertyStateArgsPreview,
  AxiosError,
  PropertyStateArgsPreview,
  [PropertyStateType.Preview, string | number]
>;
export const usePropertyStatePreview = (
  cerberusInput: CerberusInputHcs | undefined,
  options?: PropertyStatePreviewUseQueryOptions,
): UseQueryResult<PropertyStateArgsPreview> => {
  const queryResults = useQuery(
    [
      PropertyStateType.Preview,
      cerberusInput?.hcAddressId ||
        cerberusInput?.slug ||
        formatFullAddress(cerberusInput),
    ],
    async () => {
      if (cerberusInput) {
        const response = await CerberusApi.fetchQuery<
          PropertyStatePreviewHcQuery,
          PropertyStatePreviewHcQueryVariables
        >(PropertyStatePreviewHcDocument, {
          cerberusInput,
        });
        const result: PropertyStateArgsPreview = {
          propertyStateType: PropertyStateType.Preview,
          propertyState:
            cerberusLookupResponseToPropertyStateHcPreview(response),
        };
        return result;
      } else {
        console.log(
          'enabled',
          !!cerberusInput,
          options?.enabled !== undefined ? options.enabled : true,
        );
        const msg =
          'Error in usePropertyState: cerberusInput is unexpectedly null';
        logException(msg);
        throw msg;
      }
    },
    {
      ...options,
      enabled:
        !!cerberusInput &&
        (options?.enabled !== undefined ? options.enabled : true),
    },
  );
  return queryResults;
};
