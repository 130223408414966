import { useDispatch, useSelector } from 'react-redux';

import { ReportApiPartialAppState, ReportConfigSliceState } from '@hcs/types';
import { mapDispatchToSliceActions } from '@hcs/utils';

import { REPORT_API_REDUCER_KEY } from '../rtk';
import { reportConfigSlice } from '../rtk/reportConfig.slice';

export const useReportConfigSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<ReportApiPartialAppState, ReportConfigSliceState>(
      (state) => state[REPORT_API_REDUCER_KEY][reportConfigSlice.name],
    ),
    actions: mapDispatchToSliceActions(dispatch, reportConfigSlice),
  };
};
