import React from 'react';

import { Anchor } from '@hcs/design-system';
import { SearchInput } from '@hcs/design-system';
import { Card } from '@hcs/design-system';
import { RemoveIcon } from '@hcs/design-system';
import { DexpEndpointsParams } from '@hcs/types';
import { ANALYTICS_API_DOCS_URL } from '@hcs/urls';

import { useDexpEndpointsTable } from '../../hooks';
import {
  DexpShoppingCartCard,
  DexpShoppingCartCardProps,
} from '../DexpShoppingCartCard';
import { EndpointsTabSection } from '../EndpointsTabSection';

import styles from './EndpointsTableSearchCard.module.css';

const dataHcName = 'dexp-endpoints-table-search-card';
export const dataHcEventSectionSelectDataPoints = 'Select Data Points';

interface Props {
  shoppingCartCardProps: DexpShoppingCartCardProps;
  filters?: DexpEndpointsParams;
  darkTabs?: boolean;
}

export const EndpointsTableSearchCard = ({
  shoppingCartCardProps,
  filters: filtersProp,
  darkTabs,
}: Props) => {
  const {
    state: { filters, dexpEndpointsQuery },
    actions: { tableChangeFilter },
  } = useDexpEndpointsTable(filtersProp);

  const { search = '' } = filters;
  const { isInitialLoading, data: endpoints } = dexpEndpointsQuery;

  if (isInitialLoading || !endpoints) {
    return null;
  }

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={dataHcEventSectionSelectDataPoints}
    >
      <div
        data-hc-name={`${dataHcName}-search-header`}
        className={styles.SearchHeaderContainer}
      >
        <div className={styles.Search}>
          <SearchInput
            dataHcName={`${dataHcName}-search`}
            className={styles.SearchInput}
            theme={{
              InputElement: styles.InputElement,
            }}
            value={search}
            placeholder="Search Data Points"
            onChange={(str) => {
              tableChangeFilter({ search: str });
            }}
          />
        </div>
        <Anchor
          dataHcName={`${dataHcName}-documentation-link`}
          className={styles.EndpointsDocsLink}
          href={ANALYTICS_API_DOCS_URL}
          target="_blank"
        >
          API Documentation
        </Anchor>
      </div>
      <div className={styles.EndpointsTableSearchCardContainer}>
        <div className={styles.EndpointsTableSearchCardLeftContainer}>
          {endpoints.length > 0 ? (
            <EndpointsTabSection darkTabs={darkTabs} filters={filters} />
          ) : (
            <Card
              dataHcName={dataHcName}
              className={styles.NoSearchResultsMessageCard}
            >
              <RemoveIcon
                dataHcName={`${dataHcName}-no-search-result-icon`}
                size={40}
                color="error-10"
              />
              <div className={styles.NoSearchResultsText}>
                No Search Results
              </div>
            </Card>
          )}
        </div>
        <DexpShoppingCartCard {...shoppingCartCardProps} />
      </div>
    </div>
  );
};
