import React from 'react';

export const OrgBillingPage = React.lazy(() => import('./OrgBillingPage'));
export const OrgOmSftpPage = React.lazy(() => import('./OrgOmSftpPage'));
export const OrgUsagePage = React.lazy(() => import('./OrgUsagePage'));
export const OrgUsersPage = React.lazy(() => import('./OrgUsersPage'));
export const OrgReportPreferencesPage = React.lazy(
  () => import('./OrgReportPreferencesPage'),
);
export const OrgSingleSignOnPage = React.lazy(
  () => import('./OrgSingleSignOnPage'),
);
export const OrgUsageQueriesPage = React.lazy(
  () => import('./OrgUsageQueriesPage'),
);

export const OrgManageBillingPage = React.lazy(
  () => import('./OrgManageBillingPage'),
);
export const OrgChangePlanPage = React.lazy(
  () => import('./OrgChangePlanPage'),
);
