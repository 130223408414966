import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';

import { ChartLegend } from '@hcs/design-system';
import { Legend } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { useComponentDidMount } from '@hcs/hooks';
import { usePrevious } from '@hcs/hooks';
import { useResizeObserver } from '@hcs/hooks';
import { NearbyChartSchema } from '@hcs/types';
import { BarData, ChartType, MenuItemType } from '@hcs/types';

import {
  getXLegendMap,
  makeBucketsNivioSafe,
  maxCount,
  TITLE_CONSTANTS,
} from '../../utils/charts.utils';

import { NearbyChartBarComponent } from './NearbyChartBarComponent';

import styles from './NearbyChart.module.css';

interface Props {
  charts?: NearbyChartSchema['charts'];
  chartType: ChartType;
  valueSelected?: MenuItemType;
  theme?: Partial<{ ChartLegend: string }>;
}

const baseLegend: Legend = {
  id: 'subject-property',
  title: TITLE_CONSTANTS.SUBJECT_PROPERTY,
  background: {
    type: 'single',
    color: 'var(--primary-20)',
  },
};

const commonProperties: Omit<Legend, 'id' | 'title'> = {
  background: {
    type: 'single',
    color: 'var(--neutral-light-40)',
  },
};

const nearbyLegends: Legend[] = [
  {
    id: 'nearby-properties',
    title: TITLE_CONSTANTS.NEARBY_PROPERTIES,
    ...commonProperties,
  },
  baseLegend,
];

const compLegends: Legend[] = [
  {
    id: 'nearby-comp-properties',
    title: TITLE_CONSTANTS.NEARBY_COMP_PROPERTIES,
    ...commonProperties,
  },
  baseLegend,
];

const MAX_TICKS_WITH_FRACTIONAL_TICKS = 10;
const MAX_X_AXIS_BUCKETS_BEFORE_ROTATING_TICKS = 8;
const X_AXIS_TICK_VALUE_DEGREE_ROTATION = 30;

const dataHcName = 'nearby-chart';

export const NearbyChartDisplay = ({
  chartType,
  charts,
  valueSelected,
}: Props) => {
  const barData = useMemo(() => new Map<number, BarData>(), []);
  const previousBarData = usePrevious(barData);
  const prevValueSelected = usePrevious(valueSelected);
  let prevBarData = previousBarData;
  // Only change the previous bar data if the value selected has changed
  if (valueSelected !== prevValueSelected) {
    prevBarData = previousBarData;
  }
  const [chartWidth, setChartWidth] = useState<number | undefined>();
  const chartRef = useRef<HTMLDivElement>(null);
  const docChartData = charts?.filter((row) => row.id === valueSelected)?.[0];
  const prevDocChartData = usePrevious(docChartData);
  const xLegendMap = getXLegendMap(docChartData);
  const subjectIndex = docChartData?.subjectIndex;
  const previousNumberOfBars = prevDocChartData?.buckets?.length;

  useResizeObserver(
    { ref: chartRef },
    useCallback((entry) => {
      setChartWidth(entry.contentRect.width);
    }, []),
  );

  useComponentDidMount(() => {
    if (chartRef?.current) {
      setChartWidth(chartRef.current.clientWidth);
    }
  });

  const nearbyChartComponent = useCallback(
    (props: BarItemProps<BarDatum>) => {
      return (
        <NearbyChartBarComponent
          {...props}
          barData={barData}
          prevBarData={prevBarData}
          previousNumberOfBars={previousNumberOfBars}
          subjectIndex={subjectIndex}
          chartWidth={chartWidth}
        />
      );
    },
    [subjectIndex, previousNumberOfBars, barData, prevBarData, chartWidth],
  );

  const max = maxCount(docChartData);

  if (!docChartData?.buckets || !max) {
    return (
      <div className={styles.NullState}>
        <NullState
          title={`Sorry, the data for this chart is not available`}
          dataHcName={`${dataHcName}-error`}
          absCenter={true}
        />
      </div>
    );
  }

  if (!valueSelected || !charts) {
    return null;
  }

  const longXAxisLabelDetected = docChartData?.buckets
    .map((bucket) => {
      return xLegendMap[valueSelected]['xAxisFormat'](bucket.start);
    })
    .find((xAxisLabel) => (xAxisLabel + '').length > 3);

  return (
    <div
      data-hc-name={dataHcName}
      ref={chartRef}
      className={styles.NearbyChart}
    >
      <ResponsiveBar
        data={makeBucketsNivioSafe(docChartData.buckets)}
        animate={false}
        annotations={[]}
        theme={{
          fontFamily: 'Avenir',
          textColor: '#4a4a4a',
          axis: {
            ticks: {
              text: {
                fontSize: 8,
              },
            },
            legend: {
              text: {
                fontWeight: 700,
                fontSize: 12,
              },
            },
          },
          grid: {
            line: {
              stroke: '#EDEDED',
              strokeWidth: 1,
              opacity: 0.5,
            },
          },
        }}
        keys={['count']}
        barComponent={nearbyChartComponent}
        indexBy="start"
        margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
        padding={0.3}
        colors={(d) => {
          return d.index === docChartData.subjectIndex ? '#6BA0FF' : '#EDEDED';
        }}
        borderWidth={4}
        borderColor={{ from: 'color', modifiers: [['darker', 0.1]] }}
        isInteractive={false}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation:
            longXAxisLabelDetected &&
            docChartData?.buckets.length >
              MAX_X_AXIS_BUCKETS_BEFORE_ROTATING_TICKS
              ? X_AXIS_TICK_VALUE_DEGREE_ROTATION
              : 0,
          format: xLegendMap[valueSelected]['xAxisFormat'],
          legend: xLegendMap[valueSelected]['xAxisLabel'],
          legendPosition: 'middle',
          legendOffset: 38,
        }}
        gridYValues={
          max && max < MAX_TICKS_WITH_FRACTIONAL_TICKS ? max : undefined
        }
        axisLeft={{
          tickValues: 5,
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Properties',
          legendPosition: 'middle',
          legendOffset: -34,
        }}
        axisRight={null}
        axisTop={null}
        borderRadius={undefined}
        colorBy="indexValue"
        enableGridX={false}
        enableGridY={true}
        enableLabel={true}
      />
      <ChartLegend
        dataHcName="chart-legend"
        legendItems={
          chartType === ChartType.Nearby ? nearbyLegends : compLegends
        }
        theme={{
          Container: styles.ChartLegend,
          DotLabel: {
            Label: styles.ChartLegendLabel,
            Dot: styles.ChartLegendDot,
          },
        }}
      />
    </div>
  );
};
