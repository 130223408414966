import React from 'react';
import classNames from 'classnames';

import styles from './QuickFilter.module.css';

export interface QuickFilterButtonProps {
  label: string;
  active: boolean;
  dataHcName: string;
  onClick: () => void;
  disabled?: boolean;
}
export interface QuickFilterProps {
  dataHcName: string;
  className?: string;
  disabled?: boolean;
  buttons: QuickFilterButtonProps[];
}

export const QuickFilter = ({
  buttons,
  dataHcName,
  className,
  disabled,
}: QuickFilterProps) => {
  return (
    <div
      className={classNames(styles.QuickFilters, className)}
      data-hc-name={dataHcName}
    >
      {buttons.map(
        ({ label, active, dataHcName: buttonDataHcName, ...button }, b) => (
          <button
            key={`button-${b}`}
            data-hc-name={buttonDataHcName}
            {...button}
            disabled={disabled}
            className={classNames(styles.QuickFilterButton, {
              [styles.active]: active,
              [styles.disabled]: disabled,
            })}
          >
            {label}
          </button>
        ),
      )}
    </div>
  );
};
