import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useUrlState } from '@hcs/hooks';
import { DexpEvent, DexpJobsResponse } from '@hcs/types';

import {
  DataExplorerApi,
  DexpJobsPaginationOptions,
} from '../api/dataExplorer.api';
import { DEXP_REPORTS_PER_PAGE } from '../constants';

import { useSubscribeToDexpJobEvents } from './useSubscribeToDexpJobEvents';

const DEXP_JOBS_QUERY_KEY = 'dexp-jobs';
export const useDexpJobs = () => {
  const initialState: DexpJobsPaginationOptions = {
    page: 1,
    pageSize: DEXP_REPORTS_PER_PAGE,
  };
  const STATE_ID = 'dexp-jobs-table';
  const { state, actions } = useUrlState(STATE_ID, initialState);
  const queryClient = useQueryClient();
  const dexpJobsQuery = useQuery(
    [DEXP_JOBS_QUERY_KEY, state.pageSize, state.page],
    async () => await DataExplorerApi.fetchDexpJobs(state),
  );
  const cachedQueries = queryClient.getQueriesData<
    DexpJobsResponse | undefined
  >([DEXP_JOBS_QUERY_KEY]);
  const dexpEventCallback = useCallback(
    (dexpEvent: DexpEvent) => {
      cachedQueries.forEach(([queryKey, queryData]) => {
        if (queryData) {
          const updatedData = {
            ...queryData,
            data: queryData.data.map((cachedJob) => {
              if (cachedJob.id === dexpEvent.job.id) {
                return dexpEvent.job;
              } else {
                return cachedJob;
              }
            }),
          };
          queryClient.setQueryData<DexpJobsResponse>(queryKey, updatedData);
        }
      });
    },
    [queryClient, cachedQueries],
  );
  useSubscribeToDexpJobEvents(dexpEventCallback);
  return {
    ...dexpJobsQuery,
    loadNextPage: () => {
      actions.patchUrlState([
        {
          op: 'replace',
          path: `/page`,
          value: (state.page || 1) + 1,
        },
      ]);
    },
    loadPrevPage: () => {
      const prevPage = state.page && state.page > 1 ? state.page - 1 : 1;
      actions.patchUrlState([
        {
          op: 'replace',
          path: `/page`,
          value: prevPage,
        },
      ]);
    },
  };
};
