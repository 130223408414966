import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { LeadFeedApi } from '../';

export const QUERY_KEY_TEMPLATES = 'templates';
export const useTemplate = () => {
  const { data: templates } = useQuery(
    [QUERY_KEY_TEMPLATES],
    async () => await LeadFeedApi.fetchTemplates(),
  );

  const template = useMemo(() => {
    if (templates) {
      // sort by last updated desc in case more than one was created somehow
      const sortedTemplates = [...templates].sort(
        (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime(),
      );
      if (sortedTemplates[0]) {
        return sortedTemplates[0];
      }
    }
    return undefined;
  }, [templates]);
  return template;
};
