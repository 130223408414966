import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { DataExplorerIcon, ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { useNavigateToDexp } from '../../hooks/useNavigatetoDexp';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './ProductCards.module.css';

interface DexpProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

const dataHcName = 'data-explorer-card';

export const DexpProductCard = ({
  navigateToSSSelectPlan,
}: DexpProductCardProps) => {
  const appConfigExtended = useAppConfigExtended(AppSlugs.DataExplorer);
  const { active, handleOpen, handleClose } = useActiveState();
  const navigateToDexp = useNavigateToDexp();
  if (!appConfigExtended) return null;
  const { hasAccess } = appConfigExtended;
  const handleClick = () => {
    if (hasAccess) {
      return navigateToDexp();
    } else {
      return handleOpen();
    }
  };
  return (
    <>
      <ProductCard
        dataHcName={dataHcName}
        theme={{
          ProductCard: styles.ProductCard,
        }}
        productName="Data Explorer"
        subheading={appConfigExtended.appConfig.secondaryName}
        productDescription="Add real-estate data to Excel spreadsheet from a web browser."
        hasAccess={!!hasAccess}
        Icon={DataExplorerIcon}
        onClick={handleClick}
      />
      <ProductPreviewDialog
        active={active}
        appConfigExtended={appConfigExtended}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleClose}
      />
    </>
  );
};
