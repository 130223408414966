import { ExperienceFlagIds } from '@hcs/types';

import {
  useExperienceFlags,
  useExperienceFlagsSystem,
} from './useExperienceFlags';

export const useExperienceFlag = (experienceFlagId: ExperienceFlagIds) =>
  useExperienceFlags()[experienceFlagId];

export const useExperienceFlagSystem = (experienceFlagId: ExperienceFlagIds) =>
  useExperienceFlagsSystem()[experienceFlagId];
