import { useNavigate } from 'react-router';

import { APP_CONFIG_CANARYAI } from '../constants';

export const useNavigateToCanaryAI = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_CANARYAI.rootPath);
  };
};
