import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAccount } from '@hcs/authn';
import { CapabilityCheck } from '@hcs/authz';
import {
  Button,
  Card,
  CardTitle,
  CopyInput,
  LoadingSpinner,
  NoContent,
  StatusMessage,
  Switch,
  TopLevelBanner,
} from '@hcs/design-system';
import { Input, TextArea } from '@hcs/design-system';
import { DialogInputLayout, FormFieldsSwitch } from '@hcs/forms';
import { SamlProviderConfig, SamlProviderConfigFormData } from '@hcs/types';
import { SOLUTIONS_URL } from '@hcs/urls';
import { logException } from '@hcs/utils';

import { useActivateOrgSamlProvider } from '../../hooks/useActivateOrgSamlProvider';
import { useOrgSamlProvider } from '../../hooks/useOrgSamlProvider';
import { useSaveOrgSamlProvider } from '../../hooks/useSaveOrgSamlProvider';
import { DeleteOrgSamlProviderDialog } from '../DeleteOrgSamlProviderDialog';

import styles from './OrgSamlProvider.module.css';

const FORM_SCHEMA = yup.object().shape({
  certificate: yup.string().required('Certificate is required'),
  authMetadata: yup.string().required('Auth Metadata is required'),
  authAudience: yup.string().nullable(),
  domain: yup.string().required('Domain is required'),
  activated: yup.boolean().default(false),
  mandatory: yup.boolean().default(false),
});

interface BaseInputProps {
  label: string;
  labelHelper?: string;
  required: boolean;
  form: UseFormReturn<SamlProviderConfigFormData>;
  dataHcName: string;
}

interface TextAreaProps extends BaseInputProps {
  fieldName: Exclude<
    keyof SamlProviderConfigFormData,
    'authAudience' | 'mandatory' | 'activated'
  >;
}

const TextAreaField = ({
  label,
  required,
  fieldName,
  form,
  dataHcName,
}: TextAreaProps) => {
  return (
    <DialogInputLayout
      label={label}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <TextArea
            {...field}
            placeholder={label}
            value={field.value || ''}
            dataHcName={`${dataHcName}-input`}
            onChange={field.onChange}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

interface TextInputProps extends BaseInputProps {
  readOnly?: boolean;
  fieldName: Extract<
    keyof SamlProviderConfigFormData,
    'authAudience' | 'domain'
  >;
}

const TextField = ({
  label,
  labelHelper,
  required,
  fieldName,
  form,
  dataHcName,
  readOnly,
}: TextInputProps) => {
  return (
    <DialogInputLayout
      label={label}
      labelHelper={labelHelper}
      required={required}
      dataHcName={dataHcName}
      className={styles.Field}
    >
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <Input
            {...field}
            readOnly={readOnly}
            placeholder={label}
            value={field.value || ''}
            dataHcName={`${dataHcName}-input`}
            onChange={field.onChange}
            required={required}
            error={form.formState.errors[fieldName]?.message}
          />
        )}
      />
    </DialogInputLayout>
  );
};

interface Props {
  orgId?: number;
  mode: 'internal' | 'external';
}

const dataHcName = 'org-saml-provider';
export const OrgSamlProvider = ({ orgId, mode }: Props) => {
  const { data: account } = useAccount();
  const { data: existingSamlProvider, isLoading: useSamlProviderLoading } =
    useOrgSamlProvider(orgId);
  const { mutate: activateOrgSamlProvider } = useActivateOrgSamlProvider();
  const {
    mutate: saveMutationMutate,
    isLoading: saveMutationIsLoading,
    isError: saveMutationIsError,
    error: saveMutationError,
  } = useSaveOrgSamlProvider();

  const form = useForm<SamlProviderConfigFormData>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      certificate: null,
      authMetadata: null,
      authAudience: null,
      domain: null,
      mandatory: false,
    },
  });
  const [deleteDialogActive, setDeleteDialogActive] = useState(false);

  const { reset: formReset } = form;

  const resetToExsitingProvider = useCallback(
    (existingSamlProvider: SamlProviderConfig) => {
      formReset({
        authAudience: existingSamlProvider.authAudience,
        authMetadata: existingSamlProvider.authMetadata,
        certificate: existingSamlProvider.certificate,
        domain: existingSamlProvider.domain,
        mandatory: existingSamlProvider.mandatory,
      });
    },
    [formReset],
  );

  const clearForm = useCallback(() => {
    formReset({
      authAudience: null,
      authMetadata: null,
      certificate: null,
      mandatory: false,
      domain: null,
    });
  }, [formReset]);

  // update default values after saml config load (if they exist)
  useEffect(() => {
    if (existingSamlProvider) {
      resetToExsitingProvider(existingSamlProvider);
    }
  }, [existingSamlProvider, resetToExsitingProvider]);

  if (useSamlProviderLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-loader`} />;
  }

  const handleSubmit = (formData: SamlProviderConfigFormData) => {
    if (orgId !== undefined) {
      saveMutationMutate({
        formData,
        orgId,
        existingSamlProvider: existingSamlProvider || null,
      });
    } else {
      logException('OrgSamlProvider: orgId is undefined on submit');
    }
  };

  const showDeleteButton =
    existingSamlProvider !== undefined &&
    existingSamlProvider !== null &&
    orgId !== undefined;

  return (
    <CapabilityCheck
      capabilityKey="org-sso-config"
      error403={
        <NoContent>
          You do not have permission to edit single sign on settings.
        </NoContent>
      }
    >
      <Card dataHcName={`${dataHcName}-card`} maxWidth={900} noGap>
        <CardTitle
          dataHcName={`${dataHcName}-card-title`}
          className={styles.CardTitle}
        >
          <div>SAML Provider Config</div>
          {showDeleteButton ? (
            <Button
              dataHcName={`${dataHcName}-delete-button`}
              label="Delete"
              onClick={() => setDeleteDialogActive(true)}
              highlightRed
            />
          ) : null}
        </CardTitle>
        {saveMutationIsError && (
          <StatusMessage
            dataHcName={`${dataHcName}-error-message`}
            show={saveMutationIsError}
            type="error"
            title={saveMutationError.message}
          />
        )}
        {existingSamlProvider?.activated && (
          <div className={styles.ActiveInfo}>
            <DialogInputLayout
              label="Org Slug"
              labelHelper="Enter this slug when logging in via the Single Sign On flow."
              required={false}
              dataHcName={`org-slug`}
              className={styles.Field}
            >
              <b>{account?.organization.slug}</b>
            </DialogInputLayout>
            <DialogInputLayout
              label="Login Link"
              labelHelper="Bookmark this link to be automatically logged in via SSO. Note: this login URL will only work for SP-initiated SAML."
              required={false}
              dataHcName={`sso-login-link`}
              className={styles.Field}
            >
              <CopyInput
                dataHcName="sso-login-link-input"
                value={SOLUTIONS_URL + `/sso/${account?.organization.slug}`}
              />
            </DialogInputLayout>
            <DialogInputLayout
              label="Entity ID"
              required={false}
              dataHcName={`entity-id`}
              className={styles.Field}
            >
              <CopyInput
                dataHcName="entity-id-input"
                value={existingSamlProvider.ssoURL || ''}
              />
            </DialogInputLayout>
            <DialogInputLayout
              label="ACS URL"
              required={false}
              dataHcName={`acs-url`}
              className={styles.Field}
            >
              <CopyInput
                dataHcName="acs-url-input"
                value={existingSamlProvider.ssoURL || ''}
              />
            </DialogInputLayout>
          </div>
        )}
        {existingSamlProvider && !existingSamlProvider.activated && (
          <TopLevelBanner
            dataHcName="saml-activate-banner"
            className={styles.ActiveWarning}
          >
            We are reviewing your SAML configuration and will notify you when it
            has been activated.
          </TopLevelBanner>
        )}
        <FormProvider {...form}>
          <form
            data-hc-name={dataHcName}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            {mode === 'internal' && existingSamlProvider && orgId && (
              <DialogInputLayout
                label="Activated"
                required={false}
                dataHcName={dataHcName}
                className={styles.Field}
              >
                <Switch
                  dataHcName={`${dataHcName}-activated-switch`}
                  switchOffOption={{ value: false }}
                  switchOnOption={{ value: true }}
                  value={!!existingSamlProvider?.activated}
                  onChange={(activated) => {
                    activateOrgSamlProvider({
                      orgId,
                      providerId: existingSamlProvider.id,
                      activated,
                    });
                  }}
                />
              </DialogInputLayout>
            )}
            <TextField
              label="Domain"
              labelHelper='Domain should match the email address domain of your users. Example: for user email "bob@company1.com" the domain is "company1.com" (without quotes)'
              required
              fieldName="domain"
              dataHcName={`${dataHcName}-domain-field`}
              form={form}
              readOnly={!!existingSamlProvider?.activated}
            />
            <TextAreaField
              label="Certificate"
              required
              fieldName="certificate"
              dataHcName={`${dataHcName}-certificate-field`}
              form={form}
            />
            <TextAreaField
              label="Auth Metadata"
              required
              fieldName="authMetadata"
              dataHcName={`${dataHcName}-auth-metadata-field`}
              form={form}
            />
            <TextField
              label="Auth Audience"
              required={false}
              fieldName="authAudience"
              dataHcName={`${dataHcName}-auth-audience-field`}
              form={form}
            />
            <DialogInputLayout
              label="Mandatory"
              labelHelper="If enabled, all users will be required to use SSO to log in"
              required={false}
              dataHcName={dataHcName}
              className={styles.Field}
            >
              <FormFieldsSwitch label="" name="mandatory" />
            </DialogInputLayout>
            <div className={styles.FormButtons}>
              {existingSamlProvider ? (
                <Button
                  dataHcName={`${dataHcName}-cancel-button`}
                  label="Cancel Changes"
                  disabled={!form.formState.isDirty || saveMutationIsLoading}
                  primary={false}
                  onClick={() => resetToExsitingProvider(existingSamlProvider)}
                />
              ) : null}

              <Button
                dataHcName={`${dataHcName}-save-button`}
                type="submit"
                label="Save"
                disabled={saveMutationIsLoading || !form.formState.isValid}
              />
            </div>
          </form>
        </FormProvider>
        {showDeleteButton ? (
          <DeleteOrgSamlProviderDialog
            active={deleteDialogActive}
            onClose={() => setDeleteDialogActive(false)}
            providerId={existingSamlProvider.id}
            orgId={orgId}
            onDeleteSuccess={clearForm}
          />
        ) : null}
      </Card>
    </CapabilityCheck>
  );
};
