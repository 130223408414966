import { useMemo } from 'react';

import { AddressSearch } from '@hcs/address-search';
import {
  MultiSearch,
  MultiSearchInput,
  Popover,
  SearchIcon,
} from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';
import { SearchByMlsNumber } from '@hcs/search-by-mls-number';
import { useToastSlice } from '@hcs/toast';
import {
  AddressSearchHit,
  CompTypes,
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
} from '@hcs/types';

import { useCompCompareDialogSlice, useSubjectDocument } from '../../hooks';
import { useAddCompsToFarm } from '../../hooks/useAddCompsToFarm';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAll } from '../../utils/reportConfig.utils';

export const COMP_SEARCH_QUERY_ID = 'comp-search';
export interface CompSearchProps {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  onSelect?: (addressSearchHit: AddressSearchHit) => void;
}
const dataHcName = 'comp-search';
export const dataHcEventSectionCompSearch = 'Comp Search';
export const COMP_SEARCH_FEATURES_SALE = [
  ReportFeatures.CompsSelectByAddress,
  ReportFeatures.CompsSelectByListing,
];
export const COMP_SEARCH_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelectByAddress,
  ReportFeatures.RentalCompsSelectByListing,
];
export const CompSearch = ({
  reportId,
  className,
  compType,
  onSelect,
}: CompSearchProps) => {
  const isRental = compType === CompTypes.Rental;
  const dataHcEventNameSelect = isRental ? 'Add Rental Comp' : 'Add Comp';
  const { data: reportConfig } = useReportConfig(reportId);
  const { data: subjectDoc } = useSubjectDocument(reportId);
  const { isPhoneWidth } = useScreenSize();
  const { actions } = useCompCompareDialogSlice();
  const { mutate: addCompsToFarm } = useAddCompsToFarm(reportId, compType, {
    onSuccess: (data, selectCompArg) => {
      if (selectCompArg.type === 'hcAddressId') {
        const hcAddressId = selectCompArg.hcAddressIds[0];
        if (hcAddressId) {
          actions.compCompareDialogOpen({
            type: 'hcAddressId',
            compType,
            hcAddressId,
          });
        }
      } else {
        const listingIdentifier = selectCompArg.listingIdentifiers[0];
        if (listingIdentifier) {
          actions.compCompareDialogOpen({
            type: 'listing',
            compType,
            listingIdentifier,
          });
        }
      }
    },
  });
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const subjectHcAddressId = subjectDoc?.data.propertyState.hcAddressId;
  const inputs = useMemo(() => {
    const options: MultiSearchInput[] = [];

    if (
      reportFeaturesSupportedAll(reportConfig, [
        isRental
          ? ReportFeatures.RentalCompsSelectByAddress
          : ReportFeatures.CompsSelectByAddress,
      ])
    ) {
      options.push({
        input: (
          <AddressSearch
            selectEngagement={{
              dataHcEventName: dataHcEventNameSelect,
              dataHcEventType: MeaningfulEventTypes.Goal,
            }}
            placeholder={isPhoneWidth ? "Address" : "Search for an Address"}
            onSelect={(address) => {
              if (
                subjectHcAddressId &&
                Number(address.id) === subjectHcAddressId
              ) {
                toastOpen({
                  type: 'error',
                  title: 'Subject Property Cannot Be Selected as a Comparable',
                });
              } else if (address.id) {
                onSelect?.(address);
                addCompsToFarm({
                  type: 'hcAddressId',
                  hcAddressIds: [Number(address.id)],
                });
              } else {
                toastOpen({
                  type: 'error',
                  title: 'Unsupported Address',
                });
              }
            }}
            disableClear
            hideChevron
            inputStyle="transparent"
          />
        ),
        label: 'Address',
        value: 'address',
      });
    }
    if (
      !isRental &&
      reportFeaturesSupportedAll(reportConfig, [
        ReportFeatures.CompsSelectByListing,
      ])
    ) {
      options.push({
        input: (
          <SearchByMlsNumber
            queryId={COMP_SEARCH_QUERY_ID}
            onSelect={(listing) => {
              if (
                subjectHcAddressId &&
                listing.address?.hcAddressId === subjectHcAddressId
              ) {
                toastOpen({
                  type: 'error',
                  title: 'Subject Property Cannot Be a Comparable',
                });
              } else if (listing.address?.hcAddressId) {
                addCompsToFarm({
                  type: 'hcAddressId',
                  hcAddressIds: [Number(listing.address.hcAddressId)],
                });
              }
            }}
            inputStyle="transparent"
            hideChevron
            disableClear
          />
        ),
        label: 'MLS',
        value: 'listing',
      });
    }

    return options;
  }, [compType, reportConfig, subjectHcAddressId]);

  // If no inputs are supported, do not show this feature
  if (!inputs.length) return null;

  const content = (
    <MultiSearch
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionCompSearch}
      className={className}
      inputs={inputs}
    />
  );
  if (isPhoneWidth) {
    return (
      <Popover
        dataHcName={`${dataHcName}-mobile-popover`}
        dataHcEventSection={dataHcEventSectionCompSearch}
        trigger={<SearchIcon dataHcName={`${dataHcName}-mobile-icon`} />}
        content={content}
      />
    );
  } else {
    return content;
  }
};
