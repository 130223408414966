import snakecaseKeys from 'snakecase-keys';

import {
  AddressParts,
  OrderItem,
  OrderItemGroup,
  OrderItemsParams,
  OrderItemsParamsForApi,
  OrderItemsSearchApiParams,
  OrderItemsSearchInputs,
} from '@hcs/types';
import { camelCaseToSnakeCase, logException } from '@hcs/utils';

import {
  ORDER_ITEMS_PER_PAGE,
  ORDER_ITEMS_SEARCH_RESULTS_LIMIT,
} from '../constants/OrderItems.constants';

interface SortListItem {
  field: keyof OrderItem;
  asc: boolean;
}

const ADDRESS: SortListItem = {
  field: 'address',
  asc: true,
};
const CUSTOMER_ITEM_ID: SortListItem = {
  field: 'customerItemId',
  asc: true,
};
const STATE: SortListItem = {
  field: 'state',
  asc: true,
};
const CITY: SortListItem = {
  field: 'city',
  asc: true,
};
const UNIT: SortListItem = {
  field: 'unit',
  asc: true,
};
const ID: SortListItem = {
  field: 'id',
  asc: true,
};
const PROPERTY_TYPE: SortListItem = {
  field: 'propertyType',
  asc: true,
};
const ZIPCODE: SortListItem = {
  field: 'zipcode',
  asc: true,
};
const VALIDATION_ISSUES_SUMMARY: SortListItem = {
  field: 'validationIssueSummary',
  asc: true,
};
const CAN_OVERRIDE_ALL_ISSUES: SortListItem = {
  field: 'canOverrideAllIssues',
  asc: false,
};

export const prepOrderItemParamsForApi = ({
  orderItemGroup,
  sortBy,
  sortOrder,
  ...params
}: OrderItemsParams): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();
  const isProcessItem = (): boolean | null => {
    if (orderItemGroup === OrderItemGroup.InOrder) {
      return true;
    } else if (orderItemGroup === OrderItemGroup.NeedReview) {
      return null;
    } else {
      return false;
    }
  };
  const sortArray: SortListItem[] = [];
  // how to apply the sort order?
  if (sortBy) {
    const sortOrderBoolean = sortOrder === 'ASC';
    if (sortBy === 'customerItemId') {
      sortArray.push(
        { ...CUSTOMER_ITEM_ID, asc: sortOrderBoolean },
        STATE,
        CITY,
        ADDRESS,
        UNIT,
        ID,
      );
    } else if (sortBy === 'propertyType') {
      sortArray.push(
        { ...PROPERTY_TYPE, asc: sortOrderBoolean },
        STATE,
        CITY,
        ADDRESS,
        UNIT,
        CUSTOMER_ITEM_ID,
        ID,
      );
    } else if (sortBy === 'address') {
      sortArray.push(
        { ...ADDRESS, asc: sortOrderBoolean },
        STATE,
        CITY,
        UNIT,
        CUSTOMER_ITEM_ID,
        ID,
      );
    } else if (sortBy === 'city') {
      sortArray.push(
        { ...CITY, asc: sortOrderBoolean },
        STATE,
        ADDRESS,
        UNIT,
        CUSTOMER_ITEM_ID,
        ID,
      );
    } else if (sortBy === 'state') {
      sortArray.push(
        { ...STATE, asc: sortOrderBoolean },
        CITY,
        ADDRESS,
        UNIT,
        CUSTOMER_ITEM_ID,
        ID,
      );
    } else if (sortBy === 'zipCode') {
      sortArray.push(
        { ...ZIPCODE, asc: sortOrderBoolean },
        STATE,
        CITY,
        ADDRESS,
        UNIT,
        ID,
      );
    } else if (sortBy === 'issues') {
      sortArray.push(
        CAN_OVERRIDE_ALL_ISSUES,
        { ...VALIDATION_ISSUES_SUMMARY, asc: sortOrderBoolean },
        STATE,
        CITY,
        ADDRESS,
        UNIT,
        ID,
      );
    } else if (sortBy === 'createdAt') {
      sortArray.push({
        field: 'createdAt',
        asc: sortOrderBoolean,
      });
    } else if (sortBy === 'dueDate') {
      sortArray.push({
        field: 'dueDate',
        asc: sortOrderBoolean,
      });
    } else {
      logException(
        `orderItem.utils.ts prepOrderItemParamsForApi: Invalid sortBy field ${sortBy}`,
      );
    }
  }
  const paramsForApi: OrderItemsParamsForApi = snakecaseKeys({
    ...params,
    pageSize: params?.pageSize || ORDER_ITEMS_PER_PAGE,
    ...(sortArray.length > 0
      ? {
          ordering: sortArray.map((sortItem) => {
            return `${sortItem.asc ? '' : '-'}${camelCaseToSnakeCase(
              sortItem.field,
            )}`;
          }),
        }
      : {}),
    processItem: isProcessItem(),
  });

  let prop: keyof OrderItemsParamsForApi;

  for (prop in paramsForApi) {
    const value = paramsForApi[prop];
    urlSearchParams.append(prop, value as string);
  }

  return urlSearchParams;
};

export const getFullAddress = ({
  address,
  unit,
  unitDesignator,
  city,
  state,
  zipcode,
}: AddressParts) => {
  const fullUnit = unit
    ? unitDesignator
      ? `${unitDesignator} ${unit}`
      : `${unit}`
    : null;
  return fullUnit
    ? `${address} ${fullUnit}, ${city}, ${state}, ${zipcode}`
    : `${address}, ${city}, ${state}, ${zipcode}`;
};

export const getFullAddressFromOrderItem = (orderItem: OrderItem) => {
  const addressParts: AddressParts = {
    address: orderItem.address,
    unit: orderItem.unit,
    city: orderItem.city,
    state: orderItem.state,
    zipcode: orderItem.zipcode,
  };
  return getFullAddress(addressParts);
};

export const orderItemsSearchInputsToApiParams = (
  orderItemsSearchInputs: OrderItemsSearchInputs,
): OrderItemsSearchApiParams => {
  return {
    q: orderItemsSearchInputs.searchStr || '',
    limit: orderItemsSearchInputs.limit || ORDER_ITEMS_SEARCH_RESULTS_LIMIT,
  };
};
