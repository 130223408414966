import { ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, Toggle, ToggleOption } from '@hcs/design-system';
import { useWindowResize } from '@hcs/hooks';
import {
  SelfServeOrgInfo,
  SelfServePlan,
  SelfServicePlanTerm,
} from '@hcs/types';

import { SELF_SERVICE_TIERS } from '../../configs';
import { SELF_SERVICE_SIGN_UP_CONTACT_URL } from '../../constants/selfService.constants';
import { useSelfServicePlans } from '../../hooks';
import { PlanCard } from '../PlanCard/PlanCard';
import { SelfServiceCancelSubscription } from '../SelfServiceCancelSubscription';

import styles from './SelfServicePlanSelector.module.css';
export interface SelfServicePlanSelectorProps {
  title?: ReactNode;
  className?: string;
  trials?: boolean;
  orgSsInfo?: SelfServeOrgInfo;
  hideCtas?: boolean;
  recommendedPlan?: SelfServePlan;
  onSelectPlan: (plan: SelfServePlan) => void;
}
const dataHcName = 'self-service-plan-selector';

export const SelfServicePlanSelector = ({
  title = 'Choose the right plan for you',
  onSelectPlan,
  trials,
  hideCtas,
  orgSsInfo,
  className,
  recommendedPlan,
}: SelfServicePlanSelectorProps) => {
  const [term, setBillingPeriod] = useState<SelfServicePlanTerm>(
    recommendedPlan?.term || orgSsInfo?.self_serve_plan?.term || 'Year',
  );
  const { isLoading, data: plans } = useSelfServicePlans();
  const hasTrialPlan = !!plans?.find((p) => !!p.trial);
  const billingPeriodOptions = useMemo<ToggleOption<SelfServicePlanTerm>[]>(
    () => [
      {
        dataHcName: `${dataHcName}-billing-period-annual`,
        label: (
          <div>
            Pay yearly{' '}
            <span className={styles.ToggleHighlight}> get 2 months free</span>
          </div>
        ),
        value: 'Year',
      },
      {
        dataHcName: `${dataHcName}-billing-period-monthly`,
        label: 'Pay monthly',
        value: 'Month',
      },
    ],
    [],
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const resizeCallback = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);
  useWindowResize(resizeCallback);

  return (
    <section className={classNames(styles.SelfServicePlanSelector, className)}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Toggle}>
        <Toggle<SelfServicePlanTerm>
          dataHcName={`${dataHcName}-billing-period`}
          tertiary
          onChange={setBillingPeriod}
          value={term}
          equalWidthOptions={
            windowWidth ? (windowWidth > 450 ? true : false) : true
          }
          options={billingPeriodOptions}
        />
      </div>
      <div className={styles.Plans}>
        {SELF_SERVICE_TIERS.map((tier) => {
          const plan = plans?.find(
            (p) => p.term === term && p.name === tier.key,
          );
          if (!plan && !isLoading) {
            return null;
          }
          const isCurrentPlan =
            orgSsInfo?.self_serve_plan?.id &&
            plan?.id === orgSsInfo.self_serve_plan.id;
          const isUpcomingPlan =
            orgSsInfo?.upcoming_self_serve_plan?.id &&
            plan?.id === orgSsInfo.upcoming_self_serve_plan.id;
          const canPurchaseCurrentPlan =
            isCurrentPlan &&
            (orgSsInfo?.canceled_subscription || orgSsInfo?.changed_plan);
          return (
            <div key={`plan-${tier.key}`} className={styles.PlanColumn}>
              <PlanCard
                isLoading={isLoading}
                hideCta={hideCtas}
                className={classNames({
                  [styles.currentPlan]:
                    isCurrentPlan || recommendedPlan?.name === plan?.name,
                  [styles.upcomingPlan]: isUpcomingPlan,
                })}
                planId={plan?.id}
                trials={trials}
                highlightCta={
                  recommendedPlan
                    ? recommendedPlan.name === plan?.name // Highlight recommended plan by tier
                    : !hasTrialPlan &&
                      !isCurrentPlan &&
                      !isUpcomingPlan &&
                      plan?.name === 'Pro'
                }
                cta={
                  isCurrentPlan ? (
                    <Button
                      dataHcName="current-plan-cta"
                      disabled={
                        !orgSsInfo.canceled_subscription &&
                        !orgSsInfo.changed_plan
                      }
                      onClick={
                        canPurchaseCurrentPlan && plan
                          ? () => onSelectPlan(plan)
                          : undefined
                      }
                      secondary
                    >
                      {canPurchaseCurrentPlan ? 'Buy Now' : 'Your Current Plan'}
                    </Button>
                  ) : isUpcomingPlan ? (
                    <Button dataHcName="upcoming-plan-cta" disabled secondary>
                      Your Upcoming Plan
                    </Button>
                  ) : undefined
                }
                onSelectPlan={onSelectPlan}
              />
              {isCurrentPlan && !orgSsInfo.canceled_subscription && (
                <SelfServiceCancelSubscription />
              )}
              {recommendedPlan?.name === plan?.name && (
                <div className={styles.RecommendedLabel}>
                  Recommended For You
                </div>
              )}
            </div>
          );
        })}
        {!isLoading && (
          <PlanCard
            tierKey="Enterprise"
            hideCta={hideCtas}
            onSelectPlan={() => {
              window.open(SELF_SERVICE_SIGN_UP_CONTACT_URL, '_blank');
            }}
          />
        )}
      </div>
    </section>
  );
};
