import classNames from 'classnames';

import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '@hcs/urls';

import styles from './TermsAndPrivacyLinks.module.css';

interface Props {
  className?: string;
}
const dataHcName = 'terms-and-privacy-links';
export const TermsAndPrivacyLinks = ({ className }: Props) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.TermsAndPrivacyLinks, className)}
    >
      <a target="_blank" rel="noreferrer" href={TERMS_OF_USE_URL}>
        Terms of Use
      </a>
      <div className={styles.Divider}>|</div>
      <a target="_blank" rel="noreferrer" href={PRIVACY_POLICY_URL}>
        Privacy Policy
      </a>
    </div>
  );
};
