import React from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import classNames from 'classnames';

import { getRelatedTargetHcName } from '@hcs/utils';

import { InputNumber, InputNumberProps } from '../InputNumber';

import styles from './RangeField.module.css';

export interface RangeFieldProps<MinFieldNames, MaxFieldNames> {
  dataHcName: string;
  className?: string;
  minName: MinFieldNames;
  maxName: MaxFieldNames;
  shouldFormat?: InputNumberProps['shouldFormat'];
  isFloat?: boolean;
  isPercent?: boolean;
}
export const RangeField = <
  FormData extends FieldValues,
  MinFieldNames extends FieldPath<FormData>,
  MaxFieldNames extends FieldPath<FormData>,
>(
  props: RangeFieldProps<MinFieldNames, MaxFieldNames>,
) => {
  const {
    className,
    dataHcName,
    minName,
    maxName,
    shouldFormat = {
      shouldFormatNumber: true,
    },
    isFloat = false,
  } = props;

  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext<FormData>();

  const maxHcName = `${dataHcName}-max-input`;
  const minHcName = `${dataHcName}-min-input`;
  const minError = errors[minName]?.message;
  const maxError = errors[maxName]?.message;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.RangeFieldsContainer, className)}
    >
      <Controller
        name={minName}
        control={control}
        render={({ field }) => (
          <InputNumber
            placeholder="No Min"
            dataHcName={minHcName}
            {...field}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
            }}
            onBlur={(e) => {
              field.onBlur();

              // if focus is not shifted to the MAX input, force validation of MAX
              if (getRelatedTargetHcName(e) !== maxHcName) {
                trigger(maxName);
              }
            }}
            shouldFormat={shouldFormat}
            isFloat={isFloat}
            error={typeof minError === 'string' ? minError : undefined}
          />
        )}
      />{' '}
      <span className={styles.RangeFieldSeparator}>-</span>
      <Controller
        name={maxName}
        control={control}
        render={({ field }) => (
          <InputNumber
            placeholder="No Max"
            dataHcName={maxHcName}
            {...field}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
            }}
            onBlur={(e) => {
              field.onBlur();

              // if focus is not shifted to the MIN input, force validation of MIN
              if (getRelatedTargetHcName(e) !== minHcName) {
                trigger(minName);
              }
            }}
            shouldFormat={shouldFormat}
            isFloat={isFloat}
            error={typeof maxError === 'string' ? maxError : undefined}
          />
        )}
      />
    </div>
  );
};
