import React, { useState } from 'react';

import { FilterRange, Input } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateEditCallbackArgs,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
} from '@hcs/types';
import { formatNumber, numInputStrStrip } from '@hcs/utils';

const FIELD = PropertyStateFields.bedrooms;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Bedrooms';
const labelShort = 'Beds';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  }
  return propertyState?.propertyDetails?.bedrooms;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatNumber(getValue(propertyStateArgs));

// For displaying multiple fields in a single line
const labelMicro: FieldConfig['labelMicro'] = 'Bed';
const formatValueShort: FieldConfig['formatValueShort'] = formatValue;

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)} ${labelShort}`;
const propertyStatePath: PropertyStatePaths = '/propertyDetails/bedrooms';

// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState(
    defaultValue == null ? null : defaultValue.toString(),
  );

  return (
    <Input
      dataHcName="bedroom-adjust-input"
      className={className}
      value={value?.toString() || ''}
      onChange={(newValue) => {
        const valueOrNull = newValue === '' ? null : numInputStrStrip(newValue);
        setValue(valueOrNull);

        onChange({
          path: propertyStatePath,
          value: valueOrNull === null ? valueOrNull : Number(valueOrNull),
          field: FIELD,
        });
      }}
      maxLength={2}
    />
  );
};

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <FilterRange
      className={className}
      disabled={disabled}
      maxVal={999}
      initialValue={{
        min: spatialFiltersInput.minBeds
          ? formatNumber(spatialFiltersInput.minBeds)
          : '',
        max: spatialFiltersInput.maxBeds
          ? formatNumber(spatialFiltersInput.maxBeds)
          : '',
      }}
      onBlur={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minBeds: value.min === '' ? null : Number(value.min),
            maxBeds: value.max === '' ? null : Number(value.max),
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const BEDROOMS_CONFIG: PropertyStateFieldConfig<typeof FIELD> = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  labelMicro,
  formatValueShort,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.Beds,
};
