import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useClickOutsideComponent } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';
import { ReportFeatures, ReportId, ValuationMethod } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';
import { FIXED_QUERY_SELECTOR } from '@hcs/webapps';

import { ValueSelector } from '../../components-deprecated';
import { AdjustedAvmSelectorItem } from '../../features/ReportValueSelectorDeprecated/AdjustedAvmSelectorItem';
import { useSubjectValueDocument } from '../../hooks';
import { useLegacyCompValues } from '../../hooks/useLegacyCompValues';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import { AdjustedCompBasedAvgSelectorItem } from './AdjustedCompBasedAvgSelectorItem';
import { AvmSelectorItem } from './AvmSelectorItem';
import { ComparableValueSelectorItem } from './ComparableValueSelectorItem';
import { UserValueSelectorItem } from './UserValueSelectorItem';

import styles from './ReportValueSelector.module.css';

export interface ReportValueSelectorProps {
  reportId: ReportId;
  showRentalValue: boolean;
  className?: string;
  onClickSelectComps: VoidFunction;
  onClickProvideLocation: NavigateToCompSelectionDrawFn;
}

const dataHcNameRaw = 'report-value-selector';
export const REPORT_VALUE_SELECTOR_FEATURES_SALE = [
  ReportFeatures.HcAvm,
  ReportFeatures.ComparableValue,
  ReportFeatures.UserOpinionOfPrice,
  ReportFeatures.AdjustedAvm,
];
export const REPORT_VALUE_SELECTOR_FEATURES_RENTAL = [
  ReportFeatures.RentalHcAvm,
  ReportFeatures.RentalComparableValue,
  ReportFeatures.RentalUserOpinionOfPrice,
];
export const ReportValueSelector = ({
  reportId,
  showRentalValue,
  className,
  onClickSelectComps,
  onClickProvideLocation,
}: ReportValueSelectorProps) => {
  const compType = showRentalValue ? CompTypes.Rental : CompTypes.Sold;
  const dataHcName = showRentalValue
    ? `rental-${dataHcNameRaw}`
    : dataHcNameRaw;
  const [open, setOpen] = useState(false);
  // TODO: Post Phase III - Remove this temporary comp value hook
  const { data: legacyCompValues } = useLegacyCompValues(reportId, compType);
  const { data: avmDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });
  const refContainer = useRef(null);
  const elm = document.querySelector(FIXED_QUERY_SELECTOR);
  useClickOutsideComponent(refContainer, () => setOpen(false));

  if (!elm) return null;
  if (!avmDocument || !legacyCompValues) {
    return <div data-hc-name={`${dataHcName}-skeleton`}>Loading...</div>;
  }
  const selected = avmDocument.data.selectedValue;
  const { isCompAvgSelected, isCompValueSelected } = legacyCompValues;

  return (
    <div data-hc-name={dataHcName} className={className}>
      <ValueSelector
        dataHcName={`${dataHcName}-value-selector`}
        onClick={() => setOpen(!open)}
      />
      {open &&
        createPortal(
          <div
            className={styles.Popover}
            ref={refContainer}
            data-hc-name={`${dataHcName}-popover`}
          >
            <div className={styles.PopoverAccent} />
            <div className={styles.AVMSelectorItem}>
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[
                  showRentalValue
                    ? ReportFeatures.RentalHcAvm
                    : ReportFeatures.HcAvm,
                ]}
              >
                {selected !== ValuationMethod.Avm && (
                  <AvmSelectorItem
                    reportId={reportId}
                    showRentalValue={showRentalValue}
                    onClose={() => {
                      setOpen(false);
                    }}
                  />
                )}
              </ReportFeaturesSupported>
              {!showRentalValue && (
                <ReportFeaturesSupported
                  reportId={reportId}
                  reportFeatures={[ReportFeatures.AdjustedAvm]}
                >
                  <AdjustedAvmSelectorItem
                    showRentalValue={false}
                    reportId={reportId}
                    onClose={() => {
                      setOpen(false);
                    }}
                  />
                </ReportFeaturesSupported>
              )}
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[
                  showRentalValue
                    ? ReportFeatures.RentalComparableValue
                    : ReportFeatures.ComparableValue,
                ]}
              >
                <>
                  {!isCompValueSelected && (
                    <ComparableValueSelectorItem
                      reportId={reportId}
                      showRentalValue={showRentalValue}
                      onClose={() => setOpen(false)}
                      onClickSelectComps={onClickSelectComps}
                      onClickProvideLocation={onClickProvideLocation}
                    />
                  )}
                  {!isCompAvgSelected && (
                    <AdjustedCompBasedAvgSelectorItem
                      reportId={reportId}
                      showRentalValue={showRentalValue}
                      onClose={() => setOpen(false)}
                      onClickSelectComps={onClickSelectComps}
                      onClickProvideLocation={onClickProvideLocation}
                    />
                  )}
                </>
              </ReportFeaturesSupported>
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[
                  showRentalValue
                    ? ReportFeatures.RentalUserOpinionOfPrice
                    : ReportFeatures.UserOpinionOfPrice,
                ]}
              >
                <UserValueSelectorItem
                  reportId={reportId}
                  showRentalValue={showRentalValue}
                  onClose={() => setOpen(false)}
                />
              </ReportFeaturesSupported>
            </div>
          </div>,
          elm,
        )}
    </div>
  );
};
