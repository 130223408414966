import React from 'react';

import { MultiSelect, Option } from '@hcs/design-system';
import { FormError } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyTypeEnum } from '@hcs/types';
import { CompTypes, PropertyStateFields, PropertyStateType } from '@hcs/types';
import {
  CompFiltersAll,
  CompsFiltersPaths,
  FILTER_MATCH_SUBJECT,
  ReportId,
} from '@hcs/types';
import { formatPropertyType } from '@hcs/utils';

import { FilterCard } from '../../components-deprecated';
import { QuickFilter, QuickFilterButton } from '../../components-deprecated';
import { useCompsFiltersDocument, useSubjectDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import { CompFilterClearButton } from './CompFilterClearButton';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
}
const PROPERTY_TYPE_OPTIONS: Option<PropertyTypeEnum>[] = [
  {
    value: PropertyTypeEnum.Sfr,
    label: formatPropertyType(PropertyTypeEnum.Sfr),
  },
  {
    value: PropertyTypeEnum.Condo,
    label: formatPropertyType(PropertyTypeEnum.Condo),
  },
  {
    value: PropertyTypeEnum.Townhouse,
    label: formatPropertyType(PropertyTypeEnum.Townhouse),
  },
  {
    value: PropertyTypeEnum.MultiFamily,
    label: formatPropertyType(PropertyTypeEnum.MultiFamily),
  },
  {
    value: PropertyTypeEnum.Manufactured,
    label: formatPropertyType(PropertyTypeEnum.Manufactured),
  },
];

const FIELD = PropertyStateFields.propertyType;
const PATH_FILTER: CompsFiltersPaths = `/data/filters/${FIELD}`;
const PATH_FIELD: CompsFiltersPaths = `${PATH_FILTER}/field`;
const PATH_ABS: CompsFiltersPaths = `${PATH_FILTER}/absoluteValue`;
const PATH_REL: CompsFiltersPaths = `${PATH_FILTER}/relativeValue`;
const dataHcName = 'comp-filter-property-type';
export const CompFilterPropertyType = ({
  reportId,
  compType,
  className,
}: Props) => {
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: filterDocument } = useCompsFiltersDocument(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  if (!filterDocument || !subjectDocument || !reportPermissions) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const filterValue =
    filterDocument.data.filters?.[PropertyStateFields.propertyType];
  const subjectValue = getPropertyStateFieldValue(
    PropertyStateFields.propertyType,
    {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectDocument.data.propertyState,
    },
  );

  const handleChangeRelativeValue = (
    relativeValue: CompFiltersAll[PropertyStateFields.propertyType]['relativeValue'],
  ) => {
    documentPatchMutation.mutate({
      reportId,
      document: filterDocument,
      operations: [
        {
          op: 'add',
          path: PATH_REL,
          value: relativeValue,
        },
        {
          op: 'add',
          path: PATH_FIELD,
          value: FIELD,
        },
      ],
    });
  };

  return (
    <FilterCard
      label="Property Type"
      info={
        filterValue?.error?.field === 'absoluteValue' ? (
          <FormError
            dataHcName={`${dataHcName}-not-savable`}
            value="Cannot apply the saved filter because the subject value is missing"
          />
        ) : subjectValue === null ? (
          'Missing Subject Value'
        ) : (
          `Subject: ${formatPropertyType(subjectValue)}`
        )
      }
      className={className}
      dataHcName={dataHcName}
      clearFilterButton={
        <CompFilterClearButton
          reportId={reportId}
          compType={compType}
          compField={PropertyStateFields.propertyType}
        />
      }
    >
      {subjectValue && (
        <QuickFilter dataHcName={`${dataHcName}-quick-filter`}>
          <QuickFilterButton
            label="Match to Subject"
            active={filterValue?.relativeValue === FILTER_MATCH_SUBJECT}
            dataHcName={`${dataHcName}-quick-filter-btn-0`}
            onClick={() => handleChangeRelativeValue(FILTER_MATCH_SUBJECT)}
          />
        </QuickFilter>
      )}
      <MultiSelect
        dataHcName={`${dataHcName}-select`}
        value={filterValue?.absoluteValue || []}
        options={PROPERTY_TYPE_OPTIONS}
        disabled={!reportPermissions.isEditable}
        onChange={(value: PropertyTypeEnum[]) => {
          if (value.length === 0) {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'remove',
                  path: PATH_FILTER,
                },
              ],
            });
          } else {
            documentPatchMutation.mutate({
              reportId,
              document: filterDocument,
              operations: [
                {
                  op: 'add',
                  path: PATH_ABS,
                  value,
                },
                {
                  op: 'add',
                  path: PATH_FIELD,
                  value: FIELD,
                },
              ],
            });
          }
        }}
      />
    </FilterCard>
  );
};
