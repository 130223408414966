import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Operation } from 'fast-json-patch';

import { CerberusStatsFilters } from '@hcs/types';
import { jsonPatchApplyEnhanced, logException } from '@hcs/utils';

import { HuellApi } from '../api';

import { QUERY_KEY_CERBERUS_STATS_FILTERS_USER } from './useCerberusStatsFiltersForUser';

export const usePatchCerberusStatsFiltersForUser = () => {
  const queryClient = useQueryClient();
  const patchCerberusStatsFilterMutation = useMutation(
    async (operations: Operation[]) => {
      return await HuellApi.patchCerberusStatsFiltersForUser(operations);
    },
    {
      // When mutate is called:
      onMutate: async (operations) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          QUERY_KEY_CERBERUS_STATS_FILTERS_USER,
        ]);

        // Snapshot the previous value
        const previousFilters = queryClient.getQueryData<CerberusStatsFilters>([
          QUERY_KEY_CERBERUS_STATS_FILTERS_USER,
        ]);

        const newValue = jsonPatchApplyEnhanced(
          previousFilters || {},
          operations,
        )[0]?.newDocument;

        if (newValue) {
          // Optimistically update to the new value
          queryClient.setQueryData<CerberusStatsFilters>(
            [QUERY_KEY_CERBERUS_STATS_FILTERS_USER],
            newValue,
          );
        } else {
          logException(
            new Error(
              'usePatchCerberusStatsFiltersForUser: json patch newDocument was undefined',
            ),
          );
        }

        // Return a context object with the snapshotted value
        return { previousFilters };
      },
    },
  );
  return {
    ...patchCerberusStatsFilterMutation,
    mutate: patchCerberusStatsFilterMutation.mutate,
  };
};
