import { Fragment } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { useIsLoggedIn } from '@hcs/authn';
import { Button } from '@hcs/design-system';
import { CardBadge } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { useExperienceFlag } from '@hcs/experience-flags';
import {
  COMP_FIELDS_CONFIG,
  PROPERTY_STATE_FIELD_CONFIGS,
} from '@hcs/property-state';
import { isCompField } from '@hcs/property-state';
import {
  CompFields,
  CompTypes,
  MeaningfulEventTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import {
  CompIdentifier,
  IncludeHcAdjustments,
  ReportFeatures,
  ReportId,
  ValuationMethodLabel,
  ValuationMethodLabelRental,
} from '@hcs/types';
import { NavigateToPropertyPreviewFn } from '@hcs/types';

import { UserPropertyAdjustedBadge } from '../../components-deprecated';
import {
  APPRAISAL_COMP_DATA_SOURCES_LAUNCHER_FEATURES,
  AppraisalCompDataSourcesLauncher,
} from '../../features/AppraisalCompDataSources/AppraisalCompDataSourcesLauncher';
import { useComp, useReportConfig } from '../../hooks';
import { usePropertyStateFieldsSupported } from '../../hooks/usePropertyStateFieldsSupported';
import { checkIsHcAdjustment } from '../../utils';
import {
  COMP_PUBLIC_REMARKS_FEATURES_RENTAL,
  COMP_PUBLIC_REMARKS_FEATURES_SALE,
  CompPublicRemarks,
} from '../CompPublicRemarks';
import {
  COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_RENTAL,
  COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_SALE,
  CompTransactionHistoryLauncher,
} from '../CompTransactionHistory/CompTransactionHistoryLauncher';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';
import { CompPropertyCard } from '..';

import { CompCompareChangeHandler } from './CompCompare.types';
import { CompCompareRow } from './CompCompareRow';

import styles from './CompCompare.module.css';

interface CompCompareColumnProps {
  colIndex: number;
  reportId: ReportId;
  compIdentifier: CompIdentifier;
  navigateToPropertyPreview: NavigateToPropertyPreviewFn;
  isAdjusting: boolean;
  className?: string;
  fields: (PropertyStateFields | CompFields)[];
  onErrorChange: (field: PropertyStateFields, error?: string) => void;
  onChangeAdjustment: CompCompareChangeHandler;
  includeHcAdjustments: IncludeHcAdjustments;
}

const dataHcName = 'comp-compare-col';
export const COMP_COMPARE_COLUMN_FEATURES_SALE = [ReportFeatures.CompsSelect];
export const COMP_COMPARE_COLUMN_FEATURES_RENTAL = [
  ReportFeatures.RentalCompsSelect,
];

const CardBadges = ({
  reportId,
  compIdentifier,
  handleNavigateToPropertyPreview,
}: Pick<CompCompareColumnProps, 'reportId' | 'compIdentifier'> & {
  handleNavigateToPropertyPreview: VoidFunction;
}) => {
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const dataHcEventNameLookupProperty = isRental
    ? 'Pull Report for Rental Comp'
    : 'Pull Report for Comp';
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return (
    <>
      {isLoggedIn && (
        <CardBadge
          dataHcName={`${dataHcName}-pull-report`}
          dataHcEventName={dataHcEventNameLookupProperty}
          dataHcEventType={MeaningfulEventTypes.Goal}
          onClick={handleNavigateToPropertyPreview}
          clickable
        >
          Lookup Property
        </CardBadge>
      )}
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={
          isRental
            ? COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_RENTAL
            : COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_SALE
        }
      >
        <CompTransactionHistoryLauncher
          reportId={reportId}
          compIdentifier={compIdentifier}
          trigger={
            <CardBadge
              dataHcName={`${dataHcName}-badge-transaction-history`}
              clickable
            >
              Transaction History
            </CardBadge>
          }
        />
      </ReportFeaturesSupported>
      {compIdentifier.type === 'appraisalComp' && (
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental ? [] : APPRAISAL_COMP_DATA_SOURCES_LAUNCHER_FEATURES
          }
        >
          <AppraisalCompDataSourcesLauncher
            reportId={reportId}
            compIdentifier={compIdentifier}
            trigger={
              <CardBadge
                dataHcName={`${dataHcName}-badge-appraisal-comp-data-sources`}
                clickable
              >
                Sources
              </CardBadge>
            }
          />
        </ReportFeaturesSupported>
      )}
    </>
  );
};

const RowBadge = ({
  reportId,
  compField,
  compIdentifier,
  colIndex,
}: Pick<CompCompareColumnProps, 'reportId' | 'compIdentifier'> & {
  colIndex: number;
  compField: CompFields | PropertyStateFields;
}) => {
  const compQuery = useComp(reportId, compIdentifier);
  const { userPropertyAdjustments } = compQuery.data || {};
  if (!isCompField(compField)) {
    const fieldAdjustments = userPropertyAdjustments?.adjustments?.[compField];
    if (fieldAdjustments?.currentValue !== fieldAdjustments?.sourceValue) {
      const { Display, label } = PROPERTY_STATE_FIELD_CONFIGS[compField];
      return (
        <UserPropertyAdjustedBadge
          dataHcName={`${dataHcName}-${colIndex}-${compField}`}
          label={label}
          value={
            <Display
              key={`subject-col-${compField}`}
              propertyStateArgs={{
                propertyStateType: 'flat',
                propertyState: {
                  [compField]:
                    userPropertyAdjustments?.adjustments?.[compField]
                      ?.sourceValue,
                },
              }}
            />
          }
        />
      );
    }
  }
  return null;
};

const RowContent = ({
  reportId,
  compField,
  compIdentifier,
  isAdjusting,
  onChangeAdjustment,
  onErrorChange,
}: Pick<
  CompCompareColumnProps,
  | 'reportId'
  | 'compIdentifier'
  | 'isAdjusting'
  | 'onChangeAdjustment'
  | 'onErrorChange'
> & {
  compField: CompFields | PropertyStateFields;
}) => {
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const compQuery = useComp(reportId, compIdentifier);
  const { compSchema, documentId, userPropertyAdjustments } =
    compQuery.data || {};

  const hasAdjustments = !isEmpty(userPropertyAdjustments?.adjustments);
  const reportConfigQuery = useReportConfig(reportId);
  const { data: reportConfig } = reportConfigQuery;

  if (isCompField(compField)) {
    const { Display, Edit } = COMP_FIELDS_CONFIG[compField];
    const isAdjustingRow = isAdjusting && Edit && documentId;
    return isAdjustingRow ? (
      <Edit
        comp={compSchema}
        onChange={(changeArg) =>
          onChangeAdjustment({
            changeArg,
            documentId,
            type: 'compField',
          })
        }
        className={styles.Input}
      />
    ) : (
      <Display comp={compSchema} />
    );
  } else {
    const propertyStateArgs: PropertyStateArgsCore = {
      propertyStateType: PropertyStateType.Core,
      propertyState: compSchema?.propertyState,
    };
    const { Display, Edit } = PROPERTY_STATE_FIELD_CONFIGS[compField];
    const isAdjustingRow =
      compField === PropertyStateFields.currentStatus
        ? isAdjusting &&
          Edit &&
          documentId &&
          !!reportConfig?.reportFeaturesSupport?.[
            ReportFeatures.CompsEditListingStatus
          ]
        : isAdjusting && Edit && documentId;

    return isAdjustingRow && Edit && documentId ? (
      <Edit
        key={`edit-subject-col-${compField}`}
        propertyStateArgs={propertyStateArgs}
        className={styles.Input}
        onErrorChange={(errorMessage?: string) => {
          onErrorChange(compField, errorMessage);
        }}
        onChange={(changeArg) =>
          onChangeAdjustment({
            changeArg,
            documentId,
            type: 'propertyState',
          })
        }
      />
    ) : (
      <span className={styles.FieldContainer}>
        <span className={styles.DisplayContainer}>
          {compField === PropertyStateFields.currentValue ||
          compField === PropertyStateFields.currentValueRental ? (
            <Tooltip
              dataHcName={`${dataHcName}-current-value-tooltip`}
              trigger={<Display propertyStateArgs={propertyStateArgs} />}
              label={
                isRental
                  ? ValuationMethodLabelRental.avm
                  : hasAdjustments
                    ? ValuationMethodLabel.adjustedAvm
                    : ValuationMethodLabel.avm
              }
            />
          ) : (
            <Display propertyStateArgs={propertyStateArgs} />
          )}
        </span>
      </span>
    );
  }
};
export const CompCompareColumn = ({
  compIdentifier,
  className,
  fields,
  colIndex,
  reportId,
  navigateToPropertyPreview,
  isAdjusting,
  onChangeAdjustment,
  includeHcAdjustments,
  onErrorChange,
}: CompCompareColumnProps) => {
  const experienceFlag = useExperienceFlag('PEXP_REDESIGN');
  const isRental = compIdentifier.compType === CompTypes.Rental;
  const dataHcEventNameLookupProperty = isRental
    ? 'Pull Report for Rental Comp'
    : 'Pull Report for Comp';
  const propertyFields = usePropertyStateFieldsSupported(reportId, fields);
  const reportConfigQuery = useReportConfig(reportId);
  const { data: reportConfig } = reportConfigQuery;
  const compQuery = useComp(reportId, compIdentifier);
  const documentId = compQuery.data?.documentId;
  const compSchema = compQuery.data?.compSchema;
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  const handleNavigateToPropertyPreview = () => {
    if (compSchema?.propertyState.hcAddressId) {
      navigateToPropertyPreview(
        {
          hcAddressId: compSchema?.propertyState.hcAddressId,
        },
        true,
      );
    }
  };
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CompareCol, className)}
    >
      {compQuery.isInitialLoading ? (
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />
      ) : !compSchema ? (
        <div data-hc-name={`${dataHcName}-error`}>Could Not Retrieve Comp</div>
      ) : (
        <>
          <div
            className={classNames(styles.CardCell, {
              [styles.cardCellLegacy]: experienceFlag,
              [styles.cardCellLegacyAdjusting]: experienceFlag && isAdjusting,
            })}
          >
            <CompPropertyCard
              reportId={reportId}
              className={styles.CompPropertyCard}
              compIdentifier={compIdentifier}
              clickToCompare={false}
              showPrice={false}
              badges={
                <CardBadges
                  reportId={reportId}
                  compIdentifier={compIdentifier}
                  handleNavigateToPropertyPreview={
                    handleNavigateToPropertyPreview
                  }
                />
              }
              noDetails
              noHighlight
              flat
            />
          </div>
          <div className={styles.Fields}>
            {propertyFields.map((compField) => {
              const { isHcAdjustmentField, isHcDateAdjustment } =
                checkIsHcAdjustment(compField);
              const isSelectedAdjustmentField =
                (isHcDateAdjustment && includeHcAdjustments.hcAdjustmentDate) ||
                (isHcAdjustmentField &&
                  !isHcDateAdjustment &&
                  includeHcAdjustments.hcAdjustmentPropertyDetails);
              const classNameAdjustment = classNames({
                [styles.selectedAdjustment]: isSelectedAdjustmentField,
              });
              const { Edit } = isCompField(compField)
                ? COMP_FIELDS_CONFIG[compField]
                : PROPERTY_STATE_FIELD_CONFIGS[compField];
              const isAdjustingRow =
                compField === PropertyStateFields.currentStatus
                  ? !!Edit &&
                    !!documentId &&
                    isAdjusting &&
                    !!reportConfig?.reportFeaturesSupport?.[
                      ReportFeatures.CompsEditListingStatus
                    ]
                  : !!Edit && !!documentId && isAdjusting;

              return (
                <Fragment key={`row-${colIndex}-${compField}`}>
                  <CompCompareRow
                    className={classNameAdjustment}
                    isAdjusting={isAdjustingRow}
                    badge={
                      <RowBadge
                        reportId={reportId}
                        compField={compField}
                        compIdentifier={compIdentifier}
                        colIndex={colIndex}
                      />
                    }
                  >
                    <RowContent
                      reportId={reportId}
                      compField={compField}
                      compIdentifier={compIdentifier}
                      isAdjusting={isAdjusting}
                      onChangeAdjustment={onChangeAdjustment}
                      onErrorChange={onErrorChange}
                    />
                  </CompCompareRow>
                </Fragment>
              );
            })}

            <ReportFeaturesSupported
              reportId={reportId}
              reportFeatures={
                isRental
                  ? COMP_PUBLIC_REMARKS_FEATURES_RENTAL
                  : COMP_PUBLIC_REMARKS_FEATURES_SALE
              }
            >
              <CompCompareRow>
                <CompPublicRemarks
                  reportId={reportId}
                  compIdentifier={compIdentifier}
                />
              </CompCompareRow>
            </ReportFeaturesSupported>
          </div>
        </>
      )}
      <div className={styles.ColumnActions}>
        <div className={styles.ColumnAction}>
          {isLoggedIn && (
            <Button
              dataHcName={`${dataHcName}-lookup-btn`}
              dataHcEventName={dataHcEventNameLookupProperty}
              dataHcEventType={MeaningfulEventTypes.Goal}
              className={styles.LookupButton}
              onClick={handleNavigateToPropertyPreview}
              primary
            >
              Lookup Property
            </Button>
          )}
        </div>
        <ReportFeaturesSupported
          reportId={reportId}
          reportFeatures={
            isRental
              ? COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_RENTAL
              : COMP_TRANSACTION_HISTORY_LAUNCHER_FEATURES_SALE
          }
        >
          <div className={styles.ColumnAction}>
            <CompTransactionHistoryLauncher
              reportId={reportId}
              compIdentifier={compIdentifier}
            />
          </div>
        </ReportFeaturesSupported>
      </div>
    </div>
  );
};
