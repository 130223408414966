import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrderViaCsv } from '@hcs/types';

import { NewOrderApi, OrderSubmitCsvError } from '../api/newOrderApi';

import { QUERY_KEY_ORDERS } from './useOrders';

interface Params {
  addOrderWithFile: OrderViaCsv;
}

export const useNewOrderSubmitCsv = () => {
  const queryClient = useQueryClient();
  return useMutation<void, OrderSubmitCsvError, Params>(
    async ({ addOrderWithFile }) =>
      await NewOrderApi.newOrderSubmitCsv(addOrderWithFile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_ORDERS]);
      },
    },
  );
};
