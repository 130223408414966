import { AxiosClient } from '@hcs/http-clients';
import { CapabilitiesResponse } from '@hcs/types';
import { ACCOUNT_URL } from '@hcs/urls';

export const HcProductsApi = {
  fetchDexpEntitlementCapabilities: async ({
    entitlementKey,
  }: {
    entitlementKey: string;
  }) => {
    const response = await AxiosClient.get<CapabilitiesResponse>(
      `${ACCOUNT_URL}/products/dexp/entitlements/${entitlementKey}/capabilities`,
    );
    return response.data;
  },
};
