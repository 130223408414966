import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatBoolYesNoUnknown, formatNumber, NULL_VALUE } from '@hcs/utils';

const FIELD = PropertyStateFields.parking;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Parking';
const labelShort = 'Parking';
const propertyStatePath: PropertyStatePaths = '/propertyDetails/parking';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.propertyDetails?.parking;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) => {
  const parking = getValue(propertyStateArgs);
  if (parking) {
    const results = [];
    if (parking.total != null) {
      results.push(formatBoolYesNoUnknown(!!parking.total));
      results.push(`Count: ${parking.total}`);
    }
    if (parking.parkingDetails) {
      results.push(
        `Descriptions: ${parking.parkingDetails
          .map((details) => `${details?.type}: ${formatNumber(details?.count)}`)
          .join(',')}`,
      );
    }
    return results.length > 0 ? results.join('; ') : NULL_VALUE;
  } else {
    return NULL_VALUE;
  }
};

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)} Spaces`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const PARKING_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
};
