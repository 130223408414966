import { differenceInDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  PropertyStateCore,
  PropertyStateFields,
  PropertyStateFlat,
  PropertyStateType,
} from '@hcs/types';
import {
  AppraisalCompDataSourcesDocument,
  AppraisalCompDocument,
  CompDocument,
  DataVerification,
  RentalCompDocument,
  RiskFactorsComparables,
  RiskFactorsCompProperty,
  RiskFactorsValue,
  RiskLevel,
  RiskSchema,
  RiskSummaryProperty,
  SimilarityLevel,
  SubjectValueSchema,
  ValuationMethod,
} from '@hcs/types';
import { parseDateString } from '@hcs/utils';

import { APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS } from '../constants/appraisalReview.constants';

interface ComparisonInputs {
  // Comps
  appraisalCompDocs: AppraisalCompDocument[];
  appraisalCompsDataSourcesDocs: AppraisalCompDataSourcesDocument[];
  compDocs: (CompDocument | RentalCompDocument)[];
  // Value
  hcSubjectValue: SubjectValueSchema | undefined;
  appraisalSubjectValue: SubjectValueSchema | undefined;
  // Subject
  hcSubject: PropertyStateCore | undefined;
  appraisalSubject: PropertyStateCore | undefined;
}

const makeDataVerificationObj = <F extends PropertyStateFields>({
  propertyStateField,
  appraisal,
  hc,
}: {
  propertyStateField: F;
  appraisal: PropertyStateFlat[F];
  hc: PropertyStateFlat[F];
}): DataVerification | undefined => {
  let hasDiscrepancy = hc !== appraisal;
  const { propertyStatePath } =
    PROPERTY_STATE_FIELD_CONFIGS[propertyStateField];
  if (Array.isArray(hc) || Array.isArray(appraisal)) {
    const hcCompare = Array.isArray(hc) ? hc.join(',') : hc;
    const appraisalCompare = Array.isArray(appraisal)
      ? appraisal.join(',')
      : appraisal;
    hasDiscrepancy = hcCompare !== appraisalCompare;
  }
  if (hasDiscrepancy) {
    return {
      [propertyStateField]: {
        appraisal: appraisal === null ? undefined : appraisal,
        hc: hc,
        mls: hc,
        pr: hc,
        path: propertyStatePath,
      },
    };
  }
  return undefined;
};

const verifyPropertyState = (
  hc: PropertyStateCore | undefined,
  appraisal: PropertyStateCore | undefined,
): DataVerification => {
  let dataVerification: DataVerification = {};
  APPRAISAL_REVIEW_PROPERTY_STATE_FIELDS.forEach((propertyStateField) => {
    const { getValue } = PROPERTY_STATE_FIELD_CONFIGS[propertyStateField];
    const diff = makeDataVerificationObj({
      propertyStateField,
      hc: getValue({
        propertyStateType: PropertyStateType.Core,
        propertyState: hc,
      }),
      appraisal: getValue({
        propertyStateType: PropertyStateType.Core,
        propertyState: appraisal,
      }),
    });
    if (diff) {
      dataVerification = { ...dataVerification, ...diff };
    }
  });
  return dataVerification;
};

const computeCompPropertyRisk = (
  comp: AppraisalCompDocument | CompDocument,
  dataSources: AppraisalCompDataSourcesDocument | undefined,
  hcComps: ComparisonInputs['compDocs'],
): RiskSummaryProperty<RiskFactorsCompProperty> => {
  const similarityLevel = comp.data.similarity.levelAdjusted;
  const riskLevel: RiskLevel =
    similarityLevel === SimilarityLevel.High
      ? 'LOW_RISK'
      : similarityLevel === SimilarityLevel.Moderate
        ? 'RISK'
        : 'HIGH_RISK';
  const distance = comp.data.distance;
  const riskFactors: RiskFactorsCompProperty[] = [
    similarityLevel === SimilarityLevel.High
      ? { riskFactor: 'COMP_SIMILARITY_HIGH', riskLevel: 'LOW_RISK' }
      : similarityLevel === SimilarityLevel.Moderate
        ? { riskFactor: 'COMP_SIMILARITY_MODERATE', riskLevel: 'RISK' }
        : { riskFactor: 'COMP_SIMILARITY_LOW', riskLevel: 'HIGH_RISK' },
  ];

  // HC Comp
  if (
    hcComps.find((d) => {
      const idA = d.data.propertyState.hcAddressId;
      const idB = comp.data.propertyState.hcAddressId;
      return idA && idB && idA === idB;
    })
  ) {
    riskFactors.push({ riskFactor: 'IS_HC_COMP', riskLevel: 'LOW_RISK' });
  }

  // Distance
  if (distance != null) {
    if (distance < 0.5) {
      riskFactors.push({
        riskFactor: 'COMP_DISTANCE_HALF_MILE',
        riskLevel: 'LOW_RISK',
      });
    } else if (distance <= 1) {
      riskFactors.push({ riskFactor: 'COMP_DISTANCE_MILE', riskLevel: 'RISK' });
    } else if (distance > 1) {
      riskFactors.push({
        riskFactor: 'COMP_DISTANCE_FAR',
        riskLevel: 'HIGH_RISK',
      });
    }
  }

  // Transaction Date
  const currentStatusDate = getPropertyStateFieldValue(
    PropertyStateFields.currentStatusDate,
    {
      propertyState: comp.data.propertyState,
      propertyStateType: PropertyStateType.Core,
    },
  );
  if (currentStatusDate != null) {
    const now = new Date();
    const diff = differenceInDays(now, parseDateString(currentStatusDate));
    if (diff < 180) {
      riskFactors.push({
        riskFactor: 'COMP_TRANSACTION_HALF_YEAR',
        riskLevel: 'LOW_RISK',
      });
    } else if (diff <= 365) {
      riskFactors.push({
        riskFactor: 'COMP_TRANSACTION_YEAR',
        riskLevel: 'RISK',
      });
    } else if (diff > 365) {
      riskFactors.push({
        riskFactor: 'COMP_TRANSACTION_STALE',
        riskLevel: 'HIGH_RISK',
      });
    }
  }

  // Data Discrepancy
  const dataVerification = verifyPropertyState(
    dataSources?.data.hc,
    comp.data.propertyState,
  );
  if (isEmpty(dataVerification)) {
    riskFactors.push({
      riskFactor: 'COMP_DETAILS_MATCH',
      riskLevel: 'LOW_RISK',
    });
  }
  return { riskLevel, riskFactors, dataVerification };
};

export const computeComparablesRisk = ({
  appraisalCompDocs,
  compDocs,
  appraisalCompsDataSourcesDocs,
}: Pick<
  ComparisonInputs,
  'appraisalCompDocs' | 'compDocs' | 'appraisalCompsDataSourcesDocs'
>): RiskSchema['comparables'] => {
  const bySimAppraisal: { [key in SimilarityLevel]: number } = {
    [SimilarityLevel.High]: 0,
    [SimilarityLevel.Moderate]: 0,
    [SimilarityLevel.Low]: 0,
  };
  const bySimHc: { [key in SimilarityLevel]: number } = {
    [SimilarityLevel.High]: 0,
    [SimilarityLevel.Moderate]: 0,
    [SimilarityLevel.Low]: 0,
  };
  const propertyRisk: RiskSchema['comparables']['propertyRisk'] = {};
  const riskFactors: RiskFactorsComparables[] = [];
  let riskLevel: RiskLevel = 'LOW_RISK';
  let highSimAvailable = false;
  let moderateSimAvailable = false;
  compDocs.forEach((compDoc) => {
    const similarity = compDoc.data.similarity.levelAdjusted;
    if (similarity) {
      bySimHc[similarity]++;
    }
    if (similarity === SimilarityLevel.High) {
      highSimAvailable = true;
      moderateSimAvailable = true;
    } else if (similarity === SimilarityLevel.Moderate) {
      moderateSimAvailable = true;
    }
  });
  if (!moderateSimAvailable) {
    riskLevel = 'HIGH_RISK';
    riskFactors.push({
      riskFactor: 'NO_MODERATE_SIMILARITY_COMPS_AVAILABLE',
      riskLevel: 'HIGH_RISK',
    });
  } else if (!highSimAvailable) {
    riskLevel = 'RISK';
    riskFactors.push({
      riskFactor: 'NO_HIGH_SIMILARITY_COMPS_AVAILABLE',
      riskLevel: 'RISK',
    });
  } else if (
    bySimHc[SimilarityLevel.High] > 0 &&
    bySimHc[SimilarityLevel.High] < 8
  ) {
    riskLevel = 'RISK';
    riskFactors.push({
      riskFactor: 'FEW_HIGH_SIMILARITY_COMPS_AVAILABLE',
      riskLevel: 'RISK',
    });
  }

  let isHcCompsSelected = false;
  appraisalCompDocs.forEach((compDoc) => {
    const compId = compDoc.data.compID;
    const similarity = compDoc.data.similarity.levelAdjusted;
    if (similarity) bySimAppraisal[similarity]++;
    if (
      compDocs.find((d) => {
        const idA = d.data.propertyState.hcAddressId;
        const idB = compDoc.data.propertyState.hcAddressId;
        return idA && idB && idA === idB;
      })
    ) {
      isHcCompsSelected = true;
    }
    propertyRisk[compId] = computeCompPropertyRisk(
      compDoc,
      appraisalCompsDataSourcesDocs.find(
        (d) => d.data.hc.hcAddressId === compDoc.data.propertyState.hcAddressId,
      ),
      compDocs,
    );
  });

  if (isHcCompsSelected) {
    riskFactors.push({
      riskFactor: 'HC_COMP_SELECTED',
      riskLevel: 'LOW_RISK',
    });
  }
  if (
    bySimAppraisal[SimilarityLevel.High] &&
    !bySimAppraisal[SimilarityLevel.Moderate] &&
    !bySimAppraisal[SimilarityLevel.Low]
  ) {
    riskLevel = 'LOW_RISK';
    riskFactors.push({
      riskFactor: 'HIGH_SIMILARITY_COMPS_SELECTED',
      riskLevel: 'LOW_RISK',
    });
  } else if (!bySimAppraisal[SimilarityLevel.High] && highSimAvailable) {
    riskLevel = 'HIGH_RISK';
    riskFactors.push({
      riskFactor: 'NO_SIMILAR_COMPS_SELECTED',
      riskLevel: 'HIGH_RISK',
    });
  }
  return {
    riskLevel,
    riskFactors,
    propertyRisk,
  };
};

export const computeValueRisk = ({
  appraisalSubjectValue,
  hcSubjectValue,
  appraisalSubject,
  hcSubject,
}: ComparisonInputs): RiskSchema['value'] => {
  let riskLevel: RiskLevel = 'RISK';
  const riskFactors: RiskFactorsValue[] = [];

  // Value
  const appraisalValue = appraisalSubjectValue?.userValue.value.value || 0;
  const { value, valueLower, valueUpper } =
    hcSubjectValue?.[ValuationMethod.Avm].value || {};
  if (!value || !valueLower || !valueUpper) {
    riskLevel = 'HIGH_RISK';
    riskFactors.push({ riskFactor: 'AVM_UNAVAILABLE', riskLevel: 'HIGH_RISK' });
  } else if (appraisalValue > valueUpper) {
    riskLevel = 'HIGH_RISK';
    riskFactors.push({ riskFactor: 'VALUE_HIGH', riskLevel: 'HIGH_RISK' });
  } else if (appraisalValue < valueLower) {
    riskLevel = 'HIGH_RISK';
    riskFactors.push({ riskFactor: 'VALUE_LOW', riskLevel: 'HIGH_RISK' });
  } else {
    riskLevel = 'LOW_RISK';
    riskFactors.push({ riskFactor: 'VALUE_IN_RANGE', riskLevel: 'LOW_RISK' });
  }

  // Condition
  const conditionAppraisal = getPropertyStateFieldValue(
    PropertyStateFields.condition,
    {
      propertyStateType: PropertyStateType.Core,
      propertyState: appraisalSubject,
    },
  );
  const conditionHc = getPropertyStateFieldValue(
    PropertyStateFields.condition,
    {
      propertyStateType: PropertyStateType.Core,
      propertyState: hcSubject,
    },
  );
  if (conditionAppraisal === conditionHc) {
    riskFactors.push({
      riskFactor: 'SUBJECT_CONDITION_MATCH',
      riskLevel: 'LOW_RISK',
    });
  } else {
    riskFactors.push({
      riskFactor: 'SUBJECT_CONDITION_DISCREPANCY',
      riskLevel: 'RISK',
    });
  }

  // Property Details
  const dataVerification = verifyPropertyState(hcSubject, appraisalSubject);
  if (isEmpty(dataVerification)) {
    riskFactors.push({
      riskFactor: 'SUBJECT_DETAILS_MATCH',
      riskLevel: 'LOW_RISK',
    });
  } else {
    riskFactors.push({
      riskFactor: 'SUBJECT_DETAILS_DISCREPANCY',
      riskLevel: 'RISK',
    });
  }
  return {
    riskLevel,
    riskFactors,
    dataVerification,
  };
};

type RiskRow = [RiskLevel, RiskLevel, RiskLevel];
const OVERALL_RISK: [RiskRow, RiskRow, RiskRow] = [
  ['LOW_RISK', 'LOW_RISK', 'RISK'],
  ['RISK', 'RISK', 'RISK'],
  ['HIGH_RISK', 'HIGH_RISK', 'HIGH_RISK'],
];
const riskIndex: Record<RiskLevel, 0 | 1 | 2> = {
  LOW_RISK: 0,
  RISK: 1,
  HIGH_RISK: 2,
};

export const computeOverallRisk = (
  { riskLevel: valueRiskLevel }: RiskSchema['value'],
  { riskLevel: comparablesRiskLevel }: RiskSchema['comparables'],
) => {
  const riskIndexValue = riskIndex[valueRiskLevel];
  const riskIndexComps = riskIndex[comparablesRiskLevel];
  return OVERALL_RISK[riskIndexValue][riskIndexComps];
};

export const computeRiskSchema = (
  inputs: ComparisonInputs,
): Omit<RiskSchema, 'valueComparison'> => {
  const value = computeValueRisk(inputs);
  const comparables = computeComparablesRisk(inputs);
  return {
    overall: computeOverallRisk(value, comparables),
    value,
    comparables,
  };
};
