import { PayloadAction } from '@reduxjs/toolkit';

import {
  CompFields,
  CompFilterBarSliceState,
  PropertyStateFields,
} from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: CompFilterBarSliceState = {
  buttonsVisible: {},
  overflowVisible: false,
  overflowButtonVisible: false,
  overflowPortalId: null,
  overflowCount: 0,
};

export const compFilterBarSlice = createHcReduxSlice({
  name: 'compFilterBar',
  initialState,
  reducers: {
    buttonVisibilityChange(
      state,
      action: PayloadAction<{
        compField: PropertyStateFields | CompFields;
        visible: boolean;
      }>,
    ) {
      state.buttonsVisible[action.payload.compField] = action.payload.visible;
      state.overflowCount = (() => {
        let value = 0;
        Object.entries(state.buttonsVisible).forEach((entry) => {
          const visible = entry[1];
          if (!visible) {
            value++;
          }
        });
        return value;
      })();
    },
    overflowVisibilityChange(
      state,
      action: PayloadAction<{
        visible: boolean;
      }>,
    ) {
      state.overflowVisible = action.payload.visible;
    },
    overflowButtonVisibilityChange(
      state,
      action: PayloadAction<{
        visible: boolean;
      }>,
    ) {
      state.overflowButtonVisible = action.payload.visible;
    },
    overflowPortalMounted(
      state,
      action: PayloadAction<{
        portalId: string;
      }>,
    ) {
      state.overflowPortalId = action.payload.portalId;
    },
  },
});
