import React from 'react';
import classNames from 'classnames';

import { DotLabel } from '../../../notifications/DotLabel';

import { Legend, LegendTheme } from './ChartLegend.types';

import styles from './ChartLegend.module.css';

export interface ChartLegendProps {
  dataHcName: string;
  legendItems: Legend[];
  theme?: LegendTheme;
}

export const ChartLegend = ({
  dataHcName,
  legendItems,
  theme,
}: ChartLegendProps) => {
  const legendItemList = legendItems.map((legendItem: Legend) => {
    const dotBorder = legendItem.borderColor
      ? `rgb(${legendItem.borderColor.join(', ')})`
      : undefined;

    let dotColor = '';
    if (legendItem.background.type === 'gradient') {
      dotColor = `linear-gradient(${
        legendItem?.deg || ''
      }rgb(${legendItem.background.start.join(
        ', ',
      )}) 0%, rgb(${legendItem.background.end.join(', ')}) 100%)`;
    } else {
      dotColor = legendItem.background.color;
    }

    return (
      <div key={legendItem.id} className={styles.LegendItem}>
        <DotLabel
          dataHcName={`${dataHcName}-marker-label`}
          label={legendItem.title}
          dotColor={{
            colorType: 'custom',
            color: dotColor,
          }}
          isDotDashed={legendItem.isDotDashed}
          dotBorderColor={dotBorder}
          theme={theme?.DotLabel}
        />
      </div>
    );
  });

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.ChartLegendHorizontal, theme?.Container)}
    >
      {legendItemList}
    </div>
  );
};
