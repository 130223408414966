import { useState } from 'react';
import { useNavigate } from "react-router";
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { ItemsIcon } from '@hcs/design-system';
import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';

import styles from './SearchReportSelect.module.css';


export const SearchReportSelect = () => {
  const [active, updateActive] = useState('search');
  const navigate = useNavigate();

  return (
    <div className={styles.SearchReportSelect}>
      <Button
        dataHcName={`pexp-search-select`}
        primary={active === 'search'}
        secondary={active === 'history'}
        className={classNames({
          [styles.ButtonBorderless]: active === 'history'
        })}
        onClick={() => {
          updateActive('search');
          navigate(`${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SEARCH}`);
        }}
      >
        <HomeMagnifyingGlassIcon/>
        Search
      </Button>
      <Button
        dataHcName={`pexp-report-history-select`}
        primary={active === 'history'}
        secondary={active === 'search'}
        className={classNames({
          [styles.ButtonBorderless]: active === 'search'
        })}
        onClick={() => {
          updateActive('history');
          navigate(`${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`);
        }}
      >
        <ItemsIcon/>
        Report History
      </Button>
    </div>
  );
};
