import React, { ReactNode } from 'react';

import { useActiveState } from '@hcs/hooks';
import { PropertyStateLocation } from '@hcs/types';
import { StreetViewOptions } from '@hcs/types';

import { StreetViewButton } from '../../components/StreetViewButton';
import { useStreetView } from '../../hooks/useStreetView';
import { StreetViewDialog } from '../StreetViewDialog';

interface Props {
  dataHcName: string;
  dataHcEventSection?: string;
  location?: PropertyStateLocation['location'] | null;
  options?: StreetViewOptions;
  trigger?: ReactNode;
  onToggle?: (isStreetViewOpen: boolean) => void;
  theme?: {
    StreetViewLauncherButton?: string;
    Icon?: string;
    StreetViewLauncherDialog?: string;
  };
}

export const StreetViewLauncher = ({
  location,
  dataHcName,
  dataHcEventSection,
  onToggle,
  trigger,
  options,
  theme,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  const streetViewQuery = useStreetView(
    location?.latitude && location.longitude ? location : undefined,
    options,
  );
  const handleDialogOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onToggle?.(true);
    handleOpen();
  };
  const handleDialogClose = () => {
    onToggle?.(false);
    handleClose();
  };
  const disabled = !streetViewQuery.data && streetViewQuery.isSuccess;
  return (
    <>
      {trigger ? (
        <span
          data-hc-name={`${dataHcName}-button`}
          data-hc-event-section={dataHcEventSection}
          className={theme?.StreetViewLauncherButton}
          onClick={!disabled ? handleDialogOpen : undefined}
        >
          {trigger}
        </span>
      ) : (
        <StreetViewButton
          onClick={handleDialogOpen}
          dataHcEventSection={disabled ? undefined : dataHcEventSection}
          className={theme?.StreetViewLauncherButton}
          dataHcName={`${dataHcName}-button`}
          disabled={disabled}
        />
      )}
      <StreetViewDialog
        active={active}
        onClose={handleDialogClose}
        dataHcEventSection={disabled ? undefined : dataHcEventSection}
        location={location}
        className={theme?.StreetViewLauncherDialog}
        dataHcName={`${dataHcName}-dialog`}
      />
    </>
  );
};
