import React from 'react';
import classNames from 'classnames';

import { Toggle } from '@hcs/design-system';
import { HorizontalSelector } from '@hcs/design-system';
import { TabPopover } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { ComparisonIcon, GridIcon, ListIcon } from '@hcs/design-system';
import {
  CardViewIcon,
  CompareViewIcon,
  TableViewIcon,
} from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useActiveState } from '@hcs/hooks';
import {
  CompsListTypes,
  CompsListViewType,
  CompTypes,
  MeaningfulEventTypes,
  ReportId,
} from '@hcs/types';

import { COMPS_LIST_VIEW_TYPE_CONFIGS } from '../../../constants';
import { useCompDocuments } from '../../../hooks';
import { useCompsList } from '../../../hooks/useCompsList';

import styles from './CompsListViewTypeControl.module.css';

const CardConfig = COMPS_LIST_VIEW_TYPE_CONFIGS[CompsListViewType.Card];
const ListConfig = COMPS_LIST_VIEW_TYPE_CONFIGS[CompsListViewType.Table];
const CompareConfig = COMPS_LIST_VIEW_TYPE_CONFIGS[CompsListViewType.Compare];

interface Props {
  reportId: ReportId;
  dataHcEventSection?: string;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
}
const IconsByViewType = {
  [CompsListViewType.Card]: GridIcon,
  [CompsListViewType.Table]: ListIcon,
  [CompsListViewType.Compare]: ComparisonIcon,
};
const dataHcName = 'comp-list-view-toggle';
export const CompsListViewTypeControl = ({
  reportId,
  compType,
  className,
  dataHcEventSection,
  compsListType,
}: Props) => {
  const { active, setActiveState, handleClose } = useActiveState();
  const { data: compDocuments } = useCompDocuments(reportId, compType);

  const {
    state: { listViewType },
    actions: { compsListSetListViewType },
  } = useCompsList({ reportId, compType, compsListType });
  const options = [
    {
      label: (
        <>
          <CardConfig.Icon
            className={styles.Icon}
            size="sm"
            color="neutral-dark-20"
          />{' '}
          {CardConfig.name} View
        </>
      ),
      value: CardConfig.listViewType,
      disabled: false,
      dataHcEventName: 'Changed to Card View',
      dataHcEventType: MeaningfulEventTypes.Goal,
    },
    {
      label: (
        <>
          <ListConfig.Icon
            className={styles.Icon}
            size="sm"
            color="neutral-dark-20"
          />{' '}
          {ListConfig.name} View
        </>
      ),
      value: ListConfig.listViewType,
      dataHcEventName: 'Changed to List View',
      dataHcEventType: MeaningfulEventTypes.Goal,
      disabled: false,
    },
    {
      label: (
        <>
          <CompareConfig.Icon
            className={styles.Icon}
            size="sm"
            color="neutral-dark-20"
          />{' '}
          {CompareConfig.name} View
        </>
      ),
      value: CompareConfig.listViewType,
      disabled: false,
      dataHcEventName: 'Changed to Comparison View',
      dataHcEventType: MeaningfulEventTypes.Goal,
    },
  ];
  const optionsLegacy = [
    {
      label: <CompareViewIcon size="xs" dataHcName={`${dataHcName}-compare`} />,
      value: CompsListViewType.Compare,
      disabled: !compDocuments?.length,
    },
    {
      label: <CardViewIcon size="xs" dataHcName={`${dataHcName}-card`} />,
      value: CompsListViewType.Card,
      disabled: false,
    },
    {
      label: <TableViewIcon size="xs" dataHcName={`${dataHcName}-table`} />,
      value: CompsListViewType.Table,
      disabled: false,
    },
  ];
  const SelectedIcon = IconsByViewType[listViewType];
  const COMPS_LIST_HEADER_PADDING: [number, number, number, number] = [
    0, 0, 0, 0,
  ];
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REDESIGN"
      next={
        <TabPopover
          pagePadding={COMPS_LIST_HEADER_PADDING}
          dataHcEventSection={dataHcEventSection}
          active={active}
          dataHcName={dataHcName}
          onChangeActiveState={setActiveState}
          chevron="down"
          trigger={
            <Tooltip
              dataHcName={`${dataHcName}-tooltip`}
              trigger={<SelectedIcon height={24} width={24} />}
              label="Change View"
            />
          }
          content={
            <HorizontalSelector
              dataHcName={dataHcName}
              value={listViewType}
              className={classNames(
                styles.CompsListViewTypeControl,
                styles.HorizontalSelector,
                className,
              )}
              options={options}
              onSelect={(newValue) => {
                compsListSetListViewType(newValue);
                handleClose();
              }}
            />
          }
        />
      }
      now={
        <Toggle
          dataHcName={dataHcName}
          value={listViewType}
          className={className}
          options={optionsLegacy}
          onChange={compsListSetListViewType}
        />
      }
    />
  );
};
