import {
  GeoLocation,
  MarkerAnimationStep,
  MarkerOffset,
  Viewport,
} from '@hcs/types';

import {
  convertRMGLBoundsToMapBounds,
  convertViewportToRMGLBounds,
} from './conversion.utils';
import { isViewportInitialized } from './viewport.utils';

/**
 * an example: When a marker popup is opened, determine if and how much the map bounds need to change to
 * make space for the marker popup.
 */
export const getViewportToFitPaddingAroundMarker = ({
  viewState,
  markerGeoLocation: geoLocation,
  minVerticalBufferPx, // The minimum number of pixels needed at the top, bottom of the marker for the popup
  minRightBufferPx, // The minimum number of pixels needed at the right of the marker for the popup (assumes the popup will show up on the right)
}: {
  viewState?: Viewport;
  markerGeoLocation: GeoLocation;
  minVerticalBufferPx: number;
  minRightBufferPx: number;
}): Viewport | null => {
  if (!viewState || !viewState?.height || !viewState.width) {
    return null;
  }
  const reactGLMapBounds = convertViewportToRMGLBounds(viewState);
  if (
    reactGLMapBounds &&
    isViewportInitialized(viewState) &&
    geoLocation.latitude &&
    geoLocation.longitude
  ) {
    // mapBounds are just easier to work with vs. an array of arrays
    const mapBounds = convertRMGLBoundsToMapBounds(reactGLMapBounds);

    // The minimum percentage of the map viewport needed to the top and right of the marker for the popup
    const minRightBufferPercentage = minRightBufferPx / viewState.width;
    const minTopBufferPercentage = minVerticalBufferPx / viewState.height;

    // The amount of lat/lng that the property(marker) is from the top, bottom, and right
    // edges of the map viewport.
    const latitudeTopDiff = mapBounds.ne.lat - geoLocation?.latitude;
    const latitudeBottomDiff = geoLocation?.latitude - mapBounds.sw.lat;
    const longitudeRightDiff = mapBounds.ne.lng - geoLocation?.longitude;

    // Use the lat/lng values above to find the percentage of the map viewport that the
    // marker is from the top, bottom, and right edges
    const latitudeTopDiffPercentage =
      latitudeTopDiff / (mapBounds.ne.lat - mapBounds.sw.lat);
    const latitudeBottomDiffPercentage =
      latitudeBottomDiff / (mapBounds.ne.lat - mapBounds.sw.lat);
    const longitudeRightDiffPercentage =
      longitudeRightDiff / (mapBounds.ne.lng - mapBounds.sw.lng);

    /**
     * We know the minimum percentage of the viewport that the marker needs to be from the
     * top, right, and bottom edges. And above we found the percentage of the map viewport that IS
     * to the top, right, and bottom of the marker. Use the difference of those percentages to
     * find how much latitude to add/remove to the top/bottom and how much longitude to add to the right.
     */
    const latTopToMove =
      (minTopBufferPercentage - latitudeTopDiffPercentage) *
      (mapBounds.ne.lat - mapBounds.sw.lat);
    const latBottomToMove =
      (minTopBufferPercentage - latitudeBottomDiffPercentage) *
      (mapBounds.ne.lat - mapBounds.sw.lat);
    const lngToMove =
      (minRightBufferPercentage - longitudeRightDiffPercentage) *
      (mapBounds.ne.lng - mapBounds.sw.lng);

    const newLatitude =
      latTopToMove > 0
        ? viewState.latitude + latTopToMove
        : latBottomToMove > 0
          ? viewState.latitude - latBottomToMove
          : viewState.latitude;
    const newlongitude = viewState.longitude + (lngToMove > 0 ? lngToMove : 0);
    return {
      ...viewState,
      latitude: newLatitude,
      longitude: newlongitude,
    };
  }
  return null;
};

/**
 * Get an array of pixel offsets that visually create an Archimedean spiral
 */
export const getSpiralOffsets = (coordCnt: number): MarkerOffset[] => {
  const coords: MarkerOffset[] = [];
  let legLength = 20,
    angle = 0;

  for (let i = 0; i < coordCnt; i++) {
    angle = angle + (28 / legLength + i * 0.0005);
    coords.push({
      offsetTop: legLength * Math.cos(angle) - 55,
      offsetLeft: legLength * Math.sin(angle) - 5,
    });
    legLength = legLength + (Math.PI * 2 * 5) / angle;
  }

  return coords;
};

// Returns an array of pixel offsets for each marker that, depending on the number
// of markers, will produce a shape for sets of 2, 3, 4, and more than 4 markers
export const getMarkerOffsets = (markerCount: number): MarkerOffset[] => {
  if (markerCount === 2) {
    return [
      {
        offsetLeft: -25,
        offsetTop: 25,
      },
      {
        offsetLeft: 25,
        offsetTop: 25,
      },
    ];
  }

  if (markerCount === 3) {
    return [
      // top-left
      {
        offsetLeft: -25,
        offsetTop: -35,
      },
      // top-right
      {
        offsetLeft: 25,
        offsetTop: -35,
      },
      // bottom
      {
        offsetLeft: 0,
        offsetTop: 30,
      },
    ];
  }

  if (markerCount === 4) {
    return [
      // top-left
      {
        offsetLeft: -25,
        offsetTop: -35,
      },
      // top-right
      {
        offsetLeft: 25,
        offsetTop: -35,
      },
      // bottom-right
      {
        offsetLeft: 25,
        offsetTop: 20,
      },
      // bottom-left
      {
        offsetLeft: -25,
        offsetTop: 20,
      },
    ];
  }

  if (markerCount > 4) {
    return getSpiralOffsets(markerCount);
  }

  return [];
};

/**
 * Get an array of 2 animation steps that move a marker from a (0, 0) relative position
 * to its destinationOffset.
 */
export const getCenterOutAnimationSteps = (
  destinationOffset: MarkerOffset,
): MarkerAnimationStep[] => {
  return [
    {
      offset: {
        offsetLeft: 0,
        offsetTop: 0,
      },
    },
    {
      offset: destinationOffset,
    },
  ];
};

/**
 * Get an array of animation steps for a marker. The animation is
 * designed to move a marker through a series of pixel offsets (or other animations),
 * and end up in its spot in the series according to its index.
 *
 * The current use case moves markers through points on a spiral until they reach
 * their spot on the spiral.
 */
export const getSeriesAnimationSteps = (
  index: number,
  seriesOffsets: MarkerOffset[],
): MarkerAnimationStep[] => {
  const steps: MarkerAnimationStep[] = [];

  let j = 0;
  do {
    steps.push({
      offset: {
        offsetLeft: seriesOffsets[j]?.offsetLeft || 0,
        offsetTop: seriesOffsets[j]?.offsetTop || 0,
      },
    });
    j++;
  } while (j <= index);

  return steps;
};
