import { useEffect, useState } from 'react';

import { usePrevious } from '@hcs/hooks';
import { LegendInterval, VectileLayers } from '@hcs/types';

import { useHcMap } from '../hooks/useHcMap';
import { getLegendBreaksForMetric } from '../utils/heatmap.utils';
import { latLngToTileCoords } from '../utils/tile.utils';

import { useHcMapLayers } from './useHcMapLayers';
import { useVectilesStats } from './useVectilesStats';

export const useHcMapLayerLegendBreaks = (
  mapId: string,
): LegendInterval[] | undefined => {
  const { vectilesLayerSource } = useHcMapLayers(mapId);
  const { mapState } = useHcMap(mapId);
  const { viewport, boundsInfo, heatmap } = mapState || {};
  const vectilesMetricId = heatmap?.vectilesMetricId;
  const propertyType = heatmap?.propertyType;
  const integerZoom = viewport?.zoom ? Math.floor(viewport.zoom) : 0;

  const tileNE = boundsInfo?.northEast
    ? latLngToTileCoords({
        lat: boundsInfo.northEast.lat,
        lng: boundsInfo.northEast.lng,
        zoom: integerZoom,
      })
    : undefined;
  const tileSW = boundsInfo?.southWest
    ? latLngToTileCoords({
        lat: boundsInfo.southWest.lat,
        lng: boundsInfo.southWest.lng,
        zoom: integerZoom,
      })
    : undefined;

  const tileRangeURLSegment = `${tileSW?.x}-${tileNE?.x}/${tileNE?.y}-${tileSW?.y}`;

  const { data, isInitialLoading } = useVectilesStats(
    vectilesLayerSource?.vectilesLayer || VectileLayers.HCS_BLOCKS,
    integerZoom,
    tileRangeURLSegment,
  );

  const [intervals, setIntervals] = useState<LegendInterval[] | undefined>();

  /* Only get breaks if zoom level is within range for active metric or if not metric is passed,
   * use the default zoom level -> source keyword mapping
   */
  const nextIntervals: LegendInterval[] | undefined =
    tileNE &&
    tileSW &&
    tileSW.x > 0 &&
    tileNE.x > 0 &&
    data &&
    !isInitialLoading &&
    data?.property_stats &&
    vectilesMetricId
      ? getLegendBreaksForMetric(
          vectilesMetricId,
          data?.property_stats,
          propertyType,
        )
      : undefined;
  const prevMetric = usePrevious(vectilesMetricId);
  const prevPropertyType = usePrevious(propertyType);
  const prevIsLoading = usePrevious(isInitialLoading);
  useEffect(() => {
    if (
      ((prevIsLoading && !isInitialLoading) ||
        prevMetric !== vectilesMetricId) &&
      nextIntervals
    ) {
      setIntervals(nextIntervals);
    }
  }, [
    nextIntervals,
    prevIsLoading,
    isInitialLoading,
    data,
    vectilesMetricId,
    propertyType,
    prevMetric,
    prevPropertyType,
  ]);

  return intervals;
};
