import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useUserIsLegacySelfService } from '@hcs/authn';
import { Popover } from '@hcs/design-system';
import { DownloadIcon } from '@hcs/design-system';
import {
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
  ReportStatus,
} from '@hcs/types';

import { useReport } from '../../hooks';
import { useDownloadReportExcel } from '../../hooks/useDownloadReportExcel';
import { useDownloadReportPdf } from '../../hooks/useDownloadReportPdf';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './DownloadReport.module.css';

interface Props {
  reportId: ReportId;
  trigger?: ReactNode;
  className?: string;
}
export const DOWNLOAD_REPORT_FEATURES = [
  ReportFeatures.OutputPdf,
  ReportFeatures.OutputExcel,
];
const dataHcName = 'download-report';
const dataHcEventSection = 'Download Report';
export const DownloadReport = ({ reportId, className, trigger }: Props) => {
  const reportQuery = useReport(reportId);
  const { data: isUserLegacySelfService } = useUserIsLegacySelfService();
  const downloadReportPdfMutation = useDownloadReportPdf(reportId);
  const downloadReportExcelMutation = useDownloadReportExcel(reportId);

  const pdfDisabled =
    downloadReportPdfMutation.isLoading ||
    reportQuery.data?.status !== ReportStatus.Completed;
  const excelDisabled =
    downloadReportExcelMutation.isLoading ||
    reportQuery.data?.status !== ReportStatus.Completed;
  const handleDownloadReportPdf = () => {
    if (!pdfDisabled) {
      downloadReportPdfMutation.mutate();
    }
  };

  const handleDownloadReportExcel = () => {
    if (!excelDisabled) {
      downloadReportExcelMutation.mutate();
    }
  };

  return (
    <ReportFeaturesSupported
      reportId={reportId}
      reportFeatures={DOWNLOAD_REPORT_FEATURES}
    >
      <Popover
        dataHcName={`${dataHcName}-popover`}
        dataHcEventSection={dataHcEventSection}
        closeOnClick
        trigger={
          trigger || (
            <div className={classNames(styles.IconContainer, className)}>
              <div>
                <DownloadIcon dataHcName={`${dataHcName}-icon`} />
              </div>
              <span className={styles.IconButtonText}>Download</span>
            </div>
          )
        }
        content={
          <div
            data-hc-name={`${dataHcName}-content`}
            className={styles.Content}
          >
            <ul className={styles.Buttons}>
              <ReportFeaturesSupported
                reportId={reportId}
                reportFeatures={[ReportFeatures.OutputPdf]}
              >
                <li
                  onClick={handleDownloadReportPdf}
                  className={classNames(styles.Button, {
                    [styles.disabled || '']: pdfDisabled,
                  })}
                  data-hc-name={`${dataHcName}-download-pdf-button`}
                  data-hc-event-name={
                    pdfDisabled ? undefined : 'Download Report PDF'
                  }
                  data-hc-event-type={
                    pdfDisabled ? undefined : MeaningfulEventTypes.Goal
                  }
                >
                  PDF
                </li>
              </ReportFeaturesSupported>
              {isUserLegacySelfService !== undefined &&
                !isUserLegacySelfService && (
                  <ReportFeaturesSupported
                    reportId={reportId}
                    reportFeatures={[ReportFeatures.OutputExcel]}
                  >
                    <li
                      onClick={handleDownloadReportExcel}
                      className={classNames(styles.Button, {
                        [styles.disabled || '']: excelDisabled,
                      })}
                      data-hc-name={`${dataHcName}-download-excel-button`}
                      data-hc-event-name={
                        excelDisabled
                          ? undefined
                          : 'Download Report Spreadsheet'
                      }
                      data-hc-event-type={
                        excelDisabled ? undefined : MeaningfulEventTypes.Goal
                      }
                    >
                      Excel
                    </li>
                  </ReportFeaturesSupported>
                )}
            </ul>
          </div>
        }
      />
    </ReportFeaturesSupported>
  );
};
