import { useAccount } from '@hcs/authn';
import { AppConfig, AppConfigExtended } from '@hcs/types';

import { checkAppAccess } from '../utils/appAccess.utils';

import { useAuthDevToolsSlice } from './useAuthDevToolsSlice';

/** Accepts a list of AppConfigs and returns only those that the user can access */
export const useAppConfigsExtended = (
  appConfigs: AppConfig[],
  options?: {
    /** Shows out app configs that the user does not have access to */
    showUnauthorized?: boolean;
  },
) => {
  const {
    state: { disabledApps },
  } = useAuthDevToolsSlice();
  const accountQuery = useAccount();
  const data: AppConfigExtended[] = [];
  if (accountQuery.isSuccess && accountQuery.data) {
    appConfigs.forEach((appConfig) => {
      const appConfigExtended: AppConfigExtended = {
        hasAccess: disabledApps[appConfig.appSlug]
          ? false
          : checkAppAccess(accountQuery.data.legacy, appConfig),
        appConfig,
      };
      if (appConfigExtended.hasAccess || options?.showUnauthorized) {
        data.push(appConfigExtended);
      }
    });
  }
  return {
    ...accountQuery,
    data,
  };
};
