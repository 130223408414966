import { useAccount, useUserIsLegacySelfService } from '@hcs/authn';
import { combineUseQueryResult } from '@hcs/utils';

import { checkUserIsOrgAdmin } from '../utils/appAccess.utils';

export const useUserIsOrgAdmin = () => {
  const accountQuery = useAccount();
  const isLegacySSOrgQuery = useUserIsLegacySelfService();
  const combinedQuery = combineUseQueryResult([
    accountQuery,
    isLegacySSOrgQuery,
  ]);
  if (accountQuery.isSuccess && isLegacySSOrgQuery.isSuccess) {
    return {
      ...combinedQuery,
      data:
        checkUserIsOrgAdmin(accountQuery.data.legacy) &&
        !isLegacySSOrgQuery.data,
    };
  } else {
    // Return account query status if data is not yet fetched
    return {
      ...combinedQuery,
      data: undefined,
    };
  }
};
