import { BarDatum } from '@nivo/bar';

import { SimilarPropertiesChartSchema } from '@hcs/types';
import { ChartDataType, MenuItemType } from '@hcs/types';
import { BarChartBucket, ChartBar } from '@hcs/types';
import { formatMoney, formatNumber, formatNumberAbbrev } from '@hcs/utils';

export const makeBucketsNivioSafe = <M>(
  buckets: BarChartBucket<M>[],
): BarDatum[] => buckets.map(({ metaData, ...bucket }) => bucket);

export const getMiddle = (barWidth: number, numberOfBars: number) => {
  return (numberOfBars * barWidth) / 2;
};

export const showRange = (
  value: number,
  type: ChartDataType,
  docChartData: ChartBar | undefined,
) => {
  const bucket = docChartData?.buckets?.find((bucket) => {
    return bucket.start === value;
  });

  const format =
    type === ChartDataType.NUMBER_ABBREVIATION
      ? formatNumberAbbrev
      : type === ChartDataType.NUMBER
        ? formatNumber
        : formatMoney;
  return `${format(bucket?.start)} - ${format(bucket?.end)}`;
};

export const maxCount = (
  chart?: ChartBar | SimilarPropertiesChartSchema['chart'],
) =>
  chart?.buckets?.reduce(
    (max: number, obj: BarChartBucket) => (max > obj.count ? max : obj.count),
    0,
  );

export const TITLE_CONSTANTS = {
  VALUATION_TOGGLE: 'Similar Properties',
  VALUATION: 'Similar Properties Sold',
  VALUATION_CHART: 'See What Similar Homes Sold for in this Neighborhood',
  VALUATION_FILTERED_COMPARABLES: 'See What Similar Comps Sold for',
  NEARBY_PROPERTIES: 'Nearby Properties',
  NEARBY_COMP_PROPERTIES: 'Comp. Properties',
  SUBJECT_PROPERTY: 'Subject Property',
  FILTERED_COMPARABLES: 'Filtered Comparables',
  COMPARABLE_PROPERTIES: 'Comparable Properties',
};

export const getXLegendMap = (docChartData: ChartBar | undefined) => {
  return {
    [MenuItemType.SQFT]: {
      xAxisLabel: 'Gross Living Area (SQFT)',
      xAxisFormat: (value: number) =>
        showRange(value, ChartDataType.NUMBER_ABBREVIATION, docChartData),
    },
    [MenuItemType.PRICE_PER_SQ_FT]: {
      xAxisLabel: '$ / Gross Living Area (SQFT)',
      xAxisFormat: (value: number) =>
        showRange(value, ChartDataType.MONEY, docChartData),
    },
    [MenuItemType.SITE_AREA]: {
      xAxisLabel: 'Lot Size (SQFT)',
      xAxisFormat: (value: number) =>
        showRange(value, ChartDataType.NUMBER_ABBREVIATION, docChartData),
    },
    [MenuItemType.PRICE_SITE_AREA]: {
      xAxisLabel: '$ / Lot Size (SQFT)',
      xAxisFormat: (value: number) =>
        showRange(value, ChartDataType.MONEY, docChartData),
    },
    [MenuItemType.BEDS]: {
      xAxisLabel: 'Bedrooms',
      xAxisFormat: (value: number) => value,
    },
    [MenuItemType.BATHS]: {
      xAxisLabel: 'Bathrooms',
      xAxisFormat: (value: number) => value,
    },
    [MenuItemType.AGE]: {
      xAxisLabel: 'Age (Years)',
      xAxisFormat: (value: number) =>
        showRange(value, ChartDataType.NUMBER, docChartData),
    },
  };
};
