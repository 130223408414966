import { MutationOptions, useMutation } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { DexpCreateJobInputs, DexpEvent } from '@hcs/types';

import { DataExplorerApi } from '../api/dataExplorer.api';

export const useDexpCreateJob = (
  mutationOptions?: MutationOptions<
    DexpEvent,
    Error,
    DexpCreateJobInputs,
    unknown
  >,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<DexpEvent, Error, DexpCreateJobInputs>(
    async (inputs: DexpCreateJobInputs) => DataExplorerApi.createJob(inputs),
    {
      onMutate: (vars) => {
        toastOpen({
          type: 'loading',
          title: 'Creating Analysis...',
          duration: null,
        });
        mutationOptions?.onMutate?.(vars);
      },
      onSuccess: (data, vars, context) => {
        toastOpen({ type: 'success', title: 'Dexp Analysis Created' });
        mutationOptions?.onSuccess?.(data, vars, context);
      },
      onError: (error, vars, context) => {
        toastOpen({ type: 'error', title: 'Failed to Create Analysis' });
        mutationOptions?.onError?.(error, vars, context);
      },
    },
  );
};
