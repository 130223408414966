import React, { createContext, ReactNode, useReducer } from 'react';

import { searchByMlsNumberSubmit } from './searchByMlsNumber.actions';
import {
  searchByMlsNumberReducer,
  SearchByMlsNumberState,
} from './searchByMlsNumber.reducer';

const searchByMlsNumberState: SearchByMlsNumberState = {};

const initialValue = {
  state: searchByMlsNumberState,
  onSubmit: () => {
    return;
  },
} as {
  state: SearchByMlsNumberState;
  onSubmit: (queryKey: string, value: string) => void;
};

export const SearchByMlsNumberContext = createContext(initialValue);

export const SearchByMlsNumberProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    searchByMlsNumberReducer,
    searchByMlsNumberState,
  );
  return (
    <SearchByMlsNumberContext.Provider
      value={{
        state,
        onSubmit: (queryId: string, v: string) => {
          dispatch(searchByMlsNumberSubmit(queryId, v));
        },
      }}
    >
      {children}
    </SearchByMlsNumberContext.Provider>
  );
};
