import { useCallback, useMemo } from 'react';

import { GeoInterestedType, PreferencesKeys } from '@hcs/types';
import { logWarning, toggleStringInArray } from '@hcs/utils';

import { getGeoInterestedFromUiPreferences } from '../utils';

import { usePatchUiPreferencesForUser } from './usePatchUiPreferencesForUser';
import { useUiPreferencesForUser } from './useUiPreferencesForUser';

export const useGeoInterestedUiPreferences = (
  geoInterestedType: GeoInterestedType | null,
) => {
  const uiPreferencesQuery = useUiPreferencesForUser();
  const uiPreferencesMutation = usePatchUiPreferencesForUser();
  const geoInterestedList = useMemo(() => {
    const geoInterested = getGeoInterestedFromUiPreferences(
      uiPreferencesQuery.data || null,
    );
    if (geoInterestedType === null) {
      logWarning(
        `useGeoInterestedUiPreferences: called with geoInterestedType: ${geoInterestedType}`,
      );
    }
    return geoInterestedType ? geoInterested?.[geoInterestedType] : undefined;
  }, [uiPreferencesQuery.data, geoInterestedType]);

  const geoInterestedMap = useMemo(() => {
    if (geoInterestedList) {
      return geoInterestedList.reduce<Record<string, true>>((accum, id) => {
        accum[id] = true;
        return accum;
      }, {});
    }
    return geoInterestedList;
  }, [geoInterestedList]);

  const onToggleInterested = useCallback(
    (interestedId: string) => {
      uiPreferencesMutation.mutate([
        {
          op: 'add',
          path: `/${PreferencesKeys.GeoInterested}/${geoInterestedType}`,
          value: toggleStringInArray(interestedId, geoInterestedList),
        },
      ]);
    },
    [geoInterestedList, geoInterestedType],
  );

  return {
    state: {
      geoInterestedMap,
      query: uiPreferencesQuery,
    },
    actions: {
      onToggleInterested,
    },
  };
};
