import { DefaultValues } from 'react-hook-form';
import * as yup from 'yup';

import {
  OfferCreateObj,
  OfferNowFormData,
  OfferNowFormDataComplete,
  OfferRequest,
} from '@hcs/types';

export const offerNowValidationSchema = yup.object().shape(
  {
    // nullable(true) is needed so the number validator doesn't try to validate null but required will still win out
    initialAmount: yup
      .number()
      .required('Initial Offer Amount is required')
      .min(0, 'Initial Offer Amount cannot be negative')
      .when('maxAmount', ([maxAmount]: (number | null)[], schema) => {
        // if returns true, no error is shown to user:
        return schema.test(
          'initialAmount-maxAmount-compatible',
          'Initial Amount should not be more than than Max Offer',
          (initialAmount: number | null) => {
            // if user opens form and edits initialAmount, this prevents the form
            // from throwing an error UNTIL maxAmount is entered.
            if (!maxAmount || !initialAmount) {
              return true;
            }
            return initialAmount <= maxAmount;
          },
        );
      }),
    maxAmount: yup
      .number()
      .required('Max Offer is required')
      .min(0, 'Max Offer cannot be negative')
      .when('initialAmount', ([initialAmount]: (number | null)[], schema) => {
        // if returns true, no error is shown to user:
        return schema.test(
          'maxAmount-initialAmount-compatible',
          'Max Offer should be more than than Initial Offer Amount',
          (maxAmount: number | null) => {
            if (maxAmount !== null && initialAmount !== null) {
              return maxAmount >= initialAmount;
            }
            return true;
          },
        );
      }),
    amountDown: yup
      .number()
      .required('Amount Down is required')
      .min(0, 'Amount Down cannot be negative')
      .when('initialAmount', ([initialAmount]: (number | null)[], schema) => {
        // if returns true, no error is shown to user:
        return schema.test(
          'amountDown-initialAmount-compatible',
          'Amount Down should not be more than Initial Offer',
          (amountDown: number | null) => {
            if (amountDown !== null && initialAmount !== null) {
              return amountDown <= initialAmount;
            }
            return true;
          },
        );
      }),
    daysToClose: yup
      .number()
      .required('Days To Close is required')
      .min(0, 'Days To Close cannot be negative'),
    hasInspection: yup.boolean(),
    daysToInspection: yup.number().when('hasInspection', {
      is: true,
      then: (schema) => schema.required('Days To Inspection is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    hasDueDiligence: yup.boolean(),
    daysToDueDiligence: yup
      .number()
      .min(0, 'Days To Due Diligence  cannot be negative')
      .when('hasDueDiligence', {
        is: true,
        then: (schema) => schema.required('Days To Due Diligence is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    signerNameOnTitle: yup
      .string()
      .required('Signer Name on Title is required'),
    businessName: yup.string().nullable(),
  },
  // deps to avoid circular dependency
  [
    ['initialAmount', 'maxAmount'],
    ['initialAmount', 'amountDown'],
  ],
);

export const OFFER_FORM_DEFAULT_VALUES: DefaultValues<OfferNowFormData> = {
  initialAmount: null,
  maxAmount: null,
  amountDown: null,
  daysToClose: null,
  hasInspection: false,
  daysToInspection: null,
  hasDueDiligence: false,
  daysToDueDiligence: null,
  signerNameOnTitle: null,
  businessName: null,
};

export const createOfferFormToNewOffer = ({
  hcAddressId,
  fullAddress,
  addressSlug,
  formData,
}: OfferCreateObj): OfferRequest => {
  // need to figure out a better way to do this and make TS happy. The yup validation should insure we end up with complete data
  const formDataComplete = formData as OfferNowFormDataComplete;
  return {
    offer: {
      hcAddressId: hcAddressId,
      listingAddress: fullAddress,
      slug: addressSlug,
      initialAmount: formDataComplete.initialAmount,
      maxAmount: formDataComplete.maxAmount,
      amountDown: formDataComplete.amountDown,
      daysToClose: formDataComplete.daysToClose,
      daysToInspection: formData.hasInspection
        ? formData.daysToInspection
        : null,
      daysToDueDiligence: formData.hasDueDiligence
        ? formData.daysToDueDiligence
        : null,
      titleType: 'in_severalty',
      titleNames: [formDataComplete.signerNameOnTitle],
      businessName: formData.businessName,
    },
  };
};
