import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { default as LazyRender, forceCheck } from 'react-lazyload';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { useResizeObserver } from '@hcs/hooks';
import { CompTypes } from '@hcs/types';
import { CompSchema, CompsListTypes, ReportId } from '@hcs/types';

import {
  COMPS_LIST_CARDS_GAP,
  COMPS_LIST_CARDS_WIDTH,
} from '../../../constants';
import { useCompDocIdsByCompId, useCompDocuments } from '../../../hooks';
import { useCompsList } from '../../../hooks/useCompsList';
import { CompPropertyCard } from '../../CompPropertyCard';
import { ReportLegalDisclaimers } from '../../ReportLegalDisclaimers';

import styles from './CompsListCards.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  compsListType: CompsListTypes;
  className?: string;
  // Show only selected comps
  selectedCompsOnly?: boolean;
  onMouseEnter?: (hovered: {
    compSchema: CompSchema;
    documentId: string | undefined;
  }) => void;
  onMouseLeave?: VoidFunction;
  showDisclaimer?: boolean;
}
const dataHcName = 'comps-list-cards';
export const CompsListCards = ({
  reportId,
  compType,
  compsListType,
  className,
  selectedCompsOnly = false,
  showDisclaimer = true,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const scrollContainerId = `${dataHcName}-scrollContainer`;
  const [cardsPerRow, setCardsPerRow] = useState<number | undefined>(undefined);
  const { data: compDocuments } = useCompDocuments(reportId, compType);
  const {
    state: {
      compsListQuery: { isInitialLoading, data: compsListData },
    },
  } = useCompsList({
    reportId,
    compType,
    compsListType,
  });
  const listRef = useRef<HTMLDivElement>(null);
  const calcCardWidth = (containerWidth: number) => {
    const newCardsPerRow = Math.max(
      1,
      Math.floor(
        containerWidth / (COMPS_LIST_CARDS_WIDTH + COMPS_LIST_CARDS_GAP),
      ),
    );
    if (newCardsPerRow !== cardsPerRow) {
      setCardsPerRow(newCardsPerRow);
    }
    // Check if lazy-rendered components should be shown after resize
    forceCheck();
  };
  // Update card width when the scroll container is resized
  useResizeObserver(
    { ref: listRef },
    useCallback(
      (entry) => {
        calcCardWidth(entry.contentRect.width);
      },
      [cardsPerRow],
    ),
  );
  // Component is now mounted and ready to calculate width
  useEffect(() => {
    if (listRef.current && !cardsPerRow) {
      calcCardWidth(listRef.current.clientWidth);
    }
  }, [compsListData, listRef.current]);
  // Create map of selected comps to prevent unnecessary iterations
  const { data: selectCompsByAddressId } = useCompDocIdsByCompId(
    reportId,
    compType,
  );
  const compSchemasToRender = useMemo(
    () =>
      selectedCompsOnly
        ? compDocuments?.map((compDocument) => compDocument.data)
        : compsListData,
    [selectedCompsOnly, compDocuments, compsListData],
  );
  useEffect(() => {
    // Update lazy render if comp schemas change
    forceCheck();
  }, [compSchemasToRender]);
  if (isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }
  return (
    <div
      data-hc-name={`${dataHcName}-main`}
      // react-lazyload has a bug that doesn't support passing the Element
      // MR is open on the project
      id={scrollContainerId}
      ref={listRef}
      className={classNames(styles.CompsListCards, className)}
      style={{
        gap: `${COMPS_LIST_CARDS_GAP}px`,
        padding: `${COMPS_LIST_CARDS_GAP}px`,
      }}
    >
      {cardsPerRow &&
        compSchemasToRender?.map((compSchema, i) => {
          const compId = compSchema.compID;
          const documentId = selectCompsByAddressId[compId];
          return (
            <LazyRender
              key={`comp-card-${i}-${compId}`}
              scrollContainer={`#${scrollContainerId}`}
              height={331}
              className={styles.PropertyCardWrapper}
              unmountIfInvisible
              overflow
              scroll
              resize
            >
              <CompPropertyCard
                reportId={reportId}
                compIdentifier={{
                  type:
                    compsListType === 'appraisal' ? 'appraisalComp' : 'compId',
                  compId,
                  compType,
                }}
                onMouseEnter={() => {
                  onMouseEnter?.({
                    compSchema,
                    documentId,
                  });
                }}
                onMouseLeave={onMouseLeave}
                className={styles.CompPropertyCard}
              />
            </LazyRender>
          );
        })}
      {showDisclaimer && (
        <LazyRender
          key="mls-attribution"
          scrollContainer={`#${scrollContainerId}`}
          height={100}
          style={{
            width: `100%`,
          }}
          overflow
          scroll
          resize
        >
          <ReportLegalDisclaimers reportId={reportId} />
        </LazyRender>
      )}
    </div>
  );
};
