import { useDispatch, useSelector } from 'react-redux';

import { mapDispatchToSliceActions } from '@hcs/utils';

import {
  DataExplorerPartialAppState,
  dataExplorerSlice,
  DataExplorerSliceState,
} from '../rtk';
import { DATA_EXPLORER_REDUCER_KEY } from '../rtk';

export const useDataExplorerSlice = () => {
  const dispatch = useDispatch();
  return {
    state: useSelector<DataExplorerPartialAppState, DataExplorerSliceState>(
      (state) => state[DATA_EXPLORER_REDUCER_KEY],
    ),
    actions: mapDispatchToSliceActions(dispatch, dataExplorerSlice),
  };
};
