import { Navigate, useParams } from 'react-router-dom';

import { PrivatePage } from '@hcs/authn';
import { LayoutBanner } from '@hcs/design-system';
import { EntryPageContainer } from '@hcs/design-system';
import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import { OfferFormPage_DEPRECATED } from '@hcs/lead-feed';
import { BiasBanner } from '@hcs/report-api';
import { ReportGlobalHeaderContent } from '@hcs/report-api';
import { parseReportId } from '@hcs/report-api';
import { SearchReportSelect } from '@hcs/report-api';
import { ReportId } from '@hcs/types';
import { build404RedirectRoute } from '@hcs/utils';

import homeImg from '../../../assets/images/House-Photo-1.jpg';
import PexpIconPng from '../../../assets/images/pexp-icon.png';
import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { PrivatePageRedirect } from '../../../auth-redirects/PrivatePageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { LoginPage } from '../../access/pages';
import { PexpGlobalHeader } from '../navigation/PexpGlobalHeader';
import { PexpNav } from '../navigation/PexpNav';
import {
  BiasPage,
  PexpCompSelectionDrawPage,
  PexpCompSelectionPage,
  PexpReportHistoryPage,
  PexpReportPage,
  PexpReportPdfPage,
  PexpSearchPage,
} from '../pages';
import PexpRootPage from '../pages/PexpRootPage';
import PexpSelfServiceSetupPage from '../pages/PexpSelfServiceSetupPage';

const PEXP_ROOT_PATH = APP_CONFIG_PEXP.rootPath;
interface WrapperProps {
  Component: (props: { reportId: ReportId }) => JSX.Element | null;
}

const ReportIdRouteWrapper = ({ Component }: WrapperProps) => {
  const { reportId } = useParams();
  if (reportId) {
    return <Component reportId={parseReportId(reportId) || ''} />;
  } else {
    return null;
  }
};

export const publicRoutes = () => {
  return [
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.PUBLIC_REPORT}`,
      element: (
        <LayoutGlobalHeaderChildren
          header={
            <PexpGlobalHeader>
              <ReportIdRouteWrapper Component={ReportGlobalHeaderContent} />
            </PexpGlobalHeader>
          }
        >
          <ExperienceFlag
            experienceFlagId="PEXP_REPORT_API"
            next={<PexpNav/>}
          />
          <LayoutContent noPadding />
        </LayoutGlobalHeaderChildren>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.PUBLIC_REPORT}`,
          element: <PexpReportPage />,
        },
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.PUBLIC_REPORT_W_VAL_TYPE}`,
          element: <PexpReportPage />,
        },
      ],
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.PUBLIC_COMP_SELECTION}`,
      element: <PexpCompSelectionPage />,
    },
    // END PUBLIC REPORT URLS
  ];
};

export const routes = () => {
  return [
    // BEGIN REPORT URLS
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT}`,
      element: (
        <LayoutGlobalHeaderChildren
          header={
            <PexpGlobalHeader>
              <ReportIdRouteWrapper Component={ReportGlobalHeaderContent} />
            </PexpGlobalHeader>
          }
        >
          <ExperienceFlag
            experienceFlagId="PEXP_REPORT_API"
            next={<PexpNav/>}
          />
          <LayoutContent noPadding />
        </LayoutGlobalHeaderChildren>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT}`,
          element: (
            <PrivateApplicationPageRedirect>
              <PexpReportPage />
            </PrivateApplicationPageRedirect>
          ),
        },
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT_W_VAL_TYPE}`,
          element: (
            <PrivateApplicationPageRedirect>
              <PexpReportPage />
            </PrivateApplicationPageRedirect>
          ),
        },
      ],
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.COMP_SELECTION}`,
      element: (
        <PrivateApplicationPageRedirect>
          <PexpCompSelectionPage />
        </PrivateApplicationPageRedirect>
      ),
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.COMP_SELECTION_DRAW}`,
      element: (
        <PrivateApplicationPageRedirect>
          <PexpCompSelectionDrawPage />
        </PrivateApplicationPageRedirect>
      ),
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT}`,
      element: (
        <LayoutGlobalHeaderChildren
          header={
            <PexpGlobalHeader>
              <ReportIdRouteWrapper Component={ReportGlobalHeaderContent} />
            </PexpGlobalHeader>
          }
        >
          <ExperienceFlag
            experienceFlagId="PEXP_REPORT_API"
            next={<PexpNav/>}
          />
          <LayoutContent noPadding />
        </LayoutGlobalHeaderChildren>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.PDF}`,
          element: (
            <PrivateApplicationPageRedirect>
              <PexpReportPdfPage />
            </PrivateApplicationPageRedirect>
          ),
        },
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT_W_VAL_TYPE}`,
          element: (
            <PrivateApplicationPageRedirect>
              <PexpReportPage />
            </PrivateApplicationPageRedirect>
          ),
        },
      ],
    },
    // END REPORT URLS
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.OFFER}`,
      element: (
        <LayoutGlobalHeaderChildren header={<PexpGlobalHeader />}>
          <LayoutContent />
        </LayoutGlobalHeaderChildren>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.OFFER}`,
          element: (
            <PrivatePage unauthenticatedRender={<LoginPage />}>
              {/* eslint-disable-next-line react/jsx-pascal-case */}
              <OfferFormPage_DEPRECATED />
            </PrivatePage>
          ),
        },
      ],
    },
    {
      path: PEXP_ROOT_PATH,
      element: (
        <PrivateApplicationPageRedirect>
          <LayoutGlobalHeaderChildren
            header={
              <PexpGlobalHeader authenticatedActionPopovers={undefined} />
            }
          >
            <PexpRootPage/>
          </LayoutGlobalHeaderChildren>
        </PrivateApplicationPageRedirect>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.SEARCH}`,
          element: <PexpSearchPage />,
        },
        {
          path: PEXP_ROOT_PATH,
          element: <Navigate to={VIEW_PATHS_PEXP.SEARCH} replace />,
        },
      ],
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`,
      element: (
        <PrivatePageRedirect>
          <LayoutGlobalHeaderChildren header={<PexpGlobalHeader />}>
            <PexpNav/>
            <LayoutContent />
          </LayoutGlobalHeaderChildren>
        </PrivatePageRedirect>
      ),
      children: [
        {
          path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`,
          element: <PexpReportHistoryPage />,
        },
      ],
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.SELF_SERVICE_SETUP}`,
      element: (
        <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
          <EntryPageContainer>
            <SearchReportSelect/>
            <LayoutBanner
              bannerContent={<img alt="" src={PexpIconPng} />}
              title="Property Explorer 24/7"
              description="Let's setup your account."
            >
              <PexpSelfServiceSetupPage />
            </LayoutBanner>
          </EntryPageContainer>
        </LayoutGlobalHeaderChildren>
      ),
    },
    {
      path: `${PEXP_ROOT_PATH}/${VIEW_PATHS_PEXP.BIAS}`,
      element: (
        <PrivateApplicationPageRedirect>
          <LayoutGlobalHeaderChildren
            header={
              <PexpGlobalHeader authenticatedActionPopovers={undefined} />
            }
          >
            <LayoutBanner
              title="Reducing Bias with AVM Technology"
              description="Property Explorer can now evaluate a home appraisal and flag cases of potential bias or inaccurate valuation. Drop an Appraisal MISMO XML anywhere on this page to get started."
              backgroundImage={homeImg}
              bannerContent={<BiasBanner />}
            />
          </LayoutGlobalHeaderChildren>
        </PrivateApplicationPageRedirect>
      ),
      children: [
        {
          path: '',
          element: <BiasPage />,
        },
      ],
    },
    build404RedirectRoute(PEXP_ROOT_PATH),
  ];
};
