import {
  AddOrderFields,
  CreateNewOrderState,
  FIELD_NAMES,
  FieldMeta,
  ITEM_FIELD_NAMES,
  ItemFieldMeta,
  OrderDetailsFormFields,
  OrderTypeDescriptor,
  ProductTypeDescriptor,
} from '@hcs/types';

import { AS_REPAIRED_VALUE_NAMES, GROUP_KEYS, PRODUCT_IDS } from './constants';

export const getAddOrderFields = (
  orderDetailsForm: OrderDetailsFormFields,
  formState: CreateNewOrderState,
): AddOrderFields => {
  const addOrderFields: AddOrderFields = {
    ...orderDetailsForm,
    addressFormFields: formState.addressFormFields,
    orderFile: formState.orderFile,
    orderType: formState.selectedOrderType?.id || '',
    itemsSource: formState.itemsSource || 'entry',
    deliverySpeed: formState.deliverySpeed?.id,
  };

  const {
    itemsSource,
    orderFile,
    addressFormFields,
    intendedUserSame,
    ...restValues
  } = addOrderFields;

  const lenderValues = intendedUserSame
    ? {
        [FIELD_NAMES.LENDER_NAME]: addOrderFields[FIELD_NAMES.CLIENT_NAME],
        [FIELD_NAMES.LENDER_ADDRESS]:
          addOrderFields[FIELD_NAMES.CLIENT_ADDRESS],
        [FIELD_NAMES.LENDER_CITY]: addOrderFields[FIELD_NAMES.CLIENT_CITY],
        [FIELD_NAMES.LENDER_STATE]: addOrderFields[FIELD_NAMES.CLIENT_STATE],
        [FIELD_NAMES.LENDER_ZIPCODE]:
          addOrderFields[FIELD_NAMES.CLIENT_ZIPCODE],
      }
    : {};

  if (!restValues.customerOrderId && restValues.name) {
    let slugified = restValues.name.replace(/\s|\\|\//g, '-');
    if (slugified.length > 10) {
      slugified = slugified.substr(0, 10);
    }
    restValues.customerOrderId = `${slugified}-${Date.now()}`;
  }

  const ret: AddOrderFields = {
    ...restValues,
    ...lenderValues,
    itemsSource,
  };

  Object.keys(ret).forEach((key) => {
    ret[key as keyof AddOrderFields] === undefined &&
      delete ret[key as keyof AddOrderFields];
  });

  return ret;
};

export const createProductTypeChoices = (data?: OrderTypeDescriptor[]) => {
  const orderTypes = data || [];
  if (orderTypes) {
    const productTypes = GROUP_KEYS.map((groupKey) => ({
      groupKey: groupKey,
      productType: PRODUCT_IDS[groupKey],
      orderTypes: [] as OrderTypeDescriptor[],
    }));
    for (const orderType of orderTypes) {
      const groupKey = orderType.groupKey;
      const productType = productTypes.find(
        (possibleProductType) => possibleProductType.groupKey === groupKey,
      );
      if (productType) {
        productType.orderTypes.push(orderType);
      }
    }
    const products = productTypes.filter((productType) => {
      return productType ? productType.orderTypes.length > 0 : false;
    });
    return products as ProductTypeDescriptor[];
  } else {
    return [] as ProductTypeDescriptor[];
  }
};

export const getItemFieldMetaByOrderType = (
  orderTypeDescriptor: OrderTypeDescriptor,
) => {
  const shownItemFields = orderTypeDescriptor.shownItemFields;
  const requiredItemFields = orderTypeDescriptor.requiredItemFields;

  return Object.values(ITEM_FIELD_NAMES).reduce<ItemFieldMeta>((acc, key) => {
    const newPair = {
      [key]: {
        shown: shownItemFields.includes(key),
        required: requiredItemFields.includes(key),
      },
    };
    return {
      ...acc,
      ...newPair,
    };
  }, {} as ItemFieldMeta);
};

export const getFieldMetaByOrderType = (
  orderTypeDescriptor: OrderTypeDescriptor,
) => {
  const shownFields = orderTypeDescriptor.shownFields;
  const requiredFields = orderTypeDescriptor.requiredFields;

  return Object.values(FIELD_NAMES).reduce((acc, key) => {
    const newPair = {
      [key]: {
        shown: shownFields.includes(key),
        required: requiredFields.includes(key),
      },
    };
    return {
      ...acc,
      ...newPair,
    };
  }, {} as FieldMeta);
};

export const removeFileExtension = (input: string): string => {
  // matches input with extension
  const matches = input.match(/(.*)\.[^.]+$/);
  // if no match, return original input
  const fileName: string = matches ? matches[1] || input : input;
  return fileName;
};

export const getAsRepairedLabel = (
  includesAsRepairedValue?: boolean,
): string | null => {
  if (includesAsRepairedValue === true) {
    return AS_REPAIRED_VALUE_NAMES.asRepaired;
  } else if (includesAsRepairedValue === false) {
    return AS_REPAIRED_VALUE_NAMES.asIs;
  }
  return null;
};

export const getRequiredErrorText = (field: string) => `${field} is required`;

export const getRequiredIndicator = (required: boolean) =>
  required ? '*' : '';
