import React from 'react';
import classNames from 'classnames';

import { CompleteIcon, ErrorIcon, RemoveIcon } from '@hcs/design-system';
import { IconProps } from '@hcs/types';
import { RiskLevel } from '@hcs/types';

import styles from './AppraisalRiskLevelIcon.module.css';

interface Props extends IconProps {
  riskLevel: RiskLevel;
}
export const AppraisalRiskLevelIcon = ({
  riskLevel,
  className,
  ...svgProps
}: Props) => {
  const Icon =
    riskLevel === 'HIGH_RISK'
      ? RemoveIcon
      : riskLevel === 'LOW_RISK'
        ? CompleteIcon
        : ErrorIcon;
  return (
    <Icon
      className={classNames(className, {
        [styles.high]: riskLevel === 'HIGH_RISK',
        [styles.med]: riskLevel === 'RISK',
        [styles.low]: riskLevel === 'LOW_RISK',
      })}
      {...svgProps}
    />
  );
};
