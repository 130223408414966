import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { FetchPortfolioAssetsPayload, PortfolioAssets } from '@hcs/types';

import { PortfolioApi } from '../api';

export const QUERY_KEY_PORTFOLIO_ASSETS = 'portfolio-assets';
export const usePortfolioAssets = ({
  portfolioId,
  filters,
  cursor,
  order,
}: FetchPortfolioAssetsPayload) => {
  return useQuery<PortfolioAssets, AxiosError>(
    [QUERY_KEY_PORTFOLIO_ASSETS, portfolioId, filters, cursor, order],
    async () => {
      return await PortfolioApi.fetchPortfolioAssets({
        portfolioId,
        filters,
        cursor,
        order,
      });
    },
  );
};
