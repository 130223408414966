export const capitalizeFirstLetter = (str: string): string =>
  str
    .toLowerCase()
    .split(',')
    .map((s) => {
      s = s.trim();
      return s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join(',');

export const capitalizeFirstLetterOfEachWord = (str: string): string =>
  str.split(' ').map(capitalizeFirstLetter).join(' ');

export const trimWithEllipsis = (str: string, max: number) => {
  if (str.length > max) {
    return `${str.slice(0, max - 3)}...`;
  }
  return str;
};

export const camelCaseToSnakeCase = (str: string) => {
  if (!str) return str;
  const upperChars = str.match(/([A-Z])/g);
  const lowerChars = str.match(/([a-z])/g);
  if (!upperChars || !lowerChars) {
    return str;
  }
  upperChars.forEach((char) => {
    str = str.replace(char, '_' + char.toLowerCase());
  });

  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
};

export const snakeCaseToCamelCase = (str: string) => {
  if (!str) return str;
  const parts = str.split('_');
  if (parts.length) {
    const result = parts.reduce((curr, part) => {
      if (curr === '') {
        return `${part.toLowerCase()}`;
      } else {
        const firstChar = part.charAt(0).toUpperCase();
        const rest = part.slice(1).toLowerCase();
        return `${curr}${firstChar}${rest}`;
      }
    });
    return result;
  } else {
    return str;
  }
};

// Convert camelCase field labels for display in the UI (good to use as a fallback if a hardcoded label isn't found)
export const camelCaseToLabel = (s: string) => {
  // i.e. 'SaleAVMDetails' -> 'Sale AVM Details'
  return (s.charAt(0).toUpperCase() + s.slice(1))
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z])([a-z])/g, '$1 $2$3');
};

export const removeSpecialChars = (str: string, replace?: string) =>
  str
    .replace(/(?!\w|\s)./g, replace || '') // Remove any character that is not a word or whitespace
    .replace(/\s+/g, ' ') // Find any appearance of 1 or more whitespaces and replace it with one single white space
    .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2'); // Trim the string to remove any whitespace at the beginning or the end.

export const isNumericStr = (str: string | null | undefined) => {
  if (!str) return false;
  return !isNaN(Number(str));
};

export const isNumberOrDecimalPoint = (
  val: string,
  decimalAllowed: boolean,
) => {
  val = val.replace(/,/g, ' ').replace(/\$/g, '');
  if ((val.match(/\./g) || []).length > (decimalAllowed ? 1 : 0)) {
    return false; //too many decimal points
  }
  if (decimalAllowed && val.length > 0 && val.charAt(val.length - 1) === '.') {
    val = val.slice(0, val.length - 1);
  }
  return !isNaN(Number(val));
};

// Remove padding from a base64 string (removes trailing `=` characters that are not supported in URLs and some request headers)
export const base64RemovePadding = (base64String: string) =>
  base64String.replace(/={1,2}$/, '');

export const stringToNumber = (
  v: string | null | undefined,
): number | null | undefined =>
  v === undefined ? v : !v ? null : Number(removeSpecialChars(v));
