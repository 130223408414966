import { PayloadAction } from '@reduxjs/toolkit';

import { AddendumItem } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

export interface AddendumSliceState {
  addendumList: AddendumItem[];
}

export interface AddendumPartialAppState {
  addendumSlice: AddendumSliceState;
}

const initialState: AddendumSliceState = {
  addendumList: [],
};

export const addendumSlice = createHcReduxSlice({
  name: 'addendumSlice',
  initialState,
  reducers: {
    addItemToAddendumList(
      state,
      action: PayloadAction<{
        term: AddendumItem['term'];
        description: AddendumItem['description'];
        source: AddendumItem['source'];
      }>,
    ) {
      if (
        state?.addendumList &&
        state.addendumList.findIndex((f) => f.term === action.payload.term) ===
          -1
      ) {
        state.addendumList.push({
          term: action.payload.term,
          description: action.payload.description,
          source: action.payload.source,
        });
      }
    },
    clearList(state) {
      state.addendumList = initialState.addendumList;
    },
  },
});
