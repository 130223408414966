import { SORT_ORDER } from '@hcs/types';

import { logWarning } from './logger.utils';

const sortNullCheck = (
  a: unknown | null | undefined,
  b: unknown | null | undefined,
) => {
  if (a == null) {
    if (b == null) {
      return 0;
    }
    return 1;
  }
  if (b == null) {
    if (a == null) {
      return 0;
    }
    return -1;
  }
  return 0;
};

export const sortFn = (
  a: string | number | Date | null | undefined,
  b: string | number | Date | null | undefined,
  order: SORT_ORDER,
) => {
  if (a == null || b == null) {
    return sortNullCheck(a, b);
  }

  if (typeof a === 'string' && typeof b === 'string') {
    if (order === 'DESC') {
      // ignorePunctuation helps avoid unpredictable sorting related to punctuation, ensures that Albany, GA comes before Albany-Lebanon, OR
      return b.localeCompare(a, undefined, { ignorePunctuation: true });
    } else {
      return a.localeCompare(b, undefined, { ignorePunctuation: true });
    }
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (order === 'DESC') {
      return b - a;
    } else {
      return a - b;
    }
  }

  if (a instanceof Date && b instanceof Date) {
    if (order === 'DESC') {
      // valueOf will get millis since epoch
      return b.valueOf() - a.valueOf();
    } else {
      return a.valueOf() - b.valueOf();
    }
  }

  logWarning('sortFn: unhandled data type');
  return 0;
};

export const getOppositeSortOrder = (
  sortOrder: SORT_ORDER | null,
): SORT_ORDER | null => {
  if (sortOrder === SORT_ORDER.Asc) {
    return SORT_ORDER.Desc;
  } else if (sortOrder === SORT_ORDER.Desc) {
    return SORT_ORDER.Asc;
  } else {
    return null;
  }
};
