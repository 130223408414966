import axios from 'axios';
import { AxiosResponse } from 'axios';
import snakecaseKeys from 'snakecase-keys';

import {
  AxiosAccessTokenClientBearer,
  AxiosClient,
  fetchAccount,
} from '@hcs/http-clients';
import {
  Account,
  AuthapiCheckResetPwTokenRequestExt,
  AuthapiForgotPasswordRequest,
  AuthapiLoginRequestExt,
  AuthapiResetPwRequestExt,
  ResetPasswordPayload,
  SignUpPayload,
  UserapiUserHmacResponseExt,
} from '@hcs/types';
import { ACCOUNT_URL, ACCOUNT_URL_EXTERNAL } from '@hcs/urls';

const LOGIN_UNAUTHORIZED_HTTP_STATUS = 401;
const RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS = 401;

export class ResetPasswordInvalidTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ResetPasswordInvalidTokenError';
  }
}

export const AuthnApi = {
  login: async (payload: AuthapiLoginRequestExt) => {
    try {
      const response = await AxiosClient.post<Account>(
        `${ACCOUNT_URL_EXTERNAL}/auth/login`,
        payload,
        {
          withCredentials: true,
        },
      );
      return await fetchAccount(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === LOGIN_UNAUTHORIZED_HTTP_STATUS) {
          throw new Error('Unknown/unconfirmed email or incorrect password');
        }
        if (e.response?.data.status) {
          throw new Error(e.response.data.status);
        }
      }
      throw e;
    }
  },
  // Uses refresh token cookie
  logout: async () => {
    await AxiosClient.post<void>(`${ACCOUNT_URL}/auth/logout`, null, {
      withCredentials: true,
    });
    return;
  },
  fetchAccount: async () => {
    return await fetchAccount();
  },
  fetchUserHmacHash: async (userId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<UserapiUserHmacResponseExt>(
        `${ACCOUNT_URL_EXTERNAL}/users/${userId}/hmac`,
      );
    return response.data;
  },
  forgotPassword: async (payload: AuthapiForgotPasswordRequest) => {
    await AxiosClient.post<
      void,
      AxiosResponse<void>,
      AuthapiForgotPasswordRequest
    >(`${ACCOUNT_URL}/auth/forgot-password`, payload);
  },
  signUp: async (signUpPayload: SignUpPayload) => {
    const response = await AxiosClient.post<Account>(
      `${ACCOUNT_URL_EXTERNAL}/auth/invite-register`,
      snakecaseKeys(signUpPayload, { deep: true }),
      {
        withCredentials: true,
      },
    );
    return await fetchAccount(response.data);
  },
  checkResetPasswordToken: async (tokenOrNonce: {
    token?: string;
    nonce?: string;
  }) => {
    try {
      await AxiosClient.post<
        void,
        AxiosResponse<void>,
        AuthapiCheckResetPwTokenRequestExt
      >(`${ACCOUNT_URL_EXTERNAL}/auth/check-reset-password-token`, {
        token: tokenOrNonce.token,
        nonce: tokenOrNonce.nonce,
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (
          e.response &&
          e.response.status === RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS
        ) {
          throw new ResetPasswordInvalidTokenError('Token expired or invalid');
        }
      }
      throw e;
    }
  },
  resetPassword: async (payload: ResetPasswordPayload) => {
    try {
      const response = await AxiosClient.post<
        Account,
        AxiosResponse<Account>,
        AuthapiResetPwRequestExt
      >(`${ACCOUNT_URL_EXTERNAL}/auth/reset-password`, payload, {
        withCredentials: true,
      });

      return fetchAccount(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (
          e.response &&
          e.response.status === RESET_PASSWORD_UNAUTHORIZED_HTTP_STATUS
        ) {
          throw new ResetPasswordInvalidTokenError(
            'Verification link is expired or invalid',
          );
        }
      }
      throw e;
    }
  },
};
