import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ReportApiDocument, ReportEventTypes, ReportId } from '@hcs/types';

import { ReportApi } from '../api';
import { useSubscribeToReportEvents } from '../hooks/useSubscribeToReportEvents';

export const QUERY_KEY_DOCUMENT_BY_ID = 'report-api-document-id';
export const useDocumentById = <T extends ReportApiDocument>(
  reportId: ReportId,
  documentId?: string,
) => {
  const queryClient = useQueryClient();
  useSubscribeToReportEvents({
    callbackId: QUERY_KEY_DOCUMENT_BY_ID,
    onMessage: useCallback(
      // Handler to update the query cache for this hook when we receive relevant SSEs
      (reportEvent) => {
        if (
          reportEvent.type === ReportEventTypes.DocumentsRefreshed &&
          reportEvent.patchStatus === 'DONE'
        ) {
          queryClient.refetchQueries([QUERY_KEY_DOCUMENT_BY_ID]);
        }
      },
      [queryClient],
    ),
  });
  return useQuery(
    [QUERY_KEY_DOCUMENT_BY_ID, reportId, documentId],
    async () => {
      if (documentId) {
        return ReportApi.fetchDocument<T>(reportId, documentId);
      } else {
        throw new Error('No documentId argument');
      }
    },
    {
      // Managing enabled here because the `documentId` argument
      // is almost always dependent on a different request
      enabled: !!reportId && !!documentId,
      onSuccess: () => {
        // Keep useDocumentRole queryCache
      },
    },
  );
};
