import React, { useEffect, useState } from 'react';

import {
  DateStrInput,
  MonthsAgoSelect,
  TableCell,
  TableHeaderCell,
} from '@hcs/design-system';
import {
  DateStr,
  PropertyStateFilterProps,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgs,
  PropertyStateArgsProps,
  PropertyStateEditProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStateFlat,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
} from '@hcs/types';
import {
  dateStrToHumanOrderDate,
  dateStrToMonthsAgo,
  formatDateShort,
  monthsAgoToDateStr,
} from '@hcs/utils';

const FIELD = PropertyStateFields.lastCloseDate;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Sale Date';
const labelShort = 'Sale Date';
const propertyStatePath: PropertyStatePaths =
  '/complexFieldsSale/lastCloseDate';
const getValue: FieldConfig['getValue'] = (
  propertyStateArgs: PropertyStateArgs | undefined,
): PropertyStateFlat[typeof FIELD] => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.complexFieldsSale?.lastCloseDate;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatDateShort(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `Sold ${formatValue(propertyStateArgs)}`;

// Component for displaying a field from a schema
const Display: FieldConfig['Display'] = ({
  propertyStateArgs,
}: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
  onErrorChange,
}: PropertyStateEditProps<typeof FIELD>) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      const humanOrderDateFormat = dateStrToHumanOrderDate(defaultValue);
      setValue(humanOrderDateFormat || null);
    }
  }, [defaultValue]);

  return (
    <DateStrInput
      dataHcName="close-date-adjust-input"
      tooltipError={true}
      allowNull={true}
      className={className}
      value={value || ''}
      onErrorChange={onErrorChange}
      onChange={(value) => {
        setValue(value || null);
        onChange({
          path: propertyStatePath,
          value: value as DateStr,
          field: FIELD,
        });
      }}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'left',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

const Filter = ({
  spatialFiltersInput,
  onChange,
  className,
  disabled,
}: PropertyStateFilterProps<typeof FIELD>) => {
  return (
    <MonthsAgoSelect
      className={className}
      disabled={disabled}
      value={
        spatialFiltersInput.minSaleDate
          ? dateStrToMonthsAgo(spatialFiltersInput.minSaleDate)
          : null
      }
      onChange={(value) => {
        onChange({
          field: FIELD,
          spatialFilterInputs: {
            minSaleDate: value ? monthsAgoToDateStr(value) : null,
          },
        });
      }}
      dataHcName={`${FIELD}-filter`}
    />
  );
};

export const LAST_CLOSE_DATE_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  Display,
  HeaderCell,
  ContentCell,
  Edit,
  Filter,
  spatialSortField: SpatialSortField.SaleDate,
};
