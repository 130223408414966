import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  PropertyLocationDocument,
  PropertyLocationQuery,
  PropertyLocationQueryVariables,
} from '@hcs/types';
import { PropertyStateCerberusInput } from '@hcs/types';

import {
  cerberusLookupResponseToPropertyStateCommon,
  prepPropertyStateCerberusInputs,
} from '../utils/property-state.utils';

const QUERY_KEY_CERBERUS_PROPERTY_LOCATION = 'cerberus-property-location';
export const usePropertyStateLocation = (
  propertyStateCerberusInput: PropertyStateCerberusInput,
) => {
  const { cerberusInput } = prepPropertyStateCerberusInputs(
    propertyStateCerberusInput,
  );
  return useQuery({
    queryKey: [QUERY_KEY_CERBERUS_PROPERTY_LOCATION, cerberusInput],
    queryFn: async () => {
      if (cerberusInput) {
        const response = await CerberusApi.fetchQuery<
          PropertyLocationQuery,
          PropertyLocationQueryVariables
        >(PropertyLocationDocument, {
          cerberusInput,
        });
        return cerberusLookupResponseToPropertyStateCommon(response);
      } else {
        return null;
      }
    },
  });
};
