import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { CardSection } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { usePropertyStateCore } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { PropertyStateFields } from '@hcs/types';
import { formatFullAddress } from '@hcs/utils';
import { formatMoney } from '@hcs/utils';
import { logException } from '@hcs/utils';

import { OFFER_PAGE_SEARCH_PARAMS } from '../../constants';
import { useOfferNowAvailable } from '../../hooks/useOfferNowAvailable';

import { OfferForm } from '.';

import styles from './OfferFormPage.module.css';

interface CardTitleProps {
  address?: string | null;
  avm?: number | null;
  listPrice?: number | null;
  dataHcName: string;
}
const CardTitle = ({ address, avm, listPrice, dataHcName }: CardTitleProps) => {
  return (
    <div data-hc-name={dataHcName}>
      <div className={styles.FormCardTitle}>Your Offer For</div>
      {address && (
        <>
          <div
            data-hc-name={`${dataHcName}-address`}
            className={styles.FormCardAddress}
          >
            {address}
          </div>
          <div className={styles.FormCardItems}>
            <div
              data-hc-name={`${dataHcName}-avm`}
              className={styles.FormCardItem}
            >
              <span className={styles.FormCardItemLabel}>HouseCanary AVM</span>
              <span className={styles.FormCardItemValue}>
                {formatMoney(avm)}
              </span>
            </div>
            <div
              data-hc-name={`${dataHcName}-list-price`}
              className={styles.FormCardItem}
            >
              <span className={styles.FormCardItemLabel}>
                Current List Price
              </span>
              <span className={styles.FormCardItemValue}>
                {formatMoney(listPrice)}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const dataHcName = 'offer-form-page';
/*
 * This standalone page is deprecated. It's a holdover from when pexp was hosted as a standalone product
 * now we use OfferFromReportApi and OfferFormDialog to show a offer now link in the solutions pexp app
 */
export const OfferFormPage_DEPRECATED = () => {
  const { isSuccess: isOfferNowAvailableSuccess, data: offerNowAvailable } =
    useOfferNowAvailable();

  console.warn(
    '[DEPRECATED FEATURE]',
    'Use @hcs/report-api/features/OfferFromReportApi and/or @hcs/lead-feed/features/OfferFormDialog instead',
  );

  const [searchParams] = useSearchParams();
  const hcAddressIdParam = searchParams.get(
    OFFER_PAGE_SEARCH_PARAMS.HcAddressId,
  );
  const hcAddressId: number | null = hcAddressIdParam
    ? Number(hcAddressIdParam)
    : null;

  const { data: propertyStateArgs } = usePropertyStateCore(
    hcAddressId ? { hcAddressId } : undefined,
  );

  if (!isOfferNowAvailableSuccess) {
    return <LoadingSpinner fixedCenter dataHcName={`${dataHcName}-skeleton`} />;
  } else if (offerNowAvailable === false) {
    logException(
      'OfferFormPage: offer now access attempting, but not available for org',
    );
    return (
      <CardSection
        title="No Access"
        dataHcName={`${dataHcName}-no-access`}
        theme={{
          Container: styles.CardSection,
        }}
      >
        <div>
          <p>
            I&apos;m sorry but your organization does not have access to the
            &ldquo;Offer Now&rdquo; feature.
          </p>
          <p>Please contact your account manager for more information.</p>
        </div>
      </CardSection>
    );
  } else if (!hcAddressId) {
    logException('OfferFormPage: hc_address_id is missing from the url');
    return (
      <CardSection
        title="Address Missing"
        dataHcName={`${dataHcName}-address-missing`}
        theme={{
          Container: styles.CardSection,
        }}
      >
        <div>Address is missing from the url.</div>
      </CardSection>
    );
  }

  const listPrice =
    propertyStateArgs &&
    getPropertyStateFieldValue(
      PropertyStateFields.currentPrice,
      propertyStateArgs,
    );
  const avm =
    propertyStateArgs &&
    getPropertyStateFieldValue(
      PropertyStateFields.currentValue,
      propertyStateArgs,
    );
  const fullAddress = formatFullAddress(
    propertyStateArgs?.propertyState?.location,
  );

  return (
    <div className={styles.ContainerPadding} data-hc-name={dataHcName}>
      <CardSection
        title={
          <CardTitle
            dataHcName={`${dataHcName}-card-title`}
            address={fullAddress}
            avm={avm && !isNaN(avm) ? avm : undefined}
            listPrice={listPrice && !isNaN(listPrice) ? listPrice : undefined}
          />
        }
        dataHcName={`${dataHcName}-card-section`}
        theme={{
          Container: styles.CardSection,
          Card: styles.Card,
        }}
      >
        <OfferForm
          hcAddressId={hcAddressId}
          addressSlug={propertyStateArgs?.propertyState?.location?.addressSlug}
          fullAddress={fullAddress}
          listPrice={listPrice && !isNaN(listPrice) ? listPrice : undefined}
        />
      </CardSection>
    </div>
  );
};
