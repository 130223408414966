import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { Geometry } from 'geojson';

import { DateStr, DatetimeStr } from '../Date.types';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: DateStr;
  DateTime: DatetimeStr;
  GeoJSON: Geometry;
  Int64: number;
  LooseFloat: number;
  NumericID: number;
  SpatialArgumentBool: boolean | [boolean, null];
  Year: number;
};

export type AvmValueAtSixConditions = {
  __typename?: 'AVMValueAtSixConditions';
  conditionClass?: Maybe<Scalars['Int']>;
  conditionClassC?: Maybe<Scalars['String']>;
  neighborhoodConditionClass?: Maybe<Scalars['Int']>;
  valueAtCondC1?: Maybe<Scalars['Int']>;
  valueAtCondC2?: Maybe<Scalars['Int']>;
  valueAtCondC3?: Maybe<Scalars['Int']>;
  valueAtCondC4?: Maybe<Scalars['Int']>;
  valueAtCondC5?: Maybe<Scalars['Int']>;
  valueAtCondC6?: Maybe<Scalars['Int']>;
  valueLowerAtCondC1?: Maybe<Scalars['Int']>;
  valueLowerAtCondC2?: Maybe<Scalars['Int']>;
  valueLowerAtCondC3?: Maybe<Scalars['Int']>;
  valueLowerAtCondC4?: Maybe<Scalars['Int']>;
  valueLowerAtCondC5?: Maybe<Scalars['Int']>;
  valueLowerAtCondC6?: Maybe<Scalars['Int']>;
  valueUpperAtCondC1?: Maybe<Scalars['Int']>;
  valueUpperAtCondC2?: Maybe<Scalars['Int']>;
  valueUpperAtCondC3?: Maybe<Scalars['Int']>;
  valueUpperAtCondC4?: Maybe<Scalars['Int']>;
  valueUpperAtCondC5?: Maybe<Scalars['Int']>;
  valueUpperAtCondC6?: Maybe<Scalars['Int']>;
};

export enum AddressLookupType {
  BuildingLookup = 'BUILDING_LOOKUP',
  Lookup = 'LOOKUP',
}

export type AddressReference = {
  __typename?: 'AddressReference';
  fields?: Maybe<AddressReferenceFields>;
  hcAddressId?: Maybe<Scalars['NumericID']>;
  /** @deprecated "Use hcAddressId" */
  id?: Maybe<Scalars['String']>;
};

export type AddressReferenceFields = {
  __typename?: 'AddressReferenceFields';
  /** @deprecated "Use hcBuildingId" */
  buildingId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  fullLine?: Maybe<Scalars['String']>;
  hasAvm?: Maybe<Scalars['String']>;
  hcBuildingId?: Maybe<Scalars['NumericID']>;
  isUnit?: Maybe<Scalars['String']>;
  latitudeLongitude?: Maybe<Scalars['String']>;
  partialLine?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AgeValueFactor = {
  __typename?: 'AgeValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The median value of this attribute in the area */
  areaMedian?: Maybe<Scalars['Int']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Int']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export type AgentDetails = {
  __typename?: 'AgentDetails';
  email?: Maybe<Scalars['String']>;
  identifiers?: Maybe<AgentIdentifiers>;
  licenseNumber?: Maybe<Scalars['String']>;
  listings?: Maybe<AgentListingSearchResults>;
  name?: Maybe<Scalars['String']>;
  office?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  statistic?: Maybe<AgentStatistic>;
  statistics?: Maybe<AgentStatistics>;
};

export type AgentDetailsListingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  countyFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period?: InputMaybe<AgentStatisticsPeriod>;
  stateAbbrev?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AgentIdentifierLicenseNumber = {
  __typename?: 'AgentIdentifierLicenseNumber';
  licenseNumber?: Maybe<Scalars['String']>;
  licenseStateAbbr?: Maybe<Scalars['String']>;
  licenseStateFips?: Maybe<Scalars['String']>;
};

export type AgentIdentifierMlsAgentId = {
  __typename?: 'AgentIdentifierMlsAgentID';
  hcMlsId?: Maybe<Scalars['Int']>;
  mlsAgentId?: Maybe<Scalars['String']>;
};

export type AgentIdentifiers = {
  __typename?: 'AgentIdentifiers';
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenseNumbers?: Maybe<Array<Maybe<AgentIdentifierLicenseNumber>>>;
  mlsAgentIds?: Maybe<Array<Maybe<AgentIdentifierMlsAgentId>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AgentListingSearchResults = {
  __typename?: 'AgentListingSearchResults';
  edges?: Maybe<Array<Maybe<AgentListingSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type AgentListingSearchResultsEdges = {
  __typename?: 'AgentListingSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Listing>;
};

/** Agent name search method */
export enum AgentNameSearchMethod {
  Match = 'MATCH',
  TypeAhead = 'TYPE_AHEAD',
}

export type AgentSearchResults = {
  __typename?: 'AgentSearchResults';
  edges?: Maybe<Array<Maybe<AgentSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type AgentSearchResultsEdges = {
  __typename?: 'AgentSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AgentDetails>;
};

export type AgentStatistic = {
  __typename?: 'AgentStatistic';
  county?: Maybe<AgentStatisticCounty>;
  msa?: Maybe<AgentStatisticMsa>;
  national?: Maybe<AgentStatisticNational>;
  state?: Maybe<AgentStatisticState>;
  zipcode?: Maybe<AgentStatisticZipcode>;
};

export type AgentStatisticCountyArgs = {
  fips: Scalars['String'];
  listingStatus: AgentStatisticsListingStatus;
  listingType: AgentStatisticsListingType;
  period: AgentStatisticsPeriod;
  representationType: AgentStatisticsRepresentationType;
};

export type AgentStatisticMsaArgs = {
  listingStatus: AgentStatisticsListingStatus;
  listingType: AgentStatisticsListingType;
  msaId: Scalars['String'];
  period: AgentStatisticsPeriod;
  representationType: AgentStatisticsRepresentationType;
};

export type AgentStatisticNationalArgs = {
  listingStatus: AgentStatisticsListingStatus;
  listingType: AgentStatisticsListingType;
  period: AgentStatisticsPeriod;
  representationType: AgentStatisticsRepresentationType;
};

export type AgentStatisticStateArgs = {
  listingStatus: AgentStatisticsListingStatus;
  listingType: AgentStatisticsListingType;
  period: AgentStatisticsPeriod;
  representationType: AgentStatisticsRepresentationType;
  stateAbbr?: InputMaybe<Scalars['String']>;
  stateFips?: InputMaybe<Scalars['String']>;
};

export type AgentStatisticZipcodeArgs = {
  listingStatus: AgentStatisticsListingStatus;
  listingType: AgentStatisticsListingType;
  period: AgentStatisticsPeriod;
  representationType: AgentStatisticsRepresentationType;
  zipcode: Scalars['String'];
};

export type AgentStatisticCounty = {
  __typename?: 'AgentStatisticCounty';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  fips?: Maybe<Scalars['String']>;
  listingStatus?: Maybe<AgentStatisticsListingStatus>;
  listingType?: Maybe<AgentStatisticsListingType>;
  period?: Maybe<AgentStatisticsPeriod>;
  representationType?: Maybe<AgentStatisticsRepresentationType>;
};

export type AgentStatisticMsa = {
  __typename?: 'AgentStatisticMsa';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  listingStatus?: Maybe<AgentStatisticsListingStatus>;
  listingType?: Maybe<AgentStatisticsListingType>;
  msaId?: Maybe<Scalars['String']>;
  period?: Maybe<AgentStatisticsPeriod>;
  representationType?: Maybe<AgentStatisticsRepresentationType>;
};

export type AgentStatisticNational = {
  __typename?: 'AgentStatisticNational';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  listingStatus?: Maybe<AgentStatisticsListingStatus>;
  listingType?: Maybe<AgentStatisticsListingType>;
  period?: Maybe<AgentStatisticsPeriod>;
  representationType?: Maybe<AgentStatisticsRepresentationType>;
};

export type AgentStatisticState = {
  __typename?: 'AgentStatisticState';
  abbrev?: Maybe<Scalars['String']>;
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  fips?: Maybe<Scalars['String']>;
  listingStatus?: Maybe<AgentStatisticsListingStatus>;
  listingType?: Maybe<AgentStatisticsListingType>;
  period?: Maybe<AgentStatisticsPeriod>;
  representationType?: Maybe<AgentStatisticsRepresentationType>;
};

export type AgentStatisticZipcode = {
  __typename?: 'AgentStatisticZipcode';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  listingStatus?: Maybe<AgentStatisticsListingStatus>;
  listingType?: Maybe<AgentStatisticsListingType>;
  period?: Maybe<AgentStatisticsPeriod>;
  representationType?: Maybe<AgentStatisticsRepresentationType>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AgentStatistics = {
  __typename?: 'AgentStatistics';
  county?: Maybe<Array<Maybe<AgentStatisticCounty>>>;
  msa?: Maybe<Array<Maybe<AgentStatisticMsa>>>;
  national?: Maybe<Array<Maybe<AgentStatisticNational>>>;
  state?: Maybe<Array<Maybe<AgentStatisticState>>>;
  zipcode?: Maybe<Array<Maybe<AgentStatisticZipcode>>>;
};

export type AgentStatisticsCountyArgs = {
  fips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listingStatus?: InputMaybe<Array<InputMaybe<AgentStatisticsListingStatus>>>;
  listingType?: InputMaybe<Array<InputMaybe<AgentStatisticsListingType>>>;
  period?: InputMaybe<Array<InputMaybe<AgentStatisticsPeriod>>>;
  representationType?: InputMaybe<
    Array<InputMaybe<AgentStatisticsRepresentationType>>
  >;
};

export type AgentStatisticsMsaArgs = {
  listingStatus?: InputMaybe<Array<InputMaybe<AgentStatisticsListingStatus>>>;
  listingType?: InputMaybe<Array<InputMaybe<AgentStatisticsListingType>>>;
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period?: InputMaybe<Array<InputMaybe<AgentStatisticsPeriod>>>;
  representationType?: InputMaybe<
    Array<InputMaybe<AgentStatisticsRepresentationType>>
  >;
};

export type AgentStatisticsNationalArgs = {
  listingStatus?: InputMaybe<Array<InputMaybe<AgentStatisticsListingStatus>>>;
  listingType?: InputMaybe<Array<InputMaybe<AgentStatisticsListingType>>>;
  period?: InputMaybe<Array<InputMaybe<AgentStatisticsPeriod>>>;
  representationType?: InputMaybe<
    Array<InputMaybe<AgentStatisticsRepresentationType>>
  >;
};

export type AgentStatisticsStateArgs = {
  listingStatus?: InputMaybe<Array<InputMaybe<AgentStatisticsListingStatus>>>;
  listingType?: InputMaybe<Array<InputMaybe<AgentStatisticsListingType>>>;
  period?: InputMaybe<Array<InputMaybe<AgentStatisticsPeriod>>>;
  representationType?: InputMaybe<
    Array<InputMaybe<AgentStatisticsRepresentationType>>
  >;
  stateAbbr?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AgentStatisticsZipcodeArgs = {
  listingStatus?: InputMaybe<Array<InputMaybe<AgentStatisticsListingStatus>>>;
  listingType?: InputMaybe<Array<InputMaybe<AgentStatisticsListingType>>>;
  period?: InputMaybe<Array<InputMaybe<AgentStatisticsPeriod>>>;
  representationType?: InputMaybe<
    Array<InputMaybe<AgentStatisticsRepresentationType>>
  >;
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Agent search sort field */
export enum AgentStatisticsField {
  AveragePrice = 'AVERAGE_PRICE',
  CountListings = 'COUNT_LISTINGS',
  TotalPrice = 'TOTAL_PRICE',
}

/** Agent listing type */
export enum AgentStatisticsListingStatus {
  All = 'ALL',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

/** Agent listing type */
export enum AgentStatisticsListingType {
  Rental = 'RENTAL',
  Sale = 'SALE',
}

/** Agent statistics period */
export enum AgentStatisticsPeriod {
  EighteenMonths = 'EIGHTEEN_MONTHS',
  SixMonths = 'SIX_MONTHS',
  ThirtySixMonths = 'THIRTY_SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS',
}

/** Agent representation type */
export enum AgentStatisticsRepresentationType {
  All = 'ALL',
  Buy = 'BUY',
  List = 'LIST',
}

export enum ApplicationType {
  All = 'ALL',
  Api = 'API',
  Consumer = 'CONSUMER',
  ConsumerAllMls = 'CONSUMER_ALL_MLS',
  ConsumerUser = 'CONSUMER_USER',
  Full = 'FULL',
  Google = 'GOOGLE',
  Idx = 'IDX',
  Pre = 'PRE',
  Testpr = 'TESTPR',
  Vow = 'VOW',
  Vr = 'VR',
}

export enum AssociationFeeFrequency {
  Annually = 'ANNUALLY',
  Biweekly = 'BIWEEKLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY',
  Semimonthly = 'SEMIMONTHLY',
  Weekly = 'WEEKLY',
}

export type AutocompleteResults = {
  __typename?: 'AutocompleteResults';
  /** Residential address matches from HouseCanary's database */
  addresses?: Maybe<Array<AddressReference>>;
  /** Place (zip and city) matches */
  places?: Maybe<Array<PlaceDetails>>;
};

/** Quantized quality of an AVM value. The exact criteria associated with this are subject to change with the latest guidance from research team. */
export enum AvmQuality {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum AvmQualityMethod {
  Consumer = 'CONSUMER',
  Enterprise = 'ENTERPRISE',
}

export type BasementValueFactor = {
  __typename?: 'BasementValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The % of homes in the area that have this attribute */
  areaPercent?: Maybe<Scalars['Float']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Boolean']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export type Bathrooms = {
  __typename?: 'Bathrooms';
  /** Full bath count (if available) */
  full?: Maybe<Scalars['Int']>;
  /** Partial bath count (if available) */
  partial?: Maybe<Scalars['Int']>;
  /** Human displayable count of bathrooms in 'Realtor' terminology (i.e. 2.5) */
  summaryCount?: Maybe<Scalars['Float']>;
};

export type BathroomsValueFactor = {
  __typename?: 'BathroomsValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The median value of this attribute in the area */
  areaMedian?: Maybe<Scalars['Float']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Float']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export type BboxInput = {
  neLat: Scalars['Float'];
  neLng: Scalars['Float'];
  swLat: Scalars['Float'];
  swLng: Scalars['Float'];
};

export type BedroomsValueFactor = {
  __typename?: 'BedroomsValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The median value of this attribute in the area */
  areaMedian?: Maybe<Scalars['Int']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Int']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export type BlockCrime = {
  __typename?: 'BlockCrime';
  /** statistics of all types of crimes together in the area */
  all?: Maybe<CrimeStatisticDetails>;
  /** statistics of crimes other than property and violent crimes in the area */
  other?: Maybe<CrimeStatisticDetails>;
  /** statistics of property crimes in the area */
  property?: Maybe<CrimeStatisticDetails>;
  /** statistics of violent crimes in the area */
  violent?: Maybe<CrimeStatisticDetails>;
};

export type BlockDetails = {
  __typename?: 'BlockDetails';
  blockID?: Maybe<Scalars['String']>;
  /** statistics of crimes in the area */
  crime?: Maybe<BlockCrime>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  /** statistics of nature hazards in the area */
  hazard?: Maybe<BlockHazard>;
  /** HouseCanary rental index */
  hcri?: Maybe<Array<BlockHcri>>;
  /** histograms of property details in the area */
  histogram?: Maybe<BlockHistogram>;
  name?: Maybe<Scalars['String']>;
  /** statistics of property types in the area */
  propertyTypeCount?: Maybe<Array<PropertyTypeCount>>;
  /** statistics of property rental value in the area */
  rentalValue?: Maybe<Array<BlockRentalValue>>;
  /** Federally-designated toxic contamination cleanup sites near the block */
  superFund?: Maybe<BlockSuperFund>;
  /** statistics of property value in the area */
  value?: Maybe<Array<BlockValue>>;
};

export type BlockGroupDetails = {
  __typename?: 'BlockGroupDetails';
  blockGroupID?: Maybe<Scalars['String']>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  /** HouseCanary rental index */
  hcri?: Maybe<Array<BlockGroupHcri>>;
  name?: Maybe<Scalars['String']>;
  /** statistics of property rental value in the area */
  rentalValue?: Maybe<Array<BlockGroupRentalValue>>;
  /** statistics of property value in the area */
  value?: Maybe<Array<BlockGroupValue>>;
};

export type BlockGroupDistributionValue = {
  __typename?: 'BlockGroupDistributionValue';
  /** sample count in the block group */
  count?: Maybe<Scalars['Int']>;
  /** maximum price in the block group */
  max?: Maybe<Scalars['Int']>;
  /** median price in the block group */
  mean?: Maybe<Scalars['Int']>;
  /** minimum price in the block group */
  min?: Maybe<Scalars['Int']>;
  /**  price of the area at 5th percentile */
  percentile5?: Maybe<Scalars['Int']>;
  /**  price of the area at 25th percentile */
  percentile25?: Maybe<Scalars['Int']>;
  /**  price of the area at 50th percentile (median) */
  percentile50?: Maybe<Scalars['Int']>;
  /**  price of the area at 75th percentile */
  percentile75?: Maybe<Scalars['Int']>;
  /**  price of the area at 95th percentile */
  percentile95?: Maybe<Scalars['Int']>;
  /** standard deviation of the price in the block group */
  standardDeviation?: Maybe<Scalars['Int']>;
};

export type BlockGroupDistributionValuePerSqft = {
  __typename?: 'BlockGroupDistributionValuePerSqft';
  /** Sample count in the block */
  count?: Maybe<Scalars['Int']>;
  /** Maximum price per sqft in the block group */
  max?: Maybe<Scalars['Float']>;
  /** Median price per sqft in the block group */
  mean?: Maybe<Scalars['Float']>;
  /** Minimum price per sqft in the block group */
  min?: Maybe<Scalars['Float']>;
  /** Price per sqft of the area at 5th percentile */
  percentile5?: Maybe<Scalars['Float']>;
  /** Price per sqft of the area at 25th percentile */
  percentile25?: Maybe<Scalars['Float']>;
  /** Price per sqft of the area at 50th percentile (median) */
  percentile50?: Maybe<Scalars['Float']>;
  /** Price per sqft of the area at 75th percentile */
  percentile75?: Maybe<Scalars['Float']>;
  /** Price per sqft of the area at 95th percentile */
  percentile95?: Maybe<Scalars['Float']>;
  /** Standard deviation of the price per sqft in the block group */
  standardDeviation?: Maybe<Scalars['Float']>;
};

export type BlockGroupHcri = {
  __typename?: 'BlockGroupHcri';
  /** count of properties considered for average and median gross yields. */
  grossYieldCount?: Maybe<Scalars['Int']>;
  /** average gross yield over the selected area of summarization. */
  grossYieldMean?: Maybe<Scalars['Float']>;
  /** Median gross yield over the selected area of summarization. */
  grossYieldMedian?: Maybe<Scalars['Float']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
};

export type BlockGroupRentalValue = {
  __typename?: 'BlockGroupRentalValue';
  /** statistical distributions of property rental value */
  distribution?: Maybe<BlockGroupValueDistribution>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
};

export type BlockGroupValue = {
  __typename?: 'BlockGroupValue';
  /** statistical distributions of property value */
  distribution?: Maybe<BlockGroupValueDistribution>;
  forecast?: Maybe<BlockGroupValueForecast>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  timeSeries?: Maybe<Array<BlockGroupValueTimeSeriesElement>>;
};

export type BlockGroupValueTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type BlockGroupValueDistribution = {
  __typename?: 'BlockGroupValueDistribution';
  /** statistical distributions of block group price */
  value?: Maybe<BlockGroupDistributionValue>;
  /** statistical distributions of block group price per sqft */
  valuePerSqft?: Maybe<BlockGroupDistributionValuePerSqft>;
};

export type BlockGroupValueForecast = {
  __typename?: 'BlockGroupValueForecast';
  appreciation1Year?: Maybe<Scalars['Float']>;
};

export type BlockGroupValueTimeSeriesElement = {
  __typename?: 'BlockGroupValueTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** median price in the block group */
  valueMedian?: Maybe<Scalars['Int']>;
  /** median price per sqft in the block group */
  valuePerSqftMedian?: Maybe<Scalars['Float']>;
};

export type BlockHazard = {
  __typename?: 'BlockHazard';
  /** earthquake statistics in the area */
  earthquake?: Maybe<HazardEarthquake>;
  /** hail statistics in the area */
  hail?: Maybe<HazardHail>;
  /** hurricane statistics in the area */
  hurricane?: Maybe<HazardHurricane>;
  /** tornado statistics in the area */
  tornado?: Maybe<HazardTornado>;
  /** wind storm statistics in the area */
  wind?: Maybe<HazardWind>;
};

export type BlockHcri = {
  __typename?: 'BlockHcri';
  /** count of properties considered for average and median gross yields. */
  grossYieldCount?: Maybe<Scalars['Int']>;
  /** average gross yield over the selected area of summarization. */
  grossYieldMean?: Maybe<Scalars['Float']>;
  /** Median gross yield over the selected area of summarization. */
  grossYieldMedian?: Maybe<Scalars['Float']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
};

export type BlockHistogram = {
  __typename?: 'BlockHistogram';
  /** histogram of the age of home */
  ageOfHome?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of bathroom count */
  baths?: Maybe<Array<HistogramFloatBinRange>>;
  /** histogram of bedroom count */
  beds?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of building area */
  buildingArea?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of lot size */
  lotsize?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of rental value */
  rentalValue?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of rental value per sqft */
  rentalValuePerSqft?: Maybe<Array<HistogramFloatBinRange>>;
  /** histogram of property value */
  value?: Maybe<Array<HistogramIntBinRange>>;
  /** histogram of property value per sqft */
  valuePerSqft?: Maybe<Array<HistogramIntBinRange>>;
};

export type BlockRentalValue = {
  __typename?: 'BlockRentalValue';
  /** statistical distributions of property rental value */
  distribution?: Maybe<BlockValueDistribution>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
};

export type BlockSuperFund = {
  __typename?: 'BlockSuperFund';
  /** Superfund sites that the property is located on (if any) */
  withinMiles0?: Maybe<BlockSuperFundWithinMiles>;
  /** Superfund sites within 1 mile of the property (if any) */
  withinMiles1?: Maybe<BlockSuperFundWithinMiles>;
  /** Superfund sites within 4 miles of the property (if any) */
  withinMiles4?: Maybe<BlockSuperFundWithinMiles>;
};

export type BlockSuperFundWithinMiles = {
  __typename?: 'BlockSuperFundWithinMiles';
  /** Number of Superfund sites in the area */
  count?: Maybe<Scalars['Int']>;
  /** Specifics for each Superfund site in the area (if any) */
  details?: Maybe<Array<BlockSuperfundWithinMilesDetails>>;
};

export type BlockSuperfundWithinMilesDetails = {
  __typename?: 'BlockSuperfundWithinMilesDetails';
  /** Official ID of the Superfund site */
  epsSiteid?: Maybe<Scalars['String']>;
  /** URL for site details hosted by the EPA */
  link?: Maybe<Scalars['String']>;
  /** Status of the site on the National Priorities List */
  nplStatus?: Maybe<Scalars['String']>;
  /** Official name of the Superfund site */
  siteName?: Maybe<Scalars['String']>;
  /** date when the site entry was last modified in ISO date format */
  updatedDate?: Maybe<Scalars['Date']>;
};

export type BlockValue = {
  __typename?: 'BlockValue';
  /** statistical distributions of property value */
  distribution?: Maybe<BlockValueDistribution>;
  forecast?: Maybe<BlockValueForecast>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  timeSeries?: Maybe<Array<BlockValueTimeSeriesElement>>;
};

export type BlockValueTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type BlockValueDistribution = {
  __typename?: 'BlockValueDistribution';
  /** statistical distributions of property rental value */
  value?: Maybe<BlockValueDistributionValue>;
  /** statistical distributions of property rental value per sqft */
  valuePerSqft?: Maybe<BlockValueDistributionValuePerSqft>;
};

export type BlockValueDistributionValue = {
  __typename?: 'BlockValueDistributionValue';
  /** sample count in the block */
  count?: Maybe<Scalars['Int']>;
  /** maximum price in the block */
  max?: Maybe<Scalars['Int']>;
  /** median price in the block */
  mean?: Maybe<Scalars['Int']>;
  /** minimum price in the block */
  min?: Maybe<Scalars['Int']>;
  /** rental price of the area at 5th percentile */
  percentile5?: Maybe<Scalars['Int']>;
  /** rental price of the area at 25th percentile */
  percentile25?: Maybe<Scalars['Int']>;
  /** rental price of the area at 50th percentile (median) */
  percentile50?: Maybe<Scalars['Int']>;
  /** rental price of the area at 75th percentile */
  percentile75?: Maybe<Scalars['Int']>;
  /** rental price of the area at 95th percentile */
  percentile95?: Maybe<Scalars['Int']>;
  /** standard deviation of the price in the block */
  standardDeviation?: Maybe<Scalars['Int']>;
};

export type BlockValueDistributionValuePerSqft = {
  __typename?: 'BlockValueDistributionValuePerSqft';
  /** sample count in the block */
  count?: Maybe<Scalars['Int']>;
  /** maximum price per sqft in the block */
  max?: Maybe<Scalars['Float']>;
  /** median price per sqft in the block */
  mean?: Maybe<Scalars['Float']>;
  /** minimum price per sqft in the block */
  min?: Maybe<Scalars['Float']>;
  /** rental price per sqft of the area at 5th percentile */
  percentile5?: Maybe<Scalars['Float']>;
  /** rental price per sqft of the area at 25th percentile */
  percentile25?: Maybe<Scalars['Float']>;
  /** rental price per sqft of the area at 50th percentile (median) */
  percentile50?: Maybe<Scalars['Float']>;
  /** rental price per sqft of the area at 75th percentile */
  percentile75?: Maybe<Scalars['Float']>;
  /** rental price per sqft of the area at 95th percentile */
  percentile95?: Maybe<Scalars['Float']>;
  /** standard deviation of the price per sqft in the block */
  standardDeviation?: Maybe<Scalars['Float']>;
};

export type BlockValueForecast = {
  __typename?: 'BlockValueForecast';
  appreciation1Year?: Maybe<Scalars['Float']>;
};

export type BlockValueTimeSeriesElement = {
  __typename?: 'BlockValueTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** median price in the block */
  valueMedian?: Maybe<Scalars['Int']>;
  /** median price per sqft in the block */
  valuePerSqftMedian?: Maybe<Scalars['Float']>;
};

export type BoardDetails = {
  __typename?: 'BoardDetails';
  /** Coverage flag at the MLS level. This could be False but still considered covered through reciprocity. */
  covered?: Maybe<Scalars['Boolean']>;
  /** HouseCanary internal ID for an MLS */
  hcMlsId?: Maybe<Scalars['Int']>;
  /** MLS name */
  name?: Maybe<Scalars['String']>;
  /** MLS is covered through reciprocity (or a data share) with another covered MLS. */
  reciprocity?: Maybe<Scalars['Boolean']>;
};

export type CapRateFilterInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  operatingExpensesRate: Scalars['Float'];
  vacancyRate: Scalars['Float'];
};

export type CapRateOnCostSpatialFilterInput = {
  closingCosts: Scalars['Float'];
  leaseUpDays: Scalars['Int'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  operatingExpensesRate: Scalars['Float'];
  renovationCosts: Scalars['Float'];
  vacancyRate: Scalars['Float'];
};

export type CerberusAgentInput = {
  /** Agent license number */
  licenseNumber?: InputMaybe<Scalars['String']>;
  /** Agent license state */
  licenseStateAbbr?: InputMaybe<Scalars['String']>;
  /** Agent license state */
  licenseStateFips?: InputMaybe<Scalars['String']>;
  /** Agent name */
  name?: InputMaybe<Scalars['String']>;
  /** Agent name search method */
  nameSearchMethod?: InputMaybe<AgentNameSearchMethod>;
  /** Agent slug */
  slug?: InputMaybe<Scalars['String']>;
};

export type CerberusAgentStatisticInput = {
  /** County fips identifier */
  countyFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  listingStatus?: InputMaybe<AgentStatisticsListingStatus>;
  listingType?: InputMaybe<AgentStatisticsListingType>;
  /** MSA identifier */
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  national?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<AgentStatisticsPeriod>;
  representationType?: InputMaybe<AgentStatisticsRepresentationType>;
  sortField?: InputMaybe<AgentStatisticsField>;
  sortOrder?: InputMaybe<Order>;
  stateAbbrev?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** State fips identifier */
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** zip identifier */
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CerberusBlockGroupInput = {
  /** Block group identifier */
  blockGroupId?: InputMaybe<Scalars['String']>;
  /** property type identifier */
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type CerberusBlockInput = {
  /** block identifier */
  blockId?: InputMaybe<Scalars['String']>;
  /** histogram bin count (default is 5 bins) */
  histogramBinCount?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  /**  deprecated, use propertyTypeEnum */
  propertyType?: InputMaybe<Scalars['String']>;
  /** property type identifier */
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type CerberusInput = {
  /** type of application making the request */
  application?: InputMaybe<ApplicationType>;
  city?: InputMaybe<Scalars['String']>;
  /** data source to use */
  dataSource?: InputMaybe<DataSource>;
  /** Address identifier from Address API */
  hcAddressId?: InputMaybe<Scalars['NumericID']>;
  /** Identifies a property by unique HC assigned slug */
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  /** Street number and name. (i.e. 123 Main St) */
  streetAddress?: InputMaybe<Scalars['String']>;
  /** Unit number if applicable.  (i.e. Unit 12) */
  unit?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CerberusListingEntitiesInput = {
  /** type of application making the request */
  application?: InputMaybe<ApplicationType>;
  /** MLS Listing ID */
  listingId?: InputMaybe<Scalars['String']>;
};

export type CerberusListingEntitiesLookupResults = {
  __typename?: 'CerberusListingEntitiesLookupResults';
  /** Listing reference for results of searching by MLS Listing ID/Number. */
  listingEntities?: Maybe<Array<ListingEntity>>;
};

export type CerberusListingLookupResults = {
  __typename?: 'CerberusListingLookupResults';
  /** Full listing records. */
  listings?: Maybe<Array<Listing>>;
};

export type CerberusListingsInput = {
  /** type of application making the request */
  application?: InputMaybe<ApplicationType>;
  /** listing entity-id */
  entityId?: InputMaybe<Scalars['String']>;
  /** MLS Listing ID */
  listingId?: InputMaybe<Scalars['String']>;
};

export type CerberusLoanOfficerInput = {
  /** Loan Officer name */
  name?: InputMaybe<Scalars['String']>;
  /** Loan Officer name search method */
  nameSearchMethod?: InputMaybe<AgentNameSearchMethod>;
  /** Loan Officer NMLS ID */
  nmlsId?: InputMaybe<Scalars['String']>;
  /** Loan Officer slug */
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LoanOfficerType>;
};

export type CerberusLoanOfficerStatisticInput = {
  /** County fips identifier */
  countyFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  /** MSA identifier */
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  national?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<LoanOfficerStatisticsPeriod>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
  sortField?: InputMaybe<LoanOfficerStatisticsField>;
  sortOrder?: InputMaybe<Order>;
  stateAbbrev?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** State fips identifier */
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** zip identifier */
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CerberusMetroDivisionInput = {
  metroDivisionId?: InputMaybe<Scalars['String']>;
};

export type CerberusMlsIdentifierInput = {
  hcMlsID: Scalars['Int'];
};

export type CerberusMsaInput = {
  /** MSA identifier */
  msaId?: InputMaybe<Scalars['String']>;
};

export type CerberusStateInput = {
  /** State abbreviation */
  abbrev?: InputMaybe<Scalars['String']>;
  /** State fips identifier */
  fips?: InputMaybe<Scalars['String']>;
};

export type CerberusZipInput = {
  /** property type identifier */
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
  /** zip identifier */
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CommonLocation = {
  __typename?: 'CommonLocation';
  discovery?: Maybe<CommonLocationDiscovery>;
  hc?: Maybe<CommonLocationHc>;
};

export type CommonLocationDiscovery = {
  __typename?: 'CommonLocationDiscovery';
  hcAddress?: Maybe<CommonLocationDiscoveryHcAddress>;
};

export type CommonLocationDiscoveryHcAddress = {
  __typename?: 'CommonLocationDiscoveryHcAddress';
  /** Designates the particulars of where an entity exists */
  address?: Maybe<Scalars['String']>;
  /** An easy to read, non-hyphenated version of an address */
  addressFullLine?: Maybe<Scalars['String']>;
  /** An easy to read version of an address */
  addressSlug?: Maybe<Scalars['String']>;
  /** Census blockGroup identifier */
  blockGroupId?: Maybe<Scalars['String']>;
  /** Census blockGroup identifier from 2010 census */
  blockGroupId2010?: Maybe<Scalars['String']>;
  /** Census blockGroup identifier from 2020 census */
  blockGroupId2020?: Maybe<Scalars['String']>;
  /** Census block unique identifier */
  blockId?: Maybe<Scalars['String']>;
  /** Census block unique identifier from 2010 */
  blockId2010?: Maybe<Scalars['String']>;
  /** Census block unique identifier from 2020 */
  blockId2020?: Maybe<Scalars['String']>;
  /** The city/town/municipality associated with a particular location */
  city?: Maybe<Scalars['String']>;
  /** Boolean flag indicating whether or not an address is complete */
  completeYN?: Maybe<Scalars['Boolean']>;
  /** The encompassing county associated with a particular location */
  county?: Maybe<Scalars['String']>;
  /** A code which uniquely identified counties and county equivalents in the United States. (First 2 bytes = State; last 3 = County) */
  fips?: Maybe<Scalars['String']>;
  /** Relative level of geographic accuracy */
  geoPrecision?: Maybe<Scalars['String']>;
  /** HC assigned address_id */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  /** Lines that run east west (horizontally); measures distance north or south of the equator */
  latitude?: Maybe<Scalars['Float']>;
  /** Lines that run north south (vertically); measures distance east or west of the prime meridian */
  longitude?: Maybe<Scalars['Float']>;
  /** A unique identifier that represents one or more main/secondary counties delineated by an employment center or centers, plus adjacent counties associated with the main/secondary county or counties through commuting ties */
  metroDivisionId?: Maybe<Scalars['String']>;
  /** A unique identifier that represents a Census Metropolitan Statistical Area (area containing 50,000 or more inhabitants) */
  msaId?: Maybe<Scalars['String']>;
  /** Boolean flag indicating whether or not an address is verified as real through our internal address system */
  realYN?: Maybe<Scalars['Boolean']>;
  /** State associated with a given hcAddress */
  state?: Maybe<Scalars['String']>;
  /** Census tract unique identifier. Extracted from BlockID */
  tractId?: Maybe<Scalars['String']>;
  /** Census tract unique identifier from 2010 */
  tractId2010?: Maybe<Scalars['String']>;
  /** Census tract unique identifier from 2020 */
  tractId2020?: Maybe<Scalars['String']>;
  /** Unit associated with a given hcAddress */
  unit?: Maybe<Scalars['String']>;
  /** Boolean flag indicating whether or not an address is verified through our internal address system */
  verifiedYN?: Maybe<Scalars['Boolean']>;
  /** Zipcode associated with a location */
  zipcode?: Maybe<Scalars['String']>;
  /** ZipcodePlus4 associated with a location */
  zipcodePlus4?: Maybe<Scalars['String']>;
};

export type CommonLocationHc = {
  __typename?: 'CommonLocationHc';
  /** This is not populated. */
  neighborhood?: Maybe<Scalars['String']>;
  /** The hc derived or inferred name. This value could come directly from Assessor or Listing or the same values from adjacent properties. */
  subdivision?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHc = {
  __typename?: 'CommonPropertyDetailsHc';
  /** Assessors' Parcel Number is unique identifier assigned and maintained by the tax assessor for the property's jurisdiction. */
  apn?: Maybe<Scalars['String']>;
  association?: Maybe<Array<Maybe<CommonPropertyDetailsHcAssociation>>>;
  /** Amenities/services offered by HOA */
  associationAmenities?: Maybe<Scalars['String']>;
  associationEstimated?: Maybe<CommonPropertyDetailsHcAssociationEstimated>;
  /** Amenities/services supported by the HOA fee */
  associationFeeIncludes?: Maybe<Scalars['String']>;
  basement?: Maybe<CommonPropertyDetailsHcBasement>;
  bathrooms?: Maybe<CommonPropertyDetailsHcBathrooms>;
  bathroomsQuarter?: Maybe<CommonPropertyDetailsHcBathroomsQuarter>;
  /** The total number of bedrooms in the dwelling */
  bedrooms?: Maybe<Scalars['Int']>;
  /** The sum of BedroomsTotal plus other rooms that may be used as a bedroom but are not defined as bedroom per local policy */
  bedroomsPossible?: Maybe<Scalars['Int']>;
  /** HC enumerated construction type values of construction material descriptors */
  constructionTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** HC enumerated values of cooling features */
  cooling?: Maybe<Array<Maybe<Scalars['String']>>>;
  feesOther?: Maybe<Array<Maybe<CommonPropertyDetailsHcFeesOther>>>;
  /** Combined HOA and other Fees */
  feesTotalMonthly?: Maybe<Scalars['Float']>;
  femaFloodZones?: Maybe<CommonPropertyDetailsHcFemaFloodZones>;
  /** A numeric field that describes the level within the structure, SFR or a unit in a building, where the main entry to the dwelling is located.  When a unit has one floor it is implicit that this is also the level of the unit itself. Associated with EntryLevel in RESO */
  floorLocation?: Maybe<Scalars['Float']>;
  /** Boolean value indicating if there is a Home Owners Association.  A separate Y/N field is needed because not all associations have dues. */
  hasAssociation?: Maybe<Scalars['Boolean']>;
  /** HC enumerated values of heating features */
  heating?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The total livable area within the structure in square feet */
  livingArea?: Maybe<Scalars['Float']>;
  /** Finished area within the structure that is at or above the surface of the ground. Derived from the aboveGradeFinishedArea reso field. */
  livingAreaAboveGrade?: Maybe<Scalars['Float']>;
  /** Finished area within the structure that is below the surface of the ground. Derived from the belowGradeFinishedArea reso field. */
  livingAreaBelowGrade?: Maybe<Scalars['Float']>;
  /** Maximum GLA, if provided as a range from MLS */
  livingAreaRangeMax?: Maybe<Scalars['Float']>;
  /** Minimum GLA, if provided as a range from MLS */
  livingAreaRangeMin?: Maybe<Scalars['Float']>;
  /** The total area of the lot in square feet */
  lotSize?: Maybe<Scalars['Float']>;
  /** Maximum lot size in square feet, if provided as a range from MLS */
  lotSizeRangeMax?: Maybe<Scalars['Float']>;
  /** Minimum lot size in square feet, if provided as a range from MLS */
  lotSizeRangeMin?: Maybe<Scalars['Float']>;
  parcel?: Maybe<CommonPropertyDetailsHcParcel>;
  parking?: Maybe<CommonPropertyDetailsHcParking>;
  /** Boolean representing if a property record includes a community pool. */
  poolCommunityYN?: Maybe<Scalars['Boolean']>;
  /** Boolean representing if a property record includes a private pool. Returns null if private ownership is of low confidence. */
  poolYN?: Maybe<Scalars['Boolean']>;
  /** Enumerated field of ownership (residential/condo/pud/commercial/etc.) */
  propertyOwnership?: Maybe<Scalars['String']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  /** Concatenated string of a property record's property type and property subtype. */
  propertyTypeDescription?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** Internal field to trace generation of PropertyType field. */
  propertyTypeLogic?: Maybe<Scalars['String']>;
  /** The number of rooms in the dwelling. */
  roomsTotal?: Maybe<Scalars['Float']>;
  schools?: Maybe<CommonPropertyDetailsHcSchools>;
  /** Boolean representing if a property utilizes a public/city sewer, as opposed to a private/septic system */
  sewerPublicYN?: Maybe<Scalars['Boolean']>;
  /** Boolean for whether or not RESO terms indicates the existence of solar panels */
  solarPanelsYN?: Maybe<Scalars['Boolean']>;
  /** The total number of floors in the building.  In the case of multi-dwelling structures, this is the entire structure and not the individual dwelling being sold. */
  storiesNumber?: Maybe<Scalars['Float']>;
  tax?: Maybe<CommonPropertyDetailsHcTax>;
  taxRate?: Maybe<CommonPropertyDetailsHcTaxRate>;
  taxRateCounty?: Maybe<CommonPropertyDetailsHcTaxRateCounty>;
  /** Total number of units included in the income property, occupied or unoccupied. */
  unitsTotal?: Maybe<Scalars['Int']>;
  /** The year that an occupancy permit is first granted for the house or other local measure of initial habitability of the build. The type definition permits an empty value with an attribute noting that it is an unknown date or that the building is new construction. While constraints have not been applied, convention at the time of adoption has this as a four (4) digit year value. */
  yearBuilt?: Maybe<Scalars['Int']>;
  zoning?: Maybe<CommonPropertyDetailsHcZoning>;
};

export type CommonPropertyDetailsHcAssociation = {
  __typename?: 'CommonPropertyDetailsHcAssociation';
  /** Fee for property's home owner's association. Defaults to monthly fee if frequency is not explicitly provided */
  fee?: Maybe<Scalars['Float']>;
  /** Expected fee frequency for a property's home owner's association */
  frequency?: Maybe<Scalars['String']>;
  /** HOA name */
  name?: Maybe<Scalars['String']>;
  /** HOA phone */
  phone?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcAssociationEstimated = {
  __typename?: 'CommonPropertyDetailsHcAssociationEstimated';
  estimated?: Maybe<Scalars['Boolean']>;
  fee?: Maybe<Scalars['Float']>;
  feeTotalAnnual?: Maybe<Scalars['Float']>;
  feeTotalMonthly?: Maybe<Scalars['Float']>;
  frequency?: Maybe<AssociationFeeFrequency>;
  maxFee?: Maybe<Scalars['Float']>;
  minFee?: Maybe<Scalars['Float']>;
  nSamples?: Maybe<Scalars['Int']>;
  subdivision?: Maybe<Scalars['String']>;
  subdivisionId?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcBasement = {
  __typename?: 'CommonPropertyDetailsHcBasement';
  /** A list of information and features about the basement. i.e. None/Slab, Finished, Partially Finished, Crawl Space, Dirt, Outside Entrance, Radon Mitigation */
  description?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of information and features about the basement. i.e. None/Slab, Finished, Partially Finished, Crawl Space, Dirt, Outside Entrance, Radon Mitigation */
  finishedPct?: Maybe<Scalars['Float']>;
  /** A list of information and features about the basement. i.e. None/Slab, Finished, Partially Finished, Crawl Space, Dirt, Outside Entrance, Radon Mitigation */
  has?: Maybe<Scalars['Boolean']>;
  /** A list of information and features about the basement. i.e. None/Slab, Finished, Partially Finished, Crawl Space, Dirt, Outside Entrance, Radon Mitigation */
  totalArea?: Maybe<Scalars['Int']>;
};

export type CommonPropertyDetailsHcBathrooms = {
  __typename?: 'CommonPropertyDetailsHcBathrooms';
  displayDetails?: Maybe<CommonPropertyDetailsHcBathroomsDisplayDetails>;
  /** Count of distinct full or 3/4 bathrooms */
  full?: Maybe<Scalars['Int']>;
  /** Count of distinct 1/2, 1/4, or other partial bathrooms */
  partial?: Maybe<Scalars['Int']>;
  /** Count of distinct bathrooms */
  total?: Maybe<Scalars['Int']>;
  /** Decimal expression of total bathrooms to support sources that only provide a single bathroom value expressed as a decimal. This will be populated for all listings with any bathroom data. This should be used as a single field for searching. Full, 3/4 = 1 Partial, 1/2, 1/4 = 0.5 */
  totalProjected?: Maybe<Scalars['Float']>;
  /** Raw provided bathroom field that has no available (or unknown) breakdown of full and partial bathroom counts. */
  totalProvided?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcBathroomsDisplayDetails = {
  __typename?: 'CommonPropertyDetailsHcBathroomsDisplayDetails';
  /** If Bathrooms Quarter populated Full: #, 3/4: #, 1/2: #, 1/4: #. If Full or Partial have data then Full: #, Partial: # If "provided" has a value then Total Provided: # Else Total: # */
  description?: Maybe<Scalars['String']>;
  /** Only populated if used in display.description field */
  full?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  half?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  oneQuarter?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  partial?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  threeQuarter?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  total?: Maybe<Scalars['Int']>;
  /** Only populated if used in display.description field */
  totalProvided?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcBathroomsQuarter = {
  __typename?: 'CommonPropertyDetailsHcBathroomsQuarter';
  full?: Maybe<Scalars['Int']>;
  half?: Maybe<Scalars['Int']>;
  oneQuarter?: Maybe<Scalars['Int']>;
  threeQuarter?: Maybe<Scalars['Int']>;
};

export type CommonPropertyDetailsHcFeesOther = {
  __typename?: 'CommonPropertyDetailsHcFeesOther';
  /** The amount of non-HOA fee represented */
  amount?: Maybe<Scalars['Float']>;
  /** The unit in which the non-HOA fee is paid */
  amountUnit?: Maybe<Scalars['String']>;
  /** The frequency the non-HOA fee is paid.  For example, Weekly, Monthly, Annually, Bi-Monthly, One Time, etc. */
  frequency?: Maybe<Scalars['String']>;
  /** The type of non-HOA fee represented */
  type?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcFemaFloodZone = {
  __typename?: 'CommonPropertyDetailsHcFemaFloodZone';
  /** FEMA Flood Zone Effective Date */
  effectiveDate?: Maybe<Scalars['String']>;
  /** FEMA Flood Zone Risk */
  floodRisk?: Maybe<Scalars['Int']>;
  /** FEMA Flood Zone Panel Number */
  panelNumber?: Maybe<Scalars['String']>;
  /** FEMA Flood Zone designation */
  zone?: Maybe<ZoneType>;
  /** Fema Flood Zone description */
  zoneDescription?: Maybe<Scalars['String']>;
  zoneSubType?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcFemaFloodZones = {
  __typename?: 'CommonPropertyDetailsHcFemaFloodZones';
  byLatLong?: Maybe<Array<Maybe<CommonPropertyDetailsHcFemaFloodZone>>>;
};

export type CommonPropertyDetailsHcParcel = {
  __typename?: 'CommonPropertyDetailsHcParcel';
  geometry?: Maybe<Scalars['GeoJSON']>;
  parcelId?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcParking = {
  __typename?: 'CommonPropertyDetailsHcParking';
  /** A list of features or description of the parking included in the sale/lease. */
  description?: Maybe<Scalars['String']>;
  /** The number of spaces in the garage(s) */
  garage?: Maybe<Scalars['Float']>;
  parkingDetails?: Maybe<
    Array<Maybe<CommonPropertyDetailsHcParkingParkingDetails>>
  >;
  /** The total number of all types of parking spaces associated with the property record. */
  total?: Maybe<Scalars['Float']>;
};

export type CommonPropertyDetailsHcParkingParkingDetails = {
  __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
  /** Number of parking spaces represented by parkingDetails/type */
  count?: Maybe<Scalars['Float']>;
  /** HC enumerated values of the type of parking associated with the property record. (Garage, Carport, Covered, Open, Other) */
  type?: Maybe<Scalars['String']>;
};

export type CommonPropertyDetailsHcSchool = {
  __typename?: 'CommonPropertyDetailsHcSchool';
  assessmentYear?: Maybe<Scalars['Int']>;
  attendanceGeometry?: Maybe<Scalars['GeoJSON']>;
  city?: Maybe<Scalars['String']>;
  /** Distance to property in meters */
  distance?: Maybe<Scalars['Float']>;
  levels?: Maybe<Array<CommonPropertyDetailsHcSchoolLevel>>;
  location?: Maybe<Scalars['GeoJSON']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** Grade level served by the given school */
export enum CommonPropertyDetailsHcSchoolLevel {
  Elementary = 'ELEMENTARY',
  High = 'HIGH',
  Middle = 'MIDDLE',
}

export type CommonPropertyDetailsHcSchools = {
  __typename?: 'CommonPropertyDetailsHcSchools';
  elementary?: Maybe<Array<Maybe<CommonPropertyDetailsHcSchool>>>;
  high?: Maybe<Array<Maybe<CommonPropertyDetailsHcSchool>>>;
  middle?: Maybe<Array<Maybe<CommonPropertyDetailsHcSchool>>>;
};

export type CommonPropertyDetailsHcTax = {
  __typename?: 'CommonPropertyDetailsHcTax';
  /** The annual property tax amount as of the last assessment made by the taxing authority. */
  amountAnnual?: Maybe<Scalars['Float']>;
  /** A type of legal description for land in developed areas where streets or other rights-of-ways delineate large parcels of land referred to as divided into lots on which homes or other types of developments are built. Such a description would also reference an official plat filed with the clerk or recorder for that area which shows the location of the block and often the dimensions of the lots therein. The text here is also an index into the property as described by the County Recorder. */
  legalDescription?: Maybe<Scalars['String']>;
  /** The property value as of the last assessment made by the taxing authority. */
  valueAssessed?: Maybe<Scalars['Float']>;
  /** The property value as of the last assessment made by the market authority. */
  valueMarket?: Maybe<Scalars['Float']>;
  /** The year of the property value as of the last assessment made by the taxing authority. This year may not apply to the same year the property's Assessed Value or Market Value were determined. */
  year?: Maybe<Scalars['Int']>;
};

export type CommonPropertyDetailsHcTaxRate = {
  __typename?: 'CommonPropertyDetailsHcTaxRate';
  fips?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  taxRateCodeArea?: Maybe<Scalars['String']>;
  taxRateNonOwnerOccupied?: Maybe<Scalars['Float']>;
  taxRateOwnerOccupied?: Maybe<Scalars['Float']>;
};

export type CommonPropertyDetailsHcTaxRateCounty = {
  __typename?: 'CommonPropertyDetailsHcTaxRateCounty';
  fips?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  taxRateNonOwnerOccupied?: Maybe<Scalars['Float']>;
  taxRateOwnerOccupied?: Maybe<Scalars['Float']>;
};

export type CommonPropertyDetailsHcZoning = {
  __typename?: 'CommonPropertyDetailsHcZoning';
  /** A division of the city or county into areas of different permissible land uses. This Zone field should be used for the short code that is commonly used. For full textual descriptions please use the ZoningDescription field. */
  code?: Maybe<Scalars['String']>;
  /** A division of the city or county into areas of different permissible land uses. This Zone field should be used for the short code that is commonly used. For full textual descriptions please use the ZoningDescription field. */
  description?: Maybe<Scalars['String']>;
};

export type CompFieldBool = {
  __typename?: 'CompFieldBool';
  adjustment?: Maybe<Scalars['Int']>;
  compValue?: Maybe<Scalars['Boolean']>;
  diffValue?: Maybe<Scalars['String']>;
  subjectValue?: Maybe<Scalars['Boolean']>;
};

export type CompFieldFloat = {
  __typename?: 'CompFieldFloat';
  adjustment?: Maybe<Scalars['Int']>;
  compValue?: Maybe<Scalars['Float']>;
  diffValue?: Maybe<Scalars['Float']>;
  subjectValue?: Maybe<Scalars['Float']>;
};

export type CompFieldInt = {
  __typename?: 'CompFieldInt';
  adjustment?: Maybe<Scalars['Int']>;
  compValue?: Maybe<Scalars['Int']>;
  diffValue?: Maybe<Scalars['Int']>;
  subjectValue?: Maybe<Scalars['Int']>;
};

export type CompFieldPropertyType = {
  __typename?: 'CompFieldPropertyType';
  adjustment?: Maybe<Scalars['Int']>;
  /**
   * Use compValueEnum;
   * @deprecated No longer supported
   */
  compValue?: Maybe<PropertyType>;
  compValueEnum?: Maybe<PropertyTypeEnum>;
  diffValue?: Maybe<Scalars['String']>;
  /**
   * Use subjectValueEnum;
   * @deprecated No longer supported
   */
  subjectValue?: Maybe<PropertyType>;
  subjectValueEnum?: Maybe<PropertyTypeEnum>;
};

export type ComplexFields = {
  __typename?: 'ComplexFields';
  /** The listing address associated with the complex fields */
  currentLocation?: Maybe<CommonLocationDiscoveryHcAddress>;
  /** Complex fields document identifier used to join to other documents */
  documentId?: Maybe<Scalars['String']>;
  /** Complex fields document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** Complex fields entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** path to full complex fields document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /** address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  rental?: Maybe<ComplexFieldsRental>;
  sale?: Maybe<ComplexFieldsSale>;
  /** source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type ComplexFieldsMetadata = {
  __typename?: 'ComplexFieldsMetadata';
  /** document identifier */
  documentId?: Maybe<Scalars['String']>;
  /** document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** path to full document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /** address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  listingHistories?: Maybe<Array<Maybe<ComplexFieldsMetadataListingHistories>>>;
  /** source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type ComplexFieldsMetadataListingHistories = {
  __typename?: 'ComplexFieldsMetadataListingHistories';
  /** Not Supported Yet */
  currentDaysOnMarket?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  currentDaysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  currentDaysToClose?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  currentDaysToCloseCumulative?: Maybe<Scalars['Int']>;
  currentEntityId?: Maybe<Scalars['String']>;
  currentHcpyLookupKey?: Maybe<Scalars['String']>;
  /** Whether latest event is for a rental transaction */
  currentIsRental?: Maybe<Scalars['Boolean']>;
  currentListDate?: Maybe<Scalars['String']>;
  currentListingPrice?: Maybe<Scalars['Float']>;
  currentLocation?: Maybe<CommonLocationDiscoveryHcAddress>;
  /** @deprecated "Use currentPropertyTypeEnum" */
  currentPropertyType?: Maybe<Scalars['String']>;
  currentPropertyTypeEnum?: Maybe<PropertyTypeEnum>;
  currentStatus?: Maybe<Scalars['String']>;
  currentStatusDate?: Maybe<Scalars['String']>;
  currentlyOnMarket?: Maybe<Scalars['Boolean']>;
  daysOffMarket?: Maybe<Scalars['Int']>;
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  documentTimestamp?: Maybe<Scalars['String']>;
  eventHistory?: Maybe<
    Array<Maybe<ComplexFieldsMetadataListingHistoriesEventHistory>>
  >;
  hcMlsId?: Maybe<Scalars['Int']>;
  listingId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type ComplexFieldsMetadataListingHistoriesEventHistory = {
  __typename?: 'ComplexFieldsMetadataListingHistoriesEventHistory';
  date?: Maybe<Scalars['String']>;
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  displayStatus?: Maybe<Scalars['String']>;
  documentTimestamp?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  hcpyLookupKey?: Maybe<Scalars['String']>;
  /** Whether event is for a rental transaction */
  isRental?: Maybe<Scalars['Boolean']>;
  mlsTimestamp?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceVariance?: Maybe<Scalars['Float']>;
  sourceTimestamp?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ComplexFieldsRental = {
  __typename?: 'ComplexFieldsRental';
  /** (Rental) Number of days the rental listing has been on market since the last event in the history */
  currentDaysOnMarket?: Maybe<Scalars['Int']>;
  /** (Rental) Total number of days the rental listing has been on market (all time) */
  currentDaysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** (Rental) Number of days a rental listing has been on market since the last event in the history with a particular MLS */
  currentDaysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** (Rental) Number of days a rental listing has before the expected closing date */
  currentDaysToClose?: Maybe<Scalars['Int']>;
  /** (Rental) Total number of days a rental listing has before expected closing date (all time) */
  currentDaysToCloseCumulative?: Maybe<Scalars['Int']>;
  /** HC MLS identifier of the MLS referencing a rental */
  currentHcMlsId?: Maybe<Scalars['Int']>;
  /** Unique identifier used to join a rental listing to legacy hcpy data */
  currentHcpyLookupKey?: Maybe<Scalars['String']>;
  /** (Rental) The latest list date in which a rental listing was made public */
  currentListDate?: Maybe<Scalars['String']>;
  /** (Rental) The listing ID for the last event/status in the Listing Event History */
  currentListingId?: Maybe<Scalars['String']>;
  /** (Rental) The listing price of the rental listing since the last event in the history */
  currentListingPrice?: Maybe<Scalars['Float']>;
  /** (Rental) The current price of the rental listing */
  currentPrice?: Maybe<Scalars['Float']>;
  /** @deprecated "Use currentPropertyTypeEnum" */
  currentPropertyType?: Maybe<Scalars['String']>;
  currentPropertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** (Rental) The source ID (MLS) for the last event/status in the Listing Event History */
  currentSourceId?: Maybe<Scalars['String']>;
  /** (Rental) The standardized status for the last event/status in the Listing Event History */
  currentStatus?: Maybe<Scalars['String']>;
  /** (Rental) The effective date for the last event/status in the Listing Event History */
  currentStatusDate?: Maybe<Scalars['String']>;
  /** (Rental) Boolean representing whether or not the last event/status in the Listing Event History is active */
  currentlyOnMarket?: Maybe<Scalars['Boolean']>;
  /** (Rental) The final cross-listing days off market as of the Document Timestamp above */
  daysOffMarket?: Maybe<Scalars['Int']>;
  /** [To Be Replaced] The final cross-listing cumulative DOM as of the Document Timestamp above */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  eventHistory?: Maybe<Array<Maybe<ComplexFieldsRentalEventHistory>>>;
  /** (Rental) The effective date for the last closed event in the Listing Event History */
  lastCloseDate?: Maybe<Scalars['String']>;
  /** (Rental) Number of days the rental listing has been on market since the last closed event in the history */
  lastCloseDaysOnMarket?: Maybe<Scalars['Int']>;
  /** (Rental) Total number of days the rental listing has been on market since the last closed event (all time) */
  lastCloseDaysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** (Rental) Number of days a rental listing has been on market since the last closed event in the history with a particular MLS */
  lastCloseDaysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** (Rental) Number of days a rental listing's last closed event has before the expected closing date */
  lastCloseDaysToClose?: Maybe<Scalars['Int']>;
  /** (Rental) Number of days a rental listing's last closed event has before the expected closing date (all time) */
  lastCloseDaysToCloseCumulative?: Maybe<Scalars['Int']>;
  /** HC MLS identifier of the MLS referencing a rental listing */
  lastCloseHcMlsId?: Maybe<Scalars['Int']>;
  /** Unique identifier used to join a rental listing's last closed event to legacy hcpy data */
  lastCloseHcpyLookupKey?: Maybe<Scalars['String']>;
  /** (Rental) The last closed list date in which a rental listing was made public */
  lastCloseListDate?: Maybe<Scalars['String']>;
  /** ListingId of the last closed rental listing */
  lastCloseListingId?: Maybe<Scalars['String']>;
  /** (Rental) The list price for the last closed event in the Listing Event History */
  lastCloseListingPrice?: Maybe<Scalars['Float']>;
  /** (Rental) The list price for the last closed event in the Listing Event History */
  lastClosePrice?: Maybe<Scalars['Float']>;
  /** @deprecated "Use lastClosePropertyTypeEnum" */
  lastClosePropertyType?: Maybe<Scalars['String']>;
  lastClosePropertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** (Rental) Name of the source the last close was associated with */
  lastCloseSourceId?: Maybe<Scalars['String']>;
  /** (Rental) Type of source the last close was associated with */
  lastCloseSourceType?: Maybe<Scalars['String']>;
};

export type ComplexFieldsRentalEventHistory = {
  __typename?: 'ComplexFieldsRentalEventHistory';
  /** The address ID for the rental event */
  addressId?: Maybe<Scalars['Int']>;
  /** A boolean representing whether or not the rental has any history of arm's length transactions, or transactions where there is a transfer of property and the buyer and seller act independently of one another */
  armsLength?: Maybe<Scalars['Boolean']>;
  /** Effective date of rental event */
  date?: Maybe<Scalars['String']>;
  /** [To Be Replaced] The cumulative DOM up until this rental event's effective date */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** An HC-derived status that considers rental events before/after to set things like "Price Change" or multi-status updates in a single day */
  displayStatus?: Maybe<Scalars['String']>;
  /** A boolean representing whether or not the rental has any history of being flagged as distressed */
  distressed?: Maybe<Scalars['Boolean']>;
  /** Timestamp the rental event document was last updated in HC */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** The entity ID for the rental event document */
  entityId?: Maybe<Scalars['String']>;
  /** The HC-derived MLS id for a given event */
  hcMlsId?: Maybe<Scalars['Int']>;
  /** Unique identifier used to join a rental listing to legacy hcpy data */
  hcpyLookupKey?: Maybe<Scalars['String']>;
  listingGroupId?: Maybe<Scalars['Int']>;
  /** The listing ID for the rental event */
  listingId?: Maybe<Scalars['String']>;
  /** The modification timestamp for the rental event from the MLS */
  mlsTimestamp?: Maybe<Scalars['String']>;
  /** Effective price of rental event */
  price?: Maybe<Scalars['Float']>;
  priceChangeLastClose?: Maybe<Scalars['Float']>;
  priceChangePctLastClose?: Maybe<Scalars['Float']>;
  /** Percent change in price from the prior rental event in the same listing group */
  priceVariance?: Maybe<Scalars['Float']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** Boolean representing whether or not an instance of rental event history occurred during a foreclosure */
  reo?: Maybe<Scalars['Boolean']>;
  /** The source ID (e.g. MLS abbr) for the rental event */
  sourceId?: Maybe<Scalars['String']>;
  /** Timestamp of the event source record */
  sourceTimestamp?: Maybe<Scalars['String']>;
  /** Indicates the source type for the rental event (MLS/DEED/etc.) */
  sourceType?: Maybe<Scalars['String']>;
  /** Standard status of rental event */
  status?: Maybe<Scalars['String']>;
};

export type ComplexFieldsSale = {
  __typename?: 'ComplexFieldsSale';
  /** A boolean representing whether or not the current state of a listing has any history of arm's length transactions, or transactions where there is a transfer of property and the buyer and seller act independently of one another */
  currentArmsLength?: Maybe<Scalars['Boolean']>;
  /** (Sale) The cross-listing DOM of the current state of a listing as of the Document Timestamp above */
  currentDaysOnMarket?: Maybe<Scalars['Int']>;
  /** (Sale) The final cross-listing cumulative DOM of the current state of a listing as of the Document Timestamp above */
  currentDaysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** (Sale) The final cross-listing cumulative DOM of the current state of a listing as represented on a MLS */
  currentDaysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** (Sale) Number of days a sale listing has before the expected closing date */
  currentDaysToClose?: Maybe<Scalars['Int']>;
  /** (Sale) The final cumulative number of days a listing has until closing, as of the Document Timestamp above */
  currentDaysToCloseCumulative?: Maybe<Scalars['Int']>;
  /** (Sale) A boolean representing whether or not a listing was either under foreclosure, pre-foreclosure or control of the lender/bank from deed record at the time of the latest event */
  currentDistressed?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  currentFlipYN?: Maybe<Scalars['Boolean']>;
  /** (Sale) The HC-derived MLS identifier for a given sale */
  currentHcMlsId?: Maybe<Scalars['Int']>;
  /** (Sale) The HC identifier used to join a sale listing to legacy hcpy data */
  currentHcpyLookupKey?: Maybe<Scalars['String']>;
  /** (Sale) The list date for the last event/status in the listing event history */
  currentListDate?: Maybe<Scalars['Date']>;
  /** (Sale) The listing ID for the last event/status in the listing event history */
  currentListingId?: Maybe<Scalars['String']>;
  /** (Sale) The listing price of the sale listing during the last event in the listing event history */
  currentListingPrice?: Maybe<Scalars['Float']>;
  /** (Sale) A boolean representing whether or not the current price available for the transaction should be considered a fair market value price. This would be false for distressed sales that are valid arms length transfers. */
  currentMarketValuePrice?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  currentMultiParcel?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  currentPartialInterest?: Maybe<Scalars['Boolean']>;
  /** (Sale) the price associated with the current status. This will be the closePrice for Closed listings and listPrice otherwise. */
  currentPrice?: Maybe<Scalars['Float']>;
  /**
   * Use currentPropertyTypeEnum
   * @deprecated No longer supported
   */
  currentPropertyType?: Maybe<Scalars['String']>;
  currentPropertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** (Sale) The source ID (MLS) for the last event/status in the Listing Event History */
  currentSourceId?: Maybe<Scalars['String']>;
  /** (Sale) The standardized status for the last event/status in the Listing Event History */
  currentStatus?: Maybe<Scalars['String']>;
  /** (Sale) The effective date for the last event/status in the Listing Event History */
  currentStatusDate?: Maybe<Scalars['Date']>;
  /** (Sale) Whether the last event/status in the Listing Event History is active */
  currentlyOnMarket?: Maybe<Scalars['Boolean']>;
  /** (Sale) The final cross-listing days off market as of the Document Timestamp above */
  daysOffMarket?: Maybe<Scalars['Int']>;
  /** [Will be replaced] (Sale) The final cross-listing cumulative DOM as of the Document Timestamp above */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  eventHistory?: Maybe<Array<Maybe<ComplexFieldsSaleEventHistory>>>;
  /** Not Supported Yet */
  lastCloseArmsLength?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  lastCloseDate?: Maybe<Scalars['Date']>;
  /** Not Supported Yet */
  lastCloseDaysOnMarket?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseDaysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseDaysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseDaysToClose?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseDaysToCloseCumulative?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseDistressed?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  lastCloseFlipYN?: Maybe<Scalars['Boolean']>;
  /** (Sale) HC MLS identifier of the MLS referencing a sale event during its latest closed event */
  lastCloseHcMlsId?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  lastCloseHcpyLookupKey?: Maybe<Scalars['String']>;
  /** Not Supported Yet */
  lastCloseListDate?: Maybe<Scalars['Date']>;
  /** Not Supported Yet */
  lastCloseListingId?: Maybe<Scalars['String']>;
  /** Not Supported Yet */
  lastCloseListingPrice?: Maybe<Scalars['Float']>;
  /** Not Supported Yet */
  lastCloseMarketValuePrice?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  lastCloseMultiParcel?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  lastClosePartialInterest?: Maybe<Scalars['Boolean']>;
  /** Not Supported Yet */
  lastClosePrice?: Maybe<Scalars['Float']>;
  /**
   * Use lastClosePropertyTypeEnum
   * @deprecated No longer supported
   */
  lastClosePropertyType?: Maybe<Scalars['String']>;
  lastClosePropertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** Not Supported Yet */
  lastCloseSourceId?: Maybe<Scalars['String']>;
  /** Not Supported Yet */
  lastCloseSourceType?: Maybe<Scalars['String']>;
};

export type ComplexFieldsSaleEventHistory = {
  __typename?: 'ComplexFieldsSaleEventHistory';
  /** The address ID for the sale event */
  addressId?: Maybe<Scalars['Int']>;
  armsLength?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  buyer?: Maybe<Array<Maybe<ComplexFieldsSaleEventHistoryBuyer>>>;
  /** Effective date of sale event */
  date?: Maybe<Scalars['Date']>;
  /** The cumulative DOM up until this sale event's effective date */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** APN recorded with the deed linked to the event */
  deedApn?: Maybe<Scalars['String']>;
  /** loan amount recorded concurrently with the deed linked to the event */
  deedConcurrentLoanAmoun?: Maybe<Scalars['Float']>;
  /** From deed record */
  deedDate?: Maybe<Scalars['Date']>;
  /** From deed record */
  deedPrice?: Maybe<Scalars['Float']>;
  /** From deed record */
  deedType?: Maybe<Scalars['String']>;
  /** the code assigned by the public records vendor for the type of deed document recorded */
  deedVendorDocumentTypeCode?: Maybe<Scalars['String']>;
  /** the code assigned by the public records vendor for the origin of the sales price sent with the deed record */
  deedVendorSalesPriceCode?: Maybe<Scalars['String']>;
  /** An HC-derived status that considers sale events before/after to set things like "Price Change" or multi-status updates in a single day */
  displayStatus?: Maybe<Scalars['String']>;
  /** A boolean representing whether or not a listing was either under foreclosure, pre-foreclosure or control of the lender/bank from deed record at the time of an event */
  distressed?: Maybe<Scalars['Boolean']>;
  /** Timestamp the sale event document was last updated in HC */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** The entity ID for the sale event document */
  entityId?: Maybe<Scalars['String']>;
  /** event level indicator of whether there was an Arms Length sale within a year of this event's sale group being initiated */
  flipYN?: Maybe<Scalars['Boolean']>;
  hcMlsId?: Maybe<Scalars['Int']>;
  hcpyLookupKey?: Maybe<Scalars['String']>;
  listingGroupId?: Maybe<Scalars['Int']>;
  /** The listing ID for the sale event */
  listingId?: Maybe<Scalars['String']>;
  /** From deed record */
  marketValuePrice?: Maybe<Scalars['Boolean']>;
  /** The modification timestamp for the sale event from the MLS */
  mlsTimestamp?: Maybe<Scalars['String']>;
  /** From deed record */
  multiParcel?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  partialInterest?: Maybe<Scalars['Boolean']>;
  /** Effective price of sale event */
  price?: Maybe<Scalars['Float']>;
  priceChangeLastClose?: Maybe<Scalars['Float']>;
  priceChangePctLastClose?: Maybe<Scalars['Float']>;
  /** Percent change in price from the prior sale event in the same listing group */
  priceVariance?: Maybe<Scalars['Float']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** From deed record */
  recorderBook?: Maybe<Scalars['String']>;
  /** From deed record */
  recorderDocument?: Maybe<Scalars['String']>;
  /** From deed record */
  recorderPage?: Maybe<Scalars['String']>;
  reo?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  seller?: Maybe<Array<Maybe<ComplexFieldsSaleEventHistorySeller>>>;
  /** Boolean denoting whether this event was tied to a transaction flagged as a Short Sale */
  shortSale?: Maybe<Scalars['Boolean']>;
  /** The source ID (e.g. MLS abbr) for the sale event */
  sourceId?: Maybe<Scalars['String']>;
  /** Timestamp of the event source record */
  sourceTimestamp?: Maybe<Scalars['String']>;
  /** Indicates the source type for the sale event (MLS/DEED/etc.) */
  sourceType?: Maybe<Scalars['String']>;
  /** Standard status of sale event */
  status?: Maybe<Scalars['String']>;
  /** From deed record */
  transfer?: Maybe<Scalars['Boolean']>;
};

export type ComplexFieldsSaleEventHistoryBuyer = {
  __typename?: 'ComplexFieldsSaleEventHistoryBuyer';
  /** From deed record */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLastOrEntity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ComplexFieldsSaleEventHistorySeller = {
  __typename?: 'ComplexFieldsSaleEventHistorySeller';
  /** From deed record */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLastOrEntity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CountLimitInput = {
  limit?: InputMaybe<Scalars['Int']>;
  type: CountLimitType;
};

export enum CountLimitType {
  Auto = 'AUTO',
  Count = 'COUNT',
}

export type CountyDetails = {
  __typename?: 'CountyDetails';
  countyFips?: Maybe<Scalars['String']>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  name?: Maybe<Scalars['String']>;
};

export type CountyIdinput = {
  countyID?: InputMaybe<Scalars['String']>;
};

export type CountySearchResults = {
  __typename?: 'CountySearchResults';
  edges?: Maybe<Array<Maybe<CountySearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type CountySearchResultsEdges = {
  __typename?: 'CountySearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CountyDetails>;
};

export type CrimeStatisticDetails = {
  __typename?: 'CrimeStatisticDetails';
  /** incident count of past 2 years within the census block with 50m bubble around it */
  incidents?: Maybe<Scalars['Int']>;
  /** county level percentile of the incidents for the block */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of the incidents for the block */
  percentileNation?: Maybe<Scalars['Int']>;
};

export enum DataSource {
  DataFactory = 'DATA_FACTORY',
  Default = 'DEFAULT',
  Discovery = 'DISCOVERY',
}

export type DataSourceStatus = {
  __typename?: 'DataSourceStatus';
  switched?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Date']>;
};

export type DataStatus = {
  __typename?: 'DataStatus';
  avm?: Maybe<DataSourceStatus>;
  bgPrice?: Maybe<DataSourceStatus>;
  blockPrice?: Maybe<DataSourceStatus>;
  ltv?: Maybe<DataSourceStatus>;
  ltvorig?: Maybe<DataSourceStatus>;
  ltvverbose?: Maybe<DataSourceStatus>;
  rentalAvm?: Maybe<DataSourceStatus>;
  strengthOfOffer?: Maybe<DataSourceStatus>;
};

export type DataStatusResult = {
  __typename?: 'DataStatusResult';
  statuses?: Maybe<DataStatus>;
};

export type DynamicAvmInput = {
  bathroomCount?: InputMaybe<Scalars['Float']>;
  bedroomCount?: InputMaybe<Scalars['Int']>;
  buildingArea?: InputMaybe<Scalars['Int']>;
  poolYn?: InputMaybe<Scalars['Boolean']>;
  siteArea?: InputMaybe<Scalars['Int']>;
  totalNumberOfRooms?: InputMaybe<Scalars['Int']>;
  yearBuilt?: InputMaybe<Scalars['Year']>;
};

export type ExcludeZipcodesInput = {
  /** Zipcodes to exclude from results. NOTE: This is only implemented for ElasticSearch based spatial searches. */
  zipcodes: Array<InputMaybe<Scalars['String']>>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  precision?: Maybe<GeoPrecision>;
};

export type GeoLocationInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  precision?: InputMaybe<GeoPrecision>;
};

/** Geocoding precision level associated with translating an address to a lat/lon */
export enum GeoPrecision {
  /** Geocode supplied by a user.  Accuracy unknown. */
  Manual = 'manual',
  /** Exact geocode of the center of the parcel */
  Parcel = 'parcel',
  /** Exact geocode of the house within the parcel */
  Rooftop = 'rooftop',
  Street0 = 'street0',
  Unknown = 'unknown',
  Vendor = 'vendor',
  /** Inexact geocode.  Zip level accuracy. */
  Zip = 'zip',
  /** Inexact geocode.  Zip+1 level accuracy. */
  Zip6 = 'zip6',
  /** Inexact geocode.  Zip+2 level accuracy. */
  Zip7 = 'zip7',
  /** Inexact geocode.  Zip+3 level accuracy. */
  Zip8 = 'zip8',
  /** Inexact geocode.  Zip+4 level accuracy. */
  Zip9 = 'zip9',
}

export type GeojsonInput = {
  geometry: Scalars['GeoJSON'];
};

/** Supported geolocation formats */
export enum GeolocationFormat {
  /** dict format as {"longitude": 123, "latitude": 456} */
  Dict = 'DICT',
  /** geojson format */
  Geojson = 'GEOJSON',
}

export type HcListingStats = {
  __typename?: 'HCListingStats';
  /** Median sale-to-list price ratio computed as the closed sale price over the most recently listed price prior to the property going into contract. Result is expressed as a percentage */
  closeToListPriceMedian?: Maybe<Scalars['Float']>;
  /** Median sale-to-list price ratio computed as the closed sale price over the most recently listed price prior to the property going into contract. Result is expressed as a percentage */
  closeToListPriceMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** Median sale-to-list price ratio computed as the closed sale price over the original listed price. Result is expressed as a percentage */
  closeToListPriceOriginalMedian?: Maybe<Scalars['Float']>;
  /** Median sale-to-list price ratio computed as the closed sale price over the original listed price. Result is expressed as a percentage */
  closeToListPriceOriginalMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** Median days on market for all single family detached properties actively listed for sale or in contract as of the date specified */
  daysOnMarketMedian?: Maybe<Scalars['Int']>;
  /** Median days on market for all single family detached properties actively listed for sale or in contract as of the date specified */
  daysOnMarketMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median days on market for all single family detached properties actively listed for sale as of the date specified */
  daysOnMarketNotContractMedian?: Maybe<Scalars['Int']>;
  /** Median days on market for all single family detached properties actively listed for sale as of the date specified */
  daysOnMarketNotContractMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to close date, for single family detached properties going into closed sale */
  daysToCloseMedian?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to close date, for single family detached properties going into closed sale */
  daysToCloseMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to contract date, for single family detached properties going into contract */
  daysToContractMedian?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to contract date, for single family detached properties going into contract */
  daysToContractMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to the first price reduction, for listed single family detached properties which experienced a price reduction */
  daysToFirstPriceDropMedian?: Maybe<Scalars['Int']>;
  /** Median days on market from original list date to the first price reduction, for listed single family detached properties which experienced a price reduction */
  daysToFirstPriceDropMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Date of time series */
  eventDate?: Maybe<Scalars['Date']>;
  /** Total number of listed single family detached properties which went into closed sale status during the week ending on the date specified */
  listingsClosedCount?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties which went into closed sale status during the week ending on the date specified */
  listingsClosedCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Number of single family detached properties newly listed for sale during the week ending on the date specified */
  listingsNewCount?: Maybe<Scalars['Int']>;
  /** Number of single family detached properties newly listed for sale during the week ending on the date specified */
  listingsNewCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Total number of single family detached properties actively listed for sale, or in contract, as of the date specified */
  listingsOnMarketCount?: Maybe<Scalars['Int']>;
  /** Total number of single family detached properties actively listed for sale, or in contract, as of the date specified */
  listingsOnMarketCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties which experienced any price change during the week ending on the date specified */
  listingsPriceChangeCount?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties which experienced any price change during the week ending on the date specified */
  listingsPriceChangeCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  listingsPriceDropCount?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  listingsPriceDropCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Percent of all actively listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  listingsPriceDropPercent?: Maybe<Scalars['Float']>;
  /** Percent of all actively listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  listingsPriceDropPercentMovingAvg?: Maybe<Scalars['Float']>;
  /** Total number of listed single family detached properties removed from market during the week ending on the date specified (not due to going into contract or close) */
  listingsRemovedCount?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties removed from market during the week ending on the date specified (not due to going into contract or close) */
  listingsRemovedCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Percent of all actively listed single family detached properties removed from market during the week ending on the date specified (not due to going into contract or close) */
  listingsRemovedPercent?: Maybe<Scalars['Int']>;
  /** Percent of all actively listed single family detached properties removed from market during the week ending on the date specified (not due to going into contract or close) */
  listingsRemovedPercentMovingAvg?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties going into contract during the week ending on the date specified */
  listingsUnderContractCount?: Maybe<Scalars['Int']>;
  /** Total number of listed single family detached properties going into contract during the week ending on the date specified */
  listingsUnderContractCountMovingAvg?: Maybe<Scalars['Int']>;
  /** Percent of all actively listed single family detached properties in contract status as of the date specified */
  listingsUnderContractPercent?: Maybe<Scalars['Float']>;
  /** Percent of all actively listed single family detached properties in contract status as of the date specified */
  listingsUnderContractPercentMovingAvg?: Maybe<Scalars['Float']>;
  /** Median months of supply for all single family detached properties as of the date specified */
  monthsOfSupplyMedian?: Maybe<Scalars['Float']>;
  /** Median months of supply for all single family detached properties as of the date specified */
  monthsOfSupplyMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** The median percent change in price, for actively listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  priceChangePercentMedian?: Maybe<Scalars['Float']>;
  /** The median percent change in price, for actively listed single family detached properties which experienced a price reduction during the week ending on the date specified */
  priceChangePercentMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** Median sold price of single family detached properties going into closed sale status during the week ending on the date specified */
  priceClosedMedian?: Maybe<Scalars['Int']>;
  /** Median sold price of single family detached properties going into closed sale status during the week ending on the date specified */
  priceClosedMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median list price of single family detached properties newly listed for sale during the week ending on the date specified */
  priceNewListMedian?: Maybe<Scalars['Int']>;
  /** Median list price of single family detached properties newly listed for sale during the week ending on the date specified */
  priceNewListMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median list price of all actively listed properties as of the date specified */
  priceOnMarketMedian?: Maybe<Scalars['Int']>;
  /** Median list price of all actively listed properties as of the date specified */
  priceOnMarketMedianMovingAvg?: Maybe<Scalars['Int']>;
  /** Median sold price-per-square-foot of single family detached properties going into closed sale status during the week ending on the date specified */
  pricePerSqftClosedMedian?: Maybe<Scalars['Float']>;
  /** Median sold price-per-square-foot of single family detached properties going into closed sale status during the week ending on the date specified */
  pricePerSqftClosedMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** Median list price-per-square-foot of single family detached  properties newly listed for sale during the week ending on the date specified */
  pricePerSqftNewListMedian?: Maybe<Scalars['Float']>;
  /** Median list price-per-square-foot of single family detached  properties newly listed for sale during the week ending on the date specified */
  pricePerSqftNewListMedianMovingAvg?: Maybe<Scalars['Float']>;
  /** Median list price-per-square-foot of all actively listed single family detached properties as of the date specified */
  pricePerSqftOnMarketMedian?: Maybe<Scalars['Float']>;
  /** Median list price-per-square-foot of all actively listed single family detached properties as of the date specified */
  pricePerSqftOnMarketMedianMovingAvg?: Maybe<Scalars['Float']>;
  /**
   *  deprecated, use closeToListPriceMedian
   * @deprecated No longer supported
   */
  saleToListPriceMedian?: Maybe<Scalars['Float']>;
  /**
   *  deprecated, use closeToListPriceMedianMovingAvg
   * @deprecated No longer supported
   */
  saleToListPriceMedianMovingAvg?: Maybe<Scalars['Float']>;
  /**
   *  deprecated, use closeToListPriceOriginalMedian
   * @deprecated No longer supported
   */
  saleToListPriceOriginalMedian?: Maybe<Scalars['Float']>;
  /**
   *  deprecated, use closeToListPriceOriginalMedianMovingAvg
   * @deprecated No longer supported
   */
  saleToListPriceOriginalMedianMovingAvg?: Maybe<Scalars['Float']>;
};

export type HazardEarthquake = {
  __typename?: 'HazardEarthquake';
  /** expected maximum horizontal acceleration of the earthquake within next 50 years with 10% probability of being exceeded.  The value is in percentage, comparing to the gravity acceleration 9.8 (m/s2). */
  maxPercentGravity?: Maybe<Scalars['Float']>;
  /** county level percentile of maxPercentG */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of maxPercentG */
  percentileNation?: Maybe<Scalars['Int']>;
};

export type HazardHail = {
  __typename?: 'HazardHail';
  /** The energy score calculation of hailstones is based on the scaling factor (hail stone properties, such as size, density, and terminal velocity) and occurrences.  The accumulated energy is the summation of the historical energy scores. */
  accumulatedEnergy?: Maybe<Scalars['Float']>;
  /** county level percentile of accumulatedEnergy */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of accumulatedEnergy */
  percentileNation?: Maybe<Scalars['Int']>;
};

export type HazardHurricane = {
  __typename?: 'HazardHurricane';
  /** The energy calculation of hurricane is based on the power function (non-linear energy increase) over the maximum wind velocity of the hurricane passing over the block with 10 miles bubble, recorded every 6 hours over the duration of of the hurricane.  The accumulated energy is the summation of the historical energy score since 2004. */
  accumulatedEnergy?: Maybe<Scalars['Float']>;
  /** county level percentile of accumulatedEnergy */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of accumulatedEnergy */
  percentileNation?: Maybe<Scalars['Int']>;
};

export type HazardTornado = {
  __typename?: 'HazardTornado';
  /** The energy score calculation of a tornado is based on the power function (non-linear increase of energy) over the maximum wind velocity of the tornados passing over the block with 10 miles bubble, estimated from the Enhanced Fujita Scale categorization.  The accumulated energy is the summation of the historical energy scores. */
  accumulatedEnergy?: Maybe<Scalars['Float']>;
  /** county level percentile of accumulatedEnergy */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of accumulatedEnergy */
  percentileNation?: Maybe<Scalars['Int']>;
};

export type HazardWind = {
  __typename?: 'HazardWind';
  /** The energy calculation of windstorm is based on the power function (non-linear increase of energy) over the maximum wind velocity of the windstorm passing over the block with 10 miles bubble.  The accumulated energy is the summation of the historical energy scores. */
  accumulatedEnergy?: Maybe<Scalars['Float']>;
  /** county level percentile of accumulatedEnergy */
  percentileCounty?: Maybe<Scalars['Int']>;
  /** national level percentile of accumulatedEnergy */
  percentileNation?: Maybe<Scalars['Int']>;
};

export type HistogramFloatBinRange = {
  __typename?: 'HistogramFloatBinRange';
  /** count of entities in the bin */
  count?: Maybe<Scalars['Int']>;
  /** ceiling of the bin */
  end?: Maybe<Scalars['Float']>;
  /** floor of the bin */
  start?: Maybe<Scalars['Float']>;
};

export type HistogramIntBinRange = {
  __typename?: 'HistogramIntBinRange';
  /** count of entities in the bin */
  count?: Maybe<Scalars['Int']>;
  /** ceiling of the bin */
  end?: Maybe<Scalars['Int']>;
  /** floor of the bin */
  start?: Maybe<Scalars['Int']>;
};

export type HpiPerformanceCagr = {
  __typename?: 'HpiPerformanceCagr';
  /** 1-year historical compound annual growth rate (CAGR) */
  cagr1Year?: Maybe<Scalars['Float']>;
  /** Forecast 1-year compound annual growth rate (CAGR) */
  cagr1YearForecast?: Maybe<Scalars['Float']>;
  /** Spread between the area 1-year compound annual growth rate (CAGR) and all areas in the nation */
  cagr1YearZscore?: Maybe<Scalars['Float']>;
  /** Forecast 2-year compound annual growth rate (CAGR) */
  cagr2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast 3-year compound annual growth rate (CAGR) */
  cagr3YearForecast?: Maybe<Scalars['Float']>;
  /** 5-year historical compound annual growth rate (CAGR) */
  cagr5Year?: Maybe<Scalars['Float']>;
  /** Spread between the area 5-year compound annual growth rate (CAGR) and all areas in the nation */
  cagr5YearZscore?: Maybe<Scalars['Float']>;
  /** 10-year historical compound annual growth rate (CAGR) */
  cagr10Year?: Maybe<Scalars['Float']>;
  /** Spread between the area 10-year compound annual growth rate (CAGR) and all areas in the nation */
  cagr10YearZscore?: Maybe<Scalars['Float']>;
  /** 20-year historical compound annual growth rate (CAGR) */
  cagr20Year?: Maybe<Scalars['Float']>;
  /** Spread between the area 20-year compound annual growth rate (CAGR) and all areas in the nation */
  cagr20YearZscore?: Maybe<Scalars['Float']>;
  /** How many months went into the 'cagr' calculation (currently is always 240 because 'cagr' defaults to 20 years) */
  calcMonths?: Maybe<Scalars['Int']>;
};

export type HpiPerformanceReturn = {
  __typename?: 'HpiPerformanceReturn';
  /** Home price appreciation for the last 1 years based on HPI */
  return1Year?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 12 months based on HPI forecast */
  return1YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 24 months based on HPI forecast */
  return2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 36 months based on HPI forecast */
  return3YearForecast?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 5 years based on HPI */
  return5Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 10 years based on HPI */
  return10Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 20 years based on HPI */
  return20Year?: Maybe<Scalars['Float']>;
};

export type HpiPerformanceVolatility = {
  __typename?: 'HpiPerformanceVolatility';
  /** Measure of volatility in local HPI relative to national HPI. Values greater than 1 indicate that local HPI is more volatile than the nation overall. */
  beta?: Maybe<Scalars['Float']>;
  /** Number of standard deviations beta is from mean */
  betaZscore?: Maybe<Scalars['Float']>;
  /** Modigliani measure of risk adjusted returns of local HPI */
  modiglianiRiskAdjustedReturn?: Maybe<Scalars['Float']>;
  /** Spread between the area Modigliani risk-adjusted performance HPI and all areas in the nation */
  modiglianiRiskAdjustedReturnZscore?: Maybe<Scalars['Float']>;
  /** Sharpe ratio for the area (dimensionless) */
  sharpeRatio?: Maybe<Scalars['Float']>;
  /** Spread between the area Sharpe ratio and all areas in the nation */
  sharpeRatioZscore?: Maybe<Scalars['Float']>;
};

export type LandValue = {
  __typename?: 'LandValue';
  altitude?: Maybe<Scalars['Int']>;
  condition?: Maybe<Scalars['Int']>;
  fsd?: Maybe<Scalars['Float']>;
  hcAddressId?: Maybe<Scalars['NumericID']>;
  landAVMFlag?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Float']>;
  lotSize?: Maybe<Scalars['Int']>;
  lotSizeParcel?: Maybe<Scalars['Int']>;
  lotSizeUsed?: Maybe<Scalars['Int']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  quality?: Maybe<AvmQuality>;
  tract?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  valueLand?: Maybe<Scalars['Int']>;
  valueLandLower?: Maybe<Scalars['Int']>;
  valueLandPerSqFt?: Maybe<Scalars['Float']>;
  valueLandUpper?: Maybe<Scalars['Int']>;
};

export type LatestListing = {
  __typename?: 'LatestListing';
  rental?: Maybe<Listing>;
  sale?: Maybe<Listing>;
};

export type LatestState = {
  __typename?: 'LatestState';
  closedRental?: Maybe<LatestStateRental>;
  closedSale?: Maybe<LatestStateSale>;
  rental?: Maybe<LatestStateRental>;
  sale?: Maybe<LatestStateSale>;
};

export type LatestStateRental = {
  __typename?: 'LatestStateRental';
  /** The address ID for the rental event */
  addressId?: Maybe<Scalars['Int']>;
  /** A boolean representing whether or not the rental has any history of arm's length transactions, or transactions where there is a transfer of property and the buyer and seller act independently of one another */
  armsLength?: Maybe<Scalars['Boolean']>;
  /** Effective date of rental event */
  date?: Maybe<Scalars['String']>;
  /** [To Be Replaced] The cumulative DOM up until this rental event's effective date */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** An HC-derived status that considers rental events before/after to set things like "Price Change" or multi-status updates in a single day */
  displayStatus?: Maybe<Scalars['String']>;
  /** A boolean representing whether or not the rental has any history of being flagged as distressed */
  distressed?: Maybe<Scalars['Boolean']>;
  /** Timestamp the rental event document was last updated in HC */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** The entity ID for the rental event document */
  entityId?: Maybe<Scalars['String']>;
  /** The HC-derived MLS id for a given event */
  hcMlsId?: Maybe<Scalars['Int']>;
  /** Unique identifier used to join a rental listing to legacy hcpy data */
  hcpyLookupKey?: Maybe<Scalars['String']>;
  /** Listing involved in the transaction */
  listing?: Maybe<Listing>;
  listingGroupId?: Maybe<Scalars['Int']>;
  /** The listing ID for the rental event */
  listingId?: Maybe<Scalars['String']>;
  /** The modification timestamp for the rental event from the MLS */
  mlsTimestamp?: Maybe<Scalars['String']>;
  /** Effective price of rental event */
  price?: Maybe<Scalars['Float']>;
  priceChangeLastClose?: Maybe<Scalars['Float']>;
  priceChangePctLastClose?: Maybe<Scalars['Float']>;
  /** Percent change in price from the prior rental event in the same listing group */
  priceVariance?: Maybe<Scalars['Float']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** Boolean representing whether or not an instance of rental event history occurred during a foreclosure */
  reo?: Maybe<Scalars['Boolean']>;
  /** The source ID (e.g. MLS abbr) for the rental event */
  sourceId?: Maybe<Scalars['String']>;
  /** Timestamp of the event source record */
  sourceTimestamp?: Maybe<Scalars['String']>;
  /** Indicates the source type for the rental event (MLS/DEED/etc.) */
  sourceType?: Maybe<Scalars['String']>;
  /** Standard status of rental event */
  status?: Maybe<Scalars['String']>;
};

export type LatestStateSale = {
  __typename?: 'LatestStateSale';
  /** The address ID for the sale event */
  addressId?: Maybe<Scalars['Int']>;
  armsLength?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  buyer?: Maybe<Array<Maybe<ComplexFieldsSaleEventHistoryBuyer>>>;
  /** Effective date of sale event */
  date?: Maybe<Scalars['Date']>;
  /** The cumulative DOM up until this sale event's effective date */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** APN recorded with the deed linked to the event */
  deedApn?: Maybe<Scalars['String']>;
  /** loan amount recorded concurrently with the deed linked to the event */
  deedConcurrentLoanAmoun?: Maybe<Scalars['Float']>;
  /** From deed record */
  deedDate?: Maybe<Scalars['Date']>;
  /** From deed record */
  deedPrice?: Maybe<Scalars['Float']>;
  /** From deed record */
  deedType?: Maybe<Scalars['String']>;
  /** the code assigned by the public records vendor for the type of deed document recorded */
  deedVendorDocumentTypeCode?: Maybe<Scalars['String']>;
  /** the code assigned by the public records vendor for the origin of the sales price sent with the deed record */
  deedVendorSalesPriceCode?: Maybe<Scalars['String']>;
  /** An HC-derived status that considers sale events before/after to set things like "Price Change" or multi-status updates in a single day */
  displayStatus?: Maybe<Scalars['String']>;
  /** A boolean representing whether or not a listing was either under foreclosure, pre-foreclosure or control of the lender/bank from deed record at the time of an event */
  distressed?: Maybe<Scalars['Boolean']>;
  /** Timestamp the sale event document was last updated in HC */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** The entity ID for the sale event document */
  entityId?: Maybe<Scalars['String']>;
  /** event level indicator of whether there was an Arms Length sale within a year of this event's sale group being initiated */
  flipYN?: Maybe<Scalars['Boolean']>;
  hcMlsId?: Maybe<Scalars['Int']>;
  hcpyLookupKey?: Maybe<Scalars['String']>;
  listing?: Maybe<Listing>;
  listingGroupId?: Maybe<Scalars['Int']>;
  /** The listing ID for the sale event */
  listingId?: Maybe<Scalars['String']>;
  /** From deed record */
  marketValuePrice?: Maybe<Scalars['Boolean']>;
  /** The modification timestamp for the sale event from the MLS */
  mlsTimestamp?: Maybe<Scalars['String']>;
  /** From deed record */
  multiParcel?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  partialInterest?: Maybe<Scalars['Boolean']>;
  /** Effective price of sale event */
  price?: Maybe<Scalars['Float']>;
  priceChangeLastClose?: Maybe<Scalars['Float']>;
  priceChangePctLastClose?: Maybe<Scalars['Float']>;
  /** Percent change in price from the prior sale event in the same listing group */
  priceVariance?: Maybe<Scalars['Float']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** From deed record */
  recorderBook?: Maybe<Scalars['String']>;
  /** From deed record */
  recorderDocument?: Maybe<Scalars['String']>;
  /** From deed record */
  recorderPage?: Maybe<Scalars['String']>;
  reo?: Maybe<Scalars['Boolean']>;
  /** From deed record */
  seller?: Maybe<Array<Maybe<ComplexFieldsSaleEventHistorySeller>>>;
  /** Boolean denoting whether this event was tied to a transaction flagged as a Short Sale */
  shortSale?: Maybe<Scalars['Boolean']>;
  /** The source ID (e.g. MLS abbr) for the sale event */
  sourceId?: Maybe<Scalars['String']>;
  /** Timestamp of the event source record */
  sourceTimestamp?: Maybe<Scalars['String']>;
  /** Indicates the source type for the sale event (MLS/DEED/etc.) */
  sourceType?: Maybe<Scalars['String']>;
  /** Standard status of sale event */
  status?: Maybe<Scalars['String']>;
  /** From deed record */
  transfer?: Maybe<Scalars['Boolean']>;
};

export type LienHistory = {
  __typename?: 'LienHistory';
  /** document identifier */
  documentId?: Maybe<Scalars['String']>;
  /** document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** entity identifier */
  entityId?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<LienHistoryEvents>>>;
  eventsNOD?: Maybe<Array<Maybe<LienHistoryEventsNod>>>;
  /** path to full document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /**
   *  hcAddressId; deprecated, use top level lookup address identifier from address api
   * @deprecated No longer supported
   */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  loanCutoffDate?: Maybe<Scalars['Date']>;
  loanToValue?: Maybe<LienHistoryLoanToValue>;
  loanToValueNew?: Maybe<LienHistoryLoanToValueNew>;
  /** source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type LienHistoryEventsArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']>;
};

export type LienHistoryEventsNodArgs = {
  onlyCurrent?: InputMaybe<Scalars['Boolean']>;
};

export type LienHistoryEvents = {
  __typename?: 'LienHistoryEvents';
  adjustableRate1stChangeMax?: Maybe<Scalars['Float']>;
  adjustableRate1stChangeMin?: Maybe<Scalars['Float']>;
  adjustableRateChangeDate?: Maybe<Scalars['Date']>;
  adjustableRateChangeMonths?: Maybe<Scalars['Int']>;
  adjustableRateDuration?: Maybe<Scalars['Int']>;
  adjustableRateIndex?: Maybe<Scalars['String']>;
  adjustableRateMargin?: Maybe<Scalars['Float']>;
  adjustableRateMax?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  apn?: Maybe<Scalars['String']>;
  borrower?: Maybe<Array<Maybe<LienHistoryEventsBorrower>>>;
  contractDate?: Maybe<Scalars['Date']>;
  /** Recorded since the last transfer of the property */
  current?: Maybe<Scalars['Boolean']>;
  dueDate?: Maybe<Scalars['Date']>;
  eventType?: Maybe<Scalars['String']>;
  hc?: Maybe<LienHistoryEventsHc>;
  interestOnlyMonths?: Maybe<Scalars['Int']>;
  interestRateRecorded?: Maybe<Scalars['LooseFloat']>;
  issues?: Maybe<Array<Maybe<LoanIssueType>>>;
  lender?: Maybe<LienHistoryEventsLender>;
  loanOfficer?: Maybe<LoanOfficerDetails>;
  loanOfficerName?: Maybe<Scalars['String']>;
  loanOfficerNmlsID?: Maybe<Scalars['String']>;
  loanOrganization?: Maybe<LoanOfficerDetails>;
  loanOrganizationName?: Maybe<Scalars['String']>;
  loanOrganizationNmlsID?: Maybe<Scalars['String']>;
  loanType?: Maybe<LoanType>;
  mortgageBroker?: Maybe<LoanOfficerDetails>;
  mortgageBrokerName?: Maybe<Scalars['String']>;
  mortgageBrokerNmlsID?: Maybe<Scalars['String']>;
  recorderBookNumber?: Maybe<Scalars['String']>;
  recorderDocumentNumber?: Maybe<Scalars['String']>;
  recorderPageNumber?: Maybe<Scalars['String']>;
  recordingDate?: Maybe<Scalars['Date']>;
  /** source identifier of event */
  sourceId?: Maybe<Scalars['String']>;
  /** Source provided timestamp for event */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type LienHistoryEventsBorrower = {
  __typename?: 'LienHistoryEventsBorrower';
  idcerberus?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameFirst?: Maybe<Scalars['String']>;
  nameLastOrEntity?: Maybe<Scalars['String']>;
};

export type LienHistoryEventsHc = {
  __typename?: 'LienHistoryEventsHc';
  dataAssumptions?: Maybe<Array<Maybe<LoanIssueType>>>;
  dataAssumptionsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dataAssumptionsMask?: Maybe<Scalars['Int']>;
  dataAssumptionsScore?: Maybe<Scalars['Int']>;
  initialInterestRateEstimated?: Maybe<Scalars['Boolean']>;
  initialInterestRateMissing?: Maybe<Scalars['Boolean']>;
  interestRate15YFred?: Maybe<Scalars['Float']>;
  interestRate30YFred?: Maybe<Scalars['Float']>;
  interestRateUsed?: Maybe<Scalars['Float']>;
  isArm?: Maybe<Scalars['Boolean']>;
  loanLengthMonths?: Maybe<Scalars['Int']>;
  loanMonthsCompleted?: Maybe<Scalars['Int']>;
  monthlyPayment?: Maybe<Scalars['Float']>;
  principalOutstanding?: Maybe<Scalars['Float']>;
  principalPaid?: Maybe<Scalars['Float']>;
  seriesUsed?: Maybe<Scalars['String']>;
};

export type LienHistoryEventsLender = {
  __typename?: 'LienHistoryEventsLender';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LienHistoryEventsNod = {
  __typename?: 'LienHistoryEventsNod';
  addressSourceDocument?: Maybe<Scalars['String']>;
  amountPastDue?: Maybe<Scalars['Int']>;
  amountPastDueAsOfDate?: Maybe<Scalars['Date']>;
  apn?: Maybe<Scalars['String']>;
  auctionCity?: Maybe<Scalars['String']>;
  auctionDate?: Maybe<Scalars['Date']>;
  auctionLocation?: Maybe<Scalars['String']>;
  auctionMinimumBidAmount?: Maybe<Scalars['Int']>;
  auctionTime?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryOriginalName?: Maybe<Scalars['String']>;
  beneficiaryOriginalType?: Maybe<Scalars['String']>;
  beneficiaryType?: Maybe<Scalars['String']>;
  borrower?: Maybe<Array<Maybe<LienHistoryEventsBorrower>>>;
  caseId?: Maybe<Scalars['String']>;
  contactAddressCity?: Maybe<Scalars['String']>;
  contactAddressFull?: Maybe<Scalars['String']>;
  contactAddressState?: Maybe<Scalars['String']>;
  contactAddressUnitNumber?: Maybe<Scalars['String']>;
  contactAddressZipCode?: Maybe<Scalars['String']>;
  contactAddressZipCodePlusFour?: Maybe<Scalars['String']>;
  contactFirstNameMiddleName?: Maybe<Scalars['String']>;
  contactLastNameOrCorpName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['Date']>;
  countyName?: Maybe<Scalars['String']>;
  /** Recorded since the last transfer of the property */
  current?: Maybe<Scalars['Boolean']>;
  installmentPaymentDueDate?: Maybe<Scalars['Date']>;
  legalDescription?: Maybe<Scalars['String']>;
  legalDescriptionBlock?: Maybe<Scalars['String']>;
  legalDescriptionLot?: Maybe<Scalars['String']>;
  legalDescriptionSubdivision?: Maybe<Scalars['String']>;
  lenderName?: Maybe<Scalars['String']>;
  lenderType?: Maybe<Scalars['String']>;
  loanAmount?: Maybe<Scalars['Int']>;
  loanMersRegisteredYN?: Maybe<Scalars['Boolean']>;
  loanNumber?: Maybe<Scalars['String']>;
  loanRecorderBookNumber?: Maybe<Scalars['String']>;
  loanRecorderDocumentNumber?: Maybe<Scalars['String']>;
  loanRecorderPageNumber?: Maybe<Scalars['String']>;
  loanRecordingDate?: Maybe<Scalars['Date']>;
  nodTypeCategory?: Maybe<Scalars['String']>;
  nodTypeCode?: Maybe<Scalars['String']>;
  nodTypeDescription?: Maybe<Scalars['String']>;
  nodTypeEnum?: Maybe<Scalars['String']>;
  originalNodRecorderBookNumber?: Maybe<Scalars['String']>;
  originalNodRecorderDocumentNumber?: Maybe<Scalars['String']>;
  originalNodRecorderPageNumber?: Maybe<Scalars['String']>;
  originalNodRecordingDate?: Maybe<Scalars['Date']>;
  recorderBookNumber?: Maybe<Scalars['String']>;
  recorderDocumentNumber?: Maybe<Scalars['String']>;
  recorderPageNumber?: Maybe<Scalars['String']>;
  recordingDate?: Maybe<Scalars['Date']>;
  /** source provided timestamp for event */
  sourceTimestamp?: Maybe<Scalars['String']>;
  statePostalCode?: Maybe<Scalars['String']>;
  trusteeAddressCity?: Maybe<Scalars['String']>;
  trusteeAddressFull?: Maybe<Scalars['String']>;
  trusteeAddressState?: Maybe<Scalars['String']>;
  trusteeAddressUnitNumber?: Maybe<Scalars['String']>;
  trusteeAddressZipCode?: Maybe<Scalars['String']>;
  trusteeAddressZipCodePlusFour?: Maybe<Scalars['String']>;
  trusteeFirstNameMiddleName?: Maybe<Scalars['String']>;
  trusteeLastNameOrCorpName?: Maybe<Scalars['String']>;
  trusteePhone?: Maybe<Scalars['String']>;
  trusteeSaleId?: Maybe<Scalars['String']>;
  unpaidBalance?: Maybe<Scalars['Int']>;
  vendorDocumentId?: Maybe<Scalars['String']>;
  vendorDocumentIdDeed?: Maybe<Scalars['String']>;
  vendorDocumentIdSam?: Maybe<Scalars['String']>;
};

export type LienHistoryLoanToValue = {
  __typename?: 'LienHistoryLoanToValue';
  asOfMonth?: Maybe<Scalars['Date']>;
  dataAssumptions?: Maybe<Array<Maybe<LoanIssueType>>>;
  defaultLastDate?: Maybe<Scalars['Date']>;
  defaultYN?: Maybe<Scalars['Boolean']>;
  equity?: Maybe<Scalars['Int']>;
  equityLower?: Maybe<Scalars['Int']>;
  equityUpper?: Maybe<Scalars['Int']>;
  forecast?: Maybe<LoanToValueForecast>;
  lienAmountTotal?: Maybe<Scalars['Int']>;
  liensCurrent?: Maybe<Array<Maybe<LoanToValueLiensCurrent>>>;
  loanToValue?: Maybe<Scalars['Float']>;
  loanToValueLower?: Maybe<Scalars['Float']>;
  loanToValueUpper?: Maybe<Scalars['Float']>;
  origination?: Maybe<LoanToValueOrigination>;
  principalOutstandingTotal?: Maybe<Scalars['Int']>;
  principalPaidTotal?: Maybe<Scalars['Int']>;
  totalMonthlyPayments?: Maybe<Scalars['Int']>;
};

export type LienHistoryLoanToValueNew = {
  __typename?: 'LienHistoryLoanToValueNew';
  asOf?: Maybe<Scalars['Date']>;
  dataAssumptions?: Maybe<Array<Maybe<LoanIssueType>>>;
  dataAssumptionsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dataAssumptionsMask?: Maybe<Scalars['Int']>;
  dataAssumptionsScore?: Maybe<Scalars['Int']>;
  defaultLastDate?: Maybe<Scalars['Date']>;
  defaultYN?: Maybe<Scalars['Boolean']>;
  equity?: Maybe<Scalars['Int']>;
  equityLower?: Maybe<Scalars['Int']>;
  equityUpper?: Maybe<Scalars['Int']>;
  forecast?: Maybe<LoanToValueForecast>;
  lienEventsUsed?: Maybe<Array<Maybe<LienHistoryEvents>>>;
  loanToValue?: Maybe<Scalars['Float']>;
  loanToValueLower?: Maybe<Scalars['Float']>;
  loanToValueUpper?: Maybe<Scalars['Float']>;
  origination?: Maybe<LoanToValueOrigination>;
  principalOutstandingTotal?: Maybe<Scalars['Int']>;
  principalPaidTotal?: Maybe<Scalars['Int']>;
  totalLienAmount?: Maybe<Scalars['Int']>;
  totalMonthlyPayments?: Maybe<Scalars['Int']>;
};

export type ListDateOrSaleDateInput = {
  maxListDate?: InputMaybe<Scalars['Date']>;
  maxSaleDate?: InputMaybe<Scalars['Date']>;
  minListDate?: InputMaybe<Scalars['Date']>;
  minSaleDate?: InputMaybe<Scalars['Date']>;
};

export type Listing = {
  __typename?: 'Listing';
  /** dict of keys to join with agent documents */
  agentJoinId?: Maybe<ListingAgentJoinId>;
  /** Details of the Agents (and their Offices/Brokerages) associated with the listing and transaction. This includes Sell and Buy side agents. */
  agentOffice?: Maybe<ListingAgentOffice>;
  /** Content hash of fields used in complex fields calculations */
  complexFieldsContentHash?: Maybe<Scalars['String']>;
  /** Indicates a record that has been deleted. Deleted listings should not be used for any external service. */
  deleted?: Maybe<Scalars['Boolean']>;
  derived?: Maybe<ListingDerived>;
  /** data-factory identifier */
  dfId?: Maybe<Scalars['Int']>;
  /** document identifier */
  documentId?: Maybe<Scalars['String']>;
  /** document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** path to full document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /** address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  /** Address-slug from address api */
  hcAddressSlug?: Maybe<Scalars['String']>;
  /** Key to lookup information in hcpy system */
  hcpyLookupKey?: Maybe<Scalars['String']>;
  /** Identifiers and references for the listing, including the Listing ID aka MLS Number. */
  id?: Maybe<ListingIdcerberus>;
  /** Attributes related to the listing contract and transaction. */
  listingDetails?: Maybe<ListingListingDetails>;
  /** Details related to the location of the property as normalized and geocoded by HouseCanary and as provided with the listing. */
  location?: Maybe<ListingLocation>;
  /** Details of the media tied to the listing. This is primarily Photo urls as hosted by HouseCanary. */
  media?: Maybe<ListingMedia>;
  mls?: Maybe<ListingMls>;
  /** Not Support with data yet. This structure allows for the capture of MLS specific fields and their values. */
  mlsDirect?: Maybe<ListingMlsDirect>;
  /** dict of keys to join with office documents */
  officeJoinId?: Maybe<ListingOfficeJoinId>;
  /** Key to join with open-house documents */
  openHouseJoinId?: Maybe<Scalars['String']>;
  /** Key to join with photos documents */
  photosJoinId?: Maybe<Scalars['String']>;
  /** Attributes related to the physical details of the subject property's land and structure as covered in the listing. */
  propertyDetails?: Maybe<ListingPropertyDetails>;
  /** Content hash of fields used in research fields calculations */
  researchFieldsContentHash?: Maybe<Scalars['String']>;
  /** Field in source that sourceCreatedTimestamp came from */
  sourceCreatedField?: Maybe<Scalars['String']>;
  /** Source provided timestamp for data created */
  sourceCreatedTimestamp?: Maybe<Scalars['String']>;
  /** Source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** Source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type ListingAgentJoinId = {
  __typename?: 'ListingAgentJoinID';
  /** agent join id for buying agent */
  buyer?: Maybe<Scalars['String']>;
  /** agent join id for co-buying agent */
  coBuyer?: Maybe<Scalars['String']>;
  /** agent join id for co-listing agent */
  coList?: Maybe<Scalars['String']>;
  /** agent join id for second co-listing agent */
  coList2?: Maybe<Scalars['String']>;
  /** agent join id for third co-listing agent */
  coList3?: Maybe<Scalars['String']>;
  /** agent join id for listing agent */
  list?: Maybe<Scalars['String']>;
};

export type ListingAgentOffice = {
  __typename?: 'ListingAgentOffice';
  hc?: Maybe<ListingAgentOfficeHc>;
};

export type ListingAgentOfficeHc = {
  __typename?: 'ListingAgentOfficeHc';
  agentOffice?: Maybe<Array<Maybe<ListingAgentOfficeHcAgentOffice>>>;
};

export type ListingAgentOfficeHcAgentOffice = {
  __typename?: 'ListingAgentOfficeHcAgentOffice';
  /** Key that joins listing documents to an agent from agentOffice documents */
  agentKey?: Maybe<Scalars['String']>;
  /** Listing Agent/Office AgentMls identifier */
  agentMlsId?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Agent email */
  email?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Agent license number */
  licenseNumber?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Agent Name */
  name?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Office Name */
  office?: Maybe<Scalars['String']>;
  /** Key that joins listing documents to MLS office documents */
  officeKey?: Maybe<Scalars['String']>;
  /** Listing Agent/Office OfficeMls identifier */
  officeMlsId?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Agent Phone Number. Only available by default from the Agent resource. */
  phone?: Maybe<Scalars['String']>;
  /** Listing Agent/Office Office Phone Number. Only available by default from the Agent resource. */
  phoneOffice?: Maybe<Scalars['String']>;
  /** Identifies agent/office type (listing, buying, co-listing, etc.) */
  type?: Maybe<Scalars['String']>;
};

export type ListingDerived = {
  __typename?: 'ListingDerived';
  hc?: Maybe<ListingDerivedHc>;
};

export type ListingDerivedHc = {
  __typename?: 'ListingDerivedHc';
  listingCondition?: Maybe<ListingDerivedHcListingCondition>;
  /** Strength Of Offer */
  strengthOfOffer: StrengthOfOffer;
};

export type ListingDerivedHcStrengthOfOfferArgs = {
  offerPrice?: InputMaybe<Scalars['Int']>;
};

export type ListingDerivedHcListingCondition = {
  __typename?: 'ListingDerivedHcListingCondition';
  conditionClass?: Maybe<Scalars['Int']>;
  /** A score (0-100) based on a listing's estimated physical condition (calculated by image analysis). Higher scores mean better conditions. */
  confidenceScore?: Maybe<Scalars['Float']>;
  /** A score (0-100) based on estimated physical condition of local listings. Higher scores mean better conditions. */
  localScore?: Maybe<Scalars['Int']>;
  /** A score (0-100) based on estimated physical condition of national listings. Higher scores mean better conditions. */
  nationalScore?: Maybe<Scalars['Int']>;
  /** Score derived through Image Classification process; used to then generate local and national percentiles. */
  score?: Maybe<Scalars['Float']>;
};

export type ListingEntity = {
  __typename?: 'ListingEntity';
  /** whether document is deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  hcMlsId?: Maybe<Scalars['Int']>;
  /** source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type ListingIdcerberus = {
  __typename?: 'ListingIdcerberus';
  discovery?: Maybe<ListingIdcerberusDiscovery>;
  hc?: Maybe<ListingIdcerberusHc>;
};

export type ListingIdcerberusDiscovery = {
  __typename?: 'ListingIdcerberusDiscovery';
  /** Listing address identifier */
  addressId?: Maybe<Scalars['NumericID']>;
  /** Listing building identifier */
  buildingAddressId?: Maybe<Scalars['NumericID']>;
  /** Internal listing MLS identifier */
  hcMlsId?: Maybe<Scalars['Int']>;
  /** Listing's unique ID of each document */
  listingDocumentId?: Maybe<Scalars['String']>;
  /** Listing group identifier used to organize listings across multiple MLSs within complex fields */
  listingGroupId?: Maybe<Scalars['String']>;
  /** Listing ID from MLS */
  listingId?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  /** Sale group identifier used for sale transactions from deed vs. MLS to connect listings to transactions */
  saleGroupId?: Maybe<Scalars['String']>;
};

export type ListingIdcerberusHc = {
  __typename?: 'ListingIdcerberusHc';
  /** HC provided APN (Assessor's Parcel Number) identifier used by local tax assessor */
  apn?: Maybe<Scalars['String']>;
};

export type ListingListingDetails = {
  __typename?: 'ListingListingDetails';
  discovery?: Maybe<ListingListingDetailsDiscovery>;
  hc?: Maybe<ListingListingDetailsHc>;
  reso?: Maybe<ListingListingDetailsReso>;
};

export type ListingListingDetailsDiscovery = {
  __typename?: 'ListingListingDetailsDiscovery';
  /** Calculated value to determine display priority */
  listingPriority?: Maybe<Scalars['Int']>;
};

export type ListingListingDetailsHc = {
  __typename?: 'ListingListingDetailsHc';
  /** Boolean representing whether or not a listing's address is allowed to be displayed */
  addressDisplayNo?: Maybe<Scalars['Boolean']>;
  /** Boolean representing whether or not listing is age restricted, such as 55+ or senior community listings. */
  ageRestricted?: Maybe<Scalars['Boolean']>;
  /** Boolean representing whether or not a listing was transacted at arm's length (a transaction between two parties with no prior relationship to each other) */
  armsLength?: Maybe<Scalars['Boolean']>;
  /** Boolean representing whether or not a listing's AVM can be displayed */
  avmDisplayNo?: Maybe<Scalars['Boolean']>;
  /** Boolean representing whether or not a listing's BuyerBrokerage compensation can be displayed */
  buyerBrokerageCompensationDisplay?: Maybe<Scalars['String']>;
  /** The listing date that ownership of a property is officially transferred from the seller to the buyer */
  closeDate?: Maybe<Scalars['String']>;
  /** The amount of money paid by the purchaser to the seller for the property under the agreement. */
  closePrice?: Maybe<Scalars['Float']>;
  /** Denotes whether or not the listing's comments can be displayed */
  commentsDisplayNo?: Maybe<Scalars['Boolean']>;
  concessions?: Maybe<ListingListingDetailsHcConcessions>;
  /** The latest provided price associated with the listing. This will be the closePrice for Closed listings and listPrice otherwise. */
  currentPrice?: Maybe<Scalars['Float']>;
  /** Simple projection of Standard Status to one of the following values: Active, Contract, SettledSale, OffMarket, Leased, Deleted, Unknown */
  currentSaleStatus?: Maybe<Scalars['String']>;
  /** The start date of the listing's current sale status. Currently derived from the RESO StatusChangeTimestamp field */
  currentStatusDate?: Maybe<Scalars['String']>;
  /** The number of days the listing is on market, as defined by the MLS business rules */
  daysOnMarket?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  daysOnMarketCumulative?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  daysOnMarketMLS?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  daysToClose?: Maybe<Scalars['Int']>;
  /** Not Supported Yet */
  daysToCloseCumulative?: Maybe<Scalars['Int']>;
  /** Computed number of days until a listing's contract is signed. */
  daysToContract?: Maybe<Scalars['Int']>;
  /** Boolean flag that indicates whether or not a listing shows signs of distress/disrepair */
  distressed?: Maybe<Scalars['Boolean']>;
  /** Returns the keywords used to identify a listing as distressed */
  distressedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Not Supported Yet
   * @deprecated No longer supported
   */
  flipYN?: Maybe<Scalars['Boolean']>;
  /** Boolean flag indicating whether or not a listing's IDX details can be displayed */
  idxDisplayNo?: Maybe<Scalars['Boolean']>;
  /** The HC-provided current price of the property as determined by the seller and the seller's broker. For auctions, this is the minimum or reserve price. */
  listPrice?: Maybe<Scalars['Float']>;
  /** The HC-provided original listing price of the property on the initial agreement between the seller and the seller's broker. */
  listPriceOriginal?: Maybe<Scalars['Float']>;
  /** Maximum List Price (if range provided) */
  listPriceRangeMax?: Maybe<Scalars['Float']>;
  /** Minimum List Price (if range provided) */
  listPriceRangeMin?: Maybe<Scalars['Float']>;
  /** The HC-provided effective date of the agreement between the seller and the seller's broker. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  listingDate?: Maybe<Scalars['String']>;
  /** Timestamp of listing's last update from the providing MLS, standardized to UTC */
  mlsLastUpdate?: Maybe<Scalars['String']>;
  /** The name of the MLS through which the listing was provided */
  mlsName?: Maybe<Scalars['String']>;
  openHouse?: Maybe<Array<Maybe<ListingListingDetailsHcOpenHouse>>>;
  /** Replicating a legacy flag to note where there exist detail(s) that would impact interpretation of price. Historically, this used only Hazards and Income restrictions. */
  priceConsiderations?: Maybe<Array<Maybe<Scalars['String']>>>;
  remarks?: Maybe<Array<Maybe<ListingListingDetailsHcRemarks>>>;
  remarksPrivate?: Maybe<Scalars['String']>;
  remarksPublic?: Maybe<Scalars['String']>;
  /** The increment of time covered by specified lease/rental amount. */
  rentalAmountFrequency?: Maybe<Scalars['String']>;
  /** Enumerated list of terms/conditions of the rental listing */
  rentalLeaseTerm?: Maybe<Scalars['String']>;
  /** Boolean flag indicating whether or not a listing is considered a rental */
  rentalListing?: Maybe<Scalars['Boolean']>;
  /** A text description of how the Rental Listing boolean flag was derived. e.g. from our record classifications, or a specific RESO field, or the mapper class type, etc. */
  rentalLogic?: Maybe<Scalars['String']>;
  /** Boolean denoting whether this listing is flagged as a Short Sale */
  reo?: Maybe<Scalars['Boolean']>;
  /** Enumerated list of special conditions that describe the type of sale. i.e. Standard, REO, Short Sale, Probate, Auction, NOD, etc., at the time of listing. */
  specialListingConditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** HC provided status for listing. Replaces StandardStatus in RESO. One of the following values Active, Active Under Contract, Canceled, Closed, Coming Soon, Delete, Expired, Hold, Incomplete, Pending, Withdrawn */
  status?: Maybe<Scalars['String']>;
};

export type ListingListingDetailsHcConcessions = {
  __typename?: 'ListingListingDetailsHcConcessions';
  /** The value of buyer concessions included in the sales agreement. The unit of the value is represented in the buyerDescription field. */
  buyerAmount?: Maybe<Scalars['Float']>;
  /** The unit associated with the buyer concessions amount. '$' represents a set amount, while '%' represents a percentage of the sale price. */
  buyerDescription?: Maybe<Scalars['String']>;
  /** The value of seller concessions included in the sales agreement. The unit of the value is represented in the sellerDescription field. */
  sellerAmount?: Maybe<Scalars['Float']>;
  /** The unit associated with the seller concessions amount. '$' represents a set amount, while '%' represents a percentage of the sale price. */
  sellerDescription?: Maybe<Scalars['String']>;
};

export type ListingListingDetailsHcOpenHouse = {
  __typename?: 'ListingListingDetailsHcOpenHouse';
  /** Boolean flag indicating whether or not an appointment is required for a listing's open house */
  appointmentRequired?: Maybe<Scalars['Boolean']>;
  /** Date of a listings open house */
  date?: Maybe<Scalars['String']>;
  /** The time a listings open house ends */
  endTime?: Maybe<Scalars['String']>;
  /** List of potential refreshments served at a listing's open house */
  refreshments?: Maybe<Scalars['String']>;
  /** Remarks concerning a listing's open house */
  remarks?: Maybe<Scalars['String']>;
  /** The time a listing's open house starts */
  startTime?: Maybe<Scalars['String']>;
  /** The status of a listing's open house */
  status?: Maybe<Scalars['String']>;
  /** Designates an open house's */
  type?: Maybe<Scalars['String']>;
};

export type ListingListingDetailsHcRemarks = {
  __typename?: 'ListingListingDetailsHcRemarks';
  /** Designates remark's type, typically Public (viewable to anyone) or Private */
  type?: Maybe<Scalars['String']>;
  /** The contents of the listing's remark field */
  value?: Maybe<Scalars['String']>;
};

export type ListingListingDetailsReso = {
  __typename?: 'ListingListingDetailsReso';
  /** If the property is located behind an unmanned security gate such as in a Gated Community, what is the code to gain access through the secured gate. */
  accessCode?: Maybe<Scalars['String']>;
  /** A text field to convey a specific contact phone number or email address for the listing firm. */
  attributionContact?: Maybe<Scalars['String']>;
  /** The date the property will be available for possession/occupation. */
  availabilityDate?: Maybe<Scalars['String']>;
  /** The total commission to be paid for this sale, expressed as either a percentage or a constant currency amount. */
  buyerAgencyCompensation?: Maybe<Scalars['String']>;
  /** A list of types to clarify the value entered in the BuyerAgencyCompensation field. For example $, % or some other clarification of the BuyerAgencyCompensation. */
  buyerAgencyCompensationType?: Maybe<Scalars['String']>;
  /** Date the listing contract between the seller and listing agent was cancelled. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  cancelationDate?: Maybe<Scalars['String']>;
  /** With for-sale listings, the date the purchase agreement was fulfilled. With lease listings, the date the requirements were fulfilled, such as contract and/or deposit. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  closeDate?: Maybe<Scalars['String']>;
  /** Are there concessions included in the sales agreement? Yes, No or Call Listing Agent */
  concessions?: Maybe<Scalars['String']>;
  /** The dollar amount of the concessions. If the concessions are made by the seller, some may subtract this value from the sales price as a means of calculating their own true price. If concessions are made by the buyer, some may add this amount to the sale price to create their own true price. Concessions made by both buyer and seller should be subtracted from each other providing a net value. Details of this calculation should be added to the Concessions Comments field. */
  concessionsAmount?: Maybe<Scalars['Int']>;
  /** Comments describing the concessions made by the buyer or the seller. */
  concessionsComments?: Maybe<Scalars['String']>;
  /** A list of contingencies that must be satisfied in order to complete the transaction. */
  contingency?: Maybe<Scalars['String']>;
  /** The date an offer was made with a contingency. The Listing remains On Market. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  contingentDate?: Maybe<Scalars['String']>;
  /** The date of the listings contractual status change. This is not necessarily the time the agent made the change in the MLS system, but rather the date of the contractual change. */
  contractStatusChangeDate?: Maybe<Scalars['String']>;
  /** Notice of the legal rights of the owner of the information or data. */
  copyrightNotice?: Maybe<Scalars['String']>;
  /** The number of days the property is on market, as defined by the MLS business rules. */
  cumulativeDaysOnMarket?: Maybe<Scalars['Int']>;
  /** The number of days the listing is on market, as defined by the MLS business rules. */
  daysOnMarket?: Maybe<Scalars['Int']>;
  /** Text that serves as the negation or limitation of the rights under a warranty given by a seller to a buyer. */
  disclaimer?: Maybe<Scalars['String']>;
  /** Legal or pertinent information that should be disclosed to potential buyer's agents. */
  disclosures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of the Documents available for the property. Knowing what documents are available for the property is valuable information. */
  documentsAvailable?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** System generated timestamp of when the last update or change to the documents for this listing was made. */
  documentsChangeTimestamp?: Maybe<Scalars['String']>;
  /** The total number of documents or supplements included with the listings. */
  documentsCount?: Maybe<Scalars['Int']>;
  /** A commission rate is a dual commission rate, a variable commission rate, or both. A dual or variable rate commission arrangement is one in which the seller agrees to pay a specified commission if the property is sold by the listing broker without assistance and a different commission if the sale results through the efforts of a cooperating broker, or one in which the seller agrees to pay a specified commission if the property is sold by the listing broker either with or without the assistance of a cooperating broker and a different commission if the sale results through the efforts of a seller. */
  dualVariableCompensationYN?: Maybe<Scalars['Boolean']>;
  /** Elements of the property that will not be included in the sale. i.e. Chandeliers will be removed prior to close. */
  exclusions?: Maybe<Scalars['String']>;
  /** The date when the listing agreement will expire. This is the date entered by the agent reflecting when the change occurred, or will occur, contractually, not a timestamp of when the change was made in the MLS. The expiration date of listings, prior to their expiration, cancellation, sale or lease, is confidential information and should be restricted to the agent and their managers, partners or broker. */
  expirationDate?: Maybe<Scalars['String']>;
  /** Is a home warranty included in the sale of the property? Single select. */
  homeWarrantyYN?: Maybe<Scalars['Boolean']>;
  /** Portable elements of the property that will be included in the sale. */
  inclusions?: Maybe<Scalars['String']>;
  /** A yes/no field that states the seller has allowed the listing address to be displayed on Internet sites. */
  internetAddressDisplayYN?: Maybe<Scalars['Boolean']>;
  /** A yes/no field that states the seller allows the listing can be displayed with an AVM on Internet sites. */
  internetAutomatedValuationDisplayYN?: Maybe<Scalars['Boolean']>;
  /** A yes/no field that states the seller allows a comment or blog system to be attached to the listing on Internet sites. */
  internetConsumerCommentYN?: Maybe<Scalars['Boolean']>;
  /** A yes/no field that states the seller has allowed the listing to be displayed on Internet sites. */
  internetEntireListingDisplayYN?: Maybe<Scalars['Boolean']>;
  /** Will the seller consider leasing the property instead of selling? Single select. */
  leaseConsideredYN?: Maybe<Scalars['Boolean']>;
  /** A list of compensations other than the original Selling Office Compensation. i.e. Compensation Paid on Renewal, Compensation Paid on Tenant Purchase, No Renewal Commission, Call Listing Office, etc. */
  leaseRenewalCompensation?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The responsible Board or Association of REALTORS for this listing. */
  listAOR?: Maybe<Scalars['String']>;
  /** The lower price used for Value Range Pricing. The List Price must be greater than or equal to the ListPriceLow. */
  listPriceLow?: Maybe<Scalars['Float']>;
  /** The nature of the agreement between the seller and the listing agent. Examples are Exclusive Agency, Open Listing, etc. */
  listingAgreement?: Maybe<Scalars['String']>;
  /** The effective date of the agreement between the seller and the seller's broker. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  listingContractDate?: Maybe<Scalars['String']>;
  /** The well known identifier for the listing. The value may be identical to that of the Listing Key, but the Listing ID is intended to be the value used by a human to retrieve the information about a specific listing. In a multiple originating system or a merged system, this value may not be unique and may require the use of the provider system to create a synthetic unique value. */
  listingId?: Maybe<Scalars['String']>;
  /** A unique identifier for this record from the immediate source. This may be a number, or string that can include URI or other forms. This is the system you are connecting to and not necessarily the original source of the record. */
  listingKey?: Maybe<Scalars['String']>;
  /** Defines the type or level of service the listing member will be providing to the selling home owner. This will typically be a single selection. Examples include Full Service, Limited Service or Entry Only. */
  listingService?: Maybe<Scalars['String']>;
  /** Terms of the listing such as Lien Release, Subject to Court Approval or Owner Will Carry. Also may include options that describe the financing terms that are acceptable to the seller, i.e. cash, assumable, FHA loan, etc. */
  listingTerms?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A field describing the location of the lock box. */
  lockBoxLocation?: Maybe<Scalars['String']>;
  /** The serial number of the lockbox placed on the property. */
  lockBoxSerialNumber?: Maybe<Scalars['String']>;
  /** A field describing the type of lock box. */
  lockBoxType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Timestamp of the last major change on the listing (see also MajorChangeType). */
  majorChangeTimestamp?: Maybe<Scalars['String']>;
  /** Description of the last major change on the listing, i.e. “price reduction”, “back on market”, etc. May be used to display on a summary view of listing results to quickly identify listings that have had major changes recently. */
  majorChangeType?: Maybe<Scalars['String']>;
  /** Local or regional status that are well known by business users. Each MlsStatus must map to a single StandardStatus. Multiple MlsStatus may map to a single StandardStatus. */
  mlsStatus?: Maybe<Scalars['String']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the date/time the listing was last modified. */
  modificationTimestamp?: Maybe<Scalars['String']>;
  /** The date the listing was taken off market. Where possible, this date is reflective of the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  offMarketDate?: Maybe<Scalars['String']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the most recent date/time the listing's status was set to and off market status (not Active or Backup) */
  offMarketTimestamp?: Maybe<Scalars['String']>;
  /** The date the listing was placed on market. Where possible, this date is reflective of the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  onMarketDate?: Maybe<Scalars['String']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the most recent date/time the listing's status was set to Active or Backup. This also includes initial input of the listing to Active/Backup or from a draft or approval status to Active/Backup. */
  onMarketTimestamp?: Maybe<Scalars['String']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the date/time the listing was entered and made visible to members of the MLS. */
  originalEntryTimestamp?: Maybe<Scalars['String']>;
  /** Unique identifier from the originating system which is commonly a key to that system. In the case where data is passed through more than one system, this is the originating system key. This is a foreign key relating to the system where this record was originated. */
  originatingSystemKey?: Maybe<Scalars['String']>;
  /** The name of the originating record provider. Most commonly the name of the MLS. The place where the listing is originally input by the member. The legal name of the company. To be used for display. */
  originatingSystemName?: Maybe<Scalars['String']>;
  /** A text description of the manner in which title to a property is held. Trust, Corporation, Joint Tenant, Individual. */
  ownership?: Maybe<Scalars['String']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the most recent date/time the listing's status was set to Pending. */
  pendingTimestamp?: Maybe<Scalars['String']>;
  /** System generated timestamp of when the last update or change to the photos for this listing was made. */
  photosChangeTimestamp?: Maybe<Scalars['String']>;
  /** The total number of pictures or photos included with the listing. */
  photosCount?: Maybe<Scalars['Int']>;
  /** A list defining when possession will occur. i.e. COE, COE+1, etc. */
  possession?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The most recent previous ListPrice of the listing. */
  previousListPrice?: Maybe<Scalars['Float']>;
  /** The transactional timestamp automatically recorded by the MLS system representing the date/time the listing's price was last changed. */
  priceChangeTimestamp?: Maybe<Scalars['String']>;
  /** With for-sale listings, the date an offer was accepted and the listing was no longer on market. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. With lease listings this may represent a meeting of the minds to lease, but some contractual requirements are yet to be fulfilled, such as contract signing or receipt of the deposit. */
  purchaseContractDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Array<Maybe<ListingListingDetailsResoRemarks>>>;
  /** The name of the contact for the showing of the listed property. */
  showingContactName?: Maybe<Scalars['String']>;
  /** The type of contact for the showing. i.e. Agent, Broker, Seller. */
  showingContactType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Remarks that detail the seller's instructions for showing the subject property. Showing instructions may include: contact information, showing times, notice required or other information. These remarks are privileged and are not for public viewing. */
  showingInstructions?: Maybe<Scalars['String']>;
  /** Is there a sign on the property. */
  signOnPropertyYN?: Maybe<Scalars['Boolean']>;
  /** A list of options that describe the type of sale. i.e. Standard, REO, Short Sale, Probate, Auction, NOD, etc., at the time of listing. */
  specialListingConditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The transactional timestamp automatically recorded by the MLS system representing the date/time the listing's status was last changed. */
  statusChangeTimestamp?: Maybe<Scalars['String']>;
  /** The total commission to be paid to the Sub Agency, expressed as either a percentage or a constant currency amount. */
  subAgencyCompensation?: Maybe<Scalars['String']>;
  /** A list of types to clarify the value entered in the SubAgencyCompensation field. For example $, % or some other clarification of the SubAgencyCompensation. */
  subAgencyCompensationType?: Maybe<Scalars['String']>;
  /** When permitted by the broker, the options made by the agent on behalf of the seller, where they would like their listings syndicated. i.e. Zillow, Trulia, Homes.com, etc. */
  syndicateTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The total commission to be paid to the transaction facilitator, expressed as either a percentage or a constant currency amount. */
  transactionBrokerCompensation?: Maybe<Scalars['String']>;
  /** A list of types to clarify the value entered in the TransactionBrokerCompensation field. For example $, % or some other clarification of the TransactionBrokerCompensation. */
  transactionBrokerCompensationType?: Maybe<Scalars['String']>;
  /** System generated timestamp of when the last update or change to the videos for this listing was made. */
  videosChangeTimestamp?: Maybe<Scalars['String']>;
  /** The total number of videos or virtual tours included with the listing. */
  videosCount?: Maybe<Scalars['Int']>;
  /** A text field that holds the URL for a branded virtual tour of the property. */
  virtualTourURLBranded?: Maybe<Scalars['String']>;
  /** A text field that holds the URL for an unbranded virtual tour of the property. */
  virtualTourURLUnbranded?: Maybe<Scalars['String']>;
  /** Date the listing was withdrawn from the market. This is not when a listing contact was cancelled or closed, but a withdrawal from the market while the contract between the seller and listing agent is still in effect and an offer has not been accepted. This is the date entered by the agent reflecting when the change occurred contractually, not a timestamp of when the change was made in the MLS. */
  withdrawnDate?: Maybe<Scalars['String']>;
};

export type ListingListingDetailsResoRemarks = {
  __typename?: 'ListingListingDetailsResoRemarks';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ListingLocation = {
  __typename?: 'ListingLocation';
  discovery?: Maybe<ListingLocationDiscovery>;
  hc?: Maybe<CommonLocationHc>;
  reso?: Maybe<ListingLocationReso>;
};

export type ListingLocationDiscovery = {
  __typename?: 'ListingLocationDiscovery';
  hcAddress?: Maybe<CommonLocationDiscoveryHcAddress>;
};

export type ListingLocationReso = {
  __typename?: 'ListingLocationReso';
  /** The group of addresses to which the USPS assigns the same code to aid in mail delivery. For the USPS, these codes are 9 digits: 5 numbers for the ZIP Code, one letter for the carrier route type, and 3 numbers for the carrier route number. */
  carrierRoute?: Maybe<Scalars['String']>;
  /** The city in listing address. */
  city?: Maybe<Scalars['String']>;
  /** The country abbreviation in a postal address. */
  country?: Maybe<Scalars['String']>;
  /** The County, Parish or other regional authority */
  countyOrParish?: Maybe<Scalars['String']>;
  /** Nearest cross streets to the property. This field is in addition to, and independent of, the driving directions field. */
  crossStreet?: Maybe<Scalars['String']>;
  /** Driving directions to the property. */
  directions?: Maybe<Scalars['String']>;
  /** The name of the primary school having a catchment area that includes the associated property. */
  elementarySchool?: Maybe<Scalars['String']>;
  /** The name of the elementary school district having a catchment area that includes the associated property. */
  elementarySchoolDistrict?: Maybe<Scalars['String']>;
  /** The elevation of the property in relation to sea level. Use the Elevation Units field to communicate the unit of measurement. i.e. Feet or Meters. */
  elevation?: Maybe<Scalars['Int']>;
  /** A pick list of the unit of measurement used in the Elevation field. i.e. Feet, Meters. */
  elevationUnits?: Maybe<Scalars['String']>;
  fips?: Maybe<Scalars['String']>;
  /** The name of the high school having a catchment area that includes the associated property. */
  highSchool?: Maybe<Scalars['String']>;
  /** The name of the high school district having a catchment area that includes the associated property. When only one school district is used, this field should be used over the Junior or Elementary Districts. */
  highSchoolDistrict?: Maybe<Scalars['String']>;
  /** The geographic latitude of some reference point on the property, specified in degrees and decimal parts. Positive numbers must not include the plus symbol. */
  latitude?: Maybe<Scalars['Float']>;
  /** The geographic longitude of some reference point on the property, specified in degrees and decimal parts. Positive numbers must not include the plus symbol. */
  longitude?: Maybe<Scalars['Float']>;
  /** A map coordinate for the property, as determined by local custom. This is not necessarily the same as the geographic coordinate but may depend on the coordinate system used by whatever mapping service is customarily used by the listing service. */
  mapCoordinate?: Maybe<Scalars['String']>;
  /** Name of the map or map book publisher. */
  mapCoordinateSource?: Maybe<Scalars['String']>;
  /** URI to a map of the property. */
  mapURL?: Maybe<Scalars['String']>;
  /** The name of the junior or middle school having a catchment area that includes the associated property. */
  middleOrJuniorSchool?: Maybe<Scalars['String']>;
  /** The name of the junior or middle school district having a catchment area that includes the associated property. */
  middleOrJuniorSchoolDistrict?: Maybe<Scalars['String']>;
  /** The major marketing area name, as defined by the MLS or other non-governmental organization. If there is only one MLS Area in use, it must be the MLSAreaMajor. */
  mlsAreaMajor?: Maybe<Scalars['String']>;
  /** The minor/sub marketing area name, as defined by the MLS or other non-governmental organization. If there is only one MLS Area in use, it must be the MLSAreaMajor. */
  mlsAreaMinor?: Maybe<Scalars['String']>;
  /** The official city per the USPS. May be different from the "City". */
  postalCity?: Maybe<Scalars['String']>;
  /** The postal code portion of a street or mailing address. */
  postalCode?: Maybe<Scalars['String']>;
  /** The postal code +4 portion of a street or mailing address. */
  postalCodePlus4?: Maybe<Scalars['String']>;
  /** Text field containing the accepted postal abbreviation for the state or province. */
  stateOrProvince?: Maybe<Scalars['String']>;
  /** Information other than a prefix or suffix for the street portion of a postal address. */
  streetAdditionalInfo?: Maybe<Scalars['String']>;
  /** The direction indicator that precedes the listed property's street name. */
  streetDirPrefix?: Maybe<Scalars['String']>;
  /** The direction indicator that follows a listed property's street address. */
  streetDirSuffix?: Maybe<Scalars['String']>;
  /** The street name portion of a listed property's street address. */
  streetName?: Maybe<Scalars['String']>;
  /** The street number portion of a listed property's street address. In some areas the street number may contain non-numeric characters. This field can also contain extensions and modifiers to the street number, such as "1/2" or "-B". This street number field should not include Prefixes, Direction or Suffixes. */
  streetNumber?: Maybe<Scalars['String']>;
  /** The integer portion of the street number. */
  streetNumberNumeric?: Maybe<Scalars['Int']>;
  /** The suffix portion of a listed property's street address. */
  streetSuffix?: Maybe<Scalars['String']>;
  /** The Street Suffix Modifier allows the member to enter a unique Street Suffix that was not found in the Street Suffix pick list or to extend or prefix the suffix. */
  streetSuffixModifier?: Maybe<Scalars['String']>;
  /** A neighborhood, community, complex or builder tract. */
  subdivisionName?: Maybe<Scalars['String']>;
  /** A subdivision of the county. */
  township?: Maybe<Scalars['String']>;
  /** Text field containing the number or portion of a larger building or complex. Unit Number should appear following the street suffix or, if it exists, the street suffix direction, in the street address. Examples are: "APT G", "55", etc. */
  unitNumber?: Maybe<Scalars['String']>;
  /** The UnparsedAddress is a text representation of the address with the full civic location as a single entity. It may optionally include any of City, StateOrProvince, PostalCode and Country. */
  unparsedAddress?: Maybe<Scalars['String']>;
};

export type ListingMedia = {
  __typename?: 'ListingMedia';
  /** Media data managed and handled by HouseCanary. */
  hc?: Maybe<ListingMediaHc>;
  /** No data mapped here. */
  reso?: Maybe<ListingMediaReso>;
};

export type ListingMediaHc = {
  __typename?: 'ListingMediaHc';
  images?: Maybe<Array<Maybe<ListingMediaHcImages>>>;
  urls?: Maybe<Array<Maybe<ListingMediaHcUrls>>>;
};

export type ListingMediaHcImages = {
  __typename?: 'ListingMediaHcImages';
  derived?: Maybe<ListingMediaHcImagesDerived>;
  hash?: Maybe<Scalars['String']>;
  hashSource?: Maybe<Scalars['String']>;
  /** A number that denotes the order of a unit of media belonging to a sequence/collection */
  order?: Maybe<Scalars['Int']>;
  /** The type of hc provided media being referenced/called */
  type?: Maybe<Scalars['String']>;
  /** HC-provided URL used to store imagery related to a listing */
  url?: Maybe<Scalars['String']>;
};

export type ListingMediaHcImagesDerived = {
  __typename?: 'ListingMediaHcImagesDerived';
  classification?: Maybe<ListingMediaHcImagesDerivedImageClassification>;
  conditionPytorch?: Maybe<ListingMediaHcImagesDerivedImageConditionPytorch>;
  conditionV2?: Maybe<ListingMediaHcImagesDerivedImageConditionV2>;
};

export type ListingMediaHcImagesDerivedImageClassification = {
  __typename?: 'ListingMediaHcImagesDerivedImageClassification';
  confidence?: Maybe<Scalars['Int']>;
  prediction?: Maybe<Scalars['Int']>;
  predictionDescription?: Maybe<Scalars['String']>;
  predictionGroup?: Maybe<Scalars['String']>;
};

export type ListingMediaHcImagesDerivedImageConditionPytorch = {
  __typename?: 'ListingMediaHcImagesDerivedImageConditionPytorch';
  condition?: Maybe<Scalars['String']>;
  conditionClass?: Maybe<Scalars['Int']>;
  conditionClassProbability1?: Maybe<Scalars['Float']>;
  conditionClassProbability2?: Maybe<Scalars['Float']>;
  conditionClassProbability3?: Maybe<Scalars['Float']>;
  conditionClassProbability4?: Maybe<Scalars['Float']>;
  conditionClassProbability5?: Maybe<Scalars['Float']>;
  conditionClassProbability6?: Maybe<Scalars['Float']>;
  conditionClassProbabilityC?: Maybe<Scalars['Float']>;
  conditionProbability?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  qualityClass?: Maybe<Scalars['Int']>;
  qualityClassProbability1?: Maybe<Scalars['Float']>;
  qualityClassProbability2?: Maybe<Scalars['Float']>;
  qualityClassProbability3?: Maybe<Scalars['Float']>;
  qualityClassProbability4?: Maybe<Scalars['Float']>;
  qualityClassProbability5?: Maybe<Scalars['Float']>;
  qualityClassProbability6?: Maybe<Scalars['Float']>;
  qualityClassProbabilityC?: Maybe<Scalars['Float']>;
  qualityProbability?: Maybe<Scalars['Float']>;
};

export type ListingMediaHcImagesDerivedImageConditionV2 = {
  __typename?: 'ListingMediaHcImagesDerivedImageConditionV2';
  awesome?: Maybe<Scalars['Float']>;
  bad_high?: Maybe<Scalars['Float']>;
  bad_low?: Maybe<Scalars['Float']>;
  fire?: Maybe<Scalars['Float']>;
  good?: Maybe<Scalars['Float']>;
  ok?: Maybe<Scalars['Float']>;
  subpar_high?: Maybe<Scalars['Float']>;
  subpar_low?: Maybe<Scalars['Float']>;
  under_construction?: Maybe<Scalars['Float']>;
  very_good?: Maybe<Scalars['Float']>;
};

export type ListingMediaHcUrls = {
  __typename?: 'ListingMediaHcUrls';
  /** The type of RESO-provided media being referenced/called */
  type?: Maybe<Scalars['String']>;
  /** The URL used to store media related to a listing */
  url?: Maybe<Scalars['String']>;
};

export type ListingMediaReso = {
  __typename?: 'ListingMediaReso';
  /** The RESO-provided URL used to store imagery related to a listing */
  imageUrl?: Maybe<Scalars['String']>;
};

export type ListingMls = {
  __typename?: 'ListingMls';
  fips?: Maybe<Array<Maybe<ListingMlsFips>>>;
  /** HouseCanary-assigned numeric id designated to a MLS */
  hcMlsId?: Maybe<Scalars['Int']>;
  /** Timestamp for the last successful data update with the given MLS data feed. */
  lastRefreshed?: Maybe<Scalars['String']>;
  /** The name of the MLS. The place where the listing is originally input by the member. The legal name of the company. */
  name?: Maybe<Scalars['String']>;
  /** The short name of the MLS. */
  nameShort?: Maybe<Scalars['String']>;
  /** Rules and regulations that determine listing display compliance as determined by the MLS. */
  regulations?: Maybe<MlsRegulations>;
  /** List of postal abbreviations for the states (or provinces) in which the MLS primarily operates. */
  stateMainDisplay?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListingMlsDirect = {
  __typename?: 'ListingMlsDirect';
  reso?: Maybe<ListingMlsDirectReso>;
};

export type ListingMlsDirectReso = {
  __typename?: 'ListingMlsDirectReso';
  mlsRaw?: Maybe<ListingMlsDirectResoMlsRaw>;
};

export type ListingMlsDirectResoMlsRaw = {
  __typename?: 'ListingMlsDirectResoMlsRaw';
  /** Full description or longname of the field as provided by the MLS */
  fieldLabel?: Maybe<Scalars['String']>;
  /**  Enumerated HouseCanary label for a conceptual grouping */
  groupLabel?: Maybe<Scalars['String']>;
  /** Exact field name as provided by the MLS */
  name?: Maybe<Scalars['String']>;
  /** The value of the named field as provided by the MLS */
  value?: Maybe<Scalars['String']>;
};

export type ListingMlsFips = {
  __typename?: 'ListingMlsFips';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ListingOfficeJoinId = {
  __typename?: 'ListingOfficeJoinID';
  /** office join id for buying office */
  buyer?: Maybe<Scalars['String']>;
  /** office join id for co-buying office */
  coBuyer?: Maybe<Scalars['String']>;
  /** office join id for co-listing office */
  coList?: Maybe<Scalars['String']>;
  /** office join id for second co-listing office */
  coList2?: Maybe<Scalars['String']>;
  /** office join id for third co-listing office */
  coList3?: Maybe<Scalars['String']>;
  /** office join id for listing office */
  list?: Maybe<Scalars['String']>;
};

export type ListingPropertyDetails = {
  __typename?: 'ListingPropertyDetails';
  /** Physical details of the property normalized to HouseCanary common schema. Intended to support consistent and programmatic use. */
  hc?: Maybe<CommonPropertyDetailsHc>;
  /** Physical details of the property as mostly directly mapped from raw MLS data to the RESO Data Dictionary. This should used when looking for complete data provided by the listing that is not fully normalized by HouseCanary. */
  reso?: Maybe<ListingPropertyDetailsReso>;
};

export type ListingPropertyDetailsReso = {
  __typename?: 'ListingPropertyDetailsReso';
  /** Finished area within the structure that is at or above the surface of the ground. */
  aboveGradeFinishedArea?: Maybe<Scalars['Float']>;
  /** The source of the measurements. This is a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  aboveGradeFinishedAreaSource?: Maybe<Scalars['String']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  aboveGradeFinishedAreaUnits?: Maybe<Scalars['String']>;
  /** A list or description of the accessibility features included in the sale/lease. */
  accessibilityFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** If additional parcels are included in the sale, a list of those parcel's IDs separated by commas. Do not include the first or primary parcel number, that should be located in the Parcel Number field. */
  additionalParcelsDescription?: Maybe<Scalars['String']>;
  /** Boolean representing if there more than one parcel or lot included in the sale */
  additionalParcelsYN?: Maybe<Scalars['Boolean']>;
  /** A list of the appliances that will be included in the sale/lease of the property. */
  appliances?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list describing the style of the structure. For example, Victorian, Ranch, Craftsman, etc. */
  architecturalStyle?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Amenities provided by the Home Owners Association, Mobile Park or Complex. For example Pool, Clubhouse, etc. */
  associationAmenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A fee paid by the homeowner to the Home Owners Association which is used for the upkeep of the common area, neighborhood or other association related benefits. */
  associationFee?: Maybe<Scalars['Float']>;
  /** A fee paid by the homeowner to the second of two Home Owners Associations, which is used for the upkeep of the common area, neighborhood or other association related benefits. */
  associationFee2?: Maybe<Scalars['Float']>;
  /** The frequency the association fee is paid. For example, Weekly, Monthly, Annually, Bi-Monthly, One Time, etc. */
  associationFee2Frequency?: Maybe<Scalars['String']>;
  /** The frequency the association fee is paid. For example, Weekly, Monthly, Annually, Bi-Monthly, One Time, etc. */
  associationFeeFrequency?: Maybe<Scalars['String']>;
  /** Services included with the association fee. For example Landscaping, Trash, Water, etc. */
  associationFeeIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The name of the Home Owners Association. */
  associationName?: Maybe<Scalars['String']>;
  /** The name of the second of two Home Owners Association. */
  associationName2?: Maybe<Scalars['String']>;
  /** The phone number of the Home Owners Association. North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol. */
  associationPhone?: Maybe<Scalars['String']>;
  /** The phone number of the second of two Home Owners Association. North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol. */
  associationPhone2?: Maybe<Scalars['String']>;
  /** Is there a Home Owners Association. A separate Y/N field is needed because not all associations have dues. */
  associationYN?: Maybe<Scalars['Boolean']>;
  /** A flag indicating that the garage attached to the dwelling. */
  attachedGarageYN?: Maybe<Scalars['Boolean']>;
  /** A list of information and features about the basement. i.e. None/Slab, Finished, Partially Finished, Crawl Space, Dirt, Outside Entrance, Radon Mitigation */
  basement?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A room containing all 4 of the 4 elements constituting a bath, which are; Toilet, Sink, Bathtub or Shower Head. A Full Bath will typically contain four elements; Sink, Toilet, Tub and Shower Head (in tub or stall). However, some may considered a Sink, Toilet and Tub (without a shower) a Full Bath, others consider this to be a Three Quarter Bath. In the event that BathroomsThreeQuarter is not in use, this field may represent the sum of all Full and Three Quarter bathrooms. */
  bathroomsFull?: Maybe<Scalars['Int']>;
  /** A room containing 2 of the 4 elements constituting a bath, which are; Toilet, Sink, Bathtub or Shower Head. A Half Bath will typically contain a Sink and Toilet. */
  bathroomsHalf?: Maybe<Scalars['Int']>;
  /** A room containing 1 of the 4 elements constituting a bath which are; Toilet, Sink, Bathtub or Shower Head. Examples are a vanity with a sink or a WC (Water Closet, which is a room with only a toilet). */
  bathroomsOneQuarter?: Maybe<Scalars['Int']>;
  /** The number of partial bathrooms in the property being sold/leased. When used in combination with the BathroomsFull field, this replaces (or is the sum of) all Half and One Quarter bathrooms; and in the event BathroomsThreeQuarter is not used, BathroomsFull replaces (or is the sum of) all Full and Three Quarter baths. This field should not be used in combination with the BathroomsOneQuarter or the BathroomsHalf. */
  bathroomsPartial?: Maybe<Scalars['Int']>;
  /** A room containing 3 of the 4 elements constituting a bath, which are; Toilet, Sink, Bathtub or Shower Head. A typical Three Quarter Bath will contain Sink, Toilet and Shower. Some may considered a Sink, Toilet and Tub (without a shower) a Three Quarter Bath, others consider this to be a Full Bath. */
  bathroomsThreeQuarter?: Maybe<Scalars['Int']>;
  bathroomsTotalInteger?: Maybe<Scalars['Int']>;
  /** The sum of BedroomsTotal plus other rooms that may be used as a bedroom but are not defined as bedroom per local policy. */
  bedroomsPossible?: Maybe<Scalars['Int']>;
  /** The total number of bedrooms in the dwelling. */
  bedroomsTotal?: Maybe<Scalars['Int']>;
  /** Finished area within the structure that is below ground. */
  belowGradeFinishedArea?: Maybe<Scalars['Float']>;
  /** The source of the measurements. This is a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  belowGradeFinishedAreaSource?: Maybe<Scalars['String']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  belowGradeFinishedAreaUnits?: Maybe<Scalars['String']>;
  /** Type of mobile home. */
  bodyType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The builders model name or number for the property. */
  builderModel?: Maybe<Scalars['String']>;
  /** Name of the builder of the property or builder's tract. */
  builderName?: Maybe<Scalars['String']>;
  /** The source of the measurements. This is a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  buildingAreaSource?: Maybe<Scalars['String']>;
  /** Total area of the structure. Includes both finished and unfinished areas. */
  buildingAreaTotal?: Maybe<Scalars['Float']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  buildingAreaUnits?: Maybe<Scalars['String']>;
  /** Features or amenities of the building or business park. */
  buildingFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Name of the building or business park. */
  buildingName?: Maybe<Scalars['String']>;
  buyerFinancing?: Maybe<
    Array<Maybe<ListingPropertyDetailsResoBuyerFinancing>>
  >;
  /** Cap Rate is equivalent to the return on investment you would receive if you pay cash for a property. The ratio between the net operating income produced by an asset and its capital cost (the original price paid to buy the asset) or alternatively its current market value. */
  capRate?: Maybe<Scalars['Float']>;
  /** The number of carport spaces included in the sale. */
  carportSpaces?: Maybe<Scalars['Float']>;
  /** A flag indicating that the listing has a carport. This flag may be T/F, Y/N or other true, false or unknown indicator. As with all flags, the field may be null. */
  carportYN?: Maybe<Scalars['Boolean']>;
  /** A multi select list with options like 1 Common Wall, 2 Common Walls, No Common Walls, No One Above, No One Below. Implementation should include rules preventing illogical selection combinations and to ensure consistency with the Property Attached Y/N field. */
  commonWalls?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of features related to, or available within, the community. */
  communityFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of the materials that were used in the construction of the property. */
  constructionMaterials?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list describing the cooling or air conditioning features of the property. */
  cooling?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property has cooling or Air Conditioning. */
  coolingYN?: Maybe<Scalars['Boolean']>;
  /** The total number of garage and carport spaces. */
  coveredSpaces?: Maybe<Scalars['Float']>;
  /** Are crops included in the sale of the property. */
  cropsIncludedYN?: Maybe<Scalars['Boolean']>;
  /** Measurement or percentage of the property that has been cultivated. */
  cultivatedArea?: Maybe<Scalars['Float']>;
  /** A list of options that describe the type of financing that the seller currently has in place for the property being sold. i.e. cash, assumable, FHA loan, etc. */
  currentFinancing?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of the type(s) of current use of the property. The current use of the property is an important factor in understanding the overall condition of the land and determining it's appropriateness for intended use. */
  currentUse?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of the Development Status of the property. The developmental status of land is an important factor in selling, purchasing and developing of land properties. */
  developmentStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The compass direction that the main entrance to the building faces. For example, North, South, East, West, South-West, etc. It may also be known as the building exposure. */
  directionFaces?: Maybe<Scalars['String']>;
  /** A list of features or description of the doors included in the sale/lease. */
  doorFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of electric-service related features of the property (e.g. 110 Volt, 3 Phase, 220 Volt, RV Hookup). Note: the previous "Electric" field was renamed to DistanceToElectric */
  electric?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Does the property currently have electrical utility available on the property. */
  electricOnPropertyYN?: Maybe<Scalars['Boolean']>;
  /** A numeric field that describes the level within the structure, SFR or a unit in a building, where the main entry to the dwelling is located. When a unit has one floor it is implicit that this is also the level of the unit itself. */
  entryLevel?: Maybe<Scalars['Int']>;
  /** A description of the main entry way to the property. i.e. Elevator, Ground Level w/ Steps, Ground Level w/o Steps, Mid Level, Top Level, etc. */
  entryLocation?: Maybe<Scalars['String']>;
  /** Information about the status of the existing lease on the property. i.e. Net, NNN, NN, Gross, Absolute Net, Escalation Clause, Ground Lease, etc. */
  existingLeaseType?: Maybe<Array<Maybe<Scalars['String']>>>;
  expense?: Maybe<Array<Maybe<ListingPropertyDetailsResoExpense>>>;
  /** A list of features or description of the exterior of the property included in the sale/lease. */
  exteriorFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specifies whether or not Farm Credit Service shares are included in the price of the property. */
  farmCreditServiceInclYN?: Maybe<Scalars['Boolean']>;
  /** The source of the measurements. This may be a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. This field applies to all farm area fields (Cultivated, Pasture, Range, Wooded) */
  farmLandAreaSource?: Maybe<Scalars['String']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. This field applies to all farm area fields (Cultivated, Pasture, Range, Wooded) */
  farmLandAreaUnits?: Maybe<Scalars['String']>;
  /** A list of types of fencing found at the property being sold. */
  fencing?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The source of the Rental information. For example Accountant, Owner, etc. */
  financialDataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of features or description of the fireplace(s) included in the sale/lease. */
  fireplaceFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Does the property include a fireplace. */
  fireplaceYN?: Maybe<Scalars['Boolean']>;
  /** The total number of fireplaces included in the property. */
  fireplacesTotal?: Maybe<Scalars['Int']>;
  /** A list of the type(s) of flooring found within the property. */
  flooring?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The area or dimensions of the footprint of the structure on the lot. */
  foundationArea?: Maybe<Scalars['Float']>;
  /** A list of the type(s) of foundation on which the property sits. */
  foundationDetails?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Textual description of the length of the frontages selected in the Frontage Type field. */
  frontageLength?: Maybe<Scalars['String']>;
  /** Pick list of types of frontage. i.e. Oceanfront, Lakefront, Golf course…etc…. Information about roads or road frontage should be located in the Road Frontage Type and Road Surface Type fields. */
  frontageType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property being leased is furnished, unfurnished or partially furnished. */
  furnished?: Maybe<Scalars['String']>;
  /** The number of spaces in the garage(s). */
  garageSpaces?: Maybe<Scalars['Float']>;
  /** A flag indicating that the listing has a garage. This flag may be T/F, Y/N or other true, false or unknown indicator. As with all flags, the field may be null. */
  garageYN?: Maybe<Scalars['Boolean']>;
  /** A list of gas-service related features of the property (e.g. Natural Gas, Private LP Tank, None). Note: the previous "Gas" field was renamed to DistanceToGas */
  gas?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specifies whether or not the property owner has grazing permits from the Bureau of Land Management. */
  grazingPermitsBlmYN?: Maybe<Scalars['Boolean']>;
  /** Specifies whether or not the property owner has grazing permits from the Forestry Service. */
  grazingPermitsForestServiceYN?: Maybe<Scalars['Boolean']>;
  /** Specifies whether or not the property owner has private grazing permits. */
  grazingPermitsPrivateYN?: Maybe<Scalars['Boolean']>;
  greenBuildingVerification?: Maybe<
    Array<Maybe<ListingPropertyDetailsResoGreenBuildingVerification>>
  >;
  /** The name of the verification or certification awarded to a new or pre-existing residential or commercial structure. For example: LEED, Energy Star, ICC-700. In cases where more than one certification have been awarded, leverage multiple iterations of the green verification fields via the repeating element method. */
  greenBuildingVerificationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of general green attributes such as energy efficient doors, or appliances without naming specific elements whose efficiency rating wanes over time. */
  greenEnergyEfficient?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of methods of generating energy such as solar, wind or geothermal. */
  greenEnergyGeneration?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of indoor air quality measures. */
  greenIndoorAirQuality?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list describing efficiencies involved with the property's location such as walkability or transportation proximity. */
  greenLocation?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of sustainable elements used in the construction of the structure. */
  greenSustainability?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of general water conserving attributes of the property such as landscaping or reclamation. */
  greenWaterConservation?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The actual current income from rent and all other revenue generating sources. */
  grossIncome?: Maybe<Scalars['Float']>;
  /** The maximum amount of annual rent collected if the property were 100% occupied all year and all tenants paid their rent. */
  grossScheduledIncome?: Maybe<Scalars['Float']>;
  /** Does the property include a structure that can be lived in. */
  habitableResidenceYN?: Maybe<Scalars['Boolean']>;
  /** A list describing the heating features of the property. */
  heating?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property has heating. */
  heatingYN?: Maybe<Scalars['Boolean']>;
  /** A list of horse amenities on the lot or in the community. */
  horseAmenities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Property is allowed to raise horses. */
  horseYN?: Maybe<Scalars['Boolean']>;
  /** A list of income sources included in the GrossScheduledIncome and GrossIncome. i.e. Laundry, Parking, Recreation, Storage, etc. */
  incomeIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of features or description of the interior of the property included in the sale/lease. */
  interiorFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The source which the property receives its water for irrigation. */
  irrigationSource?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The number of acres allowed under the property's water rights. */
  irrigationWaterRightsAcres?: Maybe<Scalars['Float']>;
  /** Does the property include water rights for irrigation? A Boolean or Yes / No field. */
  irrigationWaterRightsYN?: Maybe<Scalars['Boolean']>;
  /** When the land is not included in the sale, but is leased, the amount of the lease. This is the Space Rent for Mobile homes in a Park. */
  landLeaseAmount?: Maybe<Scalars['Float']>;
  /** When the land is not included in the sale, but is leased, the frequency the Land Lease Fee is paid. */
  landLeaseAmountFrequency?: Maybe<Scalars['String']>;
  /** When the land is not included in the sale, but is leased, the expiration date of the Land Lease. */
  landLeaseExpirationDate?: Maybe<Scalars['String']>;
  /** The land is not included in the sale and a lease exists. */
  landLeaseYN?: Maybe<Scalars['Boolean']>;
  /** Add this pick list of features and locations where the laundry is located in the property being sold. i.e. Gas Dryer Hookup, In Kitchen, In Garage, etc. CRMLS sees over 50% utilization of this field which has a dozen enumerations making it too long to fold into other fields such as rooms or Interior Features. */
  laundryFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The area that may be leased within the commercial property. */
  leasableArea?: Maybe<Scalars['Float']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  leasableAreaUnits?: Maybe<Scalars['String']>;
  /** A pick list of lengths that represent the length of the lease. i.e. Weekly, Month to Month, 6 Month Lease, 12 Month Lease, 24 Month Lease. */
  leaseTerm?: Maybe<Scalars['String']>;
  /** The number of levels in the property being sold. For example, One Level, Two Levels, Split Level, Three or More Levels, Multi Level, Loft. A discrete horizontal plane of interior living space (excluding basements). */
  levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The total livable area within the structure. */
  livingArea?: Maybe<Scalars['Float']>;
  /** The source of the measurements. This is a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  livingAreaSource?: Maybe<Scalars['String']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  livingAreaUnits?: Maybe<Scalars['String']>;
  /** The source of the measurements. This may be a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  lotDimensionsSource?: Maybe<Scalars['String']>;
  /** A list of features or description of the lot included in the sale/lease. */
  lotFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The total Acres of the lot. This field is related to the Lot Size Area and Lot Size Units and must be in sync with the values represented in those fields. Lot Size Source also applies to this field when used. */
  lotSizeAcres?: Maybe<Scalars['Float']>;
  /** The total area of the lot. See Lot Size Units for the units of measurement (Square Feet, Square Meters, Acres, etc.). */
  lotSizeArea?: Maybe<Scalars['Float']>;
  /** The dimensions of the lot minimally represented as length and width (i.e. 250 x 180) or a measurement of all sides of the polygon representing the property lines of the property. i.e. 30 x 50 x 120 x 60 x 22. */
  lotSizeDimensions?: Maybe<Scalars['String']>;
  /** The source of the measurements. This may be a pick list of options showing the source of the measurement. i.e. Agent, Assessor, Estimate, etc. */
  lotSizeSource?: Maybe<Scalars['String']>;
  /** The total square footage of the lot. This field is related to the Lot Size Area and Lot Size Units and must be in sync with the values represented in those fields. Lot Size Source also applies to this field when used. */
  lotSizeSquareFeet?: Maybe<Scalars['Float']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  lotSizeUnits?: Maybe<Scalars['String']>;
  /** The number of bathrooms located on the main or entry level of the property. */
  mainLevelBathrooms?: Maybe<Scalars['Int']>;
  /** The number of bedrooms located on the main or entry level of the property. */
  mainLevelBedrooms?: Maybe<Scalars['Int']>;
  /** Make of the mobile or manufactured home. */
  make?: Maybe<Scalars['String']>;
  mlsZoning?: Maybe<Scalars['String']>;
  mlsZoningDescription?: Maybe<Scalars['String']>;
  /** A pick list of the unit of measurement for the area. i.e. Square Feet, Square Meters, Acres, etc. */
  mobileDimUnits?: Maybe<Scalars['String']>;
  /** Is the mobile home to remain and be included in the sale of the property. */
  mobileHomeRemainsYN?: Maybe<Scalars['Boolean']>;
  /** Length of the mobile/manufactured home. */
  mobileLength?: Maybe<Scalars['Int']>;
  mobileManufactured?: Maybe<ListingPropertyDetailsResoMobileManufactured>;
  /** Width of the mobile/manufactured home. */
  mobileWidth?: Maybe<Scalars['Int']>;
  /** Model of the mobile or manufactured home. */
  model?: Maybe<Scalars['String']>;
  /** Net operating income is the revenue from a property after operating expenses have been deducted, but before deducting income taxes and financing expenses (interest and Principal Payments). For example, Gross Income - Operating Expenses = Net Operating Income (NOI). */
  netOperatingIncome?: Maybe<Scalars['Float']>;
  /** Is the property newly constructed and has not been previously occupied? */
  newConstructionYN?: Maybe<Scalars['Boolean']>;
  /** Total number of separate buildings included in the income property. */
  numberOfBuildings?: Maybe<Scalars['Int']>;
  /** Total number of lots on the property or included in the sale. Land properties are often sold with multiple lots. It is important to be able to describe how many lots are in the property and not in all cases do lots have separate Parcel IDs. */
  numberOfLots?: Maybe<Scalars['Int']>;
  /** The number of pads or spaces in the mobile home park. */
  numberOfPads?: Maybe<Scalars['Int']>;
  /** Total number of separate meters on the property. */
  numberOfSeparateElectricMeters?: Maybe<Scalars['Int']>;
  /** Total number of separate meters on the property. */
  numberOfSeparateGasMeters?: Maybe<Scalars['Int']>;
  /** Total number of separate meters on the property. */
  numberOfSeparateWaterMeters?: Maybe<Scalars['Int']>;
  /** Total number of units currently under a lease agreement. */
  numberOfUnitsLeased?: Maybe<Scalars['Int']>;
  /** The total number of units leasable month to month. */
  numberOfUnitsMoMo?: Maybe<Scalars['Int']>;
  /** Total number of units included in the income property, occupied or unoccupied. */
  numberOfUnitsTotal?: Maybe<Scalars['Int']>;
  /** The number of units currently vacant. */
  numberOfUnitsVacant?: Maybe<Scalars['Int']>;
  /** Name of the current occupant, if any, of the property being sold. */
  occupantName?: Maybe<Scalars['String']>;
  /** North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol. */
  occupantPhone?: Maybe<Scalars['String']>;
  /** A field that describes the type of occupant, i.e. Owner, Tenant, Vacant. */
  occupantType?: Maybe<Scalars['String']>;
  /** The number of open or uncovered parking spaces included in the sale. */
  openParkingSpaces?: Maybe<Scalars['Float']>;
  /** A flag indicating that any parking spaces associated with the property are not covered by a roof. */
  openParkingYN?: Maybe<Scalars['Boolean']>;
  /** The costs associated with the operation and maintenance of an income-producing property. */
  operatingExpense?: Maybe<Scalars['Float']>;
  /** When individual expense fields are not used and only a total is entered, this lists the expenses that are included in the OperatingExpense field. */
  operatingExpenseIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of other equipment that will be included in the sale of the property. */
  otherEquipment?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Other types of parking available to, or part of, the property. */
  otherParking?: Maybe<Scalars['String']>;
  /** A list of structures other than the main dwelling. For example, Guest House, Barn, Shed, etc. */
  otherStructures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Name of the owner of the property being sold. */
  ownerName?: Maybe<Scalars['String']>;
  /** A list of expenses for the property paid for by the owner as opposed to the tenant (e.g. Water, Trash, Electric). */
  ownerPays?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol. */
  ownerPhone?: Maybe<Scalars['String']>;
  /** A number used to uniquely identify a parcel or lot. This number is typically issued by the county or county assessor. The AP number format varies from county to county. It is recommended that all Parcel Numbers be transmitted without dashes or hyphens. */
  parcelNumber?: Maybe<Scalars['String']>;
  /** Name of the manager of the mobile home park. */
  parkManagerName?: Maybe<Scalars['String']>;
  /** North American 10 digit phone numbers should be in the format of ###-###-#### (separated by hyphens). Other conventions should use the common local standard. International numbers should be preceded by a plus symbol. */
  parkManagerPhone?: Maybe<Scalars['String']>;
  /** Name of the mobile home park or corporate/commercial park. */
  parkName?: Maybe<Scalars['String']>;
  /** A list of features or description of the parking included in the sale/lease. */
  parkingFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The total number of parking spaces included in the sale. */
  parkingTotal?: Maybe<Scalars['Float']>;
  /** Measurement or percentage of the property that has been allocated as pasture or grazing area. */
  pastureArea?: Maybe<Scalars['Float']>;
  /** A list of features or description of the patio or porch included in the sale/lease. */
  patioAndPorchFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Are pets allowed at the property being leased? A list of yes, no and more detailed restrictions/allowances. */
  petsAllowed?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of features or description of the pool included in the sale/lease. */
  poolFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property has a privately owned pool that is included in the sale/lease. */
  poolPrivateYN?: Maybe<Scalars['Boolean']>;
  /** A list of the type(s) of possible or best uses of the property. Probable use gives a good indication of what the best use or potential use of the property could be. i.e. Primary, Vacation, Investment, Rental, Retirement */
  possibleUse?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A flag indicating that the primary structure is attached to another structure that is not included in the sale. i.e. one unit of a duplex. This flag may be T/F, Y/N or a list of attached or detached. As with all flags, the field may be null. In some systems this information may be part of the Property Sub Type. */
  propertyAttachedYN?: Maybe<Scalars['Boolean']>;
  /** A list describing the condition of the property and any structures included in the sale. */
  propertyCondition?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of types of residential and residential lease properties, i.e. SFR, Condo, etc. Or a list of Sub Types for Mobile, such as Expando, Manufactured, Modular, etc. */
  propertySubType?: Maybe<Scalars['String']>;
  /** A list of types of properties such as Residential, Lease, Income, Land, Mobile, Commercial Sale, etc... */
  propertyType?: Maybe<Scalars['String']>;
  /** This field specifically identifies the Range identified by the Public Land Survey System (PLSS). */
  publicSurveyRange?: Maybe<Scalars['String']>;
  /** This field specifically identifies the Section identified by the Public Land Survey System (PLSS). */
  publicSurveySection?: Maybe<Scalars['String']>;
  /** This field specifically identifies the Township identified by the Public Land Survey System (PLSS). */
  publicSurveyTownship?: Maybe<Scalars['String']>;
  /** Measurement or percentage of the property that has been allocated as range. */
  rangeArea?: Maybe<Scalars['Float']>;
  /** Is the property in a rent control area? */
  rentControlYN?: Maybe<Scalars['Boolean']>;
  /** A list of services or items that the tenant is not responsible to pay. */
  rentIncludes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of types of Road frontage. i.e. Freeway frontage, No Road Frontage, etc. The road frontage of the property is an important factor in determining value of the property and it's appropriateness for intended use. */
  roadFrontageType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The person or entity responsible for road maintenance (e.g., City, County, Private). */
  roadResponsibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Pick list of types of surface of the Road to access the property. The surface of the road(s) for access to the property is an important factor in determining value of the property and it's appropriateness for intended use. */
  roadSurfaceType?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list describing the type or style of roof. For example Spanish Tile, Composite, Shake, etc. */
  roof?: Maybe<Array<Maybe<Scalars['String']>>>;
  room?: Maybe<Array<Maybe<ListingPropertyDetailsResoRoom>>>;
  /** The number of rooms in the dwelling. */
  roomsTotal?: Maybe<Scalars['Int']>;
  /** The dimensions of the RV parking area minimally represented as length and width (i.e. 25 x 18) or a measurement of all sides of the polygon representing the usable RV parking space. i.e. 33 x 15 x 12 x 60. */
  rvParkingDimensions?: Maybe<Scalars['String']>;
  /** A list describing the security features included in the sale/lease. */
  securityFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The community is a senior community. */
  seniorCommunityYN?: Maybe<Scalars['Boolean']>;
  /** A list describing the sewer or septic features of the property. */
  sewer?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of types of mobile home skirting. */
  skirt?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of features or description of the spa included in the sale/lease. */
  spaFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property has a spa. */
  spaYN?: Maybe<Scalars['Boolean']>;
  /** The number of floors in the property being sold. */
  stories?: Maybe<Scalars['Float']>;
  /** The total number of floors in the building. In the case of multi-dwelling structures, this is the entire structure and not the individual dwelling being sold. */
  storiesTotal?: Maybe<Scalars['Float']>;
  /** The annual property tax amount as of the last assessment made by the taxing authority. */
  taxAnnualAmount?: Maybe<Scalars['Float']>;
  /** The property value as of the last assessment made by the taxing authority. */
  taxAssessedValue?: Maybe<Scalars['Float']>;
  /** A type of legal description for land in developed areas where streets or other rights-of-ways delineate large parcels of land referred to as divided into lots on which homes or other types of developments are built. An example would read "Lot 12 of Block 45 of Tract 3002 of the City of San Dunes, Desert County." Such a description would also reference an official plat filed with the clerk or recorder for that area which shows the location of the block and often the dimensions of the lots therein. */
  taxBlock?: Maybe<Scalars['String']>;
  /** Some systems of parcel identification incorporate a method which utilizes a county identifier, a tax book number, a tax map number and a parcel identification number. */
  taxBookNumber?: Maybe<Scalars['String']>;
  /** A list of tax exemptions as they relate to the property. */
  taxExemptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A type of legal description for land in developed areas where streets or other rights-of-ways delineate large parcels of land referred to as divided into lots on which homes or other types of developments are built. An example would read "Lot 12 of Block 45 of Tract 3002 of the City of San Dunes, Desert County." Such a description would also reference an official plat filed with the clerk or recorder for that area which shows the location of the block and often the dimensions of the lots therein. The text here is also an index into the property as described by the County Recorder. */
  taxLegalDescription?: Maybe<Scalars['String']>;
  /** A type of legal description for land in developed areas where streets or other rights-of-ways delineate large parcels of land referred to as divided into lots on which homes or other types of developments are built. An example would read "Lot 12 of Block 45 of Tract 3002 of the City of San Dunes, Desert County." Such a description would also reference an official plat filed with the clerk or recorder for that area which shows the location of the block and often the dimensions of the lots therein. */
  taxLot?: Maybe<Scalars['String']>;
  /** Some systems of parcel identification incorporate a method which utilizes a county identifier, a tax book number, a tax map number and a parcel identification number. */
  taxMapNumber?: Maybe<Scalars['String']>;
  /** Any other annual taxes, not including the tax reported in the TaxAmount field, as of the last assessment made by the taxing authority. */
  taxOtherAnnualAssessmentAmount?: Maybe<Scalars['Float']>;
  /** Some systems of parcel identification incorporate a method which utilizes a county identifier, a tax book number, a tax map number and a parcel identification number. */
  taxParcelLetter?: Maybe<Scalars['String']>;
  /** The current tax status of the mobile home in cases where the land or space is included in the sale. */
  taxStatusCurrent?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A type of legal description for land in developed areas where streets or other rights-of-ways delineate large parcels of land referred to as divided into lots on which homes or other types of developments are built. An example would read "Lot 12 of Block 45 of Tract 3002 of the City of San Dunes, Desert County." Such a description would also reference an official plat filed with the clerk or recorder for that area which shows the location of the block and often the dimensions of the lots therein. */
  taxTract?: Maybe<Scalars['String']>;
  /** The year in with the last assessment of the property value/tax was made. */
  taxYear?: Maybe<Scalars['Int']>;
  /** A list of telephone-service related features of the property (e.g. Installed, Public, Available). Note: the previous "Telephone" field was renamed to DistanceToPhoneService */
  telephone?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of services or items that the tenant is responsible to pay. */
  tenantPays?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The state of the surface of the land included with the property. i.e. flat, rolling, etc. */
  topography?: Maybe<Scalars['String']>;
  /** Total actual rent currently being collected from tenants of the income property. */
  totalActualRent?: Maybe<Scalars['Float']>;
  unit?: Maybe<Array<Maybe<ListingPropertyDetailsResoUnit>>>;
  /** Are the units furnished? i.e. All Units, Varies By Unit, None. */
  unitsFurnished?: Maybe<Scalars['String']>;
  /** A list of the utilities for the property being sold/leased. */
  utilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An estimate of the amount of rent that may be foregone because of unoccupied units. */
  vacancyAllowance?: Maybe<Scalars['Float']>;
  /** An estimate of the percent of rent that may be foregone because of unoccupied units. */
  vacancyAllowanceRate?: Maybe<Scalars['Float']>;
  /** A list of the type(s) of vegetation on the property. Note that this is not for farm crops, but more residential type vegetation. */
  vegetation?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A view as seen from the listed property. */
  view?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property has a view. */
  viewYN?: Maybe<Scalars['Boolean']>;
  /** A walkability index based on the time to walk from a property to near by essentials such as grocery stores, schools, churches, etc. See www.walkscore.com for more information and requirements for using WalkScore. */
  walkScore?: Maybe<Scalars['Int']>;
  /** The name, if known, of the body of water on which the property is located. (E.g., lake name, river name, ocean name, sea name, canal name). */
  waterBodyName?: Maybe<Scalars['String']>;
  /** Features of the waterfront on which the property is located. */
  waterFrontFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of the source(s) of water for the property */
  waterSource?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The property is on the waterfront. */
  waterfrontYN?: Maybe<Scalars['Boolean']>;
  /** A list of features or description of the windows included in the sale/lease. */
  windowFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Measurement or percentage of the property that is wooded or forest. */
  woodedArea?: Maybe<Scalars['Float']>;
  /** The year that an occupancy permit is first granted for the house or other local measure of initial habitability of the build. The type definition permits an empty value with an attribute noting that it is an unknown date or that the building is new construction. While constraints have not been applied, convention at the time of adoption has this as a four (4) digit year value. */
  yearBuilt?: Maybe<Scalars['Int']>;
  /** A description of the details behind the year the structure was built. */
  yearBuiltDetails?: Maybe<Scalars['String']>;
  /** The year a major rebuild/renovated of the structure occurred. */
  yearBuiltEffective?: Maybe<Scalars['Int']>;
  /** Add a list of sources of the year built. i.e. Appraiser, Assessor, Builder, Estimated, etc., */
  yearBuiltSource?: Maybe<Scalars['String']>;
};

export type ListingPropertyDetailsResoBuyerFinancing = {
  __typename?: 'ListingPropertyDetailsResoBuyerFinancing';
  /** A list of options that describe the type of financing used. This field is used when setting a listing to Closed. i.e. cash, FHA loan, etc. */
  description?: Maybe<Scalars['String']>;
  /** A list of options that describe the type of financing used. This field is used when setting a listing to Closed. i.e. cash, FHA loan, etc. */
  loanAmount?: Maybe<Scalars['Float']>;
  /** A list of options that describe the type of financing used. This field is used when setting a listing to Closed. i.e. cash, FHA loan, etc. */
  loanTerm?: Maybe<Scalars['Float']>;
  /** A list of options that describe the type of financing used. This field is used when setting a listing to Closed. i.e. cash, FHA loan, etc. */
  type?: Maybe<Scalars['String']>;
};

export type ListingPropertyDetailsResoExpense = {
  __typename?: 'ListingPropertyDetailsResoExpense';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type ListingPropertyDetailsResoGreenBuildingVerification = {
  __typename?: 'ListingPropertyDetailsResoGreenBuildingVerification';
  body?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
};

export type ListingPropertyDetailsResoMobileManufactured = {
  __typename?: 'ListingPropertyDetailsResoMobileManufactured';
  doh?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
};

export type ListingPropertyDetailsResoRoom = {
  __typename?: 'ListingPropertyDetailsResoRoom';
  area?: Maybe<Scalars['Float']>;
  areaSource?: Maybe<Scalars['String']>;
  areaUnits?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthSource?: Maybe<Scalars['String']>;
  lengthWidthUnits?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type ListingPropertyDetailsResoUnit = {
  __typename?: 'ListingPropertyDetailsResoUnit';
  actualRent?: Maybe<Scalars['Float']>;
  bathsTotal?: Maybe<Scalars['Float']>;
  bedsTotal?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  furnished?: Maybe<Scalars['String']>;
  garageSpaces?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
};

export type ListingStatsSearchFiltersInput = {
  closeToListPriceMedianMax?: InputMaybe<Scalars['Float']>;
  closeToListPriceMedianMin?: InputMaybe<Scalars['Float']>;
  daysOnMarketMedianMax?: InputMaybe<Scalars['Int']>;
  daysOnMarketMedianMin?: InputMaybe<Scalars['Int']>;
  daysToCloseMedianMax?: InputMaybe<Scalars['Int']>;
  daysToCloseMedianMin?: InputMaybe<Scalars['Int']>;
  listingsClosedCountMax?: InputMaybe<Scalars['Int']>;
  listingsClosedCountMin?: InputMaybe<Scalars['Int']>;
  listingsNewCountMax?: InputMaybe<Scalars['Int']>;
  listingsNewCountMin?: InputMaybe<Scalars['Int']>;
  listingsOnMarketCountMax?: InputMaybe<Scalars['Int']>;
  listingsOnMarketCountMin?: InputMaybe<Scalars['Int']>;
  listingsPriceDropPercentMin?: InputMaybe<Scalars['Float']>;
  listingsPriceDropPercentsMax?: InputMaybe<Scalars['Float']>;
  monthsOfSupplyMedianMax?: InputMaybe<Scalars['Float']>;
  monthsOfSupplyMedianMin?: InputMaybe<Scalars['Float']>;
  priceChangePercentMedianMax?: InputMaybe<Scalars['Float']>;
  priceChangePercentMedianMin?: InputMaybe<Scalars['Float']>;
  priceClosedMedianMax?: InputMaybe<Scalars['Int']>;
  priceClosedMedianMin?: InputMaybe<Scalars['Int']>;
  priceOnMarketMedianMax?: InputMaybe<Scalars['Int']>;
  priceOnMarketMedianMin?: InputMaybe<Scalars['Int']>;
};

export type LivingAreaValueFactor = {
  __typename?: 'LivingAreaValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The median value of this attribute in the area */
  areaMedian?: Maybe<Scalars['Int']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Int']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

/** Loan issues */
export enum LoanIssueType {
  /** ARM rate change year unavailable */
  ArmChangeYearUnavailable = 'ARM_CHANGE_YEAR_UNAVAILABLE',
  /** ARM delta from index missing, treating as fixed rate */
  ArmDeltaIndexMissingTreatingAsFixed = 'ARM_DELTA_INDEX_MISSING_TREATING_AS_FIXED',
  /** Not variable from start ARM, deriving missing ARM rate change year from rate change frequency */
  ArmDerivingMissingChangeYear = 'ARM_DERIVING_MISSING_CHANGE_YEAR',
  /** ARM fixed period duration malformed/errors-in-deriving, treating as fixed rate */
  ArmFixedPeriodMalformedTreatingAsFixed = 'ARM_FIXED_PERIOD_MALFORMED_TREATING_AS_FIXED',
  /** ARM fixed period duration too long, so as to be malformed/errors-in-deriving, treating as fixed rate */
  ArmFixedPeriodTooLongTreatingAsFixed = 'ARM_FIXED_PERIOD_TOO_LONG_TREATING_AS_FIXED',
  /** Not variable from start ARM,LoanIssueType missing ARM rate change year, no rate change frequency available, treating as fixed rate */
  ArmMissingChangeYearTreatingAsFixed = 'ARM_MISSING_CHANGE_YEAR_TREATING_AS_FIXED',
  /** Malformed ARM rate change date, treating as fixed rate */
  ArmRateChangeDateMalformed = 'ARM_RATE_CHANGE_DATE_MALFORMED',
  /** ARM rate change day assumed */
  ArmRateChangeDayAssumed = 'ARM_RATE_CHANGE_DAY_ASSUMED',
  /** Assuming ARM rate change frequency is annual */
  ArmRateChangeFreqAssumedAnnual = 'ARM_RATE_CHANGE_FREQ_ASSUMED_ANNUAL',
  /** ARM rate change month & day assumed */
  ArmRateChangeMonthDayAssumed = 'ARM_RATE_CHANGE_MONTH_DAY_ASSUMED',
  /** ARM rate index greater than 6 months out of date to required date */
  ArmRateIndexExceeds_6Months = 'ARM_RATE_INDEX_EXCEEDS_6_MONTHS',
  /** ARM rate index out of date by at most 6 months to required date */
  ArmRateIndexOutOfDate = 'ARM_RATE_INDEX_OUT_OF_DATE',
  /** Assumptions made in ARM index rate fetch */
  AssumedFetchArmIndexRates = 'ASSUMED_FETCH_ARM_INDEX_RATES',
  /** Assuming ARM interest only duration is equal to fixed period duration */
  AssumingArmInterestOnlyDurEqFixedPeriodDur = 'ASSUMING_ARM_INTEREST_ONLY_DUR_EQ_FIXED_PERIOD_DUR',
  /** Assuming LIBOR_6M as ARM index */
  AssumingLibor_6MAsArmIndex = 'ASSUMING_LIBOR_6M_AS_ARM_INDEX',
  /** Unable to retrieve ARM index rate as of required calculation date, treating as fixed rate */
  CantFindArmIndexRateForDateTreatingAsFixed = 'CANT_FIND_ARM_INDEX_RATE_FOR_DATE_TREATING_AS_FIXED',
  /** In fixed ARM period but using estimated initial fixed rate */
  FixedArmEstimatingInitialInterestRate = 'FIXED_ARM_ESTIMATING_INITIAL_INTEREST_RATE',
  /** In fixed ARM period but missing initial fixed rate, failing out */
  FixedArmMissingInitialInterestRate = 'FIXED_ARM_MISSING_INITIAL_INTEREST_RATE',
  /** Estimated interest rate */
  InterestRateEstimated = 'INTEREST_RATE_ESTIMATED',
  /** Interest rate unavailable, failing out */
  InterestRateUnavailable = 'INTEREST_RATE_UNAVAILABLE',
  /** Unavailable date of last sale */
  LastSaleDateUnavailable = 'LAST_SALE_DATE_UNAVAILABLE',
  /** Loan determined by active heuristic */
  LoanDeterminedActiveHeuristic = 'LOAN_DETERMINED_ACTIVE_HEURISTIC',
  /** Loan has unknown balloon */
  LoanHasUnknownBalloon = 'LOAN_HAS_UNKNOWN_BALLOON',
  /** Loan length estimated */
  LoanLengthEstimated = 'LOAN_LENGTH_ESTIMATED',
  /** Assuming ARM interest only duration is equal to fixed period duration */
  MalformedArmInterestOnlyDurDisregarding = 'MALFORMED_ARM_INTEREST_ONLY_DUR_DISREGARDING',
  /** Due date unavailable */
  MissingDueDate = 'MISSING_DUE_DATE',
  /** Record date unavailable */
  MissingRecordDate = 'MISSING_RECORD_DATE',
  /** Loan type is Negative Amortization, failing out */
  NegativeAmortization = 'NEGATIVE_AMORTIZATION',
  /** No interest only period in ARM and estimating initial fixed rate */
  NoInterestOnlyArmEstimatingInitialInterestRate = 'NO_INTEREST_ONLY_ARM_ESTIMATING_INITIAL_INTEREST_RATE',
  /** No interest only period in ARM and missing initial fixed rate, failing out */
  NoInterestOnlyArmMissingInitialInterestRate = 'NO_INTEREST_ONLY_ARM_MISSING_INITIAL_INTEREST_RATE',
  /** Loan type is Reverse Mortgage, failing out */
  ReverseMortgage = 'REVERSE_MORTGAGE',
  /** Unable to retrieve ARM index rates, treating as fixed rate */
  UnableToFetchArmIndexRates = 'UNABLE_TO_FETCH_ARM_INDEX_RATES',
}

export type LoanOfficerDetails = {
  __typename?: 'LoanOfficerDetails';
  identifiers?: Maybe<LoanOfficerIdentifiers>;
  liens?: Maybe<LoanOfficerLiensSearchResults>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  statistic?: Maybe<LoanOfficerStatistic>;
  statistics?: Maybe<LoanOfficerStatistics>;
  type?: Maybe<LoanOfficerType>;
};

export type LoanOfficerDetailsLiensArgs = {
  after?: InputMaybe<Scalars['String']>;
  countyFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period?: InputMaybe<LoanOfficerStatisticsPeriod>;
  stateAbbrev?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LoanOfficerIdentifierMlsId = {
  __typename?: 'LoanOfficerIdentifierMlsID';
  nmlsId?: Maybe<Scalars['String']>;
};

export type LoanOfficerIdentifiers = {
  __typename?: 'LoanOfficerIdentifiers';
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationalMlsIds?: Maybe<Array<Maybe<LoanOfficerIdentifierMlsId>>>;
};

export type LoanOfficerLiensSearchResults = {
  __typename?: 'LoanOfficerLiensSearchResults';
  edges?: Maybe<Array<Maybe<LoanOfficerLiensSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type LoanOfficerLiensSearchResultsEdges = {
  __typename?: 'LoanOfficerLiensSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LienHistoryEvents>;
};

/** Loan amount types */
export enum LoanOfficerLoanAmountType {
  All = 'ALL',
  Between_250KAnd_500K = 'BETWEEN_250K_AND_500K',
  Between_500KAnd_1M = 'BETWEEN_500K_AND_1M',
  GreaterThan_1M = 'GREATER_THAN_1M',
  LessThan_250K = 'LESS_THAN_250K',
}

/** Loan types */
export enum LoanOfficerLoanType {
  All = 'ALL',
}

/** Supported property types */
export enum LoanOfficerPropertyType {
  All = 'ALL',
}

export type LoanOfficerSearchResults = {
  __typename?: 'LoanOfficerSearchResults';
  edges?: Maybe<Array<Maybe<LoanOfficerSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type LoanOfficerSearchResultsEdges = {
  __typename?: 'LoanOfficerSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LoanOfficerDetails>;
};

export type LoanOfficerStatistic = {
  __typename?: 'LoanOfficerStatistic';
  county?: Maybe<LoanOfficerStatisticCounty>;
  msa?: Maybe<LoanOfficerStatisticMsa>;
  national?: Maybe<LoanOfficerStatisticNational>;
  state?: Maybe<LoanOfficerStatisticState>;
  zipcode?: Maybe<LoanOfficerStatisticZipcode>;
};

export type LoanOfficerStatisticCountyArgs = {
  fips: Scalars['String'];
  loanAmount: LoanOfficerLoanAmountType;
  loanType: LoanOfficerLoanType;
  period: LoanOfficerStatisticsPeriod;
  propertyType: LoanOfficerPropertyType;
};

export type LoanOfficerStatisticMsaArgs = {
  loanAmount: LoanOfficerLoanAmountType;
  loanType: LoanOfficerLoanType;
  msaId: Scalars['String'];
  period: LoanOfficerStatisticsPeriod;
  propertyType: LoanOfficerPropertyType;
};

export type LoanOfficerStatisticNationalArgs = {
  loanAmount: LoanOfficerLoanAmountType;
  loanType: LoanOfficerLoanType;
  period: LoanOfficerStatisticsPeriod;
  propertyType: LoanOfficerPropertyType;
};

export type LoanOfficerStatisticStateArgs = {
  loanAmount: LoanOfficerLoanAmountType;
  loanType: LoanOfficerLoanType;
  period: LoanOfficerStatisticsPeriod;
  propertyType: LoanOfficerPropertyType;
  stateAbbr?: InputMaybe<Scalars['String']>;
  stateFips?: InputMaybe<Scalars['String']>;
};

export type LoanOfficerStatisticZipcodeArgs = {
  loanAmount: LoanOfficerLoanAmountType;
  loanType: LoanOfficerLoanType;
  period: LoanOfficerStatisticsPeriod;
  propertyType: LoanOfficerPropertyType;
  zipcode: Scalars['String'];
};

export type LoanOfficerStatisticCounty = {
  __typename?: 'LoanOfficerStatisticCounty';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  countTotal?: Maybe<Scalars['Int']>;
  fips?: Maybe<Scalars['String']>;
  loanAmount?: Maybe<LoanOfficerLoanAmountType>;
  loanType?: Maybe<LoanOfficerLoanType>;
  period?: Maybe<LoanOfficerStatisticsPeriod>;
  propertyType?: Maybe<LoanOfficerPropertyType>;
};

export type LoanOfficerStatisticMsa = {
  __typename?: 'LoanOfficerStatisticMsa';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  countTotal?: Maybe<Scalars['Int']>;
  loanAmount?: Maybe<LoanOfficerLoanAmountType>;
  loanType?: Maybe<LoanOfficerLoanType>;
  msaId?: Maybe<Scalars['String']>;
  period?: Maybe<LoanOfficerStatisticsPeriod>;
  propertyType?: Maybe<LoanOfficerPropertyType>;
};

export type LoanOfficerStatisticNational = {
  __typename?: 'LoanOfficerStatisticNational';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  countTotal?: Maybe<Scalars['Int']>;
  loanAmount?: Maybe<LoanOfficerLoanAmountType>;
  loanType?: Maybe<LoanOfficerLoanType>;
  period?: Maybe<LoanOfficerStatisticsPeriod>;
  propertyType?: Maybe<LoanOfficerPropertyType>;
};

export type LoanOfficerStatisticState = {
  __typename?: 'LoanOfficerStatisticState';
  abbrev?: Maybe<Scalars['String']>;
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  countTotal?: Maybe<Scalars['Int']>;
  fips?: Maybe<Scalars['String']>;
  loanAmount?: Maybe<LoanOfficerLoanAmountType>;
  loanType?: Maybe<LoanOfficerLoanType>;
  period?: Maybe<LoanOfficerStatisticsPeriod>;
  propertyType?: Maybe<LoanOfficerPropertyType>;
};

export type LoanOfficerStatisticZipcode = {
  __typename?: 'LoanOfficerStatisticZipcode';
  amountAverage?: Maybe<Scalars['Float']>;
  amountTotal?: Maybe<Scalars['Float']>;
  countTotal?: Maybe<Scalars['Int']>;
  loanAmount?: Maybe<LoanOfficerLoanAmountType>;
  loanType?: Maybe<LoanOfficerLoanType>;
  period?: Maybe<LoanOfficerStatisticsPeriod>;
  propertyType?: Maybe<LoanOfficerPropertyType>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LoanOfficerStatistics = {
  __typename?: 'LoanOfficerStatistics';
  county?: Maybe<Array<Maybe<LoanOfficerStatisticCounty>>>;
  msa?: Maybe<Array<Maybe<LoanOfficerStatisticMsa>>>;
  national?: Maybe<Array<Maybe<LoanOfficerStatisticNational>>>;
  state?: Maybe<Array<Maybe<LoanOfficerStatisticState>>>;
  zipcode?: Maybe<Array<Maybe<LoanOfficerStatisticZipcode>>>;
};

export type LoanOfficerStatisticsCountyArgs = {
  fips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  period?: InputMaybe<Array<InputMaybe<LoanOfficerStatisticsPeriod>>>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
};

export type LoanOfficerStatisticsMsaArgs = {
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  msaId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period?: InputMaybe<Array<InputMaybe<LoanOfficerStatisticsPeriod>>>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
};

export type LoanOfficerStatisticsNationalArgs = {
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  period?: InputMaybe<Array<InputMaybe<LoanOfficerStatisticsPeriod>>>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
};

export type LoanOfficerStatisticsStateArgs = {
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  period?: InputMaybe<Array<InputMaybe<LoanOfficerStatisticsPeriod>>>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
  stateAbbr?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  stateFips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LoanOfficerStatisticsZipcodeArgs = {
  loanAmount?: InputMaybe<Array<InputMaybe<LoanOfficerLoanAmountType>>>;
  loanType?: InputMaybe<Array<InputMaybe<LoanOfficerLoanType>>>;
  period?: InputMaybe<Array<InputMaybe<LoanOfficerStatisticsPeriod>>>;
  propertyType?: InputMaybe<Array<InputMaybe<LoanOfficerPropertyType>>>;
  zipcode?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Agent search sort field */
export enum LoanOfficerStatisticsField {
  AmountAverage = 'AMOUNT_AVERAGE',
  AmountTotal = 'AMOUNT_TOTAL',
  CountTotal = 'COUNT_TOTAL',
}

/** LoanOfficer statistics period */
export enum LoanOfficerStatisticsPeriod {
  EighteenMonths = 'EIGHTEEN_MONTHS',
  SixMonths = 'SIX_MONTHS',
  ThirtySixMonths = 'THIRTY_SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS',
}

/** Loan Officer Types */
export enum LoanOfficerType {
  Broker = 'BROKER',
  Officer = 'OFFICER',
  Organization = 'ORGANIZATION',
}

export type LoanToValueForecast = {
  __typename?: 'LoanToValueForecast';
  month0?: Maybe<LoanToValueForecastFieldsByTerm>;
  month6?: Maybe<LoanToValueForecastFieldsByTerm>;
  month12?: Maybe<LoanToValueForecastFieldsByTerm>;
  month18?: Maybe<LoanToValueForecastFieldsByTerm>;
  month24?: Maybe<LoanToValueForecastFieldsByTerm>;
  month30?: Maybe<LoanToValueForecastFieldsByTerm>;
  month36?: Maybe<LoanToValueForecastFieldsByTerm>;
};

export type LoanToValueForecastFieldsByTerm = {
  __typename?: 'LoanToValueForecastFieldsByTerm';
  dataAssumptions?: Maybe<Array<Maybe<LoanIssueType>>>;
  loanToValue?: Maybe<Scalars['Float']>;
  loanToValueLower?: Maybe<Scalars['Float']>;
  loanToValueUpper?: Maybe<Scalars['Float']>;
};

export type LoanToValueLiensCurrent = {
  __typename?: 'LoanToValueLiensCurrent';
  adjustableRateIndex?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  borrower?: Maybe<Array<Maybe<LoanToValueLiensCurrentBorrower>>>;
  contractDate?: Maybe<Scalars['Date']>;
  dataAssumptions?: Maybe<Array<Maybe<LoanIssueType>>>;
  dueDate?: Maybe<Scalars['Date']>;
  interestRateUsed?: Maybe<Scalars['Float']>;
  lender?: Maybe<LoanToValueLiensCurrentLender>;
  /** Loan term in months */
  loanTerm?: Maybe<Scalars['Int']>;
  loanType?: Maybe<LoanType>;
  principalOutstanding?: Maybe<Scalars['Int']>;
  principalPaid?: Maybe<Scalars['Int']>;
};

export type LoanToValueLiensCurrentBorrower = {
  __typename?: 'LoanToValueLiensCurrentBorrower';
  /** not yet supported */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** not yet supported */
  type?: Maybe<Scalars['String']>;
};

export type LoanToValueLiensCurrentLender = {
  __typename?: 'LoanToValueLiensCurrentLender';
  /** not yet supported */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** not yet supported */
  type?: Maybe<Scalars['String']>;
};

export type LoanToValueOrigination = {
  __typename?: 'LoanToValueOrigination';
  amount?: Maybe<Scalars['Int']>;
  contractDate?: Maybe<Scalars['Date']>;
  loanToValue?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Int']>;
  valueSource?: Maybe<Scalars['String']>;
};

/** Loan types */
export enum LoanType {
  Arm = 'ARM',
  Assumption = 'ASSUMPTION',
  Balloon = 'BALLOON',
  ClosedEnd = 'CLOSED_END',
  Commercial = 'COMMERCIAL',
  Construction = 'CONSTRUCTION',
  Conventional = 'CONVENTIONAL',
  FannieMaeFreddieMac = 'FANNIE_MAE_FREDDIE_MAC',
  FarmersHomeAdministration = 'FARMERS_HOME_ADMINISTRATION',
  Fha = 'FHA',
  LandContract = 'LAND_CONTRACT',
  Modification = 'MODIFICATION',
  NegativeAmortization = 'NEGATIVE_AMORTIZATION',
  NonPurchaseMoney = 'NON_PURCHASE_MONEY',
  OpenEnd = 'OPEN_END',
  Other = 'OTHER',
  PurchaseMoney = 'PURCHASE_MONEY',
  Reverse = 'REVERSE',
  RevolvingCreditLine = 'REVOLVING_CREDIT_LINE',
  Sba = 'SBA',
  SecondToCoverDownPayment = 'SECOND_TO_COVER_DOWN_PAYMENT',
  SellerTakeBack = 'SELLER_TAKE_BACK',
  /** Stand Alone First */
  StandAloneFirst = 'STAND_ALONE_FIRST',
  StandAloneRefi = 'STAND_ALONE_REFI',
  /** Stand Alone Second */
  StandAloneSecond = 'STAND_ALONE_SECOND',
  StateVeterans = 'STATE_VETERANS',
  Unknown = 'UNKNOWN',
  Usda = 'USDA',
  Va = 'VA',
}

export type LookupResults = PropertyInformation & {
  __typename?: 'LookupResults';
  /** Transaction history, Current State, and Last Close State as computed with Listings and Deeds. Includes all status and pricing events. The main data is divided between Sale and Rental. The response requires application to handle display rights. */
  complexFields?: Maybe<Array<ComplexFields>>;
  /** Individual Listing histories for all listings allowed for display under the provided application. This can be joined to Listings on entityId. */
  complexFieldsMetadata?: Maybe<Array<ComplexFieldsMetadata>>;
  /** Within a single lookup, return comparable properties for the subject alongside any (or all) other property level data */
  comps?: Maybe<SpatialSearchResults>;
  /** Returns the latest Listing of type Sale and/or Rental based on the application/profile provided. It can include all data found in the common Listing schema. This uses the computed event history found in complex-fields. */
  latestListing: LatestListing;
  /** Returns data for four types of the computed transactional state of the property. For each, it can include the state/event level details, like status, price, and dates, as well as full listing data if the event is tied to a Listing. */
  latestState: LatestState;
  /** History of liens recorded concurrently with deeds and as standalone mortgages; history of all recorded Notice of Default (and related) events; HouseCanary Loan To Value calculation. */
  lienHistory: LienHistory;
  /** Use to get all permissioned Listings with their full available details */
  listings?: Maybe<Array<Listing>>;
  /** Return full address information that includes street address, Census geo IDs, latitude and longitude, location precision, and HC generated subdivision. */
  location?: Maybe<CommonLocation>;
  /** Understand whether the property's location is to be fully covered by available MLSs. There is a simple, top-level boolean for coverage as well as details for each principal MLS operating in the property's county. */
  mlsCoverage?: Maybe<MlsCoverage>;
  /** A complete view of how HouseCanary sees the core details of the property through three versions: computed best hc version, latest MLS listing version, and latest Assessment version. The focus of this data set are the physical characteristics of the property and structure, although it does include additional data on taxes and ownership. */
  propertyDetails: PropertyDetails;
  /** Full annual history of tax amount, assessed value, and market value as managed by the property's county (or equivalent). History mostly starts around 2015. Current ownership as recorded with the county is included. */
  taxHistory: TaxHistory;
  /** All property level HouseCanary valuation data and functionality around versions of value, historical, HPI adjusted, and property details adjusted. This include land value, rental value, and the standard value/AVM. */
  value: PropertyValue;
};

export type LookupResultsCompsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<SpatialPaginationInput>;
  radius?: InputMaybe<RadiusInput>;
  spatialFilters?: InputMaybe<SpatialCompsFiltersInput>;
  subjectFeatures?: InputMaybe<PropertyFeaturesInput>;
};

export type LookupResultsLienHistoryArgs = {
  asOf?: InputMaybe<Scalars['Date']>;
};

/** Risk Level */
export enum LossRiskLevel {
  High = 'HIGH',
  Low = 'LOW',
  Modest = 'MODEST',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW',
}

export type LotSizeValueFactor = {
  __typename?: 'LotSizeValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The median value of this attribute in the area */
  areaMedian?: Maybe<Scalars['Int']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Int']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export enum MarketGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
}

export type MetroDivisionAffordability = {
  __typename?: 'MetroDivisionAffordability';
  timeSeries?: Maybe<Array<MetroDivisionAffordabilityTimeSeriesElement>>;
};

export type MetroDivisionAffordabilityTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MetroDivisionAffordabilityTimeSeriesElement = {
  __typename?: 'MetroDivisionAffordabilityTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Raw affordability value. Represents the percent of median household income required to make the median home payment on a 30 year fixed rate mortgage with 20% down */
  payment?: Maybe<Scalars['Float']>;
  /** Normalized distance of afford_pmt from a long term linear trend. Units are in standard deviations from the mean */
  paymentDetrended?: Maybe<Scalars['Float']>;
};

export type MetroDivisionDetails = {
  __typename?: 'MetroDivisionDetails';
  /** Time Series - Affordability */
  affordability?: Maybe<MetroDivisionAffordability>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  /** House Price Index */
  hpi?: Maybe<MetroDivisionHpi>;
  metroDivisionID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MetroDivisionHpi = {
  __typename?: 'MetroDivisionHpi';
  timeSeries?: Maybe<Array<MetroDivisionHpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type MetroDivisionHpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MetroDivisionHpiTimeSeriesElement = {
  __typename?: 'MetroDivisionHpiTimeSeriesElement';
  /** Monthly change in velocity_value */
  accelerationValue?: Maybe<Scalars['Float']>;
  /** The normalized distance of hpi_value from a long term linear trend. Units are in standard deviations from the mean */
  distance?: Maybe<Scalars['Float']>;
  /** Month of the datapoint in the time series. string in ISO date format */
  month?: Maybe<Scalars['Date']>;
  /** Real housing price index after adjusting nominal HPI for inflation as measured by the CPI */
  real?: Maybe<Scalars['Float']>;
  /** Monthly returns in the nominal housing price index. Formally computed as hpi_value(t)/hpi_value(t-1) */
  return?: Maybe<Scalars['Float']>;
  /** Year over year percent change in the nominal housing price index. Formally computed as [hpi_value(t)/hpi_value(t-12)]-1. percent as float (.075 = 7.5%) */
  return1YearPercentChange?: Maybe<Scalars['Float']>;
  /** Long term linear trend in hpi_value */
  trend?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) */
  value?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) indexed to month of time series generation */
  valueIndexed?: Maybe<Scalars['Float']>;
  /** Smoothed version of the year over year change in hpi_value */
  velocityValue?: Maybe<Scalars['Float']>;
};

export type MlsCoverage = {
  __typename?: 'MlsCoverage';
  boards?: Maybe<Array<BoardDetails>>;
  /** Answers the question where the property is fully covered by all principal MLSs in its county. */
  covered?: Maybe<Scalars['Boolean']>;
};

export type MlsCoverageLevel = {
  __typename?: 'MlsCoverageLevel';
  level?: Maybe<Scalars['Int']>;
};

export type MlsRegulations = {
  __typename?: 'MlsRegulations';
  /** Boolean value representing whether or not MLS data can be augmented with additional non-listing data as long as: 1) It's not a prohibited data field. 2) Source of additional data is clearly identified */
  augmenting?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not cancelled listings can be displayed under MLS regulations */
  cancelled?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not closed listings can be displayed under MLS regulations */
  closed?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not the sold price of a closed listing can only be shown when logged in. */
  closedLoginPrice?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not MLS listings can be combined with other listings such as */
  coMingling?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not coming soon listings can be displayed under MLS regulations */
  comingSoon?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not contingent listings can be displayed under MLS regulations */
  contingent?: Maybe<Scalars['Boolean']>;
  /** Boolean value that representing whether or not contingent with kickout listings can be displayed under MLS regulations */
  contingentWithKickOut?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not the name of cooperating brokerage must also appear when displaying sold listings. */
  cooperatingBrokerage?: Maybe<Scalars['Boolean']>;
  /** Copyright statement as required by the MLS */
  copyright?: Maybe<Scalars['String']>;
  /** Boolean value representing whether or not deleted listings can be displayed under MLS regulations */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Disclaimer as required by the MLS */
  disclaimer?: Maybe<Scalars['String']>;
  /** Boolean value representing whether or not expired listings can be displayed under MLS regulations */
  expired?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not leased listings can be displayed under MLS regulations */
  leased?: Maybe<Scalars['Boolean']>;
  /** MLS logo as required for display by the MLS */
  logo?: Maybe<Scalars['String']>;
  /** Smaller MLS logo as required for display on individual images by the MLS */
  logoOverlay?: Maybe<Scalars['String']>;
  /** Boolean value representing whether or not pending listings can be displayed under MLS regulations */
  pending?: Maybe<Scalars['Boolean']>;
  /** Boolean value that represents whether or not all photos for closed listings are allowed to be publicly displayed */
  photosClosedAll?: Maybe<Scalars['Boolean']>;
  /** Boolean value that represents whether or not only the first photo for a closed listing may be shown */
  photosClosedFirstOnly?: Maybe<Scalars['Boolean']>;
  /** The number of days for which photos for closed listings may be shown. Applies to all other photo display rules. */
  photosClosedHistory?: Maybe<Scalars['Int']>;
  /** Boolean value that represents whether or not all photos for a closed listing may be displayed when logged in */
  photosClosedLogin?: Maybe<Scalars['Boolean']>;
  /** Boolean value that represents whether or not the MLS Logo must be overlaid on property search cards */
  photosLogoOverlay?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not sold listings can be displayed under MLS regulations */
  sold?: Maybe<Scalars['Boolean']>;
  /** Boolean value that represents whether or not unknown listings can be displayed under MLS regulations */
  unknown?: Maybe<Scalars['Boolean']>;
  /** Boolean value representing whether or not withdrawn listings can be displayed under MLS regulations */
  withdrawn?: Maybe<Scalars['Boolean']>;
};

export enum MlsState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  ComingSoon = 'COMING_SOON',
  Contingent = 'CONTINGENT',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Leased = 'LEASED',
  Pending = 'PENDING',
  Sold = 'SOLD',
  Unknown = 'UNKNOWN',
  Withdrawn = 'WITHDRAWN',
}

export enum MlsStateGroup {
  Active = 'ACTIVE',
  Contingent = 'CONTINGENT',
  OffMarket = 'OFF_MARKET',
  Pending = 'PENDING',
}

export type MsaDetails = {
  __typename?: 'MsaDetails';
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  /** HouseCanary rental index */
  hcri?: Maybe<MsaHcri>;
  /** House price index */
  hpi?: Maybe<MsaHpi>;
  /** listing stats */
  listingStats?: Maybe<MsaListingStats>;
  lsad?: Maybe<Scalars['String']>;
  memi?: Maybe<Scalars['String']>;
  msa?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameLsad?: Maybe<Scalars['String']>;
  /** Population stats */
  populationStats?: Maybe<PopulationStats>;
  /** rental listing stats */
  rentalListingStats?: Maybe<MsaRentalListingStats>;
  /** Rental Price Index - propertyType is deprecated input, use propertyTypeEnum instead */
  rpi?: Maybe<MsaRpi>;
  /** count of zipcodes in the msa */
  zipCount?: Maybe<Scalars['Int']>;
  zips?: Maybe<Array<Maybe<ZipDetails>>>;
};

export type MsaDetailsRpiArgs = {
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type MsaHcri = {
  __typename?: 'MsaHcri';
  /** Average effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median effective gross yields. */
  effectiveYieldCount?: Maybe<Scalars['Int']>;
  /** Median effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldMedian?: Maybe<Scalars['Float']>;
  /** Average forecast standard deviation (uncertainty measurement) for all rental values in the selected area of summarization. */
  fsdAverage?: Maybe<Scalars['Float']>;
  fsdMedian?: Maybe<Scalars['Float']>;
  /** Average gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median gross yields. */
  grossYieldCount?: Maybe<Scalars['Int']>;
  /** Median gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldMedian?: Maybe<Scalars['Float']>;
  /** Average rental value of the selected area of summarization. */
  rentalAverage?: Maybe<Scalars['Int']>;
  /** Count of properties considered for average and median rental valuation summaries. */
  rentalCount?: Maybe<Scalars['Int']>;
  /** Median rental value of the selected area of summarization. */
  rentalMedian?: Maybe<Scalars['Int']>;
};

export type MsaHpi = {
  __typename?: 'MsaHpi';
  forecastCalcs?: Maybe<MsaHpiForecastCalcs>;
  historicalCalcs?: Maybe<MsaHpiHistoricalCalcs>;
  timeSeries?: Maybe<Array<MsaHpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type MsaHpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MsaHpiForecastCalcs = {
  __typename?: 'MsaHpiForecastCalcs';
  cagr?: Maybe<MsaHpiForecastCalcsCagr>;
  ratio?: Maybe<MsaHpiForecastCalcsRatio>;
  return?: Maybe<MsaHpiForecastCalcsReturn>;
  /** Probability that this market's hpi will be lower 12 months from now than the current hpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
  risk1YearLossLevel?: Maybe<LossRiskLevel>;
};

export type MsaHpiForecastCalcsCagr = {
  __typename?: 'MsaHpiForecastCalcsCagr';
  /** Forecast 1-year compound annual growth rate (CAGR) */
  cagr1YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast 2-year compound annual growth rate (CAGR) */
  cagr2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast 3-year compound annual growth rate (CAGR) */
  cagr3YearForecast?: Maybe<Scalars['Float']>;
};

export type MsaHpiForecastCalcsRatio = {
  __typename?: 'MsaHpiForecastCalcsRatio';
  /** Forecast home price appreciation ratio for the next 3 months based on HPI forecast (1.0000) */
  ratio3MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 6 months based on HPI forecast */
  ratio6MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 12 months based on HPI forecast */
  ratio12MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 18 months based on HPI forecast */
  ratio18MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 24 months based on HPI forecast */
  ratio24MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 30 months based on HPI forecast */
  ratio30MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 36 months based on HPI forecast */
  ratio36MonthForecast?: Maybe<Scalars['Float']>;
};

export type MsaHpiForecastCalcsReturn = {
  __typename?: 'MsaHpiForecastCalcsReturn';
  /** Forecast home price appreciation for the next 12 months based on HPI forecast */
  return1YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 24 months based on HPI forecast */
  return2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 36 months based on HPI forecast */
  return3YearForecast?: Maybe<Scalars['Float']>;
};

export type MsaHpiHistoricalCalcs = {
  __typename?: 'MsaHpiHistoricalCalcs';
  cagr?: Maybe<MsaHpiHistoricalCalcsCagr>;
  /** Historical max percent loss in HPI over a 12 month period */
  max1YearLoss?: Maybe<Scalars['Float']>;
  return?: Maybe<MsaHpiHistoricalCalcsReturn>;
};

export type MsaHpiHistoricalCalcsCagr = {
  __typename?: 'MsaHpiHistoricalCalcsCagr';
  /** 1-year historical compound annual growth rate (CAGR) */
  cagr1Year?: Maybe<Scalars['Float']>;
  /** 5-year historical compound annual growth rate (CAGR) */
  cagr5Year?: Maybe<Scalars['Float']>;
  /** 10-year historical compound annual growth rate (CAGR) */
  cagr10Year?: Maybe<Scalars['Float']>;
  /** 20-year historical compound annual growth rate (CAGR) */
  cagr20Year?: Maybe<Scalars['Float']>;
};

export type MsaHpiHistoricalCalcsReturn = {
  __typename?: 'MsaHpiHistoricalCalcsReturn';
  /** Home price appreciation for the last 1 years based on HPI */
  return1Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 5 years based on HPI */
  return5Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 10 years based on HPI */
  return10Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 20 years based on HPI */
  return20Year?: Maybe<Scalars['Float']>;
};

export type MsaHpiTimeSeriesElement = {
  __typename?: 'MsaHpiTimeSeriesElement';
  /** Monthly change in velocity_value */
  accelerationValue?: Maybe<Scalars['Float']>;
  /** The normalized distance of hpi_value from a long term linear trend. Units are in standard deviations from the mean */
  distance?: Maybe<Scalars['Float']>;
  /** Month of the datapoint in the time series. string in ISO date format */
  month?: Maybe<Scalars['Date']>;
  /** Real housing price index after adjusting nominal HPI for inflation as measured by the CPI */
  real?: Maybe<Scalars['Float']>;
  /** Monthly returns in the nominal housing price index. Formally computed as hpi_value(t)/hpi_value(t-1) */
  return?: Maybe<Scalars['Float']>;
  /** Year over year percent change in the nominal housing price index. Formally computed as [hpi_value(t)/hpi_value(t-12)]-1. percent as float (.075 = 7.5%) */
  return1YearPercentChange?: Maybe<Scalars['Float']>;
  /** Probability that this market's hpi will be lower 12 months from now than the current hpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
  risk1YearLossLevel?: Maybe<LossRiskLevel>;
  /** Long term linear trend in hpi_value */
  trend?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) */
  value?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) indexed to month of time series generation */
  valueIndexed?: Maybe<Scalars['Float']>;
  /** Smoothed version of the year over year change in hpi_value */
  velocityValue?: Maybe<Scalars['Float']>;
};

export type MsaInput = {
  /** MsaIds to restrict results. */
  msaID: Scalars['String'];
};

export type MsaListingStats = {
  __typename?: 'MsaListingStats';
  /** MSA Listing Stats. */
  latestStats?: Maybe<HcListingStats>;
  timeSeries?: Maybe<Array<HcListingStats>>;
};

export type MsaListingStatsTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MsaRentalListingStats = {
  __typename?: 'MsaRentalListingStats';
  /** MSA Listing Stats. */
  latestStats?: Maybe<HcListingStats>;
  timeSeries?: Maybe<Array<HcListingStats>>;
};

export type MsaRentalListingStatsTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MsaRpi = {
  __typename?: 'MsaRpi';
  /** RPI forecast cals - propertyType is deprecated input, use propertyTypeEnum instead  */
  forecastCalcs?: Maybe<Array<Maybe<MsaRpiForecastCalcs>>>;
  /** RPI time series - propertyType is deprecated input, use propertyTypeEnum instead  */
  timeSeries?: Maybe<Array<RpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type MsaRpiForecastCalcsArgs = {
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type MsaRpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type MsaRpiForecastCalcs = {
  __typename?: 'MsaRpiForecastCalcs';
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  /** Type of property. One of - SFR, CONDO, TOWNHOUSE */
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  return1Year?: Maybe<Scalars['Float']>;
  /** Probability that this market's rpi will be lower 12 months from now than the current rpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
  risk1YearLossLevel?: Maybe<LossRiskLevel>;
};

export type MsaSearchResults = {
  __typename?: 'MsaSearchResults';
  edges?: Maybe<Array<Maybe<MsaSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type MsaSearchResultsEdges = {
  __typename?: 'MsaSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MsaDetails>;
};

export type MsasInput = {
  /** MsaIds to restrict results. NOTE: This is only implemented for ElasticSearch based spatial searches. */
  msaIds: Array<InputMaybe<Scalars['String']>>;
};

/** General sort ordering */
export enum Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  altNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  countyName?: Maybe<Scalars['String']>;
  fips?: Maybe<Array<Maybe<Scalars['String']>>>;
  formattedAddress?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<PlaceLocation>;
  mlsCoverage?: Maybe<MlsCoverageLevel>;
  name?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeType?: Maybe<PlaceType>;
  region?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['GeoJSON']>;
  shapeType?: Maybe<Scalars['String']>;
  viewport?: Maybe<PlaceViewport>;
};

export type PlaceDetails = {
  __typename?: 'PlaceDetails';
  description?: Maybe<Scalars['String']>;
  /** Coordinates for the location of the place */
  location?: Maybe<PlaceLocation>;
  placeId?: Maybe<Scalars['String']>;
  /** Type of place; 'city', 'zipcode' */
  placeType?: Maybe<PlaceType>;
  /** GeoJson representation of th shape of the place */
  shape?: Maybe<Scalars['GeoJSON']>;
  /** Shape type of the place; 'polygon', 'multipolygon', 'point' */
  shapeType?: Maybe<Scalars['String']>;
  /** North East and South West bounds of the place */
  viewport?: Maybe<PlaceViewport>;
};

export type PlaceIdInput = {
  /** type of application making the request */
  application?: InputMaybe<ApplicationType>;
  /** The place identifier, retrieved from a place lookup request */
  placeId?: InputMaybe<Scalars['String']>;
  /** The slug of the place to retrieve */
  slug?: InputMaybe<Scalars['String']>;
};

export type PlaceLocation = {
  __typename?: 'PlaceLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PlaceLookupResult = {
  __typename?: 'PlaceLookupResult';
  place?: Maybe<Place>;
};

export type PlaceTermInput = {
  /** Used to specify a traditional address search lookup OR a building lookup */
  addressLookupType?: InputMaybe<AddressLookupType>;
  /** Building identifier used to search for units in a specific building */
  hcBuildingId?: InputMaybe<Scalars['NumericID']>;
  /** Used to prioritize results near latitude, longitude */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Used to prioritize results near latitude, longitude */
  longitude?: InputMaybe<Scalars['Float']>;
  /** The type of place to search for. */
  placeType?: InputMaybe<PlaceType>;
  /** The search term */
  term: Scalars['String'];
};

export enum PlaceType {
  Cities = 'CITIES',
  City = 'CITY',
  Regions = 'REGIONS',
  Zipcode = 'ZIPCODE',
}

export type PlaceViewport = {
  __typename?: 'PlaceViewport';
  northeastLatitude?: Maybe<Scalars['Float']>;
  northeastLongitude?: Maybe<Scalars['Float']>;
  southwestLatitude?: Maybe<Scalars['Float']>;
  southwestLongitude?: Maybe<Scalars['Float']>;
};

export type PoolValueFactor = {
  __typename?: 'PoolValueFactor';
  /** Accessibility friendly description of how this compares to the average in the area */
  accessibleComparisonDescription?: Maybe<Scalars['String']>;
  /** The % of homes in the area that have this attribute */
  areaPercent?: Maybe<Scalars['Float']>;
  /** Human readable description of how this compares to the average in the area */
  comparisonDescription?: Maybe<Scalars['String']>;
  /** A human readable name for this factor */
  displayName: Scalars['String'];
  /** The value of this home */
  thisProperty?: Maybe<Scalars['Boolean']>;
  /** The positive or negative amount in dollars this factor represents */
  value: Scalars['Int'];
};

export type Population = {
  __typename?: 'Population';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Current year population */
  population?: Maybe<Scalars['Float']>;
  /** Previous year population */
  population1YearAgo?: Maybe<Scalars['Float']>;
  /** Change in population year over year */
  population1YearChange?: Maybe<Scalars['Float']>;
  /** Ratio of the population to the prior year */
  population1YearRatio?: Maybe<Scalars['Float']>;
  timeSeries?: Maybe<Array<PopulationTimeSeriesElement>>;
};

export type PopulationTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type PopulationStats = {
  __typename?: 'PopulationStats';
  nationalUnemploymentRate?: Maybe<UnemploymentRate>;
  population?: Maybe<Population>;
  unemploymentRate?: Maybe<UnemploymentRate>;
};

export type PopulationTimeSeriesElement = {
  __typename?: 'PopulationTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Current year population */
  population?: Maybe<Scalars['Float']>;
};

export type PropensityToSell = {
  __typename?: 'PropensityToSell';
  countyPercentile?: Maybe<Scalars['Float']>;
  nationwidePercentile?: Maybe<Scalars['Float']>;
  propensity1MonthForecast?: Maybe<Scalars['Float']>;
  propensity3MonthForecast?: Maybe<Scalars['Float']>;
  propensity6MonthForecast?: Maybe<Scalars['Float']>;
  zipPercentile?: Maybe<Scalars['Float']>;
};

export type PropertyDetails = {
  __typename?: 'PropertyDetails';
  blockDetails?: Maybe<BlockDetails>;
  blockGroupDetails?: Maybe<BlockGroupDetails>;
  /** Document identifier */
  documentId?: Maybe<Scalars['String']>;
  /** Document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** Entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** Path to full document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /** Details of the property determined by HouseCanary logic to be the best given the sources, assessments and listings, available. */
  hc?: Maybe<PropertyDetailsHc>;
  /** Address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  location?: Maybe<PropertyDetailsLocation>;
  /** Details of the property as normalized from the latest listing available. This will contain no populated data if there is no associated listing. */
  mls?: Maybe<PropertyDetailsMls>;
  msaDetails?: Maybe<MsaDetails>;
  /** Details of the property as normalized from the latest assessment data available. This will contain no data if there is no assessment associated with the property. This should be used in contexts where listing data is not permissible to show. */
  pr?: Maybe<PropertyDetailsPr>;
  propensityToSell?: Maybe<PropensityToSell>;
  /** Source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** Source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
  zipDetails?: Maybe<ZipDetails>;
};

export type PropertyDetailsHc = {
  __typename?: 'PropertyDetailsHc';
  property?: Maybe<CommonPropertyDetailsHc>;
};

export type PropertyDetailsLocation = {
  __typename?: 'PropertyDetailsLocation';
  discovery?: Maybe<CommonLocationDiscovery>;
  hc?: Maybe<CommonLocationHc>;
};

export type PropertyDetailsMls = {
  __typename?: 'PropertyDetailsMls';
  /** The property details from the listing as normalized to the common HouseCanary propertyDetails data schema. This will match the data found with the associated listing record's hc propertyDetails. */
  property?: Maybe<CommonPropertyDetailsHc>;
  /** Limited set of fields to reference the listing used. */
  source?: Maybe<PropertyDetailsMlsSource>;
};

export type PropertyDetailsMlsSource = {
  __typename?: 'PropertyDetailsMlsSource';
  /** the entityId for the listing used in this property details document. It can be used for direct lookups in the API. */
  entityId?: Maybe<Scalars['String']>;
  /** HouseCanary internal ID for the source MLS of the listing used. */
  hcMlsId?: Maybe<Scalars['Int']>;
};

export type PropertyDetailsPr = {
  __typename?: 'PropertyDetailsPr';
  /** assessment sourced details about the recorded owner. */
  ownership?: Maybe<PropertyDetailsPrOwnership>;
  /** the property details from the assessment data as normalized to the common HouseCanary propertyDetails data schema. */
  property?: Maybe<CommonPropertyDetailsHc>;
  /** details as mapped directly from public records source data; there is limited control and normalization applied by HouseCanary. */
  source?: Maybe<PropertyDetailsPrSource>;
};

export type PropertyDetailsPrOwnership = {
  __typename?: 'PropertyDetailsPrOwnership';
  addressMailing?: Maybe<Scalars['String']>;
  cityMailing?: Maybe<Scalars['String']>;
  exemptionTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  exemptions?: Maybe<Scalars['String']>;
  owner?: Maybe<Array<Maybe<PropertyDetailsPrOwnershipOwner>>>;
  /** The owner occupancy status as of the property as of the latest assessment update. This value will be NULL if there is a transfer of the property that is more recent than the last assessment update. */
  ownerOccupied?: Maybe<Scalars['Boolean']>;
  /** The manner in which the title to the property is held. */
  ownerVestingType?: Maybe<Scalars['String']>;
  stateMailing?: Maybe<Scalars['String']>;
  unitMailing?: Maybe<Scalars['String']>;
  unitTypeMailing?: Maybe<Scalars['String']>;
  zipcodeMailing?: Maybe<Scalars['String']>;
  zipcodePlus4Mailing?: Maybe<Scalars['String']>;
};

export type PropertyDetailsPrOwnershipOwner = {
  __typename?: 'PropertyDetailsPrOwnershipOwner';
  /** Owner of the property. This may be a single string when the delimiter for the names as provided by the county is inconsistent. */
  name?: Maybe<Scalars['String']>;
};

export type PropertyDetailsPrSource = {
  __typename?: 'PropertyDetailsPrSource';
  /** List of extra features of the property that add value to the property as recorded by the county. This is very limited in its population. */
  amenityTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Assessor Parcel Number */
  apn?: Maybe<Scalars['String']>;
  architecturalStyle?: Maybe<Scalars['String']>;
  assessmentEdition?: Maybe<Scalars['String']>;
  attic?: Maybe<Scalars['Boolean']>;
  buildingConditionCode?: Maybe<Scalars['String']>;
  buildingQuality?: Maybe<Scalars['String']>;
  buildings?: Maybe<Scalars['String']>;
  constructionType?: Maybe<Scalars['String']>;
  cooling?: Maybe<Scalars['String']>;
  coolingType?: Maybe<Scalars['String']>;
  countyLandUseSubType?: Maybe<Scalars['String']>;
  countyLandUseType?: Maybe<Scalars['String']>;
  /** Indicates the existence of or the count of the number of elevators on the property as recorded with the county records. */
  elevator?: Maybe<Scalars['String']>;
  /** assessment document entity id */
  entityId?: Maybe<Scalars['String']>;
  /** county fips code */
  fips?: Maybe<Scalars['String']>;
  fireplaceYN?: Maybe<Scalars['Boolean']>;
  fireplacesTotal?: Maybe<Scalars['Int']>;
  foundationMaterial?: Maybe<Scalars['String']>;
  heating?: Maybe<Scalars['String']>;
  heatingType?: Maybe<Scalars['String']>;
  latestAssessmentEdition?: Maybe<Scalars['String']>;
  legalDescription?: Maybe<Scalars['String']>;
  levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The owner occupancy status as of the property as of the latest assessment update. This value will be NULL if there is a transfer of the property that is more recent than the last assessment update. */
  ownerOccupied?: Maybe<Scalars['Boolean']>;
  parkingCount?: Maybe<Scalars['Float']>;
  parkingCountType?: Maybe<Scalars['String']>;
  roofMaterial?: Maybe<Scalars['String']>;
  roofType?: Maybe<Scalars['String']>;
  sewer?: Maybe<Scalars['String']>;
  /** county subdivision value extracted from the parcel's legal description. */
  subdivision?: Maybe<Scalars['String']>;
  taxRateCodeArea?: Maybe<Scalars['String']>;
  /** Vendor given Id */
  vendorId?: Maybe<Scalars['String']>;
  vendorLandUseCode?: Maybe<Scalars['String']>;
  wallsExteriorMaterial?: Maybe<Scalars['String']>;
  water?: Maybe<Scalars['String']>;
  zoning?: Maybe<Scalars['String']>;
};

export type PropertyFeaturesInput = {
  bathroomCount?: InputMaybe<Scalars['Float']>;
  bedroomCount?: InputMaybe<Scalars['Int']>;
  buildingArea?: InputMaybe<Scalars['Int']>;
  currentValue?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<GeoLocationInput>;
  siteArea?: InputMaybe<Scalars['Int']>;
  yearBuilt?: InputMaybe<Scalars['Year']>;
};

export type PropertyIdentifierInput = {
  city?: InputMaybe<Scalars['String']>;
  /** Identifies a property by unique HC assigned ID */
  hcAddressId?: InputMaybe<Scalars['NumericID']>;
  /** Identifies a property by unique HC assigned slug */
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  /** Street number and name. (i.e. 123 Main St) */
  streetAddress?: InputMaybe<Scalars['String']>;
  /** Unit number if applicable.  (i.e. Unit 12) */
  unit?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type PropertyInformation = {
  /** Returns the latest Listing of type Sale and/or Rental based on the application/profile provided. It can include all data found in the common Listing schema. This uses the computed event history found in complex-fields. */
  latestListing: LatestListing;
  /** Returns data for four types of the computed transactional state of the property. For each, it can include the state/event level details, like status, price, and dates, as well as full listing data if the event is tied to a Listing. */
  latestState: LatestState;
  /** History of liens recorded concurrently with deeds and as standalone mortgages; history of all recorded Notice of Default (and related) events; HouseCanary Loan To Value calculation. */
  lienHistory: LienHistory;
  /** Use to get all permissioned Listings with their full available details */
  listings?: Maybe<Array<Listing>>;
  /** Return full address information that includes street address, Census geo IDs, latitude and longitude, location precision, and HC generated subdivision. */
  location?: Maybe<CommonLocation>;
  /** Understand whether the property's location is to be fully covered by available MLSs. There is a simple, top-level boolean for coverage as well as details for each principal MLS operating in the property's county. */
  mlsCoverage?: Maybe<MlsCoverage>;
  /** A complete view of how HouseCanary sees the core details of the property through three versions: computed best hc version, latest MLS listing version, and latest Assessment version. The focus of this data set are the physical characteristics of the property and structure, although it does include additional data on taxes and ownership. */
  propertyDetails: PropertyDetails;
  /** Full annual history of tax amount, assessed value, and market value as managed by the property's county (or equivalent). History mostly starts around 2015. Current ownership as recorded with the county is included. */
  taxHistory: TaxHistory;
  /** All property level HouseCanary valuation data and functionality around versions of value, historical, HPI adjusted, and property details adjusted. This include land value, rental value, and the standard value/AVM. */
  value: PropertyValue;
};

export type PropertyInformationLienHistoryArgs = {
  asOf?: InputMaybe<Scalars['Date']>;
};

export type PropertyInput = {
  /** HCAddressIDs to restrict results. NOTE: This is only implemented for ElasticSearch based spatial searches. */
  hcAddressIds: Array<InputMaybe<Scalars['NumericID']>>;
};

export type PropertySummary = {
  __typename?: 'PropertySummary';
  /** @deprecated "Use hcAddressId" */
  addressId?: Maybe<Scalars['String']>;
  avm?: Maybe<Value>;
  basement?: Maybe<Scalars['Boolean']>;
  baths?: Maybe<Bathrooms>;
  beds?: Maybe<Scalars['Int']>;
  bestHighSchoolPercentile?: Maybe<Scalars['Float']>;
  bestMiddleSchoolPercentile?: Maybe<Scalars['Float']>;
  bestPrimarySchoolPercentile?: Maybe<Scalars['Float']>;
  blockId?: Maybe<Scalars['String']>;
  /** @deprecated "Use hcBuildingId" */
  buildingId?: Maybe<Scalars['String']>;
  conditionClass?: Maybe<Scalars['Int']>;
  doNotShowAddress?: Maybe<Scalars['Boolean']>;
  doNotShowAvm?: Maybe<Scalars['Boolean']>;
  doNotShowComments?: Maybe<Scalars['Boolean']>;
  doNotShowPublicly?: Maybe<Scalars['Boolean']>;
  geoLocation?: Maybe<GeoLocation>;
  hcAddressId?: Maybe<Scalars['NumericID']>;
  hcBuildingId?: Maybe<Scalars['NumericID']>;
  hcMlsId?: Maybe<Scalars['NumericID']>;
  /** Property is HC certified */
  hccv?: Maybe<Scalars['Boolean']>;
  leaseDate?: Maybe<Scalars['Date']>;
  leasePrice?: Maybe<Scalars['Int']>;
  listDate?: Maybe<Scalars['Date']>;
  listPrice?: Maybe<Scalars['Int']>;
  listPriceRentalYield?: Maybe<Scalars['Float']>;
  lotArea?: Maybe<Scalars['Int']>;
  mlsState?: Maybe<MlsState>;
  mlsStateDate?: Maybe<Scalars['Date']>;
  msaId?: Maybe<Scalars['NumericID']>;
  parkingSpots?: Maybe<Scalars['String']>;
  placeCityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeZipcodeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  pool?: Maybe<Scalars['Boolean']>;
  privateSewer?: Maybe<Scalars['Boolean']>;
  propensityCountyPercentile?: Maybe<Scalars['Float']>;
  propensityNationwidePercentile?: Maybe<Scalars['Float']>;
  propensityZipcodePercentile?: Maybe<Scalars['Float']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<PropertyType>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  rentalAvm?: Maybe<RentalValue>;
  rentalListDate?: Maybe<Scalars['Date']>;
  rentalListPrice?: Maybe<Scalars['Int']>;
  rentalMlsState?: Maybe<MlsState>;
  rentalMlsStateDate?: Maybe<Scalars['Date']>;
  /** Rental yield of this property */
  rentalYield?: Maybe<Scalars['Float']>;
  saleDate?: Maybe<Scalars['Date']>;
  salePrice?: Maybe<Scalars['Int']>;
  sqft?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['Int']>;
  unitsTotal?: Maybe<Scalars['Int']>;
  yearBuilt?: Maybe<Scalars['Year']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Supported property types */
export enum PropertyType {
  Commercial = 'COMMERCIAL',
  Condo = 'CONDO',
  Coop = 'COOP',
  ManufacturedHome = 'MANUFACTURED_HOME',
  MultiFamily = 'MULTI_FAMILY',
  Other = 'OTHER',
  SingleFamilyAttached = 'SINGLE_FAMILY_ATTACHED',
  SingleFamilyDetached = 'SINGLE_FAMILY_DETACHED',
  Timeshare = 'TIMESHARE',
  VacantLot = 'VACANT_LOT',
}

export type PropertyTypeCount = {
  __typename?: 'PropertyTypeCount';
  /** count of property selected in the census block */
  count?: Maybe<Scalars['Int']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
};

/** Supported property types */
export enum PropertyTypeEnum {
  Agricultural = 'AGRICULTURAL',
  Commercial = 'COMMERCIAL',
  Condo = 'CONDO',
  Land = 'LAND',
  Manufactured = 'MANUFACTURED',
  MultiFamily = 'MULTI_FAMILY',
  Other = 'OTHER',
  RentalUnit = 'RENTAL_UNIT',
  Sfr = 'SFR',
  Timeshare = 'TIMESHARE',
  Townhouse = 'TOWNHOUSE',
}

export type PropertyValue = {
  __typename?: 'PropertyValue';
  land?: Maybe<LandValue>;
  rental?: Maybe<RentalValue>;
  rentalValueRpiAdjusted?: Maybe<RentalValueRpiAdjusted>;
  value?: Maybe<Value>;
  valueAsOfDate?: Maybe<ValueAsOfDate>;
  valueDetailsAdjusted?: Maybe<Value>;
  /** Key factors that influence the AVM for this property. */
  valueFactors?: Maybe<ValueFactors>;
  valueHistorical?: Maybe<Array<ValueAsOfDateTime>>;
  valueHistory?: Maybe<Array<ValueAsOfDate>>;
  valueHpiAdjusted?: Maybe<ValueHpiAdjusted>;
};

export type PropertyValueRentalArgs = {
  minQuality?: InputMaybe<AvmQuality>;
  qualityMethod?: InputMaybe<AvmQualityMethod>;
};

export type PropertyValueRentalValueRpiAdjustedArgs = {
  adjustToDate?: InputMaybe<Scalars['Date']>;
  clientValue?: InputMaybe<Scalars['Int']>;
};

export type PropertyValueValueArgs = {
  minQuality?: InputMaybe<AvmQuality>;
  qualityMethod?: InputMaybe<AvmQualityMethod>;
};

export type PropertyValueValueAsOfDateArgs = {
  asOfDate?: InputMaybe<Scalars['Date']>;
};

export type PropertyValueValueDetailsAdjustedArgs = {
  details: DynamicAvmInput;
  minQuality?: InputMaybe<AvmQuality>;
  qualityMethod?: InputMaybe<AvmQualityMethod>;
};

export type PropertyValueValueHistoricalArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type PropertyValueValueHistoryArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type PropertyValueValueHpiAdjustedArgs = {
  adjustToDate?: InputMaybe<Scalars['Date']>;
  clientValue?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  agents?: Maybe<AgentSearchResults>;
  /** Returns data for all Metro Divisions at once. All Metro Division level data can be found here. The only parameter is for sorting the response by Metro Division name. */
  allMetroDivisionDetails?: Maybe<Array<Maybe<MetroDivisionDetails>>>;
  /** Returns data for all MSAs at once. All MSA level data can be found here. The only parameter is for sorting the response by MSA name. */
  allMsaDetails?: Maybe<Array<Maybe<MsaDetails>>>;
  /** Returns data for all States at once. All State level data can be found here. The only parameter is for sorting the response by State name. */
  allStateDetails?: Maybe<Array<Maybe<StateDetails>>>;
  counties?: Maybe<CountySearchResults>;
  dataStatus?: Maybe<DataStatusResult>;
  loanOfficers?: Maybe<LoanOfficerSearchResults>;
  /** Lookup property level data by Address. This is the primary query to use for core property data. It supports lookup by raw address components or by HC Address ID. */
  lookup?: Maybe<LookupResults>;
  /** Lookup all available Block level data by the 15 digit block ID (required). */
  lookupBlockDetails?: Maybe<BlockDetails>;
  /** Lookup all available Blockgroup level data by the 12 digit blockgroup ID (required). */
  lookupBlockGroupDetails?: Maybe<BlockGroupDetails>;
  /** Find HC listing references for a MLS Number aka Listing ID. This is for users that know a publicly published ID for listings to find that in Cerberus. This only returns the reference information that should be used in separate lookups to get full data. */
  lookupListingEntities?: Maybe<CerberusListingEntitiesLookupResults>;
  /** Return data for a specific Listing by the internal entityID for the associated listing document/object. This is not relevant for general data lookup. */
  lookupListings?: Maybe<CerberusListingLookupResults>;
  /** Lookup all available Metro Division level data by Metro Division ID (required). This is has the same data available as the allMetroDivisionDetails query. */
  lookupMetroDivisionDetails?: Maybe<MetroDivisionDetails>;
  /** Lookup HC managed attributes for an MLS by the internal HC MLS ID. This includes data like regulations and geographic coverage. */
  lookupMls?: Maybe<ListingMls>;
  /** Lookup all available MSA level data by MSA ID (required). This is has the same data available as the allMSADetails query. */
  lookupMsaDetails?: Maybe<MsaDetails>;
  lookupPlace?: Maybe<PlaceLookupResult>;
  /** Lookup all available State level data by the 2 digit State FIPS code (required). This is has the same data available as the allStateDetails query. */
  lookupStateDetails?: Maybe<StateDetails>;
  /** Lookup all available ZIP level data by the 5 digit ZIP Code aka postal code (required). */
  lookupZipDetails?: Maybe<ZipDetails>;
  msas?: Maybe<MsaSearchResults>;
  /** Spatial search */
  propertySpatialComps?: Maybe<SpatialSearchResults>;
  /** Spatial es-sort search */
  propertySpatialESSearch?: Maybe<SpatialSearchResults>;
  /** Spatial search */
  propertySpatialSearch?: Maybe<SpatialSearchResults>;
  searchPlace?: Maybe<AutocompleteResults>;
  /** Get similarity scores by subject and comps property details */
  similarityScoreByPropertyDetails?: Maybe<Array<Maybe<ScoreEntry>>>;
  states?: Maybe<StateSearchResults>;
  zips?: Maybe<ZipSearchResults>;
};

export type QueryAgentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  agent?: InputMaybe<CerberusAgentInput>;
  first?: InputMaybe<Scalars['Int']>;
  statistics?: InputMaybe<CerberusAgentStatisticInput>;
};

export type QueryAllMetroDivisionDetailsArgs = {
  order?: InputMaybe<Order>;
};

export type QueryAllMsaDetailsArgs = {
  order?: InputMaybe<Order>;
};

export type QueryAllStateDetailsArgs = {
  order?: InputMaybe<Order>;
};

export type QueryCountiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryLoanOfficersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  loanOfficer?: InputMaybe<CerberusLoanOfficerInput>;
  statistics?: InputMaybe<CerberusLoanOfficerStatisticInput>;
};

export type QueryLookupArgs = {
  id: CerberusInput;
};

export type QueryLookupBlockDetailsArgs = {
  id: CerberusBlockInput;
};

export type QueryLookupBlockGroupDetailsArgs = {
  id: CerberusBlockGroupInput;
};

export type QueryLookupListingEntitiesArgs = {
  id: CerberusListingEntitiesInput;
};

export type QueryLookupListingsArgs = {
  id: CerberusListingsInput;
};

export type QueryLookupMetroDivisionDetailsArgs = {
  id: CerberusMetroDivisionInput;
};

export type QueryLookupMlsArgs = {
  id: CerberusMlsIdentifierInput;
};

export type QueryLookupMsaDetailsArgs = {
  id: CerberusMsaInput;
};

export type QueryLookupPlaceArgs = {
  id: PlaceIdInput;
};

export type QueryLookupStateDetailsArgs = {
  id: CerberusStateInput;
};

export type QueryLookupZipDetailsArgs = {
  id: CerberusZipInput;
};

export type QueryMsasArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  listingStats?: InputMaybe<ListingStatsSearchFiltersInput>;
  rentalListingStats?: InputMaybe<ListingStatsSearchFiltersInput>;
};

export type QueryPropertySpatialCompsArgs = {
  id?: InputMaybe<SpatialCompsPropertiesIdentifierInput>;
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<SpatialPaginationInput>;
  spatialFilters?: InputMaybe<SpatialCompsFiltersInput>;
  subjectFeatures?: InputMaybe<PropertyFeaturesInput>;
};

export type QueryPropertySpatialEsSearchArgs = {
  buildingLimit?: InputMaybe<Scalars['Int']>;
  countLimit?: InputMaybe<CountLimitInput>;
  id?: InputMaybe<SpatialSortPropertiesIdentifierInput>;
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<SpatialPaginationInput>;
  sort?: InputMaybe<SpatialSortInput>;
  spatialFilters?: InputMaybe<SpatialSortFiltersInput>;
  subjectFeatures?: InputMaybe<PropertyFeaturesInput>;
};

export type QueryPropertySpatialSearchArgs = {
  buildingLimit?: InputMaybe<Scalars['Int']>;
  countLimit?: InputMaybe<CountLimitInput>;
  id?: InputMaybe<SpatialSearchPropertiesIdentifierInput>;
  limit?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<SpatialPaginationInput>;
  sort?: InputMaybe<SpatialSortInput>;
  spatialFilters?: InputMaybe<SpatialFiltersInput>;
  subjectFeatures?: InputMaybe<PropertyFeaturesInput>;
};

export type QuerySearchPlaceArgs = {
  id: PlaceTermInput;
};

export type QuerySimilarityScoreByPropertyDetailsArgs = {
  comps?: InputMaybe<Array<SimilarityScoreInput>>;
  subject: SimilarityScoreInput;
};

export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  listingStats?: InputMaybe<ListingStatsSearchFiltersInput>;
  rentalListingStats?: InputMaybe<ListingStatsSearchFiltersInput>;
};

export type QueryZipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  listingStats?: InputMaybe<ListingStatsSearchFiltersInput>;
};

export type RadiusInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  /** Search radius in meters */
  radius: Scalars['Int'];
};

export type RentalAvmValueAtSixConditions = {
  __typename?: 'RentalAVMValueAtSixConditions';
  valueAtCondC1?: Maybe<Scalars['Int']>;
  valueAtCondC2?: Maybe<Scalars['Int']>;
  valueAtCondC3?: Maybe<Scalars['Int']>;
  valueAtCondC4?: Maybe<Scalars['Int']>;
  valueAtCondC5?: Maybe<Scalars['Int']>;
  valueAtCondC6?: Maybe<Scalars['Int']>;
};

export type RentalAdjustment = {
  __typename?: 'RentalAdjustment';
  rentalAdjustmentDetails?: Maybe<RentalAdjustmentDetails>;
};

export type RentalAdjustmentDetails = {
  __typename?: 'RentalAdjustmentDetails';
  diffValue?: Maybe<Scalars['String']>;
  dollars?: Maybe<Scalars['Int']>;
};

export type RentalValue = {
  __typename?: 'RentalValue';
  condition?: Maybe<Scalars['Int']>;
  fsd?: Maybe<Scalars['Float']>;
  fsd90Percent?: Maybe<Scalars['Float']>;
  hcAddressId?: Maybe<Scalars['NumericID']>;
  hcCertifiedValue?: Maybe<Scalars['Boolean']>;
  hcCertifiedValueConditionAverageOrBetter?: Maybe<Scalars['Boolean']>;
  /**
   * Use valueLower
   * @deprecated No longer supported
   */
  priceLower?: Maybe<Scalars['Int']>;
  /**
   * Use value
   * @deprecated No longer supported
   */
  priceMean?: Maybe<Scalars['Int']>;
  /**
   * Use valueUpper
   * @deprecated No longer supported
   */
  priceUpper?: Maybe<Scalars['Int']>;
  propertyScore?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  propertyType?: Maybe<Scalars['String']>;
  quality?: Maybe<AvmQuality>;
  value?: Maybe<Scalars['Int']>;
  valueAtSixConditions?: Maybe<RentalAvmValueAtSixConditions>;
  valueLower?: Maybe<Scalars['Int']>;
  valueLowerMin?: Maybe<Scalars['Int']>;
  valueMarketingLower?: Maybe<Scalars['Int']>;
  valueMarketingUpper?: Maybe<Scalars['Int']>;
  valueMedian?: Maybe<Scalars['Int']>;
  valuePerSqFt?: Maybe<Scalars['Float']>;
  valueUpper?: Maybe<Scalars['Int']>;
  valueUpperMax?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['Float']>;
};

export type RentalValueRpiAdjusted = {
  __typename?: 'RentalValueRpiAdjusted';
  adjustedBy?: Maybe<RentalValueRpiAdjustedBy>;
  fromDate?: Maybe<Scalars['Date']>;
  fromValue?: Maybe<Scalars['Int']>;
  toDate?: Maybe<Scalars['Date']>;
};

export type RentalValueRpiAdjustedBy = {
  __typename?: 'RentalValueRpiAdjustedBy';
  msa?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['Int']>;
};

export type ResultSetInfo = {
  __typename?: 'ResultSetInfo';
  totalCount?: Maybe<Scalars['Int']>;
  totalCountType?: Maybe<TotalCountType>;
};

export type RpiTimeSeriesElement = {
  __typename?: 'RpiTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /**
   * Use value;Median rental price for the particular month
   * @deprecated No longer supported
   */
  price?: Maybe<Scalars['Int']>;
  /**
   * Use valueIndexed;Median rental price for the particular month indexed to month of time series generation
   * @deprecated No longer supported
   */
  priceIndexed?: Maybe<Scalars['Float']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  /** Number of historical rental listings and/or sales which were available for that month at the time of index creation */
  transactionCount?: Maybe<Scalars['Int']>;
  /**
   * Use unitValue;Median rental price per square foot for the particular month
   * @deprecated No longer supported
   */
  unitPrice?: Maybe<Scalars['Float']>;
  /** Median rental price per square foot for the particular month */
  unitValue?: Maybe<Scalars['Float']>;
  /** Median rental price for the particular month */
  value?: Maybe<Scalars['Int']>;
  /** Median rental price for the particular month indexed to month of time series generation */
  valueIndexed?: Maybe<Scalars['Float']>;
};

export type Score = {
  __typename?: 'Score';
  default?: Maybe<ScoreDetails>;
  gross?: Maybe<ScoreDetails>;
  net?: Maybe<ScoreDetails>;
  predictedGross?: Maybe<ScoreDetails>;
};

export type ScoreDetails = {
  __typename?: 'ScoreDetails';
  dollars?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  levelAdjusted?: Maybe<Scalars['Int']>;
  relative?: Maybe<Scalars['Float']>;
  similarity?: Maybe<Scalars['Int']>;
  similarityAdjusted?: Maybe<Scalars['Int']>;
};

export type ScoreEntry = {
  __typename?: 'ScoreEntry';
  fields?: Maybe<ScoreFields>;
  score?: Maybe<Score>;
  scoreRental?: Maybe<RentalAdjustment>;
  searchID?: Maybe<Scalars['String']>;
};

export type ScoreFields = {
  __typename?: 'ScoreFields';
  ageOfHome?: Maybe<CompFieldInt>;
  ageOfHomeMissInd?: Maybe<CompFieldBool>;
  basementBinary?: Maybe<CompFieldBool>;
  baths?: Maybe<CompFieldFloat>;
  beds?: Maybe<CompFieldInt>;
  buildingArea?: Maybe<CompFieldInt>;
  buildingAreaMissInd?: Maybe<CompFieldBool>;
  currentMedBlockPrice?: Maybe<CompFieldInt>;
  lotsize?: Maybe<CompFieldInt>;
  poolBinary?: Maybe<CompFieldBool>;
  propertyType?: Maybe<CompFieldPropertyType>;
};

export type SimilarityScoreInput = {
  /** Age of home, years */
  ageOfHome?: InputMaybe<Scalars['Int']>;
  /** AVM Mean Value */
  avmMean?: InputMaybe<Scalars['Int']>;
  /** Has a basement (true/false) */
  basementBinary?: InputMaybe<Scalars['Boolean']>;
  /** Number of bathrooms */
  baths?: InputMaybe<Scalars['Float']>;
  /** Number of bedrooms */
  beds?: InputMaybe<Scalars['Int']>;
  /** Census Block Group ID */
  blockGroupID?: InputMaybe<Scalars['String']>;
  /** Census Block ID */
  blockID?: InputMaybe<Scalars['String']>;
  /** Building area, sq.ft */
  buildingArea?: InputMaybe<Scalars['Int']>;
  /** Census County ID */
  countyID?: InputMaybe<Scalars['String']>;
  /** Distance to property in meters */
  distance?: InputMaybe<Scalars['Int']>;
  /** Listing age in months */
  listingAgeInMonths?: InputMaybe<Scalars['Float']>;
  /** Lot size, sq.ft */
  lotSize?: InputMaybe<Scalars['Int']>;
  /** Has a pool (true/false) */
  poolBinary?: InputMaybe<Scalars['Boolean']>;
  /**  deprecated, use propertyTypeEnum */
  propertyType?: InputMaybe<PropertyType>;
  /** Property type Enum (translated internally to CND, INC, SFD, TH) */
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
  /** Total number of rooms */
  totalNumberOfRooms?: InputMaybe<Scalars['Int']>;
  /** Census Tract ID */
  tractID?: InputMaybe<Scalars['String']>;
};

export type SpatialCompsFiltersInput = {
  conditionClass?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  maxAvmPrice?: InputMaybe<Scalars['Int']>;
  minAvmPrice?: InputMaybe<Scalars['Int']>;
  minLeaseDate?: InputMaybe<Scalars['Date']>;
  minListDate?: InputMaybe<Scalars['Date']>;
  minRentalListDate?: InputMaybe<Scalars['Date']>;
  minSaleDate?: InputMaybe<Scalars['Date']>;
  minSimilarityScore?: InputMaybe<Scalars['Int']>;
  mlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  mlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
  /**  deprecated, Use propertyTypeEnum; */
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**  Supported values are - SFR, TOWNHOUSE, CONDO, MANUFACTURED, MULTI_FAMILY */
  propertyTypeEnum?: InputMaybe<Array<InputMaybe<PropertyTypeEnum>>>;
  rentalMlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  rentalMlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
  strategy?: InputMaybe<SpatialFiltersStrategyInput>;
};

export type SpatialCompsPropertiesIdentifierInput = {
  application?: InputMaybe<ApplicationType>;
  geojson?: InputMaybe<GeojsonInput>;
  radius?: InputMaybe<RadiusInput>;
  subject?: InputMaybe<PropertyIdentifierInput>;
};

export type SpatialFiltersInput = {
  basement?: InputMaybe<Scalars['SpatialArgumentBool']>;
  conditionClass?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hccv?: InputMaybe<Scalars['Boolean']>;
  hidePrivateListings?: InputMaybe<Scalars['Boolean']>;
  listDateOrSaleDate?: InputMaybe<ListDateOrSaleDateInput>;
  max12moRiskOfDecline?: InputMaybe<Scalars['Float']>;
  maxAvmFsd?: InputMaybe<Scalars['Float']>;
  maxAvmLowerPrice?: InputMaybe<Scalars['Int']>;
  maxAvmPrice?: InputMaybe<Scalars['Int']>;
  maxAvmUpperPrice?: InputMaybe<Scalars['Int']>;
  maxBaths?: InputMaybe<Scalars['Int']>;
  maxBathsWithHalfs?: InputMaybe<Scalars['Float']>;
  maxBeds?: InputMaybe<Scalars['Int']>;
  maxBestHighSchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxBestMiddleSchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxBestPrimarySchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxConfidentListToAvmPrice?: InputMaybe<Scalars['Float']>;
  maxConfidentRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  maxConfidentRentalYield?: InputMaybe<Scalars['Float']>;
  maxCrimeCountyPercentile?: InputMaybe<Scalars['Float']>;
  maxCrimeNationalPercentile?: InputMaybe<Scalars['Float']>;
  maxGeoPrecision?: InputMaybe<GeoPrecision>;
  maxHpi?: InputMaybe<Scalars['Float']>;
  maxLeaseDate?: InputMaybe<Scalars['Date']>;
  maxLeasePrice?: InputMaybe<Scalars['Int']>;
  maxListDate?: InputMaybe<Scalars['Date']>;
  maxListPrice?: InputMaybe<Scalars['Int']>;
  maxListPriceRentalYield?: InputMaybe<Scalars['Float']>;
  maxListToAvmPrice?: InputMaybe<Scalars['Float']>;
  maxLotArea?: InputMaybe<Scalars['Int64']>;
  maxMarketGrade?: InputMaybe<MarketGrade>;
  maxParkingSpots?: InputMaybe<Scalars['Int']>;
  maxPricePerSqft50?: InputMaybe<Scalars['Int']>;
  maxRentalAvmFsd?: InputMaybe<Scalars['Float']>;
  maxRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  maxRentalListDate?: InputMaybe<Scalars['Date']>;
  maxRentalListPrice?: InputMaybe<Scalars['Int']>;
  maxRentalYield?: InputMaybe<Scalars['Float']>;
  maxSaleDate?: InputMaybe<Scalars['Date']>;
  maxSalePrice?: InputMaybe<Scalars['Int']>;
  maxSqft?: InputMaybe<Scalars['Int']>;
  maxStories?: InputMaybe<Scalars['Int']>;
  maxUnitsTotal?: InputMaybe<Scalars['Int']>;
  maxYearBuilt?: InputMaybe<Scalars['Int']>;
  min12moRiskOfDecline?: InputMaybe<Scalars['Float']>;
  minAvmFsd?: InputMaybe<Scalars['Float']>;
  minAvmLowerPrice?: InputMaybe<Scalars['Int']>;
  minAvmPrice?: InputMaybe<Scalars['Int']>;
  minAvmUpperPrice?: InputMaybe<Scalars['Int']>;
  minBaths?: InputMaybe<Scalars['Int']>;
  minBathsWithHalfs?: InputMaybe<Scalars['Float']>;
  minBeds?: InputMaybe<Scalars['Int']>;
  minBestHighSchoolPercentile?: InputMaybe<Scalars['Float']>;
  minBestMiddleSchoolPercentile?: InputMaybe<Scalars['Float']>;
  minBestPrimarySchoolPercentile?: InputMaybe<Scalars['Float']>;
  minConfidentListToAvmPrice?: InputMaybe<Scalars['Float']>;
  minConfidentRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  minConfidentRentalYield?: InputMaybe<Scalars['Float']>;
  minCrimeCountyPercentile?: InputMaybe<Scalars['Float']>;
  minCrimeNationalPercentile?: InputMaybe<Scalars['Float']>;
  minGeoPrecision?: InputMaybe<GeoPrecision>;
  minHpi?: InputMaybe<Scalars['Float']>;
  minLeaseDate?: InputMaybe<Scalars['Date']>;
  minLeasePrice?: InputMaybe<Scalars['Int']>;
  minListDate?: InputMaybe<Scalars['Date']>;
  minListPrice?: InputMaybe<Scalars['Int']>;
  minListPriceRentalYield?: InputMaybe<Scalars['Float']>;
  minListToAvmPrice?: InputMaybe<Scalars['Float']>;
  minLotArea?: InputMaybe<Scalars['Int64']>;
  minMarketGrade?: InputMaybe<MarketGrade>;
  minParkingSpots?: InputMaybe<Scalars['Int']>;
  minPricePerSqft50?: InputMaybe<Scalars['Int']>;
  minRentalAvmFsd?: InputMaybe<Scalars['Float']>;
  minRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  minRentalListDate?: InputMaybe<Scalars['Date']>;
  minRentalListPrice?: InputMaybe<Scalars['Int']>;
  minRentalYield?: InputMaybe<Scalars['Float']>;
  minSaleDate?: InputMaybe<Scalars['Date']>;
  minSalePrice?: InputMaybe<Scalars['Int']>;
  minSimilarityScore?: InputMaybe<Scalars['Int']>;
  minSqft?: InputMaybe<Scalars['Int']>;
  minStories?: InputMaybe<Scalars['Int']>;
  minUnitsTotal?: InputMaybe<Scalars['Int']>;
  minYearBuilt?: InputMaybe<Scalars['Int']>;
  mlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  mlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
  pool?: InputMaybe<Scalars['SpatialArgumentBool']>;
  privateSewer?: InputMaybe<Scalars['SpatialArgumentBool']>;
  /**  deprecated, Use propertyTypeEnum; */
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**  Supported values are - SFR, TOWNHOUSE, CONDO, MANUFACTURED, MULTI_FAMILY */
  propertyTypeEnum?: InputMaybe<Array<InputMaybe<PropertyTypeEnum>>>;
  rentalMlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  rentalMlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
};

export type SpatialFiltersStrategyInput = {
  distanceMean?: InputMaybe<Scalars['Float']>;
  distanceStandardDeviation?: InputMaybe<Scalars['Float']>;
  mlsStateAgeMean?: InputMaybe<Scalars['Float']>;
  mlsStateAgeStandardDeviation?: InputMaybe<Scalars['Float']>;
  strategy?: InputMaybe<SpatialStrategyEnum>;
};

export type SpatialPaginationInput = {
  cursor?: InputMaybe<Scalars['String']>;
  paginate?: InputMaybe<Scalars['Boolean']>;
};

export type SpatialSearchCountResult = {
  __typename?: 'SpatialSearchCountResult';
  count?: Maybe<Scalars['Int']>;
  labelLocation?: Maybe<Scalars['GeoJSON']>;
};

export type SpatialSearchCountResultLabelLocationArgs = {
  format?: InputMaybe<GeolocationFormat>;
};

export type SpatialSearchDetail = PropertyInformation & {
  __typename?: 'SpatialSearchDetail';
  /** Complex Fields from cerberus. */
  complexFields?: Maybe<Array<ComplexFields>>;
  /** Distance to subject location in meters */
  distance?: Maybe<Scalars['Float']>;
  /** Returns the latest Listing of type Sale and/or Rental based on the application/profile provided. It can include all data found in the common Listing schema. This uses the computed event history found in complex-fields. */
  latestListing: LatestListing;
  /** Returns data for four types of the computed transactional state of the property. For each, it can include the state/event level details, like status, price, and dates, as well as full listing data if the event is tied to a Listing. */
  latestState: LatestState;
  /** History of liens recorded concurrently with deeds and as standalone mortgages; history of all recorded Notice of Default (and related) events; HouseCanary Loan To Value calculation. */
  lienHistory: LienHistory;
  /** Use to get all permissioned Listings with their full available details */
  listings?: Maybe<Array<Listing>>;
  /** Return full address information that includes street address, Census geo IDs, latitude and longitude, location precision, and HC generated subdivision. */
  location?: Maybe<CommonLocation>;
  /** Understand whether the property's location is to be fully covered by available MLSs. There is a simple, top-level boolean for coverage as well as details for each principal MLS operating in the property's county. */
  mlsCoverage?: Maybe<MlsCoverage>;
  /** A complete view of how HouseCanary sees the core details of the property through three versions: computed best hc version, latest MLS listing version, and latest Assessment version. The focus of this data set are the physical characteristics of the property and structure, although it does include additional data on taxes and ownership. */
  propertyDetails: PropertyDetails;
  score?: Maybe<Score>;
  similarity?: Maybe<SpatialSimilarity>;
  summary?: Maybe<PropertySummary>;
  /** Full annual history of tax amount, assessed value, and market value as managed by the property's county (or equivalent). History mostly starts around 2015. Current ownership as recorded with the county is included. */
  taxHistory: TaxHistory;
  /** All property level HouseCanary valuation data and functionality around versions of value, historical, HPI adjusted, and property details adjusted. This include land value, rental value, and the standard value/AVM. */
  value: PropertyValue;
};

export type SpatialSearchDetailLienHistoryArgs = {
  asOf?: InputMaybe<Scalars['Date']>;
};

export type SpatialSearchDetailResults = {
  __typename?: 'SpatialSearchDetailResults';
  atEnd?: Maybe<Scalars['Boolean']>;
  cursor?: Maybe<Scalars['String']>;
  hits?: Maybe<Array<SpatialSearchDetail>>;
  labelLocation?: Maybe<Scalars['GeoJSON']>;
  /** @deprecated "Use TotalCountType instead" */
  sortAdditionalResultsAvailable?: Maybe<Scalars['Boolean']>;
  /** @deprecated "Use TotalCount instead" */
  sortConsideredResultsCount?: Maybe<Scalars['Int']>;
  suppressedCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalCountType?: Maybe<TotalCountType>;
};

export type SpatialSearchDetailResultsLabelLocationArgs = {
  format?: InputMaybe<GeolocationFormat>;
};

export type SpatialSearchOverCountResult = {
  __typename?: 'SpatialSearchOverCountResult';
  labelLocation?: Maybe<Scalars['GeoJSON']>;
  moreThan?: Maybe<Scalars['Int']>;
};

export type SpatialSearchOverCountResultLabelLocationArgs = {
  format?: InputMaybe<GeolocationFormat>;
};

export type SpatialSearchPropertiesIdentifierInput = {
  application?: InputMaybe<ApplicationType>;
  bbox?: InputMaybe<BboxInput>;
  county?: InputMaybe<CountyIdinput>;
  geojson?: InputMaybe<GeojsonInput>;
  msa?: InputMaybe<MsaInput>;
  place?: InputMaybe<PlaceIdInput>;
  radius?: InputMaybe<RadiusInput>;
  subject?: InputMaybe<PropertyIdentifierInput>;
  tile?: InputMaybe<TileInput>;
  zipcode?: InputMaybe<ZipcodeInput>;
};

export type SpatialSearchResults =
  | SpatialSearchCountResult
  | SpatialSearchDetailResults
  | SpatialSearchOverCountResult;

export type SpatialSimilarity = {
  __typename?: 'SpatialSimilarity';
  level?: Maybe<Scalars['String']>;
  levelAdjusted?: Maybe<Scalars['String']>;
  levelRank?: Maybe<Scalars['Int']>;
  salesPriceAdjusted?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  scoreAdjusted?: Maybe<Scalars['Int']>;
};

/** Spatial search sort field */
export enum SpatialSortField {
  Avm = 'AVM',
  Basement = 'BASEMENT',
  Baths = 'BATHS',
  Beds = 'BEDS',
  ListDate = 'LIST_DATE',
  ListPrice = 'LIST_PRICE',
  LotArea = 'LOT_AREA',
  ParkingSpots = 'PARKING_SPOTS',
  Pool = 'POOL',
  RentalAvm = 'RENTAL_AVM',
  RentalYield = 'RENTAL_YIELD',
  SaleDate = 'SALE_DATE',
  SalePrice = 'SALE_PRICE',
  Sqft = 'SQFT',
  YearBuilt = 'YEAR_BUILT',
}

export type SpatialSortFiltersInput = {
  basement?: InputMaybe<Scalars['SpatialArgumentBool']>;
  capRate?: InputMaybe<CapRateFilterInput>;
  capRateOnCost?: InputMaybe<CapRateOnCostSpatialFilterInput>;
  conditionClass?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hccv?: InputMaybe<Scalars['Boolean']>;
  hidePrivateListings?: InputMaybe<Scalars['Boolean']>;
  listDateOrSaleDate?: InputMaybe<ListDateOrSaleDateInput>;
  max12moRiskOfDecline?: InputMaybe<Scalars['Float']>;
  maxAvmFsd?: InputMaybe<Scalars['Float']>;
  maxAvmLowerPrice?: InputMaybe<Scalars['Int']>;
  maxAvmPrice?: InputMaybe<Scalars['Int']>;
  maxAvmUpperPrice?: InputMaybe<Scalars['Int']>;
  maxBaths?: InputMaybe<Scalars['Int']>;
  maxBathsWithHalfs?: InputMaybe<Scalars['Float']>;
  maxBeds?: InputMaybe<Scalars['Int']>;
  maxBestHighSchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxBestMiddleSchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxBestPrimarySchoolPercentile?: InputMaybe<Scalars['Float']>;
  maxConfidentListToAvmPrice?: InputMaybe<Scalars['Float']>;
  maxConfidentRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  maxConfidentRentalYield?: InputMaybe<Scalars['Float']>;
  maxCrimeCountyPercentile?: InputMaybe<Scalars['Float']>;
  maxCrimeNationalPercentile?: InputMaybe<Scalars['Float']>;
  maxGeoPrecision?: InputMaybe<GeoPrecision>;
  maxHpi?: InputMaybe<Scalars['Float']>;
  maxLeaseDate?: InputMaybe<Scalars['Date']>;
  maxLeasePrice?: InputMaybe<Scalars['Int']>;
  maxListDate?: InputMaybe<Scalars['Date']>;
  maxListPrice?: InputMaybe<Scalars['Int']>;
  maxListPriceRentalYield?: InputMaybe<Scalars['Float']>;
  maxListToAvmPrice?: InputMaybe<Scalars['Float']>;
  maxLotArea?: InputMaybe<Scalars['Int64']>;
  maxMarketGrade?: InputMaybe<MarketGrade>;
  maxParkingSpots?: InputMaybe<Scalars['Int']>;
  maxPricePerSqft50?: InputMaybe<Scalars['Int']>;
  maxPropensityCountyPercentile?: InputMaybe<Scalars['Float']>;
  maxPropensityNationwidePercentile?: InputMaybe<Scalars['Float']>;
  maxPropensityZipcodePercentile?: InputMaybe<Scalars['Float']>;
  maxRentalAvmFsd?: InputMaybe<Scalars['Float']>;
  maxRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  maxRentalListDate?: InputMaybe<Scalars['Date']>;
  maxRentalListPrice?: InputMaybe<Scalars['Int']>;
  maxRentalYield?: InputMaybe<Scalars['Float']>;
  maxSaleDate?: InputMaybe<Scalars['Date']>;
  maxSalePrice?: InputMaybe<Scalars['Int']>;
  maxSqft?: InputMaybe<Scalars['Int']>;
  maxStories?: InputMaybe<Scalars['Int']>;
  maxUnitsTotal?: InputMaybe<Scalars['Int']>;
  maxYearBuilt?: InputMaybe<Scalars['Int']>;
  min12moRiskOfDecline?: InputMaybe<Scalars['Float']>;
  minAvmFsd?: InputMaybe<Scalars['Float']>;
  minAvmLowerPrice?: InputMaybe<Scalars['Int']>;
  minAvmPrice?: InputMaybe<Scalars['Int']>;
  minAvmUpperPrice?: InputMaybe<Scalars['Int']>;
  minBaths?: InputMaybe<Scalars['Int']>;
  minBathsWithHalfs?: InputMaybe<Scalars['Float']>;
  minBeds?: InputMaybe<Scalars['Int']>;
  minBestHighSchoolPercentile?: InputMaybe<Scalars['Float']>;
  minBestMiddleSchoolPercentile?: InputMaybe<Scalars['Float']>;
  minBestPrimarySchoolPercentile?: InputMaybe<Scalars['Float']>;
  minConfidentListToAvmPrice?: InputMaybe<Scalars['Float']>;
  minConfidentRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  minConfidentRentalYield?: InputMaybe<Scalars['Float']>;
  minCrimeCountyPercentile?: InputMaybe<Scalars['Float']>;
  minCrimeNationalPercentile?: InputMaybe<Scalars['Float']>;
  minGeoPrecision?: InputMaybe<GeoPrecision>;
  minHpi?: InputMaybe<Scalars['Float']>;
  minLeaseDate?: InputMaybe<Scalars['Date']>;
  minLeasePrice?: InputMaybe<Scalars['Int']>;
  minListDate?: InputMaybe<Scalars['Date']>;
  minListPrice?: InputMaybe<Scalars['Int']>;
  minListPriceRentalYield?: InputMaybe<Scalars['Float']>;
  minListToAvmPrice?: InputMaybe<Scalars['Float']>;
  minLotArea?: InputMaybe<Scalars['Int64']>;
  minMarketGrade?: InputMaybe<MarketGrade>;
  minParkingSpots?: InputMaybe<Scalars['Int']>;
  minPricePerSqft50?: InputMaybe<Scalars['Int']>;
  minPropensityCountyPercentile?: InputMaybe<Scalars['Float']>;
  minPropensityNationwidePercentile?: InputMaybe<Scalars['Float']>;
  minPropensityZipcodePercentile?: InputMaybe<Scalars['Float']>;
  minRentalAvmFsd?: InputMaybe<Scalars['Float']>;
  minRentalAvmPrice?: InputMaybe<Scalars['Int']>;
  minRentalListDate?: InputMaybe<Scalars['Date']>;
  minRentalListPrice?: InputMaybe<Scalars['Int']>;
  minRentalYield?: InputMaybe<Scalars['Float']>;
  minSaleDate?: InputMaybe<Scalars['Date']>;
  minSalePrice?: InputMaybe<Scalars['Int']>;
  minSimilarityScore?: InputMaybe<Scalars['Int']>;
  minSqft?: InputMaybe<Scalars['Int']>;
  minStories?: InputMaybe<Scalars['Int']>;
  minUnitsTotal?: InputMaybe<Scalars['Int']>;
  minYearBuilt?: InputMaybe<Scalars['Int']>;
  mlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  mlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
  pool?: InputMaybe<Scalars['SpatialArgumentBool']>;
  privateSewer?: InputMaybe<Scalars['SpatialArgumentBool']>;
  /**  deprecated, Use propertyTypeEnum; */
  propertyType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**  Supported values are - SFR, TOWNHOUSE, CONDO, MANUFACTURED, MULTI_FAMILY */
  propertyTypeEnum?: InputMaybe<Array<InputMaybe<PropertyTypeEnum>>>;
  rentalMlsState?: InputMaybe<Array<InputMaybe<MlsState>>>;
  rentalMlsStateGroup?: InputMaybe<Array<InputMaybe<MlsStateGroup>>>;
};

export type SpatialSortInput = {
  field: SpatialSortField;
  order?: InputMaybe<Order>;
};

export type SpatialSortPropertiesIdentifierInput = {
  application?: InputMaybe<ApplicationType>;
  bbox?: InputMaybe<BboxInput>;
  excludeZipcodes?: InputMaybe<ExcludeZipcodesInput>;
  geojson?: InputMaybe<GeojsonInput>;
  msa?: InputMaybe<MsasInput>;
  place?: InputMaybe<PlaceIdInput>;
  property?: InputMaybe<PropertyInput>;
  radius?: InputMaybe<RadiusInput>;
  subject?: InputMaybe<PropertyIdentifierInput>;
  tile?: InputMaybe<TileInput>;
};

/** Spatial search strategy */
export enum SpatialStrategyEnum {
  /** default strategy used to enterprise */
  ScoreAndCollect = 'SCORE_AND_COLLECT',
  /** default strategy used by comehome */
  ScoreAndReturn = 'SCORE_AND_RETURN',
  /** new testing strategy */
  ScoreDistanceAgeAndCollect = 'SCORE_DISTANCE_AGE_AND_COLLECT',
  /** new testing strategy */
  ScoreStepDistanceAgeAndCollect = 'SCORE_STEP_DISTANCE_AGE_AND_COLLECT',
}

export type StateAffordability = {
  __typename?: 'StateAffordability';
  timeSeries?: Maybe<Array<StateAffordabilityTimeSeriesElement>>;
};

export type StateAffordabilityTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type StateAffordabilityTimeSeriesElement = {
  __typename?: 'StateAffordabilityTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Raw affordability value. Represents the percent of median household income required to make the median home payment on a 30 year fixed rate mortgage with 20% down */
  payment?: Maybe<Scalars['Float']>;
  /** Normalized distance of afford_pmt from a long term linear trend. Units are in standard deviations from the mean */
  paymentDetrended?: Maybe<Scalars['Float']>;
};

export type StateDetails = {
  __typename?: 'StateDetails';
  abbrev?: Maybe<Scalars['String']>;
  /** Time Series - Affordability */
  affordability?: Maybe<StateAffordability>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  /** HouseCanary Rental Index */
  hcri?: Maybe<StateHcri>;
  /** House Price Index */
  hpi?: Maybe<StateHpi>;
  /** Listing Stats */
  listingStats?: Maybe<StateListingStats>;
  name?: Maybe<Scalars['String']>;
  /** Rental Listing Stats */
  rentalListingStats?: Maybe<StateRentalListingStats>;
  /** Rental Price Index */
  rpi?: Maybe<StateRpi>;
  stateFips?: Maybe<Scalars['String']>;
};

export type StateHcri = {
  __typename?: 'StateHcri';
  /** Average effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median effective gross yields. */
  effectiveYieldCount?: Maybe<Scalars['Int']>;
  /** Median effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldMedian?: Maybe<Scalars['Float']>;
  /** Average forecast standard deviation (uncertainty measurement) for all rental values in the selected area of summarization. */
  fsdAverage?: Maybe<Scalars['Float']>;
  /** Median forecast standard deviation (uncertainty measurement) for all rental values in the selected area of summarization. */
  fsdMedian?: Maybe<Scalars['Float']>;
  /** Average gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median gross yields. */
  grossYieldCount?: Maybe<Scalars['Int']>;
  /** Median gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldMedian?: Maybe<Scalars['Float']>;
  /** Average rental value of the selected area of summarization. */
  rentalAverage?: Maybe<Scalars['Int']>;
  /** Count of properties considered for average and median rental valuation summaries. */
  rentalCount?: Maybe<Scalars['Int']>;
  /** Median rental value of the selected area of summarization. */
  rentalMedian?: Maybe<Scalars['Int']>;
};

export type StateHpi = {
  __typename?: 'StateHpi';
  timeSeries?: Maybe<Array<StateHpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type StateHpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type StateHpiTimeSeriesElement = {
  __typename?: 'StateHpiTimeSeriesElement';
  /** Monthly change in velocity_value */
  accelerationValue?: Maybe<Scalars['Float']>;
  /** The normalized distance of hpi_value from a long term linear trend. Units are in standard deviations from the mean */
  distance?: Maybe<Scalars['Float']>;
  /** Month of the datapoint in the time series. string in ISO date format */
  month?: Maybe<Scalars['Date']>;
  /** Real housing price index after adjusting nominal HPI for inflation as measured by the CPI */
  real?: Maybe<Scalars['Float']>;
  /** Monthly returns in the nominal housing price index. Formally computed as hpi_value(t)/hpi_value(t-1) */
  return?: Maybe<Scalars['Float']>;
  /** Year over year percent change in the nominal housing price index. Formally computed as [hpi_value(t)/hpi_value(t-12)]-1. percent as float (.075 = 7.5%) */
  return1YearPercentChange?: Maybe<Scalars['Float']>;
  /** Long term linear trend in hpi_value */
  trend?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) */
  value?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) indexed to month of time series generation */
  valueIndexed?: Maybe<Scalars['Float']>;
  /** Smoothed version of the year over year change in hpi_value */
  velocityValue?: Maybe<Scalars['Float']>;
};

export type StateListingStats = {
  __typename?: 'StateListingStats';
  /** State Listing Stats. */
  latestStats?: Maybe<HcListingStats>;
  timeSeries?: Maybe<Array<HcListingStats>>;
};

export type StateListingStatsTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type StateRentalListingStats = {
  __typename?: 'StateRentalListingStats';
  /** State Listing Stats. */
  latestStats?: Maybe<HcListingStats>;
  timeSeries?: Maybe<Array<HcListingStats>>;
};

export type StateRentalListingStatsTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type StateRpi = {
  __typename?: 'StateRpi';
  /** RPI time series - propertyType is deprecated input, use propertyTypeEnum instead */
  timeSeries?: Maybe<Array<RpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type StateRpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type StateSearchResults = {
  __typename?: 'StateSearchResults';
  edges?: Maybe<Array<Maybe<StateSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type StateSearchResultsEdges = {
  __typename?: 'StateSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<StateDetails>;
};

export type StrengthOfOffer = {
  __typename?: 'StrengthOfOffer';
  fipsProbabilityOfAccept?: Maybe<Scalars['Float']>;
  msaProbabilityOfAccept?: Maybe<Scalars['Float']>;
};

export type TaxHistory = {
  __typename?: 'TaxHistory';
  /** Document identifier */
  documentId?: Maybe<Scalars['String']>;
  /** Document write timestamp */
  documentTimestamp?: Maybe<Scalars['String']>;
  /** Entity identifier */
  entityId?: Maybe<Scalars['String']>;
  /** Path to full document */
  fullDocumentPath?: Maybe<Scalars['String']>;
  /** Annual details for taxes and county valuations as organized and ordered by HouseCanary. */
  hc?: Maybe<TaxHistoryHc>;
  /** Address identifier from address api */
  hcAddressId?: Maybe<Scalars['NumericID']>;
  /** Key to join with listing documents */
  joinId?: Maybe<Scalars['String']>;
  /** Source identifier */
  sourceId?: Maybe<Scalars['String']>;
  /** Source provided timestamp for data updated */
  sourceTimestamp?: Maybe<Scalars['String']>;
};

export type TaxHistoryHc = {
  __typename?: 'TaxHistoryHc';
  /** Annual breakdown of assessed value, by land, improvement, and overall, as managed by the county. */
  assessed?: Maybe<Array<Maybe<TaxHistoryHcAssessed>>>;
  /** Annual breakdown of the market value, by land, improvement, and overall, as managed by the county. Market Value has a low data population rate as most Counties do not make this part of their tax rolls. */
  market?: Maybe<Array<Maybe<TaxHistoryHcMarket>>>;
  /** Ownership data as of the last assessment refresh available to HouseCanary. */
  ownership?: Maybe<TaxHistoryHcOwnership>;
  /** Annual data on the taxed amount. */
  tax?: Maybe<Array<Maybe<TaxHistoryHcTax>>>;
};

export type TaxHistoryHcAssessed = {
  __typename?: 'TaxHistoryHcAssessed';
  apn?: Maybe<Scalars['String']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  valueAssessed?: Maybe<Scalars['Float']>;
  valueAssessedImprovement?: Maybe<Scalars['Float']>;
  valueAssessedLand?: Maybe<Scalars['Float']>;
  yearAssessed?: Maybe<Scalars['Float']>;
};

export type TaxHistoryHcMarket = {
  __typename?: 'TaxHistoryHcMarket';
  apn?: Maybe<Scalars['String']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  valueMarket?: Maybe<Scalars['Float']>;
  valueMarketImprovement?: Maybe<Scalars['Float']>;
  valueMarketLand?: Maybe<Scalars['Float']>;
  yearMarket?: Maybe<Scalars['Float']>;
};

export type TaxHistoryHcOwnership = {
  __typename?: 'TaxHistoryHcOwnership';
  /** Date of latest assessment where ownership info was sourced */
  lastUpdatedDate?: Maybe<Scalars['String']>;
  owner?: Maybe<Array<Maybe<TaxHistoryHcOwnershipOwner>>>;
  ownerOccupied?: Maybe<Scalars['Boolean']>;
};

export type TaxHistoryHcOwnershipOwner = {
  __typename?: 'TaxHistoryHcOwnershipOwner';
  entityId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TaxHistoryHcTax = {
  __typename?: 'TaxHistoryHcTax';
  amountAnnual?: Maybe<Scalars['Float']>;
  apn?: Maybe<Scalars['String']>;
  /** @deprecated "Use propertyTypeEnum" */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  year?: Maybe<Scalars['Float']>;
};

export type TileInput = {
  x: Scalars['Int'];
  y: Scalars['Int'];
  zoom: Scalars['Int'];
};

/** Type of timeseries data to pass. */
export enum TimeSeriesType {
  Combined = 'COMBINED',
  Forecast = 'FORECAST',
  Historical = 'HISTORICAL',
}

export enum TotalCountType {
  AtLeast = 'AT_LEAST',
  Exact = 'EXACT',
}

export type UnemploymentRate = {
  __typename?: 'UnemploymentRate';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  timeSeries?: Maybe<Array<UnemploymentRateTimeSeriesElement>>;
  /** Unemployment rate */
  unemploymentRate?: Maybe<Scalars['Float']>;
  /** Previous year unemployment rate */
  unemploymentRate1YearAgo?: Maybe<Scalars['Float']>;
  /** Change in unemployment rate year over year */
  unemploymentRate1YearChange?: Maybe<Scalars['Float']>;
  /** Ratio of the unemployment rate to the prior year */
  unemploymentRate1YearRatio?: Maybe<Scalars['Float']>;
};

export type UnemploymentRateTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type UnemploymentRateTimeSeriesElement = {
  __typename?: 'UnemploymentRateTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Unemployment rate */
  unemploymentRate?: Maybe<Scalars['Float']>;
};

export type Value = {
  __typename?: 'Value';
  adjustmentFields?: Maybe<ScoreFields>;
  adjustmentScore?: Maybe<Score>;
  /** @deprecated No longer supported */
  condition?: Maybe<Scalars['Int']>;
  fsd?: Maybe<Scalars['Float']>;
  /** @deprecated No longer supported */
  fsd90Percent?: Maybe<Scalars['Float']>;
  hcAddressId?: Maybe<Scalars['NumericID']>;
  /** @deprecated No longer supported */
  hcCertifiedValue?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer supported */
  hcCertifiedValueConditionAverageOrBetter?: Maybe<Scalars['Boolean']>;
  /**
   * Use valueLower
   * @deprecated No longer supported
   */
  priceLower?: Maybe<Scalars['Int']>;
  /**
   * Use value
   * @deprecated No longer supported
   */
  priceMean?: Maybe<Scalars['Int']>;
  /**
   * Use valueUpper
   * @deprecated No longer supported
   */
  priceUpper?: Maybe<Scalars['Int']>;
  propertyScore?: Maybe<Scalars['Int']>;
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  quality?: Maybe<AvmQuality>;
  value?: Maybe<Scalars['Int']>;
  valueAtSixConditions?: Maybe<AvmValueAtSixConditions>;
  valueLower?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  valueLowerMin?: Maybe<Scalars['Int']>;
  valueMarketing?: Maybe<Scalars['Int']>;
  valueMedian?: Maybe<Scalars['Int']>;
  valueUpper?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  valueUpperMax?: Maybe<Scalars['Int']>;
};

export type ValueAsOfDate = {
  __typename?: 'ValueAsOfDate';
  date?: Maybe<Scalars['Date']>;
  fsd?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Int']>;
  valueLower?: Maybe<Scalars['Int']>;
  valueUpper?: Maybe<Scalars['Int']>;
};

export type ValueAsOfDateTime = {
  __typename?: 'ValueAsOfDateTime';
  date?: Maybe<Scalars['DateTime']>;
  fsd?: Maybe<Scalars['Float']>;
  sourceTable?: Maybe<Scalars['String']>;
  sourceTableServiceDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Int']>;
  valueLower?: Maybe<Scalars['Int']>;
  valueUpper?: Maybe<Scalars['Int']>;
};

export type ValueFactors = {
  __typename?: 'ValueFactors';
  age?: Maybe<AgeValueFactor>;
  basement?: Maybe<BasementValueFactor>;
  bathrooms?: Maybe<BathroomsValueFactor>;
  bedrooms?: Maybe<BedroomsValueFactor>;
  livingArea?: Maybe<LivingAreaValueFactor>;
  lotSize?: Maybe<LotSizeValueFactor>;
  pool?: Maybe<PoolValueFactor>;
};

export type ValueHpiAdjusted = {
  __typename?: 'ValueHpiAdjusted';
  adjustedBy?: Maybe<ValueHpiAdjustedBy>;
  fromDate?: Maybe<Scalars['Date']>;
  fromValue?: Maybe<Scalars['Int']>;
  toDate?: Maybe<Scalars['Date']>;
};

export type ValueHpiAdjustedBy = {
  __typename?: 'ValueHpiAdjustedBy';
  block?: Maybe<Scalars['Int']>;
  blockGroup?: Maybe<Scalars['Int']>;
  msa?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['Int']>;
};

export type ZipAffordability = {
  __typename?: 'ZipAffordability';
  timeSeries?: Maybe<Array<ZipAffordabilityTimeSeriesElement>>;
};

export type ZipAffordabilityTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type ZipAffordabilityTimeSeriesElement = {
  __typename?: 'ZipAffordabilityTimeSeriesElement';
  /** Month of the datapoint in the time series. */
  month?: Maybe<Scalars['Date']>;
  /** Raw affordability value. Represents the percent of median household income required to make the median home payment on a 30 year fixed rate mortgage with 20% down */
  payment?: Maybe<Scalars['Float']>;
  /** Normalized distance of afford_pmt from a long term linear trend. Units are in standard deviations from the mean */
  paymentDetrended?: Maybe<Scalars['Float']>;
};

export type ZipCrossSectionVariable = {
  __typename?: 'ZipCrossSectionVariable';
  /** The margin of error on the estimate from sample size extrapolation */
  errorMargin?: Maybe<Scalars['Float']>;
  /** The count, percentage or number being measured in the series */
  estimate?: Maybe<Scalars['Float']>;
  /** Percent margin of error based on the sample size collected and the extrapolation to the universe */
  percentErrorMargin?: Maybe<Scalars['Float']>;
  /** When variable_name is part of a larger sequence, percent_of_total is taken as the estimate divided by the sum(estimates) over the entire sequence */
  percentOfTotal?: Maybe<Scalars['Float']>;
  /** The name of the series being summarized. These may be counts, year-over-year changes, prices, etc. */
  seriesName?: Maybe<Scalars['String']>;
  /** The population which are being counted, summed, an analyzed. */
  universe?: Maybe<Scalars['String']>;
  /** The lower bound of the series element being measured */
  valueFrom?: Maybe<Scalars['Int']>;
  /** The name of the level of the series being measured */
  valueLabel?: Maybe<Scalars['String']>;
  /** The upper bound of the series element being measured */
  valueTo?: Maybe<Scalars['Int']>;
  /** The name of the level of the series being measured */
  variableName?: Maybe<Scalars['String']>;
};

export type ZipDetails = {
  __typename?: 'ZipDetails';
  /** Time Series - Affordability */
  affordability?: Maybe<ZipAffordability>;
  crossSectionVariable?: Maybe<Array<ZipCrossSectionVariable>>;
  geoLatitude?: Maybe<Scalars['Float']>;
  geoLongitude?: Maybe<Scalars['Float']>;
  geometry?: Maybe<Scalars['GeoJSON']>;
  geopoint?: Maybe<ZipGeopoint>;
  /** HouseCanary Rental Index */
  hcri?: Maybe<ZipHcri>;
  /** House Price Index */
  hpi?: Maybe<ZipHpi>;
  hpiPerformance?: Maybe<ZipHpiPerformance>;
  /** total area of land in square miles */
  landArea?: Maybe<Scalars['Float']>;
  /** Listing Stats */
  listingStats?: Maybe<ZipListingStats>;
  /** Measurements of market quality */
  marketQuality?: Maybe<ZipMarketQuality>;
  /** Historical max percent loss in HPI over a 12 month period */
  max1YearLoss?: Maybe<Scalars['Float']>;
  /** Metropolitan Statistical Area (MSA) 5-digit code */
  msa?: Maybe<Scalars['String']>;
  /** Metropolitan Statistical Area (MSA) name */
  msaName?: Maybe<Scalars['String']>;
  /** Whether an MSA is standard or micro. */
  msaType?: Maybe<ZipMsaType>;
  /** number of zip codes in msa with data coverage */
  msaZipCountCoverage?: Maybe<Scalars['Int']>;
  /** total number of zip codes in msa */
  msaZipCountTotal?: Maybe<Scalars['Int']>;
  /** Aggregate rating across the *_msa_bin columns */
  overallMsaBin?: Maybe<Scalars['Int']>;
  /** Aggregate rating across the *_state_bin columns */
  overallStateBin?: Maybe<Scalars['Int']>;
  /** Preferred name for the zipcode */
  preferredName?: Maybe<Scalars['String']>;
  ranking?: Maybe<ZipRanking>;
  /** Probability that this market's hpi will be lower 12 months from now than the current hpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
  risk1YearLossLevel?: Maybe<LossRiskLevel>;
  /** Rental Price Index - propertyType is deprecated input, use propertyTypeEnum instead  */
  rpi?: Maybe<ZipRpi>;
  /** USPS abbreviations, 2 chars */
  state?: Maybe<Scalars['String']>;
  /** state level FIPS (Federal Information Processing Standard) 2 digit code */
  stateFips?: Maybe<Scalars['String']>;
  /** number of zip codes in state with data coverage */
  stateZipCountCoverage?: Maybe<Scalars['Int']>;
  /** total zip count accepted via zip/state geom test by TIGER ingestion */
  stateZipCountTotal?: Maybe<Scalars['Int']>;
  /** total area in square miles */
  totalArea?: Maybe<Scalars['Float']>;
  /** total area of water in square miles */
  waterArea?: Maybe<Scalars['Float']>;
  /** identifier, this is the zipcode */
  zipcode?: Maybe<Scalars['String']>;
};

export type ZipDetailsCrossSectionVariableArgs = {
  seriesName?: InputMaybe<Scalars['String']>;
  universe?: InputMaybe<Scalars['String']>;
  variableName?: InputMaybe<Scalars['String']>;
};

export type ZipDetailsRpiArgs = {
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type ZipGeopoint = {
  __typename?: 'ZipGeopoint';
  center?: Maybe<ZipGeopointCenter>;
  msa?: Maybe<ZipGeopointMsa>;
};

export type ZipGeopointCenter = {
  __typename?: 'ZipGeopointCenter';
  /** Latitude value, in degrees */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude value, in degrees */
  longitude?: Maybe<Scalars['Float']>;
};

export type ZipGeopointMsa = {
  __typename?: 'ZipGeopointMsa';
  /** Latitude value, in degrees */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude value, in degrees */
  longitude?: Maybe<Scalars['Float']>;
  /** Legal/Statistical Area Description code */
  lsad?: Maybe<Scalars['String']>;
  /** 5-digit US census MSA ID */
  msaID?: Maybe<Scalars['String']>;
  /** Whether an MSA is standard or micro */
  msaType?: Maybe<ZipMsaType>;
  /** Name of MSA */
  name?: Maybe<Scalars['String']>;
  /** Legal/Statistical Area Description name */
  nameLsad?: Maybe<Scalars['String']>;
};

export type ZipHcri = {
  __typename?: 'ZipHcri';
  /** Average effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median effective gross yields. */
  effectiveYieldCount?: Maybe<Scalars['Int']>;
  /** Median effective gross yield over the selected area of summarization. Effective_gross_yield = (rental AVM * 12 - estimated_taxes) / AVM */
  effectiveYieldMedian?: Maybe<Scalars['Float']>;
  /** Average forecast standard deviation (uncertainty measurement) for all rental values in the selected area of summarization. */
  fsdAverage?: Maybe<Scalars['Float']>;
  /** Median forecast standard deviation (uncertainty measurement) for all rental values in the selected area of summarization. */
  fsdMedian?: Maybe<Scalars['Float']>;
  /** Average gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldAverage?: Maybe<Scalars['Float']>;
  /** Count of properties considered for average and median gross yields. */
  grossYieldCount?: Maybe<Scalars['Int']>;
  /** Median gross yield over the selected area of summarization. Gross_yield = rental AVM * 12 / AVM */
  grossYieldMedian?: Maybe<Scalars['Float']>;
  /** Average rental value of the selected area of summarization. */
  rentalAverage?: Maybe<Scalars['Int']>;
  /** Count of properties considered for average and median rental valuation summaries. */
  rentalCount?: Maybe<Scalars['Int']>;
  /** Median rental value of the selected area of summarization. */
  rentalMedian?: Maybe<Scalars['Int']>;
};

export type ZipHpi = {
  __typename?: 'ZipHpi';
  forecastCalcs?: Maybe<ZipHpiForecastCalcs>;
  historicalCalcs?: Maybe<ZipHpiHistoricalCalcs>;
  timeSeries?: Maybe<Array<ZipHpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type ZipHpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type ZipHpiForecastCalcs = {
  __typename?: 'ZipHpiForecastCalcs';
  cagr?: Maybe<ZipHpiForecastCalcsCagr>;
  ratio?: Maybe<ZipHpiForecastCalcsRatio>;
  return?: Maybe<ZipHpiForecastCalcsReturn>;
  /** Probability that this market's hpi will be lower 12 months from now than the current hpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
};

export type ZipHpiForecastCalcsCagr = {
  __typename?: 'ZipHpiForecastCalcsCagr';
  /** Forecast 1-year compound annual growth rate (CAGR) */
  cagr1YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast 2-year compound annual growth rate (CAGR) */
  cagr2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast 3-year compound annual growth rate (CAGR) */
  cagr3YearForecast?: Maybe<Scalars['Float']>;
};

export type ZipHpiForecastCalcsRatio = {
  __typename?: 'ZipHpiForecastCalcsRatio';
  /** Forecast home price appreciation ratio for the next 3 months based on HPI forecast (1.0000) */
  ratio3MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 6 months based on HPI forecast */
  ratio6MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 12 months based on HPI forecast */
  ratio12MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 18 months based on HPI forecast */
  ratio18MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 24 months based on HPI forecast */
  ratio24MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 30 months based on HPI forecast */
  ratio30MonthForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation ratio for the next 36 months based on HPI forecast */
  ratio36MonthForecast?: Maybe<Scalars['Float']>;
};

export type ZipHpiForecastCalcsReturn = {
  __typename?: 'ZipHpiForecastCalcsReturn';
  /** Forecast home price appreciation for the next 12 months based on HPI forecast */
  return1YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 24 months based on HPI forecast */
  return2YearForecast?: Maybe<Scalars['Float']>;
  /** Forecast home price appreciation for the next 36 months based on HPI forecast */
  return3YearForecast?: Maybe<Scalars['Float']>;
};

export type ZipHpiHistoricalCalcs = {
  __typename?: 'ZipHpiHistoricalCalcs';
  cagr?: Maybe<ZipHpiHistoricalCalcsCagr>;
  /** Historical max percent loss in HPI over a 12 month period */
  max1YearLoss?: Maybe<Scalars['Float']>;
  return?: Maybe<ZipHpiHistoricalCalcsReturn>;
};

export type ZipHpiHistoricalCalcsCagr = {
  __typename?: 'ZipHpiHistoricalCalcsCagr';
  /** 1-year historical compound annual growth rate (CAGR) */
  cagr1Year?: Maybe<Scalars['Float']>;
  /** 5-year historical compound annual growth rate (CAGR) */
  cagr5Year?: Maybe<Scalars['Float']>;
  /** 10-year historical compound annual growth rate (CAGR) */
  cagr10Year?: Maybe<Scalars['Float']>;
  /** 20-year historical compound annual growth rate (CAGR) */
  cagr20Year?: Maybe<Scalars['Float']>;
};

export type ZipHpiHistoricalCalcsReturn = {
  __typename?: 'ZipHpiHistoricalCalcsReturn';
  /** Home price appreciation for the last 1 years based on HPI */
  return1Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 5 years based on HPI */
  return5Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 10 years based on HPI */
  return10Year?: Maybe<Scalars['Float']>;
  /** Home price appreciation for the last 20 years based on HPI */
  return20Year?: Maybe<Scalars['Float']>;
};

export type ZipHpiPerformance = {
  __typename?: 'ZipHpiPerformance';
  cagr?: Maybe<HpiPerformanceCagr>;
  /** Number of months used for measures */
  calcMonths?: Maybe<Scalars['Int']>;
  return?: Maybe<HpiPerformanceReturn>;
  volatility?: Maybe<HpiPerformanceVolatility>;
};

export type ZipHpiTimeSeriesElement = {
  __typename?: 'ZipHpiTimeSeriesElement';
  /** Monthly change in velocity_value */
  accelerationValue?: Maybe<Scalars['Float']>;
  /** The normalized distance of hpi_value from a long term linear trend. Units are in standard deviations from the mean */
  distance?: Maybe<Scalars['Float']>;
  /** Month of the datapoint in the time series. string in ISO date format */
  month?: Maybe<Scalars['Date']>;
  /** Real housing price index after adjusting nominal HPI for inflation as measured by the CPI */
  real?: Maybe<Scalars['Float']>;
  /** Monthly returns in the nominal housing price index. Formally computed as hpi_value(t)/hpi_value(t-1) */
  return?: Maybe<Scalars['Float']>;
  /** Year over year percent change in the nominal housing price index. Formally computed as [hpi_value(t)/hpi_value(t-12)]-1. percent as float (.075 = 7.5%) */
  return1YearPercentChange?: Maybe<Scalars['Float']>;
  /** Long term linear trend in hpi_value */
  trend?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) */
  value?: Maybe<Scalars['Float']>;
  /** Nominal housing price index (HPI) indexed to month of time series generation */
  valueIndexed?: Maybe<Scalars['Float']>;
  /** Smoothed version of the year over year change in hpi_value */
  velocityValue?: Maybe<Scalars['Float']>;
};

export type ZipListingStats = {
  __typename?: 'ZipListingStats';
  /** Zipcode Listing Stats. */
  latestStats?: Maybe<HcListingStats>;
  timeSeries?: Maybe<Array<HcListingStats>>;
};

export type ZipListingStatsTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  toDate?: InputMaybe<Scalars['Date']>;
};

/** Whether an MSA is standard or micro. */
export enum ZipMsaType {
  Micro = 'MICRO',
  Standard = 'STANDARD',
}

export type ZipMarketQuality = {
  __typename?: 'ZipMarketQuality';
  /** Letter grade representing measure of market quality. */
  grade?: Maybe<MarketGrade>;
  /** Number (0-4) representing measure of market quality. In API, mapped to letter grades. (4=A, 3=B, 2=C, 1=D, 0=F). */
  rating?: Maybe<Scalars['Int']>;
};

export type ZipRanking = {
  __typename?: 'ZipRanking';
  affordability?: Maybe<ZipRankingAffordability>;
  commute?: Maybe<ZipRankingCommute>;
  education?: Maybe<ZipRankingEducation>;
  highTech?: Maybe<ZipRankingHighTech>;
  homeValue?: Maybe<ZipRankingHomeValue>;
  hpi?: Maybe<ZipRankingHpi>;
  income?: Maybe<ZipRankingIncome>;
  investmentIncome?: Maybe<ZipRankingInvestmentIncome>;
  meanHomeValue?: Maybe<ZipRankingMeanHomeValue>;
  monthlyCostOwn?: Maybe<ZipRankingMonthlyCostOwn>;
  monthlyCostRent?: Maybe<ZipRankingMonthlyCostRent>;
  poverty?: Maybe<ZipRankingPoverty>;
  retirementIncome?: Maybe<ZipRankingRetirementIncome>;
  unemployment?: Maybe<ZipRankingUnemployment>;
  unemploymentRateScaled?: Maybe<ZipRankingUnemploymentRateScaled>;
};

export type ZipRankingAffordability = {
  __typename?: 'ZipRankingAffordability';
  /** current affordability either above or below trend. takes one of two values 'above' or 'below' */
  distanceDescription?: Maybe<Scalars['String']>;
  /** median affordability across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median affordability across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** ZIP code affordability percentile in MSA */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode affordability percentile in state (for MSAs this value is relative to other MSAs in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode affordability percentile in msa mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode affordability percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
  /** Current value of afford_detrended in the hpi time series files. Normalized distance of afford_pmt from a long term linear trend. Units are in standard deviations from the mean */
  value?: Maybe<Scalars['Float']>;
};

export type ZipRankingCommute = {
  __typename?: 'ZipRankingCommute';
  /** average commute time in minutes */
  commuteTime?: Maybe<Scalars['Float']>;
  /** median commute across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median commute across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode commute percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode commute percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode commute percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode commute percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingEducation = {
  __typename?: 'ZipRankingEducation';
  /** median education across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median education across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** Percent of population with college degree */
  percentage?: Maybe<Scalars['Float']>;
  /** zipcode education percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode education percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode education percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode education percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingHighTech = {
  __typename?: 'ZipRankingHighTech';
  /** median high_tech across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median high_tech across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** Percent of workers in high tech jobs */
  percentage?: Maybe<Scalars['Float']>;
  /** zipcode high_tech percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode high_tech percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode high_tech percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode high_tech percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingHomeValue = {
  __typename?: 'ZipRankingHomeValue';
  /** median home_value across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median home_value across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode home_value percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode home_value percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode home_value percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode home_value percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
  /** raw median home value reported by census */
  rawValue?: Maybe<Scalars['Float']>;
  /** current median home value */
  value?: Maybe<Scalars['Float']>;
};

export type ZipRankingHpi = {
  __typename?: 'ZipRankingHpi';
  /** Normalized distance of hpi_value from a long term linear trend. Units are in standard deviations from the mean. */
  distance?: Maybe<Scalars['Float']>;
  /** Current HPI either above or below trend. One of 'above' or 'below' */
  distanceDescription?: Maybe<Scalars['String']>;
  /** Change in HPI year-over-year */
  return1Year?: Maybe<Scalars['Float']>;
  /** Forecast change in HPI over next 12 months */
  return1YearForecast?: Maybe<Scalars['Float']>;
  /** zipcode HPI 12m forecast change percentile relative to other zips in the msa */
  return1YearForecastPercentileMsa?: Maybe<Scalars['Float']>;
  /** msa or zipcode HPI 12m forecast change percentile relative to US */
  return1YearForecastPercentileNational?: Maybe<Scalars['Float']>;
  /** msa or zipcode HPI 12m forecast change percentile relative to state */
  return1YearForecastPercentileState?: Maybe<Scalars['Float']>;
  /** zipcode HPI prior 12m change percentile relative to other zips in the msa */
  return1YearPercentileMsa?: Maybe<Scalars['Float']>;
  /** msa or zipcode prior 12m change percentile relative to US */
  return1YearPercentileNational?: Maybe<Scalars['Float']>;
  /** msa or zipcode prior 12m change percentile relative to state */
  return1YearPercentileState?: Maybe<Scalars['Float']>;
  /** current value of velocity_value in hpi time series file */
  velocityCurrent?: Maybe<Scalars['Float']>;
  /** Is current velocity accelerating or decelerating, takes one of two values 'increasing' or 'decreasing' */
  velocityDelta?: Maybe<Scalars['String']>;
  /** Is current velocity positive or negative, takes one of two values 'positive' or 'negative' */
  velocityDirection?: Maybe<Scalars['String']>;
  /** value of velocity_value in hpi time series file 12 months ago */
  velocityYearAgo?: Maybe<Scalars['Float']>;
};

export type ZipRankingIncome = {
  __typename?: 'ZipRankingIncome';
  /** Avg household earnings from all sources */
  amount?: Maybe<Scalars['Float']>;
  /** median income across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median income across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode income percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode income percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode income percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode income percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingInvestmentIncome = {
  __typename?: 'ZipRankingInvestmentIncome';
  /** Avg household investment income */
  amount?: Maybe<Scalars['Float']>;
  /** median investment_income across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median investment_income across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode investment_income percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode investment_income percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode investment_income percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode investment_income percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingMeanHomeValue = {
  __typename?: 'ZipRankingMeanHomeValue';
  /** median mean_home_value across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median mean_home_value across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode mean_home_value percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode mean_home_value percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode mean_home_value percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode mean_home_value percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
  /** raw mean home value reported by census */
  rawValue?: Maybe<Scalars['Float']>;
  /** Current mean home value */
  value?: Maybe<Scalars['Float']>;
};

export type ZipRankingMonthlyCostOwn = {
  __typename?: 'ZipRankingMonthlyCostOwn';
  /** median monthly cost of home ownership in dollars */
  amount?: Maybe<Scalars['Float']>;
  /** median monthly_cost_own across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median monthly_cost_own across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_own percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_own percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_own percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode monthly_cost_own percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingMonthlyCostRent = {
  __typename?: 'ZipRankingMonthlyCostRent';
  /** median monthly cost to rent */
  amount?: Maybe<Scalars['Float']>;
  /** median monthly_cost_rent across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median monthly_cost_rent across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_rent percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_rent percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode monthly_cost_rent percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode monthly_cost_rent percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingPoverty = {
  __typename?: 'ZipRankingPoverty';
  /** median poverty across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median poverty across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** percent of households below the poverty line */
  percentage?: Maybe<Scalars['Float']>;
  /** zipcode poverty percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode poverty percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode poverty percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode poverty percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingRetirementIncome = {
  __typename?: 'ZipRankingRetirementIncome';
  /** Avg household retirement income */
  amount?: Maybe<Scalars['Float']>;
  /** median retirement_income across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median retirement_income across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** ZIP code retirement_income percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** ZIP code retirement_income percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** ZIP code retirement_income percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** ZIP code retirement_income percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingUnemployment = {
  __typename?: 'ZipRankingUnemployment';
  /** median unemployment across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median unemployment across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** raw unemployment percentage reported by census */
  percentage?: Maybe<Scalars['Float']>;
  /** zipcode unemployment percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode unemployment percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode unemployment percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode unemployment percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
};

export type ZipRankingUnemploymentRateScaled = {
  __typename?: 'ZipRankingUnemploymentRateScaled';
  /** median ur_scaled across all zipcodes in the msa */
  medianMsa?: Maybe<Scalars['Float']>;
  /** median ur_scaled across all zipcodes in the state (for msas this value is relative to other msas in the same state) */
  medianState?: Maybe<Scalars['Float']>;
  /** zipcode ur_scaled percentile in msa */
  percentileMsa?: Maybe<Scalars['Float']>;
  /** zipcode ur_scaled percentile in state (for msas this value is relative to other msas in the same state) */
  percentileState?: Maybe<Scalars['Float']>;
  /** zipcode ur_scaled percentile mapped to quintile bin, 0-4 with 4 highest */
  quintileMsa?: Maybe<Scalars['Int']>;
  /** zipcode ur_scaled percentile in state mapped to quintile bin, 0-4 with 4 highest (for msas this value is relative to other msas in the same state) */
  quintileState?: Maybe<Scalars['Int']>;
  /** raw unemployment percentage reported by census (duplicate of unemployment column) */
  rawValue?: Maybe<Scalars['Float']>;
  /** current zipcode estimate of the unemployment rate based on msa trend */
  value?: Maybe<Scalars['Float']>;
};

export type ZipRpi = {
  __typename?: 'ZipRpi';
  /** RPI forecast cals - propertyType is deprecated input, use propertyTypeEnum instead  */
  forecastCalcs?: Maybe<Array<Maybe<ZipRpiForecastCalcs>>>;
  /** RPI time series - propertyType is deprecated input, use propertyTypeEnum instead */
  timeSeries?: Maybe<Array<RpiTimeSeriesElement>>;
  timeSeriesCurrent?: Maybe<Scalars['Date']>;
};

export type ZipRpiForecastCalcsArgs = {
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
};

export type ZipRpiTimeSeriesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  order?: InputMaybe<Order>;
  propertyType?: InputMaybe<PropertyType>;
  propertyTypeEnum?: InputMaybe<PropertyTypeEnum>;
  seriesType?: InputMaybe<TimeSeriesType>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type ZipRpiForecastCalcs = {
  __typename?: 'ZipRpiForecastCalcs';
  /**
   * Use propertyTypeEnum;
   * @deprecated No longer supported
   */
  propertyType?: Maybe<Scalars['String']>;
  /** Type of property. One of - SFR, CONDO, TOWNHOUSE */
  propertyTypeEnum?: Maybe<PropertyTypeEnum>;
  return1Year?: Maybe<Scalars['Float']>;
  /** Probability that this market's rpi will be lower 12 months from now than the current rpi */
  risk1YearLoss?: Maybe<Scalars['Float']>;
  risk1YearLossLevel?: Maybe<LossRiskLevel>;
};

export type ZipSearchResults = {
  __typename?: 'ZipSearchResults';
  edges?: Maybe<Array<Maybe<ZipSearchResultsEdges>>>;
  pageInfo?: Maybe<PageInfo>;
  resultSetInfo?: Maybe<ResultSetInfo>;
};

export type ZipSearchResultsEdges = {
  __typename?: 'ZipSearchResultsEdges';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ZipDetails>;
};

export type ZipcodeInput = {
  zipcode?: InputMaybe<Scalars['String']>;
};

export enum ZoneType {
  A = 'A',
  A1_30 = 'A1_30',
  A99 = 'A99',
  Ae = 'AE',
  Ah = 'AH',
  Ao = 'AO',
  Ar = 'AR',
  AreaNotIncluded = 'AreaNotIncluded',
  D = 'D',
  OpenWater = 'OpenWater',
  Unknown = 'Unknown',
  V = 'V',
  Ve = 'VE',
  X = 'X',
}

export type PropertySpatialDetailResultsFragment = {
  __typename: 'SpatialSearchDetailResults';
  atEnd?: boolean | null;
  cursor?: string | null;
  totalCount?: number | null;
  hits?: Array<{
    __typename?: 'SpatialSearchDetail';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
    };
    complexFields?: Array<{
      __typename?: 'ComplexFields';
      sale?: {
        __typename?: 'ComplexFieldsSale';
        currentHcpyLookupKey?: string | null;
        currentSourceId?: string | null;
        currentDaysOnMarketCumulative?: number | null;
        currentDaysToCloseCumulative?: number | null;
        lastCloseHcMlsId?: number | null;
        lastCloseHcpyLookupKey?: string | null;
        lastCloseListingId?: string | null;
        currentArmsLength?: boolean | null;
        currentDistressed?: boolean | null;
        currentFlipYN?: boolean | null;
        currentListDate?: DateStr | null;
        currentListingPrice?: number | null;
        lastCloseDate?: DateStr | null;
        lastClosePrice?: number | null;
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: DateStr | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
      rental?: {
        __typename?: 'ComplexFieldsRental';
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: string | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
    }> | null;
  }> | null;
};

export type PropertySpatialSearchListQueryVariables = Exact<{
  id: SpatialSortPropertiesIdentifierInput;
  limit: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<SpatialSortInput>;
  spatialFilters: SpatialSortFiltersInput;
}>;

export type PropertySpatialSearchListQuery = {
  __typename?: 'Query';
  propertySpatialESSearch?:
    | { __typename?: 'SpatialSearchCountResult' }
    | {
        __typename: 'SpatialSearchDetailResults';
        atEnd?: boolean | null;
        cursor?: string | null;
        totalCount?: number | null;
        hits?: Array<{
          __typename?: 'SpatialSearchDetail';
          propertyDetails: {
            __typename?: 'PropertyDetails';
            hcAddressId?: number | null;
            location?: {
              __typename?: 'PropertyDetailsLocation';
              hc?: {
                __typename?: 'CommonLocationHc';
                neighborhood?: string | null;
                subdivision?: string | null;
              } | null;
              discovery?: {
                __typename?: 'CommonLocationDiscovery';
                hcAddress?: {
                  __typename?: 'CommonLocationDiscoveryHcAddress';
                  address?: string | null;
                  addressSlug?: string | null;
                  blockGroupId?: string | null;
                  blockId?: string | null;
                  city?: string | null;
                  completeYN?: boolean | null;
                  county?: string | null;
                  fips?: string | null;
                  geoPrecision?: GeoPrecision | null;
                  latitude?: number | null;
                  longitude?: number | null;
                  metroDivisionId?: string | null;
                  msaId?: string | null;
                  realYN?: boolean | null;
                  state?: string | null;
                  tractId?: string | null;
                  unit?: string | null;
                  verifiedYN?: boolean | null;
                  zipcode?: string | null;
                  zipcodePlus4?: string | null;
                } | null;
              } | null;
            } | null;
          };
          complexFields?: Array<{
            __typename?: 'ComplexFields';
            sale?: {
              __typename?: 'ComplexFieldsSale';
              currentHcpyLookupKey?: string | null;
              currentSourceId?: string | null;
              currentDaysOnMarketCumulative?: number | null;
              currentDaysToCloseCumulative?: number | null;
              lastCloseHcMlsId?: number | null;
              lastCloseHcpyLookupKey?: string | null;
              lastCloseListingId?: string | null;
              currentArmsLength?: boolean | null;
              currentDistressed?: boolean | null;
              currentFlipYN?: boolean | null;
              currentListDate?: DateStr | null;
              currentListingPrice?: number | null;
              lastCloseDate?: DateStr | null;
              lastClosePrice?: number | null;
              currentHcMlsId?: number | null;
              currentListingId?: string | null;
              currentPrice?: number | null;
              currentStatus?: string | null;
              currentStatusDate?: DateStr | null;
              currentlyOnMarket?: boolean | null;
              currentDaysOnMarketMLS?: number | null;
            } | null;
            rental?: {
              __typename?: 'ComplexFieldsRental';
              currentHcMlsId?: number | null;
              currentListingId?: string | null;
              currentPrice?: number | null;
              currentStatus?: string | null;
              currentStatusDate?: string | null;
              currentlyOnMarket?: boolean | null;
              currentDaysOnMarketMLS?: number | null;
            } | null;
          }> | null;
        }> | null;
      }
    | { __typename?: 'SpatialSearchOverCountResult' }
    | null;
};

export type PropertySpatialSearchMapQueryVariables = Exact<{
  id: SpatialSortPropertiesIdentifierInput;
  limit: Scalars['Int'];
  spatialFilters: SpatialSortFiltersInput;
}>;

export type PropertySpatialSearchMapQuery = {
  __typename?: 'Query';
  propertySpatialESSearch?:
    | {
        __typename: 'SpatialSearchCountResult';
        count?: number | null;
        labelLocation?: Geometry | null;
      }
    | {
        __typename: 'SpatialSearchDetailResults';
        atEnd?: boolean | null;
        cursor?: string | null;
        totalCount?: number | null;
        hits?: Array<{
          __typename?: 'SpatialSearchDetail';
          propertyDetails: {
            __typename?: 'PropertyDetails';
            hcAddressId?: number | null;
            location?: {
              __typename?: 'PropertyDetailsLocation';
              hc?: {
                __typename?: 'CommonLocationHc';
                neighborhood?: string | null;
                subdivision?: string | null;
              } | null;
              discovery?: {
                __typename?: 'CommonLocationDiscovery';
                hcAddress?: {
                  __typename?: 'CommonLocationDiscoveryHcAddress';
                  address?: string | null;
                  addressSlug?: string | null;
                  blockGroupId?: string | null;
                  blockId?: string | null;
                  city?: string | null;
                  completeYN?: boolean | null;
                  county?: string | null;
                  fips?: string | null;
                  geoPrecision?: GeoPrecision | null;
                  latitude?: number | null;
                  longitude?: number | null;
                  metroDivisionId?: string | null;
                  msaId?: string | null;
                  realYN?: boolean | null;
                  state?: string | null;
                  tractId?: string | null;
                  unit?: string | null;
                  verifiedYN?: boolean | null;
                  zipcode?: string | null;
                  zipcodePlus4?: string | null;
                } | null;
              } | null;
            } | null;
          };
          complexFields?: Array<{
            __typename?: 'ComplexFields';
            sale?: {
              __typename?: 'ComplexFieldsSale';
              currentHcpyLookupKey?: string | null;
              currentSourceId?: string | null;
              currentDaysOnMarketCumulative?: number | null;
              currentDaysToCloseCumulative?: number | null;
              lastCloseHcMlsId?: number | null;
              lastCloseHcpyLookupKey?: string | null;
              lastCloseListingId?: string | null;
              currentArmsLength?: boolean | null;
              currentDistressed?: boolean | null;
              currentFlipYN?: boolean | null;
              currentListDate?: DateStr | null;
              currentListingPrice?: number | null;
              lastCloseDate?: DateStr | null;
              lastClosePrice?: number | null;
              currentHcMlsId?: number | null;
              currentListingId?: string | null;
              currentPrice?: number | null;
              currentStatus?: string | null;
              currentStatusDate?: DateStr | null;
              currentlyOnMarket?: boolean | null;
              currentDaysOnMarketMLS?: number | null;
            } | null;
            rental?: {
              __typename?: 'ComplexFieldsRental';
              currentHcMlsId?: number | null;
              currentListingId?: string | null;
              currentPrice?: number | null;
              currentStatus?: string | null;
              currentStatusDate?: string | null;
              currentlyOnMarket?: boolean | null;
              currentDaysOnMarketMLS?: number | null;
            } | null;
          }> | null;
        }> | null;
      }
    | {
        __typename: 'SpatialSearchOverCountResult';
        moreThan?: number | null;
        labelLocation?: Geometry | null;
      }
    | null;
};

export type PropertySpatialSearchCountQueryVariables = Exact<{
  id: SpatialSortPropertiesIdentifierInput;
  spatialFilters: SpatialSortFiltersInput;
}>;

export type PropertySpatialSearchCountQuery = {
  __typename?: 'Query';
  propertySpatialESSearch?:
    | { __typename: 'SpatialSearchCountResult'; count?: number | null }
    | { __typename: 'SpatialSearchDetailResults' }
    | { __typename: 'SpatialSearchOverCountResult'; moreThan?: number | null }
    | null;
};

export type AllMsaDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type AllMsaDetailsQuery = {
  __typename?: 'Query';
  allMsaDetails?: Array<{
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    listingStats?: {
      __typename?: 'MsaListingStats';
      latestStats?: {
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      } | null;
    } | null;
    rentalListingStats?: {
      __typename?: 'MsaRentalListingStats';
      latestStats?: {
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      } | null;
    } | null;
    hpi?: {
      __typename?: 'MsaHpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: {
        __typename?: 'MsaHpiForecastCalcs';
        risk1YearLoss?: number | null;
        return?: {
          __typename?: 'MsaHpiForecastCalcsReturn';
          return1YearForecast?: number | null;
        } | null;
      } | null;
    } | null;
    hcri?: { __typename?: 'MsaHcri'; grossYieldMedian?: number | null } | null;
    rpi?: {
      __typename?: 'MsaRpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: Array<{
        __typename?: 'MsaRpiForecastCalcs';
        risk1YearLoss?: number | null;
        return1Year?: number | null;
      } | null> | null;
    } | null;
    populationStats?: {
      __typename?: 'PopulationStats';
      population?: {
        __typename?: 'Population';
        month?: DateStr | null;
        population1YearAgo?: number | null;
        population1YearChange?: number | null;
        population1YearRatio?: number | null;
      } | null;
    } | null;
  } | null> | null;
};

export type MsaDetailsQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
}>;

export type MsaDetailsQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    listingStats?: {
      __typename?: 'MsaListingStats';
      latestStats?: {
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      } | null;
    } | null;
    rentalListingStats?: {
      __typename?: 'MsaRentalListingStats';
      latestStats?: {
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      } | null;
    } | null;
    hpi?: {
      __typename?: 'MsaHpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: {
        __typename?: 'MsaHpiForecastCalcs';
        risk1YearLoss?: number | null;
        return?: {
          __typename?: 'MsaHpiForecastCalcsReturn';
          return1YearForecast?: number | null;
        } | null;
      } | null;
    } | null;
    hcri?: { __typename?: 'MsaHcri'; grossYieldMedian?: number | null } | null;
    rpi?: {
      __typename?: 'MsaRpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: Array<{
        __typename?: 'MsaRpiForecastCalcs';
        risk1YearLoss?: number | null;
        return1Year?: number | null;
      } | null> | null;
    } | null;
    populationStats?: {
      __typename?: 'PopulationStats';
      population?: {
        __typename?: 'Population';
        month?: DateStr | null;
        population1YearAgo?: number | null;
        population1YearChange?: number | null;
        population1YearRatio?: number | null;
      } | null;
    } | null;
  } | null;
};

export type MsaListingStatsTimeSeriesQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type MsaListingStatsTimeSeriesQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    listingStats?: {
      __typename?: 'MsaListingStats';
      timeSeries?: Array<{
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      }> | null;
    } | null;
    rentalListingStats?: {
      __typename?: 'MsaRentalListingStats';
      timeSeries?: Array<{
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type MsaHpiTimeSeriesQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type MsaHpiTimeSeriesQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    hpi?: {
      __typename?: 'MsaHpi';
      historicalTimeSeries?: Array<{
        __typename?: 'MsaHpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
      forecastTimeSeries?: Array<{
        __typename?: 'MsaHpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type MsaRpiTimeSeriesQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type MsaRpiTimeSeriesQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    rpi?: {
      __typename?: 'MsaRpi';
      historicalTimeSeries?: Array<{
        __typename?: 'RpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
      forecastTimeSeries?: Array<{
        __typename?: 'RpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type ZipRpiTimeSeriesQueryVariables = Exact<{
  zipcode?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type ZipRpiTimeSeriesQuery = {
  __typename?: 'Query';
  lookupZipDetails?: {
    __typename?: 'ZipDetails';
    zipcode?: string | null;
    msa?: string | null;
    preferredName?: string | null;
    rpi?: {
      __typename?: 'ZipRpi';
      historicalTimeSeries?: Array<{
        __typename?: 'RpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
      forecastTimeSeries?: Array<{
        __typename?: 'RpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type MsaUnemploymentTimeSeriesQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type MsaUnemploymentTimeSeriesQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    populationStats?: {
      __typename?: 'PopulationStats';
      unemploymentRate?: {
        __typename?: 'UnemploymentRate';
        timeSeries?: Array<{
          __typename?: 'UnemploymentRateTimeSeriesElement';
          month?: DateStr | null;
          unemploymentRate?: number | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type MsaPopulationTimeSeriesQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type MsaPopulationTimeSeriesQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    msa?: string | null;
    name?: string | null;
    zipCount?: number | null;
    populationStats?: {
      __typename?: 'PopulationStats';
      population?: {
        __typename?: 'Population';
        timeSeries?: Array<{
          __typename?: 'PopulationTimeSeriesElement';
          month?: DateStr | null;
          population?: number | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type MsaZipsQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
}>;

export type MsaZipsQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    zips?: Array<{
      __typename?: 'ZipDetails';
      zipcode?: string | null;
    } | null> | null;
  } | null;
};

export type ZipGeomQueryVariables = Exact<{
  zipcode?: InputMaybe<Scalars['String']>;
}>;

export type ZipGeomQuery = {
  __typename?: 'Query';
  lookupZipDetails?: {
    __typename?: 'ZipDetails';
    zipcode?: string | null;
    msa?: string | null;
    preferredName?: string | null;
    geometry?: Geometry | null;
    geoLatitude?: number | null;
    geoLongitude?: number | null;
  } | null;
};

export type AllMsaZipDetailsQueryVariables = Exact<{
  msaId?: InputMaybe<Scalars['String']>;
}>;

export type AllMsaZipDetailsQuery = {
  __typename?: 'Query';
  lookupMsaDetails?: {
    __typename?: 'MsaDetails';
    zips?: Array<{
      __typename?: 'ZipDetails';
      zipcode?: string | null;
      msa?: string | null;
      preferredName?: string | null;
      listingStats?: {
        __typename?: 'ZipListingStats';
        latestStats?: {
          __typename?: 'HCListingStats';
          daysOnMarketMedian?: number | null;
          daysOnMarketMedianMovingAvg?: number | null;
          daysOnMarketNotContractMedian?: number | null;
          daysOnMarketNotContractMedianMovingAvg?: number | null;
          daysToCloseMedian?: number | null;
          daysToCloseMedianMovingAvg?: number | null;
          daysToContractMedian?: number | null;
          daysToContractMedianMovingAvg?: number | null;
          daysToFirstPriceDropMedian?: number | null;
          daysToFirstPriceDropMedianMovingAvg?: number | null;
          eventDate?: DateStr | null;
          listingsClosedCount?: number | null;
          listingsClosedCountMovingAvg?: number | null;
          listingsNewCount?: number | null;
          listingsNewCountMovingAvg?: number | null;
          listingsOnMarketCount?: number | null;
          listingsOnMarketCountMovingAvg?: number | null;
          listingsPriceChangeCount?: number | null;
          listingsPriceChangeCountMovingAvg?: number | null;
          listingsPriceDropCount?: number | null;
          listingsPriceDropCountMovingAvg?: number | null;
          listingsPriceDropPercent?: number | null;
          listingsPriceDropPercentMovingAvg?: number | null;
          listingsRemovedCount?: number | null;
          listingsRemovedCountMovingAvg?: number | null;
          listingsRemovedPercent?: number | null;
          listingsRemovedPercentMovingAvg?: number | null;
          listingsUnderContractCount?: number | null;
          listingsUnderContractCountMovingAvg?: number | null;
          listingsUnderContractPercent?: number | null;
          listingsUnderContractPercentMovingAvg?: number | null;
          monthsOfSupplyMedian?: number | null;
          monthsOfSupplyMedianMovingAvg?: number | null;
          priceClosedMedian?: number | null;
          priceClosedMedianMovingAvg?: number | null;
          priceNewListMedian?: number | null;
          priceNewListMedianMovingAvg?: number | null;
          priceOnMarketMedian?: number | null;
          priceOnMarketMedianMovingAvg?: number | null;
          pricePerSqftClosedMedian?: number | null;
          pricePerSqftClosedMedianMovingAvg?: number | null;
          pricePerSqftNewListMedian?: number | null;
          pricePerSqftNewListMedianMovingAvg?: number | null;
          pricePerSqftOnMarketMedian?: number | null;
          pricePerSqftOnMarketMedianMovingAvg?: number | null;
          saleToListPriceMedian?: number | null;
          saleToListPriceMedianMovingAvg?: number | null;
          saleToListPriceOriginalMedian?: number | null;
          saleToListPriceOriginalMedianMovingAvg?: number | null;
        } | null;
      } | null;
      hpi?: {
        __typename?: 'ZipHpi';
        timeSeriesCurrent?: DateStr | null;
        forecastCalcs?: {
          __typename?: 'ZipHpiForecastCalcs';
          risk1YearLoss?: number | null;
          return?: {
            __typename?: 'ZipHpiForecastCalcsReturn';
            return1YearForecast?: number | null;
          } | null;
        } | null;
      } | null;
      hcri?: {
        __typename?: 'ZipHcri';
        grossYieldMedian?: number | null;
      } | null;
      rpi?: {
        __typename?: 'ZipRpi';
        timeSeriesCurrent?: DateStr | null;
        forecastCalcs?: Array<{
          __typename?: 'ZipRpiForecastCalcs';
          risk1YearLoss?: number | null;
          return1Year?: number | null;
        } | null> | null;
      } | null;
      marketQuality?: {
        __typename?: 'ZipMarketQuality';
        grade?: MarketGrade | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ZipDetailsQueryVariables = Exact<{
  zipcode?: InputMaybe<Scalars['String']>;
}>;

export type ZipDetailsQuery = {
  __typename?: 'Query';
  lookupZipDetails?: {
    __typename?: 'ZipDetails';
    zipcode?: string | null;
    msa?: string | null;
    preferredName?: string | null;
    listingStats?: {
      __typename?: 'ZipListingStats';
      latestStats?: {
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      } | null;
    } | null;
    hpi?: {
      __typename?: 'ZipHpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: {
        __typename?: 'ZipHpiForecastCalcs';
        risk1YearLoss?: number | null;
        return?: {
          __typename?: 'ZipHpiForecastCalcsReturn';
          return1YearForecast?: number | null;
        } | null;
      } | null;
    } | null;
    hcri?: { __typename?: 'ZipHcri'; grossYieldMedian?: number | null } | null;
    rpi?: {
      __typename?: 'ZipRpi';
      timeSeriesCurrent?: DateStr | null;
      forecastCalcs?: Array<{
        __typename?: 'ZipRpiForecastCalcs';
        risk1YearLoss?: number | null;
        return1Year?: number | null;
      } | null> | null;
    } | null;
    marketQuality?: {
      __typename?: 'ZipMarketQuality';
      grade?: MarketGrade | null;
    } | null;
  } | null;
};

export type ZipListingStatsTimeSeriesQueryVariables = Exact<{
  zipcode?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type ZipListingStatsTimeSeriesQuery = {
  __typename?: 'Query';
  lookupZipDetails?: {
    __typename?: 'ZipDetails';
    listingStats?: {
      __typename?: 'ZipListingStats';
      timeSeries?: Array<{
        __typename?: 'HCListingStats';
        daysOnMarketMedian?: number | null;
        daysOnMarketMedianMovingAvg?: number | null;
        daysOnMarketNotContractMedian?: number | null;
        daysOnMarketNotContractMedianMovingAvg?: number | null;
        daysToCloseMedian?: number | null;
        daysToCloseMedianMovingAvg?: number | null;
        daysToContractMedian?: number | null;
        daysToContractMedianMovingAvg?: number | null;
        daysToFirstPriceDropMedian?: number | null;
        daysToFirstPriceDropMedianMovingAvg?: number | null;
        eventDate?: DateStr | null;
        listingsClosedCount?: number | null;
        listingsClosedCountMovingAvg?: number | null;
        listingsNewCount?: number | null;
        listingsNewCountMovingAvg?: number | null;
        listingsOnMarketCount?: number | null;
        listingsOnMarketCountMovingAvg?: number | null;
        listingsPriceChangeCount?: number | null;
        listingsPriceChangeCountMovingAvg?: number | null;
        listingsPriceDropCount?: number | null;
        listingsPriceDropCountMovingAvg?: number | null;
        listingsPriceDropPercent?: number | null;
        listingsPriceDropPercentMovingAvg?: number | null;
        listingsRemovedCount?: number | null;
        listingsRemovedCountMovingAvg?: number | null;
        listingsRemovedPercent?: number | null;
        listingsRemovedPercentMovingAvg?: number | null;
        listingsUnderContractCount?: number | null;
        listingsUnderContractCountMovingAvg?: number | null;
        listingsUnderContractPercent?: number | null;
        listingsUnderContractPercentMovingAvg?: number | null;
        monthsOfSupplyMedian?: number | null;
        monthsOfSupplyMedianMovingAvg?: number | null;
        priceClosedMedian?: number | null;
        priceClosedMedianMovingAvg?: number | null;
        priceNewListMedian?: number | null;
        priceNewListMedianMovingAvg?: number | null;
        priceOnMarketMedian?: number | null;
        priceOnMarketMedianMovingAvg?: number | null;
        pricePerSqftClosedMedian?: number | null;
        pricePerSqftClosedMedianMovingAvg?: number | null;
        pricePerSqftNewListMedian?: number | null;
        pricePerSqftNewListMedianMovingAvg?: number | null;
        pricePerSqftOnMarketMedian?: number | null;
        pricePerSqftOnMarketMedianMovingAvg?: number | null;
        saleToListPriceMedian?: number | null;
        saleToListPriceMedianMovingAvg?: number | null;
        saleToListPriceOriginalMedian?: number | null;
        saleToListPriceOriginalMedianMovingAvg?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type ZipHpiTimeSeriesQueryVariables = Exact<{
  zipcode?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type ZipHpiTimeSeriesQuery = {
  __typename?: 'Query';
  lookupZipDetails?: {
    __typename?: 'ZipDetails';
    hpi?: {
      __typename?: 'ZipHpi';
      historicalTimeSeries?: Array<{
        __typename?: 'ZipHpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
      forecastTimeSeries?: Array<{
        __typename?: 'ZipHpiTimeSeriesElement';
        month?: DateStr | null;
        valueIndexed?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type MsaHcriCoreFragment = {
  __typename?: 'MsaHcri';
  grossYieldMedian?: number | null;
};

export type ZipHcriCoreFragment = {
  __typename?: 'ZipHcri';
  grossYieldMedian?: number | null;
};

export type MsaHpiCoreFragment = {
  __typename?: 'MsaHpi';
  timeSeriesCurrent?: DateStr | null;
  forecastCalcs?: {
    __typename?: 'MsaHpiForecastCalcs';
    risk1YearLoss?: number | null;
    return?: {
      __typename?: 'MsaHpiForecastCalcsReturn';
      return1YearForecast?: number | null;
    } | null;
  } | null;
};

export type MsaHpiTimeSeriesFragment = {
  __typename?: 'MsaHpiTimeSeriesElement';
  month?: DateStr | null;
  valueIndexed?: number | null;
};

export type ZipHpiCoreFragment = {
  __typename?: 'ZipHpi';
  timeSeriesCurrent?: DateStr | null;
  forecastCalcs?: {
    __typename?: 'ZipHpiForecastCalcs';
    risk1YearLoss?: number | null;
    return?: {
      __typename?: 'ZipHpiForecastCalcsReturn';
      return1YearForecast?: number | null;
    } | null;
  } | null;
};

export type ZipHpiTimeSeriesFragment = {
  __typename?: 'ZipHpiTimeSeriesElement';
  month?: DateStr | null;
  valueIndexed?: number | null;
};

export type ListingStatsFragment = {
  __typename?: 'HCListingStats';
  daysOnMarketMedian?: number | null;
  daysOnMarketMedianMovingAvg?: number | null;
  daysOnMarketNotContractMedian?: number | null;
  daysOnMarketNotContractMedianMovingAvg?: number | null;
  daysToCloseMedian?: number | null;
  daysToCloseMedianMovingAvg?: number | null;
  daysToContractMedian?: number | null;
  daysToContractMedianMovingAvg?: number | null;
  daysToFirstPriceDropMedian?: number | null;
  daysToFirstPriceDropMedianMovingAvg?: number | null;
  eventDate?: DateStr | null;
  listingsClosedCount?: number | null;
  listingsClosedCountMovingAvg?: number | null;
  listingsNewCount?: number | null;
  listingsNewCountMovingAvg?: number | null;
  listingsOnMarketCount?: number | null;
  listingsOnMarketCountMovingAvg?: number | null;
  listingsPriceChangeCount?: number | null;
  listingsPriceChangeCountMovingAvg?: number | null;
  listingsPriceDropCount?: number | null;
  listingsPriceDropCountMovingAvg?: number | null;
  listingsPriceDropPercent?: number | null;
  listingsPriceDropPercentMovingAvg?: number | null;
  listingsRemovedCount?: number | null;
  listingsRemovedCountMovingAvg?: number | null;
  listingsRemovedPercent?: number | null;
  listingsRemovedPercentMovingAvg?: number | null;
  listingsUnderContractCount?: number | null;
  listingsUnderContractCountMovingAvg?: number | null;
  listingsUnderContractPercent?: number | null;
  listingsUnderContractPercentMovingAvg?: number | null;
  monthsOfSupplyMedian?: number | null;
  monthsOfSupplyMedianMovingAvg?: number | null;
  priceClosedMedian?: number | null;
  priceClosedMedianMovingAvg?: number | null;
  priceNewListMedian?: number | null;
  priceNewListMedianMovingAvg?: number | null;
  priceOnMarketMedian?: number | null;
  priceOnMarketMedianMovingAvg?: number | null;
  pricePerSqftClosedMedian?: number | null;
  pricePerSqftClosedMedianMovingAvg?: number | null;
  pricePerSqftNewListMedian?: number | null;
  pricePerSqftNewListMedianMovingAvg?: number | null;
  pricePerSqftOnMarketMedian?: number | null;
  pricePerSqftOnMarketMedianMovingAvg?: number | null;
  saleToListPriceMedian?: number | null;
  saleToListPriceMedianMovingAvg?: number | null;
  saleToListPriceOriginalMedian?: number | null;
  saleToListPriceOriginalMedianMovingAvg?: number | null;
};

export type PopulationCoreFragment = {
  __typename?: 'Population';
  month?: DateStr | null;
  population1YearAgo?: number | null;
  population1YearChange?: number | null;
  population1YearRatio?: number | null;
};

export type PopulationTimeSeriesFragment = {
  __typename?: 'PopulationTimeSeriesElement';
  month?: DateStr | null;
  population?: number | null;
};

export type UnemploymentRateTimeSeriesFragment = {
  __typename?: 'UnemploymentRateTimeSeriesElement';
  month?: DateStr | null;
  unemploymentRate?: number | null;
};

export type MsaRpiCoreFragment = {
  __typename?: 'MsaRpi';
  timeSeriesCurrent?: DateStr | null;
  forecastCalcs?: Array<{
    __typename?: 'MsaRpiForecastCalcs';
    risk1YearLoss?: number | null;
    return1Year?: number | null;
  } | null> | null;
};

export type ZipRpiCoreFragment = {
  __typename?: 'ZipRpi';
  timeSeriesCurrent?: DateStr | null;
  forecastCalcs?: Array<{
    __typename?: 'ZipRpiForecastCalcs';
    risk1YearLoss?: number | null;
    return1Year?: number | null;
  } | null> | null;
};

export type RpiTimeSeriesFragment = {
  __typename?: 'RpiTimeSeriesElement';
  month?: DateStr | null;
  valueIndexed?: number | null;
};

export type ZipCoreFragment = {
  __typename?: 'ZipDetails';
  zipcode?: string | null;
  msa?: string | null;
  preferredName?: string | null;
};

export type ZipGeomFragment = {
  __typename?: 'ZipDetails';
  geometry?: Geometry | null;
  geoLatitude?: number | null;
  geoLongitude?: number | null;
};

export type ZipMarketQualityFragment = {
  __typename?: 'ZipMarketQuality';
  grade?: MarketGrade | null;
};

export type PropertyLocationQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyLocationQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStatePreviewHcQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStatePreviewHcQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      hc?: {
        __typename?: 'PropertyDetailsHc';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    };
    latestListing: {
      __typename?: 'LatestListing';
      sale?: {
        __typename?: 'Listing';
        entityId?: string | null;
        agentOffice?: {
          __typename?: 'ListingAgentOffice';
          hc?: {
            __typename?: 'ListingAgentOfficeHc';
            agentOffice?: Array<{
              __typename?: 'ListingAgentOfficeHcAgentOffice';
              agentKey?: string | null;
              agentMlsId?: string | null;
              email?: string | null;
              licenseNumber?: string | null;
              name?: string | null;
              office?: string | null;
              officeKey?: string | null;
              officeMlsId?: string | null;
              phone?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        id?: {
          __typename?: 'ListingIdcerberus';
          discovery?: {
            __typename?: 'ListingIdcerberusDiscovery';
            hcMlsId?: number | null;
            listingId?: string | null;
          } | null;
        } | null;
        mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
      } | null;
      rental?: {
        __typename?: 'Listing';
        entityId?: string | null;
        agentOffice?: {
          __typename?: 'ListingAgentOffice';
          hc?: {
            __typename?: 'ListingAgentOfficeHc';
            agentOffice?: Array<{
              __typename?: 'ListingAgentOfficeHcAgentOffice';
              agentKey?: string | null;
              agentMlsId?: string | null;
              email?: string | null;
              licenseNumber?: string | null;
              name?: string | null;
              office?: string | null;
              officeKey?: string | null;
              officeMlsId?: string | null;
              phone?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        id?: {
          __typename?: 'ListingIdcerberus';
          discovery?: {
            __typename?: 'ListingIdcerberusDiscovery';
            hcMlsId?: number | null;
            listingId?: string | null;
          } | null;
        } | null;
        mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
      } | null;
    };
    complexFields?: Array<{
      __typename?: 'ComplexFields';
      sale?: {
        __typename?: 'ComplexFieldsSale';
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: DateStr | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
      rental?: {
        __typename?: 'ComplexFieldsRental';
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: string | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type PropertyStateCoreHcQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStateCoreHcQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      hc?: {
        __typename?: 'PropertyDetailsHc';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          apn?: string | null;
          associationAmenities?: string | null;
          associationFeeIncludes?: string | null;
          constructionTypes?: Array<string | null> | null;
          cooling?: Array<string | null> | null;
          hasAssociation?: boolean | null;
          heating?: Array<string | null> | null;
          poolYN?: boolean | null;
          roomsTotal?: number | null;
          storiesNumber?: number | null;
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          association?: Array<{
            __typename?: 'CommonPropertyDetailsHcAssociation';
            fee?: number | null;
            frequency?: string | null;
            name?: string | null;
            phone?: string | null;
          } | null> | null;
          basement?: {
            __typename?: 'CommonPropertyDetailsHcBasement';
            finishedPct?: number | null;
            description?: Array<string | null> | null;
            has?: boolean | null;
            totalArea?: number | null;
          } | null;
          parking?: {
            __typename?: 'CommonPropertyDetailsHcParking';
            total?: number | null;
            garage?: number | null;
            description?: string | null;
            parkingDetails?: Array<{
              __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
              count?: number | null;
              type?: string | null;
            } | null> | null;
          } | null;
          tax?: {
            __typename?: 'CommonPropertyDetailsHcTax';
            amountAnnual?: number | null;
            year?: number | null;
            legalDescription?: string | null;
          } | null;
          zoning?: {
            __typename?: 'CommonPropertyDetailsHcZoning';
            code?: string | null;
          } | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
      blockDetails?: {
        __typename?: 'BlockDetails';
        superFund?: {
          __typename?: 'BlockSuperFund';
          withinMiles0?: {
            __typename?: 'BlockSuperFundWithinMiles';
            count?: number | null;
          } | null;
          withinMiles1?: {
            __typename?: 'BlockSuperFundWithinMiles';
            count?: number | null;
          } | null;
          withinMiles4?: {
            __typename?: 'BlockSuperFundWithinMiles';
            count?: number | null;
          } | null;
        } | null;
        value?: Array<{
          __typename?: 'BlockValue';
          propertyType?: PropertyTypeEnum | null;
          propertyTypeEnum?: PropertyTypeEnum | null;
          distribution?: {
            __typename?: 'BlockValueDistribution';
            valuePerSqft?: {
              __typename?: 'BlockValueDistributionValuePerSqft';
              percentile50?: number | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      msaDetails?: {
        __typename?: 'MsaDetails';
        msa?: string | null;
        name?: string | null;
        zipCount?: number | null;
      } | null;
    };
    complexFields?: Array<{
      __typename?: 'ComplexFields';
      sale?: {
        __typename?: 'ComplexFieldsSale';
        currentHcpyLookupKey?: string | null;
        currentSourceId?: string | null;
        currentDaysOnMarketCumulative?: number | null;
        currentDaysToCloseCumulative?: number | null;
        lastCloseHcMlsId?: number | null;
        lastCloseHcpyLookupKey?: string | null;
        lastCloseListingId?: string | null;
        currentArmsLength?: boolean | null;
        currentDistressed?: boolean | null;
        currentFlipYN?: boolean | null;
        currentListDate?: DateStr | null;
        currentListingPrice?: number | null;
        lastCloseDate?: DateStr | null;
        lastClosePrice?: number | null;
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: DateStr | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
      rental?: {
        __typename?: 'ComplexFieldsRental';
        currentHcpyLookupKey?: string | null;
        currentSourceId?: string | null;
        lastCloseHcMlsId?: number | null;
        lastCloseHcpyLookupKey?: string | null;
        lastCloseListingId?: string | null;
        currentDaysOnMarketCumulative?: number | null;
        currentDaysToCloseCumulative?: number | null;
        currentListDate?: string | null;
        currentListingPrice?: number | null;
        lastCloseSourceId?: string | null;
        lastCloseDate?: string | null;
        lastClosePrice?: number | null;
        lastCloseSourceType?: string | null;
        currentHcMlsId?: number | null;
        currentListingId?: string | null;
        currentPrice?: number | null;
        currentStatus?: string | null;
        currentStatusDate?: string | null;
        currentlyOnMarket?: boolean | null;
        currentDaysOnMarketMLS?: number | null;
      } | null;
    }> | null;
    taxHistory: {
      __typename?: 'TaxHistory';
      hc?: {
        __typename?: 'TaxHistoryHc';
        ownership?: {
          __typename?: 'TaxHistoryHcOwnership';
          ownerOccupied?: boolean | null;
          lastUpdatedDate?: string | null;
        } | null;
      } | null;
    };
    latestListing: {
      __typename?: 'LatestListing';
      sale?: {
        __typename?: 'Listing';
        entityId?: string | null;
        agentOffice?: {
          __typename?: 'ListingAgentOffice';
          hc?: {
            __typename?: 'ListingAgentOfficeHc';
            agentOffice?: Array<{
              __typename?: 'ListingAgentOfficeHcAgentOffice';
              agentKey?: string | null;
              agentMlsId?: string | null;
              email?: string | null;
              licenseNumber?: string | null;
              name?: string | null;
              office?: string | null;
              officeKey?: string | null;
              officeMlsId?: string | null;
              phone?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        listingDetails?: {
          __typename?: 'ListingListingDetails';
          hc?: {
            __typename?: 'ListingListingDetailsHc';
            armsLength?: boolean | null;
            distressed?: boolean | null;
            reo?: boolean | null;
            status?: string | null;
            remarks?: Array<{
              __typename?: 'ListingListingDetailsHcRemarks';
              type?: string | null;
              value?: string | null;
            } | null> | null;
            concessions?: {
              __typename?: 'ListingListingDetailsHcConcessions';
              buyerAmount?: number | null;
              buyerDescription?: string | null;
              sellerAmount?: number | null;
              sellerDescription?: string | null;
            } | null;
          } | null;
          reso?: {
            __typename?: 'ListingListingDetailsReso';
            concessionsAmount?: number | null;
            concessionsComments?: string | null;
            concessions?: string | null;
          } | null;
        } | null;
        id?: {
          __typename?: 'ListingIdcerberus';
          discovery?: {
            __typename?: 'ListingIdcerberusDiscovery';
            hcMlsId?: number | null;
            listingId?: string | null;
          } | null;
        } | null;
        mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
      } | null;
      rental?: {
        __typename?: 'Listing';
        entityId?: string | null;
        agentOffice?: {
          __typename?: 'ListingAgentOffice';
          hc?: {
            __typename?: 'ListingAgentOfficeHc';
            agentOffice?: Array<{
              __typename?: 'ListingAgentOfficeHcAgentOffice';
              agentKey?: string | null;
              agentMlsId?: string | null;
              email?: string | null;
              licenseNumber?: string | null;
              name?: string | null;
              office?: string | null;
              officeKey?: string | null;
              officeMlsId?: string | null;
              phone?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        listingDetails?: {
          __typename?: 'ListingListingDetails';
          hc?: {
            __typename?: 'ListingListingDetailsHc';
            armsLength?: boolean | null;
            distressed?: boolean | null;
            reo?: boolean | null;
            status?: string | null;
            remarks?: Array<{
              __typename?: 'ListingListingDetailsHcRemarks';
              type?: string | null;
              value?: string | null;
            } | null> | null;
            concessions?: {
              __typename?: 'ListingListingDetailsHcConcessions';
              buyerAmount?: number | null;
              buyerDescription?: string | null;
              sellerAmount?: number | null;
              sellerDescription?: string | null;
            } | null;
          } | null;
          reso?: {
            __typename?: 'ListingListingDetailsReso';
            concessionsAmount?: number | null;
            concessionsComments?: string | null;
            concessions?: string | null;
          } | null;
        } | null;
        id?: {
          __typename?: 'ListingIdcerberus';
          discovery?: {
            __typename?: 'ListingIdcerberusDiscovery';
            hcMlsId?: number | null;
            listingId?: string | null;
          } | null;
        } | null;
        mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
      } | null;
    };
    value: {
      __typename?: 'PropertyValue';
      value?: {
        __typename?: 'Value';
        fsd?: number | null;
        fsd90Percent?: number | null;
        value?: number | null;
        valueLower?: number | null;
        valueMedian?: number | null;
        valueUpper?: number | null;
        quality?: AvmQuality | null;
        valueAtSixConditions?: {
          __typename?: 'AVMValueAtSixConditions';
          conditionClass?: number | null;
          neighborhoodConditionClass?: number | null;
          valueAtCondC1?: number | null;
          valueAtCondC2?: number | null;
          valueAtCondC3?: number | null;
          valueAtCondC4?: number | null;
          valueAtCondC5?: number | null;
          valueAtCondC6?: number | null;
        } | null;
      } | null;
      land?: {
        __typename?: 'LandValue';
        valueLand?: number | null;
        valueLandLower?: number | null;
        valueLandPerSqFt?: number | null;
        valueLandUpper?: number | null;
      } | null;
      rental?: {
        __typename?: 'RentalValue';
        fsd?: number | null;
        fsd90Percent?: number | null;
        quality?: AvmQuality | null;
        value?: number | null;
        valueLower?: number | null;
        valueMedian?: number | null;
        valueUpper?: number | null;
        yield?: number | null;
        valueAtSixConditions?: {
          __typename?: 'RentalAVMValueAtSixConditions';
          valueAtCondC1?: number | null;
          valueAtCondC2?: number | null;
          valueAtCondC3?: number | null;
          valueAtCondC4?: number | null;
          valueAtCondC5?: number | null;
          valueAtCondC6?: number | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStateListingQueryVariables = Exact<{
  cerberusListingsInput: CerberusListingsInput;
}>;

export type PropertyStateListingQuery = {
  __typename?: 'Query';
  lookupListings?: {
    __typename?: 'CerberusListingLookupResults';
    listings?: Array<{
      __typename?: 'Listing';
      entityId?: string | null;
      hcAddressId?: number | null;
      id?: {
        __typename?: 'ListingIdcerberus';
        discovery?: {
          __typename?: 'ListingIdcerberusDiscovery';
          hcMlsId?: number | null;
          listingId?: string | null;
        } | null;
      } | null;
      agentOffice?: {
        __typename?: 'ListingAgentOffice';
        hc?: {
          __typename?: 'ListingAgentOfficeHc';
          agentOffice?: Array<{
            __typename?: 'ListingAgentOfficeHcAgentOffice';
            agentKey?: string | null;
            agentMlsId?: string | null;
            email?: string | null;
            licenseNumber?: string | null;
            name?: string | null;
            office?: string | null;
            officeKey?: string | null;
            officeMlsId?: string | null;
            phone?: string | null;
            type?: string | null;
          } | null> | null;
        } | null;
      } | null;
      mls?: {
        __typename?: 'ListingMls';
        hcMlsId?: number | null;
        lastRefreshed?: string | null;
        name?: string | null;
        nameShort?: string | null;
        regulations?: {
          __typename?: 'MlsRegulations';
          augmenting?: boolean | null;
          cancelled?: boolean | null;
          closed?: boolean | null;
          closedLoginPrice?: boolean | null;
          coMingling?: boolean | null;
          comingSoon?: boolean | null;
          contingent?: boolean | null;
          contingentWithKickOut?: boolean | null;
          cooperatingBrokerage?: boolean | null;
          copyright?: string | null;
          deleted?: boolean | null;
          disclaimer?: string | null;
          expired?: boolean | null;
          leased?: boolean | null;
          logo?: string | null;
          logoOverlay?: string | null;
          pending?: boolean | null;
          photosClosedAll?: boolean | null;
          photosClosedFirstOnly?: boolean | null;
          photosClosedHistory?: number | null;
          photosClosedLogin?: boolean | null;
          photosLogoOverlay?: boolean | null;
          sold?: boolean | null;
          unknown?: boolean | null;
          withdrawn?: boolean | null;
        } | null;
      } | null;
      location?: {
        __typename?: 'ListingLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'ListingLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      media?: {
        __typename?: 'ListingMedia';
        hc?: {
          __typename?: 'ListingMediaHc';
          images?: Array<{
            __typename?: 'ListingMediaHcImages';
            order?: number | null;
            type?: string | null;
            url?: string | null;
            derived?: {
              __typename?: 'ListingMediaHcImagesDerived';
              classification?: {
                __typename?: 'ListingMediaHcImagesDerivedImageClassification';
                confidence?: number | null;
                prediction?: number | null;
                predictionDescription?: string | null;
                predictionGroup?: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
      listingDetails?: {
        __typename?: 'ListingListingDetails';
        reso?: {
          __typename?: 'ListingListingDetailsReso';
          listingTerms?: Array<string | null> | null;
        } | null;
        hc?: {
          __typename?: 'ListingListingDetailsHc';
          addressDisplayNo?: boolean | null;
          ageRestricted?: boolean | null;
          armsLength?: boolean | null;
          avmDisplayNo?: boolean | null;
          buyerBrokerageCompensationDisplay?: string | null;
          closeDate?: string | null;
          closePrice?: number | null;
          commentsDisplayNo?: boolean | null;
          currentPrice?: number | null;
          currentSaleStatus?: string | null;
          currentStatusDate?: string | null;
          daysOnMarket?: number | null;
          daysOnMarketCumulative?: number | null;
          daysOnMarketMLS?: number | null;
          daysToClose?: number | null;
          daysToCloseCumulative?: number | null;
          daysToContract?: number | null;
          distressed?: boolean | null;
          flipYN?: boolean | null;
          idxDisplayNo?: boolean | null;
          listPrice?: number | null;
          listPriceOriginal?: number | null;
          listPriceRangeMax?: number | null;
          listPriceRangeMin?: number | null;
          listingDate?: string | null;
          mlsLastUpdate?: string | null;
          mlsName?: string | null;
          rentalAmountFrequency?: string | null;
          rentalLeaseTerm?: string | null;
          rentalListing?: boolean | null;
          rentalLogic?: string | null;
          reo?: boolean | null;
          status?: string | null;
          concessions?: {
            __typename?: 'ListingListingDetailsHcConcessions';
            buyerAmount?: number | null;
            buyerDescription?: string | null;
            sellerAmount?: number | null;
            sellerDescription?: string | null;
          } | null;
          remarks?: Array<{
            __typename?: 'ListingListingDetailsHcRemarks';
            type?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
      propertyDetails?: {
        __typename?: 'ListingPropertyDetails';
        reso?: {
          __typename?: 'ListingPropertyDetailsReso';
          appliances?: Array<string | null> | null;
          architecturalStyle?: Array<string | null> | null;
          constructionMaterials?: Array<string | null> | null;
          exteriorFeatures?: Array<string | null> | null;
          greenEnergyEfficient?: Array<string | null> | null;
          fireplaceFeatures?: Array<string | null> | null;
          fireplacesTotal?: number | null;
          fireplaceYN?: boolean | null;
          foundationDetails?: Array<string | null> | null;
          interiorFeatures?: Array<string | null> | null;
          propertyAttachedYN?: boolean | null;
          yearBuiltEffective?: number | null;
          heating?: Array<string | null> | null;
          cooling?: Array<string | null> | null;
          coolingYN?: boolean | null;
          heatingYN?: boolean | null;
        } | null;
        hc?: {
          __typename?: 'CommonPropertyDetailsHc';
          apn?: string | null;
          associationAmenities?: string | null;
          associationFeeIncludes?: string | null;
          constructionTypes?: Array<string | null> | null;
          cooling?: Array<string | null> | null;
          hasAssociation?: boolean | null;
          heating?: Array<string | null> | null;
          poolYN?: boolean | null;
          roomsTotal?: number | null;
          storiesNumber?: number | null;
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          association?: Array<{
            __typename?: 'CommonPropertyDetailsHcAssociation';
            fee?: number | null;
            frequency?: string | null;
            name?: string | null;
            phone?: string | null;
          } | null> | null;
          basement?: {
            __typename?: 'CommonPropertyDetailsHcBasement';
            finishedPct?: number | null;
            description?: Array<string | null> | null;
            has?: boolean | null;
            totalArea?: number | null;
          } | null;
          parking?: {
            __typename?: 'CommonPropertyDetailsHcParking';
            total?: number | null;
            garage?: number | null;
            description?: string | null;
            parkingDetails?: Array<{
              __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
              count?: number | null;
              type?: string | null;
            } | null> | null;
          } | null;
          tax?: {
            __typename?: 'CommonPropertyDetailsHcTax';
            amountAnnual?: number | null;
            year?: number | null;
            legalDescription?: string | null;
          } | null;
          zoning?: {
            __typename?: 'CommonPropertyDetailsHcZoning';
            code?: string | null;
          } | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type PropertyStateMediaQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStateMediaQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    latestListing: {
      __typename?: 'LatestListing';
      sale?: {
        __typename?: 'Listing';
        entityId?: string | null;
        media?: {
          __typename?: 'ListingMedia';
          hc?: {
            __typename?: 'ListingMediaHc';
            images?: Array<{
              __typename?: 'ListingMediaHcImages';
              order?: number | null;
              type?: string | null;
              url?: string | null;
              derived?: {
                __typename?: 'ListingMediaHcImagesDerived';
                classification?: {
                  __typename?: 'ListingMediaHcImagesDerivedImageClassification';
                  confidence?: number | null;
                  prediction?: number | null;
                  predictionDescription?: string | null;
                  predictionGroup?: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        mls?: {
          __typename?: 'ListingMls';
          nameShort?: string | null;
          hcMlsId?: number | null;
          lastRefreshed?: string | null;
          name?: string | null;
          regulations?: {
            __typename?: 'MlsRegulations';
            augmenting?: boolean | null;
            cancelled?: boolean | null;
            closed?: boolean | null;
            closedLoginPrice?: boolean | null;
            coMingling?: boolean | null;
            comingSoon?: boolean | null;
            contingent?: boolean | null;
            contingentWithKickOut?: boolean | null;
            cooperatingBrokerage?: boolean | null;
            copyright?: string | null;
            deleted?: boolean | null;
            disclaimer?: string | null;
            expired?: boolean | null;
            leased?: boolean | null;
            logo?: string | null;
            logoOverlay?: string | null;
            pending?: boolean | null;
            photosClosedAll?: boolean | null;
            photosClosedFirstOnly?: boolean | null;
            photosClosedHistory?: number | null;
            photosClosedLogin?: boolean | null;
            photosLogoOverlay?: boolean | null;
            sold?: boolean | null;
            unknown?: boolean | null;
            withdrawn?: boolean | null;
          } | null;
        } | null;
        agentOffice?: {
          __typename?: 'ListingAgentOffice';
          hc?: {
            __typename?: 'ListingAgentOfficeHc';
            agentOffice?: Array<{
              __typename?: 'ListingAgentOfficeHcAgentOffice';
              agentKey?: string | null;
              agentMlsId?: string | null;
              email?: string | null;
              licenseNumber?: string | null;
              name?: string | null;
              office?: string | null;
              officeKey?: string | null;
              officeMlsId?: string | null;
              phone?: string | null;
              type?: string | null;
            } | null> | null;
          } | null;
        } | null;
        listingDetails?: {
          __typename?: 'ListingListingDetails';
          hc?: {
            __typename?: 'ListingListingDetailsHc';
            closeDate?: string | null;
            closePrice?: number | null;
            currentPrice?: number | null;
            currentStatusDate?: string | null;
            listPrice?: number | null;
            listingDate?: string | null;
            mlsName?: string | null;
            rentalListing?: boolean | null;
            status?: string | null;
          } | null;
        } | null;
        id?: {
          __typename?: 'ListingIdcerberus';
          discovery?: {
            __typename?: 'ListingIdcerberusDiscovery';
            hcMlsId?: number | null;
            listingId?: string | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStatePreviewMlsQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStatePreviewMlsQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      mls?: {
        __typename?: 'PropertyDetailsMls';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStateCoreMlsQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStateCoreMlsQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      mls?: {
        __typename?: 'PropertyDetailsMls';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          apn?: string | null;
          associationAmenities?: string | null;
          associationFeeIncludes?: string | null;
          constructionTypes?: Array<string | null> | null;
          cooling?: Array<string | null> | null;
          hasAssociation?: boolean | null;
          heating?: Array<string | null> | null;
          poolYN?: boolean | null;
          roomsTotal?: number | null;
          storiesNumber?: number | null;
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          association?: Array<{
            __typename?: 'CommonPropertyDetailsHcAssociation';
            fee?: number | null;
            frequency?: string | null;
            name?: string | null;
            phone?: string | null;
          } | null> | null;
          basement?: {
            __typename?: 'CommonPropertyDetailsHcBasement';
            finishedPct?: number | null;
            description?: Array<string | null> | null;
            has?: boolean | null;
            totalArea?: number | null;
          } | null;
          parking?: {
            __typename?: 'CommonPropertyDetailsHcParking';
            total?: number | null;
            garage?: number | null;
            description?: string | null;
            parkingDetails?: Array<{
              __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
              count?: number | null;
              type?: string | null;
            } | null> | null;
          } | null;
          tax?: {
            __typename?: 'CommonPropertyDetailsHcTax';
            amountAnnual?: number | null;
            year?: number | null;
            legalDescription?: string | null;
          } | null;
          zoning?: {
            __typename?: 'CommonPropertyDetailsHcZoning';
            code?: string | null;
          } | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStatePreviewPrQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStatePreviewPrQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      pr?: {
        __typename?: 'PropertyDetailsPr';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStateCorePrQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyStateCorePrQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    propertyDetails: {
      __typename?: 'PropertyDetails';
      hcAddressId?: number | null;
      location?: {
        __typename?: 'PropertyDetailsLocation';
        hc?: {
          __typename?: 'CommonLocationHc';
          neighborhood?: string | null;
          subdivision?: string | null;
        } | null;
        discovery?: {
          __typename?: 'CommonLocationDiscovery';
          hcAddress?: {
            __typename?: 'CommonLocationDiscoveryHcAddress';
            address?: string | null;
            addressSlug?: string | null;
            blockGroupId?: string | null;
            blockId?: string | null;
            city?: string | null;
            completeYN?: boolean | null;
            county?: string | null;
            fips?: string | null;
            geoPrecision?: GeoPrecision | null;
            latitude?: number | null;
            longitude?: number | null;
            metroDivisionId?: string | null;
            msaId?: string | null;
            realYN?: boolean | null;
            state?: string | null;
            tractId?: string | null;
            unit?: string | null;
            verifiedYN?: boolean | null;
            zipcode?: string | null;
            zipcodePlus4?: string | null;
          } | null;
        } | null;
      } | null;
      pr?: {
        __typename?: 'PropertyDetailsPr';
        property?: {
          __typename?: 'CommonPropertyDetailsHc';
          apn?: string | null;
          associationAmenities?: string | null;
          associationFeeIncludes?: string | null;
          constructionTypes?: Array<string | null> | null;
          cooling?: Array<string | null> | null;
          hasAssociation?: boolean | null;
          heating?: Array<string | null> | null;
          poolYN?: boolean | null;
          roomsTotal?: number | null;
          storiesNumber?: number | null;
          bedrooms?: number | null;
          livingArea?: number | null;
          lotSize?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeDescription?: string | null;
          yearBuilt?: number | null;
          association?: Array<{
            __typename?: 'CommonPropertyDetailsHcAssociation';
            fee?: number | null;
            frequency?: string | null;
            name?: string | null;
            phone?: string | null;
          } | null> | null;
          basement?: {
            __typename?: 'CommonPropertyDetailsHcBasement';
            finishedPct?: number | null;
            description?: Array<string | null> | null;
            has?: boolean | null;
            totalArea?: number | null;
          } | null;
          parking?: {
            __typename?: 'CommonPropertyDetailsHcParking';
            total?: number | null;
            garage?: number | null;
            description?: string | null;
            parkingDetails?: Array<{
              __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
              count?: number | null;
              type?: string | null;
            } | null> | null;
          } | null;
          tax?: {
            __typename?: 'CommonPropertyDetailsHcTax';
            amountAnnual?: number | null;
            year?: number | null;
            legalDescription?: string | null;
          } | null;
          zoning?: {
            __typename?: 'CommonPropertyDetailsHcZoning';
            code?: string | null;
          } | null;
          bathrooms?: {
            __typename?: 'CommonPropertyDetailsHcBathrooms';
            totalProjected?: number | null;
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyStateStrengthOfOfferQueryVariables = Exact<{
  cerberusInput: CerberusInput;
  offerPrice?: InputMaybe<Scalars['Int']>;
}>;

export type PropertyStateStrengthOfOfferQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    latestListing: {
      __typename?: 'LatestListing';
      sale?: {
        __typename?: 'Listing';
        derived?: {
          __typename?: 'ListingDerived';
          hc?: {
            __typename?: 'ListingDerivedHc';
            strengthOfOffer: {
              __typename?: 'StrengthOfOffer';
              fipsProbabilityOfAccept?: number | null;
              msaProbabilityOfAccept?: number | null;
            };
          } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type PropertyTransactionHistoryQueryVariables = Exact<{
  cerberusInput: CerberusInput;
}>;

export type PropertyTransactionHistoryQuery = {
  __typename?: 'Query';
  lookup?: {
    __typename?: 'LookupResults';
    complexFields?: Array<{
      __typename?: 'ComplexFields';
      sale?: {
        __typename?: 'ComplexFieldsSale';
        eventHistory?: Array<{
          __typename?: 'ComplexFieldsSaleEventHistory';
          addressId?: number | null;
          armsLength?: boolean | null;
          date?: DateStr | null;
          daysOnMarketCumulative?: number | null;
          deedDate?: DateStr | null;
          deedPrice?: number | null;
          deedType?: string | null;
          displayStatus?: string | null;
          distressed?: boolean | null;
          documentTimestamp?: string | null;
          entityId?: string | null;
          hcMlsId?: number | null;
          hcpyLookupKey?: string | null;
          listingGroupId?: number | null;
          listingId?: string | null;
          marketValuePrice?: boolean | null;
          mlsTimestamp?: string | null;
          multiParcel?: boolean | null;
          partialInterest?: boolean | null;
          price?: number | null;
          priceChangeLastClose?: number | null;
          priceChangePctLastClose?: number | null;
          priceVariance?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeEnum?: PropertyTypeEnum | null;
          recorderBook?: string | null;
          recorderDocument?: string | null;
          recorderPage?: string | null;
          reo?: boolean | null;
          sourceId?: string | null;
          sourceTimestamp?: string | null;
          sourceType?: string | null;
          status?: string | null;
          transfer?: boolean | null;
        } | null> | null;
      } | null;
      rental?: {
        __typename?: 'ComplexFieldsRental';
        eventHistory?: Array<{
          __typename?: 'ComplexFieldsRentalEventHistory';
          addressId?: number | null;
          armsLength?: boolean | null;
          date?: string | null;
          daysOnMarketCumulative?: number | null;
          displayStatus?: string | null;
          distressed?: boolean | null;
          documentTimestamp?: string | null;
          entityId?: string | null;
          hcMlsId?: number | null;
          listingId?: string | null;
          listingGroupId?: number | null;
          mlsTimestamp?: string | null;
          price?: number | null;
          priceChangeLastClose?: number | null;
          priceChangePctLastClose?: number | null;
          priceVariance?: number | null;
          propertyType?: PropertyTypeEnum | null;
          propertyTypeEnum?: PropertyTypeEnum | null;
          reo?: boolean | null;
          sourceId?: string | null;
          sourceTimestamp?: string | null;
          sourceType?: string | null;
          status?: string | null;
        } | null> | null;
      } | null;
    }> | null;
  } | null;
};

export type BlockDetailsCoreFragment = {
  __typename?: 'BlockDetails';
  superFund?: {
    __typename?: 'BlockSuperFund';
    withinMiles0?: {
      __typename?: 'BlockSuperFundWithinMiles';
      count?: number | null;
    } | null;
    withinMiles1?: {
      __typename?: 'BlockSuperFundWithinMiles';
      count?: number | null;
    } | null;
    withinMiles4?: {
      __typename?: 'BlockSuperFundWithinMiles';
      count?: number | null;
    } | null;
  } | null;
  value?: Array<{
    __typename?: 'BlockValue';
    propertyType?: PropertyTypeEnum | null;
    propertyTypeEnum?: PropertyTypeEnum | null;
    distribution?: {
      __typename?: 'BlockValueDistribution';
      valuePerSqft?: {
        __typename?: 'BlockValueDistributionValuePerSqft';
        percentile50?: number | null;
      } | null;
    } | null;
  }> | null;
};

export type TransactionHistoryEventSaleFragment = {
  __typename?: 'ComplexFieldsSaleEventHistory';
  addressId?: number | null;
  armsLength?: boolean | null;
  date?: DateStr | null;
  daysOnMarketCumulative?: number | null;
  deedDate?: DateStr | null;
  deedPrice?: number | null;
  deedType?: string | null;
  displayStatus?: string | null;
  distressed?: boolean | null;
  documentTimestamp?: string | null;
  entityId?: string | null;
  hcMlsId?: number | null;
  hcpyLookupKey?: string | null;
  listingGroupId?: number | null;
  listingId?: string | null;
  marketValuePrice?: boolean | null;
  mlsTimestamp?: string | null;
  multiParcel?: boolean | null;
  partialInterest?: boolean | null;
  price?: number | null;
  priceChangeLastClose?: number | null;
  priceChangePctLastClose?: number | null;
  priceVariance?: number | null;
  propertyType?: PropertyTypeEnum | null;
  propertyTypeEnum?: PropertyTypeEnum | null;
  recorderBook?: string | null;
  recorderDocument?: string | null;
  recorderPage?: string | null;
  reo?: boolean | null;
  sourceId?: string | null;
  sourceTimestamp?: string | null;
  sourceType?: string | null;
  status?: string | null;
  transfer?: boolean | null;
};

export type TransactionHistoryEventRentalFragment = {
  __typename?: 'ComplexFieldsRentalEventHistory';
  addressId?: number | null;
  armsLength?: boolean | null;
  date?: string | null;
  daysOnMarketCumulative?: number | null;
  displayStatus?: string | null;
  distressed?: boolean | null;
  documentTimestamp?: string | null;
  entityId?: string | null;
  hcMlsId?: number | null;
  listingId?: string | null;
  listingGroupId?: number | null;
  mlsTimestamp?: string | null;
  price?: number | null;
  priceChangeLastClose?: number | null;
  priceChangePctLastClose?: number | null;
  priceVariance?: number | null;
  propertyType?: PropertyTypeEnum | null;
  propertyTypeEnum?: PropertyTypeEnum | null;
  reo?: boolean | null;
  sourceId?: string | null;
  sourceTimestamp?: string | null;
  sourceType?: string | null;
  status?: string | null;
};

export type ComplexFieldsRentalPreviewFragment = {
  __typename?: 'ComplexFieldsRental';
  currentHcMlsId?: number | null;
  currentListingId?: string | null;
  currentPrice?: number | null;
  currentStatus?: string | null;
  currentStatusDate?: string | null;
  currentlyOnMarket?: boolean | null;
  currentDaysOnMarketMLS?: number | null;
};

export type ComplexFieldsRentalCoreFragment = {
  __typename?: 'ComplexFieldsRental';
  currentHcpyLookupKey?: string | null;
  currentSourceId?: string | null;
  lastCloseHcMlsId?: number | null;
  lastCloseHcpyLookupKey?: string | null;
  lastCloseListingId?: string | null;
  currentDaysOnMarketCumulative?: number | null;
  currentDaysToCloseCumulative?: number | null;
  currentListDate?: string | null;
  currentListingPrice?: number | null;
  lastCloseSourceId?: string | null;
  lastCloseDate?: string | null;
  lastClosePrice?: number | null;
  lastCloseSourceType?: string | null;
  currentHcMlsId?: number | null;
  currentListingId?: string | null;
  currentPrice?: number | null;
  currentStatus?: string | null;
  currentStatusDate?: string | null;
  currentlyOnMarket?: boolean | null;
  currentDaysOnMarketMLS?: number | null;
};

export type ComplexFieldsSalePreviewFragment = {
  __typename?: 'ComplexFieldsSale';
  currentHcMlsId?: number | null;
  currentListingId?: string | null;
  currentPrice?: number | null;
  currentStatus?: string | null;
  currentStatusDate?: DateStr | null;
  currentlyOnMarket?: boolean | null;
  currentDaysOnMarketMLS?: number | null;
};

export type ComplexFieldsSaleCoreFragment = {
  __typename?: 'ComplexFieldsSale';
  currentHcpyLookupKey?: string | null;
  currentSourceId?: string | null;
  currentDaysOnMarketCumulative?: number | null;
  currentDaysToCloseCumulative?: number | null;
  lastCloseHcMlsId?: number | null;
  lastCloseHcpyLookupKey?: string | null;
  lastCloseListingId?: string | null;
  currentArmsLength?: boolean | null;
  currentDistressed?: boolean | null;
  currentFlipYN?: boolean | null;
  currentListDate?: DateStr | null;
  currentListingPrice?: number | null;
  lastCloseDate?: DateStr | null;
  lastClosePrice?: number | null;
  currentHcMlsId?: number | null;
  currentListingId?: string | null;
  currentPrice?: number | null;
  currentStatus?: string | null;
  currentStatusDate?: DateStr | null;
  currentlyOnMarket?: boolean | null;
  currentDaysOnMarketMLS?: number | null;
};

export type LandCoreFragment = {
  __typename?: 'LandValue';
  valueLand?: number | null;
  valueLandLower?: number | null;
  valueLandPerSqFt?: number | null;
  valueLandUpper?: number | null;
};

export type ListingDetailsDiscoveryIdsFragment = {
  __typename?: 'ListingIdcerberusDiscovery';
  hcMlsId?: number | null;
  listingId?: string | null;
};

export type ListingDetailsMetadataFragment = {
  __typename?: 'Listing';
  entityId?: string | null;
  id?: {
    __typename?: 'ListingIdcerberus';
    discovery?: {
      __typename?: 'ListingIdcerberusDiscovery';
      hcMlsId?: number | null;
      listingId?: string | null;
    } | null;
  } | null;
  mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
};

export type AgentOfficeFragment = {
  __typename?: 'ListingAgentOfficeHcAgentOffice';
  agentKey?: string | null;
  agentMlsId?: string | null;
  email?: string | null;
  licenseNumber?: string | null;
  name?: string | null;
  office?: string | null;
  officeKey?: string | null;
  officeMlsId?: string | null;
  phone?: string | null;
  type?: string | null;
};

export type ListingDetailsPreviewFragment = {
  __typename?: 'Listing';
  entityId?: string | null;
  agentOffice?: {
    __typename?: 'ListingAgentOffice';
    hc?: {
      __typename?: 'ListingAgentOfficeHc';
      agentOffice?: Array<{
        __typename?: 'ListingAgentOfficeHcAgentOffice';
        agentKey?: string | null;
        agentMlsId?: string | null;
        email?: string | null;
        licenseNumber?: string | null;
        name?: string | null;
        office?: string | null;
        officeKey?: string | null;
        officeMlsId?: string | null;
        phone?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
  id?: {
    __typename?: 'ListingIdcerberus';
    discovery?: {
      __typename?: 'ListingIdcerberusDiscovery';
      hcMlsId?: number | null;
      listingId?: string | null;
    } | null;
  } | null;
  mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
};

export type ListingListingDetailsMediaFragment = {
  __typename?: 'ListingListingDetailsHc';
  closeDate?: string | null;
  closePrice?: number | null;
  currentPrice?: number | null;
  currentStatusDate?: string | null;
  listPrice?: number | null;
  listingDate?: string | null;
  mlsName?: string | null;
  rentalListing?: boolean | null;
  status?: string | null;
};

export type ListingDetailsMediaFragment = {
  __typename?: 'Listing';
  entityId?: string | null;
  mls?: {
    __typename?: 'ListingMls';
    nameShort?: string | null;
    hcMlsId?: number | null;
    lastRefreshed?: string | null;
    name?: string | null;
    regulations?: {
      __typename?: 'MlsRegulations';
      augmenting?: boolean | null;
      cancelled?: boolean | null;
      closed?: boolean | null;
      closedLoginPrice?: boolean | null;
      coMingling?: boolean | null;
      comingSoon?: boolean | null;
      contingent?: boolean | null;
      contingentWithKickOut?: boolean | null;
      cooperatingBrokerage?: boolean | null;
      copyright?: string | null;
      deleted?: boolean | null;
      disclaimer?: string | null;
      expired?: boolean | null;
      leased?: boolean | null;
      logo?: string | null;
      logoOverlay?: string | null;
      pending?: boolean | null;
      photosClosedAll?: boolean | null;
      photosClosedFirstOnly?: boolean | null;
      photosClosedHistory?: number | null;
      photosClosedLogin?: boolean | null;
      photosLogoOverlay?: boolean | null;
      sold?: boolean | null;
      unknown?: boolean | null;
      withdrawn?: boolean | null;
    } | null;
  } | null;
  agentOffice?: {
    __typename?: 'ListingAgentOffice';
    hc?: {
      __typename?: 'ListingAgentOfficeHc';
      agentOffice?: Array<{
        __typename?: 'ListingAgentOfficeHcAgentOffice';
        agentKey?: string | null;
        agentMlsId?: string | null;
        email?: string | null;
        licenseNumber?: string | null;
        name?: string | null;
        office?: string | null;
        officeKey?: string | null;
        officeMlsId?: string | null;
        phone?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
  listingDetails?: {
    __typename?: 'ListingListingDetails';
    hc?: {
      __typename?: 'ListingListingDetailsHc';
      closeDate?: string | null;
      closePrice?: number | null;
      currentPrice?: number | null;
      currentStatusDate?: string | null;
      listPrice?: number | null;
      listingDate?: string | null;
      mlsName?: string | null;
      rentalListing?: boolean | null;
      status?: string | null;
    } | null;
  } | null;
  id?: {
    __typename?: 'ListingIdcerberus';
    discovery?: {
      __typename?: 'ListingIdcerberusDiscovery';
      hcMlsId?: number | null;
      listingId?: string | null;
    } | null;
  } | null;
};

export type ListingSaleTypeFragment = {
  __typename?: 'ListingListingDetailsHc';
  armsLength?: boolean | null;
  distressed?: boolean | null;
  reo?: boolean | null;
  status?: string | null;
};

export type ListingListingDetailsCoreFragment = {
  __typename?: 'ListingListingDetailsHc';
  armsLength?: boolean | null;
  distressed?: boolean | null;
  reo?: boolean | null;
  status?: string | null;
  remarks?: Array<{
    __typename?: 'ListingListingDetailsHcRemarks';
    type?: string | null;
    value?: string | null;
  } | null> | null;
  concessions?: {
    __typename?: 'ListingListingDetailsHcConcessions';
    buyerAmount?: number | null;
    buyerDescription?: string | null;
    sellerAmount?: number | null;
    sellerDescription?: string | null;
  } | null;
};

export type ListingResoDetailsCoreFragment = {
  __typename?: 'ListingListingDetailsReso';
  concessionsAmount?: number | null;
  concessionsComments?: string | null;
  concessions?: string | null;
};

export type ListingDetailsCoreFragment = {
  __typename?: 'Listing';
  entityId?: string | null;
  agentOffice?: {
    __typename?: 'ListingAgentOffice';
    hc?: {
      __typename?: 'ListingAgentOfficeHc';
      agentOffice?: Array<{
        __typename?: 'ListingAgentOfficeHcAgentOffice';
        agentKey?: string | null;
        agentMlsId?: string | null;
        email?: string | null;
        licenseNumber?: string | null;
        name?: string | null;
        office?: string | null;
        officeKey?: string | null;
        officeMlsId?: string | null;
        phone?: string | null;
        type?: string | null;
      } | null> | null;
    } | null;
  } | null;
  listingDetails?: {
    __typename?: 'ListingListingDetails';
    hc?: {
      __typename?: 'ListingListingDetailsHc';
      armsLength?: boolean | null;
      distressed?: boolean | null;
      reo?: boolean | null;
      status?: string | null;
      remarks?: Array<{
        __typename?: 'ListingListingDetailsHcRemarks';
        type?: string | null;
        value?: string | null;
      } | null> | null;
      concessions?: {
        __typename?: 'ListingListingDetailsHcConcessions';
        buyerAmount?: number | null;
        buyerDescription?: string | null;
        sellerAmount?: number | null;
        sellerDescription?: string | null;
      } | null;
    } | null;
    reso?: {
      __typename?: 'ListingListingDetailsReso';
      concessionsAmount?: number | null;
      concessionsComments?: string | null;
      concessions?: string | null;
    } | null;
  } | null;
  id?: {
    __typename?: 'ListingIdcerberus';
    discovery?: {
      __typename?: 'ListingIdcerberusDiscovery';
      hcMlsId?: number | null;
      listingId?: string | null;
    } | null;
  } | null;
  mls?: { __typename?: 'ListingMls'; nameShort?: string | null } | null;
};

export type ListingImageFragment = {
  __typename?: 'ListingMediaHcImages';
  order?: number | null;
  type?: string | null;
  url?: string | null;
  derived?: {
    __typename?: 'ListingMediaHcImagesDerived';
    classification?: {
      __typename?: 'ListingMediaHcImagesDerivedImageClassification';
      confidence?: number | null;
      prediction?: number | null;
      predictionDescription?: string | null;
      predictionGroup?: string | null;
    } | null;
  } | null;
};

export type ListingMediaFragment = {
  __typename?: 'ListingMediaHc';
  images?: Array<{
    __typename?: 'ListingMediaHcImages';
    order?: number | null;
    type?: string | null;
    url?: string | null;
    derived?: {
      __typename?: 'ListingMediaHcImagesDerived';
      classification?: {
        __typename?: 'ListingMediaHcImagesDerivedImageClassification';
        confidence?: number | null;
        prediction?: number | null;
        predictionDescription?: string | null;
        predictionGroup?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type MlsRegulationsFragmentFragment = {
  __typename?: 'MlsRegulations';
  augmenting?: boolean | null;
  cancelled?: boolean | null;
  closed?: boolean | null;
  closedLoginPrice?: boolean | null;
  coMingling?: boolean | null;
  comingSoon?: boolean | null;
  contingent?: boolean | null;
  contingentWithKickOut?: boolean | null;
  cooperatingBrokerage?: boolean | null;
  copyright?: string | null;
  deleted?: boolean | null;
  disclaimer?: string | null;
  expired?: boolean | null;
  leased?: boolean | null;
  logo?: string | null;
  logoOverlay?: string | null;
  pending?: boolean | null;
  photosClosedAll?: boolean | null;
  photosClosedFirstOnly?: boolean | null;
  photosClosedHistory?: number | null;
  photosClosedLogin?: boolean | null;
  photosLogoOverlay?: boolean | null;
  sold?: boolean | null;
  unknown?: boolean | null;
  withdrawn?: boolean | null;
};

export type ListingMlsFragmentFragment = {
  __typename?: 'ListingMls';
  hcMlsId?: number | null;
  lastRefreshed?: string | null;
  name?: string | null;
  nameShort?: string | null;
  regulations?: {
    __typename?: 'MlsRegulations';
    augmenting?: boolean | null;
    cancelled?: boolean | null;
    closed?: boolean | null;
    closedLoginPrice?: boolean | null;
    coMingling?: boolean | null;
    comingSoon?: boolean | null;
    contingent?: boolean | null;
    contingentWithKickOut?: boolean | null;
    cooperatingBrokerage?: boolean | null;
    copyright?: string | null;
    deleted?: boolean | null;
    disclaimer?: string | null;
    expired?: boolean | null;
    leased?: boolean | null;
    logo?: string | null;
    logoOverlay?: string | null;
    pending?: boolean | null;
    photosClosedAll?: boolean | null;
    photosClosedFirstOnly?: boolean | null;
    photosClosedHistory?: number | null;
    photosClosedLogin?: boolean | null;
    photosLogoOverlay?: boolean | null;
    sold?: boolean | null;
    unknown?: boolean | null;
    withdrawn?: boolean | null;
  } | null;
};

export type MsaCoreFragment = {
  __typename?: 'MsaDetails';
  msa?: string | null;
  name?: string | null;
  zipCount?: number | null;
};

export type BathroomsPreviewFragment = {
  __typename?: 'CommonPropertyDetailsHcBathrooms';
  totalProjected?: number | null;
};

export type BasementCoreFragment = {
  __typename?: 'CommonPropertyDetailsHcBasement';
  finishedPct?: number | null;
  description?: Array<string | null> | null;
  has?: boolean | null;
  totalArea?: number | null;
};

export type ParkingCoreFragment = {
  __typename?: 'CommonPropertyDetailsHcParking';
  total?: number | null;
  garage?: number | null;
  description?: string | null;
  parkingDetails?: Array<{
    __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
    count?: number | null;
    type?: string | null;
  } | null> | null;
};

export type TaxCoreFragment = {
  __typename?: 'CommonPropertyDetailsHcTax';
  amountAnnual?: number | null;
  year?: number | null;
  legalDescription?: string | null;
};

export type ZoningCoreFragment = {
  __typename?: 'CommonPropertyDetailsHcZoning';
  code?: string | null;
};

export type PropertyDetailsPreviewFragment = {
  __typename?: 'CommonPropertyDetailsHc';
  bedrooms?: number | null;
  livingArea?: number | null;
  lotSize?: number | null;
  propertyType?: PropertyTypeEnum | null;
  propertyTypeDescription?: string | null;
  yearBuilt?: number | null;
  bathrooms?: {
    __typename?: 'CommonPropertyDetailsHcBathrooms';
    totalProjected?: number | null;
  } | null;
};

export type PropertyDetailsCoreFragment = {
  __typename?: 'CommonPropertyDetailsHc';
  apn?: string | null;
  associationAmenities?: string | null;
  associationFeeIncludes?: string | null;
  constructionTypes?: Array<string | null> | null;
  cooling?: Array<string | null> | null;
  hasAssociation?: boolean | null;
  heating?: Array<string | null> | null;
  poolYN?: boolean | null;
  roomsTotal?: number | null;
  storiesNumber?: number | null;
  bedrooms?: number | null;
  livingArea?: number | null;
  lotSize?: number | null;
  propertyType?: PropertyTypeEnum | null;
  propertyTypeDescription?: string | null;
  yearBuilt?: number | null;
  association?: Array<{
    __typename?: 'CommonPropertyDetailsHcAssociation';
    fee?: number | null;
    frequency?: string | null;
    name?: string | null;
    phone?: string | null;
  } | null> | null;
  basement?: {
    __typename?: 'CommonPropertyDetailsHcBasement';
    finishedPct?: number | null;
    description?: Array<string | null> | null;
    has?: boolean | null;
    totalArea?: number | null;
  } | null;
  parking?: {
    __typename?: 'CommonPropertyDetailsHcParking';
    total?: number | null;
    garage?: number | null;
    description?: string | null;
    parkingDetails?: Array<{
      __typename?: 'CommonPropertyDetailsHcParkingParkingDetails';
      count?: number | null;
      type?: string | null;
    } | null> | null;
  } | null;
  tax?: {
    __typename?: 'CommonPropertyDetailsHcTax';
    amountAnnual?: number | null;
    year?: number | null;
    legalDescription?: string | null;
  } | null;
  zoning?: {
    __typename?: 'CommonPropertyDetailsHcZoning';
    code?: string | null;
  } | null;
  bathrooms?: {
    __typename?: 'CommonPropertyDetailsHcBathrooms';
    totalProjected?: number | null;
  } | null;
};

export type PropertyLocationDiscoveryFragment = {
  __typename?: 'CommonLocationDiscoveryHcAddress';
  address?: string | null;
  addressSlug?: string | null;
  blockGroupId?: string | null;
  blockId?: string | null;
  city?: string | null;
  completeYN?: boolean | null;
  county?: string | null;
  fips?: string | null;
  geoPrecision?: GeoPrecision | null;
  latitude?: number | null;
  longitude?: number | null;
  metroDivisionId?: string | null;
  msaId?: string | null;
  realYN?: boolean | null;
  state?: string | null;
  tractId?: string | null;
  unit?: string | null;
  verifiedYN?: boolean | null;
  zipcode?: string | null;
  zipcodePlus4?: string | null;
};

export type PropertyLocationHcFragment = {
  __typename?: 'CommonLocationHc';
  neighborhood?: string | null;
  subdivision?: string | null;
};

export type PropertyLocationFragment = {
  __typename?: 'PropertyDetailsLocation';
  hc?: {
    __typename?: 'CommonLocationHc';
    neighborhood?: string | null;
    subdivision?: string | null;
  } | null;
  discovery?: {
    __typename?: 'CommonLocationDiscovery';
    hcAddress?: {
      __typename?: 'CommonLocationDiscoveryHcAddress';
      address?: string | null;
      addressSlug?: string | null;
      blockGroupId?: string | null;
      blockId?: string | null;
      city?: string | null;
      completeYN?: boolean | null;
      county?: string | null;
      fips?: string | null;
      geoPrecision?: GeoPrecision | null;
      latitude?: number | null;
      longitude?: number | null;
      metroDivisionId?: string | null;
      msaId?: string | null;
      realYN?: boolean | null;
      state?: string | null;
      tractId?: string | null;
      unit?: string | null;
      verifiedYN?: boolean | null;
      zipcode?: string | null;
      zipcodePlus4?: string | null;
    } | null;
  } | null;
};

export type ValueCoreFragment = {
  __typename?: 'Value';
  fsd?: number | null;
  fsd90Percent?: number | null;
  value?: number | null;
  valueLower?: number | null;
  valueMedian?: number | null;
  valueUpper?: number | null;
  quality?: AvmQuality | null;
  valueAtSixConditions?: {
    __typename?: 'AVMValueAtSixConditions';
    conditionClass?: number | null;
    neighborhoodConditionClass?: number | null;
    valueAtCondC1?: number | null;
    valueAtCondC2?: number | null;
    valueAtCondC3?: number | null;
    valueAtCondC4?: number | null;
    valueAtCondC5?: number | null;
    valueAtCondC6?: number | null;
  } | null;
};

export type RentalValueCoreFragment = {
  __typename?: 'RentalValue';
  fsd?: number | null;
  fsd90Percent?: number | null;
  quality?: AvmQuality | null;
  value?: number | null;
  valueLower?: number | null;
  valueMedian?: number | null;
  valueUpper?: number | null;
  yield?: number | null;
  valueAtSixConditions?: {
    __typename?: 'RentalAVMValueAtSixConditions';
    valueAtCondC1?: number | null;
    valueAtCondC2?: number | null;
    valueAtCondC3?: number | null;
    valueAtCondC4?: number | null;
    valueAtCondC5?: number | null;
    valueAtCondC6?: number | null;
  } | null;
};

export type PropertyValueRentalCoreFragment = {
  __typename?: 'RentalValue';
  condition?: number | null;
  fsd?: number | null;
  fsd90Percent?: number | null;
  value?: number | null;
  valueLower?: number | null;
  valueLowerMin?: number | null;
  valueMedian?: number | null;
  valueUpper?: number | null;
  valueUpperMax?: number | null;
};

export type ResoPropertyDetailsFragment = {
  __typename?: 'ListingPropertyDetailsReso';
  appliances?: Array<string | null> | null;
  architecturalStyle?: Array<string | null> | null;
  constructionMaterials?: Array<string | null> | null;
  exteriorFeatures?: Array<string | null> | null;
  greenEnergyEfficient?: Array<string | null> | null;
  fireplaceFeatures?: Array<string | null> | null;
  fireplacesTotal?: number | null;
  fireplaceYN?: boolean | null;
  foundationDetails?: Array<string | null> | null;
  interiorFeatures?: Array<string | null> | null;
  propertyAttachedYN?: boolean | null;
  yearBuiltEffective?: number | null;
  heating?: Array<string | null> | null;
  cooling?: Array<string | null> | null;
  coolingYN?: boolean | null;
  heatingYN?: boolean | null;
};

export type ResoListingDetailsFragment = {
  __typename?: 'ListingListingDetailsReso';
  listingTerms?: Array<string | null> | null;
};

export type TaxHistoryOwnershipCoreFragment = {
  __typename?: 'TaxHistoryHcOwnership';
  ownerOccupied?: boolean | null;
  lastUpdatedDate?: string | null;
};

export type TaxHistoryCoreFragment = {
  __typename?: 'TaxHistoryHc';
  ownership?: {
    __typename?: 'TaxHistoryHcOwnership';
    ownerOccupied?: boolean | null;
    lastUpdatedDate?: string | null;
  } | null;
};

export type MlsLookupQueryVariables = Exact<{
  cerberusMlsIdentifierInput: CerberusMlsIdentifierInput;
}>;

export type MlsLookupQuery = {
  __typename?: 'Query';
  lookupMls?: {
    __typename?: 'ListingMls';
    hcMlsId?: number | null;
    name?: string | null;
    nameShort?: string | null;
    lastRefreshed?: string | null;
    stateMainDisplay?: Array<string | null> | null;
    regulations?: {
      __typename?: 'MlsRegulations';
      augmenting?: boolean | null;
      cancelled?: boolean | null;
      closed?: boolean | null;
      closedLoginPrice?: boolean | null;
      coMingling?: boolean | null;
      comingSoon?: boolean | null;
      contingent?: boolean | null;
      contingentWithKickOut?: boolean | null;
      cooperatingBrokerage?: boolean | null;
      copyright?: string | null;
      deleted?: boolean | null;
      disclaimer?: string | null;
      expired?: boolean | null;
      leased?: boolean | null;
      logo?: string | null;
      logoOverlay?: string | null;
      pending?: boolean | null;
      photosClosedAll?: boolean | null;
      photosClosedFirstOnly?: boolean | null;
      photosClosedHistory?: number | null;
      photosClosedLogin?: boolean | null;
      photosLogoOverlay?: boolean | null;
      sold?: boolean | null;
      unknown?: boolean | null;
      withdrawn?: boolean | null;
    } | null;
    fips?: Array<{
      __typename?: 'ListingMlsFips';
      id?: string | null;
    } | null> | null;
  } | null;
};

export type PlaceLookupQueryVariables = Exact<{
  placeIdInput: PlaceIdInput;
}>;

export type PlaceLookupQuery = {
  __typename?: 'Query';
  lookupPlace?: {
    __typename?: 'PlaceLookupResult';
    place?: {
      __typename?: 'Place';
      countyName?: string | null;
      formattedAddress?: string | null;
      locality?: string | null;
      name?: string | null;
      placeId?: string | null;
      placeType?: PlaceType | null;
      region?: string | null;
      shape?: Geometry | null;
      altNames?: Array<string | null> | null;
      fips?: Array<string | null> | null;
      location?: {
        __typename?: 'PlaceLocation';
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      mlsCoverage?: {
        __typename?: 'MlsCoverageLevel';
        level?: number | null;
      } | null;
      viewport?: {
        __typename?: 'PlaceViewport';
        northeastLatitude?: number | null;
        northeastLongitude?: number | null;
        southwestLatitude?: number | null;
        southwestLongitude?: number | null;
      } | null;
    } | null;
  } | null;
};

export type PlacesSearchQueryVariables = Exact<{
  placeTermInput: PlaceTermInput;
}>;

export type PlacesSearchQuery = {
  __typename?: 'Query';
  searchPlace?: {
    __typename?: 'AutocompleteResults';
    places?: Array<{
      __typename?: 'PlaceDetails';
      description?: string | null;
      placeId?: string | null;
      placeType?: PlaceType | null;
      location?: {
        __typename?: 'PlaceLocation';
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      viewport?: {
        __typename?: 'PlaceViewport';
        northeastLatitude?: number | null;
        northeastLongitude?: number | null;
        southwestLatitude?: number | null;
        southwestLongitude?: number | null;
      } | null;
    }> | null;
  } | null;
};

export const PropertyLocationHcFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLocationHc' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonLocationHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'neighborhood' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subdivision' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyLocationHcFragment, unknown>;
export const PropertyLocationDiscoveryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLocationDiscovery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonLocationDiscoveryHcAddress' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completeYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'county' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fips' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geoPrecision' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metroDivisionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'realYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tractId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verifiedYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipcodePlus4' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyLocationDiscoveryFragment, unknown>;
export const PropertyLocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLocation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PropertyDetailsLocation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hc' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PropertyLocationHc' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discovery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hcAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PropertyLocationDiscovery',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationHcFragmentDoc.definitions,
    ...PropertyLocationDiscoveryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PropertyLocationFragment, unknown>;
export const ComplexFieldsSalePreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplexFieldsSalePreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currentHcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentListingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentStatusDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentlyOnMarket' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysOnMarketMLS' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComplexFieldsSalePreviewFragment, unknown>;
export const ComplexFieldsSaleCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplexFieldsSaleCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsSale' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ComplexFieldsSalePreview' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentHcpyLookupKey' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currentSourceId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysOnMarketCumulative' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysToCloseCumulative' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCloseHcMlsId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCloseHcpyLookupKey' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCloseListingId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currentArmsLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentDistressed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentFlipYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentListDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentListingPrice' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCloseDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastClosePrice' } },
        ],
      },
    },
    ...ComplexFieldsSalePreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ComplexFieldsSaleCoreFragment, unknown>;
export const ComplexFieldsRentalPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplexFieldsRentalPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsRental' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'currentHcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentListingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentStatusDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentlyOnMarket' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysOnMarketMLS' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComplexFieldsRentalPreviewFragment, unknown>;
export const PropertySpatialDetailResultsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertySpatialDetailResults' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SpatialSearchDetailResults' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'atEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complexFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsSaleCore',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsRentalPreview',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...ComplexFieldsSaleCoreFragmentDoc.definitions,
    ...ComplexFieldsRentalPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PropertySpatialDetailResultsFragment, unknown>;
export const MsaHcriCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MsaHcriCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MsaHcri' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'grossYieldMedian' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaHcriCoreFragment, unknown>;
export const ZipHcriCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipHcriCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipHcri' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'grossYieldMedian' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipHcriCoreFragment, unknown>;
export const MsaHpiCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MsaHpiCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MsaHpi' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forecastCalcs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'risk1YearLoss' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'return' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'return1YearForecast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSeriesCurrent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaHpiCoreFragment, unknown>;
export const MsaHpiTimeSeriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MsaHpiTimeSeries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MsaHpiTimeSeriesElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueIndexed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaHpiTimeSeriesFragment, unknown>;
export const ZipHpiCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipHpiCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipHpi' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forecastCalcs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'risk1YearLoss' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'return' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'return1YearForecast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSeriesCurrent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipHpiCoreFragment, unknown>;
export const ZipHpiTimeSeriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipHpiTimeSeries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipHpiTimeSeriesElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueIndexed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipHpiTimeSeriesFragment, unknown>;
export const ListingStatsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingStats' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HCListingStats' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysOnMarketMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysOnMarketMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysOnMarketNotContractMedian' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'daysOnMarketNotContractMedianMovingAvg',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'daysToCloseMedian' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysToCloseMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysToContractMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysToContractMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysToFirstPriceDropMedian' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'daysToFirstPriceDropMedianMovingAvg',
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'eventDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsClosedCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsClosedCountMovingAvg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'listingsNewCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsNewCountMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsOnMarketCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsOnMarketCountMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceChangeCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceChangeCountMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceDropCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceDropCountMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceDropPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsPriceDropPercentMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsRemovedCount' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsRemovedCountMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsRemovedPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsRemovedPercentMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsUnderContractCount' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'listingsUnderContractCountMovingAvg',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingsUnderContractPercent' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'listingsUnderContractPercentMovingAvg',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsOfSupplyMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsOfSupplyMedianMovingAvg' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'priceClosedMedian' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceClosedMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceNewListMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceNewListMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceOnMarketMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceOnMarketMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerSqftClosedMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerSqftClosedMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerSqftNewListMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerSqftNewListMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerSqftOnMarketMedian' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'pricePerSqftOnMarketMedianMovingAvg',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saleToListPriceMedian' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saleToListPriceMedianMovingAvg' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saleToListPriceOriginalMedian' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'saleToListPriceOriginalMedianMovingAvg',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingStatsFragment, unknown>;
export const PopulationCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PopulationCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Population' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'population1YearAgo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'population1YearChange' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'population1YearRatio' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PopulationCoreFragment, unknown>;
export const PopulationTimeSeriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PopulationTimeSeries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PopulationTimeSeriesElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'population' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PopulationTimeSeriesFragment, unknown>;
export const UnemploymentRateTimeSeriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UnemploymentRateTimeSeries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnemploymentRateTimeSeriesElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unemploymentRate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnemploymentRateTimeSeriesFragment, unknown>;
export const MsaRpiCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MsaRpiCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MsaRpi' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forecastCalcs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyType' },
                value: { kind: 'EnumValue', value: 'SINGLE_FAMILY_DETACHED' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'risk1YearLoss' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'return1Year' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSeriesCurrent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaRpiCoreFragment, unknown>;
export const ZipRpiCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipRpiCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipRpi' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forecastCalcs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'propertyType' },
                value: { kind: 'EnumValue', value: 'SINGLE_FAMILY_DETACHED' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'risk1YearLoss' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'return1Year' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeSeriesCurrent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipRpiCoreFragment, unknown>;
export const RpiTimeSeriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RpiTimeSeries' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RpiTimeSeriesElement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'month' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueIndexed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpiTimeSeriesFragment, unknown>;
export const ZipCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipCoreFragment, unknown>;
export const ZipGeomFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipGeom' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'geometry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geoLatitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'geoLongitude' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ZipGeomFragment, unknown>;
export const ZipMarketQualityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZipMarketQuality' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ZipMarketQuality' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'grade' } }],
      },
    },
  ],
} as unknown as DocumentNode<ZipMarketQualityFragment, unknown>;
export const BlockDetailsCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BlockDetailsCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BlockDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'superFund' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withinMiles0' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withinMiles1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'withinMiles4' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'value' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyTypeEnum' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'distribution' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valuePerSqft' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'percentile50' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlockDetailsCoreFragment, unknown>;
export const TransactionHistoryEventSaleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionHistoryEventSale' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsSaleEventHistory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'armsLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysOnMarketCumulative' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'deedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deedPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deedType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distressed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hcpyLookupKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketValuePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mlsTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'multiParcel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partialInterest' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceChangeLastClose' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceChangePctLastClose' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'priceVariance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyTypeEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recorderBook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recorderDocument' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recorderPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transfer' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionHistoryEventSaleFragment, unknown>;
export const TransactionHistoryEventRentalFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TransactionHistoryEventRental' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsRentalEventHistory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'addressId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'armsLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daysOnMarketCumulative' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'displayStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distressed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'documentTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingGroupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mlsTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceChangeLastClose' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceChangePctLastClose' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'priceVariance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyTypeEnum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionHistoryEventRentalFragment, unknown>;
export const ComplexFieldsRentalCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ComplexFieldsRentalCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ComplexFieldsRental' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ComplexFieldsRentalPreview' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentHcpyLookupKey' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currentSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCloseHcMlsId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCloseHcpyLookupKey' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCloseListingId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysOnMarketCumulative' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentDaysToCloseCumulative' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currentListDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentListingPrice' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCloseSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastCloseDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastClosePrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastCloseSourceType' },
          },
        ],
      },
    },
    ...ComplexFieldsRentalPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ComplexFieldsRentalCoreFragment, unknown>;
export const LandCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LandValue' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'valueLand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLandLower' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLandPerSqFt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLandUpper' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LandCoreFragment, unknown>;
export const ListingDetailsDiscoveryIdsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingDetailsDiscoveryIds' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingIdcerberusDiscovery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingDetailsDiscoveryIdsFragment, unknown>;
export const ListingDetailsMetadataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingDetailsMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Listing' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discovery' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ListingDetailsDiscoveryIds',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nameShort' } },
              ],
            },
          },
        ],
      },
    },
    ...ListingDetailsDiscoveryIdsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingDetailsMetadataFragment, unknown>;
export const AgentOfficeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentOffice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingAgentOfficeHcAgentOffice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'office' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officeKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officeMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentOfficeFragment, unknown>;
export const ListingDetailsPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingDetailsPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Listing' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ListingDetailsMetadata' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agentOffice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AgentOffice' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingDetailsMetadataFragmentDoc.definitions,
    ...AgentOfficeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingDetailsPreviewFragment, unknown>;
export const MlsRegulationsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MlsRegulationsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MlsRegulations' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'augmenting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closedLoginPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coMingling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comingSoon' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contingent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contingentWithKickOut' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cooperatingBrokerage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'copyright' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'disclaimer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leased' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoOverlay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pending' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photosClosedAll' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photosClosedFirstOnly' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photosClosedHistory' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'photosClosedLogin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photosLogoOverlay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unknown' } },
          { kind: 'Field', name: { kind: 'Name', value: 'withdrawn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MlsRegulationsFragmentFragment, unknown>;
export const ListingMlsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingMlsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingMls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hcMlsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastRefreshed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nameShort' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regulations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MlsRegulationsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MlsRegulationsFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingMlsFragmentFragment, unknown>;
export const ListingListingDetailsMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingListingDetailsMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingListingDetailsHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'closeDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closePrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentStatusDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mlsName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentalListing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingListingDetailsMediaFragment, unknown>;
export const ListingDetailsMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingDetailsMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Listing' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ListingDetailsMetadata' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ListingMlsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agentOffice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AgentOffice' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ListingListingDetailsMedia',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingDetailsMetadataFragmentDoc.definitions,
    ...ListingMlsFragmentFragmentDoc.definitions,
    ...AgentOfficeFragmentDoc.definitions,
    ...ListingListingDetailsMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingDetailsMediaFragment, unknown>;
export const ListingSaleTypeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingSaleType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingListingDetailsHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'armsLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distressed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingSaleTypeFragment, unknown>;
export const ListingListingDetailsCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingListingDetailsCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingListingDetailsHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ListingSaleType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remarks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'concessions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'buyerAmount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'buyerDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sellerAmount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sellerDescription' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingSaleTypeFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingListingDetailsCoreFragment, unknown>;
export const ListingResoDetailsCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingResoDetailsCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingListingDetailsReso' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'concessionsAmount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'concessionsComments' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'concessions' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingResoDetailsCoreFragment, unknown>;
export const ListingDetailsCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingDetailsCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Listing' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ListingDetailsMetadata' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentOffice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agentOffice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AgentOffice' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ListingListingDetailsCore',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reso' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ListingResoDetailsCore' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingDetailsMetadataFragmentDoc.definitions,
    ...AgentOfficeFragmentDoc.definitions,
    ...ListingListingDetailsCoreFragmentDoc.definitions,
    ...ListingResoDetailsCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingDetailsCoreFragment, unknown>;
export const ListingImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingMediaHcImages' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'derived' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'classification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confidence' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prediction' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'predictionDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'predictionGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingImageFragment, unknown>;
export const ListingMediaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ListingMedia' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingMediaHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ListingImage' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingImageFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ListingMediaFragment, unknown>;
export const MsaCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MsaCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MsaDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'msa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaCoreFragment, unknown>;
export const BathroomsPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BathroomsPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHcBathrooms' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'totalProjected' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BathroomsPreviewFragment, unknown>;
export const PropertyDetailsPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bathrooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BathroomsPreview' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lotSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyTypeDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yearBuilt' } },
        ],
      },
    },
    ...BathroomsPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PropertyDetailsPreviewFragment, unknown>;
export const BasementCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BasementCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHcBasement' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'finishedPct' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalArea' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasementCoreFragment, unknown>;
export const ParkingCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParkingCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHcParking' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parkingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParkingCoreFragment, unknown>;
export const TaxCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaxCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHcTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amountAnnual' } },
          { kind: 'Field', name: { kind: 'Name', value: 'year' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaxCoreFragment, unknown>;
export const ZoningCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ZoningCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHcZoning' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
      },
    },
  ],
} as unknown as DocumentNode<ZoningCoreFragment, unknown>;
export const PropertyDetailsCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyDetailsCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CommonPropertyDetailsHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'PropertyDetailsPreview' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'apn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'association' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associationAmenities' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associationFeeIncludes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BasementCore' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'constructionTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cooling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasAssociation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ParkingCore' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'poolYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storiesNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tax' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TaxCore' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoning' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ZoningCore' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyDetailsPreviewFragmentDoc.definitions,
    ...BasementCoreFragmentDoc.definitions,
    ...ParkingCoreFragmentDoc.definitions,
    ...TaxCoreFragmentDoc.definitions,
    ...ZoningCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PropertyDetailsCoreFragment, unknown>;
export const ValueCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ValueCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Value' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fsd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fsd90Percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLower' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueMedian' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueUpper' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valueAtSixConditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'conditionClass' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'neighborhoodConditionClass' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC3' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC5' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC6' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValueCoreFragment, unknown>;
export const RentalValueCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RentalValueCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RentalValue' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fsd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fsd90Percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLower' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueMedian' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueUpper' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'valueAtSixConditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC1' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC3' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC4' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC5' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAtCondC6' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'yield' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RentalValueCoreFragment, unknown>;
export const PropertyValueRentalCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyValueRentalCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RentalValue' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fsd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fsd90Percent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLower' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueLowerMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueMedian' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueUpper' } },
          { kind: 'Field', name: { kind: 'Name', value: 'valueUpperMax' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyValueRentalCoreFragment, unknown>;
export const ResoPropertyDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResoPropertyDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingPropertyDetailsReso' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'appliances' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'architecturalStyle' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'constructionMaterials' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exteriorFeatures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'greenEnergyEfficient' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fireplaceFeatures' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fireplacesTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fireplaceYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'foundationDetails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interiorFeatures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyAttachedYN' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearBuiltEffective' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'heating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cooling' } },
          { kind: 'Field', name: { kind: 'Name', value: 'coolingYN' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heatingYN' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResoPropertyDetailsFragment, unknown>;
export const ResoListingDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResoListingDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ListingListingDetailsReso' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'listingTerms' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResoListingDetailsFragment, unknown>;
export const TaxHistoryOwnershipCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaxHistoryOwnershipCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TaxHistoryHcOwnership' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ownerOccupied' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedDate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaxHistoryOwnershipCoreFragment, unknown>;
export const TaxHistoryCoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaxHistoryCore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TaxHistoryHc' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownership' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TaxHistoryOwnershipCore' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TaxHistoryOwnershipCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TaxHistoryCoreFragment, unknown>;
export const PropertySpatialSearchListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertySpatialSearchList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SpatialSortPropertiesIdentifierInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SpatialSortInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spatialFilters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SpatialSortFiltersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySpatialESSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paginate' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'cursor' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spatialFilters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'spatialFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SpatialSearchDetailResults' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PropertySpatialDetailResults',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertySpatialDetailResultsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertySpatialSearchListQuery,
  PropertySpatialSearchListQueryVariables
>;
export const PropertySpatialSearchMapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertySpatialSearchMap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SpatialSortPropertiesIdentifierInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spatialFilters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SpatialSortFiltersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySpatialESSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countLimit' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'EnumValue', value: 'AUTO' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spatialFilters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'spatialFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SpatialSearchDetailResults' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PropertySpatialDetailResults',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SpatialSearchCountResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelLocation' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'DICT' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'SpatialSearchOverCountResult',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moreThan' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelLocation' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'format' },
                            value: { kind: 'EnumValue', value: 'DICT' },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertySpatialDetailResultsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertySpatialSearchMapQuery,
  PropertySpatialSearchMapQueryVariables
>;
export const PropertySpatialSearchCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertySpatialSearchCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SpatialSortPropertiesIdentifierInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spatialFilters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SpatialSortFiltersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertySpatialESSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'paginate' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spatialFilters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'spatialFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SpatialSearchCountResult' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'SpatialSearchOverCountResult',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moreThan' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertySpatialSearchCountQuery,
  PropertySpatialSearchCountQueryVariables
>;
export const AllMsaDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllMsaDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allMsaDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rentalListingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hpi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaHpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hcri' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaHcriCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rpi' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'propertyType' },
                      value: {
                        kind: 'EnumValue',
                        value: 'SINGLE_FAMILY_DETACHED',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaRpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'populationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'population' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PopulationCore' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...ListingStatsFragmentDoc.definitions,
    ...MsaHpiCoreFragmentDoc.definitions,
    ...MsaHcriCoreFragmentDoc.definitions,
    ...MsaRpiCoreFragmentDoc.definitions,
    ...PopulationCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AllMsaDetailsQuery, AllMsaDetailsQueryVariables>;
export const MsaDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rentalListingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hpi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaHpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hcri' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaHcriCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rpi' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'propertyType' },
                      value: {
                        kind: 'EnumValue',
                        value: 'SINGLE_FAMILY_DETACHED',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MsaRpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'populationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'population' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PopulationCore' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...ListingStatsFragmentDoc.definitions,
    ...MsaHpiCoreFragmentDoc.definitions,
    ...MsaHcriCoreFragmentDoc.definitions,
    ...MsaRpiCoreFragmentDoc.definitions,
    ...PopulationCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MsaDetailsQuery, MsaDetailsQueryVariables>;
export const MsaListingStatsTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaListingStatsTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rentalListingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...ListingStatsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MsaListingStatsTimeSeriesQuery,
  MsaListingStatsTimeSeriesQueryVariables
>;
export const MsaHpiTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaHpiTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hpi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'historicalTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'HISTORICAL' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MsaHpiTimeSeries' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'forecastTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'FORECAST' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MsaHpiTimeSeries' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...MsaHpiTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MsaHpiTimeSeriesQuery,
  MsaHpiTimeSeriesQueryVariables
>;
export const MsaRpiTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaRpiTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rpi' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'propertyType' },
                      value: {
                        kind: 'EnumValue',
                        value: 'SINGLE_FAMILY_DETACHED',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'historicalTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'HISTORICAL' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'propertyType' },
                            value: {
                              kind: 'EnumValue',
                              value: 'SINGLE_FAMILY_DETACHED',
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RpiTimeSeries' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'forecastTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'FORECAST' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'propertyType' },
                            value: {
                              kind: 'EnumValue',
                              value: 'SINGLE_FAMILY_DETACHED',
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RpiTimeSeries' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...RpiTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MsaRpiTimeSeriesQuery,
  MsaRpiTimeSeriesQueryVariables
>;
export const ZipRpiTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZipRpiTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipcode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupZipDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipcode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ZipCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rpi' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'propertyType' },
                      value: {
                        kind: 'EnumValue',
                        value: 'SINGLE_FAMILY_DETACHED',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'historicalTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'HISTORICAL' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'propertyType' },
                            value: {
                              kind: 'EnumValue',
                              value: 'SINGLE_FAMILY_DETACHED',
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RpiTimeSeries' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'forecastTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'FORECAST' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'propertyType' },
                            value: {
                              kind: 'EnumValue',
                              value: 'SINGLE_FAMILY_DETACHED',
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RpiTimeSeries' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ZipCoreFragmentDoc.definitions,
    ...RpiTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ZipRpiTimeSeriesQuery,
  ZipRpiTimeSeriesQueryVariables
>;
export const MsaUnemploymentTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaUnemploymentTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'populationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unemploymentRate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeSeries' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'fromDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'toDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'ASCENDING',
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UnemploymentRateTimeSeries',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...UnemploymentRateTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MsaUnemploymentTimeSeriesQuery,
  MsaUnemploymentTimeSeriesQueryVariables
>;
export const MsaPopulationTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaPopulationTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MsaCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'populationStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'population' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeSeries' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'fromDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fromDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'toDate' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'toDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'order' },
                                  value: {
                                    kind: 'EnumValue',
                                    value: 'ASCENDING',
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PopulationTimeSeries',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MsaCoreFragmentDoc.definitions,
    ...PopulationTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  MsaPopulationTimeSeriesQuery,
  MsaPopulationTimeSeriesQueryVariables
>;
export const MsaZipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MsaZips' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'zips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MsaZipsQuery, MsaZipsQueryVariables>;
export const ZipGeomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZipGeom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipcode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupZipDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipcode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ZipCore' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ZipGeom' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ZipCoreFragmentDoc.definitions,
    ...ZipGeomFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ZipGeomQuery, ZipGeomQueryVariables>;
export const AllMsaZipDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllMsaZipDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'msaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMsaDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msaId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msaId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'zips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ZipCore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listingStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latestStats' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ListingStats',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hpi' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipHpiCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcri' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipHcriCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rpi' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'propertyType' },
                            value: {
                              kind: 'EnumValue',
                              value: 'SINGLE_FAMILY_DETACHED',
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipRpiCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'marketQuality' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipMarketQuality' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ZipCoreFragmentDoc.definitions,
    ...ListingStatsFragmentDoc.definitions,
    ...ZipHpiCoreFragmentDoc.definitions,
    ...ZipHcriCoreFragmentDoc.definitions,
    ...ZipRpiCoreFragmentDoc.definitions,
    ...ZipMarketQualityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AllMsaZipDetailsQuery,
  AllMsaZipDetailsQueryVariables
>;
export const ZipDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZipDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipcode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupZipDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipcode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ZipCore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hpi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ZipHpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hcri' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ZipHcriCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rpi' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'propertyType' },
                      value: {
                        kind: 'EnumValue',
                        value: 'SINGLE_FAMILY_DETACHED',
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ZipRpiCore' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketQuality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ZipMarketQuality' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ZipCoreFragmentDoc.definitions,
    ...ListingStatsFragmentDoc.definitions,
    ...ZipHpiCoreFragmentDoc.definitions,
    ...ZipHcriCoreFragmentDoc.definitions,
    ...ZipRpiCoreFragmentDoc.definitions,
    ...ZipMarketQualityFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ZipDetailsQuery, ZipDetailsQueryVariables>;
export const ZipListingStatsTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZipListingStatsTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipcode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupZipDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipcode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listingStats' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ListingStats' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingStatsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ZipListingStatsTimeSeriesQuery,
  ZipListingStatsTimeSeriesQueryVariables
>;
export const ZipHpiTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZipHpiTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipcode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupZipDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'zipcode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zipcode' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hpi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'historicalTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'HISTORICAL' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipHpiTimeSeries' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'forecastTimeSeries' },
                        name: { kind: 'Name', value: 'timeSeries' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'seriesType' },
                            value: { kind: 'EnumValue', value: 'FORECAST' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fromDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fromDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'toDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'toDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'ASCENDING' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ZipHpiTimeSeries' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ZipHpiTimeSeriesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ZipHpiTimeSeriesQuery,
  ZipHpiTimeSeriesQueryVariables
>;
export const PropertyLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyLocationQuery,
  PropertyLocationQueryVariables
>;
export const PropertyStatePreviewHcDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStatePreviewHc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hc' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsPreview',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestListing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingDetailsPreview',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingDetailsPreview',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complexFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsSalePreview',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsRentalPreview',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsPreviewFragmentDoc.definitions,
    ...ListingDetailsPreviewFragmentDoc.definitions,
    ...ComplexFieldsSalePreviewFragmentDoc.definitions,
    ...ComplexFieldsRentalPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStatePreviewHcQuery,
  PropertyStatePreviewHcQueryVariables
>;
export const PropertyStateCoreHcDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateCoreHc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hc' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsCore',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blockDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'BlockDetailsCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'msaDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MsaCore' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complexFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsSaleCore',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ComplexFieldsRentalCore',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hc' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TaxHistoryCore' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestListing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingDetailsCore',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingDetailsCore',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ValueCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'land' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'LandCore' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'RentalValueCore' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsCoreFragmentDoc.definitions,
    ...BlockDetailsCoreFragmentDoc.definitions,
    ...MsaCoreFragmentDoc.definitions,
    ...ComplexFieldsSaleCoreFragmentDoc.definitions,
    ...ComplexFieldsRentalCoreFragmentDoc.definitions,
    ...TaxHistoryCoreFragmentDoc.definitions,
    ...ListingDetailsCoreFragmentDoc.definitions,
    ...ValueCoreFragmentDoc.definitions,
    ...LandCoreFragmentDoc.definitions,
    ...RentalValueCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStateCoreHcQuery,
  PropertyStateCoreHcQueryVariables
>;
export const PropertyStateListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusListingsInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusListingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupListings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusListingsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discovery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hcMlsId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'listingId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agentOffice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hc' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'agentOffice',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AgentOffice',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entityId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingMlsFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hc' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyLocationHc',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discovery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hcAddress' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PropertyLocationDiscovery',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hc' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ListingMedia',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listingDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reso' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ResoListingDetails',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hc' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressDisplayNo',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ageRestricted',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'armsLength' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'avmDisplayNo',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'buyerBrokerageCompensationDisplay',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'closeDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'closePrice' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'commentsDisplayNo',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'concessions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'buyerAmount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'buyerDescription',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sellerAmount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sellerDescription',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currentPrice',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currentSaleStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currentStatusDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysOnMarket',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysOnMarketCumulative',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysOnMarketMLS',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysToClose',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysToCloseCumulative',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'daysToContract',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'distressed' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'flipYN' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'idxDisplayNo',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'listPrice' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'listPriceOriginal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'listPriceRangeMax',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'listPriceRangeMin',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'listingDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mlsLastUpdate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mlsName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'remarks' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rentalAmountFrequency',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rentalLeaseTerm',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rentalListing',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'rentalLogic',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reo' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reso' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ResoPropertyDetails',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hc' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsCore',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AgentOfficeFragmentDoc.definitions,
    ...ListingMlsFragmentFragmentDoc.definitions,
    ...PropertyLocationHcFragmentDoc.definitions,
    ...PropertyLocationDiscoveryFragmentDoc.definitions,
    ...ListingMediaFragmentDoc.definitions,
    ...ResoListingDetailsFragmentDoc.definitions,
    ...ResoPropertyDetailsFragmentDoc.definitions,
    ...PropertyDetailsCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStateListingQuery,
  PropertyStateListingQueryVariables
>;
export const PropertyStateMediaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestListing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ListingDetailsMedia',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'media' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hc' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'ListingMedia',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ListingDetailsMediaFragmentDoc.definitions,
    ...ListingMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStateMediaQuery,
  PropertyStateMediaQueryVariables
>;
export const PropertyStatePreviewMlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStatePreviewMls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsPreview',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStatePreviewMlsQuery,
  PropertyStatePreviewMlsQueryVariables
>;
export const PropertyStateCoreMlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateCoreMls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsCore',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStateCoreMlsQuery,
  PropertyStateCoreMlsQueryVariables
>;
export const PropertyStatePreviewPrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStatePreviewPr' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pr' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsPreview',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsPreviewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStatePreviewPrQuery,
  PropertyStatePreviewPrQueryVariables
>;
export const PropertyStateCorePrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateCorePr' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'propertyDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hcAddressId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PropertyLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pr' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'property' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PropertyDetailsCore',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PropertyLocationFragmentDoc.definitions,
    ...PropertyDetailsCoreFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyStateCorePrQuery,
  PropertyStateCorePrQueryVariables
>;
export const PropertyStateStrengthOfOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyStateStrengthOfOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offerPrice' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestListing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'derived' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hc' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'strengthOfOffer',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'offerPrice',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'offerPrice',
                                                },
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'fipsProbabilityOfAccept',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'msaProbabilityOfAccept',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PropertyStateStrengthOfOfferQuery,
  PropertyStateStrengthOfOfferQueryVariables
>;
export const PropertyTransactionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyTransactionHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'complexFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventHistory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'TransactionHistoryEventSale',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rental' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eventHistory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'TransactionHistoryEventRental',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TransactionHistoryEventSaleFragmentDoc.definitions,
    ...TransactionHistoryEventRentalFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PropertyTransactionHistoryQuery,
  PropertyTransactionHistoryQueryVariables
>;
export const MlsLookupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MlsLookup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cerberusMlsIdentifierInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CerberusMlsIdentifierInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupMls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cerberusMlsIdentifierInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'hcMlsId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameShort' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regulations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'augmenting' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cancelled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closedLoginPrice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coMingling' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comingSoon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contingent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contingentWithKickOut' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cooperatingBrokerage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'copyright' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'disclaimer' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expired' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leased' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoOverlay' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pending' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photosClosedAll' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photosClosedFirstOnly' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photosClosedHistory' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photosClosedLogin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photosLogoOverlay' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sold' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unknown' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'withdrawn' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastRefreshed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stateMainDisplay' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MlsLookupQuery, MlsLookupQueryVariables>;
export const PlaceLookupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlaceLookup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'placeIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PlaceIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lookupPlace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'placeIdInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'place' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locality' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'shape' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'altNames' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'fips' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mlsCoverage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewport' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'northeastLatitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'northeastLongitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'southwestLatitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'southwestLongitude',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlaceLookupQuery, PlaceLookupQueryVariables>;
export const PlacesSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlacesSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'placeTermInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PlaceTermInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPlace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'placeTermInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'places' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'latitude' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longitude' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewport' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'northeastLatitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'northeastLongitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'southwestLatitude',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'southwestLongitude',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlacesSearchQuery, PlacesSearchQueryVariables>;
