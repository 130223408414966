import { useEffect } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { EngagementEventData } from '@hcs/types';

import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';

export const useRegisterEngagementTrackingDataWhileMounted = (
  eventDataId: string,
  event_data: EngagementEventData | undefined,
) => {
  const componentId = useComponentInternalId();
  const {
    actions: { registerEventData, deregisterEventData },
  } = useEngagementTrackingEventDataSlice();
  useEffect(() => {
    if (event_data) {
      registerEventData({
        componentId,
        eventDataId,
        event_data,
      });
    }
    return () => {
      deregisterEventData({
        componentId,
        eventDataId,
      });
    };
  }, [
    event_data,
    componentId,
    eventDataId,
    registerEventData,
    deregisterEventData,
  ]);
};
