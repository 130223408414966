import { useEffect, useState } from 'react';

export enum ScreenSizes {
  Desktop = 'desktop',
  Laptop = 'laptop',
  Tablet = 'tablet',
  Phone = 'phone',
}

const MEDIA_QUERIES: [ScreenSizes, string][] = [
  [ScreenSizes.Desktop, 'width >= 1440px'],
  [ScreenSizes.Laptop, '(width >= 1024px) and (width < 1440px)'],
  [ScreenSizes.Tablet, '(width >= 768px) and (width < 1024px)'],
  [ScreenSizes.Phone, 'width < 768px'],
];

// Returns the current screen size of the browser as defined by MEDIA_QUERIES
// This is useful for rendering decisions based on the screen size BUT we should
// always prefer to use CSS media queries when possible
export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizes | null>(null);

  useEffect(() => {
    const handlers: Map<MediaQueryList, (mql: MediaQueryListEvent) => void> =
      new Map();
    for (const [size, query] of MEDIA_QUERIES) {
      const mql = window.matchMedia(`(${query})`);
      const handler = (mql: MediaQueryList | MediaQueryListEvent) => {
        if (mql.matches) {
          setScreenSize(size);
        }
      };
      mql.addEventListener('change', handler);
      handler(mql);
      handlers.set(mql, handler);
    }

    return () => {
      handlers.forEach((handler, mql) => {
        mql.removeEventListener('change', handler);
      });
    };
  }, []);

  return {
    screenSize,
    isDesktopWidth: screenSize === ScreenSizes.Desktop,
    isLaptopWidth: screenSize === ScreenSizes.Laptop,
    isMobileWidth:
      screenSize === ScreenSizes.Phone || screenSize === ScreenSizes.Tablet,
    isPhoneWidth: screenSize === ScreenSizes.Phone,
  };
};
