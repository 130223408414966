import { MutationOptions, useMutation } from '@tanstack/react-query';

import { DexpEvent } from '@hcs/types';

import { DataExplorerApi } from '../api/dataExplorer.api';

export const useDexpCreateJobFromUpload = (
  mutationOptions?: MutationOptions<DexpEvent, Error, FormData, unknown>,
) => {
  return useMutation(DataExplorerApi.uploadAndCreateJob, mutationOptions);
};
