import { useState } from 'react';

import { Anchor } from '@hcs/design-system';

import { SS_CHECKOUT_SESSION_PARAM } from '../../constants/selfService.constants';
import { useSelfServiceCheckout } from '../../hooks';
import { useSelfServicePlan } from '../../hooks/useSelfServicePlan';
import { StripeRedirect } from '../StripeRedirect';

const dataHcName = 'signup-checkout';
interface CheckoutProps {
  planId: string;
  navigateToSelectPlan: VoidFunction;
  cancelUrl: string;
  successUrl: string;
}

const _addParamsToUrls = (
  url: string,
  urlParams: Record<string, string | null> | null,
) => {
  const urlInstance = new URL(url);
  // Add utm params to the success/cancel urls to preserve them in engagement tracking when redirecting back
  for (const [key, value] of Object.entries(urlParams || {})) {
    if (value != null) {
      urlInstance.searchParams.set(key, value);
    }
  }
  return urlInstance.toString();
};

export const SignUpCheckout = ({
  planId,
  cancelUrl,
  successUrl,
  navigateToSelectPlan,
}: CheckoutProps) => {
  const { data: plan } = useSelfServicePlan(planId || undefined);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { isError, mutate: checkoutMutation } = useSelfServiceCheckout({
    onSuccess: ({ url }) => {
      setRedirectUrl(url || null);
    },
  });
  // do not redirect to checkout until we're sure the planId is valid
  if (!plan) return null;
  return (
    <StripeRedirect
      redirectUrl={redirectUrl}
      subtitle="We're taking you to our payment provider to complete checkout"
      onRequestUrl={() => {
        checkoutMutation({
          plan_id: plan.id,
          cancel_url: cancelUrl,
          success_url: _addParamsToUrls(successUrl, {
            [SS_CHECKOUT_SESSION_PARAM]: 'true',
          }),
        });
      }}
      error={
        isError
          ? {
              subtitle: (
                <>
                  We could not redirect you to our payment provider to complete
                  checkout.
                  <br /> We've been alerted about the issue.
                </>
              ),
              nextAction: (
                <>
                  You can try again by{' '}
                  <Anchor
                    dataHcName={`${dataHcName}-reselect-plan`}
                    onClick={navigateToSelectPlan}
                  >
                    reselecting a plan
                  </Anchor>
                  , or you can contact support using the circular blue chat
                  button in the bottom right-hand corner of the screen.
                </>
              ),
            }
          : undefined
      }
    />
  );
};
