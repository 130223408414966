import React from 'react';
import classNames from 'classnames';

import { TextButton } from '@hcs/design-system';
import { Tooltip } from '@hcs/design-system';
import { TableCell } from '@hcs/design-system';
import { DownloadIcon } from '@hcs/design-system';
import { ErrorIcon } from '@hcs/design-system';
import { ChevronRightSmallIcon } from '@hcs/design-system';
import { useUrlState } from '@hcs/hooks';
import { DexpJob, DexpJobStatus, MeaningfulEventTypes } from '@hcs/types';
import { formatDateLong, formatNumber, formatPercent } from '@hcs/utils';

import { ANALYSIS_RESULTS_URL_STATE_KEY } from '../../constants';
import { useDownloadDexpJob } from '../../hooks/useDownloadDexpJob';
import { dataHcEventSectionDexpErrorSummary } from '../AnalysisResultsErrorSummary';

import styles from './AnalysisResults.module.css';

interface TableDataProps {
  dexpJob: DexpJob;
  isTableCell: boolean;
}

export const analysisResultsTableData: {
  colName: string;
  key: string;
  headerCellAlign?: 'left' | 'right' | 'center';
  Component: ({ dexpJob }: TableDataProps) => JSX.Element;
}[] = [
  {
    colName: 'File name',
    key: 'file-name',
    Component: ({ dexpJob }: TableDataProps) => (
      <TableCell className={styles.TableCell}>{dexpJob.fileName}</TableCell>
    ),
  },
  {
    colName: 'Run by',
    key: 'run-by',
    Component: ({ dexpJob }: TableDataProps) => (
      <TableCell className={styles.TableCell}>{dexpJob.ownerEmail}</TableCell>
    ),
  },
  {
    colName: 'Run on',
    key: 'run-on',
    Component: ({ dexpJob }: TableDataProps) => (
      <TableCell className={styles.TableCell}>
        {formatDateLong(dexpJob.createdAt)}
      </TableCell>
    ),
  },
  {
    colName: 'Submitted',
    key: 'submitted',
    Component: ({ dexpJob }: TableDataProps) => (
      <TableCell className={styles.TableCell} align="center">
        {dexpJob.addressesCount}
      </TableCell>
    ),
  },
  {
    colName: 'Success rate',
    key: 'success-rate',
    Component: ({ dexpJob }: TableDataProps) => (
      <TableCell className={styles.TableCell} align="center">
        {dexpJob.status === DexpJobStatus.Error
          ? null
          : formatPercent(
              dexpJob.dataPointsCompleted / dexpJob.dataPointsTotal,
            )}
      </TableCell>
    ),
  },
  {
    colName: 'Errors',
    key: 'errors',
    headerCellAlign: 'center',
    Component: ({ dexpJob }: TableDataProps) => {
      const { actions } = useUrlState<{ selectedDexpJob?: number }>(
        ANALYSIS_RESULTS_URL_STATE_KEY,
        {},
      );

      return (
        <TableCell
          className={classNames(styles.TableCell, styles.CenteredIcon)}
          align="center"
        >
          {dexpJob.status === DexpJobStatus.CompletedWithErrors ? (
            <Tooltip
              dataHcName={`analysis-results-table-error-popover`}
              trigger={
                <ErrorIcon
                  name="error-icon"
                  onClick={() =>
                    actions.setUrlState({ selectedDexpJob: dexpJob.id })
                  }
                  size="sm"
                  color="error-10"
                />
              }
              label={
                <div className={styles.PopoverContent}>
                  <div>{`${formatNumber(
                    dexpJob.dataPointsCompleted,
                  )} out of ${formatNumber(
                    dexpJob.dataPointsTotal,
                  )} points populated`}</div>
                  <TextButton
                    onClick={() =>
                      actions.setUrlState({ selectedDexpJob: dexpJob.id })
                    }
                    dataHcName="see-error-details"
                    dataHcEventSection={dataHcEventSectionDexpErrorSummary}
                    icon={<ChevronRightSmallIcon />}
                    iconPosition="right"
                    className={styles.SeeDetailsButton}
                  >
                    See details
                  </TextButton>
                </div>
              }
            />
          ) : dexpJob.status === DexpJobStatus.Error ? (
            <Tooltip
              dataHcName={`analysis-results-table-error-popover`}
              trigger={
                <ErrorIcon name="error-icon" size="sm" color="error-10" />
              }
              label={
                <div className={styles.PopoverContent}>
                  <div>{`Analysis failed due to unexpected error`}</div>
                </div>
              }
            />
          ) : null}
        </TableCell>
      );
    },
  },
  {
    colName: 'Download',
    key: 'download',
    headerCellAlign: 'center',
    Component: ({ dexpJob }: TableDataProps) => {
      const { mutate: downloadFileMutation } = useDownloadDexpJob();
      const handleDownload = () =>
        dexpJob.outputFile && downloadFileMutation(dexpJob.outputFile);

      return (
        <TableCell
          className={classNames(styles.TableCell, styles.CenteredIcon)}
          align="center"
        >
          <span>
            {dexpJob.status === DexpJobStatus.Error ? null : (
              <DownloadIcon
                dataHcEventName="DEXP Analysis Download"
                dataHcEventType={MeaningfulEventTypes.Goal}
                onClick={handleDownload}
                size="sm"
              />
            )}
          </span>
        </TableCell>
      );
    },
  },
];
