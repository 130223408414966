import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SORT_ORDER, SortType } from '@hcs/types';
import { camelCaseToSnakeCase, snakeCaseToCamelCase } from '@hcs/utils';

export interface QueryParamNames {
  Search?: string;
  Sort: string;
  Order: string;
}

// need sort type
export const useSearchAndSort = <SortableFields extends string>({
  queryParamNames,
  options,
}: {
  queryParamNames: QueryParamNames;
  options?: {
    defaultSort?: SortType<SortableFields>;
    transform?: boolean;
    replace?: boolean;
  };
}): {
  search: string | null;
  sort: SortType<SortableFields> | null;
  onSearch: (search: string) => void;
  onSort: (field: SortableFields) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { defaultSort, transform = true } = options || {};
  // use sort query params or default sort as initial sort
  const getInitialSort = () => {
    const queryParamSortField: unknown = transform
      ? snakeCaseToCamelCase(searchParams.get(queryParamNames.Sort) || '')
      : searchParams.get(queryParamNames.Sort) || '';
    if (queryParamSortField !== '') {
      const queryParamsOrder = searchParams.get(queryParamNames.Order);
      return {
        field: queryParamSortField as SortableFields,
        order:
          queryParamsOrder?.toUpperCase() === SORT_ORDER.Desc
            ? SORT_ORDER.Desc
            : SORT_ORDER.Asc,
      };
    } else if (defaultSort) {
      return defaultSort;
    } else {
      return null;
    }
  };
  const [sort, setSort] = useState<SortType<SortableFields> | null>(
    getInitialSort(),
  );

  const setSortParams = (field: SortableFields, order: `${SORT_ORDER}`) => {
    searchParams.set(
      queryParamNames.Sort,
      transform ? camelCaseToSnakeCase(field) : field,
    );
    searchParams.set(queryParamNames.Order, order.toLowerCase());
    setSearchParams(
      searchParams,
      options?.replace === false ? undefined : { replace: true },
    );
  };

  // if sort query params did not exist but a default sort does, set sort query params to default sort
  useEffect(() => {
    if (!searchParams.get(queryParamNames.Sort) && defaultSort) {
      setSortParams(defaultSort.field, defaultSort.order);
    }
  }, []);

  const onSort = (field: SortableFields) => {
    const newOrder =
      sort?.field === field && sort?.order === SORT_ORDER.Asc
        ? SORT_ORDER.Desc
        : SORT_ORDER.Asc;
    setSortParams(field, newOrder);
    setSort({
      field,
      order: newOrder,
    });
  };

  const onSearch = (searchTerm: string) => {
    if (queryParamNames.Search) {
      if (!searchTerm) {
        searchParams.delete(queryParamNames.Search);
      } else {
        searchParams.set(queryParamNames.Search, searchTerm);
      }
      setSearchParams(
        searchParams,
        options?.replace ? { replace: true } : undefined,
      );
    }
  };

  return {
    search: queryParamNames.Search
      ? searchParams.get(queryParamNames.Search)
      : null,
    sort,
    onSearch,
    onSort,
  };
};
