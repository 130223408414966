import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useComponentInternalId } from '@hcs/hooks';
import { useIsInternalUser } from '@hcs/internal-users';

import {
  LOCAL_STORAGE_KEY_CONSOLE_ENABLED,
  LOCAL_STORAGE_KEY_CONSOLE_VERBOSE,
  PFX,
  STATUS_MESSAGE_DISABLED,
  STATUS_MESSAGE_ENABLED,
  STATUS_MESSAGE_VERBOSE,
} from '../constants';
import {
  getIsHcsConsoleEnabled,
  getIsHcsConsoleEnabledVerbose,
} from '../utils/hcsConsole.utils';

declare global {
  interface Window {
    hcsConsole?: {
      status: () =>
        | typeof STATUS_MESSAGE_VERBOSE
        | typeof STATUS_MESSAGE_ENABLED
        | typeof STATUS_MESSAGE_DISABLED;
      enable: () => void;
      enableVerbose: () => void;
      disable: () => void;
    };
  }
}

export const HCS_CONSOLE_STATE_CHANGE_EVENT = 'hcsConsoleStateChange';
// Dispatch custom events to keep state in sync across hook instances
const dispatchStateChangeEvent = () => {
  document.dispatchEvent(new Event(HCS_CONSOLE_STATE_CHANGE_EVENT));
};

const getUpdatedState = () => ({
  enabled: getIsHcsConsoleEnabled(),
  enabledVerbose: getIsHcsConsoleEnabledVerbose(),
});

const listeners: Record<
  string,
  Dispatch<
    SetStateAction<{
      enabled: boolean;
      enabledVerbose: boolean;
    }>
  >
> = {};

const handleStateChangeL = () => {
  Object.keys(listeners).forEach((hookInternalId) => {
    const callback = listeners[hookInternalId];
    callback?.(getUpdatedState());
  });
};

export const useHcsConsole = () => {
  const internalId = useComponentInternalId();
  const [state, setState] = useState(getUpdatedState());
  const { data: isInternalUser } = useIsInternalUser();

  useEffect(() => {
    // Subscribe hook instance to listeners
    if (!Object.keys(listeners).length) {
      document.addEventListener(
        HCS_CONSOLE_STATE_CHANGE_EVENT,
        handleStateChangeL,
        false,
      );
    }
    listeners[internalId] = setState;

    return () => {
      if (listeners[internalId]) {
        delete listeners[internalId];
      }
      if (!Object.keys(listeners).length) {
        document.removeEventListener(
          HCS_CONSOLE_STATE_CHANGE_EVENT,
          handleStateChangeL,
        );
      }
    };
  }, []);
  useEffect(() => {
    if (!window.hcsConsole) {
      getIsHcsConsoleEnabled() && console.log(`${PFX} Initialized`);
      window.hcsConsole = {
        status: () => {
          if (getIsHcsConsoleEnabled()) {
            if (getIsHcsConsoleEnabledVerbose()) {
              return STATUS_MESSAGE_VERBOSE;
            }
            return STATUS_MESSAGE_ENABLED;
          }
          return STATUS_MESSAGE_DISABLED;
        },
        enable: () => {
          // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
          localStorage.setItem(LOCAL_STORAGE_KEY_CONSOLE_ENABLED, 'true');
          localStorage.removeItem(LOCAL_STORAGE_KEY_CONSOLE_VERBOSE);
          console.log(STATUS_MESSAGE_ENABLED);
          dispatchStateChangeEvent();
        },
        enableVerbose: () => {
          // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
          localStorage.setItem(LOCAL_STORAGE_KEY_CONSOLE_ENABLED, 'true');
          localStorage.setItem(LOCAL_STORAGE_KEY_CONSOLE_VERBOSE, 'true');
          console.log(STATUS_MESSAGE_VERBOSE);
          dispatchStateChangeEvent();
        },
        disable: () => {
          // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
          localStorage.removeItem(LOCAL_STORAGE_KEY_CONSOLE_ENABLED);
          localStorage.removeItem(LOCAL_STORAGE_KEY_CONSOLE_VERBOSE);
          console.log(STATUS_MESSAGE_DISABLED);
          dispatchStateChangeEvent();
        },
      };
    }
  }, [isInternalUser]);
  return {
    hcsConsole: window.hcsConsole,
    hcsConsoleState: state,
  };
};
