import { ItemObjInShoppingCart } from '@hcs/types';
import { DexpEndpoint } from '@hcs/types';

export const getSelectedEndpoints = (
  endpointsInShoppingCart: Record<DexpEndpoint['id'], ItemObjInShoppingCart>,
): string[] => {
  return Object.keys(endpointsInShoppingCart).reduce<string[]>(
    (selectedEndpointsAcc, endpointKey) => {
      const isSelected =
        endpointsInShoppingCart[endpointKey] &&
        endpointsInShoppingCart[endpointKey].isSelected;
      return isSelected
        ? [...selectedEndpointsAcc, endpointKey]
        : selectedEndpointsAcc;
    },
    [],
  );
};
