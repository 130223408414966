import {
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ACCOUNT_FIELD_CONFIGS,
  AccountFieldInput,
  INVALID_EMAIL_MESSAGE,
  REQUIRED_MESSAGE,
} from '@hcs/authn';
import { Button } from '@hcs/design-system';
import { StateDropdown } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { AccountFields } from '@hcs/types';
import {
  UserContactInfoFields,
  UserContactInfoForm as UserContactInfoFormType,
} from '@hcs/types';
import { capitalizeFirstLetter } from '@hcs/utils';
import { validatorUtils } from '@hcs/utils';

import { useUserContactInfo } from '../../hooks/useUserContactInfo';
import { useUserContactInfoPatch } from '../../hooks/useUserContactInfoPatch';

import styles from './UserContactInfoForm.module.css';

const { maxFieldSizeMessage } = validatorUtils;

interface UserFieldConfig {
  label: string;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  validate: yup.StringSchema<
    string | null | undefined,
    Record<string, string>,
    string | null | undefined
  >;
}
// Configs for common user contact fields
export const USER_CONTACT_FIELD_CONFIGS: {
  [key in UserContactInfoFields]: UserFieldConfig;
} = {
  firstName: {
    label: 'First Name',
    maxLength: 100,
    required: true,
    validate: yup
      .string()
      .max(100, maxFieldSizeMessage('First Name', 100))
      .required(REQUIRED_MESSAGE),
  },
  lastName: {
    label: 'Last Name',
    maxLength: 100,
    required: true,
    validate: yup
      .string()
      .max(100, maxFieldSizeMessage('Last Name', 100))
      .required(REQUIRED_MESSAGE),
  },
  email: {
    label: 'Email',
    maxLength: 128,
    required: true,
    validate: yup
      .string()
      .email(INVALID_EMAIL_MESSAGE)
      .max(128, maxFieldSizeMessage('Email', 128))
      .required(REQUIRED_MESSAGE),
  },
  streetAddress: {
    label: 'Street Address',
    maxLength: 255,
    validate: yup
      .string()
      .max(255, maxFieldSizeMessage('Street Address', 255))
      .nullable(),
  },
  city: {
    label: 'City',
    maxLength: 40,
    validate: yup.string().max(40, maxFieldSizeMessage('City', 40)).nullable(),
  },
  state: {
    label: 'State',
    maxLength: 2,
    validate: yup.string().max(255, maxFieldSizeMessage('State', 2)).nullable(),
  },
  zipCode: {
    label: 'Zip Code',
    maxLength: 20,
    validate: yup
      .string()
      .max(20, maxFieldSizeMessage('Zip Code', 20))
      .nullable(),
  },
  phone: {
    label: 'Phone Number',
    maxLength: 40,
    validate: yup
      .string()
      .max(20, maxFieldSizeMessage('Phone Number', 20))
      .nullable(),
  },
};

const FORM_SCHEMA = yup.object().shape({
  [AccountFields.FirstName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.FirstName].validator,
  [AccountFields.LastName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.LastName].validator,
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
  [AccountFields.StreetAddress]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.StreetAddress].validator,
  [AccountFields.City]: ACCOUNT_FIELD_CONFIGS[AccountFields.City].validator,
  [AccountFields.State]: ACCOUNT_FIELD_CONFIGS[AccountFields.State].validator,
  [AccountFields.Zipcode]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.Zipcode].validator,
  [AccountFields.Phone]: ACCOUNT_FIELD_CONFIGS[AccountFields.Phone].validator,
});

interface Props {
  className?: string;
}

const dataHcName = 'user-contact-info-form';

const Field = ({
  fieldName,
  form,
}: {
  fieldName: keyof UserContactInfoFormType;
  form: UseFormReturn<UserContactInfoFormType>;
}) => {
  const fieldConfig = ACCOUNT_FIELD_CONFIGS[fieldName];
  const errorMessage = form.formState.errors[fieldName]?.message;
  return (
    <Controller
      name={fieldName}
      control={form.control}
      render={({ field }) => {
        if (fieldName === AccountFields.State) {
          return (
            <StateDropdown
              {...field}
              value={field.value || ''}
              className={styles.Input}
              dataHcName={`${dataHcName}-${fieldName}`}
              placeholder={fieldConfig.label}
              onSelect={field.onChange}
            />
          );
        } else {
          return (
            <AccountFieldInput
              {...field}
              className={styles.Input}
              disabled={fieldName === AccountFields.Email}
              error={
                errorMessage ? capitalizeFirstLetter(errorMessage) : undefined
              }
            />
          );
        }
      }}
    />
  );
};

// this component ensures the account is loaded to populate w/ initial values
const UserContactInfoFormContent = ({
  className,
  userContactInfo,
}: Props & { userContactInfo: UserContactInfoFormType }) => {
  const { mutate: patchUserContactInfo } = useUserContactInfoPatch();
  const form = useForm<UserContactInfoFormType>({
    resolver: yupResolver(FORM_SCHEMA),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: userContactInfo,
  });
  const handleSubmit = (formPayload: UserContactInfoFormType) => {
    // Only send dirty fields to the patch Api method
    const patchPayload: Partial<UserContactInfoFormType> = {};
    Object.keys(form.formState.dirtyFields).forEach((f) => {
      const field = f as keyof UserContactInfoFormType;
      if (form.formState.dirtyFields[field]) {
        const value = formPayload[field];
        patchPayload[field] = value === null ? '' : value;
      }
    });
    if (Object.keys(patchPayload).length) {
      patchUserContactInfo(patchPayload, {
        onSuccess: () => {
          form.reset(formPayload);
        },
      });
    }
  };
  return (
    <FormProvider {...form}>
      <form
        data-hc-name={dataHcName}
        className={className}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Field fieldName={AccountFields.FirstName} form={form} />
        <Field fieldName={AccountFields.LastName} form={form} />
        <Field fieldName={AccountFields.Email} form={form} />
        <Field fieldName={AccountFields.StreetAddress} form={form} />
        <Field fieldName={AccountFields.City} form={form} />
        <Field fieldName={AccountFields.State} form={form} />
        <Field fieldName={AccountFields.Zipcode} form={form} />
        <Field fieldName={AccountFields.Phone} form={form} />
        <Button
          dataHcName={`${dataHcName}-submit`}
          type="submit"
          label="Submit"
          disabled={!form.formState.isDirty || !form.formState.isValid}
        />
      </form>
    </FormProvider>
  );
};

export const UserContactInfoForm = (props: Props) => {
  const { data: userContactInfo } = useUserContactInfo();
  if (!userContactInfo) {
    return (
      <LoadingSpinner absoluteCenter dataHcName={`${dataHcName}-skeleton`} />
    );
  } else {
    return (
      <UserContactInfoFormContent
        {...props}
        userContactInfo={userContactInfo}
      />
    );
  }
};
