import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItem } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL } from '../../constants';

import { DisplayProps } from '.';

const Display = ({ orderItem }: DisplayProps): JSX.Element | null => {
  const unit = orderItem.unit ? ` ${orderItem.unit}` : '';
  const fullAddress = `${orderItem.address}${unit}, ${formatMissing(
    orderItem.city,
  )}, ${formatMissing(orderItem.state)} ${orderItem.zipcode}`;
  return (
    <div data-hc-name={`${DATA_HC_NAME_ORDER_ITEMS_TABLE_CELL}-address`}>
      {fullAddress}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Address</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const ORDER_ITEM_ADDRESS_CONFIG = {
  HeaderCell,
  ContentCell,
  label: 'Address',
  sortable: true,
};
