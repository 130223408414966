import React, { useMemo } from 'react';

import { useAccount } from '@hcs/authn';
import { MainNav, MainNavProps, NavItemConfig } from '@hcs/design-system';
import { AppSlugs } from '@hcs/types';

import { useBundleAppConfigs } from '../../hooks/useBundleAppConfigs';
import { checkAppAccess } from '../../utils';

export interface AppAccessMainNavItemConfig extends NavItemConfig {
  // Must have access to all apps if multiple are passed
  appSlug?: AppSlugs | AppSlugs[];
}
export interface AppAccessMainNavProps
  extends Omit<MainNavProps, 'navItemsConfig'> {
  navItemsConfig: AppAccessMainNavItemConfig[];
}
export const AppAccessMainNav = ({
  navItemsConfig: navItemsConfigProp,
  ...props
}: AppAccessMainNavProps) => {
  const appConfigs = useBundleAppConfigs();
  const { data: account } = useAccount();
  const navItemsConfig = useMemo(() => {
    const itemConfigs: NavItemConfig[] = [];
    navItemsConfigProp.forEach((itemConfig) => {
      if (itemConfig.appSlug) {
        if (typeof itemConfig.appSlug === 'string') {
          const appConfig = appConfigs?.find(
            (a) => a.appSlug === itemConfig.appSlug,
          );
          if (
            account &&
            appConfig &&
            checkAppAccess(account.legacy, appConfig)
          ) {
            itemConfigs.push(itemConfig);
          }
        } else {
          const appsWithAccess = itemConfig.appSlug.filter((appSlug) => {
            const appConfig = appConfigs?.find((a) => a.appSlug === appSlug);
            return (
              account && appConfig && checkAppAccess(account.legacy, appConfig)
            );
          });
          if (appsWithAccess.length === itemConfig.appSlug.length) {
            itemConfigs.push(itemConfig);
          }
        }
      } else {
        itemConfigs.push(itemConfig);
      }
    });
    return itemConfigs;
  }, [account, navItemsConfigProp, appConfigs]);
  return <MainNav {...props} navItemsConfig={navItemsConfig} />;
};
