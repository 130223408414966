import React from 'react';
import classNames from 'classnames';

import { formatNumber } from '@hcs/utils';

import styles from './MapCluster.module.css';

export interface MapClusterProps {
  dataHcName: string;
  pointCount: number;
  isOverCount?: boolean;
  clickable?: boolean;
  onClick?: VoidFunction;
}
export const MapCluster = ({
  dataHcName,
  pointCount,
  isOverCount = false,
  clickable = true,
  onClick,
}: MapClusterProps) => {
  const capClusterCount = pointCount > 9999;

  return (
    <div
      className={classNames(styles.MapCluster, {
        [styles.clickable]: clickable,
      })}
      onClick={onClick}
      data-hc-name={dataHcName}
    >
      <div className={styles.Inner}>
        <span className={styles.Label}>{`${formatNumber(
          capClusterCount ? 9999 : pointCount,
        )}${isOverCount || capClusterCount ? '+' : ''}`}</span>
      </div>
    </div>
  );
};
