import React from 'react';
import classNames from 'classnames';

import { LoadingSpinner } from '@hcs/design-system';
import { CompTypes } from '@hcs/types';
import { ReportId, SubjectValuePaths, ValuationMethod } from '@hcs/types';
import { NavigateToCompSelectionDrawFn } from '@hcs/types';

import { ValueSelectorButton } from '../../components-deprecated';
import { ValueSelectorItem } from '../../components-deprecated';
import { useSubjectValueDocument } from '../../hooks';
import { useDocumentPatch } from '../../hooks/useDocumentPatch';
import { useLegacyCompValues } from '../../hooks/useLegacyCompValues';
import { useReportLimitedType } from '../../hooks/useReportLimitedType';
import { useReportPermissions } from '../../hooks/useReportPermissions';

import styles from './ReportValueSelector.module.css';

interface Props {
  reportId: ReportId;
  showRentalValue: boolean;
  onClose?: VoidFunction;
  className?: string;
  onClickSelectComps: VoidFunction;
  onClickProvideLocation: NavigateToCompSelectionDrawFn;
}

const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const PATH_ADJ_DATE: SubjectValuePaths = `/data/${ValuationMethod.Comps}/hcAdjustments/hcAdjustmentDate`;
const PATH_ADJ_DETAILS: SubjectValuePaths = `/data/${ValuationMethod.Comps}/hcAdjustments/hcAdjustmentPropertyDetails`;
const dataHcName = 'adjusted-comp-based-avg-selector-item';
export const AdjustedCompBasedAvgSelectorItem = ({
  reportId,
  showRentalValue,
  className,
  onClose,
  onClickSelectComps,
  onClickProvideLocation,
}: Props) => {
  const compType = showRentalValue ? CompTypes.Rental : CompTypes.Sold;
  const { data: legacyCompValues } = useLegacyCompValues(reportId, compType);
  const { data: reportLimited } = useReportLimitedType(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: avmDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });

  if (!avmDocument || !reportPermissions || !legacyCompValues) {
    return (
      <LoadingSpinner
        dataHcName={`${dataHcName}-skeleton`}
        containerHeight="380px"
      />
    );
  }
  const shouldProvideLocation =
    reportLimited?.limitedMapping['missing-location'] &&
    !reportLimited.locationProvidedByUser;
  const { comparableAverage } = legacyCompValues;
  const handleSelect = () => {
    if (comparableAverage?.value.value) {
      onClose?.();
    } else if (shouldProvideLocation) {
      onClickProvideLocation(reportId, compType);
    } else {
      onClickSelectComps();
    }
    documentPatchMutation.mutate({
      reportId,
      document: avmDocument,
      operations: [
        {
          op: 'replace',
          path: PATH_SELECTED_VALUE,
          value: ValuationMethod.Comps,
        },
        {
          op: 'add',
          path: PATH_ADJ_DATE,
          value: false,
        },
        {
          op: 'add',
          path: PATH_ADJ_DETAILS,
          value: false,
        },
      ],
    });
  };

  return (
    <ValueSelectorItem
      title={
        showRentalValue ? 'Rental Comparable Average' : 'Comparable Average'
      }
      dataHcName={dataHcName}
      value={comparableAverage?.value.value}
      className={classNames(styles.AVMSelectionItem, className)}
    >
      {comparableAverage?.value.value ? (
        <span data-hc-name={`${dataHcName}-value-description`}>
          Average of all the selected comparables (based on sales or most recent
          list price) plus the user&apos;s price adjustment for each
        </span>
      ) : (
        <span data-hc-name={`${dataHcName}-nonvalue-description`}>
          Average of all the selected comparables (based on sales or most recent
          list price) plus the user&apos;s price adjustment for each comparable.
          Click below to select comps now.
        </span>
      )}
      {reportPermissions.isEditable && (
        <ValueSelectorButton
          dataHcName={`${dataHcName}-value-selector-button`}
          onClick={handleSelect}
        >
          {comparableAverage?.value.value
            ? 'Use For Report'
            : shouldProvideLocation
              ? 'Update Subject Location'
              : `Select ${showRentalValue ? 'Rental ' : ''}Comps`}
        </ValueSelectorButton>
      )}
    </ValueSelectorItem>
  );
};
