import React from 'react';

import { useAppConfigExtended } from '@hcs/auth';
import { AgileSuiteIcon, ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { useNavigateToAgileSuite } from '../../hooks/useNavigateToAgileSuite';
import { AgilePdfProductCards } from '../AgilePdfProductCards';
import { AgileInspectionProductLink } from '../AgilePdfProductCards/AgileInspectionProductLink';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './AgileSuiteProductCard.module.css';

interface AgileSuiteProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

const dataHcName = 'agile-suite-card';
export const AgileSuiteProductCard = ({
  navigateToSSSelectPlan,
}: AgileSuiteProductCardProps) => {
  const navigateToAgileSuite = useNavigateToAgileSuite();
  const { active, handleOpen, handleClose } = useActiveState();
  const appConfigExtended = useAppConfigExtended(AppSlugs.AgileSuite);
  if (!appConfigExtended) return null;
  const { hasAccess } = appConfigExtended;
  const handleClick = () => {
    if (hasAccess) {
      navigateToAgileSuite();
    } else {
      return handleOpen();
    }
  };
  return (
    <>
      <ProductCard
        dataHcName={dataHcName}
        theme={{
          ProductCard: styles.ProductCard,
          DefaultCell: styles.DefaultCell,
        }}
        productName="Agile Suite"
        subheading={appConfigExtended.appConfig.secondaryName}
        productDescription="All your order valuation solutions needs in one place."
        hasAccess={!!hasAccess}
        Icon={AgileSuiteIcon}
        largeContent={
          <>
            <AgilePdfProductCards />
            <AgileInspectionProductLink />
          </>
        }
        large
        onClick={handleClick}
      />
      <ProductPreviewDialog
        appConfigExtended={appConfigExtended}
        active={active}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleClose}
      />
    </>
  );
};
