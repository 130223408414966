import React from 'react';

import { Skeleton } from '@hcs/design-system';
import { ReportId } from '@hcs/types';
import { formatMarketRiskLevel, formatPercent } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useDaysOnMarketChartDocument } from '../../hooks/useDaysOnMarketChartDocument';
import { useMarketAnalysisDocument } from '../../hooks/useMarketAnalysisDocument';
import { useMonthsOfSupplyChartDocument } from '../../hooks/useMonthsOfSupplyChartDocument';

import { MARKET_ANALYSIS_FEATURES } from './MarketAnalysis';

interface Props {
  reportId: ReportId;
  className?: string;
}
export const MARKET_ANALYSIS_BUTTON_SUMMARY = MARKET_ANALYSIS_FEATURES;
const dataHcName = 'market-analysis-button-summary';
export const MarketAnalysisButtonSummary = ({ reportId, className }: Props) => {
  const daysOnMarketQuery = useDaysOnMarketChartDocument(reportId);
  const monthsOfSupplyQuery = useMonthsOfSupplyChartDocument(reportId);
  const marketAnalysisQuery = useMarketAnalysisDocument(reportId);
  const combinedQuery = combineUseQueryResult([
    daysOnMarketQuery,
    monthsOfSupplyQuery,
    marketAnalysisQuery,
  ]);
  if (combinedQuery.isInitialLoading) {
    return (
      <Skeleton width="70%" type="text" dataHcName={`${dataHcName}-skeleton`} />
    );
  } else if (daysOnMarketQuery.isError && marketAnalysisQuery.isError) {
    return <>An error fetching chart data occurred</>;
  } else if (
    !daysOnMarketQuery.data?.data?.chart.data?.length &&
    !marketAnalysisQuery.data?.data?.risk.score
  ) {
    return <>Data unavailable for this Market</>;
  }

  return (
    <div className={className}>
      1 Year Risk of Decline:
      <span data-hc-name={`${dataHcName}-stat-risk-of-decline`}>
        {formatPercent(marketAnalysisQuery.data?.data?.risk.current, false)}
      </span>{' '}
      (
      <span data-hc-name={`${dataHcName}-stat-risk-level`}>
        {formatMarketRiskLevel(marketAnalysisQuery.data?.data?.risk.score)}
      </span>
      )
    </div>
  );
};
