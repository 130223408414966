import { useIcon } from '@hcs/hooks';
import { IconProps } from '@hcs/types';

import AddItem from './AddItemIcon.svg?react';
import Age from './AgeIcon.svg?react';
import Analysis from './AnalysisIcon.svg?react';
import AtWork from './AtWorkIcon.svg?react';
import Basement from './BasementIcon.svg?react';
import Bathroom from './BathroomIcon.svg?react';
import Bedroom from './BedroomIcon.svg?react';
import Building from './BuildingIcon.svg?react';
import CalendarClock from './CalendarClockIcon.svg?react';
import CalendarScheduleSearch from './CalendarScheduleSearchIcon.svg?react';
import Chart from './ChartIcon.svg?react';
import Clock from './ClockIcon.svg?react';
import ClockRewind from './ClockRewindIcon.svg?react';
import Comment from './CommentIcon.svg?react';
import Comparison from './ComparisonIcon.svg?react';
import ContactUs from './ContactUsIcon.svg?react';
import DataPriority from './DataPriorityIcon.svg?react';
import Delete from './DeleteIcon.svg?react';
import Document from './DocumentIcon.svg?react';
import DownloadCircle from './DownloadCircleIcon.svg?react';
import Download from './DownloadIcon.svg?react';
import DownloadSave from './DownloadSaveIcon.svg?react';
import Edit from './EditIcon.svg?react';
import Entitlements from './EntitlementsIcon.svg?react';
import ExternalLink from './ExternalLinkIcon.svg?react';
import Eye from './EyeIcon.svg?react';
import Filter from './FilterIcon.svg?react';
import Grid from './GridIcon.svg?react';
import HcComps from './HcCompsIcon.svg?react';
import HomeDark from './HomeDarkIcon.svg?react';
import HomeDownload from './HomeDownloadIcon.svg?react';
import HomeElectricity from './HomeElectricityIcon.svg?react';
import Home from './HomeIcon.svg?react';
import HomePrice from './HomePriceIcon.svg?react';
import InspectionCorrection from './InspectionCorrectionIcon.svg?react';
import Items from './ItemsIcon.svg?react';
import ListAnalysis from './ListAnalysisIcon.svg?react';
import List from './ListIcon.svg?react';
import Logout from './LogoutIcon.svg?react';
import LotSize from './LotSizeIcon.svg?react';
import Mail from './MailIcon.svg?react';
import MapFolding from './MapFoldingIcon.svg?react';
import Map from './MapIcon.svg?react';
import MapPin from './MapPinIcon.svg?react';
import NoData from './NoDataIcon.svg?react';
import NoImages from './NoImagesIcon.svg?react';
import OmCalendar from './OmCalendarIcon.svg?react';
import OmFile from './OmFileIcon.svg?react';
import OmFileId from './OmFileIdIcon.svg?react';
import Organization from './OrganizationIcon.svg?react';
import OrgSettings from './OrgSettingsIcon.svg?react';
import PersonalizeReports from './PersonalizeReportsIcon.svg?react';
import Pin from './PinIcon.svg?react';
import Pool from './PoolIcon.svg?react';
import Profile from './ProfileIcon.svg?react';
import Refresh from './RefreshIcon.svg?react';
import SearchAdd from './SearchAddIcon.svg?react';
import SearchComp from './SearchCompIcon.svg?react';
import Search from './SearchIcon.svg?react';
import Segment from './SegmentIcon.svg?react';
import Settings from './SettingsIcon.svg?react';
import Share from './ShareIcon.svg?react';
import Sort from './SortIcon.svg?react';
import Support from './SupportIcon.svg?react';
import Time from './TimeIcon.svg?react';
import Usage from './UsageIcon.svg?react';
import UserGroup from './UserGroupIcon.svg?react';
import UserInterface from './UserInterfaceIcon.svg?react';
import UserProfile from './UserProfileIcon.svg?react';
import UserSettings from './UserSettingsIcon.svg?react';
import Yield from './YieldIcon.svg?react';

export const AddItemIcon = (props: IconProps) => useIcon(AddItem, props);
export const AgeIcon = (props: IconProps) => useIcon(Age, props);
export const AnalysisIcon = (props: IconProps) => useIcon(Analysis, props);
export const AtWorkIcon = (props: IconProps) => useIcon(AtWork, props);
export const BasementIcon = (props: IconProps) => useIcon(Basement, props);
export const BathroomIcon = (props: IconProps) => useIcon(Bathroom, props);
export const BedroomIcon = (props: IconProps) => useIcon(Bedroom, props);
export const BuildingIcon = (props: IconProps) => useIcon(Building, props);
export const CalendarClockIcon = (props: IconProps) =>
  useIcon(CalendarClock, props);
export const CalendarScheduleSearchIcon = (props: IconProps) =>
  useIcon(CalendarScheduleSearch, props);

export const ChartIcon = (props: IconProps) => useIcon(Chart, props);
export const ClockIcon = (props: IconProps) => useIcon(Clock, props);
export const ClockRewindIcon = (props: IconProps) =>
  useIcon(ClockRewind, props);
export const CommentIcon = (props: IconProps) => useIcon(Comment, props);
export const ComparisonIcon = (props: IconProps) => useIcon(Comparison, props);
export const ContactUsIcon = (props: IconProps) => useIcon(ContactUs, props);
export const DataPriorityIcon = (props: IconProps) =>
  useIcon(DataPriority, props);
export const DeleteIcon = (props: IconProps) => useIcon(Delete, props);
export const DocumentIcon = (props: IconProps) => useIcon(Document, props);
export const DownloadCircleIcon = (props: IconProps) =>
  useIcon(DownloadCircle, props);
export const DownloadIcon = (props: IconProps) => useIcon(Download, props);
export const DownloadSaveIcon = (props: IconProps) =>
  useIcon(DownloadSave, props);
export const EditIcon = (props: IconProps) => useIcon(Edit, props);
export const EntitlementsIcon = (props: IconProps) =>
  useIcon(Entitlements, props);
export const EyeIcon = (props: IconProps) => useIcon(Eye, props);
export const ExternalLinkIcon = (props: IconProps) =>
  useIcon(ExternalLink, props);
export const FilterIcon = (props: IconProps) => useIcon(Filter, props);
export const GridIcon = (props: IconProps) => useIcon(Grid, props);
export const HcCompsIcon = (props: IconProps) => useIcon(HcComps, props);
export const HomeDarkIcon = (props: IconProps) => useIcon(HomeDark, props);
export const HomeIcon = (props: IconProps) => useIcon(Home, props);
export const HomeDownloadIcon = (props: IconProps) =>
  useIcon(HomeDownload, props);
export const HomeElectricityIcon = (props: IconProps) =>
  useIcon(HomeElectricity, props);
export const HomePriceIcon = (props: IconProps) => useIcon(HomePrice, props);
export const InspectionCorrectionIcon = (props: IconProps) =>
  useIcon(InspectionCorrection, props);
export const ItemsIcon = (props: IconProps) => useIcon(Items, props);
export const ListAnalysisIcon = (props: IconProps) =>
  useIcon(ListAnalysis, props);
export const ListIcon = (props: IconProps) => useIcon(List, props);
export const LogoutIcon = (props: IconProps) => useIcon(Logout, props);
export const LotSizeIcon = (props: IconProps) => useIcon(LotSize, props);
export const MailIcon = (props: IconProps) => useIcon(Mail, props);
export const MapIcon = (props: IconProps) => useIcon(Map, props);
export const MapFoldingIcon = (props: IconProps) => useIcon(MapFolding, props);
export const MapPinIcon = (props: IconProps) => useIcon(MapPin, props);
export const NoDataIcon = (props: IconProps) => useIcon(NoData, props);
export const NoImagesIcon = (props: IconProps) => useIcon(NoImages, props);
export const OmCalendarIcon = (props: IconProps) => useIcon(OmCalendar, props);
export const OmFileIcon = (props: IconProps) => useIcon(OmFile, props);
export const OmFileIdIcon = (props: IconProps) => useIcon(OmFileId, props);
export const OrganizationIcon = (props: IconProps) =>
  useIcon(Organization, props);
export const OrgSettingsIcon = (props: IconProps) =>
  useIcon(OrgSettings, props);

export const PersonalizeReportsIcon = (props: IconProps) =>
  useIcon(PersonalizeReports, props);
export const PinIcon = (props: IconProps) => useIcon(Pin, props);
export const PoolIcon = (props: IconProps) => useIcon(Pool, props);
export const ProfileIcon = (props: IconProps) => useIcon(Profile, props);
export const SearchAddIcon = (props: IconProps) => useIcon(SearchAdd, props);
export const SearchCompIcon = (props: IconProps) => useIcon(SearchComp, props);
export const SearchIcon = (props: IconProps) => useIcon(Search, props);
export const SegmentIcon = (props: IconProps) => useIcon(Segment, props);
export const SettingsIcon = (props: IconProps) => useIcon(Settings, props);
export const ShareIcon = (props: IconProps) => useIcon(Share, props);
export const SortIcon = (props: IconProps) => useIcon(Sort, props);
export const SupportIcon = (props: IconProps) => useIcon(Support, props);
export const RefreshIcon = (props: IconProps) => useIcon(Refresh, props);
export const TimeIcon = (props: IconProps) => useIcon(Time, props);
export const UsageIcon = (props: IconProps) => useIcon(Usage, props);
export const UserGroupIcon = (props: IconProps) => useIcon(UserGroup, props);
export const UserProfileIcon = (props: IconProps) =>
  useIcon(UserProfile, props);
export const UserInterfaceIcon = (props: IconProps) =>
  useIcon(UserInterface, props);
export const UserSettingsIcon = (props: IconProps) =>
  useIcon(UserSettings, props);
export const YieldIcon = (props: IconProps) => useIcon(Yield, props);
