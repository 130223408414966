import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { ActionButtons } from '@hcs/design-system';
import { HcsAdminNewUsageQuery } from '@hcs/types';
import { isDateStr, parseDateString } from '@hcs/utils';

import { useHcsAdminOrgUsageQueryCreate } from '../../hooks/useHcsAdminOrgUsageQueryCreate';
import { OrgUsageZuoraQueryDetailsCreate } from '../OrgUsageZuoraQueryDetails';

interface Props {
  actionButtonsPortalId?: string;
  onClose: () => void;
  orgId: number;
}

const ORG_USAGE_QUERY_INPUT_FORM_SCHEMA = object({
  address: string(),
  authReqId: string(),
  component: string(),
  startReqTime: string()
    .required('From date is required')
    .test(
      'is-valid-date',
      'From date must be a valid date',
      (value: string | undefined) => {
        if (value == null) {
          return true;
        }
        return isDateStr(value);
      },
    ),
  endReqTime: string()
    .required('To date is required')
    .test(
      'is-valid-date',
      'To date must be a valid date',
      (value: string | undefined) => {
        if (value == null) {
          return true;
        }
        return isDateStr(value);
      },
    )
    .when('startReqTime', ([startReqTime]: (string | undefined)[], schema) => {
      if (startReqTime == null) {
        return schema;
      }
      // if returns true, no error is shown to user:
      return schema.test(
        'from-to-dates-compatible',
        'To date must be on or after from date',
        (endReqTime: string | undefined) => {
          if (endReqTime == null) {
            return true;
          }
          if (isDateStr(startReqTime) && isDateStr(endReqTime)) {
            return parseDateString(startReqTime) <= parseDateString(endReqTime);
          }
          return true;
        },
      );
    }),
});

export const OrgUsageZuoraQueryCreate = ({
  actionButtonsPortalId,
  onClose,
  orgId,
}: Props) => {
  const form = useForm<HcsAdminNewUsageQuery>({
    resolver: yupResolver(ORG_USAGE_QUERY_INPUT_FORM_SCHEMA),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const createUsageQuery = useHcsAdminOrgUsageQueryCreate(orgId);

  const handleSubmit = (data: HcsAdminNewUsageQuery) => {
    const submitData = Object.keys(data)
      .filter((field) => data[field as keyof HcsAdminNewUsageQuery] !== '')
      .reduce(
        (initial, field) => ({
          ...initial,
          [field]: data[field as keyof HcsAdminNewUsageQuery],
        }),
        {
          ignoreNonbillable: data.ignoreNonbillable,
          propertySuccessStatus: data.propertySuccessStatus,
        },
      );
    createUsageQuery.mutate(submitData);
    form.reset();
    onClose();
  };

  const dataHcName = 'org-query-create-dialog';
  return (
    <FormProvider {...form}>
      <form data-hc-name={`${dataHcName}-form`}>
        <OrgUsageZuoraQueryDetailsCreate />
        <ActionButtons
          portalIdRender={actionButtonsPortalId}
          dataHcName={`${dataHcName}-actions`}
          actions={[
            {
              dataHcName: `${dataHcName}-save`,
              label: 'Generate Report',
              type: 'submit',
              disabled: !form.formState.isValid,
              onClick: form.handleSubmit(handleSubmit),
              primary: true,
            },
          ]}
        />
      </form>
    </FormProvider>
  );
};
