import camelcaseKeys from 'camelcase-keys';

import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import {
  PexpArchivedReportResponse,
  ProcessedPexpArchivedReport,
} from '@hcs/types';
import { HUELL_URL } from '@hcs/urls';

import { HuellOrgPreferencesApi } from './huellOrgPreferences.api';
import { HuellPersonalizationApi } from './huellPersonalization.api';
import { HuellUserPreferencesApi } from './huellUserPreferences.api';

// All Huell endpoints: https://git.housecanary.net/Engineering/huell/-/blob/develop/huell/urls.py
export const HuellApi = {
  ...HuellOrgPreferencesApi,
  ...HuellUserPreferencesApi,
  ...HuellPersonalizationApi,
  fetchArchivedPexpReports: async () => {
    const response =
      await AxiosAccessTokenClientEhrm.get<PexpArchivedReportResponse>(
        `${HUELL_URL}/emporium/property_lookups/`,
      );
    const data = camelcaseKeys(response.data, { deep: true });
    const addressesProcessed: Record<string, boolean> = {};
    // Remove active reports here since we should never use them in the new UI
    // This endpoint will likely be deprecated in the medium term
    const processedArchivedReports: ProcessedPexpArchivedReport[] = [];
    data.lookups.forEach((lookup) => {
      const {
        streetAddress: address,
        unit,
        city,
        state,
        zipcode,
        addressSlug,
        clientId,
        addressKey,
      } = lookup;
      if (!addressesProcessed[addressKey]) {
        lookup.reports.forEach((report) => {
          // Do not expose active legacy reports since they will have been migrated to report-api
          if (!report.isActive) {
            processedArchivedReports.push({
              ...report,
              clientId,
              address,
              unit,
              city,
              state,
              zipcode,
              addressSlug,
            });
          }
        });
        addressesProcessed[addressKey] = true;
      }
    });
    return processedArchivedReports.sort((a, b) =>
      a.purchaseDate < b.purchaseDate
        ? 1
        : a.purchaseDate > b.purchaseDate
          ? -1
          : a.address > b.address
            ? 1
            : a.address < b.address
              ? -1
              : 0,
    );
  },
};
