import { useNavigate } from 'react-router';

import { APP_CONFIG_DEXP } from '../constants/AppConfigDexp.constants';

export const useNavigateToDexp = () => {
  const navigate = useNavigate();
  return () => {
    navigate(APP_CONFIG_DEXP.rootPath);
  };
};
