import { gql } from 'graphql-request';

import {
  FRAGMENT_PROPERTY_DETAILS_CORE,
  FRAGMENT_PROPERTY_DETAILS_PREVIEW,
} from './fragments/PropertyDetails.graphql';
import { FRAGMENT_PROPERTY_LOCATION } from './fragments/PropertyStateLocation.graphql';

export const QUERY_PROPERTY_STATE_PREVIEW_MLS = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  ${FRAGMENT_PROPERTY_DETAILS_PREVIEW}
  query PropertyStatePreviewMls($cerberusInput: CerberusInput!) {
    # HC INTERNAL
    lookup(id: $cerberusInput) {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
        mls {
          property {
            ...PropertyDetailsPreview
          }
        }
      }
    }
  }
`;

export const QUERY_PROPERTY_STATE_CORE_MLS = gql`
  ${FRAGMENT_PROPERTY_LOCATION}
  ${FRAGMENT_PROPERTY_DETAILS_CORE}
  query PropertyStateCoreMls($cerberusInput: CerberusInput!) {
    # HC INTERNAL
    lookup(id: $cerberusInput) {
      propertyDetails {
        hcAddressId
        location {
          ...PropertyLocation
        }
        mls {
          property {
            ...PropertyDetailsCore
          }
        }
      }
    }
  }
`;
