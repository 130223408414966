import React from 'react';
import classNames from 'classnames';

import { TopLevelBanner } from '@hcs/design-system';
import { SingleColumnContent } from '@hcs/design-system';
import { ReportFeatures, ReportId, ValuationMethod } from '@hcs/types';
import { formatMoney, formatMoneyPerMonth } from '@hcs/utils';

import { useReportPermissions, useReportValue } from '../../hooks';
import { dataHcEventSectionEditSubjectDetails } from '../SubjectDetailsTable';
import {
  dataHcEventSectionOpinionOfPriceRental,
  dataHcEventSectionOpinionOfPriceSale,
} from '../UserOpinionOfPriceForm';

import styles from './ReportValueTopLevelBanner.module.css';

interface ReportValueTopLevelBannerProps {
  reportId: ReportId;
  showRentalValue?: boolean;
  className?: string;
  onClickEditAdjusted?: VoidFunction;
  onClickEditUser?: VoidFunction;
}
const dataHcName = 'report-value-top-level-banner';
export const REPORT_VALUE_TOP_LEVEL_BANNER_FEATURES_SALE: ReportFeatures[] = [
  ReportFeatures.AdjustedAvm,
  ReportFeatures.UserOpinionOfPrice,
];
export const REPORT_VALUE_TOP_LEVEL_BANNER_FEATURES_RENTAL: ReportFeatures[] = [
  ReportFeatures.RentalUserOpinionOfPrice,
];
export const ReportValueTopLevelBanner = ({
  reportId,
  showRentalValue,
  className,
  onClickEditAdjusted,
  onClickEditUser,
}: ReportValueTopLevelBannerProps) => {
  const { data: reportValue } = useReportValue(reportId, showRentalValue);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const adjustedValueSelected =
    reportValue?.valuationMethod === ValuationMethod.Adjusted;
  if (
    adjustedValueSelected ||
    reportValue?.valuationMethod === ValuationMethod.UserEntered
  ) {
    const reportDollarValue = reportValue.selectedValue?.value.value;
    const handleOnClick = adjustedValueSelected
      ? onClickEditAdjusted
      : onClickEditUser;

    return (
      <SingleColumnContent>
        <TopLevelBanner
          dataHcName={dataHcName}
          dataHcEventSection={
            adjustedValueSelected
              ? dataHcEventSectionEditSubjectDetails
              : showRentalValue
                ? dataHcEventSectionOpinionOfPriceRental
                : dataHcEventSectionOpinionOfPriceSale
          }
          className={classNames(styles.ReportValueTopLevelBanner, className)}
          actionButton={
            reportPermissions?.isEditable && handleOnClick
              ? {
                  label: adjustedValueSelected
                    ? showRentalValue
                      ? 'Edit Adjusted Rental Value'
                      : 'Edit Adjusted Value'
                    : showRentalValue
                      ? 'Edit User Rental Value'
                      : 'Edit User Value',
                  onClick: handleOnClick,
                }
              : undefined
          }
        >
          {showRentalValue
            ? `Your ${
                adjustedValueSelected ? 'adjusted' : 'opinion of'
              } rental value is ${formatMoneyPerMonth(reportDollarValue)}`
            : `Your ${
                adjustedValueSelected ? 'adjusted' : 'opinion of'
              } value is ${formatMoney(reportDollarValue)}`}
        </TopLevelBanner>
      </SingleColumnContent>
    );
  } else {
    return null;
  }
};
