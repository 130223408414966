import React, { useState } from 'react';

import { Input, TableCell, TableHeaderCell } from '@hcs/design-system';
import { PropertyStateEditCallbackArgs, SpatialSortField } from '@hcs/types';
import {
  PropertyStateArgsProps,
  PropertyStateFieldConfig,
  PropertyStateFields,
  PropertyStatePaths,
  PropertyStateTableCellProps,
  PropertyStateTableHeaderCellProps,
  PropertyStateType,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatNumber, numInputStrStrip } from '@hcs/utils';

const FIELD = PropertyStateFields.garageSpaces;
type FieldConfig = PropertyStateFieldConfig<typeof FIELD>;
const label = 'Garage Spaces';
const labelShort = 'Garage Spaces';
const propertyStatePath: PropertyStatePaths = '/propertyDetails/parking/garage';
const getValue: FieldConfig['getValue'] = (propertyStateArgs) => {
  const { propertyState, propertyStateType } = propertyStateArgs || {};
  if (propertyStateType === 'flat') {
    return propertyState?.[FIELD];
  } else if (propertyStateType === PropertyStateType.Core) {
    return propertyState?.propertyDetails?.parking?.garage;
  }
  return undefined;
};
const formatValue: FieldConfig['formatValue'] = (propertyStateArgs) =>
  formatNumber(getValue(propertyStateArgs));

const formatInline: FieldConfig['formatInline'] = (propertyStateArgs) =>
  `${formatValue(propertyStateArgs)} Spaces`;
// Component for displaying a field from a schema
const Display = ({ propertyStateArgs }: PropertyStateArgsProps) => {
  return <>{formatValue(propertyStateArgs)}</>;
};

const Edit = ({
  propertyStateArgs,
  onChange,
  className,
}: PropertyStateArgsProps & {
  onChange: (args: PropertyStateEditCallbackArgs<typeof FIELD>) => void;
  className?: string;
}) => {
  const defaultValue = getValue(propertyStateArgs);
  const [value, setValue] = useState(
    defaultValue == null ? null : defaultValue.toString(),
  );

  return (
    <Input
      dataHcName="garage-spaces-adjust-input"
      className={className}
      value={value?.toString() || ''}
      onChange={(newValue) => {
        const valueOrNull = newValue === '' ? null : numInputStrStrip(newValue);
        setValue(valueOrNull);

        onChange({
          path: propertyStatePath,
          value: valueOrNull === null ? valueOrNull : Number(valueOrNull),
          field: FIELD,
        });
      }}
      maxLength={2}
    />
  );
};

const HeaderCell = (props: PropertyStateTableHeaderCellProps) => {
  return <TableHeaderCell {...props}>{labelShort}</TableHeaderCell>;
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultHeaderProps: Partial<TableHeaderCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableHeaderCell: true,
};
HeaderCell.defaultProps = defaultHeaderProps;

const ContentCell = ({
  propertyStateArgs,
  ...tableCellProps
}: PropertyStateTableCellProps) => {
  return (
    <TableCell {...tableCellProps}>
      <Display propertyStateArgs={propertyStateArgs} />
    </TableCell>
  );
};
// Using default props allows for child.props checks
// in the Table components to work properly
const defaultContentProps: Partial<TableCellProps> = {
  align: 'right',
  // Needed so child component type-checking passes
  isTableCell: true,
};
ContentCell.defaultProps = defaultContentProps;

export const GARAGE_SPACES_CONFIG: FieldConfig = {
  field: FIELD,
  label,
  labelShort,
  propertyStatePath,
  getValue,
  formatValue,
  formatInline,
  HeaderCell,
  ContentCell,
  Display,
  Edit,
  spatialSortField: SpatialSortField.ParkingSpots,
};
