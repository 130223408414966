import React, { useMemo } from 'react';

import { checkAppAccess, useBundleAppConfigs } from '@hcs/auth';
import { useAccount } from '@hcs/authn';
import {
  ChildItemConfig,
  SubNav,
  SubNavItemConfig,
  SubNavProps,
} from '@hcs/design-system';
import { useFeatureFlagsForUser } from '@hcs/huell';
import { AppSlugs, CapabilitiesCheckParams } from '@hcs/types';

import { useCapabilities } from '../../hooks';
import { checkHasCapabilities } from '../../utils/capabilityCheck.utils';

export interface AppAccessSubNavChildConfig extends ChildItemConfig {
  appSlug?: AppSlugs;
  capabiliesCheckParams?: CapabilitiesCheckParams;
}
interface AppAccessSubNavItemConfig extends Omit<SubNavItemConfig, 'children'> {
  children: (AppAccessSubNavChildConfig | false | null | undefined)[];
}

export interface AppAccessSubNavProps
  extends Omit<SubNavProps, 'subNavItemsConfig'> {
  subNavItemsConfig: (AppAccessSubNavItemConfig | false | null | undefined)[];
}
export const AppAccessSubNav = ({
  subNavItemsConfig: subNavItemsConfigProp,
  ...props
}: AppAccessSubNavProps) => {
  const appConfigs = useBundleAppConfigs();
  const { data: account } = useAccount();
  const { data: capabilities } = useCapabilities();
  const { data: featureFlags } = useFeatureFlagsForUser();
  const subNavItemsConfig = useMemo(() => {
    const itemConfigs: SubNavItemConfig[] = [];
    subNavItemsConfigProp.forEach((itemConfig) => {
      const childConfigs: ChildItemConfig[] = [];
      if (!itemConfig) {
        return;
      }
      itemConfig.children.forEach((c) => {
        if (!c) return;
        const { appSlug, capabiliesCheckParams, ...childConfig } = c;
        let shouldAddItem = false;
        if (appSlug || capabiliesCheckParams) {
          let appSlugCheckPassed = false;
          let capabilitiesCheckPassed = false;
          if (appSlug) {
            const appConfig = appConfigs?.find((a) => a.appSlug === appSlug);
            if (
              account &&
              appConfig &&
              checkAppAccess(account.legacy, appConfig)
            ) {
              appSlugCheckPassed = true;
            }
          } else {
            appSlugCheckPassed = true;
          }

          if (capabiliesCheckParams) {
            if (
              account &&
              capabilities &&
              checkHasCapabilities(
                capabiliesCheckParams,
                capabilities,
                featureFlags,
              )
            ) {
              capabilitiesCheckPassed = true;
            }
          } else {
            capabilitiesCheckPassed = true;
          }

          shouldAddItem = appSlugCheckPassed && capabilitiesCheckPassed;
        } else {
          shouldAddItem = true;
        }

        if (shouldAddItem) {
          childConfigs.push(childConfig);
        }
      });
      if (childConfigs.length) {
        itemConfigs.push({
          ...itemConfig,
          children: childConfigs,
        });
      }
    });
    return itemConfigs;
  }, [account, subNavItemsConfigProp, appConfigs, capabilities, featureFlags]);
  return <SubNav {...props} subNavItemsConfig={subNavItemsConfig} />;
};
