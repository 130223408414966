import { SVGProps, useSvg } from '@hcs/hooks';

import HouseCanaryLogoCurrentColorSvg from './hc-logo_current-color.svg?react';
import HouseCanaryLogoFullColorSvg from './hc-logo_full-color.svg?react';
import HouseCanaryLogomarkCurrentColorSvg from './hc-logomark_current-color.svg?react';
import HouseCanaryLogomarkFullColorSvg from './hc-logomark_full-color.svg?react';
import PoweredByHouseCanaryLogoSvg from './powered-by-housecanary-logo.svg?react';

export const HouseCanaryLogoFullColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogoFullColorSvg, props);

export const HouseCanaryLogoCurrentColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogoCurrentColorSvg, props);

export const HouseCanaryLogomarkFullColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogomarkFullColorSvg, props);

export const HouseCanaryLogomarkCurrentColor = (props: SVGProps) =>
  useSvg(HouseCanaryLogomarkCurrentColorSvg, props);

export const PoweredByHouseCanaryLogo = (props: SVGProps) =>
  useSvg(PoweredByHouseCanaryLogoSvg, props);
