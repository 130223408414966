import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { TabButton } from '@hcs/design-system';
import { Skeleton, SkeletonLoader } from '@hcs/design-system';
import { AvmQualityBadge } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import {
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
  ReportTypes,
  SubjectValuePaths,
  ValuationMethod,
} from '@hcs/types';
import { formatMoney, formatMoneyPerMonth } from '@hcs/utils';

import { CompValueInfoIcon } from '../../features/CompValueInfoIcon';
import { OfferFromReportId } from '../../features/OfferFromReportId';
import {
  useDocumentPatch,
  useReport,
  useReportPermissions,
  useSubjectValueDocument,
} from '../../hooks';
import { useAppraisalSubjectValueDocument } from '../../hooks/useAppraisalSubjectValueDocument';
import { useComparableValueHcSuggestedDocument } from '../../hooks/useComparableValueHcSuggestedDocument';
import { useOfferNowAvailableForReport } from '../../hooks/useOfferNowAvailableForReport';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedMoreThanOne } from '../../utils/reportConfig.utils';
import { DOWNLOAD_REPORT_FEATURES, DownloadReport } from '../DownloadReport';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './ReportValue.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
  showRentalValue: boolean;
  onClickSelectComps: VoidFunction;
}
export const REPORT_VALUE_FEATURES_SALE = [
  ReportFeatures.HcAvm,
  ReportFeatures.AdjustedAvm,
  ReportFeatures.ComparableValue,
  ReportFeatures.UserOpinionOfPrice,
];

export const REPORT_VALUE_FEATURES_RENTAL = [
  ReportFeatures.RentalHcAvm,
  ReportFeatures.RentalComparableValue,
  ReportFeatures.RentalUserOpinionOfPrice,
];

const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const dataHcName = 'report-value';

export const ReportValueAvm = ({
  reportId,
  showRentalValue,
}: {
  reportId: ReportId;
  showRentalValue: boolean;
}) => {
  const { data: report } = useReport(reportId);
  const { data: subjectValueDocument, isInitialLoading } =
    useSubjectValueDocument(reportId, { showRentalValue });
  const valueFormatter = showRentalValue ? formatMoneyPerMonth : formatMoney;
  const avm = subjectValueDocument?.data[ValuationMethod.Avm].value;
  return (
    <>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        <div>
          {isInitialLoading ? (
            <Skeleton dataHcName={`${dataHcName}-value-skeleton`} type="text" />
          ) : (
            valueFormatter(avm?.value || 0)
          )}
        </div>
        {avm && report && (
          <AvmQualityBadge
            dataHcName={`${dataHcName}-avm-quality-badge`}
            avm={avm}
            position={'midRight'}
            showRentalValue={showRentalValue}
            showQuality={report.reportType === ReportTypes.Value}
          />
        )}
      </div>
      <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        {showRentalValue
          ? "HouseCanary's Estimated Rental Value"
          : "HouseCanary's Estimated Value"}
      </div>
    </>
  );
};

export const ReportValueComparable = ({
  reportId,
  showRentalValue,
}: {
  reportId: ReportId;
  showRentalValue: boolean;
}) => {
  const { data: subjectValueDocument, isInitialLoading } =
    useSubjectValueDocument(reportId, { showRentalValue });
  const valueFormatter = showRentalValue ? formatMoneyPerMonth : formatMoney;
  const comparableValue =
    subjectValueDocument?.data[ValuationMethod.Comps].value;
  return (
    <>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        {isInitialLoading ? (
          <Skeleton dataHcName={`${dataHcName}-value-skeleton`} type="text" />
        ) : (
          valueFormatter(comparableValue?.value || 0)
        )}
        <CompValueInfoIcon
          isRental={showRentalValue}
          theme={{
            Button: styles.CompValueInfoIconButton,
          }}
          color={'neutral-dark-10'}
          size={'large'}
        />
      </div>
      <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        {showRentalValue ? 'Comparable Rental Value' : 'Comparable Value'}
      </div>
    </>
  );
};

export const ReportValueHcComparable = ({
  reportId,
  showRentalValue,
}: {
  reportId: ReportId;
  showRentalValue: boolean;
}) => {
  const { data: subjectValueDocument, isInitialLoading } =
    useComparableValueHcSuggestedDocument(reportId);
  const valueFormatter = showRentalValue ? formatMoneyPerMonth : formatMoney;
  const comparableValue =
    subjectValueDocument?.data[ValuationMethod.Comps].value;
  return (
    <>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        {isInitialLoading ? (
          <Skeleton dataHcName={`${dataHcName}-value-skeleton`} type="text" />
        ) : (
          valueFormatter(comparableValue?.value || 0)
        )}
        <CompValueInfoIcon
          isRental={showRentalValue}
          theme={{
            Button: styles.CompValueInfoIconButton,
          }}
          color={'neutral-dark-10'}
          size={'large'}
        />
      </div>
      <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        {showRentalValue
          ? 'HouseCanary Comparable Rental Value'
          : 'HouseCanary Comparable Value'}
      </div>
    </>
  );
};

export const ReportValueAppraisal = ({ reportId }: { reportId: ReportId }) => {
  const { data: subjectValueDocument, isInitialLoading } =
    useAppraisalSubjectValueDocument(reportId);
  const comparableValue =
    subjectValueDocument?.data[ValuationMethod.UserEntered].value;
  return (
    <>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        {isInitialLoading ? (
          <Skeleton dataHcName={`${dataHcName}-value-skeleton`} type="text" />
        ) : (
          formatMoney(comparableValue?.value || 0)
        )}
      </div>
      <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        Appraisal Subject Value
      </div>
    </>
  );
};

export const ReportValueAppraisalComps = ({
  reportId,
}: {
  reportId: ReportId;
}) => {
  const { data: subjectValueDocument, isInitialLoading } =
    useAppraisalSubjectValueDocument(reportId);
  const comparableValue =
    subjectValueDocument?.data[ValuationMethod.Comps].value;
  return (
    <>
      <div className={styles.Value} data-hc-name={`${dataHcName}-value`}>
        {isInitialLoading ? (
          <Skeleton dataHcName={`${dataHcName}-value-skeleton`} type="text" />
        ) : (
          formatMoney(comparableValue?.value || 0)
        )}
      </div>
      <div className={styles.Label} data-hc-name={`${dataHcName}-label`}>
        Appraisal Comparable Value
      </div>
    </>
  );
};

export const ReportValue = ({
  reportId,
  className,
  showRentalValue,
  onClickSelectComps,
}: Props) => {
  const { data: reportConfig } = useReportConfig(reportId);
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: reportPermissions } = useReportPermissions(reportId);
  const { active, handleOpen, handleClose, handleToggle } = useActiveState();
  const { isInitialLoading, data: subjectValueDocument } =
    useSubjectValueDocument(reportId, {
      showRentalValue,
    });
  const { data: offerNowAvailableForReport } =
    useOfferNowAvailableForReport(reportId);
  // Use local state to control the active tab on read-only supports
  const [readOnlyActiveTab, setReadOnlyActiveTab] = useState<
    ValuationMethod.Avm | ValuationMethod.Comps | undefined
  >(undefined);
  const hcAvmSelected =
    subjectValueDocument?.data.selectedValue === ValuationMethod.Avm ||
    readOnlyActiveTab === ValuationMethod.Avm;
  const comparableValueSelected =
    (subjectValueDocument?.data.selectedValue === ValuationMethod.Comps ||
      readOnlyActiveTab === ValuationMethod.Comps) &&
    readOnlyActiveTab !== ValuationMethod.Avm;
  const collapsibleState =
    !hcAvmSelected && !comparableValueSelected && !isInitialLoading;
  useEffect(() => {
    if (collapsibleState) {
      handleClose();
    }
  }, [collapsibleState]);

  const handleSelectValue = (
    valuationMethod: ValuationMethod.Avm | ValuationMethod.Comps,
  ) => {
    if (reportPermissions?.isEditable) {
      if (subjectValueDocument) {
        documentPatchMutation.mutate({
          reportId,
          document: subjectValueDocument,
          operations: [
            {
              op: 'replace',
              path: PATH_SELECTED_VALUE,
              value: valuationMethod,
            },
          ],
        });
      }
    } else {
      setReadOnlyActiveTab(valuationMethod);
    }
  };
  const showTabs = reportFeaturesSupportedMoreThanOne(reportConfig, [
    ReportFeatures.HcAvm,
    ReportFeatures.ComparableValue,
  ]);

  /**
   * Candidate for React Unit Testing:
   * ReportValue component's value changes depending on how users value reports. The tooltip should also change with values.
   *
   * Default Value    - ReportValue should show AVM Value. ReportValue should not be in a collapsed state. The tooltip should reflect AVM Value ranges.
   * Adjusted AVM     - ReportValue should show AVM Value. ReportValue should be in a collapsed state. The tooltip should reflect AVM Value ranges.
   * Opinion of Price - ReportValue should show AVM Value. ReportValue should be in a collapsed state. The tooltip should reflect AVM Value ranges.
   * Comp Value       - ReportValue should show Comp Value. Report Value should not be in a collapsed state. The tooltip should reflect Comp Value ranges.
   */
  const reportValue =
    subjectValueDocument?.data[
      comparableValueSelected ? ValuationMethod.Comps : ValuationMethod.Avm
    ].value.value;
  const isHighConfidence =
    subjectValueDocument?.data[ValuationMethod.Avm].calculatedFields
      .avmConfidence === 'HIGH' && !comparableValueSelected;

  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={
        showRentalValue ? 'Selected Rental Value' : 'Selected Value'
      }
      className={classNames(
        styles.ReportValue,
        {
          [styles.collapsedState]: collapsibleState && !active,
          [styles.isHighConfidence]: isHighConfidence,
          [styles.avm]: !isInitialLoading && comparableValueSelected,
          [styles.comps]: !isInitialLoading && !comparableValueSelected,
          [styles.hiddenTabs]: !showTabs,
        },
        className,
      )}
    >
      <SkeletonLoader
        dataHcName={`${dataHcName}-skeleton`}
        isLoading={isInitialLoading}
      >
        {collapsibleState && (
          <div
            data-hc-name={`${dataHcName}-collapse-toggle`}
            onClick={handleToggle}
            className={styles.CollapseChevron}
          >
            <DirectionalChevron
              direction={active ? 'up' : 'down'}
              size="sm"
              smallIcon
            />
          </div>
        )}
        {(collapsibleState && active) || !collapsibleState ? (
          <>
            <div className={styles.Content}>
              <div className={styles.ReportValueSection}>
                {comparableValueSelected ? (
                  <ReportValueComparable
                    reportId={reportId}
                    showRentalValue={showRentalValue}
                  />
                ) : (
                  <ReportValueAvm
                    reportId={reportId}
                    showRentalValue={showRentalValue}
                  />
                )}
              </div>

              <div className={styles.ActionRow}>
                {comparableValueSelected &&
                !reportValue &&
                reportPermissions?.isEditable ? (
                  <Button
                    dataHcName={`${dataHcName}-select-comps`}
                    label={
                      showRentalValue ? 'Choose Rental Comps' : 'Choose Comps'
                    }
                    onClick={onClickSelectComps}
                  />
                ) : !showRentalValue && offerNowAvailableForReport ? (
                  <OfferFromReportId
                    reportId={reportId}
                    theme={{
                      Button: styles.OfferNowButton,
                    }}
                  />
                ) : (
                  <ReportFeaturesSupported
                    reportId={reportId}
                    reportFeatures={DOWNLOAD_REPORT_FEATURES}
                  >
                    <DownloadReport
                      reportId={reportId}
                      trigger={
                        <Button
                          label="Download Report"
                          dataHcName={`${dataHcName}-download`}
                          secondary
                        />
                      }
                    />
                  </ReportFeaturesSupported>
                )}
              </div>
            </div>
            {showTabs && (
              <div data-hc-name={`${dataHcName}-tabs`} className={styles.Tabs}>
                <TabButton
                  label={
                    showRentalValue ? (
                      <>
                        HouseCanary
                        <br />
                        Rental Value
                      </>
                    ) : (
                      'HouseCanary Value'
                    )
                  }
                  dataHcName={`${dataHcName}-tab-${ValuationMethod.Avm}`}
                  dataHcEventType={
                    hcAvmSelected ? undefined : MeaningfulEventTypes.Goal
                  }
                  dataHcEventName={
                    hcAvmSelected
                      ? undefined
                      : showRentalValue
                        ? 'Selected Rental AVM'
                        : 'Selected AVM'
                  }
                  onClick={() => {
                    handleSelectValue(ValuationMethod.Avm);
                  }}
                  className={classNames(styles.avm, {
                    [styles.selected]: !comparableValueSelected,
                  })}
                  tabPosition="bottom"
                  isActive={!comparableValueSelected}
                  curveLeft={false}
                  curveRight={!comparableValueSelected}
                  blue
                />
                <TabButton
                  dataHcEventType={
                    comparableValueSelected
                      ? undefined
                      : MeaningfulEventTypes.Goal
                  }
                  dataHcEventName={
                    comparableValueSelected
                      ? undefined
                      : showRentalValue
                        ? 'Selected Rental Comparable Value'
                        : 'Selected Comparable Value'
                  }
                  label={
                    reportPermissions?.isEditable ? (
                      showRentalValue ? (
                        <>
                          Use Rental
                          <br />
                          Comp Value
                        </>
                      ) : (
                        'Use Comp Value'
                      )
                    ) : showRentalValue ? (
                      <>Rental Comp Value</>
                    ) : (
                      'Comp Value'
                    )
                  }
                  dataHcName={`${dataHcName}-tab--${ValuationMethod.Comps}`}
                  className={classNames(styles.comps, {
                    [styles.selected]: comparableValueSelected,
                  })}
                  onClick={() => {
                    handleSelectValue(ValuationMethod.Comps);
                  }}
                  tabPosition="bottom"
                  isActive={comparableValueSelected}
                  curveRight={false}
                  curveLeft={comparableValueSelected}
                />
              </div>
            )}
          </>
        ) : (
          <div
            data-hc-name={`${dataHcName}-collapsed-content`}
            onClick={handleOpen}
            className={styles.Content}
          >
            HouseCanary’s value and comp value
          </div>
        )}
      </SkeletonLoader>
    </div>
  );
};
