import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { isUserAssignedRole } from '@hcs/auth';
import { useAccount } from '@hcs/authn';
import { Checkbox } from '@hcs/design-system';
import { RadioButton } from '@hcs/design-system';
import { TextArea } from '@hcs/design-system';
import { Ledger } from '@hcs/design-system';
import {
  MeaningfulEventTypes,
  ReportFeatures,
  ReportId,
  Roles,
  SubjectValuePaths,
  ValuationMethod,
  ValueComments,
} from '@hcs/types';
import { formatMoney, formatMoneyPerMonth } from '@hcs/utils';

import {
  VALUATION_METHOD_LABELS as VALUATION_METHOD_LABELS_SALE,
  VALUATION_METHOD_LABELS_RENTAL,
} from '../../constants';
import {
  useDocumentPatch,
  useReportPermissions,
  useSubjectValueDocument,
} from '../../hooks';

import styles from './UserOpinionOfPriceForm.module.css';

interface Props {
  reportId: ReportId;
  showRentalValue: boolean;
  className?: string;
}
export const USER_OPINION_OF_PRICE_FORM_FEATURES_SALE: ReportFeatures[] = [
  ReportFeatures.UserOpinionOfPrice,
];

export const USER_OPINION_OF_PRICE_FORM_FEATURES_RENTAL: ReportFeatures[] = [
  ReportFeatures.RentalUserOpinionOfPrice,
];
const PATH_SELECTED_VALUE: SubjectValuePaths = '/data/selectedValue';
const PATH_USER_VALUE: SubjectValuePaths = `/data/${ValuationMethod.UserEntered}/value/value`;
const PATH_USER_VALUE_REASON: SubjectValuePaths = `/data/${ValuationMethod.UserEntered}/valueComments/reason`;
const PATH_USER_VALUE_COMMENTS: SubjectValuePaths = `/data/${ValuationMethod.UserEntered}/valueComments/comments`;
const dataHcName = 'user-opinion-of-price-form';
export const dataHcEventSectionOpinionOfPriceSale = 'Opinion of Price';
export const dataHcEventSectionOpinionOfPriceRental = 'Opinion of Rental Price';
export const UserOpinionOfPriceForm = ({
  reportId,
  showRentalValue,
  className,
}: Props) => {
  const documentPatchMutation = useDocumentPatch(reportId);
  const { data: account } = useAccount();
  const isBrokerRole = isUserAssignedRole([Roles.Broker], account?.legacy);
  const { data: subjectValueDocument } = useSubjectValueDocument(reportId, {
    showRentalValue,
  });
  const { data: reportPermissions } = useReportPermissions(reportId);
  const valueComments =
    subjectValueDocument?.data[ValuationMethod.UserEntered].valueComments;
  const [comments, setComments] = useState(valueComments?.comments || '');
  useEffect(() => {
    const newComments = valueComments?.comments || '';
    if (comments !== newComments) {
      setComments(newComments);
    }
  }, [valueComments?.comments]);
  const VALUATION_METHOD_LABELS = showRentalValue
    ? VALUATION_METHOD_LABELS_RENTAL
    : VALUATION_METHOD_LABELS_SALE;
  const handleChooseReason = (valueReason: ValueComments['reason']) => {
    if (subjectValueDocument && reportPermissions?.isEditable) {
      documentPatchMutation.mutate({
        reportId,
        document: subjectValueDocument,
        operations: [
          {
            op: 'add',
            path: PATH_USER_VALUE_REASON,
            value: valueReason,
          },
        ],
      });
    }
  };
  const handleSubmitComment = (comments: string) => {
    if (subjectValueDocument) {
      documentPatchMutation.mutate({
        reportId,
        document: subjectValueDocument,
        operations: [
          {
            op: 'add',
            path: PATH_USER_VALUE_COMMENTS,
            value: comments,
          },
        ],
      });
    }
  };
  const userValueDisabled = !valueComments?.reason && !comments;
  const selectValueDisabled =
    !subjectValueDocument?.data[ValuationMethod.UserEntered].value.value ||
    userValueDisabled ||
    !reportPermissions?.isEditable;
  const selectValueChecked =
    subjectValueDocument?.data.selectedValue === ValuationMethod.UserEntered;
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-section={
        showRentalValue
          ? dataHcEventSectionOpinionOfPriceRental
          : dataHcEventSectionOpinionOfPriceSale
      }
      className={classNames(styles.UserOpinionOfPriceForm, className)}
    >
      <div className={styles.ColumnLeft}>
        <div
          data-hc-name={`${dataHcName}-avm-underpriced`}
          className={classNames(styles.RadioRow, {
            [styles.disabled]: !reportPermissions?.isEditable,
          })}
          onClick={() => handleChooseReason('avm-under')}
        >
          <div
            data-hc-name={`${dataHcName}-row-label`}
            className={styles.RowLabel}
          >
            AVM is underpriced
          </div>
          <RadioButton
            large
            disabled={!reportPermissions?.isEditable}
            checked={valueComments?.reason === 'avm-under'}
            value="avm-under"
            // Click event on the row will control checked state
            onChange={() => undefined}
            dataHcName={`${dataHcName}-avm-underpriced-radio`}
          />
        </div>
        <div
          data-hc-name={`${dataHcName}-avm-overpriced`}
          className={classNames(styles.RadioRow, {
            [styles.disabled]: !reportPermissions?.isEditable,
          })}
          onClick={() => handleChooseReason('avm-over')}
        >
          <div
            data-hc-name={`${dataHcName}-row-label`}
            className={styles.RowLabel}
          >
            AVM is overpriced
          </div>
          <RadioButton
            large
            disabled={!reportPermissions?.isEditable}
            dataHcName={`${dataHcName}-avm-overpriced-radio`}
            checked={valueComments?.reason === 'avm-over'}
            value="avm-over"
            // Click event on the row will control checked state
            onChange={() => undefined}
          />
        </div>
        <div
          className={classNames(styles.RadioRow, {
            [styles.disabled]: !reportPermissions?.isEditable,
          })}
          onClick={() => handleChooseReason('missing-details')}
        >
          <div
            data-hc-name={`${dataHcName}-row-label`}
            className={styles.RowLabel}
          >
            Missing property features
          </div>
          <RadioButton
            large
            disabled={!reportPermissions?.isEditable}
            dataHcName={`${dataHcName}-missing-features`}
            checked={valueComments?.reason === 'missing-details'}
            value="missing-details"
            // Click event on the row will control checked state
            onChange={() => undefined}
          />
        </div>
        <div className={styles.CommentRow}>
          <div
            data-hc-name={`${dataHcName}-row-label`}
            className={styles.RowLabel}
          >
            Other
          </div>
          <TextArea
            disabled={!reportPermissions?.isEditable}
            value={comments}
            placeholder="Your comments can be typed here"
            dataHcName={`${dataHcName}-comment`}
            onChange={setComments}
            onBlur={(e) => {
              handleSubmitComment(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.ColumnRight}>
        <Ledger
          formatter={showRentalValue ? formatMoneyPerMonth : formatMoney}
          items={[
            {
              arrayKey: ValuationMethod.Avm,
              label: VALUATION_METHOD_LABELS[ValuationMethod.Avm],
              value:
                subjectValueDocument?.data[ValuationMethod.Avm].value.value,
            },
            {
              arrayKey: ValuationMethod.Comps,
              label: VALUATION_METHOD_LABELS[ValuationMethod.Comps],
              value:
                subjectValueDocument?.data[ValuationMethod.Comps].value.value ||
                0,
            },
          ]}
          total={{
            label: VALUATION_METHOD_LABELS[ValuationMethod.UserEntered],
            editable: true,
            disabled: userValueDisabled || !reportPermissions?.isEditable,
            onBlur: (value) => {
              if (subjectValueDocument) {
                documentPatchMutation.mutate({
                  reportId,
                  document: subjectValueDocument,
                  operations: [
                    {
                      op: 'add',
                      path: PATH_USER_VALUE,
                      value,
                    },
                  ],
                });
              }
            },
            value:
              subjectValueDocument?.data[ValuationMethod.UserEntered].value
                .value,
          }}
          dataHcName={`${dataHcName}-price-section`}
        />
        <Checkbox
          disabled={selectValueDisabled}
          checked={selectValueChecked}
          dataHcEventName={
            selectValueDisabled
              ? undefined
              : selectValueChecked
                ? `Deselected User Opinion of ${
                    showRentalValue ? 'Rental ' : ''
                  }Price`
                : `Selected User Opinion of ${
                    showRentalValue ? 'Rental ' : ''
                  }Price`
          }
          dataHcEventType={
            selectValueDisabled ? undefined : MeaningfulEventTypes.Goal
          }
          onChange={(newChecked) => {
            if (subjectValueDocument) {
              documentPatchMutation.mutate({
                reportId,
                document: subjectValueDocument,
                operations: [
                  {
                    op: 'add',
                    path: PATH_SELECTED_VALUE,
                    value: newChecked
                      ? ValuationMethod.UserEntered
                      : ValuationMethod.Avm,
                  },
                ],
              });
            }
          }}
          dataHcName={`${dataHcName}-use-for-report`}
          label="Use your opinion of price for report?"
          detail={
            !isBrokerRole &&
            'This value is not generated or endorsed by HouseCanary, and it does not represent a broker price opinion (BPO) or a formal appraisal.'
          }
        />
      </div>
    </div>
  );
};
