import { useQuery } from '@tanstack/react-query';

import { SelfServiceApi } from '../api';

const QUERY_KEY_SELF_SERVICE_PLANS = 'QUERY_KEY_SELF_SERVICE_PLANS';
export const useSelfServicePlans = () => {
  return useQuery([QUERY_KEY_SELF_SERVICE_PLANS], async () => {
    return (await SelfServiceApi.plans()).plans;
  });
};
