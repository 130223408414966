import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import {
  CardViewIcon,
  CompactViewIcon,
  FlexScroll,
  IconButton,
} from '@hcs/design-system';
import { InfoTooltip } from '@hcs/design-system';
import { NoContent } from '@hcs/design-system';
import { Disclosure } from '@hcs/design-system';
import { MlsAttribution } from '@hcs/mls-lookup';
import {
  PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_RENTAL,
  PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE,
  PropertyCardProps,
} from '@hcs/property-state';
import { CustomCellData } from '@hcs/property-state';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { PropertyStateCardPropsCreator } from '@hcs/property-state';
import { PropertyStateCardList } from '@hcs/property-state';
import {
  PropertyStateTable,
  PropertyStateTableDatum,
  PropertyStateTableProps,
} from '@hcs/property-state';
import { isCompField } from '@hcs/property-state';
import {
  Order,
  PropertySpatialSearchListQueryVariables,
  SpatialSortField,
} from '@hcs/types';
import { PropertyListSortOption } from '@hcs/types';
import {
  PropertyStateCerberusInput,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { usePropertySpatialSearchList } from '../../hooks/usePropertySpatialSearchList';

import { SortPopover } from './SortPopover';

import styles from './PropertySpatialSearchList.module.css';

const getSortFields = (
  fieldsArg: PropertyCardProps['fields'],
): PropertyListSortOption[] => {
  const sortOptions: PropertyListSortOption[] = [];
  const addFieldsToSortOptions = (fields: PropertyCardProps['fields']) => {
    fields?.forEach((field) => {
      if (Array.isArray(field)) {
        return addFieldsToSortOptions(field);
      } else if (!isCompField(field)) {
        const { spatialSortField, label } = PROPERTY_STATE_FIELD_CONFIGS[field];
        if (spatialSortField) {
          sortOptions.push({
            field: spatialSortField,
            order: Order.Ascending,
            label: `${label} - Ascending`,
          });
          sortOptions.push({
            field: spatialSortField,
            order: Order.Descending,
            label: `${label} - Descending`,
          });
        }
      }
    });
  };
  addFieldsToSortOptions(fieldsArg);
  return sortOptions;
};

const getDefaultFields = <
  D extends CustomCellData = undefined,
>(): PropertyStateTableProps<D>['fields'] => [
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentPrice,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatusDate,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.currentStatus,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bedrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.bathrooms,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.livingArea,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.lotSize,
  },
  {
    type: 'propertyStateFields',
    value: PropertyStateFields.yearBuilt,
  },
];

export interface PropertySpatialSearchListProps<
  D extends CustomCellData = undefined,
> {
  listType: 'card' | 'table';
  includeListTypeToggle?: boolean;
  onChangeListType?: (listType: 'card' | 'table') => void;
  // scrollContainerId is needed if multiple instances are rendered simultaneously
  scrollContainerId?: string;
  // Optional description for a InfoTooltip that is rendered next to results
  resultsInfo?: string;
  spatialSearchVariables: PropertySpatialSearchListQueryVariables;
  onPropertyEnter?: (
    propertyStateCerberusInput: PropertyStateCerberusInput,
  ) => void;
  onPropertyLeave?: VoidFunction;
  customDataCreator?:
    | D
    | ((propertyStateCerberusInput: PropertyStateCerberusInput | null) => D);
  propertyStateCardProps?: PropertyStateCardPropsCreator;
  propertyStateTableProps?: Omit<
    PropertyStateTableProps<D>,
    'data' | 'dataHcName'
  >;
}
const dataHcName = 'property-spatial-search-list';
export const PropertySpatialSearchList = <
  D extends CustomCellData = undefined,
>({
  listType: listTypeProp,
  resultsInfo,
  includeListTypeToggle,
  scrollContainerId = 'property-spatial-search-list-scroll-element',
  spatialSearchVariables: spatialSearchVariablesProp,
  onPropertyEnter,
  onPropertyLeave,
  onChangeListType,
  customDataCreator,
  propertyStateCardProps,
  propertyStateTableProps,
}: PropertySpatialSearchListProps<D>) => {
  const [isBottom, setIsBottom] = useState(false);
  const [listType, setListType] =
    useState<PropertySpatialSearchListProps['listType']>(listTypeProp);
  useEffect(() => {
    setListType(listTypeProp);
  }, [listTypeProp]);
  const cardSortOptions = useMemo(() => {
    const p =
      typeof propertyStateCardProps === 'function'
        ? propertyStateCardProps({}) // No need to worry about any props that dependon
        : propertyStateCardProps;

    return getSortFields(
      p?.fields ||
        (p?.isRental
          ? PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_RENTAL
          : PROPERTY_SUMMARY_DETAILS_DEFAULT_FIELDS_SALE),
    );
  }, [propertyStateCardProps]);
  const [sortOption, setSortOption] = useState<PropertyListSortOption | null>(
    cardSortOptions.find(
      (o) =>
        o.field === spatialSearchVariablesProp.sort?.field &&
        o.order === spatialSearchVariablesProp.sort.order,
    ) ||
      cardSortOptions.find(
        (o) =>
          o.field === SpatialSortField.ListDate && o.order === Order.Descending,
      ) ||
      null,
  );
  const spatialSearchVariables = useMemo(() => {
    return {
      ...spatialSearchVariablesProp,
      sort: sortOption?.field && {
        field:
          sortOption?.field === 'RENTAL_YIELD_BY_LIST_PRICE'
            ? SpatialSortField.RentalYield
            : sortOption.field,
        order: sortOption?.order,
      },
    };
  }, [sortOption, spatialSearchVariablesProp]);
  const {
    data,
    isInitialLoading,
    fetchNextPage: fetchNextPageUndebounced,
  } = usePropertySpatialSearchList(spatialSearchVariables);
  const fetchNextPage = useCallback(
    debounce(fetchNextPageUndebounced, 1000, { leading: true }),
    [fetchNextPageUndebounced],
  );
  const mlsIds = useMemo(() => {
    const ids: Record<string, boolean> = {};
    if (data?.propertyHits) {
      data.propertyHits.forEach((hit) => {
        const mlsId = hit.complexFieldsSale?.currentHcMlsId;
        if (mlsId && !ids[mlsId]) {
          ids[mlsId] = true;
        }
      });
    }
    return Object.keys(ids);
  }, [data?.propertyHits]);

  const sort = spatialSearchVariables.sort;
  const propertyStateTableFields = useMemo<
    PropertyStateTableProps<D>['fields']
  >(() => {
    const fields: PropertyStateTableProps<D>['fields'] = [];
    (propertyStateTableProps?.fields || getDefaultFields<D>()).forEach(
      (field) => {
        if (field.type === 'propertyStateFields') {
          const { spatialSortField, label } =
            PROPERTY_STATE_FIELD_CONFIGS[field.value];
          fields.push({
            ...field,
            headerCellProps: {
              ...field.headerCellProps,
              sortable: !!spatialSortField,
              sort:
                sort?.field === spatialSortField
                  ? sort?.order === 'ASCENDING'
                    ? 'ASC'
                    : 'DESC'
                  : undefined,
              onClick: () => {
                const alreadySortedByField = sort?.field === spatialSortField;
                const sortOrder = alreadySortedByField
                  ? sort?.order === 'ASCENDING'
                    ? Order.Descending
                    : Order.Ascending
                  : Order.Descending;
                if (spatialSortField) {
                  setSortOption({
                    field: spatialSortField,
                    order: sortOrder,
                    label: `${label} - ${
                      sortOrder === Order.Ascending ? 'Ascending' : 'Descending'
                    }`,
                  });
                }
                field.headerCellProps?.onClick?.();
              },
            },
          });
        } else {
          fields.push(field);
        }
      },
    );
    return fields;
  }, [sort, propertyStateTableProps, cardSortOptions]);
  const propertyStateCerberusInputs = data?.propertyHits?.map((hit) =>
    hit.hcAddressId
      ? {
          propertyStateType: PropertyStateType.Core,
          cerberusInput: {
            hcAddressId: hit.hcAddressId,
          },
        }
      : null,
  );

  const noContent = !isInitialLoading && !data?.propertyHits?.length && (
    <NoContent
      style={{ marginTop: '30px' }}
      dataHcName={`${dataHcName}-no-content`}
      margins={listType === 'table'}
    >
      No Properties Found
    </NoContent>
  );

  return (
    <FlexScroll
      dataHcName={dataHcName}
      header={{
        height: 40,
        content: (
          <div className={styles.Header}>
            {listType === 'card' && (
              <SortPopover
                selected={sortOption}
                sortOptions={cardSortOptions}
                onSelect={setSortOption}
                dataHcName={`${dataHcName}-sort-popover`}
              />
            )}
            <span
              className={styles.ResultCount}
              data-hc-name={`${dataHcName}-result-count`}
            >
              {resultsInfo && (
                <InfoTooltip
                  dataHcName={`${dataHcName}-result-info`}
                  description={resultsInfo}
                />
              )}
              {formatNumber(data?.totalCount)}{' '}
              {data?.totalCount !== 1 ? 'Results' : 'Result'}
            </span>
            {includeListTypeToggle && (
              <IconButton
                dataHcName={`${dataHcName}-listTypeToggle`}
                icon={
                  listType === 'table' ? <CardViewIcon /> : <CompactViewIcon />
                }
                onClick={() => {
                  const newListType = listType === 'table' ? 'card' : 'table';
                  setListType(newListType);
                  onChangeListType?.(newListType);
                }}
              />
            )}
          </div>
        ),
      }}
    >
      {listType === 'card' ? (
        <PropertyStateCardList
          dataHcName={`${dataHcName}-cards`}
          className={styles.Content}
          propertyStateCardProps={(propertyStateCerberusInput) => {
            const cProps = propertyStateCardProps
              ? typeof propertyStateCardProps === 'function'
                ? propertyStateCardProps(propertyStateCerberusInput)
                : propertyStateCardProps
              : {};
            return {
              ...cProps,
              onMouseEnter: () => {
                onPropertyEnter?.(propertyStateCerberusInput);
                cProps.onMouseEnter?.();
              },
            };
          }}
          onScrollToBottom={fetchNextPage}
          onScroll={(newIsBottom) => {
            if (newIsBottom !== isBottom) {
              setIsBottom(newIsBottom);
            }
          }}
          scrollContainerId={scrollContainerId}
          propertyStateCerberusInputs={propertyStateCerberusInputs}
        >
          {noContent}
        </PropertyStateCardList>
      ) : (
        <PropertyStateTable
          {...propertyStateTableProps}
          dataHcName={`${dataHcName}-table`}
          fields={propertyStateTableFields}
          data={
            propertyStateCerberusInputs?.map((propertyStateCerberusInput) => {
              const customData =
                typeof customDataCreator === 'function'
                  ? customDataCreator(propertyStateCerberusInput)
                  : customDataCreator;
              const datum: PropertyStateTableDatum<D> = {
                propertyStateCerberusInput,
                customData,
                tableRowProps: {
                  onMouseEnter: () => {
                    if (propertyStateCerberusInput) {
                      onPropertyEnter?.(propertyStateCerberusInput);
                    }
                  },
                  onMouseLeave: onPropertyLeave,
                },
              };
              return datum;
            }) || []
          }
          infiniteScroll={{
            onScrollToBottom: fetchNextPage,
            footer: (
              <>
                {noContent}
                <Disclosure dataHcName="disclosure" includeAVMSection={false} />
                {mlsIds.map((mlsId) => (
                  <MlsAttribution
                    key={`${dataHcName}-mls-attribution-${mlsId}`}
                    mlsId={parseInt(mlsId)}
                  />
                ))}
              </>
            ),
          }}
        />
      )}
    </FlexScroll>
  );
};
