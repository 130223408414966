import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  SaveSftpSettings,
  SftpSettings,
  SftpSettingsFormData,
} from '@hcs/types';
import { logException } from '@hcs/utils';

import { OrderManagerApi } from '../api/orderManagerApi';
import { PLACEHOLDER_PASSWORD } from '../constants';

import { QUERY_KEY_SFTP_SETTINGS } from './useSftpSettings';

const formDataToSaveSftpSettings = (
  formData: SftpSettingsFormData,
): SaveSftpSettings | null => {
  const { sftpHostname, sftpUsername, sftpPassword, sftpPort, includeJson } =
    formData;
  if (
    sftpHostname !== null &&
    sftpUsername !== null &&
    sftpPassword !== null &&
    sftpPort !== null
  ) {
    return {
      sftpHostname,
      sftpUsername,
      sftpPassword,
      sftpPort,
      includeJson,
    };
  }
  return null;
};

interface Params {
  shouldCreate: boolean;
  formData: SftpSettingsFormData;
}

export const useSaveSftpSettings = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<SftpSettings, AxiosError, Params>(
    async ({ shouldCreate, formData }) => {
      const saveSftpSettings = formDataToSaveSftpSettings(formData);
      if (saveSftpSettings !== null) {
        let newSettings = null;
        if (shouldCreate) {
          newSettings =
            await OrderManagerApi.createSftpSettings(saveSftpSettings);
        } else {
          const { sftpPassword, ...formDataNoPassword } = saveSftpSettings;
          const payload =
            sftpPassword === PLACEHOLDER_PASSWORD
              ? formDataNoPassword
              : saveSftpSettings;
          newSettings = await OrderManagerApi.updateSftpSettings(payload);
        }
        return newSettings;
      } else {
        logException(
          'useSaveSftpSettings: one of the required values was null',
        );
        throw new Error('One of the required fields is missing');
      }
    },
    {
      onSuccess: (sftpResponse) => {
        queryClient.setQueryData<SftpSettings>(
          [QUERY_KEY_SFTP_SETTINGS],
          sftpResponse,
        );
        toastOpen({
          type: 'success',
          title: 'SFTP Settings Saved',
        });
      },
    },
  );
};
