import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { OptionalEngagementProps } from '@hcs/types';

import { CheckIcon } from '../../../../svgs/icons/indicator';

import styles from './RadioButton.module.css';

export interface RadioButtonProps<T> extends OptionalEngagementProps {
  dataHcName: string;
  label?: ReactNode | string;
  value: T;
  checked: boolean;
  onChange: (value: T) => void;
  disabled?: boolean;
  className?: string;
  large?: boolean;
}
export const RadioButton = <
  T extends string | number | boolean | null | undefined,
>({
  dataHcName,
  dataHcEventName,
  dataHcEventSection,
  dataHcEventType,
  disabled,
  label,
  value,
  onChange,
  className,
  checked,
  large,
}: RadioButtonProps<T>) => {
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-name={dataHcEventName}
      data-hc-event-section={dataHcEventSection}
      data-hc-event-type={dataHcEventType}
      onClick={disabled ? undefined : () => onChange(value)}
      className={classNames(styles.RadioButton, className, {
        [styles.disabled]: disabled,
      })}
    >
      <input type="radio" checked={checked} readOnly />
      <div
        className={classNames(styles.Radio, {
          [styles.large]: large,
        })}
      >
        {large && checked && (
          <CheckIcon
            color="neutral-light-10"
            size={40}
            dataHcName={`${dataHcName}-check-icon`}
          />
        )}
      </div>
      {label && <span data-hc-name={`${dataHcName}-label`}>{label}</span>}
    </div>
  );
};
