import React, { useMemo } from 'react';

import {
  AlertIcon,
  Anchor,
  AnimatedCheck,
  Checkbox,
  Tooltip,
} from '@hcs/design-system';
import {
  EXPERIENCE_FLAG_CONFIGS,
  useExperienceFlagsWithoutDevTool,
} from '@hcs/experience-flags';
import { useExperienceFlagsDevLocalStorageState } from '@hcs/experience-flags';
import {
  useExperienceFlags,
  useExperienceFlagsDevtools,
  useExperienceFlagsFeatureFlagControlled,
  useExperienceFlagsPreferenceControlled,
} from '@hcs/experience-flags';
import { useExperienceFlagsSlice } from '@hcs/experience-flags';
import {
  ExperienceFlagIds,
  HcsDevToolItemTypeDefault,
  HcsDevToolItemTypeSwitch,
} from '@hcs/types';
import { formatSnakeCase } from '@hcs/utils';

import { HcsDevToolsPortal } from '../HcsDevToolsPortal';

import styles from './ExperienceFlagsDevTool.module.css';

type Item = HcsDevToolItemTypeSwitch<boolean> | HcsDevToolItemTypeDefault;

const experienceFlagIds = Object.keys(
  EXPERIENCE_FLAG_CONFIGS,
) as ExperienceFlagIds[];

const dataHcName = 'experience-flags-dev-tool';
const LOCAL_STORAGE_KEY = dataHcName;
export const ExperienceFlagsDevTool = () => {
  const {
    actions: { experienceFlagSet, experienceFlagsResetAll },
  } = useExperienceFlagsSlice();
  const devtoolsState = useExperienceFlagsDevtools();
  const expFlagsNoDevTool = useExperienceFlagsWithoutDevTool();
  const expFlagsFeatureFlags = useExperienceFlagsFeatureFlagControlled();
  const expFlagsPreferences = useExperienceFlagsPreferenceControlled();

  const { actions: localStorageActions } =
    useExperienceFlagsDevLocalStorageState();
  const saveToLocalStorage =
    localStorageActions.getItem(LOCAL_STORAGE_KEY) === 'true';
  const experienceFlagsWithOverrides = useExperienceFlags();
  const items: Item[] = useMemo(() => {
    const v: Item[] = [];
    experienceFlagIds.sort().forEach((s) => {
      const experienceFlagId = s as ExperienceFlagIds;
      if (!(experienceFlagId in EXPERIENCE_FLAG_CONFIGS)) {
        console.warn(`Unsupported Experience Flag: ${experienceFlagId}`);
        return;
      }
      const {
        description,
        displayName: displayNameConfig,
        jiraTickets,
        figmaLinks,
      } = EXPERIENCE_FLAG_CONFIGS[experienceFlagId];
      const displayName =
        displayNameConfig || formatSnakeCase(experienceFlagId);
      const isOverriddenByFeatureFlag =
        experienceFlagId in expFlagsFeatureFlags;
      const isOverriddenByPreference = experienceFlagId in expFlagsPreferences;
      if (experienceFlagId in experienceFlagsWithOverrides) {
        const devToolValue = devtoolsState[experienceFlagId]?.value;
        const content = (
          <div className={styles.ExperienceFlagItem}>
            <div className={styles.CheckCell}>
              {devToolValue != null &&
                devToolValue !== expFlagsNoDevTool[experienceFlagId] && (
                  <Tooltip
                    dataHcName={`${dataHcName}-is-controlled`}
                    trigger={<AnimatedCheck className={styles.GreenCheck} />}
                    label={
                      <span>
                        This devtool is changing the value of this flag
                      </span>
                    }
                  />
                )}
            </div>
            <Tooltip
              dataHcName={`${dataHcName}-tooltip`}
              trigger={<span>{displayName} </span>}
              label={
                <>
                  <div className={styles.TooltipLabel}>{displayName}</div>
                  <p className={styles.TooltipDescription}>{description}</p>
                  {!!jiraTickets?.length && (
                    <div className={styles.TooltipRow}>
                      <div className={styles.TooltipRowLabel}>JIRA:</div>
                      {jiraTickets?.map((l) => {
                        return (
                          <a
                            href={`https://housecanary.atlassian.net/browse/${l}`}
                            className={styles.TooltipValue}
                            key={l}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {l}
                          </a>
                        );
                      })}
                    </div>
                  )}
                  {!!figmaLinks && (
                    <div className={styles.TooltipRow}>
                      <div className={styles.TooltipRowLabel}>Figma:</div>
                      {figmaLinks.prototype && (
                        <a
                          className={styles.TooltipValue}
                          href={figmaLinks.prototype}
                          key={figmaLinks.prototype}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Prototype
                        </a>
                      )}
                      {figmaLinks.inspect && (
                        <a
                          href={figmaLinks.inspect}
                          className={styles.TooltipValue}
                          key={figmaLinks.inspect}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Inspect
                        </a>
                      )}
                      {figmaLinks.designSystem && (
                        <a
                          href={figmaLinks.designSystem}
                          className={styles.TooltipValue}
                          key={figmaLinks.inspect}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Design System
                        </a>
                      )}
                    </div>
                  )}
                </>
              }
            />
            {(isOverriddenByFeatureFlag || isOverriddenByPreference) && (
              <Tooltip
                dataHcName={`${dataHcName}-override-warning`}
                trigger={
                  <AlertIcon
                    height={13}
                    dataHcName={`${dataHcName}-override-button`}
                  />
                }
                label={
                  isOverriddenByFeatureFlag
                    ? 'This experience flag is controlled by a feature flag.'
                    : 'This experience flag is controlled by a user or org preference.'
                }
              />
            )}
          </div>
        );
        v.push({
          type: 'switch',
          item: {
            content,
          },
          switchProps: {
            dataHcName: `${dataHcName}-${experienceFlagId}-control`,
            switchOnOption: { value: true },
            switchOffOption: { value: false },
            value: experienceFlagsWithOverrides[experienceFlagId],
            onChange: (experienceOption: boolean) => {
              if (saveToLocalStorage) {
                localStorageActions.setItem(
                  experienceFlagId,
                  `${experienceOption}`,
                );
              }
              experienceFlagSet({
                type: experienceFlagId,
                value: experienceOption,
              });
            },
          },
        });
      }
    });
    return v;
  }, [devtoolsState, experienceFlagsWithOverrides, expFlagsNoDevTool]);
  return (
    <HcsDevToolsPortal
      groups={[
        {
          title: 'Experience Flags',
          items: [
            {
              type: 'item',
              item: {
                content: (
                  <div className={styles.AllControlRow}>
                    <div className={styles.AllControlLabel}>
                      <div className={styles.CheckCell} />
                      <span>Save to LocalStorage </span>
                    </div>
                    <div>
                      <Checkbox
                        dataHcName={`${dataHcName}-save`}
                        checked={
                          localStorageActions.getItem(LOCAL_STORAGE_KEY) ===
                          'true'
                        }
                        onChange={(v) => {
                          if (v) {
                            experienceFlagIds.forEach((f) => {
                              localStorageActions.setItem(
                                f,
                                experienceFlagsWithOverrides[f].toString(),
                              );
                            });
                            localStorageActions.setItem(
                              LOCAL_STORAGE_KEY,
                              'true',
                            );
                          } else {
                            localStorageActions.clear();
                            localStorageActions.removeItem(LOCAL_STORAGE_KEY);
                          }
                        }}
                      />
                    </div>
                  </div>
                ),
              },
            },
            {
              type: 'item',
              item: {
                content: (
                  <div className={styles.AllControlRow}>
                    <div className={styles.AllControlLabel}>
                      <div className={styles.CheckCell} />
                      <span>Control All Flags </span>
                    </div>
                    <div>
                      <Anchor
                        dataHcName={`${dataHcName}-now`}
                        onClick={(e) => {
                          e.stopPropagation();
                          experienceFlagIds.forEach((experienceFlagId) => {
                            if (saveToLocalStorage) {
                              localStorageActions.setItem(
                                experienceFlagId,
                                'false',
                              );
                            }
                            experienceFlagSet({
                              type: experienceFlagId,
                              value: false,
                            });
                          });
                        }}
                      >
                        Now
                      </Anchor>
                      <span className={styles.AnchorDivider} />
                      <Anchor
                        dataHcName={`${dataHcName}-now`}
                        onClick={(e) => {
                          e.stopPropagation();
                          experienceFlagIds.forEach((experienceFlagId) => {
                            if (saveToLocalStorage) {
                              localStorageActions.setItem(
                                experienceFlagId,
                                'true',
                              );
                            }
                            experienceFlagSet({
                              type: experienceFlagId,
                              value: true,
                            });
                          });
                        }}
                      >
                        Next
                      </Anchor>
                      <span className={styles.AnchorDivider} />
                      <Anchor
                        dataHcName={`${dataHcName}-reset`}
                        onClick={(e) => {
                          e.stopPropagation();
                          localStorageActions.clear();
                          experienceFlagsResetAll();
                        }}
                      >
                        Reset
                      </Anchor>
                    </div>
                  </div>
                ),
              },
            },
            ...items,
          ],
        },
      ]}
    />
  );
};
