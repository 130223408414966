import { useQuery } from '@tanstack/react-query';

import { CerberusApi } from '@hcs/cerberus';
import {
  MsaUnemploymentTimeSeriesDocument,
  MsaUnemploymentTimeSeriesQuery,
  MsaUnemploymentTimeSeriesQueryVariables,
} from '@hcs/types';

const QUERY_KEY_MSA_UNEMPLOYMENT_TIME_SERIES = 'msa-unemployment-time-series';

export const useMsaUnemploymentTimeSeries = ({
  msaId,
  fromDate,
  toDate,
}: MsaUnemploymentTimeSeriesQueryVariables) => {
  return useQuery(
    [QUERY_KEY_MSA_UNEMPLOYMENT_TIME_SERIES, msaId, fromDate, toDate],
    async () => {
      return await CerberusApi.fetchQuery<
        MsaUnemploymentTimeSeriesQuery,
        MsaUnemploymentTimeSeriesQueryVariables
      >(MsaUnemploymentTimeSeriesDocument, {
        msaId,
        fromDate,
        toDate,
      });
    },
  );
};
