import React from 'react';

import { useAppConfigExtended, useCheckAppAccess } from '@hcs/auth';
import { HomeMagnifyingGlassIcon, ProductCard } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AppSlugs } from '@hcs/types';

import { APP_CONFIG_PEXP } from '../../constants';
import { useNavigateToPexp } from '../../hooks/useNavigateToPexp';
import { ProductPreviewDialog } from '../ProductPreviewDialog';

import styles from './ProductCards.module.css';

interface PexpProductCardProps {
  navigateToSSSelectPlan?: VoidFunction;
}

const dataHcName = 'property-explorer-card';

export const PexpProductCard = ({
  navigateToSSSelectPlan,
}: PexpProductCardProps) => {
  const appConfigExtended = useAppConfigExtended(AppSlugs.PropertyExplorer);
  const { active, handleOpen, handleClose } = useActiveState();
  const { data: hasAccess } = useCheckAppAccess(AppSlugs.PropertyExplorer);
  const navigateToPexp = useNavigateToPexp();
  const handleClick = () => {
    if (hasAccess) {
      return navigateToPexp();
    } else {
      return handleOpen();
    }
  };
  if (!appConfigExtended) {
    return null;
  }
  return (
    <>
      <ProductCard
        onClick={handleClick}
        theme={{
          ProductCard: styles.ProductCard,
        }}
        dataHcName={dataHcName}
        productName="Property Explorer"
        subheading={appConfigExtended.appConfig.secondaryName}
        productDescription="Premier valuation tool to unlock the true value of any home."
        hasAccess={!!hasAccess}
        Icon={HomeMagnifyingGlassIcon}
      />
      <ProductPreviewDialog
        active={active}
        appConfigExtended={{ hasAccess, appConfig: APP_CONFIG_PEXP }}
        navigateToSSSelectPlan={navigateToSSSelectPlan}
        onClose={handleClose}
      />
    </>
  );
};
