import { useMemo } from 'react';

import { useAccount } from '@hcs/authn';
import { AppSlugs } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useCheckAppAccess } from './useCheckAppAccess';

const PORTFOLIO_APP_SLUGS = AppSlugs.PortfolioMonitor;

export const usePortfolioMonitorZuoraSubscription = () => {
  const accountQuery = useAccount();
  const hasAppAccessQuery = useCheckAppAccess(PORTFOLIO_APP_SLUGS);

  const combinedQuery = combineUseQueryResult([
    accountQuery,
    hasAppAccessQuery,
  ]);

  const subscriptions = useMemo(() => {
    if (combinedQuery.isSuccess && hasAppAccessQuery.data) {
      const app = accountQuery.data?.legacy.accessibleApplications.find(
        (accessibleApp) => accessibleApp.name === PORTFOLIO_APP_SLUGS,
      );

      return app;
    }

    return undefined;
  }, [accountQuery.data, hasAppAccessQuery.data, combinedQuery.isSuccess]);

  return {
    ...combinedQuery,
    data: subscriptions,
  };
};
