import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import Bowser from 'bowser';

import { useCurrentAppConfig } from '@hcs/auth';
import {
  getSessionId,
  getUtmFromLocalStorage,
  useAccount,
  useIsImpersonatingUser,
} from '@hcs/authn';
import {
  useOrgSelfServeInfo,
  useOrgSubscriptionType,
} from '@hcs/self-serve-info';
import {
  EngagementEventArg,
  EngagementEventData,
  MeaningfulEventTypes,
} from '@hcs/types';
import { capitalizeFirstLetterOfEachWord } from '@hcs/utils';

import { getPageGroupFromUrl, isMeaningfulEvent } from '../utils';

import { useEngagementTrackingDevToolState } from './useEngagementTrackingDevToolState';
import { useEngagementTrackingEventDataSlice } from './useEngagementTrackingEventDataSlice';
import { useEngagementTrackingMeaningfulEventsSlice } from './useEngagementTrackingMeaningfulEventsSlice';
import { useEventQueueSlice } from './useEventQueueSlice';
import { useTrackBeaconEvent } from './useTrackBeaconEvent';
import { useTrackIntercomEvent } from './useTrackIntercomEvent';

declare global {
  interface Window {
    RELEASE: string;
  }
}

const browser = Bowser.getParser(window.navigator.userAgent);

// Tracker for calling Beacon and Intercom
export const useTrackEngagementEvent = () => {
  const {
    actions: { queueEvent },
    state: { shouldQueueEvents },
  } = useEventQueueSlice();
  const {
    state: { logEvents },
  } = useEngagementTrackingDevToolState();
  const {
    state: { event_section: lastEventSection },
    actions: { setEventSection },
  } = useEngagementTrackingMeaningfulEventsSlice();
  const { state: registeredEventDataState } =
    useEngagementTrackingEventDataSlice();
  // Hooks for populatating data on the event before sending
  const { appSlug } = useCurrentAppConfig();
  const accountQuery = useAccount();
  const { data: orgSsInfo } = useOrgSelfServeInfo();
  const { data: subscription_type } = useOrgSubscriptionType();
  const { data: isImpersonatingUser } = useIsImpersonatingUser();
  // Setup data registered on the slice for spreading onto the event_data before sending to the api
  const registeredEventData = useMemo(() => {
    let data: Partial<EngagementEventData> = {};

    for (const eventDataId in registeredEventDataState) {
      const eData = registeredEventDataState[eventDataId]?.event_data;
      if (eData) {
        data = {
          ...data,
          ...eData,
        };
      }
    }
    return data;
  }, [registeredEventDataState]);
  const ssEventData = orgSsInfo
    ? {
        plan: orgSsInfo.self_serve_plan,
        canceled_subscription: orgSsInfo.canceled_subscription,
        upcoming_self_serve_plan: orgSsInfo.upcoming_self_serve_plan,
      }
    : {};
  const trackBeaconEventMutation = useTrackBeaconEvent();
  const trackIntercomMutation = useTrackIntercomEvent();
  const mutation = useMutation(async (eventArg: EngagementEventArg) => {
    const session_id = getSessionId();
    const { utmCodes, otherTrackingParams } = getUtmFromLocalStorage();
    const triggeredEvents: EngagementEventArg[] = [
      {
        ...eventArg,
        event_data: {
          ...eventArg.event_data,
          // Save the url search params from when the event was triggered
          url_search_params: Object.fromEntries(
            new URLSearchParams(window.location.search).entries(),
          ),
        },
      },
    ];
    if (
      eventArg.event_data?.event_section &&
      eventArg.event_data.event_section !== lastEventSection
    ) {
      triggeredEvents.push({
        event_name: `${eventArg.event_data.event_section} Section Viewed`,
        event_type: MeaningfulEventTypes.SectionViewed,
        event_data: eventArg.event_data,
      });
    }
    setEventSection({
      event_section: eventArg.event_data?.event_section || null,
    });
    // don't track events from hc employees impersonating customers
    if (isImpersonatingUser === false || isImpersonatingUser === undefined) {
      triggeredEvents.forEach((eArg) => {
        const shouldLog =
          logEvents && (logEvents === 'all' || isMeaningfulEvent(eArg));
        if (shouldQueueEvents) {
          if (shouldLog) {
            console.log('[Engagement Tracking] Queue Event', eArg.event_name);
          }
          queueEvent(eArg);
        } else {
          // Augment event with data data
          const augmentedEvent = {
            app: appSlug,
            // Typically populated from the url but product can request routes be wrapped in EngagementEventPageGroup feature
            page_group: registeredEventData.page_group || getPageGroupFromUrl(),
            ...utmCodes,
            http_path: window.location.pathname,
            http_referer: document.referrer,
            auth_user_id: accountQuery.data?.user.id,
            email: accountQuery.data?.user.email,
            auth_organization_slug: accountQuery.data?.organization.slug,
            auth_organization_id: accountQuery.data?.organization.id,
            // in some cases like useSelfServiceRegister we have more up to date auth info
            ...eArg,
            subscription_type,
            session_id,
            event_data: {
              // Temporarily keep sessionId and subscriptionType in event_data and top-level
              session_id,
              ...registeredEventData,
              ...ssEventData,
              ...eArg.event_data,
              subscription_type,
              build: window.RELEASE,
              url_search_params: {
                ...otherTrackingParams,
                // Ensure the url search params from when the event was triggered are sent
                ...eArg.event_data?.url_search_params,
              },
              device: {
                browser: browser.getBrowserName(),
                browser_version: browser.getBrowserVersion(),
                browser_height: window.innerHeight,
                browser_width: window.innerWidth,
                os: browser.getOSName(),
                os_version: browser.getOSVersion(),
                user_agent: browser.getUA(),
              },
            },
          };
          if (shouldLog) {
            console.log(
              `[Engagement Tracking] ${
                augmentedEvent.event_type
                  ? `${capitalizeFirstLetterOfEachWord(
                      augmentedEvent.event_type,
                    )} `
                  : ''
              }Event: ${augmentedEvent.event_name}`,
              augmentedEvent,
            );
          }
          trackBeaconEventMutation.mutate(augmentedEvent);
          trackIntercomMutation.mutate(augmentedEvent);
        }
      });
    }
  });
  return mutation;
};
