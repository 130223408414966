import React, { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './FlexScroll.module.css';

export interface FlexScrollFixedContentProp {
  // Pixel height of the fixed content
  height: number;
  className?: string;
  content: ReactNode;
}

interface FlexScrollTheme {
  FlexScroll?: string;
  Header?: string;
  Scroll?: string;
  Footer?: string;
}
export interface FlexScrollProps {
  dataHcName: string;
  dataHcEventSection?: string;
  className?: string;
  theme?: FlexScrollTheme;
  // Rendered in scrollable area
  children: ReactNode;
  noPadding?: boolean;
  header?: FlexScrollFixedContentProp;
  footer?: FlexScrollFixedContentProp;
}

export const FlexScroll = forwardRef<HTMLDivElement, FlexScrollProps>(
  (
    {
      dataHcName,
      dataHcEventSection,
      className,
      children,
      header,
      theme,
      noPadding,
      footer,
    }: FlexScrollProps,
    ref,
  ) => {
    return (
      <div
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSection}
        className={classNames(styles.FlexScroll, className, theme?.FlexScroll, {
          [styles.noPadding]: noPadding,
        })}
      >
        <div
          className={classNames(styles.Header, theme?.Header)}
          style={{ flex: `0 0 ${header?.height || 0}px` }}
        >
          {header?.content || null}
        </div>
        <div
          ref={ref}
          className={classNames(styles.Scroll, theme?.Scroll)}
          data-hc-name={`${dataHcName}-scroll-cell`}
        >
          {children}
        </div>
        <div
          className={classNames(styles.Footer, theme?.Footer)}
          style={{ flex: `0 0 ${footer?.height || 0}px` }}
        >
          {footer?.content || null}
        </div>
      </div>
    );
  },
);
