import React from 'react';

import {
  CreateNewOrderState,
  OrderTypeDescriptor,
  ProductType,
  ProductTypeDescriptor,
} from '@hcs/types';

import { PRODUCT_DETAILS } from '../constants';

import styles from './ProductButton.module.css';

interface Props {
  dataHcName: string;
  productType: ProductType;
  product: ProductTypeDescriptor;
  onClick: (
    productType: ProductType,
    orderTypes?: OrderTypeDescriptor[],
    updatedState?: Partial<CreateNewOrderState>,
  ) => void;
}

export const ProductButton = ({
  dataHcName,
  productType,
  product,
  onClick,
}: Props) => {
  const { title, description, Icon, iconProps } = PRODUCT_DETAILS[productType];

  return (
    <button
      className={styles.Tile}
      onClick={() => {
        onClick(productType, product.orderTypes, {
          orderTypes: product.orderTypes,
        });
      }}
      data-hc-name={dataHcName || 'tile-button'}
    >
      <div className={styles.Product}>
        <div
          data-hc-name={`${dataHcName}-product-icon`}
          className={styles.ProductIcon}
        >
          <Icon {...iconProps} />
        </div>
        <h4
          data-hc-name={`${dataHcName}-product-title`}
          className={styles.ProductTitle}
        >
          {title}
        </h4>
        <div
          data-hc-name={`${dataHcName}-product-description`}
          className={styles.ProductDescription}
        >
          {description}
        </div>
      </div>
    </button>
  );
};
