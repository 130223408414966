import { GeoLocation, GeoPrecision, PropertyStateLocation } from '@hcs/types';

// Returns undefined if lat and long are not set
export const locationToGeoLocation = (
  location?: PropertyStateLocation['location'] | null,
): GeoLocation | undefined => {
  const { latitude, longitude } = location || {};
  if (latitude && longitude) {
    return {
      latitude,
      longitude,
      precision: location?.geoPrecision,
    };
  }
  return undefined;
};

export const propertyStatesToGeoLocations = (
  propertyStates: PropertyStateLocation[],
): GeoLocation[] => {
  const geoLocations: GeoLocation[] = [];
  propertyStates.forEach((propertyState) => {
    const geoLocation = locationToGeoLocation(propertyState.location);
    if (geoLocation) {
      geoLocations.push(geoLocation);
    }
  });
  return geoLocations;
};

export const geoPrecisionSupportsStreetView = (
  location?: PropertyStateLocation['location'] | null,
): boolean =>
  !![
    GeoPrecision.Manual,
    GeoPrecision.Parcel,
    GeoPrecision.Rooftop,
    GeoPrecision.Street0,
  ].find(
    (p) =>
      p === location?.geoPrecision ||
      // TODO: Remove Uppercase condition when EXP-914 is fixed
      p.toUpperCase() === location?.geoPrecision,
  );
