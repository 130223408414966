import React, { ReactNode } from 'react';

import { useScreenSize } from '@hcs/hooks';

import { UserProfileIcon } from '../../../../svgs/icons/generics';
import { TabPopover } from '../../popovers/TabPopover';

import styles from './UserMenu.module.css';

const dataHcName = 'global-user-menu';

export interface UserMenuProps {
  triggerClassName?: string;
  content: ReactNode;
}

export const UserMenu = ({ triggerClassName, content }: UserMenuProps) => {
  const { isPhoneWidth } = useScreenSize();
  const lightTrigger = (
    <UserProfileIcon
      className={styles.Icon}
      dataHcName={`${dataHcName}-icon`}
      color="neutral-light-10"
    />
  );
  const darkTrigger = (
    <UserProfileIcon
      className={styles.Icon}
      dataHcName={`${dataHcName}-icon`}
      color="neutral-dark-20"
    />
  );
  return (
    <TabPopover
      dataHcName={`${dataHcName}-popover`}
      closeOnClick
      theme={{
        Button: triggerClassName,
        Content: styles.Content,
      }}
      buttonName="User Menu"
      trigger={isPhoneWidth ? lightTrigger : darkTrigger}
      closeTrigger={darkTrigger}
      inPhoneMenu={isPhoneWidth}
      content={content}
    />
  );
};
