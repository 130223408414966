import { useNavigate } from 'react-router';

import {
  APP_CONFIG_AGILE_SUITE,
  VIEW_ORDER_TABS_AGILE_SUITE,
  VIEW_PATHS_ORDER_MANAGER,
} from '@hcs/hc-products';

interface NavigateViewOrderArgs {
  orderId: number;
  needReview?: boolean;
  processItem?: boolean | null;
}

export const useNavigateViewOrder = () => {
  const navigate = useNavigate();

  return ({ orderId, needReview, processItem }: NavigateViewOrderArgs) => {
    const getViewOrderTab = () => {
      if (processItem === null || needReview) {
        return VIEW_ORDER_TABS_AGILE_SUITE.NEED_REVIEW;
      } else if (processItem === false) {
        return VIEW_ORDER_TABS_AGILE_SUITE.REMOVED;
      } else {
        return VIEW_ORDER_TABS_AGILE_SUITE.IN_ORDER;
      }
    };
    const viewOrderTab = getViewOrderTab();

    navigate(
      `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDER}/${orderId}`,
      {
        state: {
          tab: viewOrderTab,
        },
      },
    );
  };
};
