import React, { useEffect, useMemo } from 'react';

import {
  AutoComplete,
  AutoCompleteMultiSelectConfig,
  AutoCompleteProps,
} from '@hcs/design-system';
import { BuyBox, BuyBoxZipcode, MSA } from '@hcs/types';

import { useMsaZips } from '../../hooks';
import { useBuyBoxZipcodes } from '../../hooks/useBuyBoxZipcodes';

export const findUnSelectedZips = (
  selectedZips: BuyBoxZipcode[],
  allZips?: BuyBoxZipcode[] | null,
): BuyBoxZipcode[] => {
  console.log('in find unselected zips');
  if (selectedZips.length === 0 || !allZips) {
    return [];
  }
  return allZips.filter((zipcode) => !selectedZips.includes(zipcode));
};

export interface ZipsBuyBoxSourceConfig {
  source: 'buyBox';
  buyBoxId: BuyBox['id'];
}

export interface ZipsMsaSourceConfig {
  source: 'msa';
  msaId: MSA['msaId'] | null;
}

interface BuyBoxZipMultiSelectProps
  extends Omit<
    AutoCompleteProps<BuyBoxZipcode>,
    'dataHcName' | 'options' | 'config'
  > {
  onSelect: (
    selectedZips: BuyBoxZipcode[],
    unSelectedZips: BuyBoxZipcode[],
  ) => void;
  value: AutoCompleteMultiSelectConfig<BuyBoxZipcode>['value'] | null;
  config: ZipsBuyBoxSourceConfig | ZipsMsaSourceConfig;
  selectAllOnDataChange?: boolean;
}

const dataHcName = 'buy-box-zip-multi-select';
export const BuyBoxZipMultiSelect = ({
  config,
  onSelect,
  value,
  selectAllOnDataChange = false,
  onBlur,
  ...restProps
}: BuyBoxZipMultiSelectProps) => {
  const buyBoxZipcodesData = useBuyBoxZipcodes(
    config.source === 'buyBox' ? config.buyBoxId : null,
  );
  const msaZipcodes = useMsaZips(config.source === 'msa' ? config.msaId : null);
  const allZips =
    config.source === 'buyBox' ? buyBoxZipcodesData.data : msaZipcodes.data;
  const options = useMemo(() => {
    if (!allZips) {
      return [];
    }
    return allZips.map((zipcode) => {
      return {
        value: zipcode,
        label: zipcode,
        searchString: zipcode,
      };
    });
  }, [allZips]);

  useEffect(() => {
    if (allZips && selectAllOnDataChange) {
      const selectedZips = allZips;
      onSelect(selectedZips, []);
      if (onBlur) {
        onBlur();
      }
    }
    // @jdimattia: assumption is onSelect and onBlur will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allZips, selectAllOnDataChange]);

  return (
    <AutoComplete<BuyBoxZipcode>
      dataHcName={dataHcName}
      options={options}
      config={{
        selectType: 'multi',
        value: value || undefined,
        onSelect: (selectedZips: BuyBoxZipcode[]) => {
          onSelect(selectedZips, findUnSelectedZips(selectedZips, allZips));
        },
        label: 'Zipcodes',
      }}
      onBlur={onBlur}
      {...restProps}
    />
  );
};
