import { PortfolioMonitorBillingComponents } from '@hcs/types';
import { PortfolioFormNotificationTypes } from '@hcs/types';

const BillingComponentToNotificationType: {
  [key in PortfolioMonitorBillingComponents]: PortfolioFormNotificationTypes | null;
} = {
  [PortfolioMonitorBillingComponents.AVM]: PortfolioFormNotificationTypes.Avm,
  [PortfolioMonitorBillingComponents.LTV]: PortfolioFormNotificationTypes.Ltv,
  [PortfolioMonitorBillingComponents.MLS]: PortfolioFormNotificationTypes.Mls,
  [PortfolioMonitorBillingComponents.LIEN]: PortfolioFormNotificationTypes.Lien,
  [PortfolioMonitorBillingComponents.NOD]: PortfolioFormNotificationTypes.Nod,
  [PortfolioMonitorBillingComponents.Base]: null,
};

export const accessibleNotificationsFromSubscriptions = (
  subscriptions: PortfolioMonitorBillingComponents[],
) => {
  return subscriptions.reduce<PortfolioFormNotificationTypes[]>(
    (acc, subscription) => {
      const notificationType = BillingComponentToNotificationType[subscription];
      return notificationType ? [...acc, notificationType] : acc;
    },
    [],
  );
};
