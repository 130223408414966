import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CaseReducerActions, Slice, SliceCaseReducers } from '@reduxjs/toolkit';

type DispatchWrap = (...args: unknown[]) => void;
export const useMapDispatchToSliceActions = <
  S,
  A extends SliceCaseReducers<S>,
  N extends string,
>(
  slice: Slice<S, A>,
) => {
  const dispatch = useDispatch();
  return useMemo(() => {
    const results: { [key: string]: DispatchWrap } = {};
    for (const name in slice.actions) {
      const fn = slice.actions[name];
      results[name] = (payload: unknown) => {
        const action = fn?.(payload);
        if (action) {
          dispatch(action);
        }
        return action;
      };
    }
    return results as CaseReducerActions<A, N>;
  }, [slice.actions, dispatch]);
};
