import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Report, ReportId } from '@hcs/types';

import { ReportApi } from '../api';

import { QUERY_KEY_REPORT } from './useReport';

export const QUERY_KEY_CREATE_SHAREABLE_REPORT_LINK =
  'report-api-create-public-report-link';
export const useReportPublicLinkCreate = (reportId: ReportId) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      return await ReportApi.createPublicLink(reportId);
    },
    {
      onSuccess: (publicLink) => {
        const report = queryClient.getQueryData<Report>([
          QUERY_KEY_REPORT,
          reportId,
        ]);
        if (report && publicLink) {
          queryClient.setQueryData<Report>([QUERY_KEY_REPORT, reportId], {
            ...report,
            metadata: {
              ...report.metadata,
              publicLinkUid: publicLink.publicLinkUid,
            },
          });
        }
      },
    },
  );
};
