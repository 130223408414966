// please keep alphabetical, but keep the rental versions next to the sale versions of listing stats
export enum CerberusStatsFields {
  daysOnMarketMedian = 'listingStats.daysOnMarketMedian',
  daysOnMarketMedianRental = 'rentalListingStats.daysOnMarketMedian',
  daysOnMarketMedianMovingAvg = 'listingStats.daysOnMarketMedianMovingAvg',
  daysOnMarketMedianMovingAvgRental = 'rentalListingStats.daysOnMarketMedianMovingAvg',
  daysOnMarketNotContractMedian = 'listingStats.daysOnMarketNotContractMedian',
  daysOnMarketNotContractMedianRental = 'rentalListingStats.daysOnMarketNotContractMedian',
  daysOnMarketNotContractMedianMovingAvg = 'listingStats.daysOnMarketNotContractMedianMovingAvg',
  daysOnMarketNotContractMedianMovingAvgRental = 'rentalListingStats.daysOnMarketNotContractMedianMovingAvg',
  daysToCloseMedian = 'listingStats.daysToCloseMedian',
  daysToCloseMedianRental = 'rentalListingStats.daysToCloseMedian',
  daysToCloseMedianMovingAvg = 'listingStats.daysToCloseMedianMovingAvg',
  daysToCloseMedianMovingAvgRental = 'rentalListingStats.daysToCloseMedianMovingAvg',
  daysToContractMedian = 'listingStats.daysToContractMedian',
  daysToContractMedianRental = 'rentalListingStats.daysToContractMedian',
  daysToContractMedianMovingAvg = 'listingStats.daysToContractMedianMovingAvg',
  daysToContractMedianMovingAvgRental = 'rentalListingStats.daysToContractMedianMovingAvg',
  daysToFirstPriceDropMedian = 'listingStats.daysToFirstPriceDropMedian',
  daysToFirstPriceDropMedianRental = 'rentalListingStats.daysToFirstPriceDropMedian',
  daysToFirstPriceDropMedianMovingAvg = 'listingStats.daysToFirstPriceDropMedianMovingAvg',
  daysToFirstPriceDropMedianMovingAvgRental = 'rentalListingStats.daysToFirstPriceDropMedianMovingAvg',
  displayName = 'displayName',
  grossYieldMedian = 'hcri.grossYieldMedian',
  hpiLatestMonth = 'hpi.timeSeriesCurrent',
  listingsClosedCount = 'listingStats.listingsClosedCount',
  listingsClosedCountRental = 'rentalListingStats.listingsClosedCountRental',
  listingsClosedCountMovingAvg = 'listingStats.listingsClosedCountMovingAvg',
  listingsClosedCountMovingAvgRental = 'rentalListingStats.listingsClosedCountMovingAvg',
  listingsNewCount = 'listingStats.listingsNewCount',
  listingsNewCountRental = 'rentalListingStats.listingsNewCount',
  listingsNewCountMovingAvg = 'listingStats.listingsNewCountMovingAvg',
  listingsNewCountMovingAvgRental = 'rentalListingStats.listingsNewCountMovingAvg',
  listingsOnMarketCount = 'listingStats.listingsOnMarketCount',
  listingsOnMarketCountRental = 'rentalListingStats.listingsOnMarketCount',
  listingsOnMarketCountMovingAvg = 'listingStats.listingsOnMarketCountMovingAvg',
  listingsOnMarketCountMovingAvgRental = 'rentalListingStats.listingsOnMarketCountMovingAvg',
  listingsPriceChangeCount = 'listingStats.listingsPriceChangeCount',
  listingsPriceChangeCountRental = 'rentalListingStats.listingsPriceChangeCount',
  listingsPriceChangeCountMovingAvg = 'listingStats.listingsPriceChangeCountMovingAvg',
  listingsPriceChangeCountMovingAvgRental = 'rentalListingStats.listingsPriceChangeCountMovingAvg',
  listingsPriceDropCount = 'listingStats.listingsPriceDropCount',
  listingsPriceDropCountRental = 'rentalListingStats.listingsPriceDropCount',
  listingsPriceDropCountMovingAvg = 'listingStats.listingsPriceDropCountMovingAvg',
  listingsPriceDropCountMovingAvgRental = 'rentalListingStats.listingsPriceDropCountMovingAvg',
  listingsPriceDropPercent = 'listingStats.listingsPriceDropPercent',
  listingsPriceDropPercentRental = 'rentalListingStats.listingsPriceDropPercent',
  listingsPriceDropPercentMovingAvg = 'listingStats.listingsPriceDropPercentMovingAvg',
  listingsPriceDropPercentMovingAvgRental = 'rentalListingStats.listingsPriceDropPercentMovingAvg',
  listingsRemovedCount = 'listingStats.listingsRemovedCount',
  listingsRemovedCountRental = 'rentalListingStats.listingsRemovedCount',
  listingsRemovedCountMovingAvg = 'listingStats.listingsRemovedCountMovingAvg',
  listingsRemovedCountMovingAvgRental = 'rentalListingStats.listingsRemovedCountMovingAvg',
  listingsRemovedPercent = 'listingStats.listingsRemovedPercent',
  listingsRemovedPercentRental = 'rentalListingStats.listingsRemovedPercent',
  listingsRemovedPercentMovingAvg = 'listingStats.listingsRemovedPercentMovingAvg',
  listingsRemovedPercentMovingAvgRental = 'rentalListingStats.listingsRemovedPercentMovingAvg',
  listingsUnderContractCount = 'listingStats.listingsUnderContractCount',
  listingsUnderContractCountRental = 'rentalListingStats.listingsUnderContractCount',
  listingsUnderContractCountMovingAvg = 'listingStats.listingsUnderContractCountMovingAvg',
  listingsUnderContractCountMovingAvgRental = 'rentalListingStats.listingsUnderContractCountMovingAvg',
  listingsUnderContractPercent = 'listingStats.listingsUnderContractPercent',
  listingsUnderContractPercentRental = 'rentalListingStats.listingsUnderContractPercent',
  listingsUnderContractPercentMovingAvg = 'listingStats.listingsUnderContractPercentMovingAvg',
  listingsUnderContractPercentMovingAvgRental = 'rentalListingStats.listingsUnderContractPercentMovingAvg',
  listingStatsEventDate = 'listingStats.eventDate',
  listingStatsEventDateRental = 'rentalListingStats.eventData',
  marketGrade = 'marketQuality.grade',
  monthsOfSupplyMedian = 'listingStats.monthsOfSupplyMedian',
  monthsOfSupplyMedianRental = 'rentalListingStats.monthsOfSupplyMedian',
  monthsOfSupplyMedianMovingAvg = 'listingStats.monthsOfSupplyMedianMovingAvg',
  monthsOfSupplyMedianMovingAvgRental = 'rentalListingStats.monthsOfSupplyMedianMovingAvg',
  netPopulationGrowth = 'calculatedFields.netPopulationGrowth',
  populationLatestMonth = 'populationStats.populationLatestMonth',
  priceClosedMedian = 'listingStats.priceClosedMedian',
  priceClosedMedianRental = 'rentalListingStats.priceClosedMedian',
  priceClosedMedianMovingAvg = 'listingStats.priceClosedMedianMovingAvg',
  priceClosedMedianMovingAvgRental = 'rentalListingStats.priceClosedMedianMovingAvg',
  priceNewListMedian = 'listingStats.priceNewListMedian',
  priceNewListMedianRental = 'rentalListingStats.priceNewListMedian',
  priceNewListMedianMovingAvg = 'listingStats.priceNewListMedianMovingAvg',
  priceNewListMedianMovingAvgRental = 'rentalListingStats.priceNewListMedianMovingAvg',
  priceOnMarketMedian = 'listingStats.priceOnMarketMedian',
  priceOnMarketMedianRental = 'rentalListingStats.priceOnMarketMedian',
  priceOnMarketMedianMovingAvg = 'listingStats.priceOnMarketMedianMovingAvg',
  priceOnMarketMedianMovingAvgRental = 'rentalListingStats.priceOnMarketMedianMovingAvg',
  pricePerSqftClosedMedian = 'listingStats.pricePerSqftClosedMedian',
  pricePerSqftClosedMedianRental = 'rentalListingStats.pricePerSqftClosedMedian',
  pricePerSqftClosedMedianMovingAvg = 'listingStats.pricePerSqftClosedMedianMovingAvg',
  pricePerSqftClosedMedianMovingAvgRental = 'rentalListingStats.pricePerSqftClosedMedianMovingAvg',
  pricePerSqftNewListMedian = 'listingStats.pricePerSqftNewListMedian',
  pricePerSqftNewListMedianRental = 'rentalListingStats.pricePerSqftNewListMedian',
  pricePerSqftNewListMedianMovingAvg = 'listingStats.pricePerSqftNewListMedianMovingAvg',
  pricePerSqftNewListMedianMovingAvgRental = 'rentalListingStats.pricePerSqftNewListMedianMovingAvg',
  pricePerSqftOnMarketMedian = 'listingStats.pricePerSqftOnMarketMedian',
  pricePerSqftOnMarketMedianRental = 'rentalListingStats.pricePerSqftOnMarketMedian',
  pricePerSqftOnMarketMedianMovingAvg = 'listingStats.pricePerSqftOnMarketMedianMovingAvg',
  pricePerSqftOnMarketMedianMovingAvgRental = 'rentalListingStats.pricePerSqftOnMarketMedianMovingAvg',
  return1YearForecast = 'hpi.return1YearForecast',
  risk1YearLoss = 'hpi.risk1YearLoss',
  rpiLatestMonth = 'rpi.timeSeriesCurrent',
  rpiReturn1Year = 'rpi.return1Year',
  saleToListPriceMedian = 'listingStats.saleToListPriceMedian',
  saleToListPriceMedianRental = 'rentalListingStats.saleToListPriceMedian',
  saleToListPriceMedianMovingAvg = 'listingStats.saleToListPriceMedianMovingAvg',
  saleToListPriceMedianMovingAvgRental = 'rentalListingStats.saleToListPriceMedianMovingAvg',
  saleToListPriceOriginalMedian = 'listingStats.saleToListPriceOriginalMedian',
  saleToListPriceOriginalMedianRental = 'rentalListingStats.saleToListPriceOriginalMedian',
  saleToListPriceOriginalMedianMovingAvg = 'listingStats.saleToListPriceOriginalMedianMovingAvg',
  saleToListPriceOriginalMedianMovingAvgRental = 'rentalListingStats.saleToListPriceOriginalMedianMovingAvg',
}
