import {
  VECTILES_METRICS_CONFIG,
  VECTILES_METRICS_GROUP_CONFIG,
} from '../constants';

import { useHcMap } from './useHcMap';

export const useHcMapLayers = (mapId: string) => {
  const { mapState } = useHcMap(mapId);
  const zoom = mapState?.viewport.zoom;
  const vectilesMetricConfig = mapState?.heatmap.vectilesMetricId
    ? VECTILES_METRICS_CONFIG[mapState.heatmap.vectilesMetricId]
    : undefined;
  const vectilesMetricGroupConfig = mapState?.heatmap.vectilesMetricGroup
    ? VECTILES_METRICS_GROUP_CONFIG[mapState.heatmap.vectilesMetricGroup]
    : undefined;
  const vectilesLayerSource = zoom
    ? vectilesMetricGroupConfig?.vectilesLayers.find(
        ({ zoomRange }) => zoom >= zoomRange.min && zoom < zoomRange.max,
      )
    : undefined;
  return {
    hcMapLayersState: mapState?.heatmap,
    vectilesLayerSource,
    vectilesMetricConfig,
    vectilesMetricGroupConfig,
  };
};
