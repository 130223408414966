import { useCallback } from 'react';

import { useCerberusStatsFiltersForUser } from '@hcs/huell';
import { usePatchCerberusStatsFiltersForUser } from '@hcs/huell';
import { CerberusStatsFields } from '@hcs/types';
import { CerberusStatsFieldFilters, CerberusStatsFilterType } from '@hcs/types';
import { SORT_ORDER } from '@hcs/types';
import { logWarning } from '@hcs/utils';
import { getOppositeSortOrder } from '@hcs/utils';

import { useMarketStateSearchSlice } from '../hooks/useMarketStateSearchSlice';
import { isCerberusStatsFilterField } from '../utils/cerberusStatsFilters.utils';

export const useCerberusStatsFilters = (
  cerberusStatsFilterType: CerberusStatsFilterType | null,
) => {
  const filtersQuery = useCerberusStatsFiltersForUser();
  const { mutate, isLoading: mutationIsLoading } =
    usePatchCerberusStatsFiltersForUser();
  const { state: searchState, actions: searchActions } =
    useMarketStateSearchSlice();

  if (cerberusStatsFilterType === null) {
    logWarning(
      `useCerberusStatsFilters: called with cerberusStatsFilterType: ${cerberusStatsFilterType}`,
    );
  }

  const filterSet = cerberusStatsFilterType
    ? filtersQuery.data?.[cerberusStatsFilterType]
    : cerberusStatsFilterType;
  const sortField = filterSet?.sortField;
  const sortOrder = filterSet?.sortOrder;
  const interestedOnly = filterSet?.interestedOnly;

  const onToggleInterestedOnly = useCallback(() => {
    mutate([
      {
        op: 'add',
        path: `/${cerberusStatsFilterType}/interestedOnly`,
        value: !interestedOnly,
      },
    ]);
  }, [interestedOnly, cerberusStatsFilterType, mutate]);

  const onSort = useCallback(
    (newSortField: CerberusStatsFields | null) => {
      mutate([
        {
          op: 'add',
          path: `/${cerberusStatsFilterType}/sortField`,
          value: newSortField,
        },
        {
          op: 'add',
          path: `/${cerberusStatsFilterType}/sortOrder`,
          value:
            newSortField === sortField && sortOrder
              ? getOppositeSortOrder(sortOrder)
              : SORT_ORDER.Asc,
        },
      ]);
    },
    [sortField, sortOrder, cerberusStatsFilterType, mutate],
  );

  const onSearch = (searchStr: string | null) =>
    cerberusStatsFilterType === CerberusStatsFilterType.msa
      ? searchActions.updateMsaSearch(searchStr)
      : searchActions.updateZipSearch(searchStr);

  const fieldFiltersPath = `/${cerberusStatsFilterType}/fieldFilters`;

  const onUpdateFieldFilters = useCallback(
    (fieldFilters: CerberusStatsFieldFilters) => {
      mutate([
        {
          op: 'add',
          path: fieldFiltersPath,
          value: fieldFilters,
        },
      ]);
    },
    [fieldFiltersPath, mutate],
  );

  // need to clear out filters applied to fields that are now hidden
  const onUpdateFilterableFields = useCallback(
    (filterableFields: CerberusStatsFields[]) => {
      const existingFieldFilters = filterSet?.fieldFilters;
      // if no filters are stored, don't have to update
      if (!existingFieldFilters) {
        return;
      } else {
        // build up a new fieldFilters using the currently selected fields that match any stored filters
        const newFieldFilters =
          filterableFields.reduce<CerberusStatsFieldFilters>((accum, field) => {
            if (
              isCerberusStatsFilterField(field) &&
              existingFieldFilters[field]
            ) {
              // jdimattia: I'm not sure why this check is needed
              if (field === CerberusStatsFields.marketGrade) {
                accum[field] = existingFieldFilters[field];
              } else {
                accum[field] = existingFieldFilters[field];
              }
            }
            return accum;
          }, {});
        onUpdateFieldFilters(newFieldFilters);
      }
    },
    [filterSet?.fieldFilters, onUpdateFieldFilters],
  );

  const onUpdateFieldFilter = useCallback(
    <T extends keyof CerberusStatsFieldFilters>(
      field: T,
      value: CerberusStatsFieldFilters[T],
    ) => {
      mutate([
        {
          op: 'add',
          path: `${fieldFiltersPath}/${field}`,
          value,
        },
      ]);
    },
    [fieldFiltersPath, mutate],
  );

  const onClearFieldFilter = useCallback(
    (field: keyof CerberusStatsFieldFilters) => {
      mutate([
        {
          op: 'add',
          path: `${fieldFiltersPath}/${field}`,
          value: null,
        },
      ]);
    },
    [fieldFiltersPath, mutate],
  );

  return {
    state: {
      filterSet,
      filterSetQueryIsLoading: filtersQuery.isInitialLoading,
      filterSetMutationIsLoading: mutationIsLoading,
      search:
        cerberusStatsFilterType === CerberusStatsFilterType.msa
          ? searchState.msaSearch
          : searchState.zipSearch,
    },
    actions: {
      onToggleInterestedOnly,
      onSort,
      onSearch,
      onUpdateFieldFilters,
      onUpdateFilterableFields,
      onUpdateFieldFilter,
      onClearFieldFilter,
    },
  };
};
