import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';

import { ActionButtons } from '@hcs/design-system';
import {
  DialogInputLayout,
  FormFieldsInput,
  FormFieldsSwitch,
} from '@hcs/forms';
import {
  OrganizationapiCreateOrgRequestInt,
  OrganizationResponseInt,
} from '@hcs/types';

import { useCreateOrganization } from '../../hooks/useCreateOrganization';
import { OrgTypeDropdown } from '../OrgTypeDropdown';

import styles from './CreateOrganization.module.css';
const FORM_SCHEMA = yup.object({
  name: yup.string().required('Name is required'),
  m3terAccountId: yup.string(),
  active: yup.boolean(),
  enterprise: yup.boolean(),
  test: yup.boolean(),
});
const MULTI_INPUT_THEME = { InputContainer: styles.MultiInputLine };
const dataHcName = 'create-org-form';

interface Props {
  defaultValues?: Partial<OrganizationapiCreateOrgRequestInt>;
  onCancel: VoidFunction;
  onSuccess: (newOrg: OrganizationResponseInt) => void;
  actionPortalIdRender?: string;
  className?: string;
}
export const CreateOrganizationForm = ({
  defaultValues,
  onCancel,
  className,
  onSuccess,
  actionPortalIdRender,
}: Props) => {
  const { mutate: createOrganization } = useCreateOrganization({ onSuccess });
  const form = useForm<OrganizationapiCreateOrgRequestInt>({
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      ...defaultValues,
      active: defaultValues?.active !== undefined ? defaultValues.active : true,
    },
  });
  const handleSubmit = (formData: OrganizationapiCreateOrgRequestInt) => {
    createOrganization(formData);
  };
  return (
    <FormProvider {...form}>
      <form
        data-hc-name={`${dataHcName}-form`}
        className={classNames(styles.Form, className)}
      >
        <DialogInputLayout
          label="Name"
          required
          dataHcName={`${dataHcName}-name`}
        >
          <FormFieldsInput
            name="name"
            label=""
            error={form.formState.errors.name?.message?.toString()}
            className={styles.Input}
          />
        </DialogInputLayout>
        <DialogInputLayout
          label="Org Type"
          dataHcName={`${dataHcName}-type`}
          theme={MULTI_INPUT_THEME}
          required={false}
        >
          <Controller
            name="type"
            control={form.control}
            render={({ field }) => {
              return (
                <OrgTypeDropdown
                  {...field}
                  className={styles.Input}
                  value={field.value || null}
                  onSelect={(val) => {
                    field.onChange(val);
                    form.clearErrors('type');
                  }}
                  error={form.formState.errors.type?.message?.toString()}
                />
              );
            }}
          />
        </DialogInputLayout>
        <DialogInputLayout
          label=""
          dataHcName={`${dataHcName}-salesforce`}
          theme={MULTI_INPUT_THEME}
          required={false}
        >
          <FormFieldsSwitch name="active" label="Active" />
          <FormFieldsSwitch name="test" label="Test Account" />
        </DialogInputLayout>
        <ActionButtons
          dataHcName={`${dataHcName}-actions`}
          actions={[
            {
              dataHcName: `${dataHcName}-cancel`,
              label: 'Cancel',
              secondary: true,
              onClick: onCancel,
            },
            {
              dataHcName: `${dataHcName}-submit`,
              label: 'Create Org',
              primary: true,
              onClick: form.handleSubmit(handleSubmit),
            },
          ]}
          portalIdRender={actionPortalIdRender}
        />
      </form>
    </FormProvider>
  );
};
