import { useCallback } from 'react';

import { IntercomEventData } from '@hcs/types';

import { useIntercomInstance } from './useIntercomInstance';

export const useLogIntercomEvent = () => {
  const intercomInstance = useIntercomInstance();
  return useCallback(
    (event_name: string, event_data: IntercomEventData) => {
      if (intercomInstance) {
        intercomInstance('trackEvent', event_name, event_data);
      }
    },
    [intercomInstance],
  );
};
