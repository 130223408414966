import React from 'react';

import { AgileEvaluationProductCard } from './AgileEvaluationProductCard';
import { AgileInsightsProductCard } from './AgileInsightsProductCard';

import styles from './AgilePdfProductCards.module.css';

const dataHcName = 'agile-pdf-product-cards';

export const AgilePdfProductCards = () => {
  return (
    <div className={styles.AgilePdfProductCards} data-hc-name={dataHcName}>
      <AgileInsightsProductCard />
      <AgileEvaluationProductCard />
    </div>
  );
};
