import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAccount } from '@hcs/authn';
import { useToastSlice } from '@hcs/toast';
import {
  CompDocument,
  DeleteDocumentArg,
  RentalCompDocument,
  ReportApiDocument,
  ReportEventTypes,
  ReportId,
} from '@hcs/types';

import { ReportApi } from '../api';
import { useReportEventsRecovery } from '../hooks/useReportEventsRecovery';

import { QUERY_KEY_DOCUMENT_ROLE } from './useDocumentRole';

export const useDocumentDelete = (reportId: ReportId) => {
  const queryClient = useQueryClient();
  const expectEvent = useReportEventsRecovery(reportId);
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const { data: account } = useAccount();
  return useMutation(
    async (deleteDocumentArg: DeleteDocumentArg) => {
      // Only show this toast when logged in
      if (account) {
        toastOpen({
          type: 'loading',
          title: 'Updating report values...',
          duration: null,
        });
      }
      return await ReportApi.deleteDocument(deleteDocumentArg);
    },
    {
      onMutate: async ({ reportId, documentId }) => {
        expectEvent(ReportEventTypes.DocumentsDeleted);
        // Update documentRole cache if populated
        const documentRoleQueryKey = [QUERY_KEY_DOCUMENT_ROLE, reportId];
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: documentRoleQueryKey });
        let deletedDocument: ReportApiDocument | undefined = undefined;
        // Snapshot the previous value
        const documentRoleCache =
          queryClient.getQueriesData<ReportApiDocument[] | undefined>(
            documentRoleQueryKey,
          ) || [];
        for (const entry of documentRoleCache) {
          if (entry) {
            const [queryKey, documentsForRole] = entry;
            // Filter out the deleted document and update the cache if it existed in this role
            const filtered: ReportApiDocument[] = [];
            if (documentsForRole) {
              for (let d = 0; d <= documentsForRole?.length || 0; d++) {
                const document = documentsForRole[d];
                if (document) {
                  if (document.documentId !== documentId) {
                    filtered.push(document);
                  } else {
                    deletedDocument = document;
                  }
                }
              }
            }
            // We need to make sure we have found the deleted document
            if (
              filtered.length !== documentsForRole?.length &&
              deletedDocument
            ) {
              // Update the cache if the document was deleted
              queryClient.setQueryData<ReportApiDocument[]>(queryKey, filtered);
              // Break the loop since a document can only have 1 role
              break;
            }
          }
        }

        // Return a context object with the snapshotted value
        return { deletedDocument };
      },
      onError: (err, vars, context) => {
        toastOpen({
          type: 'error',
          title: 'Error Removing Document',
        });
        const { deletedDocument } = context || {};
        if (deletedDocument) {
          const documentRoleQueryKey = [
            QUERY_KEY_DOCUMENT_ROLE,
            deletedDocument.role,
          ];
          const cachedData =
            queryClient.getQueryData<(CompDocument | RentalCompDocument)[]>(
              documentRoleQueryKey,
            );
          queryClient.setQueryData<ReportApiDocument[]>(documentRoleQueryKey, [
            ...(cachedData || []),
            deletedDocument,
          ]);
        }
      },
    },
  );
};
