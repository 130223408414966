import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Dropdown } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { RadioButton } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { ErrorIcon } from '@hcs/design-system';
import { usePatchReportPreferencesForUser } from '@hcs/huell';
import { usePatchSavedCompFilterSetsForUser } from '@hcs/huell';
import { useReportPreferencesForUser } from '@hcs/huell';
import { useSavedCompFilterSetsForUser } from '@hcs/huell';
import { getPrimaryFilterSetPreferencesKey } from '@hcs/huell';
import { getCompFieldConfig } from '@hcs/property-state';
import { SavedCompFilters, SavedCompFilterSet } from '@hcs/types';
import { CompFields, CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { getDatetimeString } from '@hcs/utils';

import { StarCheckbox } from '../../components-deprecated';
import { useAppliedSavedCompFilterSet } from '../../hooks';
import { useCompsFiltersDocument } from '../../hooks/useCompsFiltersDocument';
import {
  convertAppliedFiltersToSavedFilterSetValues,
  formatRelativeFilterValue,
  isAppliedFilterNotSavable,
} from '../../utils';

import styles from './SavedCompFilterSetFormDeprecated.module.css';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  className?: string;
  onSuccess?: (updatedFilterSets: SavedCompFilters) => void;
}
const MAX_FILTER_SETS = 10;
const dataHcName = 'saved-comp-filter-set-form';
export const SavedCompFilterSetFormDeprecated = ({
  reportId,
  compType,
  className,
  onSuccess,
}: Props) => {
  const preferenceKey =
    compType === CompTypes.Sold ? 'compFilterSetId' : 'rentalCompFilterSetId';
  // Queries
  const reportPreferencesQuery = useReportPreferencesForUser();
  const filterQuery = useCompsFiltersDocument(reportId, compType);
  const filterSetQuery = useSavedCompFilterSetsForUser(compType);
  const reachedMaxFilterSets =
    Object.keys(filterSetQuery.data || {}).length >= MAX_FILTER_SETS;
  // Local State
  const [editType, setEditType] = useState<'edit' | 'new'>(
    reachedMaxFilterSets ? 'edit' : 'new',
  );
  const [newName, setNewName] = useState('');
  const [newNameError, setNewNameError] = useState('');
  const [isPrimary, setIsPrimary] = useState(false);
  const [selectedFilterSetId, setSelectedFilterSetId] = useState<string | null>(
    Date.now().toString(),
  );
  const {
    data: { numSavableAppliedFilters },
  } = useAppliedSavedCompFilterSet(reportId, compType);
  const isFormDisabled = numSavableAppliedFilters < 1;
  // Mutations
  const selectedFilterSet =
    selectedFilterSetId && filterSetQuery.data?.[selectedFilterSetId];
  const currentPrimaryFilterSetId =
    reportPreferencesQuery.data?.[preferenceKey];
  const { mutate: patchReportPreferencesForUser } =
    usePatchReportPreferencesForUser();
  const { mutate: patchSavedCompFilterSetsForUser } =
    usePatchSavedCompFilterSetsForUser({
      compType,
      options: {
        onSuccess: (updatedFilterSets) => {
          onSuccess?.(updatedFilterSets);
          if (isPrimary) {
            // This will be defined here but TS doesn't know that
            patchReportPreferencesForUser?.([
              {
                op: 'add',
                path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
                value: selectedFilterSetId,
              },
            ]);
          } else if (currentPrimaryFilterSetId === selectedFilterSetId) {
            // This will be defined here but TS doesn't know that
            patchReportPreferencesForUser?.([
              {
                op: 'remove',
                path: `/${getPrimaryFilterSetPreferencesKey(compType)}`,
              },
            ]);
          }
          setSelectedFilterSetId(Date.now().toString());
        },
      },
    });

  const existingFilterSetOptions = useMemo(
    () =>
      Object.entries(filterSetQuery.data || {}).map(
        ([filterSetId, filterSet]) => {
          return {
            label: filterSet.label,
            searchString: filterSet.label,
            value: filterSetId,
          };
        },
      ),
    [filterSetQuery],
  );

  // Validate that new name is unique
  useEffect(() => {
    if (newName && filterSetQuery.data) {
      for (const filterSetId in filterSetQuery.data) {
        const filterSet = filterSetQuery.data[filterSetId];
        if (filterSet && filterSet.label === newName) {
          setNewNameError('Please give this filter set a unique name');
          return;
        }
      }
    }
    setNewNameError('');
  }, [newName, filterSetQuery.data]);

  if (
    filterQuery.isInitialLoading ||
    filterSetQuery.isInitialLoading ||
    reportPreferencesQuery.isInitialLoading
  ) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  // Handlers
  const handleChangeEditType = (newEditType: 'new' | 'edit') => {
    if (newEditType !== editType) {
      setEditType(newEditType);
      setNewName('');
      setSelectedFilterSetId(
        newEditType === 'new' ? Date.now().toString() : null,
      );
      setIsPrimary(false);
    }
  };
  const handleSelectFilterSet = (filterSetId: string | null) => {
    setSelectedFilterSetId(filterSetId);
    if (
      filterSetId &&
      reportPreferencesQuery.data?.[preferenceKey] === filterSetId
    ) {
      setIsPrimary(true);
    }
  };
  const handleSubmit = () => {
    // Mutations
    const appliedFilters = filterQuery.data?.data.filters;
    const updatedAt = getDatetimeString();
    if (appliedFilters && patchSavedCompFilterSetsForUser) {
      if (editType === 'edit' && selectedFilterSet) {
        const filterSetToSave: SavedCompFilterSet = {
          ...selectedFilterSet,
          updatedAt,
          values: convertAppliedFiltersToSavedFilterSetValues(appliedFilters),
          // Mutations
        };
        // Edit Existing Filter Set
        patchSavedCompFilterSetsForUser([
          {
            op: 'add',
            path: `/${selectedFilterSetId}`,
            value: filterSetToSave,
          },
        ]);
      } else {
        const filterSetToSave: SavedCompFilterSet = {
          label: newName,
          createdByReportId: reportId,
          updatedAt,
          values: convertAppliedFiltersToSavedFilterSetValues(appliedFilters),
          // Mutations
        };
        // Save New Filter Set
        patchSavedCompFilterSetsForUser([
          {
            op: 'add',
            path: `/${selectedFilterSetId}`,
            value: filterSetToSave,
          },
        ]);
      }
    }
  };

  return (
    <div data-hc-name={dataHcName} className={classNames(className)}>
      {isFormDisabled ? (
        <p className={styles.error}>
          You cannot save this filter set because the subject property is
          missing all related property data.
        </p>
      ) : (
        <p className={styles.Instructions}>
          Save these filters for future reports. All filters are saved relative
          (e.g. +/- 1 bed) to the subject.
        </p>
      )}
      <div className={styles.EditType}>
        {/* Create New */}
        <div className={styles.EditTypeOption}>
          <div className={styles.EditTypeRadio}>
            <RadioButton
              dataHcName={`${dataHcName}-new-checkbox`}
              checked={editType === 'new'}
              value="new"
              onChange={handleChangeEditType}
              disabled={reachedMaxFilterSets || isFormDisabled}
            />
            <h3
              data-hc-name={`${dataHcName}-new-title`}
              onClick={
                reachedMaxFilterSets || isFormDisabled
                  ? undefined
                  : () => handleChangeEditType('new')
              }
            >
              Create New
            </h3>
          </div>
          <div className={styles.EditTypeInput}>
            <Input
              dataHcName={`${dataHcName}-new-name`}
              value={newName}
              onChange={setNewName}
              disabled={editType !== 'new' || isFormDisabled}
              placeholder="Enter name for saved filters"
              error={newNameError}
              maxLength={25}
            />
          </div>
        </div>
        {/* Edit Existing */}
        <div className={styles.EditTypeOption}>
          <div className={styles.EditTypeRadio}>
            <RadioButton
              dataHcName={`${dataHcName}-edit-checkbox`}
              checked={editType === 'edit'}
              disabled={isFormDisabled || !existingFilterSetOptions.length}
              value="edit"
              onChange={handleChangeEditType}
            />
            <h3
              data-hc-name={`${dataHcName}-edit-title`}
              onClick={() =>
                !isFormDisabled &&
                !!existingFilterSetOptions.length &&
                handleChangeEditType('edit')
              }
            >
              Edit Existing
            </h3>
          </div>
          <div className={styles.EditTypeInput}>
            <Dropdown
              disabled={editType !== 'edit' || isFormDisabled}
              dataHcName={`${dataHcName}-edit-filter-set-id`}
              options={[...existingFilterSetOptions]}
              value={selectedFilterSetId || ''}
              onSelect={handleSelectFilterSet}
              placeholder="Enter name for saved filters"
              className={styles.EditDropdown}
            />
          </div>
        </div>
      </div>
      {/* Filters to Save */}
      <div
        className={styles.Filters}
        data-hc-name={`${dataHcName}-filter-values`}
      >
        {Object.entries(filterQuery.data?.data.filters || {}).map((entry) => {
          const appliedFilterValue = entry[1];
          const isError = isAppliedFilterNotSavable(appliedFilterValue);
          const dataHcNameField = `${dataHcName}-filter-value-${appliedFilterValue.field}`;
          return (
            <div
              key={`saved-filter-${appliedFilterValue.field}`}
              data-hc-name={dataHcNameField}
              className={styles.Filter}
            >
              <div
                className={styles.FilterLabel}
                data-hc-name={`${dataHcNameField}-label`}
              >
                {isError && (
                  <ErrorIcon
                    dataHcName={`${dataHcNameField}-error-icon`}
                    className={styles.ErrorIcon}
                  />
                )}
                {getCompFieldConfig(appliedFilterValue.field).label}
              </div>
              <div
                data-hc-name={`${dataHcNameField}-value`}
                className={classNames({ [styles.error]: isError })}
              >
                {isError
                  ? appliedFilterValue.field === CompFields.distance
                    ? 'Custom shapes cannot be saved'
                    : 'Cannot save filter because Subject is missing value'
                  : formatRelativeFilterValue(appliedFilterValue)}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.Actions}>
        <div>
          <StarCheckbox
            dataHcName={`${dataHcName}-primary`}
            checked={isPrimary}
            onChange={isFormDisabled ? setIsPrimary : () => null}
          />
          <span
            data-hc-name={`${dataHcName}-primary`}
            onClick={() => !isFormDisabled && setIsPrimary(!isPrimary)}
            className={styles.PrimaryLabel}
          >
            {' '}
            This is my primary filter set
          </span>
        </div>
        <div>
          <Button
            dataHcName={`${dataHcName}-submit`}
            disabled={
              isFormDisabled ||
              !!newNameError ||
              !selectedFilterSetId ||
              (editType === 'edit' ? !selectedFilterSet : !newName)
            }
            label="Save"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
