import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { useMapPreferencesForUser } from '@hcs/huell';
import { DEFAULT_LAYERS, DEFAULT_LAYERS_RENTAL } from '@hcs/maps';
import { HcMap } from '@hcs/maps';
import { PropertyMarker } from '@hcs/maps';
import { PropertyMarkerTypes } from '@hcs/maps';
import { useHcMap } from '@hcs/maps';
import { INITIAL_ZOOM } from '@hcs/maps';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import { GeoLocation } from '@hcs/types';
import { PreferencesKeys } from '@hcs/types';
import { ControlPosition } from '@hcs/types';
import {
  CompTypes,
  PropertyStateArgsCore,
  PropertyStateFields,
  PropertyStateType,
} from '@hcs/types';
import { CompIdentifier, ReportFeatures, ReportId } from '@hcs/types';
import { OpenMarkerPopup } from '@hcs/types';
import { formatNumberAbbrev } from '@hcs/utils';
import { locationToGeoLocation } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { HoveredCompState } from '../../features';
import { CompFarmListViewType } from '../../features/CompFarmList/CompFarmList.types';
import {
  useCompDocuments,
  useCompFarmList,
  useCompsFarmDocument,
  useCompsFilteredDocument,
  useReport,
  useSubjectDocument,
} from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import { CompFilterDistanceGeoJsonLayer } from '../CompFilterDistanceGeoJsonLayer';
import { CompMarkerPopup } from '../CompMarkerPopup';
import { CompMarkers, CompMarkersSelected } from '../CompMarkers';
import { SubjectMarker } from '../SubjectMarker';
import { SubjectMarkerPopup } from '../SubjectMarkerPopup';

interface Props {
  reportId: ReportId;
  compType: CompTypes;
  hoveredComp: HoveredCompState | null;
  selectedOnly?: boolean;
  onChangeClusterMarkers?: (v: boolean) => void;
  children?: ReactNode;
  onClick?: VoidFunction;
}

export const COMP_SELECTION_MAP_ID = 'REPORT_API/COMP_SELECTION_MAP';
const dataHcName = 'comp-selection-map';
export const COMP_SELECTION_MAP_FEATURES = [ReportFeatures.CompsSelect];
export const CompSelectionMapDeprecated = ({
  reportId,
  compType,
  hoveredComp,
  onClick,
  selectedOnly = false,
  children,
}: Props) => {
  const mapId = `${COMP_SELECTION_MAP_ID}-${reportId}-${compType}`;
  const { data: reportConfig } = useReportConfig(reportId);
  const {
    state: { listViewType },
  } = useCompFarmList(reportId, compType);
  const { data: subjectDocument } = useSubjectDocument(reportId);
  const compsQuery = useCompDocuments(reportId, compType);
  const compsFarmQuery = useCompsFarmDocument(reportId, compType);
  const compsFilteredQuery = useCompsFilteredDocument(reportId, compType);
  const { latitude, longitude } =
    subjectDocument?.data.propertyState.location || {};
  const { data: mapPreferences } = useMapPreferencesForUser();
  const { mapState } = useHcMap(mapId);
  const { isFetched } = useReport(reportId);
  const { showMarkers } = mapState?.markers || {};

  const isHoveredCompSelected = useMemo(() => {
    if (hoveredComp) {
      return !!compsQuery.data?.find(
        (d) => d.data.compID === hoveredComp.compSchema.compID,
      );
    }
    return false;
  }, [hoveredComp, compsQuery.data]);

  const [openMarkerPopup, setOpenMarkerPopup] =
    useState<OpenMarkerPopup | null>(null);
  const onCloseMarkerPopup = useCallback(() => setOpenMarkerPopup(null), []);
  const onOpenSubjectMarkerPopup = useCallback(
    () => setOpenMarkerPopup({ type: 'subject' }),
    [],
  );

  const subjectPropertyType = subjectDocument?.data.propertyState
    ? getPropertyStateFieldValue(PropertyStateFields.propertyType, {
        propertyStateType: PropertyStateType.Core,
        propertyState: subjectDocument.data.propertyState,
      })
    : undefined;

  const geoLocationsToFit: GeoLocation[] | undefined = useMemo(() => {
    const coords: GeoLocation[] = [];
    if (selectedOnly) {
      compsQuery.data?.forEach((compDoc) => {
        const compGeoLocation = locationToGeoLocation(
          compDoc.data.propertyState.location,
        );
        if (compGeoLocation) {
          coords.push(compGeoLocation);
        }
      });
    } else {
      compsFilteredQuery.data?.data.compIDs?.forEach((compId) => {
        const compSchema = compsFarmQuery.data?.data?.farm?.[compId];
        const compGeoLocation = locationToGeoLocation(
          compSchema?.propertyState.location,
        );
        if (compGeoLocation) {
          coords.push(compGeoLocation);
        }
      });
    }
    if (coords.length) {
      return coords;
    }
    return undefined;
  }, [
    selectedOnly,
    compsQuery.data,
    compsFilteredQuery.data,
    compsFarmQuery.data,
  ]);

  const combinedQueryResult = combineUseQueryResult([
    compsQuery,
    compsFilteredQuery,
    compsFarmQuery,
    compsFarmQuery,
  ]);

  if (!combinedQueryResult.isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-spinner`} />;
  }

  if (!latitude || !longitude) {
    return <div>Error: Missing subject coordinates</div>;
  }

  if (!isFetched) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const compId: CompIdentifier | undefined =
    openMarkerPopup?.type === 'comp' && openMarkerPopup?.compIdentifier
      ? openMarkerPopup.compIdentifier
      : hoveredComp?.compSchema?.compID &&
          listViewType === CompFarmListViewType.Table
        ? {
            type: 'compId',
            compId: hoveredComp?.compSchema?.compID,
            compType,
          }
        : undefined;

  const hoveredCompGeoLocation = hoveredComp
    ? locationToGeoLocation(hoveredComp.compSchema.propertyState.location)
    : undefined;
  const hoveredCompPropertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: hoveredComp?.compSchema.propertyState,
  };
  return (
    <HcMap
      uiPreferencesKey={
        compType === CompTypes.Rental
          ? PreferencesKeys.CompsMapRental
          : PreferencesKeys.CompsMapSale
      }
      onClick={onClick}
      layersControl={
        reportFeaturesSupportedAny(reportConfig, [ReportFeatures.Heatmaps])
          ? {
              position: ControlPosition.BottomRight,
              vectilesMetricGroups:
                compType === CompTypes.Rental
                  ? DEFAULT_LAYERS_RENTAL
                  : DEFAULT_LAYERS,
            }
          : undefined
      }
      latitude={latitude}
      longitude={longitude}
      zoom={INITIAL_ZOOM}
      propertyType={subjectPropertyType}
      mapId={mapId}
      dataHcName={dataHcName}
      fitBounds={
        geoLocationsToFit
          ? { coords: geoLocationsToFit, fitId: 'comps' }
          : undefined
      }
      satelliteControl={{ position: ControlPosition.BottomLeft }}
      zoomControl={{ position: ControlPosition.TopLeft }}
      clusterControl={{ position: ControlPosition.TopRight }}
    >
      <CompFilterDistanceGeoJsonLayer reportId={reportId} compType={compType} />

      {!selectedOnly && showMarkers && (
        <CompMarkers
          reportId={reportId}
          mapId={mapId}
          compType={compType}
          clustersEnabled={!mapPreferences?.disableClusters}
          onClickMarker={setOpenMarkerPopup}
        />
      )}
      {showMarkers && (
        <CompMarkersSelected
          mapId={mapId}
          reportId={reportId}
          compType={compType}
          onClickMarker={setOpenMarkerPopup}
        />
      )}
      <SubjectMarker reportId={reportId} onClick={onOpenSubjectMarkerPopup} />
      {hoveredComp && hoveredCompGeoLocation && (
        <PropertyMarker
          dataHcName={`${dataHcName}-marker-hovered`}
          type={PropertyMarkerTypes.Comp}
          mapId={mapId}
          markerId={hoveredComp.compSchema.compID}
          geoLocation={hoveredCompGeoLocation}
          listingStatus={
            compType === CompTypes.Rental
              ? getPropertyStateFieldValue(
                  PropertyStateFields.currentStatusRental,
                  hoveredCompPropertyStateArgs,
                )
              : getPropertyStateFieldValue(
                  PropertyStateFields.currentStatus,
                  hoveredCompPropertyStateArgs,
                )
          }
          selected={isHoveredCompSelected}
          label={formatNumberAbbrev(
            getPropertyStateFieldValue(
              compType === CompTypes.Rental
                ? PropertyStateFields.currentPriceRental
                : PropertyStateFields.currentPrice,
              hoveredCompPropertyStateArgs,
            ),
          )}
          pulse
        />
      )}
      {children}
      {compId && (
        <CompMarkerPopup
          compIdentifier={compId}
          reportId={reportId}
          onClose={onCloseMarkerPopup}
        />
      )}
      {openMarkerPopup?.type === 'subject' && (
        <SubjectMarkerPopup reportId={reportId} onClose={onCloseMarkerPopup} />
      )}
    </HcMap>
  );
};
