import React from 'react';

import { useIsLoggedIn } from '@hcs/authn';
import { MainNav } from '@hcs/design-system';
import { ItemsIcon } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import { dataHcEventSectionReportSearch } from '@hcs/report-api';

const dataHcName = 'pexp-left-nav';

export const PexpNav = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  if (!isLoggedIn) return null;
  const NAV_ITEMS_CONFIG = [
    {
      label: 'Search',
      navigateTo: `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SEARCH}`,
      dataHcName: `${dataHcName}-search-link`,
      Icon: HomeMagnifyingGlassIcon,
      dataHcEventSection: dataHcEventSectionReportSearch,
    },
    {
      label: 'Report History',
      navigateTo: `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.REPORT_HISTORY}`,
      dataHcName: `${dataHcName}-report-history-link`,
      dataHcEventSection: 'Report History',
      Icon: ItemsIcon,
    },
  ];
  return (
    <MainNav
      dataHcName={dataHcName}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
