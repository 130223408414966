import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { BulkEditBuyBoxFormData } from '@hcs/types';

import { bulkEditFormToBulkEditPatch, BuyBoxBulkEditError } from '../utils';
import { LeadFeedApi } from '../';

import { QUERY_KEY_BUY_BOX } from './useBuyBox';
import { QUERY_KEY_BUY_BOX_SUMMARIES } from './useBuyBoxSummaries';

export const useBuyBoxBulkEdit = () => {
  const queryClient = useQueryClient();
  return useMutation<
    undefined,
    BuyBoxBulkEditError | AxiosError,
    BulkEditBuyBoxFormData,
    void
  >(
    async (formData) => {
      return await LeadFeedApi.bulkEditBuyBoxes(
        bulkEditFormToBulkEditPatch(formData),
      );
    },
    {
      onSuccess: (emptyResult, formData) => {
        queryClient.invalidateQueries([QUERY_KEY_BUY_BOX_SUMMARIES]);
        formData.collectionIds.forEach((buyBoxId) => {
          queryClient.invalidateQueries([QUERY_KEY_BUY_BOX, { id: buyBoxId }]);
        });
      },
    },
  );
};
