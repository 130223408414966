import { useMemo } from 'react';

import { useUiPreferencesForUser } from '@hcs/huell';
import { CerberusStatsFields } from '@hcs/types';
import { PreferencesKeys } from '@hcs/types';

import {
  MARKET_STATE_MSA_ALL_CHARTS_ORDER,
  MARKET_STATE_MSA_ALL_COLUMNS_ORDER,
  MARKET_STATE_MSA_CHARTS_DEFAULT_INACTIVE,
  MARKET_STATE_MSA_COLUMNS_DEFAULT_INACTIVE,
  MARKET_STATE_ZIP_ALL_CHARTS_ORDER,
  MARKET_STATE_ZIP_ALL_COLUMNS_ORDER,
  MARKET_STATE_ZIP_CHARTS_DEFAULT_INACTIVE,
  MARKET_STATE_ZIP_COLUMNS_DEFAULT_INACTIVE,
} from '../constants';

type FieldsType = 'columnsMsa' | 'columnsZip' | 'chartsMsa' | 'chartsZip';

const getPreferencesKeyFromType = (
  type: FieldsType,
):
  | PreferencesKeys.MarketStateColumnsMsa
  | PreferencesKeys.MarketStateColumnsZip
  | PreferencesKeys.MarketStateChartsMsa
  | PreferencesKeys.MarketStateChartsZip => {
  switch (type) {
    case 'columnsMsa':
      return PreferencesKeys.MarketStateColumnsMsa;
    case 'columnsZip':
      return PreferencesKeys.MarketStateColumnsZip;
    case 'chartsMsa':
      return PreferencesKeys.MarketStateChartsMsa;
    case 'chartsZip':
      return PreferencesKeys.MarketStateChartsZip;
  }
};

const getInactiveDefaultFromType = (
  type: FieldsType,
): CerberusStatsFields[] => {
  switch (type) {
    case 'columnsMsa':
      return MARKET_STATE_MSA_COLUMNS_DEFAULT_INACTIVE;
    case 'columnsZip':
      return MARKET_STATE_ZIP_COLUMNS_DEFAULT_INACTIVE;
    case 'chartsMsa':
      return MARKET_STATE_MSA_CHARTS_DEFAULT_INACTIVE;
    case 'chartsZip':
      return MARKET_STATE_ZIP_CHARTS_DEFAULT_INACTIVE;
  }
};

const getAllFieldsFromType = (type: FieldsType): CerberusStatsFields[] => {
  switch (type) {
    case 'columnsMsa':
      return MARKET_STATE_MSA_ALL_COLUMNS_ORDER;
    case 'columnsZip':
      return MARKET_STATE_ZIP_ALL_COLUMNS_ORDER;
    case 'chartsMsa':
      return MARKET_STATE_MSA_ALL_CHARTS_ORDER;
    case 'chartsZip':
      return MARKET_STATE_ZIP_ALL_CHARTS_ORDER;
  }
};

// A Hook to return cerberus stats fields based on user preferences and defaults
export const useCerberusStatsFields = (type: FieldsType) => {
  const preferencesKey = getPreferencesKeyFromType(type);

  const mergedUserOrgUiPreferences = useUiPreferencesForUser();
  const { isInitialLoading, data } = mergedUserOrgUiPreferences;
  const preferredOrder = data?.[preferencesKey]?.order;
  const preferredInactive = data?.[preferencesKey]?.inactive;
  return useMemo(() => {
    const order: CerberusStatsFields[] = [];
    const active: { [key in CerberusStatsFields]?: boolean } = {};
    const inactiveDefaultList = getInactiveDefaultFromType(type);
    const inactiveDefaultMap = inactiveDefaultList.reduce<{
      [key in CerberusStatsFields]?: boolean;
    }>((accum, field) => {
      accum[field] = true;
      return accum;
    }, {});
    const inactive: { [key in CerberusStatsFields]?: boolean } =
      preferredInactive || {};
    // Columns that were added to the product after the user set their preferences
    // This feature is unused now but could be used to highlight new features for users.
    const newCols: { [key in CerberusStatsFields]?: boolean } = {};

    if (!isInitialLoading) {
      preferredOrder?.forEach((field: CerberusStatsFields) => {
        order.push(field);
        active[field] = true;
      });
      // Include any columns that were added to the product after preferences were set
      const allFieldsList = getAllFieldsFromType(type);
      allFieldsList.forEach((field) => {
        if (!active[field] && !inactive[field]) {
          newCols[field] = true;
          // do we want this new field to be inactive by default?
          if (inactiveDefaultMap[field]) {
            inactive[field] = true;
          } else {
            active[field] = true;
            order.push(field);
          }
        }
      });
    }
    if (!isInitialLoading) {
      return {
        ...mergedUserOrgUiPreferences,
        data: { order, active, inactive, newCols },
      };
    } else {
      return {
        ...mergedUserOrgUiPreferences,
        data: undefined,
      };
    }
  }, [
    preferredOrder,
    preferredInactive,
    mergedUserOrgUiPreferences,
    isInitialLoading,
    type,
  ]);
};
