import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  CompDocument,
  CompFarmUserPropertyAdjustments,
  DocumentRoles,
  RentalCompDocument,
  ReportEventTypes,
  ReportId,
} from '@hcs/types';

import { ReportApi } from '../api';
import { QUERY_KEY_DOCUMENT_ROLE } from '../hooks/useDocumentRole';
import { useSubscribeToReportEvents } from '../hooks/useSubscribeToReportEvents';
import { processUserAdjustmentChanges } from '../utils/userAdjustments.utils';

export const QUERY_KEY_COMPS_FARM_ADJUSTMENTS =
  'QUERY_KEY_COMPS_FARM_ADJUSTMENTS';
export const useCompsFarmAdjustments = (
  reportId: ReportId,
  documentRole: DocumentRoles.CompsFarm | DocumentRoles.RentalCompsFarm,
) => {
  const queryKey = [QUERY_KEY_COMPS_FARM_ADJUSTMENTS, reportId, documentRole];
  const queryClient = useQueryClient();
  useSubscribeToReportEvents({
    callbackId: QUERY_KEY_COMPS_FARM_ADJUSTMENTS,
    onMessage: (reportEvent) => {
      if (reportEvent.type === ReportEventTypes.DocumentsDeleted) {
        reportEvent.report.documents.forEach((d) => {
          if (
            d.role === DocumentRoles.Comp ||
            d.role === DocumentRoles.RentalComp
          ) {
            queryClient.invalidateQueries(queryKey);
          }
        });
      } else if (reportEvent.type === ReportEventTypes.DocumentsUpdated) {
        reportEvent.report.userPropertyAdjustmentChanges?.forEach((change) => {
          const adjustmentCache =
            queryClient.getQueryData<CompFarmUserPropertyAdjustments | null>(
              queryKey,
            ) || {};
          if (
            change.documentRole === DocumentRoles.Comp ||
            change.documentRole === DocumentRoles.RentalComp
          ) {
            const docRoleCache = queryClient.getQueryData<
              (CompDocument | RentalCompDocument)[]
            >([QUERY_KEY_DOCUMENT_ROLE, reportId, change.documentRole]);
            const changedDocument = docRoleCache?.find(
              (d) => d.documentId === change.documentID,
            );
            const changedCompId = changedDocument?.data.compID;
            if (changedCompId) {
              adjustmentCache[changedCompId] = {
                reportID: Number(reportId),
                documentID: '',
                documentRole:
                  documentRole === DocumentRoles.RentalCompsFarm
                    ? DocumentRoles.RentalComp
                    : DocumentRoles.Comp,
                adjustments: {
                  ...adjustmentCache?.[changedCompId],
                  ...processUserAdjustmentChanges(change.adjustments),
                },
              };
              queryClient.setQueryData<CompFarmUserPropertyAdjustments>(
                queryKey,
                adjustmentCache,
              );
            }
          }
        });
      }
    },
  });
  return useQuery(queryKey, async () => {
    return await ReportApi.fetchUserPropertyAdjustmentsFarm(
      reportId,
      documentRole,
    );
  });
};
