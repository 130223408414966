import { AxiosAccessTokenClientBearer, AxiosClient } from '@hcs/http-clients';
import {
  Account,
  RegisterRequest,
  SelfServeChangePlanRequest,
  SelfServeCheckoutRequest,
  SelfServeRecommendedPlanResponse,
  SelfServicePlansResponse,
  StripePortalRequest,
  StripeRedirectResponse,
} from '@hcs/types';
import { ACCOUNT_URL_EXTERNAL } from '@hcs/urls';

export const SelfServiceApi = {
  register: async (payload: RegisterRequest) => {
    const response = await AxiosClient.post<Account>(
      `${ACCOUNT_URL_EXTERNAL}/ss/register`,
      payload,
      {
        withCredentials: true,
      },
    );
    return response.data;
  },
  plans: async () => {
    return (
      await AxiosAccessTokenClientBearer.get<SelfServicePlansResponse>(
        `${ACCOUNT_URL_EXTERNAL}/ss/plans`,
      )
    ).data;
  },
  checkout: async (payload: SelfServeCheckoutRequest) => {
    return await AxiosAccessTokenClientBearer.post<StripeRedirectResponse>(
      `${ACCOUNT_URL_EXTERNAL}/ss/checkout`,
      payload,
    );
  },
  changePlan: async (payload: SelfServeChangePlanRequest) => {
    return await AxiosAccessTokenClientBearer.post<void>(
      `${ACCOUNT_URL_EXTERNAL}/ss/changeplan`,
      payload,
    );
  },
  portal: async (payload: StripePortalRequest) => {
    return await AxiosAccessTokenClientBearer.post<StripeRedirectResponse>(
      `${ACCOUNT_URL_EXTERNAL}/ss/portal`,
      payload,
    );
  },
  changePayment: async (payload: StripePortalRequest) => {
    return await AxiosAccessTokenClientBearer.post<StripeRedirectResponse>(
      `${ACCOUNT_URL_EXTERNAL}/ss/changepayment`,
      payload,
    );
  },
  cancelPlan: async () => {
    await AxiosAccessTokenClientBearer.delete<void>(
      `${ACCOUNT_URL_EXTERNAL}/ss/cancelplans`,
    );
  },
  recommendedPlan: async () => {
    return (
      await AxiosAccessTokenClientBearer.get<SelfServeRecommendedPlanResponse>(
        `${ACCOUNT_URL_EXTERNAL}/ss/recommended-plan`,
      )
    ).data;
  },
  convertLegacy: async () => {
    return await AxiosAccessTokenClientBearer.post<void>(
      `${ACCOUNT_URL_EXTERNAL}/ss/legacy/convert`,
    );
  },
};
