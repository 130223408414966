import React from 'react';

import { RadioOption, RadioSelect } from '../../selects/RadioSelect';

type MonthsAgoOption = RadioOption<number | null>;
export interface MonthsAgoSelectProps {
  dataHcName: string;
  disabled?: boolean;
  className?: string;
  value: number | null;
  onChange: (value: number | null) => void;
}

const OPTIONS: MonthsAgoOption[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Prior 3 Months',
    value: 3,
  },
  {
    label: 'Prior 6 Months',
    value: 6,
  },
  {
    label: 'Prior 9 Months',
    value: 9,
  },
  {
    label: 'Prior Year',
    value: 12,
  },
  {
    label: 'Prior 2 Years',
    value: 24,
  },
];
export const MonthsAgoSelect = ({
  dataHcName,
  className,
  value,
  disabled,
  onChange,
}: MonthsAgoSelectProps) => {
  return (
    <RadioSelect
      dataHcName={dataHcName}
      disabled={disabled}
      className={className}
      value={value}
      options={OPTIONS}
      onChange={onChange}
    />
  );
};
