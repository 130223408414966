import { useQuery } from '@tanstack/react-query';

import { LegacySelfServiceApi } from '../api';

export const QUERY_KEY_SELF_SERVICE_PAYMENT_METHOD =
  'self-service-payment-method';
export const useSelfServicePaymentMethod = (config?: { enabled: boolean }) => {
  return useQuery(
    [QUERY_KEY_SELF_SERVICE_PAYMENT_METHOD],
    async () => {
      const response = await LegacySelfServiceApi.fetchPaymentMethods();
      if (response.length) {
        return response[0];
      }
      return null;
    },
    {
      enabled: config !== undefined ? config.enabled : true,
    },
  );
};
