import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { IconProps } from '@hcs/types';

export const useIcon = (
  Svg: FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >,
  {
    dataHcName,
    dataHcEventType,
    dataHcEventName,
    dataHcEventIgnore,
    className,
    size,
    color,
    ...props
  }: IconProps,
) => {
  const sizeInput = () => {
    switch (size) {
      case Number(size):
        return size;
      case 'xs':
        return 14;
      case 'sm':
        return 16;
      case 'lg':
        return 30;
      case 'xl':
        return 60;
      default:
        return 24; //md is default
    }
  };
  const { ref, ...restProps } = props;
  return (
    <div
      data-hc-name={dataHcName}
      data-hc-event-type={dataHcEventType}
      data-hc-event-name={dataHcEventName}
      data-hc-event-ignore={dataHcEventIgnore}
      className={classNames('IconContainer', className)}
      style={{
        color: color ? `var(--${color})` : undefined,
        fontSize: sizeInput(),
      }}
    >
      <Svg {...restProps} />
    </div>
  );
};
