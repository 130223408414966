import { useMemo } from 'react';

import { useSelfServicePlans } from './useSelfServicePlans';

export const useSelfServicePlan = (planId: string | undefined) => {
  const { data: ssPlans, ...query } = useSelfServicePlans();
  return {
    ...query,
    data: useMemo(
      () => ssPlans?.find((plan) => plan.id === planId),
      [ssPlans, planId],
    ),
  };
};
