import { AxiosResponse } from 'axios';

import { AxiosAccessTokenClientBearer } from '@hcs/http-clients';
import {
  OrganizationapiIsSSOResponse,
  SamlapiActivateProviderConfigRequestExt,
  SamlProviderConfig,
  SaveSamlProviderConfig,
} from '@hcs/types';
import { ACCOUNT_URL } from '@hcs/urls';

export const SingleSignOnApi = {
  fetchIsSso: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<OrganizationapiIsSSOResponse>(
        `${ACCOUNT_URL}/organizations/${orgId}/is-sso`,
      );
    return response.data;
  },
  createOrgSamlProvider: async (
    orgId: number,
    payload: SaveSamlProviderConfig,
  ) => {
    const response = await AxiosAccessTokenClientBearer.post<
      SaveSamlProviderConfig,
      AxiosResponse<SamlProviderConfig>
    >(`${ACCOUNT_URL}/organizations/${orgId}/saml-providers`, payload);
    return response.data;
  },
  fetchOrgSamlProvider: async (orgId: number) => {
    const response =
      await AxiosAccessTokenClientBearer.get<SamlProviderConfig | null>(
        `${ACCOUNT_URL}/organizations/${orgId}/saml-providers`,
      );
    return response.data;
  },
  updateOrgSamlProvider: async (
    orgId: number,
    providerId: string,
    payload: SaveSamlProviderConfig,
  ) => {
    const response = await AxiosAccessTokenClientBearer.put<
      SaveSamlProviderConfig,
      AxiosResponse<SamlProviderConfig>
    >(
      `${ACCOUNT_URL}/organizations/${orgId}/saml-providers/${providerId}`,
      payload,
    );
    return response.data;
  },
  deleteOrgSamlProvider: async (orgId: number, providerId: string) => {
    await AxiosAccessTokenClientBearer.delete(
      `${ACCOUNT_URL}/organizations/${orgId}/saml-providers/${providerId}`,
    );
  },
  activateOrgSamlProvider: async ({
    orgId,
    providerId,
    activated,
  }: {
    orgId: number;
    providerId: string;
  } & SamlapiActivateProviderConfigRequestExt) => {
    const response = await AxiosAccessTokenClientBearer.put<
      SamlapiActivateProviderConfigRequestExt,
      AxiosResponse<SamlProviderConfig>
    >(
      `${ACCOUNT_URL}/organizations/${orgId}/saml-providers/${providerId}/activate`,
      { activated },
    );
    return response.data;
  },
};
