import { CompTypes } from '@hcs/types';
import {
  CompIdentifierId,
  CompSchema,
  ReportFeatures,
  ReportId,
} from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { sortCompSchemas } from '../utils';

import { useCompDocuments } from './useCompDocuments';
import { useCompsFarmDocument } from './useCompsFarmDocument';
import { useReportConfig } from './useReportConfig';
import { useCompsFilteredDocument } from '.';

interface SelectedOrSuggestedComp {
  documentId?: string | null;
  compIdentifier: CompIdentifierId;
  compSchema: CompSchema;
}

interface SelectedOrSuggestedCompsData {
  isSuggested: boolean;
  isSelected: boolean;
  compType: CompTypes;
  comps: SelectedOrSuggestedComp[];
}

export const useSelectedOrSuggestedComps = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const isRental = compType === CompTypes.Rental;
  const { data: reportConfig } = useReportConfig(reportId);
  const compsFilteredQuery = useCompsFilteredDocument(reportId, compType);
  const compsQuery = useCompDocuments(reportId, compType);
  const farmQuery = useCompsFarmDocument(reportId, compType);
  const combinedQueryDeps = combineUseQueryResult([
    compsFilteredQuery,
    compsQuery,
    farmQuery,
  ]);
  if (
    compsQuery.isSuccess &&
    farmQuery.isSuccess &&
    compsFilteredQuery.isSuccess
  ) {
    const isSuggested =
      !!reportConfig?.reportFeaturesSupport[
        isRental
          ? ReportFeatures.RentalCompsHcSuggested
          : ReportFeatures.CompsHcSuggested
      ] && !compsQuery.data.length;
    const isSelected = !isSuggested;
    // Pick comps from either comp documents or most similar from farm
    // Suggested comps are sorted by similarity
    const comps: SelectedOrSuggestedComp[] = [];
    if (isSelected) {
      compsQuery.data.forEach((compDocument) => {
        comps.push({
          documentId: compDocument.documentId,
          compSchema: compDocument.data,
          compIdentifier: {
            type: 'compId',
            compId: compDocument.data.compID,
            compType,
          },
        });
      });
    } else if (isSuggested) {
      compsFilteredQuery?.data?.data.hcSuggestedComps?.forEach((compId) => {
        const compSchema = farmQuery.data?.data.farm?.[compId];
        if (compSchema) {
          comps.push({
            compIdentifier: {
              type: 'compId',
              compId: compSchema.compID,
              compType,
            },
            compSchema,
          });
        }
      });
    }
    const data: SelectedOrSuggestedCompsData = {
      isSelected,
      isSuggested,
      comps: comps.sort((a, b) => sortCompSchemas(a.compSchema, b.compSchema)),
      compType,
    };
    return {
      ...combinedQueryDeps,
      data,
    };
  }

  return { ...combinedQueryDeps, data: undefined };
};
