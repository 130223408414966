import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY_PERSONALIZATION } from '@hcs/huell';
import { ReportId } from '@hcs/types';

import { ReportApi } from '../api';

export const QUERY_KEY_REPORT_COBRANDING = 'report-api-cobranding-report';
export const useReportCobranding = (reportId: ReportId) => {
  return useQuery(
    [QUERY_KEY_PERSONALIZATION, QUERY_KEY_REPORT_COBRANDING, reportId],
    async () => {
      return await ReportApi.fetchReportCobranding(reportId);
    },
  );
};
