import {
  AgileEvaluationIcon,
  AgileInsightsIcon,
  AgileSuiteIcon,
  InspectionsIcon,
} from '@hcs/design-system';
import {
  AgileProductGroupings,
  AgileSuiteProductConfig,
  AppConfig,
  AppSlugs,
} from '@hcs/types';

import AgileEvaluationProductImage from '../assets/product-images/agile-evaluation-image.png';
import AgileInsightsProductImage from '../assets/product-images/agile-insights-image.png';
import AgileInspectionProductImage from '../assets/product-images/agile-inspection-image.png';
import AgileSuiteProductImage from '../assets/product-images/agile-suite-image.png';

export const APP_CONFIG_AGILE_SUITE: AppConfig = {
  name: 'Agile Suite',
  secondaryName: 'Delivered by Order Manager',
  applicationKey: 'agile-suite',
  type: 'product',
  globalHeaderTitle: 'Order Manager',
  appSlug: AppSlugs.AgileSuite,
  rootPath: `/order-manager`,
  Icon: AgileSuiteIcon,
  descriptionLong: `
        Access a collection of tools to assess residential real estate valuation and risk
        for both individual assets and groups of properties.
      `,
  descriptionShort:
    'Order valuation solutions for loan servicing, investments, and more',
  marketingUrl: 'https://www.housecanary.com/agile-evaluation/',
  marketingImage: AgileSuiteProductImage,
};

export enum VIEW_PATHS_ORDER_MANAGER {
  ORDERS = '/orders',
  ORDER = '/order',
  REPORT_SUMMARY = '/report-summary',
  PDF = 'report/:reportId/pdf',
}

export enum VIEW_ORDER_TABS_AGILE_SUITE {
  IN_ORDER = 'in-order',
  NEED_REVIEW = 'need-review',
  REMOVED = 'removed',
}

export const AGILE_SUITE_CONFIG_AGILE_EVALUATION: AgileSuiteProductConfig = {
  name: 'Agile Evaluation',
  appSlug: AgileProductGroupings.AgileEvaluation,
  Icon: AgileEvaluationIcon,
  descriptionLong: `
        Unlock instant context around a home's true value with our easy-to-absorb individual
        home reports, featuring details, neighborhood data, and proprietary analysis built by
        our data scientists.
      `,
  descriptionShort: 'Condition-informed evaluation with inspection',
  marketingUrl: 'https://www.housecanary.com/agile-evaluation/',
  marketingImage: AgileEvaluationProductImage,
};

export const AGILE_SUITE_CONFIG_AGILE_INSIGHTS: AgileSuiteProductConfig = {
  name: 'Agile Insights',
  appSlug: AgileProductGroupings.AgileInsights,
  Icon: AgileInsightsIcon,
  descriptionLong: `
        Unlock instant context around a home's true value with our easy-to-absorb individual
        home reports, featuring details, neighborhood data, and proprietary analysis built by
        our data scientists.
      `,
  descriptionShort: 'Pre-appraisal assessment tool with AVM',
  marketingUrl: 'https://www.housecanary.com/agile-evaluation/',
  marketingImage: AgileInsightsProductImage,
};

export const AGILE_SUITE_CONFIG_AGILE_INSPECTION: AgileSuiteProductConfig = {
  name: 'Agile Inspection',
  appSlug: AgileProductGroupings.AgileInspection,
  Icon: InspectionsIcon,
  descriptionLong: `
    Property inspections executed by a qualified professional
    who's been trained to inspect certain types of homes in specific
    areas, ensuring high-quality, reliable inspection results.
  `,
  descriptionShort: '',
  marketingUrl: 'https://www.housecanary.com/agile-evaluation/',
  marketingImage: AgileInspectionProductImage,
};
