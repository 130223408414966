import { useMemo } from 'react';

import { CompTypes } from '@hcs/types';
import { ReportId } from '@hcs/types';
import { CompSchema } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useCompsFarmDocument } from './useCompsFarmDocument';
import { useCompsFilteredDocument } from './useCompsFilteredDocument';

export const useCompFarmListFiltered = (
  reportId: ReportId,
  compType: CompTypes,
) => {
  const farmDocumentQuery = useCompsFarmDocument(reportId, compType);
  const filterDocumentQuery = useCompsFilteredDocument(reportId, compType);
  const { data: farmDocument } = farmDocumentQuery;
  const { data: filteredDocument } = filterDocumentQuery;
  const data = useMemo(() => {
    const data: CompSchema[] = [];
    if (farmDocument && filteredDocument) {
      filteredDocument.data.compIDs?.forEach((compId) => {
        const schema = farmDocument.data.farm?.[compId];
        if (schema) {
          data.push(schema);
        }
      });
    }
    return data;
  }, [farmDocument, filteredDocument]);

  return {
    ...combineUseQueryResult([farmDocumentQuery, filterDocumentQuery]),
    data,
  };
};
