import React from 'react';

import { SearchIcon, TabPopover } from '@hcs/design-system';
import { useScreenSize } from '@hcs/hooks';

import { OrderItemsSearchAutoComplete } from '../OrderItemsSearchAutoComplete';

import styles from './HeaderContent.module.css';

const dataHcName = 'header-order-items';

interface Props {
  onClickOrder: (v: {
    orderId: number;
    needReview?: boolean;
    processItem?: boolean | null;
  }) => void;
}
export const HeaderContent = ({ onClickOrder }: Props) => {
  const { isPhoneWidth } = useScreenSize();
  const lightTrigger = (
    <SearchIcon color={isPhoneWidth ? 'neutral-light-10' : undefined} />
  );
  const darkTrigger = <SearchIcon />;
  return (
    <TabPopover
      dataHcName={`${dataHcName}-search`}
      trigger={isPhoneWidth ? lightTrigger : darkTrigger}
      closeTrigger={darkTrigger}
      inPhoneMenu={isPhoneWidth}
      theme={{
        Content: styles.Content,
      }}
      content={
        <div className={styles.SearchContent}>
          <label className={styles.SearchLabel}>Search Orders:</label>
          <OrderItemsSearchAutoComplete
            dataHcName={`${dataHcName}-search-field`}
            className={styles.SearchInput}
            hideChevron
            onSelect={(orderItem) => {
              onClickOrder({
                orderId: orderItem.orderId,
                processItem: orderItem.processItem,
              });
            }}
          />
        </div>
      }
    />
  );
};
