import { Color } from '@deck.gl/core/typed';
import {
  DraggingEvent,
  Feature,
  FeatureCollection,
  Geometry,
  GuideFeatureCollection,
  LineStringCoordinates,
  ModeProps,
  MultiLineStringCoordinates,
  MultiPointCoordinates,
  MultiPolygonCoordinates,
  PolygonCoordinates,
  Position,
} from '@nebula.gl/edit-modes';
import {
  EditHandleFeature,
  EditHandleType,
  GuideFeature,
  PointerMoveEvent,
  TentativeFeature,
} from '@nebula.gl/edit-modes/src/types';
import turfArea from '@turf/area';
import turfCentroid from '@turf/centroid';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { convertArea, convertLength, Units } from '@turf/helpers';
import { RGBAColor } from 'deck.gl';
import isEmpty from 'lodash/isEmpty';

import { DrawType, FeatureProperties, GeometryTypesEnum } from '@hcs/types';
import { QUARTER_OF_AN_ACRE_IN_SQUARE_FEET } from '@hcs/utils';

export const GEO_JSON_COLOR = '#3388ff';

export enum DISTANCE_UNITS {
  MILES = 'miles',
  METERS = 'meters',
}

export const isPolygonCoordinates = (
  val?:
    | Position
    | LineStringCoordinates
    | PolygonCoordinates
    | MultiPointCoordinates
    | MultiLineStringCoordinates
    | MultiPolygonCoordinates,
): val is PolygonCoordinates => {
  return (
    (val as PolygonCoordinates)?.length !== undefined &&
    (val?.length || 0) > 0 &&
    Array.isArray(val?.[0])
  );
};

// This will convert a center point and radius to a POLYGON type that can
// be displayed on the screen, will ensure the final polygon has units
// in miles
export const getGEOJSONPolygonCircleForDisplay = (
  geoJsonFilter: Feature,
): GeoJSON.Feature => {
  const subjectLocation = geoJsonFilter.geometry.coordinates as Position;
  const radius =
    geoJsonFilter?.properties?.radius_unit === DISTANCE_UNITS.METERS
      ? convertLength(
          geoJsonFilter?.properties?.radius,
          DISTANCE_UNITS.METERS,
          DISTANCE_UNITS.MILES,
        )
      : geoJsonFilter?.properties?.radius;

  const circlePolygon = circle(
    [subjectLocation?.[0], subjectLocation?.[1]],
    radius,
    {
      units: DISTANCE_UNITS.MILES,
    },
  );
  return {
    ...circlePolygon,
    properties: {
      shape: GeometryTypesEnum.CIRCLE,
      drawType: 'radius' as DrawType,
      radius: radius,
    } as FeatureProperties,
  };
};

export const getFeatureCollection = (feature: Feature): FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: [feature],
  } as FeatureCollection;
};

export const getColorWithAlpha = (color: Color, alpha: number): RGBAColor => {
  return [...color.slice(0, 3), alpha] as unknown as RGBAColor;
};

export const isPosition = (
  val?:
    | Position
    | LineStringCoordinates
    | PolygonCoordinates
    | MultiPointCoordinates
    | MultiLineStringCoordinates
    | MultiPolygonCoordinates,
): val is Position => {
  return (val as Position)?.length === 2 && typeof val?.[0] === 'number';
};

export const formatMeasurement = (measure: number): string => {
  return measure.toFixed(2);
};

export const getObjectFromGuides = (
  guides: GuideFeatureCollection,
): Readonly<GuideFeature> | undefined => {
  return guides.features.find(
    (f) => isEmpty(f.properties) && f.geometry.coordinates.length > 2,
  );
};

export const updateMeasurements = (
  event: PointerMoveEvent,
  props: ModeProps<FeatureCollection>,
  modeType: GeometryTypesEnum,
  area: number,
  coordsVal?:
    | Position
    | LineStringCoordinates
    | PolygonCoordinates
    | MultiPointCoordinates
    | MultiLineStringCoordinates
    | MultiPolygonCoordinates,
  guides?: TentativeFeature | GuideFeatureCollection | null,
): void => {
  const {
    modeConfig: { mouseMove, measurementCallback },
  } = props;
  mouseMove(event.mapCoords);
  if (guides) {
    const areaSqFt = convertArea(area, 'meters', 'feet');

    const areaSquareMiles =
      areaSqFt >= QUARTER_OF_AN_ACRE_IN_SQUARE_FEET
        ? convertArea(areaSqFt, 'feet', DISTANCE_UNITS.MILES)
        : undefined;

    const centroid = turfCentroid(guides);
    const coords: Position | undefined = isPolygonCoordinates(coordsVal)
      ? coordsVal?.[0]?.[0]
      : undefined;
    const options = {
      units: DISTANCE_UNITS.MILES as Units,
    };
    const radius =
      modeType === GeometryTypesEnum.CIRCLE && coords
        ? `${formatMeasurement(
            Math.max(
              distance(coords, centroid.geometry.coordinates, options),
              0.001,
            ),
          )} ${DISTANCE_UNITS.MILES}`
        : undefined;

    if (measurementCallback) {
      measurementCallback(
        areaSquareMiles
          ? `${formatMeasurement(areaSquareMiles)} mi²`
          : `${formatMeasurement(areaSqFt)} ft²`,
        radius,
      );
    }
  }
};

export const handlePointerMove = (
  event: PointerMoveEvent,
  props: ModeProps<FeatureCollection>,
  tentativeGuide?: TentativeFeature | null,
): void => {
  if (tentativeGuide) {
    updateMeasurements(
      event,
      props,
      GeometryTypesEnum.CIRCLE,
      turfArea(tentativeGuide),
      tentativeGuide?.geometry.coordinates,
      tentativeGuide,
    );
  } else {
    const {
      modeConfig: { mouseMove },
    } = props;
    mouseMove(event.mapCoords);
  }
};

export const handleDragging = (
  event: DraggingEvent,
  props: ModeProps<FeatureCollection>,
  guides: GuideFeatureCollection,
) => {
  const feature = props.data.features?.[0];
  const modeType: GeometryTypesEnum =
    feature?.properties?.shape || GeometryTypesEnum.POLYGON;
  const coordsVal = feature ? feature?.geometry.coordinates : undefined;
  const area = props?.data.features[0]
    ? turfArea(props?.data.features[0].geometry)
    : 0;
  if (guides) {
    updateMeasurements(event, props, modeType, area, coordsVal, guides);
  }
};

function getEditHandlesForCoordinates(
  coordinates: Position[],
  positionIndexPrefix: number[],
  featureIndex: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  const editHandles: EditHandleFeature[] = [];
  for (let i = 0; i < coordinates.length; i++) {
    const position = coordinates[i];
    if (position) {
      editHandles.push({
        type: 'Feature',
        properties: {
          guideType: 'editHandle',
          positionIndexes: [...positionIndexPrefix, i],
          featureIndex,
          editHandleType,
        },
        geometry: {
          type: 'Point',
          coordinates: position,
        },
      });
    }
  }
  return editHandles;
}

export function getEditHandlesForGeometry(
  geometry: Geometry,
  featureIndex: number,
  editHandleType: EditHandleType = 'existing',
): EditHandleFeature[] {
  let handles: EditHandleFeature[] = [];

  switch (geometry.type) {
    case 'Point':
      // positions are not nested
      handles = [
        {
          type: 'Feature',
          properties: {
            guideType: 'editHandle',
            editHandleType,
            positionIndexes: [],
            featureIndex,
          },
          geometry: {
            type: 'Point',
            coordinates: geometry.coordinates,
          },
        },
      ];
      break;
    case 'MultiPoint':
    case 'LineString':
      // positions are nested 1 level
      handles = handles.concat(
        getEditHandlesForCoordinates(
          geometry.coordinates,
          [],
          featureIndex,
          editHandleType,
        ),
      );
      break;
    case 'Polygon':
    case 'MultiLineString':
      // positions are nested 2 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        const pos = geometry?.coordinates?.[a];
        if (pos) {
          handles = handles.concat(
            getEditHandlesForCoordinates(
              pos,
              [a],
              featureIndex,
              editHandleType,
            ),
          );
        }
        if (geometry.type === 'Polygon') {
          // Don't repeat the first/last handle for Polygons
          handles = handles.slice(0, -1);
        }
      }

      break;
    case 'MultiPolygon':
      // positions are nested 3 levels
      for (let a = 0; a < geometry.coordinates.length; a++) {
        const posA = geometry?.coordinates?.[a];
        if (posA)
          for (let b = 0; b < posA.length; b++) {
            const posB = posA?.[b];
            if (posB) {
              handles = handles.concat(
                getEditHandlesForCoordinates(
                  posB,
                  [a, b],
                  featureIndex,
                  editHandleType,
                ),
              );
            }
            // Don't repeat the first/last handle for Polygons
            handles = handles.slice(0, -1);
          }
      }

      break;
    default:
      throw Error(`Unhandled geometry type: ${(geometry as Geometry)?.type}`);
  }

  return handles;
}
