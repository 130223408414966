import { useMemo } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  DexpEndpoint,
  DexpEndpointsFilterIds,
  DexpEndpointsParams,
} from '@hcs/types';
import { matchBeginningOfAllWords } from '@hcs/utils';

import { DataExplorerApi } from '../api/dataExplorer.api';

export const DEXP_ENDPOINTS_QUERY_KEY = 'dexp-endpoints';
export const useDexpEndpoints = (
  filters?: DexpEndpointsParams,
): UseQueryResult<DexpEndpoint[]> => {
  const endpointsQuery = useQuery(
    [DEXP_ENDPOINTS_QUERY_KEY],
    DataExplorerApi.fetchDexpEndpoints,
  );
  const data = useMemo(() => {
    const filteredData: DexpEndpoint[] = [];
    endpointsQuery.data?.forEach((endpoint) => {
      if (!filters?.excluded?.includes(endpoint.id)) {
        const searchString = filters?.[DexpEndpointsFilterIds.Search];
        let filterEndpoint = false;
        if (filters?.[DexpEndpointsFilterIds.Access] && !endpoint.access) {
          filterEndpoint = true;
        }
        if (
          searchString &&
          !(
            matchBeginningOfAllWords(searchString, endpoint.description) ||
            matchBeginningOfAllWords(searchString, endpoint.level) ||
            matchBeginningOfAllWords(searchString, endpoint.name)
          )
        ) {
          filterEndpoint = true;
        }

        if (filters?.included && !filters.included.includes(endpoint.id)) {
          filterEndpoint = true;
        }

        if (filterEndpoint === false) {
          filteredData.push(endpoint);
        }
      }
    });
    return filteredData;
  }, [endpointsQuery.data, filters]);

  if (endpointsQuery.data && data) {
    return {
      ...endpointsQuery,
      data,
    };
  }

  return endpointsQuery;
};
